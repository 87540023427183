import { get, getOr, isNil } from 'lodash/fp';
import React from 'react';
import * as yup from 'yup';
import StaticField from '../components/fields/display/StaticField';
import UpdateNumeric from '../components/fields/edition/UpdateNumeric';
import { getValueFromTable, weightFieldAfter } from '../utils';
import fieldTemplate from './fieldTemplate';
import raiseInvalidConfiguration from './raiseInvalidConfiguration';
import { InterestRateMode } from './schema';

const getDefaultInterestRate = (values, context, initialValue = null) => {
    // get interest rate config
    const config = get('selectedFinanceProduct.interestRateSetting', context);

    if (config === undefined) {
        // we do not have enough data for it
        return undefined;
    }

    const { mode, default: defaultValue } = config;

    switch (mode) {
        case InterestRateMode.FLAT:
        case InterestRateMode.RANGE:
            return defaultValue;

        case InterestRateMode.TABLE: {
            const { paymentTerm, downPayment } = values;
            const { table } = config;

            if (paymentTerm === undefined || downPayment === undefined) {
                // we are still missing data to compute it
                return undefined;
            }

            if (!table) {
                raiseInvalidConfiguration(context.t);
            }

            // look into the table
            // if there not enough data we can safely return 0 instead
            return getValueFromTable(
                table,
                [item => item.downPayment === downPayment.percentage, item => item.term >= paymentTerm],
                'term'
            );
        }

        default:
            // unknown base
            return initialValue;
    }
};

const resetOnChanges = (values, context, previousValue) => {
    // get interest rate config
    const mode = get('selectedFinanceProduct.interestRateSetting.mode', context);

    if (!(mode === InterestRateMode.TABLE)) {
        // we do nothing
        return previousValue;
    }

    // we reset
    return undefined;
};

const interestRateField = {
    // copy template
    ...fieldTemplate,

    // setup meta
    label: t => t('calculator.label.interestRate'),
    weight: weightFieldAfter('coe'),

    // setup states
    isViewable: getOr(false, 'selectedFinanceProduct.interestRateSetting.show'),
    isEditable: getOr(false, 'selectedFinanceProduct.interestRateSetting.editable'),

    // setup display rendering
    getDisplayProperties: () => ({
        children: props => (
            // this is not a component but a render function
            // eslint-disable-next-line react/destructuring-assignment, react/prop-types
            <StaticField {...props} value={isNil(props?.value) || props?.value === '' ? '-' : `${props?.value}%`} />
        ),
    }),

    // setup edition rendering
    editionComponent: UpdateNumeric,
    getEditionProperties: context => {
        // get interest rate config
        const { selectedFinanceProduct, formats, t } = context;
        const { interestRateSetting } = selectedFinanceProduct;
        // extract what we need
        const { min, max, step } = interestRateSetting;

        const minErrorMessage = t('calculator.error.interestRateMin', { min });
        const validation = yup
            .number()
            .typeError(minErrorMessage)
            .min(min, minErrorMessage)
            .max(max, t('calculator.error.interestRateMax', { max }))
            .required(minErrorMessage);

        return {
            min,
            max,
            step,
            prefix: '%',
            precision: 2,
            validation,
            fixedDecimalScale: formats?.fixedDecimalScale,
        };
    },

    // setup values management
    getInitialValue: getDefaultInterestRate,
    updates: {
        // reset the interest rate whenever the finance product changed
        financeProduct: () => undefined,

        // we need to update the value whenever the term change
        // or down payment
        paymentTerm: resetOnChanges,
        downPayment: resetOnChanges,
    },
};

export default interestRateField;
