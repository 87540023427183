import styled from 'styled-components';

export const Title = styled.div`
    padding: 6px 0 9.5px 0;
    font-size: 1.28rem;
    display: flex;
    flex-direction: row;
    color: #000000;
    height: 39px;
    box-sizing: border-box;
    cursor: pointer;
`;

export const TabContainer = styled.div`
    position: relative;
    margin-top: 25px;
`;

export const Tabs = styled.div`
    cursor: pointer;
    position: absolute;
    top: -55px;
    right: 0;
    display: flex;
    flex-direction: row;
    font-size: 0.78rem;
    text-transform: uppercase;
`;
export const TabItem = styled.div`
    color: #000000;
    border-bottom: 1px solid #dedede;
    padding: 5px 10px;
    line-height: 1;
    text-align: center;
    &.active {
        color: #000000;
        border-bottom: 2px solid #007acc;
    }
`;

export const DocumentContainer = styled.div`
    margin-right: 20px;
    @media screen and (max-width: 670px) {
        ${TabContainer} {
            margin-top: 0;
            ${Tabs} {
                position: static;
                margin-bottom: 20px;
                overflow: auto;
            }
        }
    }
`;

export const DocumentBg = styled.div`
    width: 100%;
    background-color: rgba(255, 255, 255, 0.31);
`;

export const DocumentGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
`;

export const PreviewContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const PreviewItem = styled.div`
    width: 105px;
    height: 150px;
    background-color: rgba(0, 0, 0, 0.06);
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        color: black;
        width: 105px;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 150px;
        display: block;
        border: 1px solid #eeeeee;
        cursor: pointer;
    }
    .clear {
        cursor: pointer;
        position: absolute;
        right: 3px;
        top: 3px;
        img {
            width: 20px;
            height: 20px;
            border: 0;
        }
    }
`;

export const Clear = styled.div`
    cursor: pointer;
    position: absolute;
    right: 3px;
    top: 3px;
    img {
        width: 20px;
        height: 20px;
        border: 0;
    }
`;

export const SVGBox = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 0;
    svg.default-image {
        font-size: 1.43rem;
        color: #aaa;
    }
    svg.loading {
        font-size: 1.43rem;
        color: #000000;
    }
    &.render {
        cursor: pointer;
    }
`;

export const PreviewUpload = styled.label`
    display: flex;
    flex: 1;
    min-width: 105px;

    .upload {
        flex: 1;
        height: 150px;
        text-transform: uppercase;
        background-color: rgba(0, 0, 0, 0.06);
        color: rgba(0, 0, 0, 0.38);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        cursor: pointer;
        position: relative;
        input {
            display: none;
        }
        .replace-style {
            width: 60px;
            text-align: center;
        }
    }
`;
