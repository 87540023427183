import styled, { css } from 'styled-components';
import BoxedContainer from '../shared/BoxedContainer';
import BoxedWrapper from '../shared/BoxedWrapper';

const BoxedTextareaWrapper = styled(BoxedWrapper.FullWidth)`
    min-height: 60px;
    height: auto;
    padding: 10px 15px;
    justify-content: center;
    line-height: 1;
    box-sizing: border-box;

    ${BoxedContainer.Label} {
        text-align: center;
        font-size: 0.85rem;
        margin: 0;
        line-height: 1.5;
    }
`;

export const Text = styled.p`
    font-size: 1.21rem;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 0;

    ${props =>
        props.onClick &&
        css`
            color: ${props.theme.themeHighlightColour};
            cursor: pointer;
        `}
`;

export default BoxedTextareaWrapper;
