import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash/fp';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Channel } from '../../../../../schema';
import { getCurrentCountry } from '../../../../../selectors';
import {
    getDealerEstablishment,
    GetDealerEstablishmentQuery,
    GetDealerEstablishmentQueryVariables,
} from './useDealerEstablishment.graphql';

const useDealerEstablishment = (id: string, channel: Channel) => {
    const { establishment, code } = useSelector(getCurrentCountry);
    const { data, loading } = useQuery<GetDealerEstablishmentQuery, GetDealerEstablishmentQueryVariables>(
        getDealerEstablishment,
        {
            variables: { dealerId: id },
            skip: isEmpty(id),
            fetchPolicy: 'cache-first',
        }
    );

    const selectedDealerEstablishment = useMemo(() => {
        if (code !== 'NZ') {
            return 0;
        }

        switch (channel) {
            case Channel.NEW:
                return data?.result?.new || establishment?.amount || 0;

            case Channel.EXPRESS:
                return data?.result?.express || establishment?.amount || 0;

            case Channel.USED:
                return data?.result?.used || establishment?.amount || 0;

            case Channel.EVENT:
                return data?.result?.event || establishment?.amount || 0;

            default:
                return 0;
        }
    }, [code, channel, data, establishment]);

    return useMemo(() => ({ selectedDealerEstablishment, loading }), [loading, selectedDealerEstablishment]);
};
export default useDealerEstablishment;
