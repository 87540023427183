import styled from 'styled-components';

const FormTabContainer = styled.div`
    position: relative;
    padding: 12.5px 30px;
    ul.react-tabs__tab-list {
        margin-right: -10px;
        margin-bottom: 18.5px;
        font-size: 0.93rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        li.react-tabs__tab {
            color: #9c9c9c;
            text-transform: uppercase;
            text-decoration: underline;
            cursor: pointer;
            margin: 0 10px;
            &:focus {
                outline: none;
            }
            &.react-tabs__tab--selected {
                color: #000000;
                text-decoration: none;
            }
        }
    }
    @media screen and (max-width: 670px) {
        ul.react-tabs__tab-list {
            top: -40px;
        }
    }

    .react-tabs__tab-panel > div:last-child {
        padding-bottom: 30px;
    }
`;

export default FormTabContainer;
