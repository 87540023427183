import { createContext, useContext } from 'react';
import { InjectedFormProps } from 'redux-form';
import { ApplicationFormValues } from '../../../Workflow/Finance/application/Form/context';
import { AppointmentDataFragment } from '../../appointment.graphql';
import { GetReservedAppointmentDatesQuery } from '../Edition.graphql';

export type AppointmentDatas = Omit<AppointmentDataFragment, 'latestApplication'>;

export type AppointmentFormValues = AppointmentDatas & {
    latestApplication: ApplicationFormValues;
};

export type AppointmentFormProps = {
    disabled?: boolean;
    useCustomerNationality: boolean;
    useCustomerBirthDay: boolean;
    reservedAppointments: GetReservedAppointmentDatesQuery['reserved'];
};

export type AppointmentFormContextData = AppointmentFormProps &
    InjectedFormProps<AppointmentFormValues, AppointmentFormProps> & {
        values: AppointmentFormValues;
    };

export const AppointmentFormContext = createContext<AppointmentFormContextData>(null!);

export const useAppointmentFormContext = () => {
    const context = useContext(AppointmentFormContext);

    if (!context) {
        throw new Error('AppointmentFormContext is not provided');
    }

    return context;
};
