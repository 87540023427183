import { get, isNil } from 'lodash/fp';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { Unit } from '../../../../../schema';
import { getCurrentCountry } from '../../../../../selectors';
import FieldContainer from '../../../../template/Field-container';
import NumberField from '../../../../template/Number';
import UnitField from '../../../../template/options-field/UnitField';
import { useFormContext } from './context';

const DownPaymentICC = () => {
    const { disabled, currency, rounding, values, change } = useFormContext();

    const icc = get('downPaymentSetting.icc', values);
    const { min, max } = icc || {};
    const { useExternalCalculator = false } = useSelector(getCurrentCountry);
    const noICC = isNil(icc);

    useEffect(() => {
        if (min === null || max === null || min === undefined || max === undefined) {
            change('downPaymentSetting.icc.step', null);
        }

        if (min === max && min !== null && min !== undefined) {
            change('downPaymentSetting.icc.step', 0);
            change('downPaymentSetting.icc.default', min);
        }

        if (useExternalCalculator && noICC) {
            change('downPaymentSetting.icc', {
                min: 0,
                max: 0,
                step: 0,
                default: 0,
                defaultUnit: Unit.PERCENTAGE,
            });
        }
    }, [change, min, max, useExternalCalculator, noICC]);

    if (!useExternalCalculator) {
        return null;
    }

    return (
        <div className="row">
            <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="sub-container-fluid">
                    <FieldContainer label="ICC Min">
                        <Field
                            component={NumberField}
                            disabled={disabled}
                            min={0}
                            name="downPaymentSetting.icc.min"
                            precision={rounding.percentage.count}
                            allowEmpty
                        />
                    </FieldContainer>
                    <span />
                    <FieldContainer label="ICC Max">
                        <Field
                            component={NumberField}
                            disabled={disabled}
                            min={0}
                            name="downPaymentSetting.icc.max"
                            precision={rounding.percentage.count}
                            allowEmpty
                        />
                    </FieldContainer>
                    <span />
                    <FieldContainer label="ICC Step">
                        <Field
                            component={NumberField}
                            disabled={(min === max && min !== null && min !== undefined) || disabled}
                            min={1}
                            name="downPaymentSetting.icc.step"
                            precision={rounding.percentage.count}
                            allowEmpty
                        />
                    </FieldContainer>
                </div>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="sub-container-fluid">
                    <div className="flex-3">
                        <FieldContainer label="Default">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                min={0}
                                name="downPaymentSetting.icc.default"
                                precision={rounding.percentage.count}
                                allowEmpty
                            />
                        </FieldContainer>
                    </div>
                    <span />
                    <UnitField currencySymbol={currency} label="" name="downPaymentSetting.icc.defaultUnit" />
                </div>
            </div>
        </div>
    );
};

export default DownPaymentICC;
