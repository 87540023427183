import { get, isEmpty } from 'lodash/fp';
import React from 'react';
import { useHistory } from 'react-router';
import ApvPortlet from '../../../../template/Portlet';
import { ListTable } from '../../../../ui/lists';
import { useFormContext } from './context';

const columns = [
    { id: 'identifier', name: 'ID', renderCell: get('identifier') },
    { id: 'name', name: 'Name', renderCell: get('name') },
];

const Dealers = () => {
    const { dealers } = useFormContext();
    const history = useHistory();

    if (isEmpty(dealers)) {
        return null;
    }

    return (
        <ApvPortlet name="Dealers" closable open>
            <div className="container-fluid">
                <br />
                <ListTable
                    columns={columns}
                    items={dealers}
                    onItemClick={item => history.push(`/scope/dealers/${item.id}`)}
                />
            </div>
        </ApvPortlet>
    );
};

export default Dealers;
