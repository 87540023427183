import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useCalculatorContext } from '../../../CalculatorContext';
import { useValues } from '../../../ValuesContext';
import Price from '../../../ui/Price';
import { useSnapshotValue } from '../ConnectedSnapshotField';
import { useFormatCurrency } from '../CurrencyField';

const CarPriceField = ({ setFieldOnEdition }) => {
    // get the calculator context
    const calculatorContext = useCalculatorContext();
    const { formatCurrency } = useFormatCurrency();

    // get the fields & values
    const { fields, isUsingSnapshot } = calculatorContext;
    const values = useValues();
    const snapshotValue = useSnapshotValue('carPrice');

    // get the value for the car price
    const { carPrice: price } = values;

    // get the editable state
    const editable =
        fields.carPrice.isEditable(calculatorContext, values) && !fields.carPrice.isInvalid(calculatorContext);

    // if the field is editable, we will update it on click
    const onClick = useMemo(() => (editable ? () => setFieldOnEdition('carPrice') : undefined), [
        editable,
        setFieldOnEdition,
    ]);

    return (
        <Price editable={editable} onClick={onClick}>
            {formatCurrency(isUsingSnapshot ? snapshotValue : price)}
        </Price>
    );
};

CarPriceField.propTypes = {
    setFieldOnEdition: PropTypes.func.isRequired,
};

export default CarPriceField;
