import styled from 'styled-components';

const Background = styled.div`
    background: #cecece;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -999;
`;

export default Background;
