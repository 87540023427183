import PropTypes from 'prop-types';
import React from 'react';
import { Field, fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import styled from 'styled-components';
import { ErrorMessageDiv } from '../../../containers/Layout';

const Container = styled.div`
    width: 25px;
    height: 35px;
    display: flex;
    align-items: center;
    padding-bottom: 5px;
`;

const CheckboxInput = ({ input, meta }) => {
    const { value = false, onChange, name } = input;
    const { active, touched, error = null } = meta;
    const hasError = !active && touched && !!error;

    const boolValue = Boolean(value);

    return (
        <>
            <Container>
                <input checked={boolValue} name={name} onChange={onChange} type="checkbox" readOnly />
            </Container>
            {hasError && <ErrorMessageDiv>{error}</ErrorMessageDiv>}
        </>
    );
};

CheckboxInput.propTypes = {
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};

const CheckboxField = props => <Field {...props} component={CheckboxInput} />;

export default CheckboxField;
