import { parseISO } from 'date-fns';
import { isNumber, get, set, flow, map, reverse, toString, toLower, isEmpty, trim, isString } from 'lodash/fp';

export const pickAndRename = mapper => values =>
    Object.entries(mapper).reduce((acc, [key, sourceKey]) => set(key, get(sourceKey, values), acc), {});

export const reverseMap = flow([Object.entries, map(reverse), Object.fromEntries]);

export const searchOnKeys = (keys, items) => {
    const entries = flow([
        map(item => ({
            item,
            data: keys
                .map(key => get(key, item))
                .filter(Boolean)
                .map(flow([toString, toLower])),
        })),
    ])(items);

    return hash => {
        if (isEmpty(trim(hash))) {
            return items;
        }

        const cleanedHash = toLower(hash);

        return entries.filter(entry => entry.data.some(part => part.includes(cleanedHash))).map(entry => entry.item);
    };
};

export const toDate = value => {
    if (value === undefined || value === null) {
        return value;
    }

    if (isString(value)) {
        return parseISO(value);
    }

    return value;
};

const UNIT_DURATIONS = {
    d: 24 * 60 * 60,
    h: 60 * 60,
    m: 60,
};

export const toDuration = value => {
    if (!isNumber(value)) {
        return '';
    }

    // get days
    const days = Math.floor(value / UNIT_DURATIONS.d);
    let remaining = value % UNIT_DURATIONS.d;

    // get hours
    const hours = Math.floor(remaining / UNIT_DURATIONS.h);
    remaining = value % UNIT_DURATIONS.h;

    // and minutes
    const minutes = Math.round(remaining / UNIT_DURATIONS.m);

    return [
        [days, 'd'],
        [hours, 'h'],
        [minutes, 'm'],
    ]
        .filter(item => item[0])
        .flatMap(item => item.join(''))
        .join(' ');
};
