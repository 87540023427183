import { set, getOr, isEqual, flow } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useState, useMemo, memo, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import defaultTheme from '../defaultTheme';
import * as defaultFields from '../fields';
import CalculatorComparisonFields from './ComparisonCalculatorFields';
import ComparisonContext from './ComparisonContext';
import ComparisonFieldsContext from './ComparisonFieldsContext';
import ComparisonContainer from './ui/ComparisonContainer';
import LabelsContainer from './ui/LabelsContainer';

const getMemoizedCallbacks = () => {
    // create an empty store
    let store = {};

    return (key, callback, dependencies) => {
        // get the current instance and previous dependencies
        const { current, dependencies: previousDependencies } = getOr({}, key, store);

        if (!current || !isEqual(previousDependencies, dependencies)) {
            // update the store because it is either the first time we see that instance
            // or dependencies changed
            store = set(key, { current: callback, dependencies }, store);

            return callback;
        }

        return current;
    };
};

const createNewCalculator = initialValues => {
    // first generate an uniq ID
    const id = uuidv4();

    // create a component for this instance
    const fieldComponent = props => <CalculatorComparisonFields {...props} id={id} />;

    // return the final instance
    return {
        id,
        initialValues,
        values: initialValues,
        errors: {},
        context: null,
        fieldComponent,
        memoizedCallbacks: getMemoizedCallbacks(),
    };
};

const CalculatorComparison = ({
    children,
    className,
    initialCalculators,
    fields = defaultFields,
    theme = defaultTheme,
    onChanges,
}) => {
    // list of calculators
    const [calculators, setCalculators] = useState(() => initialCalculators.map(createNewCalculator));

    const extendedTheme = useMemo(
        () => ({
            ...theme,
            calculator: {
                ...theme.calculator,
                // we need to override those two settings for the theme
                singleColumn: true,
                noLabel: true,
            },
        }),
        [theme]
    );

    // static methods
    const helpers = useMemo(
        () => ({
            // method to add a new calculator
            add: () =>
                setCalculators(instances => [
                    ...instances,
                    createNewCalculator(instances[instances.length - 1].values),
                ]),
            // method to remove a calculator
            remove: id =>
                setCalculators(instances => {
                    if (instances.length <= 1) {
                        // cannot delete the last one anyway
                        return instances;
                    }

                    return instances.filter(calculator => calculator.id !== id);
                }),
        }),
        [setCalculators]
    );

    // bind the whole thing together
    const items = useMemo(
        () =>
            calculators.map((calculator, index) => {
                // memoized callback for changes
                const onChange = calculator.memoizedCallbacks(
                    'onChange',
                    (newValues, newErrors, newContext) =>
                        setCalculators(instances =>
                            flow([
                                // update values
                                set([index, 'values'], newValues),
                                // update errors
                                set([index, 'errors'], newErrors),
                                // update context
                                set([index, 'context'], newContext),
                            ])(instances)
                        ),
                    [setCalculators, index]
                );

                // memoized callback for removal
                const remove = calculator.memoizedCallbacks(
                    'remove',
                    () => {
                        helpers.remove(calculator.id);
                    },
                    [calculator.id, helpers.remove]
                );

                return {
                    remove,
                    values: calculator.values,
                    errors: calculator.errors,
                    context: calculator.context,
                    props: {
                        key: calculator.id,
                        theme: extendedTheme,
                        fields,
                        initialValues: calculator.values,
                        onChange,
                        fieldComponent: calculator.fieldComponent,
                    },
                };
            }),
        [calculators, extendedTheme, fields, helpers]
    );

    useEffect(() => {
        if (onChanges) {
            onChanges(items, helpers);
        }
    }, [onChanges, items, helpers]);

    return (
        <ComparisonContext calculators={calculators}>
            <ComparisonContainer className={className}>
                <ComparisonFieldsContext>
                    {sharedFields => (
                        <>
                            <div>
                                <LabelsContainer>
                                    {sharedFields.map(([key, field]) => (
                                        <field.displayLabelComponent key={key} field={field} />
                                    ))}
                                </LabelsContainer>
                            </div>

                            {children(items, helpers)}
                        </>
                    )}
                </ComparisonFieldsContext>
            </ComparisonContainer>
        </ComparisonContext>
    );
};

CalculatorComparison.propTypes = {
    children: PropTypes.func.isRequired,
    className: PropTypes.string,
    fields: PropTypes.shape({}),
    initialCalculators: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    onChanges: PropTypes.func.isRequired,
    theme: PropTypes.shape({}),
};

export default memo(CalculatorComparison);
