import { TableList, Label } from '@appvantageasia/afc-ui';
import { format, parseISO } from 'date-fns';
import { flow, get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import withCompanyFormatting from '../../../utils/withCompanyFormatting';

const formatMonth = value => format(parseISO(value), 'MMM yyyy');
const formatPaidOn = value => format(parseISO(value), 'dd MMM yyyy');

const Container = styled.div`
    margin-top: 16px;
    grid-column: 1 / 3;
    color: black;

    table {
        margin-top: 5px;
        margin-bottom: 5px;
        table-layout: fixed;
        th:last-child {
            text-align: left; !important
        }
        td:last-child {
            text-align: left; !important
        }
    }
`;

const useColumns = formats => {
    const { formatCurrencyOnly } = formats;

    return useMemo(
        () => [
            {
                key: 'month',
                label: 'Month',
                getValue: flow([get('month'), formatMonth]),
            },
            {
                key: 'paidAt',
                label: 'Paid On',
                getValue: flow([get('paidAt'), formatPaidOn]),
            },
            {
                key: 'amount',
                label: 'Amount',
                getValue: item => (
                    <span style={{ whiteSpace: 'nowrap' }}>{formatCurrencyOnly(get('amount', item), 'SGD')}</span>
                ),
            },
            {
                key: 'employer',
                label: 'Employer',
                getValue: get('employer'),
            },
        ],
        [formatCurrencyOnly]
    );
};

const CPFContributions = ({ history, formats }) => {
    const columns = useColumns(formats);

    if (!history) {
        return null;
    }

    return (
        <Container>
            <Label>CPF Contributions</Label>
            <TableList columns={columns} items={history} />
        </Container>
    );
};

CPFContributions.propTypes = {
    formats: PropTypes.shape({}),
    history: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
};

export default withCompanyFormatting(CPFContributions);
