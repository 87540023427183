const bindSetValue = (run, context, values, boundFields, setValues, dirtyReference) => {
    const { onUpdates } = context;

    return run(
        'setValue',
        () => (field, value) => {
            const setValue = finalValue => {
                // set dirty reference to true
                // eslint-disable-next-line no-param-reassign
                dirtyReference.current = true;
                // update values
                setValues(state => ({ ...state, [field]: finalValue }));
            };

            if (onUpdates) {
                // call the hook to get the value
                const cleanedValue = onUpdates(field, value, values);

                if (cleanedValue instanceof Promise) {
                    // wait then update the value
                    cleanedValue.then(finalValue => setValue(finalValue));
                } else {
                    // just update the value
                    setValue(value);
                }
            } else {
                // just update the value
                setValue(value);
            }
        },
        [setValues, boundFields, onUpdates, values]
    );
};

export default bindSetValue;
