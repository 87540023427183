import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { memoize } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import * as UI from './ui/Pagination';

const getPages = (currentPage, maxPage) => {
    // put our current page first in the list
    const pages = [currentPage];

    do {
        // get the index for the next page
        const nextPage = currentPage + pages.length;

        if (nextPage > maxPage) {
            // we reached the latest page
            break;
        }

        // append it to our list
        pages.push(nextPage);
    } while (pages.length < 3);

    while (pages.length < 3) {
        // get the index for the previous page
        const previousPage = pages[0] - 1;

        if (previousPage <= 0) {
            // we reached the first page
            break;
        }

        // prepend it to our list
        pages.unshift(previousPage);
    }

    return pages;
};

const Paging = ({ page, pageSize, setPage, itemCount, pageCount }) => {
    const goTo = useMemo(() => {
        return memoize(index => () => setPage(index));
    }, [setPage]);

    if (!itemCount) {
        // there is no pagination to display
        return null;
    }

    // compute information
    const firstItem = (page - 1) * pageSize + 1;
    const lastNum = Math.min(firstItem + pageSize - 1, itemCount);

    return (
        <UI.Pagination>
            <UI.Text>
                Showing&nbsp;{firstItem}&nbsp;to&nbsp;{lastNum}&nbsp;of&nbsp;{itemCount}&nbsp;entries
            </UI.Text>
            {pageCount >= 2 && (
                <UI.Pages>
                    <UI.Page onClick={goTo(1)}>First</UI.Page>
                    <UI.Page onClick={goTo(Math.max(1, page - 1))}>
                        <Icon icon={faArrowLeft} />
                    </UI.Page>
                    {getPages(page, pageCount).map(pageIndex => (
                        <UI.Page key={pageIndex} active={page === pageIndex} onClick={goTo(pageIndex)}>
                            {pageIndex}
                        </UI.Page>
                    ))}
                    <UI.Page onClick={goTo(Math.min(pageCount, page + 1))}>
                        <Icon icon={faArrowRight} />
                    </UI.Page>
                    <UI.Page onClick={goTo(pageCount)}>Last</UI.Page>
                </UI.Pages>
            )}
        </UI.Pagination>
    );
};

Paging.propTypes = {
    itemCount: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
};

export default Paging;
