import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash/fp';
import React from 'react';
import { statusFalseColor, statusTrueColor } from '../../../containers/Layout';
import ApplicationStatus from './ApplicationStatus';
import Checkbox from './Checkbox';

export const renderActive = (key = 'isActive') => item => (
    <div style={{ textAlign: 'center' }}>
        <FontAwesomeIcon color={get(key, item) ? statusTrueColor.color : statusFalseColor.color} icon={faCircle} />
    </div>
);

export const renderDateTime = (key, formatDateTime) => item => {
    const value = get(key, item);

    return value && formatDateTime(value);
};

export const renderCurrencyDown = (key, formatCurrencyDown) => item => {
    const value = get(key, item);

    if (!value) {
        return null;
    }

    return <span style={{ whiteSpace: 'nowrap' }}>{formatCurrencyDown(value)}</span>;
};

export const renderStatus = () => ({ statusText: { label, color } }) => (
    <ApplicationStatus color={color}>{label}</ApplicationStatus>
);

export const renderStatusForCustomer = () => ({ latestApplication }) => {
    if (!latestApplication) {
        return '';
    }

    const {
        statusText: { label, color },
    } = latestApplication;

    return <ApplicationStatus color={color}>{label}</ApplicationStatus>;
};

const defaultGetId = item => item.id;

export const renderCheckbox = onCheckboxChange => (item, getId = defaultGetId) => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Checkbox checked={item.isSelected} onChange={() => onCheckboxChange(getId(item))} type="checkbox" />
    </div>
);

export const renderSource = getSourceLabel => item => {
    const channel = get('channel', item);
    const access = get('access', item);

    return getSourceLabel(channel, access);
};
