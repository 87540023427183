import dayjs, { Dayjs } from 'dayjs';
import { isFinite } from 'lodash/fp';

const getDepreciation = (
    price: number,
    parfRebateAmount: number,
    coeExpiryDate: Date | string,
    registrationDate: Date | string,
    today: Dayjs
) => {
    if (isFinite(price) && isFinite(parfRebateAmount) && coeExpiryDate && registrationDate) {
        const diff = dayjs(coeExpiryDate).diff(today, 'year', true);

        if (today.diff(dayjs(registrationDate), 'year', true) <= 10) {
            if (diff > 0) {
                const amount = (price - parfRebateAmount) / (diff < 1 ? 1 : diff);

                return amount > 0 ? amount : undefined;
            }

            return price - parfRebateAmount;
        }

        if (diff > 0) {
            const amount = price / (diff < 1 ? 1 : diff);

            return amount > 0 ? amount : undefined;
        }

        return price;
    }

    return undefined;
};

export default getDepreciation;
