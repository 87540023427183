import { createContext, useContext } from 'react';
import { InjectedFormProps } from 'redux-form';
import { InventoryUnitState } from '../../../../../schema';
import {
    GetInventoryQuery,
    InventorySetDataFragment,
    InventoryUnitDataFragment,
    ModelInventoryDataFragment,
    VariantInventoryDataFragment,
} from '../Edition.graphql';

export type LanguageOptions = {
    label: string;
    value: string;
};

type Units = {
    id: string;
    vin: string;
    location: string;
    state: InventoryUnitState | null | undefined;
};

export type FormValues = {
    inventory: GetInventoryQuery['inventory'];
    inventorySet: InventorySetDataFragment;
    options: InventorySetDataFragment['options'];
    variant: VariantInventoryDataFragment & { prices: { [zoneId: string]: number } };
    model: ModelInventoryDataFragment;
    subModel: ModelInventoryDataFragment | ModelInventoryDataFragment['parent'];
    lastModified: string;
    units: Units[];
    inventoryUnit: InventoryUnitDataFragment;
    no: number | null;
    title: string;
    identifier: string;
    mandatories: { [key: string]: boolean };
};

type FormContextData = InjectedFormProps & {
    disabled: boolean;
    languageOptions: LanguageOptions[];
    values: FormValues;
};

export const Context = createContext<FormContextData>(null!);

export const useFormContext = () => useContext(Context);
