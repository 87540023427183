import styled from 'styled-components';

const Card = styled.div`
    background: #ffffff;
    color: #666666;
    padding: 50px;
    margin-bottom: 20px;
`;

export default Card;
