import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import ConnectedLoadingLayer from '../common/ConnectedLoadingLayer';
import ContentErrorBoundary from '../errors/ContentErrorBoundary';
import { SystemContainer, Content, BodyContainer } from '../ui/SystemContainer';
import { GlobalError } from '../utilities/errors';
import Header from './Header';
import MenuLinks from './MenuLinks';
import Notification from './Notification';

const Wrapper = ({ children }) => {
    const [fixedMenu, setFixedMenu] = useState(false);

    const toggleMenu = useCallback(() => {
        setFixedMenu(!fixedMenu);
    }, [fixedMenu, setFixedMenu]);

    return (
        <>
            <MenuLinks fixedMenu={fixedMenu} toggleMenu={toggleMenu} />
            <SystemContainer>
                <Header toggleMenu={toggleMenu} />
                <BodyContainer fixedMenu={fixedMenu}>
                    <GlobalError />
                    <Notification />
                    <Content>
                        <ConnectedLoadingLayer />
                        <ContentErrorBoundary>{children}</ContentErrorBoundary>
                    </Content>
                </BodyContainer>
            </SystemContainer>
        </>
    );
};

Wrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Wrapper;
