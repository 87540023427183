import * as validators from '../../../../../utils/validators';

const validate = validators.compose(
    validators.requiredString('identifier'),
    validators.requiredString('name'),
    validators.requiredNumber('order'),
    validators.requiredBoolean('isActive')
);

export default validate;
