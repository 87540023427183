import { get, getOr } from 'lodash/fp';
import * as yup from 'yup';
import DualField from '../components/fields/display/DualField';
import DualSnapshotField from '../components/fields/display/DualSnapshotField';
import UpdateDual from '../components/fields/edition/UpdateDual';
import { getDefaultDualValues, getValueFromTable, weightFieldAfter } from '../utils';
import fieldTemplate from './fieldTemplate';
import raiseInvalidConfiguration from './raiseInvalidConfiguration';
import { BalloonBase, BalloonMode, FinanceProductSubType } from './schema';

export const getDefaultBalloonPayment = (values, context) => {
    // get the balloon config
    const config = get('selectedFinanceProduct.balloonSetting', context);
    const { totalPrice, loanAmount, paymentTerm } = values;

    if (config === undefined || config === null) {
        // cannot do anything yet
        return undefined;
    }

    const { basedOn, mode, default: defaultValue, defaultUnit } = config;

    if (mode === undefined || totalPrice === undefined || paymentTerm === undefined) {
        // we are still missing data to compute it
        return undefined;
    }

    let base;

    switch (basedOn) {
        case BalloonBase.CARPRICE:
            base = totalPrice;
            break;

        case BalloonBase.LOAN:
            base = loanAmount?.amount;
            break;

        default:
            base = 0;
            break;
    }

    let dualValues;

    switch (mode) {
        case BalloonMode.RANGE:
            dualValues = getDefaultDualValues(base, defaultValue, defaultUnit);
            break;
        case BalloonMode.TABLE:
            {
                const { table } = config;

                const selectedVariant = get('selectedVariant', context);
                const variantId = selectedVariant?.version?.id;

                if (!table || variantId === undefined) {
                    raiseInvalidConfiguration(context.t);
                }

                const value = getValueFromTable(
                    table,
                    [item => item.term >= paymentTerm, item => item.variantId === variantId],
                    'term'
                );

                dualValues = getDefaultDualValues(base, value, defaultUnit);
            }
            break;
        default:
            dualValues = null;
    }

    if (dualValues) {
        const { amount } = dualValues;
        const { formats } = context;

        // round the initial amount down
        return { ...dualValues, amount: formats.roundNumberDown(amount) };
    }

    return dualValues;
};

const balloonPaymentField = {
    // copy the template
    ...fieldTemplate,

    // setup label and order
    label: t => t('calculator.label.balloonPayment'),
    weight: weightFieldAfter('loanAmount'),

    // setup states
    isViewable: context =>
        (get('selectedFinanceProduct.subType', context) === FinanceProductSubType.HIREPURCHASEBALLOON ||
            get('selectedFinanceProduct.subType', context) === FinanceProductSubType.HALFHALF) &&
        getOr(false, 'selectedFinanceProduct.balloonSetting.show', context),
    isEditable: getOr(false, 'selectedFinanceProduct.balloonSetting.editable'),

    // setup display rendering
    displayComponent: DualField,
    // setup edition rendering
    editionComponent: UpdateDual,
    getEditionProperties: (context, { totalPrice }) => {
        const { selectedFinanceProduct, formats, t } = context;
        const { balloonSetting } = selectedFinanceProduct;
        const { min, max, step } = balloonSetting;

        const enablePercentage = true;

        const minErrorMessage = t('calculator.error.balloonPaymentMin', { min });
        const validation = yup
            .number()
            .typeError(minErrorMessage)
            .min(min, minErrorMessage)
            .max(max, t('calculator.error.balloonPaymentMax', { max }))
            .required(minErrorMessage);

        return {
            min,
            max,
            step,
            currency: formats?.currencySymbol,
            compute: {
                fromAmount: amount => formats.roundPercentageDown((amount * 100) / totalPrice),
                fromPercentage: percentage => formats.roundNumberDown((percentage / 100) * totalPrice),
            },
            enablePercentage,
            validation,
        };
    },

    // setup values management
    getInitialValue: getDefaultBalloonPayment,
    updates: {
        financeProduct: () => undefined,
        totalPrice: () => undefined,
        paymentTerm: () => undefined,
    },

    // snapshot configuration
    snapshot: {
        ...fieldTemplate.snapshot,
        component: DualSnapshotField,
    },
};

export default balloonPaymentField;
