import React, { useMemo, useState } from 'react';
import { Channel } from '../../../schema';
import { ChartContainer } from '../../ui/dashboard';
import ApplicationChart from './ApplicationChart';
import DepositPaymentsPerDay from './DepositPaymentsPerDay';
import Footer from './Footer';
import Header from './Header';
import LatestApplicationTable from './LatestApplicationTable';
import LeadChart from './LeadChart';
import PopularApplicationEventChart from './PopularApplicationEventChart';
import PopularApplicationVariantChart from './PopularApplicationVariantChart';
import PopularLeadEventChart from './PopularLeadEventChart';
import PopularLeadVariantChart from './PopularLeadVariantChart';
import Statistics from './Statistics';
import TopSalesManWithApplication from './TopSalesManWithApplication';
import TopSalesManWithLead from './TopSalesManWithLead';
import TopSalesManWithLoan from './TopSalesManWithLoan';
import { Context } from './context';

const Dashboard = () => {
    // Previously channel options were listed based on the user's permissions.
    // Now we are listing all the channels and the user can select the channel
    // he wants to see.
    // Because, in Reservations/Finance list, there is no filter for channel as well
    // and the user can see all the channels.
    // it just based on user is assigned through superior - downline - parallel combination
    const channelOptions = useMemo(() => {
        const options = [
            {
                value: Channel.NEW,
                label: 'New Car Channel',
                title: 'Dashboard for New Car Channel',
                channels: [Channel.NEW],
            },
            {
                value: Channel.USED,
                label: 'Used Car Channel',
                title: 'Dashboard for Used Car Channel',
                channels: [Channel.USED],
            },
            {
                value: Channel.EXPRESS,
                label: 'Pre-owned Channel',
                title: 'Dashboard for Pre-owned Channel',
                channels: [Channel.EXPRESS],
            },
            {
                value: Channel.EVENT,
                label: 'Events Channel',
                title: 'Dashboard for Events Channel',
                channels: [Channel.EVENT],
            },
        ];

        return [
            {
                value: 'all',
                label: 'All Channels',
                title: 'Dashboard for All Channels',
                channels: options.reduce((acc, curr) => acc.concat(...curr.channels), []),
            },
        ].concat(options);
    }, []);

    const [channels, setChannels] = useState(channelOptions[0].channels);
    const [dealerIds, setDealerIds] = useState(null);
    const hasEvents = useMemo(() => channels.includes(Channel.EVENT), [channels]);
    const context = useMemo(() => ({ channels, options: channelOptions, dealerIds }), [
        channelOptions,
        channels,
        dealerIds,
    ]);

    return (
        <Context.Provider value={context}>
            <ChartContainer>
                <Header dealerIds={dealerIds} setChannels={setChannels} setDealerIds={setDealerIds} />
                <div className="row">
                    <div className="col-lg-6">
                        <ApplicationChart />
                        <PopularApplicationVariantChart />
                    </div>
                    <div className="col-lg-6">
                        <Statistics />
                        <TopSalesManWithApplication />
                        <TopSalesManWithLoan />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <LatestApplicationTable />
                    </div>
                </div>
                {hasEvents && (
                    <div className="row">
                        <div className="col-lg-6">
                            <PopularApplicationEventChart />
                        </div>
                        <div className="col-lg-6">
                            <PopularLeadEventChart />
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-lg-6">
                        <LeadChart />
                    </div>
                    <div className="col-lg-6">
                        <PopularLeadVariantChart />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <TopSalesManWithLead />
                    </div>
                    <div className="col-lg-6">
                        <DepositPaymentsPerDay />
                    </div>
                </div>
            </ChartContainer>
            <Footer />
        </Context.Provider>
    );
};
export default Dashboard;
