import { getOr } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useComparisonFieldsContext } from './ComparisonFieldsContext';
import SingleCalculatorFields from './SingleCalculatorFields';

const CalculatorComparisonFields = ({ id, viewableFields, ...props }) => {
    // first we need the comparison field context
    const { register, unregister, fields } = useComparisonFieldsContext();

    // keep the context up to date
    useEffect(() => {
        // register viewable fields for this instance
        register(id, viewableFields);

        return () => {
            // unregister (very important)
            unregister(id);
        };
    }, [id, viewableFields, register, unregister]);

    // override fields
    const finalFields = useMemo(() => {
        // get back an object which make getter easier
        const calculatorFields = Object.fromEntries(viewableFields);

        return fields.map(([name, defaultField]) => {
            // get the field from the calculator
            const field = getOr(
                { ...defaultField, displayComponent: defaultField.displayEmptyComponent },
                name,
                calculatorFields
            );

            // keep it as an entry
            return [name, field];
        });
    }, [viewableFields, fields]);

    return <SingleCalculatorFields viewableFields={finalFields} {...props} />;
};

CalculatorComparisonFields.propTypes = {
    id: PropTypes.string.isRequired,
    viewableFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CalculatorComparisonFields;
