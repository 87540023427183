import styled from 'styled-components';

const Footer = {
    FootContainer: styled.div`
        background: white;
        padding: 0px 30px;
        position: fixed;
        bottom: 0px;
        right: 0px;
        left: 0px;
    `,
    FootBar: styled.div`
        background: transparent;
        border-top: 1px solid #dedede;
        height: auto;
        padding: 9.5px 0 19px 0;
        display: flex;
        flex-direction: column;

        button:nth-last-child(1) {
            margin-right: 0;
        }
    `,
    FootBarRow: styled.div`
        display: flex;
        flex-direction: row;
    `,
    FootBarButton: styled.button`
        height: 33px;
        font-size: 0.72rem;
        border: 1px solid #4f4e5c;
        margin: 0 4.75px;
        text-transform: uppercase;
        color: #000000;
        background: transparent;
        border-radius: 3px;
        padding: 0px 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        svg {
            margin-right: 5px;
        }
        a {
            color: #fff;
        }
        &:hover {
            opacity: 0.7;
        }
        &:focus {
            outline: none;
        }
        cursor: pointer;
        &.disabled {
            opacity: 0.7;
            cursor: not-allowed;
        }
        &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
        }
    `,
};

export default Footer;
