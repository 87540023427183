import { uniqBy, sortBy, flow, map } from 'lodash/fp';

const getAvailableBanks = (run, financeProducts) =>
    run(
        'availableBanks',
        () =>
            flow(
                map(product => product.bank),
                uniqBy('id'),
                sortBy('order')
            )(financeProducts),
        [financeProducts]
    );

export default getAvailableBanks;
