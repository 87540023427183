import { isEqual } from 'lodash';
import { orderBy, get, map, isNil, find } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { Field, fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import { ErrorMessageDiv } from '../../containers/Layout';
import BlockSelect from '../ui/form/BlockSelect';
import OutlineSelect from '../ui/form/OutlineSelect';
import Wrapper from '../ui/form/Wrapper';

export const SelectInput = ({
    label,
    placeholder,
    options,
    input,
    disabled = false,
    meta,
    multi = false,
    maxValueLength = null,
    noSort = false,
    onChangeEvent = null,
    selectComponent: Select = BlockSelect,
    ...selectProps
}) => {
    const { value, onChange: change, name } = input;
    const { active, touched, error = null } = meta;
    const hasError = !active && touched && !!error;

    // list to changes on the selection
    const onChange = useCallback(
        (selection, actionMeta) => {
            // get the next value
            const nextValue = multi ? map(get('value'), selection) : get('value', selection);

            if (Array.isArray(nextValue) && maxValueLength && nextValue.length > maxValueLength) {
                // skip it because we reach the limit
                return;
            }

            if (isEqual(nextValue, value)) {
                // no change, skip the update
                return;
            }

            if (multi) {
                const { action, removedValue } = actionMeta;
                if ((action === 'remove-value' || action === 'pop-value') && removedValue?.isFixed) {
                    return;
                }
            }

            // apply changes into the redux form
            change(nextValue, selection);

            if (onChangeEvent) {
                onChangeEvent(nextValue, selection);
            }
        },
        [change, multi, maxValueLength, onChangeEvent, value]
    );

    // get selection from values and options
    const currentSelection =
        useMemo(() => {
            if (isNil(value)) {
                return multi ? [] : null;
            }

            if (multi) {
                // get the list of selected options
                return map(optionValue => find(option => isEqual(option.value, optionValue), options), value);
            }

            // get the selected option
            return find(option => isEqual(option.value, value), options) || null;
        }, [value, options, multi]) || null;

    // sort options (optional behavior)
    const cleanedOptions = useMemo(() => {
        if (noSort) {
            return options;
        }

        return orderBy(['label'], ['asc'], options);
    }, [noSort, options]);

    return (
        <Wrapper label={label} name={name}>
            <Select
                isDisabled={disabled}
                isMulti={multi}
                onChange={onChange}
                options={cleanedOptions}
                placeholder={placeholder}
                value={currentSelection}
                {...selectProps}
            />
            {hasError && <ErrorMessageDiv>{error}</ErrorMessageDiv>}
        </Wrapper>
    );
};

SelectInput.propTypes = {
    disabled: PropTypes.bool,
    height: PropTypes.string,
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    label: PropTypes.string,
    maxHeight: PropTypes.string,
    maxValueLength: PropTypes.number,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
    minHeight: PropTypes.string,
    multi: PropTypes.bool,
    noSort: PropTypes.bool,
    onChangeEvent: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool, PropTypes.object])
                .isRequired,
        }).isRequired
    ).isRequired,
    placeholder: PropTypes.string,
    selectComponent: PropTypes.elementType,
};

const DropdownField = props => <Field {...props} component={SelectInput} />;

DropdownField.Outline = props => <DropdownField selectComponent={OutlineSelect} {...props} />;

export default DropdownField;
