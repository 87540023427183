import { createContext, useContext } from 'react';
import { OptionGroup } from '../../../../../schema';
import { LanguageOptions } from '../../inventoryUnit/Form/context';

type FormContextData = {
    disabled: boolean;
    languageOptions: LanguageOptions[];
    values: OptionGroup;
};

export const Context = createContext<FormContextData>(null!);

export const useFormContext = () => useContext(Context);
