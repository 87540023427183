import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCountryCode } from '../../../../../../selectors';
import DatePickerField from '../../../../../shared/form-v2/DatePickerField';
import NumberField from '../../../../../shared/form-v2/NumberField';
import { Disclaimer, Emphasis } from '../../../../../ui/calculator';

const PreOwnedDetails = () => {
    const today = useMemo(() => new Date(), []);
    const countryCode = useSelector(getCountryCode);

    // show disclaimer label only in SG
    const showDisclaimer = countryCode === 'SG';

    return (
        <div>
            <DatePickerField
                label="Original Registration Date"
                max={today}
                name="preOwnedCarDetails.originalRegistrationDate"
            />
            <DatePickerField label="COE Expiry Date" name="preOwnedCarDetails.coeExpiryDate" />
            <NumberField label="QP Paid" name="preOwnedCarDetails.qpPaid" type="currency" />
            <NumberField label="Open Market Value" name="preOwnedCarDetails.omv" type="currency" />
            <br />
            {showDisclaimer && (
                <Disclaimer>
                    <Trans
                        components={{
                            span: (
                                <Emphasis
                                    href="https://vrl.lta.gov.sg/lta/vrl/action/pubfunc?ID=EnquireRebateBeforeDeReg"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                />
                            ),
                        }}
                        i18nKey="preOwnedDetails.disclaimer"
                    />
                </Disclaimer>
            )}
        </div>
    );
};

export default PreOwnedDetails;
