import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { format, subMonths, getDate, getDaysInMonth } from 'date-fns';
import { get, isEmpty } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

const getSubmittedApplications = get('count');

const DayChartBlock = ({
    data,
    id,
    xAxisTitle,
    yAxisTitle,
    getAmount: getAmountProp,
    useCustomLabelTemplateAdapter = true,
}) => {
    const noData = isEmpty(get('currentMonth', data?.metrics)) && isEmpty(get('lastMonth', data?.metrics));

    const getAmount = useMemo(() => getAmountProp ?? getSubmittedApplications, [getAmountProp]);

    useEffect(() => {
        if (noData) {
            return;
        }

        const chart = am4core.create(id, am4charts.XYChart);

        // Get max days between previous month and current month
        const maxDaysInMonths = Math.max(getDaysInMonth(new Date()), getDaysInMonth(subMonths(new Date(), 1)));

        chart.data = Array.from(Array(maxDaysInMonths).keys()).map(item => {
            const { currentMonth, lastMonth } = data?.metrics || {};
            const dateNumber = item + 1;

            const findCurrent = currentMonth?.find(i => getDate(new Date(i.date)) === dateNumber);
            const findLast = lastMonth?.find(i => getDate(new Date(i.date)) === dateNumber);

            return {
                date: dateNumber.toString().padStart(2, '0'),
                current: getAmount(findCurrent) || 0,
                last: getAmount(findLast) || 0,
            };
        });

        // display swith title
        chart.legend = new am4charts.Legend();
        chart.legend.position = 'top';
        chart.legend.paddingBottom = 20;
        chart.legend.labels.template.maxWidth = 200;

        // Add scrollbar
        chart.scrollbarX = new am4core.Scrollbar();

        // define x axis
        const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        xAxis.dataFields.category = 'date';
        xAxis.title.text = xAxisTitle;
        xAxis.renderer.cellStartLocation = 0.1;
        xAxis.renderer.cellEndLocation = 0.9;
        xAxis.renderer.grid.template.location = 0;
        xAxis.renderer.minGridDistance = 50;

        // define y axis
        const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.title.text = yAxisTitle;
        if (useCustomLabelTemplateAdapter) {
            yAxis.renderer.labels.template.adapter.add('text', text =>
                // eslint-disable-next-line no-nested-ternary
                /^-?\d*\.\d+$/.test(parseFloat(text)) ? '' : text ? parseInt(text, 10) : text
            );
        }

        // filter different month
        const arrangeColumns = () => {
            const series = chart.series.getIndex(0);

            const w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
            if (series.dataItems.length > 1) {
                const x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX');
                const x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX');
                const delta = ((x1 - x0) / chart.series.length) * w;
                if (am4core.isNumber(delta)) {
                    const middle = chart.series.length / 2;

                    let newIndex = 0;
                    // eslint-disable-next-line no-shadow
                    chart.series.each(series => {
                        if (!series.isHidden && !series.isHiding) {
                            // eslint-disable-next-line no-param-reassign
                            series.dummyData = newIndex;
                            newIndex++;
                        } else {
                            // eslint-disable-next-line no-param-reassign
                            series.dummyData = chart.series.indexOf(series);
                        }
                    });
                    const visibleCount = newIndex;
                    const newMiddle = visibleCount / 2;

                    chart.series.each(item => {
                        const trueIndex = chart.series.indexOf(item);
                        const newAgainIndex = item.dummyData;

                        const dx = (newAgainIndex - trueIndex + middle - newMiddle) * delta;

                        item.animate(
                            { property: 'dx', to: dx },
                            series.interpolationDuration,
                            series.interpolationEasing
                        );
                        item.bulletsContainer.animate(
                            { property: 'dx', to: dx },
                            series.interpolationDuration,
                            series.interpolationEasing
                        );
                    });
                }
            }
        };

        // create series
        const createSeries = (value, name, color) => {
            const series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = value;
            series.dataFields.categoryX = 'date';
            series.name = name;
            series.columns.template.fill = color;
            series.columns.template.strokeOpacity = 0;

            series.events.on('hidden', arrangeColumns);
            series.events.on('shown', arrangeColumns);

            series.columns.template.tooltipText = '{categoryX} {name}: {valueY}';

            return series;
        };

        createSeries('current', `Current Month (${format(new Date(), 'MMM')})`, am4core.color('#67b7dc'));
        createSeries('last', `Last Month (${format(subMonths(new Date(), 1), 'MMM')})`, am4core.color('#a367dc'));
    }, [data, id, noData, xAxisTitle, yAxisTitle, getAmount, useCustomLabelTemplateAdapter]);

    return noData ? (
        <div className="m-1">There is currently no data for this month.</div>
    ) : (
        <div id={id} style={{ height: '400px' }} />
    );
};

DayChartBlock.propTypes = {
    data: PropTypes.shape({
        metrics: PropTypes.shape({}),
    }),
    getAmount: PropTypes.func,
    id: PropTypes.string.isRequired,
    useCustomLabelTemplateAdapter: PropTypes.bool,
    xAxisTitle: PropTypes.string,
    yAxisTitle: PropTypes.string,
};

export default DayChartBlock;
