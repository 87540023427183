import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import FieldContainer from '../../../../template/Field-container';
import NumberField from '../../../../template/Number';
import { useFormContext } from './context';

const InterestRateRange = () => {
    const { disabled, values, change } = useFormContext();

    const min = get('interestRateSetting.min', values);
    const max = get('interestRateSetting.max', values);

    useEffect(() => {
        if (min === max) {
            change('interestRateSetting.step', 0);
            change('interestRateSetting.default', min);
            change('interestRateSetting.editable', false);
        }
    }, [min, max, change]);

    return (
        <div className="row">
            <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="sub-container-fluid">
                    <FieldContainer label="Min*">
                        <Field
                            component={NumberField}
                            disabled={disabled}
                            min={0}
                            name="interestRateSetting.min"
                            precision={2}
                        />
                    </FieldContainer>
                    <span />
                    <FieldContainer label="Max*">
                        <Field
                            component={NumberField}
                            disabled={disabled}
                            min={0}
                            name="interestRateSetting.max"
                            precision={2}
                        />
                    </FieldContainer>
                    <span />
                    <FieldContainer label="Step*">
                        <Field
                            component={NumberField}
                            disabled={min === max || disabled}
                            min={0}
                            name="interestRateSetting.step"
                            precision={2}
                        />
                    </FieldContainer>
                </div>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12">
                <FieldContainer label="Default*">
                    <Field
                        component={NumberField}
                        disabled={min === max || disabled}
                        min={0}
                        name="interestRateSetting.default"
                        precision={2}
                    />
                </FieldContainer>
            </div>
        </div>
    );
};

export default InterestRateRange;
