import { useQuery } from '@apollo/client';
import { isNil } from 'lodash/fp';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCountry, getZoneId } from '../../../selectors';
import { getApplicationChart, GetApplicationChartQuery } from './ApplicationChart.graphql';
import DayChart from './DayChart';
import { useDashboardContext } from './context';

const title = 'Application Submitted';
const tooltip =
    // eslint-disable-next-line max-len
    'Total number of applications received by the Bank for the month. Received resubmission will replace the original Received submission. Applications that are cancelled will not be counted';
const xAxisTitle = 'Application Date';
const yAxisTitle = 'No. of Submitted Applications';

const ApplicationChart = () => {
    const { id: countryId } = useSelector(getCurrentCountry);
    const zoneId = useSelector(getZoneId);
    const { channels, dealerIds } = useDashboardContext();

    const { data } = useQuery<GetApplicationChartQuery>(getApplicationChart, {
        skip: isNil(dealerIds) || dealerIds.length < 1 || channels.length < 1,
        // somehow set it to `cache-and-network` resulting multiple request made
        // tried change it to `network-only` it giving same result
        // tried to memoized the variables, still no luck
        // tried with separated render, still no luck
        // tried with separated graphql usage, still no luck
        // `no-cache` is okay
        // thus `no-cache` that is
        fetchPolicy: 'no-cache',
        variables: {
            filter: { zoneId, countryId, dealerIds, channels },
            isLead: false,
        },
    });

    return (
        <DayChart
            data={data}
            id="application"
            title={title}
            tooltip={tooltip}
            xAxisTitle={xAxisTitle}
            yAxisTitle={yAxisTitle}
        />
    );
};

export default ApplicationChart;
