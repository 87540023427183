import { get, getOr } from 'lodash/fp';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentZone, getCurrentCountry, getRuntimeSettings } from '../../../../../../selectors';
import Portlet from '../../../../../template/Portlet';
import { useFormContext } from '../context';
import Event from './Event';
import Express from './Express';
import New from './New';
import Used from './Used';

const getHush = async (rawKey: string) => {
    try {
        const json = JSON.parse(rawKey);

        return `${json?.client_email}`;
    } catch (error) {
        return '';
    }
};

const Channels = () => {
    const { values, zones } = useFormContext();
    const edition = !!get('lastModified', values);

    const rounding = get('rounding', values);
    const currency = get('currency', values);
    const precision = getOr(0, 'rounding.amount.count', values);
    const countryId = get('id', values);

    // only sg display COE
    const countryCode = get('code', values);
    const isCoeEnabled = countryCode === 'SG';

    // only NZ display PPSR and Establishment
    const isPpsrAndEstablishmentEnabled = countryCode === 'NZ';

    // only TW use Luxury Tax
    const isLuxuryTaxEnabled = countryCode === 'TW';

    // get codes
    const currentCountry = useSelector(getCurrentCountry);
    const currentZone = useSelector(getCurrentZone);

    // use current zone code if same country. if not, use the first zone code
    const isSameCountry = currentCountry?.id === countryId;

    // use current zone code only if same country and currnet zone is not null
    const zoneCode = isSameCountry && currentZone ? currentZone.code : zones?.[0]?.code;

    const companyCode = get('company.code', values);

    const countryZoneCode = zoneCode && zoneCode !== countryCode ? `${countryCode}-${zoneCode}` : countryCode;

    // get CI base link
    const { ciBaseLink } = useSelector(getRuntimeSettings);

    // the country is being created
    if (!edition) {
        return null;
    }

    const commonProps = {
        ciBaseLink,
        companyCode,
        countryZoneCode,
        currency,
        rounding,
    };

    return (
        <Portlet name="Channel Settings" closable open>
            <New
                {...commonProps}
                getHush={getHush}
                isCoeEnabled={isCoeEnabled}
                isLuxuryTaxEnabled={isLuxuryTaxEnabled}
                isPpsrAndEstablishmentEnabled={isPpsrAndEstablishmentEnabled}
                precision={precision}
            />
            <Used {...commonProps} getHush={getHush} />
            <Express
                {...commonProps}
                isCoeEnabled={isCoeEnabled}
                isLuxuryTaxEnabled={isLuxuryTaxEnabled}
                isPpsrAndEstablishmentEnabled={isPpsrAndEstablishmentEnabled}
                precision={precision}
                rounding={rounding}
            />
            <Event {...commonProps} getHush={getHush} />
        </Portlet>
    );
};

export default Channels;
