import { useApolloClient } from '@apollo/client';
import { omit } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { submit } from 'redux-form';
import { addNotification } from '../../../actions';
import { useContentTranslation } from '../../../i18n';
import { ConsentOrDeclarationOwnerType } from '../../../schema';
import { getCurrentUserCountryDealers, getGlobalPermissions, getUser } from '../../../selectors';
import { CONSENT_DELETE_NOTIFICATION, CONSENT_SAVE_NOTIFICATION } from '../../../shared/constants/notification';
import { handleResponseError, prepareForGraphQL } from '../../../utilities/forms';
import useValidation from '../../../utils/useValidation';
import { useModal } from '../../Modal';
import FormLayout from '../../ui/form/FormLayout';
import { ConsentOrDeclarationDataFragment } from './Edition.graphql';
import Form from './Form';
import validator from './Form/validate';
import { create, update, remove } from './Page.graphql';

type PageProps = {
    id: string;
    initialValues: Partial<ConsentOrDeclarationDataFragment>;
};

const Page = ({ initialValues, id }: PageProps) => {
    // check permissions
    const { mayMangeConsentDeclaration } = useSelector(getGlobalPermissions);

    const history = useHistory();
    const dispatch = useDispatch();

    const submitForm = useCallback(() => dispatch(submit('consent')), [dispatch]);

    const client = useApolloClient();
    const { mapIntlValue } = useContentTranslation();
    const onSubmit = useCallback(
        async ({ id: consentId, ...data }) => {
            const query = consentId ? update : create;
            const variables = {
                data: prepareForGraphQL(
                    omit(['hasLegalMarkup'], {
                        ...data,
                        name: mapIntlValue(data.name),
                        description: mapIntlValue(data.description),
                        legalMarkup: mapIntlValue(data.legalMarkup),
                    })
                ),
                id: consentId,
            };

            if (consentId) {
                // we need to remove some more dta
                variables.data = omit(['version', 'lastModified'], variables.data);
            }

            return client.mutate({ mutation: query, variables }).catch(handleResponseError);
        },
        [client, mapIntlValue]
    );

    const returnUrl = useMemo(() => {
        if (!initialValues.owner) {
            // something is off
            return '/';
        }

        const { id: ownerId } = initialValues.owner;

        switch (initialValues.owner.type) {
            case ConsentOrDeclarationOwnerType.EVENT:
                return `/event/${ownerId}`;

            case ConsentOrDeclarationOwnerType.COUNTRY:
            case ConsentOrDeclarationOwnerType.ALLEVENTS:
            case ConsentOrDeclarationOwnerType.NEWCARCHANNEL:
            case ConsentOrDeclarationOwnerType.PREOWNEDCARCHANNEL:
            default:
                // we don't know from where we are coming from
                // or we are coming from consent
                return `/consent`;
        }
    }, [initialValues]);

    const onSubmitSuccess = useCallback(() => {
        dispatch(addNotification(CONSENT_SAVE_NOTIFICATION));
        history.push(returnUrl);
    }, [dispatch, history, returnUrl]);

    const modal = useModal();
    const onDelete = useCallback(() => {
        modal.confirm({
            title: '',
            content: 'Are you sure you want to delete this consent?',
            options: [
                { label: 'No', action: () => null },
                {
                    label: 'Yes',
                    action: () =>
                        client.mutate({ mutation: remove, variables: { id: initialValues?.id } }).then(() => {
                            dispatch(addNotification(CONSENT_DELETE_NOTIFICATION));
                            history.push(returnUrl);
                        }),
                },
            ],
        });
    }, [modal, client, initialValues, dispatch, history, returnUrl]);

    const onCancel = useCallback(() => history.goBack(), [history]);

    const userDealers = useSelector(getCurrentUserCountryDealers);
    const user = useSelector(getUser) || {};

    const mayDelete = useMemo(
        () =>
            user?.isSuperUser ||
            (mayMangeConsentDeclaration && initialValues?.dealerIds?.every(dealerId => userDealers.includes(dealerId))),
        [initialValues, mayMangeConsentDeclaration, userDealers, user]
    );
    const actions = useMemo(() => {
        if (!mayMangeConsentDeclaration) {
            return [];
        }

        return [
            mayMangeConsentDeclaration &&
                initialValues?.id && {
                    label: 'delete',
                    onAction: onDelete,
                    disabled: !mayDelete,
                },
            mayMangeConsentDeclaration && {
                label: 'save',
                onAction: submitForm,
            },
        ];
    }, [initialValues, mayDelete, mayMangeConsentDeclaration, onDelete, submitForm]);

    const validate = useValidation(validator);

    return (
        <FormLayout
            bodyComponent={
                initialValues && (
                    <Form
                        disabled={!mayMangeConsentDeclaration}
                        id={id}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onSubmitSuccess={onSubmitSuccess}
                        validate={validate}
                    />
                )
            }
            moreActions={actions}
            onCancel={onCancel}
            title="Consents & Declarations"
        />
    );
};

export default Page;
