import React from 'react';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../i18n';
import { yesNoOptions } from '../../../../shared/constants/options';
import useTimeZones from '../../../../utils/useTimeZones';
import DatePickerField from '../../../template/DatePicker';
import DropdownField from '../../../template/Dropdown';
import FieldContainer from '../../../template/Field-container';
import FileUpload from '../../../template/FileUpload';
import InputField from '../../../template/Input';
import SwitchField from '../../../template/Switch';
import TextareaField from '../../../template/Textarea';
import TimeDuration from '../../../template/TimeDuration';
import TimePickerField from '../../../template/TimePicker';
import { useFormContext } from './context';

const MainDetails = () => {
    const { disabled = false, change } = useFormContext();
    const { formatPath } = useContentTranslation();
    const timezones = useTimeZones();

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Start">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1 }}>
                                    <Field component={DatePickerField} disabled={disabled} name="start" />
                                </div>
                                <div style={{ width: '10px' }} />
                                <div style={{ flex: 1 }}>
                                    <Field component={TimePickerField} disabled={disabled} name="start" />
                                </div>
                                <div style={{ width: '10px' }} />
                                <div style={{ flex: 1 }}>
                                    <DropdownField
                                        disabled={disabled}
                                        name="startTimeZone"
                                        onChangeEvent={(value: string) => change('endTimeZone', value)}
                                        options={timezones}
                                    />
                                </div>
                            </div>
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="End">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 1 }}>
                                    <Field component={DatePickerField} disabled={disabled} name="end" />
                                </div>
                                <div style={{ width: '10px' }} />
                                <div style={{ flex: 1 }}>
                                    <Field component={TimePickerField} disabled={disabled} name="end" />
                                </div>
                                <div style={{ width: '10px' }} />
                                <div style={{ flex: 1 }}>
                                    <DropdownField
                                        disabled={disabled}
                                        name="endTimeZone"
                                        onChangeEvent={(value: string) => change('startTimeZone', value)}
                                        options={timezones}
                                    />
                                </div>
                            </div>
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Warning Before">
                            <Field component={TimeDuration} disabled={disabled} name="warningBefore" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Maintenance Page Title">
                            <Field component={InputField} disabled={disabled} name={formatPath('title')} />
                        </FieldContainer>
                        <FieldContainer label="Active">
                            <Field component={SwitchField} disabled={disabled} name="isActive" options={yesNoOptions} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Maintenance Page Content">
                            <Field component={TextareaField} disabled={disabled} name={formatPath('description')} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Image">
                            <Field
                                accept=".jpg,.png,.jpeg,.svg"
                                component={FileUpload}
                                disabled={disabled}
                                label="Size 1000x500px in jpg, png or svg"
                                name="image"
                            />
                        </FieldContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainDetails;
