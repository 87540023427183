import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const Grid = styled.div`
    display: grid;
    grid-template-columns: ${props => `repeat(${props.size}, 1fr)`};
    grid-gap: 5px;

    @media (max-width: 320px) {
        grid-template-columns: ${props => `repeat(${props.smallSize}, 1fr)`};
    }
`;

const Option = styled.button`
    border: 1px solid #ced4da;
    outline: none;
    font-size: 1.35rem;
    line-height: 15pt;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #000;
    background-color: #fff;
    cursor: pointer;

    ${props =>
        props.active &&
        css`
            color: #fff;
            background-color: #000;
        `}
`;

const OptionGrid = ({ options, onChange, value, size = 2, smallSize = 1 }) => (
    <Grid size={size} smallSize={smallSize}>
        {options.map(option => (
            <Option key={option.value} active={value === option.value} onClick={() => onChange(option.value)}>
                {option.label}
            </Option>
        ))}
    </Grid>
);

OptionGrid.propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        })
    ).isRequired,
    size: PropTypes.number,
    smallSize: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default OptionGrid;
