import { useQuery } from '@apollo/client';
import { omit } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { getLanguages } from '../../../../i18n';
import { ChatbotType } from '../../../../schema';
import { getLastModified } from '../../../../utilities/forms';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { getData } from './Edition.graphql';
import Page from './Page';

const getInitialChatbotCredentials = chatbot => {
    switch (chatbot?.type) {
        case ChatbotType.DIALOGFLOW:
            return { dialogflowCredentials: chatbot.credentials };

        case ChatbotType.HERO:
            return { heroCredentials: chatbot.credentials };

        case ChatbotType.USERLIKE:
            return { userlikeCredentials: chatbot.credentials };

        default:
            return undefined;
    }
};

const Edition = () => {
    const { id } = useParams();
    const variables = { id };
    const { data, loading } = useQuery(getData, { variables, fetchPolicy: 'network-only' });
    const formatDateTime = useFormatDateTime();

    const initialValues = useMemo(() => {
        const values = data?.country;

        if (!values) {
            return null;
        }

        return {
            ...omit(['zones', 'banks'], values),
            mlcName: getLanguages(values?.languages || []).reduce(
                (acc, languagesCode) => ({
                    ...acc,
                    [languagesCode]: values.mlcName?.[languagesCode] || values.name,
                }),
                {}
            ),
            lastModified: getLastModified(values.version, formatDateTime),
            channelSetting: {
                ...values.channelSetting,
                new: {
                    ...values.channelSetting?.new,
                    chatbot: {
                        type: ChatbotType.DIALOGFLOW,
                        dialogflowCredentials: values.channelSetting?.new?.chatbot?.credentials,
                    },
                    isFinanceApplicationOptional: !values.channelSetting?.new?.isFinanceApplicationMandatory,
                },
                used: {
                    ...values.channelSetting?.used,
                    chatbot: {
                        type: ChatbotType.DIALOGFLOW,
                        dialogflowCredentials: values.channelSetting?.used?.chatbot?.credentials,
                    },
                    isFinanceApplicationOptional: !values.channelSetting?.used?.isFinanceApplicationMandatory,
                },
                express: {
                    ...values.channelSetting?.express,
                    isFinanceApplicationOptional: !values.channelSetting?.express?.isFinanceApplicationMandatory,
                },
                event: {
                    ...values.channelSetting?.event,
                    chatbot: {
                        type: values.channelSetting?.event?.chatbot?.type,
                        ...getInitialChatbotCredentials(values.channelSetting?.event?.chatbot),
                    },
                },
            },
        };
    }, [data, formatDateTime]);
    if (!loading && !initialValues) {
        // the product does not exist
        return <Redirect to="/scope/countries" />;
    }

    return (
        <Page
            banks={data?.country?.banks}
            events={data?.events?.items}
            initialValues={initialValues}
            insurances={data?.country?.insurances}
            settings={data?.settings}
            zones={data?.country?.zones}
        />
    );
};

export default Edition;
