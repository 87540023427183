import { useQuery } from '@apollo/client';
import { get, isNil, orderBy } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ConsentOrDeclaration } from '../../../schema';
import { getCurrentCountry } from '../../../selectors';
import { searchOnKeys } from '../../../utilities/fp';
import { List, cells, ListSearch, DealerSelectionDropDown } from '../../ui/lists';
import usePaging from '../../utilities/usePaging';
import ConsentListContainer from './ConsentContainer';
import { getData, GetDataQuery } from './ConsentList.graphql';
import ConsentListFooter from './ConsentListFooter';

const useColumns = () => {
    return useMemo(
        () => [
            {
                id: 'name',
                name: 'Name / Description',
                renderCell: (item: ConsentOrDeclaration) => get('name.en', item) || get('description.en', item),
                underline: true,
                hasSort: true,
            },
            {
                id: 'owner.name',
                name: 'Owner',
                renderCell: get('owner.name'),
                hasSort: true,
            },
            {
                id: 'hasCheckbox',
                name: 'Checkbox',
                renderCell: cells.renderActive('hasCheckbox'),
                textAlignCenter: true,
                hasSort: true,
            },
            {
                id: 'isMandatory',
                name: 'Mandatory',
                renderCell: cells.renderActive('isMandatory'),
                textAlignCenter: true,
                hasSort: true,
            },
            { name: 'Order No.', id: 'order', renderCell: get('order'), hasSort: true },
            {
                id: 'isActive',
                name: 'Active',
                renderCell: cells.renderActive(),
                textAlignCenter: true,
                hasSort: true,
            },
        ],
        []
    );
};

const searchFields = ['name.en', 'owner.name'];

type SortKey = 'asc' | 'desc';

const sortItems = <T,>(items: T[], [sortKey, sortOrder]: [string, SortKey]): T[] => {
    // we always sort by value key
    return orderBy(sortKey, sortOrder, items);
};

const ConsentList = () => {
    // get current context
    const country = useSelector(getCurrentCountry);
    const [dealerIds, setDealerIds] = useState<string[]>(null!);

    // prepare history
    const history = useHistory();

    const variables = { id: country.id, dealerIds };
    const { data, loading, error } = useQuery<GetDataQuery>(getData, {
        fetchPolicy: 'cache-and-network',
        variables,
        skip: isNil(dealerIds),
    });
    const items = data?.results || [];
    const isLoading = loading && items.length <= 0;

    // searching
    const [search, setSearch] = useState('');
    const searchMethod = useMemo(() => searchOnKeys(searchFields, items), [items]);
    const matchedItems = useMemo(() => searchMethod(search), [search, searchMethod]);

    // sorting
    const [sortedOn, onSort] = useState<[string, SortKey]>(['name.en', 'asc']);
    const sortedItems = useMemo(() => sortItems(matchedItems, sortedOn), [matchedItems, sortedOn]);

    // paging
    // @ts-ignore
    const [pagedItems, paging] = usePaging(sortedItems, { search });

    const columns = useColumns();

    return (
        <ConsentListContainer>
            <List
                columns={columns}
                // @ts-ignore
                error={!!error}
                footer={<ConsentListFooter />}
                headerBottomComponent={<DealerSelectionDropDown dealerIds={dealerIds} onValueChanged={setDealerIds} />}
                headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
                items={pagedItems as any[]}
                loading={isLoading}
                onItemClick={item => history.push(`/consent/${item.id}`)}
                onSort={onSort}
                paging={paging}
                sortedOn={sortedOn}
                title="Consents & Declarations"
            />
        </ConsentListContainer>
    );
};

export default ConsentList;
