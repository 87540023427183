// eslint-disable-next-line import/no-extraneous-dependencies
import { Decimal } from 'decimal.js';
import { get, getOr } from 'lodash/fp';
import * as yup from 'yup';
import DualField from '../components/fields/display/DualField';
import DualSnapshotField from '../components/fields/display/DualSnapshotField';
import UpdateDual from '../components/fields/edition/UpdateDual';
import { getDefaultValueByMinAndMax } from '../context/computeChanges';
import { getDefaultDualValues, getValueFromTable, weightFieldAfter } from '../utils';
import fieldTemplate from './fieldTemplate';
import raiseInvalidConfiguration from './raiseInvalidConfiguration';
import { DownpaymentMode, FinanceProductBase, Unit } from './schema';

export const getAccurate = value => (100 * 1000 - value * 1000) / 1000;

export const getRangeByTotalPrice = (config, totalPrice) => {
    const { min, max } = config;

    return {
        ...config,
        min: min > totalPrice ? totalPrice : min,
        max: max > totalPrice ? totalPrice : max,
    };
};

export const getRangeByBasedOn = (context, config, otherConfig, basedOn, totalPrice) => {
    const { selectedFinanceProduct } = context;
    const { basedOn: financeProductBasedOn } = selectedFinanceProduct;
    const { min, max, defaultUnit, step } = getRangeByTotalPrice(config, totalPrice);
    const { min: otherMin, max: otherMax, step: otherStep } = getRangeByTotalPrice(otherConfig, totalPrice);

    const isBasedOnAndCurrency = basedOn === financeProductBasedOn && defaultUnit === Unit.CURRENCY;
    const minAmount = isBasedOnAndCurrency ? totalPrice - otherMax : min;
    const maxAmount = isBasedOnAndCurrency ? totalPrice - otherMin : max;

    return {
        min: getDefaultValueByMinAndMax(minAmount, 0, totalPrice),
        max: getDefaultValueByMinAndMax(maxAmount, 0, totalPrice),
        step: isBasedOnAndCurrency ? otherStep : step,
    };
};

export const getDownPaymentBasedOnLoan = (context, totalPrice) => {
    const basedOn = get('selectedFinanceProduct.basedOn', context);
    const { downPaymentSetting, loanSetting } = get('selectedFinanceProduct', context);
    const { default: downPaymentDefault, defaultUnit: unit } = downPaymentSetting;
    const { default: loanDefault } = loanSetting;

    if (unit === Unit.CURRENCY && basedOn === FinanceProductBase.LOAN) {
        return totalPrice - getDefaultValueByMinAndMax(loanDefault, 0, totalPrice);
    }

    return getDefaultValueByMinAndMax(downPaymentDefault, 0, totalPrice);
};

const getDefaultDownPayment = (values, context, initialValue = null) => {
    // get interest rate config
    const config = get('selectedFinanceProduct.downPaymentSetting', context);

    if (config === undefined) {
        // we do not have enough data for it
        return undefined;
    }
    const { mode, table, defaultUnit } = config;
    const { paymentTerm, totalPrice } = values;
    const defaultDownPaymentValue = getDownPaymentBasedOnLoan(context, totalPrice);

    if ((mode === DownpaymentMode.TABLE && paymentTerm === undefined) || totalPrice === undefined) {
        // we are still missing data to compute it
        return undefined;
    }

    switch (mode) {
        case DownpaymentMode.RANGE:
            return getDefaultDualValues(totalPrice, defaultDownPaymentValue, defaultUnit);

        case DownpaymentMode.TABLE: {
            if (!table) {
                raiseInvalidConfiguration(context.t);
            }

            // look into the table
            // if there not enough data we can safely return 0 instead
            const tableValue = getValueFromTable(table, [item => item.term >= paymentTerm], 'term');

            return getDefaultDualValues(totalPrice, tableValue, defaultUnit);
        }

        default:
            // unknown base
            return initialValue;
    }
};

const downPaymentField = {
    // copy template
    ...fieldTemplate,

    // setup meta
    label: t => t('calculator.label.downPayment'),
    weight: weightFieldAfter('interestRate'),

    // setup states
    isViewable: getOr(false, 'selectedFinanceProduct.downPaymentSetting.show'),
    isEditable: context => {
        const config = get('selectedFinanceProduct.downPaymentSetting', context);
        const basedOn = get('selectedFinanceProduct.basedOn', context);
        const min = get('min', config);
        const max = get('max', config);
        const unit = get('defaultUnit', config);
        const editable = get('editable', config);

        return basedOn === FinanceProductBase.LOAN && unit === Unit.CURRENCY ? editable : min !== max && editable;
    },

    // setup display rendering
    displayComponent: DualField,

    // setup edition rendering
    editionComponent: UpdateDual,
    getEditionProperties: (context, { totalPrice }) => {
        const { selectedFinanceProduct, formats, t } = context;
        const { downPaymentSetting, loanSetting } = selectedFinanceProduct;
        const { defaultUnit } = downPaymentSetting;
        const unit = defaultUnit === Unit.PERCENTAGE ? '%' : ` ${formats?.currencySymbol}`;

        const enablePercentage = true;

        const { min, max, step } = getRangeByBasedOn(
            context,
            downPaymentSetting,
            loanSetting,
            FinanceProductBase.LOAN,
            totalPrice
        );

        const minErrorMessage = t('calculator.error.downPaymentMin', { min, unit });
        const validation = yup
            .number()
            .typeError(minErrorMessage)
            .min(min, minErrorMessage)
            .max(max, t('calculator.error.downPaymentMax', { max, unit }))
            .required(minErrorMessage);

        return {
            min,
            max,
            step,
            unit: defaultUnit,
            currency: formats?.currencySymbol,
            compute: {
                fromAmount: amount => formats.roundPercentageDown((amount * 100) / totalPrice),
                fromPercentage: percentage => formats.roundNumber((percentage / 100) * totalPrice),
            },
            enablePercentage,
            validation,
        };
    },

    // setup values management
    getInitialValue: (values, context) => {
        const { formats } = context;
        const value = getDefaultDownPayment(values, context);

        return value && { ...value, amount: formats.roundNumber(value.amount) };
    },
    updates: {
        // reset when the finance product changed
        financeProduct: () => undefined,

        // we do not need care total price change to change downpayment
        // because we always calculate from load amount
        // then trigger downpayment change from loan amount
        totalPrice: () => undefined,

        // compute amount to match loan amount
        loanAmount: ({ totalPrice, loanAmount }) => {
            if (loanAmount === undefined || totalPrice === undefined) {
                // not enough data yet
                return undefined;
            }

            // if loan amount already confirmed,
            // we do not need round again for downpayment
            // so we just use minus to get confirmed value
            return {
                percentage: new Decimal(100 - loanAmount.percentage).toNumber(),
                amount: new Decimal(totalPrice - loanAmount.amount).toNumber(),
            };
        },
    },

    // snapshot configuration
    snapshot: {
        ...fieldTemplate.snapshot,
        component: DualSnapshotField,
    },
};

export default downPaymentField;
