import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { CalculationMode, DerivedMethod, FinanceProductSubType, FinanceProductType } from '../../../../../schema';
import {
    calculationModeOptions,
    derivedMethodOptions,
    paymentModeOptions,
    yesNoOptions,
} from '../../../../../shared/constants/options';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import NumberField from '../../../../template/Number';
import SwitchField from '../../../../template/Switch';
import { useFormContext } from './context';

const Calculation = () => {
    const { disabled, values, change } = useFormContext();

    const showPaymentMode = get('paymentMode.show', values);
    const derivedMethod = get('paymentMode.derivedMethod', values);

    const productType = get('type', values);
    const productSubType = get('subType', values);
    const isUccl = productSubType === FinanceProductSubType.UCCLLEASING;

    useEffect(() => {
        if (!showPaymentMode) {
            // cannot be editable when show if off
            change('paymentMode.editable', false);
        }
    }, [showPaymentMode, change]);

    useEffect(() => {
        if (
            productType === FinanceProductType.FINANCE ||
            (productType === FinanceProductType.LEASING && productSubType === FinanceProductSubType.UCCLLEASING)
        ) {
            // change MPDM to formula if product type is finance
            change('paymentMode.derivedMethod', DerivedMethod.FORMULA);
        }

        if (productType === FinanceProductType.LEASING && productSubType === FinanceProductSubType.UCCLLEASING) {
            change('paymentMode.calculationMode', CalculationMode.FLAT);
        }
    }, [change, productType, productSubType]);

    useEffect(() => {
        if (productSubType !== FinanceProductSubType.HIREPURCHASEBALLOONGFV) {
            // change to percentage undefined
            change('paymentMode.reducedPercentage', undefined);
        }
    }, [change, productSubType]);

    const paymentMethodDisabled =
        disabled ||
        productType ||
        productSubType === FinanceProductSubType.LEASEPURCHASE ||
        productSubType === FinanceProductSubType.LEASEPURCHASEICC;

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField
                            disabled={paymentMethodDisabled}
                            label="Monthly Payment Derived Method*"
                            name="paymentMode.derivedMethod"
                            options={derivedMethodOptions}
                            placeholder="Select Monthly Payment Derived Method"
                        />
                    </div>
                    {derivedMethod === DerivedMethod.FORMULA && (
                        <>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <DropdownField
                                    disabled={disabled}
                                    label="Mode of Payment*"
                                    name="paymentMode.mode"
                                    options={paymentModeOptions}
                                    placeholder="Select Mode of Payment"
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Show">
                                    <Field
                                        component={SwitchField}
                                        disabled={disabled}
                                        name="paymentMode.show"
                                        options={yesNoOptions}
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Editable">
                                    <Field
                                        component={SwitchField}
                                        disabled={!showPaymentMode || disabled}
                                        name="paymentMode.editable"
                                        options={yesNoOptions}
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <DropdownField
                                    disabled={disabled || isUccl}
                                    label="Calculation Mode*"
                                    name="paymentMode.calculationMode"
                                    options={calculationModeOptions}
                                    placeholder="Select Calculation Mode"
                                />
                            </div>
                        </>
                    )}
                    {productSubType === FinanceProductSubType.HIREPURCHASEBALLOONGFV && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Reduced Monthly Payment - Reduced %">
                                <Field
                                    component={NumberField}
                                    disabled={disabled}
                                    max={100}
                                    min={0}
                                    name="paymentMode.reducedPercentage"
                                />
                            </FieldContainer>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Calculation;
