import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const DatePickerFieldDiv = styled.div`
    width: 100%;
    padding: 9px 0;

    .react-datepicker-wrapper,
    .react-datepicker__input-container {
        width: 100%;
    }

    input {
        width: 100%;
        border: 0;
        color: ${props => props.theme.themeHighlightColour};
        background: #ffffff;
        height: 18px;
        font-size: 14px;
        padding-left: 10px;
        text-align: end;
    }

    input[type='text']:disabled {
        color: #000000;
    }
`;

const DatePickerField = ({ children, ...props }) => (
    <DatePickerFieldDiv>
        {/* This is the input component containing the logic for parsing the Date */}
        {React.cloneElement(children, props)}
    </DatePickerFieldDiv>
);

DatePickerField.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DatePickerField;
