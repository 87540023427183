import { Modal } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState, useMemo } from 'react';
import Error from './ui/Error';

const Edition = ({ className, calculatorContext, editionContext, field, values, setFieldOnEdition }) => {
    if (field === null) {
        return null;
    }

    // get the component for edition
    const { fields, setValue, setInitChangeSource } = calculatorContext;
    const { editionComponent: Component, getEditionProperties, label, editionLabel = label } = fields[field];

    if (!Component) {
        // something is off
        throw new Error(`edition component cannot be undefined for ${field} field.`);
    }

    const initialValue = values[field];

    // reference in which the value can be assign
    // or either a function to retrieve it
    const valueRef = useRef(initialValue);

    // error management
    const [error, setError] = useState(null);
    const disabledValidation = !!error;

    // callback to close the component
    const onClose = useCallback(() => setFieldOnEdition(null), [setFieldOnEdition]);

    // callback to apply changes
    const onValidation = useCallback(
        event => {
            event.preventDefault();

            if (disabledValidation) {
                return false;
            }

            // first get the value
            const value = valueRef.current instanceof Function ? valueRef.current() : valueRef.current;

            // close the modal
            setFieldOnEdition(null);

            // update the field value
            setValue(field, value);
            setInitChangeSource(field);

            return false;
        },
        [field, valueRef, setFieldOnEdition, setValue, disabledValidation, setInitChangeSource]
    );

    // get edition properties
    const props = useMemo(() => {
        return getEditionProperties(calculatorContext, values);
    }, [calculatorContext, getEditionProperties, values]);

    // get the label for the modal
    const cleanedLabel =
        editionLabel instanceof Function ? editionLabel(editionContext, calculatorContext) : editionLabel;

    return (
        <Modal
            className={className}
            disabledConfirm={disabledValidation}
            onClose={onClose}
            onConfirm={onValidation}
            title={cleanedLabel}
            showClose
            showConfirm
            showTitle
        >
            <form onSubmit={onValidation}>
                <Component
                    {...editionContext}
                    {...props}
                    initialValue={initialValue}
                    name={field}
                    setError={setError}
                    valueRef={valueRef}
                />
                {/* PO do not want modal extend when error message display
                    so we need min height for error message */}
                <Error>{error}</Error>
                <input style={{ display: 'none' }} type="submit" />
            </form>
        </Modal>
    );
};

Edition.propTypes = {
    calculatorContext: PropTypes.shape({
        fields: PropTypes.shape({}).isRequired,
        onUpdates: PropTypes.func,
        setInitChangeSource: PropTypes.func.isRequired,
        setValue: PropTypes.func.isRequired,
    }).isRequired,
    className: PropTypes.string,
    editionContext: PropTypes.shape({}),
    field: PropTypes.string,
    setFieldOnEdition: PropTypes.func.isRequired,
    values: PropTypes.shape({}).isRequired,
};

// use memo to reduce updates
export default Edition;
