import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Select from 'react-select';
import { useTheme } from 'styled-components';

const OutlineSelect = ({ isTextCenter, ...props }) => {
    const theme = useTheme();
    const customStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                cursor: 'pointer',
                outline: 'none',
                boxShadow: 'none',
                borderColor: state.selectProps.hasError ? '#c10034 !important' : '#ced4da !important',
            }),
            menu: provided => ({
                ...provided,
                top: '41px',
                left: '0',
                right: '0',
                marginTop: 0,
                marginBottom: 0,
                width: 'auto',
                border: '1px solid #ced4da',
                borderRadius: '3px',
                boxShadow: 'none',
            }),
            menuList: provided => ({
                ...provided,
                padding: 0,
            }),
            option: (provided, { isSelected }) => ({
                ...provided,
                cursor: 'pointer',
                minHeight: '38px',
                lineHeight: '1',
                display: 'flex',
                alignItems: 'center',
                padding: '0 15px',
                backgroundColor: 'transparent',
                color: isSelected ? theme?.themeHighlightColour : '#000000',
                ':hover': {
                    ...provided[':hover'],
                    backgroundColor: theme?.themeHighlightColour,
                    color: '#FFFFFF',
                },
                ':active': {
                    ...provided[':active'],
                    backgroundColor: theme?.themeHighlightColour,
                    color: '#000000',
                },
            }),
            dropdownIndicator: provided => ({
                ...provided,
                padding: '0 10px 0 0',
                color: '#00000080',
                ':hover': {
                    ...provided[':hover'],
                    color: '#00000080',
                },
            }),
            indicatorSeparator: () => ({ display: 'none' }),
            input: provided => ({ ...provided, margin: 0 }),
            valueContainer: provided => ({
                ...provided,
                padding: '0 15px',
                justifyContent: isTextCenter ? 'center' : 'flex-start',
            }),
            singleValue: provided => ({
                ...provided,
                marginLeft: 0,
                marginRight: 0,
                color: '#000000',
                top: 'unset',
                transform: 'none',
            }),
        }),
        [theme, isTextCenter]
    );

    return (
        <Select
            placeholder=""
            {...props}
            menuPlacement="auto"
            noOptionsMessage={() => 'No data available'}
            styles={customStyles}
        />
    );
};

OutlineSelect.propTypes = {
    isTextCenter: PropTypes.bool,
};

export default OutlineSelect;
