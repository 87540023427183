import { OutlineCenterInput } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';

const UpdatePromoCode = ({ initialValue, valueRef }) => {
    // create a state to update the value
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        // update the value on the reference
        // eslint-disable-next-line no-param-reassign, react/prop-types
        valueRef.current = value;
    }, [value, valueRef]);

    const reset = useCallback(() => setValue(''), [setValue]);

    return <OutlineCenterInput onChange={setValue} reset={reset} value={value} autoFocus />;
};

UpdatePromoCode.propTypes = {
    initialValue: PropTypes.number,
    valueRef: PropTypes.shape({}).isRequired,
};

export default UpdatePromoCode;
