import { useApolloClient, useQuery } from '@apollo/client';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getFormValues } from 'redux-form';
import { addNotification } from '../../../../actions';
import { AppointmentStatus } from '../../../../schema';
import { getGlobalPermissions, getRuntimeSettings } from '../../../../selectors';
import { APPOINTMENT_SAVE_NOTIFICATION } from '../../../../shared/constants/notification';
import { handleResponseError } from '../../../../utilities/forms';
import applySourceChange from '../../../../utils/applySourceChange';
import ApplicationForm from '../../Workflow/Finance/application/ApplicationForm';
import { updateCustomer } from '../../Workflow/common/shared/customer.graphql';
import { mapUpdateCustomerStateToPayload } from '../../Workflow/common/utilities/map';
import useFormChanged from '../../Workflow/common/utilities/useFormChanged';
import {
    updateStatusAppointment,
    UpdateStatusAppointmentMutation,
    UpdateStatusAppointmentMutationVariables,
    rearrangeDateAppointment,
    RearrangeDateAppointmentMutation,
    RearrangeDateAppointmentMutationVariables,
    GetReservedAppointmentDatesQuery,
    GetReservedAppointmentDatesQueryVariables,
    getReservedAppointmentDates,
} from './Edition.graphql';
import AppointmentForm from './Form';
import { AppointmentFormValues } from './Form/context';

export type AppointmentPageProps = {
    initialValues?: AppointmentFormValues | null;
};

const AppointmentPage = ({ initialValues }: AppointmentPageProps) => {
    const history = useHistory();

    const dispatch = useDispatch();
    const client = useApolloClient();

    const { data, loading } = useQuery<GetReservedAppointmentDatesQuery, GetReservedAppointmentDatesQueryVariables>(
        getReservedAppointmentDates,
        {
            fetchPolicy: 'network-only',
            variables: {
                filter: {
                    zoneId: initialValues?.latestApplication.zoneId,
                    dealerIds: [initialValues?.latestApplication.dealerId],
                    exceptId: initialValues?.id,
                },
            },
            skip:
                !initialValues ||
                !initialValues?.latestApplication.zoneId ||
                !initialValues?.latestApplication.dealerId,
        }
    );

    // Runtime setting
    const { useCustomerBirthDay, useCustomerNationality } = useSelector(getRuntimeSettings);

    // Get global permissions
    const { mayManageCustomers, mayManageAppointments } = useSelector(getGlobalPermissions);

    // Handle customer redirection
    const { state } = useLocation<{ customerId: string }>();
    const redirect = useCallback(() => {
        if (state?.customerId) {
            history.push(`/customer/${state?.customerId}`);

            return;
        }

        history.push('/appointment');
    }, [history, state]);

    // Form Status
    // Cannot use `useFormValues` because it didn't initialized yet
    const values = useSelector(getFormValues('appointment')) as AppointmentFormValues;
    const hasChanged = useFormChanged('appointment');

    const onSubmit = useCallback(async () => {
        const customerData = applySourceChange(values.latestApplication.customer);

        if (mayManageCustomers) {
            await client.mutate({
                mutation: updateCustomer,
                variables: mapUpdateCustomerStateToPayload(customerData),
            });
        }

        // Rearrange date, only when the date has changed
        const promise = client.mutate<RearrangeDateAppointmentMutation, RearrangeDateAppointmentMutationVariables>({
            mutation: rearrangeDateAppointment,
            variables: {
                id: values.id,
                date: values.date,
            },
        });

        promise.then(() => {
            dispatch(addNotification(APPOINTMENT_SAVE_NOTIFICATION(values.identifier)));

            redirect();
        });

        return promise.catch(handleResponseError);
    }, [client, dispatch, mayManageCustomers, redirect, values]);

    const onCancel = useCallback(() => history.goBack(), [history]);

    const onChangeStatus = useCallback(
        async (newStatus: AppointmentStatus) => {
            const promise = client.mutate<UpdateStatusAppointmentMutation, UpdateStatusAppointmentMutationVariables>({
                mutation: updateStatusAppointment,
                variables: {
                    id: values.id,
                    status: newStatus,
                },
            });

            promise.then(() => {
                dispatch(addNotification(APPOINTMENT_SAVE_NOTIFICATION(values.identifier)));

                redirect();
            });

            return promise.catch(handleResponseError);
        },
        [client, dispatch, redirect, values]
    );

    const title = useMemo(() => `Appointment Details - ${values?.identifier ?? ''}`, [values]);

    const bodyComponent = useMemo(
        () =>
            initialValues && (
                <AppointmentForm
                    disabled={[AppointmentStatus.COMPLETED, AppointmentStatus.VOID].includes(initialValues?.status)}
                    initialValues={initialValues}
                    reservedAppointments={data?.reserved ?? []}
                    useCustomerBirthDay={useCustomerBirthDay}
                    useCustomerNationality={useCustomerNationality}
                />
            ),
        [data, initialValues, useCustomerBirthDay, useCustomerNationality]
    );

    const moreActions = useMemo(() => {
        const actions = [
            mayManageAppointments &&
                ![
                    AppointmentStatus.COMPLETED,
                    AppointmentStatus.VOID,
                    AppointmentStatus.CONTACTED,
                    AppointmentStatus.CHECKIN,
                ].includes(values?.status) && {
                    label: 'Contacted',
                    onAction: () => onChangeStatus(AppointmentStatus.CONTACTED),
                },

            mayManageAppointments &&
                ![AppointmentStatus.COMPLETED, AppointmentStatus.VOID, AppointmentStatus.CHECKIN].includes(
                    values?.status
                ) && {
                    label: 'Checked in',
                    onAction: () => onChangeStatus(AppointmentStatus.CHECKIN),
                },

            mayManageAppointments &&
                ![AppointmentStatus.COMPLETED, AppointmentStatus.VOID].includes(values?.status) && {
                    label: 'Completed',
                    onAction: () => onChangeStatus(AppointmentStatus.COMPLETED),
                },

            mayManageAppointments &&
                ![AppointmentStatus.COMPLETED, AppointmentStatus.VOID].includes(values?.status) && {
                    label: 'Void',
                    onAction: () => onChangeStatus(AppointmentStatus.VOID),
                },

            mayManageAppointments && {
                label: 'submit changes',
                disabled: !hasChanged,
                onAction: onSubmit,
            },
        ];

        return actions.filter(Boolean);
    }, [hasChanged, mayManageAppointments, onChangeStatus, onSubmit, values]);

    if (loading) {
        return null;
    }

    return (
        <ApplicationForm
            bodyComponent={bodyComponent}
            moreActions={moreActions}
            onCancel={onCancel}
            onSubmit={onSubmit}
            title={title}
        />
    );
};

export default AppointmentPage;
