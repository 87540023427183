import { identity } from 'lodash';
import React from 'react';
import { useCalculatorContext } from '../../CalculatorContext';
import StaticField, { sharedPropTypes } from './StaticField';

export const useFormatPercentage = () => {
    const { formats = {} } = useCalculatorContext();
    const { formatPercentage = identity } = formats;

    return formatPercentage;
};

const PercentageField = props => {
    const formatPercentage = useFormatPercentage();

    return <StaticField {...props} format={formatPercentage} />;
};

PercentageField.propTypes = {
    ...sharedPropTypes,
};

export default PercentageField;

export const renderPercentage = props => <PercentageField {...props} />;
