// @ts-ignore
import { Modal } from '@appvantageasia/afc-ui';
import styled, { css } from 'styled-components';
import { Channel } from '../../../schema';

const CalculatorModal = styled(Modal)`
    z-index: 1;
    ${props =>
        props.channel === Channel.EXPRESS &&
        props.countryCode === 'SG' &&
        css`
            & > * {
                width: 800px;
                max-width: 800px;
            }
        `}
`;

export default CalculatorModal;
