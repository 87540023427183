import { Paging } from '@appvantageasia/afc-ui';
import { get, orderBy, getOr } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { BookingStatus } from '../../../../../schema';
import { getContext, getZoneId } from '../../../../../selectors';
import { useCompanyFormatting } from '../../../../../utils/withCompanyFormatting';
import ApvPortlet from '../../../../template/Portlet';
import { ListTable } from '../../../../ui/lists';
import usePaging from '../../../../utilities/usePaging';
import { useFormContext } from './context';

const useColumns = (zoneCode, zoneId, zones, variantPrices, currencySymbol) => {
    const history = useHistory();

    return useMemo(() => {
        const pricesColumns = [];

        if (zoneCode === 'All') {
            // if on a country level, list prices for all zones
            zones.forEach(({ code, id }) => {
                pricesColumns.push({
                    name: `Price - ${code} (${currencySymbol})`,
                    id: `prices[${id}]`,
                    renderCell: item => get(`prices[${id}]`, item) || variantPrices?.[id] || '-',
                });
            });
        } else {
            // else if on a zone level, list price for this zone only
            pricesColumns.push({
                name: `Price - ${zoneCode} (${currencySymbol})`,
                id: `prices[${zoneId}]`,
                renderCell: item => get(`prices[${zoneId}]`, item) || variantPrices?.[zoneId] || '-',
            });
        }

        return [
            { name: 'No.', id: 'identifier', renderCell: getOr('-', 'identifier') },
            { name: 'VIN', id: 'vin', renderCell: item => get('details.vin', item) || '-' },
            ...pricesColumns,
            { name: 'Location', id: 'location', renderCell: item => get('details.location', item) || '-' },
            { name: 'Status', id: 'state', renderCell: getOr('-', 'booking.status') },
            {
                name: 'Application ID',
                id: 'applicationId',
                renderCell: item => {
                    if (get('state', item) === BookingStatus.MANUALLYDEDUCTED) {
                        return getOr('-', 'applicationIdentifier', item);
                    }

                    return getOr('-', 'booking.application.identifier', item);
                },
                underline: true,
                onClick: (event, item) => {
                    const applicationId = item?.booking?.application?.version?.id;
                    if (applicationId) {
                        event.stopPropagation();
                        history.push(`/workflow/applications/${applicationId}`);
                    }
                },
            },
        ];
    }, [zoneCode, zones, currencySymbol, variantPrices, zoneId, history]);
};

const formatPrice = format => ({ zoneId, value }) => [zoneId, value !== null ? format(value) : null];

const Inventory = () => {
    const { values } = useFormContext();

    // get currency formatting
    const { currencySymbol, formatNumber } = useCompanyFormatting();

    const variant = get('variant', values);
    const inventorySet = get('inventorySet', values);
    const inventory = get('inventory', values);

    const initialUnits = get('inventorySet.units', values).map(unit => ({
        ...unit,
        prices: unit.prices.length ? Object.fromEntries(unit.prices.map(formatPrice(formatNumber))) : {},
    }));

    const variantPrices = Object.fromEntries(variant.prices.map(formatPrice(formatNumber)));

    const sortedItems = useMemo(() => orderBy(['identifier'], ['asc'], initialUnits), [initialUnits]);

    const [pagedItems, paging] = usePaging(sortedItems);

    // get all zones containing zone code and zoneId
    const { user, countryCode, zoneCode = 'All', companyCode } = useSelector(getContext);
    const { availableCompanies: companies } = user;
    const { countries = [] } = companies.find(item => item.code === companyCode) || {};
    const country = countries.filter(({ code }) => code === countryCode);
    const { zones } = country[0];

    // get current zoneId
    const zoneId = useSelector(getZoneId);

    const history = useHistory();
    const columns = useColumns(zoneCode, zoneId, zones, variantPrices, currencySymbol);

    return (
        <ApvPortlet name="Inventory" closable open>
            <br />
            <div className="container-fluid">
                <ListTable
                    columns={columns}
                    items={pagedItems}
                    onItemClick={item =>
                        history.push(`/vehicle/inventories/${inventory.id}/${inventorySet.id}/${item.id}`)
                    }
                />
                {paging && <Paging {...paging} />}
            </div>
        </ApvPortlet>
    );
};

export default Inventory;
