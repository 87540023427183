import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { FinanceProductSubType } from '../../../../../schema';
import { yesNoOptions } from '../../../../../shared/constants/options';
import FieldContainer from '../../../../template/Field-container';
import NumberField from '../../../../template/Number';
import SwitchField from '../../../../template/Switch';
import { useFormContext } from './context';

const PaymentTerm = () => {
    const { disabled, values, change } = useFormContext();

    const subType = get('subType', values);

    const min = get('termSetting.min', values);
    const max = get('termSetting.max', values);
    const show = get('termSetting.show', values);

    useEffect(() => {
        if (min === max) {
            change('termSetting.step', 0);
            change('termSetting.default', min);
        }

        if (!show || min === max) {
            change('termSetting.editable', false);
        }
    }, [change, min, max, show]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <div className="sub-container-fluid">
                        <FieldContainer label="Min (Months)*">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                min={0}
                                name="termSetting.min"
                                precision={0}
                            />
                        </FieldContainer>
                        <span />
                        <FieldContainer label="Max (Months)*">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                min={0}
                                name="termSetting.max"
                                precision={0}
                            />
                        </FieldContainer>
                        <span />
                        <FieldContainer label="Step (Months)*">
                            <Field
                                component={NumberField}
                                disabled={min === max || disabled}
                                max={max}
                                min={1}
                                name="termSetting.step"
                                precision={0}
                            />
                        </FieldContainer>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <div className="sub-container-fluid">
                        <div className="flex-3">
                            <FieldContainer label="Default (Months)*">
                                <Field
                                    component={NumberField}
                                    disabled={disabled}
                                    min={0}
                                    name="termSetting.default"
                                    precision={0}
                                />
                            </FieldContainer>
                        </div>
                    </div>
                </div>
                {subType === FinanceProductSubType.DEFERREDPRINCIPAL && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Interest Only">
                            <Field component={NumberField} min={0} name="termSetting.interestOnly" />
                        </FieldContainer>
                    </div>
                )}
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Show">
                        <Field
                            component={SwitchField}
                            disabled={disabled}
                            name="termSetting.show"
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Editable">
                        <Field
                            component={SwitchField}
                            disabled={!show || disabled || min === max}
                            name="termSetting.editable"
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                </div>
            </div>
        </div>
    );
};

export default PaymentTerm;
