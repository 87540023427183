import UpdateOptions from '../components/fields/edition/UpdateOption';
import { getRangeOptions, weightFieldAfter } from '../utils';
import fieldTemplate from './fieldTemplate';
import { BalloonMode, FinanceProductSubType, FinanceProductType } from './schema';

const getDefaultMileage = (values, context) => {
    const { selectedFinanceProduct } = context;

    if (!selectedFinanceProduct) {
        // we do not know yet
        return undefined;
    }

    if (selectedFinanceProduct.subType === FinanceProductSubType.LEASEPURCHASE) {
        return selectedFinanceProduct.averageMileageSetting?.default;
    }

    return 10000;
};

const checkIsViewable = (financeProductType, balloonType) =>
    financeProductType === FinanceProductType.LEASING &&
    [BalloonMode.TABLE, BalloonMode.MILEAGETABLE, 'leasing'].includes(balloonType);

const mileageField = {
    // copy template
    ...fieldTemplate,

    // setup meta
    label: t => t('calculator.label.mileage'),
    weight: weightFieldAfter('monthlyInstalment'),

    // setup states
    isViewable: context => {
        const { selectedFinanceProduct } = context;

        if (!selectedFinanceProduct) {
            // we do not know yet
            return false;
        }

        if (selectedFinanceProduct.subType === FinanceProductSubType.LEASEPURCHASE) {
            return selectedFinanceProduct.averageMileageSetting?.show || false;
        }

        return checkIsViewable(selectedFinanceProduct.type, selectedFinanceProduct.balloonSetting?.mode);
    },
    isEditable: context => {
        const { selectedFinanceProduct } = context;

        if (!selectedFinanceProduct) {
            // we do not know yet
            return false;
        }

        if (selectedFinanceProduct.subType === FinanceProductSubType.LEASEPURCHASE) {
            return selectedFinanceProduct.averageMileageSetting?.editable || false;
        }

        return false;
    },

    // setup values management
    getInitialValue: getDefaultMileage,
    updates: {
        financeProduct: () => undefined,
    },

    // setup edition rendering
    editionComponent: UpdateOptions,
    getEditionProperties: context => {
        // get interest rate config
        const { selectedFinanceProduct } = context;
        const { averageMileageSetting } = selectedFinanceProduct;

        // extract what we need
        const { min, max, step } = averageMileageSetting;

        return {
            size: 4,
            smallSize: 3,
            options: getRangeOptions(min, max, step),
        };
    },
};

export default mileageField;
