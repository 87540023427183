import React, { ComponentType, useContext } from 'react';
import { ModalContext, ModalContextData } from './ModalProvider';

export const useModal = () => useContext(ModalContext);

export type WithModalInjectedProps = {
    modal: ModalContextData;
};

export const withModal = <Props extends WithModalInjectedProps = WithModalInjectedProps>(
    WrappedComponent: ComponentType<Props>
) => {
    const Component = (props: Omit<Props, keyof WithModalInjectedProps>) => {
        const modal = useModal();
        const newProps = { ...props, modal } as Props;

        return <WrappedComponent {...newProps} />;
    };

    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    Component.displayName = `withModal(${displayName})`;

    return Component;
};
