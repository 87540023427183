import * as graph from './account.graphql';

// eslint-disable-next-line import/prefer-default-export
export const refreshAuthorization = async client => {
    const { data } = await client.mutate({ mutation: graph.refreshCredentials, fetchPolicy: 'no-cache' });

    return data;
};

export const switchTenant = async (client, params) => {
    const { data } = await client.mutate({
        mutation: graph.switchTenant,
        variables: { ...params },
        fetchPolicy: 'no-cache',
    });

    return data;
};

export const logout = async client => {
    const { data } = await client.mutate({ mutation: graph.logout, fetchPolicy: 'no-cache' });

    return data;
};
