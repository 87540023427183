import styled from 'styled-components';

export const TextAlignRight = styled.div`
    text-align: right !important;
`;

export const TextAlignLeft = styled.div`
    text-align: left !important;
`;

export const TextAlignCenter = styled.div`
    text-align: center !important;
`;
