import { get, getOr } from 'lodash/fp';
import React from 'react';
import StaticField from '../components/fields/display/StaticField';
import UpdateOptions from '../components/fields/edition/UpdateOption';
import { weightFieldAfter } from '../utils';
import fieldTemplate from './fieldTemplate';
import { DerivedMethod, PaymentMode } from './schema';

const getDefaultPaymentMode = get('selectedFinanceProduct.paymentMode.mode');

export const usePaymentModeOptions = t => {
    return [
        { value: PaymentMode.ADVANCE, label: t('calculator.label.modeOfPayment.options.advance') },
        { value: PaymentMode.ARREARS, label: t('calculator.label.modeOfPayment.options.arrears') },
    ];
};

const paymentModeField = {
    // copy template
    ...fieldTemplate,

    // setup meta
    label: t => t('calculator.label.paymentMode'),
    weight: weightFieldAfter('financeProduct'),

    // setup states
    isViewable: context =>
        get('selectedFinanceProduct.paymentMode.derivedMethod', context) === DerivedMethod.FORMULA &&
        getOr(false, 'selectedFinanceProduct.paymentMode.show', context),
    isEditable: getOr(false, 'selectedFinanceProduct.paymentMode.editable'),

    // setup display rendering
    getDisplayProperties: ({ t }) => ({
        children: props => {
            const paymentModeOptions = usePaymentModeOptions(t);

            // eslint-disable-next-line react/prop-types
            const paymentMode = paymentModeOptions.find(item => item.value === props?.value)?.label;

            return <StaticField {...props} value={paymentMode} />;
        },
    }),

    // setup edition rendering
    editionComponent: UpdateOptions,
    getEditionProperties: ({ t }) => ({
        options: usePaymentModeOptions(t),
    }),

    // setup value management
    getInitialValue: (values, context) => getDefaultPaymentMode(context),
    updates: {
        // reset when the finance product changed
        financeProduct: () => undefined,
    },
};

export default paymentModeField;
