/* eslint-disable react/jsx-no-undef */
import { get, isEmpty } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useContentTranslation } from '../../../../../i18n';
import { getCurrentCountry } from '../../../../../selectors';
import { useSort } from '../../../../../utilities/hooks';
import ApvPortlet from '../../../../template/Portlet';
import { cells, ListTable } from '../../../../ui/lists';
import { useFormContext } from './context';

const Options = () => {
    const history = useHistory();
    const { options } = useFormContext();
    const { currency } = useSelector(getCurrentCountry);
    const { formatPath } = useContentTranslation();

    const columns = useMemo(
        () => [
            { name: 'Option ID', id: 'identifier', renderCell: get('identifier'), hasSort: true },
            { name: 'Option Name', id: 'name', renderCell: get(formatPath('name')), hasSort: true, underline: true },
            { name: 'Option Category', id: 'group', renderCell: get(formatPath('group.name')), hasSort: true },
            { name: `Price (${currency})`, id: 'price', renderCell: get('price'), hasSort: true },
            { name: 'Order No.', id: 'order', renderCell: get('order'), hasSort: true },
            { name: 'Active', id: 'active', renderCell: cells.renderActive(), hasSort: true },
        ],
        [currency, formatPath]
    );

    // sorting
    const [sortedOn, onSort] = useState(['identifier', 'asc']);
    const sortedItems = useSort(options, sortedOn);

    if (isEmpty(options)) {
        return null;
    }

    return (
        <ApvPortlet name="Options" closable open>
            <br />
            <div className="container-fluid">
                <ListTable
                    columns={columns}
                    items={sortedItems}
                    onItemClick={item => history.push(`/vehicle/options/${item.id}`)}
                    onSort={onSort}
                    sortedOn={sortedOn}
                />
            </div>
        </ApvPortlet>
    );
};

export default Options;
