import styled from 'styled-components';

const NotFoundCompanyCountryWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export default NotFoundCompanyCountryWrapper;
