import { get, isNil } from 'lodash/fp';
import * as validators from '../../../../utils/validators';

const validate = validators.compose(
    validators.requiredBoolean('inspected'),
    validators.only(values => {
        const vehicleCondition = get('listing.vehicle.condition.value', values);

        return vehicleCondition && vehicleCondition !== 'new';
    }, validators.requiredTruthy('inspected')),
    validators.requiredBoolean('setting.isHidden'),
    validators.requiredString('setting.availability'),
    validators.only(
        values => !isNil(values.details),
        validators.compose(
            validators.requiredValue('details.ownerIdType'),
            validators.requiredString('details.ownerId'),
            validators.requiredString('details.make'),
            validators.requiredString('details.model'),
            validators.requiredString('details.registrationNumber'),
            validators.requiredString('details.engineNumber'),
            validators.requiredString('details.chassisNumber'),
            validators.requiredNumber('details.omv'),
            validators.requiredDate('details.originalRegistrationDate'),
            validators.requiredDate('details.firstRegistrationDate'),
            validators.requiredNumber('details.transfers'),
            validators.requiredNumber('details.arf'),
            validators.requiredNumber('details.parfRebateAmount'),
            validators.requiredValue('details.coeCategory'),
            validators.requiredNumber('details.coePeriod'),
            validators.requiredDate('details.coeExpiryDate')
        )
    )
);

export default validate;
