import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { ThemeProvider } from 'styled-components';
import defaultTheme from '../defaultTheme';
import * as defaultFields from '../fields';
import CalculatorContextProvider from './CalculatorContext';
import CalculatorInner from './CalculatorInner';
import ValuesContextProvider from './ValuesContext';

const Calculator = ({
    className,
    disabled = false,
    fieldComponent,
    initialValues,
    skipContextValidation = false,
    onChange,
    onUpdates,
    snapshot,
    variants,
    financeProducts,
    fields = defaultFields,
    formats,
    theme = defaultTheme,
    modalClassName,
    useOptions = true,
    allowOutdated = false,
    promo,
    fallbackFinanceProduct = null,
    contentTranslation,
}) => (
    <ThemeProvider theme={theme}>
        <ValuesContextProvider initialValues={initialValues}>
            {(values, setValues) => (
                <CalculatorContextProvider
                    allowOutdated={allowOutdated}
                    contentTranslation={contentTranslation}
                    disabled={disabled}
                    fallbackFinanceProduct={fallbackFinanceProduct}
                    fields={fields}
                    financeProducts={financeProducts}
                    formats={formats}
                    onChange={onChange}
                    onUpdates={onUpdates}
                    promo={promo}
                    setValues={setValues}
                    skipContextValidation={skipContextValidation}
                    snapshot={snapshot}
                    useOptions={useOptions}
                    values={values}
                    variants={variants}
                >
                    {calculatorContext => (
                        <CalculatorInner
                            calculatorContext={calculatorContext}
                            className={className}
                            fieldComponent={fieldComponent}
                            modalClassName={modalClassName}
                            values={values}
                        />
                    )}
                </CalculatorContextProvider>
            )}
        </ValuesContextProvider>
    </ThemeProvider>
);

Calculator.propTypes = {
    allowOutdated: PropTypes.bool,
    className: PropTypes.string,
    contentTranslation: PropTypes.shape({}),
    disabled: PropTypes.bool,
    fallbackFinanceProduct: PropTypes.shape({}),
    fieldComponent: PropTypes.elementType.isRequired,
    fields: PropTypes.shape({}),
    financeProducts: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    formats: PropTypes.shape({}),
    initialValues: PropTypes.shape({}).isRequired,
    modalClassName: PropTypes.string,
    onChange: PropTypes.func,
    onUpdates: PropTypes.func,
    promo: PropTypes.shape({
        valid: PropTypes.bool,
    }),
    skipContextValidation: PropTypes.bool,
    snapshot: PropTypes.shape({}),
    theme: PropTypes.shape({}),
    useOptions: PropTypes.bool,
    variants: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

// makes it pure to avoid useless rendering because of the apps using it
export default memo(Calculator);
