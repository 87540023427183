import { useQuery } from '@apollo/client';
import { getOr } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { compose } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import { FormTabContainer } from '../../../../../containers/Layout';
import { AssetCondition, Channel } from '../../../../../schema';
import { getCurrentCountry } from '../../../../../selectors';
import ApvPortlet from '../../../../template/Portlet';
import Languages from './Languages';
import MainDetails from './MainDetails';
import VehicleAssignment from './VehicleAssignment';
import { Context } from './context';
import { getData } from './data.graphql';

const Form = props => {
    const { form, languageOptions } = props;
    const values = useSelector(getFormValues(form));

    const { id: countryId, currency } = useSelector(getCurrentCountry);

    const variables = { countryId, channel: Channel.NEW };
    const { data } = useQuery(getData, { variables, fetchPolicy: 'cache-and-network' });

    const { groups, variants } = useMemo(
        () => ({
            groups: getOr([], 'groups.items', data).map(item => ({
                label: item.label.en,
                value: item.value,
            })),
            variants: getOr([], 'variants.items', data).filter(
                ({ assetCondition }) => assetCondition === AssetCondition.NEW || assetCondition === AssetCondition.DEMO
            ),
        }),
        [data]
    );

    const context = { ...props, values, currency, groups, variants };
    const displayMultiLanguage = languageOptions.length > 1;

    return (
        <Context.Provider value={context}>
            <FormTabContainer>
                <Tabs forceRenderTabPanel>
                    <TabList>
                        <Tab>Main Details</Tab>
                        {displayMultiLanguage && <Tab>Multi-Language</Tab>}
                        <Tab>Assign Vehicles</Tab>
                    </TabList>
                    <TabPanel>
                        <ApvPortlet name="Main Details" closable open>
                            <MainDetails />
                        </ApvPortlet>
                    </TabPanel>
                    {displayMultiLanguage && (
                        <TabPanel>
                            <Languages />
                        </TabPanel>
                    )}
                    <TabPanel>
                        <ApvPortlet name="Assign Vehicles" closable open>
                            <VehicleAssignment />
                        </ApvPortlet>
                    </TabPanel>
                </Tabs>
            </FormTabContainer>
        </Context.Provider>
    );
};

Form.propTypes = {
    form: PropTypes.string.isRequired,
    languageOptions: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

export default compose(reduxForm({ form: 'option' }), memo)(Form);
