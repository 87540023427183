import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { exportInsuranceApplications } from '../../../../../actions';
import { Channel } from '../../../../../schema';
import { useModal } from '../../../../Modal';
import ConnectedLoadingLayer from '../../../../common/ConnectedLoadingLayer';
import { useDownloadChannels } from '../../../../shared/useDownloadChannels';
import { DownloadModal, CloseButton, Header, Message, SelectButton, DownloadButton } from '../../../../ui/Download';
import BlockSelect from '../../../../ui/form/BlockSelect';

const durationOptions = [
    { value: 0, label: 'Current Month' },
    { value: 1, label: 'Last Month' },
    { value: 3, label: 'Last 3 Months' },
    { value: 6, label: 'Last 6 Months' },
    { value: 12, label: 'Last 12 Months' },
];

const channelOptions = [
    { value: Channel.NEW, label: 'New' },
    { value: Channel.EXPRESS, label: 'Pre-owned' },
];
export type DownloadProps = {
    onClose: () => void;
    type: string;
    dealerIds: string[];
};

const Download = ({ onClose, type, dealerIds }: DownloadProps) => {
    const modal = useModal();
    const dispatch = useDispatch();
    const [duration, setDuration] = useState(durationOptions[0]);
    const [channel, setChannel, { usedChannelOptions, channelValue }] = useDownloadChannels(channelOptions);

    const onDurationChange = useCallback(value => setDuration(value), [setDuration]);
    const onChannelChange = useCallback(value => setChannel(value), [setChannel]);

    const download = useCallback(
        () =>
            dispatch(exportInsuranceApplications(duration.value, channelValue, type, dealerIds))
                // @ts-ignore
                .then((password: string) => {
                    onClose();
                    if (password) {
                        modal.confirm({
                            title: 'Password',
                            content: password,
                            options: [],
                        });
                    }
                }),
        [dispatch, duration.value, channelValue, type, dealerIds, onClose, modal]
    );

    return (
        <DownloadModal onRequestClose={onClose} isOpen>
            <div>
                <ConnectedLoadingLayer>
                    <CloseButton onClick={onClose}>&times;</CloseButton>
                    <Header>Download {type}</Header>
                    <Message>Please select the channel and the time frame of {type.toLowerCase()} to download.</Message>
                    <SelectButton>
                        <BlockSelect
                            className="select"
                            onChange={onChannelChange}
                            options={usedChannelOptions}
                            value={[channel]}
                        />
                        <BlockSelect
                            className="select"
                            onChange={onDurationChange}
                            options={durationOptions}
                            value={[duration]}
                        />
                        <DownloadButton onClick={(channel ? download : null) as any}>download</DownloadButton>
                    </SelectButton>
                </ConnectedLoadingLayer>
            </div>
        </DownloadModal>
    );
};

export default Download;
