import { useQuery } from '@apollo/client';
import cs from 'classnames';
import { format, subMonths } from 'date-fns';
import { isNil } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCountry, getZoneId } from '../../../selectors';
import { StatisticsBlock } from '../../ui/dashboard';
import { getApplicationCounter } from './Statistics.graphql';
import { useDashboardContext } from './context';

const Statistics = () => {
    const { id: countryId } = useSelector(getCurrentCountry);
    const zoneId = useSelector(getZoneId);
    const { channels, dealerIds } = useDashboardContext();
    const filter = { zoneId, countryId, dealerIds, channels };
    const { data: result } = useQuery(getApplicationCounter, {
        skip: isNil(dealerIds) || dealerIds.length < 1 || channels.length < 1,
        fetchPolicy: 'cache-and-network',
        variables: { filter },
    });

    const [currentTab, setCurrentTab] = useState(0);
    const tabs = useMemo(
        () =>
            [
                { label: `Current Month (${format(new Date(), 'MMM')})`, dataKey: 'currentMonth' },
                {
                    label: `Last Month (${format(subMonths(new Date(), 1), 'MMM')})`,
                    dataKey: 'lastMonth',
                },
            ].map((tab, index) => ({ ...tab, active: currentTab === index })),
        [currentTab]
    );

    const data = useMemo(() => result?.metrics, [result]);

    return (
        <section className="card" order-id="card-6">
            <div className="card-header">
                <h5 className="mb-0 mr-3 d-inline-block text-black">
                    <strong>Statistics for Finance Application</strong>
                </h5>
            </div>
            <div className="card-body statistics">
                <ul className="nav nav-tabs mb-4" role="tablist">
                    {tabs.map((tab, index) => (
                        <li key={index.toString()} className="nav-item" onClick={() => setCurrentTab(index)}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className={cs('application-chart nav-link', { active: tab.active })} href="#">
                                {tab.label}
                            </a>
                        </li>
                    ))}
                </ul>
                <div className="tab-content">
                    <StatisticsBlock value={data && data[tabs[currentTab]?.dataKey]} />
                </div>
            </div>
        </section>
    );
};

export default Statistics;
