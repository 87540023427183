import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Container, Title, Select } from '../../ui/dashboard/Header';
import { DealerSelectionDropDown } from '../../ui/lists';
import { useDashboardContext } from './context';

const Header = ({ dealerIds, setChannels, setDealerIds }) => {
    const { options } = useDashboardContext();
    const [option, setOption] = useState(options[0]);

    const onChange = useCallback(
        value => {
            setOption(value);
            setChannels(value.channels);
        },
        [setChannels]
    );

    return (
        <Container>
            <Title>{option.title}</Title>
            <div>
                <DealerSelectionDropDown dealerIds={dealerIds} onValueChanged={setDealerIds} withWrapper={false} />
                <Select defaultValue={options[0]} isSearchable={false} onChange={onChange} options={options} />
            </div>
        </Container>
    );
};

Header.propTypes = {
    dealerIds: PropTypes.arrayOf(PropTypes.string),
    setChannels: PropTypes.func,
    setDealerIds: PropTypes.func,
};

export default Header;
