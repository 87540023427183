import styled, { css } from 'styled-components';

const BoxedContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #ced4da;
    min-height: 60px;
    padding: 10px 15px;
    line-height: 1;
    box-sizing: border-box;

    ${props =>
        props.fullWidth &&
        css`
            grid-column: 1 /3;
        `}
`;

BoxedContainer.Label = styled.label`
    font-size: 0.78rem;
    line-height: 1.5;
    text-transform: ${props => props.theme.casing};
    color: #666666;
    text-align: left;
`;

BoxedContainer.Error = styled.p`
    color: #ff0000;
    font-size: 0.72rem;
    margin-bottom: 5px;
`;

export default BoxedContainer;
