import styled from 'styled-components';
import TextField from './TextField';

const IdentityNumberField = styled(TextField)`
    ::placeholder {
        color: ${props => props.theme.themeHighlightColour};
        font-style: italic;
    }
`;

export default IdentityNumberField;
