import { PrimaryButton } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import * as yup from 'yup';
import { createFormValidation, requiredString } from '../../../utilities/forms';
import FormError from '../../shared/form/FormError';
import TextField from '../../shared/form/TextField';
import FormActions from '../../ui/form/FormActions';
import LoginLogo from '../../ui/login/LoginLogo';
import Title from '../../ui/login/Title';

const RequestNewPasswordForm = ({ handleSubmit }) => (
    <form onSubmit={handleSubmit}>
        <LoginLogo />
        <Title>FORGOT YOUR PASSWORD ?</Title>
        <p>We will send you an email with instructions on how to reset your password.</p>
        <TextField.Outline label="Username" name="username" placeholder="Username" />
        <FormActions>
            <div>
                <PrimaryButton type="submit">Submit</PrimaryButton>
            </div>
            <FormError form="requestNewPassword">{error => <FormActions.Error>{error}</FormActions.Error>}</FormError>
        </FormActions>
    </form>
);

RequestNewPasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

const schema = yup.object().shape({
    username: requiredString('Username is mandatory'),
});

export default reduxForm({
    form: 'requestNewPassword',
    validate: createFormValidation(schema),
})(RequestNewPasswordForm);
