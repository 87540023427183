import styled from 'styled-components';

const PriceLabel = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    padding-top: 6px;

    span {
        text-transform: ${props => props.theme.calculator.labelTransform};
        font-size: 0.85rem;
        color: ${props => props.theme.calculator.labelColor};
        white-space: pre-line;
    }
`;

export default PriceLabel;
