import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { getGlobalPermissions } from '../../selectors';
import {
    VariantList,
    ModelList,
    SubModelList,
    MakeList,
    OptionGroupList,
    OptionList,
    InventoryList,
    CreateMake,
    EditMake,
    CreateModel,
    EditModel,
    CreateSubModel,
    EditSubModel,
    CreateVariant,
    EditVariant,
    CreateOption,
    EditOption,
    CreateOptionGroup,
    EditOptionGroup,
    CreateInventory,
    EditInventory,
    EditInventorySet,
    EditInventoryUnit,
} from '../routes/Vehicles';

const VehicleRouter = () => {
    const { mayManageVehicles, mayViewVehicles } = useSelector(getGlobalPermissions);

    if (!mayViewVehicles) {
        return <Redirect to="/home" />;
    }

    return (
        <Switch>
            <Route component={OptionList} path="/vehicle/options" exact />
            {mayManageVehicles && <Route component={CreateOption} path="/vehicle/options/new" exact />}
            {mayManageVehicles && <Route component={CreateOption} path="/vehicle/options/fromGroup/:groupId" exact />}
            <Route component={EditOption} path="/vehicle/options/:id" exact />
            <Route component={OptionGroupList} path="/vehicle/optionGroups" exact />
            {mayManageVehicles && <Route component={CreateOptionGroup} path="/vehicle/optionGroups/new" exact />}
            <Route component={EditOptionGroup} path="/vehicle/optionGroups/:id" exact />
            <Route component={InventoryList} path="/vehicle/inventories" exact />
            {mayManageVehicles && <Route component={CreateInventory} path="/vehicle/inventories/new" exact />}
            <Route component={EditInventory} path="/vehicle/inventories/:id" exact />
            <Route component={EditInventorySet} path="/vehicle/inventories/:id/:setId" exact />
            <Route component={EditInventoryUnit} path="/vehicle/inventories/:id/:setId/:unitId" exact />
            <Route component={VariantList} path="/vehicle/variants" exact />
            {mayManageVehicles && <Route component={CreateVariant} path="/vehicle/variants/new" exact />}
            {mayManageVehicles && <Route component={CreateVariant} path="/vehicle/variants/fromModel/:modelId" exact />}
            {mayManageVehicles && (
                <Route component={CreateVariant} path="/vehicle/variants/fromSubModel/:modelId/:subModelId" exact />
            )}
            <Route component={EditVariant} path="/vehicle/variants/:id" exact />
            <Route component={SubModelList} path="/vehicle/subModels" exact />
            {mayManageVehicles && <Route component={CreateSubModel} path="/vehicle/subModels/new" exact />}
            {mayManageVehicles && (
                <Route component={CreateSubModel} path="/vehicle/subModels/fromModel/:modelId" exact />
            )}
            <Route component={EditSubModel} path="/vehicle/subModels/:id" exact />
            <Route component={ModelList} path="/vehicle/models" exact />
            {mayManageVehicles && <Route component={CreateModel} path="/vehicle/models/new" exact />}
            {mayManageVehicles && <Route component={CreateModel} path="/vehicle/models/fromMake/:makeId" exact />}
            <Route component={EditModel} path="/vehicle/models/:id" exact />
            <Route component={MakeList} path="/vehicle/makes" exact />
            {mayManageVehicles && <Route component={CreateMake} path="/vehicle/makes/new" exact />}
            <Route component={EditMake} path="/vehicle/makes/:id" exact />
            <Redirect to="/home" />
        </Switch>
    );
};

export default VehicleRouter;
