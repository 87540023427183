import { find, get } from 'lodash/fp';

const selectFinanceProduct = (run, context, financeProducts, values) => {
    const fallbackFinanceProduct = get('fallbackFinanceProduct', context);
    const snapshot = get('snapshot.selectedFinanceProduct', context);
    const financeProductId = get('financeProduct', values);

    if (snapshot?.id === financeProductId) {
        return snapshot;
    }

    const selectedProduct = run(
        'selectedFinanceProduct',
        () => find(financeProduct => financeProduct.id === financeProductId, financeProducts),
        [financeProductId, financeProducts]
    );

    if (!selectedProduct && fallbackFinanceProduct?.id === financeProductId) {
        return fallbackFinanceProduct;
    }

    return selectedProduct;
};

export default selectFinanceProduct;
