import React from 'react';
import { COPYRIGHT } from '../../../utilities/constants/copyright';
import FooterContainer from '../../ui/FooterContainer';

const Footer = () => (
    <FooterContainer>
        <div> {COPYRIGHT} Appvantage. All Rights Reserved. </div>
        <div> Version {process.env.REACT_APP_VERSION} </div>
    </FooterContainer>
);

export default Footer;
