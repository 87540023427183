import { set, omit, uniqBy, flow, get, flatten, sortBy } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useMemo } from 'react';

export const Context = createContext(null);

export const useComparisonFieldsContext = () => useContext(Context);

const getFieldsFromStore = flow([
    // first we only need the values for each instance
    Object.values,
    // flatten the list
    flatten,
    // remove duplicates
    uniqBy(get('0')),
    // sort remaining by weight
    sortBy(['1.weight']),
]);

const ComparisonFieldsContext = ({ children }) => {
    const [store, setStore] = useState({});

    // static methods
    const { register, unregister } = useMemo(
        () => ({
            register: (id, viewableFields) => setStore(state => set(id, viewableFields, state)),
            unregister: id => setStore(state => omit([id], state)),
        }),
        [setStore]
    );

    // get shared fields all together
    const fields = useMemo(() => getFieldsFromStore(store), [store]);

    // finally compute the context
    const context = useMemo(() => ({ fields, register, unregister }), [fields, register, unregister]);

    return <Context.Provider value={context}>{children(fields)}</Context.Provider>;
};

ComparisonFieldsContext.propTypes = {
    children: PropTypes.func.isRequired,
    fields: PropTypes.shape({}),
};

export default ComparisonFieldsContext;
