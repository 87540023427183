import { useQuery } from '@apollo/client';
import { get, isNil, orderBy } from 'lodash/fp';
import PropType from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useContentTranslation } from '../../../i18n';
import { getZoneId, getCurrentCountry, getCurrentZone } from '../../../selectors';
import withCompanyFormatting from '../../../utils/withCompanyFormatting';
import useFormatDateTime from '../../shared/useFormatDateTime';
import { cells } from '../../ui/lists';
import ListTable from '../../ui/lists/ListTable';
import { getLatestApplications, getLatestApplicationsByCountryId } from './LatestApplicationTable.graphql';
import { useDashboardContext } from './context';

const useColumns = formatCurrencyDown => {
    const zone = useSelector(getCurrentZone);
    const formatDateTime = useFormatDateTime();
    const { formatPath } = useContentTranslation();

    return useMemo(() => {
        const columns = [
            {
                name: 'Application Date',
                id: 'version.createdAt',
                renderCell: cells.renderDateTime('version.createdAt', formatDateTime),
            },
            { name: 'App ID', id: 'identifier', renderCell: get('identifier'), underline: true },
            {
                name: 'Salesperson',
                id: 'assignee.name',
                renderCell: get('assignee.name'),
            },
            { name: 'Customer', id: 'customer.name.value', renderCell: get('customer.name.value') },
            { name: 'Variant', id: formatPath('variant.name'), renderCell: get(formatPath('variant.name')) },
            { name: 'Bank', id: 'bank.name', renderCell: get(formatPath('bank.name')) },
            {
                name: 'Financial Product',
                id: formatPath('financeProduct.name'),
                renderCell: get(formatPath('financeProduct.name')),
            },
            {
                name: 'Loan',
                id: 'calculator.loan.amount',
                renderCell: cells.renderCurrencyDown('calculator.loan.amount', formatCurrencyDown),
            },
            { name: 'Status', id: 'status', renderCell: cells.renderStatus(get('statusText')) },
            {
                name: 'Last Activity',
                id: 'version.updatedAt',
                renderCell: cells.renderDateTime('version.updatedAt', formatDateTime),
            },
            { name: 'Zone', id: 'zone.code', renderCell: get('zone.code'), hasSort: true },
        ];

        if (!zone) {
            return columns;
        }

        return columns.filter(item => item.id !== 'zone.code');
    }, [formatDateTime, formatPath, formatCurrencyDown, zone]);
};

const LatestApplicationTable = ({ formats }) => {
    const { formatCurrencyDown } = formats;

    const history = useHistory();

    const { id: countryId } = useSelector(getCurrentCountry);
    const zoneId = useSelector(getZoneId);
    const { channels, dealerIds } = useDashboardContext();
    const variables = { id: zoneId || countryId, dealerIds, channels };
    const { data } = useQuery(zoneId ? getLatestApplications : getLatestApplicationsByCountryId, {
        skip: isNil(dealerIds) || dealerIds.length < 1 || channels.length < 1,
        fetchPolicy: 'cache-and-network',
        variables,
    });

    const columns = useColumns(formatCurrencyDown);

    return (
        <section className="card" order-id="card-10">
            <div className="card-header">
                <h5 className="mb-0 mr-3 d-inline-block text-black">
                    <strong>Latest 5 New Applications without resubmission</strong>
                </h5>
            </div>
            <div className="card-body" style={{ overflow: 'auto' }}>
                {data?.applications ? (
                    <ListTable
                        columns={columns}
                        items={orderBy('version.createdAt', 'desc', data?.applications) || []}
                        onItemClick={item =>
                            history.push(`/workflow/applications/${item.version.id}`, {
                                showCustomerSection: true,
                            })
                        }
                    />
                ) : (
                    <div className="m-1">There is currently no data.</div>
                )}
            </div>
        </section>
    );
};

LatestApplicationTable.propTypes = {
    formats: PropType.shape({
        formatCurrencyDown: PropType.func,
    }),
};

export default withCompanyFormatting(LatestApplicationTable);
