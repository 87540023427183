import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFormValues, reduxForm, InjectedFormProps, getFormSyncErrors } from 'redux-form';
import { RuntimeType } from '../../../../schema';
import { getCurrentCountry, getRuntimeSettings } from '../../../../selectors';
import AFCApplicantDetails from './ApplicantDetails';
import ApplicationJourney from './ApplicationJourney';
import PMEApplicantDetails from './PME/ApplicantDetails';
import { Context, FormProps } from './context';
import validate from './validate';

const Form = (props: FormProps & InjectedFormProps<any, FormProps>) => {
    const { form } = props;
    const values = useSelector(getFormValues(form));

    const { mask, currency, rounding } = useSelector(getCurrentCountry);

    const context = { ...props, values, mask, currency, rounding };
    const { type } = useSelector(getRuntimeSettings);

    const ApplicantDetails = useMemo(() => {
        switch (type) {
            case RuntimeType.PME:
                return PMEApplicantDetails;
            case RuntimeType.AFC:
            default:
                return AFCApplicantDetails;
        }
    }, [type]);

    return (
        <Context.Provider value={context}>
            <ApplicantDetails />
            <ApplicationJourney />
        </Context.Provider>
    );
};

// @ts-ignore
export default reduxForm<any, any>({ form: 'customer', validate })(Form);
