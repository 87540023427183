// @ts-ignore
import { Modal, Actions, GrayButton, ActionsCenter } from '@appvantageasia/afc-ui';
import React from 'react';
import styled from 'styled-components';

const Info = styled.p`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
`;

type MultipleSessionModalProps = {
    onClose: () => void;
};

const MultipleSessionModal = ({ onClose }: MultipleSessionModalProps) => (
    <Modal onClose={onClose} title="Termination of Previous Active Session" showTitle>
        <Info>
            You have another active session. For security reasons, we will terminate the previous session. Click
            &ldquo;Proceed&ldquo; to continue.
        </Info>
        <Actions>
            <ActionsCenter>
                <GrayButton onClick={onClose}>Proceed</GrayButton>
            </ActionsCenter>
        </Actions>
    </Modal>
);

export default MultipleSessionModal;
