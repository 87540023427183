import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import CurrencyInput from 'react-currency-input';
import { fieldMetaPropTypes, fieldInputPropTypes } from 'redux-form';
import styled from 'styled-components';
import { ErrorMessageDiv } from '../../containers/Layout';

const CurrencyField = ({ allowNegative, input, className, meta, disabled, precision, prefix, placeholder }) => {
    const { error, touched, active } = meta;
    const { value, onChange } = input;

    const handleChange = useCallback((event, maskedvalue, floatvalue) => onChange(floatvalue), [onChange]);

    return (
        <div className={className}>
            <CurrencyInput
                allowNegative={allowNegative}
                disabled={disabled}
                onChangeEvent={handleChange}
                placeholder={placeholder}
                precision={precision}
                prefix={`${prefix} ` || ''}
                value={value === '' ? null : value}
                allowEmpty
            />
            <ErrorMessageDiv>{touched && !active && error}</ErrorMessageDiv>
        </div>
    );
};

CurrencyField.propTypes = {
    allowNegative: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
    placeholder: PropTypes.string,
    precision: PropTypes.number,
    prefix: PropTypes.string,
};

export default styled(CurrencyField)`
    width: 100%;
    input {
        width: 100%;
        border: 0;
        color: #000000;
        background: #f0f0f0;
        height: 30px;
        font-size: 0.85rem;
        padding: 0 10px;
        text-align: ${props => props.textAlign};
    }
    input[type='text']:disabled {
        background: #d8d8d8;
    }
`;
