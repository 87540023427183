import { createContext, useContext } from 'react';
import { ApplicationPhase } from '../../../../../../schema';
import { ApplicationCustomerDataFragment, GetApplicationQuery } from '../../../common/data/Application.graphql';

export type ApplicationFormValues = GetApplicationQuery['application'] & {
    display: any;
    initialCustomer: ApplicationCustomerDataFragment;
    initialPromoCode: string;
};

export type FormProps = {
    allowPrimaryInfoChanges?: boolean;
    disabled?: boolean;
    setCalculatorStatus: (status: boolean) => void;
    useCustomerNationality: boolean;
    useCustomerBirthDay: boolean;
    validation: {
        phonePattern: RegExp | null;
    };
    applicationPhase: ApplicationPhase;
    countryCode: string;
};

export type FormContextData = FormProps & {
    values: ApplicationFormValues;
};

export const Context = createContext<FormContextData>(null!);

export const useFormContext = () => useContext(Context);
