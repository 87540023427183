import { get, getOr, isEmpty } from 'lodash/fp';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useContentTranslation } from '../../../../../i18n';
import { modelTypeOptions } from '../../../../../shared/constants/options';
import { useSort } from '../../../../../utilities/hooks';
import ApvPortlet from '../../../../template/Portlet';
import { cells, ListTable } from '../../../../ui/lists';
import { useFormContext } from './context';

const columns = formatPath => [
    { name: 'Vehicle ID', id: 'identifier', renderCell: get('identifier'), hasSort: true },
    { name: 'Variant Name', id: 'name', renderCell: get(formatPath('name')), hasSort: true, underline: true },
    {
        name: 'Model',
        id: 'model',
        renderCell: item => getOr(get(formatPath('model.name'), item), formatPath('model.parent.name'), item),
        hasSort: true,
    },
    {
        name: 'Body Type',
        id: 'bodyType',
        renderCell: item => {
            const type = get('model.parent.type', item) || get('model.type', item);

            return modelTypeOptions.find(option => option.value === type)?.label;
        },
        hasSort: true,
    },
    { name: 'Order No.', id: 'order', renderCell: get('order'), hasSort: true },
    { name: 'Active', id: 'active', renderCell: cells.renderActive(), hasSort: true },
];

const Variants = () => {
    const history = useHistory();
    const { variants } = useFormContext();
    const { formatPath } = useContentTranslation();

    // sorting
    const [sortedOn, onSort] = useState(['identifier', 'asc']);
    const sortedItems = useSort(variants, sortedOn);

    if (isEmpty(variants)) {
        return null;
    }

    return (
        <ApvPortlet name="Variant" closable open>
            <br />
            <div className="container-fluid">
                <ListTable
                    columns={columns(formatPath)}
                    items={sortedItems}
                    onItemClick={item => history.push(`/vehicle/variants/${item.id}`)}
                    onSort={onSort}
                    sortedOn={sortedOn}
                />
            </div>
        </ApvPortlet>
    );
};

export default Variants;
