import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field, FormSection } from 'redux-form';
import { PermissionLevel, PermissionCode } from '../../../../../schema';
import { getCurrentCountry } from '../../../../../selectors';
import FieldContainer from '../../../../template/Field-container';
import Portlet from '../../../../template/Portlet';
import SwitchField from '../../../../template/Switch';
import { RoleFormContext, useFormContext } from './context';

const switchOptions = [
    { label: 'HIDE', value: PermissionLevel.NONE },
    { label: 'VIEW', value: PermissionLevel.VIEW },
    { label: 'MANAGE', value: PermissionLevel.MANAGE },
];

const yesNoOptions = [
    { label: 'Yes', value: PermissionLevel.MANAGE },
    { label: 'No', value: PermissionLevel.NONE },
];

const yesNotOptions = [
    PermissionCode.CI_NEW_CAR_CHANNEL,
    PermissionCode.CI_USED_CAR_CHANNEL,
    PermissionCode.CI_EXPRESS_CHANNEL,
    PermissionCode.CI_EVENT_CHANNEL,
    PermissionCode.ADMIN_RECALCULATE_INTEREST_RATE,
    PermissionCode.ADMIN_RECALCULATE_RESIDUAL_VALUE,
    PermissionCode.CI_RECALCULATE_INTEREST_RATE,
    PermissionCode.CI_RECALCULATE_RESIDUAL_VALUE,
];

const recalculateDeps = {
    [`${PermissionCode.ADMIN_RECALCULATE_INTEREST_RATE}`]: 'ADMIN_RECALCULATE',
    [`${PermissionCode.ADMIN_RECALCULATE_RESIDUAL_VALUE}`]: 'ADMIN_RECALCULATE',
    [`${PermissionCode.CI_RECALCULATE_INTEREST_RATE}`]: 'CI_RECALCULATE',
    [`${PermissionCode.CI_RECALCULATE_RESIDUAL_VALUE}`]: 'CI_RECALCULATE',
};

const createGroupComponent = (title: string, permissions: {}) => () => {
    const { disabled, values } = useFormContext() as RoleFormContext;
    const userPermission = values.permissions;

    const isAdminLoginDisabled = userPermission.ADMIN_LOGIN === PermissionLevel.NONE;
    const disableAllAdminField = title === 'Admin Portal' && isAdminLoginDisabled;

    const isAdminApplicationsManage = userPermission.ADMIN_APPLICATIONS === PermissionLevel.MANAGE;
    const isCiApplicationsManage = userPermission.CI_APPLICATIONS === PermissionLevel.MANAGE;

    const latestPermission = (Object.entries(permissions) as [PermissionCode, string][]).filter(([code]) => {
        if (recalculateDeps[code]) {
            return userPermission[recalculateDeps[code]] === PermissionLevel.MANAGE;
        }

        return true;
    });

    return (
        <Portlet name={title} closable open>
            <div className="container-fluid">
                <div className="row">
                    {latestPermission.map(([key, label]) => (
                        <div key={key} className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label={label}>
                                <Field
                                    component={SwitchField}
                                    disabled={
                                        disabled ||
                                        (key !== PermissionCode.ADMIN_LOGIN && disableAllAdminField) ||
                                        (key === PermissionCode.ADMIN_RECALCULATE && !isAdminApplicationsManage) ||
                                        (key === PermissionCode.CI_RECALCULATE && !isCiApplicationsManage)
                                    }
                                    name={key}
                                    options={yesNotOptions.includes(key) ? yesNoOptions : switchOptions}
                                />
                            </FieldContainer>
                        </div>
                    ))}
                </div>
            </div>
        </Portlet>
    );
};

const FinanceCalculatorPermissions = createGroupComponent('Dealer Sales Tool', {
    [`${PermissionCode.CI_NEW_CAR_CHANNEL}`]: 'New Car Channel',
    [`${PermissionCode.CI_USED_CAR_CHANNEL}`]: 'Used Car Channel',
    [`${PermissionCode.CI_EXPRESS_CHANNEL}`]: 'Pre-owned Channel',
    [`${PermissionCode.CI_EVENT_CHANNEL}`]: 'Event Channel',
    [`${PermissionCode.CI_RESERVATIONS}`]: 'Reservations',
    [`${PermissionCode.CI_APPLICATIONS}`]: 'Finance Applications',
    [`${PermissionCode.CI_INSURANCE_APPLICATIONS}`]: 'Insurance Applications',
    [`${PermissionCode.CI_LEADS}`]: 'Leads', // New Permission
    [`${PermissionCode.CI_APPOINTMENT}`]: 'Appointment',
    [`${PermissionCode.CI_CUSTOMERS}`]: 'Customers', // New Permission
    [`${PermissionCode.CI_RECALCULATE}`]: 'Recalculate',
    [`${PermissionCode.CI_RECALCULATE_RESIDUAL_VALUE}`]: 'Residual Value - Editable',
    [`${PermissionCode.CI_RECALCULATE_INTEREST_RATE}`]: 'Interest Rate - Editable',
});

const AdminPortalPermissions = () => {
    const { usePorscheFinder } = useSelector(getCurrentCountry);

    const Permissions = useMemo(
        () =>
            createGroupComponent('Admin Portal', {
                [`${PermissionCode.ADMIN_LOGIN}`]: 'Login',
                [`${PermissionCode.ADMIN_RESERVATIONS}`]: 'Reservations',
                [`${PermissionCode.ADMIN_APPLICATIONS}`]: 'Finance Applications',
                [`${PermissionCode.ADMIN_INSURANCE_APPLICATIONS}`]: 'Insurance Applications',
                [`${PermissionCode.ADMIN_LEADS}`]: 'Leads', // New Permission
                [`${PermissionCode.ADMIN_APPOINTMENT}`]: 'Appointment',
                [`${PermissionCode.ADMIN_CUSTOMERS}`]: 'Customers', // New Permission
                // eslint-disable-next-line max-len
                [`${PermissionCode.ADMIN_FINANCE}`]: 'Financial Products', // New permission from all previous finance permissions
                // eslint-disable-next-line max-len
                [`${PermissionCode.ADMIN_VEHICLES}`]: 'Vehicle Models', // New permission from all previous model permissions
                [`${PermissionCode.ADMIN_USERS}`]: 'Users', // New permission as super admin ?
                [`${PermissionCode.ADMIN_ROLES}`]: 'Roles', // New permission as super admin ?
                [`${PermissionCode.ADMIN_ROLE_HIERARCHY}`]: 'Role Hierarchy', // New permission as super admin ?
                [`${PermissionCode.ADMIN_MAINTENANCE}`]: 'Maintenance', // New Permission ??
                [`${PermissionCode.ADMIN_CONSENT_DECLARATION}`]: 'Consents & Declarations',
                [`${PermissionCode.ADMIN_PROMO_CODE}`]: 'Promo Codes',
                [`${PermissionCode.ADMIN_NEW_CAR_CHANNEL}`]: 'New Car Channel',
                [`${PermissionCode.ADMIN_USED_CAR_CHANNEL}`]: 'Used Car Channel',
                [`${PermissionCode.ADMIN_EXPRESS_CHANNEL}`]: 'Pre-owned Channel',
                [`${PermissionCode.ADMIN_EVENT_CHANNEL}`]: 'Event Channel',
                ...(usePorscheFinder && { [`${PermissionCode.ADMIN_FINDER_VEHICLE}`]: 'Finder Vehicle' }),
                [`${PermissionCode.ADMIN_RECALCULATE}`]: 'Recalculate',
                [`${PermissionCode.ADMIN_RECALCULATE_RESIDUAL_VALUE}`]: 'Residual Value - Editable',
                [`${PermissionCode.ADMIN_RECALCULATE_INTEREST_RATE}`]: 'Interest Rate - Editable',
            }),
        [usePorscheFinder]
    );

    return <Permissions />;
};

const Permissions = () => (
    <FormSection name="permissions">
        <FinanceCalculatorPermissions />
        <AdminPortalPermissions />
    </FormSection>
);

export default Permissions;
