import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    color: #9c9c9cc7;
    font-size: 0.85rem;
`;

const StyledIcon = styled(Icon)`
    color: ${props => (props.checked ? '#5fb142' : '#da223a')};
    margin-right: 1rem;

    flex-shrink: 0;
    flex-basis: 15px;
`;

const PasswordRequirement = ({ description, isChecked }) => (
    <Container>
        <StyledIcon checked={isChecked} icon={isChecked ? faCheck : faTimes} />
        <div>{description}</div>
    </Container>
);

PasswordRequirement.propTypes = {
    description: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired,
};

export default PasswordRequirement;
