import { Decimal } from 'decimal.js';
import { isNumber, isNil } from 'lodash/fp';
import numeral from 'numeral';
import { RoundingMode } from '../schema';

export const getCurrencyFormat = precision => {
    switch (precision) {
        case 0:
            return '0,0';

        case 1:
            return '0,0.0';

        case 2:
            return '0,0.00';

        default:
            return '0,0.00';
    }
};

export const parseNumber = value => {
    if (isNumber(value) || isNil(value)) {
        return value;
    }

    return parseFloat(value.replace(/,/g, ''));
};

export const roundNumber = (precision, method = RoundingMode.ROUNDINGUP) => {
    const powN = new Decimal(10).pow(precision);

    return value => {
        if (value) {
            if (method === RoundingMode.ROUNDINGDOWN) {
                return new Decimal(value).mul(powN).floor().div(powN).toNumber();
            }

            return new Decimal(value).mul(powN).ceil().div(powN).toNumber();
        }

        return 0;
    };
};

export const formatPercentage = precision => {
    return value => `${value.toFixed(precision)}%`;
};

export const formatCurrencyOnly = (defaultUnit = '$') => (value, unit = defaultUnit) => {
    if (value < 0) {
        return `-${unit} ${value}`;
    }

    return `${unit} ${value}`;
};

export const formatCurrency = (precision, defaultUnit = '$', method = RoundingMode.ROUNDINGUP) => {
    const formatStr = getCurrencyFormat(precision);
    const roundFunction = roundNumber(precision, method);

    return (value, unit = defaultUnit) => {
        if (value < 0) {
            return `-${unit} ${numeral(-roundFunction(value)).format(formatStr)}`;
        }

        return `${unit} ${numeral(roundFunction(value)).format(formatStr)}`;
    };
};

export const formatCurrencyValues = (precision, defaultUnit = '$', method = RoundingMode.ROUNDINGUP) => {
    const formatStr = getCurrencyFormat(precision);
    const roundFunction = roundNumber(precision, method);

    return (values, unit = defaultUnit, separator = ',') => {
        const formattedValues = values.map(value => `${numeral(roundFunction(value)).format(formatStr)}`);

        return `${unit} ${formattedValues.join(separator)}`;
    };
};

export const formatNumber = (precision, method = RoundingMode.ROUNDINGUP) => {
    const formatStr = getCurrencyFormat(precision);
    const roundFunction = roundNumber(precision, method);

    return value => {
        if (value < 0) {
            return `-${numeral(-roundFunction(value)).format(formatStr)}`;
        }

        return `${numeral(roundFunction(value)).format(formatStr)}`;
    };
};

export const convertPercentageToRealNumber = num => num / 100.0;

export const convertRealNumberToPercentage = num => num * 100;
