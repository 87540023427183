import { useRef, useEffect } from 'react';

const useTooltip = valueNodeRef => {
    const tooltipRef = useRef(null);

    useEffect(() => {
        const { current: valueNode } = valueNodeRef;

        if (!valueNode) {
            return () => {};
        }

        const onMouseOver = event => {
            const { current: tooltip } = tooltipRef;

            if (!tooltip) {
                return;
            }

            if (tooltip.style.visibility === 'visible') {
                return;
            }

            const { clientHeight, clientWidth } = tooltip;
            const { top, left, width } = event.target.getBoundingClientRect();

            tooltip.style.top = `${top - clientHeight - 5}px`;
            tooltip.style.left = `${left + width / 2 - clientWidth / 2}px`;
            tooltip.style.visibility = 'visible';
        };

        const onMouseLeave = () => {
            const { current: tooltip } = tooltipRef;

            if (!tooltip) {
                return;
            }

            tooltip.style.visibility = 'hidden';
        };

        valueNode.addEventListener('click', onMouseOver, false);
        valueNode.addEventListener('mouseenter', onMouseOver, false);
        valueNode.addEventListener('mouseleave', onMouseLeave, false);

        return () => {
            valueNode.removeEventListener('click', onMouseOver, false);
            valueNode.removeEventListener('mouseenter', onMouseOver, false);
            valueNode.removeEventListener('mouseleave', onMouseLeave, false);
        };
    }, [valueNodeRef, tooltipRef]);

    return tooltipRef;
};

export default useTooltip;
