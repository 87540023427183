// @ts-ignore
import { Actions, DarkButton, Modal } from '@appvantageasia/afc-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InjectedFormProps, reduxForm } from 'redux-form';
import * as validators from '../../../../../utils/validators';
import TextField from '../../../../shared/form/TextField';
import BoxedGrid from '../../../../ui/form/BoxedGrid';
import usePhone from '../../../../utilities/usePhone';
import useCustomerNamesSynchronization from '../../../Customer/useCustomerNamesSynchronization';
import useCustomerSource from '../../../Customer/useCustomerSource';
import { ApplicationFormValues } from './Form/context';

const watchedFields = ['name', 'firstName', 'lastName', 'email', 'phone', 'identityNumber'];

export type AddGuarantorFormModalProps = {
    onClose: () => void;
    validation: {
        phonePattern: RegExp | null;
    };
};

export type AddGuarantorFormModalValues = Pick<
    ApplicationFormValues['customer'],
    'name' | 'email' | 'phone' | 'identityNumber'
>;

const AddGuarantorFormModal = ({
    onClose,
    handleSubmit,
}: AddGuarantorFormModalProps & InjectedFormProps<AddGuarantorFormModalValues, AddGuarantorFormModalProps>) => {
    const { onNormalizeFullName } = useCustomerNamesSynchronization();
    useCustomerSource(watchedFields);

    const { t } = useTranslation();

    const { code: phoneCountryCode, minDigits: minPhoneDigit, maxDigits: maxPhoneDigit } = usePhone();

    return (
        <Modal onClose={onClose} title="Add Guarantor" showClose showTitle>
            <BoxedGrid>
                <TextField.BoxedFullWidth label="Name" name="name.value" normalize={onNormalizeFullName} autoFocus />
                <TextField.BoxedFullWidth label="Email" name="email.value" type="email" />
                <TextField.Boxed
                    label="Mobile No."
                    maxLength={maxPhoneDigit}
                    minLength={minPhoneDigit}
                    name="phone.value"
                    prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                    type="tel"
                />
                <TextField.Boxed
                    label="Identity No."
                    name="identityNumber.value"
                    placeholder={t('customerDetails.placeholder.identityNumber')}
                />
            </BoxedGrid>
            <Actions>
                <DarkButton onClick={handleSubmit}>Send</DarkButton>
            </Actions>
        </Modal>
    );
};

const validate = validators.compose(
    validators.customerProperty('name', validators.requiredString('name.value')),
    validators.customerProperty(
        'email',
        validators.compose(validators.requiredString('email.value'), validators.validEmail('email.value'))
    ),
    validators.customerProperty(
        'identityNumber',
        validators.compose(
            validators.requiredString('identityNumber.value'),
            validators.validNIRC('identityNumber.value', 'details.nationality.value', 'dateOfBirth.value')
        )
    ),
    validators.customerProperty(
        'phone',
        validators.compose(
            validators.requiredString('phone.value'),
            validators.contextValidation('phone.value', 'phonePattern', 'Invalid Mobile Number')
        )
    )
);

// @ts-ignore
export default reduxForm<AddGuarantorFormModalValues, AddGuarantorFormModalProps>({ form: 'guarantor', validate })(
    // @ts-ignore
    AddGuarantorFormModal
);
