import { get } from 'lodash';
import { UserDataFragment } from '../components/routes/ProfileRoute/Page.graphql';
import {
    ApplicationDataFragment,
    InsuranceApplicationDataFragment,
} from '../components/routes/Workflow/common/data/Application.graphql';
import {
    ApplicationActivationStatus,
    ApplicationEventType,
    ApplicationStatus,
    PermissionCode,
    PermissionLevel,
    EventExternalSite,
} from '../schema';
import { CountryDataFragment } from './useLoadCompany.graphql';

/* may user void the application */
export const mayVoidApplication = (application: ApplicationDataFragment) => {
    const { status } = application;

    switch (status) {
        case ApplicationStatus.COMPLETED:
        case ApplicationStatus.CANCELLED:
            return false;

        default:
            return true;
    }
};

/* may user complete the application */
export const mayCompleteApplication = (application: ApplicationDataFragment) => {
    const { status, event } = application;

    // Check if it's a finder application and reservation period is above zero
    if (
        event?.setting?.externalSite === EventExternalSite.PORSCHEFINDER &&
        event?.setting?.reservationPeriod &&
        event?.setting?.reservationPeriod > 0 &&
        status === ApplicationStatus.RECEIVED
    ) {
        return false;
    }

    switch (status) {
        case ApplicationStatus.COMPLETED:
        case ApplicationStatus.CANCELLED:
            return false;

        default:
            return true;
    }
};

/* may user approve the application */
export const mayApprovedApplication = (application: ApplicationDataFragment) => {
    const { status, appliedForFinancing } = application;

    if (!appliedForFinancing) {
        return false;
    }

    switch (status) {
        case ApplicationStatus.SUBMITTED:
        case ApplicationStatus.RECEIVED:
        case ApplicationStatus.CUSTOMERINFORECEIVED:
            return true;

        default:
            return false;
    }
};

/* may user submit changes on application */
export const maySubmitChangesOnApplication = (application: ApplicationDataFragment) => {
    const { status, appliedForFinancing } = application;

    if (!appliedForFinancing) {
        return false;
    }

    switch (status) {
        case ApplicationStatus.RECEIVED:
        case ApplicationStatus.CUSTOMERINFORECEIVED:
        case ApplicationStatus.SIGNING:
        case ApplicationStatus.PENDINGOTP:
        case ApplicationStatus.APPROVED:
        case ApplicationStatus.SIGNINGREJECTED:
        case ApplicationStatus.SIGNINGTIMEOUT:
        case ApplicationStatus.UNABLETOCONNECT:
        case ApplicationStatus.CONNECTIONFAILED:
        case ApplicationStatus.DRAFT:
            return true;

        default:
            return false;
    }
};

/* may user re-try submitting changes on application */
export const mayRetrySubmitChangesOnApplication = (application: ApplicationDataFragment) => {
    const { status, appliedForFinancing } = application;

    if (!appliedForFinancing) {
        return false;
    }

    switch (status) {
        case ApplicationStatus.SUBMISSIONFAILED:
        case ApplicationStatus.UNABLETOSUBMIT:
            return true;

        default:
            return false;
    }
};

/* may user validation the application */
export const mayValidateApplication = (application: ApplicationDataFragment) => {
    const { status, activationStatus } = application;

    if (status !== ApplicationStatus.APPROVED) {
        // application need to be approved
        return false;
    }

    switch (activationStatus) {
        case ApplicationActivationStatus.SUBMITTED:
        case ApplicationActivationStatus.RECEIVED:
            return false;

        default:
            // any other activation status is ok
            return true;
    }
};

/* may user save changes on application */
export const maySaveChangesOnApplication = (application: ApplicationDataFragment) => {
    const { appliedForFinancing, reservationDeposit, events } = application;

    const submitted = events.some(item => item.type === ApplicationEventType.SUBMIT);

    // save changes is only for payment
    // allows user to update basic customer information
    return submitted && !appliedForFinancing && !!reservationDeposit;
};

/* may user agreement concluded */
export const mayAgreementConcluded = (application: ApplicationDataFragment) => {
    const { status, event } = application;

    const isPorscheFinder = event?.setting?.externalSite === EventExternalSite.PORSCHEFINDER;
    const isIccCheckout = event?.setting?.externalSite === EventExternalSite.ICC;

    if (
        (isPorscheFinder || isIccCheckout) &&
        event?.setting?.reservationPeriod &&
        event?.setting?.reservationPeriod > 0 &&
        [ApplicationStatus.RECEIVED, ApplicationStatus.LEADRECEIVED].includes(status)
    ) {
        return true;
    }

    return false;
};

export type ApplicationPermissions = {
    mayVoidApplication: boolean;
    mayCompleteApplication: boolean;
    mayApprovedApplication: boolean;
    maySubmitChangesOnApplication: boolean;
    mayRetrySubmitChangesOnApplication: boolean;
    mayValidateApplication: boolean;
    maySaveChangesOnApplication: boolean;
    mayAgreementConcluded: boolean;
};

export type InsuranceApplicationPermissions = {
    mayVoidApplication: boolean;
    mayCompleteApplication: boolean;
    mayApprovedApplication: boolean;
    maySubmitChangesOnApplication: boolean;
    maySaveChangesOnApplication: boolean;
};

/* return the permission map for an application  */
export const getApplicationPermissions = (application: ApplicationDataFragment): ApplicationPermissions => ({
    mayVoidApplication: mayVoidApplication(application),
    mayCompleteApplication: mayCompleteApplication(application),
    mayApprovedApplication: mayApprovedApplication(application),
    maySubmitChangesOnApplication: maySubmitChangesOnApplication(application),
    mayRetrySubmitChangesOnApplication: mayRetrySubmitChangesOnApplication(application),
    mayValidateApplication: mayValidateApplication(application),
    maySaveChangesOnApplication: maySaveChangesOnApplication(application),
    mayAgreementConcluded: mayAgreementConcluded(application),
});

/** TODO update actual implmementation */
/* return the permission map for an insurance application  */
export const getInsuranceApplicationPermissions = (
    application: InsuranceApplicationDataFragment
): InsuranceApplicationPermissions => ({
    mayVoidApplication: true,
    mayCompleteApplication: true,
    mayApprovedApplication: true,
    maySubmitChangesOnApplication: true,
    maySaveChangesOnApplication: true,
});

// permission check for super user only
const checkSuperUser = (user: UserDataFragment) => !!get(user, 'isSuperUser');

// permission factory for global permission
const checkGlobalPermission = (...expectedPermissions: string[]) => (
    user: UserDataFragment,
    country: CountryDataFragment
) => {
    if (!user) {
        // unauthenticated user
        return false;
    }

    const { permissions: userPermissions, isSuperUser } = user;

    if (isSuperUser) {
        // super users have all permissions anyway
        return true;
    }

    // get selected country id
    const { id: countryId } = country;

    // get available permission based on selected country
    const availablePermission = userPermissions.find(permission => permission.countryId === countryId);

    if (availablePermission) {
        const { role } = availablePermission;
        const { permissions } = role;

        const mapPermissions = permissions.map(i => `${i.code}_${i.level}`);

        for (const permission of expectedPermissions) {
            if (mapPermissions.includes(permission)) {
                return true;
            }
        }
    }

    return false;
};

/* may user manage its profile  */
export const mayManageOwnProfile = checkGlobalPermission(`${PermissionCode.ADMIN_LOGIN}_${PermissionLevel.MANAGE}`);

/* may user view users */
export const mayViewUsers = checkGlobalPermission(
    `${PermissionCode.ADMIN_USERS}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_USERS}_${PermissionLevel.VIEW}`
);

/* may user manage users */
export const mayManageUsers = checkGlobalPermission(`${PermissionCode.ADMIN_USERS}_${PermissionLevel.MANAGE}`);

/* may user view roles */
export const mayViewRoles = checkGlobalPermission(
    `${PermissionCode.ADMIN_ROLES}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_ROLES}_${PermissionLevel.VIEW}`
);

/* may user manage roles */
export const mayManageRoles = checkGlobalPermission(`${PermissionCode.ADMIN_ROLES}_${PermissionLevel.MANAGE}`);

/* may the user view role hierarchy */
export const mayViewRolesHierarchy = checkGlobalPermission(
    `${PermissionCode.ADMIN_ROLE_HIERARCHY}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_ROLE_HIERARCHY}_${PermissionLevel.VIEW}`
);

/* may the user manage role hierarchy */
export const mayManageRolesHierarchy = checkGlobalPermission(
    `${PermissionCode.ADMIN_ROLE_HIERARCHY}_${PermissionLevel.MANAGE}`
);

/* may user manage companies */
export const mayManageCompanies = checkSuperUser;

/* may user manage countries */
export const mayManageCountries = checkSuperUser;

export const mayManageDealers = checkSuperUser;

const getIsPromoCodeEnabled = (country: CountryDataFragment) =>
    country?.channelSetting?.new?.isPromoCodeEnabled ||
    country?.channelSetting?.express?.isPromoCodeEnabled ||
    country?.channelSetting?.used?.isPromoCodeEnabled ||
    country?.channelSetting?.event?.isPromoCodeEnabled;

/* may user view promo */
export const mayManagePromo = (user: UserDataFragment, country: CountryDataFragment) => {
    if (getIsPromoCodeEnabled(country)) {
        return checkGlobalPermission(`${PermissionCode.ADMIN_PROMO_CODE}_${PermissionLevel.MANAGE}`)(user, country);
    }

    return false;
};

/* may user view promo */
export const mayViewPromo = (user: UserDataFragment, country: CountryDataFragment) => {
    if (getIsPromoCodeEnabled(country)) {
        return checkGlobalPermission(
            `${PermissionCode.ADMIN_PROMO_CODE}_${PermissionLevel.MANAGE}`,
            `${PermissionCode.ADMIN_PROMO_CODE}_${PermissionLevel.VIEW}`
        )(user, country);
    }

    return false;
};

/* may user view finance products */
export const mayViewFinanceProducts = checkGlobalPermission(
    `${PermissionCode.ADMIN_FINANCE}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_FINANCE}_${PermissionLevel.VIEW}`
);

/* may user manage finance products */
export const mayManageFinanceProducts = checkGlobalPermission(
    `${PermissionCode.ADMIN_FINANCE}_${PermissionLevel.MANAGE}`
);

/* may user view vehicles */
export const mayViewVehicles = checkGlobalPermission(
    `${PermissionCode.ADMIN_VEHICLES}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_VEHICLES}_${PermissionLevel.VIEW}`
);

/* may user manage vehicles */
export const mayManageVehicles = checkGlobalPermission(`${PermissionCode.ADMIN_VEHICLES}_${PermissionLevel.MANAGE}`);

/* may the user view applications */
export const mayViewReservations = checkGlobalPermission(
    `${PermissionCode.ADMIN_RESERVATIONS}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_RESERVATIONS}_${PermissionLevel.VIEW}`
);

/* may the user manage applications */
export const mayManageReservations = checkGlobalPermission(
    `${PermissionCode.ADMIN_RESERVATIONS}_${PermissionLevel.MANAGE}`
);

/* may the user view applications */
export const mayViewApplications = checkGlobalPermission(
    `${PermissionCode.ADMIN_APPLICATIONS}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_APPLICATIONS}_${PermissionLevel.VIEW}`
);

/* may the user manage applications */
export const mayManageApplications = checkGlobalPermission(
    `${PermissionCode.ADMIN_APPLICATIONS}_${PermissionLevel.MANAGE}`
);

/* may the user view insurance applications */
export const mayViewInsuranceApplications = checkGlobalPermission(
    `${PermissionCode.ADMIN_INSURANCE_APPLICATIONS}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_INSURANCE_APPLICATIONS}_${PermissionLevel.VIEW}`
);

/* may the user manage insurance applications */
export const mayManageInsuranceApplications = checkGlobalPermission(
    `${PermissionCode.ADMIN_INSURANCE_APPLICATIONS}_${PermissionLevel.MANAGE}`
);

/* may the user view leads */
export const mayViewLeads = checkGlobalPermission(
    `${PermissionCode.ADMIN_LEADS}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_LEADS}_${PermissionLevel.VIEW}`
);

/* may the user manage leads */
export const mayManageLeads = checkGlobalPermission(`${PermissionCode.ADMIN_LEADS}_${PermissionLevel.MANAGE}`);

/* may the user view customers */
export const mayViewCustomers = checkGlobalPermission(
    `${PermissionCode.ADMIN_CUSTOMERS}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_CUSTOMERS}_${PermissionLevel.VIEW}`
);

/* may the user manage customers */
export const mayManageCustomers = checkGlobalPermission(`${PermissionCode.ADMIN_CUSTOMERS}_${PermissionLevel.MANAGE}`);

/* may the user view maintenance */
export const mayViewMaintenance = checkGlobalPermission(
    `${PermissionCode.ADMIN_MAINTENANCE}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_MAINTENANCE}_${PermissionLevel.VIEW}`
);

/* may the user manage maintenance */
export const mayManageMaintenance = checkGlobalPermission(
    `${PermissionCode.ADMIN_MAINTENANCE}_${PermissionLevel.MANAGE}`
);

/* may the user view new car channels */
export const mayViewNewCarChannel = checkGlobalPermission(
    `${PermissionCode.ADMIN_NEW_CAR_CHANNEL}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_NEW_CAR_CHANNEL}_${PermissionLevel.VIEW}`
);
/* may the user manage new car channels */
export const mayManageNewCarChannel = checkGlobalPermission(
    `${PermissionCode.ADMIN_NEW_CAR_CHANNEL}_${PermissionLevel.MANAGE}`
);

/* may the user view used car channel */
export const mayViewUsedCarChannel = checkGlobalPermission(
    `${PermissionCode.ADMIN_USED_CAR_CHANNEL}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_USED_CAR_CHANNEL}_${PermissionLevel.VIEW}`
);
/* may the user manage used car channels */
export const mayManageUsedCarChannel = checkGlobalPermission(
    `${PermissionCode.ADMIN_USED_CAR_CHANNEL}_${PermissionLevel.MANAGE}`
);

/* may the user view express channel */
export const mayViewExpressChannel = checkGlobalPermission(
    `${PermissionCode.ADMIN_EXPRESS_CHANNEL}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_EXPRESS_CHANNEL}_${PermissionLevel.VIEW}`
);
/* may the user manage express channels */
export const mayManageExpressChannel = checkGlobalPermission(
    `${PermissionCode.ADMIN_EXPRESS_CHANNEL}_${PermissionLevel.MANAGE}`
);

/* may the user view used car channel */
export const mayViewEventChannel = checkGlobalPermission(
    `${PermissionCode.ADMIN_EVENT_CHANNEL}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_EVENT_CHANNEL}_${PermissionLevel.VIEW}`
);
/* may the user manage maintenance */
export const mayManageEventChannel = checkGlobalPermission(
    `${PermissionCode.ADMIN_EVENT_CHANNEL}_${PermissionLevel.MANAGE}`
);

/* may the user manage consent and declaration */
export const mayMangeConsentDeclaration = checkGlobalPermission(
    `${PermissionCode.ADMIN_CONSENT_DECLARATION}_${PermissionLevel.MANAGE}`
);

/* may the user view consent and declaration */
export const mayViewConsentDeclaration = checkGlobalPermission(
    `${PermissionCode.ADMIN_CONSENT_DECLARATION}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_CONSENT_DECLARATION}_${PermissionLevel.VIEW}`
);

/* may the user manage finder vehicle */
export const mayManageFinderVehicles = checkGlobalPermission(
    `${PermissionCode.ADMIN_FINDER_VEHICLE}_${PermissionLevel.MANAGE}`
);

/* may the user view finder vehicle */
export const mayViewFinderVehicles = checkGlobalPermission(
    `${PermissionCode.ADMIN_FINDER_VEHICLE}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_FINDER_VEHICLE}_${PermissionLevel.VIEW}`
);

/* may the user manage recalculate setting in finance product detail */
export const mayManageRecalculate = checkGlobalPermission(
    `${PermissionCode.ADMIN_RECALCULATE}_${PermissionLevel.MANAGE}`
);

/* may the user view recalculate setting in finance product detail */
export const mayViewRecalculate = checkGlobalPermission(
    `${PermissionCode.ADMIN_RECALCULATE}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_RECALCULATE}_${PermissionLevel.VIEW}`
);

export const mayManageRecalculateInterestRate = checkGlobalPermission(
    `${PermissionCode.ADMIN_RECALCULATE_INTEREST_RATE}_${PermissionLevel.MANAGE}`
);

export const mayManageRecalculateResidualValue = checkGlobalPermission(
    `${PermissionCode.ADMIN_RECALCULATE_RESIDUAL_VALUE}_${PermissionLevel.MANAGE}`
);

export const mayViewAppointments = checkGlobalPermission(
    `${PermissionCode.ADMIN_APPOINTMENT}_${PermissionLevel.MANAGE}`,
    `${PermissionCode.ADMIN_APPOINTMENT}_${PermissionLevel.VIEW}`
);

export const mayManageAppointments = checkGlobalPermission(
    `${PermissionCode.ADMIN_APPOINTMENT}_${PermissionLevel.MANAGE}`
);

export type GlobalPermissions = {
    mayManageOwnProfile: boolean;
    mayViewUsers: boolean;
    mayManageUsers: boolean;
    mayViewRoles: boolean;
    mayManageRoles: boolean;
    mayViewRolesHierarchy: boolean;
    mayManageRolesHierarchy: boolean;
    mayManageCompanies: boolean;
    mayManageCountries: boolean;
    mayManageDealers: boolean;
    mayViewFinanceProducts: boolean;
    mayManageFinanceProducts: boolean;
    mayViewVehicles: boolean;
    mayManageVehicles: boolean;
    mayViewCustomers: boolean;
    mayViewReservations: boolean;
    mayManageReservations: boolean;
    mayViewApplications: boolean;
    mayManageApplications: boolean;
    mayViewInsuranceApplications: boolean;
    mayManageInsuranceApplications: boolean;
    mayManageCustomers: boolean;
    mayViewMaintenance: boolean;
    mayManageMaintenance: boolean;
    mayViewLeads: boolean;
    mayManageLeads: boolean;
    mayViewPromo: boolean;
    mayViewNewCarChannel: boolean;
    mayManageNewCarChannel: boolean;
    mayViewUsedCarChannel: boolean;
    mayManageUsedCarChannel: boolean;
    mayViewExpressChannel: boolean;
    mayManageExpressChannel: boolean;
    mayViewEventChannel: boolean;
    mayManageEventChannel: boolean;
    mayViewConsentDeclaration: boolean;
    mayMangeConsentDeclaration: boolean;
    mayManageFinderVehicles: boolean;
    mayViewFinderVehicles: boolean;
    mayManageRecalculate: boolean;
    mayViewRecalculate: boolean;
    mayManageRecalculateInterestRate: boolean;
    mayManageRecalculateResidualValue: boolean;

    // Appointment
    mayViewAppointments: boolean;
    mayManageAppointments: boolean;
};

/* return the global permission map */
export const getGlobalPermissions = (user: UserDataFragment, country: CountryDataFragment): GlobalPermissions => ({
    mayManageOwnProfile: mayManageOwnProfile(user, country),
    mayViewUsers: mayViewUsers(user, country),
    mayManageUsers: mayManageUsers(user, country),
    mayViewRoles: mayViewRoles(user, country),
    mayManageRoles: mayManageRoles(user, country),
    mayViewRolesHierarchy: mayViewRolesHierarchy(user, country),
    mayManageRolesHierarchy: mayManageRolesHierarchy(user, country),
    mayManageCompanies: mayManageCompanies(user),
    mayManageCountries: mayManageCountries(user),
    mayManageDealers: mayManageDealers(user),
    mayViewFinanceProducts: mayViewFinanceProducts(user, country),
    mayManageFinanceProducts: mayManageFinanceProducts(user, country),
    mayViewVehicles: mayViewVehicles(user, country),
    mayManageVehicles: mayManageVehicles(user, country),
    mayViewCustomers: mayViewCustomers(user, country),
    mayViewReservations: mayViewReservations(user, country),
    mayManageReservations: mayManageReservations(user, country),
    mayViewApplications: mayViewApplications(user, country),
    mayManageApplications: mayManageApplications(user, country),
    mayViewInsuranceApplications: mayViewInsuranceApplications(user, country),
    mayManageInsuranceApplications: mayManageInsuranceApplications(user, country),
    mayManageCustomers: mayManageCustomers(user, country),
    mayViewMaintenance: mayViewMaintenance(user, country),
    mayManageMaintenance: mayManageMaintenance(user, country),
    mayViewLeads: mayViewLeads(user, country),
    mayManageLeads: mayManageLeads(user, country),
    mayViewPromo: mayViewPromo(user, country),
    mayViewNewCarChannel: mayViewNewCarChannel(user, country),
    mayManageNewCarChannel: mayManageNewCarChannel(user, country),
    mayViewUsedCarChannel: mayViewUsedCarChannel(user, country),
    mayManageUsedCarChannel: mayManageUsedCarChannel(user, country),
    mayViewExpressChannel: mayViewExpressChannel(user, country),
    mayManageExpressChannel: mayManageExpressChannel(user, country),
    mayViewEventChannel: mayViewEventChannel(user, country),
    mayManageEventChannel: mayManageEventChannel(user, country),
    mayViewConsentDeclaration: mayViewConsentDeclaration(user, country),
    mayMangeConsentDeclaration: mayMangeConsentDeclaration(user, country),
    mayManageFinderVehicles: mayManageFinderVehicles(user, country),
    mayViewFinderVehicles: mayViewFinderVehicles(user, country),
    mayManageRecalculate: mayManageRecalculate(user, country),
    mayViewRecalculate: mayViewRecalculate(user, country),
    mayManageRecalculateInterestRate: mayManageRecalculateInterestRate(user, country),
    mayManageRecalculateResidualValue: mayManageRecalculateResidualValue(user, country),
    mayViewAppointments: mayViewAppointments(user, country),
    mayManageAppointments: mayManageAppointments(user, country),
});
