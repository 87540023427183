import { useApolloClient } from '@apollo/client';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { submit, change } from 'redux-form';
import { addNotification } from '../../../actions';
import { getCurrentCountry, getGlobalPermissions } from '../../../selectors';
import { FINDER_VEHICLE_SAVE_NOTIFICATION } from '../../../shared/constants/notification';
import { handleResponseError, prepareForGraphQL } from '../../../utilities/forms';
import FormLayout from '../../ui/form/FormLayout';
import { FinderVehicleDataFragment } from './Edition.graphql';
import Form from './Form';
import { update } from './Page.graphql';
import PrefillForm, { parse } from './PrefillForm';

export type PageProps = {
    hasDealer: boolean;
    initialValues: Partial<FinderVehicleDataFragment>;
    refetch: (variables: { id: string; countryId: string }) => void;
};

const usePrefillForm = (dispatch: ReturnType<typeof useDispatch>) => {
    const [visible, setVisible] = useState(false);

    const submitForm = useCallback(() => dispatch(submit('finderVehiclePrefill')), [dispatch]);

    const onCancel = useCallback(() => setVisible(false), [setVisible]);

    const onSubmit = useCallback(
        ({ raw }) => {
            const parsed = parse(raw);

            dispatch(change('finderVehicle', 'details', parsed));

            setVisible(false);
        },
        [dispatch, setVisible]
    );

    const actions = useMemo(
        () => [
            {
                label: 'next',
                onAction: submitForm,
            },
        ],
        [submitForm]
    );

    return { visible, onSubmit, onCancel, actions, setVisible };
};

const Page = ({ initialValues, hasDealer, refetch }: PageProps) => {
    const country = useSelector(getCurrentCountry);
    const { mayManageFinderVehicles } = useSelector(getGlobalPermissions);

    const history = useHistory();
    const dispatch = useDispatch();

    const client = useApolloClient();

    const submitForm = useCallback(() => dispatch(submit('finderVehicle')), [dispatch]);

    const onSubmit = useCallback(
        ({ id, setting, inspected, details }) => {
            const variables = {
                id,
                finderVehicle: prepareForGraphQL({ setting, inspected, details }),
            };

            return client.mutate({ mutation: update, variables }).catch(handleResponseError);
        },
        [client]
    );

    const onSubmitSuccess = useCallback(() => {
        dispatch(addNotification(FINDER_VEHICLE_SAVE_NOTIFICATION));
        history.push('/finder');
    }, [history, dispatch]);

    const onCancel = useCallback(() => history.goBack(), [history]);

    const prefillForm = usePrefillForm(dispatch);

    const isConditionNew = useMemo(() => initialValues.listing?.vehicle?.condition?.value?.toLowerCase() === 'new', [
        initialValues,
    ]);

    const actions = useMemo(() => {
        if (!mayManageFinderVehicles) {
            return [];
        }

        return [
            !isConditionNew &&
                country?.code === 'SG' && {
                    label: 'add data from lta',
                    onAction: () => prefillForm.setVisible(true),
                },
            {
                label: 'save',
                onAction: submitForm,
            },
        ];
    }, [mayManageFinderVehicles, isConditionNew, country, submitForm, prefillForm]);

    return (
        <>
            <FormLayout
                bodyComponent={<PrefillForm initialValues={{}} onSubmit={prefillForm.onSubmit} />}
                moreActions={prefillForm.actions}
                onCancel={prefillForm.onCancel}
                title="Add Data from LTA"
                visible={prefillForm.visible}
            />
            <FormLayout
                bodyComponent={
                    initialValues && (
                        <Form
                            disabled={!mayManageFinderVehicles}
                            hasDealer={hasDealer}
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            onSubmitSuccess={onSubmitSuccess}
                            refetch={refetch}
                        />
                    )
                }
                moreActions={actions}
                onCancel={onCancel}
                title="Finder Vehicle"
                visible={!prefillForm.visible}
            />
        </>
    );
};

export default Page;
