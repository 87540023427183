import { isNil } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { EventExternalSite } from '../../../../../schema';
import { onOffOptions } from '../../../../../shared/constants/options';
import FieldContainer from '../../../../template/Field-container';
import FileUpload from '../../../../template/FileUpload';
import SwitchField from '../../../../template/Switch';
import TextareaField from '../../../../template/Textarea';
import { useFormContext } from './context';

const EDM = () => {
    const { disabled, values, change } = useFormContext();
    const { formatPath } = useContentTranslation();

    const isNormalEvent = values?.setting.externalSite === EventExternalSite.NONE;
    const isSummaryVehicleEnabled = values?.edm?.isSummaryVehicleEnabled;

    useEffect(() => {
        if (isNil(isSummaryVehicleEnabled)) {
            change('edm.isSummaryVehicleEnabled', false);
        }
    }, [change, isSummaryVehicleEnabled]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Subject">
                        <Field component={TextareaField} maxLength="256" name={formatPath('edm.subject')} />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Intro Title">
                        <Field component={TextareaField} name={formatPath('edm.introTitle')} />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Content Text">
                        <Field component={TextareaField} name={formatPath('edm.contentText')} />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Intro Image">
                        <Field
                            accept=".jpg,.png,.jpeg"
                            component={FileUpload}
                            label="Image in jpg or png"
                            name="edm.introImage"
                        />
                    </FieldContainer>
                </div>
                {!isNormalEvent && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Show Summary Vehicle">
                            <Field
                                component={SwitchField}
                                disabled={disabled}
                                name="edm.isSummaryVehicleEnabled"
                                options={onOffOptions}
                            />
                        </FieldContainer>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EDM;
