/* eslint-disable */
import React, { Component } from 'react';
import styled from 'styled-components';
import { ErrorMessageDiv } from '../../containers/Layout';

const TextareaFieldDiv = styled.div`
    width: 100%;
    textarea {
        min-height: 100px !important;
        width: 100%;
        border: 0;
        color: #000000;
        background: #f0f0f0;
        font-size: 0.85rem;
        padding: 10px;
        outline: none;
    }
    textarea:disabled {
        background: #d8d8d8;
    }
`;

class TextareaField extends Component {
    state = {};

    componentWillMount() {}

    render() {
        const { error, touched, active } = this.props.meta;

        return (
            <TextareaFieldDiv>
                {this.props.rows && (
                    <textarea
                        {...this.props.input}
                        disabled={this.props.disabled}
                        maxLength={this.props.maxLength ? this.props.maxLength : ''}
                        placeholder={this.props.placeholder ? this.props.placeholder : ''}
                        rows={this.props.rows ? this.props.rows : ''}
                    />
                )}
                {!this.props.rows && (
                    <textarea
                        {...this.props.input}
                        disabled={this.props.disabled}
                        maxLength={this.props.maxLength ? this.props.maxLength : ''}
                        placeholder={this.props.placeholder ? this.props.placeholder : ''}
                        style={{ minHeight: '145px' }}
                    />
                )}

                <ErrorMessageDiv>{touched && !active && error}</ErrorMessageDiv>
            </TextareaFieldDiv>
        );
    }
}

export default TextareaField;
