import { useQuery } from '@apollo/client';
import { getOr, get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { getCountryId } from '../../../../selectors';
import DropdownField from '../../../template/Dropdown';
import { getOptions } from './Form.graphql';
import validate from './validate';

const Form = ({ disabled = false, isUpdate = false, form }) => {
    // get roles
    const countryId = useSelector(getCountryId);
    const variables = { countryId };
    const { data } = useQuery(getOptions, { fetchPolicy: 'cache-and-network', variables });
    const roles = getOr([], 'roles.items', data);

    // get current values
    const values = useSelector(getFormValues(form));
    const roleId = get('id', values);

    // get role ids below the current selected role
    const childIds = useMemo(() => {
        if (!roleId) {
            return [];
        }

        const getChildIds = levelId =>
            roles.filter(role => role.parentId === levelId).map(role => [role.value, ...getChildIds(role.value)]);

        return [roleId, ...getChildIds(roleId)];
    }, [roles, roleId]);

    // exclude role below our current role then
    const availableParents = useMemo(() => {
        return [
            {
                value: '',
                label: 'None',
            },
        ].concat(roles.filter(role => !childIds.includes(role.value)));
    }, [roles, childIds]);

    return (
        <div className="container-fluid" style={{ margin: '0 -10px' }}>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DropdownField
                        disabled={isUpdate || disabled}
                        label="Role"
                        name="id"
                        options={roles}
                        placeholder="Select Role"
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DropdownField
                        disabled={disabled}
                        label="Parent Role"
                        name="parentId"
                        options={availableParents}
                        placeholder="Select Parent Role"
                        noSort
                    />
                </div>
            </div>
        </div>
    );
};

Form.propTypes = {
    disabled: PropTypes.bool,
    form: PropTypes.string.isRequired,
    isUpdate: PropTypes.bool,
};

export default reduxForm({ form: 'hierarchy', validate })(Form);
