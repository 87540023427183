import { flow, filter, sortBy, toPairs } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import SingleCalculatorFields from './SingleCalculatorFields';

const getViewabledFields = (calculatorContext, values, theme) =>
    flow([
        // get entries at first
        toPairs,
        // exclude none viewable fields
        filter(([, field]) => field.isViewable(calculatorContext, values, theme)),
        // sort remaining by weight
        sortBy(['1.weight']),
    ])(calculatorContext.fields);

const Fields = ({
    calculatorContext,
    setFieldOnEdition,
    values,
    fieldComponent: Component = SingleCalculatorFields,
}) => {
    // get the theme
    const theme = useTheme();

    // list viewable field (list of field names)
    const viewableFields = useMemo(() => {
        return getViewabledFields(calculatorContext, values, theme);
    }, [calculatorContext, values, theme]);

    return (
        <Component
            calculatorContext={calculatorContext}
            setFieldOnEdition={setFieldOnEdition}
            values={values}
            viewableFields={viewableFields}
        />
    );
};

Fields.propTypes = {
    calculatorContext: PropTypes.shape({}).isRequired,
    fieldComponent: PropTypes.elementType,
    setFieldOnEdition: PropTypes.func.isRequired,
    values: PropTypes.shape({}).isRequired,
};

export default Fields;
