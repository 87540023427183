import styled from 'styled-components';

export const IndicationIcon = styled.span`
    cursor: pointer;
    color: #ccc;
    position: relative;

    svg {
        position: absolute;
        font-size: 1.26rem;
        top: -1px;
    }
`;

export const FullScreenIcon = styled.div`
    cursor: pointer;
    float: right;
    color: #ccc;

    span {
        text-decoration: underline;
        color: #aaa;
        padding-right: 10px;
        font-size: 1.06rem;
    }

    svg {
        font-size: 1.23rem;
    }
`;

export const FullScreenExitIcon = styled.div`
    cursor: pointer;
    color: black;
    align-self: flex-end;
    color: #ccc;
`;

const ChartContainer = styled.div`
    background: rgb(243, 243, 248);
    padding: 20px 20px 0 20px;

    @media (min-width: 992px) {
        .card-header {
            padding: 10px 1.25rem;
        }
        .top-sales-man {
            padding: 17px;
            height: 250px;
        }

        .statistics {
            height: 356px;
            padding: 17px;
        }

        .popular {
            height: 460px;
            padding: 17px;
        }

        .dayChart {
            height: 460px;
            padding: 17px;
        }
    }
`;

export default ChartContainer;
