import { get } from 'lodash';
import {
    Unit,
    BalloonBase,
    BalloonMode,
    FinanceProductType,
    FinanceProductSubType,
    AverageMileageTableBase,
    MatchComputeSubtype,
    MatchCalculationMode,
    MatchFinanceProductType,
} from './schema';

class InternalError extends Error {}

const mapNodeName = (key, type, subType) => {
    let newKey = '';
    const mapping = {
        termSetting: 'term',
        downPaymentSetting: 'downPayment',
        loanSetting: 'loan',
        interestRateSetting: 'interestRate',
        balloonSetting: 'balloon',
        depositSetting: 'deposit',
    };

    if (mapping[key] !== undefined) {
        newKey = mapping[key];
    }

    if (
        key === 'balloonSetting' &&
        (type === FinanceProductType.LEASING ||
            (type === FinanceProductType.FINANCE && subType === FinanceProductSubType.AGILITY))
    ) {
        newKey = 'residualValue';
    }

    return newKey;
};

const mapType = (key, unit) => {
    const mapping = {
        termSetting: 'INTEGER',
        interestRateSetting: 'PERCENTAGE',
    };

    if (mapping[key] !== undefined) {
        return mapping[key];
    }

    return unit === Unit.PERCENTAGE ? 'PERCENTAGE' : 'CURRENCY';
};

const convert = product => {
    const detail = {
        nodes: {},
        meta: {},
    };

    // depend on product type, balloon can also represent residual value
    const keys = [
        'termSetting',
        'downPaymentSetting',
        'loanSetting',
        'balloonSetting',
        'interestRateSetting',
        'depositSetting',
    ];

    let useLeaseReferenceTable = false;
    keys.forEach(key => {
        const setting = product[key];

        if (!setting) {
            const nodeName = mapNodeName(key, product.type, product.subType);
            detail.nodes[nodeName] = { effective: false };
        } else {
            const { editable, default: defaultValue } = setting;

            const basedOn = key === 'balloonSetting' && setting?.basedOn === BalloonBase.LOAN ? 'Loan' : 'FinalPrice';

            const fixed = defaultValue
                ? {
                      type: mapType(key, setting?.defaultUnit),
                      value: defaultValue,
                  }
                : undefined;

            const range = {
                type: mapType(key, setting?.defaultUnit),
                max: setting?.max,
                min: setting?.min,
                step: setting?.step,
                default: setting?.default,
            };

            let table;
            if (setting?.table) {
                const keyTable = setting?.table;
                const values = keyTable?.values;

                const item = values[0];

                if (item) {
                    table = {
                        type: item.value > 100 ? 'CURRENCY' : 'PERCENTAGE',
                        entries: values,
                    };

                    if (key === 'depositSetting') {
                        table.type = 'INTEGER';
                    }

                    if (key === 'balloonSetting') {
                        table.taggedTo =
                            keyTable.settings?.basedOn === AverageMileageTableBase.MODEL ? 'Model' : 'Variant';
                    }

                    if (setting.balloonSetting?.mode === BalloonMode.LEASING) {
                        useLeaseReferenceTable = true;
                    }
                }
            }

            const nodeName = mapNodeName(key, product.type, product.subType);
            if (editable) {
                detail.nodes[nodeName] = {
                    effective: true,
                    source: 'range',
                    range,
                    basedOn,
                };
            } else if (setting?.table) {
                detail.nodes[nodeName] = {
                    effective: true,
                    source: 'table',
                    table,
                    basedOn,
                };
            } else if (fixed === undefined || fixed.value === 0) {
                detail.nodes[nodeName] = {
                    effective: false,
                };
            } else {
                detail.nodes[nodeName] = {
                    effective: true,
                    source: 'fixed',
                    fixed,
                    basedOn,
                };
            }
        }
    });

    if (
        useLeaseReferenceTable &&
        detail.nodes.residualValue !== undefined &&
        detail.nodes.residualValue.source === 'table'
    ) {
        detail.nodes.monthlyInstalment = {
            effective: true,
            source: 'table',
            table: detail.nodes.residualValue.table,
        };
        detail.nodes.residualValue = {
            effective: false,
        };
    }

    if (detail.nodes.downPayment.effective === true) {
        detail.nodes.downPayment.dominant = true;
    } else if (detail.nodes.loan.effective === true) {
        detail.nodes.loan.dominant = true;
    } else {
        throw new InternalError();
    }

    detail.meta.type = MatchFinanceProductType[product.type];
    detail.meta.subType = MatchComputeSubtype[product.subType];
    // Todo: paymentMode can from input
    detail.meta.paymentMode = product.paymentMode.mode;
    detail.meta.calculationMode = MatchCalculationMode[product.paymentMode.calculationMode];
    detail.meta.interestOnlyTerms = get(product, ['termSetting', 'interestOnly']);

    return { detail };
};

export default convert;
