import { isNil, isEmpty } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useCalculatorContext } from '../CalculatorContext';

const Container = styled.div`
    border-top: 1px solid #ced4da;
    margin-top: 30px;
    padding-top: 20px;
`;

const Name = styled.div`
    font-weight: bold;
    line-height: 1.5;
`;

const Description = styled.div`
    font-size: 0.93rem;
    line-height: 1.5;
`;

const FinanceProductDescription = ({ value }) => {
    const { financeProducts, contentTranslation } = useCalculatorContext();
    const { ct } = contentTranslation;

    const financeProduct = useMemo(() => {
        return financeProducts.find(
            product =>
                product.id === value &&
                // show based on selected language
                Object.prototype.hasOwnProperty.call(product.description, contentTranslation.language)
        );
    }, [financeProducts, value, contentTranslation.language]);

    if (!financeProduct) {
        return null;
    }

    const name = ct(financeProduct.name);
    const description = ct(financeProduct.description);

    if (isNil(description) || isEmpty(description)) {
        // nothing to display
        return null;
    }

    return (
        <Container>
            <Name>{name}</Name>
            <Description>{description}</Description>
        </Container>
    );
};

FinanceProductDescription.propTypes = {
    value: PropTypes.string.isRequired,
};

export default FinanceProductDescription;
