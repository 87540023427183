import PropTypes from 'prop-types';
import React from 'react';
import { animated, useSpring } from 'react-spring';

export const spring = {
    from: { opacity: 0, transform: 'scale3d(0.3, 0.3, 0.3)' },
    to: { opacity: 1, transform: 'scale3d(1, 1, 1)' },
};

const ZoomIn = ({ children }) => {
    const animation = useSpring(spring);

    return <animated.div style={animation}>{children}</animated.div>;
};

ZoomIn.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ZoomIn;
