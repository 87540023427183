import { v4 } from 'uuid';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const ROTATE_NOTIFICATION = 'ROTATE_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';

export const addNotification = (message, clearOnPageChange = false) => ({
    type: ADD_NOTIFICATION,
    payload: {
        id: v4(),
        message,
        clearOnPageChange,
    },
});

export const deleteNotification = id => ({ type: DELETE_NOTIFICATION, payload: id });

export const clearNotification = () => ({ type: CLEAR_NOTIFICATION });

export const rotateNotification = () => ({ type: ROTATE_NOTIFICATION });
