import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import { getCurrentCountry } from '../../../../../selectors';
import ApvPortlet from '../../../../template/Portlet';
import MainDetails from './MainDetails';
import { Context } from './context';
import validate from './validate';

const Form = props => {
    const { form } = props;
    const values = useSelector(getFormValues(form));

    const { currency, rounding } = useSelector(getCurrentCountry);

    const context = { ...props, values, currency, rounding };

    return (
        <Context.Provider value={context}>
            <ApvPortlet name="Main Details" closable open>
                <MainDetails />
            </ApvPortlet>
        </Context.Provider>
    );
};

Form.propTypes = {
    form: PropTypes.string.isRequired,
};

export default compose(reduxForm({ form: 'promo', validate }), memo)(Form);
