import {
    BalloonBase,
    BalloonMode,
    CalculationMode,
    DerivedMethod,
    DownPaymentMode,
    FinanceProductBase,
    InterestRateDisplayMode,
    InterestRateMode,
    Unit,
} from '../../../../schema';

const defaultValues = {
    dealerIds: [],
    csvDealerIds: [],
    basedOn: FinanceProductBase.DOWNPAYMENT,
    isActive: true,
    variants: [],
    finderVehicles: [],
    balloonSetting: {
        mode: BalloonMode.RANGE,
        basedOn: BalloonBase.CARPRICE,
        min: 0,
        max: 0,
        step: 0,
        default: 0,
        defaultUnit: Unit.PERCENTAGE,
        show: false,
        editable: false,
    },
    downPaymentSetting: {
        mode: DownPaymentMode.RANGE,
        min: 0,
        max: 0,
        step: 0,
        default: 0,
        defaultUnit: Unit.PERCENTAGE,
        show: false,
        editable: false,
    },
    interestRateSetting: {
        mode: InterestRateMode.FLAT,
        min: 0,
        max: 0,
        step: 0,
        default: 0,
        displayMode: InterestRateDisplayMode.NONE,
        editable: false,
    },
    paymentMode: {
        show: false,
        calculationMode: CalculationMode.FLAT,
        derivedMethod: DerivedMethod.FORMULA,
    },
    loanSetting: {
        min: 0,
        max: 0,
        step: 0,
        default: 0,
        defaultUnit: Unit.PERCENTAGE,
        show: false,
        editable: false,
    },
    termSetting: {
        min: 0,
        max: 0,
        step: 0,
        default: 0,
        show: false,
        editable: false,
    },
    structuredPayment: {
        min: 0,
        max: 0,
        step: 0,
        default: 0,
        defaultUnit: Unit.PERCENTAGE,
        show: false,
        editable: false,
    },
    tradeInSetting: {
        show: false,
        withLiability: false,
    },
    balloonGFVSetting: {
        marginOfFinance: {
            min: null,
            max: null,
            show: false,
        },
        settlementInstalmentOn: 0,
    },
};

export default defaultValues;
