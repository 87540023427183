import styled from 'styled-components';

const WrapperContainer = styled.div`
    width: 100%;

    label {
        display: block;
        color: #9c9c9c;
        font-size: 0.85rem;
        padding-top: 11px;
        margin: 0;
        padding-bottom: 9.5px;
        height: 35px;
        box-sizing: border-box;
    }
`;

export default WrapperContainer;
