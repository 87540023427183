import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { getCurrentCountry } from '../../../../../selectors';
import CheckboxField from '../../../../shared/form/CheckboxField';
import CurrencyField from '../../../../template/Currency';
import FieldContainer from '../../../../template/Field-container';
import ApvPortlet from '../../../../template/Portlet';
import { useFormContext } from './context';

const Prices = () => {
    const { disabled, values, change } = useFormContext();
    const { currency, zones, rounding } = useSelector(getCurrentCountry);
    const { ct } = useContentTranslation();
    const mandatories = get('mandatories', values);

    useEffect(() => {
        zones.forEach(zone => {
            if (!mandatories[zone.id]) {
                change(`prices.${zone.id}`, null);
            }
        });
    }, [mandatories, zones, change]);

    return (
        <ApvPortlet name="Vehicle Price" closable open>
            <div className="container-fluid">
                <div className="row">
                    {zones.map(zone => (
                        <div key={zone.id} className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label={ct(zone.name)}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <CheckboxField name={`mandatories.${zone.id}`} />
                                    <Field
                                        component={CurrencyField}
                                        disabled={disabled || !mandatories[zone.id]}
                                        name={`prices.${zone.id}`}
                                        precision={rounding.amount.count}
                                        prefix={currency}
                                    />
                                </div>
                            </FieldContainer>
                        </div>
                    ))}
                </div>
            </div>
        </ApvPortlet>
    );
};

export default Prices;
