import { isNil } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import WrapperContainer from './WrapperContainer';

const Wrapper = ({ label, name, children }) => (
    <WrapperContainer>
        {!isNil(label) && <label htmlFor={name}>{label}</label>}
        {children}
    </WrapperContainer>
);

Wrapper.propTypes = {
    children: PropTypes.node.isRequired,
    label: PropTypes.string,
    name: PropTypes.string,
};

export default Wrapper;
