import { isArray } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { getMonthlyInstallmentLabel } from '../../../utils';
import { useComparisonContext } from '../../ComparisonContext';
import MonthlyValue from '../../ui/MonthlyValue';
import { useFormatCurrency } from './CurrencyField';
import StaticField from './StaticField';

const render = formatCurrency => (item, index) => {
    return (
        <div key={index.toString()}>
            {formatCurrency(item.value)} <span> - {item.label} </span>
        </div>
    );
};

const MonthlyInstalmentField = ({ monthlyInstalment, interestOnlyTerms, paymentTerm, ...props }) => {
    // get formatting function
    const { formatCurrency } = useFormatCurrency();

    // we need to know if we are running comparison
    // and if there is any deferred product in any calculators
    const { hasDeferredProduct, active } = useComparisonContext();

    // compute value
    const value = useMemo(() => {
        if (isArray(monthlyInstalment)) {
            // get its fields and variants
            const labels = getMonthlyInstallmentLabel(interestOnlyTerms, paymentTerm);

            return (
                <MonthlyValue direction={active ? 'column' : 'row'}>
                    {monthlyInstalment
                        .map((item, index) => ({ value: item, label: labels[index] }))
                        .map(render(formatCurrency))}
                </MonthlyValue>
            );
        }

        return formatCurrency(monthlyInstalment);
    }, [monthlyInstalment, interestOnlyTerms, paymentTerm, formatCurrency, active]);

    const fieldProps = { ...props, value };

    // patch properties when there is deferred products
    if (hasDeferredProduct) {
        fieldProps.height = '80px !important';
    }

    return <StaticField {...fieldProps} />;
};

MonthlyInstalmentField.propTypes = {
    interestOnlyTerms: PropTypes.number,
    monthlyInstalment: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    paymentTerm: PropTypes.number,
};

export default MonthlyInstalmentField;
