import { useQuery } from '@apollo/client';
import { get } from 'lodash/fp';
import React, { useCallback, useEffect, useReducer, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FootBar, FootBarButton, FootContainer } from '../../../../containers/Layout';
import { useContentTranslation } from '../../../../i18n';
import { SortOrder } from '../../../../schema';
import { getCurrentCountry, getZoneId } from '../../../../selectors';
import { appointmentStatusOptions } from '../../../../shared/constants/options';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { cells, DealerSelectionDropDown, List, ListSearch } from '../../../ui/lists';
import useServerPaging from '../../../utilities/useServerPaging';
import { getSourceLabel } from '../../Workflow/Finance/lists/LeadList';
import { AppointmentDataFragment } from '../appointment.graphql';
import AppointmentDateFilter from './AppointmentDateFilter';
import AppointmentDownload from './AppointmentDownload';
import { getAppointments, GetAppointmentsQuery, GetAppointmentsQueryVariables } from './AppointmentList.graphql';
import { AppointmentListProvider } from './AppointmentListContext';
import { appointmentReducer } from './reducer';

export const sortToTable = (sort: SortOrder): string => (sort === SortOrder.ASC ? 'asc' : 'desc');
export const sortToEnum = (sort: 'asc' | 'desc') => (sort === 'asc' ? SortOrder.ASC : SortOrder.DESC);

const useColumns = (dealerIds: string[] | null) => {
    const formatDateTime = useFormatDateTime();
    const { formatPath } = useContentTranslation();

    return useMemo<any[]>(() => {
        return [
            {
                name: 'Appointment Date/ Time',
                id: 'date',
                renderCell: cells.renderDateTime('date', formatDateTime),
                hasSort: true,
                filter: <AppointmentDateFilter />,
                styles: {
                    paddingRight: '60px',
                },
            },
            {
                name: 'Appt ID',
                id: 'identifier',
                renderCell: get('identifier'),
                underline: true,
                hasSort: true,
            },
            dealerIds?.length && {
                name: 'Dealer',
                id: 'latestApplication.dealer.name',
                renderCell: get(formatPath('latestApplication.dealer.name')),
                hasSort: true,
            },
            {
                name: 'Assigned to',
                id: 'assignee.name',
                renderCell: get('assignee.name'),
                hasSort: true,
            },
            {
                name: 'Customer',
                id: 'latestApplication.customer.name.value',
                renderCell: get('latestApplication.customer.name.value'),
                hasSort: true,
                sortKey: 'customer.name.value',
            },
            {
                name: 'Variant',
                id: 'latestApplication.variant.name',
                renderCell: get(formatPath('latestApplication.variant.name')),
                hasSort: true,
                sortKey: formatPath('variant.name'),
            },
            {
                name: 'Status',
                id: 'status',
                renderCell: (item: AppointmentDataFragment) =>
                    appointmentStatusOptions.find(option => option.value === item.status)?.label,
                hasSort: true,
            },
            {
                name: 'Last Activity',
                id: 'version.updatedAt',
                renderCell: cells.renderDateTime('version.updatedAt', formatDateTime),
                hasSort: true,
            },
            {
                name: 'Channel',
                id: 'latestApplication.channel',
                renderCell: (item: AppointmentDataFragment) => {
                    const channel = get('latestApplication.channel')(item);
                    const access = get('latestApplication.access')(item);

                    return getSourceLabel(channel, access);
                },
                hasSort: true,
                sortKey: 'application.channel',
            },
            {
                name: 'Event name',
                id: 'latestApplication.event.name',
                renderCell: get('latestApplication.event.name'),
                hasSort: true,
                sortKey: 'event.name',
            },
        ].filter(Boolean);
    }, [dealerIds, formatDateTime, formatPath]);
};

export const getSortingOrderLabel = (order: SortOrder) => {
    switch (order) {
        case SortOrder.DESC:
            return 'DESC';

        default:
            return 'ASC';
    }
};

const AppointmentList = () => {
    const history = useHistory();

    const zoneId = useSelector(getZoneId);
    const country = useSelector(getCurrentCountry);

    const [stateReducer, dispatch] = useReducer(appointmentReducer, {
        dealerIds: null,
        sortedOn: {
            type: 'identifier',
            order: SortOrder.DESC,
        },
    });

    const { dealerIds, search, sortedOn, startAppointmentDate, endAppointmentDate } = stateReducer;

    const [paging] = useServerPaging({ search, filter: { startAppointmentDate, endAppointmentDate } });

    const [downloadModal, setDownloadModal] = useState<null | JSX.Element>(null);

    const { data, loading, error } = useQuery<GetAppointmentsQuery, GetAppointmentsQueryVariables>(getAppointments, {
        fetchPolicy: 'cache-and-network',
        variables: {
            filter: {
                dealerIds,
                zoneId,
                countryId: country?.id,
                startAppointmentDate,
                endAppointmentDate,
            },
            sorting: [
                {
                    type: sortedOn.type,
                    // @ts-ignore
                    order: getSortingOrderLabel(sortedOn.order),
                },
            ],
            search,
            paging: {
                limit: paging.pageSize,
                offset: (paging.page - 1) * paging.pageSize,
            },
        },
    });

    useEffect(() => {
        paging.setItemCount(data?.appointments?.count || 0);
    }, [paging, data]);

    const onDealerChange = useCallback((newValues: string[]) => {
        dispatch({ type: 'SET_DEALER', payload: newValues });
    }, []);

    const onSort = useCallback((newSorts: [string, 'asc' | 'desc']) => {
        dispatch({ type: 'SET_SORT', payload: [newSorts[0], sortToEnum(newSorts[1])] });
    }, []);

    const onSearch = useCallback((newSearch: string) => {
        dispatch({ type: 'SET_SEARCH', payload: newSearch });
    }, []);

    const onItemClick = useCallback(
        (item: AppointmentDataFragment) => {
            history.push(`/appointment/${item.version.id}`);
        },
        [history]
    );

    const closeDownloadModal = useCallback(() => setDownloadModal(null), [setDownloadModal]);

    const openDownloadModal = useCallback(
        () => setDownloadModal(<AppointmentDownload dealerIds={dealerIds ?? []} onClose={closeDownloadModal} />),
        [setDownloadModal, dealerIds, closeDownloadModal]
    );

    const columns = useColumns(dealerIds);

    const items = data?.appointments?.items || [];

    return (
        <AppointmentListProvider dispatch={dispatch} state={stateReducer}>
            <List
                columns={columns}
                error={error}
                footer={
                    <FootContainer>
                        <FootBar>
                            <FootBarButton onClick={openDownloadModal}>download</FootBarButton>
                        </FootBar>
                    </FootContainer>
                }
                headerBottomComponent={
                    <DealerSelectionDropDown dealerIds={dealerIds} onValueChanged={onDealerChange} />
                }
                headerLeftComponent={<ListSearch initialValue={search || ''} onSubmit={onSearch} />}
                items={items}
                loading={loading && items.length <= 0}
                onItemClick={onItemClick}
                onSort={onSort}
                paging={paging}
                sortedOn={[sortedOn.type, sortToTable(sortedOn.order)]}
                title="Appointments"
            />
            {downloadModal}
        </AppointmentListProvider>
    );
};

export default AppointmentList;
