/* eslint-disable no-underscore-dangle */
import {
    OutlineError,
    OutlineWrapperV2 as OutlineWrapper,
    OutlineSelectV2 as OutlineSelect,
    // @ts-ignore
} from '@appvantageasia/afc-ui';
import { format, addDays, isValid, setHours, setMinutes } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, WrappedFieldProps } from 'redux-form';
import { getCurrentZone, getRuntimeSettings } from '../../../../../selectors';
import { toDate } from '../../../../../utilities/fp';
import { convertLocalToUTC } from '../../../../../utils/timezones';
import { DatePickerInput } from '../../../../shared/form-v2/DatePickerField';
import { SelectInput } from '../../../../shared/form-v2/SelectField';
import { useAppointmentFormContext } from './context';

// intentionally lowercase it, to reduce error inputs
const mapDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

type AppointmentDateTimePureProps = {
    name: string;
};

type AppointmentDateTimeProps = WrappedFieldProps & AppointmentDateTimePureProps;

const setHoursAndMinutes = (date: Date, hours: number, minutes: number) => setMinutes(setHours(date, hours), minutes);

const AppointmentDateTime = ({ input, meta }: AppointmentDateTimeProps) => {
    const { disabled, reservedAppointments } = useAppointmentFormContext();

    const zone = useSelector(getCurrentZone);
    const { appointment: appointmentSetting } = useSelector(getRuntimeSettings);

    const [appointmentDate, setAppointmentDate] = useState(() =>
        zone ? utcToZonedTime(toDate(input.value), zone.timezone) : input.value
    );
    const [appointmentTime, setAppointmentTime] = useState(() => format(toDate(appointmentDate), 'HH:mm'));

    // this was fallback, if environment setting was not found
    const { slotHourEnd = 24, slotHourStart = 0, slotMinuteInterval = 15 } = appointmentSetting;

    const getUtcDateString = useCallback(
        (newDate: string | Date, newHour: string, newMinute: string) => {
            const convertedDate = toDate(newDate).setHours(parseInt(newHour, 10), parseInt(newMinute, 10), 0, 0);

            const usedDate = new Date(convertedDate);
            const utcDateString = zone?.timezone ? convertLocalToUTC(usedDate, zone.timezone) : usedDate.toISOString();

            return utcDateString;
        },
        [zone]
    );

    const onDateChange = useCallback(
        (value: string) => {
            if (!isValid(value)) {
                return;
            }

            const [hour, minute] = appointmentTime.split(':');
            const utcDateString = getUtcDateString(value, hour, minute);

            setAppointmentDate(value);
            input.onChange(utcDateString);
        },
        [appointmentTime, getUtcDateString, input]
    );

    const onTimeChange = useCallback(
        (value: string) => {
            const [hour, minute] = value.split(':');

            const utcDateString = getUtcDateString(appointmentDate, hour, minute);

            setAppointmentTime(value);
            input.onChange(utcDateString);
        },
        [getUtcDateString, appointmentDate, input]
    );

    const allowedDays = useMemo(
        () => appointmentSetting?.availabilityDays?.map((day: string) => mapDays.indexOf(day.toLowerCase())) ?? [],
        [appointmentSetting]
    );

    const timeOptions = useMemo(() => {
        const currentDateString = format(toDate(appointmentDate), 'yyyy-MM-dd');
        const blockedTimes = reservedAppointments.find(item => item.date === currentDateString)?.times ?? [];
        const availableTimesFromSetting = appointmentSetting?.availabilityTimes ?? [];

        // make an array of hours and minutes for time picker options with label and value
        return Array.from({ length: (slotHourEnd - slotHourStart) * (60 / slotMinuteInterval) }, (_, i) => {
            const hour = slotHourStart + Math.floor((i * slotMinuteInterval) / 60);
            const minute = (i * slotMinuteInterval) % 60;

            const label = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
            const value = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

            if (
                blockedTimes.includes(value) ||
                (availableTimesFromSetting?.length > 0 && !availableTimesFromSetting.includes(value))
            ) {
                return { label: '', value: '' };
            }

            return { label, value };
        }).filter(item => item.label !== '');
    }, [appointmentDate, appointmentSetting, reservedAppointments, slotHourEnd, slotHourStart, slotMinuteInterval]);

    return (
        <>
            <div className="col-md-4 col-sm-12 col-xs-12">
                <DatePickerInput
                    // @ts-ignore
                    allowedDays={allowedDays}
                    disabled={disabled}
                    input={{
                        value: appointmentDate,
                        onChange: onDateChange,
                    }}
                    label="Appointment Date"
                    max={
                        appointmentSetting.slotMaxDays
                            ? addDays(setHoursAndMinutes(new Date(), 23, 59), appointmentSetting.slotMaxDays ?? 0)
                            : undefined
                    }
                    meta={meta}
                    min={addDays(setHoursAndMinutes(new Date(), 0, 0), appointmentSetting.slotMinDays ?? 0)}
                />
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12">
                <SelectInput
                    disabled={disabled}
                    errorComponent={OutlineError}
                    input={{
                        value: appointmentTime,
                        onChange: onTimeChange,
                    }}
                    label="Appointment Time"
                    meta={{
                        ...meta,
                        active: meta.active ?? false,
                    }}
                    options={timeOptions ?? []}
                    selectComponent={OutlineSelect}
                    wrapperComponent={OutlineWrapper}
                />
            </div>
        </>
    );
};

const FieldAppointmentDateTime = (props: AppointmentDateTimePureProps) => (
    <Field component={AppointmentDateTime} {...props} />
);

export default FieldAppointmentDateTime;
