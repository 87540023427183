import { getOr } from 'lodash/fp';
import React, { Fragment, useContext } from 'react';
import { ReduxFormContext } from 'redux-form';
import { BankPresetOption } from '../../../../../schema';
import { formatCurrency } from '../../../../../utils/numberHelpers';
import { useCompanyFormatting } from '../../../../../utils/withCompanyFormatting';
import SelectField from '../../../../shared/form-v2/SelectField';
import TextField from '../../../../shared/form-v2/TextField';
import SubTitle from '../../../../ui/SubTitle';
import { useCustomerOptions } from '../../useOptions';

const QuotationDetails = () => {
    const { getValues, sectionPrefix } = useContext(ReduxFormContext);

    const customerOptions = useCustomerOptions();

    const { currencySymbol } = useCompanyFormatting();

    const formValues = getValues();
    const bankPresetOption = getOr(null, 'financeProduct.bank.presetOption', formValues);

    const isEnbdBankPreset = bankPresetOption === BankPresetOption.ENBDBANK;

    if (!isEnbdBankPreset) {
        return null;
    }

    const carOptions = getOr([], `${sectionPrefix}.details.quotation.options`, formValues);

    return (
        <>
            <SubTitle>Quotation Details</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField label="Full Name on Quotation" name="details.quotation.fullName.value" disabled />
                </div>

                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField label="Commission No" name="details.quotation.commissionNo.value" disabled />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField label="Engine No" name="details.quotation.engineNo.value" disabled />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField label="Chassis No" name="details.quotation.chassisNo.value" disabled />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField label="Exterior Color" name="details.quotation.exteriorColor.value" disabled />
                </div>
                {carOptions?.map((option: any, index: number) => (
                    <Fragment key={index.toString()}>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField
                                label={`Option Description ${index + 1}`}
                                name={`details.quotation.options[${index}].description.value`}
                                disabled
                            />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <SelectField.Outline
                                label={`Option VAT  ${index + 1}`}
                                name={`details.quotation.options[${index}].isVatIncluded`}
                                options={customerOptions?.dealerVatOptions ?? []}
                                disabled
                            />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField
                                fixedValue={formatCurrency(2, currencySymbol)(option.price)}
                                label={`Option Price (Exc. VAT) ${index + 1}`}
                                name={`details.quotation.options[${index}].price`}
                                disabled
                            />
                        </div>
                    </Fragment>
                ))}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label="Downpayment To"
                        name="details.quotation.downpaymentTo.value"
                        options={customerOptions?.downpaymentTo ?? []}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField label="Company Name" name="details.quotation.companyName.value" disabled />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField label="F&I Manager" name="details.quotation.financeInsuranceManager.value" disabled />
                </div>
            </div>
        </>
    );
};

export default QuotationDetails;
