import { useQuery } from '@apollo/client';
import { faPlus, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flow, get, orderBy, isNil } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import { FootBar, FootBarButton, FootContainer } from '../../../../containers/Layout';
import {
    getCurrentCountry,
    getUser,
    getCompanyCode,
    getCountryCode,
    getZoneCode,
    getRuntimeSettings,
    getDefaultZoneCode,
} from '../../../../selectors';
import { searchOnKeys } from '../../../../utilities/fp';
import * as core from '../../../../utils/permissions';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { List, cells, ListSearch, DealerSelectionDropDown } from '../../../ui/lists';
import usePaging from '../../../utilities/usePaging';
import { getTables } from './EventList.graphql';

const searchFields = ['identifier', 'description', 'value', 'name'];

const EventUrlCell = styled.div`
    text-align: center;
`;
const inactiveColor = '#919099';
const isExpired = flow([get('period.end'), it => new Date(it) < new Date()]);
const isExternalLinkClickable = item => !isExpired(item) && get('isActive', item);

export const renderStatus = item => {
    const clickable = isExternalLinkClickable(item);

    return (
        <EventUrlCell>
            <FontAwesomeIcon
                color={clickable ? 'initial' : inactiveColor}
                cursor={clickable ? 'pointer' : 'not-allowed'}
                icon={faExternalLinkAlt}
            />
        </EventUrlCell>
    );
};

const Highlight = styled.span`
    ${props =>
        props.expired &&
        css`
            color: #d5001c;
        `}
`;

const renderHighlightedDate = (key, formatDateTime) => item => (
    <Highlight expired={isExpired(item)}>{cells.renderDateTime(key, formatDateTime)(item)}</Highlight>
);

const useColumns = () => {
    const formatDateTime = useFormatDateTime();

    const activeZoneCode = useSelector(getZoneCode);
    const defaultZoneCode = useSelector(getDefaultZoneCode);
    // get default zone when active zone is all
    const zoneCode = activeZoneCode === 'All' ? defaultZoneCode : activeZoneCode;

    const countryCode = useSelector(getCountryCode);
    const companyCode = useSelector(getCompanyCode);
    const countryZoneCode = zoneCode === countryCode ? zoneCode : `${countryCode}-${zoneCode}`;

    // get CI base link
    const { ciBaseLink } = useSelector(getRuntimeSettings);
    const urlPrefix = `${ciBaseLink}/${companyCode}/${countryZoneCode}/event/`;

    return useMemo(
        () => [
            { name: 'Event ID', id: 'identifier', renderCell: get('identifier'), hasSort: true, underline: true },
            { name: 'Event Name', id: 'name', renderCell: get('name'), hasSort: true },
            {
                name: 'Start Date',
                id: 'period.start',
                renderCell: renderHighlightedDate('period.start', formatDateTime),
                hasSort: true,
            },
            {
                name: 'End Date',
                id: 'period.end',
                renderCell: renderHighlightedDate('period.end', formatDateTime),
                hasSort: true,
            },
            {
                name: 'Payment',
                id: 'setting.isDepositPaymentMandatory',
                renderCell: cells.renderActive('setting.isDepositPaymentMandatory'),
                hasSort: true,
            },
            {
                name: 'URL',
                id: 'url',
                // function instead of string to sort based on true/false
                sortKey: {
                    id: 'url',
                    sort: item => !(!isExpired(item) && get('isActive', item)),
                },
                renderCell: renderStatus,
                hasSort: true,
                onClick: (event, item) => {
                    event.stopPropagation();

                    if (isExternalLinkClickable(item)) {
                        window.open(urlPrefix + item.id, '_blank');
                    }
                },
            },
            { name: 'Active', id: 'isActive', renderCell: cells.renderActive(), hasSort: true },
        ],
        [formatDateTime, urlPrefix]
    );
};

const sortItems = (items, [sortKey, sortOrder]) => {
    // sort by sortKey if manual sort function is not defined
    return orderBy(sortKey.sort || sortKey, sortOrder, items);
};

const EventList = () => {
    const history = useHistory();

    const currentCountry = useSelector(getCurrentCountry);
    const [dealerIds, setDealerIds] = useState(null);
    const { id } = currentCountry;
    const variables = { id, dealerIds };
    const { data, loading, error } = useQuery(getTables, {
        fetchPolicy: 'cache-and-network',
        variables,
        skip: isNil(dealerIds),
    });
    const items = data?.results?.items || [];
    const isLoading = loading && items.length <= 0;

    const user = useSelector(getUser);
    const mayManageEventChannel = useMemo(() => core.mayManageEventChannel(user, currentCountry), [
        currentCountry,
        user,
    ]);

    // searching
    const [search, setSearch] = useState('');
    const searchMethod = useMemo(() => searchOnKeys(searchFields, items), [items]);
    const matchedItems = useMemo(() => searchMethod(search), [search, searchMethod]);

    // sorting
    const [sortedOn, onSort] = useState(['code', 'asc']);
    const sortedItems = useMemo(() => sortItems(matchedItems, sortedOn), [matchedItems, sortedOn]);

    // paging
    const [pagedItems, paging] = usePaging(sortedItems, { search });

    const columns = useColumns();

    const footer = (
        <FootContainer>
            <FootBar>
                {mayManageEventChannel && (
                    <FootBarButton onClick={() => history.push('/event/new')}>
                        <FontAwesomeIcon icon={faPlus} /> ADD Event
                    </FootBarButton>
                )}
            </FootBar>
        </FootContainer>
    );

    return (
        <List
            columns={columns}
            error={error}
            footer={footer}
            headerBottomComponent={<DealerSelectionDropDown dealerIds={dealerIds} onValueChanged={setDealerIds} />}
            headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
            items={pagedItems}
            loading={isLoading}
            onItemClick={item => history.push(`/event/${item.id}`)}
            onSort={onSort}
            paging={paging}
            sortedOn={sortedOn}
            title="Events"
        />
    );
};

export default EventList;
