import { get, find, flow, size, lt } from 'lodash/fp';
import BankField from '../components/fields/display/BankField';
import UpdateOptions from '../components/fields/edition/UpdateOption';
import { weightFieldAfter } from '../utils';
import fieldTemplate from './fieldTemplate';

const hasManyBanks = flow([get('banks'), size, lt(1)]);

const findBank = id => flow([get('banks'), find(bank => get('id', bank) === id), get('id')]);

const bankField = {
    // copy template
    ...fieldTemplate,

    // setup meta
    label: t => t('calculator.label.bank'),
    weight: weightFieldAfter('carOptions'),

    // setup states
    isViewable: hasManyBanks,
    isEditable: hasManyBanks,
    // cannot select the bank as long as the variant is invalid
    isInvalid: ({ isInvalidVariant }) => isInvalidVariant,

    // setup display rendering
    displayComponent: BankField,
    displayEmptyComponent: BankField,

    // setup edition rendering
    editionComponent: UpdateOptions,
    getEditionProperties: ({ banks }) => ({
        options: banks.map(({ name, id }) => ({ value: id, label: name })),
    }),

    // setup value management
    getInitialValue: (values, context) => get('banks.0.id', context) || get('fallbackFinanceProduct.bankId', context),
    updates: {
        // reset the bank whenever the variant changed
        carModel: ({ bank }, context) => findBank(bank)(context),
    },

    // snpashot settings
    snapshot: {
        ...fieldTemplate.snapshot,
        component: BankField,
    },
};

export default bankField;
