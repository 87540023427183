import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { getRangeOptions } from '../../../utils';
import UpdateNumeric from './UpdateNumeric';
import UpdateOptions from './UpdateOption';

const UpdatePaymentTerm = ({ termSetting, ...props }) => {
    const { t } = useTranslation();
    const Component = termSetting.step === 1 ? UpdateNumeric : UpdateOptions;

    const otherProps = useMemo(() => {
        const { step, min, max } = termSetting;

        if (step === 1) {
            const minErrorMessage = t('calculator.error.paymentTermMin', { min });
            const validation = yup
                .number()
                .typeError(minErrorMessage)
                .min(min, minErrorMessage)
                .max(max, t('calculator.error.paymentTermMax', { max }))
                .required(minErrorMessage);

            return { min, max, step, validation };
        }

        return {
            options: getRangeOptions(min, max, step),
            size: 4,
            smallSize: 3,
        };
    }, [termSetting, t]);

    return <Component {...props} {...otherProps} />;
};

UpdatePaymentTerm.propTypes = {
    termSetting: PropTypes.shape({
        max: PropTypes.number.isRequired,
        min: PropTypes.number.isRequired,
        step: PropTypes.number.isRequired,
    }).isRequired,
};

export default UpdatePaymentTerm;
