import { get } from 'lodash/fp';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, ReduxFormContext, autofill } from 'redux-form';
import { PaymentProviderType, Rounding } from '../../../../../schema';
import { paymentProviderOptions } from '../../../../../shared/constants/options';
import CurrencyField from '../../../../template/Currency';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import KeyField from '../../../../template/KeyField';
import { Tips } from '../../../../ui/form/File';
import { GetDataQuery } from '../../country/Edition.graphql';
import { getHash } from './MyInfoIntegration';

export type PaymentIntegrationProps = {
    currency: string;
    disabled?: boolean;
    rounding: Rounding;
    settings: GetDataQuery['settings'];
};

const AdyenPaymentIntegration = ({ disabled = false }: PaymentIntegrationProps) => (
    <>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label="Merchant Account ID">
                <Field
                    component={InputField}
                    disabled={disabled}
                    name="bookingPayment.provider.settings.merchantAccount"
                />
            </FieldContainer>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label="API Key">
                <Field component={InputField} disabled={disabled} name="bookingPayment.provider.settings.apiKey" />
            </FieldContainer>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label="Client Key">
                <Field component={InputField} disabled={disabled} name="bookingPayment.provider.settings.clientKey" />
            </FieldContainer>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label="Origin Key">
                <Field component={InputField} disabled={disabled} name="bookingPayment.provider.settings.originKey" />
            </FieldContainer>
        </div>
    </>
);

const PorschePaymentIntegration = ({ settings }: PaymentIntegrationProps) => (
    <>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label="API Key">
                <Field
                    component={InputField}
                    fixedValue={settings?.porschePaymentConfiguration.apiKey}
                    name="__exclude.porschePaymentConfiguration.apiKey"
                    disabled
                />
            </FieldContainer>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label="Secret">
                <Field
                    component={InputField}
                    fixedValue={settings?.porschePaymentConfiguration.secret}
                    name="__exclude.porschePaymentConfiguration.secret"
                    disabled
                />
            </FieldContainer>
        </div>
    </>
);

const PayGatePaymentIntegration = ({ disabled = false }: PaymentIntegrationProps) => (
    <>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label="PayGate ID">
                <Field component={InputField} disabled={disabled} name="bookingPayment.provider.settings.apiKey" />
            </FieldContainer>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label="Encryption Key">
                <Field
                    component={InputField}
                    disabled={disabled}
                    name="bookingPayment.provider.settings.encryptionKey"
                />
            </FieldContainer>
        </div>
    </>
);

const TTBPaymentIntegration = ({ disabled = false }: PaymentIntegrationProps) => (
    <>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label="TTB API Key">
                <Field component={InputField} disabled={disabled} name="bookingPayment.provider.settings.apiKey" />
            </FieldContainer>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label="TTB Office Id">
                <Field component={InputField} disabled={disabled} name="bookingPayment.provider.settings.officeId" />
            </FieldContainer>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label="Signing Private Key">
                <Field
                    component={KeyField}
                    disabled={disabled}
                    hashFun={getHash}
                    name="bookingPayment.provider.settings.signingPrivateKey"
                />
            </FieldContainer>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label="Encryption Private Key">
                <Field
                    component={KeyField}
                    disabled={disabled}
                    hashFun={getHash}
                    name="bookingPayment.provider.settings.encryptionPrivateKey"
                />
            </FieldContainer>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label="Signing Public Key">
                <Field
                    component={KeyField}
                    disabled={disabled}
                    hashFun={getHash}
                    name="bookingPayment.provider.settings.signingPublicKey"
                />
            </FieldContainer>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label="Encryption Public Key">
                <Field
                    component={KeyField}
                    disabled={disabled}
                    hashFun={getHash}
                    name="bookingPayment.provider.settings.encryptionPublicKey"
                />
            </FieldContainer>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label="KID">
                <Field component={InputField} disabled={disabled} name="bookingPayment.provider.settings.kid" />
            </FieldContainer>
        </div>
    </>
);

const PaymentIntegration = (props: PaymentIntegrationProps) => {
    const { rounding, currency, disabled = false, settings } = props;
    // setup form context
    const { sectionPrefix, form } = useContext(ReduxFormContext);
    // retrieve form values
    const values = useSelector(getFormValues(form));
    // get payment provider path
    const providerPath = useMemo(
        () => (sectionPrefix ? `${sectionPrefix}.bookingPayment.provider` : 'bookingPayment.provider'),
        [sectionPrefix]
    );
    const providerType = get(`${providerPath}.type`, values);
    const dispatch = useDispatch();

    useEffect(() => {
        if (providerType === PaymentProviderType.PORSCHE) {
            // remove following fields for porsche payment
            dispatch(autofill(form, `${providerPath}.settings.apiKey`, undefined));
            dispatch(autofill(form, `${providerPath}.settings.clientKey`, undefined));
            dispatch(autofill(form, `${providerPath}.settings.originKey`, undefined));
            // remove following fields for pay gate
            dispatch(autofill(form, `${providerPath}.settings.encryptionKey`, undefined));
        }
    }, [dispatch, form, providerPath, providerType]);

    const renderProviderSettings = useCallback(() => {
        switch (providerType) {
            case PaymentProviderType.ADYEN:
                return <AdyenPaymentIntegration {...props} />;

            case PaymentProviderType.PORSCHE:
                return <PorschePaymentIntegration {...props} />;

            case PaymentProviderType.PAYGATE:
                return <PayGatePaymentIntegration {...props} />;

            case PaymentProviderType.TTB:
                return <TTBPaymentIntegration {...props} />;

            default:
                return null;
        }
    }, [props, providerType]);

    return (
        <>
            <hr />
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Payment Amount">
                        <Field
                            component={CurrencyField}
                            disabled={disabled}
                            name="bookingPayment.amount"
                            precision={rounding?.amount?.count}
                            prefix={currency}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DropdownField
                        disabled={disabled}
                        label="Payment Gateway"
                        name="bookingPayment.provider.type"
                        options={paymentProviderOptions}
                        placeholder="Select Payment Gateway"
                    />
                </div>
                {renderProviderSettings()}
            </div>
            {providerType === PaymentProviderType.PORSCHE && <Tips>Set up Assortment in Dealer</Tips>}
        </>
    );
};

export default PaymentIntegration;
