import { get } from 'lodash/fp';
import React, { useRef, useEffect } from 'react';
import { Field } from 'redux-form';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import { useFormContext } from './context';

const InsuranceIntegration = () => {
    const { values, change } = useFormContext();
    const integration = get('integration', values);
    const hasIntegration = get('hasIntegration', values);

    // use a reference to avoid triggering the effect
    const previousIntegration = useRef(integration);
    previousIntegration.current = integration;

    useEffect(() => {
        if (!hasIntegration) {
            change('integration', null);
        }
    }, [hasIntegration, previousIntegration, change]);

    if (!hasIntegration) {
        return null;
    }

    return (
        <>
            <hr />
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Submission URL">
                        <Field component={InputField} name="integration.submission" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Resubmission URL">
                        <Field component={InputField} name="integration.resubmission" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Cancellation URL">
                        <Field component={InputField} name="integration.cancellation" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Quick Quote URL">
                        <Field component={InputField} name="integration.quickQuote" />
                    </FieldContainer>
                </div>
            </div>
        </>
    );
};

export default InsuranceIntegration;
