import PropTypes from 'prop-types';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import styled from 'styled-components';

export type ErrorContextData = [string | null, (error: string | null) => void];

export const ErrorContext = createContext<ErrorContextData | null>(null);

export const useErrorContext = () => {
    const context = useContext(ErrorContext);

    if (!context) {
        throw new Error('Error context not provided');
    }

    return context;
};

export type GlobalErrorProviderProps = {
    children: JSX.Element | ReactNode;
};

export const GlobalErrorProvider = ({ children }: GlobalErrorProviderProps) => {
    const state = useState<string | null>(null);

    return <ErrorContext.Provider value={state}>{children}</ErrorContext.Provider>;
};

GlobalErrorProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

const ErrorBlock = styled.div`
    color: #ff0000;
    padding: 0 30px 10px 30px;
    background-color: black;
    flex: initial;
    height: 25px;
`;

export const GlobalError = () => {
    const [error] = useErrorContext();

    return error && <ErrorBlock>{error}</ErrorBlock>;
};
