import { trim, isNumber, isString, split } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import styled from 'styled-components';
import { ErrorMessageDiv } from '../../containers/Layout';

const durationRegex = /^(?<number>[0-9]+)(?<unit>[wdhm]?)$/;

const UNITS = ['w', 'd', 'h', 'm'];

const UNIT_DURATIONS = {
    w: 7 * 24 * 60 * 60,
    d: 24 * 60 * 60,
    h: 60 * 60,
    m: 60,
};

const invalidFormatError = 'You should use format `2w 4d 6h 45m` to set time';

const parse = value => {
    // get the parts and trim those
    const parts = split(' ', value).map(trim).filter(Boolean);

    if (!parts.length) {
        // nothing in
        return 0;
    }

    // list unit which we already met
    const metUnits = [];

    let result = 0;

    // check and analyze each part
    for (const part of parts) {
        // check each part
        const match = part.match(durationRegex);

        if (!match || metUnits.length === UNITS.length) {
            // invalid format
            return invalidFormatError;
        }

        const { unit: matchedUnit, number } = match.groups;

        // it may be an empty string
        const unit = matchedUnit || UNITS[metUnits.length];

        if (!UNITS.includes(unit)) {
            // invalid format
            return invalidFormatError;
        }

        // parse the number
        const parsedNumber = parseInt(number, 10);
        // add it to results
        result += parsedNumber * UNIT_DURATIONS[unit];

        // register the unit
        metUnits.push(unit);
    }

    return result;
};

const format = value => {
    if (!isNumber(value)) {
        return '';
    }

    // get weeks
    const weeks = Math.floor(value / UNIT_DURATIONS.w);
    let remaining = value % UNIT_DURATIONS.w;

    // get days
    const days = Math.floor(remaining / UNIT_DURATIONS.d);
    remaining = value % UNIT_DURATIONS.d;

    // get hours
    const hours = Math.floor(remaining / UNIT_DURATIONS.h);
    remaining = value % UNIT_DURATIONS.h;

    // and minutes
    const minutes = Math.floor(remaining / UNIT_DURATIONS.m);

    return [
        [weeks, 'w'],
        [days, 'd'],
        [hours, 'h'],
        [minutes, 'm'],
    ]
        .filter(item => item[0])
        .flatMap(item => item.join(''))
        .join(' ');
};

class TimeDuration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0,
            formattedValue: null,
            error: null,
        };
    }

    static getDerivedStateFromProps({ input, meta }) {
        const { value } = input;

        if (meta.active) {
            // do not do updates on active fields
            return {};
        }

        return {
            formattedValue: format(value),
            value,
        };
    }

    onChange = event => {
        const { input } = this.props;
        const { value } = event.target;
        const parsedValue = parse(value);

        if (isString(parsedValue)) {
            this.setState({ error: parsedValue, formattedValue: value });
            input.onChange(null);
        } else {
            this.setState({ error: null, value: parsedValue, formattedValue: value });
            input.onChange(parsedValue);
        }
    };

    onFocus = () => {
        const { input } = this.props;
        const { value } = this.state;

        input.onFocus(value);
    };

    onBlur = () => {
        const { input } = this.props;
        const { value } = this.state;

        this.setState({ formattedValue: format(value) });
        input.onBlur(value);
    };

    render() {
        const { className, input, meta, disabled } = this.props;
        const { error: stateError, formattedValue } = this.state;
        const { touched, active, error: formError } = meta;
        const error = formError || stateError;

        return (
            <div className={className}>
                <input
                    disabled={disabled}
                    name={input.name}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    type="text"
                    value={formattedValue}
                />
                {active && (
                    <div className="note-box">
                        Use this format: 2w 4d 6h 45m
                        <ul>
                            <li>w = week</li>
                            <li>d = day</li>
                            <li>h = hour</li>
                            <li>m = minute</li>
                        </ul>
                    </div>
                )}
                <ErrorMessageDiv>{touched && !active && error}</ErrorMessageDiv>
            </div>
        );
    }
}

TimeDuration.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};

export default styled(TimeDuration)`
    width: 100%;
    position: relative;

    input {
        width: 100%;
        border: 0;
        color: #000000;
        background: #f0f0f0;
        height: 30px;
        font-size: 0.85rem;
        padding: 0 10px;
        text-align: ${props => props.textAlign};
    }

    input:disabled {
        background: #d8d8d8;
    }

    .note-box {
        position: absolute;
        top: 35px;
        left: 0;
        z-index: 99;
        background: #232f4d;
        border-radius: 5px;
        padding: 10px;

        ul {
            display: block;
            list-style-type: disc;
            padding-inline-start: 17px;
            margin-bottom: 0;
        }
    }
`;
