import { SortOrder } from '../../../../schema';

export type SortState = {
    type: string;
    order: SortOrder;
};

export enum FilterDateRange {
    NONE = 'none',
    TODAY = 'today',
    WEEK = 'week',
    MONTH = 'month',
}

export type AppointmentState = {
    dealerIds: string[] | null;
    sortedOn: SortState;
    search?: string;
    startAppointmentDate?: Date;
    endAppointmentDate?: Date;
    dateRangeId?: FilterDateRange;
};

export type SetDealerAction = {
    type: 'SET_DEALER';
    payload: string[];
};

export type SetSearchAction = {
    type: 'SET_SEARCH';
    payload: string;
};

export type SetSortAction = {
    type: 'SET_SORT';
    payload: [string, SortOrder];
};

type DateOrUndefined = Date | undefined;

export type SetDateRangeAction = {
    type: 'SET_DATE_RANGE';
    payload: [DateOrUndefined, DateOrUndefined, FilterDateRange];
};

export type AppointmentAction = SetDealerAction | SetSearchAction | SetSortAction | SetDateRangeAction;

export const appointmentReducer = (state: AppointmentState, action: AppointmentAction) => {
    switch (action.type) {
        case 'SET_DEALER':
            return {
                ...state,
                dealerIds: action.payload,
            };

        case 'SET_SEARCH':
            return {
                ...state,
                search: action.payload,
            };

        case 'SET_SORT':
            return {
                ...state,
                sortedOn: {
                    type: action.payload[0],
                    order: action.payload[1],
                },
            };

        case 'SET_DATE_RANGE':
            return {
                ...state,
                startAppointmentDate: action.payload[0],
                endAppointmentDate: action.payload[1],
                dateRangeId: action.payload[2],
            };

        default:
            return state;
    }
};
