import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import { ErrorMessageDiv } from '../../containers/Layout';
import { RadioContainer, Block, Label } from '../ui/form/Radio';

const SwitchField = props => {
    const { meta, options = [], disabled, input, onClick = null, multi = false } = props;
    const { active = false, touched = false, error = null } = meta;
    const hasError = !active && touched && !!error;

    const onChange = useCallback(
        item => {
            const oldValue = input.value;
            let newValue = item.value;

            // mutiple slection switch
            if (multi) {
                if (oldValue.includes(item.value)) {
                    // if already selected, need to remove it
                    newValue = oldValue.filter(it => it !== item.value);
                } else {
                    // if no yet selected, need to add it
                    newValue = oldValue.concat([item.value]);
                }
                // single switch
            }

            input.onChange(newValue);
            if (onClick) {
                onClick(newValue);
            }
        },
        [input, multi, onClick]
    );

    const renderLabel = (item, key) => {
        const activeOption = multi ? input.value?.includes(item.value) : input.value === item.value;

        return (
            <Label key={key.toString()} active={activeOption} disabled={!disabled && item.disabled}>
                <input
                    checked={active}
                    disabled={disabled || item.disabled}
                    onChange={() => onChange(item)}
                    type="radio"
                />
                {item.label}
            </Label>
        );
    };

    return (
        <RadioContainer disabled={disabled}>
            <Block len={options.length}>{options.map(renderLabel)}</Block>
            {hasError && <ErrorMessageDiv>{error}</ErrorMessageDiv>}
        </RadioContainer>
    );
};

SwitchField.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
    multi: PropTypes.bool,
    onClick: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            disabled: PropTypes.bool,
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]).isRequired,
        }).isRequired
    ),
};

export default SwitchField;
