import { useApolloClient, ApolloError } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getCountryId } from '../../../../selectors';
import ImportModal from '../../../ui/importModal';
import { importInventory } from './ImportInventory.graphql';

const ImportVariantModal = ({ onClose }) => {
    const [errors, setErrors] = useState(null);
    const [updateCount, setUpdateCount] = useState(null);

    const countryId = useSelector(getCountryId);

    const client = useApolloClient();

    const onUpload = useCallback(
        async inputFile => {
            try {
                const variables = { countryId, file: inputFile };

                const { data } = await client.mutate({ mutation: importInventory, variables });

                setUpdateCount(data?.response?.length);
            } catch (error) {
                if (error instanceof ApolloError) {
                    const messages = error.graphQLErrors.reduce((acc, { message }) => `${acc}${message} `, '');
                    setErrors([messages]);
                }
            }
        },
        [client, countryId]
    );

    return (
        <ImportModal
            errors={errors}
            onClose={onClose}
            onDownloadClick={() => null}
            onUpload={onUpload}
            type="Inventory"
            updateCount={updateCount}
        />
    );
};

ImportVariantModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default ImportVariantModal;
