import { useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, flatMap, flow, orderBy, uniq, size } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FootBar, FootBarButton, FootContainer } from '../../../../containers/Layout';
import { getCountryId, getGlobalPermissions } from '../../../../selectors';
import { searchOnKeys } from '../../../../utilities/fp';
import { List, cells, ListSearch } from '../../../ui/lists';
import usePaging from '../../../utilities/usePaging';
import { getTables } from './OptionGroupList.graphql';
import useVehicleTabs from './useVehiclesTabs';

const columns = [
    { name: 'Category Name', id: 'name.en', renderCell: get('name.en'), hasSort: true, underline: true },
    { name: 'Options', id: 'options.length', renderCell: get('options.length'), hasSort: true },
    { name: 'Variants', id: 'variantCount', renderCell: get('variantCount'), hasSort: true },
    { name: 'Order No.', id: 'order', renderCell: get('order'), hasSort: true },
    { name: 'Active', id: 'isActive', renderCell: cells.renderActive(), hasSort: true },
];

const getVariantCount = flow([get('options'), flatMap(option => option.variants), uniq, size]);

const useComputedItems = items =>
    useMemo(
        () =>
            items.map(item => ({
                ...item,
                variantCount: getVariantCount(item),
            })),
        [items]
    );

const sortItems = (items, [sortKey, sortOrder]) => {
    // we always sort by value key
    return orderBy(sortKey, sortOrder, items);
};

const searchFields = ['name.en', 'order'];

const OptionGroupList = () => {
    const tabs = useVehicleTabs();
    const history = useHistory();
    const { mayManageVehicles } = useSelector(getGlobalPermissions);

    const countryId = useSelector(getCountryId);
    const variables = { countryId };
    const { data, loading, error } = useQuery(getTables, { fetchPolicy: 'cache-and-network', variables });
    const items = useComputedItems(data?.results?.items || []);
    const isLoading = loading && items.length <= 0;

    // searching
    const [search, setSearch] = useState('');
    const searchMethod = useMemo(() => searchOnKeys(searchFields, items), [items]);
    const matchedItems = useMemo(() => searchMethod(search), [search, searchMethod]);

    // sorting
    const [sortedOn, onSort] = useState(['name.en', 'asc']);
    const sortedItems = useMemo(() => sortItems(matchedItems, sortedOn), [matchedItems, sortedOn]);

    // paging
    const [pagedItems, paging] = usePaging(sortedItems, { search });

    const footer = (
        <FootContainer>
            <FootBar>
                {mayManageVehicles && (
                    <FootBarButton onClick={() => history.push('/vehicle/optionGroups/new')}>
                        <FontAwesomeIcon icon={faPlus} /> ADD Category
                    </FootBarButton>
                )}
            </FootBar>
        </FootContainer>
    );

    return (
        <List
            activeTab="optionGroup"
            columns={columns}
            error={error}
            footer={footer}
            headerLeftComponent={<ListSearch initialValue={setSearch} onSubmit={setSearch} />}
            items={pagedItems}
            loading={isLoading}
            onItemClick={item => history.push(`/vehicle/optionGroups/${item.id}`)}
            onSort={onSort}
            paging={paging}
            sortedOn={sortedOn}
            tabs={tabs}
            title="Option Category"
        />
    );
};

export default OptionGroupList;
