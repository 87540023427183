import {
    cloneDeep,
    map,
    flow,
    toLower,
    capitalize,
    words,
    join,
    isNil,
    getOr,
    get,
    upperFirst,
    sum,
    camelCase,
    startCase,
    omit,
    sortBy,
} from 'lodash/fp';
import { getSourceLabel } from '../../components/routes/Workflow/Finance/lists/LeadList';
import { ApplicationEventType, FinanceProductSubType, Unit } from '../../schema';
import { getLastModified } from '../forms';

const withBy = (text, author) => (author ? `${text} by ${author}` : text);

const withAmount = (text, formatCurrency, amount) =>
    amount ? `${text} \r\nApproved Amount - ${formatCurrency(amount)}` : text;

const formatApplicationStatus = ({ statusText: { text: statusLabel, extra: statusLabelExtra } }) => {
    if (statusLabelExtra) {
        return `${statusLabel} \r\n${statusLabelExtra}`;
    }

    return statusLabel;
};

const capitalizeStartOfWord = flow([
    toLower,
    words,
    map(part => (['of', 'the'].includes(part) ? part : capitalize(part))),
    join(' '),
]);

const formatActivationStatus = ({ activationStatus, events, bank: bankMeta }, formatCurrencyDown, formatDateTime) => {
    const status = capitalizeStartOfWord(activationStatus);

    const activation =
        (events &&
            events
                .filter(item => item.type === ApplicationEventType.ACTIVATE)
                .sort((a, b) => {
                    const dateA = new Date(a.at);
                    const dateB = new Date(b.at);

                    return dateA - dateB;
                })) ||
        [];

    if (activation.length <= 0) {
        return status;
    }

    return withAmount(
        withBy(`${status} at ${formatDateTime(activation[0].at)}`, activation[0].by?.name),
        formatCurrencyDown,
        bankMeta?.approvedAmount
    );
};

const formatPromoCode = (promoCode, carPrice, formatCurrencyDown, formatPercentage) => {
    const { unit, value, identifier } = promoCode;

    // check if promo code has value
    if (!value) {
        return identifier;
    }

    // check promo value more than starting price
    const isExceededCarPrice = value > carPrice;
    const availablePromoPrice = isExceededCarPrice ? carPrice : value;

    const isPercentage = unit === Unit.PERCENTAGE;
    const formattedValue = isPercentage ? (carPrice * value) / 100 : availablePromoPrice;

    const formatterPercentage = `(${formatPercentage(-value)})`;

    return `${identifier}: ${formatCurrencyDown(-formattedValue)} ${isPercentage ? formatterPercentage : ''}`;
};

const getPaymentDate = application => {
    const eventPaid = application?.events.find(item => item.type === ApplicationEventType.PAID);
    const eventPaidDate = getOr('', 'at', eventPaid);

    return eventPaidDate || '';
};

const formatApplicationType = ({ reservationDeposit, appliedForFinancing }) => {
    if (reservationDeposit && appliedForFinancing) {
        return 'Finance + Payment';
    }
    if (appliedForFinancing) {
        return 'Finance';
    }
    if (reservationDeposit) {
        return 'Payment';
    }

    return null;
};
export const mapMonthlyInstalmentPrefix = (interestOnlyTerms, terms) => {
    const divisible = interestOnlyTerms % 12 === 0 && terms % 12 === 0;

    if (divisible) {
        const interestOnlyYear = interestOnlyTerms / 12;
        const totalYear = terms / 12;

        const firstText = interestOnlyYear === 1 ? 'Year 1' : `Year 1 to ${interestOnlyYear}`;

        const secondText =
            interestOnlyYear + 1 === totalYear
                ? `Year ${interestOnlyYear + 1}`
                : `Year ${interestOnlyYear + 1} to ${totalYear}`;

        return [firstText, secondText];
    }

    const firstText = interestOnlyTerms === 1 ? 'Month 1' : `Month 1 to ${interestOnlyTerms}`;

    const secondText =
        interestOnlyTerms + 1 === terms
            ? `Month ${interestOnlyTerms + 1}`
            : `Month ${interestOnlyTerms + 1} to ${terms}`;

    return [firstText, secondText];
};

export const computeDisplayMonthlyInstalment = application => {
    const { calculator, financeProduct } = application;
    const { term, monthlyInstalments } = calculator;
    const termSetting = financeProduct?.termSetting;
    const interestOnly = termSetting?.interestOnly;

    if (calculator.monthlyInstalments?.length <= 1) {
        return null;
    }

    switch (financeProduct?.subType) {
        case FinanceProductSubType.DEFERREDPRINCIPAL:
            return mapMonthlyInstalmentPrefix(interestOnly, term);

        case FinanceProductSubType.HIREPURCHASEBALLOONGFV:
            return monthlyInstalments.map(({ start, end }) =>
                start === end ? `Month ${start} Instalment` : `Month ${start} to ${end} Instalments`
            );

        default:
            return null;
    }
};

const getMiniConfigurationValues = values => {
    if (!values) {
        return 0;
    }

    const equipmentPrice = values?.tequipment
        ? Object.values(values.tequipment).reduce((acc, equipment) => acc + (equipment?.price || 0), 0)
        : 0;

    // does not include timepiece
    return sum([values.delivery.price, values.enhancedPackage?.price, equipmentPrice]);
};

const getDisplayOptions = (options, formatCurrency, ct) => {
    if (!options) {
        return null;
    }

    return sortBy(['group.order'], options)
        .map(({ name, price, group }) => `${ct(group.name)}: ${ct(name)}${price ? ` - ${formatCurrency(price)}` : ''}`)
        .join('\n');
};

export const computeFinaceApplicationInformation = (
    application,
    { formatCurrency, formatCurrencyDown, formatPercentage },
    formatDateTime,
    { ct }
) => {
    const { calculator, options, financeProduct, promoCode, reservationDeposit } = application;

    const showModeOfPayment = get('paymentMode.show', financeProduct);

    let base = {
        paymentDate: formatDateTime(getPaymentDate(application)),
        reservationDeposit: reservationDeposit ? formatCurrency(reservationDeposit) : null,
        applicationType: formatApplicationType(application),
        carOptions: getDisplayOptions(application.options, formatCurrency, ct),
        activationStatus:
            application?.statusText && formatActivationStatus(application, formatCurrencyDown, formatDateTime),
    };

    if (calculator) {
        // format monthly instalments
        const monthlyInstalments = calculator.monthlyInstalments?.map(({ amount }) => formatCurrency(amount));

        base = {
            ...base,

            monthlyInstalmentPrefix: computeDisplayMonthlyInstalment(application),

            coeAmount: calculator.coe && formatCurrency(calculator.coe.amount),
            dealerOptionsAmount: calculator.dealerOptions && formatCurrency(calculator.dealerOptions.amount),
            ppsrAmount: calculator.ppsr && formatCurrency(calculator.ppsr.amount),
            establishmentAmount: calculator.establishment && formatCurrency(calculator.establishment.amount),
            luxuryTaxAmount: calculator.luxuryTax && formatCurrency(calculator.luxuryTax.amount),
            securityDeposit: calculator.deposit && formatCurrency(calculator.deposit.amount),
            downpaymentAmount: calculator.downPayment && formatCurrency(calculator.downPayment.amount),
            residualValueAmount: calculator.residualValue && formatCurrency(calculator.residualValue.amount),
            estimatedSurplus: calculator.estimatedSurplus && formatCurrency(calculator.estimatedSurplus),
            totalInterestPayable: calculator.totalInterestPayable && formatCurrency(calculator.totalInterestPayable),
            financialAmount: calculator.loan && formatCurrencyDown(calculator.loan.amount),
            financialAmountPercentage: calculator.loan && formatPercentage(calculator.loan.percentage),
            paymentTerm: calculator.term ? `${calculator.term} Months` : '',

            interestRate: calculator.interestRate,
            totalPrice: !isNil(calculator.price)
                ? formatCurrency(
                      calculator.price +
                          (calculator.coe ? calculator.coe.amount : 0) +
                          (calculator.nzFee ?? 0) +
                          (calculator.luxuryTax ? calculator.luxuryTax.amount : 0) +
                          options.reduce((acc, item) => acc + item.price, 0) +
                          getMiniConfigurationValues(application?.miniConfiguratorDetails)
                  )
                : undefined,

            paymentMode: showModeOfPayment && upperFirst(calculator?.paymentMode),
            monthlyInstalments,
            promoCode: promoCode
                ? formatPromoCode(promoCode, calculator.price, formatCurrencyDown, formatPercentage)
                : null,

            tradeInEquity: calculator.tradeInEquity && formatCurrency(calculator.tradeInEquity),

            // uccl
            licensePlateFee: calculator.licensePlateFee && formatCurrency(calculator.licensePlateFee),
            commission: calculator.commission,
            fixedInterestRate: calculator.fixedInterestRate && formatCurrency(calculator.fixedInterestRate),
            licenseAndFuelTax: calculator.licenseAndFuelTax && formatCurrency(calculator.licenseAndFuelTax),
            displacement: calculator.displacement,
            insuranceFeeAmount: calculator.insuranceFee && formatCurrency(calculator.insuranceFee.amount),
            taxLoss: calculator.taxLoss && formatCurrency(calculator.taxLoss),
        };
    }

    if (financeProduct) {
        base = {
            ...base,
            // basic finance product stuff
            bank: financeProduct?.bank.name,
            hasBankIntegration: financeProduct?.bank.hasIntegration,
            financialProduct: financeProduct?.name && ct(financeProduct.name),
        };
    }

    return base;
};

export const computeBaseDisplayInformation = (application, formatDateTime) => ({
    // common stuff
    dateCreated: application.version ? formatDateTime(application?.version?.createdAt) : null,
    createdBy: application.version.createdBy?.name,
    lastModified: getLastModified(application.version, formatDateTime),
    channel: application.channel,
    event: application?.event?.name || null,
    dealer: application.dealer.name,
    source: getSourceLabel(application.channel, application.access),
    appId: application.identifier,
    applicationStatus: application?.statusText && formatApplicationStatus(application),
    insuranceCompany: application.insuranceCompany,
});

export const computeDisplayInformation = (
    application,
    { formatCurrency, formatCurrencyDown, formatPercentage },
    formatDateTime,
    { ct }
) => ({
    ...computeBaseDisplayInformation(application, formatDateTime),
    ...computeFinaceApplicationInformation(
        application,
        { formatCurrency, formatCurrencyDown, formatPercentage },
        formatDateTime,
        { ct }
    ),
});

export const getConditionText = value => {
    const listing = value?.listing;

    if (!listing) {
        return null;
    }

    switch (listing.vehicle.condition.value) {
        case 'preowned':
            if (listing.warranty?.porscheApproved) {
                return 'Porsche Approved';
            }

            return 'Preowned';

        default:
            return flow([camelCase, startCase])(listing.vehicle.condition.value);
    }
};

export const calculatorDisplay = (values, application) => {
    return !!application.finderVehicle?.id && !(application.isCalculatorEnabled || application.appliedForFinancing)
        ? omit(
              [
                  'bank',
                  'financialProduct',
                  'paymentMode',
                  'monthlyInstalments',
                  'interestRate',
                  'paymentTerm',
                  'downpaymentAmount',
                  'financialAmountPercentage',
                  'financialAmount',
                  'coeAmount',
                  'ppsrAmount',
                  'establishmentAmount',
                  'securityDeposit',
                  'dealerOptionsAmount',
                  'totalPrice',
                  'residualValueAmount',
                  'estimatedSurplus',
                  'totalInterestPayable',
              ],
              values
          )
        : values;
};

export const toFormState = (application, formats, formatDateTime, translation) => ({
    // clone application itself
    ...cloneDeep(application),
    hasTradeIn: !!application?.hasTradeIn,
    hasTestDrive: !!application?.hasTestDrive,
    display: calculatorDisplay(
        computeDisplayInformation(application, formats, formatDateTime, translation),
        application
    ),
    // provide initial promo code
    initialPromoCode: application.promoCode?.identifier,
    initialCustomer: cloneDeep(application.customer),
    finderVehicleCondition: getConditionText(application.finderVehicle),
});
