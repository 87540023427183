import { ApolloError } from '@apollo/client';
import { set, get, mapValues, flow, omit, isObject, isArray, isNaN, trim } from 'lodash/fp';
import { SubmissionError } from 'redux-form';
import * as yup from 'yup';

export const requiredString = msg => yup.string().nullable().required(msg);
export const requiredNumber = msg => yup.number().nullable().required(msg);

export const createFormValidation = schema => (values, props) => {
    try {
        schema.validateSync(values, { abortEarly: false, context: props });
    } catch (validationError) {
        if (validationError instanceof yup.ValidationError) {
            return validationError.inner.reduce((errors, error) => {
                const {
                    path,
                    errors: [message],
                } = error;

                if (get(path, errors)) {
                    // do not override errors, only keep the first
                    return errors;
                }

                return set(path, message, errors);
            }, {});
        }

        throw validationError;
    }

    return null;
};

export const fromContextValidation = (instance, validationKey, errorMessage) =>
    yup.lazy((values, options) => {
        const validation = get(['context', 'validation', validationKey], options);

        if (!validation) {
            // nothing to test yet
            return instance;
        }

        return instance.test('match-validation', errorMessage, value => validation.test(value));
    });

export const handleResponseError = error => {
    if (error instanceof ApolloError) {
        const messages = error.graphQLErrors.reduce((acc, { message }) => `${acc}${message}`, '');
        throw new SubmissionError({ _error: messages });
    }

    if (error instanceof SubmissionError) {
        // throw it back
        throw error;
    }

    // print ouf the error
    console.error(error);

    throw new SubmissionError({ _error: 'Something wrong happened' });
};

export const prepareForGraphQL = data => {
    if (data instanceof Date) {
        return data.toISOString();
    }

    if (isArray(data)) {
        return data.map(prepareForGraphQL);
    }

    if (isObject(data)) {
        return flow([omit(['__typename', '__exclude']), mapValues(prepareForGraphQL)])(data);
    }

    return data;
};

export const getLastModified = (version, formatDateTime) => {
    if (!version) {
        return 'never modified';
    }

    const { updatedBy, updatedAt } = version;

    const by = trim(updatedBy?.name || 'System');

    const date = new Date(updatedAt);

    if (date instanceof Date && !isNaN(date)) {
        return `${formatDateTime(updatedAt)} by ${by}`;
    }

    return `by ${by}`;
};
