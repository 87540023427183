import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { range, difference } from 'lodash/fp';

export const exportFinancialTabsExcel = (workbook, productName, tableName) => {
    const worksheet = workbook.getWorksheet(1);
    const headerRow = worksheet.getRow(1);

    const headCellStyle = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'd9d8d9' },
    };

    for (let i = 1; i <= headerRow.cellCount; i++) {
        const cell = headerRow.getCell(i);
        cell.fill = headCellStyle;
        if (cell.value.includes('*')) {
            cell.value = {
                richText: [{ text: cell.value.slice(0, -1) }, { font: { color: { argb: 'FFFF0000' } }, text: '*' }],
            };
        }
        worksheet.getColumn(i).width = 20;
    }

    workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, `${productName} - ${tableName}.xlsx`);
    });
};

const getHeaderIndexes = (sheet, headers) => {
    const row = sheet.getRow(1);
    const headerIndexes = [];

    row.eachCell({ includeEmpty: true }, (cell, col) => {
        headerIndexes[col] = headers[cell.text];
    });

    return headerIndexes;
};

const getData = (sheet, headerIndexes) => {
    const list = [];

    for (let i = 2; i < sheet.rowCount + 1; i++) {
        const data = {};
        const row = sheet.getRow(i);

        row.eachCell({ includeEmpty: true }, (cell, col) => {
            data[headerIndexes[col]] =
                !Number.isNaN(parseFloat(cell.text)) && !Number.isNaN(cell.text - 0) ? +cell.text : cell.text;
        });

        list.push(data);
    }

    return list;
};

const getMissingHeaders = (sheet, mappedHeaders) => {
    const row = sheet.getRow(1);
    const requiredHeaders = Object.keys(mappedHeaders).filter(header => header.includes('*'));

    return difference(
        requiredHeaders,
        requiredHeaders.filter(header => range(1, sheet.columnCount + 1).some(i => row.getCell(i).text === header))
    );
};

export const getDatafromExcel = async (excel, mappedHeaders) => {
    const workbook = new Excel.Workbook();
    await workbook.xlsx.load(excel);
    const worksheet = workbook.getWorksheet(1);

    const errors = [];

    const missingHeaders = getMissingHeaders(worksheet, mappedHeaders);
    if (missingHeaders.length > 0) {
        errors.push(`Missing Header/s: ${missingHeaders.join(', ')}. Please use template and re-upload.`);
    }

    if (errors.length > 0) {
        return errors;
    }

    const headerIndexes = getHeaderIndexes(worksheet, mappedHeaders);
    const data = getData(worksheet, headerIndexes);

    return data;
};

const getColumns = mappedHeaders =>
    Object.keys(mappedHeaders).reduce((array, keyValue) => {
        return [...array, { header: keyValue, key: mappedHeaders[keyValue] }];
    }, []);

export const getWorkbookToExport = async mappedHeaders => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet();
    const columns = getColumns(mappedHeaders);

    worksheet.columns = columns;

    return workbook;
};
