import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import breakpoints from '../../../utilities/constants/breakpoints';
import Card from './Card';

const RootContainer = styled.div`
    ${props =>
        props.theme.webFontUrl &&
        css`
            @font-face {
                font-family: 'Company Font';
                src: url(${props.theme.webFontUrl});
            }
        `}

    display: flex;
    width: 100%;
    margin-top: 0;

    @media (min-width: ${breakpoints.sm}) {
        margin-top: 3.5%;
        width: auto;
    }
`;

const MainContainer = styled(Card)`
    width: 100%;
    box-sizing: content-box;

    label,
    input,
    button,
    h1,
    p {
        font-family: 'Company Font', 'Roboto', sans-serif !important;
        vertical-align: middle;
    }

    p {
        line-height: 1.4;
    }

    @media (min-width: ${breakpoints.sm}) {
        width: 320px;
    }
`;

const BrandContainer = styled(Card)`
    padding: 80px 40px;
    width: 220px;
    background: #222034;
    color: #ffffff;
    white-space: pre-wrap;
    display: none;
    box-sizing: content-box;

    // login brand container should use company font
    h1,
    div {
        font-family: 'Company Font', 'Roboto', sans-serif !important;
    }

    @media (min-width: ${breakpoints.md}) {
        display: block;
    }

    h1 {
        font-weight: bold;
        // fix bootstrap override issue
        font-size: 1.5rem;
    }

    div {
        // fix bootstrap override issue
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
    }
`;

const LoginContainer = ({ noBrand, brandName, brandWelcome, children, className }) => (
    <div className={className}>
        <RootContainer>
            <MainContainer>{children}</MainContainer>
            {!noBrand && (
                <BrandContainer>
                    <h1>{brandName}</h1>
                    <div>{brandWelcome}</div>
                </BrandContainer>
            )}
        </RootContainer>
    </div>
);

LoginContainer.propTypes = {
    brandName: PropTypes.node,
    brandWelcome: PropTypes.node,
    children: PropTypes.node.isRequired,
    className: PropTypes.string.isRequired,
    noBrand: PropTypes.bool,
};

export default styled(LoginContainer)`
    display: flex;
    justify-content: center;
`;
