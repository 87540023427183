import { faExpandArrowsAlt, faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { FullScreenIcon, FullScreenExitIcon } from '../../ui/dashboard/ChartContainer';
import FullScreenModal, { Title } from '../../ui/dashboard/FullScreenModal';
import MostPopularChartBlock from '../../ui/dashboard/MostPopularChartBlock';
import { GetPopularApplicationVariantQuery } from './PopularApplicationVariantChart.graphql';

type PopularChartProps = {
    id: string;
    data: GetPopularApplicationVariantQuery | undefined;
    title: string;
    xAxisTitle: string;
};

const PopularChart = ({ title, ...props }: PopularChartProps) => {
    const { id } = props;
    const [showFullScreen, setShowFullScreen] = useState(false);

    return (
        <section className="card" order-id="card-10">
            <div className="card-header">
                <h5 className="mb-0 mr-3 d-inline-block text-black">
                    <strong>{title}</strong>
                </h5>
                <FullScreenIcon onClick={() => setShowFullScreen(true)}>
                    <span>Fullscreen</span>
                    <FontAwesomeIcon icon={faExpandArrowsAlt} />
                </FullScreenIcon>
            </div>
            <div className="card-body popular">
                <MostPopularChartBlock {...props} />
            </div>
            {showFullScreen && (
                <FullScreenModal onRequestClose={() => setShowFullScreen(false)} isOpen>
                    <div>
                        <FullScreenExitIcon onClick={() => setShowFullScreen(false)}>
                            <FontAwesomeIcon icon={faCompressArrowsAlt} />
                        </FullScreenExitIcon>
                        <Title>{title}</Title>
                        <MostPopularChartBlock {...props} id={`${id}FullScreen`} />
                    </div>
                </FullScreenModal>
            )}
        </section>
    );
};

export default PopularChart;
