import { get } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import MultiSelectPoolField from '../../../../template/MultiSelectPool';
import { useFormContext } from './context';

const VehicleAssignment = () => {
    const { disabled, variants } = useFormContext();
    const { formatPath } = useContentTranslation();

    const translatedVariants = useMemo(
        () =>
            variants.map(variant => ({
                ...variant,
                label: get(formatPath('label'), variant),
            })),
        [variants, formatPath]
    );

    return (
        <div className="container-fluid">
            <Field
                component={MultiSelectPoolField}
                disabled={disabled}
                name="variants"
                options={translatedVariants}
                poolLabel="Variants Pool"
                selectionLabel="Selected Variants"
            />
        </div>
    );
};

export default VehicleAssignment;
