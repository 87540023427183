import React from 'react';
import { Field } from 'redux-form';
import TableField from '../../../../TableField';
import { ExcelTax, TaxTable, useDefaultTaxData } from './LicenseTaxTab';
import { useFormContext } from './context';

const defaultFuelTaxes = [0, 2160, 2880, 4320, 4800, 6180, 7200, 8640, 9810, 11220, 12180, 13080, 13950, 14910, 15720];

const fuelTaxRows: TaxTable['rows'] = [
    { label: '0', values: { min: 0, max: 0 } },
    { label: '251-500', values: { min: 251, max: 500 } },
    { label: '501-600', values: { min: 501, max: 600 } },
    { label: '601-1200', values: { min: 601, max: 1200 } },
    { label: '1201-1800', values: { min: 1201, max: 1800 } },
    { label: '1801-2400', values: { min: 1801, max: 2400 } },
    { label: '2401-3000', values: { min: 2401, max: 3000 } },
    { label: '3001-3600', values: { min: 3001, max: 3600 } },
    { label: '3601-4200', values: { min: 3601, max: 4200 } },
    { label: '4201-4800', values: { min: 4201, max: 4800 } },
    { label: '4801-5400', values: { min: 4801, max: 5400 } },
    { label: '5401-6000', values: { min: 5401, max: 6000 } },
    { label: '6001-6600', values: { min: 6001, max: 6600 } },
    { label: '6601-7200', values: { min: 6601, max: 7200 } },
    { label: '7201-8000', values: { min: 7201, max: 8000 } },
];

const FuelTaxTab = () => {
    const { disabled, rounding } = useFormContext();
    useDefaultTaxData('licenseAndFuelTaxSetting.fuelTable', defaultFuelTaxes, fuelTaxRows);

    return (
        <div className="container-fluid">
            <Field
                columnKey="value"
                columns={[{ label: 'Value', value: 'value' }]}
                component={TableField}
                disabled={disabled}
                header="Displacement"
                name="licenseAndFuelTaxSetting.fuelTable"
                precision={rounding.amount.count}
                rows={fuelTaxRows}
            />
            <ExcelTax label="Fuel Tax" name="licenseAndFuelTaxSetting.fuelTable" rows={fuelTaxRows} />
        </div>
    );
};

export default FuelTaxTab;
