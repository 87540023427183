import styled from 'styled-components';

const PureErrorV2 = styled.div`
    color: #ff0000;
    min-width: fit-content;
    text-align: right;
    padding-top: 3px;
`;

export default PureErrorV2;
