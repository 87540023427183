import styled from 'styled-components';
import Button from './Button';

const DarkButton = styled(Button)`
    color: #fff;
    background-color: #000000;
    border-radius: 0;

    &:disabled {
        cursor: not-allowed;
        color: #7e7e7e;
        opacity: 1;
    }
`;

export default DarkButton;
