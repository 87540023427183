import { get, isEmpty, flow, sortBy, map, join, orderBy } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { useContentTranslation } from '../../../../../i18n';
import ApvPortlet from '../../../../template/Portlet';
import { cells, ListTable } from '../../../../ui/lists';
import { useFormContext } from './context';

const columns = formatPath => [
    { id: 'name', name: 'Bank Name', renderCell: get(formatPath('name')), underline: true },
    { id: 'integration', name: 'Bank Integration', renderCell: item => (item.hasIntegration ? 'Yes' : 'No') },
    { id: 'zones', name: 'Zones', renderCell: flow([get('zones'), sortBy(['code']), map(get('code')), join(', ')]) },
    { id: 'order', name: 'Order No.', renderCell: get('order') },
    { id: 'active', name: 'Active', renderCell: cells.renderActive() },
];

const Banks = () => {
    const { banks } = useFormContext();
    const history = useHistory();
    const { formatPath } = useContentTranslation();
    const sortedBanks = useMemo(() => orderBy(['order'], ['asc'], banks), [banks]);

    if (isEmpty(banks)) {
        return null;
    }

    return (
        <ApvPortlet name="Banks" closable open>
            <div className="container-fluid">
                <br />
                <ListTable
                    columns={columns(formatPath)}
                    items={sortedBanks}
                    onItemClick={item => history.push(`/scope/banks/${item.id}`)}
                />
            </div>
        </ApvPortlet>
    );
};

export default Banks;
