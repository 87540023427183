import { HeadBar, HeadTitle } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { HeadBtnContainer, HeadTabBarButton } from '../../../containers/Layout';
import ListTabDropDown from './ListTabDropDown';

const getShowTabsState = tabWidth => window.innerWidth - 440 >= (tabWidth || 916);

const ListHeader = ({
    title,
    bottomComponent = null,
    leftComponent = null,
    tabs = null,
    activeTab = null,
    tabWidth,
}) => {
    const history = useHistory();

    const [showTabs, setShowTabs] = useState(getShowTabsState(tabWidth));

    useEffect(() => {
        const update = () => setShowTabs(getShowTabsState(tabWidth));
        window.addEventListener('resize', update);

        return () => {
            window.removeEventListener('resize', update);
        };
    }, [setShowTabs, tabWidth]);

    return (
        <>
            <HeadBar className="head-bar">
                <HeadTitle>{title}</HeadTitle>
                {leftComponent}
                {tabs && (
                    <HeadBtnContainer id="tabButton">
                        {showTabs ? (
                            tabs.map(tab => (
                                <HeadTabBarButton
                                    key={tab.id}
                                    className={tab.id === activeTab ? 'active' : ''}
                                    onClick={() => history.push(tab.url)}
                                >
                                    <span>{tab.name}</span>
                                </HeadTabBarButton>
                            ))
                        ) : (
                            <ListTabDropDown onChange={tab => history.push(tab.url)} tabs={tabs} />
                        )}
                    </HeadBtnContainer>
                )}
            </HeadBar>
            {bottomComponent}
        </>
    );
};

ListHeader.propTypes = {
    activeTab: PropTypes.string,
    bottomComponent: PropTypes.node,
    leftComponent: PropTypes.node,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }).isRequired
    ),
    tabWidth: PropTypes.number,
    title: PropTypes.node.isRequired,
};

export default ListHeader;
