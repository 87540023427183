import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import FieldContainer from '../../../../template/Field-container';
import NumberField from '../../../../template/Number';
import UnitField from '../../../../template/options-field/UnitField';
import { useFormContext } from './context';

const DepositRange = () => {
    const { disabled, values, change, currency, rounding } = useFormContext();

    const min = get('depositSetting.min', values);
    const max = get('depositSetting.max', values);

    useEffect(() => {
        if (min === max) {
            change('depositSetting.show', false);
            change('depositSetting.step', 0);
            change('depositSetting.default', min);
            change('depositSetting.editable', false);
        }
    }, [min, max, change]);

    return (
        <div className="row">
            <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="sub-container-fluid">
                    <FieldContainer label="Min*">
                        <Field
                            component={NumberField}
                            disabled={disabled}
                            min={0}
                            name="depositSetting.min"
                            precision={rounding.percentage.count}
                        />
                    </FieldContainer>
                    <span />
                    <FieldContainer label="Max*">
                        <Field
                            component={NumberField}
                            disabled={disabled}
                            min={0}
                            name="depositSetting.max"
                            precision={rounding.percentage.count}
                        />
                    </FieldContainer>
                    <span />
                    <FieldContainer label="Step*">
                        <Field
                            component={NumberField}
                            disabled={min === max || disabled}
                            min={0}
                            name="depositSetting.step"
                            precision={rounding.percentage.count}
                        />
                    </FieldContainer>
                </div>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="sub-container-fluid">
                    <div className="flex-3">
                        <FieldContainer label="Default*">
                            <Field
                                component={NumberField}
                                disabled={min === max || disabled}
                                min={0}
                                name="depositSetting.default"
                                precision={rounding.percentage.count}
                            />
                        </FieldContainer>
                    </div>
                    <span />
                    <UnitField currencySymbol={currency} label="" name="depositSetting.defaultUnit" disabled />
                </div>
            </div>
        </div>
    );
};

export default DepositRange;
