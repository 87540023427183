import { saveAs } from 'file-saver';
import { head } from 'lodash/fp';
import { nanoid } from 'nanoid';
import qs from 'query-string';
import { generateFilename, getApplicationFileName, generateEventApplicationFilename } from '../helpers/fileHelper';

// try first to use timezone for specified zone, else use the timezone from the first zone in the country
const getTimeZone = context => context.zone?.timezone ?? head(context.country.zones)?.timezone;

const getHeaderValueFromResponse = (response, headerKey = 'x-excel-password') => {
    for (const [key, value] of response.headers.entries()) {
        if (key === headerKey) {
            return value;
        }
    }

    return null;
};
const getFileNameFromResponse = response => {
    const disposition = getHeaderValueFromResponse(response, 'content-disposition');
    if (disposition?.indexOf('attachment') !== -1) {
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
        if (matches && matches[1]) {
            return matches[1].replace(/['"]/g, '');
        }
    }

    return null;
};

export const exportMakes = async (context, headers) => {
    const response = await fetch(`/l/export/makes/${context.company.id}`, { headers });
    const blob = await response.blob();

    return saveAs(blob, generateFilename(context, 'Makes'));
};

export const exportModels = async (context, headers) => {
    const response = await fetch(`/l/export/models/${context.country.id}`, { headers });
    const blob = await response.blob();

    return saveAs(blob, generateFilename(context, 'Models'));
};

export const exportSubModels = async (context, headers) => {
    const response = await fetch(`/l/export/submodels/${context.country.id}`, { headers });
    const blob = await response.blob();

    return saveAs(blob, generateFilename(context, 'Sub_Models'));
};

export const exportVariants = async (context, headers) => {
    const query = qs.stringify({ timezone: getTimeZone(context) });
    const response = await fetch(`/l/export/variants/${context.country.id}?${query}`, {
        headers,
    });
    const blob = await response.blob();

    return saveAs(blob, generateFilename(context, 'Variants'));
};

export const exportOptions = async (context, headers) => {
    const query = qs.stringify({ timezone: getTimeZone(context) });
    const response = await fetch(`/l/export/options/${context.country.id}?${query}`, {
        headers,
    });
    const blob = await response.blob();

    return saveAs(blob, generateFilename(context, 'Options'));
};

export const exportInventories = async (context, headers) => {
    const query = qs.stringify({ timezone: getTimeZone(context) });
    const response = await fetch(`/l/export/inventories/${context.country.id}?${query}`, {
        headers,
    });
    const blob = await response.blob();

    return saveAs(blob, generateFilename(context, 'Inventories'));
};

export const exportApplications = async (context, headers, duration, channel, type, dealerIds) => {
    const query = qs.stringify(
        { timezone: getTimeZone(context), duration, channel, type, dealerIds },
        { arrayFormat: 'index' }
    );

    const response = await fetch(`/l/export/applications/${context.country.id}?${query}`, {
        headers,
    });
    const blob = await response.blob();

    saveAs(blob, getApplicationFileName(context, duration, type));

    return getHeaderValueFromResponse(response);
};

export const exportInsuranceApplications = async (context, headers, duration, channel, type, dealerIds) => {
    const query = qs.stringify(
        { timezone: getTimeZone(context), duration, channel, type, dealerIds },
        { arrayFormat: 'index' }
    );

    const response = await fetch(`/l/export/insuranceApplications/${context.country.id}?${query}`, {
        headers,
    });

    const blob = await response.blob();

    saveAs(blob, getApplicationFileName(context, duration, type));

    return getHeaderValueFromResponse(response);
};

export const exportAppointments = async (context, headers, duration, channel, type, dealerIds) => {
    const query = qs.stringify(
        { timezone: getTimeZone(context), duration, channel, type, dealerIds },
        { arrayFormat: 'index' }
    );

    const response = await fetch(`/l/export/appointments/${context.country.id}?${query}`, {
        headers,
    });
    const blob = await response.blob();

    saveAs(blob, getApplicationFileName(context, duration, type));

    return getHeaderValueFromResponse(response);
};

export const exportTranslations = async (context, headers, formData) => {
    const query = qs.stringify({ timezone: getTimeZone(context) });
    const companyCode = formData.company.code;
    const countryCode = formData.code;
    const response = await fetch(`/l/export/translations/${formData.id}?${query}`, { headers });
    const blob = await response.blob();

    return saveAs(blob, generateFilename({ companyCode, countryCode }, 'Translations'));
};

export const exportEventsApplication = async (
    context,
    headers,
    eventId,
    identifier,
    leadOrApplicationVersionIds,
    phase,
    dealerIds
) => {
    const nonce = nanoid();

    const headersObj = {
        ...headers,
        'Content-Type': 'application/json',
    };

    const body = leadOrApplicationVersionIds.length !== 0 ? { nonce, leadOrApplicationVersionIds } : { nonce };

    const runExport = async (filePurpose, queryPurpose) => {
        const query = qs.stringify(
            { purpose: queryPurpose, timezone: getTimeZone(context), phase, dealerIds },
            { arrayFormat: 'index' }
        );

        const response = await fetch(`/l/export/eventLeadsAndApplications/${eventId}?${query}`, {
            method: 'POST',
            headers: headersObj,
            body: JSON.stringify(body),
        });

        const blob = await response.blob();

        saveAs(blob, generateEventApplicationFilename(context, identifier, filePurpose));

        return getHeaderValueFromResponse(response);
    };

    const [password] = await Promise.all([runExport('BP', 'BP_UPLOAD'), runExport('BP_LEAD', 'BP_LEAD_UPLOAD')]);

    return password;
};

export const exportApplicationDocuments = async (context, headers, applicationId) => {
    const path = `/l/export/applications/${context.country.id}/documents/${applicationId}`;
    const response = await fetch(`${path}`, {
        headers,
    });
    const blob = await response.blob();

    saveAs(blob, getFileNameFromResponse(response));

    return getHeaderValueFromResponse(response, 'x-document-password');
};
