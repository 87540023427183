import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useCalculatorContext } from '../../../CalculatorContext';
import { useValues } from '../../../ValuesContext';
import ParameterLabel from '../../../ui/ParameterLabel';
import Tooltip from '../../../ui/Tooltip';
import Variant, { Stocks } from '../../../ui/Variant';
import { useVariantLabel } from '../CarModelField';
import useTooltip from '../useTooltip';

const CarModelField = ({ setFieldOnEdition, label }) => {
    const { t } = useTranslation();
    // get the calculator context
    const calculatorContext = useCalculatorContext();
    // get its fields and variants
    const { fields, carOptions, hasMakes, snapshot } = calculatorContext;

    const { stocks } = carOptions;
    // get values
    const values = useValues();

    // get the label
    const variantLabel = useVariantLabel();

    // get the editable state
    const editable =
        fields.carModel.isEditable(calculatorContext, values) && !fields.carModel.isInvalid(calculatorContext);

    // if the field is editable, we will update it on click
    const onClick = useMemo(() => (editable ? () => setFieldOnEdition('carModel') : undefined), [
        editable,
        setFieldOnEdition,
    ]);

    // if there is no option or if the stocks is null (meaning there is no inventory management)
    // we do not display the inventory
    const hasInventory = stocks !== null;

    const valueRef = useRef(null);
    const tooltipRef = useTooltip(valueRef);

    // judge if display vin
    const { carModel } = values;
    const { vin, selectedVariant } = snapshot || {};
    const { id: oldCarModelId } = selectedVariant || {};

    const hasCarModelChanged = carModel !== oldCarModelId;

    return (
        <Variant ref={valueRef} editable={editable} hasMakes={hasMakes} onClick={onClick}>
            {hasMakes && <ParameterLabel>{label}</ParameterLabel>}
            {variantLabel}
            {!hasCarModelChanged && vin && <Stocks>{t('calculator.label.carModelVin', { vin })}</Stocks>}
            {hasCarModelChanged && hasInventory && <Stocks>{t('calculator.label.carModelStock', { stocks })}</Stocks>}
            <Tooltip ref={tooltipRef}>{variantLabel}</Tooltip>
        </Variant>
    );
};

CarModelField.propTypes = {
    label: PropTypes.string.isRequired,
    setFieldOnEdition: PropTypes.func.isRequired,
};

export default CarModelField;
