import styled from 'styled-components';

const InformationContentModal = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
`;

export default InformationContentModal;
