import Modal from 'react-modal';
import styled from 'styled-components';

export const DownloadModal = styled(Modal)`
    width: 600px;
    height: auto;
`;

export const CloseButton = styled.div`
    cursor: pointer;
    width: 20px;
    height: 20px;
    font-weight: bold;
    color: white;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Header = styled.div`
    margin-bottom: 13px;
    text-align: center;
    text-transform: capitalize;
    font-size: 1.14rem;
`;

export const Message = styled.div`
    margin-bottom: 20px;
    text-align: center;
    color: #cccccc;
    font-weight: 200;
`;

export const SelectButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;

    .select {
        width: 150px;
        margin: 0 5px;
    }
`;

export const DownloadButton = styled.div`
    height: 30px;
    line-height: 30px;
    font-weight: bold;
    align-self: center;
    background-color: transparent;
    border: 1px rgba(255, 255, 255, 0.56) solid;
    color: #fff;
    padding: 0px 15px;
    cursor: pointer;
    margin-left: 10px;
    text-transform: capitalize;
`;

export const YesNoButton = styled.button`
    margin: 10px 5px -5px 5px;
    background-color: transparent;
    border: 1px rgba(255, 255, 255, 0.56) solid;
    color: #fff;
    cursor: pointer;
    text-transform: capitalize;
    box-shadow: none;
    border-radius: 0;

    &:focus {
        border-radius: 0;
        outline: none;
    }
`;
