import Modal from 'react-modal';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
    width: 600px;
    height: 240px;
    max-height: 240px;
`;

export default StyledModal;
