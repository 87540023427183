import { useQuery } from '@apollo/client';
import { find, get, isNil } from 'lodash/fp';
import PropType from 'prop-types';
import React, { useCallback, useMemo, useState, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FootBar, FootBarButton, FootContainer } from '../../../../../containers/Layout';
import { useContentTranslation } from '../../../../../i18n';
import { EventExternalSite } from '../../../../../schema';
import { getZoneId, getCurrentCountry, getCurrentZone } from '../../../../../selectors';
import { applicationChannelOptions } from '../../../../../shared/constants/options';
import withCompanyFormatting from '../../../../../utils/withCompanyFormatting';
import useFormatDateTime from '../../../../shared/useFormatDateTime';
import { List, cells, ListSearch, DealerSelectionDropDown } from '../../../../ui/lists';
import useServerPaging from '../../../../utilities/useServerPaging';
import DownloadModal from './Download';
import { getTables, getTablesByCountryId } from './LeadList.graphql';

export const getSourceLabel = (channel, access) =>
    find(option => option.channel === channel && option.access === access, applicationChannelOptions)?.label;

const useSearchFields = () => {
    const { formatPath } = useContentTranslation();

    return useMemo(
        () => [
            'identifier',
            'name',
            'zone.code',
            'assignee.username',
            formatPath('variant.name'),
            formatPath('financeProduct.name'),
            'calculator.loan.amount',
            'channel',
        ],
        [formatPath]
    );
};

const useColumns = (hasFinanceProduct, hasBank) => {
    const formatDateTime = useFormatDateTime();
    const { formatPath } = useContentTranslation();
    const zone = useSelector(getCurrentZone);

    return useMemo(() => {
        const columns = [
            {
                name: 'Lead Date',
                id: 'version.createdAt',
                renderCell: cells.renderDateTime('version.createdAt', formatDateTime),
                hasSort: true,
            },
            {
                name: 'Lead ID',
                id: 'identifier',
                renderCell: get('identifier'),
                hasSort: true,
                underline: true,
            },
            {
                name: 'Dealer',
                id: formatPath('dealer.name'),
                renderCell: get(formatPath('dealer.name')),
                hasSort: true,
            },
            {
                name: 'Assigned to',
                id: 'assignee.username',
                renderCell: get('assignee.username'),
                hasSort: true,
            },
            {
                name: 'Customer',
                id: 'customer.name.value',
                renderCell: get('customer.name.value'),
                hasSort: true,
            },
            {
                name: 'Variant',
                id: formatPath('variant.name'),
                renderCell: get(formatPath('variant.name')),
                hasSort: true,
            },
            hasBank && { name: 'Bank', id: 'bank.name', renderCell: get(formatPath('bank.name')), hasSort: true },
            hasFinanceProduct && {
                name: 'Financial Product',
                id: formatPath('financeProduct.name'),
                renderCell: get(formatPath('financeProduct.name')),
                hasSort: true,
            },
            {
                name: 'Last Activity',
                id: 'version.updatedAt',
                renderCell: cells.renderDateTime('version.updatedAt', formatDateTime),
                hasSort: true,
            },
            !zone && { name: 'Zone', id: 'zone.code', renderCell: get('zone.code'), hasSort: true },
            { name: 'Channel', id: 'channel', renderCell: get('source'), hasSort: true },
        ];

        return columns.filter(Boolean);
    }, [formatDateTime, formatPath, hasBank, hasFinanceProduct, zone]);
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE':
            return { ...state, ...{ type: action.payload[0], order: action.payload[1] } };
        default:
            return state;
    }
};

const LeadList = ({ formats }) => {
    const history = useHistory();
    const [dealerIds, setDealerIds] = useState(null);

    const { formatCurrencyDown } = formats;
    const { id: countryId } = useSelector(getCurrentCountry);
    const zoneId = useSelector(getZoneId);

    // searching
    const [search, setSearch] = useState('');
    const searchFields = useSearchFields();

    // sorting
    const [sortedOn, dispatch] = useReducer(reducer, { type: 'identifier', order: 'DESC' });

    const onSort = sortKeys => {
        dispatch({ type: 'UPDATE', payload: sortKeys });
    };

    // paging
    const [paging] = useServerPaging({ search });

    // based on zone or channel
    const query = zoneId ? getTables : getTablesByCountryId;
    const variables = {
        id: zoneId || countryId,
        dealerIds,
        sorting: [
            {
                ...sortedOn,
                order: sortedOn.order.toUpperCase(),
            },
        ],
        paging: {
            limit: paging.pageSize,
            offset: (paging.page - 1) * paging.pageSize,
        },
        search: search
            ? searchFields.map(field => {
                  return { identifier: field, value: search };
              })
            : null,
    };

    const { data, loading, error } = useQuery(query, {
        fetchPolicy: 'cache-and-network',
        variables,
        skip: isNil(dealerIds),
    });
    const items = data?.results?.items || [];
    const isLoading = loading && items.length <= 0;

    useEffect(() => {
        paging.setItemCount(data?.results?.count || 0);
    }, [paging, data]);

    const { hasBank, hasFinanceProduct } = useMemo(
        () => ({
            hasFinanceProduct: items.some(lead => lead.financeProduct),
            hasBank: items.some(lead => lead.bank.name),
        }),
        [items]
    );

    const columns = useColumns(hasFinanceProduct, hasBank, formatCurrencyDown);

    // Adding Source Field to Application Data
    const getChannel = get('channel');
    const getAccess = get('access');

    const itemsData = useMemo(
        () =>
            items.map(row => {
                const channel = getChannel(row);
                const access = getAccess(row);

                const source = getSourceLabel(channel, access);

                return {
                    ...row,
                    // hide variant for marketing reconsent
                    variant:
                        row.event?.setting.externalSite === EventExternalSite.MARKETINGRECONSENT ? null : row.variant,
                    source,
                };
            }),
        [getAccess, getChannel, items]
    );

    const [downloadModal, setDownloadModal] = useState(false);

    const closeDownloadModal = useCallback(() => setDownloadModal(null), [setDownloadModal]);

    const openDownloadModal = useCallback(
        () => setDownloadModal(<DownloadModal dealerIds={dealerIds} onClose={closeDownloadModal} type="Leads" />),
        [setDownloadModal, dealerIds, closeDownloadModal]
    );

    const footer = (
        <FootContainer>
            <FootBar>
                <FootBarButton onClick={openDownloadModal}>download</FootBarButton>
            </FootBar>
        </FootContainer>
    );

    return (
        <>
            <List
                columns={columns}
                error={error}
                footer={footer}
                headerBottomComponent={<DealerSelectionDropDown dealerIds={dealerIds} onValueChanged={setDealerIds} />}
                headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
                items={itemsData}
                loading={isLoading}
                onItemClick={item => history.push(`/workflow/leads/${item.version.id}`, { showCustomerSection: true })}
                onSort={onSort}
                paging={paging}
                sortedOn={[sortedOn.type, sortedOn.order]}
                title="Leads"
            />
            {downloadModal}
        </>
    );
};

LeadList.propTypes = {
    formats: PropType.shape({
        formatCurrencyDown: PropType.func,
    }),
};

export default withCompanyFormatting(LeadList);
