import { useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, isNil, orderBy } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FootBar, FootBarButton, FootContainer } from '../../../../containers/Layout';
import { getCurrentCountry, getUser } from '../../../../selectors';
import { searchOnKeys } from '../../../../utilities/fp';
import * as core from '../../../../utils/permissions';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { List, cells, ListSearch, DealerSelectionDropDown } from '../../../ui/lists';
import usePaging from '../../../utilities/usePaging';
import { getTables } from './PromoList.graphql';

const searchFields = ['identifier', 'description', 'value'];

const useColumns = () => {
    const formatDateTime = useFormatDateTime();

    return useMemo(
        () => [
            { name: 'Code', id: 'identifier', renderCell: get('identifier'), hasSort: true, underline: true },
            { name: 'Description', id: 'description', renderCell: get('description'), hasSort: true },
            { name: 'Value', id: 'value', renderCell: get('value'), hasSort: true },
            { name: 'Initial Qty', id: 'initialQuantity', renderCell: get('initialQuantity'), hasSort: true },
            { name: 'Remaining Qty', id: 'remainingQuantity', renderCell: get('remainingQuantity'), hasSort: true },
            {
                name: 'Start Date',
                id: 'period.start',
                renderCell: cells.renderDateTime('period.start', formatDateTime),
                hasSort: true,
            },
            {
                name: 'End Date',
                id: 'period.end',
                renderCell: cells.renderDateTime('period.end', formatDateTime),
                hasSort: true,
            },
            { name: 'Active', id: 'isActive', renderCell: cells.renderActive(), hasSort: true },
        ],
        [formatDateTime]
    );
};

const sortItems = (items, [sortKey, sortOrder]) => {
    // we always sort by value key
    return orderBy(sortKey, sortOrder, items);
};

const PromoList = () => {
    const history = useHistory();
    const currentCountry = useSelector(getCurrentCountry);
    const [dealerIds, setDealerIds] = useState(null);

    const { id } = currentCountry;
    const variables = { id, dealerIds };
    const { data, loading, error } = useQuery(getTables, {
        fetchPolicy: 'cache-and-network',
        variables,
        skip: isNil(dealerIds),
    });
    const items = data?.results?.items || [];
    const isLoading = loading && items.length <= 0;

    const user = useSelector(getUser) || {};
    const mayManagePromo = useMemo(() => core.mayManagePromo(user, currentCountry), [currentCountry, user]);

    // searching
    const [search, setSearch] = useState('');
    const searchMethod = useMemo(() => searchOnKeys(searchFields, items), [items]);
    const matchedItems = useMemo(() => searchMethod(search), [search, searchMethod]);

    // sorting
    const [sortedOn, onSort] = useState(['code', 'asc']);
    const sortedItems = useMemo(() => sortItems(matchedItems, sortedOn), [matchedItems, sortedOn]);

    // paging
    const [pagedItems, paging] = usePaging(sortedItems, { search });

    const columns = useColumns();

    const footer = (
        <FootContainer>
            <FootBar>
                {mayManagePromo && (
                    <FootBarButton onClick={() => history.push('/promo/new')}>
                        <FontAwesomeIcon icon={faPlus} /> ADD Promo Code
                    </FootBarButton>
                )}
            </FootBar>
        </FootContainer>
    );

    return (
        <List
            columns={columns}
            error={error}
            footer={footer}
            headerBottomComponent={<DealerSelectionDropDown dealerIds={dealerIds} onValueChanged={setDealerIds} />}
            headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
            items={pagedItems}
            loading={isLoading}
            onItemClick={item => history.push(`/promo/${item.id}`)}
            onSort={onSort}
            paging={paging}
            sortedOn={sortedOn}
            title="Promo Codes"
        />
    );
};

export default PromoList;
