import { get } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { onOffOptions } from '../../../../../shared/constants/options';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import NumberField from '../../../../template/Number';
import ApvPortlet from '../../../../template/Portlet';
import SwitchField from '../../../../template/Switch';
import TextareaField from '../../../../template/Textarea';
import { useFormContext } from './context';

const MainDetails = () => {
    const { ct, formatPath } = useContentTranslation();
    const { values, zones } = useFormContext();
    const edition = !!get('lastModified', values);

    const zoneOptions = useMemo(() => zones?.map(zone => ({ ...zone, label: ct(zone.label) })), [zones, ct]);

    return (
        <ApvPortlet name="Main Details" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Insurance Company Name">
                            <Field component={InputField} maxLength={50} name={formatPath('name')} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField
                            label="Zones"
                            name="zoneIds"
                            options={zoneOptions}
                            placeholder="Select Zone"
                            multi
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Email">
                            <Field component={InputField} name="email" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Order No.">
                            <Field component={NumberField} name="order" precision={0} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Active">
                            <Field component={SwitchField} name="isActive" options={onOffOptions} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Remote Flow - Acknowledgment info">
                            <Field
                                component={TextareaField}
                                maxLength="1000"
                                name={formatPath('remoteFlowAcknowledgmentInfo')}
                            />
                        </FieldContainer>
                    </div>

                    {edition && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Last Modified">
                                <Field component={InputField} name="lastModified" disabled />
                            </FieldContainer>
                        </div>
                    )}
                </div>
            </div>
        </ApvPortlet>
    );
};

export default MainDetails;
