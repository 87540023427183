import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import NumberFormat from 'react-number-format';

const KEYCODE_UP = 38;
const KEYCODE_DOWN = 40;

const toNumber = (value, precision = 10) => {
    const q = 10 ** precision;

    // fix decimal precision
    return Math.round(value * q) / q;
};

const NumericInput = ({
    max = Number.MAX_SAFE_INTEGER,
    min = Number.MIN_SAFE_INTEGER,
    step = 1,
    onChange,
    value,
    precision = 0,
    inputRef,
    ...props
}) => {
    const inputValue = useRef(value);

    const onKeyPress = useCallback(
        event => {
            const keyCode = event.keyCode || event.which;
            const prevent = !(
                (keyCode >= 48 && keyCode <= 57) ||
                (keyCode === 46 && precision > 0) ||
                (min && min < 0 && keyCode === 45) ||
                keyCode === 13
            );

            if (prevent) {
                event.preventDefault();
            }

            return prevent;
        },
        [min, precision]
    );

    const counter = useCallback(
        n => {
            const computedValue = toNumber(value + step * n, precision);

            // ensure value is within limits
            onChange(Math.min(Math.max(computedValue, min), max));
        },
        [value, step, precision, onChange, min, max]
    );

    const onKeyDown = useCallback(
        event => {
            const keyCode = event.keyCode || event.which;

            switch (keyCode) {
                case KEYCODE_UP:
                    counter(1);
                    event.preventDefault();
                    break;
                case KEYCODE_DOWN:
                    counter(-1);
                    event.preventDefault();
                    break;
                default:
                    break;
            }
        },
        [counter]
    );

    // onValueChange is triggered whenever there is change in value
    // which can be caused by any event like change or blur event or by a prop change
    const onValueChange = useCallback(val => {
        inputValue.current = val;
    }, []);

    // we limit the value changes only when the user is typing
    const change = useCallback(() => {
        onChange(inputValue.current.floatValue ?? '');
    }, [onChange, inputValue]);

    return (
        <NumberFormat
            ref={inputRef}
            decimalScale={precision}
            onChange={change}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            onValueChange={onValueChange}
            value={value}
            {...props}
        />
    );
};

NumericInput.propTypes = {
    inputRef: PropTypes.shape({}),
    max: PropTypes.number,
    min: PropTypes.number,
    onChange: PropTypes.func,
    precision: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
};

export default NumericInput;
