import styled from 'styled-components';

const ListTabDropDownContainer = styled.div`
    position: relative;
    top: 8px;

    button.dropdown-toggle {
        background: none !important;
        border: none !important;
        padding: 0;
        margin: 0;
        font-size: 1.14rem;
        position: relative;
        top: -2px;
        cursor: pointer;
        color: #000000 !important;
        &::after {
            display: none;
        }
    }

    svg {
        margin-left: 4px;
        margin-right: 4px;
        position: relative;
        top: 2px;
        width: 7.5px !important;
        color: #000000;
    }

    .dropdown-item {
        text-align: right;
        color: #000000;
    }

    .dropdown-menu.show {
    }

    .dropdown-item:hover {
        background-color: #d8d8d8;
        color: #000000;
        cursor: pointer;
    }
    .show > .btn.btn-secondary {
        color: #000000 !important;
    }

    .show > .btn-secondary.dropdown-toggle:focus {
        box-shadow: none;
    }
`;

export default ListTabDropDownContainer;
