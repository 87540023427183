import { get } from 'lodash/fp';
import { Channel } from '../../../../../../schema';
import * as validators from '../../../../../../utils/validators';
import {
    customerPrimaryInfoValidators,
    customerValidators,
    customerPrimaryInfoOnlyValidators,
} from '../../../../Customer/Form/validate';

const validate = validators.compose(
    (values, context, errors) => {
        const allowPrimaryInfoChanges = !values.appliedForFinancing && !!values.reservationDeposit;
        const isKYCMandatory = get('financeProduct.bank.isKYCMandatory', values) || false;

        if (!isKYCMandatory) {
            return customerPrimaryInfoOnlyValidators('customer')(values, context, errors);
        }

        if (allowPrimaryInfoChanges) {
            return customerPrimaryInfoValidators('customer')(values, context, errors);
        }

        return customerValidators('customer')(values, context, errors);
    },

    validators.only(values => !!get('guarantor', values), validators.compose(customerValidators('guarantor'))),

    validators.only(
        values => get('channel', values) === Channel.EXPRESS,
        validators.compose(
            validators.requiredString('variant.model.make.name'),
            validators.requiredString('variant.model.name'),
            validators.only(
                (_, context) => context?.countryCode === 'SG',
                validators.compose(
                    validators.requiredString('variant.preOwnedCarDetails.ownerIdType'),
                    validators.requiredString('variant.preOwnedCarDetails.ownerIdSuffix'),
                    validators.minStringLength('variant.preOwnedCarDetails.ownerIdSuffix', 4),
                    validators.maxStringLength('variant.preOwnedCarDetails.ownerIdSuffix', 4)
                )
            )
        )
    )
);

export default validate;
