import { useQuery } from '@apollo/client';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { compose } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import { FormTabContainer } from '../../../../../containers/Layout';
import {
    BalloonMode,
    DepositMode,
    DerivedMethod,
    DownPaymentMode,
    FinanceProductBase,
    FinanceProductSubType,
    InterestRateMode,
} from '../../../../../schema';
import { getCurrentCountry } from '../../../../../selectors';
import ApvPortlet from '../../../../template/Portlet';
import BalloonTab from './BalloonTab';
import DepositTab from './DepositTab';
import Details from './Details';
import DownpaymentTab from './DownpaymentTab';
import FuelTaxTab from './FuelTaxTab';
import General from './General';
import InsuranceFeeTab from './InsuranceFeeTab';
import InterestRateTab from './InterestRateTab';
import Languages from './Languages';
import LeaseTab from './LeaseTab';
import LicenseTaxTab from './LicenseTaxTab';
import ResidualValueBalloonGFVTab from './ResidualValueBalloonGFVTab';
import ResidualValueTab from './ResidualValueTab';
import VehicleAssignment from './VehicleAssignment';
import { Context } from './context';
import { getData, getFinderVehicles } from './data.graphql';

const Form = props => {
    const { form, languageOptions } = props;
    const values = useSelector(getFormValues(form));

    const { id: countryId, currency, rounding, usePorscheFinder } = useSelector(getCurrentCountry);
    const variables = { countryId };
    const { data, loading } = useQuery(getData, { fetchPolicy: 'cache-and-network', variables });

    const { data: finderData, loading: finderLoading } = useQuery(getFinderVehicles, {
        fetchPolicy: 'cache-and-network',
        variables,
        skip: !usePorscheFinder,
    });

    // this function was used inside Details but not defined somehow
    const onBasedOnChange = useCallback(
        value => {
            const loanSetting = get('loanSetting', values);
            const downPaymentSetting = get('downPaymentSetting', values);

            // based on setting editable switch will be disabled
            // so change it during basedOn change
            if (
                value === FinanceProductBase.LOAN &&
                loanSetting?.show &&
                !loanSetting?.editable &&
                loanSetting?.min !== loanSetting?.max
            ) {
                props.change?.('loanSetting.editable', true);
            }
            if (
                value === FinanceProductBase.DOWNPAYMENT &&
                downPaymentSetting?.show &&
                !downPaymentSetting?.editable &&
                downPaymentSetting?.min !== downPaymentSetting?.max
            ) {
                props.change?.('downPaymentSetting.editable', true);
            }
        },
        [props, values]
    );

    const context = { ...props, values, onBasedOnChange, apiData: { ...data, ...finderData }, currency, rounding };

    const downPaymentMode = get('downPaymentSetting.mode', values);
    const interestRateMode = get('interestRateSetting.mode', values);
    const balloonMode = get('balloonSetting.mode', values);
    const financeSubType = get('subType', values);
    const derivedMethod = get('paymentMode.derivedMethod', values);
    const depositMode = get('depositSetting.mode', values);

    const displayDownpayment = downPaymentMode === DownPaymentMode.TABLE;
    const displayInterestRate = interestRateMode === InterestRateMode.TABLE;
    const displayBalloon = balloonMode === BalloonMode.TABLE;
    const displayLease = financeSubType === FinanceProductSubType.LEASE && derivedMethod === DerivedMethod.LOOKUPTABLE;
    const displayDeposit = depositMode === DepositMode.TABLE;
    const displayResidualValue =
        financeSubType === FinanceProductSubType.LEASEPURCHASE ||
        financeSubType === FinanceProductSubType.LEASEPURCHASEICC;
    const displayResidualValueBalloonGFV = financeSubType === FinanceProductSubType.HIREPURCHASEBALLOONGFV;

    const displayUcclTab = financeSubType === FinanceProductSubType.UCCLLEASING;

    const displayMultiLanguage = languageOptions.length > 1;

    if (loading || finderLoading) {
        return null;
    }

    return (
        <Context.Provider value={context}>
            <FormTabContainer>
                <Tabs forceRenderTabPanel>
                    <TabList>
                        <Tab>Main Details</Tab>
                        {displayMultiLanguage && <Tab>Multi-Language</Tab>}
                        <Tab>Parameters</Tab>
                        <Tab>Assign Vehicles</Tab>
                        {displayDownpayment && <Tab>Downpayment</Tab>}
                        {displayInterestRate && <Tab>Interest Rate</Tab>}
                        {displayResidualValue && <Tab>Residual Value</Tab>}
                        {displayResidualValueBalloonGFV && <Tab>Residual Value</Tab>}
                        {displayBalloon && <Tab>Balloon</Tab>}
                        {displayLease && <Tab>Lease Reference</Tab>}
                        {displayDeposit && <Tab>Deposit</Tab>}
                        {displayUcclTab && <Tab>License Tax</Tab>}
                        {displayUcclTab && <Tab>Fuel Tax</Tab>}
                        {displayUcclTab && <Tab>Insurance Fee</Tab>}
                    </TabList>
                    <TabPanel>
                        <General />
                    </TabPanel>
                    {displayMultiLanguage && (
                        <TabPanel>
                            <Languages />
                        </TabPanel>
                    )}
                    <TabPanel>
                        <Details />
                    </TabPanel>
                    <TabPanel>
                        <VehicleAssignment />
                    </TabPanel>
                    {displayDownpayment && (
                        <TabPanel>
                            <ApvPortlet name="Downpayment Table" closable open>
                                <DownpaymentTab />
                            </ApvPortlet>
                        </TabPanel>
                    )}
                    {displayInterestRate && (
                        <TabPanel>
                            <ApvPortlet name="Interest Rate Table" closable open>
                                <InterestRateTab />
                            </ApvPortlet>
                        </TabPanel>
                    )}
                    {displayResidualValue && (
                        <TabPanel>
                            <ApvPortlet name="Residual Value" closable open>
                                <ResidualValueTab />
                            </ApvPortlet>
                        </TabPanel>
                    )}
                    {displayResidualValueBalloonGFV && (
                        <TabPanel>
                            <ApvPortlet name="Residual Value" closable open>
                                <ResidualValueBalloonGFVTab />
                            </ApvPortlet>
                        </TabPanel>
                    )}
                    {displayBalloon && (
                        <TabPanel>
                            <ApvPortlet name="Balloon Table" closable open>
                                <BalloonTab />
                            </ApvPortlet>
                        </TabPanel>
                    )}
                    {displayLease && (
                        <TabPanel>
                            <ApvPortlet name="Lease Reference Table" closable open>
                                <LeaseTab />
                            </ApvPortlet>
                        </TabPanel>
                    )}
                    {displayDeposit && (
                        <TabPanel>
                            <ApvPortlet name="Deposit Table" closable open>
                                <DepositTab />
                            </ApvPortlet>
                        </TabPanel>
                    )}
                    {displayUcclTab && (
                        <TabPanel>
                            <ApvPortlet name="License Tax Table" closable open>
                                <LicenseTaxTab />
                            </ApvPortlet>
                        </TabPanel>
                    )}
                    {displayUcclTab && (
                        <TabPanel>
                            <ApvPortlet name="Fuel Tax Table" closable open>
                                <FuelTaxTab />
                            </ApvPortlet>
                        </TabPanel>
                    )}
                    {displayUcclTab && (
                        <TabPanel>
                            <ApvPortlet name="Insurance Fee Table" closable open>
                                <InsuranceFeeTab />
                            </ApvPortlet>
                        </TabPanel>
                    )}
                </Tabs>
            </FormTabContainer>
        </Context.Provider>
    );
};

Form.propTypes = {
    // From redux form
    change: PropTypes.func,
    form: PropTypes.string.isRequired,
    languageOptions: PropTypes.arrayOf({}),
};

export default compose(reduxForm({ form: 'financeProduct' }), memo)(Form);
