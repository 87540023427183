import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { submit } from 'redux-form';
import { addNotification } from '../../../../actions';
import { getGlobalPermissions } from '../../../../selectors';
import { HIERARCHY_SAVE_NOTIFICATION } from '../../../../shared/constants/notification';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import FormLayout from '../../../ui/form/FormLayout';
import Form from './Form';
import { update } from './Page.graphql';

const Page = ({ initialValues }) => {
    const client = useApolloClient();
    const dispatch = useDispatch();
    const history = useHistory();
    const { mayManageRolesHierarchy } = useSelector(getGlobalPermissions);

    const onCancel = useCallback(() => history.goBack(), [history]);

    const onSubmit = useCallback(
        values => {
            const variables = {
                ...prepareForGraphQL(values),
                parentId: values.parentId || null,
            };

            return client.mutate({ mutation: update, variables }).catch(handleResponseError);
        },
        [client]
    );

    const onSubmitSuccess = useCallback(() => {
        dispatch(addNotification(HIERARCHY_SAVE_NOTIFICATION));
        history.push('/access/hierarchies');
    }, [dispatch, history]);

    const submitForm = useCallback(() => dispatch(submit('hierarchy')), [dispatch]);

    return (
        <FormLayout
            bodyComponent={
                initialValues && (
                    <Form
                        disabled={!mayManageRolesHierarchy}
                        initialValues={initialValues}
                        isUpdate={!!initialValues?.id}
                        onSubmit={onSubmit}
                        onSubmitSuccess={onSubmitSuccess}
                    />
                )
            }
            moreActions={[
                mayManageRolesHierarchy && {
                    label: 'save',
                    onAction: submitForm,
                },
            ]}
            onCancel={onCancel}
            title="Hierarchy Details"
        />
    );
};

Page.propTypes = {
    initialValues: PropTypes.shape(),
};

export default Page;
