import PropTypes from 'prop-types';
import React from 'react';
import CurrencyInput from 'react-currency-input';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    input {
        width: 100%;
        border: 0;
        color: #000000;
        background: #f0f0f0;
        height: 30px;
        font-size: 0.85rem;
        padding: 0 10px;
    }
    input[type='text']:disabled {
        background: #d8d8d8;
    }
`;

const StaticCurrencyField = ({ precision, prefix, value }) => {
    return (
        <Container>
            <CurrencyInput
                precision={precision}
                prefix={prefix ? `${prefix} ` : prefix} // add extra space between numbers and prefix
                value={value}
                allowEmpty
                disabled
            />
        </Container>
    );
};

StaticCurrencyField.propTypes = {
    precision: PropTypes.number,
    prefix: PropTypes.string,
    value: PropTypes.number,
};

export default StaticCurrencyField;
