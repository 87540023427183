import { AUTHORIZATION_SET, AUTHORIZATION_UNSET } from '../actions/authorization';
import persistState from './persistState';

const reducer = (state = {}, { type, ...payload }) => {
    switch (type) {
        case AUTHORIZATION_SET:
            return payload.authorization;

        case AUTHORIZATION_UNSET:
            return {};

        default:
            return state;
    }
};

export default persistState('authorization', reducer);
