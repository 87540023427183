import { createContext, useContext } from 'react';
import { UserDataFragment } from '../../../ProfileRoute/Page.graphql';

export type RoleFormContext = {
    disabled: boolean;
    isUpdate: boolean;
    values: { users: UserDataFragment[]; permissions: { [key: string]: string } };
    form: string;
};

export const Context = createContext<RoleFormContext | null>(null);

export const useFormContext = () => useContext(Context);
