import { Paging } from '@appvantageasia/afc-ui';
import { isNil } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { AdminBgContainer, ContentBody, ScrollContainer } from '../../../containers/Layout';
import LoadingLayer from '../../common/LoadingLayer';
import { TextAlignCenter } from '../TextAlign';
import ListHeader from './ListHeader';
import ListTable from './ListTable';

const NoItemsMessage = styled(TextAlignCenter)`
    margin: 20px;
`;

const List = ({
    activeTab = null,
    title,
    headerBottomComponent,
    headerLeftComponent,
    tabs = null,
    tabWidth,
    bodyComponent,
    columns,
    items,
    onSort,
    onItemClick,
    getItemKey,
    tableClassName,
    footer,
    sortedOn,
    paging,
    loading = false,
    error,
}) => {
    if (error) {
        throw error;
    }

    return (
        <AdminBgContainer>
            {loading ? (
                <LoadingLayer />
            ) : (
                <ScrollContainer>
                    <ListHeader
                        activeTab={activeTab}
                        bottomComponent={headerBottomComponent}
                        leftComponent={headerLeftComponent}
                        tabWidth={tabWidth}
                        tabs={tabs}
                        title={title}
                    />
                    <ContentBody>
                        {bodyComponent || (
                            <>
                                <ListTable
                                    className={tableClassName}
                                    columns={columns}
                                    getKey={getItemKey}
                                    items={items}
                                    onItemClick={onItemClick}
                                    onSort={onSort}
                                    sortedOn={sortedOn}
                                />
                                {paging && <Paging {...paging} />}
                            </>
                        )}
                    </ContentBody>
                    {isNil(bodyComponent) && !items?.length && (
                        <NoItemsMessage>There are currently no {title.toLowerCase()}</NoItemsMessage>
                    )}
                </ScrollContainer>
            )}
            {footer}
        </AdminBgContainer>
    );
};

List.propTypes = {
    activeTab: PropTypes.string,
    bodyComponent: PropTypes.node,
    columns: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    error: PropTypes.oneOfType([PropTypes.shape, PropTypes.func]),
    footer: PropTypes.node,
    getItemKey: PropTypes.func,
    headerBottomComponent: PropTypes.node,
    headerLeftComponent: PropTypes.node,
    items: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    loading: PropTypes.bool,
    onItemClick: PropTypes.func,
    onSort: PropTypes.func,
    paging: PropTypes.shape({}),
    sortedOn: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
    tableClassName: PropTypes.string,
    tabs: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    tabWidth: PropTypes.number,
    title: PropTypes.node.isRequired,
};

export default List;
