import { useQuery } from '@apollo/client';
import { isNil } from 'lodash/fp';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCountry, getZoneId } from '../../../selectors';
import { getApplicationChart, GetApplicationChartQuery } from './ApplicationChart.graphql';
import DayChart from './DayChart';
import { useDashboardContext } from './context';

const title = 'Leads Submitted';
const xAxisTitle = 'Lead Date';
const yAxisTitle = 'No. of Submitted Leads';

const LeadChart = () => {
    const { id: countryId } = useSelector(getCurrentCountry);
    const zoneId = useSelector(getZoneId);
    const { channels, dealerIds } = useDashboardContext();

    const { data } = useQuery<GetApplicationChartQuery>(getApplicationChart, {
        skip: isNil(dealerIds) || dealerIds.length < 1 || channels.length < 1,
        fetchPolicy: 'cache-and-network',
        variables: {
            filter: { zoneId, countryId, dealerIds, channels },
            isLead: true,
        },
    });

    return <DayChart data={data} id="lead" title={title} xAxisTitle={xAxisTitle} yAxisTitle={yAxisTitle} />;
};

export default LeadChart;
