import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Box = styled.button`
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 5px;
    padding: 3px;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-radius: 2px;

    ${props =>
        props.active &&
        css`
            border-color: ${props.theme.calculator.valueColor};
        `}
`;

const Inner = styled.div`
    background-color: ${props => props.color};
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
    }
`;

const Item = ({ active, color, image, onClick }) => (
    <Box active={active} onClick={onClick} type="button">
        <Inner color={color}>{image && <img alt="" src={image} />}</Inner>
    </Box>
);

Item.propTypes = {
    active: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired,
    image: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

const CarOptions = ({ activeOption, options, onChange }) => (
    <Container>
        {options.map(option => (
            <Item
                key={option.id}
                active={activeOption === option.id}
                color={option.colour}
                image={option.image?.url}
                onClick={() => onChange(option)}
            />
        ))}
    </Container>
);

CarOptions.propTypes = {
    activeOption: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            colour: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            image: PropTypes.shape({
                url: PropTypes.string,
            }),
        }).isRequired
    ).isRequired,
};

export default CarOptions;

export const OptionInfo = styled.div`
    border-top: 1px solid #ced4da;
    color: #000000;
    margin-top: 30px;
    padding-top: 30px;
`;

export const OptionContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    top: -3px;
`;

export const OptionWithPrice = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    width: fit-content;
    max-width: calc(100% - 60px);
    margin: 0 auto;
    justify-content: center;
    margin-left: 30px;

    & > span {
        white-space: nowrap;

        &:first-child {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:nth-child(2) {
            padding-left: 5px;
        }
    }
`;

export const Thumbnails = styled.div`
    width: 25px;
    height: 25px;
    min-width: 25px;
    max-width: 25px;
    min-height: 25px;
    max-height: 25px;
    background-color: ${props => props.color};
    margin-left: 5px;
    border-radius: 3px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
    }
`;

export const OptionWithoutThumbnails = styled.div`
    display: flex;
    flex-direction: row;
`;
