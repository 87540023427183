import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { getLastModified } from '../../../../utilities/forms';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { getInventory } from './Edition.graphql';
import Page from './Page';

const Edition = () => {
    const { id } = useParams();
    const variables = { id };
    const { data, loading } = useQuery(getInventory, { variables, fetchPolicy: 'network-only' });
    const formatDateTime = useFormatDateTime();

    const initialValues = useMemo(() => {
        const values = data?.inventory;

        if (!values) {
            return null;
        }

        const { variant, identifier } = values;

        return {
            id,
            identifier,
            variantId: variant.id,
            subModelId: variant.model.parentId ? variant.model.id : null,
            modelId: variant.model.parentId || variant.model.id,
            lastModified: getLastModified(values.version, formatDateTime),
        };
    }, [data.inventory, id, formatDateTime]);

    if (!loading && !initialValues) {
        // the product does not exist
        return <Redirect to="/vehicle/inventories" />;
    }

    return <Page initialValues={initialValues} />;
};

export default Edition;
