import React from 'react';
import { Field } from 'redux-form';
import { yesNoOptions } from '../../../../../../shared/constants/options';
import CurrencyField from '../../../../../template/Currency';
import FieldContainer from '../../../../../template/Field-container';
import SwitchField from '../../../../../template/Switch';

type PPSRProps = {
    currency: string;
    precision: number;
};

const PPSR = ({ currency, precision }: PPSRProps) => (
    <>
        <hr />
        <div className="row">
            <div className="col-md-4 col-sm-12 col-xs-12">
                <FieldContainer label="PPSR Fee Amount">
                    <Field
                        component={CurrencyField}
                        name="ppsr.amount"
                        precision={precision}
                        prefix={currency}
                        allowNegative
                    />
                </FieldContainer>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12">
                <FieldContainer label="Editable">
                    <Field component={SwitchField} name="ppsr.editable" options={yesNoOptions} />
                </FieldContainer>
            </div>
        </div>
    </>
);

export default PPSR;
