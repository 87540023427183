import Modal from 'react-modal';
import styled from 'styled-components';

export const ModalContainer = styled(Modal)`
    width: 600px;
    height: auto;
    max-height: 400px;
`;

export const AssignApplicationContainer = styled.div`
    position: relative;
    padding: 10px;
    .close__button {
        cursor: pointer;
        font-weight: bold;
        font-size: 2rem;
        color: white;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .heading {
        margin-bottom: 13px;
        text-align: center;
        font-size: 1.14rem;
    }
    .remind-info {
        padding: 0 10px;
        text-align: left;
        font-size: 1.07rem;
        margin-bottom: 10px;
        opacity: 0.5;
    }
    .body {
        flex-grow: 1;
        overflow: auto;
        .body-list-item {
            cursor: pointer;
            border-bottom: 1px solid rgba(255, 255, 255, 0.56);
            padding: 5px 0;
            margin-right: 25px;
            &:nth-last-child(1) {
                border-bottom: 0;
            }
            .radio-input {
                margin: 0 10px;
            }
        }
    }
    .footing {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .confirm-button {
        width: 80px;
        margin-top: 15px;
        font-weight: bold;
        align-self: center;
        background-color: transparent;
        border: 1px rgba(255, 255, 255, 0.56) solid;
        color: #fff;
        padding: 5px 15px;
        text-align: center;
        cursor: pointer;
    }
`;
