import * as validators from '../../../../utils/validators';

const validate = validators.compose(
    validators.requiredDate('start'),
    validators.requiredString('startTimeZone'),
    validators.requiredDate('end'),
    validators.requiredString('endTimeZone'),
    validators.startDateMayNotExceedEndDate('start', 'end'),
    validators.requiredString('title'),
    validators.requiredBoolean('isValid'),
    validators.requiredNumber('warningBefore')
);

export default validate;
