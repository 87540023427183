import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getCurrentCountry } from '../../../../selectors';
import Page from './Page';

const Creation = () => {
    const country = useSelector(getCurrentCountry);
    const { groupId = null } = useParams();

    const initialValues = useMemo(
        () => ({
            countryId: country.id,
            groupId,
            colour: '#ef2c72',
            isActive: true,
            variants: [],
        }),
        [country.id, groupId]
    );

    return <Page initialValues={initialValues} />;
};

export default Creation;
