import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, batch } from 'react-redux';
import { useHistory } from 'react-router';
import {
    setAuthorization,
    setCompanyCode,
    setCountryCode,
    setZoneCode,
    setUser as setUserAction,
    setDealer,
} from '../../../actions';
import { LoginType } from '../../../schema';
import { handleResponseError } from '../../../utilities/forms';
import SelectionForm from './SelectionForm';
import { authenticate } from './api.graphql';
import getUserWithPermissions from './getUserWithPermissions';

const SelectionStep = ({ companies, username, password }) => {
    const client = useApolloClient();

    // we need to be able to dispatch redux action
    const dispatch = useDispatch();

    // also requires the history object
    const history = useHistory();

    // the callback to execute on submissions
    const onSubmit = useCallback(
        ({ companyId, countryId, zoneId, dealerId }) => {
            const variables = { username, password, countryId, zoneId, loginType: LoginType.ADMIN };

            return client
                .mutate({ mutation: authenticate, variables })
                .then(({ data: { authorization } }) => ({
                    ...{ ...authorization, user: getUserWithPermissions(authorization.user) },
                    // append entities ids
                    companyId,
                    countryId,
                    zoneId,
                    dealerId,
                }))
                .catch(handleResponseError);
        },
        [client, username, password]
    );

    // the callback to execute on successful submissions
    const onSubmitSuccess = useCallback(
        authorization => {
            const { token, user, companyId, countryId, zoneId, dealerId } = authorization;

            // get entities
            const company = companies.find(i => i.id === companyId);
            const country = company.countries.find(i => i.id === countryId);
            const zone = country.zones.find(i => i.id === zoneId);
            const dealer = country.dealers.find(i => i.id === dealerId);
            batch(() => {
                // persist authorization tokens
                dispatch(setAuthorization(token));
                // persist user data retrieved earlier
                dispatch(setUserAction(user));
                // update dealer to specify the selected dealer
                dispatch(setDealer(dealer));
                // update codes to specify the selected scope
                dispatch(setCompanyCode(company.code));
                dispatch(setCountryCode(country.code));
                dispatch(setZoneCode(zone.code));
                // then finally redirect
                history.push('/home');
            });
        },
        [dispatch, history, companies]
    );

    return (
        <SelectionForm companies={companies} initialValues={{}} onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess} />
    );
};

SelectionStep.propTypes = {
    companies: PropTypes.arrayOf(
        PropTypes.shape({
            countries: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    zones: PropTypes.arrayOf(
                        PropTypes.shape({
                            id: PropTypes.string.isRequired,
                            name: PropTypes.shape({}).isRequired,
                        })
                    ),
                })
            ),
            id: PropTypes.string.isRequired,
            name: PropTypes.shape({}).isRequired,
        })
    ),
    password: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
};

export default SelectionStep;
