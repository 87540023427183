import { isEmpty, isNil } from 'lodash/fp';
import { useMemo } from 'react';
import { Unit } from '../../../../../../schema';
import { FinanceDataFragment } from '../../data/useFinanceProducts.graphql';

const getInitialValue = (min: number, max: number, initial: number) => {
    // return original default value if still valid
    if (initial >= min && initial <= max) {
        return initial;
    }

    // otherwise return max value as default
    return max;
};

export const getDownPaymentSetting = (
    downPaymentSetting: FinanceDataFragment['downPaymentSetting'],
    expressDownPaymentSetting: FinanceDataFragment['downPaymentSetting'] | undefined,
    totalPrice?: number
) => {
    // there's no express setting passed
    // also downpaymen setting unit is in currency
    if (isNil(expressDownPaymentSetting)) {
        return downPaymentSetting;
    }

    // express downpayment changes was min
    const currencySetting: Partial<FinanceDataFragment['downPaymentSetting']> = {};
    if (downPaymentSetting.defaultUnit === Unit.CURRENCY) {
        currencySetting.min = (expressDownPaymentSetting.min * (totalPrice ?? 0)) / 100;
    }

    const mergedSetting = {
        ...downPaymentSetting,
        ...expressDownPaymentSetting,
        ...currencySetting,
    };

    // express setting is not yet configured
    if (isNil(mergedSetting.min) || isNil(mergedSetting.max)) {
        return mergedSetting;
    }

    // calculate min percentage
    // min and max from express setting
    const { min: updatedMin, max } = mergedSetting;
    const min = updatedMin > downPaymentSetting.min ? updatedMin : downPaymentSetting.min;

    const initial = getInitialValue(min, max, downPaymentSetting.default);

    return {
        ...mergedSetting,
        default: initial,
        min,
        max,
    };
};

export const getLoanSetting = (
    loanSetting: FinanceDataFragment['loanSetting'],
    expressLoanSetting: FinanceDataFragment['loanSetting'] | undefined,
    totalPrice?: number
) => {
    // there's no express setting passed
    // or loan setting default unit is in currency
    if (isNil(expressLoanSetting)) {
        return loanSetting;
    }

    // express loan changes was max
    const currencySetting: Partial<FinanceDataFragment['loanSetting']> = {};
    if (loanSetting.defaultUnit === Unit.CURRENCY) {
        currencySetting.max = (expressLoanSetting.max * (totalPrice ?? 0)) / 100;
    }

    const mergedSetting = {
        ...loanSetting,
        ...expressLoanSetting,
        ...currencySetting,
    };

    // express setting is not yet configured
    if (isNil(mergedSetting.min) || isNil(mergedSetting.max)) {
        return mergedSetting;
    }

    // calculate max percentage
    // min and max from express setting
    const { min, max: updatedMax } = mergedSetting;
    const max = updatedMax < loanSetting.max ? updatedMax : loanSetting.max;

    const initial = getInitialValue(min, max, loanSetting.default);

    return {
        ...mergedSetting,
        default: initial,
        min,
        max,
    };
};

export const getTermSetting = (
    termSetting: FinanceDataFragment['termSetting'],
    expressTermSetting: FinanceDataFragment['termSetting'] | undefined
) => {
    // there's no express setting passed
    if (isNil(expressTermSetting)) {
        return termSetting;
    }

    const mergedSetting = {
        ...termSetting,
        ...expressTermSetting,
    };

    // express setting is not yet configured
    if (isNil(mergedSetting.min) || isNil(mergedSetting.max)) {
        return mergedSetting;
    }

    // calculate max range
    // min and max from express setting
    const { min, max: updatedMax, step } = mergedSetting;
    // ensure updated max is divisible by interval
    const calculatedMax = Math.floor(updatedMax / step) * step;
    const max = calculatedMax < termSetting.max ? calculatedMax : termSetting.max;

    const initial = getInitialValue(min, max, termSetting.default);

    return {
        ...mergedSetting,
        default: initial,
        min,
        max,
    };
};

// map existing finance products
// with express finance product
// and variant id
const useExpressFinanceProducts = (
    financeProducts: FinanceDataFragment[],
    expressFinanceProduct: Partial<FinanceDataFragment> | undefined,
    variantVersionId: string,
    totalPrice?: number
) =>
    useMemo(() => {
        // refinements not exist
        if (isNil(expressFinanceProduct)) {
            return financeProducts.map(financeProduct => ({
                ...financeProduct,
                variants: [variantVersionId],
            }));
        }

        // express finance product is not yet ready
        if (isEmpty(expressFinanceProduct)) {
            return [];
        }

        return financeProducts.map(financeProduct => {
            return {
                ...financeProduct,
                variants: [variantVersionId],
                downPaymentSetting: getDownPaymentSetting(
                    financeProduct.downPaymentSetting,
                    expressFinanceProduct?.downPaymentSetting,
                    totalPrice
                ),
                loanSetting: getLoanSetting(financeProduct.loanSetting, expressFinanceProduct?.loanSetting, totalPrice),
                termSetting: getTermSetting(financeProduct.termSetting, expressFinanceProduct?.termSetting),
            };
        });
    }, [expressFinanceProduct, financeProducts, variantVersionId, totalPrice]);

export default useExpressFinanceProducts;
