import styled, { css } from 'styled-components';

const IS_IE = /(Trident|MSIE)/.test(window.navigator.userAgent);

export const RadioContainer = styled.div`
    width: 100%;

    ${props =>
        props.disabled &&
        css`
            opacity: 0.7;
            cursor: not-allowed;

            label {
                cursor: not-allowed;
            }
        `};
`;

export const Block = styled.div`
    width: ${props => (props.len > 2 ? '100%' : `${25 * props.len}%`)};
    display: grid;
    grid-column-gap: 1px;
    height: 30px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(${props => props.len}, 1fr);

    ${() =>
        IS_IE &&
        css`
            display: flex;

            > label {
                width: calc((100% - ${p => p.len - 1}px) / ${p => p.len});

                &:not(:last-child) {
                    margin-right: 1px;
                }
            }
        `};
`;

export const Label = styled.label`
    background-color: #f0f0f0;
    color: #000000;
    cursor: pointer;
    font-size: 0.85rem;
    text-transform: uppercase;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    ${props =>
        props.disabled &&
        css`
            opacity: 0.7;
            cursor: not-allowed;
        `};

    input {
        display: none;
    }

    ${props =>
        props.active &&
        css`
            background: #000000;
            color: #f4f4f4;
        `};
`;
