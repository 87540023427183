import * as validators from '../../../../../utils/validators';

const validate = validators.compose(
    validators.requiredString('identifier'),
    validators.requiredString('name'),
    (values, context, errors) => {
        const { isPorschePaymentAssortmentMandatory } = context;

        return isPorschePaymentAssortmentMandatory
            ? validators.requiredString('porschePaymentAssortment')(values, context, errors)
            : null;
    },
    (values, context, errors) => {
        const { country } = context;

        return country?.useAutoplay
            ? validators.compose(
                  validators.requiredNumber('autoplay.dealerId'),
                  validators.requiredNumber('autoplay.yardId')
              )(values, context, errors)
            : null;
    }
);

export default validate;
