import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash/fp';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useContentTranslation } from '../../../i18n';
import { ChannelBookingPayment, ChannelEstablishment } from '../../../schema';
import { getCurrentCountry } from '../../../selectors';
import { Container, Select } from '../../ui/dashboard/Header';
import { getData } from '../../ui/lists/DealerSelectionDropDown.graphql';

export type DealerOption = {
    label: string;
    value: string;
    bookingPayment?: ChannelBookingPayment;
    establishment?: ChannelEstablishment;
};

type DealerSelectionProps = {
    setOption: Dispatch<SetStateAction<string>>;
    setDealers: Dispatch<SetStateAction<DealerOption[]>>;
};

export enum DealerOptions {
    ALLDEALER = 'ALLDEALER',
}

const DealerSelection = ({ setOption, setDealers }: DealerSelectionProps) => {
    const { ct } = useContentTranslation();
    const { id: countryId } = useSelector(getCurrentCountry);

    const { data, loading } = useQuery(getData, {
        fetchPolicy: 'cache-and-network',
        variables: { countryId },
    });

    const dealers = useMemo(
        () =>
            (data?.dealers || []).map((option: { label: { [language: string]: string } }) => ({
                ...option,
                label: ct(option.label),
            })),
        [data, ct]
    );

    const options = [...dealers, { label: 'All Dealer', value: DealerOptions.ALLDEALER }];

    useEffect(() => {
        if (!isEmpty(dealers)) {
            setOption(dealers[0].value);
            setDealers(dealers);
        }
    }, [dealers, setDealers, setOption]);

    const onChange = useCallback(
        value => {
            setOption(value.value);
        },
        [setOption]
    );

    if (loading) {
        return null;
    }

    return (
        <Container bottom="0px">
            <Select defaultValue={options[0]} isSearchable={false} onChange={onChange} options={options} />
        </Container>
    );
};

export default DealerSelection;
