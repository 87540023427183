import { addDays } from 'date-fns';
import { getOr } from 'lodash/fp';
import React, { useContext } from 'react';
import { ReduxFormContext } from 'redux-form';
import { BankPresetOption } from '../../../../../schema';
import { yesNoOptions } from '../../../../../shared/constants/options';
import DatePickerField from '../../../../shared/form-v2/DatePickerField';
import SelectField from '../../../../shared/form-v2/SelectField';
import TextField from '../../../../shared/form-v2/TextField';
import SubTitle from '../../../../ui/SubTitle';
import useBankPresetLabel from '../../useBankPresetLabel';
import { useFormContext } from '../context';

const OccupationDetails = () => {
    const { disabled, options, allowPrimaryInfoChanges } = useFormContext();

    const { getValues, sectionPrefix } = useContext(ReduxFormContext);

    const formValues = getValues();

    const salaryTransferredToBank = getOr(
        null,
        `${sectionPrefix}.details.employment.salaryTransferredToBank.value`,
        formValues
    );
    const bankPresetOption = getOr(null, 'financeProduct.bank.presetOption', formValues);

    const getBankPresetLabel = useBankPresetLabel(bankPresetOption);

    const isEnbdBankPreset = bankPresetOption === BankPresetOption.ENBDBANK;

    return (
        <>
            <SubTitle>Occupation Details</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label="Income Type"
                        name="details.employment.incomeType.value"
                        options={getOr([], 'incomeTypes', options)}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label="Monthly Income"
                        name="details.employment.monthlyIncome.value"
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label="Employer"
                        name="details.employment.employer.value"
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DatePickerField label="Date of Joining" name="details.employment.dateOfJoining.value" disabled />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label="Total Work Experience"
                        name="details.employment.totalWorkExperience.value"
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label="Designation"
                        name="details.employment.designation.value"
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label="Salary Transferred to Bank"
                        name="details.employment.salaryTransferredToBank.value"
                        options={yesNoOptions}
                        disabled
                    />
                </div>
                {isEnbdBankPreset && !salaryTransferredToBank && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            disabled={!allowPrimaryInfoChanges && disabled}
                            label="Bank Name"
                            name="details.thirdParty.enbdBank.bankName.value"
                        />
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label="Bank Account Number"
                        name="details.employment.bankAccountNumber.value"
                    />
                </div>
                {isEnbdBankPreset && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DatePickerField
                            disabled={!allowPrimaryInfoChanges && disabled}
                            label={getBankPresetLabel('preferredFirstPaymentDate')}
                            max={addDays(new Date(), 45)}
                            min={new Date()}
                            name="details.thirdParty.enbdBank.preferredFirstPaymentDate.value"
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default OccupationDetails;
