/* eslint-disable */
import { faAngleDoubleRight, faAngleRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'prop-types';
import React from 'react';
import FieldContainer from './Field-container';
import { ButtonDiv, FilterDiv, FilteredMultiSelectContainer, OptionsDiv } from './MultiSelectStyle';

function makeLookup(arr, prop) {
    const lkup = {};
    for (let i = 0, l = arr.length; i < l; i++) {
        if (prop) {
            lkup[arr[i][prop]] = true;
        } else {
            lkup[arr[i]] = true;
        }
    }

    return lkup;
}

function getItemsByProp(arr, prop, values) {
    const items = [];
    let found = 0;
    const valuesLookup = makeLookup(values);
    for (let i = 0, la = arr.length, lv = values.length; i < la && found < lv; i++) {
        if (valuesLookup[arr[i][prop]]) {
            items.push(arr[i]);
            found++;
        }
    }

    return items;
}

const DEFAULT_CLASS_NAMES = {
    button: 'FilteredMultiSelect__button',
    buttonActive: 'FilteredMultiSelect__button--active',
    filter: 'FilteredMultiSelect__filter',
    select: 'FilteredMultiSelect__select',
};

class FilteredMultiSelectAdd extends React.Component {
    static propTypes = {
        onChange: t.func.isRequired,
        options: t.array.isRequired,

        buttonText: t.string,
        className: t.string,
        classNames: t.object,
        defaultFilter: t.string,
        disabled: t.bool,
        placeholder: t.string,
        selectedOptions: t.array,
        showFilter: t.bool,
        size: t.number,
        textProp: t.string,
        valueProp: t.string,
        label: t.string,
    };

    static defaultProps = {
        buttonTextLeft: 'Add All',
        buttonTextRight: 'Add',
        className: 'FilteredMultiSelect',
        classNames: {},
        defaultFilter: '',
        disabled: false,
        placeholder: 'type to filter',
        selectedOptions: [],
        showFilter: true,
        size: 6,
        label: '',
        textProp: 'label',
        valueProp: 'value',
    };

    constructor(props) {
        super(props);

        const { defaultFilter, selectedOptions } = props;
        this.state = {
            // Filter text
            filter: defaultFilter,
            // Options which haven't been selected and match the filter text
            filteredOptions: this._filterOptions(defaultFilter, selectedOptions),
            // Values of <options> currently selected in the <select>
            selectedValues: [],
        };
    }

    componentWillReceiveProps(nextProps) {
        // Update visible options in response to options or selectedOptions
        // changing. Also update selected values after the re-render completes, as
        // one of the previously selected options may have been removed.
        if (
            nextProps.options !== this.props.options ||
            nextProps.selectedOptions !== this.props.selectedOptions ||
            nextProps.options.length !== this.props.options.length ||
            nextProps.selectedOptions.length !== this.props.selectedOptions.length
        ) {
            this.setState(
                {
                    filteredOptions: this._filterOptions(
                        this.state.filter,
                        nextProps.selectedOptions,
                        nextProps.options
                    ),
                },
                this._updateSelectedValues
            );
        }
    }

    _getClassName(name, ...modifiers) {
        const classNames = [this.props.classNames[name] || DEFAULT_CLASS_NAMES[name]];
        for (let i = 0, l = modifiers.length; i < l; i++) {
            if (modifiers[i]) {
                classNames.push(this.props.classNames[modifiers[i]] || DEFAULT_CLASS_NAMES[modifiers[i]]);
            }
        }

        return classNames.join(' ');
    }

    _filterOptions(filter, selectedOptions, options) {
        if (typeof filter === 'undefined') {
            filter = this.state.filter;
        }
        if (typeof selectedOptions === 'undefined') {
            selectedOptions = this.props.selectedOptions;
        }
        if (typeof options === 'undefined') {
            options = this.props.options;
        }
        filter = filter.toUpperCase();

        const { textProp, valueProp } = this.props;
        const selectedValueLookup = makeLookup(selectedOptions, valueProp);
        const filteredOptions = [];

        for (let i = 0, l = options.length; i < l; i++) {
            if (
                !selectedValueLookup[options[i][valueProp]] &&
                (!filter || options[i][textProp].toUpperCase().indexOf(filter) !== -1)
            ) {
                filteredOptions.push(options[i]);
            }
        }

        return filteredOptions;
    }

    _selectRef = select => {
        this._select = select;
    };

    _onFilterChange = e => {
        const filter = e.target.value;
        this.setState(
            {
                filter,
                filteredOptions: this._filterOptions(filter),
            },
            this._updateSelectedValues
        );
    };

    _onFilterKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (this.state.filteredOptions.length === 1) {
                const selectedOption = this.state.filteredOptions[0];
                const selectedOptions = this.props.selectedOptions.concat([selectedOption]);
                this.setState({ filter: '', selectedValues: [] }, () => {
                    this.props.onChange(selectedOptions);
                });
            }
        }
    };

    _updateSelectedValues = e => {
        const el = e ? e.target : this._select;
        const selectedValues = [];
        for (let i = 0, l = el.options.length; i < l; i++) {
            if (el.options[i].selected) {
                selectedValues.push(el.options[i].value);
            }
        }
        // Always update if we were handling an event, otherwise only update if
        // selectedValues has actually changed.
        if (e || String(this.state.selectedValues) !== String(selectedValues)) {
            this.setState({ selectedValues });
        }
    };

    /**
     * Adds backing objects for the currently selected options to the selection
     * and calls back with the new list.
     */
    _addSelectedToSelection = e => {
        const selectedOptions = this.props.selectedOptions.concat(
            getItemsByProp(this.state.filteredOptions, this.props.valueProp, this.state.selectedValues)
        );
        this.setState({ selectedValues: [] }, () => {
            this.props.onChange(selectedOptions);
        });
    };

    /**
     * adds all options to new list
     */
    _addAllSelection = e => {
        this.setState({ selectedValues: [] }, () => {
            this.props.onChange(this.props.selectedOptions.concat(this.state.filteredOptions));
        });
    };

    /**
     * clear filter text
     */
    _clearFilter = e => {
        const filter = '';
        this.setState(
            {
                filter,
                filteredOptions: this._filterOptions(filter),
            },
            this._updateSelectedValues
        );
    };

    render() {
        const { filter, filteredOptions, selectedValues } = this.state;
        const { className, disabled, showFilter, size, textProp, valueProp } = this.props;
        const hasSelectedOptions = selectedValues.length > 0;

        return (
            <FilteredMultiSelectContainer className={className}>
                <FilterDiv>
                    {showFilter && (
                        <FieldContainer label="Filter">
                            <input
                                className={`${this._getClassName('filter')} filter`}
                                disabled={disabled}
                                // placeholder={placeholder}
                                onChange={this._onFilterChange}
                                onKeyPress={this._onFilterKeyPress}
                                type="text"
                                value={filter}
                            />
                            {filter ? <FontAwesomeIcon icon={faTimes} onClick={this._clearFilter} /> : ''}
                        </FieldContainer>
                    )}
                </FilterDiv>
                <OptionsDiv>
                    <FieldContainer
                        label={`${this.props.label} (${selectedValues.length}/${filteredOptions.length})`}
                    >
                        <div className="fieldContainer">
                            <select
                                ref={this._selectRef}
                                className={this._getClassName('select')}
                                disabled={disabled}
                                onChange={this._updateSelectedValues}
                                onDoubleClick={this._addSelectedToSelection}
                                size={size}
                                value={selectedValues}
                                multiple
                            >
                                {filteredOptions.map(option => {
                                    return (
                                        <option key={option[valueProp]} value={option[valueProp]}>
                                            {option[textProp]}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </FieldContainer>
                </OptionsDiv>
                <ButtonDiv>
                    <button
                        className="FilteredMultiSelect__button FilteredMultiSelect__button--active"
                        disabled={disabled}
                        onClick={this._addAllSelection}
                        type="button"
                    >
                        <div className="btnContainer">
                            <div className="text">{this.props.buttonTextLeft}</div>
                            <div className="icon-btn">
                                <FontAwesomeIcon icon={faAngleDoubleRight} />
                            </div>
                        </div>
                    </button>
                    <button
                        className={this._getClassName('button', !disabled && hasSelectedOptions && 'buttonActive')}
                        disabled={!hasSelectedOptions || disabled}
                        onClick={this._addSelectedToSelection}
                        type="button"
                    >
                        <div className="btnContainer">
                            <div className="text">{this.props.buttonTextRight}</div>
                            <div className={hasSelectedOptions ? 'icon-btn active' : 'icon-btn'}>
                                <FontAwesomeIcon icon={faAngleRight} />
                            </div>
                        </div>
                    </button>
                </ButtonDiv>
            </FilteredMultiSelectContainer>
        );
    }
}

export default FilteredMultiSelectAdd;
