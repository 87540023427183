import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useMemo, useCallback, useState, useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCountry } from '../../../../../selectors';
import { ModalContainer, AssignApplicationContainer } from '../../../../ui/AssignApplicationModal';
import { getData } from './AssignApplicationModal.graphql';

const AssignApplicationModal = ({ userId, onClose, onConfirm, open }) => {
    const { id: countryId } = useSelector(getCurrentCountry);
    const { data } = useQuery(getData, { fetchPolicy: 'cache-and-network', variables: { countryId } });

    const users = useMemo(() => data?.users?.items.filter(item => item.id !== userId) || [], [data, userId]);

    const [activeUser, setActiveUser] = useState(null);

    useEffect(() => {
        if (users.length === 1) {
            setActiveUser(users[0]);
        }
    }, [users]);

    const selectUser = useCallback(item => setActiveUser(item), [setActiveUser]);

    const assign = useCallback(() => {
        onConfirm(activeUser);
        onClose();
    }, [onConfirm, activeUser, onClose]);

    useLayoutEffect(() => {
        if (!open) {
            return () => undefined;
        }

        document.modalCount += 1;

        return () => {
            document.modalCount -= 1;
        };
    }, [open]);

    if (!open) {
        return null;
    }

    return (
        <ModalContainer onRequestClose={onClose} isOpen>
            <AssignApplicationContainer>
                <div className="close__button" onClick={onClose}>
                    &times;
                </div>
                <div className="heading">Assign Existing Applications</div>
                <div className="remind-info">
                    This user has existing applications. Please select a user to assign these applications.
                </div>
                <div className="body">
                    {users &&
                        users.map((i, index) => (
                            <div key={index.toString()} className="body-list-item" onClick={() => selectUser(i)}>
                                <input
                                    checked={i.id === activeUser?.id}
                                    className="radio-input"
                                    name="assignUser"
                                    type="radio"
                                    readOnly
                                />
                                {i.name}
                            </div>
                        ))}
                </div>
                <div className="footing">
                    <div className="confirm-button" onClick={onClose}>
                        Cancel
                    </div>
                    <div style={{ width: '10px' }} />
                    <div className="confirm-button" onClick={assign}>
                        Assign
                    </div>
                </div>
            </AssignApplicationContainer>
        </ModalContainer>
    );
};

AssignApplicationModal.propTypes = {
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    open: PropTypes.bool,
    userId: PropTypes.string.isRequired,
};

export default AssignApplicationModal;
