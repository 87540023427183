import { useQuery } from '@apollo/client';
import { omit } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { getLastModified } from '../../../../utilities/forms';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { getDealerById } from './Edition.graphql';
import Page from './Page';

const Edition = () => {
    const { id } = useParams();
    const variables = { id };
    const { data, loading } = useQuery(getDealerById, { variables, fetchPolicy: 'network-only' });
    const formatDateTime = useFormatDateTime();

    const [disabledBankIds, initialValues] = useMemo(() => {
        const values = data?.dealer;

        if (!values) {
            return [[], null];
        }

        const bankIds = values.banks.map(({ id: bankId }) => bankId);

        return [
            values.disabledBankIds,
            {
                ...omit(['banks', 'disabledBankIds'], values),
                bankIds,
                lastModified: getLastModified(values.version, formatDateTime),
            },
        ];
    }, [data, formatDateTime]);

    if (!loading && !initialValues) {
        // the product does not exist
        return <Redirect to="/scope/dealers" />;
    }

    return <Page disabledBankIds={disabledBankIds} initialValues={initialValues} />;
};

export default Edition;
