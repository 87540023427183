import { FORM_OPTION_SET, FORM_OPTION_UNSET_ALL } from '../actions/formOptions';

const reducer = (state = {}, { type, ...payload }) => {
    switch (type) {
        case FORM_OPTION_SET:
            return { ...state, [payload.key]: payload.value };

        case FORM_OPTION_UNSET_ALL:
            return {};

        default:
            return state;
    }
};

export default reducer;
