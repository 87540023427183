import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { getLastModified } from '../../../../utilities/forms';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { getUser } from './Edition.graphql';
import Page from './Page';

const Editor = () => {
    const { id } = useParams();
    const { data, loading } = useQuery(getUser, { fetchPolicy: 'network-only', variables: { id } });

    const formatDateTime = useFormatDateTime();

    const initialValues = useMemo(() => {
        const user = data?.user;

        if (!user) {
            return null;
        }

        return {
            ...user,
            lastLoginAt: formatDateTime(user.lastLoginAt),
            lastModified: getLastModified(user.version, formatDateTime),
        };
    }, [data, formatDateTime]);

    if (!loading && !initialValues) {
        return <Redirect to="/access/users" />;
    }

    return <Page initialValues={initialValues} />;
};

export default Editor;
