import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { yesNoOptions } from '../../../../../shared/constants/options';
import FieldContainer from '../../../../template/Field-container';
import SwitchField from '../../../../template/Switch';
import { useFormContext } from './context';

const TradeIn = () => {
    const { disabled, values, change } = useFormContext();

    const show = get('tradeInSetting.show', values);

    useEffect(() => {
        if (!show) {
            change('tradeInSetting.withLiability', false);
        }
    }, [change, show]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Show">
                        <Field
                            component={SwitchField}
                            disabled={disabled}
                            name="tradeInSetting.show"
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                </div>
                {show && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Liability">
                            <Field
                                component={SwitchField}
                                disabled={disabled}
                                name="tradeInSetting.withLiability"
                                options={yesNoOptions}
                            />
                        </FieldContainer>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TradeIn;
