import React from 'react';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { onOffOptions } from '../../../../../shared/constants/options';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import NumberField from '../../../../template/Number';
import SwitchField from '../../../../template/Switch';
import { useFormContext } from './context';

const MainDetails = () => {
    const { disabled, values } = useFormContext();
    const { formatPath } = useContentTranslation();

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Make ID">
                            <Field component={InputField} disabled={disabled} name="identifier" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Make">
                            <Field component={InputField} disabled={disabled} name={formatPath('name')} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Order No.">
                            <Field component={NumberField} disabled={disabled} name="order" precision={0} />
                        </FieldContainer>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Active">
                            <Field component={SwitchField} disabled={disabled} name="isActive" options={onOffOptions} />
                        </FieldContainer>
                    </div>
                    {values.lastModified && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Last Modified">
                                <Field component={InputField} name="lastModified" disabled />
                            </FieldContainer>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MainDetails;
