import { useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLanguages as getLanguageCodes } from '../i18n';
import { Country } from '../schema';
import { getCurrentCountry } from '../selectors';
import { getLanguages, GetLanguagesQuery } from './useLanguageSelector.graphql';

const useLanguageSelector = () => {
    const { data } = useQuery<GetLanguagesQuery>(getLanguages, { fetchPolicy: 'cache-first' });
    const languages = data?.options?.languages || [];
    const mappedLanguages = useMemo(() => Object.fromEntries(languages.map(option => [option.code, option.name])), [
        languages,
    ]);

    const country: Country = useSelector(getCurrentCountry);
    const defaultLanguage = country.languages[0];
    const [language, setLanguage] = useState<string>(defaultLanguage);

    const isDefaultLanguage = useMemo(() => defaultLanguage === language, [defaultLanguage, language]);

    const languageOptions = useMemo(() => {
        // Keep english language as first field in Multi Language Tab
        const languages = getLanguageCodes(country.languages);

        return languages.map((value: string) => ({ label: mappedLanguages[value], value }));
    }, [country.languages, mappedLanguages]);

    const displayMultiLanguage = useMemo(() => languageOptions.length > 1, [languageOptions.length]);

    return {
        defaultLanguage,
        isDefaultLanguage,
        language,
        languageOptions,
        setLanguage,
        displayMultiLanguage,
    };
};

export default useLanguageSelector;
