import { useMemo } from 'react';
import { ApplicationStatus } from '../../../../../schema';
import useFormDirty from '../shared/useFormDirty';
import useFormChanged from './useFormChanged';

const allowDirectlySubmitStatus = [
    ApplicationStatus.SIGNING,
    ApplicationStatus.PENDINGOTP,
    ApplicationStatus.SIGNINGREJECTED,
    ApplicationStatus.SIGNINGTIMEOUT,
    ApplicationStatus.UNABLETOCONNECT,
    ApplicationStatus.CONNECTIONFAILED,
];

const useButtonStatus = (initialValues, mayManage, maySubmitChange) => {
    const hasChanged = useFormChanged('application');
    const hasErrors = useFormDirty('application');

    return useMemo(() => {
        const { appliedForFinancing, status, reservationDeposit } = initialValues || {};

        const isFormDisabled =
            !mayManage || !appliedForFinancing || !(maySubmitChange || status === ApplicationStatus.SUBMISSIONFAILED);

        const canSubmitDirectly = allowDirectlySubmitStatus.includes(status);
        const submitChangesDisabled = isFormDisabled || hasErrors || (!canSubmitDirectly && !hasChanged);

        const voidDisabled = !mayManage || status === (ApplicationStatus.CANCELLED || ApplicationStatus.COMPLETED);
        const approveDisabled = !mayManage || status !== ApplicationStatus.RECEIVED;
        const declineDisabled = !mayManage || status !== ApplicationStatus.RECEIVED;
        const completedDisabled =
            (appliedForFinancing && status !== ApplicationStatus.APPROVED) ||
            !mayManage ||
            status === (ApplicationStatus.CANCELLED || ApplicationStatus.COMPLETED);

        const saveChangesDisabled = hasErrors || appliedForFinancing || !reservationDeposit;

        const downloadDisabled = !(initialValues?.attachments?.length > 0);

        return {
            isFormDisabled,
            completedDisabled,
            saveChangesDisabled,
            submitChangesDisabled,
            voidDisabled,
            approveDisabled,
            declineDisabled,
            downloadDisabled,
        };
    }, [hasChanged, hasErrors, initialValues, mayManage, maySubmitChange]);
};

export default useButtonStatus;
