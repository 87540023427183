import { get } from 'lodash/fp';
import * as validators from '../../../../../utils/validators';

const validate = formatPath =>
    validators.compose(
        validators.requiredString(formatPath('name')),
        validators.requiredValue('zoneIds'),
        validators.requiredStringArray('dealerIds'),
        validators.requiredBoolean('isActive'),
        validators.requiredString('email'),
        validators.requiredNumber('order'),
        validators.validEmail('email'),
        validators.requiredString('signing.onCreate'),
        validators.requiredString('signing.onUpdate'),
        validators.requiredBoolean('hasIntegration'),
        validators.only(
            get('hasIntegration'),
            validators.compose(
                validators.requiredString('integration.submission'),
                validators.requiredString('integration.resubmission'),
                validators.requiredString('integration.cancellation')
            )
        )
    );

export default validate;
