import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getOr, keyBy, isNil } from 'lodash/fp';
import React, { useState, useCallback } from 'react';
import { Field } from 'redux-form';
import { FootBarButton, TabExcelContainer } from '../../../../../containers/Layout';
import { useContentTranslation } from '../../../../../i18n';
import TableField, { createColumns } from '../../../../TableField';
import { exportFinancialTabsExcel, getWorkbookToExport } from '../../../../utilities/excel';
import ImportFinanceTablesModal from '../../lists/ImportFinanceTablesModal';
import { useFormContext } from './context';

const rows = [{ label: 'No. of Monthly Payment' }];

const DepositTab = () => {
    const { disabled, values, change } = useFormContext();
    const settings = getOr({}, 'termSetting', values);
    const depositTable = getOr([], 'depositSetting.table', values);
    const productName = getOr('', 'name', values);
    const [showImport, setShowImport] = useState(false);

    const { ct } = useContentTranslation();

    const mappedHeaders = {
        'Term *': 'term',
        'Monthly Payment *': 'value',
    };

    const exportExcel = useCallback(async () => {
        const workbook = await getWorkbookToExport(mappedHeaders);
        const worksheet = workbook.getWorksheet(1);
        const terms = createColumns(settings).map(val => val.value);
        const depositTableByTerm = keyBy('term', depositTable || []);

        terms.forEach(term => {
            worksheet.addRow({ term, value: getOr(null, `${term}.value`, depositTableByTerm) });
        });

        exportFinancialTabsExcel(workbook, ct(productName), 'Deposit');
    }, [mappedHeaders, depositTable, ct, productName, settings]);

    const onImportClose = useCallback(() => {
        setShowImport(false);
    }, [setShowImport]);

    const onImport = useCallback(
        async data => {
            const tableDataList = data
                .filter(record => !isNil(record.value))
                .map(value => ({
                    term: value.term,
                    value: value.value,
                }));

            change('depositSetting.table', tableDataList);
        },
        [change]
    );

    return (
        <>
            <Field
                columnKey="term"
                component={TableField}
                disabled={disabled}
                header="Term"
                name="depositSetting.table"
                precision={0}
                rows={rows}
                settings={settings}
            />
            <TabExcelContainer>
                <FootBarButton onClick={() => setShowImport(true)}>
                    <FontAwesomeIcon icon={faUpload} /> IMPORT EXCEL
                </FootBarButton>
                <FootBarButton onClick={exportExcel}>
                    <FontAwesomeIcon icon={faDownload} /> EXPORT EXCEL
                </FootBarButton>
            </TabExcelContainer>
            {showImport && (
                <ImportFinanceTablesModal
                    mappedHeaders={mappedHeaders}
                    onClose={onImportClose}
                    onImport={onImport}
                    type="Deposit Table"
                />
            )}
        </>
    );
};

export default DepositTab;
