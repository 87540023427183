import styled from 'styled-components';
import Button from './Button';

const DefaultButton = styled(Button)`
    border-color: #acb7bf;
    background-color: #acb7bf;
    color: #ffffff;

    &:hover,
    &:active {
        border-color: #bdc6cc;
        background-color: #bdc6cc;
    }

    &:disabled {
        border-color: #acb7bf;
        background-color: #acb7bf;
    }
`;

export default DefaultButton;
