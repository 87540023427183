import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { WrappedFieldArrayProps } from 'redux-form/lib/FieldArray';
import FieldContainer from '../../../template/Field-container';
import InputField from '../../../template/Input';
import TextAreaField from '../../../template/Textarea';

type EquipmentItemProps = { name: string; index: number };

const EquipmentItem = ({ name, index }: EquipmentItemProps) => (
    <>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label={`vehicle/equipment/${index}/code`}>
                <Field component={InputField} name={`${name}.code`} disabled />
            </FieldContainer>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label={`vehicle/equipment/${index}/name/localize`}>
                <Field component={InputField} name={`${name}.name.localize`} disabled />
            </FieldContainer>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label={`vehicle/equipment/${index}/description/localize`}>
                <Field component={TextAreaField} name={`${name}.description.localize`} disabled />
            </FieldContainer>
        </div>
    </>
);
const EquipmentArray = ({ fields }: WrappedFieldArrayProps<any>) => {
    const children = fields.map((field, index) => <EquipmentItem key={field} index={index} name={field} />);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};

// @ts-ignore
const Equipments = () => <FieldArray component={EquipmentArray} name="listing.vehicle.equipment" />;

export default Equipments;
