import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pick } from 'lodash/fp';
import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCountryCode, setZoneCode, setDefaultZoneCode, switchTenant, logout } from '../../../actions';
import { getContext, getCurrentCountry } from '../../../selectors';
import {
    DropdownS,
    DropdownItemS,
    DropdownMenuS,
    DropdownToggleS,
    ToggleContainer,
    Text,
    SingleTextContainer,
} from '../UI';

const ZoneSelection = () => {
    // we need dispatch to update the context
    const dispatch = useDispatch();

    // get information from the context
    const { user, countryCode, zoneCode = 'All', companyCode, company, dealer } = useSelector(getContext);
    const { availableCompanies: companies } = user;
    const currentCountry = useSelector(getCurrentCountry);

    const options = useMemo(() => {
        const { countries = [] } = companies.find(item => item.code === companyCode) || {};

        return countries.flatMap(country => {
            if (country.code !== countryCode) {
                // just return the country
                // and by doing so, see it as the default zone
                return [
                    {
                        ...pick(['id', 'code', 'name'], country),
                        showZoneCode: false,
                        zoneCode: country.zones.length > 1 ? 'All' : country.zones[0].code,
                        zoneId: country.zones.length > 1 ? null : country.zones[0].id,
                        defaultZoneCode: country.zones.length > 1 ? country.zones[0].code : null,
                    },
                ];
            }

            // get zones
            const { zones } = country;

            return [
                // create a fake zone for all
                zones.length > 1 && {
                    ...pick(['id', 'code', 'name'], country),
                    showZoneCode: true,
                    zoneCode: 'All',
                    defaultZoneCode: zones[0].code,
                    zoneId: null,
                },
                // append each zones
                ...zones.map(zone => ({
                    ...pick(['id', 'code', 'name'], country),
                    showZoneCode: zones.length > 1,
                    zoneCode: zone.code,
                    zoneId: zone.id,
                })),
            ].filter(Boolean);
        });
    }, [companies, countryCode, companyCode]);

    // state to open/close the zone selection
    const [open, setOpen] = useState(false);
    const toggle = useCallback(() => setOpen(state => !state), [setOpen]);

    const currentCountryText = (
        <Text>
            {currentCountry?.name}
            {/* only have multiple zone will display zone code  */}
            {currentCountry?.zones?.length > 1 && `-${zoneCode}`}
        </Text>
    );

    const renderCurrentCountryZone = (
        <ToggleContainer>
            {currentCountryText}
            <FontAwesomeIcon icon={faAngleDown} />
        </ToggleContainer>
    );

    const setCountry = useCallback(
        option => {
            // update country and zone code
            dispatch(setCountryCode(option.code));
            dispatch(setZoneCode(option.zoneCode));
            if (option.defaultZoneCode) {
                dispatch(setDefaultZoneCode(option.defaultZoneCode));
            } else {
                dispatch(setDefaultZoneCode(null));
            }

            // refresh token when tenant change in admin
            dispatch(switchTenant(company.id, option.id, option.zoneId, dealer ? dealer.id : null)).catch(error => {
                // print the error because maybe it is not network related
                console.error(error);
                // we are going to logout
                dispatch(logout('manual'));
            });
        },
        [dispatch, company, dealer]
    );

    const renderItem = (item, index) => (
        <DropdownItemS key={index.toString()} onClick={() => setCountry(item)}>
            {' '}
            {item.name}
            {item.showZoneCode && <>-{item.zoneCode}</>}{' '}
        </DropdownItemS>
    );

    if (options.length > 1) {
        return (
            <DropdownS isOpen={open} nav={false} toggle={toggle}>
                <DropdownToggleS caret>{renderCurrentCountryZone}</DropdownToggleS>
                <DropdownMenuS>{options.map(renderItem)}</DropdownMenuS>
            </DropdownS>
        );
    }

    return <SingleTextContainer>{currentCountryText}</SingleTextContainer>;
};

export default ZoneSelection;
