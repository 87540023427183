import { find, get, getOr } from 'lodash/fp';

const selectVariant = (run, context, values) => {
    // indicate if it is used car
    const snapshot = get('snapshot.selectedVariant', context);
    const variants = getOr([], 'variants', context);
    const variantId = get('carModel', values);

    // only not used car will use snapshot, coz used car only change name
    if (snapshot?.id === variantId) {
        return snapshot;
    }

    return run(
        'selectedVariant',
        () => {
            return find(variant => variant.id === variantId, variants);
        },
        [variants, variantId]
    );
};

export default selectVariant;
