import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useComputedContext from './useComputeContext';

export const Context = createContext(null);

export const useCalculatorContext = () => useContext(Context);

const CalculatorContextProvider = ({ children, values, setValues, onChange, ...rawContext }) => {
    const { t } = useTranslation();

    // add change source to indicate first changed field,
    // to avoid circle calculator
    const [initChangeSource, setInitChangeSource] = useState(null);
    const [context, cleanedValues, cleanedErrors] = useComputedContext(
        { initChangeSource, setInitChangeSource, t, ...rawContext },
        values,
        setValues
    );

    // we may want to change values
    useEffect(() => {
        setValues(cleanedValues, false);
    }, [cleanedValues, setValues]);

    // allows to keep track of the latest values with a callback
    useEffect(() => {
        if (onChange) {
            onChange(cleanedValues, cleanedErrors, context);
        }
    }, [cleanedValues, cleanedErrors, onChange, context]);

    return <Context.Provider value={context}>{children(context)}</Context.Provider>;
};

CalculatorContextProvider.propTypes = {
    children: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    setValues: PropTypes.func.isRequired,
    values: PropTypes.shape({}).isRequired,
};

export default CalculatorContextProvider;
