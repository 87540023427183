import { useApolloClient } from '@apollo/client';
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isArray } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { ErrorMessageDiv } from '../../../../../../../containers/Layout';
import { withModal } from '../../../../../../Modal';
import FileDropBox from '../../../../../../template/FileDropBox';
import LoadingIndicator from '../../../../../../template/LoadingIndicator';
import { PreviewUpload } from '../../../../../../ui/Document';
import { uploadDocument } from '../../Page.graphql';

const DocumentUpload = ({ meta, input, applicationId, activeCategory }) => {
    const [uploading, setUploading] = useState(false);
    const client = useApolloClient();

    const handleUpload = useCallback(
        async file => {
            // set the state as uploading
            setUploading(true);

            // get the upload url
            const { data } = await client.mutate({
                mutation: uploadDocument,
                variables: { file, id: applicationId, purpose: activeCategory.purpose },
            });

            // set it as uploaded/completed
            setUploading(false);

            if (data) {
                const previousDocuments = isArray(input.value) ? input.value : [];

                input.onChange([...previousDocuments, data?.attachment]);
            }
        },
        [activeCategory, applicationId, client, input]
    );

    const handleDrop = useCallback(
        files => {
            if (files[0]) {
                return handleUpload(files[0]);
            }

            return Promise.resolve();
        },
        [handleUpload]
    );

    const onChange = useCallback(event => handleDrop(event.target.files), [handleDrop]);

    const { error, touched, active } = meta;
    const { onBlur, onFocus } = input;

    return (
        <>
            <PreviewUpload>
                <FileDropBox className="upload" onDrop={handleDrop}>
                    <input onBlur={onBlur} onChange={onChange} onFocus={onFocus} type="file" />
                    <div className="replace-style">
                        <div className="icon">
                            <FontAwesomeIcon icon={faPlus} />
                        </div>
                        <div>upload</div>
                        {uploading && (
                            <LoadingIndicator>
                                <FontAwesomeIcon icon={faSpinner} size="2x" spin />
                            </LoadingIndicator>
                        )}
                    </div>
                </FileDropBox>
            </PreviewUpload>
            <ErrorMessageDiv>{touched && !active && error}</ErrorMessageDiv>
        </>
    );
};

DocumentUpload.propTypes = {
    activeCategory: PropTypes.shape({
        id: PropTypes.number,
        purpose: PropTypes.string,
    }),
    applicationId: PropTypes.string,
    input: PropTypes.shape().isRequired,
    meta: PropTypes.shape({
        active: PropTypes.bool,
        error: PropTypes.string,
        touched: PropTypes.bool,
    }).isRequired,
};

export default withModal(DocumentUpload);
