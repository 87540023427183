import styled from 'styled-components';

const Button = styled.div`
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 106.47px;
    border: 0;
    background: #f0f0f0;
    min-height: 30px;
    input {
        display: none;
    }
    span {
        color: #000000;
        text-align: center;
    }
`;

export default Button;
