import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { unsetContext } from '../../actions';
import { getIsAuthenticated } from '../../selectors';
import LoginRoute from '../routes/LoginRoute';
import ResetPasswordRoute from '../routes/ResetPasswordRoute';
import AuthenticatedRouter from './AuthenticatedRouter';
import CompanyLoginRouter from './CompanyLoginRouter';

const Routes = () => {
    const isAuthenticated = useSelector(getIsAuthenticated);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isAuthenticated) {
            // when accessing app for the first time
            // clear context once if not yet authenticated
            dispatch(unsetContext());
        }
    }, [dispatch, isAuthenticated]);

    if (!isAuthenticated) {
        return (
            <Switch>
                <Route component={LoginRoute} path="/login" exact />
                <Route component={ResetPasswordRoute} path="/login/resetpassword" exact />
                <Route component={CompanyLoginRouter} path="/:companyCode/:locationCode" />
                <Redirect to="/login" />
            </Switch>
        );
    }

    return <AuthenticatedRouter />;
};

export default Routes;
