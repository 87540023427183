import { getOr, get } from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { getCurrentCountry } from '../../../../../selectors';
import MultiSelectPoolField from '../../../../template/MultiSelectPool';
import ApvPortlet from '../../../../template/Portlet';
import { useFormContext } from './context';

const VehicleAssignment = () => {
    const { usePorscheFinder } = useSelector(getCurrentCountry);

    const { formatPath } = useContentTranslation();
    const { disabled, apiData, values, change } = useFormContext();
    const variants = getOr([], 'variants.items', apiData);
    const selectedVariants = getOr([], 'variants', values);
    const finderVehicles = getOr([], 'finderVehicles', apiData);
    const selectedFinderVehicles = getOr([], 'finderVehicles', values);

    const filteredFinderVehicles = useMemo(() => {
        // first map and filter the dealers
        const filteredDealers = apiData.banks.items
            .flatMap(({ dealers }) => dealers)
            .filter(({ id }) => values.dealerIds.includes(id));

        // then map the finder vehicles with the partner number
        return finderVehicles
            .filter(({ listing }) =>
                filteredDealers.some(({ partnerNumber }) => partnerNumber === listing?.seller?.porschePartnerNumber)
            )
            .map(finderVehicle => ({
                ...finderVehicle,
                label: `${finderVehicle.label} (${finderVehicle.listing.id})`,
            }));
    }, [apiData, finderVehicles, values.dealerIds]);

    const translatedVariants = useMemo(
        () =>
            variants.map(variant => ({
                ...variant,
                label: get(formatPath('label'), variant),
            })),
        [variants, formatPath]
    );

    useEffect(() => {
        const selectedFilteredFinderVehicles = selectedFinderVehicles.filter(id =>
            filteredFinderVehicles.some(({ value }) => value === id)
        );

        if (selectedFilteredFinderVehicles.length !== selectedFinderVehicles.length) {
            // there was a dealer removed with finder vehicles
            // we need to change the form finder vehicle ids
            change('finderVehicles', selectedFilteredFinderVehicles);
        }
    }, [change, filteredFinderVehicles, selectedFinderVehicles]);

    useEffect(() => {
        const deletedVariants = selectedVariants.filter(id => !variants.some(({ value }) => value === id));

        // there are deleted variant data
        if (deletedVariants.length) {
            // we need to clean those up
            change(
                'variants',
                selectedVariants.filter(id => !deletedVariants.includes(id))
            );
        }
    }, [change, selectedVariants, variants]);

    useEffect(() => {
        const deletedFinderVehicles = selectedFinderVehicles.filter(
            id => !finderVehicles.some(({ value }) => value === id)
        );

        // there are deleted finder vehicle data
        if (deletedFinderVehicles.length) {
            // we need to clean those up
            change(
                'finderVehicles',
                selectedFinderVehicles.filter(id => !deletedFinderVehicles.includes(id))
            );
        }
    }, [change, finderVehicles, selectedFinderVehicles]);

    return (
        <>
            <ApvPortlet name="Assign Vehicles" closable open>
                <div className="container-fluid">
                    <Field
                        component={MultiSelectPoolField}
                        disabled={disabled}
                        name="variants"
                        options={translatedVariants}
                        poolLabel="Variants Pool"
                        selectionLabel="Selected Variants"
                    />
                </div>
            </ApvPortlet>
            {values.finderDealerIds?.length > 0 && usePorscheFinder && (
                <ApvPortlet name="Assign Finder Vehicles" closable open>
                    <div className="container-fluid">
                        <Field
                            component={MultiSelectPoolField}
                            disabled={disabled}
                            name="finderVehicles"
                            options={filteredFinderVehicles}
                            poolLabel="Finder Vehicles Pool"
                            selectionLabel="Selected Finder Vehicles"
                        />
                    </div>
                </ApvPortlet>
            )}
        </>
    );
};

export default VehicleAssignment;
