import PropTypes from 'prop-types';
import React, { Component } from 'react';
import NumericInput from 'react-numeric-input';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import styled, { css } from 'styled-components';
import { ErrorMessageDiv } from '../../containers/Layout';

const InputFieldDiv = styled.div`
    width: 100%;

    .react-numeric-input {
        width: 100% !important;

        b {
            display: none;
        }

        input {
            border: 0 !important;
            color: #000000 !important;
            height: 30px !important;
            font-size: 0.85rem !important;
            padding: 0 0 0 10px !important;
            border-radius: 0 !important;
            text-shadow: none !important;
            background: #f0f0f0;
            width: 100% !important;
            ${props =>
                props.inTable &&
                css`
                    background: #cacaca;
                    max-width: 150px;
                `}
        }

        input[type='text']:disabled {
            background: #d8d8d8;
        }
    }
`;

class NumberField extends Component {
    constructor(props) {
        super(props);

        this.state = { isFocused: false };
    }

    onChange = value => {
        const { input, handleChange } = this.props;

        // update redux state
        input.onChange(value);

        // custom callback
        if (handleChange) {
            handleChange(value);
        }
    };

    onBlur = () => {
        const { input, allowEmpty } = this.props;

        if (!input.value && input.value !== 0) {
            input.onChange(allowEmpty ? null : 0);
        }

        this.setState({ isFocused: false });
    };

    onFocus = () => {
        const { input } = this.props;

        if (input.value === 0) {
            input.onChange(null);
        }

        this.setState({ isFocused: true });
    };

    myFormat = value => {
        const { isFocused } = this.state;
        const { prefix, suffix, withFocus } = this.props;

        if (withFocus && isFocused) {
            return value;
        }

        return [prefix, value, suffix].filter(Boolean).join('');
    };

    render() {
        const { meta, input, precision, min, max, disabled, inTable } = this.props;
        const { error, touched, active } = meta;

        return (
            <InputFieldDiv inTable={inTable}>
                <NumericInput
                    precision={precision}
                    {...input}
                    disabled={disabled}
                    format={this.myFormat}
                    max={max}
                    maxLength={max}
                    min={min}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                />
                {touched && !active && error && <ErrorMessageDiv>{error}</ErrorMessageDiv>}
            </InputFieldDiv>
        );
    }
}

NumberField.propTypes = {
    allowEmpty: PropTypes.bool,
    disabled: PropTypes.bool,
    handleChange: PropTypes.func,
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    inTable: PropTypes.bool,
    max: PropTypes.number,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
    min: PropTypes.number,
    precision: PropTypes.number,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    withFocus: PropTypes.bool,
};

export default NumberField;
