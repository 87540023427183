import { PrimaryButton } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import * as yup from 'yup';
import { requirements, passwordRegex } from '../../../utilities/constants/passwordRequirements';
import { createFormValidation, requiredString } from '../../../utilities/forms';
import FormError from '../../shared/form/FormError';
import PasswordField from '../../shared/form/PasswordField';
import FormActions from '../../ui/form/FormActions';
import LoginLogo from '../../ui/login/LoginLogo';
import PasswordRequirementList from '../../ui/login/PasswordRequirementList';

const validatePassword = value =>
    requirements.map(({ description, regex }) => ({
        description,
        isChecked: regex.test(value),
    }));

const ResetPasswordForm = ({ handleSubmit }) => {
    const { password = '' } = useSelector(getFormValues('resetPassword')) || {};
    const passwordRequirements = validatePassword(password);

    return (
        <form onSubmit={handleSubmit}>
            <LoginLogo />
            <PasswordField.Outline label="New Password" name="password" />
            <PasswordField.Outline label="Confirm New Password" name="confirmPassword" />
            <FormActions>
                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
                <FormError form="resetPassword">{error => <FormActions.Error>{error}</FormActions.Error>}</FormError>
            </FormActions>
            <PasswordRequirementList requirements={passwordRequirements} />
        </form>
    );
};

ResetPasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

export const makePasswordValidation = validation =>
    yup.lazy((value, { parent }) => {
        const { password, confirmPassword } = parent;
        const isMandatory = password || confirmPassword;

        if (!isMandatory) {
            return yup.string();
        }

        return validation;
    });

const schema = yup.object().shape({
    confirmPassword: makePasswordValidation(
        yup
            .string()
            .required('Password confirmation is mandatory')
            .test('is-matching', 'Password not match', function testPasswordMatch(value) {
                return value === this.parent.password;
            })
    ),
    password: requiredString('Password is mandatory').matches(
        passwordRegex,
        'Password should follow requirements below'
    ),
});

export default reduxForm({
    form: 'resetPassword',
    validate: createFormValidation(schema),
})(ResetPasswordForm);
