import { Decimal } from 'decimal.js';

const simple = (monthlyInstalment, term, interestRate, futureValue = 0) => {
    const termInterests = new Decimal(interestRate).div(12 * 100).mul(term);
    const finalLoanAmount = new Decimal(monthlyInstalment).mul(term);
    const loanAmount = finalLoanAmount.add(futureValue);

    return loanAmount.div(termInterests.add(1)).toNumber();
};

const equalTotalPayment = (monthlyInstalment, term, interestRate, futureValue = 0, advance = false) => {
    const regularizedInterestRate = new Decimal(interestRate).div(12 * 100);
    const factor = regularizedInterestRate.add(1).pow(term);

    const value = advance
        ? new Decimal(monthlyInstalment).mul(regularizedInterestRate.add(1))
        : new Decimal(monthlyInstalment);

    return value
        .div(regularizedInterestRate.div(factor.sub(1)))
        .add(futureValue)
        .div(factor)
        .toNumber();
};

const desiredLoanAmount = (parameters, detail) => {
    const { type, subType, paymentMode, calculationMode } = detail.meta;
    if (type !== 'finance' || subType !== 'Standard HP') {
        throw new Error(
            // eslint-disable-next-line max-len
            `Desired down payment calculation not available for type ${detail.meta.type} sub type ${detail.meta.subType}`
        );
    }

    const {
        monthlyInstalment: { value: monthlyInstalment },
        term: { value: term },
        interestRate: { value: interestRate },
    } = parameters;

    const advance = paymentMode === 'Advance';
    switch (calculationMode) {
        case 'Flat Rate':
            return simple(monthlyInstalment, term, interestRate, 0, advance);

        case 'Effective Rate':
            return equalTotalPayment(monthlyInstalment, term, interestRate, 0, advance);

        default:
            throw new Error(`invalid calculation mode: ${calculationMode}`);
    }
};

export default desiredLoanAmount;
