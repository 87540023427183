import { getOr, keyBy } from 'lodash/fp';

const convertOutdatedOptions = (context, carOptions) => {
    // we need to migrate options
    // get the options from the snapshot
    const snapshotOptions = getOr([], 'snapshot.options', context);
    const mapOption = keyBy('groupId', snapshotOptions);

    // get groups and currently selected
    const { groups, selected } = carOptions;

    return groups.map(group => {
        const fromSnapshot = mapOption[group.id];

        // get the option with the same version ID
        // or get the first option for this group
        return (
            selected[group.id] ||
            (fromSnapshot && group.options.find(item => item.version.id === fromSnapshot.version.id)) ||
            group.options[0]
        );
    });
};

const convertOutdated = (dirtyReference, context, carOptions, setValues) => {
    const { allowOutdated = false } = context;

    if (
        context.snapshot &&
        // if outdated are not allowed, we will convert the values after first init
        !allowOutdated &&
        dirtyReference.firstInit
    ) {
        // convert the car model ID
        const carModelId = context.variants.find(
            variant => variant.version.id === context.snapshot.selectedVariant.version.id
        )?.id;

        // convert the finance product ID
        const financeProductId = context.financeProducts.find(
            product => product.version.id === context.snapshot.selectedFinanceProduct.version.id
        )?.id;

        // update the variant ID by matching their version IDs
        setTimeout(() => {
            const changes = {
                // replace car model and finance product
                carModel: carModelId || context.snapshot.selectedVariant.id,
                financeProduct: financeProductId || context.snapshot.selectedFinanceProduct.id,
            };

            if (carOptions) {
                // only migrate options if the variant changed
                changes.carOptions = convertOutdatedOptions(context, carOptions);
            }

            setValues(state => ({ ...state, ...changes }));
        });

        // eslint-disable-next-line no-param-reassign
        dirtyReference.converted = true;
    }

    // eslint-disable-next-line no-param-reassign
    dirtyReference.firstInit = false;
};

export default convertOutdated;
