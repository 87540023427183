import { getOr } from 'lodash/fp';
import { useContext, useMemo } from 'react';
import { ReduxFormContext } from 'redux-form';
import { useCustomerOptions } from './useOptions';

const useCustomerSourceOptions = () => {
    const { sectionPrefix, getValues } = useContext(ReduxFormContext);
    const formValues = getValues();
    const customer = getOr(formValues, `${sectionPrefix}`, formValues);

    const options = useCustomerOptions();

    return useMemo(
        () => ({
            genders: (options?.genders || []).filter(
                ({ purpose }) => !purpose || purpose.includes(customer?.details?.gender?.source)
            ),
            residentialStatuses: (options?.residentialStatuses || []).filter(
                ({ purpose }) => !purpose || purpose.includes(customer?.details?.residentialStatus?.source)
            ),
        }),
        [customer, options]
    );
};

export default useCustomerSourceOptions;
