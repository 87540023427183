import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Field } from 'redux-form';
import { ErrorMessageDiv } from '../../containers/Layout';
import Input from '../ui/form/Input';
import Wrapper from '../ui/form/Wrapper';

const TextInput = props => {
    const { label, meta, input, placeholder, disabled, max, min, withFocusClear } = props;
    const { name, onFocus: defaultOnFocus, onBlur: defaultOnBlur, value, onChange } = input;

    const [lastValue, setLastValue] = useState(value);

    const onFocus = useMemo(() => {
        if (withFocusClear) {
            return () => {
                setLastValue(value);

                return onChange('');
            };
        }

        return defaultOnFocus;
    }, [withFocusClear, onChange, defaultOnFocus, setLastValue, value]);

    const onBlur = useMemo(() => {
        if (withFocusClear) {
            return event => {
                if (event.target.value !== '') {
                    return onChange(event.target.value);
                }

                return onChange(lastValue);
            };
        }

        return defaultOnBlur;
    }, [withFocusClear, lastValue, onChange, defaultOnBlur]);

    const { active, touched, error = null } = meta;
    const hasError = !active && touched && !!error;

    return (
        <Wrapper label={label} name={name}>
            <Input
                {...input}
                disabled={disabled}
                maxLength={max}
                minLength={min}
                onBlur={onBlur}
                onFocus={onFocus}
                placeholder={placeholder}
            />
            {hasError && <ErrorMessageDiv>{error}</ErrorMessageDiv>}
        </Wrapper>
    );
};

TextInput.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    }).isRequired,
    label: PropTypes.string,
    max: PropTypes.number,
    meta: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        error: PropTypes.string,
        touched: PropTypes.bool.isRequired,
    }).isRequired,
    min: PropTypes.number,
    placeholder: PropTypes.string,
    withFocusClear: PropTypes.bool,
};

const TextField = props => <Field component={TextInput} {...props} />;

export default TextField;
