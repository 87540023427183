import { Input, ErrorMessageDiv } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import Wrapper from '../ui/form/Wrapper';

const InputField = ({ min, max, fixedValue, ...props }) => {
    const { meta, input } = props;
    const { active, touched, error = null } = meta;
    const hasError = !active && touched && !!error;

    return (
        <Wrapper {...props}>
            <Input
                type="text"
                {...input}
                maxLength={max}
                minLength={min}
                {...props}
                value={fixedValue || input.value}
            />
            {hasError && <ErrorMessageDiv>{error}</ErrorMessageDiv>}
        </Wrapper>
    );
};

InputField.propTypes = {
    fixedValue: PropTypes.string,
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    max: PropTypes.number,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
    min: PropTypes.number,
};

export default InputField;
