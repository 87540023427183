import { get } from 'lodash/fp';
import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import FieldContainer from '../../../template/Field-container';
import InputField from '../../../template/Input';
import TextareaField from '../../../template/Textarea';
import { useFormContext } from './context';

const Languages = () => {
    const { disabled, languageOptions, values } = useFormContext();
    const hasLegalMarkup = get('hasLegalMarkup', values);

    const getFormatPath = useCallback((language, label) => `${label}.${language}`, []);

    return (
        <div className="container-fluid">
            <div className="row">
                {languageOptions.map((eachLanguage: { value: string; label: string }) => (
                    <div key={eachLanguage.value} className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label={`${eachLanguage.label} Name`}>
                            <Field
                                component={InputField}
                                disabled={disabled}
                                name={getFormatPath(eachLanguage.value, 'name')}
                            />
                        </FieldContainer>
                        <FieldContainer label="Description">
                            <Field
                                component={TextareaField}
                                disabled={disabled}
                                maxLength={5000}
                                name={getFormatPath(eachLanguage.value, 'description')}
                                rows={3}
                            />
                        </FieldContainer>
                        {hasLegalMarkup && (
                            <FieldContainer label="Legal Text">
                                <Field
                                    component={TextareaField}
                                    disabled={disabled}
                                    maxLength={100000}
                                    name={getFormatPath(eachLanguage.value, 'legalMarkup')}
                                    rows={10}
                                />
                            </FieldContainer>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Languages;
