import { NumericInputV2 as NumericInput } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { parseNumber } from '../../../utils/numberHelpers';
import { useCompanyFormatting } from '../../../utils/withCompanyFormatting';
import { CurrencyInput } from './CurrencyField';
import { TextInput } from './TextField';

const useTypeFormats = type => {
    // get formatting
    const formats = useCompanyFormatting();

    switch (type) {
        case 'percentage':
            return {
                precision: formats.percentagePrecision,
                fixedDecimalScale: formats.fixedDecimalScale,
                thousandSeparator: formats.thousandSeparator,
                suffix: '%',
            };

        case 'currency':
            return {
                precision: formats.currencyPrecision,
                fixedDecimalScale: formats.fixedDecimalScale,
                thousandSeparator: formats.thousandSeparator,
                prefix: formats.currencySymbol,
            };

        case 'year':
            return {
                precision: 0,
            };

        case 'number':
        default:
            return {
                precision: formats.currencyPrecision,
                fixedDecimalScale: formats.fixedDecimalScale,
                thousandSeparator: formats.thousandSeparator,
            };
    }
};

const NumberInput = ({ type = 'number', ...props }) => {
    const typeProps = useTypeFormats(type);

    switch (type) {
        case 'currency':
            return <CurrencyInput {...typeProps} {...props} />;
        default:
            return <TextInput inputComponent={NumericInput} {...typeProps} {...props} />;
    }
};

NumberInput.propTypes = {
    type: PropTypes.string.isRequired,
};

const NumberField = props => <Field component={NumberInput} normalize={parseNumber} {...props} />;

export default NumberField;
