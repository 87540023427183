import { useRef, useEffect } from 'react';

const useTooltip = valueNodeRef => {
    const tooltipRef = useRef(null);

    useEffect(() => {
        const { current: valueNode } = valueNodeRef;

        if (!valueNode) {
            return () => {};
        }

        const getIsEllipsisActive = target => {
            const { offsetWidth, scrollWidth, children } = target;

            const generalCondition = offsetWidth < scrollWidth;

            // options value is not text directly, so need to handle specially
            let optionsCondition = false;
            if (children[0]?.children[0]?.children[0]) {
                const {
                    offsetWidth: optionOffsetWidth,
                    scrollWidth: optionScrollWidth,
                } = children[0].children[0].children[0];

                optionsCondition = optionOffsetWidth < optionScrollWidth;
            }

            // promo code value is not text directly, so need to handle specially
            let promoCodeCondition = false;
            if (children[0]?.children[0]) {
                const {
                    offsetWidth: promoCodeOffsetWidth,
                    scrollWidth: promoCodeScrollWidth,
                } = children[0].children[0];

                promoCodeCondition = promoCodeOffsetWidth < promoCodeScrollWidth;
            }

            let promoDesCondition = false;
            if (children[0]?.children[1]) {
                const { offsetWidth: promoDesOffsetWidth, scrollWidth: promoDesScrollWidth } = children[0].children[1];

                promoDesCondition = promoDesOffsetWidth < promoDesScrollWidth;
            }

            return generalCondition || optionsCondition || promoCodeCondition || promoDesCondition;
        };

        const onMouseOver = event => {
            const { current: tooltip } = tooltipRef;

            if (!tooltip) {
                return;
            }

            const isEllipsisActive = getIsEllipsisActive(event.target);

            if (isEllipsisActive) {
                const { clientHeight, clientWidth } = tooltip;
                const { top, left, width } = event.target.parentElement.getBoundingClientRect();

                tooltip.style.top = `${top - clientHeight}px`;
                tooltip.style.left = `${left + width / 2 - clientWidth / 2}px`;
                tooltip.style.visibility = 'visible';
            }
        };

        const onMouseLeave = event => {
            const { current: tooltip } = tooltipRef;

            if (!tooltip) {
                return;
            }

            const isEllipsisActive = getIsEllipsisActive(event.target);

            if (isEllipsisActive) {
                tooltip.style.visibility = 'hidden';
            }
        };

        valueNode.addEventListener('mouseenter', onMouseOver, false);
        valueNode.addEventListener('mouseleave', onMouseLeave, false);

        return () => {
            valueNode.removeEventListener('mouseenter', onMouseOver, false);
            valueNode.removeEventListener('mouseleave', onMouseLeave, false);
        };
    }, [valueNodeRef, tooltipRef]);

    return tooltipRef;
};

export default useTooltip;
