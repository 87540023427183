import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import styled from 'styled-components';
import { ErrorMessageDiv } from '../../containers/Layout';

const PasswordField = ({ input, meta, className }) => {
    const { error, touched, active } = meta;

    const [showPassword, setShow] = useState(false);
    const toggle = useCallback(() => setShow(state => !state), [setShow]);

    return (
        <div className={className}>
            <div className="input-append input-group">
                <input type={showPassword ? 'text' : 'password'} {...input} />
                <span
                    className="add-on input-group-addon"
                    onClick={toggle}
                    style={{ cursor: 'pointer', borderRadius: '0' }}
                >
                    <i className={showPassword ? 'icon-eye-close icmn-eye-blocked' : 'icon-eye-open icmn-eye'} />
                </span>
            </div>
            <ErrorMessageDiv>{touched && !active && error}</ErrorMessageDiv>
        </div>
    );
};

PasswordField.propTypes = {
    className: PropTypes.string,
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};

export default styled(PasswordField)`
    width: 100%;
    input {
        width: 100%;
        border: 0;
        color: #000000;
        background: #f0f0f0;
        height: 30px;
        font-size: 0.85rem;
        padding-left: 10px;
    }
    input[type='text']:disabled {
        background: #d8d8d8;
    }
`;
