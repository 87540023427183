import { useApolloClient } from '@apollo/client';
import { omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { submit } from 'redux-form';
import { getGlobalPermissions } from '../../../selectors';
import { handleResponseError, prepareForGraphQL } from '../../../utilities/forms';
import applySourceChange from '../../../utils/applySourceChange';
import FormLayout from '../../ui/form/FormLayout';
import useValidationContext from '../../utilities/useValidationContext';
import useFormDirty from '../Workflow/common/shared/useFormDirty';
import Form from './Form';
import { update } from './Page.graphql';
import { useCustomerOptions } from './useOptions';

const Page = ({ initialValues = null, journeys = null }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const hasErrors = useFormDirty('customer');
    const { mayManageCustomers } = useSelector(getGlobalPermissions);

    const submitForm = useCallback(() => dispatch(submit('customer')), [dispatch]);
    const client = useApolloClient();

    const onSubmit = useCallback(
        async ({ id, ...data }) => {
            const customerData = applySourceChange(data, initialValues);
            const newData = prepareForGraphQL(omit(['applications', 'type', 'version', 'zone'], customerData));

            const variables = { data: newData, id };

            return client.mutate({ mutation: update, variables }).catch(handleResponseError);
        },
        [client, initialValues]
    );

    const onSubmitSuccess = useCallback(() => history.push('/customer'), [history]);
    const onCancel = useCallback(() => history.goBack(), [history]);

    const options = useCustomerOptions();
    const validation = useValidationContext();

    return (
        <FormLayout
            bodyComponent={
                initialValues &&
                options && (
                    <Form
                        disabled={!mayManageCustomers}
                        initialValues={initialValues}
                        journeys={journeys}
                        onSubmit={onSubmit}
                        onSubmitSuccess={onSubmitSuccess}
                        options={options}
                        validation={validation}
                    />
                )
            }
            moreActions={[
                mayManageCustomers && {
                    label: 'save',
                    onAction: submitForm,
                    disabled: hasErrors,
                },
            ]}
            onCancel={onCancel}
            title="Customer"
        />
    );
};

Page.propTypes = {
    initialValues: PropTypes.shape({}),
    journeys: PropTypes.shape({}),
};

export default Page;
