import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { FormTabContainer } from '../../../../../containers/Layout';
import useFormValues from '../../../../utilities/useFormValues';
import { TabActivityLog } from '../ActivityLog';
import { TabDocument } from '../Document';
import TabAppointmentDetails from './TabAppointmentDetails';
import { AppointmentFormValues, AppointmentFormProps, AppointmentFormContext } from './context';

const AppointmentForm = (
    props: AppointmentFormProps & InjectedFormProps<AppointmentFormValues, AppointmentFormProps>
) => {
    const values = useFormValues<AppointmentFormValues>();
    const context = { ...props, values };

    return (
        <AppointmentFormContext.Provider value={context}>
            <FormTabContainer>
                <Tabs className="tabs">
                    <TabList>
                        <Tab>Appointments</Tab>
                        <Tab>Document</Tab>
                        <Tab>Activity Log</Tab>
                    </TabList>
                    <TabPanel>
                        <TabAppointmentDetails />
                    </TabPanel>
                    <TabPanel>
                        <TabDocument />
                    </TabPanel>
                    <TabPanel>
                        <TabActivityLog />
                    </TabPanel>
                </Tabs>
            </FormTabContainer>
        </AppointmentFormContext.Provider>
    );
};

export default reduxForm<AppointmentFormValues, AppointmentFormProps>({
    form: 'appointment',
})(AppointmentForm);
