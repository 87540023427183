import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCountry } from '../../../../../selectors';

const useStop = (invalidCalculator, modal, values) => {
    const { channelSetting } = useSelector(getCurrentCountry);
    const { allowOptions } = channelSetting.new;

    return useCallback(async () => {
        if (invalidCalculator) {
            modal.confirm({
                title: '',
                content: 'Such Finance Product/Parameters are no longer valid.',
                options: [],
            });

            return true;
        }

        if (!allowOptions && values?.options?.length > 0) {
            let resolveFunction = null;
            const promise = new Promise(resolve => {
                resolveFunction = resolve;
            });

            modal.confirm({
                title: 'Options are not available',
                content: `Your lead will be shared without options. Do you want to continue ?`,
                options: [
                    { label: 'no', action: () => resolveFunction(true) },
                    {
                        label: 'yes',
                        action: () => resolveFunction(false),
                    },
                ],
            });

            const shouldContinue = await promise;

            return shouldContinue;
        }

        return false;
    }, [invalidCalculator, allowOptions, values, modal]);
};

export default useStop;
