import { useApolloClient } from '@apollo/client';
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isArray } from 'lodash';
import React, { useCallback, useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { ErrorMessageDiv } from '../../../../../containers/Layout';
import { withModal, WithModalInjectedProps } from '../../../../Modal';
import FileDropBox from '../../../../template/FileDropBox';
import LoadingIndicator from '../../../../template/LoadingIndicator';
import { PreviewUpload } from '../../../../ui/Document';
import {
    uploadAppointmentAttachment,
    UploadAppointmentAttachmentMutation,
    UploadAppointmentAttachmentMutationVariables,
} from '../Edition.graphql';

type DocumentUploadProps = WrappedFieldProps & {
    referenceId: string;
    purpose: string;
};

const DocumentUpload = ({ meta, input, referenceId }: DocumentUploadProps & WithModalInjectedProps) => {
    const [uploading, setUploading] = useState(false);
    const client = useApolloClient();

    const handleUpload = useCallback(
        async file => {
            // set the state as uploading
            setUploading(true);

            // get the upload url
            const { data } = await client.mutate<
                UploadAppointmentAttachmentMutation,
                UploadAppointmentAttachmentMutationVariables
            >({
                mutation: uploadAppointmentAttachment,
                variables: { file, id: referenceId },
            });

            // set it as uploaded/completed
            setUploading(false);

            if (data) {
                const previousDocuments = isArray(input.value) ? input.value : [];

                input.onChange([...previousDocuments, data?.attachment]);
            }
        },
        [client, input, referenceId]
    );

    const handleDrop = useCallback(
        files => {
            if (files[0]) {
                return handleUpload(files[0]);
            }

            return Promise.resolve();
        },
        [handleUpload]
    );

    const onChange = useCallback(event => handleDrop(event.target.files), [handleDrop]);

    const { error, touched, active } = meta;
    const { onBlur, onFocus } = input;

    return (
        <>
            <PreviewUpload>
                <FileDropBox className="upload" onDrop={handleDrop}>
                    <input onBlur={onBlur} onChange={onChange} onFocus={onFocus} type="file" />
                    <div className="replace-style">
                        <div className="icon">
                            <FontAwesomeIcon icon={faPlus} />
                        </div>
                        <div>upload</div>
                        {uploading && (
                            <LoadingIndicator>
                                <FontAwesomeIcon icon={faSpinner} size="2x" spin />
                            </LoadingIndicator>
                        )}
                    </div>
                </FileDropBox>
            </PreviewUpload>
            <ErrorMessageDiv>{touched && !active && error}</ErrorMessageDiv>
        </>
    );
};

export default withModal(DocumentUpload);
