import { getOr, sortBy, map, flow, keyBy, get } from 'lodash/fp';
import React, { useMemo, useCallback } from 'react';
import { useCalculatorContext } from '../../CalculatorContext';
import { useComparisonContext } from '../../ComparisonContext';
import { OptionContainer, OptionWithPrice, Thumbnails, OptionWithoutThumbnails } from '../../ui/CarOptions';
import EmptyBox from '../../ui/EmptyBox';
import { useFormatCurrency } from './CurrencyField';
import StaticField from './StaticField';

export const useFormatOption = isThumbnails => {
    const { formatCurrency } = useFormatCurrency();

    return useCallback(
        option => {
            const { name = '', price = 0, image, colour } = option;

            if (isThumbnails) {
                return (
                    <OptionContainer>
                        <OptionWithPrice>
                            <span>{name}</span>
                            {!!price && <span>({formatCurrency(price)})</span>}
                        </OptionWithPrice>
                        <Thumbnails color={colour}>{image && <img alt="" src={image?.url} />}</Thumbnails>
                    </OptionContainer>
                );
            }

            return (
                <OptionWithoutThumbnails>
                    <span>{name}</span>
                    {!!price && <span>({formatCurrency(price)})</span>}
                </OptionWithoutThumbnails>
            );
        },
        [formatCurrency, isThumbnails]
    );
};

const CarOptionsField = props => {
    // get the car options from the calculator context
    const { carOptions } = useCalculatorContext();
    const { selected, groups } = carOptions;
    const formatOption = useFormatOption(true);

    // compute fields
    const fields = useMemo(
        () =>
            flow([
                Object.values,
                sortBy(['order']),
                map(({ id, name, options }) => {
                    const option = getOr({}, id, selected);
                    const editionContext = { options, groupId: id, label: name };

                    return { key: id, label: name, value: formatOption(option), editionContext };
                }),
            ])(groups),
        [selected, formatOption, groups]
    );

    // function to render our field
    const renderField = fieldProps => <StaticField {...props} {...fieldProps} size={2} />;

    // we need to retrieve option from comparison too
    const { groups: sharedGroups } = useComparisonContext();

    if (sharedGroups) {
        const mappedFields = keyBy(get('key'), fields);

        return sharedGroups.map(({ id }) => {
            // get field props
            const fieldsProps = get(id, mappedFields);

            if (!fieldsProps) {
                // this group does not exist for this calculator
                return <EmptyBox key={id} />;
            }

            // get the field properties and render it
            return renderField(fieldsProps);
        });
    }

    // otherwise simply render our fields
    return fields.map(renderField);
};

export default CarOptionsField;
