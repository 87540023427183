import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    position: relative;
`;

const StyledSelect = styled.select`
    font-size: 14px;
    text-overflow: ellipsis;
    height: 40px;
    width: 100%;

    position: relative;

    background: transparent;
    border: none;

    // use placeholder color if there's no value
    color: ${props => (props.hasValue ? props.theme.themeHighlightColour : `rgb(128, 128, 128)`)};

    padding-right: ${props => (props.disabled ? 0 : `calc(1rem + 8px)`)};

    // removes appearance for ios
    // unfortunately this removes dropdown icon
    -webkit-appearance: none;
    appearance: none;

    &:focus-visible {
        outline: none;
    }

    &:disabled {
        color: #212529;
        opacity: 0.8;
    }

    // this works for all except for ios mobile devices
    text-align: ${props => (props.isAlt ? 'left' : 'right')};
`;

const StyledIcon = styled(FontAwesomeIcon)`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    color: ${props => props.theme.themeHighlightColour};
`;

const NativeSelect = ({ options, isDisabled: disabled, value: optionValue, onChange, placeholder, ...props }) => {
    const { value: actualValue = undefined } = optionValue || {};

    const handleChange = useCallback(
        ({ target }) => {
            if (onChange) {
                const selectedOption = options.find(option => option.value === target.value);

                onChange(selectedOption);
            }
        },
        [onChange, options]
    );

    const hasValue = !!actualValue;

    return (
        <Container>
            <StyledSelect
                disabled={disabled}
                hasValue={hasValue}
                onChange={handleChange}
                value={actualValue}
                {...props}
            >
                <option disabled hidden selected>
                    {placeholder}
                </option>
                {options.map(({ value, label }) => (
                    <option value={value}>{label}</option>
                ))}
            </StyledSelect>
            {!disabled && <StyledIcon icon={faAngleDown} />}
        </Container>
    );
};

const option = PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
});

NativeSelect.propTypes = {
    isAlt: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(option),
    placeholder: PropTypes.string,
    value: option,
};

export default NativeSelect;
