import MDEditor, { commands } from '@uiw/react-md-editor';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Makedown = styled(MDEditor)`
    outline: none;
    width: 100%;
    border: 0;
    color: #000000;
    background: #f0f0f0;
    font-size: 0.85rem;
    border-radius: 0;

    &:disabled {
        background: #d8d8d8;
    }
`;

const Container = styled.div`
    display: flex;
    align-items: stretch;
    width: 100%;
`;

const PureMarkdown = ({ inputRef, onChange, value }) => (
    <Container>
        <Makedown
            ref={inputRef}
            commands={[
                commands.unorderedListCommand,
                commands.codeEdit,
                commands.codeLive,
                commands.codePreview,
                commands.fullscreen,
            ]}
            height="92"
            onChange={onChange}
            preview="edit"
            value={value}
        />
    </Container>
);

PureMarkdown.propTypes = {
    inputRef: PropTypes.shape({}),
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

export default PureMarkdown;
