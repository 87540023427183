import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';

// watch scope to redirect page
const useScopeChangeEffect = (companyCode, countryCode, zoneCode) => {
    const currentScope = `${companyCode}/${countryCode}/${zoneCode}`;
    const previousScopeRef = useRef(currentScope);
    const history = useHistory();

    useEffect(() => {
        if (currentScope === previousScopeRef.current) {
            // do nothing
            return;
        }

        // update the reference
        previousScopeRef.current = currentScope;

        // get the match
        const { pathname } = history.location;
        const match = pathname.match(/\/[a-z]+/g);

        if (!match || match.length <= 0) {
            // skip it
            return;
        }

        if (pathname.startsWith('/promo')) {
            history.push(match.slice(0, 1).join(''));
        } else if (pathname.startsWith('/workflow') && match.includes('/activation')) {
            history.push(match.slice(0, 1).concat(['/applications']).join(''));
        } else if (
            (pathname.startsWith('/scope') && match.includes('/zones')) ||
            match.includes('/banks') ||
            match.includes('/consents') ||
            match.includes('/coe')
        ) {
            history.push(match.slice(0, 1).concat(['/countries']).join(''));
        } else {
            history.push(match.slice(0, 2).join(''));
        }
    }, [history, previousScopeRef, currentScope]);
};

export default useScopeChangeEffect;
