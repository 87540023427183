import { subYears } from 'date-fns';
import { getOr } from 'lodash/fp';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxFormContext } from 'redux-form';
import { MaskDirection } from '../../../../../../schema';
import { getRuntimeSettings } from '../../../../../../selectors';
import DatePickerField from '../../../../../shared/form-v2/DatePickerField';
import IdentityNumberField from '../../../../../shared/form-v2/IdentityNumberField';
import SelectField from '../../../../../shared/form-v2/SelectField';
import TextField from '../../../../../shared/form-v2/TextField';
import SubTitle from '../../../../../ui/SubTitle';
import usePhone from '../../../../../utilities/usePhone';
import { getLabelWithFlag } from '../../../../Customer/Form/CustomerDetails';
import { useFormContext } from '../../../../Customer/Form/context';
import useCustomerSourceOptions from '../../../../Customer/useCustomerSourceOptions';

const CustomerDetails = () => {
    const { getValues } = useContext(ReduxFormContext);
    const formValues = getValues();
    const { useCustomerBirthDay } = useSelector(getRuntimeSettings);
    const { disabled, options, mask, withInsurance = false } = useFormContext();
    const customer = getOr(formValues, 'customer', formValues);
    const withFocusClear = mask ? mask.direction !== MaskDirection.NONE : false;
    const minDateOfBirth = useMemo(() => subYears(new Date(), 18), []);
    const countryCode = getOr('', 'zone.country.code', formValues);

    const { code: phoneCountryCode } = usePhone(countryCode);

    const customerSourceOptions = useCustomerSourceOptions();

    const { t } = useTranslation();

    return (
        <>
            <SubTitle>Customer Details</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={getLabelWithFlag('Title', customer?.title?.source)}
                        name="customer.title.value"
                        options={getOr([], 'title', options)}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag('Full Name', customer?.name?.source)}
                        name="customer.name.value"
                        withFocusClear={withFocusClear}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag('Email 1', customer?.email?.source)}
                        name="customer.email.value"
                        withFocusClear={withFocusClear}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag('Mobile No. 1', customer?.phone?.source)}
                        name="customer.phone.value"
                        prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                        withFocusClear={withFocusClear}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={getLabelWithFlag('Nationality', customer?.details?.nationality?.source)}
                        name="customer.details.nationality.value"
                        options={getOr([], 'nationalities', options)}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <IdentityNumberField
                        label={getLabelWithFlag('Identity No.', customer?.identityNumber?.source)}
                        name="customer.identityNumber.value"
                        placeholder={t('customerDetails.placeholder.identityNumber')}
                        withFocusClear={withFocusClear}
                        disabled
                    />
                </div>
                {(withInsurance || useCustomerBirthDay) && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DatePickerField
                            label={getLabelWithFlag('Date of Birth', customer?.dateOfBirth?.source)}
                            max={minDateOfBirth}
                            name="customer.dateOfBirth.value"
                            disabled
                        />
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={getLabelWithFlag('Gender', customer?.details?.gender?.source)}
                        name="customer.details.gender.value"
                        options={getOr([], 'genders', customerSourceOptions)}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={getLabelWithFlag('Marital Status', customer?.details?.maritalStatus?.source)}
                        name="customer.details.maritalStatus.value"
                        options={getOr([], 'maritalStatuses', options)}
                        sort={false}
                        disabled
                    />
                </div>
            </div>
        </>
    );
};

export default CustomerDetails;
