import { get } from 'lodash/fp';
import { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ReduxFormContext } from 'redux-form';

const useCustomerNamesSynchronization = (section?: string) => {
    const dispatch = useDispatch();
    const { change } = useContext(ReduxFormContext);

    return useMemo(() => {
        const boundChange = (field: string, value: any) => dispatch(change(prefixWithSection(field), value));

        const prefixWithSection = (key: string) => (section ? `${section}.${key}` : key);

        const onNormalizeFullName = (value: string, previousValue: string) => {
            if (value !== previousValue) {
                boundChange('firstName.value', value);
                boundChange('lastName.value', '.');
            }

            return value;
        };

        const onNormalizeFirstName = (value: string, previousValue: string, allValues: Record<string, any>) => {
            if (value !== previousValue) {
                const lastName = get(prefixWithSection('lastName.value'), allValues);
                boundChange('name.value', lastName ? `${value} ${lastName}` : value);
            }

            return value;
        };

        const onNormalizeLastName = (value: string, previousValue: string, allValues: Record<string, any>) => {
            if (value !== previousValue) {
                const firstName = get(prefixWithSection('firstName.value'), allValues);
                boundChange('name.value', firstName ? `${firstName} ${value}` : value);
            }

            return value;
        };

        return {
            onNormalizeFullName,
            onNormalizeFirstName,
            onNormalizeLastName,
        };
    }, [change, section, dispatch]);
};

export default useCustomerNamesSynchronization;
