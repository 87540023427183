import React, { useState } from 'react';
import CredentialStep from './CredentialStep';
import RequestNewPasswordStep from './RequestNewPasswordStep';
import SelectionStep from './SelectionStep';

const LoginFlow = () => {
    // the login flow can be divided into multiple step
    // we may want to persist data over steps
    const [context, setContext] = useState({ step: 'credentials' });
    const { step, ...extraContext } = context;

    // finally render the step
    switch (step) {
        case 'credentials':
            // we are going to look for valid username and password
            // from which we will retrieve JWT tokens
            return <CredentialStep setContext={setContext} />;

        case 'selection':
            return <SelectionStep {...extraContext} />;

        case 'requestNewPassword':
            return <RequestNewPasswordStep setContext={setContext} />;

        default:
            // something is very wrong
            // this step is unknown
            throw new Error('Unknown step');
    }
};

export default LoginFlow;
