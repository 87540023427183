import React, { createContext, useReducer } from 'react';
import {
    EventApplicationsAction,
    eventApplicationsReducer,
    EventApplicationsState,
    initialEventApplicationsState,
} from './reducer';

export type EventApplicationsContextProps = {
    state: EventApplicationsState;
    dispatch: React.Dispatch<EventApplicationsAction>;
};

export const EventApplicationsContext = createContext<EventApplicationsContextProps | null>(null);

export type EventApplicationsProviderProps = EventApplicationsContextProps & {
    children?: React.ReactNode;
};

export const EventApplicationsProvider = ({ children, state, dispatch }: EventApplicationsProviderProps) => (
    <EventApplicationsContext.Provider value={{ state, dispatch }}>{children}</EventApplicationsContext.Provider>
);

export const useEventApplicationsContext = () => {
    const context = React.useContext(EventApplicationsContext);

    if (context === null) {
        throw new Error('useEventApplicationsContext must be used within a EventApplicationsProvider');
    }

    return context;
};

type WithEventApplicationsProps = {
    eventApplicationsDispatch: React.Dispatch<EventApplicationsAction>;
    eventApplicationsState: EventApplicationsState;
};

export const withEventApplications = <P extends WithEventApplicationsProps>(Component: React.ComponentType<P>) => {
    const WithEventApplicationsContext = (props: Omit<P, keyof WithEventApplicationsProps>) => {
        const [reducerState, reducerDispatch] = useReducer(eventApplicationsReducer, initialEventApplicationsState);

        return (
            <Component
                {...(props as P)}
                eventApplicationsDispatch={reducerDispatch}
                eventApplicationsState={reducerState}
            />
        );
    };

    return WithEventApplicationsContext;
};
