import { get } from 'lodash/fp';
import { useEffect } from 'react';
import { useFormContext } from './context';

const useDefaultValues = (scope, defaultValues) => {
    const { values, change } = useFormContext();

    const fieldValues = get(scope, values);

    useEffect(() => {
        Object.entries(defaultValues).forEach(([key, value]) => {
            if (get(key, fieldValues) === undefined) {
                change(`${scope}.${key}`, value);
            }
        });
    }, [fieldValues, defaultValues, change, scope]);
};

export default useDefaultValues;
