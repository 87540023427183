import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import styled from 'styled-components';
import { ErrorMessageDiv } from '../../containers/Layout';
import Input from '../ui/form/Input';
import Wrapper from '../ui/form/Wrapper';

const Suffix = styled.span`
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    border-radius: 0;
`;

const FileInput = styled.input`
    display: none;
`;

const KeyField = props => {
    const { meta, input, accept, hashFun, disabled } = props;
    const { value, onChange: change, name } = input;
    const { active, touched, error = null, dirty } = meta;
    // to display error when file is invalid
    const hasError = (!active && touched && !!error) || dirty;

    const [hash, setHash] = useState('');

    const processKey = useCallback(
        event => {
            const { files } = event.target;

            if (!files?.length) {
                change('');

                return;
            }

            const file = files[0];

            // read the file
            const reader = new FileReader();
            reader.readAsText(file, 'UTF-8');
            reader.onload = loadEvent => change(loadEvent.target.result);
            reader.onerror = console.error;
        },
        [change]
    );

    useEffect(() => {
        if (value) {
            hashFun(value)
                .then(computedHash => setHash(computedHash))
                .catch(console.error);
        }
    }, [value, setHash, hashFun]);

    const inputProps = {
        name: `${name}-key`,
        value: hash,
        disabled,
    };

    const fileInputRef = useRef(null);
    const selectKeyFile = useCallback(() => {
        if (!disabled) {
            fileInputRef.current.click();
        }
    }, [disabled]);

    return (
        <Wrapper>
            <div className="input-append input-group">
                <Input
                    {...inputProps}
                    onClick={selectKeyFile}
                    style={{ cursor: disabled ? 'default' : 'pointer' }}
                    readOnly
                />
                <Suffix className="add-on input-group-addon" disabled={disabled} onClick={selectKeyFile}>
                    <FontAwesomeIcon icon={faUpload} />
                </Suffix>
            </div>
            <FileInput ref={fileInputRef} accept={accept} name={`${name}-input`} onChange={processKey} type="file" />
            {hasError && <ErrorMessageDiv>{error}</ErrorMessageDiv>}
        </Wrapper>
    );
};

KeyField.propTypes = {
    accept: PropTypes.string,
    disabled: PropTypes.bool,
    hashFun: PropTypes.func.isRequired,
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};

export default KeyField;
