import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Modal from 'react-modal';
import App from './App';
import { GlobalErrorProvider } from './components/utilities/errors';

const node = (
    <GlobalErrorProvider>
        <App />
    </GlobalErrorProvider>
);

ReactDOM.render(node, document.getElementById('root'));

Modal.setAppElement('#root');
