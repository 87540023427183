import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Field, FieldArray, fieldArrayFieldsPropTypes } from 'redux-form';
import styled from 'styled-components';
import { FootBarButton } from '../../../../../containers/Layout';
import FieldContainer from '../../../../template/Field-container';
import TextareaField from '../../../../template/Textarea';
import { useFormContext } from './context';

const RemoveDisclaimer = styled.div`
    text-align: right;
    color: #000;
    cursor: pointer;
`;

const PriceDisclaimerArray = ({ fields, label }) => {
    const { values, change } = useFormContext();
    const enablePriceDisclaimer = get('setting.enablePriceDisclaimer', values);

    useEffect(() => {
        if (!enablePriceDisclaimer) {
            change('setting.priceDisclaimers', []);
        }
        // add a blank field when enable price disclaimer
        if (fields.length === 0 && enablePriceDisclaimer) {
            fields.push('');
        }
    }, [change, enablePriceDisclaimer, fields]);

    const add = useCallback(() => fields.push(''), [fields]);

    const items = useMemo(
        () =>
            fields.map((name, index, { remove }) => {
                const usedLabel = index === 0 && label;

                return (
                    <div key={index.toString()} className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label={usedLabel}>
                            <RemoveDisclaimer>
                                <FontAwesomeIcon icon={faTimes} onClick={() => remove(index)} />
                            </RemoveDisclaimer>
                            <Field component={TextareaField} name={name} />
                        </FieldContainer>
                    </div>
                );
            }),
        [fields, label]
    );

    if (!enablePriceDisclaimer) {
        return null;
    }

    return (
        <>
            <hr />
            <div className="row">{items}</div>
            <FootBarButton onClick={add}>
                <FontAwesomeIcon icon={faPlus} /> ADD FIELD
            </FootBarButton>
        </>
    );
};

PriceDisclaimerArray.propTypes = {
    fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
    label: PropTypes.string,
};

PriceDisclaimerArray.defaultProps = {
    label: 'Price Disclaimer',
};

const PriceDisclaimer = ({ name, label }) => (
    <FieldArray component={PriceDisclaimerArray} label={label} name={name} rerenderOnEveryChange />
);

PriceDisclaimer.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
};

PriceDisclaimer.defaultProps = {
    label: 'Price Disclaimer',
    name: 'setting.priceDisclaimers',
};

export default PriceDisclaimer;
