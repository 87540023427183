import PropTypes from 'prop-types';
import React from 'react';
import BoxedContainer from './BoxedContainer';

const BoxedWrapper = ({ label, name, children, ...props }) => (
    <BoxedContainer {...props}>
        <BoxedContainer.Label htmlFor={name}>{label}</BoxedContainer.Label>
        {children}
    </BoxedContainer>
);

BoxedWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    label: PropTypes.string,
    name: PropTypes.string,
};

BoxedWrapper.FullWidth = props => <BoxedWrapper {...props} fullWidth />;

export default BoxedWrapper;
