import React from 'react';
import { useSelector } from 'react-redux';
import { useContentTranslation } from '../../../i18n';
import { getCompany } from '../../../selectors';
import FadeInDown from '../../animations/FadeInDown';
import { useCopyright } from '../../shared/Footer';
import RootStyle from '../../ui/RootStyle';
import LoginContainer from '../../ui/login/LoginContainer';
import LoginFooter from '../../ui/login/LoginFooter';
import LoginFlow from './LoginFlow';

export const getLoginContainerProps = (company, ct) => {
    if (!company) {
        return { noBrand: true };
    }

    return {
        brandName: ct(company.name),
        brandWelcome: ct(company.description),
    };
};

const LoginRoute = () => {
    const company = useSelector(getCompany);
    const { ct } = useContentTranslation();

    const copyright = useCopyright();

    return (
        <>
            <RootStyle />
            <FadeInDown visible>
                <LoginContainer {...getLoginContainerProps(company, ct)}>
                    <LoginFlow />
                </LoginContainer>
                <LoginFooter>{copyright}</LoginFooter>
            </FadeInDown>
        </>
    );
};

export default LoginRoute;
