import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, getOr, isNil, keyBy } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field } from 'redux-form';
import { FootBarButton, TabExcelContainer } from '../../../../../containers/Layout';
import { useContentTranslation } from '../../../../../i18n';
import { TableValueVehicleType } from '../../../../../schema';
import TableField, { createColumns } from '../../../../TableField';
import DropdownField from '../../../../template/Dropdown';
import UnitField from '../../../../template/options-field/UnitField';
import { exportFinancialTabsExcel, getWorkbookToExport } from '../../../../utilities/excel';
import ImportFinanceTablesModal from '../../lists/ImportFinanceTablesModal';
import { GetProductQuery } from '../Edition.graphql';
import { APIData, useFormContext } from './context';
import { GetFinderVehiclesQuery } from './data.graphql';

const mappedHeaders = {
    'Term *': 'term',
    'Mileage *': 'mileage',
    'Variant Name': 'variantName',
    'Variant ID *': 'variantId',
    'Residual Value *': 'value',
};

type Variants = APIData['variants']['items'];
type FinderVehicles = GetFinderVehiclesQuery['finderVehicles'];
type Product = Exclude<GetProductQuery['product'], undefined | null>;
type SelectedVariants = Product['variants'];
type SelectedFinderVehicles = Product['finderVehicles'];
type TermSetting = Product['termSetting'];
type ResidualValueSetting = Product['residualValueSetting'];
type MileageSetting = Product['averageMileageSetting'];
type Table = Exclude<ResidualValueSetting, null | undefined>['table'];

type RowValues = { variantId: string; mileage: number; vehicleType: TableValueVehicleType };
type Row = { values: RowValues; label: string };

const getMileageValues = (setting: MileageSetting): number[] => {
    const { min = 0, max = 0, step = 0 } = setting || {};
    const options: number[] = [];

    if (min === undefined || max === undefined) {
        return [];
    }

    if (min === max) {
        return [min];
    }

    if (!step) {
        return [];
    }

    for (let value = min; value <= max; value += step) {
        options.push(value);
    }

    return options;
};

const getVariantAndTermKey = ({ variantId, term, mileage }: { variantId: string; term: number; mileage: number }) =>
    `${variantId}-${mileage}-${term}`;

const ResidualValueTab = () => {
    const { formatPath, ct } = useContentTranslation();
    const { disabled, rounding, values, apiData, change, currency } = useFormContext();
    const [showImport, setShowImport] = useState(false);

    // prepare values
    const productName = get(formatPath('name'), values);
    const termSettings: TermSetting = getOr({}, 'termSetting', values);
    const mileageSetting = getOr({}, 'averageMileageSetting', values);
    const selectedVariants: SelectedVariants = getOr([], 'variants', values);
    const selectFinderVehicles: SelectedFinderVehicles = getOr([], 'finderVehicles', values);
    const selectedValues = [...selectedVariants, ...selectFinderVehicles];
    const variants: Variants = getOr([], 'variants.items', apiData);
    const finderVehicles: FinderVehicles = getOr([], 'finderVehicles', apiData);
    const table: Table = getOr([], 'residualValueSetting.table', values);

    // prepare options
    const mileageValues = useMemo(() => getMileageValues(mileageSetting), [mileageSetting]);
    const mileageOptions = useMemo(
        () => [{ label: 'All', value: 'all' }, ...mileageValues.map(mileage => ({ value: mileage, label: mileage }))],
        [mileageValues]
    );
    // selected options
    const selectedVariantsOptions = useMemo(
        () => [
            { label: 'All', value: 'all' },
            ...variants
                .filter(variant => selectedVariants.includes(variant.value))
                .map(variant => ({
                    ...variant,
                    label: ct(variant.label),
                })),
            ...finderVehicles.filter(finderVehicle => selectFinderVehicles.includes(finderVehicle.value)),
        ],
        [ct, finderVehicles, selectFinderVehicles, selectedVariants, variants]
    );

    // build rows with selected options
    const rows = useMemo(() => {
        const rowVariants = selectedVariants.flatMap(variantId => {
            const variant = variants.find(item => item.value === variantId);

            return mileageValues.map(
                (mileage): Row => ({
                    values: { variantId, mileage, vehicleType: TableValueVehicleType.VARIANT },
                    label: `${ct(variant?.label)} / ${mileage}`,
                })
            );
        });

        const rowFinderVehicles = selectFinderVehicles.flatMap(variantId => {
            const finderVehicle = finderVehicles.find(item => item.value === variantId);

            return mileageValues.map(
                (mileage): Row => ({
                    values: { variantId, mileage, vehicleType: TableValueVehicleType.FINDERVEHICLE },
                    label: `${finderVehicle?.label} / ${mileage}`,
                })
            );
        });

        return [...rowVariants, ...rowFinderVehicles];
    }, [selectedVariants, selectFinderVehicles, variants, mileageValues, ct, finderVehicles]);

    // make a filter function to only display specific rows in the table
    const variantFilter = get('__exclude.filterByVariantId', values);
    const mileageFilter = get('__exclude.filterByMileage', values);
    const filter = useCallback(
        (computedRows: Row[]) => {
            let filteredRows = computedRows;

            if (variantFilter && variantFilter !== 'all') {
                filteredRows = filteredRows.filter(row => row.values.variantId === variantFilter);
            }

            if (mileageFilter && mileageFilter !== 'all') {
                filteredRows = filteredRows.filter(row => row.values.mileage === mileageFilter);
            }

            return filteredRows;
        },
        [mileageFilter, variantFilter]
    );

    // reset the filter if the selected value became invalid
    useEffect(() => {
        if (variantFilter && !selectedVariantsOptions.some(option => option.value === variantFilter)) {
            change('__exclude.filterByVariantId', 'all');
        }

        if (mileageFilter && !mileageOptions.some(option => option.value === mileageFilter)) {
            change('__exclude.filterByMileage', 'all');
        }
    }, [selectedVariantsOptions, mileageOptions, change, variantFilter, mileageFilter]);

    const exportExcel = useCallback(async () => {
        const workbook = await getWorkbookToExport(mappedHeaders);
        const worksheet = workbook.getWorksheet(1);
        const terms = createColumns(termSettings).map(val => val.value);
        const tableDataByVariantAndTerm = keyBy(getVariantAndTermKey, table || []);

        rows.forEach(row => {
            const { variantId, mileage, vehicleType } = row.values;

            const variantName =
                vehicleType === TableValueVehicleType.FINDERVEHICLE
                    ? finderVehicles.find(item => item.value === variantId)?.label
                    : ct(variants.find(item => item.value === variantId)?.label);

            terms.forEach(term => {
                const variantAndTermKey = getVariantAndTermKey({ variantId, term, mileage });
                worksheet.addRow({
                    term,
                    mileage,
                    variantId,
                    variantName,
                    value: getOr(null, `${variantAndTermKey}.value`, tableDataByVariantAndTerm),
                });
            });
        });

        exportFinancialTabsExcel(workbook, ct(productName), 'Residual Value');
    }, [termSettings, table, rows, ct, productName, finderVehicles, variants]);

    const onImportClose = useCallback(() => setShowImport(false), [setShowImport]);

    const onImport = useCallback(
        async (data: any[]) => {
            const tableDataList = data
                .filter(record => !isNil(record.value))
                .map(value => ({
                    variantId: value.variantId,
                    term: value.term,
                    value: value.value,
                    mileage: value.mileage,
                    vehicleType: finderVehicles.find(finderVehicle => finderVehicle.value === value?.variantId)
                        ? TableValueVehicleType.FINDERVEHICLE
                        : TableValueVehicleType.VARIANT,
                }));

            change('residualValueSetting.table', tableDataList);
        },
        [change, finderVehicles]
    );

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <div className="sub-container-fluid">
                        <UnitField
                            currencySymbol={currency}
                            label="Residual Value Unit"
                            name="residualValueSetting.defaultUnit"
                        />
                        <span />
                        <div className="flex-2" />
                    </div>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DropdownField
                        label="Filter by Mileage"
                        name="__exclude.filterByMileage"
                        options={mileageOptions}
                        placeholder="Select Mileage"
                        noSort
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DropdownField
                        label="Filter by Variant"
                        name="__exclude.filterByVariantId"
                        options={selectedVariantsOptions}
                        placeholder="Select Variant"
                        noSort
                    />
                </div>
            </div>
            <Field
                columnKey="term"
                component={TableField}
                disabled={disabled}
                filter={filter}
                header="Variant / Term"
                name="residualValueSetting.table"
                precision={rounding.percentage.count}
                rows={rows}
                settings={termSettings}
            />
            <TabExcelContainer>
                <FootBarButton onClick={() => setShowImport(true)}>
                    <FontAwesomeIcon icon={faUpload} /> IMPORT EXCEL
                </FootBarButton>
                <FootBarButton onClick={exportExcel}>
                    <FontAwesomeIcon icon={faDownload} /> EXPORT EXCEL
                </FootBarButton>
            </TabExcelContainer>
            {showImport && (
                <ImportFinanceTablesModal
                    mappedHeaders={mappedHeaders}
                    onClose={onImportClose}
                    onImport={onImport}
                    type="Residual Value Table"
                    variants={selectedValues}
                />
            )}
        </div>
    );
};

export default ResidualValueTab;
