import styled from 'styled-components';
import { OptionContainer, OptionWithPrice } from './CarOptions';
import CalculatorContainer from './Container';
import LabelBox from './LabelBox';
import ParameterBox from './ParameterBox';
import ParameterValue from './ParameterValue';
import { PromoWithCode } from './PromoCodeValue';

const ComparisonContainer = styled.div`
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;

    & > * {
        margin-left: 5px;

        &:first-child {
            padding-top: 130px;
            margin-left: 0;
        }
    }

    ${CalculatorContainer} {
        margin-left: 5px;
        width: 240px;

        ${ParameterBox} {
            align-items: start;

            ${ParameterValue} {
                text-align: left;

                ${OptionContainer} {
                    position: static;

                    ${OptionWithPrice} {
                        max-width: calc(100% - 30px);
                        margin: 0;
                        justify-content: flex-start;
                    }
                }

                ${PromoWithCode} {
                    justify-content: flex-start;
                }
            }
        }
    }

    ${ParameterBox}, ${LabelBox} {
        height: 40px;
        min-height: 40px;
    }
`;

export default ComparisonContainer;
