import React from 'react';
import { useSelector } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import styled from 'styled-components';
import TextareaField from '../../../template/Textarea';
import { Context, FormProps } from './context';
import validate from './validate';

const SITE_URL = 'https://vrl.lta.gov.sg/lta/vrl/action/pubfunc?ID=EnquireRebateBeforeDeReg';

const NoteContainer = styled.div`
    color: #000;
    margin-bottom: 10px;
`;

const Note = () => (
    <NoteContainer>
        Please proceed to&nbsp;
        <a href={SITE_URL} rel="noreferrer" target="_blank">
            LTA Vehicle Enquiry site
        </a>
        &nbsp;to retrieve the data for the vehicle you want to insert. When viewing the Vehicle Data screen, select all
        and copy the data and paste into the field below.
    </NoteContainer>
);

const Form = (props: FormProps & InjectedFormProps<{ raw?: string | null }, FormProps>) => {
    const { form } = props;

    const values = useSelector(getFormValues(form));

    const context = { ...props, values };

    return (
        <Context.Provider value={context}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12">
                        <Note />
                        <Field component={TextareaField} name="raw" rows={10} />
                    </div>
                </div>
            </div>
        </Context.Provider>
    );
};

// @ts-ignore
export default reduxForm({ form: 'finderVehiclePrefill', validate, enableReinitialize: true })(Form);

export { default as parse } from './parse';
