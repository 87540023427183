import { CalculatorContext } from '@appvantageasia/afc-calculator-ui-next';
import { displayFields } from '@appvantageasia/afc-calculator-ui-next/build/components/fields';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { FormSection } from 'redux-form';
import { Channel } from '../../../../../../schema';
import { Grid } from '../../../../../ui/calculator';
import { PreOwnedCarDetailsDataFragment, VariantDataFragment } from '../../../common/data/useLoadVariants.graphql';
import { FinanceDataFragment } from '../../data/useFinanceProducts.graphql';
import ConnectedCalculator, { ConnectedCalculatorProps } from '../ConnectedCalculator';
import expressComputingFields from '../expressComputingFields';
import { CalculatorSnapshot, CalculatorValues } from '../types';
import useCalculatorMeta from '../useCalculatorMeta';
import useDealerEstablishment from '../useDealerEstablishment';
import PreOwnedDetails from './PreOwnedDetails';
import useExpressFinanceProduct from './useExpressFinanceProduct';
import useExpressFinanceProducts from './useExpressFinanceProducts';
import useExpressSnapshot from './useExpressSnapshot';

export type ExpressCalculatorProps = {
    variant: VariantDataFragment;
    preOwnedCarDetails: PreOwnedCarDetailsDataFragment;
    financeProducts: FinanceDataFragment[];
    snapshot: CalculatorSnapshot;
    countryCode: string;
} & ConnectedCalculatorProps;

type ExpressWrapperProps = {
    countryCode: string;
    children?: React.ReactNode;
};

const ExpressWrapper = ({ countryCode, children }: ExpressWrapperProps) => {
    if (countryCode === 'SG') {
        return (
            <Grid>
                <FormSection name="expressVariant">
                    <PreOwnedDetails />
                </FormSection>
                <div>{children}</div>
            </Grid>
        );
    }

    return <div>{children}</div>;
};

const ExpressCalculator = ({
    variant,
    initialValues: init,
    preOwnedCarDetails,
    snapshot: initialSnapshot,
    financeProducts,
    meta: initialMeta,
    onChange,
    countryCode,
    ...props
}: ExpressCalculatorProps) => {
    const { dealerId } = props;
    const [totalPrice, setTotalPrice] = useState(0);

    const variantId = variant.id;
    const variantVersionId = variant.version.id;
    const expressFinanceProduct = useExpressFinanceProduct(preOwnedCarDetails);
    const snapshot = useExpressSnapshot(expressFinanceProduct, initialSnapshot);
    const expressFinanceProducts = useExpressFinanceProducts(
        financeProducts,
        expressFinanceProduct,
        variantVersionId,
        totalPrice
    );

    const { selectedDealerEstablishment } = useDealerEstablishment(dealerId, Channel.EXPRESS);

    const onChangeEnhanced = useCallback(
        (context: CalculatorContext<CalculatorValues>) => {
            if (context.values.totalPrice) {
                setTotalPrice(context.values.totalPrice);
            }

            if (onChange) {
                onChange(context);
            }
        },
        [onChange]
    );

    const initialValues = useMemo(() => ({ ...init, variant: variantId }), [variantId, init]);

    const additionalMeta = useMemo(
        () => ({
            ...initialMeta,
            channel: Channel.EXPRESS,
            variants: [variant],
            financeProducts: expressFinanceProducts,
            snapshot,
            zoneId: initialMeta.zoneId!,
            selectedDealerEstablishment,
        }),
        [expressFinanceProducts, initialMeta, selectedDealerEstablishment, snapshot, variant]
    );
    const meta = useCalculatorMeta(additionalMeta);

    if (!expressFinanceProducts?.length) {
        // something is still missing to properly initialize a stateful component
        // such as the calculator
        return null;
    }

    return (
        <ExpressWrapper countryCode={countryCode}>
            <ConnectedCalculator
                {...props}
                fields={expressComputingFields}
                initialValues={initialValues}
                meta={meta}
                onChange={onChangeEnhanced}
            >
                <displayFields.CarModelPriceField
                    fieldKey="carModelAndPrice"
                    isViewable={() => false}
                    size={2}
                    override
                />
                <displayFields.CarModelField
                    fieldKey="variant"
                    isViewable={() => false}
                    labelTKey="calculator.label.carModel"
                    size={2}
                    override
                />
                <displayFields.CarPriceField
                    fieldKey="carPrice"
                    isViewable={() => true}
                    labelTKey="expressCalculatorPage.calculator.carPrice"
                    size={2}
                    override
                />
            </ConnectedCalculator>
        </ExpressWrapper>
    );
};

export default memo(ExpressCalculator);
