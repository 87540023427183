import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { getFormValues, ReduxFormContext } from 'redux-form';

const useFormValues = <TValues = any>() => {
    // get form values
    const { form } = useContext(ReduxFormContext);
    const valueSelector = useCallback(state => getFormValues(form)(state), [form]);

    return useSelector(valueSelector) as TValues;
};

export default useFormValues;
