import { CalculatorSelect as Select } from '@appvantageasia/afc-ui';
import { sortBy, get, flow, map, uniqBy, filter, identity } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useCalculatorContext } from '../../CalculatorContext';

const getMakesFromVariants = flow([
    map(get('model.make')),
    uniqBy(get('id')),
    sortBy(['order', 'name']),
    map(make => ({
        value: make.id,
        label: make.name,
    })),
]);

const getModelsFromVariants = flow([
    map(({ model }) => model.parent || model),
    uniqBy(get('id')),
    sortBy(['order', 'name']),
    map(model => ({
        value: model.id,
        label: model.name,
    })),
]);

const getSubModelsFromVariants = flow([
    map(({ model }) => model.parentId && model),
    filter(Boolean),
    uniqBy(get('id')),
    sortBy(['order', 'name']),
    map(subModel => ({
        value: subModel.id,
        label: subModel.name,
    })),
]);

const getVariantsFromSubModel = (subModel, variants) =>
    flow([
        subModel ? filter(variant => variant.model?.id === subModel) : identity,
        sortBy(['order', 'name']),
        map(variant => ({
            value: variant.id,
            label: variant.name,
        })),
    ])(variants);

const UpdateCarModel = ({ initialValue, valueRef }) => {
    // get the variants from the context
    const { variants } = useCalculatorContext();

    // get initial variants
    const initialVariant = useMemo(() => {
        return variants.find(variant => variant.id === initialValue) || variants?.[0];
    }, [variants, initialValue]);

    const [state, setState] = useState({
        make: initialVariant.model.make.id,
        model: initialVariant.model?.parentId || initialVariant.model?.id,
        subModel: initialVariant.model?.parentId ? initialVariant.model?.id : null,
        // for variants we use the variant ID
        variant: initialVariant.id,
    });

    // we can always read the make directly from the state
    const { make } = state;

    // previous state persisting in a ref
    const previousStateRef = useRef(state);
    const { current: previousState } = previousStateRef;

    // get makes from variants
    const makes = useMemo(() => getMakesFromVariants(variants), [variants]);

    // filter with make
    const makeVariants = useMemo(() => {
        return variants.filter(variant => variant.model.make.id === make);
    }, [make, variants]);

    // get models from make variants
    const models = useMemo(() => getModelsFromVariants(makeVariants), [makeVariants]);

    // if the make changed, get the first model
    // otherwise keep using the one in the state
    const model = previousState.make === make ? state.model : models[0].value;

    // filter with model
    const modelVariants = useMemo(
        () => makeVariants.filter(variant => (variant.model?.parentId || variant.model?.id) === model),
        [model, makeVariants]
    );

    // get sub models from model variants
    const subModels = useMemo(() => getSubModelsFromVariants(modelVariants), [modelVariants]);

    // if the model changed, get the first sub model
    // otherwise keep using the one in the state
    const subModel = previousState.model === model ? state.subModel : subModels[0]?.value;

    // then filter with sub models
    const availableVariants = useMemo(() => {
        return getVariantsFromSubModel(subModel, modelVariants);
    }, [subModel, modelVariants]);

    // if either the model or sub model changed, get the first available variant
    // otherwise keep using the one in the state
    const currentVariant =
        previousState.model === model && previousState.subModel === subModel
            ? state.variant
            : availableVariants[0]?.value;

    // finally update previous state
    previousStateRef.current = { make, model, subModel, variant: currentVariant };

    // we are going to execute an effect to update the value in the reference
    useEffect(() => {
        // update the value on the reference
        // eslint-disable-next-line no-param-reassign, react/prop-types
        valueRef.current = currentVariant;
    }, [valueRef, currentVariant]);

    // also keep the state up to date
    useEffect(() => {
        // this one will trigger a react update
        // however we re-filtered every level of the variant definition in one-shot
        // and it will be persisted with the help of the reference
        setState({ make, model, subModel, variant: currentVariant });
    }, [make, model, subModel, currentVariant]);

    // get selected entries
    const selected = useMemo(
        () => ({
            make: makes.find(option => option.value === make),
            model: models.find(option => option.value === model),
            subModel: subModels.find(option => option.value === subModel),
            variant: availableVariants.find(option => option.value === currentVariant),
        }),
        [make, makes, model, models, subModel, subModels, currentVariant, availableVariants]
    );

    const onChange = key => option => setState({ ...state, [key]: option.value });

    return (
        <>
            {makes.length > 1 && (
                <Select key="make" name="make" onChange={onChange('make')} options={makes} value={selected.make} />
            )}
            <Select key="model" name="model" onChange={onChange('model')} options={models} value={selected.model} />
            {subModels.length > 0 && (
                <Select
                    key="subModel"
                    name="subModel"
                    onChange={onChange('subModel')}
                    options={subModels}
                    value={selected.subModel}
                />
            )}
            <Select
                key="variant"
                name="variant"
                onChange={onChange('variant')}
                options={availableVariants}
                value={selected.variant}
            />
        </>
    );
};

UpdateCarModel.propTypes = {
    initialValue: PropTypes.string,
    valueRef: PropTypes.shape({}).isRequired,
};

export default UpdateCarModel;
