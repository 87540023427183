import { useApolloClient } from '@apollo/client';
import { omit } from 'lodash/fp';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { submit } from 'redux-form';
import { addNotification } from '../../../../actions';
import { useContentTranslation } from '../../../../i18n';
import { AdhocAttachmentPurpose } from '../../../../schema';
import { getGlobalPermissions } from '../../../../selectors';
import { INVENTORY_SAVE_NOTIFICATION } from '../../../../shared/constants/notification';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import useLanguageSelector from '../../../../utils/useLanguageSelector';
import useValidation from '../../../../utils/useValidation';
import FormLayout from '../../../ui/form/FormLayout';
import { uploadImage } from '../variant/Page.graphql';
import Form from './Form';
import { FormValues } from './Form/context';
import validator from './Form/validate';
import { update } from './Page.graphql';

type PageProps = {
    initialValues: FormValues | null;
};

const Page = ({ initialValues = null }: PageProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const client = useApolloClient();
    const languageSelector = useLanguageSelector();
    const { mayManageVehicles } = useSelector(getGlobalPermissions);
    const { mapIntlValue } = useContentTranslation();
    // action to submit the redux form
    const submitForm = useCallback(() => dispatch(submit('inventoryUnit')), [dispatch]);

    // submit callback
    const onSubmit = useCallback(
        ({ inventoryUnit }) => {
            const { images = [], ...data } = inventoryUnit;

            const upload = async ({ file, id }: { file: File; id: string }) => {
                // upload the file
                if (file instanceof File) {
                    const { data: imageData } = await client.mutate({
                        mutation: uploadImage,
                        variables: { purpose: AdhocAttachmentPurpose.INVENTORYUNITIMAGE, file },
                    });

                    // turn it into an object
                    return imageData.response?.id;
                }

                return id;
            };

            const promise = Promise.all(images.map(upload));

            return promise
                .then(imageIds => {
                    const variables = {
                        unitId: inventoryUnit.id,
                        data: {
                            ...prepareForGraphQL(
                                omit(['id', 'booking', 'version', 'identifier'], {
                                    ...data,
                                    details: {
                                        ...data.details,
                                        features: mapIntlValue(data.details.features),
                                        description: mapIntlValue(data.details.description),
                                    },
                                })
                            ),
                            imageIds,
                            // transform prices object (local state) into an array (remote state)
                            prices: Object.entries(data.prices)
                                .map(([zoneId, value]) => ({ zoneId, value }))
                                .filter(price => price.value !== null),
                        },
                    };

                    return client.mutate({ mutation: update, variables });
                })
                .catch(handleResponseError);
        },
        [client, mapIntlValue]
    );

    // success callback
    const onSubmitSuccess = useCallback(() => {
        dispatch(addNotification(INVENTORY_SAVE_NOTIFICATION));
        history.push(`/vehicle/inventories/${initialValues?.inventory?.id}/${initialValues?.inventorySet?.id}`);
    }, [history, dispatch, initialValues]);

    // cancel callback
    const onCancel = useCallback(() => history.goBack(), [history]);

    const validate = useValidation(validator);

    return (
        <FormLayout
            bodyComponent={
                initialValues && (
                    <Form
                        {...languageSelector}
                        disabled={!mayManageVehicles}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onSubmitSuccess={onSubmitSuccess}
                        validate={validate}
                    />
                )
            }
            moreActions={[
                mayManageVehicles && {
                    label: 'save',
                    onAction: submitForm,
                },
            ]}
            onCancel={onCancel}
            title={initialValues?.title ? `Sub Inventory Details - ${initialValues?.title}` : 'Sub Inventory Details'}
        />
    );
};

export default Page;
