import { useQuery } from '@apollo/client';
import {
    CalculatorContext,
    CalculatorFields,
    GridCalculator,
    GridCalculatorProps,
    useCalculatorContext,
    defaultComputingFields,
    defaultPreset,
} from '@appvantageasia/afc-calculator-ui-next';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import useMedia from 'use-media';
import { useContentTranslation } from '../../../../../i18n';
import { getCurrentCountry } from '../../../../../selectors';
import { Formats, useCompanyFormatting } from '../../../../../utils/withCompanyFormatting';
import {
    getHasValidPromoCodesByCountryId,
    GetHasValidPromoCodesByCountryIdQuery,
    GetHasValidPromoCodesByCountryIdQueryVariables,
} from '../data/useLoadPromo.graphql';
import { CalculatorMeta, CalculatorValues } from './types';

export type CompanyCalculatorProps = {
    onChange?: (context: CalculatorContext<CalculatorValues>) => void;
    initialValues: Partial<CalculatorValues>;
    fields?: CalculatorFields<CalculatorValues>;
    meta: Partial<CalculatorMeta>;
    children?: ReactNode;
    preset?: JSX.Element;
} & Omit<GridCalculatorProps<CalculatorValues>, 'context' | keyof Formats | 't' | 'ct' | 'language' | 'children'>;

export const useCompanyCalculatorTheme = () => {
    // build the theme for the UI
    const defaultTheme = useTheme();
    const singleColumn = useMedia({ maxWidth: 320 });

    return useMemo(
        () => ({
            ...defaultTheme,
            calculator: {
                ...defaultTheme.calculator,
                singleColumn,
            },
        }),
        [defaultTheme, singleColumn]
    );
};

const CompanyCalculator = ({
    onChange,
    initialValues,
    fields = defaultComputingFields,
    meta,
    children,
    preset = defaultPreset,
    ...props
}: CompanyCalculatorProps) => {
    const { t } = useTranslation();
    const { ct, language } = useContentTranslation();
    const { id: countryId } = useSelector(getCurrentCountry);

    // get formatting
    const formats = useCompanyFormatting();
    const theme = useCompanyCalculatorTheme();

    const { data: hasValidPromo } = useQuery<
        GetHasValidPromoCodesByCountryIdQuery,
        GetHasValidPromoCodesByCountryIdQueryVariables
    >(getHasValidPromoCodesByCountryId, {
        variables: {
            countryId,
        },
        fetchPolicy: 'cache-first',
    });

    const hasValidPromoCodes = hasValidPromo?.hasValidPromoCodes;

    const enhancedMeta = useMemo(() => ({ ...meta, hasValidPromoCodes }), [hasValidPromoCodes, meta]);

    const context = useCalculatorContext<CalculatorValues>(initialValues, fields, enhancedMeta);

    useEffect(() => {
        if (onChange) {
            onChange(context);
        }
    }, [context, onChange]);

    return (
        <GridCalculator context={context} theme={theme} {...formats} {...props} ct={ct} language={language} t={t}>
            {preset}
            {children}
        </GridCalculator>
    );
};

export default CompanyCalculator;
