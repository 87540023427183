import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { useContentTranslation } from '../../../../../i18n';
import { toFormState } from '../../../../../utilities/applications/toFormState';
import withCompanyFormatting, { Formats } from '../../../../../utils/withCompanyFormatting';
import useFormatDateTime from '../../../../shared/useFormatDateTime';
import {
    getApplication,
    GetApplicationQuery,
    GetApplicationQueryVariables,
} from '../../common/data/Application.graphql';

import Page from './Page';

export type EditionProps = {
    formats: Formats;
};

const Edition = ({ formats }: EditionProps) => {
    const { id: versionId } = useParams<{ id: string }>();
    const { data, loading } = useQuery<GetApplicationQuery, GetApplicationQueryVariables>(getApplication, {
        fetchPolicy: 'no-cache',
        variables: { versionId },
    });

    const translation = useContentTranslation();
    const formatDateTime = useFormatDateTime();

    const initialValues = useMemo(() => {
        if (!data?.application) {
            return null;
        }

        return toFormState(data?.application, formats, formatDateTime, translation);
    }, [data, formats, formatDateTime, translation]);

    if (!loading && !initialValues) {
        return <Redirect to="/workflow/applications" />;
    }

    return <Page backUrl="/workflow/applications" initialValues={initialValues} />;
};

export default withCompanyFormatting(Edition);
