import { useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { logout } from '../../actions/shared';
import { getIsAuthenticated, getUser } from '../../selectors';
import createWebSocketClient from '../../utilities/createWebSocketClient';
import { onSecondAuthentication } from './LogoutOnOverride.graphql';

const LogoutOnOverride = () => {
    // retrieve the session/authorization from the redux state
    const isAuthenticated = useSelector(getIsAuthenticated);
    const { isSuperUser = false } = useSelector(getUser) || {};
    const { getState } = useStore();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isAuthenticated || isSuperUser) {
            // no need to do anything
            return () => null;
        }

        const client = createWebSocketClient(getState);

        const observable = client.subscribe({ query: onSecondAuthentication }).subscribe(() => {
            dispatch(logout('override'));
        });

        return () => {
            // unsubscribe
            observable.unsubscribe();
        };
    }, [isAuthenticated, isSuperUser, dispatch, getState]);

    return null;
};

export default LogoutOnOverride;
