import React from 'react';
import { useSelector } from 'react-redux';
import { getCompany } from '../../selectors';
import ImgWithFallback from './ImgWithFallback';
import defaultLogo from './assets/logo_black.png';

const CompanyLogo = props => {
    const company = useSelector(getCompany);

    if (company && company.logo) {
        return <ImgWithFallback alt="logo" {...props} fallbackSrc={defaultLogo} src={company?.logo?.url} />;
    }

    return <img alt="logo" {...props} src={defaultLogo} />;
};

export default CompanyLogo;
