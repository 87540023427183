// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { getFormValues, ReduxFormContext } from 'redux-form';
import { useContentTranslation } from '../../../../../../i18n';
import { assetConditionOptions, yesNoOptions } from '../../../../../../shared/constants/options';
import DatePickerField from '../../../../../shared/form-v2/DatePickerField';
import NumberField from '../../../../../shared/form-v2/NumberField';
import SelectField from '../../../../../shared/form-v2/SelectField';
import TextField from '../../../../../shared/form-v2/TextField';
import { ApplicationFormValues } from '../../../Finance/application/Form/context';

const Used = () => {
    const { formatPath } = useContentTranslation();
    const { form } = useContext(ReduxFormContext);
    const { booking, variant } = useSelector(getFormValues(form)) as ApplicationFormValues;

    const vin = booking?.unit?.details?.vin;

    const hasSubModel = !!variant.model.parentId;

    return (
        <Portlet name="Vehicle of Interest" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label="Make"
                            name={formatPath(
                                hasSubModel ? 'variant.model.parent.make.name' : 'variant.model.make.name'
                            )}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label="Model"
                            name={formatPath(hasSubModel ? 'variant.model.parent.name' : 'variant.model.name')}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label="Submodel"
                            name={formatPath(hasSubModel ? 'variant.model.name' : '')}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Variant" name={formatPath('variant.name')} disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Vehicle ID" name="variant.identifier" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label="Asset Condition"
                            name="variant.assetCondition"
                            options={assetConditionOptions}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Year of Manufacture" name="variant.year" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField
                            label="Depreciation"
                            name="variant.usedCarDetails.depreciation"
                            type="currency"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DatePickerField
                            label="Registration Date"
                            name="variant.usedCarDetails.registrationDate"
                            disabled
                        />
                    </div>

                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField label="OMV" name="variant.usedCarDetails.omv" type="currency" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField label="ARF" name="variant.usedCarDetails.arf" type="currency" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField label="QP Paid" name="variant.usedCarDetails.coe" type="currency" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField
                            label="Deregistration Value"
                            name="variant.usedCarDetails.deregistrationValue"
                            type="currency"
                            disabled
                        />
                    </div>
                    {vin && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField label="VIN Number" name="booking.unit.details.vin" disabled />
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField label="Starting Price" name="calculator.price" type="currency" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline label="Test Drive" name="hasTestDrive" options={yesNoOptions} disabled />
                    </div>
                </div>
            </div>
        </Portlet>
    );
};

export default Used;
