import { isArray } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { useSnapshotValue } from './fields/display/ConnectedSnapshotField';
import Grid from './ui/Grid';

const getCellSize = (size, gridSize, flexibleItem) => {
    if (!flexibleItem) {
        // we don't need to check for that cell
        return size;
    }

    // fill the last row
    return gridSize % 2 ? 1 : 2;
};

const useFlexibleIndex = (calculatorContext, values, fieldsCount) => {
    // first we need to call a hook to get the value from the snapshot
    const snapshotMonthlyInstalment = useSnapshotValue('monthlyInstalment');
    // get monthly instalment from values too
    const { monthlyInstalment } = values;
    // we need to know if we're using snapshot
    const { isUsingSnapshot, fields } = calculatorContext;

    const promoCodeIsViewable = fields.promoCode.isViewable();

    // depending the current usages we don't use the same value
    const finalMonthlyInstalment = isUsingSnapshot ? snapshotMonthlyInstalment : monthlyInstalment;

    if (isArray(finalMonthlyInstalment)) {
        // only extend the last two
        // promo should be last one
        return fieldsCount - (promoCodeIsViewable ? 3 : 2);
    }

    // only extend the last field
    return fieldsCount - (promoCodeIsViewable ? 2 : 1);
};

const SingleCalculatorFields = ({ calculatorContext, values, setFieldOnEdition, viewableFields }) => {
    const flexibleIndex = useFlexibleIndex(calculatorContext, values, viewableFields.length);

    let totalGridSize = 0;

    const fields = viewableFields.map(([name, field], index) => {
        // first we need to retrieve the component
        const { displayComponent: Component, gridSize } = field;

        if (!Component) {
            // something is off
            throw new Error(`Invalid component for ${name} field.`);
        }

        // then retrieve the dynamic properties
        const editable = field.isEditable(calculatorContext, values) && !field.isInvalid(calculatorContext);
        const props = field.getDisplayProperties(calculatorContext, values);

        const size = getCellSize(
            gridSize instanceof Function ? gridSize(calculatorContext) : gridSize,
            totalGridSize,
            index >= flexibleIndex
        );

        totalGridSize += size;

        return (
            <Component
                // the key property is based on the name
                key={name}
                // the label might be a property overridden so push it ahead
                label={field.label}
                // push the dynamic properties
                {...props}
                // and some context, useful stuff etc
                editable={editable}
                name={name}
                setFieldOnEdition={setFieldOnEdition}
                size={size}
                updateName={field.moveEditionTo}
            />
        );
    });

    return <Grid>{fields}</Grid>;
};

SingleCalculatorFields.propTypes = {
    calculatorContext: PropTypes.shape({
        isUsingSnapshot: PropTypes.bool,
    }).isRequired,
    setFieldOnEdition: PropTypes.func.isRequired,
    values: PropTypes.shape({
        monthlyInstalment: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
    }).isRequired,
    viewableFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SingleCalculatorFields;
