import styled from 'styled-components';

const SuffixAction = styled.button`
    cursor: pointer;
    border: 1px solid #ced4da;
    background-color: #eef0f4;
    color: #495057;
    font-size: 1rem;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    text-align: center;
    padding: 0.375rem 0.75rem;
    margin: 0;
    outline: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default SuffixAction;
