import React from 'react';
import { Field } from 'redux-form';
import { yesNoOptions } from '../../../../../../shared/constants/options';
import CurrencyField from '../../../../../template/Currency';
import FieldContainer from '../../../../../template/Field-container';
import SwitchField from '../../../../../template/Switch';

type COEProps = {
    currency: string;
    precision: number;
    editableName: string;
};

const COE = ({ currency, precision, editableName }: COEProps) => (
    <>
        <hr />
        <div className="row">
            <div className="col-md-4 col-sm-12 col-xs-12">
                <FieldContainer label="COE Amount">
                    <Field
                        component={CurrencyField}
                        name="coe.amount"
                        precision={precision}
                        prefix={currency}
                        allowNegative
                    />
                </FieldContainer>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12">
                <FieldContainer label="Editable">
                    <Field component={SwitchField} name={editableName} options={yesNoOptions} />
                </FieldContainer>
            </div>
        </div>
    </>
);

export default COE;
