// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React, { ReactNode } from 'react';
import AddressDetails from './AddressDetails';
import CustomerDetails from './CustomerDetails';
import DrivingLicenseDetails from './DrivingLicenseDetails';
import OccupationDetails from './OccupationDetails';

export type ApplicantDetailsProps = {
    name?: string;
    action?: ReactNode;
};

const ApplicantDetails = ({ action, name = 'Applicant Details' }: ApplicantDetailsProps) => (
    <Portlet action={action} name={name} closable open>
        <div className="container-fluid">
            <CustomerDetails />
            <AddressDetails />
            <DrivingLicenseDetails />
            <OccupationDetails />
        </div>
    </Portlet>
);

export default ApplicantDetails;
