import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Unit, PromoCodeUnit } from '../../../schema';
import DropdownField from '../Dropdown';

const UnitField = ({ currencySymbol, forPromo = false, ...props }) => {
    const options = useMemo(() => {
        const initOptions = [
            { label: '%', value: Unit.PERCENTAGE },
            { label: currencySymbol, value: Unit.CURRENCY },
        ];

        if (forPromo) {
            return initOptions.concat([{ label: 'Not Used', value: PromoCodeUnit.NOTUSED }]);
        }

        return initOptions;
    }, [currencySymbol, forPromo]);

    return <DropdownField {...props} options={options} noSort />;
};

UnitField.propTypes = {
    currencySymbol: PropTypes.string.isRequired,
    forPromo: PropTypes.bool,
};

export default UnitField;
