import PropTypes from 'prop-types';
import React from 'react';
import LabelBox from '../../ui/LabelBox';

const ConnectedLabelBox = ({ field, ...props }) => <LabelBox {...props}>{field.label}</LabelBox>;

ConnectedLabelBox.propTypes = {
    field: PropTypes.shape({
        label: PropTypes.string.isRequired,
    }).isRequired,
};

export default ConnectedLabelBox;
