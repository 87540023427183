import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import FieldContainer from '../../../../template/Field-container';
import NumberField from '../../../../template/Number';
import UnitField from '../../../../template/options-field/UnitField';
import { useFormContext } from './context';

const BalloonPaymentRange = () => {
    const { disabled, values, change, currency, rounding } = useFormContext();

    const min = get('balloonSetting.min', values);
    const max = get('balloonSetting.max', values);
    const show = get('balloonSetting.show', values);

    useEffect(() => {
        if (min === max) {
            change('balloonSetting.step', 0);
            change('balloonSetting.default', min);
        }

        if (!show || min === max) {
            change('balloonSetting.editable', false);
        }
    }, [change, min, max, show]);

    return (
        <div className="row">
            <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="sub-container-fluid">
                    <FieldContainer label="Min*">
                        <Field
                            component={NumberField}
                            disabled={disabled}
                            min={0}
                            name="balloonSetting.min"
                            precision={rounding.percentage.count}
                        />
                    </FieldContainer>
                    <span />
                    <FieldContainer label="Max*">
                        <Field
                            component={NumberField}
                            disabled={disabled}
                            min={0}
                            name="balloonSetting.max"
                            precision={rounding.percentage.count}
                        />
                    </FieldContainer>
                    <span />
                    <FieldContainer label="Step*">
                        <Field
                            component={NumberField}
                            disabled={min === max || disabled}
                            max={max}
                            min={1}
                            name="balloonSetting.step"
                            precision={rounding.percentage.count}
                        />
                    </FieldContainer>
                </div>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="sub-container-fluid">
                    <div className="flex-3">
                        <FieldContainer label="Default*">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                min={0}
                                name="balloonSetting.default"
                                precision={rounding.percentage.count}
                            />
                        </FieldContainer>
                    </div>
                    <span />
                    <UnitField currencySymbol={currency} label="" name="balloonSetting.defaultUnit" disabled />
                </div>
            </div>
        </div>
    );
};

export default BalloonPaymentRange;
