import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setScope } from '../actions';
import {
    getScope,
    GetScopeQuery,
    CompanyDataFragment,
    CountryDataFragment,
    ZoneDataFragment,
} from './useLoadCompany.graphql';

export type ScopeData = {
    company?: CompanyDataFragment | null;
    country?: CountryDataFragment | null;
    zone?: ZoneDataFragment | null;
    defaultZone?: ZoneDataFragment | null;
};

const useLoadCompany = (
    companyCode: string,
    countryCode: string,
    zoneCode: string = 'All',
    defaultZoneCode: string
): [boolean, ScopeData | null] => {
    // compute variables for the graphql query
    const variables = useMemo(
        () => ({ companyCode, countryCode, zoneCode: zoneCode === 'All' ? defaultZoneCode || zoneCode : zoneCode }),
        [companyCode, countryCode, zoneCode, defaultZoneCode]
    );
    // then use a reference to keep track of this
    const lastVariables = useRef(variables);

    // fetch using apollo
    const { loading, data, refetch } = useQuery<GetScopeQuery>(getScope, {
        variables,
        fetchPolicy: 'cache-and-network',
    });
    // we need dispatch from redux to dispatch actions
    // mostly to persist the company in the state for an easier and global access
    const dispatch = useDispatch();

    useEffect(() => {
        if (lastVariables.current !== variables) {
            // we need to refetch data because our scope changed
            refetch(variables);
            // update reference
            lastVariables.current = variables;
        }
    }, [lastVariables, variables, refetch]);

    useEffect(() => {
        if (data && data.scope) {
            const { scope } = data;
            const defaultZone = scope.zone;

            // update redux state
            if (defaultZoneCode) {
                dispatch(
                    setScope({
                        ...scope,
                        defaultZone,
                        zone: null,
                    })
                );
            } else {
                dispatch(setScope(scope));
            }
        }
    }, [data, defaultZoneCode, dispatch]);

    return [loading, data?.scope || null];
};

export default useLoadCompany;
