import React from 'react';
import { useComparisonContext } from '../../ComparisonContext';
import LabelBox from '../../ui/LabelBox';

const CarOptionsLabel = () => {
    // get groups
    const { groups } = useComparisonContext();

    // then render boxes
    return groups.map(({ id, label }) => <LabelBox key={id}>{label}</LabelBox>);
};

export default CarOptionsLabel;
