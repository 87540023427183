import { useApolloClient, useQuery } from '@apollo/client';
import { omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { submit } from 'redux-form';
import { addNotification } from '../../../../actions';
import { getUser, getCurrentCountry, getCurrentUserCountryDealers } from '../../../../selectors';
import { PROMO_DELETE_NOTIFICATION, PROMO_SAVE_NOTIFICATION } from '../../../../shared/constants/notification';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import * as core from '../../../../utils/permissions';
import { useModal } from '../../../Modal';
import FormLayout from '../../../ui/form/FormLayout';
import Form from './Form';
import { create, update, remove, getDealers } from './Page.graphql';

const Page = ({ initialValues = null }) => {
    const modal = useModal();
    const history = useHistory();
    const dispatch = useDispatch();

    const { id: countryId } = useSelector(getCurrentCountry);
    const { data: result } = useQuery(getDealers, { variables: { countryId }, fetchPolicy: 'cache-and-network' });
    const userDealers = useSelector(getCurrentUserCountryDealers);
    const user = useSelector(getUser) || {};
    const dealerLists = useMemo(() => {
        const dealers = result?.results?.items || [];

        if (user.isSuperUser) {
            // return all dealers for super user
            return dealers;
        }

        const combinedDealers = [...userDealers, ...(initialValues?.dealerIds || [])];

        // only return dealers associated to user or initialValues
        return dealers
            .filter(({ value }) => combinedDealers.includes(value))
            .map(dealer => {
                // check if dealer is associated to user

                if (userDealers.includes(dealer.value)) {
                    return dealer;
                }

                // else it should be fixed
                return { ...dealer, isFixed: true };
            });
    }, [initialValues, result, user, userDealers]);

    const currentCountry = useSelector(getCurrentCountry);
    const mayManagePromo = useMemo(() => core.mayManagePromo(user, currentCountry), [currentCountry, user]);

    const submitForm = useCallback(() => dispatch(submit('promo')), [dispatch]);

    const client = useApolloClient();
    const onSubmit = useCallback(
        async ({ id, ...data }) => {
            const query = id ? update : create;

            const variables = {
                data: prepareForGraphQL({
                    ...data,
                    identifier: data.identifier.trim(),
                }),
                id,
            };

            if (id) {
                // we need to remove some more dta
                variables.data = omit(['countryId', 'dealerId', 'version', 'lastModified'], variables.data);
            }

            return client.mutate({ mutation: query, variables }).catch(handleResponseError);
        },
        [client]
    );

    const onSubmitSuccess = useCallback(() => {
        dispatch(addNotification(PROMO_SAVE_NOTIFICATION));
        history.push('/promo');
    }, [history, dispatch]);

    const onDelete = useCallback(() => {
        modal.confirm({
            title: '',
            content: 'Are you sure you want to delete this promo code?',
            options: [
                { label: 'No', action: () => null },
                {
                    label: 'Yes',
                    action: () =>
                        client.mutate({ mutation: remove, variables: { id: initialValues?.id } }).then(() => {
                            dispatch(addNotification(PROMO_DELETE_NOTIFICATION));
                            history.push('/promo');
                        }),
                },
            ],
        });
    }, [client, dispatch, history, initialValues, modal]);

    const onCancel = useCallback(() => history.goBack(), [history]);

    const mayDelete = useMemo(
        () =>
            user?.isSuperUser ||
            (mayManagePromo && initialValues?.dealerIds?.every(dealerId => userDealers.includes(dealerId))),
        [initialValues, user, mayManagePromo, userDealers]
    );

    return (
        <FormLayout
            bodyComponent={
                initialValues && (
                    <Form
                        dealers={dealerLists}
                        disabled={!mayManagePromo}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onSubmitSuccess={onSubmitSuccess}
                    />
                )
            }
            moreActions={[
                mayManagePromo &&
                    initialValues?.id && {
                        label: 'delete',
                        onAction: onDelete,
                        disabled: !mayDelete,
                    },
                mayManagePromo && {
                    label: 'save',
                    onAction: submitForm,
                },
            ]}
            onCancel={onCancel}
            title="Promo Codes"
        />
    );
};

Page.propTypes = {
    initialValues: PropTypes.shape({}),
};

export default Page;
