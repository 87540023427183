import { isEqual } from 'lodash/fp';
import { useMemo, useState, useEffect, useRef } from 'react';

export type ServerPagingProps = {
    search?: string | null;
    pageSize?: number;
    initialPage?: number;
    filter?: any;
};

const useServerPaging = ({ search = null, pageSize = 10, initialPage = 1, filter }: ServerPagingProps = {}) => {
    const previousSearch = useRef(search);
    const previousFilter = useRef(filter);
    const [page, setPage] = useState(initialPage);
    const [itemCount, setItemCount] = useState(0);

    useEffect(() => {
        if (previousSearch.current !== search) {
            setPage(1);
            previousSearch.current = search;
        }
    }, [previousSearch, search, setPage]);

    useEffect(() => {
        if (isEqual(previousFilter.current, filter)) {
            setPage(1);
            previousFilter.current = filter;
        }
    }, [filter]);

    const state = useMemo(
        () => ({
            page,
            pageSize,
            setPage,
            setItemCount,
            pageCount: Math.ceil(itemCount / pageSize),
            itemCount,
        }),
        [setPage, setItemCount, page, itemCount, pageSize]
    );

    return [state];
};

export default useServerPaging;
