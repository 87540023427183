import { get, set, groupBy, values as toValues } from 'lodash/fp';
import * as validators from '../../../../../utils/validators';

const vinValidator = validators.lazy(values => {
    // map vins
    const vinMapping = groupBy(get('vin'), toValues(values?.units));

    return validators.forEach('units', key => {
        const unit = get(`units.${key}`, values);

        // get the VIN
        const { vin } = unit;

        // check if there's any with the same VIN
        if (vin && vinMapping[vin].length > 1) {
            return () => set(`units.${key}.vin`, 'VIN should be unique.', {});
        }

        return () => null;
    });
});

const validate = validators.compose(vinValidator);

export default validate;
