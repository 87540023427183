import React from 'react';
import CreatableSelect from 'react-select/creatable';

const customStyles = {
    control: (provided, { isDisabled }) => ({
        ...provided,
        cursor: 'pointer',
        outline: 'none',
        border: 'none',
        boxShadow: 'none',
        borderRadius: 0,
        height: '30px',
        maxHeight: '30px',
        minHeight: '30px',
        color: '#000000',
        backgroundColor: isDisabled ? '#d8d8d8' : '#f0f0f0;',
    }),
    menu: provided => ({
        ...provided,
        marginTop: 0,
        borderRadius: 0,
    }),
    menuList: provided => ({
        ...provided,
        padding: 0,
        maxHeight: '200px',
    }),
    option: (provided, { isSelected, isFocused }) => ({
        ...provided,
        cursor: 'pointer',
        color: '#000000',
        backgroundColor: isSelected || isFocused ? 'rgba(0, 0, 0, 0.04)' : '#ffffff',
        padding: '7px 10px',
        ':active': {
            ...provided[':active'],
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: provided => ({
        ...provided,
        padding: '0 5px 0 0',
        color: '#000000',
        ':hover': {
            ...provided[':hover'],
            color: '#000000',
        },
    }),
    clearIndicator: () => ({ display: 'none' }),
    input: provided => ({ ...provided, margin: 0, height: '30px', display: 'flex', alignItems: 'center' }),
    valueContainer: provided => ({
        ...provided,
        padding: '0px 10px 0 10px',
    }),
    singleValue: provided => ({
        ...provided,
        marginLeft: 0,
        marginRight: 0,
        fontSize: '0.85rem',
        color: '#000000',
        lineHeight: '30px',
    }),
    placeholder: provided => ({
        ...provided,
        color: '#000000',
        fontSize: '0.85rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        right: '10px',
        left: '10px',
    }),
};

export const selectProps = {
    menuPlacement: 'auto',
    noOptionsMessage: () => null,
    styles: customStyles,
    placeholder: null,
};

const BlockCreateableSelect = props => <CreatableSelect {...props} {...selectProps} />;

export default BlockCreateableSelect;
