import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import PasswordRequirement from './PasswordRequirement';

const Title = styled.div`
    color: #9c9c9c;
    padding: 1rem 0;
`;

const Container = styled.div`
    & > * {
        margin-bottom: 0.5rem;
    }
`;

const PasswordRequirementList = ({ requirements }) => (
    <Container>
        <Title>Password Requirements</Title>
        {requirements.map(requirement => (
            <PasswordRequirement key={requirement.description} {...requirement} />
        ))}
    </Container>
);

PasswordRequirementList.propTypes = {
    requirements: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string.isRequired,
            isChecked: PropTypes.bool.isRequired,
        })
    ),
};

export default PasswordRequirementList;
