import { useApolloClient } from '@apollo/client';
import { isNil } from 'lodash/fp';
import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFormOption } from '../../actions/formOptions';
import { fetchDialingCodes } from '../../api';
import * as selectors from '../../selectors';

const usePhone = inputCountryCode => {
    const client = useApolloClient();
    const dispatch = useDispatch();
    const { code } = useSelector(selectors.getCurrentCountry);
    const dialingCodes = useSelector(selectors.getDialingCodes);

    const countryCode = !isNil(inputCountryCode) ? inputCountryCode : code;

    useEffect(() => {
        fetchDialingCodes(client).then(values => dispatch(setFormOption('dialingCodes', values)));
    }, [client, dispatch]);

    return useMemo(() => dialingCodes?.find(item => item.countryCode === countryCode)?.phone || {}, [
        dialingCodes,
        countryCode,
    ]);
};

export default usePhone;
