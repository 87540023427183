import { useApolloClient } from '@apollo/client';
import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import { DocumentBg, DocumentContainer, DocumentGrid, TabContainer, Title } from '../../../../ui/Document';
import DocumentPreview from '../../../Workflow/Finance/application/Form/Document/DocumentPreview';
import { AttachmentDataFragment } from '../../../Workflow/common/data/Application.graphql';
import {
    deleteAppointmentAttachment,
    DeleteAppointmentAttachmentMutation,
    DeleteAppointmentAttachmentMutationVariables,
} from '../Edition.graphql';
import { useAppointmentFormContext } from '../Form/context';
import DocumentUpload from './DocumentUpload';

type RemoveMutationFunction = (props: { referenceId: string; attachmentId: string }) => Promise<any>;

const TabDocument = () => {
    const { disabled, values, change } = useAppointmentFormContext();
    const { attachments, version } = values;

    const client = useApolloClient();

    const removeAttachmentMutation = useCallback<RemoveMutationFunction>(
        ({ referenceId, attachmentId }) =>
            client.mutate<DeleteAppointmentAttachmentMutation, DeleteAppointmentAttachmentMutationVariables>({
                mutation: deleteAppointmentAttachment,
                variables: {
                    attachmentId,
                    id: referenceId,
                },
            }),
        [client]
    );

    const renderAttachment = useCallback(
        (attachment: AttachmentDataFragment) => (
            <DocumentPreview
                key={attachment.id}
                change={change}
                disabled={disabled}
                document={attachment}
                files={attachments}
                name="attachments"
                referenceId={version.id}
                removeMutation={removeAttachmentMutation}
                showRemove
            />
        ),
        [attachments, change, disabled, removeAttachmentMutation, version.id]
    );

    return (
        <DocumentContainer>
            <Title>Document Management</Title>
            <TabContainer>
                <DocumentBg>
                    <DocumentGrid>
                        {attachments.map(renderAttachment)}
                        <Field
                            component={DocumentUpload}
                            disabled={disabled}
                            name="attachments"
                            referenceId={values.version.id}
                        />
                    </DocumentGrid>
                </DocumentBg>
            </TabContainer>
        </DocumentContainer>
    );
};

export default TabDocument;
