import { renderCurrency } from '../components/fields/display/CurrencyField';
import { weightFieldAfter } from '../utils';
import fieldTemplate from './fieldTemplate';

const getDefaultTotalPrice = ({ carPrice, ppsr, establishment, coe, carOptions }, { formats }) => {
    if ([carPrice, ppsr, establishment, coe, carOptions].some(value => value === undefined)) {
        // we cannot compute it yet
        return undefined;
    }

    // make the total for options
    const sumOptionsPrice = carOptions.reduce((price, { price: optionPrice }) => optionPrice + price, 0);

    const totalPrice = carPrice + ppsr + establishment + coe + sumOptionsPrice;

    return formats.roundNumber(totalPrice);
};

const totalPriceField = {
    // copy template
    ...fieldTemplate,

    // setup meta
    label: t => t('calculator.label.totalPrice'),
    weight: weightFieldAfter('balloonPayment'),

    // setup states
    isViewable: () => true,

    // setup display rendering
    getDisplayProperties: () => ({
        children: renderCurrency,
    }),

    // setup values management
    getInitialValue: getDefaultTotalPrice,
    updates: {
        // compute again the total price whenever the coe/car price/ppsr/establishment changed
        coe: getDefaultTotalPrice,
        carPrice: getDefaultTotalPrice,
        carOptions: getDefaultTotalPrice,
        ppsr: getDefaultTotalPrice,
        establishment: getDefaultTotalPrice,
    },

    // snapshot configuration
    snapshot: {
        ...fieldTemplate.snapshot,
        getValue: getDefaultTotalPrice,
    },
};

export default totalPriceField;
