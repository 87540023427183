import { OutlineInput, OutlineWrapper, OutlineError } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import PasswordSuffix from './PasswordSuffix';
import TextField from './TextField';

const PasswordField = ({ suffixComponent, ...props }) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePassword = useCallback(() => setShowPassword(state => !state), [setShowPassword]);

    const suffixElement = (
        <PasswordSuffix component={suffixComponent} showPassword={showPassword} togglePassword={togglePassword} />
    );

    const type = showPassword ? 'text' : 'password';

    return <TextField {...props} suffixElement={suffixElement} type={type} />;
};

PasswordField.propTypes = {
    ...TextField.propTypes,
    suffixComponent: PropTypes.elementType,
};

PasswordField.Outline = props => (
    <PasswordField
        errorComponent={OutlineError}
        inputComponent={OutlineInput}
        wrapperComponent={OutlineWrapper}
        {...props}
    />
);

export default PasswordField;
