import { get } from 'lodash/fp';
import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { AssetCondition } from '../../../../../schema';
import { getCurrentCountry } from '../../../../../selectors';
import { ZoneDataFragment } from '../../../../../utils/useLoadCompany.graphql';
import withCompanyFormatting, { Formats } from '../../../../../utils/withCompanyFormatting';
import CheckboxField from '../../../../shared/form/CheckboxField';
import CurrencyField from '../../../../template/Currency';
import FieldContainer from '../../../../template/Field-container';
import ApvPortlet from '../../../../template/Portlet';
import { useFormContext } from './context';

type PricesProps = {
    formats: Formats;
};

const Prices = ({ formats }: PricesProps) => {
    const { disabled, values, change } = useFormContext();
    const { currency, zones, rounding } = useSelector(getCurrentCountry);
    const { formatCurrency } = formats;
    const { ct } = useContentTranslation();

    const assetCondition = get('variant.assetCondition', values);
    const isNewOrDemoAssetCondition = assetCondition === AssetCondition.DEMO || assetCondition === AssetCondition.NEW;

    const {
        variant: { prices },
    } = values;

    const mandatories = get('mandatories', values);

    useEffect(() => {
        // when disable checkbox, set price as null
        zones.forEach((zone: ZoneDataFragment) => {
            if (!mandatories[zone.id]) {
                change(`inventoryUnit.prices[${zone.id}]`, null);
            }
        });
    }, [change, prices, mandatories, zones]);

    const getPlaceholder = useCallback(
        zoneId => (prices?.[zoneId] && !mandatories[zoneId] ? formatCurrency(prices?.[zoneId]) : ''),
        [formatCurrency, mandatories, prices]
    );

    return (
        <ApvPortlet name="Vehicle Price" closable open>
            <div className="container-fluid">
                <div className="row">
                    {zones.map((zone: ZoneDataFragment) => (
                        <div key={zone.id} className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label={ct(zone.name)}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {!isNewOrDemoAssetCondition && <CheckboxField name={`mandatories.${zone.id}`} />}
                                    <Field
                                        component={CurrencyField}
                                        disabled={
                                            disabled ||
                                            (mandatories && !mandatories[zone.id]) ||
                                            isNewOrDemoAssetCondition
                                        }
                                        name={`inventoryUnit.prices[${zone.id}]`}
                                        placeholder={getPlaceholder(zone.id)}
                                        precision={rounding.amount.count}
                                        prefix={currency}
                                    />
                                </div>
                            </FieldContainer>
                        </div>
                    ))}
                </div>
            </div>
        </ApvPortlet>
    );
};

export default withCompanyFormatting(Prices);
