import Modal from 'react-modal';
import styled from 'styled-components';

export const Title = styled.h5`
    text-align: center;
`;

const FullScreenModal = styled(Modal)`
    width: 100%;
    height: auto;
    border: 0;
    background-color: white !important;
    color: black;
`;

export default FullScreenModal;
