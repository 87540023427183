import {
    SET_CONTEXT,
    CONTEXT_SET_COMPANY,
    CONTEXT_SET_SCOPE,
    CONTEXT_SET_COMPANY_CODE,
    CONTEXT_SET_COUNTRY_CODE,
    CONTEXT_SET_USER,
    CONTEXT_SET_ZONE_CODE,
    CONTEXT_SET_DEFAULT_ZONE_CODE,
    CONTEXT_SET_DEALER,
    CONTEXT_UNSET,
} from '../actions/context';
import persistState from './persistState';

const initialState = {
    // codes
    companyCode: null,
    countryCode: null,
    zoneCode: null,
    // scope entities
    company: null,
    country: null,
    zone: null,
    dealer: null,
    // user data
    user: null,
};

const reducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case SET_CONTEXT:
            return { ...state, ...payload };

        case CONTEXT_SET_COMPANY_CODE:
            return { ...state, companyCode: payload.companyCode };

        case CONTEXT_SET_COUNTRY_CODE:
            return { ...state, countryCode: payload.countryCode };

        case CONTEXT_SET_ZONE_CODE:
            return { ...state, zoneCode: payload.zoneCode };

        case CONTEXT_SET_DEFAULT_ZONE_CODE:
            return { ...state, defaultZoneCode: payload.defaultZoneCode };

        case CONTEXT_SET_COMPANY:
            return { ...state, company: payload.company };

        case CONTEXT_SET_DEALER:
            return { ...state, dealer: payload.dealer };

        case CONTEXT_SET_SCOPE: {
            const { company, country, zone, defaultZone } = payload.scope;

            return {
                ...state,
                companyCode: company?.code,
                company,
                countryCode: country?.code,
                country,
                zoneCode: zone?.code || 'All',
                zone,
                defaultZoneCode: defaultZone?.code,
                defaultZone,
            };
        }

        case CONTEXT_SET_USER:
            return { ...state, user: payload.user };

        case CONTEXT_UNSET:
            return initialState;

        default:
            return state;
    }
};

export default persistState('context', reducer);
