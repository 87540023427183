import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ModalClose = styled.button`
    border: none;
    outline: none;
    background-color: transparent;

    cursor: pointer;

    margin-right: auto;

    &:focus {
        border: none;
        outline: none;
    }

    svg {
        color: white;
        font-size: 1.25rem;
    }
`;

const Close = ({ onClose }) => (
    <ModalClose onClick={onClose}>
        <Icon icon={faTimes} />
    </ModalClose>
);

Close.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default Close;
