import { createContext, useContext } from 'react';
import { InjectedFormProps } from 'redux-form';
import { CountryDataFragment } from '../../../../../utils/useLoadCompany.graphql';
import { GetDataQuery } from './data.graphql';

export type APIData = GetDataQuery;

// todo later provide proper typing for values in redux form
export type ContextValue = InjectedFormProps<any> &
    Pick<CountryDataFragment, 'rounding' | 'currency'> & {
        disabled?: boolean;
        apiData: APIData;
        // todo properly define the form values type
        values: any;
    };

export const Context = createContext<ContextValue | null>(null);

export const useFormContext = () => {
    const context = useContext(Context);

    if (context === null) {
        throw new Error('Context not in react tree');
    }

    return context;
};
