import styled from 'styled-components';
import WhiteCard from './WhiteCard';

const ErrorCard = styled(WhiteCard)`
    margin: 50px;

    h1 {
        color: #161616;
        margin-bottom: 20px;

        svg {
            color: #ee5757;
        }
    }

    button {
        cursor: pointer;
    }
`;

export default ErrorCard;
