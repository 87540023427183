// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { useContentTranslation } from '../../../../../../i18n';
import { getCurrentCountry } from '../../../../../../selectors';
import { ownerIdOptions, yesNoOptions } from '../../../../../../shared/constants/options';
import DatePickerField from '../../../../../shared/form-v2/DatePickerField';
import NumberField from '../../../../../shared/form-v2/NumberField';
import SelectField from '../../../../../shared/form-v2/SelectField';
import TextField from '../../../../../shared/form-v2/TextField';

type ExpressProps = {
    disabled?: boolean;
};

const Express = ({ disabled = false }: ExpressProps) => {
    const { formatPath } = useContentTranslation();

    const { code } = useSelector(getCurrentCountry);

    const isSingaporeMarket = code === 'SG';

    return (
        <Portlet name="Vehicle of Interest" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField disabled={disabled} label="Make" name={formatPath('variant.model.make.name')} />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField disabled={disabled} label="Model" name={formatPath('variant.model.name')} />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            disabled={disabled}
                            label="Vehicle Number"
                            name="variant.preOwnedCarDetails.plateNumber"
                        />
                    </div>
                    {isSingaporeMarket && (
                        <>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField disabled={disabled} label="Asset Condition" name="variant.assetCondition" />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField disabled={disabled} label="Displacement" name="display.displacement" />
                            </div>
                        </>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DatePickerField
                            disabled={disabled || isSingaporeMarket}
                            label="Original Registration Date"
                            max={new Date()}
                            name="variant.preOwnedCarDetails.originalRegistrationDate"
                        />
                    </div>
                    {isSingaporeMarket && (
                        <>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <DatePickerField
                                    label="COE Expiry Date"
                                    name="variant.preOwnedCarDetails.coeExpiryDate"
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <NumberField
                                    label="QP Paid"
                                    name="variant.preOwnedCarDetails.qpPaid"
                                    type="currency"
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <NumberField
                                    label="Open Market Value"
                                    name="variant.preOwnedCarDetails.omv"
                                    type="currency"
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <SelectField.Outline
                                    disabled={disabled}
                                    label="Owner ID Type"
                                    name="variant.preOwnedCarDetails.ownerIdType"
                                    options={ownerIdOptions}
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField
                                    disabled={disabled}
                                    label="Owner ID (Last 4 characters)"
                                    maxLength="4"
                                    name="variant.preOwnedCarDetails.ownerIdSuffix"
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <SelectField.Outline
                                    label="Goods have been inspected by Dealer"
                                    name="variant.preOwnedCarDetails.inspected"
                                    options={yesNoOptions}
                                    disabled
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Portlet>
    );
};

export default Express;
