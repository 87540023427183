import React from 'react';
import { Field } from 'redux-form';
import FieldContainer from '../../../../template/Field-container';
import NumberField from '../../../../template/Number';
import { useFormContext } from './context';

const InterestRateFlat = () => {
    const { disabled } = useFormContext();

    return (
        <div className="row">
            <div className="col-md-4 col-sm-12 col-xs-12">
                <FieldContainer label="Interest Rate (%)*">
                    <Field
                        component={NumberField}
                        disabled={disabled}
                        min={0}
                        name="interestRateSetting.default"
                        precision={2}
                    />
                </FieldContainer>
            </div>
        </div>
    );
};

export default InterestRateFlat;
