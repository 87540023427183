import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCalculatorContext } from '../../../CalculatorContext';
import PriceBox from '../../../ui/PriceBox';
import PriceLabel from '../../../ui/PriceLabel';
import CarModelField from './CarModelField';
import CarPriceField from './CarPriceField';

const CarAndPriceDisplayField = props => {
    const { t } = useTranslation();
    const { hasMakes } = useCalculatorContext();

    return (
        <PriceBox size={2}>
            <CarModelField {...props} />
            {!hasMakes && (
                <>
                    <PriceLabel>
                        <span>{t('calculator.label.carModelWithPrice')}</span>
                    </PriceLabel>
                    <CarPriceField {...props} />
                </>
            )}
        </PriceBox>
    );
};

// use memo to reduce tree update
export default memo(CarAndPriceDisplayField);
