import { useApolloClient, ApolloError } from '@apollo/client';
// @ts-ignore
import { InformationContentModal, ErrorContentModal } from '@appvantageasia/afc-ui';
import { isEmpty, isNil } from 'lodash/fp';
import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getCountryId } from '../../../../selectors';
import { downloadFile } from '../../../../utils/file';
import UploadContent from '../../../ui/importModal/UploadContent';
import { Close, Modal } from '../../../ui/modal';
import {
    importLtaFinderData,
    ImportLtaFinderDataMutation,
    ImportLtaFinderDataMutationVariables,
} from './importLta.graphql';

const onDownloadClick = () => {
    const filename = 'LTA_Template.xlsx';
    const url = `/uploads/public/templates/${filename}`;

    downloadFile(url, filename);
};

export type ImportLtaModalProps = {
    onClose: () => void;
};

const ImportLtaModal = ({ onClose }: ImportLtaModalProps) => {
    const [errors, setErrors] = useState<null | string[]>(null);
    const [updateCount, setUpdateCount] = useState<number | null>(null);

    const countryId = useSelector(getCountryId);

    const client = useApolloClient();

    const onUpload = useCallback(
        async inputFile => {
            try {
                const variables = { countryId, file: inputFile, isUpdate: false };

                const { data } = await client.mutate<ImportLtaFinderDataMutation, ImportLtaFinderDataMutationVariables>(
                    { mutation: importLtaFinderData, variables }
                );

                const updatedCount = data?.count ?? 0;

                setUpdateCount(updatedCount);
            } catch (error) {
                if (error instanceof ApolloError) {
                    const messages = error.graphQLErrors.reduce((acc, { message }) => `${acc}${message} `, '');
                    setErrors([messages]);
                }
            }
        },
        [client, countryId]
    );

    const render = () => {
        if (!isEmpty(errors)) {
            const errorTitle = 'The following errors were found in the uploaded file. Please fix and re-upload.';

            return <ErrorContentModal errorTitle={errorTitle} errors={errors} />;
        }

        if (!isNil(updateCount)) {
            return <InformationContentModal>{updateCount} LTA Data imported</InformationContentModal>;
        }

        return (
            <UploadContent
                accept=".xlsx,.csv"
                onDownloadClick={onDownloadClick}
                onUpload={onUpload}
                type="LTA Data"
                hasTemplateDownload
            />
        );
    };

    return (
        <Modal onRequestClose={onClose} isOpen>
            <Close onClose={onClose} />
            {render()}
        </Modal>
    );
};

export default ImportLtaModal;
