import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import { compose } from 'redux';
import { getFormValues, reduxForm, InjectedFormProps } from 'redux-form';
import { FormTabContainer } from '../../../../containers/Layout';
import ApvPortlet from '../../../template/Portlet';
import Languages from './Languages';
import MainDetails from './MainDetails';
import { Context, FormProps, FormData } from './context';
import validate from './validate';

const Form = (props: FormProps & InjectedFormProps<FormData, FormProps>) => {
    const { form, languageOptions } = props;
    const displayMultiLanguage = languageOptions.length > 1;
    const values = useSelector(getFormValues(form));
    const context = { ...props, values };

    return (
        <Context.Provider value={context}>
            <FormTabContainer>
                <Tabs forceRenderTabPanel>
                    <TabList>
                        <Tab>Main Details</Tab>
                        {displayMultiLanguage && <Tab>Multi-Language</Tab>}
                    </TabList>
                    <TabPanel>
                        <ApvPortlet name="Main Details" closable open>
                            <MainDetails />
                        </ApvPortlet>
                    </TabPanel>
                    {displayMultiLanguage && (
                        <TabPanel>
                            <Languages />
                        </TabPanel>
                    )}
                </Tabs>
            </FormTabContainer>
        </Context.Provider>
    );
};

// @ts-ignore
export default compose(reduxForm<FormData, FormProps>({ form: 'maintenance', validate }), memo)(Form);
