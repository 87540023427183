import { HeadBar, HeadTitle } from '@appvantageasia/afc-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import {
    AdminBgContainer,
    ContentBody,
    ScrollContainer,
    FootContainer,
    FootBar,
    FootBarButton,
} from '../../../containers/Layout';
import ESCEventDetector from '../../common/ESCEventDetector';

const ignoreCheck = () => !!document.modalCount;

const ignoreAllCheck = () => true;

const FormLayout = ({ title, bodyComponent, onCancel, moreActions, headerLeftComponent, visible = true }) => (
    <AdminBgContainer $visible={visible}>
        <ESCEventDetector ignoreCheck={visible ? ignoreCheck : ignoreAllCheck} onEvent={onCancel} />
        <ScrollContainer>
            <HeadBar>
                <HeadTitle>{title}</HeadTitle>
                {headerLeftComponent}
            </HeadBar>
            <ContentBody>{bodyComponent}</ContentBody>
        </ScrollContainer>
        {moreActions && (
            <FootContainer>
                <FootBar>
                    {onCancel && <FootBarButton onClick={onCancel}>cancel</FootBarButton>}
                    <div style={{ flex: 1 }} />
                    {moreActions.filter(Boolean) &&
                        moreActions.filter(Boolean).map((item, index) => (
                            <FootBarButton key={index.toString()} disabled={item.disabled} onClick={item.onAction}>
                                {item.icon && <FontAwesomeIcon icon={item.icon} />} {item.label}
                            </FootBarButton>
                        ))}
                </FootBar>
            </FootContainer>
        )}
    </AdminBgContainer>
);

FormLayout.propTypes = {
    bodyComponent: PropTypes.node,
    headerLeftComponent: PropTypes.node,
    moreActions: PropTypes.arrayOf(
        PropTypes.shape({
            disabled: PropTypes.bool,
            icon: PropTypes.node,
            label: PropTypes.string,
            onAction: PropTypes.func,
        })
    ),
    onCancel: PropTypes.func,
    title: PropTypes.node.isRequired,
    visible: PropTypes.bool,
};

export default FormLayout;
