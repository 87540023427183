import { ApolloClient, useApolloClient } from '@apollo/client';
import i18n, { BackendModule } from 'i18next';
import { get, isNil, isEmpty } from 'lodash/fp';
import React, { useMemo, Suspense, useCallback } from 'react';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getTranslations as query, GetTranslationsQuery, GetTranslationsQueryVariables } from './i18n.graphql';
import { getCurrentCountry } from './selectors';
import { CountryDataFragment } from './utils/useLoadCompany.graphql';

export type LanguageOption = {
    label: string;
    value: string;
};

export type TranslationsProviderProps = {
    country?: CountryDataFragment;
    children: JSX.Element | React.ReactNode;
};

export type ContentTranslation = {
    language: string;
    ct: (value: { [language: string]: string }) => string;
    formatPath: (path: string) => string;
    mapIntlValue: (value: { [language: string]: string }) => { [language: string]: string };
};

export const getLanguages = (languages: string[]) => ['en', ...languages.filter(languageCode => languageCode !== 'en')];

export const createCountryIntlValueMapper = (country: CountryDataFragment) => (input: {
    [language: string]: string;
}) => {
    // get default language
    const language = 'en';

    // Sometimes user may not select english but we need to store the english values in DB.
    // Because we need to display content in english for admin
    const languages = getLanguages(country.languages);

    const updatedValue = languages.reduce((currentValue, currentLanguage) => {
        const value = get(currentLanguage, input);

        // if there's no data for this language
        // take default language value
        if (isNil(value) || isEmpty(value)) {
            return { ...currentValue, [currentLanguage]: get(language, input) };
        }

        return currentValue;
    }, input);

    return updatedValue;
};

export const useContentTranslation = (inputLanguage?: string): ContentTranslation => {
    // setup data
    const country = useSelector(getCurrentCountry);

    // if there's no input language
    // fallback to default

    // In admin we always display the content in 'english'.
    const language = useMemo(() => inputLanguage || 'en', [inputLanguage]);

    // translates the value for that language
    const ct = useCallback(
        (value: { [language: string]: string }): string => {
            if (!value) {
                return '';
            }

            // get language
            if (value[language]) {
                return value[language];
            }

            // otherwise return first available language
            return value[Object.keys(value)[0]];
        },
        [language]
    );

    // formats the path for that language
    const formatPath = useCallback((path: string) => `${path}.${language}`, [language]);

    // create mapper for intl values
    const mapIntlValue = useMemo(() => createCountryIntlValueMapper(country), [country]);

    return useMemo(
        () => ({
            ct,
            language,
            formatPath,
            mapIntlValue,
        }),
        [ct, language, formatPath, mapIntlValue]
    );
};

const createBackend = (apolloClient: ApolloClient<any>, countryId?: string | null): BackendModule<{}> => ({
    type: 'backend',

    init(services, backendOptions, i18nextOptions) {},

    read(language, namespace, callback) {
        if (namespace !== 'translation') {
            throw new Error('Only the namespace "translation" is supported');
        }

        apolloClient
            .query<GetTranslationsQuery, GetTranslationsQueryVariables>({
                query,
                fetchPolicy: 'cache-first',
                variables: { language, countryId },
            })
            .then(({ data }) => callback(null, data.getTranslations))
            .catch(console.error);
    },
});

const TranslationsProvider = ({ country, children }: TranslationsProviderProps) => {
    const defaultLanguage = country ? country.languages[0] : 'en';
    const countryId = country?.id || null;

    const apolloClient = useApolloClient();

    const i18nClient = useMemo(() => {
        const client = i18n
            .createInstance({
                lng: defaultLanguage,
                fallbackLng: 'en',
                defaultNS: 'translation',

                ns: ['translation'],

                interpolation: {
                    escapeValue: false,
                },
            })
            .use(createBackend(apolloClient, countryId))
            .use(initReactI18next);

        client.init();

        return client;
    }, [countryId, defaultLanguage, apolloClient]);

    return (
        <I18nextProvider i18n={i18nClient}>
            <Suspense fallback={null}>{children}</Suspense>
        </I18nextProvider>
    );
};

export default TranslationsProvider;
