// @ts-ignore
import { PortletButton } from '@appvantageasia/afc-ui';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { FormSection } from 'redux-form';
import { BankPresetOption } from '../../../../../../schema';
import { getCurrentCountry } from '../../../../../../selectors';
import AFCApplicantDetails from '../../../../Customer/Form/ApplicantDetails';
import PMEApplicantDetails from '../../../../Customer/Form/PME/ApplicantDetails';
import { Context } from '../../../../Customer/Form/context';
import { useCustomerOptions } from '../../../../Customer/useOptions';
import { GetApplicationQuery } from '../../../common/data/Application.graphql';
import useBankPresetStep from '../useBankPresetStep';
import { useFormContext } from './context';

export type CustomerProps = {
    application: GetApplicationQuery['application'];
    allowPrimaryInfoChanges?: boolean;
    disabled?: boolean;
    addGuarantor?: () => void;
    canAddGuarantor?: boolean;
};

const Customer = ({ disabled, addGuarantor, canAddGuarantor, allowPrimaryInfoChanges, application }: CustomerProps) => {
    const originalContext = useFormContext();
    const { mask, currency, rounding } = useSelector(getCurrentCountry);
    const options = useCustomerOptions();
    const newContext = {
        ...originalContext,
        mask,
        currency,
        rounding,
        options,
        disabled,
        allowPrimaryInfoChanges,
        application,
    };

    const ApplicantDetails = useBankPresetStep(
        {
            [BankPresetOption.ENBDBANK]: PMEApplicantDetails,
            [BankPresetOption.PMESA]: PMEApplicantDetails,
            [BankPresetOption.TTBBANK]: AFCApplicantDetails,
            [BankPresetOption.AFFINBANK]: AFCApplicantDetails,
            [BankPresetOption.NONE]: AFCApplicantDetails,
        },
        application?.financeProduct?.bank?.presetOption
    );

    const action = canAddGuarantor ? <PortletButton onClick={addGuarantor}>ADD GUARANTOR</PortletButton> : null;

    return (
        // @ts-ignore
        <Context.Provider value={newContext}>
            <FormSection name="customer">
                <ApplicantDetails action={action} />
            </FormSection>
        </Context.Provider>
    );
};

export default memo(Customer);
