import { getOr } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Field } from 'redux-form';
import { getLanguages } from '../../../../../i18n';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import TextareaField from '../../../../template/Textarea';
import { useFormContext } from './context';

type LanguageMap = {
    [language: string]: string;
};

type LanguageOption = {
    code: string;
    name: string;
};

const Languages = () => {
    const { disabled, values, apiData } = useFormContext();

    const languageSelector = useMemo(() => {
        const languagesMap = getOr([], 'options.languages', apiData).reduce(
            (acc: LanguageMap, language: LanguageOption) => ({ ...acc, [language.code]: language.name }),
            {}
        );

        return getLanguages(values.languages).map(code => ({ code, name: languagesMap[code] || code }));
    }, [values.languages, apiData]);

    const getFormatPath = useCallback((language, label) => `${label}.${language}`, []);

    return (
        <div className="container-fluid">
            <div className="row">
                {languageSelector.map((language: LanguageOption) => (
                    <div key={language.code} className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label={`${language.name} Country`}>
                            <Field
                                component={InputField}
                                disabled={language.code === 'en' || disabled}
                                name={getFormatPath(language.code, 'mlcName')}
                            />
                        </FieldContainer>
                        <FieldContainer label={`${language.name} Company Name`}>
                            <Field
                                component={InputField}
                                disabled={language.code === 'en' || disabled}
                                name={getFormatPath(language.code, 'company.name')}
                            />
                        </FieldContainer>
                        <FieldContainer label={`${language.name} Company Description`}>
                            <Field
                                component={TextareaField}
                                disabled={disabled}
                                maxLength="500"
                                name={getFormatPath(language.code, 'company.description')}
                            />
                        </FieldContainer>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Languages;
