import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import StyledCustomInput from '../../shared/CustomInput';

const ListSearch = ({ initialValue = '', onSubmit }) => {
    const [value, setValue] = useState(initialValue);

    const onClear = useCallback(() => setValue(''), [setValue]);

    const onChange = useCallback(
        event => {
            setValue(event.target.value);
        },
        [setValue]
    );

    const onKeyDown = useCallback(
        event => {
            if (event.key !== 'Enter') {
                // ignore this event
                return;
            }

            onSubmit(value);
        },
        [value, onSubmit]
    );

    return (
        <StyledCustomInput
            className="search"
            clear={onClear}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder="Search"
            type="text"
            value={value}
            autoFocus
        />
    );
};

ListSearch.propTypes = {
    initialValue: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
};

export default ListSearch;
