import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { getCurrentCountry } from '../../../selectors';
import FieldContainer from '../../template/Field-container';
import ApvPortlet from '../../template/Portlet';
import TextareaField from '../../template/Textarea';
import { useFormContext } from './context';

const Languages = () => {
    const { channelSetting } = useSelector(getCurrentCountry);
    const {
        languageOptions,
        mayViewNewCarChannel,
        mayManageNewCarChannel,
        mayViewExpressChannel,
        mayManageExpressChannel,
    } = useFormContext();

    const getFormatPath = useCallback((language, label) => `${label}.${language}`, []);

    return (
        <>
            {channelSetting.new.isActive && mayViewNewCarChannel && (
                <ApvPortlet name="New Car Channel" closable open>
                    <div className="container-fluid">
                        <div className="row">
                            {languageOptions.map(eachLanguage => (
                                <div key={eachLanguage.value} className="col-md-4 col-sm-12 col-xs-12">
                                    <FieldContainer label={`${eachLanguage.label} Price Disclaimer - Finance`}>
                                        <Field
                                            component={TextareaField}
                                            disabled={!mayManageNewCarChannel}
                                            name={getFormatPath(eachLanguage.value, 'newChannel.priceDisclaimer')}
                                        />
                                    </FieldContainer>
                                </div>
                            ))}
                        </div>
                        <div className="row">
                            {languageOptions.map(eachLanguage => (
                                <div key={eachLanguage.value} className="col-md-4 col-sm-12 col-xs-12">
                                    <FieldContainer label={`${eachLanguage.label} Price Disclaimer - Insurance`}>
                                        <Field
                                            component={TextareaField}
                                            disabled={!mayManageNewCarChannel}
                                            name={getFormatPath(
                                                eachLanguage.value,
                                                'newChannel.insurancePriceDisclaimer'
                                            )}
                                        />
                                    </FieldContainer>
                                </div>
                            ))}
                        </div>
                    </div>
                </ApvPortlet>
            )}
            {channelSetting.express.isActive && mayViewExpressChannel && (
                <ApvPortlet name="Pre-owned Channel" closable open>
                    <div className="container-fluid">
                        <div className="row">
                            {languageOptions.map(eachLanguage => (
                                <div key={eachLanguage.value} className="col-md-4 col-sm-12 col-xs-12">
                                    <FieldContainer label={`${eachLanguage.label} Price Disclaimer - Finance`}>
                                        <Field
                                            component={TextareaField}
                                            disabled={!mayManageExpressChannel}
                                            name={getFormatPath(eachLanguage.value, 'expressChannel.priceDisclaimer')}
                                        />
                                    </FieldContainer>
                                </div>
                            ))}
                        </div>
                        <div className="row">
                            {languageOptions.map(eachLanguage => (
                                <div key={eachLanguage.value} className="col-md-4 col-sm-12 col-xs-12">
                                    <FieldContainer label={`${eachLanguage.label} Price Disclaimer - Insurance`}>
                                        <Field
                                            component={TextareaField}
                                            disabled={!mayManageExpressChannel}
                                            name={getFormatPath(
                                                eachLanguage.value,
                                                'expressChannel.insurancePriceDisclaimer'
                                            )}
                                        />
                                    </FieldContainer>
                                </div>
                            ))}
                        </div>
                    </div>
                </ApvPortlet>
            )}
        </>
    );
};

export default Languages;
