export const AUTHORIZATION_SET = '@AUTHORIZATION/SET';

export const AUTHORIZATION_UNSET = '@AUTHORIZATION/UNSET';

export const setAuthorization = accessToken => ({
    type: AUTHORIZATION_SET,
    authorization: { accessToken },
});

export const unsetAuthorization = () => ({ type: AUTHORIZATION_UNSET });
