import { useQuery } from '@apollo/client';
import { get, isNil } from 'lodash/fp';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCountry, getZoneId } from '../../../selectors';
import DayChart from './DayChart';
import { getDepositPaymentPerDay, GetDepositPaymentPerDayQuery } from './DepositPaymentsPerDay.graphql';
import { useDashboardContext } from './context';

const title = 'Payment Deposit Received';
const xAxisTitle = 'Payment Date';
const yAxisTitle = ' Amount Received';

const getDepositPayments = get('amount');

const DepositPaymentsPerDay = () => {
    const { id: countryId } = useSelector(getCurrentCountry);
    const zoneId = useSelector(getZoneId);
    const { channels, dealerIds } = useDashboardContext();

    const { data } = useQuery<GetDepositPaymentPerDayQuery>(getDepositPaymentPerDay, {
        skip: isNil(dealerIds) || dealerIds.length < 1 || channels.length < 1,
        fetchPolicy: 'cache-and-network',
        variables: {
            filter: { zoneId, countryId, dealerIds, channels },
        },
    });

    return (
        <DayChart
            data={data}
            getAmount={getDepositPayments}
            id="depositPayments"
            title={title}
            useCustomLabelTemplateAdapter={false}
            xAxisTitle={xAxisTitle}
            yAxisTitle={yAxisTitle}
        />
    );
};

export default DepositPaymentsPerDay;
