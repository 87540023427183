import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useRef, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import useEscapeEvent from './useEscapeEvent';

const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    ${props =>
        props.isFront &&
        css`
            z-index: 999;
        `}
`;

const ModalContainer = styled.div`
    overflow: auto;
    margin: 0 15px;
    width: 500px;
    max-width: 500px;
    max-height: 90%;
    height: auto;
    background: #fff;
    color: ${props => props.theme?.calculator?.titleColor};
    position: static;
    border: 1px solid #ced4da;
    padding: 25px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    @media (max-width: 450px) {
        margin: 0 15px;
        padding: 15px;
    }
`;

export const ModalHeader = styled.div`
    display: flex;
    height: 23px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
`;

export const ModalTitle = styled.div`
    flex-grow: 2;
    text-transform: ${props => props.theme?.calculator?.titleTransform};
    font-size: 1.43rem;
    text-align: center;
`;

const ModalAction = styled.button`
    width: 30px;
    border: none;
    outline: none;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    background-color: transparent;

    svg {
        color: ${props => props.theme?.calculator?.titleColor};
        font-size: 1.78rem;
        opacity: ${props => (props.disabled ? 0.3 : 1)};
    }
`;

document.modalCount = 0;

const Modal = ({
    className,
    title,
    children,
    onClose,
    onConfirm,
    disabledConfirm,
    showClose,
    showConfirm,
    showTitle,
    isFront,
}) => {
    const containerRef = useRef(null);

    if (containerRef.current === null) {
        containerRef.current = document.createElement('div');
    }

    useLayoutEffect(() => {
        document.modalCount += 1;
        document.body.appendChild(containerRef.current);

        return () => {
            document.body.removeChild(containerRef.current);
            document.modalCount -= 1;
        };
    }, []);

    // close on escape
    useEscapeEvent(onClose);

    const modalNode = (
        <ModalBackground className={className} isFront={isFront}>
            <ModalContainer>
                {(showClose || showConfirm || showTitle) && (
                    <ModalHeader>
                        <ModalAction onClick={onClose}>{showClose && <Icon icon={faTimes} />}</ModalAction>
                        {showTitle && <ModalTitle>{title}</ModalTitle>}
                        <ModalAction disabled={disabledConfirm} onClick={onConfirm}>
                            {showConfirm && <Icon icon={faCheck} />}
                        </ModalAction>
                    </ModalHeader>
                )}
                {children}
            </ModalContainer>
        </ModalBackground>
    );

    return createPortal(modalNode, containerRef.current);
};

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    disabledConfirm: PropTypes.bool.isRequired,
    isFront: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    showClose: PropTypes.bool,
    showConfirm: PropTypes.bool,
    showTitle: PropTypes.bool,
    title: PropTypes.string.isRequired,
};

export default Modal;
