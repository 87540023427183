export const IMAGE_ARCHIVE_TYPE = '.zip,.jpg,.jpeg,.png';

export const openImportDialog = accept =>
    new Promise(resolve => {
        const inputElement = document.createElement('input');
        inputElement.type = 'file';
        inputElement.accept = accept;
        inputElement.multiple = true;
        inputElement.addEventListener('change', () => resolve(inputElement.files));
        inputElement.dispatchEvent(new MouseEvent('click'));
    });

export const isValidImageArchiveExtension = (files = []) => {
    const extensions = ['zip', 'jpg', 'jpeg', 'png'];

    const isValid = Object.values(files).every(file => {
        // get the file extension
        // eslint-disable-next-line no-bitwise
        const fileExtension = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);

        return extensions.includes(fileExtension);
    });

    return isValid;
};
