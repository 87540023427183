import { get } from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { DownPaymentSetting, FinanceProductBase, LoanSetting, Unit } from '../../../../../schema';
import { useFormContext } from './context';

type SettingValue = DownPaymentSetting | LoanSetting;

const useDownPaymentLoan = (usedBase: FinanceProductBase) => {
    const { change, values } = useFormContext();

    const basedOn = get('basedOn', values);

    const [settingKey, oppositeKey] = useMemo(() => {
        return usedBase === FinanceProductBase.LOAN
            ? ['loanSetting', 'downPaymentSetting']
            : ['downPaymentSetting', 'loanSetting'];
    }, [usedBase]);

    const usedSetting = get(settingKey, values) as SettingValue;

    // On setting change, update all other setting
    // and also update the opposite setting
    return useCallback(
        (settingValues: SettingValue) => {
            const { min, max, show, default: defaultValue, defaultUnit, step } = {
                ...usedSetting,
                ...settingValues,
            };

            const isPercentageUnit = defaultUnit === Unit.PERCENTAGE;
            const oppositeSetting = isPercentageUnit
                ? {
                      min: 100 - max,
                      max: 100 - min,
                      default: 100 - defaultValue,
                  }
                : null;

            if (min === max) {
                change(`${settingKey}.step`, 0);
                change(`${settingKey}.default`, min);

                // Only update opposite editable if unit is percentage
                if (isPercentageUnit && oppositeSetting?.min === oppositeSetting?.max) {
                    change(`${oppositeKey}.editable`, false);
                }
            }

            // If it's not show or min === max, then it's not editable
            if (!show || min === max) {
                change(`${settingKey}.editable`, false);
            }

            // Change current setting key to editable
            // Because current based on setting editable switch is disabled
            if (show && min !== max && basedOn === usedBase) {
                change(`${settingKey}.editable`, true);
            }

            switch (defaultUnit) {
                case Unit.PERCENTAGE:
                    change(`${oppositeKey}.min`, oppositeSetting?.min);
                    change(`${oppositeKey}.max`, oppositeSetting?.max);
                    change(`${oppositeKey}.step`, step);
                    change(`${oppositeKey}.default`, oppositeSetting?.default);
                    change(`${oppositeKey}.defaultUnit`, Unit.PERCENTAGE);
                    break;

                case Unit.CURRENCY:
                default:
                    change(`${oppositeKey}.min`, 0);
                    change(`${oppositeKey}.max`, 0);
                    change(`${oppositeKey}.step`, 0);
                    change(`${oppositeKey}.default`, 0);
                    change(`${oppositeKey}.defaultUnit`, Unit.CURRENCY);
            }
        },
        [change, oppositeKey, settingKey, basedOn, usedBase, usedSetting]
    );
};

export default useDownPaymentLoan;
