import { useQuery } from '@apollo/client';
import { omit } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { PermissionLevel } from '../../../../schema';
import { getLastModified } from '../../../../utilities/forms';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { getRole, GetRoleQuery } from './Edition.graphql';
import Page from './Page';
import defaultPermissions from './defaultPermissions';

type RoleParams = {
    id: string;
};

const Editor = () => {
    const { id } = useParams<RoleParams>();
    const { data } = useQuery<GetRoleQuery>(getRole, { fetchPolicy: 'network-only', variables: { id } });

    const formatDateTime = useFormatDateTime();

    const initialValues = useMemo(() => {
        const role = data?.role;

        if (!role) {
            return null;
        }

        return {
            ...omit(['version', 'country', 'permissions'], role),
            countryId: role.country.id,
            companyId: role.country.companyId,
            permissions: Object.fromEntries(
                Object.keys(defaultPermissions).map(code => {
                    const match = role.permissions.find((permission: { code: string }) => permission.code === code);

                    return [code, match?.level || PermissionLevel.NONE];
                })
            ),
            lastModified: getLastModified(role.version, formatDateTime),
        };
    }, [data, formatDateTime]);

    if (!initialValues?.id) {
        // role is still loading
        return null;
    }

    return <Page initialValues={initialValues} />;
};

export default Editor;
