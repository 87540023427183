import styled, { css } from 'styled-components';

export const Table = styled.table`
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;
    border-spacing: 0;
`;

const sharedCellStyle = css`
    vertical-align: middle;
    text-align: left;
    padding: 0 10px;
`;

export const HeaderCell = styled.th`
    position: relative;
    font-weight: bold;
    background-color: rgba(242, 242, 242, 0.5);
    height: 40px;
    color: rgba(68, 68, 68, 0.5);
    opacity: 1;
    text-transform: ${props => props.theme.casing};

    svg {
        float: right;
    }

    ${sharedCellStyle}
    padding-right: 14px;

    text-align: ${props => (props.textAlignCenter ? 'center' : 'left')};

    ${props =>
        props.active &&
        css`
            background-color: rgba(242, 242, 242, 1);
            color: rgba(68, 68, 68, 1);
        `}

    ${props =>
        props.onClick &&
        css`
            cursor: pointer;
        `}
`;

export const HeaderRow = styled.tr``;

export const Headers = styled.thead``;

export const ItemCell = styled.td`
    height: 40px;
    line-height: 1;
    ${props =>
        props.underlined &&
        css`
            text-decoration: underline;
            cursor: pointer;
        `}

    ${sharedCellStyle}
`;

export const ItemRow = styled.tr`
    ${props =>
        props.onClick &&
        css`
            cursor: pointer;
        `}

    &:nth-child(even) {
        background-color: #f2f2f2;
    }
`;

export const Items = styled.tbody``;
