import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { getGlobalPermissions } from '../../selectors';
import { ProductList, EditFinanceProduct, CreateFinanceProduct } from '../routes/FinancialProducts';

const FinanceRouter = () => {
    const { mayManageFinanceProducts, mayViewFinanceProducts } = useSelector(getGlobalPermissions);

    if (!mayViewFinanceProducts) {
        return <Redirect to="/home" />;
    }

    return (
        <Switch>
            <Route component={ProductList} path="/finance/products" exact />
            {mayManageFinanceProducts && <Route component={CreateFinanceProduct} path="/finance/products/new" exact />}
            <Route component={EditFinanceProduct} path="/finance/products/:id" exact />
            <Redirect to="/home" />
        </Switch>
    );
};

export default FinanceRouter;
