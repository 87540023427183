import { useApolloClient } from '@apollo/client';
import qs from 'qs';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useContentTranslation } from '../../../i18n';
import { getCompany, getCompanyIdentifier } from '../../../selectors';
import { handleResponseError } from '../../../utilities/forms';
import { getCompanyLoginUrl, getLocationCode, getLoginUrl } from '../../../utilities/urls';
import FadeInDown from '../../animations/FadeInDown';
import { useCopyright } from '../../shared/Footer';
import RootStyle from '../../ui/RootStyle';
import LoginContainer from '../../ui/login/LoginContainer';
import LoginFooter from '../../ui/login/LoginFooter';
import { getLoginContainerProps } from '../LoginRoute';
import { resetPassword } from '../LoginRoute/api.graphql';
import ResetPasswordForm from './ResetPasswordForm';

const useLoginUrl = () => {
    const { companyCode, countryCode, zoneCode } = useSelector(getCompanyIdentifier);

    if (companyCode && countryCode && zoneCode) {
        return getCompanyLoginUrl(companyCode, getLocationCode(countryCode, zoneCode));
    }

    return getLoginUrl();
};

const ResetPasswordRoute = () => {
    const history = useHistory();
    const loginUrl = useLoginUrl();
    const { ct } = useContentTranslation();

    const client = useApolloClient();

    const onSubmit = useCallback(
        ({ password }) => {
            const { location } = history;
            const { secret } = qs.parse(location.search, { ignoreQueryPrefix: true });

            return client
                .mutate({
                    mutation: resetPassword,
                    variables: {
                        secret,
                        password,
                    },
                })
                .catch(handleResponseError);
        },
        [client, history]
    );

    const onSubmitSuccess = useCallback(() => history.push(loginUrl, { from: 'reset' }), [history, loginUrl]);

    const company = useSelector(getCompany);

    const copyright = useCopyright();

    return (
        <>
            <RootStyle />
            <FadeInDown visible>
                <LoginContainer {...getLoginContainerProps(company, ct)}>
                    <ResetPasswordForm onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess} />
                </LoginContainer>
                <LoginFooter>{copyright}</LoginFooter>
            </FadeInDown>
        </>
    );
};

export default ResetPasswordRoute;
