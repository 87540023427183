import { get, getOr } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment, useMemo } from 'react';
import { FieldArray, fieldArrayFieldsPropTypes } from 'redux-form';
import { CustomerDetailsSource, LicenseType } from '../../../../schema';
import { licenseTypeOptions } from '../../../../shared/constants/options';
import DatePickerField from '../../../shared/form-v2/DatePickerField';
import SelectField from '../../../shared/form-v2/SelectField';
import { useFormContext } from './context';

const LicenseClass = ({ licenseType, drivingLicenseClasses, fromMyinfo = false, field }) => {
    const isQualified = licenseType === LicenseType.QUALIFIED;

    return (
        <Fragment key={field}>
            <div className="col-md-4 col-sm-12 col-xs-12">
                <SelectField.Outline
                    label={fromMyinfo ? 'Driving License (Myinfo)' : 'Driving License'}
                    name={`${field}.licenseClass`}
                    options={drivingLicenseClasses}
                    disabled
                />
            </div>
            {isQualified && (
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DatePickerField
                        label={fromMyinfo ? 'Issue Date (Myinfo)' : 'Issue Date'}
                        name={`${field}.issueDate`}
                        disabled
                    />
                </div>
            )}
        </Fragment>
    );
};

LicenseClass.propTypes = {
    drivingLicenseClasses: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    field: PropTypes.string.isRequired,
    fromMyinfo: PropTypes.bool,
    licenseType: PropTypes.string.isRequired,
};

const LicenseClasses = ({ fields, ...props }) => fields.map(field => <LicenseClass field={field} {...props} />);

LicenseClasses.propTypes = {
    disabled: PropTypes.bool,
    drivingLicenseClasses: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
    licenseType: PropTypes.string.isRequired,
};

const LicenseItem = ({ name, value, drivingLicenseValidityCodes, drivingLicenseClasses, countryCode }) => {
    // modify driving license options
    const drivingLicenseClassesOptions = useMemo(
        () => drivingLicenseClasses.filter(({ purpose }) => !purpose || purpose.includes(value.source)),
        [drivingLicenseClasses, value.source]
    );
    const drivingLicenseValidityCodesOptions = useMemo(
        () => drivingLicenseValidityCodes.filter(({ purpose }) => !purpose || purpose.includes(value.source)),
        [drivingLicenseValidityCodes, value.source]
    );
    // get the license type
    const licenseType = get('type', value);

    // we may have some extra fields depending the type
    const hasExtraFields = [LicenseType.QUALIFIED, LicenseType.PROVISIONAL].includes(licenseType);
    const fromMyinfo = value.source === CustomerDetailsSource.MYINFO;

    return (
        <>
            <div className="col-md-4 col-sm-12 col-xs-12">
                <SelectField.Outline
                    label={fromMyinfo ? 'Driving License (Myinfo)' : 'Driving License'}
                    name={`${name}.type`}
                    options={licenseTypeOptions}
                    disabled
                    isFullWidth
                />
            </div>

            {hasExtraFields && (
                <>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={fromMyinfo ? 'Validity (Myinfo)' : 'Validity'}
                            name={`${name}.validity`}
                            options={drivingLicenseValidityCodesOptions}
                            disabled
                        />
                    </div>

                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DatePickerField
                            label={fromMyinfo ? 'Expiry Date (Myinfo)' : 'Expiry Date'}
                            name={`${name}.expiryDate`}
                            disabled
                        />
                    </div>

                    {countryCode !== 'TH' && (
                        <FieldArray
                            component={LicenseClasses}
                            drivingLicenseClasses={drivingLicenseClassesOptions}
                            fromMyinfo={fromMyinfo}
                            licenseType={licenseType}
                            name={`${name}.classes`}
                            disabled
                        />
                    )}
                </>
            )}
        </>
    );
};

LicenseItem.propTypes = {
    countryCode: PropTypes.string,
    drivingLicenseClasses: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    drivingLicenseValidityCodes: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({
        expiryDate: PropTypes.instanceOf(Date),
        source: PropTypes.string,
    }).isRequired,
};

const LicenseArray = ({ fields, disabled }) => {
    const { options, application } = useFormContext();

    return fields.map((field, index) => (
        <LicenseItem
            key={field}
            countryCode={application?.zone?.country?.code}
            disabled={disabled}
            drivingLicenseClasses={getOr([], 'drivingLicenseClasses', options)}
            drivingLicenseValidityCodes={getOr([], 'drivingLicenseValidities', options)}
            index={index}
            name={field}
            value={fields.get(index)}
        />
    ));
};

LicenseArray.propTypes = {
    disabled: PropTypes.bool,
    fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
};

const DrivingLicense = props => (
    <FieldArray component={LicenseArray} name="details.drivingLicense" rerenderOnEveryChange {...props} />
);

export default DrivingLicense;
