import * as am4core from '@amcharts/amcharts4/core';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import { ApolloProvider, ApolloClient, ApolloLink, from, InMemoryCache, HttpLink, Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { createUploadLink } from 'apollo-upload-client';
import { extractFiles } from 'extract-files';
import { createBrowserHistory } from 'history';
import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { logout, initializeRuntimeSettings } from './actions';
import { withLoading } from './actions/loading';
import { ModalProvider } from './components/Modal';
import RootErrorBoundary from './components/errors/RootErrorBoundary';
import Routers from './components/routers';
import LogoutOnInactivity from './components/shared/LogoutOnInactivity';
import LogoutOnOverride from './components/shared/LogoutOnOverride';
import NotificationManager from './components/shared/NotificationManager';
import ThemeProvider from './components/utilities/ThemeProvider';
import { ErrorContext } from './components/utilities/errors';
import createStore from './createStore';
import { getAccessToken } from './selectors';

am4core.useTheme(am4themesAnimated);
am4core.addLicense('CH242320562');

Sentry.init({
    dsn: process.env.sentryDsn,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    maxValueLength: Infinity,
});

class App extends PureComponent {
    constructor(props) {
        super(props);

        // first we create the history object
        this.history = createBrowserHistory();

        const authLink = new ApolloLink((operation, forward) => {
            operation.setContext(({ headers }) => {
                const token = getAccessToken(this.store.getState());

                if (!token) {
                    return { headers };
                }

                return {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        ...headers,
                    },
                };
            });

            return forward(operation);
        });

        const loadingLink = new ApolloLink((operation, forward) => {
            const hasMutations = operation.query.definitions.some(
                // only mutation other than the refresh credentials
                definition => definition.operation === 'mutation' && definition.name.value !== 'refreshCredentials'
            );

            if (!hasMutations) {
                return forward(operation);
            }

            return new Observable(observer => {
                let loadingPromiseResolve = null;
                const loadingPromise = new Promise(resolve => {
                    loadingPromiseResolve = resolve;
                });

                this.store.dispatch(withLoading(loadingPromise));

                const onCompletion = () => {
                    if (loadingPromiseResolve) {
                        loadingPromiseResolve();
                        loadingPromiseResolve = null;
                    }
                };

                const sub = forward(operation).subscribe({
                    next: result => {
                        onCompletion();
                        observer.next(result);
                    },
                    error: networkError => {
                        onCompletion();
                        observer.error(networkError);
                    },
                    complete: () => {
                        observer.complete.bind(observer)();
                    },
                });

                return () => {
                    sub.unsubscribe();
                };
            });
        });

        const errorHandlingLink = onError(({ graphQLErrors }) => {
            if (graphQLErrors) {
                for (const err of graphQLErrors) {
                    switch (err.extensions.code) {
                        case 'UNAUTHENTICATED':
                            this.store.dispatch(logout());
                            break;

                        case 'GRAPHQL_VALIDATION_FAILED':
                            this.setError(err.message);
                            break;

                        case 'INTERNAL_SERVER_ERROR':
                            this.setError('Internal Error');
                            break;

                        default:
                            break;
                    }
                }
            }
        });

        const httpLink = ApolloLink.split(
            operation => extractFiles(operation).files.size > 0,
            createUploadLink({ uri: '/graphql' }),
            from([loadingLink, new HttpLink({ uri: '/graphql' })])
        );

        this.client = new ApolloClient({
            link: from([authLink, errorHandlingLink, httpLink]),
            cache: new InMemoryCache({
                typePolicies: {
                    Statistics: {
                        keyFields: [],
                    },
                },
            }),
            connectToDevTools: process.env.NODE_ENV === 'development',
        });

        // create the redux store
        this.store = createStore({
            history: this.history,
            client: this.client,
        });

        // initialize runtime settings
        this.store.dispatch(initializeRuntimeSettings);

        this.history.listen(() => {
            // reset the error whenever there's a change
            this.setError(null);
        });
    }

    setError(error) {
        const [, setError] = this.context;
        setError(error);
    }

    render() {
        const { store, history, client } = this;

        return (
            <ApolloProvider client={client}>
                <Provider store={store}>
                    <ThemeProvider>
                        <ModalProvider>
                            <Router history={history}>
                                <RootErrorBoundary>
                                    <ModalProvider profile="admin">
                                        <NotificationManager>
                                            <LogoutOnInactivity />
                                            <LogoutOnOverride />
                                            <Routers />
                                        </NotificationManager>
                                    </ModalProvider>
                                </RootErrorBoundary>
                            </Router>
                        </ModalProvider>
                    </ThemeProvider>
                </Provider>
            </ApolloProvider>
        );
    }
}

App.contextType = ErrorContext;

export default App;
