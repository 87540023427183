import fieldTemplate from './fieldTemplate';

const getDefaultFinanceRebate = () => 0;

const financeRebateField = {
    // copy template
    ...fieldTemplate,

    // setup states
    isEditable: () => true,

    // setup values management
    getInitialValue: getDefaultFinanceRebate,
};

export default financeRebateField;
