import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const FieldContainerDiv = styled.div`
    width: 100%;
`;

const FieldContainerLabel = styled.div`
    color: ${props => (props.isWarning ? '#fd007a' : '#9c9c9c')};
    font-size: 0.85rem;
    padding-top: 11px;
    padding-bottom: 9.5px;
    min-height: 35px;
    box-sizing: border-box;
    word-break: break-word;
`;

const FieldContainerBody = styled.div`
    width: 100%;
`;

const FieldInfo = styled.label`
    width: 20px;
    height: 20px;
    border: 1px solid gray;
    border-radius: 27px;
    padding-left: 7px;
    margin-left: 10px;
    color: gray;
`;

const FieldContainer = ({ label, className, info, isWarning, children }) => (
    <FieldContainerDiv>
        <FieldContainerLabel className={className} isWarning={isWarning}>
            {label}
            {info && <FieldInfo title={info}>i</FieldInfo>}
        </FieldContainerLabel>
        <FieldContainerBody>{children}</FieldContainerBody>
    </FieldContainerDiv>
);

FieldContainer.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    info: PropTypes.string,
    isWarning: PropTypes.bool,
    label: PropTypes.node.isRequired,
};

export default FieldContainer;
