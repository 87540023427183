import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { yesNoOptions } from '../../../../../shared/constants/options';
import FieldContainer from '../../../../template/Field-container';
import NumberField from '../../../../template/Number';
import SwitchField from '../../../../template/Switch';
import UnitField from '../../../../template/options-field/UnitField';
import { useFormContext } from './context';

const StructuredPayment = () => {
    const { disabled, values, change, currency, rounding } = useFormContext();

    const min = get('structuredPayment.min', values);
    const max = get('structuredPayment.max', values);
    const show = get('structuredPayment.show', values);

    useEffect(() => {
        if (min === max) {
            change('structuredPayment.show', false);
            change('structuredPayment.step', 0);
            change('structuredPayment.default', min);
        }

        if (!show || min === max) {
            change('structuredPayment.editable', false);
        }
    }, [change, min, max, show]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <div className="sub-container-fluid">
                        <FieldContainer label="Min*">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                min={0}
                                name="structuredPayment.min"
                                precision={rounding.percentage.count}
                            />
                        </FieldContainer>
                        <span />
                        <FieldContainer label="Max*">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                min={0}
                                name="structuredPayment.max"
                                precision={rounding.percentage.count}
                            />
                        </FieldContainer>
                        <span />
                        <FieldContainer label="Step*">
                            <Field
                                component={NumberField}
                                disabled={min === max || disabled}
                                max={max}
                                min={1}
                                name="structuredPayment.step"
                                precision={rounding.percentage.count}
                            />
                        </FieldContainer>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <div className="sub-container-fluid">
                        <div className="flex-3">
                            <FieldContainer label="Default*">
                                <Field
                                    component={NumberField}
                                    disabled={disabled}
                                    min={0}
                                    name="structuredPayment.default"
                                    precision={rounding.percentage.count}
                                />
                            </FieldContainer>
                        </div>
                        <span />
                        <UnitField currencySymbol={currency} label="" name="structuredPayment.defaultUnit" disabled />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Show">
                        <Field
                            component={SwitchField}
                            disabled={disabled}
                            name="structuredPayment.show"
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Editable">
                        <Field
                            component={SwitchField}
                            disabled={!show || disabled || min === max}
                            name="structuredPayment.editable"
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                </div>
            </div>
        </div>
    );
};

export default StructuredPayment;
