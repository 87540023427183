import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const LoadingIndicator = ({ className = undefined, children }) => (
    <div className={className}>
        <div className="mask" />
        <div className="indicator">{children}</div>
    </div>
);

LoadingIndicator.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default styled(LoadingIndicator)`
    .mask {
        background: black;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0.5;
        z-index: 9;
    }

    .indicator {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
    }
`;
