import { getOr } from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';
import DropdownField from '../Dropdown';
import { GetSalesPersonQuery } from './options.graphql';
import useSalesPerson from './useSalesPerson';

type ElementTypeOfArray<TArray> = TArray extends Array<infer TElement> ? TElement : never;

type OptionItem = ElementTypeOfArray<GetSalesPersonQuery['sales']>;
export type UserDropDownFieldProps = {
    dealerIds: string[];
    filter?: (items: OptionItem[]) => OptionItem[];
    onOptionsChanged?: (items: Array<{ label: string; value: string }>) => unknown;
};

const UserDropdownField = ({ filter, dealerIds, onOptionsChanged, ...others }: UserDropDownFieldProps) => {
    const [data, loading] = useSalesPerson(dealerIds);
    const options = useMemo(() => {
        const items: OptionItem[] = getOr([], 'sales', data);

        return (filter ? filter(items) : items).map(({ id, name }) => ({ label: name, value: id }));
    }, [data, filter]);
    useEffect(() => {
        if (!loading && onOptionsChanged) {
            onOptionsChanged(options);
        }
    }, [options, onOptionsChanged, loading]);

    return <DropdownField {...others} options={options} noSort />;
};

export default UserDropdownField;
