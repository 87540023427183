import { isEqual } from 'lodash/fp';
import { FinanceProductBase, Unit } from '../fields/schema';

const isBankInvalid = (values, context) => values.bank && !context.selectedBank;
const isWithinRange = (input, range) => {
    const { min, max } = range;

    return input >= min && input <= max;
};

const isTermWithinRange = (values, context) => {
    // get term value
    const { paymentTerm } = values;

    // there's no payment term to check
    if (!paymentTerm) {
        return true;
    }

    // setup finance products
    const { selectedFinanceProduct } = context;

    return isWithinRange(paymentTerm, selectedFinanceProduct?.termSetting);
};

export const getDefaultValueByMinAndMax = (value, min, max) => {
    if (value <= min) {
        return min;
    }
    if (value >= max) {
        return max;
    }

    return value;
};

export const getDownPaymentRange = (selectedFinanceProduct, totalPrice) => {
    const { basedOn } = selectedFinanceProduct;
    const { defaultUnit, min: downPaymentMin, max: downPaymentMax } = selectedFinanceProduct?.downPaymentSetting;
    const { min: loanMin, max: loanMax } = selectedFinanceProduct?.loanSetting;

    if (defaultUnit === Unit.CURRENCY && basedOn === FinanceProductBase.LOAN) {
        const min = totalPrice - loanMax;
        const max = totalPrice - loanMin;

        return {
            min: getDefaultValueByMinAndMax(min, 0, totalPrice),
            max: getDefaultValueByMinAndMax(max, 0, totalPrice),
        };
    }

    return {
        min: getDefaultValueByMinAndMax(downPaymentMin, 0, totalPrice),
        max: getDefaultValueByMinAndMax(downPaymentMax, 0, totalPrice),
    };
};

const isDownPaymentWithinRange = (values, context) => {
    // get loan percentage
    const { downPayment, totalPrice } = values;

    // there's no downpayment to check
    if (!downPayment) {
        return true;
    }

    // setup finance products
    const { selectedFinanceProduct } = context;
    const { defaultUnit } = selectedFinanceProduct?.downPaymentSetting;

    return isWithinRange(
        defaultUnit === Unit.PERCENTAGE ? downPayment.percentage : downPayment.amount,
        getDownPaymentRange(selectedFinanceProduct, totalPrice)
    );
};

const computeChanges = (context, values, previousValues) => {
    const { fields } = context;

    /* first look for changes */

    for (const name of Object.keys(fields)) {
        // get the current value
        const currentValue = values[name];
        const previousValue = previousValues[name];

        if (!isEqual(currentValue, previousValue)) {
            // we need to run update for this field
            const changes = Object.entries(fields[name].updates).reduce((acc, [target, updateFunction]) => {
                const previousTargetValue = values[target];
                // get new value from update function
                const newValue = updateFunction(values, context, previousTargetValue);

                if (previousTargetValue !== newValue) {
                    return { ...acc, [target]: newValue };
                }

                return acc;
            }, null);

            if (changes) {
                // return merged new values
                return { ...values, ...changes };
            }
        }
    }

    /* we may now simply run some tests */

    if (isBankInvalid(context, values)) {
        // reset the bank
        return { ...values, bank: undefined };
    }

    if (!isTermWithinRange(values, context)) {
        return { ...values, paymentTerm: undefined };
    }

    if (!isDownPaymentWithinRange(values, context)) {
        return { ...values, downPayment: undefined };
    }

    return values;
};

export default computeChanges;
