import { useApolloClient, ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { CalculatorContext } from '@appvantageasia/afc-calculator-ui-next';
// @ts-ignore
import { Actions, DarkButton } from '@appvantageasia/afc-ui';
import { isEmpty, set } from 'lodash/fp';
import React, { useCallback, useState, useMemo, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { Channel } from '../../../../../schema';
import { getCurrentCountry, getRuntimeSettings } from '../../../../../selectors';
import { useCompanyFormatting } from '../../../../../utils/withCompanyFormatting';
import useFormatDateTime from '../../../../shared/useFormatDateTime';
import { CalculatorError, CalculatorModal } from '../../../../ui/calculator';
import { GetApplicationQuery } from '../../common/data/Application.graphql';
import { applyCalculator } from '../../common/utilities/map';
import { getPromoFromIdentifier } from '../data/useLoadPromo';
import useVariants from '../utilities/useVariants';
import Calculator from './Calculator';
import { CalculatorValues } from './types';

export type RecalculateProviderProps = {
    application: GetApplicationQuery['application'] & {
        // temporary variant for preowned form in express
        expressVariant?: GetApplicationQuery['application']['variant'];
    };
    children: (openRecalculate: () => void) => ReactElement;
    form: string;
    setCalculatorStatus: (status: boolean) => void;
};

const RecalculateProvider = ({ application, children, form, setCalculatorStatus }: RecalculateProviderProps) => {
    // formatting utilities
    const formats = useCompanyFormatting();
    const translation = useContentTranslation();
    const formatDateTime = useFormatDateTime();

    const [isOpen, setOpen] = useState(false);

    const openRecalculate = useCallback(() => setOpen(true), [setOpen]);

    const { channelSetting, id: countryId, code } = useSelector(getCurrentCountry);
    const allowOptions = useMemo(() => {
        switch (application.channel) {
            case Channel.NEW:
                return channelSetting.new.allowOptions;

            default:
                return false;
        }
    }, [application.channel, channelSetting]);

    // get our variants
    const { variants, financeProducts } = useVariants(
        application?.dealerId,
        application?.zoneId,
        application?.channel,
        application?.financeProduct?.bankId,
        application?.event?.id
    );

    const isUsedCar = useMemo(
        () =>
            application?.variant.channels.express || application?.variant.channels.used
                ? application?.variant.channels.used.length > 0
                : false,
        [application]
    );

    const isPorscheFinder = useMemo(() => !!application?.finderVehicle?.id, [application]);

    const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
    const dispatch = useDispatch();
    const { type: runtimeType } = useSelector(getRuntimeSettings);

    const [context, setContext] = useState<CalculatorContext<CalculatorValues>>();
    const { values, errors } = context || {};

    // apply callback
    const apply = useCallback(async () => {
        const promo = await getPromoFromIdentifier(client, countryId, values?.promoCode ?? '', application.dealerId);
        const validPromo = promo && promo.remainingQuantity > 0 ? promo : null;

        let { variant } = application;

        const carOptions = context?.getFieldContext('carOptions');

        if (application?.expressVariant) {
            variant = set('preOwnedCarDetails', application.expressVariant.preOwnedCarDetails, variant);
        }

        applyCalculator({
            application,
            values,
            variants: isUsedCar || isPorscheFinder ? [variant] : variants,
            financeProducts,
            allowOptions,
            formats,
            dispatch,
            change,
            form,
            promo: validPromo,
            formatDateTime,
            translation,
            selectedOptions: carOptions?.selectedOptions,
        });

        // close modal
        setOpen(false);
    }, [
        client,
        countryId,
        values,
        application,
        isUsedCar,
        variants,
        financeProducts,
        allowOptions,
        formats,
        dispatch,
        form,
        formatDateTime,
        translation,
        context,
        isPorscheFinder,
    ]);

    // on calculator change callback
    const onChange = useCallback(
        context => {
            setContext(context);
            setCalculatorStatus(!isEmpty(context?.errors));
        },
        [setCalculatorStatus, setContext]
    );

    // close callback
    const onClose = useCallback(() => {
        // close modal
        setOpen(false);
    }, [setOpen]);

    const calculatorVariants = useMemo(() => (isPorscheFinder ? [application?.variant] : variants), [
        application,
        isPorscheFinder,
        variants,
    ]);

    return (
        <>
            {children(openRecalculate)}
            {isOpen && (
                <CalculatorModal
                    channel={application.channel}
                    countryCode={code}
                    onClose={onClose}
                    runtimeType={runtimeType}
                    showClose
                    showTitle
                >
                    <Calculator
                        application={application}
                        channel={application.channel}
                        financeProducts={financeProducts}
                        form={form}
                        onChange={onChange}
                        variants={calculatorVariants}
                    />
                    <Actions>
                        <DarkButton disabled={!isEmpty(errors)} onClick={apply}>
                            Save
                        </DarkButton>
                    </Actions>
                    <CalculatorError errors={errors} />
                </CalculatorModal>
            )}
        </>
    );
};

export default RecalculateProvider;
