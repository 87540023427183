import { getOr } from 'lodash/fp';
import React from 'react';
import { useCalculatorContext } from '../../CalculatorContext';
import StaticField, { sharedPropTypes } from './StaticField';

const FinanceProductField = props => {
    // get the finance products from the calculator context
    const { selectedFinanceProduct, contentTranslation } = useCalculatorContext();
    const financeProductName = getOr('', contentTranslation.formatPath('name'), selectedFinanceProduct);

    return <StaticField {...props} value={financeProductName} />;
};

FinanceProductField.propTypes = {
    ...sharedPropTypes,
};

export default FinanceProductField;
