import styled from 'styled-components';
import { OptionWithPrice } from './CarOptions';
import PromoCodeValue, { Description, PromoWithCode, PromoIcon } from './PromoCodeValue';

const Tooltip = styled.span`
    visibility: hidden;
    max-width: 200px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 15px;
    line-height: 1.2;
    white-space: pre-wrap;
    position: fixed;

    /* option tooltip style */
    ${OptionWithPrice} {
        width: auto;
        display: inline-block;

        span {
            white-space: pre-wrap !important;
            overflow: auto !important;
        }
    }

    /* promo code  tooltip style */
    ${PromoCodeValue} {
        color: #fff;
    }

    // description
    ${Description} {
        padding-top: 5px;
    }

    ${PromoWithCode} {
        > div {
            padding: 0;
        }

        span {
            color: #fff;
        }

        ${PromoIcon} {
            position: static;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
    }
`;

export default Tooltip;
