import { isEmpty } from 'lodash/fp';
import { useSelector } from 'react-redux';
import { getFormSyncErrors } from 'redux-form';

const useFormDirty = (form: string) => {
    // get form errors
    const errors = useSelector(getFormSyncErrors(form));

    return !isEmpty(errors);
};

export default useFormDirty;
