import React from 'react';
import ConnectedLabelBox from './ConnectedLabelBox';

const PromoCodeLabelBox = props => {
    // for now, we can not get promo detail information in comparison context
    // so we give a fixed height temporarily
    return <ConnectedLabelBox {...props} height="60px !important" />;
};

export default PromoCodeLabelBox;
