import styled from 'styled-components';

const ImportExportContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    font-size: 11.9px;
`;

export default ImportExportContainer;
