import { InformationContentModal, ErrorContentModal } from '@appvantageasia/afc-ui';
import { isNil } from 'lodash';
import { isEmpty } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { Close, Modal } from '../modal';
import UpdateDuplicateContent from './UpdateDuplicateContent';
import UploadContent from './UploadContent';

const ImportModal = ({
    accept = '.xlsx,.csv',
    errors,
    updateCount,
    type,
    duplicates,
    onClose,
    onDuplicateClick,
    onUpload,
    onDownloadClick,
    onOverrideClick,
    hasTemplateDownload = false,
}) => {
    const content = () => {
        if (!isEmpty(errors)) {
            const errorTitle = 'The following errors were found in the uploaded file. Please fix and re-upload.';

            return <ErrorContentModal errorTitle={errorTitle} errors={errors} />;
        }

        if (!isNil(updateCount)) {
            return (
                <InformationContentModal>
                    {updateCount} {type} imported
                </InformationContentModal>
            );
        }

        if (!isEmpty(duplicates)) {
            return (
                <UpdateDuplicateContent
                    duplicates={duplicates}
                    onDuplicateClick={onDuplicateClick}
                    onOverrideClick={onOverrideClick}
                />
            );
        }

        return (
            <UploadContent
                accept={accept}
                hasTemplateDownload={hasTemplateDownload}
                onDownloadClick={onDownloadClick}
                onUpload={onUpload}
                type={type}
            />
        );
    };

    return (
        <Modal onRequestClose={onClose} isOpen>
            <Close onClose={onClose} />
            {content()}
        </Modal>
    );
};

ImportModal.propTypes = {
    accept: PropTypes.string,
    duplicates: PropTypes.arrayOf(PropTypes.shape({})),
    errors: PropTypes.shape({}),
    hasTemplateDownload: PropTypes.bool,
    onClose: PropTypes.func,
    onDownloadClick: PropTypes.func,
    onDuplicateClick: PropTypes.func,
    onOverrideClick: PropTypes.func,
    onUpload: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    updateCount: PropTypes.number,
};

export default ImportModal;
