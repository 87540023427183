import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { getGlobalPermissions } from '../../selectors';
import { EventList, CreateEvent, EditEvent } from '../routes/Events';

const EventsRouter = () => {
    const { mayViewEventChannel, mayManageEventChannel } = useSelector(getGlobalPermissions);

    return (
        <Switch>
            {mayViewEventChannel && <Route component={EventList} path="/event" exact />}
            {mayManageEventChannel && <Route component={CreateEvent} path="/event/new" exact />}
            {mayViewEventChannel && <Route component={EditEvent} path="/event/:id" exact />}
            <Redirect to="/home" />
        </Switch>
    );
};

export default EventsRouter;
