import { ErrorContentModal } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Close } from './modal';

const ImportInvalidModal = ({ onClose, errors }) => {
    const errorTitle = 'The following errors were found in the uploaded file. Please fix and re-upload.';

    return (
        <Modal onRequestClose={onClose} isOpen>
            <Close onClose={onClose} />
            <ErrorContentModal errorTitle={errorTitle} errors={errors} />
        </Modal>
    );
};

ImportInvalidModal.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string),
    onClose: PropTypes.func.isRequired,
};

export default ImportInvalidModal;
