import { get } from 'lodash/fp';
import React from 'react';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { onOffOptions } from '../../../../../shared/constants/options';
import { COPYRIGHT } from '../../../../../utilities/constants/copyright';
import ColorPickerField from '../../../../template/ColorPicker';
import CopyrightInput from '../../../../template/CopyrightInput';
import FieldContainer from '../../../../template/Field-container';
import FileUpload from '../../../../template/FileUpload';
import InputField from '../../../../template/Input';
import Portlet from '../../../../template/Portlet';
import SwitchField from '../../../../template/Switch';
import TextareaField from '../../../../template/Textarea';
import { useFormContext } from './context';

const MainDetails = () => {
    const { formatPath } = useContentTranslation();
    const { values } = useFormContext();
    const edition = !!get('lastModified', values);

    return (
        <Portlet name="Main Details" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Company Code">
                            <Field component={InputField} disabled={edition} name="code" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Company Name">
                            <Field component={InputField} disabled={edition} name={formatPath('name')} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Email">
                            <Field component={InputField} name="email" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Theme: Highlight Colour">
                            <Field component={ColorPickerField} name="color" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Copyright">
                            <Field
                                component={CopyrightInput}
                                name={formatPath('copyright')}
                                placeholder="Company Name"
                                prefix={COPYRIGHT}
                            />
                        </FieldContainer>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Company Description">
                            <Field component={TextareaField} maxLength="1000" name={formatPath('description')} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Logo">
                            <Field
                                accept=".jpg,.png,.jpeg,.svg"
                                component={FileUpload}
                                label="Size 1000x500px in jpg, png or svg"
                                name="logo"
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Favicon">
                            <Field
                                accept=".jpg,.png,.jpeg"
                                component={FileUpload}
                                label="Size 500x500px in jpg or png"
                                name="favIcon"
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Company Web Font">
                            <Field
                                accept=".woff,.woff2,.ttf,.otf"
                                component={FileUpload}
                                label="File formats: woff,woff2, ttf, otf"
                                name="font"
                                type="font"
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Active">
                            <Field component={SwitchField} name="isActive" options={onOffOptions} />
                        </FieldContainer>
                    </div>
                    {edition && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Last Modified">
                                <Field component={InputField} name="lastModified" disabled />
                            </FieldContainer>
                        </div>
                    )}
                </div>
            </div>
        </Portlet>
    );
};

export default MainDetails;
