import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import { ErrorMessageDiv } from '../../../containers/Layout';
import { withModal } from '../../Modal';
import { FileContainer, Tips, Label, CloseIcon } from '../../ui/form/File';
import FontPreview from './FontPreview';
import ImagePreview from './ImagePreview';

const FileUpload = ({
    modal,
    input,
    meta,
    type = 'image',
    label = 'Size 1000x500px in jpg or png',
    disabled = false,
    accept = '.png,.jpg.jpeg',
}) => {
    const { onBlur, onFocus, value, onChange: change } = input;
    const { active, touched, error = null } = meta;

    // redux-form transform the value into an empty string
    // force it back to null
    const cleanedValue = value === '' ? null : value;

    const hasError = !active && touched && !!error;

    const onChange = useCallback(
        event => {
            const file = event.target.files[0];

            if (file instanceof File) {
                change(file);
            }
        },
        [change]
    );

    const isFile = cleanedValue instanceof File;
    const noData = !isFile && isEmpty(cleanedValue);

    const onDelete = useCallback(() => {
        modal.confirm({
            title: '',
            content: 'Are you sure you want to delete this?',
            options: [
                { label: 'No', action: () => null },
                { label: 'Yes', action: () => change(null) },
            ],
        });
    }, [modal, change]);

    const renderPreview = () => {
        switch (type) {
            case 'font':
                return <FontPreview file={cleanedValue} />;

            case 'image':
            default:
                return <ImagePreview file={cleanedValue} />;
        }
    };

    return (
        <FileContainer>
            <Label disabled={disabled}>
                <input
                    accept={accept}
                    disabled={disabled}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={onFocus}
                    type="file"
                />
                {renderPreview()}
            </Label>
            {!noData && (
                <CloseIcon onClick={onDelete}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                </CloseIcon>
            )}
            <Tips>{label}</Tips>
            {hasError && <ErrorMessageDiv>{error}</ErrorMessageDiv>}
        </FileContainer>
    );
};

FileUpload.propTypes = {
    accept: PropTypes.string,
    disabled: PropTypes.bool,
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    label: PropTypes.string,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
    modal: PropTypes.shape({ confirm: PropTypes.func.isRequired }).isRequired,
    type: PropTypes.string,
};

export default withModal(FileUpload);
