import { useQuery } from '@apollo/client';
import { omit } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { getCurrentCountry } from '../../../../selectors';
import { getLastModified } from '../../../../utilities/forms';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { getVariant } from './Edition.graphql';
import Page from './Page';

const Edition = () => {
    const { id } = useParams();
    const variables = { id };
    const { data, loading } = useQuery(getVariant, { variables, fetchPolicy: 'network-only' });

    const { zones } = useSelector(getCurrentCountry);

    const formatDateTime = useFormatDateTime();

    const initialValues = useMemo(() => {
        const values = data?.variant;

        if (!values) {
            return null;
        }

        const { model, prices, ...variantInitialValues } = values;

        return {
            ...omit(['options', 'channels'], variantInitialValues),
            prices: {
                ...Object.fromEntries(zones.map(zone => [zone.id, null])),
                ...Object.fromEntries(prices.map(({ zoneId, value }) => [zoneId, value])),
            },
            mandatories: {
                ...Object.fromEntries(prices.map(({ zoneId }) => [zoneId, true])),
            },
            modelId: model.parentId || model.id,
            subModelId: model.parentId ? model.id : null,
            lastModified: getLastModified(values.version, formatDateTime),
        };
    }, [data, zones, formatDateTime]);

    if (!loading && !initialValues) {
        // the product does not exist
        return <Redirect to="/vehicle/variants" />;
    }

    return <Page initialValues={initialValues} options={data?.variant?.options} />;
};

export default Edition;
