import { get, getOr } from 'lodash/fp';
import {
    BalloonMode,
    DepositMode,
    DerivedMethod,
    DownPaymentMode,
    FinanceProductBase,
    FinanceProductSubType,
    FinanceProductType,
    InterestRateMode,
} from '../../../../../schema';
import * as validators from '../../../../../utils/validators';

const getMinMaxInterestRate = (values, minStep) => {
    const basedSetting =
        values.basedOn === FinanceProductBase.DOWNPAYMENT
            ? get('downPaymentSetting', values)
            : get('loanSetting', values);

    if (basedSetting?.mode === DownPaymentMode.TABLE) {
        return {
            min: minStep,
            max: minStep,
            basedSetting,
        };
    }

    return {
        min: getOr(minStep, 'min', basedSetting),
        max: getOr(minStep, 'max', basedSetting),
        basedSetting,
    };
};

const validate = details => (formatPath, useExternalCalculator) => {
    const { downPayment, loan, icc } = details;

    return validators.compose(
        // validate main parameters
        validators.requiredString(formatPath('name')),
        validators.requiredString('type'),
        validators.requiredString('subType'),
        validators.requiredString('identifier'),
        validators.requiredDate('period.start'),
        validators.requiredString('period.startTimeZone'),
        validators.requiredDate('period.end'),
        validators.requiredString('period.endTimeZone'),
        validators.startDateMayNotExceedEndDate('period.start', 'period.end'),
        validators.requiredString('bankId'),
        validators.requiredStringArray('dealerIds'),
        validators.requiredNumber('order'),

        // validate calculation

        validators.only(
            values => get('paymentMode.derivedMethod', values) === DerivedMethod.FORMULA,
            validators.compose(
                validators.requiredString('paymentMode.mode'),
                validators.requiredBoolean('paymentMode.show')
            )
        ),
        validators.requiredBoolean('paymentMode.editable'),
        validators.requiredString('paymentMode.calculationMode'),
        validators.requiredString('paymentMode.derivedMethod'),
        validators.only(
            values => get('subType', values) === FinanceProductSubType.HIREPURCHASEBALLOONGFV,
            validators.requiredNumberLesserOrEqualThanNumber(
                'paymentMode.reducedPercentage',
                100,
                'Value cannot exceed 100'
            )
        ),

        // validate details
        validators.requiredString('basedOn'),

        // validate down payment
        validators.requiredNumber('downPaymentSetting.min'),
        validators.requiredNumber('downPaymentSetting.max'),
        validators.requiredNumber('downPaymentSetting.step'),
        validators.requiredNumber('downPaymentSetting.default'),
        validators.requiredString('downPaymentSetting.defaultUnit'),
        validators.requiredValidRange('downPaymentSetting', 1, (downPayment && downPayment?.range?.max) || 100),
        validators.requiredBoolean('downPaymentSetting.show'),
        validators.requiredBoolean('downPaymentSetting.editable'),

        validators.only(
            () => useExternalCalculator,
            validators.compose(
                validators.requiredNumber('downPaymentSetting.icc.min'),
                validators.requiredNumber('downPaymentSetting.icc.max'),
                validators.requiredNumber('downPaymentSetting.icc.step'),
                validators.requiredNumber('downPaymentSetting.icc.default'),
                validators.requiredString('downPaymentSetting.icc.defaultUnit'),
                validators.requiredValidRange('downPaymentSetting.icc', 1, (icc && icc?.range?.max) || 100)
            )
        ),

        // validate loan
        validators.requiredNumber('loanSetting.min'),
        validators.requiredNumber('loanSetting.max'),
        validators.requiredNumber('loanSetting.step'),
        validators.requiredNumber('loanSetting.default'),
        validators.requiredString('loanSetting.defaultUnit'),
        validators.requiredValidRange('loanSetting', 1, (loan && loan?.range?.max) || 100),
        validators.requiredBoolean('loanSetting.show'),
        validators.requiredBoolean('loanSetting.editable'),

        // validate payment term
        validators.requiredNumber('termSetting.min'),
        validators.requiredNumber('termSetting.max'),
        validators.requiredNumber('termSetting.step'),
        validators.requiredNumber('termSetting.default'),
        validators.requiredValidRange('termSetting', 1),
        validators.requiredBoolean('termSetting.show'),
        validators.requiredBoolean('termSetting.editable'),

        // validate interest only if the sub type is deferred principal
        validators.only(
            values => get('subType', values) === FinanceProductSubType.DEFERREDPRINCIPAL,
            validators.requiredNumber('termSetting.interestOnly')
        ),

        // validate common fields for interest rate
        validators.requiredString('interestRateSetting.mode'),
        validators.requiredString('interestRateSetting.displayMode'),
        validators.requiredBoolean('interestRateSetting.editable'),

        // validate interest rate on flat mode
        validators.only(
            values => get('interestRateSetting.mode', values) === InterestRateMode.FLAT,
            validators.requiredNumber('interestRateSetting.default')
        ),

        // validate interest rate on range mode
        validators.only(
            values => get('interestRateSetting.mode', values) === InterestRateMode.RANGE,
            validators.compose(
                validators.requiredNumber('interestRateSetting.min'),
                validators.requiredNumber('interestRateSetting.max'),
                validators.requiredNumber('interestRateSetting.step'),
                validators.requiredNumber('interestRateSetting.default'),
                validators.requiredValidRange('interestRateSetting', 0.01, 100)
            )
        ),

        // validate interest rate on table mode
        validators.only(
            values => get('interestRateSetting.mode', values) === InterestRateMode.TABLE,
            validators.compose(
                validators.lazy(values => {
                    const minStep = 1;
                    const { min, max, basedSetting } = getMinMaxInterestRate(values, minStep);
                    const tableGroupingUnit = getOr('%', 'interestRateSetting.tableGroupingUnit', values);

                    if (min !== max && tableGroupingUnit === basedSetting.defaultUnit) {
                        return validators.compose(
                            validators.requiredNumberGreaterOrEqualThanNumber('interestRateSetting.min', min),
                            validators.requiredNumberLesserOrEqualThanNumber('interestRateSetting.min', max),
                            validators.requiredNumberGreaterOrEqualThanNumber('interestRateSetting.max', min),
                            validators.requiredNumberLesserOrEqualThanNumber('interestRateSetting.max', max),
                            validators.requiredNumberGreaterOrEqualThanNumber(
                                'interestRateSetting.tableGroupingStep',
                                minStep
                            ),
                            validators.requiredNumberLesserOrEqualThanNumber(
                                'interestRateSetting.tableGroupingStep',
                                Math.max(minStep, max - min)
                            )
                        );
                    }

                    // compose nothing
                    return validators.compose();
                })
            )
        ),

        // validate deposit if type is leasing
        validators.only(
            values =>
                get('type', values) === FinanceProductType.LEASING &&
                get('subType', values) === FinanceProductSubType.LEASE,
            validators.compose(
                // validate common fields for deposit
                validators.requiredString('depositSetting.mode'),
                validators.requiredBoolean('depositSetting.show'),
                validators.requiredBoolean('depositSetting.editable'),

                // validate deposit on range mode
                validators.only(
                    values => get('depositSetting.mode', values) === DepositMode.RANGE,
                    validators.compose(
                        validators.requiredNumber('depositSetting.min'),
                        validators.requiredNumber('depositSetting.max'),
                        validators.requiredNumber('depositSetting.step'),
                        validators.requiredNumber('depositSetting.default'),
                        validators.requiredValidRange('depositSetting', 0.1, 100),
                        validators.requiredString('depositSetting.defaultUnit')
                    )
                )
            )
        ),

        // validate average mileage only if the sub type is lease purchase
        validators.only(
            values =>
                get('subType', values) === FinanceProductSubType.LEASEPURCHASE ||
                get('subType', values) === FinanceProductSubType.LEASEPURCHASEICC,
            validators.compose(
                validators.requiredNumber('averageMileageSetting.min'),
                validators.requiredNumber('averageMileageSetting.max'),
                validators.requiredNumber('averageMileageSetting.step'),
                validators.requiredNumber('averageMileageSetting.default'),
                validators.requiredString('averageMileageSetting.defaultUnit'),
                validators.requiredValidRange('averageMileageSetting', 0),
                validators.requiredBoolean('averageMileageSetting.show'),
                validators.requiredBoolean('averageMileageSetting.editable')
            )
        ),

        // validate structured payment only if the sub type is structured payment
        validators.only(
            values => get('subType', values) === FinanceProductSubType.STRUCTUREDPAYMENT,
            validators.compose(
                validators.requiredNumber('structuredPayment.min'),
                validators.requiredNumber('structuredPayment.max'),
                validators.requiredNumber('structuredPayment.step'),
                validators.requiredNumber('structuredPayment.default'),
                validators.requiredValidRange('structuredPayment', 0.1, 100),
                validators.requiredString('structuredPayment.defaultUnit'),
                validators.requiredBoolean('structuredPayment.show'),
                validators.requiredBoolean('structuredPayment.editable')
            )
        ),

        // validate balloon payment only for HP with balloon or 50/50
        validators.only(
            values =>
                get('subType', values) === FinanceProductSubType.HIREPURCHASEBALLOON ||
                get('subType', values) === FinanceProductSubType.HIREPURCHASEBALLOONNZ ||
                get('subType', values) === FinanceProductSubType.HALFHALF,
            validators.compose(
                // validate common fields for balloon payment
                validators.requiredString('balloonSetting.mode'),
                validators.requiredString('balloonSetting.basedOn'),
                validators.requiredBoolean('balloonSetting.show'),
                validators.requiredBoolean('balloonSetting.editable'),

                // validate balloon payment on range mode
                validators.only(
                    values => get('balloonSetting.mode', values) === BalloonMode.RANGE,
                    validators.compose(
                        validators.requiredNumber('balloonSetting.min'),
                        validators.requiredNumber('balloonSetting.max'),
                        validators.requiredNumber('balloonSetting.step'),
                        validators.requiredValidRange('balloonSetting', 0.1, 100),
                        validators.requiredNumber('balloonSetting.default'),
                        validators.requiredString('balloonSetting.defaultUnit')
                    )
                )
            )
        ),

        // validate residual value
        validators.only(
            values =>
                [
                    FinanceProductSubType.HIREPURCHASEBALLOONGFV,
                    FinanceProductSubType.LEASEPURCHASE,
                    FinanceProductSubType.LEASEPURCHASEICC,
                ].includes(get('subType', values)),
            validators.requiredString('residualValueSetting.defaultUnit')
        ),

        // validate license plate fee
        validators.only(
            values => get('subType', values) === FinanceProductSubType.UCCLLEASING,
            validators.compose(
                validators.requiredNumber('licensePlateFeeSetting.default'),
                validators.requiredNumber('licensePlateFeeSetting.min'),
                validators.requiredNumber('licensePlateFeeSetting.max'),
                validators.requiredNumber('licensePlateFeeSetting.step'),
                validators.lazy(values => {
                    const min = getOr(0, 'licensePlateFeeSetting.min', values);
                    const max = getOr(0, 'licensePlateFeeSetting.max', values);

                    return validators.compose(
                        validators.requiredNumberGreaterOrEqualThanNumber('licensePlateFeeSetting.default', min),
                        validators.requiredNumberLesserOrEqualThanNumber('licensePlateFeeSetting.default', max)
                    );
                })
            )
        ),

        // validate commission
        validators.only(
            values => get('subType', values) === FinanceProductSubType.UCCLLEASING,
            validators.compose(validators.requiredNumber('commissionSetting.default'))
        )
    );
};

export default validate;
