import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { DownPaymentMode, FinanceProductBase } from '../../../../../schema';
import { yesNoOptions, downPaymentTypeOptions } from '../../../../../shared/constants/options';
import FieldContainer from '../../../../template/Field-container';
import NumberField from '../../../../template/Number';
import SwitchField from '../../../../template/Switch';
import UnitField from '../../../../template/options-field/UnitField';
import DownPaymentICC from './DownPaymentICC';
import { useFormContext } from './context';
import useDownPaymentLoan from './useDownPaymentLoan';

const DownPayment = () => {
    const { disabled, values, change, currency, rounding } = useFormContext();

    const basedOn = get('basedOn', values);
    const remoteManaged = basedOn === FinanceProductBase.LOAN;

    const min = get('downPaymentSetting.min', values);
    const max = get('downPaymentSetting.max', values);
    const mode = get('downPaymentSetting.mode', values);

    const loanMin = get('loanSetting.min', values);
    const loanMax = get('loanSetting.max', values);

    const showEditable = mode !== DownPaymentMode.TABLE;

    const handleSettingChange = useDownPaymentLoan(FinanceProductBase.DOWNPAYMENT);

    useEffect(() => {
        if (!downPaymentTypeOptions.some(item => item.value === mode)) {
            change('downPaymentSetting.mode', downPaymentTypeOptions[0].value);
        }
    }, [mode, change]);

    useEffect(() => {
        if (!showEditable) {
            change('downPaymentSetting.editable', false);
        }
    }, [showEditable, change]);

    const mayManageEditable =
        basedOn === FinanceProductBase.LOAN && ((loanMin !== loanMax && min === max) || min !== max);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Type">
                        <Field
                            component={SwitchField}
                            disabled={disabled}
                            name="downPaymentSetting.mode"
                            options={downPaymentTypeOptions}
                        />
                    </FieldContainer>
                </div>
            </div>
            {mode === DownPaymentMode.RANGE && (
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div className="sub-container-fluid">
                            <FieldContainer label="Min*">
                                <Field
                                    component={NumberField}
                                    disabled={remoteManaged || disabled}
                                    handleChange={value => handleSettingChange({ min: value })}
                                    min={0}
                                    name="downPaymentSetting.min"
                                    precision={rounding.percentage.count}
                                />
                            </FieldContainer>
                            <span />
                            <FieldContainer label="Max*">
                                <Field
                                    component={NumberField}
                                    disabled={remoteManaged || disabled}
                                    handleChange={value => handleSettingChange({ max: value })}
                                    min={0}
                                    name="downPaymentSetting.max"
                                    precision={rounding.percentage.count}
                                />
                            </FieldContainer>
                            <span />
                            <FieldContainer label="Step*">
                                <Field
                                    component={NumberField}
                                    disabled={remoteManaged || min === max || disabled}
                                    handleChange={value => handleSettingChange({ step: value })}
                                    max={max}
                                    min={1}
                                    name="downPaymentSetting.step"
                                    precision={rounding.percentage.count}
                                />
                            </FieldContainer>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div className="flex-3">
                            <FieldContainer label="Default*">
                                <div className="sub-container-fluid">
                                    <div className="flex-3">
                                        <Field
                                            component={NumberField}
                                            disabled={remoteManaged || disabled}
                                            handleChange={value => handleSettingChange({ default: value })}
                                            min={0}
                                            name="downPaymentSetting.default"
                                            precision={rounding.percentage.count}
                                        />
                                    </div>
                                    <span />
                                    <div>
                                        <UnitField
                                            currencySymbol={currency}
                                            disabled={remoteManaged || disabled}
                                            name="downPaymentSetting.defaultUnit"
                                            onChangeEvent={value => handleSettingChange({ defaultUnit: value })}
                                        />
                                    </div>
                                </div>
                            </FieldContainer>
                        </div>
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Show">
                        <Field
                            component={SwitchField}
                            disabled={disabled}
                            name="downPaymentSetting.show"
                            onClick={value => handleSettingChange({ show: value })}
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                </div>
                {showEditable && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Editable">
                            <Field
                                component={SwitchField}
                                disabled={!mayManageEditable || disabled}
                                name="downPaymentSetting.editable"
                                options={yesNoOptions}
                            />
                        </FieldContainer>
                    </div>
                )}
            </div>
            <DownPaymentICC />
        </div>
    );
};

export default DownPayment;
