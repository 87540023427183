import styled from 'styled-components';

export const HeadBar = styled.div`
    min-height: 64.5px;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: row;
    align-items: center;

    .search {
        margin-right: 30px;
    }
`;

export const HeadTitle = styled.div`
    flex: 1;
    min-width: 200px;
    padding: 18px 0 23px 30px;
    font-size: 1.43rem;
    text-align: left;
    color: #000000;

    display: flex;
    align-items: center;
    flex-direction: row;

    & > *:not(:last-child) {
        margin-right: 1rem;
    }
`;
