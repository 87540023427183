import { useApolloClient } from '@apollo/client';
// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useContentTranslation } from '../../../../../i18n';
import { ApplicationPhase, Channel } from '../../../../../schema';
import { appointmentPurposeOptions, appointmentStatusOptions } from '../../../../../shared/constants/options';
import { handleResponseError } from '../../../../../utilities/forms';
import SelectField from '../../../../shared/form-v2/SelectField';
import TextField from '../../../../shared/form-v2/TextField';
import UserSelectField from '../../../../template/options-field/UserSelectField';
import useFormValues from '../../../../utilities/useFormValues';
import { LinkField } from '../../../Workflow/Finance/shared/Vehicle/Event';
import {
    updateAssigneeAppointment,
    UpdateAssigneeAppointmentMutation,
    UpdateAssigneeAppointmentMutationVariables,
} from '../Edition.graphql';
import FieldAppointmentDateTime from './FieldAppointmentDateTime';
import { AppointmentFormValues, useAppointmentFormContext } from './context';

type SectionSummaryProps = {
    dealerId: string;
    channel: Channel;
};

const SectionSummary = ({ dealerId, channel }: SectionSummaryProps) => {
    const { formatPath } = useContentTranslation();

    const { disabled } = useAppointmentFormContext();

    const { latestApplication, id } = useFormValues<AppointmentFormValues>();

    const referralLinkPrefix = useMemo(() => {
        switch (latestApplication.phase) {
            case ApplicationPhase.LEAD:
                return '/workflow/leads';

            case ApplicationPhase.RESERVATION:
                return '/workflow/reservations';

            case ApplicationPhase.FINANCE:
            default:
                return '/workflow/applications';
        }
    }, [latestApplication]);

    const client = useApolloClient();
    const onAssigneeChange = useCallback(
        value => {
            client
                .mutate<UpdateAssigneeAppointmentMutation, UpdateAssigneeAppointmentMutationVariables>({
                    mutation: updateAssigneeAppointment,
                    variables: {
                        id,
                        assigneeId: value,
                    },
                })
                .catch(handleResponseError);
        },
        [client, id]
    );

    return (
        <Portlet name="Appointment Details" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Appointment ID" name="identifier" disabled />
                    </div>
                    <FieldAppointmentDateTime name="date" />
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label="Appointment For"
                            name="purpose"
                            options={appointmentPurposeOptions}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label="Appointment Status"
                            name="status"
                            options={appointmentStatusOptions}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Date Created" name="latestApplication.display.dateCreated" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Dealer" name={formatPath('latestApplication.display.dealer')} disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Created by" name="latestApplication.display.createdBy" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <UserSelectField
                            dealerId={dealerId}
                            disabled={disabled}
                            label="Assigned to"
                            name="assignee.id"
                            onChangeEvent={onAssigneeChange}
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Channel" name="latestApplication.display.source" disabled />
                    </div>
                    {channel === Channel.EVENT && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField label="Event Name" name="latestApplication.display.event" disabled />
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <LinkField>
                            <div className="label">Lead/ Reservation/ Finance Application Reference</div>
                            <div className="link-control">
                                <Link to={`${referralLinkPrefix}/${latestApplication?.version.id}`}>
                                    {latestApplication?.display?.appId}
                                </Link>
                            </div>
                        </LinkField>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Last Modified" name="latestApplication.display.lastModified" disabled />
                    </div>
                </div>
            </div>
        </Portlet>
    );
};

export default SectionSummary;
