import { get, getOr, reduce, sortBy, find, flow, map, groupBy, mapValues, keyBy } from 'lodash/fp';

const mapCarOptions = options =>
    mapValues(
        // push deeper the option and copy the group data on the upper level
        groupOptions => ({
            ...groupOptions[0].group,
            // at the same time sort options
            options: sortBy(['order'], groupOptions),
        }),
        // group option by group id
        groupBy('groupId', options)
    );

reduce((acc, carOption) => {
    const { group } = carOption;
    const { id: groupId, name: label, order } = group;
    const { [groupId]: groupMeta = { options: [] } } = acc;
    const { options: groupOptions } = groupMeta;

    return {
        ...acc,
        [groupId]: {
            id: groupId,
            options: [...groupOptions, carOption],
            label,
            order,
        },
    };
}, {});

const generateSignature = options =>
    options.length ? options.map(option => `${option.groupId}=${option.version?.id}`).join('+') : 'noOptions';

const mapInventories = (inventory, optionMap) =>
    flow([
        get('sets'),
        map(({ remainingStock = 0, optionIds, isActive }) => {
            // get options from the map
            const options = optionIds.map(optionId => optionMap[optionId]).filter(Boolean);

            // ensure inventory is fine (all options are here/retrieved)
            if (options.length !== optionIds.length) {
                // create a mock inventory for those cases
                return ['invalid', { remainingStock: 0, isActive: false }];
            }

            // sort by group
            const sortedOptions = sortBy(['group.order'], options);
            // generate its unique signature
            const signature = generateSignature(sortedOptions);

            return [signature, { remainingStock, isActive }];
        }),
        Object.fromEntries,
    ])(inventory);

const computeCarOptions = (run, selectedVariant, values, { useOptions, snapshot }) => {
    // get options
    const options = selectedVariant.version.isOutdated && snapshot ? snapshot.options : selectedVariant.options;

    // get inventories
    const inventory = getOr(null, 'inventory', selectedVariant);

    // keep only active options in active groups
    const activeOptions = run(
        'activeOptions',
        () => {
            if (!useOptions) {
                return [];
            }

            return options.filter(option => option?.isActive && option?.group?.isActive);
        },
        [options, useOptions]
    );

    // map option by their version ID
    const optionMap = run('mapOptions', () => keyBy('version.id', activeOptions), [activeOptions]);
    // then map groups with their option sorted
    const groupMap = run('getGroups', () => mapCarOptions(activeOptions), [activeOptions]);
    // and sort groups in an array
    const groups = run('sortGroups', () => sortBy(['order'], Object.values(groupMap)), [groupMap]);
    // and also mapped inventories
    const inventories = run('getInventories', () => mapInventories(inventory, optionMap), [inventory]);

    // then get selected options
    const currentOptions = getOr([], 'carOptions', values);
    const selectedOptions = run(
        'selectedOptions',
        () =>
            flow([
                Object.keys,
                map(group => [group, find(option => option.groupId === group, currentOptions)]),
                Object.fromEntries,
            ])(groupMap),
        [groupMap, currentOptions]
    );

    // get the selected signature for the option
    const stocks = run(
        'stocks',
        () => {
            // sort options
            const sortedCurrentOptions = sortBy(['group.order'], currentOptions);
            // generate the unique signature
            const signature = generateSignature(sortedCurrentOptions);

            // check if inventory management is inactive
            if (!get(`${signature}.isActive`, inventories)) {
                // we don't need to do anything about that
                return null;
            }

            return getOr(0, `${signature}.remainingStock`, inventories);
        },
        [currentOptions, inventories]
    );

    return run(
        'carOptions',
        () => ({
            all: activeOptions,
            selected: selectedOptions,
            groups,
            inventories,
            stocks,
        }),
        [activeOptions, selectedOptions, groups, inventories, stocks]
    );
};

export default computeCarOptions;
