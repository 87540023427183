import styled from 'styled-components';
import BoxedInput from '../input/BoxedInput';

const BoxedDatePicker = styled(BoxedInput)`
    button {
        cursor: pointer;
    }
`;

export default BoxedDatePicker;
