import PropTypes from 'prop-types';
import React from 'react';
import OutlineContainer from './OutlineContainer';

const OutlineWrapper = ({ label, name, children }) => (
    <OutlineContainer>
        {label && <label htmlFor={name}>{label}</label>}
        {children}
    </OutlineContainer>
);

OutlineWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    label: PropTypes.string,
    name: PropTypes.string,
};

export default OutlineWrapper;
