import { flow, get, uniq, map, compact } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { BookingStatus } from '../../../../../schema';
import { bookingStatuses } from '../../../../../shared/constants/options';
import MarkdownField from '../../../../shared/form/MarkdownField';
import TextareaField from '../../../../shared/form/TextareaField';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import LocationField, { LocationManager } from '../../../../template/LocationField';
import ApvPortlet from '../../../../template/Portlet';
import TextField from '../../../../template/TextField';
import { useFormContext } from './context';

const getLocations = flow([
    map(get('details.location')),
    uniq,
    compact,
    map(location => ({ value: location, label: location })),
]);

const MainDetails = () => {
    const { disabled, values } = useFormContext();
    const { formatPath } = useContentTranslation();

    const initialUnits = get('inventorySet.units', values);

    const initLocation = useMemo(() => getLocations(initialUnits), [initialUnits]);

    const bookingStatus = get('inventoryUnit.booking.status', values);

    const state = get('inventoryUnit.state', values);

    const vinDisabled = bookingStatus === BookingStatus.DEDUCTED || bookingStatus === BookingStatus.RESERVED;

    return (
        <ApvPortlet name="Main Details" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Model">
                            <TextField name={formatPath('model.name')} disabled />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Submodel">
                            <TextField name={formatPath('subModel.name')} disabled />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Variant">
                            <TextField name={formatPath('variant.name')} disabled />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Asset Condition">
                            <Field component={InputField} name="variant.assetCondition" disabled />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="ID">
                            <Field component={InputField} name="identifier" disabled />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="No.">
                            <Field component={InputField} name="no" disabled />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="VIN">
                            <Field
                                component={InputField}
                                disabled={disabled || vinDisabled}
                                name="inventoryUnit.details.vin"
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Location">
                            <LocationManager initLocation={initLocation}>
                                <LocationField disabled={disabled} name="inventoryUnit.details.location" />
                            </LocationManager>
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Status">
                            {bookingStatus && bookingStatus !== BookingStatus.MANUALLYDEDUCTED ? (
                                <Field component={InputField} name="inventoryUnit.booking.status" disabled />
                            ) : (
                                <Field
                                    component={DropdownField}
                                    disabled={disabled}
                                    name="inventoryUnit.state"
                                    options={bookingStatuses}
                                />
                            )}
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Application ID">
                            {state === BookingStatus.MANUALLYDEDUCTED ? (
                                <Field component={InputField} name="inventoryUnit.applicationIdentifier" />
                            ) : (
                                <Field
                                    component={InputField}
                                    name="inventoryUnit.booking.application.identifier"
                                    disabled
                                />
                            )}
                        </FieldContainer>
                        <FieldContainer label="Last Modified">
                            <Field component={InputField} name="lastModified" disabled />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Description">
                            <TextareaField disabled={disabled} name={formatPath('inventoryUnit.details.description')} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Features">
                            <MarkdownField
                                autoFocus={false}
                                disabled={disabled}
                                name={formatPath('inventoryUnit.details.features')}
                            />
                        </FieldContainer>
                    </div>
                </div>
            </div>
        </ApvPortlet>
    );
};

export default MainDetails;
