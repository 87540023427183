import { OutlineNumericInput as NumericInput } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { Unit } from '../../../fields/schema';
import { validateOrSetError } from '../../../utils';
import { useCalculatorContext } from '../../CalculatorContext';

const UpdateDual = ({
    initialValue,
    valueRef,
    compute,
    currency,
    min,
    max,
    unit = Unit.PERCENTAGE,
    step,
    enablePercentage,
    validation,
    setError,
}) => {
    // create a state to update the value
    const [value, setValue] = useState(initialValue);
    const { amount, percentage } = value;

    // get the payment term configuration
    const { formats = {} } = useCalculatorContext();
    const { currencyPrecision, percentagePrecision, fixedDecimalScale, thousandSeparator } = formats;

    useEffect(() => {
        // update the value on the reference
        // eslint-disable-next-line no-param-reassign, react/prop-types
        valueRef.current = value;

        if (validation) {
            // execute yup validation
            validateOrSetError(validation, unit === Unit.PERCENTAGE ? value.percentage : value.amount, setError);
        }
    }, [valueRef, value, setError, unit, validation]);

    // callback to change percentage
    const setPercentage = useCallback(
        newPercentage => {
            setValue({ amount: compute.fromPercentage(newPercentage), percentage: newPercentage });
        },
        [compute]
    );

    // callback to change amount
    const setAmount = useCallback(
        newAmount => {
            setValue({ amount: newAmount, percentage: compute.fromAmount(newAmount) });
        },
        [compute]
    );

    // callback to reset
    const reset = useCallback(() => setValue({ amount: 0, percentage: 0 }), [setValue]);

    return (
        <>
            <NumericInput
                fixedDecimalScale={fixedDecimalScale}
                onChange={setAmount}
                precision={currencyPrecision}
                prefix={currency}
                reset={reset}
                thousandSeparator={thousandSeparator}
                value={amount}
                autoFocus
            />
            <NumericInput
                disabled={!enablePercentage}
                fixedDecimalScale={fixedDecimalScale}
                max={max}
                min={min}
                onChange={setPercentage}
                precision={percentagePrecision}
                prefix="%"
                step={step}
                value={percentage}
                autoFocus
            />
        </>
    );
};

UpdateDual.propTypes = {
    compute: PropTypes.shape({
        fromAmount: PropTypes.func.isRequired,
        fromPercentage: PropTypes.func.isRequired,
    }).isRequired,
    currency: PropTypes.string,
    enablePercentage: PropTypes.bool,
    initialValue: PropTypes.shape({ amount: PropTypes.number.isRequired, percentage: PropTypes.number.isRequired }),
    max: PropTypes.number,
    min: PropTypes.number,
    setError: PropTypes.func.isRequired,
    step: PropTypes.number,
    unit: PropTypes.string,
    validation: PropTypes.shape({}),
    valueRef: PropTypes.shape({}).isRequired,
};

export default UpdateDual;
