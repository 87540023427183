import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCountry, getDialingCodes, getCompany } from '../../../../selectors';
import Page from './Page';

const Creation = () => {
    const { code: countryCode, id: countryId } = useSelector(getCurrentCountry);
    const { id: companyId } = useSelector(getCompany);
    const dialingCodes = useSelector(getDialingCodes) || [];

    const initialValues = useMemo(() => {
        if (!dialingCodes) {
            return null;
        }

        return {
            isActive: false,
            permissions: [{ countryId, companyId }],
            phonePrefix: dialingCodes?.find(item => item.countryCode === countryCode)?.value,
        };
    }, [dialingCodes, countryId, companyId, countryCode]);

    return <Page initialValues={initialValues} />;
};

export default Creation;
