import styled from 'styled-components';

const MonthlyValue = styled.div`
    display: flex;
    flex-direction: ${props => props.direction};
    justify-content: center;

    & > div {
        &:first-child {
            margin-right: 10px;
        }

        span {
            font-size: 0.85rem;
            color: #666666;
            padding-right: 10px;
            padding-left: 3px;
        }
    }
`;

export default MonthlyValue;
