import {
    DatePickerV2 as DatePicker,
    OutlineWrapperV2 as OutlineWrapper,
    PureErrorV2 as PureError,
} from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React, { forwardRef, useMemo, useState } from 'react';
import { Field } from 'redux-form';
import { toDate } from '../../../utilities/fp';
import { DATE_FORMAT } from '../../../utils/DateFormat';
import DateInput from '../../DatePickerField';

export const DatePickerInput = forwardRef((props, ref) => {
    const {
        input,
        inputComponent: DateField = DatePicker,
        wrapperComponent: Wrapper = OutlineWrapper,
        errorComponent: Error = PureError,
        wrapperProps,
        meta,
        label,
        onBlurValue,
        withFocusClear,
        ...inputProps
    } = props;

    const { active, touched, error = null } = meta;
    const hasError = !active && touched && !!error?.trim();

    const { onChange, onFocus: defaultOnFocus, onBlur: defaultOnBlur, value } = input;

    const [lastValue, setLastValue] = useState(value);

    const onFocus = useMemo(() => {
        if (withFocusClear) {
            return () => {
                setLastValue(value);

                return onChange('');
            };
        }

        return defaultOnFocus;
    }, [withFocusClear, onChange, defaultOnFocus, setLastValue, value]);

    const onBlur = useMemo(() => {
        if (withFocusClear) {
            return event => {
                if (event.target.value !== '') {
                    return onChange(event.target.value);
                }

                return onChange(lastValue);
            };
        }

        if (onBlurValue !== undefined) {
            return event => {
                if (event.target.value === '') {
                    return onChange(onBlurValue);
                }

                return defaultOnBlur(event);
            };
        }

        return defaultOnBlur;
    }, [onBlurValue, onChange, defaultOnBlur, lastValue, withFocusClear]);

    const cleanedDate = useMemo(() => toDate(input.value), [input.value]);

    return (
        <div>
            <Wrapper label={label} meta={meta} name={input.name} {...wrapperProps}>
                <DateField {...input} onBlur={onBlur} onFocus={onFocus} {...inputProps} ref={ref}>
                    <DateInput dateFormat={DATE_FORMAT} {...props} onChange={onChange} value={cleanedDate} />
                </DateField>
            </Wrapper>
            {hasError && <Error>{error}</Error>}
        </div>
    );
});

DatePickerInput.displayName = 'DatePickerInput';

DatePickerInput.propTypes = {
    allowedDays: PropTypes.arrayOf(PropTypes.number),
    disabled: PropTypes.bool,
    disabledDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    errorComponent: PropTypes.elementType,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.onFocus,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    }).isRequired,
    inputComponent: PropTypes.elementType,
    label: PropTypes.string,
    max: PropTypes.instanceOf(Date),
    meta: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        error: PropTypes.string,
        touched: PropTypes.bool.isRequired,
    }).isRequired,
    min: PropTypes.instanceOf(Date),
    onBlurValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    withFocusClear: PropTypes.bool,
    wrapperComponent: PropTypes.elementType,
    // be careful when using wrapper properties, this might break optimization on pure components
    wrapperProps: PropTypes.shape({}),
};

const DatePickerField = props => <Field component={DatePickerInput} {...props} />;

export default DatePickerField;
