import { isNil, pick } from 'lodash/fp';
import { CustomerType } from '../../../../../schema';
import { computeDisplayInformation } from '../../../../../utilities/applications/toFormState';
import { prepareForGraphQL } from '../../../../../utilities/forms';
import { pickAndRename } from '../../../../../utilities/fp';

export const getCalculatorFromApplication = application => {
    const { calculator, variant, financeProduct, options, promoCode, appliedForFinancing } = application;

    return {
        isFinancingEnabled: appliedForFinancing ?? false,
        promoCode: promoCode?.identifier || calculator.promoCode,
        totalPrice: !isNil(calculator.price)
            ? calculator.price +
              (calculator.coe ? calculator.coe.amount : 0) +
              (calculator.nzFee ?? 0) +
              (calculator.luxuryTax ? calculator.luxuryTax.amount : 0) +
              options.reduce((acc, item) => acc + item.price, 0)
            : undefined,
        financeProduct: financeProduct?.id,
        variant: variant.id,
        carOptions: options.map(option => option.id) || [],
        bank: financeProduct?.bankId,
        balloon: calculator.balloon || undefined,
        downPayment: calculator.downPayment,
        loan: calculator.loan,
        carPrice: calculator.price,
        tenure: calculator.term,
        interestRate: calculator.interestRate,
        deposit: calculator.deposit,
        mileage: calculator.averageMileage,
        paymentMode: calculator.paymentMode,
        coe: calculator.coe?.amount || 0,
        ppsr: calculator.ppsr?.amount || 0,
        establishment: calculator.establishment?.amount || 0,
        tradeIn: calculator.tradeIn || undefined,
        tradeInEquity: calculator.tradeInEquity || undefined,
        liability: calculator.liability || undefined,
        residualValue: calculator.residualValue || undefined,
        cashPayment: calculator.cashPayment || undefined,
        monthlyInstalments: calculator.monthlyInstalments,
        dealerOptions: calculator.dealerOptions?.amount || 0,
        licensePlateFee: calculator.licensePlateFee || undefined,
        commission: calculator.commission || undefined,
        fixedInterestRate: calculator.fixedInterestRate || undefined,
        licenseAndFuelTax: calculator.licenseAndFuelTax || undefined,
        displacement: calculator.displacement || undefined,
        insuranceFee: calculator.insuranceFee || undefined,
        taxLoss: calculator.taxLoss || undefined,
        totalAmountPayable: calculator.totalAmountPayable || undefined,
        expectedCashPayment: calculator.expectedCashPayment || undefined,
        expectedTradeInAmount: calculator.expectedTradeInAmount || undefined,
    };
};

const getModelData = pickAndRename({
    carModel: 'variant.name',
    carPrice: 'calculator.price',
    carOptions: 'options',
    carMake: 'variant.model.make.name',
});

const getFinanceData = pickAndRename({
    interestRate: 'calculator.interestRate',
    'downPayment.amount': 'calculator.downPayment.amount',
    'downPayment.percentage': 'calculator.downPayment.percentage',
    bank: 'financeProduct.bank.name',
    'loanAmount.amount': 'calculator.loan.amount',
    'balloonPayment.amount': 'calculator.balloon.amount',
    'balloonPayment.percentage': 'calculator.balloon.percentage',
    'deposit.amount': 'calculator.deposit.amount',
    'deposit.percentage': 'calculator.deposit.percentage',
    paymentTerm: 'calculator.term',
    mileage: 'calculator.averageMileage',
    interestOnlyTerms: 'financeProduct.termSetting.interestOnly',
    paymentMode: 'calculator.paymentMode',
    licensePlateFee: 'calculator.licensePlateFee',
    commission: 'calculator.commission',
    fixedInterestRate: 'calculator.fixedInterestRate',
    licenseAndFuelTax: 'calculator.licenseAndFuelTax',
    displacement: 'calculator.displacement',
    'insuranceFee.amount': 'calculator.insuranceFee.amount',
    'insuranceFee.percentage': 'calculator.insuranceFee.percentage',
    taxLoss: 'calculator.taxLoss',
    totalAmountPayable: 'calculator.totalAmountPayable',
    expectedCashPayment: 'calculator.expectedCashPayment',
    expectedTradeInAmount: 'calculator.expectedTradeInAmount',
});

const getMeta = pickAndRename({
    financeProductType: 'financeProduct.type',
    showDownpayment: 'financeProduct.downPaymentSetting.show',
    showTerms: 'financeProduct.termSetting.show',
    showInterestRate: 'financeProduct.interestRateSetting.show',
    showLoanAmount: 'financeProduct.loanSetting.show',
    showDeposit: 'financeProduct.depositSetting.show',
    showStructuredPayment: 'financeProduct.structuredPayment.show',
    showBalloon: 'financeProduct.balloonSetting.show',
    showPaymentMode: 'financeProduct.paymentMode.show',
    balloonType: 'financeProduct.balloonSetting.mode',
});

export const getSnapshotFromApplication = application => {
    const { calculator } = application;
    const coe = calculator.coe?.amount || 0;
    const dealerOptions = calculator.dealerOptions?.amount || 0;
    const ppsr = calculator.ppsr?.amount || 0;
    const nzFee = calculator.nzFee ?? 0;
    const establishment = calculator.establishment?.amount ?? 0;
    const dealerEstablishment = calculator.dealerEstablishment ?? 0;

    const snapshot = {
        isFinancingEnabled: application.appliedForFinancing ?? false,
        variant: application.variant,
        financeProduct: application.financeProduct,
        options: application.options,
        promo: application.promoCode,
        vin: application.booking?.unit?.details?.vin,
        ...getModelData(application),
        ...getFinanceData(application),
        meta: getMeta(application),
        coe,
        ppsr,
        establishment,
        nzFee,
        dealerOptions,
        dealerEstablishment,
    };

    // if subsequentMonthlyPayment is valid, monthlyInstalment should be array
    if (application.calculator.monthlyInstalments?.length > 1) {
        snapshot.monthlyInstalment = application.calculator.monthlyInstalments.map(item => item.amount);
    } else {
        snapshot.monthlyInstalment =
            application.calculator.monthlyInstalments && application.calculator.monthlyInstalments[0].amount;
    }

    // we do not have the percentage for the loan amount in the snapshot
    // so we need to recompute it
    snapshot.loanAmount.percentage = 100 - snapshot.downPayment.percentage;

    return snapshot;
};

const getCalculatorPayload = calculator => {
    const query = {
        paymentMode: calculator.paymentMode,
        balloon: calculator.balloonPayment,
        adjustments: [],
        ...pickAndRename({
            downPayment: 'downPayment',
            loan: 'loan',
            price: 'carPrice',
            term: 'tenure',
            interestRate: 'interestRate',
            deposit: 'deposit',
            averageMileage: 'mileage',
            tradeIn: 'tradeIn',
            tradeInEquity: 'tradeInEquity',
            liability: 'liability',
            residualValue: 'residualValue',
            cashPayment: 'cashPayment',
            monthlyInstalments: 'monthlyInstalments',
            estimatedSurplus: 'estimatedSurplus',
            totalInterestPayable: 'totalInterestPayable',
            licensePlateFee: 'licensePlateFee',
            commission: 'commission',
            fixedInterestRate: 'fixedInterestRate',
            licenseAndFuelTax: 'licenseAndFuelTax',
            displacement: 'displacement',
            insuranceFee: 'insuranceFee',
            taxLoss: 'taxLoss',
            totalAmountPayable: 'totalAmountPayable',
            expectedCashPayment: 'expectedCashPayment',
            expectedTradeInAmount: 'expectedTradeInAmount',
        })(calculator),
        coe: {
            amount: calculator.coe,
        },
        dealerOptions: {
            amount: calculator.dealerOptions,
        },
        ppsr: {
            amount: calculator.ppsr,
        },
        establishment: {
            amount: calculator.establishment,
        },
    };

    return query;
};

export const applyCalculator = ({
    application: state,
    values: calculator,
    variants,
    financeProducts,
    allowOptions,
    formats,
    dispatch,
    change,
    form,
    promo,
    formatDateTime,
    translation,
    selectedOptions,
}) => {
    let variant = variants.find(item => item.id === calculator.variant);
    if (!variant && state.variant.id === calculator.variant) {
        variant = state.variant;
    }

    let financeProduct = financeProducts.find(item => item.id === calculator.financeProduct);
    if (!financeProduct && state.financeProduct.id === calculator.financeProduct) {
        financeProduct = state.financeProduct;
    }

    const { termSetting } = financeProduct;
    const { interestOnly } = termSetting;

    const newApplication = {
        ...state,
        variantId: calculator.variant,
        variant,
        financeProductId: calculator.financeProduct,
        financeProduct,
        optionIds: allowOptions ? calculator.carOptions : [],
        calculator: getCalculatorPayload(calculator),
        promoCode: promo,
        options: Object.values(selectedOptions),
    };

    if (state.initialPromoCode !== calculator.promoCode) {
        dispatch(change(form, 'promoCode', promo));
    }

    dispatch(change(form, 'variant', variant));
    dispatch(change(form, 'financeProduct', financeProduct));
    dispatch(change(form, 'options', allowOptions ? Object.values(selectedOptions) : []));
    dispatch(change(form, 'calculator', getCalculatorPayload(calculator, interestOnly)));
    dispatch(change(form, 'display', computeDisplayInformation(newApplication, formats, formatDateTime, translation)));
};

const getCustomerPayload = customer =>
    prepareForGraphQL(
        pick(
            [
                'title',
                'name',
                'firstName',
                'lastName',
                'email',
                'emailBis',
                'phone',
                'phoneBis',
                'identityNumber',
                'details',
                'dateOfBirth',
            ],
            customer
        )
    );

export const mapUpdateCustomerStateToPayload = customer => ({
    id: customer.id,
    data: getCustomerPayload(customer),
});

export const mapCreateCustomerToPayload = (zoneId, customer) => ({
    data: {
        zoneId,
        type: CustomerType.INDIVIDUAL,
        ...getCustomerPayload(customer),
    },
});
