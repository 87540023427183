import React from 'react';
import { useComparisonContext } from '../../ComparisonContext';
import ConnectedLabelBox from './ConnectedLabelBox';

const MonthlyInstalmentLabelBox = props => {
    // we need to know if there is any deferred product in any calculators
    const { hasDeferredProduct } = useComparisonContext();
    if (hasDeferredProduct) {
        return <ConnectedLabelBox {...props} height="80px !important" />;
    }

    return <ConnectedLabelBox {...props} />;
};

export default MonthlyInstalmentLabelBox;
