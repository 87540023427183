import { get } from 'lodash/fp';
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ReduxFormContext, getFormValues } from 'redux-form';
import SubTitle from '../../../ui/SubTitle';
import DrivingLicense from './DrivingLicense';
import { useFormContext } from './context';

const DrivingLicenseDetails = () => {
    const { sectionPrefix, form } = useContext(ReduxFormContext);
    const { disabled } = useFormContext();
    const valueSelector = useCallback(state => getFormValues(form)(state), [form]);

    const formValues = useSelector(valueSelector) || {};

    // @ts-ignore
    if (!get([sectionPrefix, 'details', 'drivingLicense', 'length'].filter(Boolean), formValues)) {
        return null;
    }

    return (
        <>
            <SubTitle>Driving License</SubTitle>
            <div className="row">
                <DrivingLicense disabled={disabled} />
            </div>
        </>
    );
};

export default DrivingLicenseDetails;
