import styled from 'styled-components';

export const FileContainer = styled.div`
    width: 100%;
    position: relative;
`;

export const Label = styled.label`
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 0;
    color: white;
    background: ${props => (props.disabled ? '#d8d8d8' : '#f0f0f0')};
    min-height: 145px;

    input {
        display: none;
    }

    span {
        color: #000000;
        text-align: center;
    }

    svg {
        font-size: 3.21rem;
        color: #aaa;
    }
`;

export const PreviewImage = styled.img`
    height: auto;
    max-height: 145px;
    max-width: 100%;
    object-fit: contain;
`;

export const CloseIcon = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;

    svg {
        color: #d8d8d8;
        font-size: 1.43rem;
    }
`;

export const Tips = styled.div`
    padding-top: 5px;
    color: #9c9c9c;
    font-size: 0.85rem;
`;
