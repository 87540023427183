import { CalculatorContext } from '@appvantageasia/afc-calculator-ui-next';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as selectors from '../../../../../selectors';
import { getCompanyIdentifier } from '../../../../../selectors';
import useLoadPromo from '../data/useLoadPromo';
import CompanyCalculator, { CompanyCalculatorProps } from './CompanyCalculator';
import { CalculatorValues } from './types';

export type ConnectedCalculatorProps = CompanyCalculatorProps & {
    dealerId: string;
};

const ConnectedCalculator = ({ initialValues, onChange, meta, ...props }: ConnectedCalculatorProps) => {
    const { dealerId } = props;
    const { id: countryId, code: countryCode } = useSelector(selectors.getCurrentCountry);
    const { companyCode } = useSelector(getCompanyIdentifier);

    const changeRef = useRef<CalculatorContext<CalculatorValues>['change']>();

    // the variant may eventually change later
    const [promoCode, setPromoCode] = useState(initialValues.promoCode);

    const promo = useLoadPromo(countryId, dealerId, promoCode);

    // callback to changes on the calculator
    const onChangeEnhanced = useCallback(
        async (context: CalculatorContext<CalculatorValues>) => {
            if (promoCode !== context.values.promoCode) {
                setPromoCode(context.values.promoCode);
            }

            if (!changeRef.current) {
                changeRef.current = context.change;
            }

            if (onChange) {
                onChange(context);
            }
        },
        [promoCode, onChange]
    );

    useEffect(() => {
        // the promoCode is already updated in the calculator
        // this change don't trigger an update
        // so whenever we receive new promo data
        // we need to recalculate again
        if (changeRef.current) {
            changeRef.current('totalPrice', undefined);
        }
    }, [promo]);

    const enhancedMeta = useMemo(() => ({ ...meta, promo, companyCode, countryCode }), [promo, meta]);

    return (
        <CompanyCalculator {...props} initialValues={initialValues} meta={enhancedMeta} onChange={onChangeEnhanced} />
    );
};

export default memo(ConnectedCalculator);
