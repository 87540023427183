import { sortBy, getOr, get } from 'lodash/fp';

const computeVariantProducts = (run, context, variantVersionId) => {
    const fallbackFinanceProduct = get('fallbackFinanceProduct', context);
    const financeProducts = getOr([], 'financeProducts', context);

    return run(
        'computeVariantProducts',
        () => {
            // first filter for our variant
            const filtered = financeProducts.filter(product => product.variants.includes(variantVersionId));

            if (filtered.length === 0 && fallbackFinanceProduct) {
                return [fallbackFinanceProduct];
            }

            // then sort
            return sortBy(['order'], filtered);
        },
        [financeProducts, variantVersionId, fallbackFinanceProduct]
    );
};

export default computeVariantProducts;
