import { get, sortBy, find } from 'lodash/fp';
import { ValidationError } from 'yup';
import { Unit } from './fields/schema';

export const getDefaultDualValues = (baseValue, value, unit) => {
    if (baseValue === undefined || value === undefined) {
        // we do not have enough for it
        return undefined;
    }

    switch (unit) {
        case Unit.PERCENTAGE:
            return {
                amount: (baseValue * value) / 100,
                percentage: value,
            };

        case Unit.CURRENCY:
            return {
                amount: value,
                percentage: (value * 100) / baseValue,
            };

        default:
            // meaning we didn't retrieve what we needed
            return undefined;
    }
};

export const getValueFromTable = (table, conditions, sortKey, valueKey = 'value', defaultValue = 0) => {
    // filter first
    const filteredTables = conditions.reduce((acc, condition) => acc.filter(condition), table);
    // then sort
    const sortedTable = sortBy([sortKey], filteredTables);
    // and get values
    const values = sortedTable.map(get(valueKey));

    // get the first non-null values, otherwise get the default value
    return find(Boolean, values) || defaultValue;
};

export const weightFieldAfter = (...names) => fields => {
    const weights = names.map(name => {
        const { weight } = fields[name];

        return weight instanceof Function ? weight(fields) : weight;
    });

    return Math.max(...weights) + 1;
};

export const getRangeOptions = (min, max, step = 1) => {
    const options = [];

    for (let value = min; value <= max; value += step) {
        options.push({ value, label: value.toString() });
    }

    return options;
};

export const validateOrSetError = (validation, value, setError) => {
    try {
        // run the validation
        validation.validateSync(value);
        // we didn't see any errors
        setError(null);
    } catch (error) {
        if (error instanceof ValidationError) {
            setError(error.errors[0]);
        } else {
            // error not handled here
            throw error;
        }
    }
};

export const getMonthlyInstallmentLabel = (interestOnly, terms) => {
    let first = `Month 1 to ${interestOnly}`;
    let second = `Month ${interestOnly + 1} to ${terms}`;

    if (interestOnly === 1) {
        first = 'Month 1';
    }

    if (interestOnly + 1 === terms) {
        second = `Month ${terms}`;
    }

    const divisible = interestOnly % 12 === 0 && terms % 12 === 0;
    if (divisible) {
        const interestOnlyYear = interestOnly / 12;
        const totalYear = terms / 12;

        first = `Year 1 to ${interestOnlyYear}`;
        if (interestOnlyYear === 1) {
            first = 'Year 1';
        }

        second = `Year ${interestOnlyYear + 1} to ${totalYear}`;
        if (interestOnlyYear + 1 === totalYear) {
            second = `Year ${totalYear}`;
        }
    }

    return [first, second];
};
