import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Background from '../ui/Background';
import ErrorCard from '../ui/ErrorCard';
import ErrorMessage from './ErrorMessage';

class RootErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = { error: null };
    }

    componentDidCatch(error) {
        // print it in console
        console.error(error);
        // update react state
        this.setState({ error });
        // also give it to sentry
        Sentry.captureException(error);
    }

    goBack = () => {
        const { history } = this.props;

        // reset the state
        this.setState({ error: null });
        // move back to homepage
        history.go('/');
    };

    render() {
        const { error } = this.state;
        const { children } = this.props;

        if (error) {
            // render error view
            return (
                <>
                    <Background />
                    <ErrorCard>
                        <h1>
                            <FontAwesomeIcon icon={faExclamationTriangle} /> Something&apos;s wrong here...
                        </h1>
                        <ErrorMessage />
                        <div className="text-right">
                            <button className="btn btn-default" onClick={this.goBack} type="button">
                                go back to the application
                            </button>
                        </div>
                    </ErrorCard>
                </>
            );
        }

        return children;
    }
}

RootErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
    history: PropTypes.shape({
        go: PropTypes.func.isRequired,
    }).isRequired,
};

export default withRouter(RootErrorBoundary);
