/* eslint-disable */
import React from 'react';

const isIE =
    window &&
    (window.navigator.userAgent.indexOf('MSIE') !== -1 || window.navigator.appVersion.indexOf('Trident/') > 0);

class FileDrop extends React.PureComponent {
    static eventHasFiles(event) {
        // In most browsers this is an array, but in IE11 it's an Object :(
        let hasFiles = false;
        const { types } = event.dataTransfer;

        for (const type of types) {
            if (type === 'Files') {
                hasFiles = true;
                break;
            }
        }

        return hasFiles;
    }

    frameDragCounter;

    constructor(props) {
        super({
            frame: window ? window.document : undefined,
            ...props,
        });

        this.frameDragCounter = 0;
        this.state = { draggingOverFrame: false, draggingOverTarget: false };
    }

    componentWillReceiveProps(nextProps) {
        const { frame } = this.props;

        if (nextProps.frame !== frame) {
            this.resetDragging();
            this.stopFrameListeners(frame);
            this.startFrameListeners(nextProps.frame);
        }
    }

    componentDidMount() {
        const { frame } = this.props;

        this.startFrameListeners(frame);
        this.resetDragging();

        window.addEventListener('dragover', this.handleWindowDragOverOrDrop);
        window.addEventListener('drop', this.handleWindowDragOverOrDrop);
    }

    componentWillUnmount() {
        const { frame } = this.props;

        this.stopFrameListeners(frame);

        window.removeEventListener('dragover', this.handleWindowDragOverOrDrop);
        window.removeEventListener('drop', this.handleWindowDragOverOrDrop);
    }

    resetDragging = () => {
        this.frameDragCounter = 0;
        this.setState({ draggingOverFrame: false, draggingOverTarget: false });
    };

    handleWindowDragOverOrDrop = event => {
        // This prevents the browser from trying to load whatever file the user dropped on the window
        event.preventDefault();
    };

    handleFrameDrag = event => {
        // Only allow dragging of files
        if (!FileDrop.eventHasFiles(event)) {
            return;
        }

        const { onFrameDragEnter, onFrameDragLeave } = this.props;

        // We are listening for events on the 'frame', so every time the user drags over any element in the frame's tree,
        // the event bubbles up to the frame. By keeping count of how many "dragenters" we get, we can tell if they are still
        // "draggingOverFrame" (b/c you get one "dragenter" initially, and one "dragenter"/one "dragleave" for every bubble)
        // This is far better than a "dragover" handler, which would be calling `setState` continuously.
        this.frameDragCounter += event.type === 'dragenter' ? 1 : -1;

        if (this.frameDragCounter === 1) {
            this.setState({ draggingOverFrame: true });
            if (onFrameDragEnter) {
                onFrameDragEnter(event);
            }

            return;
        }

        if (this.frameDragCounter === 0) {
            this.setState({ draggingOverFrame: false });
            if (onFrameDragLeave) {
                onFrameDragLeave(event);
            }
        }
    };

    handleFrameDrop = event => {
        const { onFrameDrop } = this.props;
        const { draggingOverTarget } = this.state;

        if (!draggingOverTarget) {
            this.resetDragging();

            if (onFrameDrop) {
                onFrameDrop(event);
            }
        }
    };

    handleDragOver = event => {
        const { onDragOver, dropEffect } = this.props;

        if (FileDrop.eventHasFiles(event)) {
            this.setState({ draggingOverTarget: true });

            if (!isIE) {
                event.dataTransfer.dropEffect = dropEffect;
            }

            if (onDragOver) {
                onDragOver(event);
            }
        }
    };

    handleDragLeave = event => {
        const { onDragLeave } = this.props;

        this.setState({ draggingOverTarget: false });

        if (onDragLeave) {
            onDragLeave(event);
        }
    };

    handleDrop = event => {
        const { onDrop } = this.props;

        if (onDrop && FileDrop.eventHasFiles(event)) {
            const files = event.dataTransfer ? event.dataTransfer.files : null;
            onDrop(files, event);
        }

        this.resetDragging();
    };

    stopFrameListeners = frame => {
        frame?.removeEventListener('dragenter', this.handleFrameDrag);
        frame?.removeEventListener('dragleave', this.handleFrameDrag);
        frame?.removeEventListener('drop', this.handleFrameDrop);
    };

    startFrameListeners = frame => {
        frame?.addEventListener('dragenter', this.handleFrameDrag);
        frame?.addEventListener('dragleave', this.handleFrameDrag);
        frame?.addEventListener('drop', this.handleFrameDrop);
    };

    render() {
        const { draggingOverFrame, draggingOverTarget } = this.state;
        const { className, children } = this.props;

        let fileDropTargetClassName = 'file-drop-target';

        if (draggingOverFrame) {
            fileDropTargetClassName += ' file-drop-dragging-over-frame';
        }
        if (draggingOverTarget) {
            fileDropTargetClassName += ' file-drop-dragging-over-target';
        }

        return (
            <div
                className={`file-drop ${className}`}
                onDragLeave={this.handleDragLeave}
                onDragOver={this.handleDragOver}
                onDrop={this.handleDrop}
            >
                <div className={fileDropTargetClassName}>{children}</div>
            </div>
        );
    }
}

export default FileDrop;
