import deepEqual from 'fast-deep-equal';
import { omit, get, keyBy, set, pick, identity, isNil } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { fieldMetaPropTypes, fieldInputPropTypes } from 'redux-form';
import { ErrorMessageDiv, Table, TableContainer } from '../containers/Layout';
import NumberField from './template/Number';

export const createColumns = settings => {
    const min = parseInt(settings.min, 10);
    const max = parseInt(settings.max, 10);
    const step = parseInt(settings.step, 10);

    if (!Number.isNaN(min) && max && min === max) {
        return [{ label: min, value: min }];
    }

    if (Number.isNaN(min) || !max || !step) {
        return [];
    }

    if (min > max || step <= 0 || min < 0) {
        return [];
    }

    const columns = [];
    for (let i = min; i <= max; i += step) {
        columns.push({ label: i, value: i });
    }

    return columns;
};

export const useColumns = (settings, columnProps) =>
    useMemo(() => columnProps ?? createColumns(settings), [settings, columnProps]);

const defaultKeyGenerator = object =>
    Object.entries(object)
        .sort((a, b) => {
            if (a[0] < b[0]) {
                return -1;
            }

            return a[0] > b[0] ? 1 : 0;
        })
        .map(([key, value]) => `${key}=${value}`)
        .join('+');

const TableField = ({
    input,
    meta,
    settings,
    disabled,
    filter = null,
    rows,
    header,
    columnKey,
    valueKey = 'value',
    generateKey = defaultKeyGenerator,
    precision = 0,
    suffix = null,
    columns: columnsProps = null,
}) => {
    const { error, touched, active } = meta;
    const { value: initialValues = [], onChange: change } = input;

    const reduxValues = useMemo(() => {
        return keyBy(entry => generateKey(omit([valueKey, '__typename'], entry)), initialValues);
    }, [initialValues, generateKey, valueKey]);

    // this field work with a local state for the values
    // only initial a map from initial values
    const [values, setValues] = useState(reduxValues);

    // first provide a unique key for each row
    const rowsWithKey = useMemo(() => {
        return rows.map(row => ({ ...row, key: generateKey(omit([valueKey], row.values || {})) || row.label }));
    }, [generateKey, rows, valueKey]);

    // generate columns
    const columns = useColumns(settings, columnsProps);

    // create cells for each row
    const computedRows = useMemo(
        () =>
            rowsWithKey.map(row => ({
                ...row,
                columns: columns.map(column => {
                    const cellValues = { ...row.values, [columnKey]: column.value };

                    return { values: cellValues, key: generateKey(omit([valueKey], cellValues)) };
                }),
            })),
        [rowsWithKey, columns, columnKey, generateKey, valueKey]
    );

    // callback to update a value
    const onChange = useCallback(
        (cell, value) =>
            setValues(state =>
                set(
                    [cell.key],
                    {
                        ...cell.values,
                        [valueKey]: value,
                    },
                    state
                )
            ),
        [valueKey]
    );

    // use an immutable variable to keep track of the current values
    // we don't want to trigger the effect whenever inner value changes
    const latestValueRef = useRef(values);

    // apply changes into redux state
    useEffect(() => {
        if (!deepEqual(latestValueRef.current, values)) {
            const keys = computedRows.flatMap(row => row.columns.map(cell => cell.key));
            const cleanedValues = Object.values(pick(keys, values)).filter(value => !isNil(get(valueKey, value)));
            latestValueRef.current = values;
            change(cleanedValues);
        }
    }, [change, values, latestValueRef, computedRows, valueKey]);

    // effect responsible to update the state whenever redux state changes
    useEffect(() => {
        if (!deepEqual(latestValueRef.current, reduxValues)) {
            latestValueRef.current = reduxValues;
            setValues(reduxValues);
        }
    }, [reduxValues, setValues, latestValueRef]);

    // rows may eventually be filtered
    const filteredRows = useMemo(() => {
        if (!filter) {
            return computedRows;
        }

        return filter(computedRows);
    }, [filter, computedRows]);

    const dummyMeta = useMemo(() => ({ ...meta, error: null }), [meta]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <TableContainer>
                        <Table>
                            <thead>
                                <tr>
                                    <th>{header}</th>
                                    {columns.map(column => (
                                        <th key={column.value}>{column.label}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredRows.map(row => (
                                    <tr key={row.key}>
                                        <td>{row.label}</td>
                                        {row.columns.map(cell => {
                                            return (
                                                <td key={cell.key}>
                                                    <NumberField
                                                        disabled={disabled}
                                                        input={{
                                                            name: cell.key,
                                                            type: 'text',
                                                            value: get([cell.key, valueKey], values),
                                                            onChange: value => onChange(cell, value),
                                                            onBlur: identity,
                                                            onDragStart: identity,
                                                            onDrop: identity,
                                                            onFocus: identity,
                                                        }}
                                                        meta={dummyMeta}
                                                        precision={precision}
                                                        suffix={suffix}
                                                        noZeroOnBlur
                                                        withFocus
                                                    />
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <ErrorMessageDiv>{touched && !active && error}</ErrorMessageDiv>
        </div>
    );
};

TableField.propTypes = {
    columnKey: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
    ),
    disabled: PropTypes.bool,
    filter: PropTypes.func,
    generateKey: PropTypes.func,
    header: PropTypes.string.isRequired,
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
    precision: PropTypes.number,
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            values: PropTypes.shape({}),
        }).isRequired
    ).isRequired,
    settings: PropTypes.shape({
        max: PropTypes.number,
        min: PropTypes.number,
        step: PropTypes.number,
    }).isRequired,
    suffix: PropTypes.string,
    valueKey: PropTypes.string,
};

export default TableField;
