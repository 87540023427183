import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const CurrencyFieldDiv = styled.div`
    width: 100%;
    padding: 9px 0;

    input {
        width: 100%;
        border: 0;
        color: ${props => props.theme.themeHighlightColour};
        background: #ffffff;
        height: 18px;
        font-size: 14px;
        padding-left: 10px;
        text-align: end;

        &:focus {
            outline: none;
        }
    }

    input[type='text']:disabled {
        color: #000000;
        opacity: 1;
    }
`;

const CurrencyField = ({ children, ...props }) => (
    <CurrencyFieldDiv>
        {/* This is the input component containing the logic for parsing the Currency */}
        {React.cloneElement(children, props)}
    </CurrencyFieldDiv>
);

CurrencyField.propTypes = {
    children: PropTypes.node.isRequired,
};

export default CurrencyField;
