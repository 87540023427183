import styled from 'styled-components';
import Button from './Button';

const OutlineButton = styled(Button)`
    color: ${props => props.theme.calculator.labelColor};
    border: 0.063rem solid ${props => props.theme.calculator.labelColor};
    background-color: #fff;

    &:disabled {
        cursor: not-allowed;
        color: #7e7e7e;
        opacity: 1;
    }
`;

export default OutlineButton;
