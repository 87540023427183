import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { getGlobalPermissions } from '../../selectors';
import {
    UserLists,
    RoleLists,
    HierarchyGrid,
    RoleCreation,
    RoleEdition,
    CreateUser,
    EditUser,
    EditHierarchy,
} from '../routes/Users';

const AccessRouter = () => {
    const {
        mayViewUsers,
        mayManageUsers,
        mayViewRoles,
        mayManageRoles,
        mayManageRolesHierarchy,
        mayViewRolesHierarchy,
    } = useSelector(getGlobalPermissions);

    return (
        <Switch>
            {mayViewUsers && <Route component={UserLists} path="/access/users" exact />}
            {mayManageUsers && <Route component={CreateUser} path="/access/users/new" exact />}
            {mayViewUsers && <Route component={EditUser} path="/access/users/:id" />}
            {mayViewRoles && <Route component={RoleLists} path="/access/roles" exact />}
            {mayManageRoles && <Route component={RoleCreation} path="/access/roles/new" exact />}
            {mayViewRoles && <Route component={RoleEdition} path="/access/roles/:id" />}
            {mayViewRolesHierarchy && <Route component={HierarchyGrid} path="/access/hierarchies" exact />}
            {mayManageRolesHierarchy && <Route component={EditHierarchy} path="/access/hierarchies/:id" />}
            <Redirect to="/home" />
        </Switch>
    );
};

export default AccessRouter;
