import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useMenuLinks from '../../utilities/useMenuLinks';
import MenuLink from './MenuLink';
import MenuLogo from './MenuLogo';
import { MenuContainer, MenuLinkWrapper, LogoContainer } from './ui';

const MenuLinks = ({ fixedMenu, toggleMenu }) => {
    // filter links
    const links = useMenuLinks();

    const currentRoute = useLocation();

    const menuLinksElement = useMemo(
        () => (
            <MenuContainer fixedMenu={fixedMenu}>
                {links.map(({ link, icon, label, key }) => (
                    <MenuLink
                        key={key}
                        className={link?.includes(currentRoute?.pathname) && 'highlight'}
                        icon={icon}
                        label={label}
                        to={link}
                    />
                ))}
            </MenuContainer>
        ),
        [currentRoute, fixedMenu, links]
    );

    return (
        <MenuLinkWrapper fixedMenu={fixedMenu}>
            <LogoContainer>
                <MenuLogo fixedMenu={fixedMenu} toggleMenu={toggleMenu} />
            </LogoContainer>
            {menuLinksElement}
        </MenuLinkWrapper>
    );
};

MenuLinks.propTypes = {
    fixedMenu: PropTypes.bool.isRequired,
    toggleMenu: PropTypes.func.isRequired,
};

export default MenuLinks;
