import { orderBy } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ErrorMessageDiv } from '../../containers/Layout';
import FilteredMultiSelectAdd from './FilteredMultiSelectAdd';
import FilteredMultiSelectRemove from './FilteredMultiSelectRemove';

class MultiSelectPoolField extends Component {
    constructor(props) {
        super(props);

        this.state = { leftOptions: [], rightOptions: [] };
    }

    static getDerivedStateFromProps(props) {
        const { input, options } = props;

        const values = Array.isArray(input.value) ? input.value : [];
        const selectedOptions = Object.fromEntries(values.map(value => [value, true]));

        const leftOptions = options.filter(option => !selectedOptions[option.value]);
        const rightOptions = options.filter(option => selectedOptions[option.value]);

        return { leftOptions, rightOptions, selectedOptions };
    }

    handleRemove = options => this.updateValue(options, false);

    handleAdd = options => this.updateValue(options, true);

    updateValue(options, state) {
        const { selectedOptions: previousSelectedOptions } = this.state;
        const { options: allOptions, input } = this.props;

        // clone the object to allow mutations
        const selectedOptions = { ...previousSelectedOptions };

        // mutate it with the asked changes
        options.forEach(option => {
            selectedOptions[option.value] = state;
        });

        // update redux form value
        const value = allOptions.filter(option => selectedOptions[option.value]).map(option => option.value);

        // apply changes in redux state
        input.onChange(value);
    }

    render() {
        const { meta, poolLabel, selectionLabel, className, disabled } = this.props;
        const { leftOptions, rightOptions } = this.state;
        const { error, touched, active } = meta;

        return (
            <div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FilteredMultiSelectAdd
                            className={className}
                            disabled={disabled}
                            label={poolLabel}
                            onChange={this.handleAdd}
                            options={orderBy(['label'], ['asc'], leftOptions)}
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FilteredMultiSelectRemove
                            className={className}
                            disabled={disabled}
                            label={selectionLabel}
                            onChange={this.handleRemove}
                            options={orderBy(['label'], ['asc'], rightOptions)}
                        />
                    </div>
                </div>
                <ErrorMessageDiv>{touched && !active && error}</ErrorMessageDiv>
            </div>
        );
    }
}

MultiSelectPoolField.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    input: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
        value: PropTypes.arrayOf(PropTypes.string.isRequired),
    }).isRequired,
    meta: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        error: PropTypes.string,
        touched: PropTypes.bool.isRequired,
    }).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    poolLabel: PropTypes.string.isRequired,
    selectionLabel: PropTypes.string.isRequired,
};

export default MultiSelectPoolField;
