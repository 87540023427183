import { getOr } from 'lodash/fp';
import { getLocationCode } from '../utilities/urls';

export const getContext = state => state.context;

export const getCompany = state => getContext(state)?.company;

export const getCurrentCountry = state => getContext(state)?.country;

export const getCurrentZone = state => getContext(state)?.zone;

export const getCurrentDealer = state => getContext(state)?.dealer;

export const getDefaultZone = state => getContext(state)?.defaultZone;

export const getCompanyCode = state => getContext(state)?.companyCode;

export const getCountryCode = state => getContext(state)?.countryCode;

export const getZones = state => getOr([], 'zones', getCurrentCountry(state));

export const getZoneCode = state => getContext(state)?.zoneCode;

export const getDefaultZoneCode = state => getContext(state)?.defaultZoneCode;

export const getCompanyId = state => getCompany(state)?.id;

export const getZoneId = state => getCurrentZone(state)?.id;

export const getCountryId = state => getCurrentCountry(state)?.id;

export const getDealerId = state => getCurrentDealer(state)?.id;

export const getPorschePaymentAssortmentMandatory = state =>
    getCurrentCountry(state)?.isPorschePaymentAssortmentMandatory;

export const getCompanyIdentifier = state => {
    const identifier = {
        // ids
        countryId: getCountryId(state),
        companyId: getCompanyId(state),
        zoneId: getZoneId(state),
        // codes
        companyCode: getCompanyCode(state),
        countryCode: getCountryCode(state),
        zoneCode: getZoneCode(state),
        defaultZoneCode: getDefaultZoneCode(state),
    };

    // build location code
    identifier.locationCode = getLocationCode(identifier.countryCode, identifier.zoneCode);

    return identifier;
};

export const getUser = state => getContext(state).user;

export const getCompanyApiIdentifier = state => ({
    companyId: getCompanyId(state),
    countryId: getCountryId(state),
    zoneId: getZoneId(state),
    countryCode: getCountryCode(state),
});

export const getCurrentUserCountryDealers = state => {
    const countryId = getCountryId(state);
    const user = getUser(state);

    const { permissions: userPermissions, isSuperUser } = user;

    if (isSuperUser) {
        // no dealers for super user
        return [];
    }

    // get available permission based on selected country
    const availablePermission = userPermissions.find(permission => permission.countryId === countryId);

    if (!availablePermission) {
        return [];
    }

    return availablePermission.dealerIds;
};
