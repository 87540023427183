import styled, { css } from 'styled-components';

const Input = styled.input`
    width: 100%;
    border: 0;
    color: #000000;
    background: #f0f0f0;
    height: 30px;
    font-size: 0.85rem;
    padding: 0 10px;
    text-align: ${props => props.textAlign};

    ${props =>
        props.inTable &&
        css`
            margin: 5px 0;
            border: 0;
            color: #000000;
            padding-left: 10px;
            background: #cacaca;
            height: 30px;
            font-size: 0.85rem;
        `}

    &:disabled {
        background: #d8d8d8;
    }
`;

export default Input;
