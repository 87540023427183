import { subYears } from 'date-fns';
import { getOr } from 'lodash/fp';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReduxFormContext } from 'redux-form';
import { MaskDirection } from '../../../../../schema';
import { getRuntimeSettings } from '../../../../../selectors';
import { getLastModified } from '../../../../../utilities/forms';
import DatePickerField from '../../../../shared/form-v2/DatePickerField';
import SelectField from '../../../../shared/form-v2/SelectField';
import TextField from '../../../../shared/form-v2/TextField';
import useFormatDateTime from '../../../../shared/useFormatDateTime';
import SubTitle from '../../../../ui/SubTitle';
import usePhone from '../../../../utilities/usePhone';
import useCustomerSourceOptions from '../../useCustomerSourceOptions';
import { useFormContext } from '../context';

const CustomerDetails = () => {
    const { getValues } = useContext(ReduxFormContext);
    const formValues = getValues();
    const { disabled, mask, options, allowPrimaryInfoChanges } = useFormContext();
    const countryCode = getOr('', 'zone.country.code', formValues);

    // get runtime settings
    const { useCustomerBirthDay } = useSelector(getRuntimeSettings);
    const withFocusClear = useMemo(() => (mask ? mask.direction !== MaskDirection.NONE : false), [mask]);
    const minDateOfBirth = useMemo(() => subYears(new Date(), 18), []);
    const { code: phoneCountryCode } = usePhone(countryCode);

    const formatDateTime = useFormatDateTime();
    const lastModified = getLastModified(formValues.version, formatDateTime);

    const customerSourceOptions = useCustomerSourceOptions();

    return (
        <>
            <SubTitle>Customer Details</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label="Title"
                        name="title.value"
                        options={getOr([], 'title', options)}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label="First Name"
                        name="firstName.value"
                        withFocusClear={withFocusClear}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label="Last Name"
                        name="lastName.value"
                        withFocusClear={withFocusClear}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label="Email"
                        name="email.value"
                        withFocusClear={withFocusClear}
                    />
                </div>

                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label="Mobile No."
                        name="phone.value"
                        prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                        withFocusClear={withFocusClear}
                        disabled
                    />
                </div>
                {useCustomerBirthDay && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DatePickerField label="Date of Birth" max={minDateOfBirth} name="dateOfBirth.value" disabled />
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label="Nationality"
                        name="details.nationality.value"
                        options={getOr([], 'nationalities', options)}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label="Gender"
                        name="details.gender.value"
                        options={getOr([], 'genders', customerSourceOptions)}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label="Marital Status"
                        name="details.maritalStatus.value"
                        options={getOr([], 'maritalStatuses', options)}
                        sort={false}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label="Education"
                        name="details.education.value"
                        options={getOr([], 'educations', options)}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField fixedValue={lastModified} label="Last Modified" name="__exclude.lastModified" disabled />
                </div>
            </div>
        </>
    );
};

export default CustomerDetails;
