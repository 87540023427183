import { get } from 'lodash/fp';
import { EventExternalSite } from '../../../../../schema';
import * as validators from '../../../../../utils/validators';

const validate = formatPath =>
    validators.compose(
        validators.requiredString('name'),
        validators.requiredStringArray('dealerIds'),
        validators.requiredDate('period.start'),
        validators.startDateMayNotExceedEndDate('period.start', 'period.end'),
        validators.requiredString('period.startTimeZone'),
        validators.requiredDate('period.end'),
        validators.requiredBoolean('isActive'),
        validators.requiredString('leadOrigin'),
        validators.requiredString('medium'),
        validators.requiredString('defaultSalesPersonId'),
        validators.requiredBoolean('setting.isDepositPaymentMandatory'),
        validators.only(
            values => get('setting.externalSite', values) !== EventExternalSite.MARKETINGRECONSENT,
            validators.compose(
                validators.requiredString(formatPath('edm.subject')),
                validators.requiredString(formatPath('edm.introTitle')),
                validators.requiredString(formatPath('edm.contentText'))
            )
        )
    );

export default validate;
