export default (state = 0, action) => {
    const { type } = action;

    switch (type) {
        case 'LOADING_BEGIN':
            return state + 1;

        case 'LOADING_END':
            return state - 1;

        default:
            return state;
    }
};
