import { getHours, getMinutes, parse, setHours, setMinutes } from 'date-fns';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TimeKeeper from 'react-timekeeper';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import styled from 'styled-components';
import { ErrorMessageDiv } from '../../containers/Layout';

const InputFieldDiv = styled.div`
    width: 100%;
    position: relative;

    .display-value {
        width: 100% !important;
        color: #000000;
        background: ${props => (props.disabled ? '#d8d8d8' : '#f0f0f0')};
        height: 30px;
        line-height: 30px;
        font-size: 0.85rem;
        padding-left: 10px;
    }

    .time-picker-overlay {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
    }

    .time-picker {
        position: absolute;
        top: 32px;
        left: 0;
        z-index: 100;
    }
`;

const pad = value => {
    const text = `00${value}`;

    return text.substr(text.length - 2);
};

class TimePickerField extends Component {
    constructor(props) {
        super(props);

        this.state = { isShowTimePicker: false, value: null, formattedValue: null };
    }

    static getDerivedStateFromProps({ input }) {
        const { value } = input;
        const date = value instanceof Date ? value : parse(value);

        return {
            value: date,
            formattedValue: `${pad(getHours(date))}:${pad(getMinutes(date))}`,
        };
    }

    onChange = ({ minute, hour24 }) => {
        const { value } = this.state;
        const { input } = this.props;

        input.onChange(setHours(setMinutes(value, minute), hour24));
    };

    showTimePicker = () => {
        const { disabled } = this.props;

        if (!disabled) {
            this.setState({ isShowTimePicker: true });
        }
    };

    hideTimePicker = () => this.setState({ isShowTimePicker: false });

    render() {
        const { meta, disabled } = this.props;
        const { isShowTimePicker, formattedValue } = this.state;
        const { error, touched, active } = meta;

        return (
            <InputFieldDiv disabled={disabled}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div className="display-value" onClick={this.showTimePicker}>
                    <span>{formattedValue}</span>
                </div>
                {isShowTimePicker && (
                    <>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                        <div className="time-picker-overlay" onClick={this.hideTimePicker} />
                        <div className="time-picker">
                            <TimeKeeper onChange={this.onChange} time={formattedValue} hour24Mode />
                        </div>
                    </>
                )}
                <ErrorMessageDiv>{touched && !active && error}</ErrorMessageDiv>
            </InputFieldDiv>
        );
    }
}

TimePickerField.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};

export default TimePickerField;
