import { useMemo } from 'react';
import { InsuranceApplicationStatus } from '../../../../../../schema';
import useFormDirty from '../../../common/shared/useFormDirty';
import useFormChanged from '../../../common/utilities/useFormChanged';
import { ApplicationFormValues } from './context';

const allowDirectlySubmitStatus = [
    InsuranceApplicationStatus.SIGNING,
    InsuranceApplicationStatus.SIGNINGREJECTED,
    InsuranceApplicationStatus.SIGNINGTIMEOUT,
    InsuranceApplicationStatus.UNABLETOCONNECT,
    InsuranceApplicationStatus.CONNECTIONFAILED,
];

const useButtonStatus = (initialValues: ApplicationFormValues | null, mayManage: boolean, maySubmitChange: boolean) => {
    const hasChanged = useFormChanged('insuranceApplication');
    const hasErrors = useFormDirty('insuranceApplication');

    return useMemo(() => {
        const { status } = initialValues || {};

        const isFormDisabled = !mayManage || !maySubmitChange;

        const canSubmitDirectly = status && allowDirectlySubmitStatus.includes(status);
        const submitChangesDisabled = isFormDisabled || hasErrors || (!canSubmitDirectly && !hasChanged);

        const voidDisabled =
            !mayManage || status === (InsuranceApplicationStatus.CANCELLED || InsuranceApplicationStatus.COMPLETED);
        const approveDisabled = !mayManage || status !== InsuranceApplicationStatus.RECEIVED;
        const declineDisabled = !mayManage || status !== InsuranceApplicationStatus.RECEIVED;

        const saveChangesDisabled = hasErrors;

        return {
            isFormDisabled,
            saveChangesDisabled,
            submitChangesDisabled,
            voidDisabled,
            approveDisabled,
            declineDisabled,
        };
    }, [hasChanged, hasErrors, initialValues, mayManage, maySubmitChange]);
};

export default useButtonStatus;
