import { useQuery } from '@apollo/client';
import { omit } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { getLastModified } from '../../../../utilities/forms';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { getModel } from './Edition.graphql';
import Page from './Page';

const Edition = () => {
    const { id } = useParams();
    const variables = { id };
    const { data, loading } = useQuery(getModel, { variables, fetchPolicy: 'network-only' });

    const formatDateTime = useFormatDateTime();

    const initialValues = useMemo(() => {
        const values = data?.model;

        if (!values) {
            return null;
        }

        return {
            ...omit(['variants', 'subModels'], values),
            lastModified: getLastModified(values.version, formatDateTime),
        };
    }, [data, formatDateTime]);

    if (!loading && !initialValues) {
        // the product does not exist
        return <Redirect to="/vehicle/models" />;
    }

    if (!loading && initialValues?.parentId) {
        return <Redirect to={`/vehicle/subModels/${id}`} />;
    }

    return <Page initialValues={initialValues} subModels={data?.model?.subModels} variants={data?.model?.variants} />;
};

export default Edition;
