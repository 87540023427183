import { useQuery } from '@apollo/client';
import { get, getOr, flow, map, uniqBy, sortBy, groupBy, mapValues, keyBy, set } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getFormSyncErrors } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { getLastModified } from '../../../../../utilities/forms';
import useFormatDateTime from '../../../../shared/useFormatDateTime';
import NumberField from '../../../../template/Number';
import { ListTable } from '../../../../ui/lists';
import { useFormContext } from './context';
import { getInventory } from './data.graphql';

const getGroupsFromOptions = flow([map(get('group')), uniqBy('id'), sortBy('order')]);

const groupOptions = flow([groupBy('group.id'), mapValues(sortBy('order'))]);

const createMatrix = (options, ...nextEntries) =>
    options.flatMap(option => {
        if (nextEntries.length) {
            return createMatrix(...nextEntries).map(otherOptions => {
                return [option, ...otherOptions];
            });
        }

        return [[option]];
    });

const generateKey = options => {
    if (!options.length) {
        return 'noOptions';
    }

    return sortBy(get('group.order'), options)
        .map(option => `${option.group.id}=${option.version.id}`)
        .join('+');
};

const Inventory = () => {
    const { disabled, values, change, form } = useFormContext();
    const { formatPath } = useContentTranslation();
    const variantId = get('variantId', values);
    const variables = { variantId };
    const { data } = useQuery(getInventory, { variables, fetchPolicy: 'network-only' });

    const errors = useSelector(getFormSyncErrors(form))?.sets;

    const cleanVariantPayload = useMemo(() => {
        return getOr([], 'variant.options', data).map(option => {
            return {
                ...option,
                name: get(formatPath('name'), option),
                group: { ...option.group, name: get(formatPath('group.name'), option) },
            };
        });
    }, [data, formatPath]);

    // first keep only active options
    const activeOptions = useMemo(() => {
        return cleanVariantPayload.filter(option => option.isActive && option.group.isActive);
    }, [cleanVariantPayload]);

    // get groups from options (sorted by order)
    const groups = useMemo(() => getGroupsFromOptions(activeOptions), [activeOptions]);

    // get option for each group
    const optionsByGroup = useMemo(() => groupOptions(activeOptions), [activeOptions]);

    // mapped options by version id
    const mappedOptions = useMemo(() => keyBy(get('version.id'), activeOptions), [activeOptions]);

    // create matrix
    const matrix = useMemo(() => {
        const entries = Object.values(optionsByGroup);

        return entries.length
            ? createMatrix(...entries)
            : [
                  [
                      /* provide one entry without option */
                  ],
              ];
    }, [optionsByGroup]);

    const inventory = get('variant.inventory', data);

    const formatDateTime = useFormatDateTime();

    useEffect(() => {
        if (inventory) {
            change('lastModified', getLastModified(inventory.version, formatDateTime));
        } else {
            change('lastModified', null);
        }
    }, [change, data, inventory, formatDateTime]);

    // get initial values from the inventory
    const remoteValues = useMemo(() => {
        if (!inventory) {
            return [];
        }

        const entries = inventory.sets.map(item => {
            // get option
            const itemOptions = item.optionIds.map(versionId => mappedOptions[versionId]).filter(Boolean);

            return [generateKey(itemOptions), item];
        });

        return Object.fromEntries(entries);
    }, [inventory, mappedOptions]);

    const initialRows = useMemo(() => {
        const entries = matrix.map(options => {
            const key = generateKey(options);

            const identifier = inventory?.identifier
                ? `${inventory?.identifier}.${getOr(0, [key, 'identifier'], remoteValues)}`
                : 0;

            return [
                key,
                {
                    key,
                    id: getOr(0, [key, 'id'], remoteValues),
                    identifier,
                    options: keyBy('group.id', options),
                    vinAssigned: getOr(0, [key, 'vins'], remoteValues),
                    initialStock: getOr(0, [key, 'initialStock'], remoteValues),
                    remainingStock: getOr(0, [key, 'remainingStock'], remoteValues),
                    reservedStock: getOr(0, [key, 'reservedStock'], remoteValues),
                    deductedStock: getOr(0, [key, 'deductedStock'], remoteValues),
                },
            ];
        });

        return Object.fromEntries(entries);
    }, [matrix, remoteValues, inventory]);

    const [rows, setRows] = useState([]);

    // reset rows whenever initial rows changed
    useEffect(() => {
        setRows(initialRows);
    }, [initialRows]);

    // apply changes to redux
    useEffect(() => {
        change(
            'sets',
            Object.values(rows).map(row => ({
                optionIds: Object.values(row.options).map(option => option.version.id),
                initialStock: row.initialStock,
                __exclude: row,
            }))
        );
    }, [rows, change]);

    const renderField = useCallback(
        field => (item, index) => (
            <NumberField
                disabled={disabled}
                input={{
                    type: 'text',
                    value: get(field, item),
                    onChange: value => setRows(state => set([item.key, field], value, state)),
                }}
                meta={{ error: get([index, field], errors), touched: true }}
                precision={0}
                inTable
                withFocus
            />
        ),
        [disabled, setRows, errors]
    );
    const history = useHistory();

    // create columns for the table
    const columns = useMemo(() => {
        const initColumns = [
            {
                name: 'ID',
                id: 'identifier',
                renderCell: get('identifier'),
                underline: true,
                onClick: (event, item) => {
                    history.push(`/vehicle/inventories/${values.id}/${item.id}`);
                },
            },
            ...groups.map(group => ({
                name: group.name,
                id: group.id,
                renderCell: get(['options', group.id, 'name']),
            })),
            {
                name: 'VIN Assigned',
                id: 'vinAssigned',
                renderCell: get('vinAssigned'),
            },
            {
                name: 'Initial Qty',
                id: 'initialStock',
                renderCell: renderField('initialStock'),
                styles: { minWidth: '100px' },
            },
            { name: 'Remaining Qty', id: 'remainingStock', renderCell: get('remainingStock') },
            { name: 'Reserved Qty', id: 'reservedStock', renderCell: get('reservedStock') },
            { name: 'Deducted Qty', id: 'deductedStock', renderCell: get('deductedStock') },
        ];

        if (inventory?.identifier) {
            return initColumns;
        }

        return initColumns.filter(i => i.id !== 'identifier' && i.id !== 'vinAssigned');
    }, [groups, renderField, inventory, history, values.id]);

    const items = useMemo(() => Object.values(rows), [rows]);

    return (
        <div>
            <br />
            <div className="container-fluid">
                <ListTable columns={columns} items={items} />
            </div>
        </div>
    );
};

export default Inventory;
