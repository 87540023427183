// eslint-disable-next-line import/no-extraneous-dependencies
import { Decimal } from 'decimal.js';
import { get, getOr } from 'lodash/fp';
import * as yup from 'yup';
import DualField from '../components/fields/display/DualField';
import DualSnapshotField from '../components/fields/display/DualSnapshotField';
import UpdateDual from '../components/fields/edition/UpdateDual';
import desiredLoanAmount from '../desiredLoanAmount';
import { weightFieldAfter } from '../utils';
import convert from './convert';
import { getAccurate, getRangeByBasedOn } from './downPayment';
import fieldTemplate from './fieldTemplate';
import raiseInvalidConfiguration from './raiseInvalidConfiguration';
import { FinanceProductBase, Unit } from './schema';

// eslint-disable-next-line consistent-return
const compute = (values, context) => {
    const { paymentTerm, interestRate, monthlyInstalment } = values;
    const { selectedVariant, selectedFinanceProduct, t } = context;

    const dependencies = [monthlyInstalment, paymentTerm, interestRate, selectedFinanceProduct, selectedVariant];

    if (dependencies.some(value => value === undefined)) {
        // no enough data yet
        return undefined;
    }

    const parameters = {
        monthlyInstalment: { value: monthlyInstalment },
        term: { value: paymentTerm },
        interestRate: { value: interestRate },
    };

    try {
        // try to use the calculator to compute the monthly payment
        const { detail } = convert(selectedFinanceProduct);

        const results = desiredLoanAmount(parameters, detail);

        return results;
    } catch (error) {
        console.error(error);
        // and send a computing error
        raiseInvalidConfiguration(t);
    }
};

const getDefaultLoanAmount = ({ downPayment, totalPrice }) => {
    if (downPayment === undefined || totalPrice === undefined) {
        return undefined;
    }

    return {
        percentage: getAccurate(downPayment.percentage),
        amount: totalPrice - downPayment.amount,
    };
};

const loanAmount = {
    // copy template
    ...fieldTemplate,

    // setup meta
    label: t => t('calculator.label.loanAmount'),
    weight: weightFieldAfter('paymentTerm'),

    // state states
    isViewable: getOr(false, 'selectedFinanceProduct.loanSetting.show'),
    isEditable: context => {
        const config = get('selectedFinanceProduct.loanSetting', context);
        const basedOn = get('selectedFinanceProduct.basedOn', context);

        if (!config) {
            return false;
        }

        const { min, max, editable, defaultUnit: unit } = config;

        return basedOn === FinanceProductBase.DOWNPAYMENT && unit === Unit.CURRENCY
            ? editable
            : min !== max && editable;
    },

    // setup display rendering
    displayComponent: DualField,

    // setup edition rendering
    editionComponent: UpdateDual,
    getEditionProperties: (context, { totalPrice }) => {
        const { selectedFinanceProduct, formats, t } = context;
        const { loanSetting, downPaymentSetting } = selectedFinanceProduct;
        const { defaultUnit } = loanSetting;
        const unit = defaultUnit === Unit.PERCENTAGE ? '%' : ` ${formats?.currencySymbol}`;

        const { min, max, step } = getRangeByBasedOn(
            context,
            loanSetting,
            downPaymentSetting,
            FinanceProductBase.DOWNPAYMENT,
            totalPrice
        );

        const minErrorMessage = t('calculator.error.loanAmountMin', { min, unit });
        const validation = yup
            .number()
            .typeError(minErrorMessage)
            .min(min, minErrorMessage)
            .max(max, t('calculator.error.loanAmountMax', { max, unit }))
            .required(minErrorMessage);

        return {
            min,
            max,
            step,
            unit: defaultUnit,
            currency: formats?.currencySymbol,
            compute: {
                fromAmount: amount => formats.roundPercentage((amount * 100) / totalPrice),
                fromPercentage: percentage => formats.roundNumberDown((percentage / 100) * totalPrice),
            },
            validation,
        };
    },

    // setup values management
    getInitialValue: getDefaultLoanAmount,
    updates: {
        // reset when the finance product changed
        financeProduct: () => undefined,

        // when total price, we firstly calculate loan amount
        // then from load amount to trigger downpayment amount
        totalPrice: ({ totalPrice }, { formats }, previousValue) => {
            if (previousValue === undefined || totalPrice === undefined) {
                // we do not know yet how to compute it
                return undefined;
            }

            return {
                ...previousValue,
                amount: formats.roundNumberDown((previousValue.percentage / 100) * totalPrice),
            };
        },

        // compute amount to match loan amount
        downPayment: ({ totalPrice, downPayment }) => {
            if (downPayment === undefined || totalPrice === undefined) {
                // not enough data yet
                return undefined;
            }

            // if downpayment already confirmed,
            // we do not need round again for loan amount
            // so we just use minus to get confirmed value
            return {
                percentage: new Decimal(100 - downPayment.percentage).toNumber(),
                amount: new Decimal(totalPrice - downPayment.amount).toNumber(),
            };
        },

        monthlyInstalment: (values, context, previousValue) => {
            const { monthlyInstalment, totalPrice } = values;
            const { initChangeSource } = context;
            if (previousValue === undefined || monthlyInstalment === undefined) {
                // not enough data yet
                return undefined;
            }

            // only change source is monthlyInstalment, will trigger loan amount change
            if (initChangeSource !== 'monthlyInstalment') {
                return previousValue;
            }

            const { formats } = context;
            const desiredAmount = compute(values, context);
            const desiredPercentage = formats.roundPercentageDown((desiredAmount * 100) / totalPrice);

            return {
                percentage: desiredPercentage,
                amount: formats.roundNumberDown(desiredAmount),
            };
        },
    },

    // snapshot configuration
    snapshot: {
        ...fieldTemplate.snapshot,
        component: DualSnapshotField,
    },
};

export default loanAmount;
