import { useQuery } from '@apollo/client';
import { cloneDeep } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { ApplicationEventType, Channel } from '../../../../../schema';
import { computeBaseDisplayInformation } from '../../../../../utilities/applications/toFormState';
import withCompanyFormatting from '../../../../../utils/withCompanyFormatting';
import useFormatDateTime from '../../../../shared/useFormatDateTime';
import {
    getInsuranceApplication,
    GetInsuranceApplicationQuery,
    GetInsuranceApplicationQueryVariables,
} from '../../common/data/Application.graphql';
import Page from './Page';

const Edition = () => {
    const { id: versionId } = useParams<{ id: string }>();
    const { data } = useQuery<GetInsuranceApplicationQuery, GetInsuranceApplicationQueryVariables>(
        getInsuranceApplication,
        {
            fetchPolicy: 'no-cache',
            variables: { versionId },
        }
    );

    const formatDateTime = useFormatDateTime();

    const initialValues = useMemo(() => {
        // there's no application retrieved
        if (!data?.insuranceApplication) {
            return null;
        }

        const insuranceApplication = data?.insuranceApplication;

        const { calculator, channel, customer } = insuranceApplication;

        const submittedEvent = insuranceApplication.events.find(({ type }) => type === ApplicationEventType.SUBMIT);

        return {
            ...cloneDeep(insuranceApplication),
            initialCustomer: cloneDeep(customer),
            display: {
                ...computeBaseDisplayInformation(insuranceApplication, formatDateTime),
                ...(channel === Channel.NEW && {
                    originalRegistrationDate: submittedEvent ? submittedEvent.at : null,
                }),
                ...(channel === Channel.EXPRESS && {
                    assetCondition: 'Pre-owned',
                }),
                totalCarPrice: calculator.price + (calculator?.coe?.amount ?? 0),
                displacement: calculator && calculator?.displacement ? calculator?.displacement : null,
            },
            calculator: {
                ...insuranceApplication.calculator,
            },
        };
    }, [data, formatDateTime]);

    return <Page initialValues={initialValues} />;
};

export default withCompanyFormatting(Edition);
