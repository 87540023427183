import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
// Ignoring reactstrap type as it using old version of reacstrap
// @ts-ignore
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import styled from 'styled-components';
import { useAppointmentListContext } from './AppointmentListContext';
import { FilterDateRange } from './reducer';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    right: 16px;
    background: transparent;
`;

const ToggleButton = styled(DropdownToggle)`
    padding: 0;

    &.active,
    &:active,
    &:focus,
    &:hover {
        color: ${props => props.theme.themeHighlightColour};
    }
`;

const OptionItem = styled(DropdownItem)`
    &.active,
    &:active,
    &:focus,
    &:hover {
        background-color: ${props => props.theme.themeHighlightColour};
        color: #ffffff;
        outline: none;
        border: 0;
        cursor: pointer;
    }
`;

const options = [
    { id: FilterDateRange.TODAY, name: 'Today' },
    { id: FilterDateRange.WEEK, name: 'This Week' },
    { id: FilterDateRange.MONTH, name: 'This Month' },
];

const AppointmentDateFilter = () => {
    const { state, dispatch } = useAppointmentListContext();
    const [isShown, setShow] = useState(false);

    const onWrapperClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(ev => {
        // Blocking table header click event, which is sort action
        ev.stopPropagation();

        setShow(old => !old);
    }, []);

    const onSelect = useCallback(
        (optionId: FilterDateRange) => {
            if (optionId === state.dateRangeId) {
                dispatch({
                    type: 'SET_DATE_RANGE',
                    payload: [undefined, undefined, FilterDateRange.NONE],
                });

                return;
            }

            switch (optionId) {
                case FilterDateRange.TODAY:
                    dispatch({
                        type: 'SET_DATE_RANGE',
                        payload: [dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate(), optionId],
                    });
                    break;

                case FilterDateRange.WEEK:
                    dispatch({
                        type: 'SET_DATE_RANGE',
                        payload: [
                            dayjs().startOf('day').startOf('week').toDate(),
                            dayjs().endOf('day').endOf('week').toDate(),
                            optionId,
                        ],
                    });
                    break;

                case FilterDateRange.MONTH:
                    dispatch({
                        type: 'SET_DATE_RANGE',
                        payload: [
                            dayjs().startOf('day').startOf('month').toDate(),
                            dayjs().endOf('day').endOf('month').toDate(),
                            optionId,
                        ],
                    });
                    break;

                default:
                    dispatch({
                        type: 'SET_DATE_RANGE',
                        payload: [undefined, undefined, optionId],
                    });
                    break;
            }
        },
        [dispatch, state.dateRangeId]
    );

    return (
        <Wrapper>
            <Dropdown isOpen={isShown} size="sm" toggle={onWrapperClick}>
                <ToggleButton caret={false} nav>
                    <FontAwesomeIcon icon={faFilter} />
                </ToggleButton>
                <DropdownMenu positionFixed right>
                    <DropdownItem header>Filter</DropdownItem>
                    {options.map(option => (
                        <OptionItem
                            key={option.id}
                            active={state.dateRangeId === option.id}
                            onClick={() => onSelect(option.id)}
                        >
                            {option.name}
                        </OptionItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </Wrapper>
    );
};

export default AppointmentDateFilter;
