import React from 'react';
import Select from 'react-select';

const customStyles = {
    control: (provided, { isDisabled }) => ({
        ...provided,
        cursor: 'pointer',
        outline: 'none',
        border: '1px solid #e3e9f0',
        boxShadow: 'none',
        borderRadius: '3px',
        height: '3.21rem',
        maxHeight: '3.21rem',
        minHeight: '3.21rem',
        backgroundColor: isDisabled ? '#d8d8d845' : '#fffff',
        ':hover': {
            ...provided[':hover'],
            border: '1px solid #e3e9f0',
        },
    }),
    menu: provided => ({
        ...provided,
        marginTop: 0,
        borderRadius: 0,
    }),
    menuList: provided => ({
        ...provided,
        padding: 0,
    }),
    option: (provided, { isSelected, isFocused }) => ({
        ...provided,
        cursor: 'pointer',
        color: '#74708d',
        backgroundColor: isSelected || isFocused ? 'rgba(0, 0, 0, 0.04)' : '#ffffff',
        padding: '7px 10px',
        ':active': {
            ...provided[':active'],
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: provided => ({
        ...provided,
        padding: '0 5px 0 0',
        color: '#74708d',
        ':hover': {
            ...provided[':hover'],
            color: '#74708d',
        },
    }),
    clearIndicator: () => ({ display: 'none' }),
    input: provided => ({ ...provided, margin: 0 }),
    valueContainer: provided => ({
        ...provided,
        padding: '0px 10px 0 10px',
        height: '3.21rem',
    }),
    singleValue: provided => ({
        ...provided,
        marginLeft: 0,
        marginRight: 0,
        color: '#74708d',
    }),
    multiValue: provided => ({
        ...provided,
        margin: '0 3px 0 0',
        color: '#74708d',
        backgroundColor: '#aba8a8',
        height: '24px',
        borderRadius: 0,
    }),
    multiValueLabel: provided => ({
        ...provided,
        padding: '0 6px 0 6px',
        lineHeight: '24px',
    }),
    multiValueRemove: (provided, { isDisabled }) =>
        isDisabled
            ? { display: 'none' }
            : {
                  ...provided,
                  ':hover': {
                      ...provided[':hover'],
                      backgroundColor: '#aba8a8',
                      color: '#74708d',
                  },
              },
    placeholder: provided => ({
        ...provided,
        color: '#74708d',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        right: '10px',
        left: '10px',
    }),
};

const OutlineSelect = props => (
    <Select {...props} menuPlacement="auto" noOptionsMessage={() => 'No data available'} styles={customStyles} />
);

export default OutlineSelect;
