import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #d8d8d8;
    height: 30px;
    width: 200px;
`;

const StyledInput = styled('input')`
    flex: 1;
    border: 0;
    background: transparent;
    font-size: 0.85rem;
    padding-left: 10px;
    color: #000000;
`;

const CustomInput = ({ className, clear, value, type, placeholder, onKeyDown, onChange, autoFocus }) => (
    <StyledDiv className={`wrapper ${className}`}>
        <StyledInput
            autoFocus={autoFocus}
            className="input"
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            type={type}
            value={value}
        />
        {value && value.length > 0 && clear && (
            <FontAwesomeIcon className="delete-icon" icon={faTimes} onClick={clear} />
        )}
    </StyledDiv>
);

CustomInput.propTypes = {
    autoFocus: PropTypes.bool,
    className: PropTypes.string.isRequired,
    clear: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
};

export default styled(CustomInput)`
    & > * {
        margin-right: 10px;
    }

    & .delete-icon:hover {
        cursor: pointer;
    }
`;
