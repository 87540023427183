import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FootBar, FootBarButton, FootContainer } from '../../../containers/Layout';
import { getGlobalPermissions } from '../../../selectors';

const ConsentListFooter = () => {
    // check permissions
    const { mayMangeConsentDeclaration } = useSelector(getGlobalPermissions);

    // prepare history
    const history = useHistory();
    const onAddConsent = useCallback(() => history.push(`/consent/fromCountry`), [history]);

    return (
        <FootContainer>
            <FootBar>
                {mayMangeConsentDeclaration && (
                    <FootBarButton onClick={onAddConsent}>
                        <FontAwesomeIcon icon={faPlus} /> Add consent
                    </FootBarButton>
                )}
            </FootBar>
        </FootContainer>
    );
};

export default ConsentListFooter;
