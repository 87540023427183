import { get, uniqBy, uniq, flow, map, size, lt, filter } from 'lodash/fp';
import { prepareForGraphQL } from '../../../../../utilities/forms';

const mapSharePayload = (state, allowOptions, variants, financeProducts) => {
    const { customer, zoneId, calculator, variant, financeProductId, options: selectedOptions, channel } = state;
    const { id: customerId } = customer;

    const { options: allOptions } = variant;
    const groupIds = flow([map(item => item.groupId), uniq])(allOptions);
    const optionGroups = map(
        item => ({
            id: item,
            options: flow([
                filter(i => i.groupId === item),
                map(i => ({
                    id: i.id,
                    selected: !!selectedOptions.find(ite => ite.id === i.id),
                    image: get('images[0].id', i)
                        ? {
                              id: get('images[0].id', i),
                              extension: `.${get('images[0].metaData.filename', i).split('.').pop()}`,
                          }
                        : null,
                })),
            ])(allOptions),
        }),
        groupIds
    );

    const info = {
        financeProductId,
        variant: {
            id: variant.id,
        },
        // need all options
        optionGroups: allowOptions ? optionGroups : [],
        calculator: {
            ...prepareForGraphQL(calculator),
            totalPrice:
                calculator.price +
                (calculator.coe?.amount ?? 0) +
                (calculator.nzFee ?? 0) +
                (calculator.luxuryTax?.amount ?? 0) +
                selectedOptions.reduce((acc, item) => acc + item.price, 0),
        },
    };

    const hasMakes = uniqBy(get('model.makeId'), variants).length > 1;
    if (hasMakes) {
        info.makeId = get('model.makeId', variant);
    }

    const availableBanks = flow(
        map(product => product.bank),
        uniqBy('id')
    )(financeProducts);
    const hasManyBanks = flow([size, lt(1)])(availableBanks);
    if (hasManyBanks) {
        info.bankId = calculator.bank;
    }

    return {
        channel,
        customerId,
        zoneId,
        data: [info],
        shouldCreateLead: false,
    };
};

export default mapSharePayload;
