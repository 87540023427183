import ConnectedField from '../components/fields/display/ConnectedField';
import ConnectedLabelBox from '../components/fields/display/ConnectedLabelBox';
import ConnectedSnapshotField from '../components/fields/display/ConnectedSnapshotField';
import EmptyBox from '../components/ui/EmptyBox';

const fieldTemplate = {
    // field label
    // when using custom components this value might be ignored
    // this value is also used as title for the edition modal
    // it may also be a function using translation
    // label: (t) => t('key')
    label: null,
    // you may also provide another label for the modal
    // editionLabel: 'your edition label'
    // by default it will use `label` if not defined
    // it may also be a function
    // editionLabel: (editionContext, calculatorContext) => { /* ... */ },

    // initialize default values
    // when data does not allow to retrieve a proper value, it is important to return undefined
    // otherwise there will be no retry to initialize it
    getInitialValue: () => undefined,

    // is the field viewable, if not it will be hidden
    // meaning it will not be rendered
    isViewable: () => false,

    // is the field editable, if not it will be disabled
    isEditable: () => false,

    // provide automatic invalidity on invalid state
    isInvalid: context => context.isInvalid,

    // get the field weight, used for ordering fields
    weight: Number.POSITIVE_INFINITY,

    // react component and its properties for display purposes
    displayComponent: ConnectedField,
    displayLabelComponent: ConnectedLabelBox,
    displayEmptyComponent: EmptyBox,
    getDisplayProperties: () => ({}),

    // size on the grid (number of columns)
    // when using custom components this value might be ignored
    // if the component is rendered outside the grid, this value should be explicitly set to 0
    gridSize: 1,

    // react component and its properties for edition
    editionComponent: () => null,
    getEditionProperties: () => ({}),

    // a field can proxy the update for another
    moveEditionTo: null,

    // update listeners
    // the key is the field source because of which we need to update whenever its value change
    // the value is a function taking the values and context as arguments
    // it should return the new value for the field itself
    updates: {},

    // snapshot configuration
    // if the field mutate for snapshot support
    // it should be an object such as
    // { getValue(snapshot), isViewable(values, context) }
    // isViewable is optional, if provided, it will override the default isViewable method
    snapshot: {
        component: ConnectedSnapshotField,
    },
};

export default fieldTemplate;
