import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Unit } from '../../../../schema';
import { getCurrentCountry, getCurrentZone, getDealerId } from '../../../../selectors';
import Page from './Page';
import defaultValues from './defaultValues';

const Creation = () => {
    const country = useSelector(getCurrentCountry);
    const zone = useSelector(getCurrentZone);
    const dealerId = useSelector(getDealerId);
    const { useExternalCalculator = false } = country;

    const initialValues = useMemo(
        () => ({
            ...defaultValues,
            ...(useExternalCalculator && {
                downPaymentSetting: {
                    ...defaultValues.downPaymentSetting,
                    icc: {
                        min: 0,
                        max: 0,
                        step: 0,
                        default: 0,
                        defaultUnit: Unit.PERCENTAGE,
                    },
                },
            }),
            countryId: country.id,
            dealerId,
            period: {
                start: new Date(),
                startTimeZone: zone?.timezone,
                endTimeZone: zone?.timezone,
            },
        }),
        [country, dealerId, zone, useExternalCalculator]
    );

    return <Page initialValues={initialValues} />;
};

export default Creation;
