import styled from 'styled-components';
import Button from './Button';

const PrimaryButton = styled(Button)`
    border-color: #3590fe;
    background-color: #3590fe;
    color: #ffffff;

    &:hover,
    &:active {
        border-color: #1f9dfe;
        background-color: #1f9dfe;
    }
`;

export default PrimaryButton;
