import styled, { css } from 'styled-components';
import ActiveTabIcon from './tab-active.svg';
import TabIcon from './tab.svg';

const IS_IE = /(Trident|MSIE)/.test(window.navigator.userAgent);

export const AdminBgContainer = styled.div`
    height: 100%;
    display: ${({ $visible = true }) => ($visible ? 'flex' : 'none')};
    flex-direction: column;
`;

export const HeadBtnContainer = styled.div`
    padding: 15.5px 30px 16px 0px;

    > button {
        display: inline-block !important;
    }
`;

export const FootBar = styled.div`
    background: transparent;
    border-top: 1px solid #dedede;
    height: 61.5px;
    padding: 9.5px 0 19px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button:nth-last-child(1) {
        margin-right: 0;
    }
`;

export const ContentBody = styled.div`
    padding: 12.5px 30px;
    background-color: #ffffff;
    color: white;
`;

export const ScrollContainer = styled.div`
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 7.5px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.1);
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        outline: 1px solid rgba(0, 0, 0, 0.2);
    }
`;

export const FootContainer = styled.div`
    margin-top: auto;
    background: white;
    padding: 0px 30px;
`;

export const HeadTabBarButton = styled.button`
    border-image-source: url(${ActiveTabIcon});
    border-image-slice: 15;
    border-width: 10px;

    position: relative;
    padding: 0 20px;
    height: 33px;
    font-size: 0.72rem;
    margin: 0 4.5px;
    border-radius: 3px;
    text-transform: uppercase;
    color: #000000;
    background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    opacity: 0.5;

    &:nth-last-child(1) {
        margin-right: 0;
    }
    &:focus {
        outline: none;
    }
    &.active {
        color: #000000;
        opacity: 1;
        border-image-source: url(${TabIcon});
    }
    &:hover {
        color: #000000;
        opacity: 1;
        border-image-source: url(${TabIcon});
    }
    cursor: pointer;
`;

export const FootBarButton = styled.button`
    height: 33px;
    font-size: 0.72rem;
    border: 1px solid #4f4e5c;
    margin: 0 4.75px;
    text-transform: uppercase;
    color: #000000;
    background: transparent;
    border-radius: 3px;
    padding: 0px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    svg {
        margin-right: 5px;
    }
    a {
        color: #fff;
    }
    &:hover {
        opacity: 0.7;
    }
    &:focus {
        outline: none;
    }
    cursor: pointer;
    &.disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
`;

export const FilterInputDiv = styled.div`
    display: flex;
    width: 200px;
    flex-direction: row;
    background: rgba(255, 255, 255, 0.06);
    margin: 17.5px 30px 30px 0;
    height: 30px;
    input {
        flex: 1;
        border: 0;
        color: white;
        background: transparent;
        font-size: 0.85rem;
        padding-left: 10px;
    }
`;

export const TableContainer = styled.div`
    width: 100%;

    ${props =>
        !props.withNoScroll &&
        css`
            overflow-x: auto;
        `}
`;

export const Table = styled.table`
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1rem;
    color: #514d6a;

    thead {
        overflow: hidden;

        tr {
            text-align: center;
            height: 30px;

            th {
                font-weight: 600 !important;
                position: relative;
                border-bottom: 1px solid #e4e9f0;
                vertical-align: middle;
                text-align: left;
                padding: 0.75rem;
                cursor: pointer;

                &.sort-active {
                    opacity: 1;

                    .head-icon {
                        opacity: 1;
                    }
                }

                .head-icon {
                    position: absolute;
                    height: 100%;
                    ${IS_IE ? 'height: 30px;' : ''}
                    width: 15px;
                    top: 0px;
                    right: 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: 0.5;
                }
            }
        }
    }

    tbody {
        tr {
            text-align: center;
            cursor: pointer;

            td {
                text-align: left;
                padding: 0.75rem;
                vertical-align: middle;
                border-bottom: 1px solid #e4e9f0;
            }

            input {
                min-width: 100px;
            }

            &:hover {
                background: #f2f4f8;
            }
        }
    }
`;

export const statusFalseColor = {
    color: '#919099',
};

export const statusTrueColor = {
    color: '#78e139',
};

export const FormTabContainer = styled.div`
    position: relative;
    ul.react-tabs__tab-list {
        margin-right: -10px;
        margin-bottom: 18.5px;
        position: absolute;
        top: -53.5px;
        font-size: 0.93rem;
        right: 0;
        display: flex;
        flex-direction: row;
        li.react-tabs__tab {
            color: #9c9c9c;
            text-transform: uppercase;
            text-decoration: underline;
            cursor: pointer;
            margin: 0 10px;
            &:focus {
                outline: none;
            }
            &.react-tabs__tab--selected {
                color: #000000;
                text-decoration: none;
            }
        }
    }
    @media screen and (max-width: 670px) {
        ul.react-tabs__tab-list {
            top: -40px;
        }
    }
`;

export const DialogBackground = styled.div`
    height: 100%;
    width: 100%;
    margin-top: 67.5px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: #141525;
    opacity: 0.58;
    color: #ffffff;
    display: flex;
    flex-direction: column;
`;

export const CloseDialogButton = styled.div`
    margin-bottom: 15px;
    cursor: pointer;
    width: 10px;
    height: 10px;
    align-self: flex-end;
    font-weight: bold;
    color: white;
`;

export const dialogButtonStyles = {
    marginTop: '15px',
    fontWeight: 'bold',
    alignSelf: 'center',
    backgroundColor: 'transparent',
    border: '1px rgba(255, 255, 255, 0.56) solid',
    color: '#fff',
    padding: '3px',
    align: 'center',
    cursor: 'pointer',
    fontSize: '12px',
};

export const PasswordRequirement = styled.div`
    font-size: 0.85rem;
    .title {
        color: #9c9c9c;
        padding-top: 11px;
        padding-bottom: 9.5px;
        height: 35px;
        box-sizing: border-box;
    }
    .item {
        display: flex;
        flex-direction: row;
        height: 20px;
        align-items: center;
        color: #9c9c9cc7;
        line-height: 1;
        svg {
            margin-right: 10px;
        }
        .checked {
            color: #5fb142;
        }
        .unchecked {
            color: #da223a;
        }
    }
`;

export const ErrorMessageDiv = styled.div`
    color: #fd007a;
    font-size: 0.72rem;
    padding: 5px 0 0 0;
`;

export const TabExcelContainer = styled.div`
    display: flex;
`;
