import { get, flow, flatMap, intersection, find, isNil } from 'lodash/fp';
import React, { useEffect, useCallback, useMemo } from 'react';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { EventMedium } from '../../../../../schema';
import { eventLeadOriginOptions, eventMediumOptions, onOffOptions } from '../../../../../shared/constants/options';
import useTimeZones from '../../../../../utils/useTimeZones';
import DatePickerField from '../../../../template/DatePicker';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import SwitchField from '../../../../template/Switch';
import UserDropDownField from '../../../../template/options-field/UserDropdownField';
import { useFormContext } from './context';

const getUserDealerIds = flow(get('permissions'), flatMap(get('dealerIds')));

const MainDetails = () => {
    const timezones = useTimeZones();
    const { ct } = useContentTranslation();
    const { disabled, values, change, availableDealers } = useFormContext();

    const medium = get('medium', values);
    const disableCampaign = medium === EventMedium.WALKIN;

    const allowPublicAccess = !get('setting.access', values);
    const edition = !!values?.lastModified;

    const dealerOptions = useMemo(
        () =>
            availableDealers.map(dealer => ({
                ...dealer,
                label: ct(dealer.label),
            })),
        [availableDealers, ct]
    );

    useEffect(() => {
        if (disableCampaign) {
            change('campaignId', '');
        }
    }, [change, disableCampaign]);

    const dealerIds = get('dealerIds', values);
    const userOptionsFilter = useCallback(
        users =>
            users.filter(user => {
                const userDealerIds = getUserDealerIds(user);

                return dealerIds.length > 0 && intersection(dealerIds, userDealerIds).length === dealerIds.length;
            }),
        [dealerIds]
    );

    const defaultSalesPersonId = get('defaultSalesPersonId', values);
    const onUserOptionsChanged = useCallback(
        options => {
            if (defaultSalesPersonId) {
                if (isNil(find(['value', defaultSalesPersonId], options))) {
                    change('defaultSalesPersonId', null);
                }
            }
        },
        [defaultSalesPersonId, change]
    );

    return (
        <div className="container-fluid">
            <div className="row">
                {edition && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Event ID">
                            <Field component={InputField} name="identifier" disabled />
                        </FieldContainer>
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Event Name">
                        <Field component={InputField} disabled={disabled} name="name" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Start Date">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ flex: 2 }}>
                                <Field component={DatePickerField} disabled={disabled} name="period.start" />
                            </div>
                            <div style={{ width: '10px' }} />
                            <div style={{ flex: 1 }}>
                                <DropdownField
                                    disabled={disabled}
                                    name="period.startTimeZone"
                                    onChangeEvent={value => change('period.endTimeZone', value)}
                                    options={timezones}
                                />
                            </div>
                        </div>
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="End Date">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ flex: 2 }}>
                                <Field component={DatePickerField} disabled={disabled} name="period.end" />
                            </div>
                            <div style={{ width: '10px' }} />
                            <div style={{ flex: 1 }}>
                                <DropdownField
                                    disabled={disabled}
                                    name="period.endTimeZone"
                                    onChangeEvent={value => change('period.startTimeZone', value)}
                                    options={timezones}
                                />
                            </div>
                        </div>
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DropdownField
                        disabled={disabled}
                        height="75px"
                        label="Assign to"
                        maxHeight="100px"
                        minHeight="75px"
                        name="dealerIds"
                        options={dealerOptions}
                        multi
                        noSort
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DropdownField
                        disabled={disabled}
                        label="Lead Origin"
                        name="leadOrigin"
                        options={eventLeadOriginOptions}
                        placeholder="Select Lead Origin"
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DropdownField
                        disabled={disabled}
                        label="Medium"
                        name="medium"
                        options={eventMediumOptions}
                        placeholder="Select Medium"
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Campaign ID">
                        <Field component={InputField} disabled={disabled || disableCampaign} name="campaignId" />
                    </FieldContainer>
                </div>
                {allowPublicAccess && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <UserDropDownField
                            dealerIds={dealerIds}
                            disabled={disabled}
                            filter={userOptionsFilter}
                            label="Responsible Sales Person"
                            name="defaultSalesPersonId"
                            onOptionsChanged={onUserOptionsChanged}
                        />
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Active">
                        <Field component={SwitchField} disabled={disabled} name="isActive" options={onOffOptions} />
                    </FieldContainer>
                </div>
                {edition && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Last Modified">
                            <Field component={InputField} name="lastModified" disabled />
                        </FieldContainer>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MainDetails;
