import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useContentTranslation, ContentTranslation } from '../i18n';
import { getCurrentCountry } from '../selectors';
import { Validator } from './validators';

const useValidation = (
    validators: (formatPath: ContentTranslation['formatPath'], useExternalCalculator: boolean) => Validator
) => {
    // prepare translation
    const { formatPath } = useContentTranslation();
    const { useExternalCalculator = false } = useSelector(getCurrentCountry);

    return useMemo(() => {
        // translate validator
        const translatedValidator = validators(formatPath, useExternalCalculator);

        // create validation
        return translatedValidator;
    }, [formatPath, validators, useExternalCalculator]);
};

export default useValidation;
