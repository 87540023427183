import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field, FormSection } from 'redux-form';
import { Rounding, ChatbotType } from '../../../../../../schema';
import { onOffOptions } from '../../../../../../shared/constants/options';
import DropdownField from '../../../../../template/Dropdown';
import FieldContainer from '../../../../../template/Field-container';
import InputField from '../../../../../template/Input';
import KeyField from '../../../../../template/KeyField';
import SwitchField from '../../../../../template/Switch';
import { ChannelTitle } from '../../../../../ui/Channel';
import PaymentIntegration from '../../../bank/Form/PaymentIntegration';
import { useFormContext } from '../context';
import COE from './COE';
import ChannelURL from './ChannelURL';
import Establishment from './Establishment';
import LuxuryTax from './LuxuryTax';
import PPSR from './PPSR';
import useToggleOtherField from './useToggleOtherField';

type NewProps = {
    ciBaseLink: string;
    companyCode: string;
    countryZoneCode: string;
    currency: string;
    getHush: (rawKey: string) => Promise<string>;
    isCoeEnabled: boolean;
    precision: number;
    rounding: Rounding;
    isPpsrAndEstablishmentEnabled: boolean;
    isLuxuryTaxEnabled: boolean;
};

const New = ({
    getHush,
    companyCode,
    countryZoneCode,
    isCoeEnabled,
    isPpsrAndEstablishmentEnabled,
    isLuxuryTaxEnabled,
    rounding,
    currency,
    precision,
    ciBaseLink,
}: NewProps) => {
    const { values, change, settings, apiData } = useFormContext();
    const chatbotOptions = apiData?.options?.chatbotOptions;

    const url = `/${companyCode}/${countryZoneCode}/new`;

    const valuePrefix = 'channelSetting.new';

    const showCOEForNew = get(`channelSetting.new.isCoeEnabled`, values);
    const showCOEForExpress = get(`channelSetting.express.isCoeEnabled`, values);
    const selectedChatbot = get(`${valuePrefix}.chatbot.type`, values);

    const showPpsrAndEstablishmentForNew = get(`channelSetting.new.isPpsrAndEstablishmentEnabled`, values);
    const showPpsrAndEstablishmentForExpress = get(`channelSetting.express.isPpsrAndEstablishmentEnabled`, values);

    useEffect(() => {
        if (!showCOEForNew && !showCOEForExpress) {
            change(`${valuePrefix}.isCoeEnabled`, false);
            change('coe.amount', 0);
            change(`${valuePrefix}.isCoeEditable`, false);
        }
    }, [change, showCOEForExpress, showCOEForNew]);

    useEffect(() => {
        if (!showPpsrAndEstablishmentForNew && !showPpsrAndEstablishmentForExpress) {
            change(`${valuePrefix}.isPpsrAndEstablishmentEnabled`, false);
            change('ppsr.amount', 0);
            change('ppsr.editable', false);
            change('establishment.amount', 0);
            change('establishment.editable', false);
        }
    }, [change, showPpsrAndEstablishmentForNew, showPpsrAndEstablishmentForExpress]);

    useEffect(() => {
        if (!isLuxuryTaxEnabled) {
            change('luxuryTax.amount', 0);
            change('luxuryTax.threshold.petrol', 0);
            change('luxuryTax.threshold.electric', 0);
            change('luxuryTax.threshold.hybrid', 0);
        }
    }, [change, isLuxuryTaxEnabled]);

    const allowFinanceApplication = get(`${valuePrefix}.allowFinanceApplication`, values);
    const isDepositPaymentMandatory = get(`${valuePrefix}.isDepositPaymentMandatory`, values);
    const isPromoCodeEnabled = get(`${valuePrefix}.isPromoCodeEnabled`, values);
    const isChatbotEnabled = get(`${valuePrefix}.isChatbotEnabled`, values);
    const isInsuranceEnabled = get(`${valuePrefix}.isInsuranceEnabled`, values);

    useToggleOtherField(`${valuePrefix}.isDepositPaymentMandatory`, `${valuePrefix}.allowFinanceApplication`);
    useToggleOtherField(`${valuePrefix}.allowFinanceApplication`, `${valuePrefix}.isDepositPaymentMandatory`);

    useEffect(() => {
        if (!allowFinanceApplication || !(isInsuranceEnabled || isDepositPaymentMandatory)) {
            change(`${valuePrefix}.isFinanceApplicationOptional`, false);
        }
    }, [allowFinanceApplication, change, isDepositPaymentMandatory, isInsuranceEnabled]);

    useEffect(() => {
        if (!isDepositPaymentMandatory) {
            change(`${valuePrefix}.bookingPayment`, null);
        }
    }, [change, isDepositPaymentMandatory]);

    useEffect(() => {
        if (!isChatbotEnabled) {
            change(`${valuePrefix}.chatbot.dialogflowCredentials`, null);
            change(`${valuePrefix}.chatbot.userlikeCredentials`, null);
        }
    }, [change, isChatbotEnabled]);

    useEffect(() => {
        if (!isInsuranceEnabled) {
            change(`${valuePrefix}.allowApplyInsuranceOnFinance`, false);
        }
    }, [change, isInsuranceEnabled]);

    return (
        <div className="container-fluid">
            <ChannelTitle>New Car Channel</ChannelTitle>
            <ChannelURL path={url} to={ciBaseLink + url} />
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Active">
                        <Field component={SwitchField} name={`${valuePrefix}.isActive`} options={onOffOptions} />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Display Dealer Options in Calculator">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.isDealerOptionsEnabled`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                {isCoeEnabled && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Display COE in Calculator">
                            <Field
                                component={SwitchField}
                                name={`${valuePrefix}.isCoeEnabled`}
                                options={onOffOptions}
                            />
                        </FieldContainer>
                    </div>
                )}
                {isPpsrAndEstablishmentEnabled && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Display Other Fees in Calculator">
                            <Field
                                component={SwitchField}
                                name={`${valuePrefix}.isPpsrAndEstablishmentEnabled`}
                                options={onOffOptions}
                            />
                        </FieldContainer>
                    </div>
                )}

                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Calculate with Monthly Payment">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.allowReverseCalculator`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Options Module">
                        <Field component={SwitchField} name={`${valuePrefix}.allowOptions`} options={onOffOptions} />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Promo Code">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.isPromoCodeEnabled`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                {isPromoCodeEnabled && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Always Show Promo Code">
                            <Field
                                component={SwitchField}
                                name={`${valuePrefix}.alwaysShowPromoCode`}
                                options={onOffOptions}
                            />
                        </FieldContainer>
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Sharing">
                        <Field component={SwitchField} name={`${valuePrefix}.allowSharing`} options={onOffOptions} />
                    </FieldContainer>
                </div>
                {/* Hide it for now, Later we may need based on requirements */}
                {/* 
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Login Access">
                            <Field
                                component={SwitchField}
                                name={`${valuePrefix}.allowPrivateAccess`}
                                options={onOffOptions}
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Public Access">
                            <Field
                                component={SwitchField}
                                name={`${valuePrefix}.allowPublicAccess`}
                                options={onOffOptions}
                            />
                        </FieldContainer>
                    </div>
                */}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Booking Deposit Payment">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.isDepositPaymentMandatory`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Insurance">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.isInsuranceEnabled`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                {isInsuranceEnabled && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Apply Insurance In Finance Applications">
                            <Field
                                component={SwitchField}
                                name={`${valuePrefix}.allowApplyInsuranceOnFinance`}
                                options={onOffOptions}
                            />
                        </FieldContainer>
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Finance Application">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.allowFinanceApplication`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                {(isInsuranceEnabled || isDepositPaymentMandatory) && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Finance Application is Optional">
                            <Field
                                component={SwitchField}
                                name={`${valuePrefix}.isFinanceApplicationOptional`}
                                options={onOffOptions}
                            />
                        </FieldContainer>
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Proceed with Customer's Device">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.allowProceedWithCustomerDevice`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Monthly Payment Filter">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.filterByMonthlyInstalment`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Chatbot">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.isChatbotEnabled`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
            </div>

            {showPpsrAndEstablishmentForNew && (
                <>
                    <PPSR currency={currency} precision={precision} />
                    <Establishment currency={currency} precision={precision} />
                </>
            )}
            {showCOEForNew && (
                <COE currency={currency} editableName="channelSetting.new.isCoeEditable" precision={precision} />
            )}
            {isLuxuryTaxEnabled && <LuxuryTax currency={currency} precision={precision} />}
            {isDepositPaymentMandatory && (
                <FormSection name={valuePrefix}>
                    <PaymentIntegration currency={currency} rounding={rounding} settings={settings} />
                </FormSection>
            )}
            {isChatbotEnabled && (
                <>
                    <hr />
                    <div className="row">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Chatbot Agent">
                                <DropdownField name={`${valuePrefix}.chatbot.type`} options={chatbotOptions} disabled />
                            </FieldContainer>
                        </div>
                        {selectedChatbot === ChatbotType.DIALOGFLOW && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Dialogflow Credentials">
                                    <Field
                                        accept=".json"
                                        component={KeyField}
                                        hashFun={getHush}
                                        name={`${valuePrefix}.chatbot.dialogflowCredentials`}
                                    />
                                </FieldContainer>
                            </div>
                        )}
                        {selectedChatbot === ChatbotType.USERLIKE && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Widget Key">
                                    <Field component={InputField} name={`${valuePrefix}.chatbot.userlikeCredentials`} />
                                </FieldContainer>
                            </div>
                        )}
                    </div>
                </>
            )}
            <hr />
        </div>
    );
};

export default New;
