import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { AssetCondition } from '../../../../schema';
import { getCurrentCountry } from '../../../../selectors';
import Page from './Page';

const Creation = () => {
    const { id: countryId, zones } = useSelector(getCurrentCountry);
    const { modelId = null, subModelId = null } = useParams();

    const initialValues = useMemo(
        () => ({
            countryId,
            modelId,
            subModelId,
            isActive: true,
            technicalDetails: {},
            usedCarDetails: {},
            prices: Object.fromEntries(zones.map(zone => [zone.id, null])),
            mandatories: {
                ...Object.fromEntries(zones.map(({ id }) => [id, false])),
            },
            images: [],
            assetCondition: AssetCondition.NEW,
        }),
        [countryId, modelId, subModelId, zones]
    );

    return <Page initialValues={initialValues} />;
};

export default Creation;
