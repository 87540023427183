import { useApolloClient } from '@apollo/client';
import { get, isObject, omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { submit } from 'redux-form';
import { addNotification } from '../../../../actions';
import { useContentTranslation } from '../../../../i18n';
import { UploadPurpose } from '../../../../schema';
import { BANK_DELETE_NOTIFICATION, BANK_SAVE_NOTIFICATION } from '../../../../shared/constants/notification';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import useLanguageSelector from '../../../../utils/useLanguageSelector';
import { useModal } from '../../../Modal';
import FormLayout from '../../../ui/form/FormLayout';
import Form from './Form';
import {
    create,
    update,
    uploadLogo,
    deleteLogo,
    uploadFont,
    deleteFont,
    remove,
    uploadBankStamp,
    deleteBankStamp,
} from './Page.graphql';

const Page = ({ initialValues = null, disabled = false, zones = [], financeProducts = [], dealers = [] }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const languageSelector = useLanguageSelector();
    const { mapIntlValue } = useContentTranslation();
    const modal = useModal();

    const submitForm = useCallback(() => dispatch(submit('bank')), [dispatch]);

    const client = useApolloClient();
    const onSubmit = useCallback(
        async ({ logo, font, ...bankData }) => {
            const stamps = get('stamps', bankData);
            const data = omit(['stamps'], bankData);
            const isUpdate = !!data?.id;
            const query = isUpdate ? update : create;

            const cleanData = prepareForGraphQL(
                omit(['hasIntegration', 'hasMyInfoIntegration', 'version', 'lastModified', 'id'], data)
            );

            const { name, remoteFlowAcknowledgmentInfo } = cleanData;

            const variables = {
                data: {
                    ...cleanData,
                    name: mapIntlValue(name),
                    remoteFlowAcknowledgmentInfo: mapIntlValue(remoteFlowAcknowledgmentInfo),
                },
                id: data?.id,
            };

            if (isUpdate) {
                variables.data = omit(['countryId'], variables.data);
            }

            let promise = client.mutate({ mutation: query, variables });

            const uploads = [];

            if (logo instanceof File) {
                // must upload a new file
                uploads.push(id => client.mutate({ mutation: uploadLogo, variables: { id, file: logo } }));
            } else if (logo === null && isObject(initialValues.logo)) {
                // must remove an existing file
                uploads.push(id => client.mutate({ mutation: deleteLogo, variables: { id } }));
            }

            if (font instanceof File) {
                // must upload a new file
                uploads.push(id => client.mutate({ mutation: uploadFont, variables: { id, file: font } }));
            } else if (font === null && isObject(initialValues.font)) {
                // must remove an existing file
                uploads.push(id => client.mutate({ mutation: deleteFont, variables: { id } }));
            }

            // Stamps
            if (stamps?.enbdQuotationRight instanceof File) {
                // must upload a new file
                uploads.push(id =>
                    client.mutate({
                        mutation: uploadBankStamp,
                        variables: {
                            id,
                            purpose: UploadPurpose.ENBD_QUOTATION_RIGHT,
                            file: stamps?.enbdQuotationRight,
                        },
                    })
                );
            } else if (stamps?.enbdQuotationRight === null && isObject(initialValues?.stamps?.enbdQuotationRight)) {
                // must remove an existing file
                uploads.push(id =>
                    client.mutate({
                        mutation: deleteBankStamp,
                        variables: { id, purpose: UploadPurpose.ENBD_QUOTATION_RIGHT },
                    })
                );
            }

            // Stamps
            if (stamps?.enbdQuotationBottom instanceof File) {
                // must upload a new file
                uploads.push(id =>
                    client.mutate({
                        mutation: uploadBankStamp,
                        variables: {
                            id,
                            purpose: UploadPurpose.ENBD_QUOTATION_BOTTOM,
                            file: stamps?.enbdQuotationBottom,
                        },
                    })
                );
            } else if (stamps?.enbdQuotationBottom === null && isObject(initialValues?.stamps?.enbdQuotationBottom)) {
                // must remove an existing file
                uploads.push(id =>
                    client.mutate({
                        mutation: deleteBankStamp,
                        variables: { id, purpose: UploadPurpose.ENBD_QUOTATION_BOTTOM },
                    })
                );
            }

            // Upload all files
            if (uploads.length) {
                promise = promise.then(({ data: { response } }) =>
                    Promise.all([Promise.resolve(response), ...uploads.map(upload => upload(response.id))])
                );
            }

            return promise.then(() => data.countryId).catch(handleResponseError);
        },
        [client, initialValues, mapIntlValue]
    );

    const onSubmitSuccess = useCallback(
        countryId => {
            dispatch(addNotification(BANK_SAVE_NOTIFICATION));
            history.push(`/scope/countries/${countryId}`);
        },
        [history, dispatch]
    );

    const onDelete = useCallback(() => {
        modal.confirm({
            title: '',
            content: 'Are you sure you want to delete this bank?',
            options: [
                { label: 'No', action: () => null },
                {
                    label: 'Yes',
                    action: () =>
                        client.mutate({ mutation: remove, variables: { id: initialValues?.id } }).then(() => {
                            dispatch(addNotification(BANK_DELETE_NOTIFICATION));
                            history.push(`/scope/countries/${initialValues?.countryId}`);
                        }),
                },
            ],
        });
    }, [client, dispatch, history, initialValues, modal]);

    const onCancel = useCallback(() => history.goBack(), [history]);

    return (
        <FormLayout
            bodyComponent={
                initialValues && (
                    <Form
                        {...languageSelector}
                        dealers={dealers}
                        disabled={disabled}
                        financeProducts={financeProducts}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onSubmitSuccess={onSubmitSuccess}
                        zones={zones}
                    />
                )
            }
            moreActions={[
                initialValues?.id &&
                    !disabled && {
                        label: 'delete',
                        onAction: onDelete,
                    },
                {
                    label: 'save',
                    onAction: submitForm,
                },
            ]}
            onCancel={onCancel}
            title="Bank"
        />
    );
};

Page.propTypes = {
    dealers: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    disabled: PropTypes.bool,
    financeProducts: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    initialValues: PropTypes.shape({}),
    zones: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
};

export default Page;
