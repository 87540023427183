import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getCurrentCountry, getGlobalPermissions } from '../../selectors';
import { Edition, FinderVehicleList } from '../routes/FinderVehicle';

const FinderVehicleRouter = () => {
    const { mayViewFinderVehicles } = useSelector(getGlobalPermissions);
    const { usePorscheFinder } = useSelector(getCurrentCountry);

    if (!mayViewFinderVehicles || !usePorscheFinder) {
        return <Redirect to="/home" />;
    }

    return (
        <Switch>
            <Route component={FinderVehicleList} path="/finder" exact />
            <Route component={Edition} path="/finder/:id" exact />
            <Redirect to="/home" />
        </Switch>
    );
};

export default FinderVehicleRouter;
