import React, { useMemo } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { FormTabContainer } from '../../../../../../containers/Layout';
import useFormValues from '../../../../../utilities/useFormValues';
import ActivityLog from '../../../Finance/application/Form/ActivityLog';
import Application from './Application';
import Document from './Document';
import { ApplicationFormValues, Context, FormProps } from './context';
import validate from './validate';

const Form = (props: FormProps & InjectedFormProps<ApplicationFormValues, FormProps>) => {
    const values = useFormValues<ApplicationFormValues>();
    const context = useMemo(() => ({ ...props, values }), [props, values]);

    return (
        <Context.Provider value={context}>
            <FormTabContainer>
                <Tabs className="tabs">
                    <TabList>
                        <Tab>Application</Tab>
                        <Tab>Document</Tab>
                        <Tab>Activity Log</Tab>
                    </TabList>
                    <TabPanel>
                        <Application />
                    </TabPanel>
                    <TabPanel>
                        <Document />
                    </TabPanel>
                    <TabPanel>
                        <ActivityLog />
                    </TabPanel>
                </Tabs>
            </FormTabContainer>
        </Context.Provider>
    );
};

// @ts-ignore
export default reduxForm<ApplicationFormValues, FormProps>({ form: 'insuranceApplication', validate })(Form);
