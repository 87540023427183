import { getOr } from 'lodash/fp';
import { renderCurrency } from '../components/fields/display/CurrencyField';
import { weightFieldAfter } from '../utils';
import fieldTemplate from './fieldTemplate';

const cashPaymentField = {
    // copy template
    ...fieldTemplate,

    // setup meta
    label: t => t('calculator.label.cashPayment'),
    weight: weightFieldAfter('tradeIn'),

    // setup states
    isViewable: getOr(false, 'selectedFinanceProduct.useTradeInFields'),
    isEditable: () => false,

    // setup display rendering
    getDisplayProperties: () => ({ children: renderCurrency }),

    // setup values management
    getInitialValue: values => {
        const { downPayment, tradeIn } = values;

        return Math.max(0, (downPayment?.amount || 0) - (tradeIn || 0));
    },

    // this field is almost reset on everything...
    updates: Object.fromEntries(['downPayment', 'tradeIn'].map(field => [field, () => undefined])),
};

export default cashPaymentField;
