import { getOr } from 'lodash/fp';
import React from 'react';
import { useCalculatorContext } from '../../CalculatorContext';
import StaticField, { sharedPropTypes } from './StaticField';

const BankField = props => {
    // get the bank from the calculator context
    const { selectedBank } = useCalculatorContext();
    const bankName = getOr('', 'name', selectedBank);

    return <StaticField {...props} value={bankName} />;
};

BankField.propTypes = {
    ...sharedPropTypes,
};

export default BankField;
