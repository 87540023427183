import { get } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { getCurrentCountry } from '../../../../../selectors';
import { onOffOptions } from '../../../../../shared/constants/options';
import ColorPickerField from '../../../../template/ColorPicker';
import CurrencyField from '../../../../template/Currency';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import FileUpload from '../../../../template/FileUpload';
import InputField from '../../../../template/Input';
import NumberField from '../../../../template/Number';
import ApvPortlet from '../../../../template/Portlet';
import SwitchField from '../../../../template/Switch';
import TextareaField from '../../../../template/Textarea';
import { useFormContext } from './context';

const MainDetails = () => {
    const { ct, formatPath } = useContentTranslation();

    const { values, zones, dealers } = useFormContext();
    const edition = !!get('lastModified', values);

    const dealerOptions = useMemo(() => dealers?.map(dealer => ({ ...dealer, label: ct(dealer.label) })), [
        dealers,
        ct,
    ]);

    const zoneOptions = useMemo(() => zones?.map(zone => ({ ...zone, label: ct(zone.label) })), [zones, ct]);

    const { currency, rounding, code: countryCode } = useSelector(getCurrentCountry);

    const isPpsrAndEstablishmentEnabled = countryCode === 'NZ';

    const bankName = get('name', values);

    return (
        <ApvPortlet name="Main Details" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Bank Name">
                            <Field component={InputField} maxLength={50} name={formatPath('name')} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField
                            label="Zones"
                            name="zoneIds"
                            options={zoneOptions}
                            placeholder="Select Zone"
                            multi
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField
                            height="75px"
                            label="Assign to"
                            maxHeight="100px"
                            minHeight="75px"
                            name="dealerIds"
                            options={dealerOptions}
                            placeholder="Select Dealer"
                            multi
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Email">
                            <Field component={InputField} name="email" />
                        </FieldContainer>
                        <FieldContainer label="Order No.">
                            <Field component={NumberField} name="order" precision={0} />
                        </FieldContainer>
                        <FieldContainer label="Bank Colour">
                            <Field component={ColorPickerField} name="color" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Bank Logo">
                            <Field
                                accept=".jpg,.png,.jpeg,.svg"
                                component={FileUpload}
                                label="Size 1000x500px in jpg, png or svg"
                                name="logo"
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Bank Font">
                            <Field
                                accept=".woff,.woff2,.ttf,.otf"
                                component={FileUpload}
                                label="File formats: woff,woff2, ttf, otf"
                                name="font"
                                type="font"
                            />
                        </FieldContainer>
                    </div>
                    {isPpsrAndEstablishmentEnabled && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label={`${ct(bankName)} Establishment Fee`}>
                                <Field
                                    component={CurrencyField}
                                    name="establishment"
                                    precision={rounding?.amount?.count}
                                    prefix={currency}
                                    allowNegative
                                />
                            </FieldContainer>
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Remote Flow - Acknowledgment info">
                            <Field
                                component={TextareaField}
                                maxLength="1000"
                                name={formatPath('remoteFlowAcknowledgmentInfo')}
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Active">
                            <Field component={SwitchField} name="isActive" options={onOffOptions} />
                        </FieldContainer>
                    </div>
                    {edition && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Last Modified">
                                <Field component={InputField} name="lastModified" disabled />
                            </FieldContainer>
                        </div>
                    )}
                </div>
            </div>
        </ApvPortlet>
    );
};

export default MainDetails;
