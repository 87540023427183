import { get, getOr } from 'lodash/fp';
import UpdatePaymentTerm from '../components/fields/edition/UpdatePaymentTerm';
import { weightFieldAfter } from '../utils';
import fieldTemplate from './fieldTemplate';

const getDefaultTerm = get('selectedFinanceProduct.termSetting.default');

const paymentTermField = {
    // copy template
    ...fieldTemplate,

    // setup meta
    label: t => t('calculator.label.paymentTerm'),
    weight: weightFieldAfter('deposit'),

    // setup states
    isViewable: getOr(false, 'selectedFinanceProduct.termSetting.show'),
    isEditable: ({ selectedFinanceProduct: { termSetting } }) => {
        const { min, max, editable } = termSetting;

        return min < max && editable;
    },

    // setup display rendering
    getDisplayProperties: () => ({
        format: value => (value ? `${value} months` : '-'),
    }),

    // setup edition rendering
    editionComponent: UpdatePaymentTerm,
    getEditionProperties: ({ selectedFinanceProduct: { termSetting } }) => ({ termSetting }),

    // setup values management
    getInitialValue: (values, context) => getDefaultTerm(context),
    updates: {
        // reset when the finance product changed
        financeProduct: () => undefined,
    },
};

export default paymentTermField;
