import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import OptionGrid from '../../ui/OptionsGrid';

const UpdateOptions = ({
    initialValue,
    valueRef,
    options,
    size = 2,
    smallSize = 1,
    footerComponent: Footer = null,
}) => {
    // create a state to update the value
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        // update the value on the reference
        // eslint-disable-next-line no-param-reassign, react/prop-types
        valueRef.current = value;
    }, [valueRef, value]);

    return (
        <>
            <OptionGrid onChange={setValue} options={options} size={size} smallSize={smallSize} value={value} />
            {Footer && <Footer value={value} />}
        </>
    );
};

UpdateOptions.propTypes = {
    footerComponent: PropTypes.element,
    initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    size: PropTypes.number,
    smallSize: PropTypes.number,
    valueRef: PropTypes.shape({}).isRequired,
};

export default UpdateOptions;
