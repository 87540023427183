import { useApolloClient } from '@apollo/client';
// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useContentTranslation } from '../../../../../../i18n';
import { ApplicationPhase } from '../../../../../../schema';
import { handleResponseError } from '../../../../../../utilities/forms';
import TextAreaField from '../../../../../shared/form-v2/TextAreaField';
import TextField from '../../../../../shared/form-v2/TextField';
import UserSelectField from '../../../../../template/options-field/UserSelectField';
import useFormValues from '../../../../../utilities/useFormValues';
import { LinkField } from '../../../Finance/shared/Vehicle/Event';
import { updateInsuranceAssignee } from '../../../common/shared/Action.graphql';
import { ApplicationFormValues } from './context';

type SummaryProps = {
    id: string;
    disabled?: boolean;
    dealerId: string;
};

const Summary = ({ id, disabled, dealerId }: SummaryProps) => {
    const { formatPath } = useContentTranslation();
    const client = useApolloClient();
    const onAssigneeChange = useCallback(
        value => {
            client
                .mutate({
                    mutation: updateInsuranceAssignee,
                    variables: {
                        insuranceApplicationId: id,
                        assigneeId: value,
                    },
                })
                .catch(handleResponseError);
        },
        [client, id]
    );

    const { application } = useFormValues<ApplicationFormValues>();

    const referralLinkPrefix = useMemo(() => {
        switch (application?.phase) {
            case ApplicationPhase.LEAD:
                return '/workflow/leads';

            case ApplicationPhase.RESERVATION:
                return '/workflow/reservations';

            case ApplicationPhase.FINANCE:
            default:
                return '/workflow/applications';
        }
    }, [application]);

    return (
        <Portlet name="Insurance Application Details" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Application ID" name="display.appId" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Date Created" name="display.dateCreated" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Dealer" name={formatPath('display.dealer')} disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <UserSelectField
                            dealerId={dealerId}
                            disabled={disabled}
                            label="Assigned to"
                            name="assigneeId"
                            onChangeEvent={onAssigneeChange}
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Channel" name="display.source" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Last Modified" name="display.lastModified" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <LinkField>
                            <div className="label">Lead/ Reservation/ Finance Application Reference</div>
                            <div className="link-control">
                                <Link to={`${referralLinkPrefix}/${application?.version.id}`}>
                                    {application?.identifier}
                                </Link>
                            </div>
                        </LinkField>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextAreaField label="Application Status" name="display.applicationStatus" disabled />
                    </div>
                </div>
            </div>
        </Portlet>
    );
};

export default Summary;
