import { useQuery } from '@apollo/client';
import { omit } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { FinanceProductSubType } from '../../../../schema';
import { getLastModified } from '../../../../utilities/forms';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { getProduct } from './Edition.graphql';
import Page from './Page';

const Edition = () => {
    const { id } = useParams();
    const variables = { id };
    const { data, loading } = useQuery(getProduct, { variables, fetchPolicy: 'network-only' });

    const formatDateTime = useFormatDateTime();

    const initialValues = useMemo(() => {
        const values = data?.product;

        if (!values) {
            return null;
        }

        // clean values
        const residualValueSetting =
            values.subType === FinanceProductSubType.HIREPURCHASEBALLOONGFV
                ? {
                      ...values.residualValueSetting,
                      table: (values.residualValueSetting.table || []).map(omit('mileage')),
                  }
                : values.residualValueSetting;

        return {
            ...values,
            residualValueSetting,
            lastModified: getLastModified(values.version, formatDateTime),
        };
    }, [data, formatDateTime]);

    if (!loading && !initialValues) {
        // the product does not exist
        return <Redirect to="/finance/products" />;
    }

    return <Page initialValues={initialValues} />;
};

export default Edition;
