// @ts-ignore
import { ImportButton } from '@appvantageasia/afc-ui';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ImportExportContainer } from '../ui/modal';

export const ExportButton = styled(ImportButton)`
    margin-left: 9.55px;
`;

type ImportModalProps = {
    enabled: boolean;
    onClose: () => void;
    refetch: () => void;
};

type ImportExportProps = {
    ImportModal: (props: ImportModalProps) => JSX.Element;
    exportFunction: () => void;
    refetch: () => void;
    disabled: boolean;
};

const ImportExport = ({ ImportModal, exportFunction, refetch, disabled }: ImportExportProps) => {
    const [showImport, setShowImport] = useState(false);
    const dispatch = useDispatch();

    const exportExcel = useCallback(() => {
        return dispatch(exportFunction());
    }, [dispatch, exportFunction]);

    const onImportClose = useCallback(() => {
        setShowImport(false);
    }, [setShowImport]);

    return (
        <>
            <ImportExportContainer>
                <ImportButton disabled={disabled} onClick={() => setShowImport(true)}>
                    <span>Import</span>
                </ImportButton>
                <ExportButton disabled={disabled} onClick={exportExcel}>
                    <span>Export</span>
                </ExportButton>
            </ImportExportContainer>
            {showImport && <ImportModal enabled={showImport} onClose={onImportClose} refetch={refetch} />}
        </>
    );
};

export default ImportExport;
