import { useQuery } from '@apollo/client';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, orderBy, isNil } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FootBar, FootBarButton, FootContainer } from '../../../containers/Layout';
import { FinderVehicleAvailability } from '../../../schema';
import { getCurrentCountry, getGlobalPermissions } from '../../../selectors';
import { getConditionText } from '../../../utilities/applications/toFormState';
import { searchOnKeys } from '../../../utilities/fp';
import { useCompanyFormatting } from '../../../utils/withCompanyFormatting';
import { List, ListSearch } from '../../ui/lists';
import usePaging from '../../utilities/usePaging';
import { GetFinderVehiclesQuery, getFinderVehicles, FinderVehicleListDataFragment } from './FinderVehicleList.graphql';
import ImportLtaModal from './imports/ImportLtaModal';

const getAvailabilityText = (value: FinderVehicleListDataFragment) => {
    const availability = get('setting.availability', value);

    switch (availability) {
        case FinderVehicleAvailability.ONETIME:
            return 'One-Time';

        default:
            return availability;
    }
};

const useColumns = () => {
    const { formatCurrency } = useCompanyFormatting();

    return useMemo(
        () => [
            { id: 'id', name: 'Inventory ID', renderCell: get('listing.id'), underline: true, hasSort: true },
            {
                id: 'listing.vehicle.orderTypeCode',
                name: 'Vehicle ID',
                renderCell: get('listing.vehicle.orderTypeCode'),
                hasSort: true,
            },
            {
                id: 'name',
                name: 'Vehicle Name',
                renderCell: get('name'),
                hasSort: true,
            },
            {
                id: 'conditionText',
                name: 'Condition',
                renderCell: get('conditionText'),
                hasSort: true,
            },
            {
                id: 'listing.seller.name.localize',
                name: 'Dealer',
                renderCell: get('listing.seller.name.localize'),
                hasSort: true,
            },
            {
                id: 'listing.vehicle.exteriorColor.name.localize',
                name: 'Colour',
                renderCell: get('listing.vehicle.exteriorColor.name.localize'),
                hasSort: true,
            },
            {
                id: 'listing.price.value',
                name: 'Price',
                renderCell: (value: FinderVehicleListDataFragment) =>
                    formatCurrency(get('listing.price.value', value), get('listing.price.currencyCode', value)),
                hasSort: true,
            },
            {
                id: 'setting.isHidden',
                name: 'Button State',
                renderCell: (value: FinderVehicleListDataFragment) => {
                    const isHidden = get('setting.isHidden', value);

                    if (isNil(isHidden)) {
                        return null;
                    }

                    return isHidden ? 'Hidden' : 'Show';
                },
                hasSort: true,
            },
            {
                id: 'availabilityText',
                name: 'Button Function',
                renderCell: get('availabilityText'),
                hasSort: true,
            },
            {
                id: 'status',
                name: 'Status',
                renderCell: get('status'),
                hasSort: true,
            },
        ],
        [formatCurrency]
    );
};

const searchFields = ['name', 'owner.name'];

type SortKey = 'asc' | 'desc';

const sortItems = <T,>(items: T[], [sortKey, sortOrder]: [string, SortKey]): T[] => {
    // we always sort by value key
    return orderBy(sortKey, sortOrder, items);
};

const FinderVehicleList = () => {
    // get current context
    const country = useSelector(getCurrentCountry);
    const { mayManageFinderVehicles } = useSelector(getGlobalPermissions);

    const [showImport, setShowImport] = useState(false);

    // prepare history
    const history = useHistory();

    const variables = { countryId: country?.id };
    const { data, loading, error, refetch } = useQuery<GetFinderVehiclesQuery>(getFinderVehicles, {
        fetchPolicy: 'cache-and-network',
        variables,
    });
    const items = useMemo(
        () =>
            (data?.results || []).map(item => ({
                ...item,
                availabilityText: getAvailabilityText(item),
                conditionText: getConditionText(item),
            })),
        [data]
    );
    const isLoading = loading && items.length <= 0;

    // searching
    const [search, setSearch] = useState('');
    const searchMethod = useMemo(() => searchOnKeys(searchFields, items), [items]);
    const matchedItems = useMemo(() => searchMethod(search), [search, searchMethod]) as any[];

    // sorting
    const [sortedOn, onSort] = useState<[string, SortKey]>(['name', 'asc']);
    const sortedItems = useMemo(() => sortItems(matchedItems, sortedOn), [matchedItems, sortedOn]);

    // paging
    const [pagedItems, paging] = usePaging(sortedItems, { search });

    // columns
    const columns = useColumns();

    const onImportClose = useCallback(() => {
        setShowImport(false);
        refetch();
    }, [refetch]);

    const footer =
        mayManageFinderVehicles && country.code === 'SG' ? (
            <FootContainer>
                <FootBar>
                    <FootBarButton onClick={() => setShowImport(true)}>
                        <FontAwesomeIcon icon={faUpload} /> Upload LTA DATA
                    </FootBarButton>
                </FootBar>
            </FootContainer>
        ) : null;

    return (
        <>
            <List
                columns={columns}
                error={!!error}
                footer={footer}
                headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
                items={pagedItems}
                loading={isLoading}
                onItemClick={item => history.push(`/finder/${item.id}`)}
                onSort={onSort}
                paging={paging}
                sortedOn={sortedOn}
                title="Finder Vehicles"
            />
            {showImport && <ImportLtaModal onClose={onImportClose} />}
        </>
    );
};

export default FinderVehicleList;
