import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { reduxForm, getFormValues, Field } from 'redux-form';
import * as yup from 'yup';
import { requirements, passwordRegex } from '../../../utilities/constants/passwordRequirements';
import { createFormValidation, requiredString } from '../../../utilities/forms';
import ContactField from '../../shared/form/ContactField';
import TextField from '../../shared/form/TextField';
import FieldContainer from '../../template/Field-container';
import FileUpload from '../../template/FileUpload';
import Grid from '../../ui/Grid';
import Portlet from '../../ui/Portlet';
import PasswordRequirementList from '../../ui/login/PasswordRequirementList';

const validatePassword = value =>
    requirements.map(({ description, regex }) => ({
        description,
        isChecked: regex.test(value),
    }));

const ProfileForm = ({ handleSubmit }) => {
    const { newPassword = '' } = useSelector(getFormValues('profile')) || {};
    const passwordRequirements = validatePassword(newPassword);

    return (
        <form onSubmit={handleSubmit}>
            <Portlet title="Main Details" open>
                <Grid>
                    <div>
                        <TextField label="User Name" name="username" disabled />
                        <TextField label="Email" name="email" />
                        <TextField label="Last Modified" name="lastModified" disabled />
                    </div>
                    <div>
                        <TextField label="Name" name="name" />
                        <ContactField />
                    </div>
                    <FieldContainer label="Image">
                        <Field
                            accept=".jpg,.png,.jpeg"
                            component={FileUpload}
                            label="Size 500x500px in jpg or png"
                            name="image"
                        />
                    </FieldContainer>
                </Grid>
            </Portlet>
            <Portlet title="Change Password" open>
                <Grid>
                    <div>
                        <TextField label="Current Password" name="password" type="password" />
                        <TextField label="New Password" name="newPassword" type="password" />
                        <TextField label="Confirm New Password" name="confirmNewPassword" type="password" />
                    </div>
                    <PasswordRequirementList requirements={passwordRequirements} />
                </Grid>
            </Portlet>
        </form>
    );
};

ProfileForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

const makePasswordValidation = validation =>
    yup.lazy((value, { parent }) => {
        const { password, newPassword, confirmNewPassword } = parent;
        const isMandatory = password || newPassword || confirmNewPassword;

        if (!isMandatory) {
            return yup.string();
        }

        return validation;
    });

const imageValidation = () =>
    yup.lazy(value => {
        if (value instanceof File) {
            return yup.mixed().test('fileSize', 'File size is large than 5MB', image => image?.size <= 5 * 1024 * 1024);
        }

        return yup.mixed().nullable();
    });

const schema = yup.object().shape({
    confirmNewPassword: makePasswordValidation(
        yup
            .string()
            .required('Comfirm new password is mandatory')
            .test('is-matching', 'Password not match', function testPasswordMatch(value) {
                return value === this.parent.newPassword;
            })
    ),
    email: yup.string().required('Email is mandatory').email('Please enter email in correct format'),
    image: imageValidation(),
    name: requiredString('Name is mandatory'),
    newPassword: makePasswordValidation(
        requiredString('New password is mandatory').matches(passwordRegex, 'Password should follow requirements side')
    ),
    password: makePasswordValidation(requiredString('Current password is mandatory')),
});

export default reduxForm({ form: 'profile', validate: createFormValidation(schema) })(ProfileForm);
