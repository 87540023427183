import { uniq } from 'lodash/fp';
import { useMemo } from 'react';
import { Channel } from '../../../../../schema';
import useLoadVariants from '../../common/data/useLoadVariants';
import useFinanceProducts from '../data/useFinanceProducts';

const useVariants = (dealerId: string, zoneId: string, channel: Channel, bank?: string | null, eventId?: string) => {
    const allVariants = useLoadVariants('cache-first', zoneId, channel);
    const { financeProducts: allFinanceProducts } = useFinanceProducts('cache-and-network', dealerId, channel, eventId);

    // get variant with a shortcut to read the zone price
    const zonedVariants = useMemo(
        () =>
            allVariants.map(item => ({
                ...item,
                price: item.prices.find(i => i.zoneId === zoneId),
            })),
        [zoneId, allVariants]
    );

    // get finance products
    const financeProducts = useMemo(() => {
        if (!bank) {
            // we don't need to filter
            return allFinanceProducts;
        }

        return allFinanceProducts.filter(product => product.bankId === bank);
    }, [bank, allFinanceProducts]);

    // get available variant for our finance products
    const variants = useMemo(() => {
        if (!bank) {
            // no need to filter anything
            return zonedVariants;
        }

        // list the ids first
        const variantIds = uniq(financeProducts.flatMap(product => product.variants));

        return zonedVariants.filter(variant => variantIds.includes(variant.version.id));
    }, [financeProducts, zonedVariants, bank]);

    return { variants, financeProducts };
};

export default useVariants;
