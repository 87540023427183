import { useQuery } from '@apollo/client';
import { get, isNil } from 'lodash/fp';
import React, { useState, useMemo, useEffect, useReducer, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FootBar, FootBarButton, FootContainer } from '../../../../../containers/Layout';
import { useContentTranslation } from '../../../../../i18n';
import { getZoneId, getCurrentCountry, getCurrentZone } from '../../../../../selectors';
import withCompanyFormatting, { Formats } from '../../../../../utils/withCompanyFormatting';
import useFormatDateTime from '../../../../shared/useFormatDateTime';
import { List, cells, ListSearch, DealerSelectionDropDown } from '../../../../ui/lists';
import useServerPaging from '../../../../utilities/useServerPaging';
import { getSourceLabel } from '../../Finance/lists/LeadList';
import { getTables, GetTablesQuery } from './ApplicationList.graphql';
import DownloadModal from './Download';

const useSearchFields = () => {
    const { formatPath } = useContentTranslation();

    return useMemo(
        () => [
            'identifier',
            'user.name',
            'zone.code',
            'customer.name.value',
            formatPath('variant.name'),
            formatPath('insuranceCompany.name'),
            'channel',
        ],
        [formatPath]
    );
};

const useColumns = (formatCurrencyDown: (value: string) => string) => {
    const zone = useSelector(getCurrentZone);
    const formatDateTime = useFormatDateTime();
    const { formatPath } = useContentTranslation();

    return useMemo(() => {
        const columns = [
            {
                name: 'Insurance Application Date',
                id: 'version.createdAt',
                renderCell: cells.renderDateTime('version.createdAt', formatDateTime),
                hasSort: true,
            },
            {
                name: 'Insurance Application ID',
                id: 'identifier',
                renderCell: get('identifier'),
                underline: true,
                hasSort: true,
            },
            {
                name: 'Assigned to',
                id: 'assignee.name',
                renderCell: get('assignee.name'),
                hasSort: true,
            },
            {
                name: 'Customer',
                id: 'customer.name.value',
                renderCell: get('customer.name.value'),
                hasSort: true,
            },
            {
                name: 'Variant',
                id: formatPath('variant.name'),
                renderCell: get(formatPath('variant.name')),
                hasSort: true,
            },
            {
                name: 'Insurance Company',
                id: formatPath('insuranceCompany.name'),
                renderCell: get(formatPath('insuranceCompany.name')),
                hasSort: true,
            },
            {
                name: 'Insurance Premium (SGD)',
                id: 'calculator.insurancePremium',
                renderCell: get('calculator.insurancePremium'),
                hasSort: true,
            },
            {
                name: 'Status',
                id: 'status',
                renderCell: cells.renderStatus(),
                hasSort: true,
            },
            {
                name: 'Last Activity',
                id: 'version.updatedAt',
                renderCell: cells.renderDateTime('version.updatedAt', formatDateTime),
                hasSort: true,
            },
            {
                name: 'Channel',
                id: 'channel',
                renderCell: cells.renderSource(getSourceLabel),
                hasSort: true,
            },
        ];

        return columns.filter(Boolean);
    }, [formatDateTime, formatPath, formatCurrencyDown, zone]);
};

const reducer = (state: { type: string; order: string }, action: { type: string; payload: string[] }) => {
    switch (action.type) {
        case 'UPDATE':
            return { ...state, ...{ type: action.payload[0], order: action.payload[1] } };
        default:
            return state;
    }
};

export type ApplicationListProps = {
    formats: Formats;
};

const ApplicationList = ({ formats }: ApplicationListProps) => {
    const history = useHistory();
    const [dealerIds, setDealerIds] = useState<string[] | null>(null);

    const { formatCurrencyDown } = formats;
    const { id: countryId } = useSelector(getCurrentCountry);
    const zoneId = useSelector(getZoneId);

    // searching
    const [search, setSearch] = useState(undefined);
    const searchFields = useSearchFields();

    // // sorting
    const [sortedOn, dispatch] = useReducer(reducer, { type: 'identifier', order: 'DESC' });
    const [paging] = useServerPaging({ search });

    const onSort = (sortKeys: string[]) => {
        dispatch({ type: 'UPDATE', payload: sortKeys });
    };

    const query = getTables;
    const variables = {
        id: zoneId || countryId,
        dealerIds,
        sorting: [
            {
                ...sortedOn,
                order: sortedOn.order.toUpperCase(),
            },
        ],
        paging: {
            limit: paging.pageSize,
            offset: (paging.page - 1) * paging.pageSize,
        },
        search: search
            ? searchFields.map(field => {
                  return { identifier: field, value: search };
              })
            : null,
    };

    const { data, loading, error } = useQuery<GetTablesQuery>(query, {
        fetchPolicy: 'cache-and-network',
        variables,
        skip: isNil(dealerIds),
    });
    const items = data?.results?.items || [];
    const isLoading = loading && items.length <= 0;

    useEffect(() => {
        paging.setItemCount(data?.results?.count || 0);
    }, [paging, data]);

    const columns = useColumns(formatCurrencyDown);

    const [downloadModal, setDownloadModal] = useState<null | React.ReactElement>(null);

    const closeDownloadModal = useCallback(() => setDownloadModal(null), [setDownloadModal]);

    const openDownloadModal = useCallback(
        () =>
            setDownloadModal(
                <DownloadModal dealerIds={dealerIds ?? []} onClose={closeDownloadModal} type="Insurance" />
            ),
        [setDownloadModal, dealerIds, closeDownloadModal]
    );

    const footer = (
        <FootContainer>
            <FootBar>
                <FootBarButton onClick={openDownloadModal}>download</FootBarButton>
            </FootBar>
        </FootContainer>
    );

    return (
        <>
            <List
                columns={columns}
                error={error}
                footer={footer as any}
                headerBottomComponent={<DealerSelectionDropDown dealerIds={dealerIds} onValueChanged={setDealerIds} />}
                headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
                items={items}
                loading={isLoading}
                onItemClick={item =>
                    history.push(`/workflow/insuranceApplications/${item.version.id}`, { showCustomerSection: true })
                }
                onSort={onSort}
                paging={paging}
                sortedOn={[sortedOn.type, sortedOn.order]}
                title="Insurance Applications"
            />
            {downloadModal}
        </>
    );
};

export default withCompanyFormatting(ApplicationList);
