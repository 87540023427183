import { addDays } from 'date-fns';
import { getOr } from 'lodash/fp';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReduxFormContext } from 'redux-form';
import { BankPresetOption } from '../../../../../schema';
import DatePickerField from '../../../../shared/form-v2/DatePickerField';
import IdentityNumberField from '../../../../shared/form-v2/IdentityNumberField';
import TextField from '../../../../shared/form-v2/TextField';
import SubTitle from '../../../../ui/SubTitle';
import useBankPresetLabel from '../../useBankPresetLabel';
import { useFormContext } from '../context';
import DrivingLicense from './DrivingLicense';

const IdentityDetails = () => {
    const { disabled, allowPrimaryInfoChanges } = useFormContext();
    const { getValues } = useContext(ReduxFormContext);
    const { t } = useTranslation();

    const formValues = getValues();

    const presetOption = getOr(null, 'financeProduct.bank.presetOption', formValues);

    const getBankPresetLabel = useBankPresetLabel(presetOption);

    const isEnbdBankPreset = presetOption === BankPresetOption.ENBDBANK;

    return (
        <>
            <SubTitle>Identity Details</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label="Passport Number"
                        name="details.passport.number"
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DatePickerField label="Passport Expiry" name="details.passport.expiry" disabled />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <IdentityNumberField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getBankPresetLabel('emiratesIdNumber')}
                        name="identityNumber.value"
                        placeholder={t('customerDetails.placeholder.identityNumber')}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DatePickerField
                        label={getBankPresetLabel('emiratesIdExpiry')}
                        name="details.emiratesIdExpiry.value"
                        disabled
                    />
                </div>
                <DrivingLicense />
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DatePickerField
                        label={getBankPresetLabel('residentSince')}
                        name="details.uaeResidentSince.value"
                        disabled
                    />
                </div>
                {isEnbdBankPreset && (
                    <>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField
                                label={getBankPresetLabel('visaNumber')}
                                name="details.thirdParty.enbdBank.visaNumber.value"
                                disabled
                            />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <DatePickerField
                                label={getBankPresetLabel('visaIssueDate')}
                                max={new Date()}
                                name="details.thirdParty.enbdBank.visaIssueDate.value"
                                disabled
                            />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <DatePickerField
                                label={getBankPresetLabel('visaExpiryDate')}
                                min={new Date()}
                                name="details.thirdParty.enbdBank.visaExpiryDate.value"
                                disabled
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default IdentityDetails;
