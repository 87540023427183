import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authorizationReducer from './authorization';
import contextReducer from './context';
import formOptionsReducer from './formOptions';
import loadingReducer from './loading';
import notificationReducer from './notification';
import runtimeSettingsReducer from './runtimeSettings';

const rootReducer = combineReducers({
    form: formReducer,
    authorization: authorizationReducer,
    context: contextReducer,
    loading: loadingReducer,
    notifications: notificationReducer,
    formOptions: formOptionsReducer,
    runtimeSettings: runtimeSettingsReducer,
});

export default rootReducer;
