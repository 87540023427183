import { get } from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { PromoCodeUnit } from '../../../../../schema';
import { onOffOptions } from '../../../../../shared/constants/options';
import useTimeZones from '../../../../../utils/useTimeZones';
import DatePickerField from '../../../../template/DatePicker';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import NumberField from '../../../../template/Number';
import SwitchField from '../../../../template/Switch';
import TextareaField from '../../../../template/Textarea';
import UnitField from '../../../../template/options-field/UnitField';
import { useFormContext } from './context';

const MainDetails = () => {
    const { ct } = useContentTranslation();
    const timezones = useTimeZones();
    const { disabled, values, change, currency, rounding, initialValues, dealers } = useFormContext();
    const unit = get('unit', values);

    const dealerOptions = useMemo(() => dealers.map(dealer => ({ ...dealer, label: ct(dealer.label) })), [dealers, ct]);

    useEffect(() => {
        if (unit === PromoCodeUnit.NOTUSED) {
            change('value', null);
        }
        // update Remaining Quantity according to Initial Quantity
        if (initialValues.initialQuantity) {
            change(
                'remainingQuantity',
                values.initialQuantity - initialValues.initialQuantity + initialValues.remainingQuantity
            );
        } else {
            change('remainingQuantity', values.initialQuantity);
        }
    }, [change, initialValues, unit, values.initialQuantity]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Code*">
                        <Field component={InputField} disabled={disabled} name="identifier" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Start Date*">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ flex: 2 }}>
                                <Field component={DatePickerField} disabled={disabled} name="period.start" />
                            </div>
                            <div style={{ width: '10px' }} />
                            <div style={{ flex: 1 }}>
                                <DropdownField
                                    disabled={disabled}
                                    name="period.startTimeZone"
                                    onChangeEvent={value => change('period.endTimeZone', value)}
                                    options={timezones}
                                />
                            </div>
                        </div>
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="End Date*">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ flex: 2 }}>
                                <Field component={DatePickerField} disabled={disabled} name="period.end" />
                            </div>
                            <div style={{ width: '10px' }} />
                            <div style={{ flex: 1 }}>
                                <DropdownField
                                    disabled={disabled}
                                    name="period.endTimeZone"
                                    onChangeEvent={value => change('period.startTimeZone', value)}
                                    options={timezones}
                                />
                            </div>
                        </div>
                    </FieldContainer>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Value*">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ flex: 1 }}>
                                <UnitField currencySymbol={currency} disabled={disabled} name="unit" forPromo />
                            </div>
                            <div style={{ width: '10px' }} />
                            <div style={{ flex: 3 }}>
                                <Field
                                    component={NumberField}
                                    disabled={disabled || unit === PromoCodeUnit.NOTUSED}
                                    min={0}
                                    name="value"
                                    precision={rounding.percentage.count}
                                />
                            </div>
                        </div>
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DropdownField
                        disabled={disabled}
                        height="75px"
                        label="Assign to"
                        maxHeight="100px"
                        minHeight="75px"
                        name="dealerIds"
                        options={dealerOptions}
                        multi
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Initial Quantity*">
                        <Field component={NumberField} disabled={disabled} max={15} name="initialQuantity" />
                    </FieldContainer>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Remaining Quantity*">
                        <Field component={NumberField} name="remainingQuantity" disabled />
                    </FieldContainer>
                </div>

                {values.lastModified && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Last Modified">
                            <Field component={InputField} name="lastModified" disabled />
                        </FieldContainer>
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Deduct Value from Total Price">
                        <Field component={SwitchField} disabled={disabled} name="deductValue" options={onOffOptions} />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Active">
                        <Field component={SwitchField} disabled={disabled} name="isActive" options={onOffOptions} />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Description">
                        <Field component={TextareaField} disabled={disabled} maxLength="500" name="description" />
                    </FieldContainer>
                </div>
            </div>
        </div>
    );
};

export default MainDetails;
