import { get } from 'lodash/fp';
import * as validators from '../../../../../utils/validators';

const validate = validators.compose(
    validators.requiredString('identifier'),
    validators.requiredString('name'),
    validators.requiredString('modelId'),
    // todo add validation when there's sub models
    validators.requiredNumber('order'),
    validators.requiredString('type'),
    validators.requiredNumber('seatingCapacity'),
    validators.requiredNumber('year'),
    validators.requiredString('assetCondition'),
    validators.requiredBoolean('isActive'),
    validators.forEach('prices', (zoneId, values) => {
        const mandatories = get('mandatories', values);

        if (mandatories[zoneId]) {
            return validators.requiredNumber(`prices.${zoneId}`);
        }

        return () => null;
    }),
    validators.only(
        values => get('features', values) !== null && get('features', values) !== undefined,
        validators.requiredMarkdownList('features', 'Features must be list.')
    )
);

export default validate;
