import styled, { css } from 'styled-components';

const ParameterValue = styled.div`
    text-align: center;
    font-size: ${props => props.theme.calculator.labelSize};
    line-height: 1.2;
    vertical-align: baseline;
    width: 100%;
    overflow: ${props => (props.name === 'carOptions' ? 'visible' : 'hidden')};
    text-overflow: ellipsis;
    white-space: nowrap;

    ${props =>
        props.editable &&
        css`
            cursor: pointer;
            color: ${props.theme.calculator.valueColor};
        `}
`;

export default ParameterValue;
