import styled, { css } from 'styled-components';

export const NoTree = styled.div`
    text-align: center;
    margin-top: 10px;
    color: #000000;
`;

const TreeContainer = styled.div`
    color: #000000;
    .rc-tree li span.rc-tree-checkbox,
    .rc-tree li span.rc-tree-iconEle {
        display: none;
    }
    .rc-tree-switcher.rc-tree-switcher_open,
    .rc-tree-switcher.rc-tree-switcher_close {
        background: none !important;
        height: 0px !important;
        display: inline-table;
    }
    .disabled .rc-tree li .rc-tree-node-content-wrapper {
        cursor: default;
    }
    .rc-tree li .rc-tree-node-content-wrapper {
        height: 22px;
        padding: 0px 10px;
        margin: 2px 0px;
    }
    .rc-tree-node-content-wrapper:hover {
        background-color: #e6f8ff;
    }
    .rc-tree-node-selected {
        background-color: #a3e3ff;
        border: none;
    }
    .rc-tree-switcher.rc-tree-switcher_close i {
        border-left: 5px solid #636363 !important;
        border-top: 5px solid transparent !important;
        border-bottom: 5px solid transparent !important;
        border-right: 0px;
        margin-top: -3px;
    }

    ${props =>
        props.disabled &&
        css`
            .rc-tree li .rc-tree-node-content-wrapper {
                cursor: default !important;
            }
            .rc-tree-node-content-wrapper:hover,
            .rc-tree-node-selected {
                background-color: white !important;
                opacity: 1 !important;
            }
        `}
`;

export default TreeContainer;
