import { negate } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import CarOptions, { OptionInfo } from '../../ui/CarOptions';
import { useFormatOption } from '../display/CarOptionsField';

const UpdateCarOptions = ({ groupId, initialValue: initialOptions, valueRef, options }) => {
    const formatOption = useFormatOption();
    const groupFilter = useCallback(option => option.groupId === groupId, [groupId]);

    // create a state to update the value
    const [value, setValue] = useState(() => initialOptions.find(groupFilter));

    useEffect(() => {
        // update the value on the reference
        // eslint-disable-next-line no-param-reassign, react/prop-types
        valueRef.current = [...initialOptions.filter(negate(groupFilter)), value].filter(Boolean);
    }, [valueRef, value, initialOptions, groupFilter]);

    return (
        <>
            <CarOptions activeOption={value?.id} onChange={setValue} options={options} />
            {value && <OptionInfo>{formatOption(value)}</OptionInfo>}
        </>
    );
};

UpdateCarOptions.propTypes = {
    groupId: PropTypes.string.isRequired,
    initialValue: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    valueRef: PropTypes.shape({}).isRequired,
};

export default UpdateCarOptions;
