import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-size: 1.28rem;
    border-bottom: 1px solid #dedede;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 39px;
    text-transform: capitalize;
    margin-bottom: 10px;
`;

const Label = styled.div`
    min-width: 200px;
    font-size: 1.43rem;
    color: #000000;
`;

const Action = styled.button`
    flex: 1;
    background-color: transparent;
    border: none;
    outline: none;
    text-align: right;
    cursor: pointer;

    svg {
        color: #000000;
    }

    &:active,
    &:focus {
        outline: none;
    }
`;

const Portlet = ({ title, open: initOpen, closeable, children }) => {
    const [open, setOpen] = useState(initOpen);

    const toggle = useCallback(() => {
        if (closeable) {
            setOpen(state => !state);
        }
    }, [setOpen, closeable]);

    return (
        <Container>
            <Title>
                <Label>{title}</Label>
                {closeable && (
                    <Action onClick={toggle}>
                        <Icon icon={open ? faAngleUp : faAngleDown} />
                    </Action>
                )}
            </Title>
            {open && <div>{children}</div>}
        </Container>
    );
};

Portlet.propTypes = {
    children: PropTypes.node.isRequired,
    closeable: PropTypes.bool,
    open: PropTypes.bool,
    title: PropTypes.string.isRequired,
};

export default Portlet;
