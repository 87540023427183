import { useApolloClient } from '@apollo/client';
import { omit, get } from 'lodash/fp';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getFormValues } from 'redux-form';
import { addNotification } from '../../../../../actions';
import { useContentTranslation } from '../../../../../i18n';
import { ApplicationEventType, ApplicationPhase } from '../../../../../schema';
import { getCurrentCountry, getGlobalPermissions, getRuntimeSettings } from '../../../../../selectors';
import * as notification from '../../../../../shared/constants/notification';
import { APPLICATION_UPDATE_NOTIFICATION } from '../../../../../shared/constants/notification';
import { handleResponseError } from '../../../../../utilities/forms';
import applySourceChange from '../../../../../utils/applySourceChange';
import { ApplicationPermissions, getApplicationPermissions } from '../../../../../utils/permissions';
import { withModal, WithModalInjectedProps } from '../../../../Modal';
import useValidationContext from '../../../../utilities/useValidationContext';
import ApplicationForm from '../../Finance/application/ApplicationForm';
import DownloadDocumentsModal from '../../Finance/application/DownloadDocumentsModal';
import Form from '../../Finance/application/Form';
import { ApplicationFormValues } from '../../Finance/application/Form/context';
import {
    update,
    createUsedVariant,
    CreateUsedVariantMutation,
    CreateUsedVariantMutationVariables,
} from '../../Finance/application/Page.graphql';
import useDownloadDocuments from '../../Finance/application/useDownloadDocuments';
import { PreOwnedCarDetailsDataFragment, VariantDataFragment } from '../../common/data/useLoadVariants.graphql';
import { retry, concludeAgreementApplication } from '../../common/shared/Action.graphql';
import { updateCustomer } from '../../common/shared/customer.graphql';
import { mapUpdateCustomerStateToPayload } from '../../common/utilities/map';
import toPayload from '../../common/utilities/toPayload';
import useButtonStatus from '../../common/utilities/useButtonStatus';
import useConfirm from '../../common/utilities/useConfirm';
import useFormChanged from '../../common/utilities/useFormChanged';
import useStop from '../../common/utilities/useStop';

const {
    APPLICATION_APPROVE_NOTIFICATION,
    APPLICATION_CANCEL_NOTIFICATION,
    APPLICATION_COMPLETE_NOTIFICATION,
    APPLICATION_DECLINE_NOTIFICATION,
    APPLICATION_SUBMIT_NOTIFICATION,
    APPLICATION_CONCLUDE_AGREEMENT_NOTICATION,
} = notification;

export const useManagedCallback = () => {
    const threadRef = useRef(null);

    return useCallback(
        // @ts-ignore
        callback => async (...args) => {
            if (threadRef.current) {
                // skip it
                return undefined;
            }

            try {
                threadRef.current = callback(...args);

                return await threadRef.current;
            } finally {
                threadRef.current = null;
            }
        },
        [threadRef]
    );
};

const isUsedCarChanged = (
    current: VariantDataFragment,
    initial: VariantDataFragment,
    formatPath: (path: string) => string
) => {
    if (get(formatPath('model.make.name'), initial) !== get(formatPath('model.make.name'), current)) {
        return true;
    }

    if (initial.model.name !== current.model.name) {
        return true;
    }

    if (!current.preOwnedCarDetails || !initial.preOwnedCarDetails) {
        return false;
    }

    return Object.keys(current.preOwnedCarDetails).find(key => {
        const getWithKey = get(`preOwnedCarDetails.${key}`);

        return getWithKey(initial) !== getWithKey(current);
    });
};

export type PageProps = {
    initialValues?: ApplicationFormValues | null;
    backUrl: string;
};

const Page = ({ initialValues = null, modal, backUrl }: PageProps & WithModalInjectedProps) => {
    const { formatPath, mapIntlValue, ct, language: defaultLanguage } = useContentTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { channelSetting, id: countryId, code } = useSelector(getCurrentCountry);
    const { allowOptions } = channelSetting.new;
    const { useCustomerBirthDay, useCustomerNationality } = useSelector(getRuntimeSettings);
    const { mayManageReservations } = useSelector(getGlobalPermissions);
    const [invalidCalculator, setInvalidCalculator] = useState(false);

    const setCalculatorStatus = useCallback((status: boolean) => setInvalidCalculator(status), [setInvalidCalculator]);

    const client = useApolloClient();

    const onCancel = useCallback(() => history.goBack(), [history]);

    const values = useSelector(getFormValues('application')) as ApplicationFormValues;

    const isStop = useStop(invalidCalculator, modal, values);

    const { state } = useLocation<{ customerId: string }>();
    const redirect = useCallback(() => {
        if (state?.customerId) {
            history.push(`/customer/${state?.customerId}`);

            return;
        }

        history.push(backUrl);
    }, [backUrl, history, state]);

    const withManagement = useManagedCallback();
    const [downloadState, downloadAction] = useDownloadDocuments(initialValues);

    const handleRedirection = useCallback(
        type => {
            const { identifier } = values || {};

            switch (type) {
                case 0:
                    dispatch(addNotification(APPLICATION_CANCEL_NOTIFICATION(identifier)));
                    break;

                case 1:
                    dispatch(addNotification(APPLICATION_COMPLETE_NOTIFICATION(identifier)));
                    break;

                case 2:
                    dispatch(addNotification(APPLICATION_DECLINE_NOTIFICATION(identifier)));
                    break;

                case 3:
                    dispatch(addNotification(APPLICATION_APPROVE_NOTIFICATION(identifier)));
                    break;

                case 4:
                    dispatch(addNotification(APPLICATION_SUBMIT_NOTIFICATION(identifier)));
                    break;

                case 5:
                    dispatch(addNotification(APPLICATION_UPDATE_NOTIFICATION(identifier)));
                    break;

                case 6:
                    dispatch(addNotification(APPLICATION_CONCLUDE_AGREEMENT_NOTICATION(identifier)));
                    break;

                default:
                    break;
            }
            redirect();
        },
        [values, redirect, dispatch]
    );

    const isUsedCar = useMemo(() => values?.variant?.channels.express, [values]);

    const onSubmit = useCallback(
        withManagement(async () => {
            const stop = await isStop();
            if (stop) {
                return null;
            }

            const customerData = applySourceChange(values.customer, initialValues?.customer);

            // update customer
            await client.mutate({
                mutation: updateCustomer,
                variables: mapUpdateCustomerStateToPayload(customerData),
            });

            const { guarantor } = values;

            // update guarantor
            if (guarantor) {
                await client.mutate({
                    mutation: updateCustomer,
                    variables: mapUpdateCustomerStateToPayload(guarantor),
                });
            }

            // for express car, if values changed, need get new variant
            let newVariant;
            if (
                isUsedCar &&
                initialValues?.variant &&
                isUsedCarChanged(values.variant, initialValues.variant, formatPath)
            ) {
                const { data } = await client.mutate<CreateUsedVariantMutation, CreateUsedVariantMutationVariables>({
                    mutation: createUsedVariant,
                    variables: {
                        data: {
                            countryId,
                            // for express use model name as variant name
                            name: mapIntlValue({ [defaultLanguage]: ct(values.variant.model.name) }),
                            makeName: mapIntlValue({ [defaultLanguage]: ct(values.variant.model.make.name) }),
                            modelName: mapIntlValue({ [defaultLanguage]: ct(values.variant.model.name) }),
                            preOwnedCarDetails: omit(
                                ['vehicleLogCard', '__typename'],
                                values.variant.preOwnedCarDetails
                            ) as PreOwnedCarDetailsDataFragment,
                        },
                    },
                });
                newVariant = data?.variant;
            }

            const variables = toPayload(values, allowOptions, newVariant);

            const promise = client.mutate({ mutation: update, variables });

            promise.then(() => handleRedirection(4));

            return promise.catch(handleResponseError);
        }),
        [isStop, values, client, isUsedCar, allowOptions, countryId, handleRedirection, withManagement]
    );

    const onRetry = useCallback(
        withManagement(() =>
            client
                .mutate({ mutation: retry, variables: { applicationId: initialValues?.id } })
                .then(() => handleRedirection(4))
                .catch(handleResponseError)
        ),
        [client, handleRedirection, initialValues, withManagement]
    );

    const onSave = useCallback(
        withManagement(() => {
            const customerData = applySourceChange(values.customer, initialValues?.customer);
            client
                .mutate({
                    mutation: updateCustomer,
                    variables: mapUpdateCustomerStateToPayload(customerData),
                })
                .then(() => handleRedirection(5))
                .catch(handleResponseError);
        }),
        [client, values, withManagement]
    );

    const onAgreementConclude = useCallback(
        withManagement(() => {
            client
                .mutate({ mutation: concludeAgreementApplication, variables: { id: initialValues?.id } })
                .then(() => handleRedirection(6));
        }),
        [client, values, withManagement]
    );

    const {
        maySubmitChangesOnApplication,
        mayRetrySubmitChangesOnApplication,
        maySaveChangesOnApplication,
        mayAgreementConcluded,
    } = useMemo(() => (initialValues && getApplicationPermissions(initialValues)) || ({} as ApplicationPermissions), [
        initialValues,
    ]);

    const { isFormDisabled, submitChangesDisabled, saveChangesDisabled, downloadDisabled } =
        useButtonStatus(initialValues, mayManageReservations, maySubmitChangesOnApplication) || {};

    const hasChanged = useFormChanged('application');
    const mayRetrySubmit = mayRetrySubmitChangesOnApplication && !hasChanged;

    const maySubmitChange = maySubmitChangesOnApplication && hasChanged;

    const action = useConfirm(modal);
    const validation = useValidationContext();

    const bodyComponent = useMemo(
        () =>
            initialValues && (
                <Form
                    allowPrimaryInfoChanges={maySaveChangesOnApplication}
                    applicationPhase={ApplicationPhase.RESERVATION}
                    countryCode={code}
                    disabled={isFormDisabled}
                    initialValues={initialValues}
                    setCalculatorStatus={setCalculatorStatus}
                    useCustomerBirthDay={useCustomerBirthDay}
                    useCustomerNationality={useCustomerNationality}
                    validation={validation}
                />
            ),
        [
            initialValues,
            isFormDisabled,
            maySaveChangesOnApplication,
            setCalculatorStatus,
            useCustomerBirthDay,
            useCustomerNationality,
            validation,
            code,
        ]
    );

    const hasSigned = !!initialValues?.events.find(item => item.type === ApplicationEventType.RECEIVE);
    const moreActions = useMemo(
        () =>
            [
                mayManageReservations && {
                    label: 'download documents',
                    disabled: downloadDisabled,
                    onAction: downloadAction.getDocuments,
                },
                mayManageReservations &&
                    mayAgreementConcluded && {
                        label: 'agreement concluded',
                        disabled: false,
                        onAction: () => action(6, onAgreementConclude),
                    },
                mayManageReservations &&
                    mayRetrySubmit && {
                        label: 'retry',
                        onAction: onRetry,
                    },
                mayManageReservations &&
                    maySubmitChange && {
                        label: hasSigned ? 'submit changes' : 'submit',
                        disabled: submitChangesDisabled,
                        onAction: onSubmit,
                    },
                mayManageReservations &&
                    maySaveChangesOnApplication && {
                        label: 'Save',
                        disabled: saveChangesDisabled,
                        onAction: onSave,
                    },
            ].filter(Boolean),
        [
            mayManageReservations,
            downloadDisabled,
            downloadAction.getDocuments,
            mayRetrySubmit,
            onRetry,
            maySubmitChange,
            hasSigned,
            submitChangesDisabled,
            onSubmit,
            maySaveChangesOnApplication,
            saveChangesDisabled,
            onSave,
            action,
            mayAgreementConcluded,
            onAgreementConclude,
        ]
    );

    const title = useMemo(() => {
        if (values) {
            return `Reservation Details - ${values.identifier}`;
        }

        return 'Reservation Details';
    }, [values]);

    return (
        <>
            <DownloadDocumentsModal isShow={downloadState.isShow} onClose={downloadAction.onClose} />
            <ApplicationForm
                bodyComponent={bodyComponent}
                moreActions={moreActions}
                onCancel={onCancel}
                onSubmit={onSubmit}
                title={title}
            />
        </>
    );
};

export default withModal(Page);
