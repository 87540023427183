import React from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getFormValues, reduxForm, InjectedFormProps } from 'redux-form';
import { FormTabContainer } from '../../../../../../containers/Layout';
import ActivityLog from './ActivityLog';
import Application from './Application';
import Document from './Document';
import { ApplicationFormValues, Context, FormProps } from './context';
import validate from './validate';

const Form = (props: FormProps & InjectedFormProps<ApplicationFormValues, FormProps>) => {
    const { form } = props;
    const values = useSelector(getFormValues(form)) as ApplicationFormValues;
    const context = { ...props, values };

    return (
        <Context.Provider value={context}>
            <FormTabContainer>
                <Tabs className="tabs">
                    <TabList>
                        <Tab>Application</Tab>
                        <Tab>Document</Tab>
                        <Tab>Activity Log</Tab>
                    </TabList>
                    <TabPanel>
                        <Application />
                    </TabPanel>
                    <TabPanel>
                        <Document />
                    </TabPanel>
                    <TabPanel>
                        <ActivityLog canAddComment />
                    </TabPanel>
                </Tabs>
            </FormTabContainer>
        </Context.Provider>
    );
};

// @ts-ignore
export default reduxForm<ApplicationFormValues, FormProps>({ form: 'application', validate })(Form);
