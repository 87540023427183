import { useApolloClient } from '@apollo/client';
import { isObject } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import { submit } from 'redux-form';
import { addNotification } from '../../../actions';
import { useContentTranslation } from '../../../i18n';
import { getGlobalPermissions } from '../../../selectors';
import { MAINTENANCE_SAVE_NOTIFICATION } from '../../../shared/constants/notification';
import { handleResponseError, prepareForGraphQL } from '../../../utilities/forms';
import { convertLocalToUTC } from '../../../utils/timezones';
import useLanguageSelector from '../../../utils/useLanguageSelector';
import FormLayout from '../../ui/form/FormLayout';
import Form from './Form';
import { updateMaintenance, uploadImage, deleteImage } from './Page.graphql';

const Page = ({ countryId, initialValues }) => {
    const dispatch = useDispatch();
    const { mayViewMaintenance, mayManageMaintenance } = useSelector(getGlobalPermissions);
    const client = useApolloClient();
    const history = useHistory();
    const languageSelector = useLanguageSelector();
    const { mapIntlValue } = useContentTranslation();

    const submitForm = useCallback(() => dispatch(submit('maintenance')), [dispatch]);
    const onCancel = useCallback(() => history.goBack(), [history]);

    const onSubmit = useCallback(
        ({ image: file, start, end, ...values }) => {
            let promise = Promise.resolve();

            if (file instanceof File) {
                // must upload a new file
                promise = client.mutate({ mutation: uploadImage, variables: { countryId, file } });
            } else if (file === null && isObject(initialValues.image)) {
                // must remove an existing file
                promise = client.mutate({ mutation: deleteImage, variables: { countryId } });
            }

            // we save the maintenance after the upload
            // because on save it will trigger the publish subscribe events
            // however if the image is saved later, it will be published without the image
            promise = promise.then(() =>
                client.mutate({
                    mutation: updateMaintenance,
                    variables: {
                        countryId,
                        data: prepareForGraphQL({
                            ...values,
                            title: mapIntlValue(values.title),
                            description: mapIntlValue(values.description),
                            start: convertLocalToUTC(start, values.startTimeZone),
                            end: convertLocalToUTC(end, values.endTimeZone),
                        }),
                    },
                })
            );

            return promise.catch(handleResponseError);
        },
        [countryId, initialValues, client, mapIntlValue]
    );

    const onSubmitSuccess = useCallback(() => {
        dispatch(addNotification(MAINTENANCE_SAVE_NOTIFICATION));
        history.push('/home');
    }, [dispatch, history]);

    if (!mayViewMaintenance) {
        return <Redirect to="/home" />;
    }

    return (
        <FormLayout
            bodyComponent={
                initialValues ? (
                    <Form
                        {...languageSelector}
                        disabled={!mayManageMaintenance}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onSubmitSuccess={onSubmitSuccess}
                    />
                ) : null
            }
            moreActions={[
                {
                    label: 'save',
                    onAction: submitForm,
                },
            ]}
            onCancel={onCancel}
            title="Maintenance Details"
        />
    );
};

Page.propTypes = {
    countryId: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({
        image: PropTypes.shape({}),
    }),
};

export default Page;
