import { get, isEmpty } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import useFormatDateTime from '../../../../shared/useFormatDateTime';
import ApvPortlet from '../../../../template/Portlet';
import { cells, ListTable } from '../../../../ui/lists';
import { RoleFormContext, useFormContext } from './context';

const useColumns = () => {
    const formatDateTime = useFormatDateTime();

    return useMemo(
        () => [
            { name: 'User Name', id: 'username', renderCell: get('username'), underline: true },
            { name: 'Name', id: 'name', renderCell: get('name') },
            { name: 'Email', id: 'email', renderCell: get('email') },
            { name: 'Contact', id: 'phone', renderCell: get('phone') },
            { name: 'Country', id: 'countries[0].country.name', renderCell: get('countries[0].country.name') },
            {
                name: 'Last Signed In',
                id: 'lastLoginAt',
                renderCell: cells.renderDateTime('lastLoginAt', formatDateTime),
            },
            { name: 'Active', id: 'isActive', renderCell: cells.renderActive() },
        ],
        [formatDateTime]
    );
};

const Users = () => {
    const { values } = useFormContext() as RoleFormContext;
    const { users } = values;

    const history = useHistory();

    const columns = useColumns();

    if (isEmpty(users)) {
        return null;
    }

    return (
        <ApvPortlet name="Users" closable open>
            <div className="container-fluid">
                <br />
                <ListTable
                    columns={columns}
                    items={users}
                    onItemClick={item => history.push(`/access/users/${item.id}`)}
                />
            </div>
        </ApvPortlet>
    );
};

export default Users;
