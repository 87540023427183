import { useApolloClient } from '@apollo/client';
import { get, omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { submit, formValueSelector } from 'redux-form';
import { addNotification } from '../../../../actions';
import { useContentTranslation } from '../../../../i18n';
import { InterestRateMode, Unit } from '../../../../schema';
import { getCurrentUserCountryDealers, getGlobalPermissions, getUser } from '../../../../selectors';
import { FINANCE_DELETE_NOTIFICATION, FINANCE_SAVE_NOTIFICATION } from '../../../../shared/constants/notification';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import useLanguageSelector from '../../../../utils/useLanguageSelector';
import useValidation from '../../../../utils/useValidation';
import { useModal } from '../../../Modal';
import FormLayout from '../../../ui/form/FormLayout';
import Form from './Form';
import validator from './Form/validate';
import { create, update, remove } from './Page.graphql';

const getUnitValidationDetails = (conditions = []) => {
    return conditions.reduce((acc, { condition, details, name }) => {
        if (condition()) {
            return { ...acc, [name]: details };
        }

        return acc;
    }, {});
};

const currencyMinMax = { min: 0, max: Infinity };

const Page = ({ initialValues = null }) => {
    const languageSelector = useLanguageSelector();
    const { mapIntlValue } = useContentTranslation();

    const selector = formValueSelector('financeProduct');
    const financeProduct = useSelector(state => selector(state, 'downPaymentSetting', 'loanSetting'));
    const downPaymentUnit = get('downPaymentSetting.defaultUnit', financeProduct);
    const loanUnit = get('loanSetting.defaultUnit', financeProduct);
    const iccDownpaymentUnit = get('downPaymentSetting.icc.defaultUnit', financeProduct);

    const financeProductValidator = useMemo(() => {
        const validationConditions = [
            {
                name: 'downPayment',
                condition: () => downPaymentUnit === Unit.CURRENCY && loanUnit === Unit.CURRENCY,
                details: { range: currencyMinMax },
            },
            {
                name: 'loan',
                condition: () => downPaymentUnit === Unit.CURRENCY && loanUnit === Unit.CURRENCY,
                details: { range: currencyMinMax },
            },
            {
                name: 'icc',
                condition: () => iccDownpaymentUnit === Unit.CURRENCY,
                details: { range: currencyMinMax },
            },
        ];
        const validationDetails = getUnitValidationDetails(validationConditions);

        return validator(validationDetails);
    }, [downPaymentUnit, loanUnit, iccDownpaymentUnit]);

    const validate = useValidation(financeProductValidator);

    const modal = useModal();
    const history = useHistory();
    const dispatch = useDispatch();
    const { mayManageFinanceProducts } = useSelector(getGlobalPermissions);

    const submitForm = useCallback(() => {
        dispatch(submit('financeProduct'));
    }, [dispatch]);

    const client = useApolloClient();
    const onSubmit = useCallback(
        async ({ id, ...data }) => {
            const query = id ? update : create;
            const cleanData = prepareForGraphQL(omit(['finderDealerIds', 'dealerId', 'csvDealerIds'], data));
            const { downPaymentSetting, name, description, interestRateSetting, termSetting } = cleanData;

            const interestRateSettingTable =
                interestRateSetting.mode === InterestRateMode.TABLE
                    ? (interestRateSetting?.table ?? []).filter(
                          item =>
                              item?.basedOnAmount >= interestRateSetting?.min &&
                              item?.basedOnAmount <= interestRateSetting?.max &&
                              item?.term >= termSetting?.min &&
                              item?.term <= termSetting?.max
                      )
                    : interestRateSetting?.table;

            const variables = {
                data: {
                    ...cleanData,
                    interestRateSetting: {
                        ...interestRateSetting,
                        table: interestRateSettingTable,
                    },
                    downPaymentSetting: {
                        ...downPaymentSetting,
                        icc:
                            downPaymentSetting.icc?.min ||
                            downPaymentSetting.icc?.max ||
                            downPaymentSetting.icc?.default
                                ? downPaymentSetting.icc
                                : null,
                    },
                    name: mapIntlValue(name),
                    description: mapIntlValue(description),
                },
                id,
            };

            if (
                cleanData.balloonGFVSetting &&
                (cleanData.balloonGFVSetting?.marginOfFinance?.min || cleanData.balloonGFVSetting?.marginOfFinance?.max)
            ) {
                const { marginOfFinance } = cleanData.balloonGFVSetting;

                variables.balloonGFVSetting = {
                    ...cleanData.balloonGFVSetting,
                    // set value to null if it stored as zero
                    marginOfFinance: {
                        ...(marginOfFinance ?? {}),
                        min: marginOfFinance?.min > 0 ? marginOfFinance?.min : null,
                        max: marginOfFinance?.max > 0 ? marginOfFinance?.max : null,
                    },
                    settlementInstalmentOn: cleanData.balloonGFVSetting?.settlementInstalmentOn ?? 0,
                };
            }

            if (id) {
                // we need to remove some more dta
                variables.data = omit(['countryId', 'version', 'lastModified'], variables.data);
            }

            return client.mutate({ mutation: query, variables }).catch(handleResponseError);
        },
        [client, mapIntlValue]
    );

    const onSubmitSuccess = useCallback(() => {
        dispatch(addNotification(FINANCE_SAVE_NOTIFICATION));
        history.push('/finance/products');
    }, [history, dispatch]);

    const onDelete = useCallback(() => {
        modal.confirm({
            title: '',
            content: 'Are you sure you want to delete this finance product?',
            options: [
                { label: 'No', action: () => null },
                {
                    label: 'Yes',
                    action: () =>
                        client.mutate({ mutation: remove, variables: { id: initialValues?.id } }).then(() => {
                            dispatch(addNotification(FINANCE_DELETE_NOTIFICATION));
                            history.push('/finance/products');
                        }),
                },
            ],
        });
    }, [client, dispatch, history, initialValues, modal]);

    const onCancel = useCallback(() => history.goBack(), [history]);
    const user = useSelector(getUser) || {};

    const userDealers = useSelector(getCurrentUserCountryDealers);
    const mayDelete = useMemo(
        () =>
            user?.isSuperUser ||
            (mayManageFinanceProducts && initialValues?.dealerIds?.every(dealerId => userDealers.includes(dealerId))),
        [initialValues, user, mayManageFinanceProducts, userDealers]
    );

    return (
        <FormLayout
            bodyComponent={
                initialValues && (
                    <Form
                        {...languageSelector}
                        disabled={!mayManageFinanceProducts}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onSubmitSuccess={onSubmitSuccess}
                        validate={validate}
                    />
                )
            }
            moreActions={[
                mayManageFinanceProducts &&
                    initialValues?.id && {
                        label: 'delete',
                        onAction: onDelete,
                        disabled: !mayDelete,
                    },
                mayManageFinanceProducts && {
                    label: 'save',
                    onAction: submitForm,
                },
            ]}
            onCancel={onCancel}
            title="Financial Product Details"
        />
    );
};

Page.propTypes = {
    initialValues: PropTypes.shape({}),
};

export default Page;
