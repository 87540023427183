import PropTypes from 'prop-types';
import React from 'react';
import SelectField from '../../shared/form-v2/SelectField';
import useSalesPerson from './useSalesPerson';

const UserSelectField = props => {
    const { dealerId } = props;
    const [data] = useSalesPerson([dealerId]);
    const slaesOptions = data && data?.sales.map(({ name, id }) => ({ label: name, value: id }));

    return <SelectField.Outline {...props} options={data && data?.sales ? slaesOptions : []} />;
};

UserSelectField.propTypes = {
    dealerId: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onChangeEvent: PropTypes.func,
};

export default UserSelectField;
