import { ApplicationCustomerDataFragment } from '../components/routes/Workflow/common/data/Application.graphql';
import { CustomerDetailsSource } from '../schema';

const applySourceChange = (
    newCustomerData?: ApplicationCustomerDataFragment,
    initialCustomerData?: ApplicationCustomerDataFragment
) => {
    const customerData = newCustomerData;
    if (newCustomerData && initialCustomerData && customerData) {
        if (newCustomerData.name?.value !== initialCustomerData.name?.value) {
            customerData.name.source = CustomerDetailsSource.MANUAL;
        }
        if (newCustomerData.firstName?.value !== initialCustomerData.firstName?.value) {
            customerData.firstName.source = CustomerDetailsSource.MANUAL;
        }
        if (newCustomerData.lastName?.value !== initialCustomerData.lastName?.value) {
            customerData.lastName.source = CustomerDetailsSource.MANUAL;
        }
        if (
            customerData.details?.maritalStatus &&
            newCustomerData.details?.maritalStatus?.value !== initialCustomerData.details?.maritalStatus?.value
        ) {
            customerData.details.maritalStatus.source = CustomerDetailsSource.MANUAL;
        }
    }

    return customerData;
};

export default applySourceChange;
