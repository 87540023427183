import { getOr, flow, map } from 'lodash/fp';
import React, { useContext, useMemo } from 'react';
import { ReduxFormContext } from 'redux-form';
import { BankPresetOption } from '../../../../../schema';
import { AddressType } from '../../../../../utilities/constants/optionTypes';
import SelectField from '../../../../shared/form-v2/SelectField';
import TextField from '../../../../shared/form-v2/TextField';
import SubTitle from '../../../../ui/SubTitle';
import usePhone from '../../../../utilities/usePhone';
import useBankPresetLabel from '../../useBankPresetLabel';
import { useFormContext } from '../context';

const getOfficeEmirateName = (bankPresetOption: BankPresetOption) => {
    switch (bankPresetOption) {
        case BankPresetOption.PMESA:
            return 'details.thirdParty.pmeSA.emirate.value';

        case BankPresetOption.ENBDBANK:
            return 'details.office.city';

        default:
            return 'details.emirate.value';
    }
};

const ContactDetails = () => {
    const { sectionPrefix, getValues } = useContext(ReduxFormContext);
    const formValues = getValues();
    const { disabled, options, allowPrimaryInfoChanges } = useFormContext();
    const countryCode = getOr('', 'zone.country.code', formValues);
    const bankPresetOption = getOr(null, 'financeProduct.bank.presetOption', formValues);

    const { code: phoneCountryCode } = usePhone(countryCode);
    const customer = getOr(formValues, `${sectionPrefix}`, formValues);
    const emirates = useMemo(() => {
        if (bankPresetOption === BankPresetOption.PMESA) {
            return (
                options?.cities
                    .filter(({ countryCode }) => countryCode === 'SA')
                    .map(({ name }) => ({ label: name, value: name })) || []
            );
        }

        return flow([map(({ code, name }) => ({ value: code, label: name }))])(options?.emirates || []);
    }, [bankPresetOption, options]);

    const getBankPresetLabel = useBankPresetLabel(bankPresetOption);

    const isPMESAPreset = bankPresetOption === BankPresetOption.PMESA;
    const isEnbdBankPreset = bankPresetOption === BankPresetOption.ENBDBANK;

    const renderResidenceAddress = useMemo(() => {
        return (
            <>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getBankPresetLabel('residenceAddress', 'address')}
                        name="details.residentialAddress.fullAddress"
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getBankPresetLabel('residencePoBox', 'poBox')}
                        name="details.residentialAddress.poBox"
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getBankPresetLabel('residenceCity', 'emirate')}
                        name={isPMESAPreset ? 'details.thirdParty.pmeSA.emirate.value' : 'details.emirate.value'}
                        options={emirates}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label="Residence Type"
                        name="details.residentialAddress.type"
                        options={getOr([], 'residenceTypes', options)}
                    />
                </div>
            </>
        );
    }, [allowPrimaryInfoChanges, disabled, emirates, getBankPresetLabel, isPMESAPreset, options]);

    const renderOfficeAddress = useMemo(() => {
        return (
            <>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getBankPresetLabel('officeAddress', 'address')}
                        name="details.office.fullAddress"
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getBankPresetLabel('officePoBox', 'poBox')}
                        name="details.office.poBox"
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getBankPresetLabel('officeCity', 'emirate')}
                        name={getOfficeEmirateName(bankPresetOption)}
                        options={emirates}
                    />
                </div>
                {!isEnbdBankPreset && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            disabled={!allowPrimaryInfoChanges && disabled}
                            label={getBankPresetLabel('officeType', 'residenceType')}
                            name="details.office.type"
                            options={getOr([], 'residenceTypes', options)}
                        />
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label="Office Landline Number"
                        name="details.office.phone"
                        prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                    />
                </div>
            </>
        );
    }, [
        allowPrimaryInfoChanges,
        disabled,
        getBankPresetLabel,
        bankPresetOption,
        emirates,
        isEnbdBankPreset,
        options,
        phoneCountryCode,
    ]);

    return (
        <>
            <SubTitle>Contact Details</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getBankPresetLabel('addressType')}
                        name="details.addressType.value"
                        options={getOr([], 'addressTypes', options)}
                    />
                </div>
                {(isEnbdBankPreset ||
                    (!isEnbdBankPreset && customer?.details?.addressType?.value === AddressType.RESIDENCE)) &&
                    renderResidenceAddress}
                {!isEnbdBankPreset &&
                    customer?.details?.addressType?.value === AddressType.OFFICE &&
                    renderOfficeAddress}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label="Home Country Address"
                        name="details.homeCountryAddress.fullAddress"
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label="Home Country"
                        name="details.homeCountryAddress.country"
                        options={getOr([], 'countries', options)}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label="Home Country Phone Number"
                        name="details.homeCountryAddress.phone"
                    />
                </div>
                {isEnbdBankPreset && renderOfficeAddress}
            </div>
        </>
    );
};

export default ContactDetails;
