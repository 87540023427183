import { get } from 'lodash/fp';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, ReduxFormContext } from 'redux-form';

const useToggleOtherField = (field: string, otherField: string) => {
    const { form, change } = useContext(ReduxFormContext);
    const valueSelector = useCallback(state => getFormValues(form)(state), [form]);
    const values = useSelector(valueSelector);
    const dispatch = useDispatch();

    const currentValue = get(field, values);
    const previousValue = useRef(currentValue);

    useEffect(() => {
        if (previousValue.current !== currentValue) {
            if (!currentValue) {
                dispatch(change(otherField, true));
            }

            previousValue.current = currentValue;
        }
    }, [currentValue, previousValue, change, otherField, dispatch]);
};

export default useToggleOtherField;
