import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import List from '../List';

const ErrorListItem = styled.li`
    list-style: disc;
    list-style-position: inside;
    text-indent: -1.4rem;
    padding-left: 20px;
`;

const UpdateModalContainer = styled.div`
    position: relative;
    padding: 10px;
`;

const ErrorContentModal = ({ errors, errorTitle }) => {
    return (
        <UpdateModalContainer>
            <p>{errorTitle}</p>
            <List>
                {errors.map(error => (
                    <ErrorListItem key={error}>{error}</ErrorListItem>
                ))}
            </List>
        </UpdateModalContainer>
    );
};

ErrorContentModal.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string),
    errorTitle: PropTypes.string.isRequired,
};

export default ErrorContentModal;
