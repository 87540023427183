import { get } from 'lodash/fp';
import * as validators from '../../../../../utils/validators';

const validate = validators.compose(
    validators.requiredString('variantId'),
    validators.forEach('sets', (setIndex, values) => {
        const value = get(`sets[${setIndex}]`, values);

        const { remainingStock, __exclude: inventory } = value;
        const { reservedStock, deductedStock } = inventory;

        const minRemaining = reservedStock + deductedStock;
        const minInitial = Math.max(minRemaining, remainingStock);

        return validators.compose(
            validators.requiredNumber(`sets[${setIndex}].initialStock`),
            validators.requiredNumberGreaterOrEqualThanNumber(`sets[${setIndex}].initialStock`, minInitial)
        );
    })
);

export default validate;
