import {
    OutlineWrapperV2 as OutlineWrapper,
    PureErrorV2 as PureError,
    TextAreaV2 as TextArea,
} from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React, { forwardRef, useMemo, useState } from 'react';
import { Field } from 'redux-form';

export const TextAreaInput = forwardRef((props, ref) => {
    const {
        input,
        fixedValue,
        inputComponent: Input = TextArea,
        wrapperComponent: Wrapper = OutlineWrapper,
        errorComponent: Error = PureError,
        wrapperProps,
        meta,
        label,
        onBlurValue,
        withFocusClear,
        ...inputProps
    } = props;

    const { active, touched, error = null } = meta;
    const hasError = !active && touched && !!error?.trim();

    const { onChange, onFocus: defaultOnFocus, onBlur: defaultOnBlur, value } = input;

    const [lastValue, setLastValue] = useState(value);

    const onFocus = useMemo(() => {
        if (withFocusClear) {
            return event => {
                setLastValue(value);

                // update state to blank
                onChange('');

                // return focus to handle active meta
                return defaultOnFocus(event);
            };
        }

        return defaultOnFocus;
    }, [withFocusClear, onChange, defaultOnFocus, setLastValue, value]);

    const onBlur = useMemo(() => {
        if (withFocusClear) {
            return event => {
                if (event.target.value !== '') {
                    // return blur to handle touched meta
                    return defaultOnBlur(event);
                }

                return onChange(lastValue);
            };
        }

        if (onBlurValue !== undefined) {
            return event => {
                if (event.target.value === '') {
                    return onChange(onBlurValue);
                }

                return defaultOnBlur(event);
            };
        }

        return defaultOnBlur;
    }, [onBlurValue, onChange, defaultOnBlur, lastValue, withFocusClear]);

    return (
        <div>
            <Wrapper label={label} meta={meta} name={input.name} {...wrapperProps} forTextArea>
                <Input
                    {...input}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    {...inputProps}
                    ref={ref}
                    value={fixedValue || value}
                />
            </Wrapper>
            {hasError && <Error>{error}</Error>}
        </div>
    );
});

TextAreaInput.displayName = 'TextAreaInput';

TextAreaInput.propTypes = {
    errorComponent: PropTypes.elementType,
    fixedValue: PropTypes.string,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.onFocus,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    }).isRequired,
    inputComponent: PropTypes.elementType,
    label: PropTypes.string,
    meta: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        error: PropTypes.string,
        touched: PropTypes.bool.isRequired,
    }).isRequired,
    onBlurValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    withFocusClear: PropTypes.bool,
    wrapperComponent: PropTypes.elementType,
    // be careful when using wrapper properties, this might break optimization on pure components
    wrapperProps: PropTypes.shape({}),
};

const TextAreaField = props => <Field component={TextAreaInput} {...props} />;

export default TextAreaField;
