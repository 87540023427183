import { getOr } from 'lodash/fp';
import React, { useContext } from 'react';
import { ReduxFormContext } from 'redux-form';
import SelectField from '../../../../shared/form-v2/SelectField';
import TextField from '../../../../shared/form-v2/TextField';
import SubTitle from '../../../../ui/SubTitle';
import usePhone from '../../../../utilities/usePhone';
import { useFormContext } from '../context';

const ReferenceDetails = () => {
    const { getValues } = useContext(ReduxFormContext);
    const formValues = getValues();
    const { disabled, options, allowPrimaryInfoChanges } = useFormContext();
    const countryCode = getOr('', 'zone.country.code', formValues);

    const { code: phoneCountryCode } = usePhone(countryCode);

    return (
        <>
            <SubTitle>Reference Details</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label="Reference Name"
                        name="details.reference.name"
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label="Relationship"
                        name="details.reference.relationship"
                        options={getOr([], 'relationships', options)}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label="Contact Number"
                        name="details.reference.phone"
                        prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                    />
                </div>
            </div>
        </>
    );
};

export default ReferenceDetails;
