import { useApolloClient } from '@apollo/client';
import { Portlet } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useContentTranslation } from '../../../../../i18n';
import { ApplicationPhase, Channel } from '../../../../../schema';
import { handleResponseError } from '../../../../../utilities/forms';
import TextAreaField from '../../../../shared/form-v2/TextAreaField';
import TextField from '../../../../shared/form-v2/TextField';
import UserSelectField from '../../../../template/options-field/UserSelectField';
import useFormValues from '../../../../utilities/useFormValues';
import { LinkField } from '../../Finance/shared/Vehicle/Event';
import { updateAssignee } from './Action.graphql';

const Summary = ({ channel, disabled, dealerId, id, applicationPhase }) => {
    const { formatPath } = useContentTranslation();

    const formValues = useFormValues();

    const client = useApolloClient();
    const onAssigneeChange = useCallback(
        value => {
            client
                .mutate({
                    mutation: updateAssignee,
                    variables: {
                        applicationId: id,
                        assigneeId: value,
                    },
                })
                .catch(handleResponseError);
        },
        [client, id]
    );

    const { prefixTitle, reference } = useMemo(() => {
        switch (applicationPhase) {
            case ApplicationPhase.RESERVATION:
                return {
                    prefixTitle: 'Reservation',
                    reference: 'Lead/ Finance Application/ Appointment Reference',
                };

            case ApplicationPhase.FINANCE:
                return {
                    prefixTitle: 'Application',
                    reference: 'Lead/ Insurance Application/ Appointment Reference',
                };

            default:
                return {
                    prefixTitle: 'Lead',
                    reference: 'Finance Application/ Appointment Reference',
                };
        }
    }, [applicationPhase]);

    const references = useMemo(() => {
        const separator = <span>/ </span>;
        const leadReference = formValues.lead?.version?.id && (
            <Link to={`/workflow/leads/${formValues?.lead.version.id}`}>{formValues?.lead?.identifier}</Link>
        );

        const financeReference = formValues.financeApplication?.version?.id && (
            <Link to={`/workflow/applications/${formValues?.financeApplication.version.id}`}>
                {formValues?.financeApplication?.identifier}
            </Link>
        );

        const insuranceReference = formValues.insuranceApplication?.version?.id && (
            <Link to={`/workflow/insuranceApplications/${formValues?.insuranceApplication.version.id}`}>
                {formValues.insuranceApplication?.identifier}
            </Link>
        );

        const appointmentReference = formValues.appointment?.identifier && (
            <Link to={`/appointment/${formValues?.appointment.version.id}`}>{formValues?.appointment?.identifier}</Link>
        );

        const links = [leadReference, financeReference, insuranceReference, appointmentReference].filter(Boolean);

        const mappedLinks = links.map((link, index) => (
            <Fragment key={index.toString()}>
                {link}
                {index < links.length - 1 ? separator : null}
            </Fragment>
        ));

        return <div className="link-control">{mappedLinks}</div>;
    }, [formValues]);

    return (
        <Portlet name={`${prefixTitle} Details`} closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label={`${prefixTitle} ID`} name="display.appId" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Date Created" name="display.dateCreated" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Dealer" name={formatPath('display.dealer')} disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Created by" name="display.createdBy" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <UserSelectField
                            dealerId={dealerId}
                            disabled={disabled}
                            label="Assigned to"
                            name="assigneeId"
                            onChangeEvent={onAssigneeChange}
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Channel" name="display.source" disabled />
                    </div>
                    {channel === Channel.EVENT && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField label="Event Name" name="display.event" disabled />
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Last Modified" name="display.lastModified" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <LinkField>
                            <div className="label">{reference}</div>
                            {references}
                        </LinkField>
                    </div>
                    {applicationPhase === ApplicationPhase.RESERVATION && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextAreaField label="Reservation Status" name="display.applicationStatus" disabled />
                        </div>
                    )}
                </div>
            </div>
        </Portlet>
    );
};

Summary.propTypes = {
    applicationPhase: PropTypes.string,
    channel: PropTypes.string,
    dealerId: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
};

export default Summary;
