import {
    ADD_NOTIFICATION,
    CLEAR_NOTIFICATION,
    DELETE_NOTIFICATION,
    ROTATE_NOTIFICATION,
} from '../actions/notification';

const reducer = (state = [], { type, payload }) => {
    switch (type) {
        case ADD_NOTIFICATION:
            return [...state, payload];
        case ROTATE_NOTIFICATION:
            return state
                .filter(notification => !notification.clearOnPageChange)
                .map(notification => ({ ...notification, clearOnPageChange: true }));
        case DELETE_NOTIFICATION:
            return state.filter(notification => notification.id !== payload);
        case CLEAR_NOTIFICATION:
            return [];
        default:
            return state;
    }
};

export default reducer;
