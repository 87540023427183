import React from 'react';
import ParameterBox from './ParameterBox';
import ParameterValue from './ParameterValue';

const EmptyBox = () => (
    <ParameterBox>
        <ParameterValue>-</ParameterValue>
    </ParameterBox>
);

export default EmptyBox;
