import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useModal } from '../../../../../Modal';
import { PreviewContainer, PreviewItem, Clear } from '../../../../../ui/Document';
import ButtonRemoveIcon from '../../../../../../shared/image/button-remove.png';

const ItemTypes = {
    CARD: 'card',
};

const Preview = ({ image, images, change, fieldName, moveImage, index, disabled }) => {
    const modal = useModal();

    const previousFileRef = useRef(null);

    const [previewUrl, setPreviewUrl] = useState(null);

    useEffect(() => {
        if (previousFileRef.current === image) {
            return;
        }

        const url = get('url', image);
        const file = get('file', image);

        if (file instanceof File) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setPreviewUrl(reader.result), false);
            reader.readAsDataURL(file);
        } else if (url) {
            setPreviewUrl(url);
        }
    }, [image]);

    const remove = useCallback(() => {
        change(
            fieldName,
            images.filter(i => (i.id ? i.id !== image.id : i.uiId !== image.uiId))
        );
    }, [change, images, image, fieldName]);

    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.x - hoverBoundingRect.left;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveImage(item, image);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            // eslint-disable-next-line no-param-reassign
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.CARD, ...image },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;

    useEffect(() => {
        drag(drop(ref));
    }, [drag, drop]);

    return (
        <PreviewItem ref={ref} style={{ opacity }}>
            <PreviewContainer>
                <img alt="variant gallery" src={previewUrl} />
            </PreviewContainer>
            {!disabled && (
                <Clear
                    onClick={() =>
                        modal.confirm({
                            title: '',
                            content: 'Do you want to remove this?',
                            options: [
                                { label: 'No', action: () => null },
                                { label: 'Yes', action: remove },
                            ],
                        })
                    }
                >
                    <img alt="" src={ButtonRemoveIcon} />
                </Clear>
            )}
        </PreviewItem>
    );
};

Preview.propTypes = {
    change: PropTypes.func,
    disabled: PropTypes.bool,
    fieldName: PropTypes.string,
    image: PropTypes.shape({
        file: PropTypes.instanceOf(File),
        id: PropTypes.string,
        uiId: PropTypes.string,
        url: PropTypes.string,
    }),
    images: PropTypes.arrayOf(
        PropTypes.shape({
            file: PropTypes.instanceOf(File),
            id: PropTypes.string,
            uiId: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    index: PropTypes.number,
    moveImage: PropTypes.func,
};

export default Preview;
