import { get, getOr } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { DerivedMethod, FinanceProductType, FinanceProductSubType } from '../../../../../schema';
import { getCompany, getCurrentCountry, getCurrentUserCountryDealers, getUser } from '../../../../../selectors';
import {
    financeSubTypesOptions,
    leaseSubTypesOptions,
    onOffOptions,
    productTypesOptions,
} from '../../../../../shared/constants/options';
import useTimeZones from '../../../../../utils/useTimeZones';
import DatePickerField from '../../../../template/DatePicker';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import NumberField from '../../../../template/Number';
import SwitchField from '../../../../template/Switch';
import TextareaField from '../../../../template/Textarea';
import { useFormContext } from './context';

const getSubTypeOptions = (financeType, countryCode, companyCode) => {
    switch (financeType) {
        case FinanceProductType.LEASING:
            return !(companyCode === 'POR' && countryCode === 'TW')
                ? leaseSubTypesOptions.filter(item => item.value !== FinanceProductSubType.UCCLLEASING)
                : leaseSubTypesOptions;

        case FinanceProductType.FINANCE:
            return countryCode !== 'NZ'
                ? financeSubTypesOptions.filter(item => item.value !== FinanceProductSubType.HIREPURCHASEBALLOONNZ)
                : financeSubTypesOptions;

        default:
            return [];
    }
};

const MainParameter = () => {
    const { formatPath, ct } = useContentTranslation();
    const timezones = useTimeZones();
    const { disabled, values, apiData, change, initialValues } = useFormContext();
    const country = useSelector(getCurrentCountry);
    const company = useSelector(getCompany);

    const financeType = get('type', values);
    const financeSubType = get('subType', values);

    const bankId = get('bankId', values);
    const [bankOptions, bankDealers] = useMemo(() => {
        const items = getOr([], 'banks.items', apiData);

        const selectedBank = items.find(({ id }) => id === bankId);

        return [
            items.map(({ id, name }) => ({ label: ct(name), value: id })),
            selectedBank ? selectedBank.dealers.map(({ id, name }) => ({ label: ct(name), value: id })) : [],
        ];
    }, [apiData, bankId, ct]);

    const userDealers = useSelector(getCurrentUserCountryDealers);
    const user = useSelector(getUser) || {};
    const dealerOptions = useMemo(() => {
        if (user.isSuperUser) {
            // return all dealers for super user
            return bankDealers;
        }

        const combinedDealers = [...userDealers, ...(initialValues?.dealerIds || [])];

        // only return dealers associated to user or initialValues
        return bankDealers
            .filter(({ value }) => combinedDealers.includes(value))
            .map(dealer => {
                // check if dealer is associated to user

                if (userDealers.includes(dealer.value)) {
                    return dealer;
                }

                // else it should be fixed
                return { ...dealer, isFixed: true };
            });
    }, [bankDealers, initialValues, user, userDealers]);

    const dealerIds = get('dealerIds', values);
    useEffect(() => {
        if (!bankId && bankOptions.length === 1) {
            change('bankId', bankOptions[0].value);

            return;
        }

        // we have relationships already setup
        if (bankId && dealerIds) {
            const cleanedDealerIds = dealerIds.filter(dealerId =>
                dealerOptions.some(({ value }) => value === dealerId)
            );
            // but some relationship to dealers is not proper here
            if (cleanedDealerIds.length !== dealerIds.length) {
                change('dealerIds', cleanedDealerIds);
            }
        }
    }, [bankOptions, bankId, change, dealerIds, dealerOptions]);

    useEffect(() => {
        // remove residual value setting
        if (
            ![
                FinanceProductSubType.LEASEPURCHASE,
                FinanceProductSubType.LEASEPURCHASEICC,
                FinanceProductSubType.HIREPURCHASEBALLOONGFV,
            ].includes(financeSubType)
        ) {
            change('residualValueSetting', null);
        }
    }, [financeSubType, change]);

    const onTypeChange = useCallback(
        newFinanceType => {
            if (newFinanceType === FinanceProductType.LEASING) {
                change('subType', FinanceProductSubType.LEASE);
                change('paymentMode.derivedMethod', DerivedMethod.LOOKUPTABLE);
            }
        },
        [change]
    );

    const onSubTypeChange = useCallback(
        newSubType => {
            if (newSubType === FinanceProductSubType.HIREPURCHASEBALLOONNZ) {
                change('loanSetting.show', false);
                change('loanSetting.editable', false);
            }
        },
        [change]
    );

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Product Name*">
                            <Field component={InputField} disabled={disabled} name={formatPath('name')} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField
                            disabled={disabled}
                            label="Product Type*"
                            name="type"
                            onChangeEvent={onTypeChange}
                            options={productTypesOptions}
                            placeholder="Select Product Type"
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField
                            disabled={disabled}
                            label="Product Sub Type*"
                            name="subType"
                            onChangeEvent={onSubTypeChange}
                            options={getSubTypeOptions(financeType, country.code, company.code)}
                            placeholder="Product Sub Type"
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Finance ID*">
                            <Field component={InputField} disabled={disabled} name="identifier" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Finance Bank Reference*">
                            <Field component={InputField} disabled={disabled} name="bankReferenceIdentifier" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Start Date*">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 2 }}>
                                    <Field component={DatePickerField} disabled={disabled} name="period.start" />
                                </div>
                                <div style={{ width: '10px' }} />
                                <div style={{ flex: 1 }}>
                                    <DropdownField
                                        disabled={disabled}
                                        name="period.startTimeZone"
                                        onChangeEvent={value => change('period.endTimeZone', value)}
                                        options={timezones}
                                    />
                                </div>
                            </div>
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="End Date*">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: 2 }}>
                                    <Field component={DatePickerField} disabled={disabled} name="period.end" />
                                </div>
                                <div style={{ width: '10px' }} />
                                <div style={{ flex: 1 }}>
                                    <DropdownField
                                        disabled={disabled}
                                        name="period.endTimeZone"
                                        onChangeEvent={value => change('period.startTimeZone', value)}
                                        options={timezones}
                                    />
                                </div>
                            </div>
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField
                            disabled={disabled}
                            label="Bank*"
                            name="bankId"
                            options={bankOptions}
                            placeholder="Please select bank"
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField
                            disabled={disabled}
                            height="75px"
                            label="Assign to"
                            maxHeight="100px"
                            minHeight="75px"
                            name="dealerIds"
                            options={dealerOptions}
                            placeholder="Please select dealer"
                            multi
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Order No.*">
                            <Field component={NumberField} disabled={disabled} name="order" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Active">
                            <Field component={SwitchField} disabled={disabled} name="isActive" options={onOffOptions} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Description">
                            <Field
                                component={TextareaField}
                                disabled={disabled}
                                maxLength="500"
                                name={formatPath('description')}
                            />
                        </FieldContainer>
                    </div>
                    {values.lastModified && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Last Modified">
                                <Field component={InputField} name="lastModified" disabled />
                            </FieldContainer>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MainParameter;
