import { defaultTheme as calculatorTheme } from '@appvantageasia/afc-calculator-ui';
// :: TODO Create theme object per dedicated brand
// :: theme.js to be used should be determined on build

const defaultTheme = casing => ({
    casing,
    themeHighlightColour: '#EF2C72',

    calculator: {
        ...calculatorTheme.calculator,
        titleTransform: casing,
        labelTransform: casing,
        valueColor: '#ef2c72',
    },
});

export default defaultTheme;
