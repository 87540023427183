import { getOr } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { createContext, useState, useContext } from 'react';

export const Context = createContext(null);

const ValuesContextProvider = ({ initialValues = {}, children }) => {
    const [values, setValues] = useState(initialValues);

    return <Context.Provider value={values}>{children(values, setValues)}</Context.Provider>;
};

ValuesContextProvider.propTypes = {
    children: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({}).isRequired,
};

export default ValuesContextProvider;

export const useValues = () => useContext(Context);

export const useValue = (field, defaultValue = '') => getOr(defaultValue, field, useValues());
