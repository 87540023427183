import * as validators from '../../../../../utils/validators';

const validate = validators.compose(
    validators.requiredString('name'),
    validators.requiredString('code'),
    validators.requiredNumber('taxRate'),
    validators.requiredString('timezone')
);

export default validate;
