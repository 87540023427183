import styled from 'styled-components';

const OutlineError = styled.div`
    background-color: #c10034;
    padding: 5px 8px;
    border-radius: 3px;
    position: absolute;
    right: 0;
    bottom: 37px;
    margin-bottom: 8px;
    z-index: 5;
    vertical-align: baseline;
    color: #ffffff;
    font-size: 0.8rem;

    &:after {
        content: '';
        width: 0;
        height: 0;
        display: block;
        border-style: solid;
        border-width: 5px 5px 0;
        border-color: #c10034 transparent transparent;
        position: absolute;
        right: 24px;
        bottom: -4px;
        margin-left: -5px;
    }
`;

export default OutlineError;
