import { useEffect } from 'react';

const useEscapeEvent = callback => {
    useEffect(() => {
        const catchEscape = event => {
            if (event.keyCode === 27 && callback) {
                callback();
            }
        };

        document.addEventListener('keydown', catchEscape, false);

        return () => {
            document.removeEventListener('keydown', catchEscape, false);
        };
    }, [callback]);
};

export default useEscapeEvent;
