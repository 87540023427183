import React from 'react';
import CompanySelection from './CompanySelection';
import ZoneSelection from './ZoneSelection';

const ScopeSelection = () => (
    <>
        <CompanySelection />
        <ZoneSelection />
    </>
);

export default ScopeSelection;
