import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getFormValues } from 'redux-form';
import { addNotification } from '../../../../../actions';
import { Channel } from '../../../../../schema';
import { getGlobalPermissions, getCurrentCountry } from '../../../../../selectors';
import {
    APPLICATION_CONCLUDE_AGREEMENT_NOTICATION,
    LEAD_SHARE_NOTIFICATION,
} from '../../../../../shared/constants/notification';
import { handleResponseError } from '../../../../../utilities/forms';
import { getApplicationPermissions } from '../../../../../utils/permissions';
import { useModal } from '../../../../Modal';
import FormLayout from '../../../../ui/form/FormLayout';
import { getVariantsFromState } from '../../common/data/useLoadVariants';
import { concludeAgreementApplication } from '../../common/shared/Action.graphql';
import useConfirm from '../../common/utilities/useConfirm';
import useStop from '../../common/utilities/useStop';
import { getFinanceProductFromState } from '../data/useFinanceProducts';
import Form from './Form';
import { shareFinanceContexts } from './Page.graphql';
import toSharePayload from './toSharePayload';

const Page = ({ initialValues = null }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { channelSetting } = useSelector(getCurrentCountry);
    const { allowOptions } = channelSetting.new;
    const { mayManageLeads } = useSelector(getGlobalPermissions);

    const client = useApolloClient();

    const onCancel = useCallback(() => history.goBack(), [history]);

    const values = useSelector(getFormValues('lead'));

    const modal = useModal();
    const isStop = useStop(false, modal, values);

    const action = useConfirm(modal);

    const { state } = useLocation();
    const redirect = useCallback(() => {
        if (state?.customerId) {
            history.push(`/customer/${state?.customerId}`);

            return;
        }

        history.push('/workflow/finance/leads');
    }, [history, state]);

    const onSubmit = useCallback(async () => {
        const stop = await isStop();
        if (stop) {
            return null;
        }

        // get finance products from apollo state
        const financeProducts = getFinanceProductFromState(
            client,
            initialValues?.dealerId,
            initialValues?.channel,
            initialValues?.event?.id
        );

        // we need use application zoneId to get varaints
        const variants = getVariantsFromState(client, initialValues?.zoneId, initialValues?.channel);
        const promise = client.mutate({
            mutation: shareFinanceContexts,
            variables: toSharePayload(values, allowOptions, variants, financeProducts),
        });

        // the mutation wont return the lead, we'll use the current lead identifier as it's immutable
        const currentIdentifier = values?.identifier;
        promise.then(() => {
            dispatch(addNotification(LEAD_SHARE_NOTIFICATION(currentIdentifier)));
            redirect();
        });

        return promise.catch(handleResponseError);
    }, [allowOptions, client, dispatch, initialValues, isStop, redirect, values]);

    const onAgreementConclude = useCallback(async () => {
        const currentIdentifier = values?.identifier;

        client.mutate({ mutation: concludeAgreementApplication, variables: { id: initialValues?.id } }).then(() => {
            dispatch(addNotification(APPLICATION_CONCLUDE_AGREEMENT_NOTICATION(currentIdentifier)));

            redirect();
        });
    }, [client, dispatch, initialValues, redirect, values]);

    const bodyComponent = useMemo(() => initialValues && <Form initialValues={initialValues} disabled />, [
        initialValues,
    ]);

    const title = useMemo(() => (values ? `Lead Details - ${values.identifier}` : 'Lead Details'), [values]);

    const { mayAgreementConcluded } = useMemo(() => (initialValues && getApplicationPermissions(initialValues)) || {}, [
        initialValues,
    ]);

    const canShare = !values?.version?.isOutdated && mayManageLeads && values?.channel !== Channel.EVENT;

    return (
        <FormLayout
            bodyComponent={bodyComponent}
            moreActions={[
                canShare && {
                    label: 'share',
                    onAction: onSubmit,
                },
                mayManageLeads &&
                    mayAgreementConcluded && {
                        label: 'agreement concluded',
                        disabled: false,
                        onAction: () => action(6, onAgreementConclude),
                    },
            ]}
            onCancel={onCancel}
            title={title}
        />
    );
};

Page.propTypes = {
    initialValues: PropTypes.shape(),
};

export default Page;
