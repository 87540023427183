import { useApolloClient, useQuery } from '@apollo/client';
import { isObject, pick } from 'lodash/fp';
import React, { useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { submit } from 'redux-form';
import { addNotification, setUser } from '../../../actions';
import { getCountryCode, getDialingCodes } from '../../../selectors';
import { PROFILE_SAVE_NOTIFICATION } from '../../../shared/constants/notification';
import { getLastModified, handleResponseError } from '../../../utilities/forms';
import useFormatDateTime from '../../shared/useFormatDateTime';
import FormLayout from '../../ui/form/FormLayout';
import getUserWithPermissions from '../LoginRoute/getUserWithPermissions';
import { uploadImage, deleteImage, getUser, update, resetPassword } from './Page.graphql';
import ProfileForm from './ProfileForm';

const ProfileRoute = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const dialingCodes = useSelector(getDialingCodes) || [];
    const countryCode = useSelector(getCountryCode);

    const formatDateTime = useFormatDateTime();

    const { data, loading, refetch } = useQuery(getUser, { fetchPolicy: 'network-only' });

    const initialValues = useMemo(
        () =>
            data?.user &&
            dialingCodes && {
                ...data?.user,
                phonePrefix:
                    data?.user.phonePrefix || dialingCodes?.find(item => item.countryCode === countryCode)?.value,
                lastModified: getLastModified(data?.user.version, formatDateTime),
            },
        [data, dialingCodes, formatDateTime, countryCode]
    );

    const ref = useRef(null);
    const onCancel = useCallback(() => history.goBack(), [history]);

    const client = useApolloClient();

    const onSave = useCallback(
        async values => {
            const { image, password } = values;

            let promise = Promise.resolve();
            if (password) {
                const variables = { ...pick(['password', 'newPassword'], values) };
                promise = promise.then(() =>
                    client.mutate({ mutation: resetPassword, variables, fetchPolicy: 'no-cache' })
                );
            }

            promise = promise.then(() =>
                client.mutate({
                    mutation: update,
                    variables: {
                        data: {
                            ...pick(['name', 'phone', 'phonePrefix', 'email'], values),
                        },
                    },
                })
            );

            if (image instanceof File) {
                // must upload a new file
                promise = promise.then(({ data: { response } }) =>
                    client.mutate({ mutation: uploadImage, variables: { id: response.id, file: image } })
                );
            } else if (image === null && isObject(initialValues.image)) {
                // must remove an existing file
                promise = promise.then(({ data: { response } }) =>
                    client.mutate({ mutation: deleteImage, variables: { id: response.id } })
                );
            }

            promise = promise.then(refetch);

            // Refetch response
            promise = promise.then(response => {
                dispatch(setUser(getUserWithPermissions(response.data.user)));
            });

            promise = promise.then(() => {
                dispatch(addNotification(PROFILE_SAVE_NOTIFICATION));
                onCancel();
            });

            return promise.catch(handleResponseError);
        },
        [client, dispatch, initialValues, onCancel, refetch]
    );

    const submitForm = useCallback(() => dispatch(submit('profile')), [dispatch]);

    if (!loading && !initialValues) {
        // profile is still loading
        return null;
    }

    return (
        <FormLayout
            bodyComponent={initialValues && <ProfileForm ref={ref} initialValues={initialValues} onSubmit={onSave} />}
            moreActions={[
                {
                    label: 'save',
                    onAction: submitForm,
                },
            ]}
            onCancel={onCancel}
            title="Profile Details"
        />
    );
};

export default ProfileRoute;
