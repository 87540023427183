import styled from 'styled-components';

const Checkbox = styled.input`
    width: 15px;
    height: 15px;
    min-width: 15px !important;
    background-color: pink;
`;

export default Checkbox;
