import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getOr, keyBy, isNil } from 'lodash/fp';
import React, { useCallback, useState } from 'react';
import { Field } from 'redux-form';
import { FootBarButton, TabExcelContainer } from '../../../../../containers/Layout';
import { useContentTranslation } from '../../../../../i18n';
import TableField, { createColumns } from '../../../../TableField';
import { exportFinancialTabsExcel, getWorkbookToExport } from '../../../../utilities/excel';
import ImportFinanceTablesModal from '../../lists/ImportFinanceTablesModal';
import { useFormContext } from './context';

const rows = [{ label: 'Downpayment' }];

const DownpaymentTab = () => {
    const { disabled, rounding, values, change } = useFormContext();
    const downPaymentTable = getOr([], 'downPaymentSetting.table', values);
    const termSettings = getOr({}, 'termSetting', values);
    const productName = getOr('', 'name', values);
    const [showImport, setShowImport] = useState(false);

    const { ct } = useContentTranslation();

    const mappedHeaders = {
        'Term *': 'term',
        'Downpayment Percentage *': 'value',
    };

    const exportExcel = useCallback(async () => {
        const workbook = await getWorkbookToExport(mappedHeaders);
        const worksheet = workbook.getWorksheet(1);
        const downPaymentTableByTerm = keyBy('term', downPaymentTable || []);
        const terms = createColumns(termSettings).map(val => val.value);

        terms.forEach(term => {
            worksheet.addRow({ term, value: getOr(null, `${term}.value`, downPaymentTableByTerm) });
        });

        exportFinancialTabsExcel(workbook, ct(productName), 'Downpayment');
    }, [mappedHeaders, downPaymentTable, ct, productName, termSettings]);

    const onImportClose = useCallback(() => {
        setShowImport(false);
    }, [setShowImport]);

    const onImport = useCallback(
        async data => {
            const tableDataList = data
                .filter(record => !isNil(record.value))
                .map(value => ({
                    term: value.term,
                    value: value.value,
                }));

            change('downPaymentSetting.table', tableDataList);
        },
        [change]
    );

    return (
        <>
            <Field
                columnKey="term"
                component={TableField}
                disabled={disabled}
                header="Term"
                name="downPaymentSetting.table"
                precision={rounding.percentage.count}
                rows={rows}
                settings={termSettings}
                suffix="%"
            />
            <TabExcelContainer>
                <FootBarButton onClick={() => setShowImport(true)}>
                    <FontAwesomeIcon icon={faUpload} /> IMPORT EXCEL
                </FootBarButton>
                <FootBarButton onClick={exportExcel}>
                    <FontAwesomeIcon icon={faDownload} /> EXPORT EXCEL
                </FootBarButton>
            </TabExcelContainer>
            {showImport && (
                <ImportFinanceTablesModal
                    mappedHeaders={mappedHeaders}
                    onClose={onImportClose}
                    onImport={onImport}
                    type="Downpayment Table"
                />
            )}
        </>
    );
};

export default DownpaymentTab;
