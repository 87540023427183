import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useContentTranslation } from '../../../i18n';
import { DealerOptions } from './DealerSelection';
import { getDealerChannelById } from './data.graphql';

type HeaderProps = {
    dealerId: string;
};

const Header = ({ dealerId }: HeaderProps) => {
    const { ct } = useContentTranslation();
    const allDealers = dealerId === DealerOptions.ALLDEALER;

    const { data, loading } = useQuery(getDealerChannelById, {
        fetchPolicy: 'cache-first',
        variables: { id: dealerId },
        skip: isEmpty(dealerId) || allDealers,
    });
    const dealerName = useMemo(() => (data ? ct(data.dealer.name) : 'All Dealer'), [data, ct]);

    if (loading) {
        return null;
    }

    return <span>Channels for {dealerName} </span>;
};

export default Header;
