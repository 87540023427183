import React, { useMemo } from 'react';
import Page from './Page';

const Creation = () => {
    const initialValues = useMemo(
        () => ({
            color: '#ef2c72',
            isActive: true,
        }),
        []
    );

    return <Page initialValues={initialValues} />;
};

export default Creation;
