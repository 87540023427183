import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { getCurrentCountry, getCurrentZone } from '../../../selectors';
import { convertUTCToLocal } from '../../../utils/timezones';
import Page from './Page';
import { getCountry } from './data.graphql';

const Maintenance = () => {
    const { id: countryId } = useSelector(getCurrentCountry);
    const { timezone } = useSelector(getCurrentZone) || {};
    const variables = { countryId };
    const { data, loading } = useQuery(getCountry, { variables, fetchPolicy: 'network-only' });
    const country = data?.country;

    const initialValues = useMemo(() => {
        if (!country) {
            return null;
        }

        const { maintenance } = country;

        if (!maintenance) {
            const now = new Date();

            return {
                warningBefore: 0,
                isActive: true,
                start: now,
                startTimeZone: timezone,
                end: now,
                endTimeZone: timezone,
            };
        }

        return {
            ...maintenance,
            start: convertUTCToLocal(maintenance.start, maintenance.startTimeZone),
            end: convertUTCToLocal(maintenance.end, maintenance.endTimeZone),
        };
    }, [country, timezone]);

    if (!loading && !country) {
        return <Redirect to="/home" />;
    }

    return <Page countryId={countryId} initialValues={initialValues} />;
};

export default Maintenance;
