import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
    outline: none;
    width: 100%;
    border: 0;
    color: ${props => props.theme.themeHighlightColour};
    background: #ffffff;
    height: 20px;
    font-size: 14px;
    text-align: end;

    &:disabled {
        color: #000000;
        opacity: 1;
    }
`;

export const Container = styled.div`
    display: flex;
    align-items: stretch;
    width: 100%;
`;

const AddOn = styled.div`
    font-weight: 400;
    line-height: normal;
    font-size: 14px;
    color: ${props => (props.disabled ? '#000' : props.theme.themeHighlightColour)};
    display: flex;
    align-items: center;
    height: 18px;
`;

const PureInput = ({ inputRef, prefix = null, disabled, ...props }) => (
    <Container>
        {prefix && <AddOn disabled={disabled}>{prefix}</AddOn>}
        <Input {...props} ref={inputRef} disabled={disabled} />
    </Container>
);

PureInput.propTypes = {
    disabled: PropTypes.bool,
    inputRef: PropTypes.shape({}),
    prefix: PropTypes.node,
};

export default PureInput;
