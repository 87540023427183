import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { getCurrentCountry } from '../../../../../selectors';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import NumberField from '../../../../template/Number';
import TextareaField from '../../../../template/Textarea';
import { useFormContext } from './context';

const MainDetails = () => {
    const { formatPath, ct } = useContentTranslation();
    const { banks } = useFormContext(); // setup data
    const country = useSelector(getCurrentCountry);

    const bankOptions = useMemo(() => {
        return banks.map(({ label, value }) => ({ value, label: ct(label) }));
    }, [banks, ct]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="ID*">
                        <Field component={InputField} name="identifier" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Partner Number">
                        <Field component={InputField} name="partnerNumber" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Name*">
                        <Field component={InputField} name={formatPath('name')} />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DropdownField label="Banks" name="bankIds" options={bankOptions} placeholder="Select Bank" multi />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Assortment">
                        <Field component={InputField} name="porschePaymentAssortment" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Telephone">
                        <Field component={InputField} name="telephone" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="WhatsApp">
                        <Field component={InputField} name="whatsapp" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Email">
                        <Field component={InputField} name="email" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Last Modified">
                        <Field component={InputField} name="lastModified" disabled />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Address">
                        <Field component={TextareaField} name={formatPath('address')} />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Additional Info">
                        <Field
                            component={TextareaField}
                            maxLength={100000}
                            name={formatPath('additionalInformation')}
                        />
                    </FieldContainer>
                </div>
                {country.useAutoplay && (
                    <>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Autoplay - Dealer ID">
                                <Field component={NumberField} name="autoplay.dealerId" />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Autoplay - Yard ID">
                                <Field component={NumberField} name="autoplay.yardId" />
                            </FieldContainer>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default MainDetails;
