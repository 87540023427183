import styled from 'styled-components';

const ActivityLogContainer = styled.div`
    table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 1rem;
        background-color: transparent;
        border-collapse: collapse;
        border-spacing: 0;
        font-size: 0.85rem;
        color: #000000;
        thead {
            overflow: hidden;
            tr {
                height: 30px;
                background-color: #d0d0d0;
                th {
                    position: relative;
                    color: black;
                    font-weight: bold;
                    vertical-align: middle;
                    padding-left: 10px;
                    cursor: pointer;
                }
            }
        }
        tbody {
            tr {
                height: 35px;
                td {
                    text-align: left;
                    padding-left: 10px;
                    vertical-align: middle;
                }
                td:nth-child(1) {
                    width: 300px;
                    border-right: 1px solid #ffffff;
                }
            }
            tr:nth-child(even) {
                background-color: #f2f2f2;
            }
            tr:nth-child(odd) {
                background-color: #ffffff;
            }
        }
    }
`;

export default ActivityLogContainer;
