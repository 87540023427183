import { useQuery } from '@apollo/client';
import { omit } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { getLastModified } from '../../../../utilities/forms';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { getGroup } from './Edition.graphql';
import Page from './Page';

const Edition = () => {
    const { id } = useParams();
    const variables = { id };
    const { data, loading } = useQuery(getGroup, { variables, fetchPolicy: 'network-only' });

    const formatDateTime = useFormatDateTime();

    const initialValues = useMemo(() => {
        const values = data?.group;

        if (!values) {
            return null;
        }

        return {
            ...omit('options', values),
            lastModified: getLastModified(values.version, formatDateTime),
        };
    }, [data, formatDateTime]);

    if (!loading && !initialValues) {
        // the product does not exist
        return <Redirect to="/vehicle/optionGroups" />;
    }

    return <Page initialValues={initialValues} options={data?.group?.options} />;
};

export default Edition;
