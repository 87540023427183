import PropType from 'prop-types';
import React, { useState, useEffect, useCallback, useRef } from 'react';

const ImgWithFallback = ({ src: originalSrc, fallbackSrc, ...props }) => {
    const fallbackRef = useRef(null);
    const [src, setSrc] = useState(originalSrc);

    useEffect(() => {
        setSrc(originalSrc);
    }, [originalSrc, setSrc]);

    useEffect(() => {
        if (fallbackRef.current === src && src !== fallbackSrc) {
            // the fallback reference did change
            setSrc(fallbackSrc);
        }

        fallbackRef.current = fallbackSrc;
    }, [src, fallbackSrc, fallbackRef, setSrc]);

    const onError = useCallback(() => setSrc(fallbackSrc), [setSrc, fallbackSrc]);

    return <img alt="" {...props} onError={onError} src={src} />;
};

ImgWithFallback.propTypes = {
    fallbackSrc: PropType.string.isRequired,
    src: PropType.string,
};

export default ImgWithFallback;
