import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getGlobalPermissions } from '../../../../selectors';

const useUserTabs = () => {
    const { mayViewUsers, mayViewRoles, mayViewRolesHierarchy } = useSelector(getGlobalPermissions);

    return useMemo(
        () =>
            [
                mayViewUsers && { id: 'user', name: 'User Management', url: '/access/users' },
                mayViewRoles && { id: 'role', name: 'Role Management', url: '/access/roles' },
                mayViewRolesHierarchy && { id: 'hierarchy', name: 'Hierarchy Management', url: '/access/hierarchies' },
            ].filter(Boolean),
        [mayViewUsers, mayViewRoles, mayViewRolesHierarchy]
    );
};

export default useUserTabs;
