import { map, get, flow, flatten, uniqBy, sortBy, filter, negate, isEmpty, some, isArray } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo } from 'react';

const Context = createContext({ groups: null, hasDeferredProduct: false, active: false });

export const useComparisonContext = () => useContext(Context);

const getOptionsFromCalculators = flow([
    map(get('values.carOptions')),
    flatten,
    filter(negate(isEmpty)),
    uniqBy('groupId'),
    sortBy(['group.order']),
    map(option => ({
        id: option.groupId,
        order: option.group.order,
        label: option.group.name,
    })),
]);

const getHasDeferredProductFromCalculators = flow([map(get('values.monthlyInstalment')), some(isArray)]);

const ComparisonContext = ({ children, calculators }) => {
    // get information
    const groups = useMemo(() => getOptionsFromCalculators(calculators), [calculators]);
    const hasDeferredProduct = useMemo(() => getHasDeferredProductFromCalculators(calculators), [calculators]);

    // group those
    const context = useMemo(() => ({ active: true, groups, hasDeferredProduct }), [groups, hasDeferredProduct]);

    return <Context.Provider value={context}>{children}</Context.Provider>;
};

ComparisonContext.propTypes = {
    calculators: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    children: PropTypes.node.isRequired,
};

export default ComparisonContext;
