import { getOr } from 'lodash/fp';
import React from 'react';
import { useCalculatorContext } from '../../CalculatorContext';
import { useValues } from '../../ValuesContext';
import MonthlyInstalmentField from './MonthlyInstalmentField';

const ConnectedMonthlyInstalmentField = props => {
    // get the calculator context
    const { selectedFinanceProduct } = useCalculatorContext();

    // get values
    const values = useValues();
    const { monthlyInstalment, paymentTerm } = values;
    const interestOnlyTerms = getOr(0, 'termSetting.interestOnly', selectedFinanceProduct);

    return (
        <MonthlyInstalmentField
            {...props}
            interestOnlyTerms={interestOnlyTerms}
            monthlyInstalment={monthlyInstalment}
            paymentTerm={paymentTerm}
        />
    );
};

export default ConnectedMonthlyInstalmentField;
