import { get } from 'lodash/fp';
import bindFields from './bindFields';
import bindSetValue from './bindSetValue';
import computeCarOptions from './computeCarOptions';
import computeHasMake from './computeHasMakes';
import computeVariantProducts from './computeVariantProducts';
import convertOutdated from './convertOutdated';
import getAvailableBanks from './getAvailableBanks';
import selectBank from './selectBank';
import selectFinanceProduct from './selectFinanceProduct';
import selectVariant from './selectVariant';

const computeContext = (rawContext, values, setValues, run) => {
    const { formats, variants = [], allowedOutdated = false } = rawContext;

    // get the selected variants
    const selectedVariant = selectVariant(run, rawContext, values);

    // we need to keep a dirty reference
    const dirtyReference = run('dirty', { current: false, converted: false, firstInit: true }, []);

    // first we need to get the finance product for our variant
    const financeProducts = computeVariantProducts(run, rawContext, get('version.id', selectedVariant));
    // get the financial product
    const selectedFinanceProduct = selectFinanceProduct(run, rawContext, financeProducts, values);
    // we are going to filter banks with finance products
    const banks = getAvailableBanks(run, financeProducts);
    // get the selected bank
    const selectedBank = selectBank(run, rawContext, banks, values);
    // bound fields
    const boundFields = bindFields(run, rawContext, dirtyReference.current);
    // callback to handle updates on field
    const boundSetValue = bindSetValue(run, rawContext, values, boundFields, setValues, dirtyReference);
    // prepare options
    const carOptions = computeCarOptions(run, selectedVariant, values, rawContext);
    // has make configuration
    const hasMakes = computeHasMake(run, rawContext);

    // convert outdated (either on first init when requested or on changes)
    convertOutdated(dirtyReference, rawContext, carOptions, setValues);

    // we can see if the context is invalid by reading it partially
    const isDirty = dirtyReference.current;

    // get invalid state for the bank
    const isInvalidBank = !banks.some(item => item.id === selectedBank?.id) && !allowedOutdated;

    // get invalid state for the variant
    const isInvalidVariant =
        !variants.some(item => item.version.id === selectedVariant?.version?.id) &&
        (!selectedVariant || (selectedVariant && !allowedOutdated && selectedVariant.version.isOutdated));

    // get invalid state for the finance product
    const isInvalidFinanceProduct =
        !financeProducts.some(item => item.version.id === selectedFinanceProduct?.version?.id) &&
        (!selectedFinanceProduct ||
            (selectedFinanceProduct && !allowedOutdated && selectedFinanceProduct.version.isOutdated));

    // global invalid state
    const isInvalid = isInvalidBank || isInvalidVariant || isInvalidFinanceProduct;

    // persist the whole computed context
    // by using memo we avoid updating it too often
    return run(
        'context',
        () => ({
            ...rawContext,
            fields: boundFields,
            currency: formats?.currencySymbol,
            banks,
            financeProducts,
            selectedVariant,
            selectedFinanceProduct,
            selectedBank,
            setValue: boundSetValue,
            carOptions,
            isDirty,
            isInvalid,
            isInvalidBank,
            isInvalidVariant,
            isInvalidFinanceProduct,
            isUsingSnapshot: !isDirty && !!rawContext.snapshot,
            hasMakes,
        }),
        [
            rawContext,
            financeProducts,
            selectedVariant,
            selectedFinanceProduct,
            selectedBank,
            banks,
            boundFields,
            boundSetValue,
            carOptions,
            isDirty,
            hasMakes,
        ]
    );
};

export default computeContext;
