import { useApolloClient } from '@apollo/client';
import { isObject, omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { submit } from 'redux-form';
import { addNotification } from '../../../../actions';
import { getGlobalPermissions } from '../../../../selectors';
import { USER_DELETE_NOTIFICATION, USER_SAVE_NOTIFICATION } from '../../../../shared/constants/notification';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import { withModal } from '../../../Modal';
import FormLayout from '../../../ui/form/FormLayout';
import Form from './Form';
import AssignApplicationModal from './Form/AssignApplicationModal';
import { create, update, remove, uploadImage, deleteImage, resendActivation, assign } from './Page.graphql';

const Page = ({ initialValues = null, modal }) => {
    const isUpdate = !!initialValues?.id;

    const dispatch = useDispatch();
    const history = useHistory();
    const { mayManageUsers } = useSelector(getGlobalPermissions);

    const client = useApolloClient();

    const onCancel = useCallback(() => history.goBack(), [history]);

    const onSubmit = useCallback(
        ({ image, ...values }) => {
            const mutation = isUpdate ? update : create;

            const data = prepareForGraphQL(
                omit(
                    [
                        'id',
                        'version',
                        'lastModified',
                        'lastLoginAt',
                        'hasSentNotificationEmail',
                        'hasPassword',
                        'status',
                    ],
                    values
                )
            );

            const variables = {
                data,
            };

            if (isUpdate) {
                variables.id = values.id;
            }

            let promise = client.mutate({ mutation, variables });

            if (image instanceof File) {
                // must upload a new file
                promise = promise.then(({ data: { response } }) =>
                    client.mutate({ mutation: uploadImage, variables: { id: response.id, file: image } })
                );
            } else if (image === null && isObject(initialValues.image)) {
                // must remove an existing file
                promise = promise.then(({ data: { response } }) =>
                    client.mutate({ mutation: deleteImage, variables: { id: response.id } })
                );
            }

            return promise.catch(handleResponseError);
        },
        [isUpdate, client, initialValues]
    );

    const onSubmitSuccess = useCallback(() => {
        dispatch(addNotification(USER_SAVE_NOTIFICATION));
        history.push('/access/users');
    }, [history, dispatch]);

    const [showAssignApplicationModal, setShowAssignApplicationModal] = useState(false);

    const closeAssignApplicationModal = useCallback(() => setShowAssignApplicationModal(false), [
        setShowAssignApplicationModal,
    ]);

    const onDelete = useCallback(() => {
        client
            .mutate({ mutation: remove, variables: { id: initialValues?.id } })
            .then(({ data: { response } }) => {
                if (response.deleted) {
                    dispatch(addNotification(USER_DELETE_NOTIFICATION));
                    history.push('/access/users');
                } else if (response.hasApplicationsToReassign) {
                    setShowAssignApplicationModal(true);
                }
            })
            .catch(handleResponseError);
    }, [client, dispatch, history, initialValues]);

    const onPrepareDelete = useCallback(() => {
        modal.confirm({
            title: '',
            content: 'Are you sure you want to delete this user?',
            options: [
                { label: 'No', action: () => null },
                {
                    label: 'Yes',
                    action: onDelete,
                },
            ],
        });
    }, [modal, onDelete]);

    // todo test resend activatin api, now it report invalid permission
    const onResendActivation = useCallback(
        () =>
            client
                .mutate({ mutation: resendActivation, variables: { userId: initialValues?.id } })
                .then(() => {
                    modal.confirm({
                        title: '',
                        content: 'Activation email has been sent',
                        options: [{ label: 'Done', action: () => null }],
                    });
                })
                .catch(handleResponseError),
        [client, initialValues, modal]
    );

    const submitForm = useCallback(() => dispatch(submit('user')), [dispatch]);

    const onAssign = useCallback(
        toUser =>
            client
                .mutate({ mutation: assign, variables: { fromUserId: initialValues?.id, toUserId: toUser?.id } })
                .then(onDelete)
                .catch(handleResponseError),
        [client, initialValues, onDelete]
    );

    const mayResendActivation =
        (!initialValues?.hasSentNotificationEmail || !initialValues?.hasPassword) &&
        initialValues?.isActive &&
        mayManageUsers;

    return (
        <FormLayout
            bodyComponent={
                initialValues && (
                    <>
                        <Form
                            disabled={!mayManageUsers}
                            initialValues={initialValues}
                            isUpdate={isUpdate}
                            onSubmit={onSubmit}
                            onSubmitSuccess={onSubmitSuccess}
                        />
                        <AssignApplicationModal
                            onClose={closeAssignApplicationModal}
                            onConfirm={onAssign}
                            open={showAssignApplicationModal}
                            userId={initialValues.id}
                        />
                    </>
                )
            }
            moreActions={[
                initialValues?.id &&
                    mayManageUsers && {
                        label: 'delete',
                        onAction: onPrepareDelete,
                    },
                mayResendActivation && {
                    label: 'resend activation',
                    onAction: onResendActivation,
                },
                mayManageUsers && {
                    label: 'save',
                    onAction: submitForm,
                },
            ]}
            onCancel={onCancel}
            title="User Details"
        />
    );
};

Page.propTypes = {
    initialValues: PropTypes.shape(),
    modal: PropTypes.shape(),
};

export default withModal(Page);
