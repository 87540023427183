import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { exportAppointments } from '../../../../actions';
import { Channel } from '../../../../schema';
import { useModal } from '../../../Modal';
import ConnectedLoadingLayer from '../../../common/ConnectedLoadingLayer';
import { useDownloadChannels } from '../../../shared/useDownloadChannels';
import { CloseButton, DownloadButton, DownloadModal, Header, Message, SelectButton } from '../../../ui/Download';
import BlockSelect from '../../../ui/form/BlockSelect';

const durationOptions = [
    { value: 0, label: 'Current Month' },
    { value: 1, label: 'Last Month' },
    { value: 3, label: 'Last 3 Months' },
    { value: 6, label: 'Last 6 Months' },
    { value: 12, label: 'Last 12 Months' },
];

const channelOptions = [{ value: Channel.EVENT, label: 'Event' }];

type AppointmentDownloadProps = {
    onClose: () => void;
    dealerIds?: string[];
};

const AppointmentDownload = ({ onClose, dealerIds }: AppointmentDownloadProps) => {
    const modal = useModal();
    const dispatch = useDispatch();

    const [duration, setDuration] = useState(durationOptions[0]);
    const [channel, setChannel, { channelValue, usedChannelOptions }] = useDownloadChannels(channelOptions);

    const onDurationChange = useCallback(value => setDuration(value), [setDuration]);
    const onChannelChange = useCallback(value => setChannel(value), [setChannel]);

    const download = useCallback(() => {
        dispatch(exportAppointments(duration.value, channelValue, 'Appointments', dealerIds))
            // @ts-ignore
            .then(password => {
                onClose();
                if (password) {
                    modal.confirm({
                        title: 'Password',
                        content: password,
                        options: [],
                    });
                }
            });
    }, [dispatch, duration.value, channelValue, dealerIds, onClose, modal]);

    return (
        <DownloadModal onRequestClose={onClose} isOpen>
            <div>
                <ConnectedLoadingLayer>
                    <CloseButton onClick={onClose}>&times;</CloseButton>
                    <Header>Download Appointments</Header>
                    <Message>Please select the channel and the time frame of appointments to download.</Message>
                    <SelectButton>
                        <BlockSelect
                            className="select"
                            defaultValue={channel}
                            onChange={onChannelChange}
                            options={usedChannelOptions}
                            value={channel}
                        />
                        <BlockSelect
                            className="select"
                            defaultValue={duration}
                            onChange={onDurationChange}
                            options={durationOptions}
                            value={duration}
                        />
                        <DownloadButton onClick={download}>download</DownloadButton>
                    </SelectButton>
                </ConnectedLoadingLayer>
            </div>
        </DownloadModal>
    );
};

export default AppointmentDownload;
