import { useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, orderBy } from 'lodash/fp';
import React, { useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FootBar, FootBarButton, FootContainer } from '../../../../containers/Layout';
import { getCountryId, getGlobalPermissions } from '../../../../selectors';
import { searchOnKeys } from '../../../../utilities/fp';
import { List, ListSearch } from '../../../ui/lists';
import usePaging from '../../../utilities/usePaging';
import { getTables } from './RoleList.graphql';
import useUserTabs from './useUserTabs';

const columns = [
    { name: 'Role Name', id: 'name', renderCell: get('name'), hasSort: true, underline: true },
    { name: 'Role Description', id: 'description', renderCell: get('description'), hasSort: true },
    { name: 'Users', id: 'users.length', renderCell: get('users.length') },
];

const searchFields = ['name', 'description'];

const sortItems = (items, [sortKey, sortOrder]) => {
    // we always sort by value key
    return orderBy(sortKey, sortOrder, items);
};

const RoleLists = () => {
    const tabs = useUserTabs();
    const history = useHistory();
    const { mayManageRoles } = useSelector(getGlobalPermissions);

    const countryId = useSelector(getCountryId);
    const variables = { countryId };
    const { data, loading, error } = useQuery(getTables, { fetchPolicy: 'cache-and-network', variables });
    const items = data?.results?.items || [];
    const isLoading = loading && items.length <= 0;

    // searching
    const [search, setSearch] = useState('');
    const searchMethod = useMemo(() => searchOnKeys(searchFields, items), [items]);
    const matchedItems = useMemo(() => searchMethod(search), [search, searchMethod]);

    // sorting
    const [sortedOn, onSort] = useState(['name', 'asc']);
    const sortedItems = useMemo(() => sortItems(matchedItems, sortedOn), [matchedItems, sortedOn]);

    // paging
    const [pagedItems, paging] = usePaging(sortedItems, { search });

    const createRole = useCallback(() => history.push(`/access/roles/new`), [history]);

    const footer = (
        <FootContainer>
            <FootBar>
                {mayManageRoles && (
                    <FootBarButton onClick={createRole}>
                        <FontAwesomeIcon icon={faPlus} /> ADD ROLE
                    </FootBarButton>
                )}
            </FootBar>
        </FootContainer>
    );

    return (
        <List
            activeTab="role"
            columns={columns}
            error={error}
            footer={footer}
            headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
            items={pagedItems}
            loading={isLoading}
            onItemClick={item => history.push(`/access/roles/${item.id}`)}
            onSort={onSort}
            paging={paging}
            sortedOn={sortedOn}
            tabWidth={316}
            tabs={tabs}
            title="Role Management"
        />
    );
};

export default RoleLists;
