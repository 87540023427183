import { get } from 'lodash/fp';
import React from 'react';
import { Field } from 'redux-form';
import { FinanceProductBase, FinanceProductSubType } from '../../../../../schema';
import { yesNoOptions } from '../../../../../shared/constants/options';
import FieldContainer from '../../../../template/Field-container';
import NumberField from '../../../../template/Number';
import SwitchField from '../../../../template/Switch';
import UnitField from '../../../../template/options-field/UnitField';
import { useFormContext } from './context';
import useDownPaymentLoan from './useDownPaymentLoan';

const Loan = () => {
    const { disabled, values, currency, rounding } = useFormContext();

    const basedOn = get('basedOn', values);
    const remoteManaged = basedOn === FinanceProductBase.DOWNPAYMENT;

    const min = get('loanSetting.min', values);
    const max = get('loanSetting.max', values);

    const downPaymentMin = get('downPaymentSetting.min', values);
    const downPaymentMax = get('downPaymentSetting.max', values);

    const subType = get('subType', values);

    const handleSettingChange = useDownPaymentLoan(FinanceProductBase.LOAN);

    const mayManageShow = subType !== FinanceProductSubType.HIREPURCHASEBALLOONNZ;

    const mayManageEditable =
        mayManageShow &&
        basedOn === FinanceProductBase.DOWNPAYMENT &&
        ((downPaymentMin !== downPaymentMax && min === max) || min !== max);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <div className="sub-container-fluid">
                        <FieldContainer label="Min*">
                            <Field
                                component={NumberField}
                                disabled={remoteManaged || disabled}
                                handleChange={value => handleSettingChange({ min: value })}
                                min={0}
                                name="loanSetting.min"
                                precision={rounding.percentage.count}
                            />
                        </FieldContainer>
                        <span />
                        <FieldContainer label="Max*">
                            <Field
                                component={NumberField}
                                disabled={remoteManaged || disabled}
                                handleChange={value => handleSettingChange({ max: value })}
                                min={0}
                                name="loanSetting.max"
                                precision={rounding.percentage.count}
                            />
                        </FieldContainer>
                        <span />
                        <FieldContainer label="Step*">
                            <Field
                                component={NumberField}
                                disabled={min === max || remoteManaged || disabled}
                                handleChange={value => handleSettingChange({ step: value })}
                                max={max}
                                min={1}
                                name="loanSetting.step"
                                precision={rounding.percentage.count}
                            />
                        </FieldContainer>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Default*">
                        <div className="sub-container-fluid">
                            <div className="flex-3">
                                <Field
                                    component={NumberField}
                                    disabled={remoteManaged || disabled}
                                    handleChange={value => handleSettingChange({ default: value })}
                                    min={0}
                                    name="loanSetting.default"
                                    precision={rounding.percentage.count}
                                />
                            </div>
                            <span />
                            <div>
                                <UnitField
                                    currencySymbol={currency}
                                    disabled={remoteManaged || disabled}
                                    name="loanSetting.defaultUnit"
                                    onChangeEvent={value => handleSettingChange({ defaultUnit: value })}
                                />
                            </div>
                        </div>
                    </FieldContainer>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Show">
                        <Field
                            component={SwitchField}
                            disabled={!mayManageShow || disabled}
                            name="loanSetting.show"
                            onClick={value => handleSettingChange({ show: value })}
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Editable">
                        <Field
                            component={SwitchField}
                            disabled={!mayManageEditable || disabled}
                            name="loanSetting.editable"
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                </div>
            </div>
        </div>
    );
};

export default Loan;
