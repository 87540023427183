// @ts-ignore
import { OutlineSelect } from '@appvantageasia/afc-ui';
import styled from 'styled-components';

export const Select = styled(OutlineSelect)``;

export const Container = styled.div<{ bottom: string }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${props => (props.bottom ? props.bottom : '23px')};

    & ${Select} {
        display: inline-block;
        min-width: 200px;
    }

    & .dealerSelectionContainer {
        display: inline-block;
        min-width: 200px;
        margin-right: 30px;
    }
`;

export const Title = styled.h1`
    color: #000000;
    font-size: 1.43rem;
    font-weight: normal;

    margin-bottom: 0;
`;
