import { SET_RUNTIME_SETTINGS } from '../actions/runtimeSettings';

const reducer = (state = {}, { type, ...payload }) => {
    switch (type) {
        case SET_RUNTIME_SETTINGS:
            return payload.settings;

        default:
            return state;
    }
};

export default reducer;
