import { createContext, useContext } from 'react';
import { Channel } from '../../../schema';

type ChannelOption = {
    value: string;
    label: string;
    title: string;
    permission: string;
    channels: Channel[];
};

export type DashboardContext = {
    channels: Channel[];
    options: ChannelOption[];
    dealerIds: string[] | null;
};

export const Context = createContext<DashboardContext>(null!);

export const useDashboardContext = () => useContext(Context);
