import { createContext, useContext } from 'react';
import { InjectedFormProps } from 'redux-form';
import { LanguageOption } from '../../../../i18n';
import { Maybe, Scalars } from '../../../../schema';

export type FormProps = {
    disabled: boolean;
    languageOptions: LanguageOption[];
};

export type FormData = {
    start: Date;
    startTimeZone: string;
    end: Date;
    endTimeZone: string;
    title: { [language: string]: string };
    description?: Maybe<{ [language: string]: string }>;
    isActive: boolean;
    warningBefore: number;
    image?: Maybe<{ id: Scalars['ObjectID']; url?: Maybe<string> }>;
};

export type FormContextData = FormProps & InjectedFormProps<FormData, FormProps>;

export const Context = createContext<FormContextData>(null!);

export const useFormContext = () => useContext(Context);
