import { createContext, useContext } from 'react';
import { FinderVehicleDataFragment } from '../Edition.graphql';

export type FormProps = {
    disabled: boolean;
    hasDealer: boolean;
    values: Partial<FinderVehicleDataFragment>;
    refetch: (variables: { id: string; countryId: string }) => void;
};

export const Context = createContext<FormProps>(null!);

export const useFormContext = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('Context is missing');
    }

    return context;
};
