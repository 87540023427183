import { HeadTitle } from '@appvantageasia/afc-ui';
import { get, map, orderBy, flow, filter } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useContentTranslation } from '../../../../../../i18n';
import { ApplicationEventSource, ApplicationEventType, CustomerInfoSource } from '../../../../../../schema';
import { useCompanyFormatting } from '../../../../../../utils/withCompanyFormatting';
import useFormatDateTime from '../../../../../shared/useFormatDateTime';
import ActivityLogContainer from '../../../../../ui/ActivityLog';
import useFormValues from '../../../../../utilities/useFormValues';
import AddCommentModal from './AddCommentModal';

const withBy = (activity, author) => (author ? `${activity} by ${author}` : activity);

const withCheckBy = (activity, author) => {
    return withBy(activity, author === 'Customer' ? 'Applicant' : author);
};

const withComment = (activity, comment) => (comment ? `${activity} - ${comment}` : activity);

const formatSource = (item, bank, insuranceCompany) => {
    switch (item.source) {
        case ApplicationEventSource.USER:
            return item.by?.name;

        case ApplicationEventSource.BANK:
            return bank;

        case ApplicationEventSource.CUSTOMER:
            return 'Customer';

        case ApplicationEventSource.GUARANTOR:
            return 'Guarantor';

        case ApplicationEventSource.SYSTEM:
            return 'System';

        case ApplicationEventSource.INSURANCE:
            return insuranceCompany;

        default:
            return null;
    }
};

const formatCustomerInfoSource = source => {
    switch (source) {
        case CustomerInfoSource.MYINFO:
            return 'MyInfo';

        case CustomerInfoSource.MANUAL:
            return 'Manual';

        default:
            return null;
    }
};

const formatActivity = (item, bank, insuranceCompany, firstSubmit, formatCurrencyDown) => {
    const author = formatSource(item, bank, insuranceCompany);

    switch (item.type) {
        case ApplicationEventType.SHARE:
            return withBy('Shared', author);

        case ApplicationEventType.DRAFT:
            return withBy('Draft is saved', author);

        case ApplicationEventType.CHECK:
            return withCheckBy(`Consent ${item.detail?.identifier} Checked`, author);

        case ApplicationEventType.SIGNING:
            return withBy(`Signing Initiated`, author);

        case ApplicationEventType.SIGN:
            return withBy(`Signing Completed`, author);

        case ApplicationEventType.SUBMIT:
            if (!firstSubmit) {
                return withBy(`Re-submitted`, author);
            }

            return withBy(`Submitted`, author);

        case ApplicationEventType.RECEIVE:
            return withBy(`Received`, author);

        case ApplicationEventType.APPROVE: {
            const activity = withBy(`Approved`, author);

            if (!item.detail?.approvedAmount) {
                return activity;
            }

            return `${activity} - ${formatCurrencyDown(item.detail.approvedAmount)}`;
        }

        case ApplicationEventType.DECLINE:
            return withBy(`Declined`, author);

        case ApplicationEventType.CANCEL:
            return withBy(`Cancelled`, author);

        case ApplicationEventType.SUBMISSIONFAIL:
            return withComment(`Submission Failed to ${bank}`, item.comment);

        case ApplicationEventType.UNABLETOSUBMIT:
            return withComment(`Unable to Submit to ${bank}`, item.comment);

        case ApplicationEventType.ACTIVATE:
            return withBy(`Activated`, author);

        case ApplicationEventType.COMPLETE:
            return withBy(`Completed`, author);

        case ApplicationEventType.SIGNREJECT:
            return withBy(`Signing Rejected`, author);

        case ApplicationEventType.DOCUMENTUPLOAD: {
            const { filename } = item.detail;

            return withBy(`Document ${filename} uploaded`, author);
        }

        case ApplicationEventType.DOCUMENTDELETE: {
            const { filename } = item.detail;

            return withBy(`Document ${filename} deleted`, author);
        }

        case ApplicationEventType.CUSTOMERINFORECEIVE: {
            const infoSource = formatCustomerInfoSource(item.detail?.source);

            if (infoSource) {
                return `Applicant's Information Received (${infoSource}) by ${bank}`;
            }

            return `Applicant's Information Received by ${bank}`;
        }

        case ApplicationEventType.GUARANTORINFORECEIVE: {
            const infoSource = formatCustomerInfoSource(item.detail?.source);

            if (infoSource) {
                return `Guarantor's information received (${infoSource})`;
            }

            return `Guarantor's information received`;
        }

        case ApplicationEventType.CANCELLATIONFAIL:
            return withComment(`Cancellation Failed to ${bank}`, item.comment);

        case ApplicationEventType.UNABLETOCANCEL:
            return withComment(`Unable to Cancel to ${bank}`, item.comment);

        case ApplicationEventType.SIGNTIMEOUT:
            return withComment(`Signing Timeout`);

        case ApplicationEventType.UNABLETOCONNECT:
            return withComment(`Unable to Connect`);

        case ApplicationEventType.CONNECTIONFAIL:
            return withComment(`Connection Failed`);

        case ApplicationEventType.PAYING:
            return withBy(`Pending Payment`, author);

        case ApplicationEventType.PAID:
            return withBy(`Payment Completed`, author);

        case ApplicationEventType.PAYMENTTIMEOUT:
            return `Payment Timeout`;

        case ApplicationEventType.UNABLETOPAY:
            return `Unable to Make Payment`;

        case ApplicationEventType.PAYMENTFAIL:
            return withBy(`Payment Failed`, author);

        case ApplicationEventType.PAYMENTCAPTURING:
            return withBy(`Capturing Payment`, author);

        case ApplicationEventType.EMAIL: {
            const { kind, receiver } = item.detail || {};

            return withBy(`Email sent to ${kind}: ${receiver}`, author);
        }

        case ApplicationEventType.ASSIGN: {
            const { kind, from, to } = item.detail;

            if (!kind || !to) {
                return null;
            }

            const assignedTo = `${kind} is assigned to ${to}`;

            if (from) {
                return `${assignedTo} from ${from}`;
            }

            return assignedTo;
        }

        case ApplicationEventType.REFUNDED:
            return withBy(`Payment Refunded`, author);

        case ApplicationEventType.AGREEMENTCONCLUDED:
            return withBy('Agreement Concluded', author);

        case ApplicationEventType.COMMENT: {
            const { comment } = item.detail;

            const information = withBy(`Comment added by`, author);

            return (
                <div style={{ margin: '10px 0' }}>
                    {information}: <br />
                    {comment}
                </div>
            );
        }

        case ApplicationEventType.APPLIEDFORFINANCING:
            return withBy(`Applied for Financing`, author);

        case ApplicationEventType.GUARANTEEDBUYBACKINITIALIZED:
            return withBy(`Guaranteed Buyback Initiated`, author);

        case ApplicationEventType.GUARANTEEDBUYBACKCOMPLETED:
            return withBy(`Guaranteed Buyback Completed`, author);

        case ApplicationEventType.GUARANTEEDBUYBACKREJECTED:
            return withBy(`Guaranteed Buyback`, author);

        case ApplicationEventType.GUARANTEEDBUYBACKTIMEOUT:
            return withComment(`Guaranteed Buyback Timeout`);

        default:
            // skip it
            return null;
    }
};

export const HeadBar = styled.div`
    min-height: 64.5px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Title = styled(HeadTitle)`
    padding-left: 0;
`;

const LinkButton = styled.button`
    text-decoration: underline;
    margin-left: 3px;
    background: none;
    border: none;
    color: ${props => (props.disabled ? '#9c9c9c' : '#069')};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

const ActivityLog = ({ canAddComment = false }) => {
    const { formatCurrencyDown } = useCompanyFormatting();
    const values = useFormValues();
    const history = useHistory();
    const { ct } = useContentTranslation();

    const events = values?.events;
    const formatDateTime = useFormatDateTime();

    const bank = get('display.bank', values);

    const insuranceCompany = get('insuranceCompany.name', values);

    const activities = useMemo(() => {
        let firstSubmit = true;

        return flow([
            map(item => {
                const entry = formatActivity(
                    item,
                    ct(bank),
                    ct(insuranceCompany),
                    firstSubmit,
                    formatCurrencyDown,
                    formatDateTime
                );

                if (entry === null) {
                    // we skip it
                    return null;
                }

                if (item.type === ApplicationEventType.SUBMIT) {
                    firstSubmit = false;
                }

                return { ...item, label: entry };
            }),
            filter(Boolean),
            orderBy('at', 'desc'),
        ])(events);
    }, [events, ct, bank, insuranceCompany, formatCurrencyDown, formatDateTime]);

    if (!activities.length) {
        return null;
    }

    const [addCommentModal, setAddCommentModal] = useState(false);

    const closeAddCommentModal = useCallback(() => {
        setAddCommentModal(null);
        history.go(0);
    }, [setAddCommentModal, history]);

    const openAddCommentModal = useCallback(
        () => setAddCommentModal(<AddCommentModal applicationId={values?.id} onClose={closeAddCommentModal} />),
        [setAddCommentModal, closeAddCommentModal, values]
    );

    return (
        <>
            <HeadBar className="head-bar">
                <Title>Activity Log</Title>
                {canAddComment && <LinkButton onClick={openAddCommentModal}>Add Comment</LinkButton>}
            </HeadBar>
            <ActivityLogContainer>
                <table>
                    <thead>
                        <tr>
                            <th>Date/Time</th>
                            <th>Activity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activities.map((item, index) => (
                            <tr key={index.toString()}>
                                <td>{formatDateTime(item.at)}</td>
                                <td>{item.label}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </ActivityLogContainer>
            {addCommentModal}
        </>
    );
};

ActivityLog.propTypes = {
    canAddComment: PropTypes.bool,
};

export default ActivityLog;
