import { PermissionCode, PermissionLevel } from '../../../../schema';

const defaultPermissions = Object.fromEntries(
    [
        // admin
        PermissionCode.ADMIN_LOGIN,
        PermissionCode.ADMIN_USERS,
        PermissionCode.ADMIN_ROLES,
        PermissionCode.ADMIN_ROLE_HIERARCHY,
        PermissionCode.ADMIN_LEADS,
        PermissionCode.ADMIN_CUSTOMERS,
        PermissionCode.ADMIN_MAINTENANCE,
        PermissionCode.ADMIN_VEHICLES,
        PermissionCode.ADMIN_FINANCE,
        PermissionCode.ADMIN_APPLICATIONS,
        PermissionCode.ADMIN_RESERVATIONS,
        PermissionCode.ADMIN_INSURANCE_APPLICATIONS,
        PermissionCode.ADMIN_PROMO_CODE,
        PermissionCode.ADMIN_NEW_CAR_CHANNEL,
        PermissionCode.ADMIN_USED_CAR_CHANNEL,
        PermissionCode.ADMIN_EXPRESS_CHANNEL,
        PermissionCode.ADMIN_EVENT_CHANNEL,
        PermissionCode.ADMIN_CONSENT_DECLARATION,
        PermissionCode.ADMIN_FINDER_VEHICLE,
        PermissionCode.ADMIN_RECALCULATE,
        PermissionCode.ADMIN_RECALCULATE_INTEREST_RATE,
        PermissionCode.ADMIN_RECALCULATE_RESIDUAL_VALUE,
        PermissionCode.ADMIN_APPOINTMENT,
        // FC
        PermissionCode.CI_NEW_CAR_CHANNEL,
        PermissionCode.CI_USED_CAR_CHANNEL,
        PermissionCode.CI_EXPRESS_CHANNEL,
        PermissionCode.CI_EVENT_CHANNEL,
        PermissionCode.CI_LEADS,
        PermissionCode.CI_CUSTOMERS,
        PermissionCode.CI_APPLICATIONS,
        PermissionCode.CI_RESERVATIONS,
        PermissionCode.CI_INSURANCE_APPLICATIONS,
        PermissionCode.CI_RECALCULATE,
        PermissionCode.CI_RECALCULATE_INTEREST_RATE,
        PermissionCode.CI_RECALCULATE_RESIDUAL_VALUE,
        PermissionCode.CI_APPOINTMENT,
    ].map(key => [key, PermissionLevel.NONE])
);

export default defaultPermissions;
