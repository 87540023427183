import PropTypes from 'prop-types';
import React, { useMemo, useState, useRef } from 'react';
import Modal from '../../modal';
import { Text } from './BoxedTextareaWrapper';
import RawTextarea from './RawTextarea';

const BoxedTextareaInput = props => {
    const { disabled, value, onChange, name, modalLabel } = props;
    const [show, setShow] = useState(false);
    const textareaRef = useRef(null);

    const { openModal, closeModal, update } = useMemo(
        () => ({
            openModal: () => setShow(true),
            closeModal: () => setShow(false),
            update: () => {
                // first update the value for redux
                onChange(textareaRef.current.value);
                // then close the modal
                setShow(false);
            },
        }),
        [setShow, onChange, textareaRef]
    );

    return (
        <>
            <Text onClick={disabled ? undefined : openModal}>{value || '-'}</Text>
            {show && (
                <Modal onClose={closeModal} onConfirm={update} title={modalLabel} showClose showConfirm showTitle>
                    <RawTextarea ref={textareaRef} defaultValue={value} name={name} />
                </Modal>
            )}
        </>
    );
};

BoxedTextareaInput.propTypes = {
    disabled: PropTypes.bool,
    modalLabel: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

export default BoxedTextareaInput;
