import React from 'react';
import BaseSelect from 'react-select';
import { useTheme } from 'styled-components';

const customStyles = {
    container: provided => ({ ...provided, marginBottom: '5px' }),
    control: provided => ({
        ...provided,
        cursor: 'pointer',
        outline: 'none',
        boxShadow: 'none',
        borderWidth: '1px',
        borderColor: '#ced4da',
        borderRadius: 0,
        height: '60px',
        ':hover': {
            ...provided[':hover'],
            borderColor: '#ced4da',
        },
    }),
    dropdownIndicator: provided => ({
        ...provided,
        padding: '0',
        width: '40px',
        color: '#000000',
        ':hover': {
            ...provided[':hover'],
            color: '#000000',
        },
    }),
    valueContainer: provided => ({
        ...provided,
        padding: '0 15px',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        margin: 0,
        color: state.selectProps.theme.calculator.valueColor,
        fontSize: '1.35rem',
        top: 'unset',
        transform: 'none',
    }),
    input: provided => ({ ...provided, margin: 0, fontSize: '1.35rem' }),
    menu: provided => ({
        ...provided,
        width: 'auto',
        left: '0px',
        right: '0px',
        marginTop: 0,
        marginBottom: 0,
        border: '1px solid #ced4da',
        borderTop: 0,
        borderRadius: 0,
        boxShadow: 'none',
        position: 'relative',
    }),
    menuList: provided => ({
        ...provided,
        padding: 0,
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        height: '60px',
        lineHeight: '60px',
        fontSize: '1.35rem',
        padding: '0 15px',
        backgroundColor: 'transparent',
        color: state.isSelected
            ? state.selectProps.theme.calculator.valueColor
            : state.selectProps.theme.calculator.textColor,
        ':hover': {
            ...provided[':hover'],
            backgroundColor: state.selectProps.theme.calculator.valueColor,
            color: '#FFFFFF',
        },
        ':active': {
            ...provided[':active'],
            backgroundColor: state.selectProps.theme.calculator.valueColor,
            color: '#000000',
        },
    }),
    indicatorSeparator: () => ({ display: 'none' }),
};

const CalculatorSelect = props => {
    const theme = useTheme();

    return (
        <BaseSelect
            placeholder=""
            {...props}
            clearable={false}
            noOptionsMessage={() => 'No data available'}
            searchable={false}
            styles={customStyles}
            theme={theme}
        />
    );
};

export default CalculatorSelect;
