import Modal from 'react-modal';
import styled from 'styled-components';

export const CommentModal = styled(Modal)`
    width: 600px;
    height: auto;
`;

export const CloseButton = styled.div`
    cursor: pointer;
    width: 20px;
    height: 20px;
    font-weight: bold;
    color: white;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Header = styled.div`
    margin-bottom: 13px;
    text-align: center;
    text-transform: capitalize;
    font-size: 1.14rem;
`;

export const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
`;

export const ActionButton = styled.div`
    font-weight: bold;
    align-self: center;
    background-color: transparent;
    border: 1px rgba(255, 255, 255, 0.56) solid;
    color: #fff;
    cursor: pointer;
    margin-left: 10px;
    text-transform: capitalize;
    padding: 5px 5px;
`;
