import { useApolloClient } from '@apollo/client';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getFormValues } from 'redux-form';
import { addNotification } from '../../../../../actions';
import { ApplicationEventType } from '../../../../../schema';
import { getGlobalPermissions, getCurrentCountry, getRuntimeSettings } from '../../../../../selectors';
import * as notification from '../../../../../shared/constants/notification';
import { APPLICATION_UPDATE_NOTIFICATION } from '../../../../../shared/constants/notification';
import { handleResponseError } from '../../../../../utilities/forms';
import applySourceChange from '../../../../../utils/applySourceChange';
import { getInsuranceApplicationPermissions, InsuranceApplicationPermissions } from '../../../../../utils/permissions';
import { withModal, WithModalInjectedProps } from '../../../../Modal';
import useValidationContext from '../../../../utilities/useValidationContext';
import { useManagedCallback } from '../../Finance/application/Page';
import {
    approveInsuranceApplication,
    cancelInsuranceApplication,
    declineInsuranceApplication,
} from '../../common/shared/Action.graphql';
import { updateCustomer } from '../../common/shared/customer.graphql';
import { mapUpdateCustomerStateToPayload } from '../../common/utilities/map';
import toPayload from '../../common/utilities/toPayload';
import useConfirm from '../../common/utilities/useConfirm';
import useFormChanged from '../../common/utilities/useFormChanged';
import ApplicationForm from './ApplicationForm';
import Form from './Form';
import { ApplicationFormValues } from './Form/context';
import useButtonStatus from './Form/useButtonStatus';
import { update } from './Page.graphql';

const {
    APPLICATION_APPROVE_NOTIFICATION,
    APPLICATION_CANCEL_NOTIFICATION,
    APPLICATION_COMPLETE_NOTIFICATION,
    APPLICATION_DECLINE_NOTIFICATION,
    APPLICATION_SUBMIT_NOTIFICATION,
} = notification;

export type PageProps = {
    initialValues?: ApplicationFormValues | null;
};

const Page = ({ initialValues = null, modal }: PageProps & WithModalInjectedProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id: countryId } = useSelector(getCurrentCountry);
    const { useCustomerBirthDay, useCustomerNationality } = useSelector(getRuntimeSettings);
    const { mayManageInsuranceApplications } = useSelector(getGlobalPermissions);

    const client = useApolloClient();

    const onCancel = useCallback(() => history.goBack(), [history]);

    const values = useSelector(getFormValues('insuranceApplication')) as ApplicationFormValues;

    const { state } = useLocation<{ customerId: string }>();
    const redirect = useCallback(() => {
        if (state?.customerId) {
            history.push(`/customer/${state?.customerId}`);

            return;
        }

        history.push('/workflow/insuranceApplications');
    }, [history, state]);

    const withManagement = useManagedCallback();

    const handleRedirection = useCallback(
        type => {
            const { identifier } = values || {};

            switch (type) {
                case 0:
                    dispatch(addNotification(APPLICATION_CANCEL_NOTIFICATION(identifier)));
                    break;

                case 1:
                    dispatch(addNotification(APPLICATION_COMPLETE_NOTIFICATION(identifier)));
                    break;

                case 2:
                    dispatch(addNotification(APPLICATION_DECLINE_NOTIFICATION(identifier)));
                    break;

                case 3:
                    dispatch(addNotification(APPLICATION_APPROVE_NOTIFICATION(identifier)));
                    break;

                case 4:
                    dispatch(addNotification(APPLICATION_SUBMIT_NOTIFICATION(identifier)));
                    break;

                case 5:
                    dispatch(addNotification(APPLICATION_UPDATE_NOTIFICATION(identifier)));
                    break;

                default:
                    break;
            }
            redirect();
        },
        [values, redirect, dispatch]
    );

    const isUsedCar = useMemo(() => values?.variant?.channels.express, [values]);

    const onSubmit = useCallback(
        withManagement(async () => {
            const customerData = applySourceChange(values.customer, initialValues?.customer);

            // update customer
            await client.mutate({
                mutation: updateCustomer,
                variables: mapUpdateCustomerStateToPayload(customerData),
            });

            const variables = toPayload(values, null, {});

            const promise = client.mutate({ mutation: update, variables });

            promise.then(() => handleRedirection(4));

            return promise.catch(handleResponseError);
        }),
        [values, client, isUsedCar, countryId, handleRedirection, withManagement]
    );

    const onVoid = useCallback(
        withManagement(() =>
            client
                .mutate({ mutation: cancelInsuranceApplication, variables: { id: initialValues?.id } })
                .then(() => handleRedirection(0))
                .catch(handleResponseError)
        ),
        [client, handleRedirection, initialValues, withManagement]
    );

    const onApprove = useCallback(
        withManagement(() =>
            client
                .mutate({ mutation: approveInsuranceApplication, variables: { id: initialValues?.id } })
                .then(() => handleRedirection(3))
                .catch(handleResponseError)
        ),
        [client, handleRedirection, initialValues, withManagement]
    );

    const onDecline = useCallback(
        withManagement(() =>
            client
                .mutate({ mutation: declineInsuranceApplication, variables: { id: initialValues?.id } })
                .then(() => handleRedirection(2))
                .catch(handleResponseError)
        ),
        [client, handleRedirection, initialValues, withManagement]
    );

    const onSave = useCallback(
        withManagement(() => {
            const customerData = applySourceChange(values.customer, initialValues?.customer);
            client
                .mutate({
                    mutation: updateCustomer,
                    variables: mapUpdateCustomerStateToPayload(customerData),
                })
                .then(() => handleRedirection(5))
                .catch(handleResponseError);
        }),
        [client, values, withManagement]
    );

    const {
        mayVoidApplication: mayVoid,
        mayCompleteApplication: mayComplete,
        mayApprovedApplication: mayApprove,
        maySubmitChangesOnApplication,
        maySaveChangesOnApplication,
    } = useMemo(
        () =>
            (initialValues && getInsuranceApplicationPermissions(initialValues)) ||
            ({} as InsuranceApplicationPermissions),
        [initialValues]
    );

    const {
        isFormDisabled,
        approveDisabled,
        declineDisabled,
        submitChangesDisabled,
        voidDisabled,
        saveChangesDisabled,
    } = useButtonStatus(initialValues, mayManageInsuranceApplications, maySubmitChangesOnApplication) || {};

    const hasChanged = useFormChanged('insuranceApplication');

    const maySubmitChange = maySubmitChangesOnApplication && hasChanged;

    const action = useConfirm(modal);
    const validation = useValidationContext();

    const bodyComponent = useMemo(
        () =>
            initialValues && (
                <Form
                    allowPrimaryInfoChanges={maySaveChangesOnApplication}
                    disabled={isFormDisabled}
                    initialValues={initialValues}
                    useCustomerBirthDay={useCustomerBirthDay}
                    useCustomerNationality={useCustomerNationality}
                    validation={validation}
                />
            ),
        [
            initialValues,
            isFormDisabled,
            maySaveChangesOnApplication,
            useCustomerBirthDay,
            useCustomerNationality,
            validation,
        ]
    );

    const hasSigned = !!initialValues?.events.find(item => item.type === ApplicationEventType.RECEIVE);
    const moreActions = useMemo(() => {
        const actions: Array<{ label: string; disabled: boolean; onAction: () => any }> = [];

        if (mayManageInsuranceApplications && mayVoid) {
            actions.push({
                label: 'void',
                disabled: voidDisabled,
                onAction: () => action(0, onVoid),
            });
        }

        if (mayManageInsuranceApplications && mayApprove) {
            actions.push({
                label: 'approve',
                disabled: approveDisabled,
                onAction: () => action(3, onApprove),
            });

            actions.push({
                label: 'decline',
                onAction: () => action(2, onDecline),
                disabled: declineDisabled,
            });
        }

        return actions;
    }, [
        mayManageInsuranceApplications,
        mayVoid,
        voidDisabled,
        mayApprove,
        approveDisabled,
        declineDisabled,
        action,
        onVoid,
        onApprove,
        onDecline,
    ]);

    const title = useMemo(
        () => (values ? `Insurance Application Details - ${values.identifier}` : 'Insurance Application Details'),
        [values]
    );

    return (
        <ApplicationForm
            bodyComponent={bodyComponent}
            moreActions={moreActions}
            onCancel={onCancel}
            onSubmit={onSubmit}
            title={title}
        />
    );
};

export default withModal(Page);
