import PropTypes from 'prop-types';
import React from 'react';
import { animated, useTransition } from 'react-spring';
import { spring as zoomInSpring } from './ZoomIn';

export const spring = {
    from: zoomInSpring.from,
    enter: zoomInSpring.to,
    leave: zoomInSpring.from,
};

const ZoomInOut = ({ children, visible }) => {
    const transitions = useTransition(visible, null, spring);

    return transitions.map(
        ({ item, key, props: animation }) =>
            item && (
                <animated.div key={key} style={animation}>
                    {children}️
                </animated.div>
            )
    );
};

ZoomInOut.propTypes = {
    children: PropTypes.node.isRequired,
    visible: PropTypes.bool.isRequired,
};

export default ZoomInOut;
