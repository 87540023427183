import React from 'react';
import styled from 'styled-components';
import Input from '../../form/number/NumericInput';
import { Container } from './PureInput';

const StyledInput = styled(Input)`
    outline: none;
    width: 100%;
    border: 0;
    color: ${props => props.theme.themeHighlightColour};
    background: #ffffff;
    height: 18px;
    font-size: 14px;
    text-align: end;

    &::placeholder {
        color: #808080;
    }

    &:disabled {
        color: #000000;
        opacity: 1;
    }
`;

const NumericInput = props => (
    <Container>
        <StyledInput {...props} />
    </Container>
);

export default NumericInput;
