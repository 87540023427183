import { getOr } from 'lodash/fp';
import React from 'react';
import { useCalculatorContext } from '../../CalculatorContext';
import StaticField, { sharedPropTypes } from './StaticField';

export const useVariantLabel = () => {
    // get the calculator context
    const { selectedVariant, hasMakes } = useCalculatorContext();

    // get the label
    const label = getOr('', 'name', selectedVariant);

    if (!hasMakes) {
        return label;
    }

    // get the make too
    const make = getOr('', 'model.make.name', selectedVariant);

    return `${make} ${label}`;
};

const CarModelField = props => {
    const variantName = useVariantLabel();

    return <StaticField {...props} value={variantName} />;
};

CarModelField.propTypes = {
    ...sharedPropTypes,
};

export default CarModelField;
