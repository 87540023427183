import PropTypes from 'prop-types';
import React from 'react';
import OutlineContainer from './OutlineContainer';

const OutlineWrapper = ({ label, name, children, forTextArea = false, isAlt = false }) => (
    <OutlineContainer style={{ flexDirection: forTextArea ? 'column' : 'row' }}>
        {!isAlt && label && <label htmlFor={name}>{label}</label>}
        {children}
    </OutlineContainer>
);

OutlineWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    forTextArea: PropTypes.bool,
    isAlt: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
};

export default OutlineWrapper;
