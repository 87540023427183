import { useQuery, useApolloClient } from '@apollo/client';
import { flow, get, map, uniqBy, filter } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { addNotification } from '../../../../actions';
import { useContentTranslation } from '../../../../i18n';
import { Channel } from '../../../../schema';
import { getCurrentCountry } from '../../../../selectors';
import { INVENTORY_DELETED_NOTIFICATION } from '../../../../shared/constants/notification';
import ConnectedLoadingLayer from '../../../common/ConnectedLoadingLayer';
import { DownloadModal, CloseButton, Header, SelectButton, YesNoButton } from '../../../ui/Download';
import BlockSelect from '../../../ui/form/BlockSelect';
import { getData, remove } from './DeleteInventory.graphql';

const getModelsFromVariants = flow([map(({ model }) => model.parent || model), uniqBy(get('version.id'))]);

const getSubModelsFromVariants = flow([
    map(({ model }) => model.parentId && model),
    uniqBy(get('version.id')),
    filter(Boolean),
]);

const getOptions = (options, ct) => options.map(i => ({ value: i.version.id, label: ct(i.name) }));

const DeleteInventoryModal = ({ onClose, validVariantIds = [] }) => {
    const { ct } = useContentTranslation();
    const [model, setModel] = useState(null);
    const [subModel, setSubModel] = useState(null);
    const [variant, setVariant] = useState(null);

    const channelOptions = [
        {
            label: 'New',
            value: Channel.NEW,
        },
        {
            label: 'Used',
            value: Channel.USED,
        },
        {
            label: 'Express',
            value: Channel.EXPRESS,
        },
        {
            label: 'Event',
            value: Channel.EVENT,
        },
    ];

    const [channel, setChannel] = useState(channelOptions[0]);

    useEffect(() => {
        setModel(null);
        setSubModel(null);
        setVariant(null);
    }, [channel]);

    const { id: countryId } = useSelector(getCurrentCountry);
    const variables = { countryId, channel: channel.value };
    const { data } = useQuery(getData, { variables, fetchPolicy: 'cache-and-netowrk' });

    const validVariants = useMemo(() => {
        if (!data?.variants?.items) {
            return [];
        }

        if (validVariantIds.length === 0) {
            return [];
        }

        const fullVariantList = data?.variants?.items;
        const entries = Object.fromEntries(fullVariantList.map(i => [i.version.id, i]));

        return validVariantIds.map(i => entries[i]).filter(Boolean);
    }, [data, validVariantIds]);

    const validModels = useMemo(() => getModelsFromVariants(validVariants), [validVariants]);

    const validSubModels = useMemo(() => getSubModelsFromVariants(validVariants), [validVariants]);

    const modelOptions = useMemo(() => getOptions(validModels, ct), [validModels, ct]);

    const filteredSubModelOptions = useMemo(() => {
        return getOptions(
            validSubModels.filter(i => i.parentId === model?.value),
            ct
        );
    }, [validSubModels, model, ct]);

    const filteredVariantOptions = useMemo(() => {
        return getOptions(
            validVariants.filter(
                i =>
                    i.model.version.id === model?.value ||
                    i.model.version.id === subModel?.value ||
                    i.model.parent?.version.id === model?.value
            ),
            ct
        );
    }, [validVariants, subModel, model, ct]);

    const previousModelRef = useRef(model);
    useEffect(() => {
        if (
            previousModelRef.current?.value !== model?.value &&
            !filteredSubModelOptions.find(option => option.value === subModel?.value)
        ) {
            if (filteredSubModelOptions.length !== 1) {
                setSubModel(null);
            } else {
                setSubModel(filteredSubModelOptions[0]);
            }

            previousModelRef.current = model;
        }
    }, [previousModelRef, model, filteredSubModelOptions, subModel]);

    const previousSubModel = useRef({ model, subModel });
    useEffect(() => {
        if (
            (previousSubModel.current.model?.value !== model?.value ||
                previousSubModel.current.subModel?.value !== subModel?.value) &&
            !(variant && filteredVariantOptions.find(option => option.value === variant?.value))
        ) {
            if (filteredVariantOptions.length !== 1) {
                setVariant(null);
            } else {
                setVariant(filteredVariantOptions[0]);
            }

            previousSubModel.current = { model, subModel };
        }
    }, [model, subModel, variant, filteredVariantOptions]);

    const client = useApolloClient();
    const history = useHistory();
    const dispatch = useDispatch();
    const onDelete = useCallback(() => {
        if (variant) {
            client.mutate({ mutation: remove, variables: { id: variant?.value } }).then(() => {
                dispatch(addNotification(INVENTORY_DELETED_NOTIFICATION));
                history.push('/vehicle/inventories');
                onClose();
            });
        }
    }, [client, variant, dispatch, onClose, history]);

    return (
        <DownloadModal onRequestClose={onClose} isOpen>
            <div>
                <ConnectedLoadingLayer>
                    <CloseButton onClick={onClose}>&times;</CloseButton>
                    <Header>Delete Inventory</Header>
                    <SelectButton>
                        <BlockSelect
                            className="select"
                            onChange={setChannel}
                            options={channelOptions}
                            placeholder="Select Channel"
                            value={channel}
                        />
                        <BlockSelect
                            className="select"
                            onChange={setModel}
                            options={modelOptions}
                            placeholder="Select Modal"
                            value={model}
                        />
                        <BlockSelect
                            className="select"
                            isDisabled={filteredSubModelOptions.length < 2}
                            onChange={setSubModel}
                            options={filteredSubModelOptions}
                            placeholder="Select Submodel"
                            value={subModel}
                        />
                        <BlockSelect
                            className="select"
                            onChange={setVariant}
                            options={filteredVariantOptions}
                            placeholder="Select Variant"
                            value={variant}
                        />
                    </SelectButton>
                    <SelectButton>
                        <YesNoButton onClick={onClose}>No</YesNoButton>
                        <YesNoButton onClick={onDelete}>Yes</YesNoButton>
                    </SelectButton>
                </ConnectedLoadingLayer>
            </div>
        </DownloadModal>
    );
};

DeleteInventoryModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    validVariantIds: PropTypes.arrayOf(PropTypes.string),
};

export default DeleteInventoryModal;
