import styled from 'styled-components';
import ParameterValue from './ParameterValue';

const Variant = styled(ParameterValue)`
    flex: 1;
    width: auto;
    text-align: ${props => (props.hasMakes ? 'center' : 'left')};
`;

export const Stocks = styled.span`
    display: block;
    font-size: 0.85rem;
    color: ${props => props.theme.calculator.labelColor};
    line-height: 1;
    text-transform: ${props => props.theme.calculator.labelTransform};
`;

export default Variant;
