import { useQuery } from '@apollo/client';
import { map, get } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useContentTranslation } from '../../../i18n';
import { getCurrentCountry } from '../../../selectors';
import BlockSelect from '../form/BlockSelect';
import { getData } from './DealerSelectionDropDown.graphql';

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 15px 30px 15px 0;
`;
const Container = styled.div`
    min-width: 200px;
`;

type DealerSelectionDropDownProps = {
    dealerIds: string[] | null;
    onValueChanged: (selections: string[]) => unknown;
    withWrapper?: boolean;
    isShow?: boolean;
};

type Dealer = {
    label: { [language: string]: string };
    value: string;
};

const DealerSelectionDropDown = ({
    dealerIds,
    onValueChanged,
    withWrapper = true,
    isShow,
}: DealerSelectionDropDownProps) => {
    const { ct } = useContentTranslation();
    const onChange = useCallback(
        selection => {
            onValueChanged(map(get('value'), selection));
        },
        [onValueChanged]
    );

    const { id: countryId } = useSelector(getCurrentCountry);
    const { data, loading } = useQuery(getData, {
        fetchPolicy: 'cache-first',
        variables: { countryId },
    });
    const dealers = data?.dealers || [];

    const options = useMemo(() => dealers.map((dealer: Dealer) => ({ ...dealer, label: ct(dealer.label) })), [
        dealers,
        ct,
    ]);

    const selectedDealers = useMemo(
        () => (dealerIds ? options.filter((dealer: Dealer) => dealerIds.includes(dealer.value)) : options),
        [dealerIds, options]
    );

    useEffect(() => {
        if (!dealerIds && options.length > 0) {
            onChange(options);
        }
    }, [onChange, dealerIds, options]);

    if (loading || !isShow) {
        return null;
    }

    const container = (
        <Container className="dealerSelectionContainer">
            <BlockSelect
                backgroundColor="#d8d8d8"
                // @ts-ignore: `defaultValue` is from react-select but somehow not present for `BlockSelect`
                height="50px"
                maxHeight="100px"
                minHeight="50px"
                onChange={onChange}
                options={options}
                value={selectedDealers}
                isMulti
            />
        </Container>
    );

    return withWrapper ? <Wrapper>{container}</Wrapper> : container;
};

export default DealerSelectionDropDown;
