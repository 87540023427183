import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { getUser } from '../../selectors';
import {
    CompanyList,
    CreateCompany,
    EditCompany,
    CountryList,
    CreateCountry,
    EditCountry,
    CreateZone,
    EditZone,
    CreateBank,
    EditBank,
    DealerList,
    CreateDealer,
    EditDealer,
    CreateInsurance,
    EditInsurance,
} from '../routes/Scope';

const ScopeRouter = () => {
    const user = useSelector(getUser) || {};

    if (!user.isSuperUser) {
        return <Redirect to="/home" />;
    }

    return (
        <Switch>
            <Route component={CompanyList} path="/scope/companies" exact />
            <Route component={CreateCompany} path="/scope/companies/new" exact />
            <Route component={EditCompany} path="/scope/companies/:id" exact />
            <Route component={CountryList} path="/scope/countries" exact />
            <Route component={CreateCountry} path="/scope/countries/new" exact />
            <Route component={EditCountry} path="/scope/countries/:id" exact />
            <Route component={CreateZone} path="/scope/zones/fromCountry/:countryId" exact />
            <Route component={EditZone} path="/scope/zones/:id" exact />
            <Route component={CreateBank} path="/scope/banks/fromCountry/:countryId" exact />
            <Route component={EditBank} path="/scope/banks/:id" exact />
            <Route component={CreateInsurance} path="/scope/insurance/fromCountry/:countryId" exact />
            <Route component={EditInsurance} path="/scope/insurance/:id" exact />
            <Route component={DealerList} path="/scope/dealers" exact />
            <Route component={CreateDealer} path="/scope/dealers/new" exact />
            <Route component={EditDealer} path="/scope/dealers/:id" exact />
            <Redirect to="/home" />
        </Switch>
    );
};

export default ScopeRouter;
