import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Select from 'react-select';
import { useTheme } from 'styled-components';

const OutlineSelect = ({ isAlt, placeholder, ...props }) => {
    const theme = useTheme();
    const customStyles = useMemo(
        () => ({
            container: provided => ({
                ...provided,
                width: '100%',
            }),
            control: (provided, state) => ({
                ...provided,
                cursor: 'pointer',
                outline: 'none',
                boxShadow: 'none',
                borderColor: state.selectProps.hasError ? '#c10034 !important' : '#ced4da !important',
                borderWidth: '0',
                backgroundColor: state.isDisabled ? '#FFFFFF' : '#FFFFFF',
                height: '40px',
            }),
            menu: provided => ({
                ...provided,
                top: '41px',
                right: '0',
                marginTop: 0,
                marginBottom: 0,
                width: 'auto',
                border: '1px solid #ced4da',
                borderRadius: '3px',
                boxShadow: 'none',
            }),
            menuList: provided => ({
                ...provided,
                padding: 0,
            }),
            option: (provided, { isSelected }) => ({
                ...provided,
                cursor: 'pointer',
                minHeight: '38px',
                lineHeight: '1',
                display: 'flex',
                alignItems: 'center',
                padding: '0 15px',
                backgroundColor: '#FFFFFF',
                color: isSelected ? theme.themeHighlightColour : '#000000',
                ':hover': {
                    ...provided[':hover'],
                    backgroundColor: theme.themeHighlightColour,
                    color: '#FFFFFF',
                },
                ':active': {
                    ...provided[':active'],
                    backgroundColor: theme.themeHighlightColour,
                    color: '#000000',
                },
            }),
            dropdownIndicator: (provided, { isDisabled }) => ({
                ...provided,
                padding: '0',
                color: theme.themeHighlightColour,
                ':hover': {
                    ...provided[':hover'],
                    color: '#00000080',
                },
                display: isDisabled ? 'none' : 'flex',
            }),
            indicatorSeparator: () => ({ display: 'none' }),
            input: provided => ({ ...provided, margin: 0 }),
            valueContainer: (provided, { isDisabled }) => ({
                ...provided,
                height: '100%',
                justifyContent: isAlt ? 'start' : 'flex-end',
                paddingLeft: isAlt ? 0 : 'initial',
                paddingRight: isDisabled ? 0 : '8px',
            }),
            singleValue: (provided, { isDisabled }) => ({
                ...provided,
                marginLeft: 0,
                marginRight: 0,
                color: isDisabled ? '#000000' : theme.themeHighlightColour,
                fontSize: '14px',
                top: 'unset',
                transform: 'none',
            }),
        }),
        [theme, isAlt]
    );

    return (
        <Select
            placeholder={isAlt ? placeholder : ''}
            {...props}
            menuPlacement="auto"
            noOptionsMessage={() => 'No data available'}
            styles={customStyles}
        />
    );
};

OutlineSelect.propTypes = {
    isAlt: PropTypes.bool,
    placeholder: PropTypes.string,
};

export default OutlineSelect;
