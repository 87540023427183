import { useApolloClient, ApolloError } from '@apollo/client';
import { flow, filter, map } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getCompanyId } from '../../../../selectors';
import { downloadFile } from '../../../../utils/file';
import ImportModal from '../../../ui/importModal';
import { importMake } from './ImportMakeModal.graphql';

const onDownloadClick = () => {
    const filename = 'Makes_Template.xlsx';
    const url = `/uploads/public/templates/${filename}`;

    downloadFile(url, filename);
};

const ImportMakeModal = ({ onClose }) => {
    const [file, setFile] = useState(null);
    const [errors, setErrors] = useState(null);
    const [updateCount, setUpdateCount] = useState(null);
    const [duplicates, setDuplicates] = useState(null);

    const companyId = useSelector(getCompanyId);

    const client = useApolloClient();

    const onUpload = useCallback(
        async inputFile => {
            try {
                setFile(inputFile);

                const variables = { companyId, file: inputFile, isUpdate: false };

                const { data } = await client.mutate({ mutation: importMake, variables });

                const { updateCount: count, duplicateIds } = data?.response;
                if (duplicateIds.length > 0) {
                    setDuplicates(['Select all', ...duplicateIds].map(id => ({ label: id, key: id, value: false })));
                }

                setUpdateCount(count);
            } catch (error) {
                if (error instanceof ApolloError) {
                    const messages = error.graphQLErrors.reduce((acc, { message }) => `${acc}${message} `, '');
                    setErrors([messages]);
                }
            }
        },
        [client, companyId]
    );

    const onDuplicateClick = useCallback(
        item => {
            if (item.key === 'Select all') {
                const updatedDuplicates = duplicates.map(duplicate => ({ ...duplicate, value: !item.value }));

                return setDuplicates(updatedDuplicates);
            }

            const updatedDuplicates = duplicates.map(duplicate => {
                if (duplicate.key === item.key) {
                    return { ...duplicate, value: !item.value };
                }

                return duplicate;
            });

            return setDuplicates(updatedDuplicates);
        },
        [duplicates]
    );

    const onOverrideClick = useCallback(async () => {
        const duplicateIdsToBeUpdated = flow([filter(x => x.value && x.key !== 'Select all'), map(x => x.key)])(
            duplicates
        );

        const variables = { companyId, file, isUpdate: true, duplicateIdsToBeUpdated };
        const { data } = await client.mutate({ mutation: importMake, variables });

        const { updateCount: count } = data?.response;

        setUpdateCount(count);
    }, [client, companyId, duplicates, file]);

    return (
        <ImportModal
            duplicates={duplicates}
            errors={errors}
            onClose={onClose}
            onDownloadClick={onDownloadClick}
            onDuplicateClick={onDuplicateClick}
            onOverrideClick={onOverrideClick}
            onUpload={onUpload}
            type="Make"
            updateCount={updateCount}
            hasTemplateDownload
        />
    );
};

ImportMakeModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default ImportMakeModal;
