import styled from 'styled-components';
import breakpoints from '../../../utilities/constants/breakpoints';

export const IconBox = styled.div`
    text-align: center;
    line-height: 50px;
    font-size: 1.2rem;
    min-width: 25px;
    color: #74708d;
`;

export const MenuContainer = styled.div`
    display: block;
    padding-bottom: 20px;
    overflow: auto;
    height: calc(100% - 68px);
    background-color: #222034;

    &::-webkit-scrollbar {
        width: ${props => (props.fixedMenu ? 5 : 0)}px;
    }

    &:hover::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #000;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #74708d;
        border-radius: 20px;
        border: 3px solid #74708d;
    }
`;

export const MenuLinkWrapper = styled.div`
    background-color: #000;
    position: fixed;
    height: 100%;
    z-index: 1;
    width: 250px;
    margin-left: ${props => (props.fixedMenu ? 0 : -190)}px;
    transition: all 0.5s ease;

    &:hover {
        margin-left: 0;
    }

    @media (max-width: ${breakpoints.sm}) {
        display: ${props => (props.fixedMenu ? 'initial' : 'none')};
    }
`;

export const LogoContainer = styled.div`
    justify-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 18px;
    min-height: 68px;
    align-items: center;
    background-color: #0e0b20;
`;

export const ToggleContainer = styled.div`
    cursor: pointer;
    text-decoration: none;
    min-width: 25px;
    text-align: center;
`;

export const MenuLogoContainer = styled.div`
    justify-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
