import { get, isEmpty } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, ReduxFormContext } from 'redux-form';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import KeyField from '../../../../template/KeyField';

export const getHash = async rawKey => {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(rawKey);
    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    return `SHA256:${hashArray.map(b => `00${b.toString(16)}`.slice(-2)).join('')}`;
};

const MyInfoIntegration = ({ disabled = false }) => {
    const { sectionPrefix, change, form } = useContext(ReduxFormContext);
    const values = useSelector(getFormValues(form));
    const dispatch = useDispatch();

    const myInfoFieldName = useMemo(() => (sectionPrefix ? `${sectionPrefix}.myInfo` : 'myInfo'), [sectionPrefix]);
    const hasMyInfoIntegrationFieldName = useMemo(
        () => (sectionPrefix ? `${sectionPrefix}.hasMyInfoIntegration` : 'hasMyInfoIntegration'),
        [sectionPrefix]
    );

    const integration = get(myInfoFieldName, values);
    const hasIntegration = get(hasMyInfoIntegrationFieldName, values);

    // use a reference to avoid triggering the effect
    const previousIntegration = useRef(integration);
    previousIntegration.current = integration;

    useEffect(() => {
        if (!hasIntegration) {
            dispatch(change(myInfoFieldName, null));
        } else if (isEmpty(previousIntegration.current)) {
            dispatch(change(myInfoFieldName, {}));
        }
    }, [hasIntegration, previousIntegration, change, myInfoFieldName, dispatch]);

    if (!hasIntegration) {
        return null;
    }

    return (
        <>
            <hr />
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Myinfo Client ID">
                        <Field component={InputField} disabled={disabled} name="myInfo.clientId" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Myinfo Client Secret">
                        <Field component={InputField} disabled={disabled} name="myInfo.clientSecret" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Myinfo Request Purpose">
                        <Field component={InputField} disabled={disabled} name="myInfo.purpose" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Myinfo Private Key">
                        <Field component={KeyField} disabled={disabled} hashFun={getHash} name="myInfo.privateKey" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Myinfo Private Key Passphrase">
                        <Field component={InputField} disabled={disabled} name="myInfo.privateKeyPassphrase" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Myinfo Public Certificate">
                        <Field component={KeyField} disabled={disabled} hashFun={getHash} name="myInfo.publicCert" />
                    </FieldContainer>
                </div>
            </div>
        </>
    );
};

MyInfoIntegration.propTypes = {
    disabled: PropTypes.bool,
};

export default MyInfoIntegration;
