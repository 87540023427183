import { createContext, useContext } from 'react';

type LanguageOptions = {
    label: string;
    value: string;
};

export type FormContextProps = {
    disabled?: boolean;
    languageOptions: LanguageOptions[];
};

export const Context = createContext<FormContextProps>(null!);

export const useFormContext = () => useContext(Context);
