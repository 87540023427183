import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const LoadingIndicator = ({ className, children }) => (
    <div className={className}>
        <div className="mask" />
        <div className="indicator">{children}</div>
    </div>
);

LoadingIndicator.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

const TabContainer = styled.div`
    position: relative;
    margin-top: 25px;
`;

const Tabs = styled.div`
    cursor: pointer;
    position: absolute;
    top: -55px;
    right: 0;
    display: flex;
    flex-direction: row;
    font-size: 0.78rem;
    text-transform: uppercase;
`;

const Document = {
    Title: styled.div`
        padding: 6px 0 9.5px 0;
        font-size: 1.28rem;
        display: flex;
        flex-direction: row;
        color: #000000;
        height: 39px;
        box-sizing: border-box;
        cursor: pointer;
    `,
    TabContainer,
    Tabs,
    TabItem: styled.div`
        color: #000000;
        border-bottom: 1px solid #dedede;
        padding: 5px 10px;
        line-height: 1;
        text-align: center;
        flex: 1;
        &:nth-child(1) {
            flex: 1;
            border: 0;
            border-bottom: 1px solid #dedede;
        }
        &.active {
            color: #000000;
            border-bottom: 2px solid #007acc;
        }
    `,
    DocumentContainer: styled.div`
        margin-right: 20px;
        @media screen and (max-width: 670px) {
            ${[TabContainer]} {
                margin-top: 0;
                ${Tabs} {
                    position: static;
                    margin-bottom: 20px;
                    overflow: auto;
                }
            }
        }
    `,
    DocumentBg: styled.div`
        width: 100%;
        background-color: rgba(255, 255, 255, 0.31);
    `,

    DocumentGrid: styled.div`
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    `,
    PreviewContainer: styled.div`
        position: relative;
        width: 100%;
        height: 100%;
    `,
    PreviewItem: styled.div`
        width: 105px;
        height: 150px;
        background-color: rgba(0, 0, 0, 0.06);
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            color: black;
            width: 105px;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            height: 150px;
            display: block;
            border: 1px solid #eeeeee;
            cursor: pointer;
        }
        .clear {
            cursor: pointer;
            position: absolute;
            right: 3px;
            top: 3px;
            img {
                width: 20px;
                height: 20px;
                border: 0;
            }
        }
    `,
    Clear: styled.div`
        cursor: pointer;
        position: absolute;
        right: 3px;
        top: 3px;
        img {
            width: 20px;
            height: 20px;
            border: 0;
        }
    `,

    SVGBox: styled.div`
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 0;
        svg.default-image {
            font-size: 1.43rem;
            color: #aaa;
        }
        svg.loading {
            font-size: 1.43rem;
            color: #000000;
        }
        &.render {
            cursor: pointer;
        }
    `,
    PreviewUpload: styled.label`
        display: flex;
        flex: 1;
        min-width: 105px;

        .upload {
            flex: 1;
            height: 150px;
            text-transform: uppercase;
            background-color: rgba(0, 0, 0, 0.06);
            color: rgba(0, 0, 0, 0.38);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            cursor: pointer;
            position: relative;
            input {
                display: none;
            }
            .replace-style {
                width: 60px;
                text-align: center;
            }
        }
    `,
    ErrorMessageDiv: styled.div`
        color: #fd007a;
        font-size: 0.72rem;
        padding: 5px 0 0 0;
    `,
    LoadingIndicator: styled(LoadingIndicator)`
        .mask {
            background: black;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0.5;
            z-index: 9;
        }

        .indicator {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;
        }
    `,
};

export default Document;
