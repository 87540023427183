import { useQuery } from '@apollo/client';
import { isNil } from 'lodash/fp';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCountry, getZoneId } from '../../../selectors';
import { getPopularApplicationEvent, GetPopularApplicationEventQuery } from './PopularApplicationEventChart.graphql';
import PopularChart from './PopularChart';
import { useDashboardContext } from './context';

const title = 'Most Popular Event - Applications (5)';

const PopularApplicationEventChart = () => {
    const { id: countryId } = useSelector(getCurrentCountry);
    const zoneId = useSelector(getZoneId);
    const { channels, dealerIds } = useDashboardContext();
    const variables = {
        filter: { zoneId, countryId, dealerIds, channels },
        isLead: false,
    };
    const { data } = useQuery<GetPopularApplicationEventQuery>(getPopularApplicationEvent, {
        skip: isNil(dealerIds) || dealerIds.length < 1 || channels.length < 1,
        fetchPolicy: 'no-cache',
        variables,
    });

    return <PopularChart data={data} id="applicationEvent" title={title} xAxisTitle="No. of Submitted Applications" />;
};

export default PopularApplicationEventChart;
