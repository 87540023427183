import { ApolloError, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { IMAGE_ARCHIVE_TYPE, isValidImageArchiveExtension, openImportDialog } from './utils';

const useUploadImages = (setErrors, getSuccessModalTitle, mutation, countryId, modal) => {
    const client = useApolloClient();

    return useCallback(async () => {
        const file = await openImportDialog(IMAGE_ARCHIVE_TYPE);

        if (!file) {
            // there is no file
            return;
        }

        if (!isValidImageArchiveExtension(file)) {
            // update the state to inform the extension is not right

            setErrors(['Invalid file extension, only zip, png, jpeg, or jpg files are allowed']);

            return;
        }

        try {
            const {
                data: { updatedCount },
            } = await client.mutate({ mutation, variables: { countryId, file } });

            modal.confirm({
                title: getSuccessModalTitle(updatedCount ?? 0),
                content: '',
                options: [],
            });
        } catch (error) {
            if (error instanceof ApolloError) {
                const messages = error.graphQLErrors.reduce((acc, { message }) => `${acc}${message} `, '');
                setErrors([messages]);
            }
        }
    }, [client, countryId, modal, setErrors, mutation, getSuccessModalTitle]);
};

export default useUploadImages;
