import { useQuery } from '@apollo/client';
import { faDownload, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash/fp';
import React, { useCallback, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { exportMakes } from '../../../../actions';
import { FootBar, FootBarButton, FootContainer } from '../../../../containers/Layout';
import { useContentTranslation } from '../../../../i18n';
import { getCompanyId, getGlobalPermissions } from '../../../../selectors';
import { searchOnKeys } from '../../../../utilities/fp';
import { useSort } from '../../../../utilities/hooks';
import { List, cells, ListSearch } from '../../../ui/lists';
import usePaging from '../../../utilities/usePaging';
import { ImportMakeModal } from '../imports';
import { getTables } from './MakeList.graphql';
import useVehicleTabs from './useVehiclesTabs';

const columns = formatPath => [
    { name: 'Make ID', id: 'identifier', renderCell: get('identifier'), hasSort: true },
    {
        name: 'Make Name',
        id: formatPath('name'),
        renderCell: get(formatPath('name')),
        hasSort: true,
        underline: true,
    },
    { name: 'Order No.', id: 'order', renderCell: get('order'), hasSort: true },
    { name: 'Active', id: 'active', renderCell: cells.renderActive(), hasSort: true },
];

const searchFields = formatPath => ['identifier', formatPath('name')];

const MakeList = () => {
    const { formatPath } = useContentTranslation();
    const tabs = useVehicleTabs();
    const history = useHistory();
    const { mayManageVehicles } = useSelector(getGlobalPermissions);
    const [showImport, setShowImport] = useState(false);

    const companyId = useSelector(getCompanyId);
    const variables = { companyId };
    const { data, loading, error, refetch } = useQuery(getTables, { fetchPolicy: 'cache-and-network', variables });
    const items = data?.results?.items || [];
    const isLoading = loading && items.length <= 0;

    // searching
    const [search, setSearch] = useState('');
    const searchMethod = useMemo(() => searchOnKeys(searchFields(formatPath), items), [items, formatPath]);
    const matchedItems = useMemo(() => searchMethod(search), [search, searchMethod]);

    // sorting
    const [sortedOn, onSort] = useState(['identifier', 'asc']);
    const sortedItems = useSort(matchedItems, sortedOn);

    // paging
    const [pagedItems, paging] = usePaging(sortedItems, { search });

    const dispatch = useDispatch();
    const exportExcel = useCallback(() => {
        return dispatch(exportMakes());
    }, [dispatch]);

    const onImportClose = useCallback(() => {
        setShowImport(false);
        refetch();
    }, [refetch]);

    const footer = (
        <FootContainer>
            <FootBar>
                {mayManageVehicles && (
                    <>
                        <FootBarButton onClick={() => history.push('/vehicle/makes/new')}>
                            <FontAwesomeIcon icon={faPlus} /> ADD Make
                        </FootBarButton>
                        <FootBarButton onClick={exportExcel}>
                            <FontAwesomeIcon icon={faDownload} /> EXPORT EXCEL
                        </FootBarButton>
                        <FootBarButton onClick={() => setShowImport(true)}>
                            <FontAwesomeIcon icon={faUpload} /> IMPORT EXCEL
                        </FootBarButton>
                    </>
                )}
            </FootBar>
        </FootContainer>
    );

    return (
        <>
            <List
                activeTab="make"
                columns={columns(formatPath)}
                error={error}
                footer={footer}
                headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
                items={pagedItems}
                loading={isLoading}
                onItemClick={item => history.push(`/vehicle/makes/${item.id}`)}
                onSort={onSort}
                paging={paging}
                sortedOn={sortedOn}
                tabs={tabs}
                title="Make"
            />
            {showImport && <ImportMakeModal onClose={onImportClose} />}
        </>
    );
};

export default MakeList;
