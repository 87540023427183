import React from 'react';
import ApvPortlet from '../../../../template/Portlet';
import Calculation from './Calculation';
import MainParameter from './MainParameter';

const General = () => (
    <>
        <ApvPortlet name="Main Details" closable open>
            <MainParameter />
        </ApvPortlet>
        <ApvPortlet name="Calculation" closable open>
            <Calculation />
        </ApvPortlet>
    </>
);

export default General;
