import { useApolloClient } from '@apollo/client';
// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import dayjs from 'dayjs';
import { get, isNil } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field, FormSection } from 'redux-form';
import styled from 'styled-components';
import getDepreciation from '../../../../helpers/getDepreciation';
import { useContentTranslation } from '../../../../i18n';
import { FinderVehicleStatus } from '../../../../schema';
import { getCurrentCountry } from '../../../../selectors';
import {
    yesNoOptions,
    showHideOptions,
    finderVehicleAvailabilityOptions,
    coeCategoryOptions,
    ownerIdOptions,
} from '../../../../shared/constants/options';
import { handleResponseError } from '../../../../utilities/forms';
import CheckboxField from '../../../shared/form/CheckboxField';
import CurrencyField from '../../../template/Currency';
import DatePickerField from '../../../template/DatePicker';
import DropdownField from '../../../template/Dropdown';
import FieldContainer from '../../../template/Field-container';
import InputField from '../../../template/Input';
import NumberField from '../../../template/Number';
import StaticCurrencyField from '../../../template/StaticCurrency';
import SwitchField from '../../../template/Switch';
import BlockSelect from '../../../ui/form/BlockSelect';
import { reset } from '../Page.graphql';
import Currency from './Currency';
import ElectricConsumption from './ElectricConsumption';
import Emission from './Emission';
import Equipments from './Equipments';
import FuelConsumption from './FuelConsumption';
import LocalizedValue from './LocalizedValue';
import NumberUnit from './NumberUnit';
import { useFormContext } from './context';

const LinkButton = styled.button`
    text-decoration: underline;
    margin-left: 3px;
    background: none;
    border: none;
    color: ${props => (props.disabled ? '#9c9c9c' : '#069')};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

const MainDetails = () => {
    const { disabled, hasDealer, refetch, values } = useFormContext();
    const { ct } = useContentTranslation();
    const client = useApolloClient();
    const { id: countryId, rounding, currency } = useSelector(getCurrentCountry);

    const status = get('status', values);
    const id = get('id', values);

    const financeProducts = values?.financeProducts || [];

    const allowToReset = status === FinderVehicleStatus.RESERVED || status === FinderVehicleStatus.SUBMITTED;

    const handleReset = useCallback(() => {
        const variables = {
            id,
        };

        return client
            .mutate({ mutation: reset, variables })
            .then(() => {
                refetch({ id, countryId });
            })
            .catch(handleResponseError);
    }, [id, client, refetch, countryId]);

    const ResetComponent = useMemo(() => {
        return (
            <>
                <span>Status</span>
                <LinkButton disabled={!allowToReset} onClick={handleReset}>
                    Reset
                </LinkButton>
            </>
        );
    }, [allowToReset, handleReset]);

    const financeProductOptions = useMemo(
        () =>
            financeProducts.map(financeProduct => ({
                value: financeProduct.id,
                label: `${ct(financeProduct.name)} (${financeProduct.identifier})`,
            })),
        [financeProducts, ct]
    );

    // depreciation calculation
    const price = values.listing?.price?.value;
    const partRebateAmount = values.details?.parfRebateAmount;
    const coeExpiryDate = values.details?.coeExpiryDate;
    const registrationDate = values.details?.firstRegistrationDate;

    const depreciation = useMemo(() => {
        const today = dayjs();

        if (isNil(price) || isNil(partRebateAmount)) {
            return undefined;
        }

        return getDepreciation(price, partRebateAmount, coeExpiryDate, registrationDate, today);
    }, [price, partRebateAmount, coeExpiryDate, registrationDate]);

    const hasDetails = !!values.details;
    const vehicleCondition = get('listing.vehicle.condition.value', values);

    return (
        <>
            <Portlet name="Main Details" closable open>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Button State">
                                <Field
                                    component={SwitchField}
                                    disabled={disabled}
                                    name="setting.isHidden"
                                    options={showHideOptions}
                                />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Button Function">
                                <Field
                                    component={SwitchField}
                                    disabled={disabled}
                                    name="setting.availability"
                                    options={finderVehicleAvailabilityOptions}
                                />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label={ResetComponent}>
                                <Field component={InputField} name="status" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Financial Product Assigned">
                                <BlockSelect
                                    backgroundColor="#d8d8d8"
                                    height="50px"
                                    maxHeight="100px"
                                    minHeight="50px"
                                    options={financeProductOptions}
                                    value={financeProductOptions}
                                    disabled
                                    isMulti
                                />
                            </FieldContainer>
                        </div>
                        {!!vehicleCondition && vehicleCondition !== 'new' && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Inspected by Dealer">
                                    <CheckboxField name="inspected" />
                                </FieldContainer>
                            </div>
                        )}
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Last Modified">
                                <Field component={InputField} name="lastModified" disabled />
                            </FieldContainer>
                        </div>
                    </div>
                </div>
            </Portlet>

            <Portlet name="Finder Vehicle Details" closable open>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="id">
                                <Field component={InputField} name="listing.id" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="marketplaceId">
                                <Field component={InputField} name="listing.marketplaceId" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="stockNumber">
                                <Field component={InputField} name="listing.stockNumber" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="additionalCustomerInformationAvailable">
                                <Field
                                    component={SwitchField}
                                    name="listing.additionalCustomerInformationAvailable"
                                    options={yesNoOptions}
                                    disabled
                                />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="availableFrom/value">
                                <Field component={DatePickerField} name="listing.availableFrom.value" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="onlineOrderableState">
                                <Field component={InputField} name="listing.onlineOrderableState" disabled />
                            </FieldContainer>
                        </div>

                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="price/currencyCode">
                                <Field component={InputField} name="listing.price.currencyCode" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="price/localize">
                                <Field component={InputField} name="listing.price.localize" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="price/recycleFeeType/localize">
                                <Field component={InputField} name="listing.price.recycleFeeType.localize" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="price/recycleFeeType/value">
                                <Field component={InputField} name="listing.price.recycleFeeType.value" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="price/value">
                                <Field component={InputField} name="listing.price.value" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="price/vatDeductible">
                                <Field component={InputField} name="listing.price.vatDeductible" disabled />
                            </FieldContainer>
                        </div>

                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer isWarning={!hasDealer} label="seller/address/localize">
                                <Field component={InputField} name="listing.seller.address.localize" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer isWarning={!hasDealer} label="seller/email">
                                <Field component={InputField} name="listing.seller.email" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer isWarning={!hasDealer} label="seller/id">
                                <Field component={InputField} name="listing.seller.id" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer isWarning={!hasDealer} label="seller/name/localize">
                                <Field component={InputField} name="listing.seller.name.localize" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer isWarning={!hasDealer} label="seller/phoneNumber">
                                <Field component={InputField} name="listing.seller.phoneNumber" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer isWarning={!hasDealer} label="seller/porschePartnerNumber">
                                <Field component={InputField} name="listing.seller.porschePartnerNumber" disabled />
                            </FieldContainer>
                        </div>

                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/vin">
                                <Field component={InputField} name="listing.vehicle.vin" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/commissionNumber">
                                <Field component={InputField} name="listing.vehicle.commissionNumber" disabled />
                            </FieldContainer>
                        </div>
                        <FormSection name="listing.vehicle.modelSeries">
                            <LocalizedValue labelPrefix="vehicle/modelSeries" />
                        </FormSection>
                        <FormSection name="listing.vehicle.modelCategory">
                            <LocalizedValue labelPrefix="vehicle/modelCategory" />
                        </FormSection>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/modelYear">
                                <Field component={InputField} name="listing.vehicle.modelYear" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/firstRegistrationDate/value">
                                <Field
                                    component={DatePickerField}
                                    name="listing.vehicle.firstRegistrationDate.value"
                                    disabled
                                />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/firstRegistrationDate/localize">
                                <Field
                                    component={InputField}
                                    name="listing.vehicle.firstRegistrationDate.localize"
                                    disabled
                                />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/name/localize">
                                <Field component={InputField} name="listing.vehicle.name.localize" disabled />
                            </FieldContainer>
                        </div>
                        <FormSection name="listing.vehicle.mileage">
                            <NumberUnit labelPrefix="vehicle/mileage" />
                        </FormSection>
                        <FormSection name="listing.vehicle.power.horsepower">
                            <NumberUnit labelPrefix="vehicle/power/horsepower" />
                        </FormSection>
                        <FormSection name="listing.vehicle.power.kilowatt">
                            <NumberUnit labelPrefix="vehicle/power/kilowatt" />
                        </FormSection>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/power/localizeValue">
                                <Field component={InputField} name="listing.vehicle.power.localizeValue" disabled />
                            </FieldContainer>
                        </div>
                        <FormSection name="listing.vehicle.displacement.cubicCentimeter">
                            <NumberUnit labelPrefix="vehicle/displacement/cubicCentimeter" />
                        </FormSection>
                        <FormSection name="listing.vehicle.displacement.liter">
                            <NumberUnit labelPrefix="vehicle/displacement/liter" />
                        </FormSection>
                        <FormSection name="listing.vehicle.transmission">
                            <LocalizedValue labelPrefix="vehicle/transmission" />
                        </FormSection>
                        <FormSection name="listing.vehicle.engineType">
                            <LocalizedValue labelPrefix="vehicle/engineType" />
                        </FormSection>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/numberOfCylinders">
                                <Field component={InputField} name="listing.vehicle.numberOfCylinders" disabled />
                            </FieldContainer>
                        </div>
                        <FormSection name="listing.vehicle.fuelType">
                            <LocalizedValue labelPrefix="vehicle/fuelType" />
                        </FormSection>
                        <FormSection name="listing.vehicle.bodyType">
                            <LocalizedValue labelPrefix="vehicle/bodyType" />
                        </FormSection>
                        <FormSection name="listing.vehicle.drivetrain">
                            <LocalizedValue labelPrefix="vehicle/drivetrain" />
                        </FormSection>
                        <FormSection name="listing.vehicle.exteriorColor.colorGroup">
                            <LocalizedValue labelPrefix="vehicle/exteriorColor/colorGroup" />
                        </FormSection>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/exteriorColor/metallic">
                                <Field
                                    component={SwitchField}
                                    name="listing.vehicle.exteriorColor.metallic"
                                    options={yesNoOptions}
                                    disabled
                                />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/exteriorColor/name/localize">
                                <Field
                                    component={InputField}
                                    name="listing.vehicle.exteriorColor.name.localize"
                                    disabled
                                />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/exteriorColor/value">
                                <Field component={InputField} name="listing.vehicle.exteriorColor.value" disabled />
                            </FieldContainer>
                        </div>
                        <FormSection name="listing.vehicle.interior.color.colorGroup">
                            <LocalizedValue labelPrefix="vehicle/interior/color/colorGroup" />
                        </FormSection>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/interior/color/seatColorHexCode">
                                <Field
                                    component={InputField}
                                    name="listing.vehicle.interior.color.seatColorHexCode"
                                    disabled
                                />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/interior/name/localize">
                                <Field component={InputField} name="listing.vehicle.interior.name.localize" disabled />
                            </FieldContainer>
                        </div>
                        <FormSection name="listing.vehicle.interior.material">
                            <LocalizedValue labelPrefix="vehicle/interior/material" />
                        </FormSection>
                        <FormSection name="listing.vehicle.rooftopColor.colorGroup">
                            <LocalizedValue labelPrefix="vehicle/rooftopColor/colorGroup" />
                        </FormSection>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/rooftopColor/name/localize">
                                <Field
                                    component={InputField}
                                    name="listing.vehicle.rooftopColor.name.localize"
                                    disabled
                                />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/rooftopColor/value">
                                <Field component={InputField} name="listing.vehicle.rooftopColor.value" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/numberOfPreviousOwners">
                                <Field component={InputField} name="listing.vehicle.numberOfPreviousOwners" disabled />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/accidentFree">
                                <Field
                                    component={SwitchField}
                                    name="listing.vehicle.accidentFree"
                                    options={yesNoOptions}
                                    disabled
                                />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/nextGeneralInspection/value">
                                <Field
                                    component={DatePickerField}
                                    name="listing.vehicle.nextGeneralInspection.value"
                                    disabled
                                />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/nextGeneralInspection/localize">
                                <Field
                                    component={InputField}
                                    name="listing.vehicle.nextGeneralInspection.localize"
                                    disabled
                                />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/orderTypeCode">
                                <Field component={InputField} name="listing.vehicle.orderTypeCode" disabled />
                            </FieldContainer>
                        </div>
                        <Equipments />
                        <FormSection name="listing.vehicle.energyEfficiency.france.critAir">
                            <LocalizedValue labelPrefix="vehicle/energyEfficiency/france/critAir" />
                        </FormSection>

                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/energyEfficiency/france/energyEfficiencyClass">
                                <Field
                                    component={InputField}
                                    name="listing.vehicle.energyEfficiency.france.energyEfficiencyClass"
                                    disabled
                                />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/energyEfficiency/switzerland/energyEfficiencyClass">
                                <Field
                                    component={InputField}
                                    name="listing.vehicle.energyEfficiency.switzerland.energyEfficiencyClass"
                                    disabled
                                />
                            </FieldContainer>
                        </div>
                        <FormSection name="listing.vehicle.unladenWeight.kilogramm">
                            <NumberUnit labelPrefix="vehicle/unladenWeight/kilogramm" />
                        </FormSection>
                        <FormSection name="listing.vehicle.unladenWeight.pound">
                            <NumberUnit labelPrefix="vehicle/unladenWeight/pound" />
                        </FormSection>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/fullServiceHistory">
                                <Field
                                    component={SwitchField}
                                    name="listing.vehicle.fullServiceHistory"
                                    options={yesNoOptions}
                                    disabled
                                />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="vehicle/productionDate">
                                <Field component={DatePickerField} name="listing.vehicle.productionDate" disabled />
                            </FieldContainer>
                        </div>
                        <FormSection name="listing.vehicle.driveSide">
                            <LocalizedValue labelPrefix="vehicle/driveSide" />
                        </FormSection>
                        <FormSection name="listing.vehicle.modelGeneration">
                            <LocalizedValue labelPrefix="vehicle/modelGeneration" />
                        </FormSection>
                        <FormSection name="listing.vehicle.usageType">
                            <LocalizedValue labelPrefix="vehicle/usageType" />
                        </FormSection>
                        <FormSection name="listing.vehicle.condition">
                            <LocalizedValue labelPrefix="vehicle/condition" />
                        </FormSection>

                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="warranty/porscheApproved">
                                <Field
                                    component={SwitchField}
                                    name="listing.warranty.porscheApproved"
                                    options={yesNoOptions}
                                    disabled
                                />
                            </FieldContainer>
                        </div>

                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="updatedAt">
                                <Field component={InputField} name="listing.updatedAt" disabled />
                            </FieldContainer>
                        </div>
                    </div>
                </div>
            </Portlet>

            {hasDetails && (
                <Portlet name="Data from LTA" closable open>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <DropdownField
                                    disabled={disabled}
                                    label="Owner Id Type"
                                    name="details.ownerIdType"
                                    options={ownerIdOptions}
                                    noSort
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Owner Id">
                                    <Field component={InputField} disabled={disabled} name="details.ownerId" />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Vehicle Make">
                                    <Field component={InputField} disabled={disabled} name="details.make" />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Vehicle Model">
                                    <Field component={InputField} disabled={disabled} name="details.model" />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Vehicle No.">
                                    <Field
                                        component={InputField}
                                        disabled={disabled}
                                        name="details.registrationNumber"
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Transfers Count">
                                    <Field component={NumberField} disabled={disabled} name="details.transfers" />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Original Registration Date">
                                    <Field
                                        component={DatePickerField}
                                        disabled={disabled}
                                        name="details.originalRegistrationDate"
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Manufacturing Year">
                                    <Field
                                        component={InputField}
                                        disabled={disabled}
                                        name="details.yearOfManufacture"
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Open Market Value">
                                    <Field
                                        component={CurrencyField}
                                        disabled={disabled}
                                        name="details.omv"
                                        precision={rounding?.amount?.count}
                                        prefix={currency}
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="QP Paid">
                                    <Field
                                        component={CurrencyField}
                                        disabled={disabled}
                                        name="details.coe"
                                        precision={rounding?.amount?.count}
                                        prefix={currency}
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="PQP Paid">
                                    <Field
                                        component={CurrencyField}
                                        disabled={disabled}
                                        name="details.pqp"
                                        precision={rounding?.amount?.count}
                                        prefix={currency}
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Actual ARF Paid">
                                    <Field
                                        component={CurrencyField}
                                        disabled={disabled}
                                        name="details.arf"
                                        precision={rounding?.amount?.count}
                                        prefix={currency}
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Engine No.">
                                    <Field component={InputField} disabled={disabled} name="details.engineNumber" />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Chassis No.">
                                    <Field component={InputField} disabled={disabled} name="details.chassisNumber" />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="First Registration Date">
                                    <Field
                                        component={DatePickerField}
                                        disabled={disabled}
                                        name="details.firstRegistrationDate"
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Primary Colour">
                                    <Field component={InputField} disabled={disabled} name="details.colour" />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Depreciation">
                                    <StaticCurrencyField
                                        precision={rounding?.amount?.count}
                                        prefix={currency}
                                        value={depreciation}
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="PARF Eligibility Expiry Date">
                                    <Field
                                        component={DatePickerField}
                                        disabled={disabled}
                                        name="details.parfEligibilityExpiryDate"
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="PARF Rebate Amount">
                                    <Field
                                        component={CurrencyField}
                                        disabled={disabled}
                                        name="details.parfRebateAmount"
                                        precision={rounding?.amount?.count}
                                        prefix={currency}
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <DropdownField
                                    disabled={disabled}
                                    label="COE Category"
                                    name="details.coeCategory"
                                    options={coeCategoryOptions}
                                    noSort
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="COE Period (Years)">
                                    <Field component={NumberField} disabled={disabled} name="details.coePeriod" />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="COE Expiry Date">
                                    <Field
                                        component={DatePickerField}
                                        disabled={disabled}
                                        name="details.coeExpiryDate"
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Max Power Output (bhp)">
                                    <Field component={NumberField} disabled={disabled} name="details.power" />
                                </FieldContainer>
                            </div>
                        </div>
                    </div>
                </Portlet>
            )}
        </>
    );
};

export default MainDetails;
