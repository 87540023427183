import { useApolloClient } from '@apollo/client';
import { omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory } from 'react-router';
import { submit, getFormValues, change } from 'redux-form';
import { addNotification } from '../../../../actions';
import { AdhocAttachmentPurpose, AssetCondition } from '../../../../schema';
import { getGlobalPermissions } from '../../../../selectors';
import { VARIANT_DELETE_NOTIFICATION, VARIANT_SAVE_NOTIFICATION } from '../../../../shared/constants/notification';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import useLanguageSelector from '../../../../utils/useLanguageSelector';
import { withModal } from '../../../Modal';
import FormLayout from '../../../ui/form/FormLayout';
import Form from './Form';
import { create, update, uploadImage, deleteVariant } from './Page.graphql';

const Page = ({ initialValues = null, options = [], modal }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const store = useStore();
    const { mayManageVehicles } = useSelector(getGlobalPermissions);
    const languageSelector = useLanguageSelector();

    const submitForm = useCallback(() => {
        const { assetCondition } = getFormValues('variant')(store.getState(), ['variant']);
        const isUsedAssetCondition = assetCondition === AssetCondition.USED;
        const showModal = options.length > 0;
        const optionNames = options.map(option => option.name).join(', ');
        const initialAssetCondition = initialValues?.assetCondition || AssetCondition.NEW;

        if (isUsedAssetCondition && showModal) {
            modal.confirm({
                title: 'Error',
                content: `Unable to change asset condition to USED for this variant as it is tied to: ${optionNames}`,
                options: [
                    {
                        label: 'Ok',
                        action: () => dispatch(change('variant', 'assetCondition', initialAssetCondition)),
                    },
                ],
            });
        } else {
            dispatch(submit('variant'));
        }
    }, [store, options, modal, initialValues, dispatch]);

    const client = useApolloClient();
    const onSubmit = useCallback(
        async ({ subModelId, images, prices, mandatories, ...data }) => {
            const upload = async ({ file, id }) => {
                // upload the file
                if (file instanceof File) {
                    const { data: imageData } = await client.mutate({
                        mutation: uploadImage,
                        variables: { purpose: AdhocAttachmentPurpose.VARIANTIMAGE, file },
                    });

                    // turn it into an object
                    return imageData.response?.id;
                }

                return id;
            };

            let promise = Promise.all(images.map(upload));

            promise = promise.then(imageIds => {
                const isUpdate = !!data?.id;
                const query = isUpdate ? update : create;
                const variables = { data: prepareForGraphQL(data), id: data?.id };

                if (isUpdate) {
                    // we need to remove some more dta
                    variables.data = omit(['version', 'lastModified', 'id'], variables.data);
                }

                if (subModelId) {
                    variables.data.modelId = subModelId;
                }

                // transform prices into an array
                variables.data.prices = Object.entries(prices)
                    .map(([zoneId, value]) => mandatories[zoneId] && { zoneId, value })
                    .filter(Boolean);

                variables.data.imageIds = imageIds;

                return client.mutate({ mutation: query, variables });
            });

            return promise.catch(handleResponseError);
        },
        [client]
    );

    const deleteForm = useCallback(async () => {
        const { id } = initialValues;
        await client.mutate({ mutation: deleteVariant, variables: { id } });

        dispatch(addNotification(VARIANT_DELETE_NOTIFICATION));
        history.push('/vehicle/variants');
    }, [client, dispatch, history, initialValues]);

    const onDelete = useCallback(() => {
        modal.confirm({
            title: '',
            content: 'Are you sure you want to delete this variant?',
            options: [
                { label: 'No', action: () => null },
                { label: 'Yes', action: deleteForm },
            ],
        });
    }, [deleteForm, modal]);

    const onSubmitSuccess = useCallback(() => {
        dispatch(addNotification(VARIANT_SAVE_NOTIFICATION));
        history.push('/vehicle/variants');
    }, [history, dispatch]);

    const onCancel = useCallback(() => history.goBack(), [history]);

    return (
        <FormLayout
            bodyComponent={
                initialValues && (
                    <Form
                        {...languageSelector}
                        disabled={!mayManageVehicles}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onSubmitSuccess={onSubmitSuccess}
                        options={options}
                    />
                )
            }
            moreActions={[
                mayManageVehicles &&
                    initialValues?.id && {
                        label: 'delete',
                        onAction: onDelete,
                    },
                mayManageVehicles && {
                    label: 'save',
                    onAction: submitForm,
                },
            ]}
            onCancel={onCancel}
            title="Variant Details"
        />
    );
};

Page.propTypes = {
    initialValues: PropTypes.shape({}),
    modal: PropTypes.shape({
        confirm: PropTypes.func,
    }),
    options: PropTypes.arrayOf(PropTypes.shape({})),
};

export default withModal(Page);
