import { useQuery, useApolloClient, ApolloError } from '@apollo/client';
import { faDownload, faImage, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flow, get, getOr, orderBy } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { exportOptions } from '../../../../actions';
import { FootBar, FootBarButton, FootContainer } from '../../../../containers/Layout';
import { getCurrentCountry, getGlobalPermissions } from '../../../../selectors';
import { searchOnKeys } from '../../../../utilities/fp';
import { withModal } from '../../../Modal';
import ImportInvalidModal from '../../../ui/ImportInvalidModal';
import { List, cells, ListSearch } from '../../../ui/lists';
import usePaging from '../../../utilities/usePaging';
import { ImportOptionModal } from '../imports';
import { getTables, uploadImages } from './OptionList.graphql';
import useVehicleTabs from './useVehiclesTabs';
import { openImportDialog, IMAGE_ARCHIVE_TYPE, isValidImageArchiveExtension } from './utils';

const useColumns = currency =>
    useMemo(
        () => [
            { name: 'Option ID', id: 'identifier', renderCell: get('identifier'), hasSort: true },
            { name: 'Option Name', id: 'name.en', renderCell: get('name.en'), hasSort: true, underline: true },
            { name: 'Option Category', id: 'group.order', renderCell: get('group.name.en'), hasSort: true },
            { name: `Price (${currency})`, id: 'price', renderCell: get('price'), hasSort: true },
            { name: 'Order No.', id: 'order', renderCell: get('order'), hasSort: true },
            { name: 'Variants Assigned', id: 'variantCount', renderCell: get('variantCount'), hasSort: true },
            { name: 'Active', id: 'isActive', renderCell: cells.renderActive(), hasSort: true },
        ],
        [currency]
    );

const searchFields = ['identifier', 'name.en', 'group.name', 'order'];

const getVariantCount = flow([getOr([], 'variants'), variants => variants.length]);

const useComputedItems = items =>
    useMemo(
        () =>
            items.map(item => ({
                ...item,
                variantCount: getVariantCount(item),
            })),
        [items]
    );

const sortItems = (items, [sortKey, sortOrder]) => {
    // we always sort by value key
    return orderBy(sortKey, sortOrder, items);
};

const OptionList = ({ modal }) => {
    const tabs = useVehicleTabs();
    const history = useHistory();
    const { mayManageVehicles } = useSelector(getGlobalPermissions);
    const [showImport, setShowImport] = useState(false);
    const [errors, setErrors] = useState(null);

    const { id: countryId, currency } = useSelector(getCurrentCountry);
    const columns = useColumns(currency);

    const variables = { countryId };
    const { data, loading, error: hasErrors, refetch } = useQuery(getTables, {
        fetchPolicy: 'cache-and-network',
        variables,
    });
    const items = useComputedItems(data?.results?.items || []);
    const isLoading = loading && items.length <= 0;

    // searching
    const [search, setSearch] = useState('');
    const searchMethod = useMemo(() => searchOnKeys(searchFields, items), [items]);
    const matchedItems = useMemo(() => searchMethod(search), [search, searchMethod]);

    // sorting
    const [sortedOn, onSort] = useState(['identifier', 'asc']);
    const sortedItems = useMemo(() => sortItems(matchedItems, sortedOn), [matchedItems, sortedOn]);

    // paging
    const [pagedItems, paging] = usePaging(sortedItems, { search });

    const client = useApolloClient();

    const dispatch = useDispatch();
    const exportExcel = useCallback(() => {
        return dispatch(exportOptions());
    }, [dispatch]);

    const onImportClose = useCallback(() => {
        setShowImport(false);
        refetch();
    }, [refetch]);

    const uploadImage = useCallback(async () => {
        const file = await openImportDialog(IMAGE_ARCHIVE_TYPE);

        if (!file) {
            // there is no file
            return;
        }

        if (!isValidImageArchiveExtension(file)) {
            // update the state to inform the extension is not right

            setErrors(['Invalid file extension, only zip, png, jpeg, or jpg files are allowed']);

            return;
        }

        try {
            const { data: updatedOption } = await client.mutate({
                mutation: uploadImages,
                variables: { countryId, file },
            });

            modal.confirm({
                title: `${updatedOption?.updatedCount} Option(s) imported`,
                content: '',
                options: [],
            });
        } catch (error) {
            if (error instanceof ApolloError) {
                const messages = error.graphQLErrors.reduce((acc, { message }) => `${acc}${message} `, '');
                setErrors([messages]);
            }
        }
    }, [client, countryId, modal]);

    const footer = (
        <FootContainer>
            <FootBar>
                {mayManageVehicles && (
                    <>
                        <FootBarButton onClick={() => history.push('/vehicle/options/new')}>
                            <FontAwesomeIcon icon={faPlus} /> ADD Option
                        </FootBarButton>
                        <FootBarButton onClick={exportExcel}>
                            <FontAwesomeIcon icon={faDownload} /> EXPORT EXCEL
                        </FootBarButton>
                        <FootBarButton onClick={() => setShowImport(true)}>
                            <FontAwesomeIcon icon={faUpload} /> IMPORT EXCEL
                        </FootBarButton>
                        <FootBarButton onClick={uploadImage}>
                            <FontAwesomeIcon icon={faImage} /> IMPORT IMAGES
                        </FootBarButton>
                    </>
                )}
            </FootBar>
        </FootContainer>
    );

    return (
        <>
            <List
                activeTab="option"
                columns={columns}
                error={hasErrors}
                footer={footer}
                headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
                items={pagedItems}
                loading={isLoading}
                onItemClick={item => history.push(`/vehicle/options/${item.id}`)}
                onSort={onSort}
                paging={paging}
                sortedOn={sortedOn}
                tabs={tabs}
                title="Option"
            />
            {showImport && <ImportOptionModal onClose={onImportClose} />}
            {errors && <ImportInvalidModal errors={errors} onClose={() => setErrors(null)} />}
        </>
    );
};

OptionList.propTypes = {
    modal: PropTypes.shape({
        confirm: PropTypes.func,
    }),
};

export default withModal(OptionList);
