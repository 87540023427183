import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { format, subMonths } from 'date-fns';
import { get, isEmpty, uniq } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const MostPopularChartBlock = ({ data, id, xAxisTitle }) => {
    const noData = isEmpty(get('currentMonth', data?.metrics)) && isEmpty(get('lastMonth', data?.metrics));

    useEffect(() => {
        if (noData) {
            return;
        }

        const chart = am4core.create(id, am4charts.XYChart);

        const { currentMonth, lastMonth } = data?.metrics || {};
        const names = uniq(currentMonth.map(item => item.name).concat(lastMonth.map(item => item.name)));

        chart.data = names.map(item => {
            const findCurrent = currentMonth?.find(i => i.name === item);
            const findLast = lastMonth?.find(i => i.name === item);

            return {
                popular: item,
                current: findCurrent?.count || 0,
                last: findLast?.count || 0,
            };
        });

        // display swith title
        chart.legend = new am4charts.Legend();
        chart.legend.position = 'top';
        chart.legend.paddingBottom = 20;
        chart.legend.labels.template.maxWidth = 200;

        // Add scrollbar
        chart.scrollbarY = new am4core.Scrollbar();

        // define x axis
        const yAxes = chart.yAxes.push(new am4charts.CategoryAxis());
        yAxes.dataFields.category = 'popular';
        yAxes.renderer.cellStartLocation = 0.3;
        yAxes.renderer.cellEndLocation = 0.7;
        yAxes.renderer.grid.template.location = 0;
        yAxes.renderer.inversed = true;
        yAxes.renderer.minGridDistance = 10;

        // define y axis
        const xAxes = chart.xAxes.push(new am4charts.ValueAxis());
        xAxes.title.text = xAxisTitle;
        xAxes.renderer.labels.template.adapter.add('text', text =>
            // eslint-disable-next-line no-nested-ternary
            /^-?\d*\.\d+$/.test(parseFloat(text)) ? '' : text ? parseInt(text, 10) : text
        );

        // filter different month
        const arrangeColumns = () => {
            const series = chart.series.getIndex(0);

            const w = 1 - yAxes.renderer.cellStartLocation - (1 - yAxes.renderer.cellEndLocation);
            if (series.dataItems.length > 1) {
                const x0 = yAxes.getY(series.dataItems.getIndex(0), 'categoryY');
                const x1 = yAxes.getY(series.dataItems.getIndex(1), 'categoryY');
                const delta = ((x1 - x0) / chart.series.length) * w;
                if (am4core.isNumber(delta)) {
                    const middle = chart.series.length / 2;

                    let newIndex = 0;
                    // eslint-disable-next-line no-shadow
                    chart.series.each(series => {
                        if (!series.isHidden && !series.isHiding) {
                            // eslint-disable-next-line no-param-reassign
                            series.dummyData = newIndex;
                            newIndex++;
                        } else {
                            // eslint-disable-next-line no-param-reassign
                            series.dummyData = chart.series.indexOf(series);
                        }
                    });
                    const visibleCount = newIndex;
                    const newMiddle = visibleCount / 2;

                    chart.series.each(item => {
                        const trueIndex = chart.series.indexOf(item);
                        const newAgainIndex = item.dummyData;

                        const dx = (newAgainIndex - trueIndex + middle - newMiddle) * delta;

                        item.animate(
                            { property: 'dy', to: dx },
                            series.interpolationDuration,
                            series.interpolationEasing
                        );
                        item.bulletsContainer.animate(
                            { property: 'dy', to: dx },
                            series.interpolationDuration,
                            series.interpolationEasing
                        );
                    });
                }
            }
        };

        // create series
        const createSeries = (value, name, color) => {
            const series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueX = value;
            series.dataFields.categoryY = 'popular';
            series.name = name;
            series.columns.template.height = am4core.percent(100);
            series.columns.template.fill = color;
            series.columns.template.strokeOpacity = 0;

            series.sequencedInterpolation = true;

            series.events.on('hidden', arrangeColumns);
            series.events.on('shown', arrangeColumns);

            series.columns.template.tooltipText = '{categoryY} {name}: {valueX}';
        };

        createSeries('current', `Current Month (${format(new Date(), 'MMM')})`, am4core.color('#67b7dc'));
        createSeries('last', `Last Month (${format(subMonths(new Date(), 1), 'MMM')})`, am4core.color('#a367dc'));
    }, [data, noData, id, xAxisTitle]);

    return noData ? (
        <div className="m-1">There is currently no data for this month.</div>
    ) : (
        <div id={id} style={{ height: '400px' }} />
    );
};

MostPopularChartBlock.propTypes = {
    data: PropTypes.shape({
        metrics: PropTypes.shape({}),
    }),
    id: PropTypes.string.isRequired,
    xAxisTitle: PropTypes.string,
};

export default MostPopularChartBlock;
