import { useRef } from 'react';
import computeChanges from '../context/computeChanges';
import computeContext from '../context/computeContext';
import createRunner from '../context/createRunner';
import initializeValues from '../context/initializeValues';

const useComputedContext = (rawContext, values, setValues) => {
    const runRef = useRef(null);

    if (!runRef.current) {
        // initialize it on the first run
        runRef.current = createRunner(values);
    }

    // get the run function
    const { run, errors } = runRef.current;

    // we need to be able to update current values
    let currentValues = values;

    for (let limit = 0; limit < 100; limit++) {
        // compute the context at first
        const context = computeContext(rawContext, currentValues, setValues, run);

        if (context.isInvalid) {
            // set an error for invalid
            errors.calculator = 'Invalid configuration';
        } else {
            // remove the key
            delete errors.calculator;
        }

        // first try to initialize values
        const initializedValues = initializeValues(context, currentValues, errors);

        if (initializedValues !== currentValues) {
            // we need to run the context again
            currentValues = initializedValues;
            // move onto the next look iteration
            continue;
        }

        // look for next values
        const { previousValues } = runRef.current;

        const nextValues = computeChanges(context, currentValues, previousValues);

        // no matter what we need to update previous values
        runRef.current.previousValues = currentValues;

        if (nextValues === currentValues) {
            // memoize errors
            const cleanedErrors = run('errors', () => ({ ...errors }), [
                ...Object.keys(errors),
                ...Object.values(errors),
            ]);

            // our work is done here
            return [context, nextValues, cleanedErrors];
        }

        // move onto the next run
        currentValues = nextValues;
    }

    throw new Error('Infinite loop detected.');
};

export default useComputedContext;
