import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { getCurrentCountry } from '../../../selectors';
import { getSalesPersonByDealerId } from './options.graphql';

const useSalesPerson = dealerIds => {
    const { id: countryId } = useSelector(getCurrentCountry);
    const variables = {
        countryId,
        dealerIds,
    };
    const { data, loading } = useQuery(getSalesPersonByDealerId, { networkPolicy: 'cache-and-network', variables });

    return [data, loading];
};
export default useSalesPerson;
