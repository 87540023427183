import { useMemo } from 'react';
import usePhone from './usePhone';

const useValidationContext = countryCode => {
    const { pattern: phonePattern } = usePhone(countryCode);

    return useMemo(
        () => ({
            phonePattern: phonePattern ? new RegExp(phonePattern) : null,
        }),
        [phonePattern]
    );
};

export default useValidationContext;
