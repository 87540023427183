import styled from 'styled-components';
import Button from './Button';

const GrayButton = styled(Button)`
    color: #ffffff;
    background-color: #888888;
    border-radius: 0;

    &:disabled {
        opacity: 1;
        cursor: not-allowed;
        color: #c4c4c4;
    }
`;

export default GrayButton;
