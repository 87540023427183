import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 145px;
`;

Container.Label = styled.label`
    text-transform: ${props => props.theme.casing};
    font-size: 1.21rem;
    text-align: left;
    margin: 15px 0 5px;
    color: #000000;
`;

const RawTextareaWrapper = ({ label, name, children, ...props }) => (
    <Container {...props}>
        <Container.Label htmlFor={name}>{label}</Container.Label>
        {children}
    </Container>
);

RawTextareaWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export default RawTextareaWrapper;
