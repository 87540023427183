import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const sharedStyle = css`
    font-size: 1.21rem;
    color: ${props => (props.disabled ? '#000' : props.theme.themeHighlightColour)};
    display: flex;
    align-items: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const AddOn = styled.div`
    font-weight: 400;
    line-height: normal;
    ${sharedStyle}
    display: flex;
    align-items: center;
    height: 22px;
`;

const Input = styled.input`
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    flex-grow: 2;
    background: none;
    box-shadow: none;
    ${sharedStyle}
    height: 22px;
`;

const BoxedInput = ({ prefix = null, suffix = null, inputRef, disabled, ...props }) => (
    <Container>
        {prefix && <AddOn disabled={disabled}>{prefix}</AddOn>}
        <Input {...props} ref={inputRef} disabled={disabled} />
        {suffix}
    </Container>
);

BoxedInput.displayName = 'BoxedInput';

BoxedInput.propTypes = {
    disabled: PropTypes.bool,
    inputRef: PropTypes.shape({}),
    prefix: PropTypes.node,
    suffix: PropTypes.node,
};

export default BoxedInput;
