import React from 'react';
import { Field } from 'redux-form';
import { yesNoOptions } from '../../../../../shared/constants/options';
import FieldContainer from '../../../../template/Field-container';
import NumberField from '../../../../template/Number';
import SwitchField from '../../../../template/Switch';
import { useFormContext } from './context';

const MarginOfFinance = () => {
    const { disabled, rounding } = useFormContext();

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="sub-container-fluid">
                        <FieldContainer label="Min (%)">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                min={0}
                                name="balloonGFVSetting.marginOfFinance.min"
                                precision={rounding.percentage.count}
                            />
                        </FieldContainer>
                        <span />
                        <FieldContainer label="Max (%)">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                min={0}
                                name="balloonGFVSetting.marginOfFinance.max"
                                precision={rounding.percentage.count}
                            />
                        </FieldContainer>
                        <span />
                        <FieldContainer label="Show">
                            <Field
                                component={SwitchField}
                                disabled={disabled}
                                name="balloonGFVSetting.marginOfFinance.show"
                                options={yesNoOptions}
                            />
                        </FieldContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarginOfFinance;
