import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentCountry } from '../../../../../selectors';
import ConnectedLoadingLayer from '../../../../common/ConnectedLoadingLayer';
import { DownloadModal, CloseButton, Header, Message } from '../../../../ui/Download';

export type DownloadDocumentsModalProps = {
    onClose: () => void;
    password?: string | null;
    isShow: boolean;
};

const DownloadDocumentsModal = ({ onClose, password, isShow }: DownloadDocumentsModalProps) => {
    const { t } = useTranslation();
    const { code } = useSelector(getCurrentCountry);

    const identifier = useMemo(() => {
        if (code === 'NZ') {
            return t('applicationDetailsPage.downloadDocuments.identifier.dateOfBirth');
        }

        return t('applicationDetailsPage.downloadDocuments.identifier.identity');
    }, [t, code]);

    if (!isShow) {
        return null;
    }

    return (
        <DownloadModal onRequestClose={onClose} isOpen>
            <div>
                <ConnectedLoadingLayer>
                    <CloseButton onClick={onClose}>&times;</CloseButton>
                    <Header>
                        <Trans i18nKey="applicationDetailsPage.downloadDocuments.message" values={{ identifier }} />
                    </Header>
                    {password && (
                        <>
                            <Header>
                                <Trans i18nKey="applicationDetailsPage.downloadDocuments.password" />
                            </Header>
                            <Message>{password}</Message>
                        </>
                    )}
                </ConnectedLoadingLayer>
            </div>
        </DownloadModal>
    );
};

export default DownloadDocumentsModal;
