import styled from 'styled-components';

const Grid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: ${props => (props.theme.calculator.singleColumn ? '1fr' : 'repeat(2, 1fr)')};
    grid-gap: 5px;
`;

export default Grid;
