import { isNil } from 'lodash/fp';
import { useMemo } from 'react';
import { FinanceDataFragment } from '../../data/useFinanceProducts.graphql';
import { CalculatorSnapshot } from '../types';
import { getDownPaymentSetting, getLoanSetting, getTermSetting } from './useExpressFinanceProducts';

const useExpressSnapshot = (
    expressFinanceProduct?: Partial<FinanceDataFragment>,
    snapshot?: CalculatorSnapshot | undefined
): CalculatorSnapshot | undefined =>
    useMemo(() => {
        if (isNil(expressFinanceProduct)) {
            return snapshot;
        }

        // check if no snapshot to override
        if (!snapshot) {
            return undefined;
        }

        const { financeProduct } = snapshot;

        // check if no finance product to override
        if (!financeProduct) {
            return undefined;
        }

        const selectedFinanceProduct = {
            ...financeProduct,
            downPaymentSetting: getDownPaymentSetting(
                financeProduct.downPaymentSetting,
                expressFinanceProduct?.downPaymentSetting
            ),
            loanSetting: getLoanSetting(financeProduct.loanSetting, expressFinanceProduct?.loanSetting),
            termSetting: getTermSetting(financeProduct.termSetting, expressFinanceProduct?.termSetting),
        };

        return {
            ...snapshot,
            selectedFinanceProduct,
        };
    }, [expressFinanceProduct, snapshot]);

export default useExpressSnapshot;
