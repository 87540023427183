import * as validators from '../../../../../../utils/validators';

const validate = validators.compose(
    validators.requiredString('customer.name.value'),
    validators.requiredString('customer.email.value'),
    validators.validEmail('customer.email.value'),
    validators.validEmail('customer.emailBis.value')
);

export default validate;
