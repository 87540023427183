import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import ListTabDropDownContainer from './ListTabDropDownContainer';

const ListTabDropDown = ({ tabs, onChange }) => {
    const [show, setShow] = useState(false);

    return (
        <ListTabDropDownContainer>
            <Dropdown isOpen={show} toggle={() => setShow(!show)}>
                <DropdownToggle caret>
                    <FontAwesomeIcon icon={faAngleDown} />
                </DropdownToggle>
                <DropdownMenu right>
                    {tabs.map(tab => (
                        <DropdownItem key={tab.id} onClick={() => onChange(tab)}>
                            {tab.name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </ListTabDropDownContainer>
    );
};

ListTabDropDown.propTypes = {
    onChange: PropTypes.func.isRequired,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
};

export default ListTabDropDown;
