import { useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, orderBy } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FootBar, FootBarButton, FootContainer } from '../../../../containers/Layout';
import { useContentTranslation } from '../../../../i18n';
import { getCountryId } from '../../../../selectors';
import { searchOnKeys } from '../../../../utilities/fp';
import { List as UIList, ListSearch } from '../../../ui/lists';
import usePaging from '../../../utilities/usePaging';
import { getCountries } from './CountryList.graphql';

const columns = formatPath => [
    { name: 'Country Code', id: 'code', renderCell: get('code'), hasSort: true },
    { name: 'Country Name', id: 'name', renderCell: get('name'), hasSort: true, underline: true },
    { name: 'Currency', id: 'currency', renderCell: get('currency'), hasSort: true },
    { name: 'Company Name', id: 'company.name', renderCell: get(formatPath('company.name')), hasSort: true },
];

const sortItems = (items, [sortKey, sortOrder]) => {
    // we always sort by value key
    return orderBy(sortKey, sortOrder, items);
};

const CountryList = () => {
    const history = useHistory();
    const { formatPath } = useContentTranslation();

    const countryId = useSelector(getCountryId);
    const variables = { countryId };
    const { data, loading, error } = useQuery(getCountries, { fetchPolicy: 'cache-and-network', variables });
    const items = data?.results?.items || [];
    const isLoading = loading && items.length <= 0;

    // searching
    const [search, setSearch] = useState('');
    const searchMethod = useMemo(() => searchOnKeys(['code', 'name', 'company.name'], items), [items]);
    const matchedItems = useMemo(() => searchMethod(search), [search, searchMethod]);

    // sorting
    const [sortedOn, onSort] = useState(['code', 'asc']);
    const sortedItems = useMemo(() => sortItems(matchedItems, sortedOn), [matchedItems, sortedOn]);

    // paging
    const [pagedItems, paging] = usePaging(sortedItems, { search });

    const footer = (
        <FootContainer>
            <FootBar>
                <FootBarButton onClick={() => history.push('/scope/countries/new')}>
                    <FontAwesomeIcon icon={faPlus} /> ADD Country
                </FootBarButton>
            </FootBar>
        </FootContainer>
    );

    return (
        <UIList
            columns={columns(formatPath)}
            error={error}
            footer={footer}
            headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
            items={pagedItems}
            loading={isLoading}
            onItemClick={item => history.push(`/scope/countries/${item.id}`)}
            onSort={onSort}
            paging={paging}
            sortedOn={sortedOn}
            title="Country/Zone"
        />
    );
};

export default CountryList;
