import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { ChromePicker } from 'react-color';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import styled from 'styled-components';
import { ErrorMessageDiv } from '../../containers/Layout';

const ColorPickerDiv = styled.div`
    .flex {
        display: flex;
        background-color: #f0f0f0;
    }
    div.hash,
    div.colorCode {
        color: #000000;
        height: 30px;
        padding-top: 4px;
        width: 100%;
    }
    div.hash {
        color: #f4f4f4;
        padding-left: 10px;
        padding-top: 5px;
        width: 28px;
        margin-right: 10px;
        background-color: #909090;
    }
    div.colorCode input {
        border: 0px;
        background-color: #f0f0f0;
        text-transform: uppercase;
    }
    button {
        width: 18px;
        height: 18px;
        margin-top: 5px;
        margin-right: 10px;
        border: 0px;
    }
    .dropdown-box {
        top: 100%;
        right: 0%;
    }
`;

const popover = {
    position: 'absolute',
    zIndex: '2',
};

const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
};

const ColorPickerField = ({ input, meta }) => {
    const { onChange: change, value } = input;
    const { error, touched, active } = meta;

    // state management
    const [open, setOpen] = useState(false);
    const toggle = useCallback(() => setOpen(state => !state), [setOpen]);

    const handleChangeComplete = useCallback(({ hex }) => change(hex), [change]);
    const handleClose = useCallback(() => setOpen(false), [setOpen]);

    const onChange = useCallback(
        event => {
            setOpen(false);
            change(`#${event.target.value}`);
        },
        [change]
    );

    const displayValue = value && value.slice(1);

    return (
        <ColorPickerDiv>
            <div className="flex">
                <div className="hash">#</div>
                <div className="colorCode">
                    <span>
                        <input {...input} onBlur={() => false} onChange={onChange} value={displayValue} />
                    </span>
                </div>
                <div className="dropdown">
                    <button className="block-color" onClick={toggle} style={{ background: value }} type="button" />
                    {open && (
                        <div className="dropdown-box" style={popover}>
                            <div onClick={handleClose} style={cover} />
                            <ChromePicker color={value} onChangeComplete={handleChangeComplete} />
                        </div>
                    )}
                </div>
            </div>
            <ErrorMessageDiv>{touched && !active && error}</ErrorMessageDiv>
        </ColorPickerDiv>
    );
};

ColorPickerField.propTypes = {
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};

export default ColorPickerField;
