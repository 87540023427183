import CarModelField from '../components/fields/display/CarModelField';
import UpdateCarModel from '../components/fields/edition/UpdateCarModel';
import fieldTemplate from './fieldTemplate';

const carModelField = {
    // copy the template
    ...fieldTemplate,

    // setup meta
    label: t => t('calculator.label.carModel'),
    weight: 0,

    // setup states
    isViewable: (context, values, theme) => theme.calculator.singleColumn,
    isEditable: () => true,

    // variant field is invalid if there's no variants to select
    isInvalid: ({ variants }) => !variants || variants.length === 0,

    // setup display rendering
    displayComponent: CarModelField,

    // setup edition rendering
    editionComponent: UpdateCarModel,

    // snapshot settings
    snapshot: {
        ...fieldTemplate.snapshot,
        component: CarModelField,
    },
};

export default carModelField;
