import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Input from './Input';

const CopyrightInput = ({ className, prefix, ...props }) => (
    <div className={className}>
        <span>{prefix}</span>
        <Input {...props} />
    </div>
);

CopyrightInput.propTypes = {
    className: PropTypes.string.isRequired,
    prefix: PropTypes.node.isRequired,
};

export default styled(CopyrightInput)`
    display: flex;

    & > span {
        min-width: 30%;
        background-color: #d8d8d8;
        color: #000;
        display: flex;
        align-items: center;
        height: 30px;
        margin-right: 5px;
        font-size: 0.85rem;
        padding: 0 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;
