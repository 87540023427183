import { get, isEmpty } from 'lodash/fp';
import React, { useEffect, useRef } from 'react';
import { Field } from 'redux-form';
import { BankIntegrationProvider } from '../../../../../schema';
import { hlfBankIntegrationApiVersionOptions } from '../../../../../shared/constants/options';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import { useFormContext } from './context';

const BankIntegration = () => {
    const { values, change } = useFormContext();
    const integration = get('integration', values);
    const hasIntegration = get('hasIntegration', values);

    // use a reference to avoid triggering the effect
    const previousIntegration = useRef(integration);
    previousIntegration.current = integration;

    useEffect(() => {
        if (!hasIntegration) {
            change('integration', null);
        } else if (isEmpty(previousIntegration.current)) {
            change('integration', { provider: BankIntegrationProvider.HLF });
        }
    }, [hasIntegration, previousIntegration, change]);

    if (!hasIntegration) {
        return null;
    }

    return (
        <>
            <hr />
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DropdownField
                        label="API Version"
                        name="integration.apiVersion"
                        options={hlfBankIntegrationApiVersionOptions}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Dealer Code for Integration V1">
                        <Field component={InputField} name="integration.v1DealerCode" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Dealer Code for Integration V2">
                        <Field component={InputField} name="integration.v2DealerCode" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Bank Base URL">
                        <Field component={InputField} name="integration.base" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Bank Submission URL">
                        <Field component={InputField} name="integration.submissionPartial" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Bank Resubmission URL">
                        <Field component={InputField} name="integration.editionPartial" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Bank Cancellation URL">
                        <Field component={InputField} name="integration.cancellationPartial" />
                    </FieldContainer>
                </div>
            </div>
        </>
    );
};

export default BankIntegration;
