import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isObject, get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';

const FontPreview = ({ file }) => {
    const hasPreview = file instanceof File || isObject(file);

    if (!hasPreview) {
        return <FontAwesomeIcon icon={faFileUpload} />;
    }

    if (file instanceof File) {
        return <span>{file.name}</span>;
    }

    return <span>{get('metaData.filename', file)}</span>;
};

FontPreview.propTypes = {
    file: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.instanceOf(File)]),
};

export default FontPreview;
