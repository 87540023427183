import { get, isEmpty } from 'lodash/fp';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useContentTranslation } from '../../../../../i18n';
import { modelTypeOptions } from '../../../../../shared/constants/options';
import { useSort } from '../../../../../utilities/hooks';
import ApvPortlet from '../../../../template/Portlet';
import { cells, ListTable } from '../../../../ui/lists';
import { useFormContext } from './context';

const columns = formatPath => [
    { name: 'Submodel ID', id: 'identifier', renderCell: get('identifier'), hasSort: true },
    { name: 'Submodel Name', id: 'name', renderCell: get(formatPath('name')), hasSort: true, underline: true },
    { name: 'Number Of Variants', id: 'variantCount', renderCell: get('variants.length'), hasSort: true },
    {
        name: 'Body Type',
        id: 'bodyType',
        renderCell: item => modelTypeOptions.find(option => option.value === item.type)?.label,
        hasSort: true,
    },
    { name: 'Order No.', id: 'order', renderCell: get('order'), hasSort: true },
    { name: 'Active', id: 'active', renderCell: cells.renderActive(), hasSort: true },
];

const SubModelList = () => {
    const { formatPath } = useContentTranslation();
    const history = useHistory();
    const { subModels } = useFormContext();

    // sorting
    const [sortedOn, onSort] = useState(['identifier', 'asc']);
    const sortedItems = useSort(subModels, sortedOn);

    if (isEmpty(subModels)) {
        return null;
    }

    return (
        <ApvPortlet name="Submodel" closable open>
            <br />
            <div className="container-fluid">
                <ListTable
                    columns={columns(formatPath)}
                    items={sortedItems}
                    onItemClick={item => history.push(`/vehicle/subModels/${item.id}`)}
                    onSort={onSort}
                    sortedOn={sortedOn}
                />
            </div>
        </ApvPortlet>
    );
};

export default SubModelList;
