import { get } from 'lodash/fp';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Field, ReduxFormContext } from 'redux-form';
import { useContentTranslation } from '../../../i18n';
import { getCurrentCountry } from '../../../selectors';
import { useCompanyFormatting } from '../../../utils/withCompanyFormatting';
import CurrencyField from '../../template/Currency';
import FieldContainer from '../../template/Field-container';
import InputField from '../../template/Input';
import MultiSelectPoolField from '../../template/MultiSelectPool';
import ApvPortlet from '../../template/Portlet';
import TextareaField from '../../template/Textarea';
import { ChannelTitle } from '../../ui/Channel';
import { Tips } from '../../ui/form/File';
import { BankDataFragment } from '../Workflow/Finance/data/useLoadBank.graphql';
import { DealerOptions } from './DealerSelection';
import { useFormContext } from './context';

const Channel = () => {
    const { ct, formatPath } = useContentTranslation();
    //  get the channel setting
    const { channelSetting, currency, rounding, useExternalCalculator, useIccCheckout } = useSelector(
        getCurrentCountry
    );
    const {
        dealerId,
        mayViewNewCarChannel,
        mayManageNewCarChannel,
        mayViewUsedCarChannel,
        mayManageUsedCarChannel,
        mayViewExpressChannel,
        mayManageExpressChannel,
        mayViewEventChannel,
        mayManageEventChannel,
        options,
        lastModified,
        onSubmit,
    } = useFormContext();
    const isSelectedAllDealer = dealerId === DealerOptions.ALLDEALER;

    const { getValues } = useContext(ReduxFormContext);
    const values = getValues();

    const newChannelBookingAmount = get('newChannel.bookingAmount', values);
    const usedChannelBookingAmount = get('usedChannel.bookingAmount', values);
    const expressChannelBookingAmount = get('expressChannel.bookingAmount', values);

    const availableBanksByDealer = get('availableBanksByDealer', values) as Partial<BankDataFragment>[];

    const hasBanks = !!availableBanksByDealer?.length;

    const { formatCurrency } = useCompanyFormatting();

    return (
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Last Modified">
                        <Field
                            component={InputField}
                            fixedValue={lastModified}
                            name="__exclude.lastModified"
                            disabled
                        />
                    </FieldContainer>
                </div>
            </div>
            {channelSetting.new.isActive && mayViewNewCarChannel && (
                <ApvPortlet name="New Car Channel" closable open>
                    <div className="container-fluid">
                        {!isSelectedAllDealer && channelSetting.new.isDepositPaymentMandatory && (
                            <div className="row">
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <FieldContainer label="Payment Amount">
                                        <Field
                                            component={CurrencyField}
                                            disabled={!mayManageNewCarChannel}
                                            name="newChannel.bookingAmount"
                                            precision={rounding?.amount?.count}
                                            prefix={currency}
                                        />
                                    </FieldContainer>
                                    {!newChannelBookingAmount && (
                                        <Tips>
                                            Leave blank for this field will use default amount
                                            {` ${formatCurrency(channelSetting.new.bookingPayment.amount)}.`}
                                        </Tips>
                                    )}
                                </div>
                            </div>
                        )}
                        {isSelectedAllDealer && useExternalCalculator && (
                            <>
                                <ChannelTitle>Financial Products for ICC</ChannelTitle>
                                <Field
                                    component={MultiSelectPoolField}
                                    disabled={!mayManageNewCarChannel}
                                    name="newChannel.iccFinanceProducts"
                                    options={options.iccFinanceProducts}
                                    poolLabel="Finance Product Pool"
                                    selectionLabel="Selected Finance Products"
                                />
                            </>
                        )}
                        {!isSelectedAllDealer && (
                            <>
                                <Field
                                    component={MultiSelectPoolField}
                                    disabled={!mayManageNewCarChannel}
                                    name="newChannel.variants"
                                    options={options.newVariants}
                                    poolLabel="Variants Pool"
                                    selectionLabel="Selected Variants"
                                />
                                <div className="row">
                                    {channelSetting.new.isCoeEnabled && (
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <FieldContainer label="COE Amount">
                                                <Field
                                                    component={CurrencyField}
                                                    disabled={!mayManageNewCarChannel}
                                                    name="coe"
                                                    precision={rounding?.amount?.count}
                                                    prefix={currency}
                                                    allowNegative
                                                />
                                            </FieldContainer>
                                        </div>
                                    )}
                                    {channelSetting.new.isPpsrAndEstablishmentEnabled && (
                                        <>
                                            <div className="col-md-4 col-sm-12 col-xs-12">
                                                <FieldContainer label="PPSR Fee Amount">
                                                    <Field
                                                        component={CurrencyField}
                                                        disabled={!mayManageNewCarChannel}
                                                        name="ppsr"
                                                        precision={rounding?.amount?.count}
                                                        prefix={currency}
                                                        allowNegative
                                                    />
                                                </FieldContainer>
                                            </div>
                                            <div className="col-md-4 col-sm-12 col-xs-12">
                                                <FieldContainer label="Dealer Establishment Fee">
                                                    <Field
                                                        component={CurrencyField}
                                                        disabled={!mayManageNewCarChannel}
                                                        name="newChannel.dealerEstablishment"
                                                        precision={rounding?.amount?.count}
                                                        prefix={currency}
                                                        allowNegative
                                                    />
                                                </FieldContainer>
                                            </div>
                                            {hasBanks &&
                                                availableBanksByDealer.map((bank, index) => (
                                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                                        <FieldContainer label={`${ct(bank.name)} Establishment Fee`}>
                                                            <Field
                                                                component={CurrencyField}
                                                                name={`availableBanksByDealer[${index}].establishment`}
                                                                precision={rounding?.amount?.count}
                                                                prefix={currency}
                                                                allowNegative
                                                                disabled
                                                            />
                                                        </FieldContainer>
                                                    </div>
                                                ))}
                                        </>
                                    )}
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <FieldContainer label="Price Disclaimer - Finance">
                                            <Field
                                                component={TextareaField}
                                                disabled={!mayManageNewCarChannel}
                                                name={formatPath('newChannel.priceDisclaimer')}
                                            />
                                        </FieldContainer>
                                    </div>
                                    {channelSetting.new.isInsuranceEnabled && (
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <FieldContainer label="Price Disclaimer - Insurance">
                                                <Field
                                                    component={TextareaField}
                                                    disabled={!mayManageNewCarChannel}
                                                    name={formatPath('newChannel.insurancePriceDisclaimer')}
                                                />
                                            </FieldContainer>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </ApvPortlet>
            )}
            {channelSetting.used.isActive && mayViewUsedCarChannel && !isSelectedAllDealer && (
                <ApvPortlet name="Used Car Channel" closable open>
                    <div className="container-fluid">
                        {channelSetting.used.isDepositPaymentMandatory && (
                            <div className="row">
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <FieldContainer label="Payment Amount">
                                        <Field
                                            component={CurrencyField}
                                            disabled={!mayManageUsedCarChannel}
                                            name="usedChannel.bookingAmount"
                                            precision={rounding?.amount?.count}
                                            prefix={currency}
                                        />
                                    </FieldContainer>
                                    {!usedChannelBookingAmount && (
                                        <Tips>
                                            Leave blank for this field will use default amount
                                            {` ${formatCurrency(channelSetting.used.bookingPayment.amount)}.`}
                                        </Tips>
                                    )}
                                </div>
                            </div>
                        )}
                        <Field
                            component={MultiSelectPoolField}
                            disabled={!mayManageUsedCarChannel}
                            name="usedChannel.variants"
                            options={options.usedVariants}
                            poolLabel="Variants Pool"
                            selectionLabel="Selected Variants"
                        />
                    </div>
                </ApvPortlet>
            )}
            {channelSetting.express.isActive && mayViewExpressChannel && !isSelectedAllDealer && (
                <ApvPortlet name="Pre-owned Channel" closable open>
                    <div className="container-fluid">
                        {channelSetting.express.isDepositPaymentMandatory && (
                            <div className="row">
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <FieldContainer label="Payment Amount">
                                        <Field
                                            component={CurrencyField}
                                            disabled={!mayManageExpressChannel}
                                            name="expressChannel.bookingAmount"
                                            precision={rounding?.amount?.count}
                                            prefix={currency}
                                        />
                                    </FieldContainer>
                                    {!expressChannelBookingAmount && (
                                        <Tips>
                                            Leave blank for this field will use default amount
                                            {` ${formatCurrency(channelSetting.express.bookingPayment.amount)}.`}
                                        </Tips>
                                    )}
                                </div>
                            </div>
                        )}
                        <Field
                            component={MultiSelectPoolField}
                            disabled={!mayManageExpressChannel}
                            name="expressChannel.financeProducts"
                            options={options.expressFinanceProducts}
                            poolLabel="Finance Product Pool"
                            selectionLabel="Selected Finance Products"
                        />
                        <div className="row">
                            {channelSetting.express.isCoeEnabled && (
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <FieldContainer label="COE Amount">
                                        <Field
                                            component={CurrencyField}
                                            disabled={!mayManageExpressChannel}
                                            name="coe"
                                            precision={rounding?.amount?.count}
                                            prefix={currency}
                                            allowNegative
                                        />
                                    </FieldContainer>
                                </div>
                            )}
                            {channelSetting.express.isPpsrAndEstablishmentEnabled && (
                                <>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <FieldContainer label="PPSR Fee Amount">
                                            <Field
                                                component={CurrencyField}
                                                disabled={!mayManageExpressChannel}
                                                name="ppsr"
                                                precision={rounding?.amount?.count}
                                                prefix={currency}
                                                allowNegative
                                            />
                                        </FieldContainer>
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <FieldContainer label="Dealer Establishment Fee">
                                            <Field
                                                component={CurrencyField}
                                                disabled={!mayManageNewCarChannel}
                                                name="expressChannel.dealerEstablishment"
                                                precision={rounding?.amount?.count}
                                                prefix={currency}
                                                allowNegative
                                            />
                                        </FieldContainer>
                                    </div>
                                    {hasBanks &&
                                        availableBanksByDealer.map((bank, index) => (
                                            <div className="col-md-4 col-sm-12 col-xs-12">
                                                <FieldContainer label={`${ct(bank.name)} Establishment Fee`}>
                                                    <Field
                                                        component={CurrencyField}
                                                        name={`availableBanksByDealer[${index}].establishment`}
                                                        precision={rounding?.amount?.count}
                                                        prefix={currency}
                                                        allowNegative
                                                        disabled
                                                    />
                                                </FieldContainer>
                                            </div>
                                        ))}
                                </>
                            )}
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Price Disclaimer - Finance">
                                    <Field
                                        component={TextareaField}
                                        disabled={!mayManageExpressChannel}
                                        name={formatPath('expressChannel.priceDisclaimer')}
                                    />
                                </FieldContainer>
                            </div>
                            {channelSetting.express.isInsuranceEnabled && (
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <FieldContainer label="Price Disclaimer - Insurance">
                                        <Field
                                            component={TextareaField}
                                            disabled={!mayManageExpressChannel}
                                            name={formatPath('expressChannel.insurancePriceDisclaimer')}
                                        />
                                    </FieldContainer>
                                </div>
                            )}
                        </div>
                    </div>
                </ApvPortlet>
            )}
            {channelSetting.event.isActive && channelSetting.event.isDepositPaymentMandatory && mayViewEventChannel && (
                <ApvPortlet name="Event Channel" closable open>
                    <div className="container-fluid">
                        {channelSetting.event.isDepositPaymentMandatory && !isSelectedAllDealer && (
                            <>
                                <ChannelTitle>Financial Products for Porsche Finder</ChannelTitle>
                                <Field
                                    component={MultiSelectPoolField}
                                    disabled={!mayManageEventChannel}
                                    name="eventChannel.finderFinanceProducts"
                                    options={options.finderFinanceProducts}
                                    poolLabel="Finance Product Pool"
                                    selectionLabel="Selected Finance Products"
                                />
                            </>
                        )}
                        {isSelectedAllDealer && (
                            <>
                                <ChannelTitle>Financial Products for Configurator</ChannelTitle>
                                <Field
                                    component={MultiSelectPoolField}
                                    disabled={!mayManageEventChannel}
                                    name="eventChannel.configuratorFinanceProducts"
                                    options={options.configuratorFinanceProducts}
                                    poolLabel="Finance Product Pool"
                                    selectionLabel="Selected Finance Products"
                                />
                            </>
                        )}
                        {isSelectedAllDealer && useIccCheckout && (
                            <>
                                <ChannelTitle>Financial Products for ICC Checkout</ChannelTitle>
                                <Field
                                    component={MultiSelectPoolField}
                                    disabled={!mayManageEventChannel}
                                    name="eventChannel.csvFinanceProducts"
                                    options={options.csvFinanceProducts}
                                    poolLabel="Finance Product Pool"
                                    selectionLabel="Selected Finance Products"
                                />
                            </>
                        )}
                    </div>
                </ApvPortlet>
            )}
        </form>
    );
};

export default Channel;
