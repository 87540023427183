import * as api from '../api';
import { setAuthorization } from './authorization';

export const refreshAuthorization = () => async (dispatch, getState, { client }) => {
    // call API to get new authorization
    const { token } = await api.refreshAuthorization(client);
    // and update state
    dispatch(setAuthorization(token));
};

export const switchTenant = (companyId, countryId, zoneId, dealerId) => async (dispatch, getState, { client }) => {
    // call API to get new authorization
    const { token } = await api.switchTenant(client, { companyId, countryId, zoneId, dealerId });
    // and update state
    dispatch(setAuthorization(token));
};
