import React from 'react';
import defaultValues from './Form/defaultValues';
import Page from './Page';

const Creation = () => {
    const initialValues = defaultValues;

    return <Page initialValues={initialValues} />;
};

export default Creation;
