import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCompany } from '../../../../selectors';
import Page from './Page';

const Creation = () => {
    const company = useSelector(getCompany);

    const initialValues = useMemo(() => ({ companyId: company.id, isActive: true }), [company]);

    return <Page initialValues={initialValues} />;
};

export default Creation;
