import { flow, isEmpty, get, negate } from 'lodash/fp';
import CarOptionsField from '../components/fields/display/CarOptionsField';
import CarOptionsLabel from '../components/fields/display/CarOptionsLabels';
import UpdateCarOptions from '../components/fields/edition/UpdateCarOption';
import { weightFieldAfter } from '../utils';
import fieldTemplate from './fieldTemplate';

const getDefaultCarOptions = (values, context) => {
    return context.carOptions.groups.map(({ options }) => options[0]);
};

const carOptionsField = {
    // copy the template
    ...fieldTemplate,

    // setup meta
    editionLabel: editionContext => editionContext.label,
    weight: weightFieldAfter('carModelAndPrice', 'carPrice'),
    gridSize: 2,

    // setup states
    isViewable: flow([get('carOptions'), negate(isEmpty)]),
    isEditable: () => true,

    // setup display rendering
    displayComponent: CarOptionsField,
    displayLabelComponent: CarOptionsLabel,

    // setup edition rendering
    editionComponent: UpdateCarOptions,

    // setup values management
    getInitialValue: getDefaultCarOptions,
    updates: {
        // reset the car options whenever the variant changed
        carModel: () => undefined,
    },

    // snapshot configuration
    snapshot: {
        ...fieldTemplate.snapshot,
        component: CarOptionsField,
    },
};

export default carOptionsField;
