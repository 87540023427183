import { OutlineNumericInput as NumericInput } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { validateOrSetError } from '../../../utils';

const UpdateNumeric = ({
    initialValue,
    valueRef,
    prefix,
    min,
    max,
    step,
    precision = 0,
    fixedDecimalScale,
    thousandSeparator,
    setError,
    validation,
}) => {
    // create a state to update the value
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        // fallback on zeo for null or undefined
        const newValue = value || 0;

        // update the value on the reference
        // eslint-disable-next-line no-param-reassign, react/prop-types
        valueRef.current = newValue;

        if (validation) {
            // execute yup validation
            validateOrSetError(validation, newValue, setError);
        }
    }, [valueRef, value, validation, setError]);

    const reset = useCallback(() => setValue(0), [setValue]);

    return (
        <NumericInput
            fixedDecimalScale={fixedDecimalScale}
            max={max}
            min={min}
            onChange={setValue}
            precision={precision}
            prefix={prefix}
            reset={reset}
            step={step}
            thousandSeparator={thousandSeparator}
            value={value}
            autoFocus
        />
    );
};

UpdateNumeric.propTypes = {
    fixedDecimalScale: PropTypes.bool,
    initialValue: PropTypes.number,
    max: PropTypes.number,
    min: PropTypes.number,
    precision: PropTypes.number,
    prefix: PropTypes.node,
    setError: PropTypes.func.isRequired,
    step: PropTypes.number,
    thousandSeparator: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    validation: PropTypes.shape({}),
    valueRef: PropTypes.shape({}).isRequired,
};

export default UpdateNumeric;
