import * as validators from '../../../../../utils/validators';

const validate = formatPath =>
    validators.compose(
        validators.requiredString('identifier'),
        validators.requiredString(formatPath('name')),
        validators.requiredString('groupId'),
        validators.requiredString('colour'),
        validators.requiredNumber('order'),
        validators.requiredNumber('price'),
        validators.requiredBoolean('isActive')
    );

export default validate;
