import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';
import React, { createContext, ReactNode, useCallback, useMemo, useState } from 'react';
import { useErrorContext } from '../utilities/errors';
import ConfirmModal, { ConfirmOption } from './ConfirmModal';

export type State = {
    content: string;
    title: string;
    options: ConfirmOption[];
};

export type ModalContextData = {
    confirm: (state: State) => void;
};

export type ModalProviderProps = {
    profile?: string;
    children: JSX.Element | ReactNode;
};

export const ModalContext = createContext<ModalContextData>(null!);

const ModalProvider = ({ children, profile = 'admin' }: ModalProviderProps) => {
    const [, setError] = useErrorContext();
    const [confirmModal, setConfirmModal] = useState<State | null>(null);

    const closeConfirm = useCallback(() => {
        setConfirmModal(null);
    }, [setConfirmModal]);

    const onConfirmAction = useCallback(
        option => {
            closeConfirm();

            const response = option.action();

            if (response instanceof Promise) {
                response.catch(error => {
                    if (error instanceof ApolloError) {
                        // display a modal for it
                        setConfirmModal({
                            title: 'Error',
                            content: error.graphQLErrors[0].message,
                            options: [{ label: 'ok', action: () => null }],
                        });

                        // and hide it in the head menu
                        setError(null);

                        return null;
                    }

                    // simply print it
                    console.error(error);

                    // then capture it
                    Sentry.captureException(error);

                    return null;
                });
            }
        },
        [closeConfirm, setConfirmModal, setError]
    );

    const context = useMemo(() => ({ confirm: setConfirmModal }), [setConfirmModal]);

    return (
        <ModalContext.Provider value={context}>
            {confirmModal && (
                <ConfirmModal
                    alertContent={confirmModal.content}
                    alertTitle={confirmModal.title}
                    onCloseClicked={closeConfirm}
                    onOptionSelected={onConfirmAction}
                    options={confirmModal.options}
                    profile={profile}
                    isOpen
                />
            )}
            {children}
        </ModalContext.Provider>
    );
};

export default ModalProvider;
