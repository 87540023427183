import { IntlValue } from '@appvantageasia/afc-calculator-ui-next';
import { createContext, useContext } from 'react';
import { InjectedFormProps } from 'redux-form';
import { Maybe } from '../../../schema';

type LanguageOptions = {
    label: string;
    value: string;
};

export type FormData = {
    newChannel: {
        variants: string[];
        bookingAmount?: Maybe<number> | undefined;
        priceDisclaimer?: string;
        dealerEstablishment?: Maybe<number> | undefined;
        iccFinanceProducts: string[];
    };
    usedChannel: {
        variants: string[];
        bookingAmount?: Maybe<number> | undefined;
        dealerEstablishment?: Maybe<number> | undefined;
    };
    expressChannel: {
        financeProducts: string[];
        bookingAmount?: Maybe<number> | undefined;
        priceDisclaimer?: string;
        dealerEstablishment?: Maybe<number> | undefined;
    };
    eventChannel: {
        finderFinanceProducts: string[];
        configuratorFinanceProducts: string[];
        csvFinanceProducts: string[];
        bookingAmount?: Maybe<number> | undefined;
        dealerEstablishment?: Maybe<number> | undefined;
    };
    coe?: number;
    ppsr?: number;
    availableBanksByDealer?: {
        name: IntlValue;
        establishment?: Maybe<number> | undefined;
    }[];
};

export type FormProps = {
    mayManageEventChannel?: boolean;
    mayManageExpressChannel?: boolean;
    mayManageNewCarChannel?: boolean;
    mayManageUsedCarChannel?: boolean;
    mayViewEventChannel?: boolean;
    mayViewExpressChannel?: boolean;
    mayViewNewCarChannel?: boolean;
    mayViewUsedCarChannel?: boolean;
    dealerId: string;
    options: {
        newVariants: { label: string; value: string }[];
        usedVariants: { label: string; value: string }[];
        expressFinanceProducts: { label: string; value: string }[];
        iccFinanceProducts: { label: string; value: string }[];
        finderFinanceProducts: { label: string; value: string }[];
        configuratorFinanceProducts: { label: string; value: string }[];
        csvFinanceProducts: { label: string; value: string }[];
    };
    lastModified: string | null;
    languageOptions: LanguageOptions[];
    onSubmit: (values: any) => Promise<void>;
};

export type FormContextData = FormProps & InjectedFormProps<FormData, FormProps>;

export const Context = createContext<FormContextData>(null!);

export const useFormContext = () => useContext(Context);
