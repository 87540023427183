import { useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, orderBy, getOr } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FootBar, FootBarButton, FootContainer } from '../../../../containers/Layout';
import { useContentTranslation } from '../../../../i18n';
import { getCountryId } from '../../../../selectors';
import { searchOnKeys } from '../../../../utilities/fp';
import { List as UIList, ListSearch } from '../../../ui/lists';
import usePaging from '../../../utilities/usePaging';
import { getDealersByCountryId } from './DealerList.graphql';

const columns = formatPath => [
    { name: 'ID', id: 'identifier', renderCell: get('identifier'), hasSort: true },
    { name: 'Name', id: 'name', renderCell: get(formatPath('name')), hasSort: true },
    { name: 'Users', id: 'users.length', renderCell: get('users.length'), hasSort: true },
    {
        name: 'Finance Products',
        id: 'financeProducts.length',
        renderCell: get('financeProducts.length'),
        hasSort: true,
    },
];

const sortItems = (items, sortKey, sortOrder) => {
    // we always sort by value key
    return orderBy(sortKey, sortOrder, items);
};

const DealerList = () => {
    const { formatPath } = useContentTranslation();
    const history = useHistory();
    const countryId = useSelector(getCountryId);
    const variables = { countryId };
    const { data, loading, error } = useQuery(getDealersByCountryId, { fetchPolicy: 'cache-and-network', variables });
    const dealers = getOr([], 'results.items', data);
    const isLoading = loading && dealers.length <= 0;

    // searching
    const [search, setSearch] = useState('');
    const searchMethod = useMemo(() => searchOnKeys(['identifier', formatPath('name')], dealers), [
        dealers,
        formatPath,
    ]);
    const matchedItems = useMemo(() => searchMethod(search), [search, searchMethod]);

    // sorting
    const [sortedOn, onSort] = useState(['code', 'asc']);
    const sortedItems = useMemo(() => sortItems(matchedItems, sortedOn), [sortedOn, matchedItems]);

    // paging
    const [pagedItems, paging] = usePaging(sortedItems, { search });

    const footer = (
        <FootContainer>
            <FootBar>
                <FootBarButton onClick={() => history.push('/scope/dealers/new')}>
                    <FontAwesomeIcon icon={faPlus} /> ADD Dealer
                </FootBarButton>
            </FootBar>
        </FootContainer>
    );

    return (
        <UIList
            columns={columns(formatPath)}
            error={error}
            footer={footer}
            headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
            items={pagedItems}
            loading={isLoading}
            onItemClick={item => history.push(`/scope/dealers/${item.id}`)}
            onSort={onSort}
            paging={paging}
            sortedOn={sortedOn}
            title="Dealers"
        />
    );
};

export default DealerList;
