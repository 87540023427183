import { get, getOr } from 'lodash/fp';
import * as yup from 'yup';
import DualField from '../components/fields/display/DualField';
import DualSnapshotField from '../components/fields/display/DualSnapshotField';
import UpdateDual from '../components/fields/edition/UpdateDual';
import { getDefaultDualValues, weightFieldAfter, getValueFromTable } from '../utils';
import fieldTemplate from './fieldTemplate';
import { compute } from './monthlyInstalment';
import raiseInvalidConfiguration from './raiseInvalidConfiguration';
import { DepositMode, FinanceProductSubType } from './schema';

const getDefaultDeposit = (values, context, initialValue = null) => {
    // get the config
    const config = get('selectedFinanceProduct.depositSetting', context);
    const { paymentTerm, totalPrice } = values;

    if (config === undefined || config === null || paymentTerm === undefined || totalPrice === undefined) {
        // cannot be done yet
        return undefined;
    }
    const { mode, default: defaultValue, defaultUnit } = config;

    switch (mode) {
        case DepositMode.RANGE:
            return getDefaultDualValues(totalPrice, defaultValue, defaultUnit);

        case DepositMode.TABLE: {
            const { table } = config;

            if (!table) {
                raiseInvalidConfiguration(context.t);
            }

            const monthlyInstalment = compute(values, context);

            // cannot be done yet
            if (!monthlyInstalment) {
                return undefined;
            }

            const value = monthlyInstalment * getValueFromTable(table, [item => item.term >= paymentTerm], 'term');

            return getDefaultDualValues(totalPrice, value, defaultUnit);
        }

        default:
            // unknown base
            return initialValue;
    }
};

const depositField = {
    // copy template
    ...fieldTemplate,

    // setup meta
    label: t => t('calculator.label.deposit'),
    weight: weightFieldAfter('downPayment'),

    // setup states
    isViewable: context =>
        get('selectedFinanceProduct.subType', context) === FinanceProductSubType.LEASE &&
        getOr(false, 'selectedFinanceProduct.depositSetting.show', context),
    isEditable: getOr(false, 'selectedFinanceProduct.depositSetting.editable'),

    displayComponent: DualField,

    editionComponent: UpdateDual,
    getEditionProperties: (context, { totalPrice }) => {
        const { selectedFinanceProduct, formats, t } = context;
        const { depositSetting } = selectedFinanceProduct;
        const { min, max, step } = depositSetting;

        const minErrorMessage = t('calculator.error.depositMin', { min });
        const validation = yup
            .number()
            .typeError(minErrorMessage)
            .min(min, minErrorMessage)
            .max(max, t('calculator.error.depositMax', { max }))
            .required(minErrorMessage);

        return {
            min,
            max,
            step,
            currency: formats?.currencySymbol,
            compute: {
                fromAmount: amount => formats.roundPercentage((amount * 100) / totalPrice),
                fromPercentage: percentage => formats.roundNumberDown((percentage / 100) * totalPrice),
            },
            validation,
        };
    },

    // setup values management
    getInitialValue: getDefaultDeposit,
    // this field needs to reset on everything just as monthlyInstalment to avoid calculating with old values
    updates: Object.fromEntries(
        [
            'financeProduct',
            'carModel',
            'balloonPayment',
            'totalPrice',
            'paymentTerm',
            'interestRate',
            'downPayment',
            'loanAmount',
            'mileage',
            'deposit',
            'paymentMode',
        ].map(field => [field, () => undefined])
    ),

    // snapshot configuration
    snapshot: {
        ...fieldTemplate.snapshot,
        component: DualSnapshotField,
    },
};

export default depositField;
