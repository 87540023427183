import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { exportApplicationDocuments } from '../../../../../actions';
import { handleResponseError } from '../../../../../utilities/forms';
import { ApplicationDataFragment } from '../../common/data/Application.graphql';

type UseDownloadDocumentsResult = [
    {
        isShow: boolean;
        password: string | null;
    },
    {
        onClose: () => void;
        getDocuments: () => void;
    }
];

const useDownloadDocuments = (application: ApplicationDataFragment | null): UseDownloadDocumentsResult => {
    const [state, setState] = useState({
        isShow: false,
        password: null,
    });

    const dispatch = useDispatch();

    const onClose = useCallback(() => {
        setState(oldState => ({ ...oldState, isShow: false }));
    }, []);

    const getDocuments = useCallback(() => {
        if (!application) {
            return;
        }
        setState({ password: null, isShow: false });

        // @ts-ignore
        dispatch(exportApplicationDocuments(application.id) as Promise<any>)
            .then(newPassword => {
                if (newPassword) {
                    setState({ isShow: true, password: newPassword });
                }
            })
            .catch(handleResponseError);
    }, [application, dispatch]);

    return [state, { onClose, getDocuments }];
};

export default useDownloadDocuments;
