import { ApolloClient, FetchPolicy, NormalizedCacheObject, useQuery } from '@apollo/client';
import { useMemo, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withLoading } from '../../../../../actions/loading';
import { Maybe } from '../../../../../schema';
import { getPromo, GetPromoQuery, PromoDataFragment } from './useLoadPromo.graphql';

export type Promo = PromoDataFragment;

export const getPromoFromIdentifier = async (
    client: ApolloClient<NormalizedCacheObject>,
    countryId: string,
    identifier: string,
    dealerIds: string[],
    fetchPolicy: FetchPolicy = 'cache-first'
): Promise<Maybe<Promo>> => {
    const variables = { countryId, identifier, dealerIds };
    const { data } = await client.query({ query: getPromo, variables, fetchPolicy });

    return data.promo || null;
};

const useLoadPromo = (
    countryId: string,
    dealerId: string,
    identifier: string = '',
    fetchPolicy: FetchPolicy = 'cache-first'
): Promo | null | undefined => {
    const variables = useMemo(() => ({ countryId, dealerIds: [dealerId], identifier }), [
        countryId,
        dealerId,
        identifier,
    ]);
    const { refetch, data, loading } = useQuery<GetPromoQuery>(getPromo, { skip: !identifier, variables, fetchPolicy });

    const dispatch = useDispatch();

    const previousVariables = useRef(variables);
    useEffect(() => {
        if (previousVariables.current !== variables) {
            dispatch(withLoading(refetch(variables)));
            previousVariables.current = variables;
        }
    }, [refetch, previousVariables, variables, dispatch]);

    return loading ? undefined : data?.promo;
};

export default useLoadPromo;
