import PropTypes from 'prop-types';
import Tree, { TreeNode } from 'rc-tree';
import React, { useCallback, useEffect, useState } from 'react';
import TreeContainer, { NoTree } from '../ui/Tree';

const customizeStyle = {
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid #636363',
    display: 'block',
};

const Trees = ({ block, nodes, onSelect }) => {
    const [loading, setLoading] = useState(1);

    useEffect(() => {
        setTimeout(() => setLoading(0), 500);
    }, [setLoading]);

    const switcherIcon = useCallback(obj => {
        if (obj.isLeaf) {
            return '';
        }

        return <i style={customizeStyle} />;
    }, []);

    const renderTreeNodes = data => {
        const loop = node =>
            node.map(item => {
                if (item.nodes) {
                    return (
                        <TreeNode key={item.id} title={item.name}>
                            {loop(item.nodes)}
                        </TreeNode>
                    );
                }

                return <TreeNode key={item.id} title={item.name} />;
            });

        return loop(data);
    };

    if (!nodes.length) {
        return <NoTree>There is no role hierarchy.</NoTree>;
    }

    return (
        <TreeContainer disabled={block}>
            <div className={block ? 'disabled' : ''}>
                {loading === 0 && nodes.length > 0 && (
                    <Tree
                        onSelect={block ? () => false : onSelect}
                        switcherIcon={switcherIcon}
                        autoExpandParent
                        defaultExpandAll
                    >
                        {renderTreeNodes(nodes)}
                    </Tree>
                )}
            </div>
        </TreeContainer>
    );
};

Trees.propTypes = {
    block: PropTypes.bool.isRequired,
    nodes: PropTypes.arrayOf(PropTypes.shape({})),
    onSelect: PropTypes.func.isRequired,
};

export default Trees;
