import { useApolloClient, useQuery } from '@apollo/client';
import { getOr, omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { submit } from 'redux-form';
import { addNotification } from '../../../../actions';
import { createCountryIntlValueMapper } from '../../../../i18n';
import {
    getCompanyIdentifier,
    getPorschePaymentAssortmentMandatory,
    getCurrentCountry,
} from '../../../../selectors/index';
import { DEALER_DELETE_NOTIFICATION, DEALER_SAVE_NOTIFICATION } from '../../../../shared/constants/notification';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import useLanguageSelector from '../../../../utils/useLanguageSelector';
import { useModal } from '../../../Modal';
import FormLayout from '../../../ui/form/FormLayout';
import Form from './Form';
import { create, update, remove, getData } from './Page.graphql';

const Page = ({ initialValues = null, disabled = false, disabledBankIds = [] }) => {
    const isUpdate = !!initialValues?.id;
    const history = useHistory();
    const dispatch = useDispatch();
    const modal = useModal();
    const { companyId, countryId } = useSelector(getCompanyIdentifier);
    const isPorschePaymentAssortmentMandatory = useSelector(getPorschePaymentAssortmentMandatory);
    const country = useSelector(getCurrentCountry);
    const languageSelector = useLanguageSelector();
    const mapIntlValue = createCountryIntlValueMapper(country);

    const submitForm = useCallback(() => dispatch(submit('dealer')), [dispatch]);

    const client = useApolloClient();
    const onSubmit = useCallback(
        ({ ...data }) => {
            const query = isUpdate ? update : create;
            // eslint-disable-next-line no-param-reassign
            data.countryId = countryId;
            // eslint-disable-next-line no-param-reassign
            data.companyId = companyId;
            const processedData = prepareForGraphQL(omit(['id', 'version', 'lastModified'], data));
            const variables = {
                data: {
                    ...processedData,
                    name: mapIntlValue(processedData.name),
                    address: mapIntlValue(processedData.address || {}),
                    additionalInformation: mapIntlValue(processedData.additionalInformation || {}),
                },
                id: data?.id,
            };
            const promise = client.mutate({ mutation: query, variables });

            return promise.catch(handleResponseError);
        },
        [client, companyId, countryId, isUpdate, mapIntlValue]
    );

    const onSubmitSuccess = useCallback(() => {
        dispatch(addNotification(DEALER_SAVE_NOTIFICATION));
        history.push('/scope/dealers');
    }, [dispatch, history]);

    const onDelete = useCallback(() => {
        modal.confirm({
            title: '',
            content: 'Are you sure you want to delete this dealer?',
            options: [
                { label: 'No', action: () => null },
                {
                    label: 'Yes',
                    action: () =>
                        client.mutate({ mutation: remove, variables: { id: initialValues?.id } }).then(() => {
                            dispatch(addNotification(DEALER_DELETE_NOTIFICATION));
                            history.push('/scope/dealers');
                        }),
                },
            ],
        });
    }, [client, dispatch, history, initialValues, modal]);

    const onCancel = useCallback(() => history.goBack(), [history]);

    const { data } = useQuery(getData, { variables: { countryId }, fetchPolicy: 'cache-and-network' });

    const banks = useMemo(
        () =>
            getOr([], 'country.banks', data).map(bank => {
                if (disabledBankIds.includes(bank.value)) {
                    return { ...bank, isFixed: true };
                }

                return bank;
            }),
        [data, disabledBankIds]
    );

    return (
        <FormLayout
            bodyComponent={
                initialValues && (
                    <Form
                        {...languageSelector}
                        banks={banks}
                        country={country}
                        disabled={disabled}
                        initialValues={initialValues}
                        isPorschePaymentAssortmentMandatory={isPorschePaymentAssortmentMandatory}
                        onSubmit={onSubmit}
                        onSubmitSuccess={onSubmitSuccess}
                    />
                )
            }
            moreActions={[
                initialValues?.id &&
                    !disabled && {
                        label: 'delete',
                        onAction: onDelete,
                    },
                {
                    label: 'save',
                    onAction: submitForm,
                },
            ]}
            onCancel={onCancel}
            title="Dealer"
        />
    );
};

Page.propTypes = {
    disabled: PropTypes.bool,
    disabledBankIds: PropTypes.arrayOf(PropTypes.string),
    initialValues: PropTypes.shape({}),
};

export default Page;
