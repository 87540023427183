import { get, getOr } from 'lodash/fp';
import * as yup from 'yup';
import UpdateNumeric from '../components/fields/edition/UpdateNumeric';
import { getValueFromTable, weightFieldAfter } from '../utils';
import fieldTemplate from './fieldTemplate';
import raiseInvalidConfiguration from './raiseInvalidConfiguration';

export const getDefaultResidualValue = (values, context) => {
    // get the balloon config
    const config = get('selectedFinanceProduct.residualValueSetting', context);

    if (config === undefined || config === null) {
        // cannot do anything yet
        return undefined;
    }

    const { table } = config;
    const { paymentTerm, mileage } = values;

    const selectedVariant = get('selectedVariant', context);
    const variantId = selectedVariant?.version?.id;

    if (!table || variantId === undefined) {
        raiseInvalidConfiguration(context.t);
    }

    return getValueFromTable(
        table,
        [item => item.term >= paymentTerm, item => item.variantId === variantId && item.mileage === mileage],
        'term',
        undefined
    );
};

const residualValueField = {
    // copy the template
    ...fieldTemplate,

    // setup label and order
    label: t => t('calculator.label.residualValueField'),
    weight: weightFieldAfter('mileage'),

    // setup states
    isViewable: context => getOr(false, 'selectedFinanceProduct.residualValueSetting.show', context),
    isEditable: getOr(false, 'selectedFinanceProduct.residualValueSetting.editable'),

    // setup display rendering
    getDisplayProperties: () => ({ format: value => `${value}%` }),
    editionComponent: UpdateNumeric,
    getEditionProperties: (context, values) => {
        const { formats, t } = context;
        const { loanAmount } = values;

        // max cannot exceed the loanAmount percentage
        const max = loanAmount?.percentage;
        const validation = yup.number().max(max, t('calculator.error.residualValueMax', { max }));

        return {
            min: 0,
            max,
            prefix: '%',
            fixedDecimalScale: formats?.fixedDecimalScale,
            validation,
        };
    },

    // setup values management
    getInitialValue: getDefaultResidualValue,
    updates: {
        financeProduct: () => undefined,
        mileage: () => undefined,
        paymentTerm: () => undefined,
    },
};

export default residualValueField;
