import { useQuery } from '@apollo/client';
import { getOr } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { FinderVehicleAvailability } from '../../../schema';
import { getCountryId } from '../../../selectors';
import { getLastModified } from '../../../utilities/forms';
import useFormatDateTime from '../../shared/useFormatDateTime';
import { getData, GetDataQuery, GetDataQueryVariables } from './Edition.graphql';
import Page from './Page';

const Edition = () => {
    const { id } = useParams<{ id: string }>();
    const countryId = useSelector(getCountryId);
    const variables = { id, countryId };
    const { data, loading, refetch } = useQuery<GetDataQuery, GetDataQueryVariables>(getData, {
        variables,
        fetchPolicy: 'network-only',
    });

    const refetchData = useCallback(
        variables => {
            refetch(variables);
        },
        [refetch]
    );

    const formatDateTime = useFormatDateTime();

    const hasDealer = useMemo(() => {
        const dealers = data?.dealers.items;
        const listing = data?.vehicle?.listing;

        if (!dealers || !listing) {
            return false;
        }

        return dealers.some(({ partnerNumber }) => listing.seller.porschePartnerNumber === partnerNumber);
    }, [data]);

    const initialValues = useMemo(() => {
        const values = data?.vehicle;

        if (!values) {
            return null;
        }

        return {
            ...values,
            setting: {
                availability: getOr(FinderVehicleAvailability.ONETIME, 'setting.availability', values),
                isHidden: getOr(false, 'setting.isHidden', values),
            },
            lastModified: getLastModified(values.version, formatDateTime),
        };
    }, [data, formatDateTime]);

    if (!loading && !initialValues) {
        // the vehicle does not exist
        return <Redirect to="/finder" />;
    }

    if (!initialValues) {
        return null;
    }

    return <Page hasDealer={hasDealer} initialValues={initialValues} refetch={refetchData} />;
};

export default Edition;
