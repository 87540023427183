import { useApolloClient } from '@apollo/client';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileSaver from 'file-saver';
import * as PDFJS from 'pdfjs-dist/webpack';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../../../../../../../selectors';
import { handleResponseError } from '../../../../../../../utilities/forms';
import { withModal } from '../../../../../../Modal';
import { PreviewContainer, SVGBox, PreviewItem, Clear } from '../../../../../../ui/Document';
import { deleteDocument } from '../../Page.graphql';
import { useFormContext } from '../context';
import ButtonRemoveIcon from '../../../../../../../shared/image/button-remove.png';

const Store = {};

const getExtension = filename => {
    const index = filename.lastIndexOf('.');

    if (index !== -1) {
        return filename.slice(index + 1);
    }

    return filename;
};

const mapTypes = {
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    pdf: 'application/pdf',
};

const loadImage = (source, filename, reader) => {
    const buffer = [];

    const readData = async () => {
        const { done, value } = await reader.read();

        if (value) {
            // push the data in the buffer
            buffer.push(value);
        }

        if (!done) {
            // read for more
            return readData();
        }

        // get its type
        const type = mapTypes[getExtension(filename)] || 'application/octet-stream';

        if (!Store[source]) {
            Store[source] = {};
        }

        if (!Store[source][filename]) {
            Store[source][filename] = {};
        }

        Store[source][filename] = {
            loading: false,
            blob: new Blob(buffer, { type }),
            img: URL.createObjectURL(new Blob(buffer, { type })),
        };

        return Store[source][filename];
    };

    return readData();
};

const useUrl = (file, setLoading) => {
    const {
        url,
        metaData: { filename },
        purpose,
    } = file;
    const [newUrl, setNewUrl] = useState(null);

    const getPDFFirstPage = useCallback(
        async reader => {
            try {
                PDFJS.disableWorker = true; // due to CORS

                // get the page
                const pdf = await PDFJS.getDocument(reader.result).promise;
                const page = await pdf.getPage(1);

                // create the viewport and canvas
                const viewport = page.getViewport({ scale: 1.5 });
                const canvas = document.createElement('canvas');
                const canvasContext = canvas.getContext('2d');
                const renderContext = { canvasContext, viewport };

                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // render it
                await page.render(renderContext).promise;

                // update the img url
                const pdfUrl = canvas.toDataURL();
                Store[purpose][filename].img = pdfUrl;

                setNewUrl(pdfUrl);
                setLoading(false);
            } catch (error) {
                console.error(error);
                // silently fail
                setNewUrl('');
                setLoading(false);
            }
        },
        [filename, purpose, setLoading]
    );

    const token = useSelector(getAccessToken);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const headers = { Authorization: `Bearer ${token}` };
                const response = await fetch(url, { headers });

                const reader = response.body.getReader();
                const image = await loadImage(purpose, filename, reader);

                if (getExtension(filename) === 'pdf') {
                    // we are going to read it again as a file
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(image.blob);
                    fileReader.onloadend = () => getPDFFirstPage(fileReader);

                    return;
                }

                setNewUrl(image.img);
                setLoading(false);
            } catch (error) {
                console.error(error);
                // let's make it silent
                setNewUrl('');
                setLoading(false);
            }
        };
        fetchData();
    }, [filename, getPDFFirstPage, purpose, setLoading, url, token]);

    if (Store?.[purpose]?.[filename]) {
        return Store?.[purpose]?.[filename]?.img;
    }

    return newUrl;
};

const DocumentPreview = ({ document, activeCategory, modal }) => {
    const { values, disabled, change } = useFormContext();
    const client = useApolloClient();
    const {
        id: attachmentId,
        url,
        metaData: { filename },
        purpose,
    } = document;
    const [loading, setLoading] = useState(url);
    const newUrl = useUrl(document, setLoading);

    const download = useCallback(() => {
        FileSaver.saveAs(Store[purpose][filename].blob, filename);
    }, [filename, purpose]);

    const remove = useCallback(() => {
        client
            .mutate({ mutation: deleteDocument, variables: { applicationId: values?.id, attachmentId } })
            .then(() => {
                change(
                    'attachments',
                    values.attachments.filter(item => item.id !== attachmentId)
                );
            })
            .catch(handleResponseError);
    }, [client, values, attachmentId, change]);

    return (
        <PreviewItem>
            <PreviewContainer>
                {loading && (
                    <div className="svg-box">
                        <FontAwesomeIcon className="loading" icon={faSpinner} size="2x" spin />
                    </div>
                )}
                {!loading && newUrl && <img alt={filename} onClick={download} src={newUrl} title={filename} />}
                {!loading && !newUrl && (
                    <SVGBox onClick={download}>
                        <FontAwesomeIcon className="default-image" icon={faImage} />
                    </SVGBox>
                )}
            </PreviewContainer>
            {!disabled && activeCategory?.id !== 1 && newUrl && (
                <Clear
                    onClick={() =>
                        modal.confirm({
                            title: '',
                            content: 'Do you want to remove this?',
                            options: [
                                { label: 'No', action: () => null },
                                { label: 'Yes', action: remove },
                            ],
                        })
                    }
                >
                    <img alt="" src={ButtonRemoveIcon} />
                </Clear>
            )}
        </PreviewItem>
    );
};

DocumentPreview.propTypes = {
    activeCategory: PropTypes.shape({
        id: PropTypes.number,
    }),
    document: PropTypes.shape({
        id: PropTypes.string,
        metaData: PropTypes.shape({
            filename: PropTypes.string.isRequired,
        }),
        purpose: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }),
    modal: PropTypes.shape({
        confirm: PropTypes.func,
    }),
};

export default withModal(DocumentPreview);
