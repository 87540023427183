const theme = {
    calculator: {
        titleColor: '#000',
        titleTransform: 'capitalize',

        labelColor: '#666666',
        labelTransform: 'capitalize',

        textColor: '#000',
        valueColor: '#ef2c72',

        singleColumn: false,
        noLabel: false,

        boxSize: '60px',
        titleSize: '0.85rem',
        labelSize: '1.21rem',
    },
};

export default theme;
