import { isObject, entries, omit } from 'lodash/fp';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getFormInitialValues, getFormValues } from 'redux-form';

const deepIsEqual = (source, otherSource) => {
    const values = entries(source);

    if (values.length !== entries(otherSource).length) {
        // we do not have the same number of entries so something is different
        return false;
    }

    for (const [key, value] of values) {
        // get the other value by using the key
        const otherValue = otherSource[key];

        if (isObject(value) || Array.isArray(value)) {
            // we need to do deeper comparison
            if (!deepIsEqual(value, otherValue)) {
                return false;
            }
        } else if (value !== otherValue) {
            return false;
        }
    }

    return true;
};

const useFormChanged = form => {
    const hasChangedRef = useRef(false);
    // get both initial values and current values
    const initialValues = useSelector(getFormInitialValues(form));
    const values = useSelector(getFormValues(form));

    return useMemo(() => {
        // if it changed once, we don't need to test again
        if (!hasChangedRef.current && values && initialValues) {
            // run the comparison

            hasChangedRef.current = !deepIsEqual(omit(['assigneeId'], values), omit(['assigneeId'], initialValues));
        }

        return hasChangedRef.current;
    }, [values, initialValues]);
};

export default useFormChanged;
