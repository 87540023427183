import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { yesNoOptions } from '../../../../../shared/constants/options';
import FieldContainer from '../../../../template/Field-container';
import NumberField from '../../../../template/Number';
import SwitchField from '../../../../template/Switch';
import { useFormContext } from './context';

const LicensePlateFee = () => {
    const { disabled, values, change, rounding } = useFormContext();

    const show = get('licensePlateFeeSetting.show', values);
    const editable = get('licensePlateFeeSetting.editable', values);
    const min = get('licensePlateFeeSetting.min', values);
    const max = get('licensePlateFeeSetting.max', values);

    useEffect(() => {
        if (show === undefined) {
            change('licensePlateFeeSetting.show', false);
        }
        if (editable === undefined) {
            change('licensePlateFeeSetting.editable', false);
        }
    }, [show, change, editable]);

    useEffect(() => {
        if (min === max) {
            change('licensePlateFeeSetting.step', 0);
            change('licensePlateFeeSetting.default', min);
        }
    }, [min, max, change]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <div className="sub-container-fluid">
                        <FieldContainer label="Min*">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                min={0}
                                name="licensePlateFeeSetting.min"
                                precision={rounding.amount.count}
                            />
                        </FieldContainer>
                        <span />
                        <FieldContainer label="Max*">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                min={0}
                                name="licensePlateFeeSetting.max"
                                precision={rounding.amount.count}
                            />
                        </FieldContainer>
                        <span />
                        <FieldContainer label="Step*">
                            <Field
                                component={NumberField}
                                disabled={min === max || disabled}
                                min={0}
                                name="licensePlateFeeSetting.step"
                                precision={rounding.amount.count}
                            />
                        </FieldContainer>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Default*">
                        <Field
                            component={NumberField}
                            disabled={disabled}
                            min={0}
                            name="licensePlateFeeSetting.default"
                            precision={0}
                        />
                    </FieldContainer>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Show">
                        <Field
                            component={SwitchField}
                            disabled={disabled}
                            name="licensePlateFeeSetting.show"
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Editable">
                        <Field
                            component={SwitchField}
                            disabled={!show || disabled}
                            name="licensePlateFeeSetting.editable"
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                </div>
            </div>
        </div>
    );
};

export default LicensePlateFee;
