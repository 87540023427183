import styled, { css } from 'styled-components';

const ParameterLabel = styled.div`
    text-align: center;
    font-size: ${props => props.theme.calculator.titleSize};
    line-height: 1.5;
    color: ${props => props.theme.calculator.labelColor};
    text-transform: ${props => props.theme.calculator.labelTransform};

    ${props =>
        props.theme.calculator.noLabel &&
        css`
            display: none;
        `}
`;

export default ParameterLabel;
