import { get, isEmpty, isNil } from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import {
    FinanceProductBase,
    FinanceProductSubType,
    InterestRateDisplayMode,
    InterestRateMode,
    RuntimeType,
    TotalInterestPayableDisplayMode,
} from '../../../../../schema';
import { getRuntimeSettings } from '../../../../../selectors';
import { configBaseOnOptions, interestRateTypeOptions } from '../../../../../shared/constants/options';
import FieldContainer from '../../../../template/Field-container';
import NumberField from '../../../../template/Number';
import SwitchField from '../../../../template/Switch';
import UnitField from '../../../../template/options-field/UnitField';
import InterestRateFlat from './InterestRateFlat';
import InterestRateRange from './InterestRateRange';
import { useFormContext } from './context';

const getModeFields = (mode: InterestRateMode) => {
    switch (mode) {
        case InterestRateMode.FLAT:
            return <InterestRateFlat />;

        case InterestRateMode.RANGE:
            return <InterestRateRange />;

        default:
            return null;
    }
};

const useDisplayModeOptions = () => {
    const { type } = useSelector(getRuntimeSettings);

    return useMemo(
        () =>
            [
                { label: 'Calculator', value: InterestRateDisplayMode.CALCULATOR },
                type === RuntimeType.PME && { label: 'Finance Product', value: InterestRateDisplayMode.FINANCEPRODUCT },
                { label: 'None', value: InterestRateDisplayMode.NONE },
            ].filter(Boolean),
        [type]
    );
};

const useTotalInterestPayableDisplayModeOptions = () => {
    const { type } = useSelector(getRuntimeSettings);

    return useMemo(
        () =>
            [
                { label: 'Calculator', value: TotalInterestPayableDisplayMode.CALCULATOR },
                type === RuntimeType.PME && {
                    label: 'Finance Product',
                    value: TotalInterestPayableDisplayMode.FINANCEPRODUCT,
                },
                { label: 'None', value: TotalInterestPayableDisplayMode.NONE },
            ].filter(Boolean),
        [type]
    );
};

const InterestRate = () => {
    const { disabled, values, change, currency, rounding } = useFormContext();

    const { basedOn, downPaymentSetting, loanSetting } = values;

    const displayModeOptions = useDisplayModeOptions();
    const totalInterestPayableDisplayModeOptions = useTotalInterestPayableDisplayModeOptions();

    const mode = get('interestRateSetting.mode', values);

    const min = get('interestRateSetting.min', values);
    const max = get('interestRateSetting.max', values);

    const editable = mode === InterestRateMode.RANGE && min !== max;
    const tableGroupingUnit = get('interestRateSetting.tableGroupingUnit', values);

    const basedSetting = useMemo(
        () => (basedOn === FinanceProductBase.DOWNPAYMENT ? downPaymentSetting : loanSetting),
        [basedOn, downPaymentSetting, loanSetting]
    );

    const totalInterestPayableDisplayMode = get('totalInterestPayableSetting.displayMode', values);

    const financeSubType = get('subType', values) as FinanceProductSubType;
    const showTotalInterestPayableSetting = [
        FinanceProductSubType.DEFERREDPRINCIPAL,
        FinanceProductSubType.HIREPURCHASE,
        FinanceProductSubType.HIREPURCHASEBALLOON,
        FinanceProductSubType.HIREPURCHASEBALLOONGFV,
        FinanceProductSubType.HIREPURCHASEBALLOONNZ,
        FinanceProductSubType.LEASEPURCHASE,
        FinanceProductSubType.LEASEPURCHASEICC,
    ].includes(financeSubType);

    useEffect(() => {
        change('interestRateSetting.editable', editable);
    }, [editable, change]);

    useEffect(() => {
        if (isEmpty(tableGroupingUnit)) {
            change('interestRateSetting.tableGroupingUnit', basedSetting.defaultUnit);
        }
        if (basedSetting.min === basedSetting.max) {
            change('interestRateSetting.tableGroupingStep', 0);
        }
    }, [basedOn, change, basedSetting, tableGroupingUnit]);

    useEffect(() => {
        if (isNil(totalInterestPayableDisplayMode) || !showTotalInterestPayableSetting) {
            change('totalInterestPayableSetting.displayMode', TotalInterestPayableDisplayMode.NONE);
        }
    }, [totalInterestPayableDisplayMode, showTotalInterestPayableSetting, change]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Type">
                        <Field
                            component={SwitchField}
                            disabled={disabled}
                            name="interestRateSetting.mode"
                            options={interestRateTypeOptions}
                        />
                    </FieldContainer>
                </div>
            </div>
            {getModeFields(mode)}
            {mode === InterestRateMode.TABLE && (
                <>
                    <div className="row">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Configure based on">
                                <Field component={SwitchField} name="basedOn" options={configBaseOnOptions} disabled />
                            </FieldContainer>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <div className="sub-container-fluid">
                                <FieldContainer label="Min*">
                                    <Field
                                        component={NumberField}
                                        disabled={disabled}
                                        max={basedSetting.max}
                                        min={basedSetting.min}
                                        name="interestRateSetting.min"
                                        precision={rounding.percentage.count}
                                    />
                                </FieldContainer>
                                <span />
                                <FieldContainer label="Max*">
                                    <Field
                                        component={NumberField}
                                        disabled={disabled}
                                        max={basedSetting.max}
                                        min={basedSetting.min}
                                        name="interestRateSetting.max"
                                        precision={rounding.percentage.count}
                                    />
                                </FieldContainer>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Table Grouping Step">
                                <div className="sub-container-fluid">
                                    <div className="flex-3">
                                        <Field
                                            component={NumberField}
                                            disabled={disabled}
                                            min={0}
                                            name="interestRateSetting.tableGroupingStep"
                                            precision={rounding.percentage.count}
                                        />
                                    </div>
                                    <span />
                                    <div>
                                        <UnitField
                                            currencySymbol={currency}
                                            name="interestRateSetting.tableGroupingUnit"
                                        />
                                    </div>
                                </div>
                            </FieldContainer>
                        </div>
                    </div>
                </>
            )}
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Interest Rate In">
                        <Field
                            component={SwitchField}
                            disabled={disabled}
                            name="interestRateSetting.displayMode"
                            options={displayModeOptions}
                        />
                    </FieldContainer>
                </div>
                {showTotalInterestPayableSetting && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Show Total Interest Payable">
                            <Field
                                component={SwitchField}
                                disabled={disabled}
                                name="totalInterestPayableSetting.displayMode"
                                options={totalInterestPayableDisplayModeOptions}
                            />
                        </FieldContainer>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InterestRate;
