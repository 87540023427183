import styled from 'styled-components';

export const FilteredMultiSelectContainer = styled.div`
    &.half-height {
        select {
            height: 150px;
        }
    }
`;

export const FilterDiv = styled.div`
    position: relative;

    svg {
        position: absolute;
        right: 10.5px;
        top: 43px;
        color: rgba(255, 255, 255, 0.31);
        cursor: pointer;
    }

    input.filter {
        width: 100%;
        border: 0;
        color: #000000;
        background: #f0f0f0;
        height: 30px;
        font-size: 0.85rem;
        padding-left: 10px;
    }

    input[type='text']:disabled {
    }
`;

export const OptionsDiv = styled.div`
    .fieldContainer {
        padding: 5px 5px 14.5px 0;
        background: #f0f0f0;
    }

    select {
        width: 100%;
        height: 300px;
        border: 0;
        color: #000000;
        background: #f0f0f0;
        font-size: 0.85rem;

        option {
            padding-left: 10px;
            height: 20px;
            margin-right: 5.5px;
        }

        &:focus {
            outline: none;
        }
    }

    option:checked {
        -webkit-text-fill-color: #000000;
        background: #d8d8d8 linear-gradient(0deg, #d8d8d8 0%, #d8d8d8 100%);
    }

    select[multiple]:focus option:checked {
        -webkit-text-fill-color: #000000;
        background: #d8d8d8 linear-gradient(0deg, #d8d8d8 0%, #d8d8d8 100%);
    }

    option:hover {
        background: #e6e6e6;
    }

    select::-webkit-scrollbar {
        width: 7.5px;
    }

    select::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 0px rgba(255, 255, 255, 0.1);
    }

    select::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        outline: 1px solid rgba(0, 0, 0, 0.2);
    }
`;

export const ButtonDiv = styled.div`
    display: flex;
    flex-direction: row;

    button {
        cursor: pointer;
        flex: 1;
        border: 0;
        color: #000000;
        background: #b0b0b0;
        font-size: 0.72rem;
        height: 33px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .btnContainer {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .icon-btn {
                svg {
                    font-size: 0.85rem;
                }

                &.active {
                    opacity: 0.7;
                }
            }
        }

        .text {
            padding: 0 5px;
            text-transform: uppercase;
        }

        &:disabled {
            color: #d8d8d8;
            cursor: default;
        }

        &:focus {
            outline: none;
        }

        &:nth-last-child(1) {
            border-left: 1px solid #ffffff;
        }
    }
`;
