import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCountry } from '../../../../selectors';
import Page from './Page';

const Creation = () => {
    const country = useSelector(getCurrentCountry);

    const initialValues = useMemo(
        () => ({
            countryId: country.id,
            isActive: true,
        }),
        [country.id]
    );

    return <Page initialValues={initialValues} />;
};

export default Creation;
