import { useQuery } from '@apollo/client';
import PropType from 'prop-types';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { useContentTranslation } from '../../../../../i18n';
import { toFormState } from '../../../../../utilities/applications/toFormState';
import { getLastModified } from '../../../../../utilities/forms';
import withCompanyFormatting from '../../../../../utils/withCompanyFormatting';
import useFormatDateTime from '../../../../shared/useFormatDateTime';
import { getApplication } from '../../common/data/Application.graphql';
import Page from './Page';

const Editor = ({ formats }) => {
    const translation = useContentTranslation();
    const formatDateTime = useFormatDateTime();

    const { id: versionId } = useParams();
    const { data, loading } = useQuery(getApplication, { fetchPolicy: 'no-cache', variables: { versionId } });

    const initialValues = useMemo(() => {
        if (!data?.application) {
            return null;
        }

        const application = toFormState(data?.application, formats, formatDateTime, translation);

        return {
            ...application,
            lastModified: getLastModified(application.version, formatDateTime),
        };
    }, [data, formats, formatDateTime, translation]);

    if (!loading && !initialValues) {
        return <Redirect to="/workflow/customers" />;
    }

    return <Page initialValues={initialValues} />;
};

Editor.propTypes = {
    formats: PropType.shape({}),
};

export default withCompanyFormatting(Editor);
