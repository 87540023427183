import { createContext, useContext } from 'react';

export type FormProps = {
    values: { raw?: string | null };
};

export const Context = createContext<FormProps>(null!);

export const useFormContext = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('Context is missing');
    }

    return context;
};
