import styled from 'styled-components';

const ImportButton = styled.button`
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 106.47px;
    border: 0;
    background: #f0f0f0;
    min-height: 30px;
    color: #000000;
    text-align: center;
`;

export default ImportButton;
