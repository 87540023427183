import { useQuery } from '@apollo/client';
import { get } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { getCountry } from './Country.graphql';
import Page from './Page';

const Creation = () => {
    const { countryId } = useParams();

    const variables = { countryId };
    const { data, loading } = useQuery(getCountry, { variables, fetchPolicy: 'cache-first' });
    const companyId = get('country.companyId', data);

    const initialValues = useMemo(
        () =>
            companyId
                ? {
                      countryId,
                      companyId,
                  }
                : null,
        [countryId, companyId]
    );

    if (!loading && !initialValues) {
        return <Redirect to="/scope/countries" />;
    }

    return <Page initialValues={initialValues} />;
};

export default Creation;
