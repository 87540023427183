import { useQuery } from '@apollo/client';
import { faDownload, faImage, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, orderBy } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { exportVariants } from '../../../../actions';
import { FootBar, FootBarButton, FootContainer } from '../../../../containers/Layout';
import { useContentTranslation } from '../../../../i18n';
import { getCountryId, getGlobalPermissions, getZoneId, getContext } from '../../../../selectors';
import { modelTypeOptions } from '../../../../shared/constants/options';
import { searchOnKeys } from '../../../../utilities/fp';
import { useCompanyFormatting } from '../../../../utils/withCompanyFormatting';
import { withModal } from '../../../Modal';
import ImportInvalidModal from '../../../ui/ImportInvalidModal';
import { List, cells, ListSearch } from '../../../ui/lists';
import usePaging from '../../../utilities/usePaging';
import { ImportVariantModal } from '../imports';
import { getTables, uploadImages } from './VariantList.graphql';
import useUploadImages from './useUploadImages';
import useVehicleTabs from './useVehiclesTabs';

const generateColumns = (zoneCode, zoneId, zones, currencySymbol) => {
    const { formatPath } = useContentTranslation();

    return useMemo(() => {
        const pricesColumns = [];

        if (zoneCode === 'All') {
            // if on a country level, list prices for all zones
            zones.forEach(({ code, id }) => {
                pricesColumns.push({
                    name: `Price - ${code} (${currencySymbol})`,
                    id: `prices[${id}]`,
                    renderCell: get(`prices[${id}]`),
                    hasSort: true,
                });
            });
        } else {
            // else if on a zone level, list price for this zone only
            pricesColumns.push({
                name: `Price - ${zoneCode} (${currencySymbol})`,
                id: `prices[${zoneId}]`,
                renderCell: get(`prices[${zoneId}]`),
                hasSort: true,
            });
        }

        return [
            { name: 'Vehicle ID', id: 'identifier', renderCell: get('identifier'), hasSort: true },
            { name: 'Variant Name', id: 'name', renderCell: get(formatPath('name')), hasSort: true, underline: true },
            { name: 'Model', id: 'model.order', renderCell: get(formatPath('model.name')), hasSort: true },
            { name: 'Submodel', id: 'subModel.order', renderCell: get(formatPath('subModel.name')), hasSort: true },
            { name: 'Body Type', id: 'bodyType', renderCell: get('bodyType'), hasSort: true },
            { name: 'Order No.', id: 'order', renderCell: get('order'), hasSort: true },
            ...pricesColumns,
            { name: 'Active', id: 'isActive', renderCell: cells.renderActive(), hasSort: true },
        ];
    }, [zoneCode, zoneId, zones, currencySymbol, formatPath]);
};

const getModel = item => get('model.parent', item) || get('model', item);

const getSubModel = item => !!get('model.parent', item) && get('model', item);

const getBodyType = item => {
    const type = get('model.parent.type', item) || get('model.type', item);

    return modelTypeOptions.find(option => option.value === type)?.label;
};

const useComputedItems = (items, formatNumber) =>
    useMemo(
        () =>
            items.map(item => ({
                ...item,
                model: getModel(item),
                subModel: getSubModel(item),
                bodyType: getBodyType(item),
                prices: Object.fromEntries(item.prices.map(({ zoneId, value }) => [zoneId, formatNumber(value)])),
            })),
        [items, formatNumber]
    );

const sortItems = (items, [sortKey, sortOrder]) => {
    // we always sort by value key
    return orderBy(sortKey, sortOrder, items);
};

const searchFields = formatPath => [
    formatPath('name'),
    'identifier',
    'bodyType',
    formatPath('make.name'),
    formatPath('subModel.name'),
    'order',
];

const VariantList = ({ modal }) => {
    const { formatPath } = useContentTranslation();
    const tabs = useVehicleTabs();
    const history = useHistory();
    const { mayManageVehicles } = useSelector(getGlobalPermissions);
    const [showImport, setShowImport] = useState(false);
    const [errors, setErrors] = useState(null);

    // get all zones containing zone code and zoneId
    const { user, countryCode, zoneCode = 'All', companyCode } = useSelector(getContext);
    const { availableCompanies: companies } = user;
    const { countries = [] } = companies.find(item => item.code === companyCode) || {};
    const country = countries.filter(({ code }) => code === countryCode);
    const { zones } = country[0];

    // get current zoneId
    const zoneId = useSelector(getZoneId);

    // get currency formatting
    const { currencySymbol, formatNumber } = useCompanyFormatting();

    const countryId = useSelector(getCountryId);
    const variables = { countryId };
    const { data, loading, error, refetch } = useQuery(getTables, { fetchPolicy: 'cache-and-network', variables });
    const items = useComputedItems(data?.results?.items || [], formatNumber);
    const isLoading = loading && items.length <= 0;

    // searching
    const [search, setSearch] = useState('');
    const searchMethod = useMemo(() => searchOnKeys(searchFields(formatPath), items), [items, formatPath]);
    const matchedItems = useMemo(() => searchMethod(search), [search, searchMethod]);

    // sorting
    const [sortedOn, onSort] = useState(['identifier', 'asc']);
    const sortedItems = useMemo(() => sortItems(matchedItems, sortedOn), [matchedItems, sortedOn]);

    // paging
    const [pagedItems, paging] = usePaging(sortedItems, { search });

    const dispatch = useDispatch();
    const exportExcel = useCallback(() => {
        return dispatch(exportVariants());
    }, [dispatch]);

    const onImportClose = useCallback(() => {
        setShowImport(false);
        refetch();
    }, [refetch]);

    const uploadImage = useUploadImages(
        setErrors,
        count => `${count} variants imported`,
        uploadImages,
        countryId,
        modal
    );

    const footer = (
        <FootContainer>
            <FootBar>
                {mayManageVehicles && (
                    <>
                        <FootBarButton onClick={() => history.push('/vehicle/variants/new')}>
                            <FontAwesomeIcon icon={faPlus} /> ADD Variant
                        </FootBarButton>
                        <FootBarButton onClick={exportExcel}>
                            <FontAwesomeIcon icon={faDownload} /> EXPORT EXCEL
                        </FootBarButton>
                        <FootBarButton onClick={() => setShowImport(true)}>
                            <FontAwesomeIcon icon={faUpload} /> IMPORT EXCEL
                        </FootBarButton>
                        <FootBarButton onClick={uploadImage}>
                            <FontAwesomeIcon icon={faImage} /> IMPORT IMAGES
                        </FootBarButton>
                    </>
                )}
            </FootBar>
        </FootContainer>
    );

    return (
        <>
            <List
                activeTab="variant"
                columns={generateColumns(zoneCode, zoneId, zones, currencySymbol)}
                error={error}
                footer={footer}
                headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
                items={pagedItems}
                loading={isLoading}
                onItemClick={item => history.push(`/vehicle/variants/${item.id}`)}
                onSort={onSort}
                paging={paging}
                sortedOn={sortedOn}
                tabs={tabs}
                title="Variants"
            />
            {showImport && <ImportVariantModal onClose={onImportClose} />}
            {errors && <ImportInvalidModal errors={errors} onClose={() => setErrors(null)} />}
        </>
    );
};

VariantList.propTypes = {
    modal: PropTypes.shape({
        confirm: PropTypes.func,
    }),
};

export default withModal(VariantList);
