// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFormValues, ReduxFormContext } from 'redux-form';
import styled from 'styled-components';
import { useContentTranslation } from '../../../../../../i18n';
import { EventExternalSite } from '../../../../../../schema';
import { assetConditionOptions, yesNoOptions } from '../../../../../../shared/constants/options';
import NumberField from '../../../../../shared/form-v2/NumberField';
import SelectField from '../../../../../shared/form-v2/SelectField';
import TextField from '../../../../../shared/form-v2/TextField';
import { ApplicationFormValues } from '../../application/Form/context';
import MiniConfiguratorDetails from './MiniConfiguratorDetails';

export const LinkField = styled.div`
    display: flex;
    min-height: 40px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #ced4da solid;

    .label {
        font-size: 14px;
        font-style: normal;
        font-weight: 100;
        color: #666666;
        touch-action: manipulation;
        white-space: nowrap;
        padding: 9px 0;
        margin-bottom: 0px;
        line-height: 20px;
        margin-right: 0.5rem;
    }

    .link-control {
        a {
            font-size: 14px;
            font-style: normal;
            font-weight: 100;
            line-height: 20px;
            color: #000000;
            text-decoration: underline;
        }
    }
`;

const Event = () => {
    const { formatPath } = useContentTranslation();
    const { form } = useContext(ReduxFormContext);
    const { booking, variant, miniConfiguratorDetails, finderVehicle, event } = useSelector(
        getFormValues(form)
    ) as ApplicationFormValues;

    const isFinderVehicleDetailsExists = useMemo(() => !!finderVehicle?.listing?.id, [finderVehicle]);

    const vin = booking?.unit?.details?.vin;

    const subModelExists = variant.model.parentId;

    // if submodel exist replace the name
    const modelName = useMemo(
        () => (subModelExists ? formatPath('variant.model.parent.name') : formatPath('variant.model.name')),
        [formatPath, subModelExists]
    );

    // only show model when variant is created adhoc
    const isExpress = variant.channels.express;

    if (event?.setting.externalSite === EventExternalSite.MARKETINGRECONSENT) {
        // do not show for marketing reconsent
        return null;
    }

    return (
        <Portlet name="Vehicle of Interest" closable open>
            <div className="container-fluid">
                <div className="row">
                    {!isFinderVehicleDetailsExists && (
                        <>
                            {!isExpress && (
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <TextField label="Make" name={formatPath('variant.model.make.name')} disabled />
                                </div>
                            )}
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField label="Model" name={modelName} disabled />
                            </div>
                            {!isExpress && (
                                <>
                                    {subModelExists && (
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <TextField
                                                label="Submodel"
                                                name={formatPath('variant.model.name')}
                                                disabled
                                            />
                                        </div>
                                    )}
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <TextField label="Variant" name={formatPath('variant.name')} disabled />
                                    </div>
                                </>
                            )}
                            {vin && (
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <TextField label="VIN Number" name="variant.vin" disabled />
                                </div>
                            )}
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <SelectField.Outline
                                    label="Asset Condition"
                                    name="assetCondition"
                                    options={assetConditionOptions}
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <NumberField label="Starting Price" name="calculator.price" type="currency" disabled />
                            </div>
                            {miniConfiguratorDetails && (
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <MiniConfiguratorDetails miniConfiguratorDetails={miniConfiguratorDetails} />
                                </div>
                            )}
                        </>
                    )}
                    {isFinderVehicleDetailsExists && (
                        <>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <div>
                                    <LinkField>
                                        <div className="label">Inventory ID</div>
                                        <div className="link-control">
                                            <Link to={`/finder/${finderVehicle?.id}`}>
                                                {finderVehicle?.listing?.id}
                                            </Link>
                                        </div>
                                    </LinkField>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField
                                    label="Vehicle ID"
                                    name="finderVehicle.listing.vehicle.orderTypeCode"
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField label="VIN" name="finderVehicle.listing.vehicle.vin" disabled />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField label="Vehicle Name" name="finderVehicle.name" disabled />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField label="Condition" name="finderVehicleCondition" disabled />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField
                                    label="Color"
                                    name="finderVehicle.listing.vehicle.exteriorColor.name.localize"
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <NumberField
                                    label="Price"
                                    name="finderVehicle.listing.price.value"
                                    type="currency"
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <SelectField.Outline
                                    label="Test Drive"
                                    name="hasTestDrive"
                                    options={yesNoOptions}
                                    disabled
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Portlet>
    );
};

export default Event;
