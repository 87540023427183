import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
    cursor: pointer;
    margin: 0 20px;
    color: #0088ff;
    font-size: 0.85rem;
    text-decoration: underline;
    text-transform: uppercase;
    background: none;
    border: none;

    &:focus {
        outline: none;
    }
`;

const PortletButton = ({ onClick, children }) => {
    // prevent parent element (portlet) click event from going off here
    const clickHandler = e => {
        e.stopPropagation();
        onClick();
    };

    return <StyledButton onClick={clickHandler}>{children}</StyledButton>;
};

PortletButton.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.node,
};

export default PortletButton;
