import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import MarkdownField from '../../../../shared/form/MarkdownField';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import TextareaField from '../../../../template/Textarea';
import { useFormContext } from './context';

const Languages = () => {
    const { disabled, languageOptions } = useFormContext();
    const getFormatPath = useCallback((language, label) => `${label}.${language}`, []);

    return (
        <div className="container-fluid">
            <div className="row">
                {languageOptions.map(eachLanguage => (
                    <div key={eachLanguage.value} className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label={`${eachLanguage.label} Variant`}>
                            <Field
                                component={InputField}
                                disabled={disabled}
                                name={getFormatPath(eachLanguage.value, 'name')}
                            />
                        </FieldContainer>
                        <FieldContainer label={`${eachLanguage.label} Description`}>
                            <Field
                                component={TextareaField}
                                disabled={disabled}
                                maxLength="500"
                                name={getFormatPath(eachLanguage.value, 'description')}
                            />
                        </FieldContainer>
                        <FieldContainer label={`${eachLanguage.label} Features`}>
                            <MarkdownField
                                autoFocus={false}
                                disabled={disabled}
                                name={getFormatPath(eachLanguage.value, 'features')}
                            />
                        </FieldContainer>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Languages;
