import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { getCurrentCountry } from '../../../../../selectors';
import CurrencyField from '../../../../template/Currency';
import DatePickerField from '../../../../template/DatePicker';
import FieldContainer from '../../../../template/Field-container';
import NumberField from '../../../../template/Number';
import ApvPortlet from '../../../../template/Portlet';

const UsedCarDetails = ({ disabled }) => {
    const { currency, rounding } = useSelector(getCurrentCountry);

    const currencyConfig = {
        precision: rounding.amount.count,
        prefix: currency,
    };

    return (
        <ApvPortlet name="Used Car Details" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Depreciation">
                            <Field
                                component={CurrencyField}
                                disabled={disabled}
                                name="usedCarDetails.depreciation"
                                {...currencyConfig}
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Mileage">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                name="usedCarDetails.mileage"
                                allowEmpty
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Registration Date">
                            <Field
                                component={DatePickerField}
                                disabled={disabled}
                                name="usedCarDetails.registrationDate"
                            />
                        </FieldContainer>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="OMV">
                            <Field
                                component={CurrencyField}
                                disabled={disabled}
                                name="usedCarDetails.omv"
                                {...currencyConfig}
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="ARF">
                            <Field
                                component={CurrencyField}
                                disabled={disabled}
                                name="usedCarDetails.arf"
                                {...currencyConfig}
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="QP Paid">
                            <Field
                                component={CurrencyField}
                                disabled={disabled}
                                name="usedCarDetails.coe"
                                {...currencyConfig}
                            />
                        </FieldContainer>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Deregistration Value">
                            <Field
                                component={CurrencyField}
                                disabled={disabled}
                                name="usedCarDetails.deregistrationValue"
                                {...currencyConfig}
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Road Tax">
                            <Field
                                component={CurrencyField}
                                disabled={disabled}
                                name="usedCarDetails.roadTax"
                                {...currencyConfig}
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="No. of Owners">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                name="usedCarDetails.noOfOwners"
                                allowEmpty
                            />
                        </FieldContainer>
                    </div>
                </div>
            </div>
        </ApvPortlet>
    );
};

UsedCarDetails.propTypes = {
    disabled: PropTypes.bool,
};

export default UsedCarDetails;
