import {
    OutlineError,
    OutlineInput,
    OutlineWrapper,
    PureError,
    PureInput,
    BoxedWrapper,
    BoxedInput,
} from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React, { forwardRef, useMemo, useState } from 'react';
import { Field } from 'redux-form';

export const TextInput = forwardRef((props, ref) => {
    const {
        input,
        fixedValue,
        inputComponent: Input = PureInput,
        wrapperComponent: Wrapper = OutlineWrapper,
        errorComponent: Error = PureError,
        wrapperProps,
        meta,
        label,
        onBlurValue,
        withFocusClear,
        ...inputProps
    } = props;

    const { active, touched, error = null } = meta;
    const hasError = !active && touched && !!error;

    const { onChange, onFocus: defaultOnFocus, onBlur: defaultOnBlur, value } = input;

    const [lastValue, setLastValue] = useState(value);

    const onFocus = useMemo(() => {
        if (withFocusClear) {
            return () => {
                setLastValue(value);

                return onChange('');
            };
        }

        return defaultOnFocus;
    }, [withFocusClear, onChange, defaultOnFocus, setLastValue, value]);

    const onBlur = useMemo(() => {
        if (withFocusClear) {
            return event => {
                if (event.target.value !== '') {
                    return onChange(event.target.value);
                }

                return onChange(lastValue);
            };
        }

        if (onBlurValue !== undefined) {
            return event => {
                if (event.target.value === '') {
                    return onChange(onBlurValue);
                }

                return defaultOnBlur(event);
            };
        }

        return defaultOnBlur;
    }, [onBlurValue, onChange, defaultOnBlur, lastValue, withFocusClear]);

    return (
        <Wrapper label={label} meta={meta} name={input.name} {...wrapperProps}>
            <Input {...input} onBlur={onBlur} onFocus={onFocus} {...inputProps} ref={ref} value={fixedValue || value} />
            {hasError && <Error>{error}</Error>}
        </Wrapper>
    );
});

TextInput.displayName = 'TextInput';

TextInput.propTypes = {
    errorComponent: PropTypes.elementType,
    fixedValue: PropTypes.string,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.onFocus,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    }).isRequired,
    inputComponent: PropTypes.elementType,
    label: PropTypes.string,
    meta: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        error: PropTypes.string,
        touched: PropTypes.bool.isRequired,
    }).isRequired,
    onBlurValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    withFocusClear: PropTypes.bool,
    wrapperComponent: PropTypes.elementType,
    // be careful when using wrapper properties, this might break optimization on pure components
    wrapperProps: PropTypes.shape({}),
};

const TextField = props => <Field component={TextInput} {...props} />;

TextField.Outline = props => (
    <TextField
        errorComponent={OutlineError}
        inputComponent={OutlineInput}
        wrapperComponent={OutlineWrapper}
        {...props}
    />
);

TextField.Boxed = props => <TextField inputComponent={BoxedInput} wrapperComponent={BoxedWrapper} {...props} />;

TextField.BoxedFullWidth = props => <TextField.Boxed wrapperComponent={BoxedWrapper.FullWidth} {...props} />;

export default TextField;
