import { CalculatorContext, displayFields } from '@appvantageasia/afc-calculator-ui-next';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import { Channel } from '../../../../../schema';
import { getCurrentCountry, getGlobalPermissions } from '../../../../../selectors';
import { GetApplicationQuery } from '../../common/data/Application.graphql';
import { VariantDataFragment } from '../../common/data/useLoadVariants.graphql';
import { getCalculatorFromApplication, getSnapshotFromApplication } from '../../common/utilities/map';
import { FinanceDataFragment } from '../data/useFinanceProducts.graphql';
import ConnectedCalculator from './ConnectedCalculator';
import ExpressCalculator from './ExpressCalculator';
import UsedCalculator from './UsedCalculator';
import { CalculatorSnapshot, CalculatorValues } from './types';
import useCalculatorMeta from './useCalculatorMeta';
import useDealerEstablishment from './useDealerEstablishment';

export type CalculatorProps = {
    application: GetApplicationQuery['application'] & {
        // temporary variant for preowned form in express
        expressVariant?: GetApplicationQuery['application']['variant'];
    };
    onChange: (context: CalculatorContext<CalculatorValues>) => void;
    channel: Channel;
    variants: VariantDataFragment[];
    financeProducts: FinanceDataFragment[];
    form: string;
};

const Calculator = ({ application, onChange, channel, variants, financeProducts, form }: CalculatorProps) => {
    const dispatch = useDispatch();
    const { code } = useSelector(getCurrentCountry);

    // get initial values from the application
    const initialValues = useMemo(() => getCalculatorFromApplication(application), [application]);
    // also get a snapshot
    const snapshot = (useMemo(() => getSnapshotFromApplication(application), [
        application,
    ]) as unknown) as CalculatorSnapshot;

    // Get dealer establishment
    const { selectedDealerEstablishment } = useDealerEstablishment(application.dealerId, channel);

    const { mayManageRecalculate, mayManageRecalculateInterestRate, mayManageRecalculateResidualValue } = useSelector(
        getGlobalPermissions
    );

    const recalculate = useMemo(
        () => ({
            isActive: true,
            mayManageRecalculate,
            mayManageRecalculateInterestRate,
            mayManageRecalculateResidualValue,
        }),
        [mayManageRecalculate, mayManageRecalculateInterestRate, mayManageRecalculateResidualValue]
    );

    const allowTradeInAmountInput = useMemo(() => application.event?.setting?.allowTradeInAmountInput, [
        application.event,
    ]);

    // common meta
    const additionalMeta = useMemo(
        () => ({
            channel,
            snapshot,
            financeProducts,
            variants,
            allowOutdated: true,
            zoneId: application.zoneId,
            recalculate,
            selectedDealerEstablishment,
            allowTradeInAmountInput: channel === Channel.EVENT && allowTradeInAmountInput,
        }),
        [
            allowTradeInAmountInput,
            application.zoneId,
            channel,
            financeProducts,
            recalculate,
            selectedDealerEstablishment,
            snapshot,
            variants,
        ]
    );
    const meta = useCalculatorMeta(additionalMeta);

    // gather common properties for calculators
    const commonProps = {
        // computed initial values
        initialValues,
        // change listener
        onChange,
        // dealer id tagged to the application
        dealerId: application.dealerId,
        // common meta
        meta,
        // disabled calculator
        disabled: !mayManageRecalculate,
    };

    // initialize pre owned details for express calculator
    useEffect(() => {
        if (channel === Channel.EXPRESS && !application.expressVariant) {
            // create temporary variant for express calculator
            dispatch(change(form, 'expressVariant', application.variant));
        }
    }, [application, channel, dispatch, form]);

    useEffect(() => {
        return () => {
            if (channel === Channel.EXPRESS) {
                // remove created field on unmount
                dispatch(change(form, 'expressVariant', undefined));
            }
        };
    }, [channel, dispatch, form]);

    // calculator render function
    const renderCalculator = useCallback(() => {
        switch (channel) {
            case Channel.NEW:
                return (
                    <ConnectedCalculator {...commonProps}>
                        <displayFields.CarModelPriceField
                            fieldKey="carModelAndPrice"
                            isViewable={() => true}
                            size={2}
                            override
                        />
                        <displayFields.CarModelField
                            fieldKey="variant"
                            isViewable={() => false}
                            labelTKey="calculator.label.carModel"
                            override
                        />
                        <displayFields.CarPriceField
                            fieldKey="carPrice"
                            isViewable={() => false}
                            labelTKey="calculator.label.carPrice"
                            override
                        />
                    </ConnectedCalculator>
                );

            case Channel.USED:
                return <UsedCalculator {...commonProps}>mock</UsedCalculator>;

            case Channel.EXPRESS:
                if (!application.expressVariant) {
                    // temporary variant is initializing
                    return null;
                }

                if (!application.expressVariant.preOwnedCarDetails) {
                    throw new Error('Missing pre owned car details on the variant');
                }

                return (
                    <ExpressCalculator
                        {...commonProps}
                        countryCode={code}
                        financeProducts={financeProducts}
                        preOwnedCarDetails={application.expressVariant.preOwnedCarDetails}
                        snapshot={snapshot}
                        variant={application.expressVariant}
                    />
                );

            default:
                return (
                    <ConnectedCalculator {...commonProps}>
                        <displayFields.CarModelField
                            fieldKey="variant"
                            isViewable={() => true}
                            labelTKey="calculator.label.carModel"
                            size={2}
                        />
                        <displayFields.CarPriceField
                            fieldKey="carPrice"
                            isViewable={() => false}
                            labelTKey="calculator.label.carPrice"
                            override
                        />
                    </ConnectedCalculator>
                );
        }
    }, [channel, commonProps, application, code, financeProducts, snapshot]);

    return renderCalculator();
};

export default Calculator;
