import {
    isValid,
    format,
    isSameDay,
    parseISO,
    getMinutes,
    setMinutes,
    getHours,
    setHours,
    getDay,
    isBefore,
    isAfter,
} from 'date-fns';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import TinyDatePicker from 'tiny-date-picker-customized';
import 'tiny-date-picker-customized/tiny-date-picker.css';

class DatePickerField extends Component {
    constructor(props) {
        super(props);

        // we need a reference on the input
        this.inputRef = React.createRef();

        // instance of our library
        this.dp = null;
    }

    componentDidMount() {
        const { value, max, min, className, dateFormat, allowedDays, disabledDates } = this.props;

        const parsedValue = value instanceof Date ? value : parseISO(value);

        // create our instance
        this.dp = TinyDatePicker(this.inputRef.current, {
            mode: 'dp-below',
            max,
            min,
            containerHTMLClass: className,
            format: date => format(date, dateFormat),
            dateClass: date => {
                const day = getDay(date);

                const isDisallowedDays = allowedDays && !allowedDays.includes(day);
                const isDisabledDates =
                    disabledDates && disabledDates.find(disabledDate => isSameDay(date, disabledDate));
                const isBeforeMinDate = min && isBefore(date, min);
                const isAfterMaxDate = max && isAfter(date, max);

                if (isDisallowedDays || isDisabledDates || isBeforeMinDate || isAfterMaxDate) {
                    return 'dp-day--disabled';
                }

                return '';
            },
        });

        // update state
        this.dp.setState({ selectedDate: parsedValue });

        // callbacks/listeners
        this.dp.on('statechange', this.onChange);
        this.dp.on('close', this.onChange);
    }

    componentDidUpdate() {
        const { dp } = this;
        const { selectedDate } = dp.state;
        const { value } = this.props;

        const parsedValue = value instanceof Date ? value : parseISO(value);

        if (!isSameDay(selectedDate, parsedValue)) {
            dp.setState({ selectedDate: parsedValue });
        }
    }

    componentWillUnmount() {
        this.dp.destroy();
    }

    onChange = (_, picker) => {
        const { onChange, value } = this.props;
        const parsedValue = value instanceof Date ? value : parseISO(value);
        const validPreviousValue = isValid(parsedValue);
        const newValue = picker.state.selectedDate;

        if (onChange && (!validPreviousValue || !isSameDay(newValue, parsedValue))) {
            if (validPreviousValue) {
                // fix the hours and minutes
                onChange(setHours(setMinutes(newValue, getMinutes(parsedValue)), getHours(parsedValue)));
            } else {
                onChange(newValue);
            }
        }
    };

    render() {
        const { disabled } = this.props;

        return <input ref={this.inputRef} disabled={disabled} type="text" readOnly />;
    }
}

DatePickerField.propTypes = {
    allowedDays: PropTypes.arrayOf(PropTypes.number),
    className: PropTypes.string,
    dateFormat: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    disabledDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    max: PropTypes.instanceOf(Date),
    min: PropTypes.instanceOf(Date),
    onChange: PropTypes.func.isRequired,
    value: PropTypes.instanceOf(Date),
};

export default styled(DatePickerField)`
    font-size: 12px !important;

    button {
        cursor: pointer;
    }

    .dp-close:hover,
    .dp-close:focus,
    .dp-clear:hover,
    .dp-clear:focus,
    .dp-today:hover,
    .dp-today:focus,
    .dp-next:hover,
    .dp-next:focus,
    .dp-prev:hover,
    .dp-prev:focus,
    .dp-cal-month:focus,
    .dp-cal-month:hover,
    .dp-cal-year:hover,
    .dp-cal-year:focus {
        background: #f0f0f0;
        color: black;
    }

    .dp-day:hover,
    .dp-month:hover,
    .dp-year:hover,
    .dp-current:focus,
    .dp-current,
    .dp-day:focus,
    .dp-month:focus,
    .dp-year:focus {
        outline: none;
        background: #f0f0f0;
        color: black;
    }

    .dp-day-today:after {
        content: '';
        height: 0;
        width: 0;
        border: 7px solid black;
        border-bottom-color: transparent;
        border-left-color: transparent;
        position: absolute;
        top: 0;
        right: 0;
    }

    &.dp::before {
        background: #f5f5f5;
        height: 0;
    }

    &.dp {
        padding-top: 0;
    }

    .dp-day.dp-day--disabled {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
    }
`;
