// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React from 'react';
import CustomerDetails from './CustomerDetails';
import DrivingLicenseDetails from './DrivingLicenseDetails';

const ApplicantDetails = () => (
    <Portlet name="Applicant Details" closable open>
        <div className="container-fluid">
            <CustomerDetails />
            <DrivingLicenseDetails />
        </div>
    </Portlet>
);

export default ApplicantDetails;
