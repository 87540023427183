import PropTypes from 'prop-types';
import React from 'react';
import ConnectedLoadingLayer from '../../../../common/ConnectedLoadingLayer';
import { DownloadModal, CloseButton, Header, Message } from '../../../../ui/Download';

const Download = ({ onClose, password }) => (
    <DownloadModal onRequestClose={onClose} isOpen>
        <div>
            <ConnectedLoadingLayer>
                <CloseButton onClick={onClose}>&times;</CloseButton>
                <Header>Password</Header>
                <Message>{password}</Message>
            </ConnectedLoadingLayer>
        </div>
    </DownloadModal>
);

Download.propTypes = {
    onClose: PropTypes.func.isRequired,
    password: PropTypes.string,
};

export default Download;
