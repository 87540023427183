import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { deleteNotification } from '../../actions/notification';

const NotificationDetectorContainer = styled.div`
    flex-shrink: 0;
    background: #888;
    padding: 5px 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
        cursor: pointer;
    }
`;

const NotificationDetector = ({ dispatch, notifications }) =>
    notifications
        .filter(i => i.clearOnPageChange)
        .map(notification => (
            <NotificationDetectorContainer key={notification.id}>
                {notification.message}
                <FontAwesomeIcon icon={faTimes} onClick={() => dispatch(deleteNotification(notification.id))} />
            </NotificationDetectorContainer>
        ));

const mapStateToProps = ({ notifications }) => ({ notifications });

export default connect(mapStateToProps)(withRouter(NotificationDetector));
