import styled from 'styled-components';

const List = styled.ul`
    overflow-y: scroll;
    font-weight: bold;

    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: white;
        border-radius: 10px;
    }
`;

export default List;
