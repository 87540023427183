import { useQuery } from '@apollo/client';
import { get, getOr } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { getCurrentCountry } from '../../../../../selectors';
import ApvPortlet from '../../../../template/Portlet';
import Inventory from './Inventory';
import MainDetails from './MainDetails';
import { Context } from './context';
import { getData } from './data.graphql';
import validate from './validate';

const Form = props => {
    const { form } = props;
    const { ct } = useContentTranslation();
    const values = useSelector(getFormValues(form));

    const { id: countryId } = useSelector(getCurrentCountry);
    const variables = { countryId };
    const { data } = useQuery(getData, { variables, fetchPolicy: 'cache-and-netowrk' });

    const { variants, models, subModels } = useMemo(
        () => ({
            variants: getOr([], 'variants.items', data).map(item => ({ ...item, label: ct(item.label) })),
            models: getOr([], 'models.items', data).map(item => ({ ...item, label: ct(item.label) })),
            subModels: getOr([], 'subModels.items', data).map(item => ({ ...item, label: ct(item.label) })),
        }),
        [data, ct]
    );

    const context = { ...props, values, variants, models, subModels };

    const variantId = get('variantId', values);

    return (
        <Context.Provider value={context}>
            <ApvPortlet name="Main Details" closable open>
                <MainDetails />
            </ApvPortlet>
            {variantId && (
                <ApvPortlet name="Inventory" closable open>
                    <Inventory key={variantId} />
                </ApvPortlet>
            )}
        </Context.Provider>
    );
};

Form.propTypes = {
    form: PropTypes.string.isRequired,
};

export default compose(reduxForm({ form: 'inventory', validate }), memo)(Form);
