import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import Activation from './Activation';
import { Context } from './context';
import validate from './validate';

const Form = props => {
    const { form } = props;
    const values = useSelector(getFormValues(form));
    const context = { ...props, values };

    return (
        <Context.Provider value={context}>
            <Activation />
        </Context.Provider>
    );
};

Form.propTypes = {
    form: PropTypes.string.isRequired,
};

export default reduxForm({ form: 'activation', validate })(Form);
