import { useQuery } from '@apollo/client';
import { faDownload, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, orderBy } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { exportSubModels } from '../../../../actions';
import { FootBar, FootBarButton, FootContainer } from '../../../../containers/Layout';
import { useContentTranslation } from '../../../../i18n';
import { getCountryId, getGlobalPermissions } from '../../../../selectors';
import { modelTypeOptions } from '../../../../shared/constants/options';
import { searchOnKeys } from '../../../../utilities/fp';
import { List, cells, ListSearch } from '../../../ui/lists';
import usePaging from '../../../utilities/usePaging';
import { ImportSubModelModal } from '../imports';
import { getTables } from './SubModelList.graphql';
import useVehicleTabs from './useVehiclesTabs';

const columns = formatPath => [
    { name: 'Submodel ID', id: 'identifier', renderCell: get('identifier'), hasSort: true },
    { name: 'Submodel Name', id: 'name', renderCell: get(formatPath('name')), hasSort: true, underline: true },
    { name: 'Model', id: 'parent.order', renderCell: get(formatPath('parent.name')), hasSort: true },
    { name: 'Number Of Variants', id: 'variants.length', renderCell: get('variants.length'), hasSort: true },
    { name: 'Body Type', id: 'bodyType', renderCell: get('bodyType'), hasSort: true },
    { name: 'Order No.', id: 'order', renderCell: get('order'), hasSort: true },
    { name: 'Active', id: 'isActive', renderCell: cells.renderActive(), hasSort: true },
];

const getBodyType = item => modelTypeOptions.find(option => option.value === item.type)?.label;

const useComputedItems = items =>
    useMemo(
        () =>
            items.map(item => ({
                ...item,
                bodyType: getBodyType(item),
            })),
        [items]
    );

const searchFields = formatPath => ['identifier', formatPath('name'), formatPath('parent.name'), 'order', 'bodyType'];

const sortItems = (items, [sortKey, sortOrder]) => {
    // we always sort by value key
    return orderBy(sortKey, sortOrder, items);
};

const SubModelList = () => {
    const { formatPath } = useContentTranslation();
    const tabs = useVehicleTabs();
    const history = useHistory();
    const { mayManageVehicles } = useSelector(getGlobalPermissions);
    const [showImport, setShowImport] = useState(false);

    const countryId = useSelector(getCountryId);
    const variables = { countryId };
    const { data, loading, error, refetch } = useQuery(getTables, { fetchPolicy: 'cache-and-network', variables });
    const items = useComputedItems(data?.results?.items || []);
    const isLoading = loading && items.length <= 0;

    // searching
    const [search, setSearch] = useState('');
    const searchMethod = useMemo(() => searchOnKeys(searchFields(formatPath), items), [items, formatPath]);
    const matchedItems = useMemo(() => searchMethod(search), [search, searchMethod]);

    // sorting
    const [sortedOn, onSort] = useState(['identifier', 'asc']);
    const sortedItems = useMemo(() => sortItems(matchedItems, sortedOn), [matchedItems, sortedOn]);

    // paging
    const [pagedItems, paging] = usePaging(sortedItems, { search });

    const dispatch = useDispatch();
    const exportExcel = useCallback(() => {
        return dispatch(exportSubModels());
    }, [dispatch]);

    const onImportClose = useCallback(() => {
        setShowImport(false);
        refetch();
    }, [refetch]);

    const footer = (
        <FootContainer>
            <FootBar>
                {mayManageVehicles && (
                    <>
                        <FootBarButton onClick={() => history.push('/vehicle/subModels/new')}>
                            <FontAwesomeIcon icon={faPlus} /> ADD Sub Model
                        </FootBarButton>
                        <FootBarButton onClick={exportExcel}>
                            <FontAwesomeIcon icon={faDownload} /> EXPORT EXCEL
                        </FootBarButton>
                        <FootBarButton onClick={() => setShowImport(true)}>
                            <FontAwesomeIcon icon={faUpload} /> IMPORT EXCEL
                        </FootBarButton>
                    </>
                )}
            </FootBar>
        </FootContainer>
    );

    return (
        <>
            <List
                activeTab="subModel"
                columns={columns(formatPath)}
                error={error}
                footer={footer}
                headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
                items={pagedItems}
                loading={isLoading}
                onItemClick={item => history.push(`/vehicle/subModels/${item.id}`)}
                onSort={onSort}
                paging={paging}
                sortedOn={sortedOn}
                tabs={tabs}
                title="Submodel"
            />
            {showImport && <ImportSubModelModal onClose={onImportClose} />}
        </>
    );
};

export default SubModelList;
