import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    color: #9c9c9c;
    font-size: 12px;
`;

const Link = styled.a`
    text-decoration: underline;
`;

type ChannelURLProps = {
    path: string;
    to: string;
};

const ChannelURL = ({ path, to }: ChannelURLProps) => (
    <Container>
        URL:{' '}
        <Link href={to} rel="noopener noreferrer" target="_blank">
            {path}
        </Link>
    </Container>
);

export default ChannelURL;
