import { useApolloClient } from '@apollo/client';
import { map, flow } from 'lodash/fp';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFormOption } from '../../../actions/formOptions';
import { getDialingCodes } from '../../../selectors';
import DropdownField from '../Dropdown';
import { queryAppOptions } from './options.graphql';

const formatOptionLabel = (item, { context }) => {
    if (context === 'menu') {
        return `${item.label} (${item.value})`;
    }

    return item?.value;
};

const processDialingCodes = flow([
    map(({ code, name, phone: { code: phonePrefix } }) => ({
        value: `+${phonePrefix}`,
        label: name,
        countryCode: code,
    })),
]);

const DialingCodeField = props => {
    const dialingCodes = useSelector(getDialingCodes) ?? [];
    const client = useApolloClient();
    const dispatch = useDispatch();

    useEffect(() => {
        client
            .query({ query: queryAppOptions, fetchPolicy: 'network-only' })
            .then(({ data }) => dispatch(setFormOption('dialingCodes', processDialingCodes(data?.options?.markets))));
    }, [client, dispatch]);

    return <DropdownField {...props} formatOptionLabel={formatOptionLabel} options={dialingCodes} />;
};

export default DialingCodeField;
