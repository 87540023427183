import * as Sentry from '@sentry/react';
import { map, flow } from 'lodash/fp';
import { queryRuntimeSettings, queryAppOptions } from './miscellaneous.graphql';

const processDialingCodes = flow([
    map(({ code, name, phone }) => ({
        value: `+${phone.code}`,
        label: name,
        countryCode: code,
        phone,
    })),
]);

export const fetchAppOptions = async client => {
    const { data } = await client.query({ query: queryAppOptions, fetchPolicy: 'network-only' });

    return data.options;
};

export const fetchDialingCodes = async client => {
    const { markets } = await fetchAppOptions(client);

    return processDialingCodes(markets);
};

export const fetchRuntimeSettings = async client => {
    const { data } = await client.query({ query: queryRuntimeSettings, fetchPolicy: 'network-only' });
    const settings = data.runtimeSettings;

    // update sentry environment
    Sentry.setTag('environment', settings.environment);

    return settings;
};
