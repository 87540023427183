import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isArray } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { v4 } from 'uuid';
import { ErrorMessageDiv } from '../../../../../../containers/Layout';
import FileDropBox from '../../../../../template/FileDropBox';
import { PreviewUpload } from '../../../../../ui/Document';

const GalleryUpload = ({ meta, input }) => {
    const handleUpload = useCallback(
        async file => {
            const previousDocuments = isArray(input.value) ? input.value : [];

            input.onChange([
                ...previousDocuments,
                {
                    file,
                    uiId: v4(),
                },
            ]);
        },
        [input]
    );

    const handleDrop = useCallback(
        files => {
            if (files[0]) {
                return handleUpload(files[0]);
            }

            return Promise.resolve();
        },
        [handleUpload]
    );

    const onChange = useCallback(event => handleDrop(event.target.files), [handleDrop]);

    const { error, touched, active } = meta;
    const { onBlur, onFocus } = input;

    return (
        <>
            <PreviewUpload>
                <FileDropBox className="upload" onDrop={handleDrop}>
                    <input accept=".jpg,.png,.jpeg" onBlur={onBlur} onChange={onChange} onFocus={onFocus} type="file" />
                    <div className="replace-style">
                        <div className="icon">
                            <FontAwesomeIcon icon={faPlus} />
                        </div>
                        <div>upload</div>
                    </div>
                </FileDropBox>
            </PreviewUpload>
            <ErrorMessageDiv>{touched && !active && error}</ErrorMessageDiv>
        </>
    );
};

GalleryUpload.propTypes = {
    input: PropTypes.shape().isRequired,
    meta: PropTypes.shape({
        active: PropTypes.bool,
        error: PropTypes.string,
        touched: PropTypes.bool,
    }).isRequired,
};

export default GalleryUpload;
