import styled from 'styled-components';

const Label = styled.div`
    font-style: normal;
    font-weight: 100;
    color: #666666;
    touch-action: manipulation;
    white-space: nowrap;
    height: 40px;
    padding: 9px 0;
    margin-bottom: 0px;
    line-height: 20px;
`;

export default Label;
