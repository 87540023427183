import styled from 'styled-components';

const ConsentListContainer = styled.div`
    height: 100%;
    td:first-child {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export default ConsentListContainer;
