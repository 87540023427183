import {
    CurrencyInputV2 as CurrencyInputWrapper,
    OutlineWrapperV2 as OutlineWrapper,
    PureErrorV2 as PureError,
} from '@appvantageasia/afc-ui';
import { isNil } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { forwardRef, useMemo, useState, useCallback } from 'react';
import Currency from 'react-currency-input';
import { Field } from 'redux-form';

export const CurrencyInput = forwardRef((props, ref) => {
    const {
        input,
        inputComponent: CurrencyField = CurrencyInputWrapper,
        wrapperComponent: Wrapper = OutlineWrapper,
        errorComponent: Error = PureError,
        wrapperProps,
        meta,
        label,
        onBlurValue,
        withFocusClear,
        allowNegative,
        precision,
        prefix,
        ...inputProps
    } = props;

    const { active, touched, error = null } = meta;
    const hasError = !active && touched && !!error?.trim();

    const { onChange, onFocus: defaultOnFocus, onBlur: defaultOnBlur, value } = input;

    const [lastValue, setLastValue] = useState(value);

    const onFocus = useMemo(() => {
        if (withFocusClear) {
            return () => {
                setLastValue(value);

                return onChange('');
            };
        }

        return defaultOnFocus;
    }, [withFocusClear, onChange, defaultOnFocus, setLastValue, value]);

    const onBlur = useMemo(() => {
        if (withFocusClear) {
            return event => {
                if (event.target.value !== '') {
                    return onChange(event.target.value);
                }

                return onChange(lastValue);
            };
        }

        if (onBlurValue !== undefined) {
            return event => {
                if (event.target.value === '') {
                    return onChange(onBlurValue);
                }

                return defaultOnBlur(event);
            };
        }

        return isNil(value) || value === '' ? defaultOnBlur : defaultOnBlur(value);
    }, [withFocusClear, onBlurValue, value, defaultOnBlur, onChange, lastValue]);

    const handleChange = useCallback((event, maskedvalue, floatvalue) => onChange(floatvalue), [onChange]);

    return (
        <div>
            <Wrapper label={label} meta={meta} name={input.name} {...wrapperProps}>
                <CurrencyField
                    {...input}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    value={value === '' ? null : value}
                    {...inputProps}
                    ref={ref}
                >
                    <Currency
                        allowNegative={allowNegative}
                        onChangeEvent={handleChange}
                        precision={precision}
                        prefix={`${prefix} ` || ''}
                        allowEmpty
                    />
                </CurrencyField>
            </Wrapper>
            {hasError && <Error>{error}</Error>}
        </div>
    );
});

CurrencyInput.displayName = 'CurrencyInput';

CurrencyInput.propTypes = {
    allowNegative: PropTypes.bool,
    errorComponent: PropTypes.elementType,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onDragStart: PropTypes.func,
        onDrop: PropTypes.func,
        onFocus: PropTypes.onFocus,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({})]),
    }).isRequired,
    inputComponent: PropTypes.elementType,
    label: PropTypes.string,
    meta: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        error: PropTypes.string,
        touched: PropTypes.bool.isRequired,
    }).isRequired,
    onBlurValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    precision: PropTypes.number,
    prefix: PropTypes.string,
    withFocusClear: PropTypes.bool,
    wrapperComponent: PropTypes.elementType,
    // be careful when using wrapper properties, this might break optimization on pure components
    wrapperProps: PropTypes.shape({}),
};

const CurrencyInputField = props => <Field component={CurrencyInput} {...props} />;

export default CurrencyInputField;
