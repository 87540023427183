import { useCallback } from 'react';

const useConfirm = modal => {
    return useCallback(
        async (type, action) => {
            let content = null;
            switch (type) {
                case 0:
                    content = 'Do you want to void this application?';
                    break;

                case 1:
                    content = 'Do you want to complete this application?';
                    break;

                case 2:
                    content = 'Do you want to decline this application?';
                    break;

                case 3:
                    content = 'Do you want to approve this application?';

                    break;
                case 5:
                    content = 'Do you want to activate this application?';
                    break;

                case 6:
                    content = 'Do you want to conclude agreement in this application?';
                    break;

                default:
                    break;
            }

            modal.confirm({
                content,
                options: [
                    { label: 'cancel', action: () => null },
                    { label: 'ok', action: () => action() },
                ],
            });

            return false;
        },
        [modal]
    );
};

export default useConfirm;
