import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Unit } from '../../../../schema';
import { getCurrentCountry, getCurrentZone, getDealerId } from '../../../../selectors';
import Page from './Page';

const Creation = () => {
    const { id: countryId } = useSelector(getCurrentCountry);
    const zone = useSelector(getCurrentZone);
    const dealerId = useSelector(getDealerId);

    const initialValues = useMemo(
        () => ({
            countryId,
            dealerId,
            unit: Unit.CURRENCY,
            isActive: true,
            deductValue: false,
            description: '',
            period: {
                start: new Date(),
                startTimeZone: zone?.timezone,
                endTimeZone: zone?.timezone,
            },
        }),
        [countryId, dealerId, zone]
    );

    return <Page initialValues={initialValues} />;
};

export default Creation;
