import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getFormValues, reduxForm, change } from 'redux-form';
import { FormTabContainer } from '../../../../../containers/Layout';
import { PermissionCode, PermissionLevel } from '../../../../../schema';
import MainDetails from './MainDetails';
import Permissions from './Permissions';
import Users from './Users';
import { Context, RoleFormContext } from './context';
import validate from './validate';

type FormProps = {
    form: string;
};

const adminPermissions = [
    PermissionCode.ADMIN_LOGIN,
    PermissionCode.ADMIN_APPLICATIONS,
    PermissionCode.ADMIN_RESERVATIONS,
    PermissionCode.ADMIN_LEADS,
    PermissionCode.ADMIN_CUSTOMERS,
    PermissionCode.ADMIN_FINANCE,
    PermissionCode.ADMIN_VEHICLES,
    PermissionCode.ADMIN_USERS,
    PermissionCode.ADMIN_ROLES,
    PermissionCode.ADMIN_ROLE_HIERARCHY,
    PermissionCode.ADMIN_MAINTENANCE,
    PermissionCode.ADMIN_CONSENT_DECLARATION,
    PermissionCode.ADMIN_PROMO_CODE,
    PermissionCode.ADMIN_NEW_CAR_CHANNEL,
    PermissionCode.ADMIN_USED_CAR_CHANNEL,
    PermissionCode.ADMIN_EXPRESS_CHANNEL,
    PermissionCode.ADMIN_EVENT_CHANNEL,
    PermissionCode.ADMIN_FINDER_VEHICLE,
    PermissionCode.ADMIN_APPOINTMENT,
];

const Form = (props: FormProps) => {
    const { form } = props;
    const dispatch = useDispatch();
    const values = useSelector(getFormValues(form)) as RoleFormContext['values'];
    const isAdminLoginDisabled = values?.permissions.ADMIN_LOGIN === PermissionLevel.NONE;
    const isAdminApplicationsManage = values?.permissions.ADMIN_APPLICATIONS === PermissionLevel.MANAGE;
    const isCiApplicationsManage = values?.permissions.CI_APPLICATIONS === PermissionLevel.MANAGE;

    const isAdminRecalculateManage = values?.permissions.ADMIN_RECALCULATE === PermissionLevel.MANAGE;
    const isCiRecalculateManage = values?.permissions.CI_RECALCULATE === PermissionLevel.MANAGE;

    useEffect(() => {
        if (isAdminLoginDisabled) {
            adminPermissions.forEach(value => dispatch(change(form, `permissions.${value}`, PermissionLevel.NONE)));
        }
    }, [dispatch, form, isAdminLoginDisabled]);

    useEffect(() => {
        if (!isAdminApplicationsManage) {
            dispatch(change(form, `permissions.${PermissionCode.ADMIN_RECALCULATE}`, PermissionLevel.NONE));
            dispatch(
                change(form, `permissions.${PermissionCode.ADMIN_RECALCULATE_INTEREST_RATE}`, PermissionLevel.NONE)
            );
            dispatch(
                change(form, `permissions.${PermissionCode.ADMIN_RECALCULATE_RESIDUAL_VALUE}`, PermissionLevel.NONE)
            );
        }
    }, [dispatch, form, isAdminApplicationsManage]);

    useEffect(() => {
        if (!isCiApplicationsManage) {
            dispatch(change(form, `permissions.${PermissionCode.CI_RECALCULATE}`, PermissionLevel.NONE));
            dispatch(change(form, `permissions.${PermissionCode.CI_RECALCULATE_INTEREST_RATE}`, PermissionLevel.NONE));
            dispatch(change(form, `permissions.${PermissionCode.CI_RECALCULATE_RESIDUAL_VALUE}`, PermissionLevel.NONE));
        }
    }, [dispatch, form, isCiApplicationsManage]);

    useEffect(() => {
        if (!isAdminRecalculateManage) {
            dispatch(
                change(form, `permissions.${PermissionCode.ADMIN_RECALCULATE_INTEREST_RATE}`, PermissionLevel.NONE)
            );
            dispatch(
                change(form, `permissions.${PermissionCode.ADMIN_RECALCULATE_RESIDUAL_VALUE}`, PermissionLevel.NONE)
            );
        }
    }, [dispatch, form, isAdminRecalculateManage]);

    useEffect(() => {
        if (!isCiRecalculateManage) {
            dispatch(change(form, `permissions.${PermissionCode.CI_RECALCULATE_INTEREST_RATE}`, PermissionLevel.NONE));
            dispatch(change(form, `permissions.${PermissionCode.CI_RECALCULATE_RESIDUAL_VALUE}`, PermissionLevel.NONE));
        }
    }, [dispatch, form, isCiRecalculateManage]);

    const context = { ...props, values } as RoleFormContext;

    return (
        <Context.Provider value={context}>
            <FormTabContainer>
                <Tabs forceRenderTabPanel>
                    <TabList>
                        <Tab>Main Details</Tab>
                        <Tab>Permissions</Tab>
                    </TabList>
                    <TabPanel>
                        <MainDetails />
                        <Users />
                    </TabPanel>
                    <TabPanel>
                        <Permissions />
                    </TabPanel>
                </Tabs>
            </FormTabContainer>
        </Context.Provider>
    );
};

// @ts-ignore
export default reduxForm({ form: 'role', validate })(Form);
