import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCountry } from '../../../../selectors';

const useFinanceTabs = () => {
    const country = useSelector(getCurrentCountry);
    const { allowOptions } = country.channelSetting.new;

    return useMemo(
        () =>
            [
                { id: 'inventory', name: 'Inventory', url: '/vehicle/inventories' },
                allowOptions && { id: 'option', name: 'Option', url: '/vehicle/options' },
                allowOptions && { id: 'optionGroup', name: 'Option Category', url: '/vehicle/optionGroups' },
                { id: 'variant', name: 'Variant', url: '/vehicle/variants' },
                { id: 'subModel', name: 'Submodel', url: '/vehicle/subModels' },
                { id: 'model', name: 'Model', url: '/vehicle/models' },
                { id: 'make', name: 'Make', url: '/vehicle/makes' },
            ].filter(Boolean),
        [allowOptions]
    );
};

export default useFinanceTabs;
