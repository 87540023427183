import { get, isEmpty } from 'lodash/fp';
import * as yup from 'yup';
import { renderCurrency } from '../components/fields/display/CurrencyField';
import UpdateNumeric from '../components/fields/edition/UpdateNumeric';
import { weightFieldAfter } from '../utils';
import fieldTemplate from './fieldTemplate';

const getDefaultCarPrice = get('selectedVariant.price.value');

const carPriceField = {
    // copy template
    ...fieldTemplate,

    // setup meta
    label: t => t('calculator.label.carPrice'),
    weight: weightFieldAfter('carModel'),
    gridSize: context => {
        const { hasMakes, isUsingSnapshot, snapshot } = context;

        if (!hasMakes) {
            // we dont care the price will always be with the variant label
            return 1;
        }

        // check if the display has options
        const hasOptions = !isEmpty(isUsingSnapshot ? snapshot?.carOptions : context.carOptions.selected);

        // take the whole width when there's options
        return hasOptions ? 2 : 1;
    },

    // setup states
    isViewable: (context, values, theme) => theme.calculator.singleColumn || context.hasMakes,
    isEditable: () => true,

    // setup display rendering
    getDisplayProperties: () => ({
        children: renderCurrency,
    }),

    // setup edition rendering
    editionComponent: UpdateNumeric,
    getEditionProperties: ({ formats, t }) => ({
        prefix: formats?.currencySymbol,
        precision: formats?.currencyPrecision,
        validation: yup.number().min(0, t('calculator.error.carPriceMin')).required(),
        fixedDecimalScale: formats?.fixedDecimalScale,
        thousandSeparator: formats?.thousandSeparator,
    }),

    // setup values management
    getInitialValue: (values, context) => getDefaultCarPrice(context),
    updates: {
        // reset the car price whenever the variant changed
        carModel: () => undefined,
    },
};

export default carPriceField;
