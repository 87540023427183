import React, { useMemo } from 'react';
import Select from 'react-select';
import styled, { useTheme } from 'styled-components';

// because react-select can not customize search input element
// so we need override it to make select is consist of input
const CustomizeSelect = styled(Select)`
    input {
        height: 22px;
        display: flex;
        align-items: center;
    }
`;

export const useBoxedSelectStyles = () => {
    const theme = useTheme();

    return useMemo(
        () => ({
            control: provided => ({
                ...provided,
                cursor: 'pointer',
                outline: 'none',
                border: 'none',
                boxShadow: 'none',
                backgroundColor: 'transparent',
                minHeight: 'auto',
                height: '22px',
            }),
            menu: provided => ({
                ...provided,
                top: '35px',
                left: '-16px',
                right: '-16px',
                marginTop: 0,
                marginBottom: 0,
                width: 'auto',
                border: '1px solid #ced4da',
                borderTop: 0,
                borderRadius: 0,
                boxShadow: 'none',
            }),
            menuList: provided => ({
                ...provided,
                padding: 0,
            }),
            option: (provided, { isSelected }) => ({
                ...provided,
                cursor: 'pointer',
                minHeight: '38px',
                lineHeight: '1',
                display: 'flex',
                alignItems: 'center',
                padding: '0 15px',
                backgroundColor: 'transparent',
                color: isSelected ? theme?.themeHighlightColour : '#000000',
                fontSize: '1.21rem',
                ':hover': {
                    ...provided[':hover'],
                    backgroundColor: theme?.themeHighlightColour,
                    color: '#FFFFFF',
                },
                ':active': {
                    ...provided[':active'],
                    backgroundColor: theme?.themeHighlightColour,
                    color: '#000000',
                },
            }),
            indicatorsContainer: () => ({ height: '22px' }),
            indicatorSeparator: () => ({ display: 'none' }),
            dropdownIndicator: () => ({ padding: '0' }),
            input: provided => ({
                ...provided,
                margin: 0,
                height: '22px',
                fontSize: '1.21rem',
                padding: 0,
            }),
            valueContainer: provided => ({
                ...provided,
                fontSize: '1.21rem',
                padding: '0',
                height: '22px',
            }),
            singleValue: (provided, { isDisabled }) => ({
                ...provided,
                marginLeft: 0,
                marginRight: 0,
                top: 'unset',
                transform: 'none',
                height: '22px',
                display: 'flex',
                alignItems: 'center',
                color: isDisabled ? '#000' : theme?.themeHighlightColour,
            }),
        }),
        [theme]
    );
};

const BoxedSelect = props => {
    const customStyles = useBoxedSelectStyles();

    return (
        <CustomizeSelect
            placeholder=""
            {...props}
            menuPlacement="auto"
            noOptionsMessage={() => 'No data available'}
            styles={customStyles}
        />
    );
};

export default BoxedSelect;
