import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import Inventory from './Inventory';
import MainDetails from './MainDetails';
import { Context } from './context';
import validate from './validate';

const Form = props => {
    const { form } = props;
    const values = useSelector(getFormValues(form));
    const context = { ...props, values };

    return (
        <Context.Provider value={context}>
            <MainDetails />
            <Inventory />
        </Context.Provider>
    );
};

Form.propTypes = {
    form: PropTypes.string.isRequired,
};

export default compose(reduxForm({ form: 'inventorySet', validate }), memo)(Form);
