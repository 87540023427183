import { getOr } from 'lodash/fp';
import * as yup from 'yup';
import { renderCurrency } from '../components/fields/display/CurrencyField';
import UpdateNumeric from '../components/fields/edition/UpdateNumeric';
import { weightFieldAfter } from '../utils';
import fieldTemplate from './fieldTemplate';

const getDefaultTradeIn = () => 0;

const tradeInField = {
    // copy template
    ...fieldTemplate,

    // setup meta
    label: t => t('calculator.label.tradeIn'),
    weight: weightFieldAfter('residualValue'),

    // setup states
    isViewable: getOr(false, 'selectedFinanceProduct.useTradeInFields'),
    isEditable: () => true,

    // setup display rendering
    getInitialValue: getDefaultTradeIn,
    getDisplayProperties: () => ({ children: renderCurrency }),

    // setup edition rendering
    editionComponent: UpdateNumeric,
    getEditionProperties: context => {
        const { formats, t } = context;

        const minErrorMessage = t('calculator.error.tradeInMin', { min: 0 });
        const validation = yup.number().typeError(minErrorMessage).min(0, minErrorMessage).required(minErrorMessage);

        return {
            min: 0,
            prefix: formats?.currencySymbol,
            precision: formats?.currencyPrecision,
            fixedDecimalScale: formats?.fixedDecimalScale,
            thousandSeparator: formats?.thousandSeparator,
            validation,
        };
    },
};

export default tradeInField;
