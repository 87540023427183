import React, { useMemo } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router';
import useLoadCompany from '../../utils/useLoadCompany';
import LoginRoute from '../routes/LoginRoute';
import NotFoundCompanyCountryRoute from '../routes/LoginRoute/NotFoundCompanyCountryRoute';
import ResetPasswordRoute from '../routes/ResetPasswordRoute';

export const prefixCompanyPath = path => `/:companyCode/:locationCode${path}`;

const locationRegex = /^(?<countryCode>[a-zA-Z0-9]+)(-(?<zoneCode>[a-zA-Z0-9]+))?$/;

const LoginRouter = () => {
    // get company code and country code from react router
    const { companyCode, locationCode } = useParams();

    // parse it to retrieve our codes
    const { countryCode, zoneCode = countryCode } = useMemo(() => {
        return locationCode.match(locationRegex)?.groups ?? {};
    }, [locationCode]);

    // get the company
    const [isLoading, scope] = useLoadCompany(companyCode, countryCode, zoneCode);
    const company = scope?.company;

    if (!isLoading && !company) {
        // return no company country page
        return <NotFoundCompanyCountryRoute />;
    }

    if (isLoading && !company) {
        // still loading
        return null;
    }

    return (
        <Switch>
            <Route component={LoginRoute} path="/:companyCode/:locationCode/login" exact />
            <Route component={ResetPasswordRoute} path="/:companyCode/:locationCode/login/resetpassword" exact />
            <Redirect to="/login" />
        </Switch>
    );
};

export default LoginRouter;
