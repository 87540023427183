export const AverageMileageTableBase = {
    VARIANT: 'Variant',
    MODEL: 'Model',
};

export const FinanceProductBase = {
    DOWNPAYMENT: 'DownPayment',
    LOAN: 'Loan',
};

export const BalloonBase = {
    CARPRICE: 'CarPrice',
    LOAN: 'Loan',
};

export const BalloonMode = {
    RANGE: 'Range',
    TABLE: 'Table',
    MILEAGETABLE: 'MileageTable',
    LEASING: 'Leasing',
};

export const InterestRateMode = {
    RANGE: 'Range',
    TABLE: 'Table',
    FLAT: 'Flat',
};

export const FinanceProductType = {
    FINANCE: 'Finance',
    LEASING: 'Leasing',
};

export const Unit = {
    PERCENTAGE: 'Percentage',
    CURRENCY: 'Currency',
    KM: 'Km',
};

export const FinanceProductSubType = {
    HIREPURCHASE: 'HirePurchase',
    HIREPURCHASEBALLOON: 'HirePurchaseBalloon',
    STRUCTUREDPAYMENT: 'StructuredPayment',
    AGILITY: 'Agility',
    HALFHALF: 'HalfHalf',
    DEFERREDPRINCIPAL: 'DeferredPrincipal',
    LEASE: 'Lease',
    LEASEPURCHASE: 'LeasePurchase',
    FIRSTPF: 'FirstPF',
};

export const MatchComputeSubtype = {
    HirePurchase: 'Standard HP',
    HirePurchaseBalloon: 'HP with Balloon',
    Agility: 'Agility',
    HalfHalf: '50 - 50',
    DeferredPrincipal: 'Deferred Principal',
};

export const CalculationMode = {
    FLAT: 'Flat',
    EFFECTIVE: 'Effective',
};

export const MatchCalculationMode = {
    Flat: 'Flat Rate',
    Effective: 'Effective Rate',
};

export const MatchFinanceProductType = {
    Finance: 'finance',
    Leasing: 'lease',
};

export const DerivedMethod = {
    FORMULA: 'Formula',
    LOOKUPTABLE: 'Lookup Table',
};

export const PaymentMode = {
    ADVANCE: 'Advance',
    ARREARS: 'Arrears',
};

export const DownpaymentMode = {
    RANGE: 'Range',
    TABLE: 'Table',
};

export const DepositMode = {
    RANGE: 'Range',
    TABLE: 'Table',
};
