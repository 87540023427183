import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import LoadingLayer from './LoadingLayer';

const getLoading = state => state.loading;

const LoadingLayerRenderer = ({ children }) => {
    const loading = useSelector(getLoading);

    return (
        <>
            {!!loading && <LoadingLayer />}
            {children}
        </>
    );
};

LoadingLayerRenderer.propTypes = {
    children: PropTypes.node,
};

export default LoadingLayerRenderer;
