import styled from 'styled-components';

const RawTextarea = styled.textarea`
    color: ${props => props.theme.themeHighlightColour};
    outline: none;
    border: 1px solid #ced4da;
    padding: 10px;
    font-size: 1.21rem;
    width: calc(100% - 20px);
    min-height: 50px;
`;

export default RawTextarea;
