import { useQuery } from '@apollo/client';
import { format, subMonths } from 'date-fns';
import { get, orderBy, getOr, isNil } from 'lodash/fp';
import PropType from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCountry, getZoneId } from '../../../selectors';
import withCompanyFormatting from '../../../utils/withCompanyFormatting';
import { cells } from '../../ui/lists';
import TopSalesManTable from './TopSalesManTable';
import { getMostApplicationSalesPerson } from './TopSalesManWithApplication.graphql';
import { useDashboardContext } from './context';

const useColumns = formatCurrencyDown =>
    useMemo(
        () => [
            {
                name: 'Name',
                id: 'name',
                renderCell: get('name'),
            },
            { name: 'Alias', id: 'alias', renderCell: get('alias') },
            {
                name: 'Loan',
                id: 'amount',
                renderCell: cells.renderCurrencyDown('amount', formatCurrencyDown),
            },
        ],
        [formatCurrencyDown]
    );

const TopSalesManWithLoan = ({ formats }) => {
    const { formatCurrencyDown } = formats;

    const { id: countryId } = useSelector(getCurrentCountry);
    const zoneId = useSelector(getZoneId);
    const { channels, dealerIds } = useDashboardContext();
    const variables = {
        filter: { zoneId, countryId, dealerIds, channels },
        isLead: false,
        sortParam: 'amount',
    };
    const { data } = useQuery(getMostApplicationSalesPerson, {
        skip: isNil(dealerIds) || dealerIds.length < 1 || channels.length < 1,
        fetchPolicy: 'no-cache',
        variables,
    });

    const columns = useColumns(formatCurrencyDown);

    const tabs = useMemo(
        () => [
            {
                label: `Current Month (${format(new Date(), 'MMM')})`,
                dataKey: 'currentMonth_salesPersonWithHighestLoans',
                data: orderBy('amount', 'desc', getOr([], 'currentMonth', data?.metrics)),
            },
            {
                label: `Last Month (${format(subMonths(new Date(), 1), 'MMM')})`,
                dataKey: 'lastMonth_salesPersonWithHighestLoans',
                data: orderBy('amount', 'desc', getOr([], 'lastMonth', data?.metrics)),
            },
        ],
        [data]
    );

    return (
        <TopSalesManTable
            columns={columns}
            tabs={tabs}
            title="Top 3 Salespersons: Highest approved total loan amount"
        />
    );
};

TopSalesManWithLoan.propTypes = {
    formats: PropType.shape({
        formatCurrencyDown: PropType.func,
    }),
};

export default withCompanyFormatting(TopSalesManWithLoan);
