import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import {
    faAddressCard,
    faCar,
    faFileAlt,
    faFileInvoiceDollar,
    faGlobeAsia,
    faIndustry,
    faTools,
    faUsers,
    faUserTie,
    faTicketAlt,
    faProjectDiagram,
    faWarehouse,
    faClipboardCheck,
} from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUser, getCurrentCountry } from '../../selectors';
import * as core from '../../utils/permissions';

const useMenuLinks = () => {
    const user = useSelector(getUser);
    const currentCountry = useSelector(getCurrentCountry);
    const permissions = useMemo(() => core.getGlobalPermissions(user, currentCountry), [currentCountry, user]);

    const { mayViewUsers, mayViewRoles } = permissions;

    // redirection based on permission
    const userManagementLink = useMemo(() => {
        if (mayViewUsers) {
            return '/access/users';
        }

        if (mayViewRoles) {
            return '/access/roles';
        }

        return '/access/hierarchies';
    }, [mayViewRoles, mayViewUsers]);

    // filter links
    return useMemo(
        () =>
            [
                {
                    label: 'Users & Roles',
                    key: 'USER_MANAGEMENT',
                    link: userManagementLink,
                    icon: faUsers,
                    permission: ['mayViewUsers', 'mayViewRoles', 'mayViewRolesHierarchy'],
                },
                {
                    label: 'Company',
                    key: 'COMPANY',
                    link: '/scope/companies',
                    icon: faIndustry,
                    permission: 'mayManageCompanies',
                },
                {
                    label: 'Country / Zone',
                    key: 'COUNTRY_ZONE_SETTINGS',
                    link: '/scope/countries',
                    icon: faGlobeAsia,
                    permission: 'mayManageCountries',
                },
                {
                    label: 'Dealer',
                    key: 'DEALER',
                    link: '/scope/dealers',
                    icon: faWarehouse,
                    permission: 'mayManageDealers',
                },
                {
                    label: 'Channels',
                    key: 'CHANNELS',
                    link: '/channels',
                    icon: faProjectDiagram,
                    permission: [
                        'mayViewNewCarChannel',
                        'mayViewUsedCarChannel',
                        'mayViewExpressChannel',
                        'mayViewEventChannel',
                    ],
                },
                {
                    label: 'Consents & Declarations',
                    key: 'CONSENT_DECLARATION',
                    link: '/consent',
                    icon: faClipboardCheck,
                    permission: 'mayViewConsentDeclaration',
                },
                {
                    label: 'Financial Products',
                    key: 'FINANCIAL_PRODUCT',
                    link: '/finance/products',
                    icon: faFileInvoiceDollar,
                    permission: 'mayViewFinanceProducts',
                },
                {
                    label: 'Vehicle Models',
                    key: 'VEHICLE_MODEL',
                    link: '/vehicle/variants',
                    icon: faCar,
                    permission: 'mayViewVehicles',
                },
                currentCountry.usePorscheFinder && {
                    label: 'Finder Vehicles',
                    key: 'FINDER_VEHICLE',
                    link: '/finder',
                    icon: faCar,
                    permission: 'mayViewFinderVehicles',
                },
                {
                    label: 'Promo Code',
                    key: 'PROMO',
                    link: '/promo',
                    icon: faTicketAlt,
                    permission: 'mayViewPromo',
                },
                {
                    label: 'Events',
                    key: 'EVENTS',
                    link: '/event',
                    icon: faCalendar,
                    permission: 'mayViewEventChannel',
                },
                {
                    label: 'Leads',
                    key: 'LEADS',
                    link: '/workflow/leads',
                    icon: faAddressCard,
                    permission: 'mayViewLeads',
                },
                {
                    label: 'Reservations',
                    key: 'RESERVATION_APPLICATIONS',
                    link: '/workflow/reservations',
                    icon: faFileAlt,
                    permission: 'mayViewReservations',
                },
                {
                    label: 'Finance Applications',
                    key: 'FINANCE_APPLICATIONS',
                    link: '/workflow/applications',
                    icon: faFileAlt,
                    permission: 'mayViewApplications',
                },
                {
                    label: 'Insurance Applications',
                    key: 'INSURANCE_APPLICATIONS',
                    link: '/workflow/insuranceApplications',
                    icon: faFileAlt,
                    permission: 'mayViewInsuranceApplications',
                },
                {
                    label: 'Appointments',
                    key: 'APPOINTMENTS',
                    link: '/appointment',
                    icon: faCalendar,
                    permission: 'mayViewAppointments',
                },
                {
                    label: 'Customers',
                    key: 'CUSTOMERS',
                    link: '/customer',
                    icon: faUserTie,
                    permission: 'mayViewCustomers',
                },
                {
                    label: 'Maintenance',
                    key: 'MAINTENANCE',
                    link: '/maintenance',
                    icon: faTools,
                    permission: 'mayViewMaintenance',
                },
            ].filter(link => {
                // clean up falsy values
                if (!link) {
                    return false;
                }

                const { permission } = link;

                return (
                    !permission ||
                    (Array.isArray(permission) &&
                        permission.map(subPermission => permissions[subPermission]).filter(Boolean).length > 0) ||
                    permissions[permission]
                );
            }),
        [currentCountry, permissions, userManagementLink]
    );
};

export default useMenuLinks;
