import { useApolloClient, useQuery } from '@apollo/client';
import { isEmpty, omit } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { submit } from 'redux-form';
import { addNotification } from '../../../actions';
import { useContentTranslation } from '../../../i18n';
import { AssetCondition } from '../../../schema';
import { getCurrentCountry, getGlobalPermissions } from '../../../selectors';
import { getLastModified, handleResponseError, prepareForGraphQL } from '../../../utilities/forms';
import useLanguageSelector from '../../../utils/useLanguageSelector';
import useFormatDateTime from '../../shared/useFormatDateTime';
import FormLayout from '../../ui/form/FormLayout';
import DealerSelection, { DealerOption, DealerOptions } from './DealerSelection';
import Form from './Form';
import Header from './Header';
import { FormData } from './context';
import {
    getData,
    GetDataQuery,
    GetDataQueryVariables,
    update,
    UpdateMutation,
    UpdateMutationVariables,
    updateDealerBookingPayment,
    UpdateDealerBookingPaymentMutation,
    UpdateDealerBookingPaymentMutationVariables,
    updateDealerEstablishment,
    UpdateDealerEstablishmentMutation,
    UpdateDealerEstablishmentMutationVariables,
    getDealerChannelById,
} from './data.graphql';

const Channels = () => {
    //  get the country ID
    const { id: countryId, channelSetting, coe, ppsr, version: countryVersion } = useSelector(getCurrentCountry);

    const languageSelector = useLanguageSelector();

    const [dealerId, setDealerId] = useState('');
    const [dealers, setDealers] = useState<DealerOption[]>([
        {
            label: 'All Dealer',
            value: DealerOptions.ALLDEALER,
        },
    ]);

    const dealer = useMemo(() => {
        return dealers.find(({ value }) => value === dealerId);
    }, [dealerId, dealers]);

    const allDealerIds = useMemo(() => {
        return dealers.filter(({ value }) => value !== DealerOptions.ALLDEALER).map(({ value }) => value);
    }, [dealers]);

    // get the dispatch method
    const dispatch = useDispatch();

    // get permissions
    const {
        mayManageNewCarChannel,
        mayManageUsedCarChannel,
        mayManageExpressChannel,
        mayManageEventChannel,
        mayViewNewCarChannel,
        mayViewUsedCarChannel,
        mayViewExpressChannel,
        mayViewEventChannel,
    } = useSelector(getGlobalPermissions);

    // from there get the variants and finance products
    const { data, loading } = useQuery<GetDataQuery, GetDataQueryVariables>(getData, {
        fetchPolicy: 'network-only',
        variables: { countryId },
    });

    const { ct } = useContentTranslation();
    const formatDateTime = useFormatDateTime();

    // get initial values as well as options
    const { initialValues, options } = useMemo(() => {
        const variants = data?.getVariantsByCountryId?.items || [];
        const products = data?.getFinanceProductsByCountryId?.items || [];
        const banks = data?.getBanksByCountryId?.items || [];

        const availableProductsByCountry = products.map(product => ({
            label: `${ct(product.name)} (${product.identifier})`,
            value: product.id,
        }));

        const availableBanksByDealer = banks.filter(bank => bank.dealerIds.includes(dealerId));

        const availableProductsByDealer = products
            .filter(product => product.dealerIds.includes(dealerId))
            .map(product => ({
                label: `${ct(product.name)} (${product.identifier})`,
                value: product.id,
            }));

        const iccFinanceProducts = products.filter(product =>
            dealerId === DealerOptions.ALLDEALER
                ? allDealerIds.every(id => product.iccDealerIds.includes(id))
                : product.iccDealerIds.includes(dealerId)
        );

        const expressFinanceProducts = products.filter(product => product.expressDealerIds.includes(dealerId));
        const finderFinanceProducts = products.filter(product => product.finderDealerIds.includes(dealerId));
        const csvFinanceProducts = products.filter(product =>
            dealerId === DealerOptions.ALLDEALER
                ? allDealerIds.every(id => product.csvDealerIds.includes(id))
                : product.csvDealerIds.includes(dealerId)
        );

        return {
            initialValues: {
                newChannel: {
                    variants: variants
                        .filter(variant => variant.channels.new.includes(dealerId))
                        .map(variant => variant.id),
                    bookingAmount: dealer?.bookingPayment?.new,
                    priceDisclaimer: channelSetting.new?.priceDisclaimer,
                    insurancePriceDisclaimer: channelSetting.new?.insurancePriceDisclaimer,
                    iccFinanceProducts: iccFinanceProducts.map(product => product.id),
                    dealerEstablishment: dealer?.establishment?.new,
                },
                usedChannel: {
                    variants: variants
                        .filter(variant => variant.channels.used.includes(dealerId))
                        .map(variant => variant.id),
                    bookingAmount: dealer?.bookingPayment?.used,
                },
                expressChannel: {
                    financeProducts: expressFinanceProducts.map(product => product.id),
                    bookingAmount: dealer?.bookingPayment?.express,
                    priceDisclaimer: channelSetting.express?.priceDisclaimer,
                    insurancePriceDisclaimer: channelSetting.express?.insurancePriceDisclaimer,
                    dealerEstablishment: dealer?.establishment?.express,
                },
                eventChannel: {
                    finderFinanceProducts: finderFinanceProducts.map(product => product.id),
                    configuratorFinanceProducts: products
                        .filter(product => product.allowOnConfigurator)
                        .map(product => product.id),
                    csvFinanceProducts: csvFinanceProducts.map(product => product.id),
                    bankName: finderFinanceProducts.length > 0 ? finderFinanceProducts[0].bank.name : '',
                },
                coe: coe?.amount,
                ppsr: ppsr?.amount,
                availableBanksByDealer,
            },
            options: {
                newVariants: variants
                    .filter(
                        variant =>
                            variant.assetCondition === AssetCondition.NEW ||
                            variant.assetCondition === AssetCondition.DEMO
                    )
                    .map(variant => ({ label: ct(variant.name), value: variant.id })),
                usedVariants: variants
                    .filter(variant => variant.assetCondition === AssetCondition.USED)
                    .map(variant => ({ label: ct(variant.name), value: variant.id })),
                expressFinanceProducts: availableProductsByDealer,
                finderFinanceProducts: availableProductsByDealer,
                configuratorFinanceProducts: availableProductsByCountry,
                iccFinanceProducts: availableProductsByCountry,
                csvFinanceProducts: availableProductsByCountry,
            },
        };
    }, [data, dealer, channelSetting, coe, ppsr, ct, dealerId, allDealerIds]);

    // fetch dealerinfo to get lastmodified
    const allDealers = dealerId === DealerOptions.ALLDEALER;
    const { data: selectedDealer } = useQuery(getDealerChannelById, {
        fetchPolicy: 'cache-first',
        variables: { id: dealerId },
        skip: isEmpty(dealerId) || allDealers,
    });

    const lastModified = useMemo(() => {
        if (selectedDealer) {
            return getLastModified(selectedDealer.dealer.version, formatDateTime);
        }

        return getLastModified(countryVersion, formatDateTime);
    }, [countryVersion, formatDateTime, selectedDealer]);

    // get apollo client
    const client = useApolloClient();

    // create the submit handler
    const onSubmit = useCallback(
        async (values: FormData) => {
            const id = dealerId === DealerOptions.ALLDEALER ? undefined : dealerId;

            const cleanData = prepareForGraphQL(
                omit(
                    [
                        'bankEstablishment',
                        'eventChannel.bankName',
                        'expressChannel.bankName',
                        'newChannel.bankName',
                        'newChannel.dealerEstablishment',
                        'expressChannel.dealerEstablishment',
                        'availableBanksByDealer',
                    ],
                    values
                )
            );

            await client
                .mutate<UpdateMutation, UpdateMutationVariables>({
                    mutation: update,
                    variables: { countryId, dealerId: id, data: cleanData },
                })
                .catch(handleResponseError);

            if (dealerId && dealerId !== DealerOptions.ALLDEALER) {
                const { newChannel, usedChannel, expressChannel, eventChannel } = values;
                const bookingPaymentData = {
                    bookingPayment: {
                        new: newChannel?.bookingAmount || null,
                        used: usedChannel?.bookingAmount || null,
                        express: expressChannel?.bookingAmount || null,
                    },
                };

                const establishmentData = {
                    establishment: {
                        new: newChannel?.dealerEstablishment || null,
                        used: usedChannel?.dealerEstablishment || null,
                        express: expressChannel?.dealerEstablishment || null,
                        event: eventChannel?.dealerEstablishment || null,
                    },
                };

                await Promise.all([
                    client
                        .mutate<UpdateDealerBookingPaymentMutation, UpdateDealerBookingPaymentMutationVariables>({
                            mutation: updateDealerBookingPayment,
                            variables: { dealerId, data: prepareForGraphQL(bookingPaymentData) },
                        })
                        .catch(handleResponseError),

                    client
                        .mutate<UpdateDealerEstablishmentMutation, UpdateDealerEstablishmentMutationVariables>({
                            mutation: updateDealerEstablishment,
                            variables: { dealerId, data: prepareForGraphQL(establishmentData) },
                        })
                        .catch(handleResponseError),
                ]);
            }
        },
        [client, countryId, dealerId]
    );

    // success handler
    const history = useHistory();
    const onSubmitSuccess = useCallback(() => {
        dispatch(addNotification('The Channel details has been updated.'));
        history.push('/home');
    }, [dispatch, history]);

    if (loading) {
        // not yet available
        return null;
    }

    return (
        <FormLayout
            bodyComponent={
                <Form
                    {...languageSelector}
                    dealerId={dealerId}
                    initialValues={initialValues}
                    lastModified={lastModified}
                    mayManageEventChannel={mayManageEventChannel}
                    mayManageExpressChannel={mayManageExpressChannel}
                    mayManageNewCarChannel={mayManageNewCarChannel}
                    mayManageUsedCarChannel={mayManageUsedCarChannel}
                    mayViewEventChannel={mayViewEventChannel}
                    mayViewExpressChannel={mayViewExpressChannel}
                    mayViewNewCarChannel={mayViewNewCarChannel}
                    mayViewUsedCarChannel={mayViewUsedCarChannel}
                    onSubmit={onSubmit}
                    onSubmitSuccess={onSubmitSuccess}
                    options={options}
                    enableReinitialize
                />
            }
            headerLeftComponent={<DealerSelection setDealers={setDealers} setOption={setDealerId} />}
            moreActions={[
                (mayManageNewCarChannel ||
                    mayManageUsedCarChannel ||
                    mayManageExpressChannel ||
                    mayManageEventChannel) && {
                    label: 'save',
                    onAction: () => dispatch(submit('channels')),
                },
            ].filter(Boolean)}
            title={<Header dealerId={dealerId} />}
        />
    );
};

export default Channels;
