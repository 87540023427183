import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCountry, getCompany } from '../../../../selectors';
import Page from './Page';
import defaultPermissions from './defaultPermissions';

const Creation = () => {
    const { id: countryId } = useSelector(getCurrentCountry);
    const { id: companyId } = useSelector(getCompany);

    const initialValues = {
        countryId,
        companyId,
        permissions: defaultPermissions,
    };

    return <Page initialValues={initialValues} />;
};

export default Creation;
