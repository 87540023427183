import { useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, orderBy } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FootBar, FootBarButton, FootContainer } from '../../../../containers/Layout';
import { useContentTranslation } from '../../../../i18n';
import { getCountryId } from '../../../../selectors';
import { searchOnKeys } from '../../../../utilities/fp';
import { List as UIList, cells, ListSearch } from '../../../ui/lists';
import usePaging from '../../../utilities/usePaging';
import { getCompanies } from './CompanyList.graphql';

const columns = formatPath => [
    { name: 'Company Code', id: 'code', renderCell: get('code'), hasSort: true },
    { name: 'Company Name', id: 'name', renderCell: get(formatPath('name')), hasSort: true, underline: true },
    { name: 'Active', id: 'isActive', renderCell: cells.renderActive(), hasSort: true },
];

const sortItems = (items, [sortKey, sortOrder]) => {
    // we always sort by value key
    return orderBy(sortKey, sortOrder, items);
};

const CompanyList = () => {
    const history = useHistory();
    const { formatPath } = useContentTranslation();

    const countryId = useSelector(getCountryId);
    const variables = { countryId };
    const { data, loading, error } = useQuery(getCompanies, { fetchPolicy: 'cache-and-network', variables });
    const items = data?.results?.items || [];
    const isLoading = loading && items.length <= 0;

    // searching
    const [search, setSearch] = useState('');
    const searchMethod = useMemo(() => searchOnKeys(['code', formatPath('name')], items), [items, formatPath]);
    const matchedItems = useMemo(() => searchMethod(search), [search, searchMethod]);

    // sorting
    const [sortedOn, onSort] = useState(['code', 'asc']);
    const sortedItems = useMemo(() => sortItems(matchedItems, sortedOn), [sortedOn, matchedItems]);

    // paging
    const [pagedItems, paging] = usePaging(sortedItems, { search });

    const footer = (
        <FootContainer>
            <FootBar>
                <FootBarButton onClick={() => history.push('/scope/companies/new')}>
                    <FontAwesomeIcon icon={faPlus} /> ADD Company
                </FootBarButton>
            </FootBar>
        </FootContainer>
    );

    return (
        <UIList
            columns={columns(formatPath)}
            error={!!error}
            footer={footer}
            headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
            items={pagedItems}
            loading={isLoading}
            onItemClick={item => history.push(`/scope/companies/${item.id}`)}
            onSort={onSort}
            paging={paging}
            sortedOn={sortedOn}
            title="Company"
        />
    );
};

export default CompanyList;
