import { useQuery } from '@apollo/client';
import { faDownload, faImage, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, flow, flatMap, map, uniqBy, sortBy, find, orderBy, uniq } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { exportInventories } from '../../../../actions';
import { FootBar, FootBarButton, FootContainer } from '../../../../containers/Layout';
import { useContentTranslation } from '../../../../i18n';
import { getCountryId, getGlobalPermissions } from '../../../../selectors';
import { searchOnKeys } from '../../../../utilities/fp';
import { withModal } from '../../../Modal';
import ImportInvalidModal from '../../../ui/ImportInvalidModal';
import { cells, List, ListSearch } from '../../../ui/lists';
import usePaging from '../../../utilities/usePaging';
import { ImportInventoryModal, DeleteInventoryModal } from '../imports';
import { getTables, uploadImages } from './InventoryList.graphql';
import useUploadImages from './useUploadImages';
import useVehicleTabs from './useVehiclesTabs';

const getGroupsFromInventory = flow([
    flatMap(flow([get('options'), map(get('group'))])),
    uniqBy(get('id')),
    sortBy(get('order')),
]);

const getOptionForGroup = groupId => flow([get('options'), find(option => get('group.id', option) === groupId)]);

const getItemKey = get('setId');

const useColumns = (groups, formatPath) =>
    useMemo(() => {
        return [
            { name: 'ID', id: 'identifier', renderCell: get('identifier'), hasSort: true, underline: true },
            {
                name: 'Variant Name',
                id: 'variant.name',
                renderCell: get(formatPath('variant.name')),
                hasSort: true,
            },
            ...groups.map(group => ({
                name: get(formatPath('name'), group),
                id: `groups.${group.id}.order`,
                renderCell: get(formatPath(`groups.${group.id}.name`)),
                hasSort: true,
            })),
            { name: 'VIN Assigned', id: 'vins', renderCell: get('vins'), hasSort: true },
            { name: 'Initial Qty', id: 'initialStock', renderCell: get('initialStock'), hasSort: true },
            { name: 'Remaining Qty', id: 'remainingStock', renderCell: get('remainingStock'), hasSort: true },
            { name: 'Reserved Qty', id: 'reservedStock', renderCell: get('reservedStock'), hasSort: true },
            { name: 'Deducted Qty', id: 'deductedStock', renderCell: get('deductedStock'), hasSort: true },
            { name: 'Active', id: 'isActive', renderCell: cells.renderActive(), hasSort: true },
        ];
    }, [groups, formatPath]);

const useComputedItems = inventories =>
    useMemo(() => {
        if (!inventories) {
            // no item nor groups
            return [[], []];
        }

        // first transform our inventory into items (one item = one inventory set)
        const inventorySets = inventories.flatMap(({ sets, ...inventory }) =>
            sets.map(item => {
                const identifier = `${inventory.identifier}.${item.identifier}`;

                return {
                    ...item,
                    ...inventory,
                    identifier,
                    inventoryIdentifier: inventory.identifier,
                    inventorySetIdentifier: item.identifier,
                };
            })
        );

        // then extract groups
        const groups = getGroupsFromInventory(inventorySets);

        // and compute data on items
        const items = inventorySets.map(item => ({
            ...item,
            groups: Object.fromEntries(groups.map(group => [group.id, getOptionForGroup(group.id)(item)])),
        }));

        return [items, groups];
    }, [inventories]);

const useSearch = (groups, items) =>
    useMemo(() => {
        return searchOnKeys(['identifier', 'variant.name', ...groups.map(group => `groups.${group.id}.name`)], items);
    }, [groups, items]);

const sortItems = (groups, items, [sortKey, sortOrder]) => {
    // we need first to sort by group
    const sortingKeys = [];
    const sortingOrders = [];

    // we always sort by group/option order

    if (sortKey !== 'identifier') {
        groups.forEach(group => {
            const groupSortKey = `groups.${group.id}.order`;
            sortingKeys.push(groupSortKey);
            sortingOrders.push(groupSortKey === sortKey ? sortOrder : 'asc');
        });
    }

    // a secondary sorting may be provide
    switch (sortKey) {
        case 'identifier':
            sortingKeys.push('inventoryIdentifier', 'inventorySetIdentifier');
            sortingOrders.push(sortOrder, sortOrder);
            break;

        case 'variant.name':
            sortingKeys.push(sortKey);
            sortingOrders.push(sortOrder);
            break;

        default:
            // do nothing then
            break;
    }

    // then execute the sorting
    return orderBy(sortingKeys, sortingOrders, items);
};

const InventoryList = ({ modal }) => {
    const tabs = useVehicleTabs();
    const history = useHistory();
    const { formatPath } = useContentTranslation();
    const { mayManageVehicles } = useSelector(getGlobalPermissions);
    const [showImport, setShowImport] = useState(false);

    const countryId = useSelector(getCountryId);
    const variables = { countryId };
    const { data, loading, error, refetch } = useQuery(getTables, { fetchPolicy: 'cache-and-network', variables });
    const [items, groups] = useComputedItems(data?.results?.items || []);
    const isLoading = loading && items.length <= 0;

    const columns = useColumns(groups, formatPath);
    const [errors, setErrors] = useState(null);

    const validVariantIds = useMemo(() => flow([map(i => i.variant.version.id), uniq])(items), [items]);

    // searching
    const searchMethod = useSearch(groups, items);
    const [search, setSearch] = useState('');
    const matchedItems = useMemo(() => searchMethod(search), [search, searchMethod]);

    // sorting
    const [sortedOn, onSort] = useState(['identifier', 'asc']);
    const sortedItems = useMemo(() => sortItems(groups, matchedItems, sortedOn), [groups, matchedItems, sortedOn]);

    // paging
    const [pagedItems, paging] = usePaging(sortedItems, { search });

    const dispatch = useDispatch();
    const exportExcel = useCallback(() => {
        return dispatch(exportInventories());
    }, [dispatch]);

    const onImportClose = useCallback(() => {
        setShowImport(false);
        refetch();
    }, [refetch]);

    const [showDelete, setShowDelete] = useState(false);

    const onDeleteClose = useCallback(() => {
        setShowDelete(false);
        refetch();
    }, [refetch]);

    const uploadImage = useUploadImages(
        setErrors,
        count => `${count} inventories  imported`,
        uploadImages,
        countryId,
        modal
    );

    const footer = (
        <FootContainer>
            <FootBar>
                {mayManageVehicles && (
                    <>
                        <FootBarButton onClick={() => setShowDelete(true)}>Delete Inventory</FootBarButton>
                        <FootBarButton onClick={() => history.push('/vehicle/inventories/new')}>
                            <FontAwesomeIcon icon={faPlus} /> ADD Inventory
                        </FootBarButton>
                        <FootBarButton onClick={exportExcel}>
                            <FontAwesomeIcon icon={faDownload} /> EXPORT EXCEL
                        </FootBarButton>
                        <FootBarButton onClick={() => setShowImport(true)}>
                            <FontAwesomeIcon icon={faUpload} /> IMPORT EXCEL
                        </FootBarButton>
                        <FootBarButton onClick={uploadImage}>
                            <FontAwesomeIcon icon={faImage} /> IMPORT IMAGES
                        </FootBarButton>
                    </>
                )}
            </FootBar>
        </FootContainer>
    );

    return (
        <>
            <List
                activeTab="inventory"
                columns={columns}
                error={error}
                footer={footer}
                getItemKey={getItemKey}
                headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
                items={pagedItems}
                loading={isLoading}
                onItemClick={item => history.push(`/vehicle/inventories/${item.id}/${item.setId}`)}
                onSort={onSort}
                paging={paging}
                sortedOn={sortedOn}
                tabs={tabs}
                title="Inventory"
            />
            {showImport && <ImportInventoryModal onClose={onImportClose} />}
            {showDelete && <DeleteInventoryModal onClose={onDeleteClose} validVariantIds={validVariantIds} />}
            {errors && <ImportInvalidModal errors={errors} onClose={() => setErrors(null)} />}
        </>
    );
};

InventoryList.propTypes = {
    modal: PropTypes.shape({
        confirm: PropTypes.func,
    }),
};

export default withModal(InventoryList);
