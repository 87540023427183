import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ErrorCard from '../ui/ErrorCard';
import ErrorMessage from './ErrorMessage';

class ContentErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = { error: null };
    }

    componentDidCatch(error) {
        // print it in console
        console.error(error);
        // update react state
        this.setState({ error });
        // also give it to sentry
        Sentry.captureException(error);
    }

    render() {
        const { error } = this.state;
        const { children } = this.props;

        if (error) {
            // render error view
            return (
                <ErrorCard>
                    <h1>
                        <FontAwesomeIcon icon={faExclamationTriangle} /> Something&apos;s wrong here...
                    </h1>
                    <ErrorMessage />
                </ErrorCard>
            );
        }

        return children;
    }
}

ContentErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ContentErrorBoundary;
