import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';

const Title = styled.h5`
    text-align: center;
`;

const Content = styled.div`
    text-align: center;
    color: #ccc;
`;

const UploadContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DownloadButton = styled.button`
    cursor: pointer;

    font-weight: bold;
    color: white;

    background-color: transparent;
    border-color: transparent;

    &:focus {
        outline: none;
    }
`;

const UploadButton = styled.label`
    font-weight: bold;
    color: #fff;

    outline: none;
    background: transparent;
    border: 1px rgba(255, 255, 255, 0.56) solid;
    padding: 5px 15px;
    margin-bottom: 0.5rem;

    cursor: pointer;
`;

const Input = styled.input`
    display: none;
`;

const UploadContent = ({ accept, type, onUpload, onDownloadClick, hasTemplateDownload }) => {
    const onChange = useCallback(
        event => {
            const file = event.target.files[0];

            if (file instanceof File) {
                onUpload(file);
            }
        },
        [onUpload]
    );

    return (
        <UploadContainer>
            <Title>Import {type}</Title>
            <Content>
                <p>
                    Please upload data with the provided template in .xlsx or .csv formats only. <br /> Note: Mandatory
                    columns are highlighted(*) and must be filled in.
                </p>
            </Content>
            <UploadButton>
                <Input accept={accept} onChange={onChange} type="file" />
                Upload File
            </UploadButton>
            {hasTemplateDownload && <DownloadButton onClick={onDownloadClick}>Download Template</DownloadButton>}
        </UploadContainer>
    );
};

UploadContent.propTypes = {
    accept: PropTypes.string,
    hasTemplateDownload: PropTypes.bool,
    onDownloadClick: PropTypes.func,
    onUpload: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};

export default UploadContent;
