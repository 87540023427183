import { HeadBar, HeadTitle } from '@appvantageasia/afc-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import useMedia from 'use-media';
import {
    AdminBgContainer,
    ContentBody,
    ScrollContainer,
    FootContainer,
    FootBar,
    FootBarButton,
} from '../../../../../containers/Layout';
import breakpoints from '../../../../../utilities/constants/breakpoints';
import ESCEventDetector from '../../../../common/ESCEventDetector';
import ActionButtonSelect from '../../../../ui/form/ActionButtonSelect';

const ignoreCheck = () => !!document.modalCount;

const ApplicationForm = ({ title, bodyComponent, onCancel, moreActions = [], onSubmit }) => {
    const isMobile = useMedia({ maxWidth: breakpoints.md });

    const actions = useMemo(() => {
        if (!moreActions) {
            // the user is not here yet
            return [];
        }

        // filter btn submit and disable true
        return moreActions.filter(item => item.label !== 'submit' && !item.disabled);
    }, [moreActions]);

    const handleChange = useCallback(option => option.onAction(), []);

    // for submit btn on off
    const submit = moreActions.some(item => item.label === 'submit' && !item.disabled);
    const hasMoreActions = moreActions.length > 0;

    const footer =
        hasMoreActions &&
        moreActions.map(item => (
            <FootBarButton key={item.label} disabled={item.disabled} onClick={item.onAction}>
                {item.icon && <FontAwesomeIcon icon={item.icon} />} {item.label}
            </FootBarButton>
        ));

    const mobileFooter = (
        <>
            <ActionButtonSelect
                isSearchable={false}
                onChange={handleChange}
                options={actions}
                placeholder="Action"
                value="Action"
                isTextCenter
            />
            {submit && <FootBarButton onClick={onSubmit}>submit</FootBarButton>}
        </>
    );

    return (
        <AdminBgContainer>
            <ESCEventDetector ignoreCheck={ignoreCheck} onEvent={onCancel} />
            <ScrollContainer>
                <HeadBar>
                    <HeadTitle>{title}</HeadTitle>
                </HeadBar>
                <ContentBody>{bodyComponent}</ContentBody>
            </ScrollContainer>
            <FootContainer>
                <FootBar>
                    {onCancel && <FootBarButton onClick={onCancel}>cancel</FootBarButton>}
                    <div style={{ flex: 1 }} />
                    {isMobile ? mobileFooter : footer}
                </FootBar>
            </FootContainer>
        </AdminBgContainer>
    );
};

ApplicationForm.propTypes = {
    bodyComponent: PropTypes.node,
    moreActions: PropTypes.arrayOf(
        PropTypes.shape({
            disabled: PropTypes.bool,
            icon: PropTypes.node,
            label: PropTypes.string,
            onAction: PropTypes.func,
        })
    ),
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    title: PropTypes.node.isRequired,
};

export default ApplicationForm;
