import styled, { css } from 'styled-components';
import ParameterValue from './ParameterValue';

const ParameterBox = styled.div`
    border: 1px solid #ced4da;
    color: ${props => props.theme.calculator.textColor};
    min-height: ${props => props.theme.calculator.boxSize};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding: 0 15px;
    overflow: hidden;
    box-sizing: border-box;

    ${props =>
        props.editable &&
        css`
            cursor: pointer;

            ${ParameterValue} {
                color: ${props.theme.calculator.valueColor};
            }
        `}

    ${props =>
        props.size === 2 &&
        !props.theme.calculator.singleColumn &&
        css`
            grid-column: 1 / 3;
        `}
    
    ${props =>
        props.height &&
        css`
            height: ${props.height};
        `}
`;

export default ParameterBox;
