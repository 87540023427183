import ComputingError from '../ComputingError';

const initializeValues = (context, values, errors) =>
    Object.entries(context.fields).reduce((nextValues, [name, field]) => {
        // get the current value
        const currentValue = nextValues[name];

        if (currentValue === undefined) {
            let newValue;

            // we need to try initialize it
            try {
                // get the new value
                newValue = field.getInitialValue(nextValues, context);
                // delete any error for that key
                // eslint-disable-next-line no-param-reassign
                delete errors[name];
            } catch (error) {
                if (error instanceof ComputingError) {
                    // set the error
                    // eslint-disable-next-line no-param-reassign
                    errors[name] = error.message;
                    // get the value from the error instance
                    newValue = error.value;
                } else {
                    // rethrow the error
                    throw error;
                }
            }

            if (newValue !== undefined) {
                // we did initialize it
                return { ...nextValues, [name]: newValue };
            }
        }

        return nextValues;
    }, values);

export default initializeValues;
