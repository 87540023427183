import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { DepositMode, Unit } from '../../../../../schema';
import { depositTypeOptions, yesNoOptions } from '../../../../../shared/constants/options';
import FieldContainer from '../../../../template/Field-container';
import SwitchField from '../../../../template/Switch';
import DepositRange from './DepositRange';
import { useFormContext } from './context';
import useDefaultValues from './useDefaultValues';

const getDepositFields = mode => {
    switch (mode) {
        case DepositMode.RANGE:
            return <DepositRange />;

        default:
            return null;
    }
};

const Deposit = () => {
    const { disabled, values, change } = useFormContext();

    const mode = get('depositSetting.mode', values);
    const show = get('depositSetting.show', values);
    const min = get('depositSetting.min', values);
    const max = get('depositSetting.max', values);
    const editable = show && (mode !== DepositMode.RANGE || min !== max);

    const showEditable = mode !== DepositMode.TABLE;

    useDefaultValues('depositSetting', {
        min: 0,
        max: 0,
        step: 0,
        default: 0,
        defaultUnit: Unit.PERCENTAGE,
        show: false,
        editable: false,
    });

    useEffect(() => {
        if (!show) {
            change('depositSetting.editable', false);
        }
    }, [change, show]);

    useEffect(() => {
        if (!depositTypeOptions.some(item => item.value === mode)) {
            change('depositSetting.mode', depositTypeOptions[0].value);
        }
    }, [mode, change]);

    useEffect(() => {
        if (!showEditable) {
            change('depositSetting.editable', false);
        }
    }, [showEditable, change]);

    useEffect(() => {
        if (mode === DepositMode.TABLE) {
            change('depositSetting.defaultUnit', Unit.CURRENCY);
        } else {
            change('depositSetting.defaultUnit', Unit.PERCENTAGE);
        }
    }, [mode, change]);

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Type">
                            <Field
                                component={SwitchField}
                                disabled={disabled}
                                name="depositSetting.mode"
                                options={depositTypeOptions}
                            />
                        </FieldContainer>
                    </div>
                </div>
                {getDepositFields(mode)}
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Show">
                            <Field
                                component={SwitchField}
                                disabled={disabled}
                                name="depositSetting.show"
                                options={yesNoOptions}
                            />
                        </FieldContainer>
                    </div>
                    {showEditable && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Editable">
                                <Field
                                    component={SwitchField}
                                    disabled={!editable || disabled}
                                    name="depositSetting.editable"
                                    options={yesNoOptions}
                                />
                            </FieldContainer>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Deposit;
