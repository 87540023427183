import styled from 'styled-components';
import breakpoints from '../../utilities/constants/breakpoints';

export const SystemContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding-left: 50px;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @font-face {
        font-family: 'Company Font';
        src: url(${props => props.theme.themeFont});
    }

    @media (max-width: ${breakpoints.sm}) {
        padding-left: 0;
    }
`;

export const Content = styled.div`
    overflow: auto;
    flex-grow: 1;
    background: white;
`;

export const BodyContainer = styled.div`
    height: 100%;
    padding-left: ${props => (props.fixedMenu ? 200 : 0)}px;
    transition: all 0.5s ease;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    @media (max-width: ${breakpoints.sm}) {
        padding-left: 0;
    }
`;
