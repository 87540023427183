import { findIndex } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Field } from 'redux-form';
import { DocumentBg, DocumentGrid } from '../../../../../ui/Document';
import { Tips } from '../../../../../ui/form/File';
import Preview from './Preview';
import Upload from './Upload';

const Gallery = ({ disabled, images, change, fieldName, hasTip = true }) => {
    const moveImage = useCallback(
        (draggedItem, droppedItem) => {
            // get drop index
            const droppedIndex = findIndex(droppedItem, images);

            // can not use draged Item, because can not get File Object from JSON.parse,
            // so we need to get drag item from image
            const originDraggedItem = images.find(i => (i.id ? i.id === draggedItem.id : i.uiId === draggedItem.uiId));
            // remove drag item, then add it to drop postion
            const newImages = images.filter(i => (i.id ? i.id !== draggedItem.id : i.uiId !== draggedItem.uiId));
            newImages.splice(droppedIndex, 0, originDraggedItem);

            change(fieldName, newImages);
        },
        [change, fieldName, images]
    );

    return (
        <DocumentBg>
            <DocumentGrid>
                <DndProvider backend={HTML5Backend}>
                    {images.map(
                        (item, index) =>
                            item && (
                                <Preview
                                    key={item.uiId || item.id}
                                    change={change}
                                    disabled={disabled}
                                    fieldName={fieldName}
                                    image={item}
                                    images={images}
                                    index={index}
                                    moveImage={moveImage}
                                />
                            )
                    )}
                </DndProvider>
                {!disabled && <Field component={Upload} disabled={disabled} name="images" />}
            </DocumentGrid>
            {hasTip && <Tips>Size 1000x600px in jpg, png or svg</Tips>}
        </DocumentBg>
    );
};

Gallery.propTypes = {
    change: PropTypes.func,
    disabled: PropTypes.bool,
    fieldName: PropTypes.string,
    hasTip: PropTypes.bool,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            file: PropTypes.instanceOf(File),
            id: PropTypes.string,
            uiId: PropTypes.string,
            url: PropTypes.string,
        })
    ),
};

export default Gallery;
