import styled from 'styled-components';

const WhiteCard = styled.div`
    background: white;
    color: #666666;
    height: auto;
    overflow: unset;
    padding: 50px;
    margin-bottom: 20px;
`;

export default WhiteCard;
