import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { reduxForm } from 'redux-form';
import { FormTabContainer } from '../../../containers/Layout';
import Channel from './Channel';
import { DealerOptions } from './DealerSelection';
import Languages from './Languages';
import { Context, FormContextData, FormData, FormProps } from './context';

const Form = (props: FormContextData) => {
    const { dealerId, languageOptions } = props;
    const context = { ...props };
    const isSelectedAllDealer = dealerId === DealerOptions.ALLDEALER;

    const displayMultiLanguage = languageOptions.length > 1 && !isSelectedAllDealer;

    return (
        <Context.Provider value={context}>
            <FormTabContainer>
                <Tabs forceRenderTabPanel>
                    <TabList style={{ marginRight: 180 }}>
                        <Tab>Main Details</Tab>
                        {displayMultiLanguage && <Tab>Multi-Language</Tab>}
                    </TabList>
                    <TabPanel>
                        <Channel />
                    </TabPanel>
                    {displayMultiLanguage && (
                        <TabPanel>
                            <Languages />
                        </TabPanel>
                    )}
                </Tabs>
            </FormTabContainer>
        </Context.Provider>
    );
};

export default reduxForm<FormData, FormProps>({ form: 'channels' })(Form);
