import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { yesNoOptions } from '../../../../../shared/constants/options';
import FieldContainer from '../../../../template/Field-container';
import NumberField from '../../../../template/Number';
import SwitchField from '../../../../template/Switch';
import { useFormContext } from './context';

const Commission = () => {
    const { disabled, values, change } = useFormContext();

    const show = get('commissionSetting.show', values);
    const editable = get('commissionSetting.editable', values);

    useEffect(() => {
        if (show === undefined) {
            change('commissionSetting.show', false);
        }
        if (editable === undefined) {
            change('commissionSetting.editable', false);
        }
    }, [show, change, editable]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Commission (%)*">
                        <Field
                            component={NumberField}
                            disabled={disabled}
                            min={0}
                            name="commissionSetting.default"
                            precision={2}
                        />
                    </FieldContainer>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Show">
                        <Field
                            component={SwitchField}
                            disabled={disabled}
                            name="commissionSetting.show"
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Editable">
                        <Field
                            component={SwitchField}
                            disabled={!show || disabled}
                            name="commissionSetting.editable"
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                </div>
            </div>
        </div>
    );
};

export default Commission;
