import styled from 'styled-components';

const Emphasis = styled.a`
    color: ${props => props.theme.themeHighlightColour};
    border-bottom: 1px solid ${props => props.theme.themeHighlightColour};

    :hover {
        color: ${props => props.theme.themeHighlightColour};
        text-decoration: none;
    }
`;

export default Emphasis;
