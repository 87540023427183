import { get } from 'lodash/fp';
import React, { useRef, useMemo, useEffect } from 'react';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { assetConditionOptions, onOffOptions, engineTypeOptions } from '../../../../../shared/constants/options';
import MarkdownField from '../../../../shared/form/MarkdownField';
import TextareaField from '../../../../shared/form/TextareaField';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import NumberField from '../../../../template/Number';
import ApvPortlet from '../../../../template/Portlet';
import SwitchField from '../../../../template/Switch';
import { useFormContext } from './context';

const MainDetails = () => {
    const { disabled, values, models, subModels, change } = useFormContext();
    const { formatPath } = useContentTranslation();

    const modelId = get('modelId', values);

    const filteredSubModels = useMemo(() => {
        return subModels.filter(subModel => subModel.modelId === modelId);
    }, [modelId, subModels]);

    const previousModelIdRef = useRef(modelId);
    useEffect(() => {
        if (modelId !== previousModelIdRef.current) {
            if (filteredSubModels.length !== 1) {
                change('subModelId', null);
            } else {
                change('subModelId', filteredSubModels[0].value);
            }

            previousModelIdRef.current = modelId;
        }
    }, [modelId, change, filteredSubModels]);

    const edition = !!values?.lastModified;

    return (
        <ApvPortlet name="Main Details" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8 col-sm-12 col-xs-12">
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <FieldContainer label="Variant Name">
                                    <Field component={InputField} disabled={disabled} name={formatPath('name')} />
                                </FieldContainer>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <FieldContainer label="Vehicle ID">
                                    <Field component={InputField} disabled={disabled} name="identifier" />
                                </FieldContainer>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <DropdownField
                                    disabled={disabled}
                                    label="Model"
                                    name="modelId"
                                    options={models}
                                    placeholder="Select Model"
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <DropdownField
                                    disabled={disabled || !filteredSubModels.length}
                                    field="mainParameter.subModel"
                                    label="Submodel"
                                    name="subModelId"
                                    options={filteredSubModels}
                                    placeholder="Select Submodel"
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <FieldContainer label="Seating Capacity">
                                    <Field
                                        component={NumberField}
                                        disabled={disabled}
                                        name="seatingCapacity"
                                        precision={0}
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <FieldContainer label="Asset Condition">
                                    <Field
                                        component={SwitchField}
                                        disabled={disabled}
                                        name="assetCondition"
                                        options={assetConditionOptions}
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <FieldContainer label="Year Of Manufacture">
                                    <Field component={NumberField} disabled={disabled} name="year" precision={0} />
                                </FieldContainer>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <FieldContainer label="Engine Type">
                                    <Field
                                        component={SwitchField}
                                        disabled={disabled}
                                        name="engineType"
                                        options={engineTypeOptions}
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <FieldContainer label="Order No.">
                                    <Field component={NumberField} disabled={disabled} name="order" precision={0} />
                                </FieldContainer>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Description">
                            <TextareaField disabled={disabled} name={formatPath('description')} />
                        </FieldContainer>
                        <FieldContainer label="Features">
                            <MarkdownField autoFocus={false} disabled={disabled} name={formatPath('features')} />
                        </FieldContainer>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Active">
                            <Field component={SwitchField} disabled={disabled} name="isActive" options={onOffOptions} />
                        </FieldContainer>
                    </div>
                    {edition && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Last Modified">
                                <Field component={InputField} name="lastModified" disabled />
                            </FieldContainer>
                        </div>
                    )}
                </div>
            </div>
        </ApvPortlet>
    );
};

export default MainDetails;
