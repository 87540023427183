import PropTypes from 'prop-types';
import React, { useCallback, createContext, useContext, useState, useMemo, useEffect } from 'react';
import BlockCreateableSelect from '../ui/form/BlockCreateableSelect';
import DropdownField from './Dropdown';

const LocationContext = createContext(null);

export const LocationManager = ({ children, initLocation }) => {
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        setLocations(initLocation);
    }, [initLocation, setLocations]);

    const add = useCallback(
        location =>
            setLocations(state => {
                if (state.find(item => item.value === location.value)) {
                    return state;
                }

                return [...state, location];
            }),
        [setLocations]
    );

    const context = useMemo(() => ({ locations, add }), [add, locations]);

    return <LocationContext.Provider value={context}>{children}</LocationContext.Provider>;
};

LocationManager.propTypes = {
    children: PropTypes.node.isRequired,
    initLocation: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })
    ),
};

const LocationField = props => {
    const { locations, add } = useContext(LocationContext);

    const onChangeEvent = useCallback(value => add({ value, label: value }), [add]);

    return (
        <DropdownField
            {...props}
            onChangeEvent={onChangeEvent}
            options={locations}
            selectComponent={BlockCreateableSelect}
        />
    );
};

LocationField.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
};

export default LocationField;
