import { faInfoCircle, faExpandArrowsAlt, faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { IndicationIcon, FullScreenIcon, FullScreenExitIcon } from '../../ui/dashboard/ChartContainer';
import DayChartBlock from '../../ui/dashboard/DayChartBlock';
import FullScreenModal, { Title } from '../../ui/dashboard/FullScreenModal';
import Tooltip from '../../ui/tooltip/Tooltip';
import useTooltip from '../../ui/tooltip/useTooltip';
import { GetApplicationChartQuery } from './ApplicationChart.graphql';
import { GetDepositPaymentPerDayQuery } from './DepositPaymentsPerDay.graphql';

type ApplicationChartProps = {
    title: string;
    data: GetDepositPaymentPerDayQuery | GetApplicationChartQuery | undefined;
    id: string;
    tooltip?: string;
    xAxisTitle?: string;
    yAxisTitle?: string;
    getAmount?: (item: unknown) => number;
    useCustomLabelTemplateAdapter?: boolean;
};

const DayChart = ({ title, tooltip, ...props }: ApplicationChartProps) => {
    const { id } = props;
    const valueRef = useRef(null);
    const tooltipRef = useTooltip(valueRef);

    const [showFullScreen, setShowFullScreen] = useState(false);

    return (
        <section className="card" order-id="card-10">
            <div className="card-header">
                <h5 className="mb-0 mr-3 d-inline-block text-black">
                    <strong>{title}</strong>
                </h5>
                {tooltip && (
                    <IndicationIcon ref={valueRef}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </IndicationIcon>
                )}
                <FullScreenIcon onClick={() => setShowFullScreen(true)}>
                    <span>Fullscreen</span>
                    <FontAwesomeIcon icon={faExpandArrowsAlt} />
                </FullScreenIcon>
                <Tooltip ref={tooltipRef}>{tooltip}</Tooltip>
            </div>
            <div className="card-body dayChart">
                <DayChartBlock {...props} />
            </div>
            {showFullScreen && (
                <FullScreenModal onRequestClose={() => setShowFullScreen(false)} isOpen>
                    <div>
                        <FullScreenExitIcon onClick={() => setShowFullScreen(false)}>
                            <FontAwesomeIcon icon={faCompressArrowsAlt} />
                        </FullScreenExitIcon>
                        <Title>{title}</Title>
                        <DayChartBlock {...props} id={`${id}FullScreen`} />
                    </div>
                </FullScreenModal>
            )}
        </section>
    );
};

export default DayChart;
