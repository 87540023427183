import { faImage } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isObject, get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { PreviewImage } from '../../ui/form/File';

const ImagePreview = ({ file }) => {
    const previousFileRef = useRef(null);

    const [previewUrl, setPreviewUrl] = useState(null);

    const hasPreview = file instanceof File || isObject(file);

    useEffect(() => {
        if (previousFileRef.current === file) {
            return;
        }

        const url = get('url', file);

        if (file instanceof File) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setPreviewUrl(reader.result), false);
            reader.readAsDataURL(file);
        } else if (url) {
            setPreviewUrl(url);
        }
    }, [file]);

    if (!hasPreview) {
        return <FontAwesomeIcon icon={faImage} />;
    }

    return <PreviewImage alt="" src={previewUrl} />;
};

ImagePreview.propTypes = {
    file: PropTypes.oneOfType([PropTypes.instanceOf(File), PropTypes.shape({})]),
};

export default ImagePreview;
