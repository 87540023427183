// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React from 'react';
import { useContentTranslation } from '../../../../../../../i18n';
import { ownerIdOptions, yesNoOptions } from '../../../../../../../shared/constants/options';
import DatePickerField from '../../../../../../shared/form-v2/DatePickerField';
import NumberField from '../../../../../../shared/form-v2/NumberField';
import SelectField from '../../../../../../shared/form-v2/SelectField';
import TextField from '../../../../../../shared/form-v2/TextField';

const Express = () => {
    const { formatPath } = useContentTranslation();

    return (
        <Portlet name="Vehicle of Interest" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Make" name={formatPath('variant.model.make.name')} disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Model" name={formatPath('variant.model.name')} disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Vehicle Number" name="variant.preOwnedCarDetails.plateNumber" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Asset Condition" name="display.assetCondition" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Displacement" name="display.displacement" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DatePickerField
                            label="Original Registration Date"
                            name="variant.preOwnedCarDetails.originalRegistrationDate"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DatePickerField
                            label="COE Expiry Date"
                            name="variant.preOwnedCarDetails.coeExpiryDate"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField
                            label="QP Paid"
                            name="variant.preOwnedCarDetails.qpPaid"
                            type="currency"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField
                            label="Open Market Value"
                            name="variant.preOwnedCarDetails.omv"
                            type="currency"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label="Owner ID Type"
                            name="variant.preOwnedCarDetails.ownerIdType"
                            options={ownerIdOptions}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label="Owner ID (Last 4 characters)"
                            maxLength="4"
                            name="variant.preOwnedCarDetails.ownerIdSuffix"
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label="Goods have been inspected by Dealer"
                            name="variant.preOwnedCarDetails.inspected"
                            options={yesNoOptions}
                            disabled
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField label="Starting Price" name="calculator.price" type="currency" disabled />
                    </div>
                </div>
            </div>
        </Portlet>
    );
};

export default Express;
