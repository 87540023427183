import { useQuery, WatchQueryFetchPolicy, ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Channel } from '../../../../../schema';
import {
    getFinanceProducts,
    GetFinanceProductsQuery,
    FinanceDataFragment,
    GetFinanceProductsQueryVariables,
} from './useFinanceProducts.graphql';

export type FinanceProduct = FinanceDataFragment;

export const getFinanceProductFromState = (
    client: ApolloClient<NormalizedCacheObject>,
    dealerId: string,
    channel: Channel,
    eventId?: string
): FinanceProduct[] | null =>
    client.readQuery<GetFinanceProductsQuery>({
        query: getFinanceProducts,
        variables: { dealerId, channel, eventId },
    })?.financeProducts || [];

const useFinanceProducts = (
    fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
    dealerId: string,
    channel: Channel,
    eventId?: string
): { loading: boolean; financeProducts: FinanceProduct[] } => {
    // get data using apollo
    const variables = { dealerId, channel, eventId };
    const { data, loading } = useQuery<GetFinanceProductsQuery, GetFinanceProductsQueryVariables>(getFinanceProducts, {
        variables,
        fetchPolicy,
    });

    return { loading, financeProducts: data?.financeProducts || [] };
};

export default useFinanceProducts;
