// @ts-ignore
import { useApolloClient } from '@apollo/client';
import React, { useState, useCallback, useMemo, ReactElement, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ReduxFormContext } from 'redux-form';
import { addNotification } from '../../../../../actions';
import { withLoading } from '../../../../../actions/loading';
import { APPLICATION_ADD_GUARANTOR_NOTIFICATION } from '../../../../../shared/constants/notification';
import useValidationContext from '../../../../utilities/useValidationContext';
import { GetApplicationQuery } from '../../common/data/Application.graphql';
import {
    createCustomer,
    CreateCustomerMutation,
    CreateCustomerMutationVariables,
} from '../../common/shared/customer.graphql';
import { mapCreateCustomerToPayload } from '../../common/utilities/map';
import toPayload from '../../common/utilities/toPayload';
import AddGuarantorFormModal from './AddGuarantorFormModal';
import { update } from './Page.graphql';

type ChildrenProps = {
    application: GetApplicationQuery['application'];
    addGuarantor: () => void;
    canAddGuarantor: boolean;
};

export type AddGuarantorProviderProps = {
    application: GetApplicationQuery['application'];
    children: (props: ChildrenProps) => ReactElement;
    backUrl: string;
};

const AddGuarantorProvider = ({ application, children, backUrl }: AddGuarantorProviderProps) => {
    const client = useApolloClient();
    const [open, setOpen] = useState(false);

    // get initial values
    // @ts-ignore
    const { initialValues } = useContext(ReduxFormContext);

    const { guarantor, zoneId, appliedForFinancing, bank } = initialValues;
    const isGuarantorIncluded = bank.isGuarantorIncluded || false;
    // show guarantor modal
    const addGuarantor = useCallback(() => setOpen(true), [setOpen]);
    // hide guarantor modal
    const onClose = useCallback(() => setOpen(false), []);
    // prepare guarantor context
    const context = useMemo(
        () => ({
            application,
            addGuarantor,
            // check if there's guarantor tied to the application
            canAddGuarantor: isGuarantorIncluded && appliedForFinancing && !guarantor?.id,
        }),
        [application, addGuarantor, isGuarantorIncluded, appliedForFinancing, guarantor]
    );

    const history = useHistory();
    const dispatch = useDispatch();

    const validation = useValidationContext();

    const onSubmit = useCallback(
        async values => {
            // prepare guarantor payload
            const guarantorPayload = mapCreateCustomerToPayload(zoneId, {
                withMyInfo: false,
                ...values,
            });

            // create guarantor
            const customerPromise = client.mutate<CreateCustomerMutation, CreateCustomerMutationVariables>({
                mutation: createCustomer,
                variables: guarantorPayload,
            });

            dispatch(withLoading(customerPromise));

            const response = await customerPromise;

            const guarantor = response?.data?.customer;

            if (!guarantor) {
                throw new Error('Failed to create guarantor');
            }

            // update application form
            const applicationPayload = toPayload({ ...initialValues, guarantor }, true, null);

            const promise = client.mutate({ mutation: update, variables: applicationPayload });

            dispatch(withLoading(promise));

            await promise;

            // display the notification
            dispatch(addNotification(APPLICATION_ADD_GUARANTOR_NOTIFICATION));

            // then close modal
            onClose();

            // then go back to application list
            // @ts-ignore
            history.push(backUrl);
        },
        [backUrl, client, dispatch, history, initialValues, onClose, zoneId]
    );

    return (
        <>
            {children(context)}
            {open && <AddGuarantorFormModal onClose={onClose} onSubmit={onSubmit} validation={validation} />}
        </>
    );
};

export default AddGuarantorProvider;
