import { get, isEmpty } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { cells, ListTable } from '../../../../ui/lists';
import ConsentListContainer from '../../../Consent/ConsentContainer';
import { useFormContext } from './context';

const useColumns = () =>
    useMemo(
        () => [
            {
                id: 'name',
                name: 'Name / Description',
                renderCell: item => get('name.en', item) || get('description.en', item),
                underline: true,
            },
            {
                id: 'hasCheckbox',
                name: 'Checkbox',
                renderCell: cells.renderActive('hasCheckbox'),
                textAlignCenter: true,
            },
            {
                id: 'isMandatory',
                name: 'Mandatory',
                renderCell: cells.renderActive('isMandatory'),
                textAlignCenter: true,
            },
            {
                id: 'isActive',
                name: 'Active',
                renderCell: cells.renderActive(),
                textAlignCenter: true,
            },
        ],
        []
    );

const ConsentList = () => {
    const { values } = useFormContext();
    const history = useHistory();

    const { consentsAndDeclarations } = values;

    const columns = useColumns(consentsAndDeclarations);

    if (isEmpty(consentsAndDeclarations)) {
        return null;
    }

    return (
        <div className="container-fluid">
            <br />
            <ConsentListContainer>
                <ListTable
                    columns={columns}
                    items={consentsAndDeclarations}
                    onItemClick={item => history.push(`/consent/${item.id}`)}
                />
            </ConsentListContainer>
        </div>
    );
};

export default ConsentList;
