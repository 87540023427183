import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getFormError } from 'redux-form';

const FormError = ({ form, children, externalError = null }) => {
    const error = useSelector(getFormError(form)) || externalError;

    return error ? children(error) : null;
};

FormError.propTypes = {
    children: PropTypes.func.isRequired,
    externalError: PropTypes.string,
    form: PropTypes.string.isRequired,
};

export default FormError;
