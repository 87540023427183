import { useQuery } from '@apollo/client';
import { omit } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useContentTranslation } from '../../../../i18n';
import { toFormState } from '../../../../utilities/applications/toFormState';

import { useCompanyFormatting } from '../../../../utils/withCompanyFormatting';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import {
    getAppointmentByVersionId,
    GetAppointmentByVersionIdQuery,
    GetAppointmentByVersionIdQueryVariables,
} from './Edition.graphql';
import AppointmentPage from './Page';

const AppointmentEdition = () => {
    const { versionId } = useParams<{ versionId: string }>();

    const { data, loading } = useQuery<GetAppointmentByVersionIdQuery, GetAppointmentByVersionIdQueryVariables>(
        getAppointmentByVersionId,
        {
            fetchPolicy: 'no-cache',
            variables: { versionId },
        }
    );

    const formats = useCompanyFormatting();
    const translation = useContentTranslation();
    const formatDateTime = useFormatDateTime();

    const initialValues = useMemo(() => {
        if (!data?.appointment) {
            return null;
        }

        const appointmentData = omit(['latestApplication'], data.appointment);

        return {
            ...appointmentData,
            latestApplication: toFormState(data.appointment.latestApplication, formats, formatDateTime, translation),
        };
    }, [data, formatDateTime, formats, translation]);

    if (!loading && !initialValues) {
        return <Redirect to="/appointment" />;
    }

    return <AppointmentPage initialValues={initialValues} />;
};

export default AppointmentEdition;
