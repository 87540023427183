import { useQuery, WatchQueryFetchPolicy, ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Channel } from '../../../../../schema';
import { getVariants, GetVariantsQuery, VariantDataFragment } from './useLoadVariants.graphql';

export type Variant = VariantDataFragment;

export const getVariantsFromState = (
    client: ApolloClient<NormalizedCacheObject>,
    zoneId: string,
    channel: string
): Variant[] | null =>
    client.readQuery<GetVariantsQuery>({
        query: getVariants,
        variables: { zoneId, channel },
    })?.variants || [];

const useLoadVariants = (
    fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
    zoneId: string,
    channel: Channel
): Variant[] => {
    // get country id
    // get data using apollo
    const variables = { zoneId, channel };
    const { data } = useQuery<GetVariantsQuery>(getVariants, { variables, fetchPolicy });

    return data?.variants || [];
};

export default useLoadVariants;
