import { get, toNumber } from 'lodash/fp';
import React, { useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getFormValues, ReduxFormContext } from 'redux-form';
import CurrencyField from '../../../shared/form-v2/CurrencyField';
import NumberField from '../../../shared/form-v2/NumberField';
import TextField from '../../../shared/form-v2/TextField';
import CPFContributions from '../../../shared/partialForms/CPFContributions';
import SubTitle from '../../../ui/SubTitle';
import { ApplicationFormValues } from '../../Workflow/Finance/application/Form/context';
import { ApplicationCustomerDataFragment } from '../../Workflow/common/data/Application.graphql';
import { getLabelWithFlag } from './CustomerDetails';
import { useFormContext } from './context';

const OccupationDetails = () => {
    const { sectionPrefix, form } = useContext(ReduxFormContext);
    const { currency } = useFormContext();

    // get form values
    const valueSelector = useCallback(state => getFormValues(form)(state), [form]);
    const formValues = useSelector(valueSelector) as ApplicationFormValues & ApplicationCustomerDataFragment;

    // get the employment section
    const cpfContributions = get(
        [sectionPrefix, 'details', 'employment', 'cpfContributions'].filter(Boolean) as string[],
        formValues
    );

    const employment = get(sectionPrefix ? `${sectionPrefix}.details.employment` : 'details.employment', formValues);

    return (
        <>
            <SubTitle>Occupation Details</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag('Employer', employment?.employer?.source)}
                        name="details.employment.employer.value"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag('Occupation', employment?.occupation?.source)}
                        name="details.employment.occupation.value"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <CurrencyField
                        label={getLabelWithFlag('Assessable Income', employment?.assessableIncome?.source)}
                        name="details.employment.assessableIncome.value"
                        prefix={currency || '$'}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <NumberField
                        label={getLabelWithFlag('Year Of Notice Of Assessment', employment?.assessmentYear?.source)}
                        maxLength="4"
                        name="details.employment.assessmentYear.value"
                        normalize={toNumber}
                        type="year"
                        disabled
                    />
                </div>
            </div>
            <CPFContributions history={cpfContributions} />
        </>
    );
};

export default OccupationDetails;
