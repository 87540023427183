import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { SubmissionError } from 'redux-form';
import { setAuthorization, setUser, setCompanyCode, setCountryCode, setZoneCode } from '../../../actions';
import { LoginType } from '../../../schema';
import { getCompanyIdentifier } from '../../../selectors';
import { handleResponseError } from '../../../utilities/forms';
import MultipleSessionModal from '../../shared/MultipleSessionModal';
import CredentialForm from './CredentialForm';
import { authenticate } from './api.graphql';
import getUserWithPermissions from './getUserWithPermissions';

const usePostLoginAction = () => {
    const history = useHistory();
    const location = useLocation();

    return useMemo(() => {
        // look for an action to execute right after login
        const { actionType } = qs.parse(location.search, { ignoreQueryPrefix: true });

        switch (actionType) {
            default:
                // by default redirect to home page
                return () => history.push('/home');
        }
    }, [history, location]);
};

const CredentialStep = ({ setContext }) => {
    const [authenticationResponse, setAuthenticationResponse] = useState(null);
    const client = useApolloClient();
    // we need to be able to dispatch actions to redux
    const dispatch = useDispatch();

    // get current company identifier (there may be none)
    const { companyId, countryId, zoneId } = useSelector(getCompanyIdentifier);

    // if those values are available, it means we are going for a direct login
    const directLogin = companyId && countryId && zoneId;

    // the callback to execute on submissions
    const onSubmit = useCallback(
        async ({ username, password }) => {
            const variables = { username, password, countryId, zoneId, loginType: LoginType.ADMIN };

            return client
                .mutate({ mutation: authenticate, variables })
                .then(({ data: { authorization } }) => {
                    if (authorization.ok) {
                        const { user } = authorization;

                        // first skip everything if it is super user
                        if (user.isSuperUser) {
                            return { ...authorization, username, password };
                        }

                        const modifiedUser = getUserWithPermissions(user);
                        const response = { ...authorization, user: modifiedUser, username, password };

                        // if it is direct login, check the user has country login permission
                        if (directLogin) {
                            if (
                                modifiedUser.availableCompanies.some(company =>
                                    company.countries.some(country => country.id === countryId)
                                )
                            ) {
                                return response;
                            }

                            return Promise.reject(
                                new SubmissionError({ _error: 'Please check your User Name and Password.' })
                            );
                        }

                        // if the user has at least one company with login permission
                        if (modifiedUser.availableCompanies.length > 0) {
                            return response;
                        }
                    }

                    return Promise.reject(
                        new SubmissionError({
                            _error: authorization.errors.map(({ message }) => message).join(' '),
                        })
                    );
                })
                .catch(handleResponseError);
        },
        [countryId, zoneId, client, directLogin]
    );

    // we may have post action on login
    const postAction = usePostLoginAction();

    const login = useCallback(
        async ({ token, user, username, password }) => {
            const availableCompanies = user?.availableCompanies || [];
            const countries = availableCompanies[0]?.countries || [];
            const zones = countries[0]?.zones || [];

            // if there is only one company, one country and one zone, skip locale selection
            if (availableCompanies.length === 1 && countries.length === 1 && zones.length === 1) {
                dispatch(setUser(user));
                dispatch(setAuthorization(token));

                if (!directLogin) {
                    client
                        .mutate({
                            mutation: authenticate,
                            variables: {
                                username,
                                password,
                                countryId: countries[0].id,
                                zoneId: zones[0].id,
                                loginType: LoginType.ADMIN,
                            },
                        })
                        .then(({ data: { authorization: secondAuthorization } }) => {
                            if (secondAuthorization.ok && secondAuthorization.token) {
                                dispatch(setAuthorization(secondAuthorization.token));
                                dispatch(setCompanyCode(availableCompanies[0].code));
                                dispatch(setCountryCode(countries[0].code));
                                dispatch(setZoneCode(zones[0].code));
                            } else {
                                return Promise.reject(
                                    new SubmissionError({ _error: 'Please check your User Name and Password.' })
                                );
                            }

                            return true;
                        })
                        .then(postAction())
                        .catch(handleResponseError);
                }
            } else {
                // update the context to move onto the next step
                setContext({ step: 'selection', companies: user.availableCompanies, username, password });
            }

            return Promise.resolve();
        },
        [dispatch, setContext, directLogin, postAction, client]
    );

    // the callback to execute on successful submissions
    const onSubmitSuccess = useCallback(
        async ({ hasExistingSession, ...data }) => {
            if (hasExistingSession) {
                setAuthenticationResponse(data);
            } else {
                await login(data);
            }

            return Promise.resolve();
        },
        [login]
    );

    const goToPasswordReset = useCallback(
        event => {
            event.preventDefault();
            setContext({ step: 'requestNewPassword' });
        },
        [setContext]
    );

    const onClose = useCallback(async () => {
        await login(authenticationResponse);

        // then we clear the state
        setAuthenticationResponse(null);
    }, [authenticationResponse, login]);

    return (
        <>
            {!!authenticationResponse && <MultipleSessionModal onClose={onClose} />}
            <CredentialForm
                goToPasswordReset={goToPasswordReset}
                onSubmit={onSubmit}
                onSubmitSuccess={onSubmitSuccess}
            />
        </>
    );
};

CredentialStep.propTypes = {
    setContext: PropTypes.func.isRequired,
};

export default CredentialStep;
