import { createContext, useContext } from 'react';
import { InjectedFormProps } from 'redux-form';
import { LanguageOption } from '../../../../../i18n';
import {
    CountryZoneDataFragment,
    CountryBankDataFragment,
    EditionCountryDataFragment,
    GetDataQuery as EditionGetDataQuery,
    CountryInsuranceDataFragment,
} from '../Edition.graphql';
import { GetDataQuery } from './data.graphql';

export type FormValues = Omit<EditionCountryDataFragment, 'banks' | 'zones'>;

export type FormContext = InjectedFormProps<FormValues> & {
    disabled?: boolean;
    values: FormValues;
    settings: EditionGetDataQuery['settings'];
    zones: CountryZoneDataFragment[];
    banks: CountryBankDataFragment[];
    insurances: CountryInsuranceDataFragment[];
    apiData: GetDataQuery;
    languageOptions: LanguageOption[];
};

export const Context = createContext<FormContext>(null!);

export const useFormContext = () => useContext(Context);
