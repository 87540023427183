import { get } from 'lodash/fp';
import React, { useEffect, useMemo, useRef } from 'react';
import { Field } from 'redux-form';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import { useFormContext } from './context';

const MainDetails = () => {
    const { disabled, values, variants, models, subModels, change } = useFormContext();

    const edition = !!get('id', values);
    const modelId = get('modelId', values);

    const filteredSubModels = useMemo(() => {
        return subModels.filter(subModel => subModel.parentId === modelId);
    }, [subModels, modelId]);

    const subModelId = get('subModelId', values);

    const filteredVariants = useMemo(() => {
        return variants.filter(variant => variant.modelId === subModelId || variant.modelId === modelId);
    }, [variants, subModelId, modelId]);

    const previousModelIdRef = useRef(modelId);
    useEffect(() => {
        if (previousModelIdRef.current !== modelId && !filteredSubModels.find(option => option.value === subModelId)) {
            if (filteredSubModels.length !== 1) {
                change('subModelId', null);
            } else {
                change('subModelId', filteredSubModels[0].value);
            }

            previousModelIdRef.current = modelId;
        }
    }, [modelId, filteredSubModels, change, previousModelIdRef, subModelId]);

    const variantId = get('variantId', values);
    const previousSubModelId = useRef({ modelId, subModelId });
    useEffect(() => {
        if (
            (previousSubModelId.current.modelId !== modelId || previousSubModelId.current.subModelId !== subModelId) &&
            !(variantId && filteredVariants.find(option => option.value === variantId))
        ) {
            if (filteredVariants.length !== 1) {
                change('variantId', null);
            } else {
                change('variantId', filteredVariants[0].value);
            }

            previousSubModelId.current = { modelId, subModelId };
        }
    }, [previousSubModelId, filteredVariants, change, modelId, subModelId, variantId]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Model">
                        <DropdownField
                            disabled={edition || disabled}
                            name="modelId"
                            options={models}
                            placeholder="Select Model"
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Submodel">
                        <DropdownField
                            disabled={edition || disabled || filteredSubModels.length < 2}
                            name="subModelId"
                            options={filteredSubModels}
                            placeholder="Select Submodel"
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Variant">
                        <DropdownField
                            disabled={edition || disabled || filteredVariants.length < 2}
                            name="variantId"
                            options={filteredVariants}
                            placeholder="Select Variant"
                        />
                    </FieldContainer>
                </div>
            </div>
            {variantId && (
                <div className="row">
                    {edition && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="ID">
                                <Field component={InputField} name="identifier" disabled />
                            </FieldContainer>
                        </div>
                    )}
                    {edition && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Last Modified">
                                <Field component={InputField} name="lastModified" disabled />
                            </FieldContainer>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default MainDetails;
