import styled from 'styled-components';
import ParameterBox from './ParameterBox';

const LabelBox = styled(ParameterBox)`
    text-transform: ${props => props.theme.calculator.labelTransform};
    font-size: 0.85rem;
    color: ${props => props.theme.calculator.labelColor};
    align-items: flex-start;
`;

export default LabelBox;
