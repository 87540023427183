import { useApolloClient } from '@apollo/client';
import { omit } from 'lodash/fp';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { submit } from 'redux-form';
import { addNotification } from '../../../../actions';
import { getGlobalPermissions } from '../../../../selectors';
import { ROLE_DELETE_NOTIFICATION, ROLE_SAVE_NOTIFICATION } from '../../../../shared/constants/notification';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import { useModal } from '../../../Modal';
import FormLayout from '../../../ui/form/FormLayout';
import Form from './Form';
import { update, remove, create } from './Page.graphql';

type RolePageProps = {
    initialValues: {
        id?: string;
        countryId: string;
        companyId: string;
    };
};

const Page = ({ initialValues }: RolePageProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isUpdate = !!initialValues?.id;
    const { mayManageRoles } = useSelector(getGlobalPermissions);
    const client = useApolloClient();
    const modal = useModal();

    const onSubmit = useCallback(
        values => {
            const mutation = isUpdate ? update : create;

            const cleanedValues = omit(['id', 'lastModified', 'companyId', 'permissions', 'users'], values);

            cleanedValues.permissions = Object.entries(values.permissions).map(([code, level]) => ({ code, level }));

            const variables = { data: prepareForGraphQL(cleanedValues) } as { data: any; id: string };

            if (isUpdate) {
                variables.id = values.id;
            }

            return client.mutate({ mutation, variables }).catch(handleResponseError);
        },
        [client, isUpdate]
    );

    const onSubmitSuccess = useCallback(() => {
        dispatch(addNotification(ROLE_SAVE_NOTIFICATION));
        history.push('/access/roles');
    }, [history, dispatch]);

    const onDelete = useCallback(() => {
        modal.confirm({
            title: '',
            content: 'Are you sure you want to delete this role?',
            options: [
                { label: 'No', action: () => null },
                {
                    label: 'Yes',
                    action: () =>
                        client.mutate({ mutation: remove, variables: { id: initialValues?.id } }).then(() => {
                            dispatch(addNotification(ROLE_DELETE_NOTIFICATION));
                            history.push('/access/roles');
                        }),
                },
            ],
        });
    }, [client, dispatch, history, initialValues, modal]);

    const onCancel = useCallback(() => history.goBack(), [history]);

    const submitForm = useCallback(() => dispatch(submit('role')), [dispatch]);

    return (
        <FormLayout
            bodyComponent={
                initialValues && (
                    <Form
                        disabled={!mayManageRoles}
                        initialValues={initialValues}
                        isUpdate={isUpdate}
                        onSubmit={onSubmit}
                        onSubmitSuccess={onSubmitSuccess}
                    />
                )
            }
            moreActions={[
                initialValues?.id &&
                    mayManageRoles && {
                        label: 'delete',
                        onAction: onDelete,
                    },
                mayManageRoles && {
                    label: 'save',
                    onAction: submitForm,
                },
            ]}
            onCancel={onCancel}
            title="Role Details"
        />
    );
};

export default Page;
