import { createContext, useContext } from 'react';
import { Mask, Rounding } from '../../../../schema';
import { GetApplicationQuery } from '../../Workflow/common/data/Application.graphql';
import { Options } from '../useOptions';

export type FormProps = {
    application?: GetApplicationQuery['application'];
    allowPrimaryInfoChanges?: boolean;
    disabled?: boolean;
    journeys: any;
    options: Options;
    withInsurance?: boolean;
};

export type FormContextData = FormProps & {
    currency: string;
    mask: Mask;
    rounding: Rounding;
};

export const Context = createContext<FormContextData>(null!);

export const useFormContext = () => useContext(Context);
