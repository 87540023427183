import { uniqBy, get } from 'lodash/fp';

const computeHasMakes = (run, context) => {
    const { variants, hasMakes } = context;

    if (hasMakes) {
        // we don't need to compute anything, this value is being enforced
        return true;
    }

    return run('hasMakes', () => uniqBy(get('model.makeId'), variants).length > 1, [variants]);
};

export default computeHasMakes;
