import React, { useMemo } from 'react';
import Page from './Page';

const Creation = () => {
    const initialValues = useMemo(
        () => ({
            identifier: '',
            name: '',
            telephone: '',
            address: '',
            bankIds: [],
        }),
        []
    );

    return <Page initialValues={initialValues} />;
};

export default Creation;
