import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { FinanceProductBase, FinanceProductSubType, FinanceProductType } from '../../../../../schema';
import { getRuntimeSettings, getCompanyIdentifier } from '../../../../../selectors';
import { configBaseOnOptions } from '../../../../../shared/constants/options';
import FieldContainer from '../../../../template/Field-container';
import ApvPortlet from '../../../../template/Portlet';
import SwitchField from '../../../../template/Switch';
import AverageMileage from './AverageMileage';
import BalloonPayment from './BalloonPayment';
import BalloonPaymentGFV from './BalloonPaymentGFV';
import Commission from './Commission';
import Deposit from './Deposit';
import Displacement from './Displacement';
import DownPayment from './DownPayment';
import FixedInterestRate from './FixedInterestRate';
import InsuranceFee from './InsuranceFee';
import InterestRate from './InterestRate';
import LicenseAndFuelTax from './LicenseAndFuelTax';
import LicensePlateFee from './LicensePlateFee';
import Loan from './Loan';
import MarginOfFinance from './MarginOfFinance';
import PaymentTerm from './PaymentTerm';
import ResidualValue from './ResidualValue';
import StructuredPayment from './StructuredPayment';
import TaxLoss from './TaxLoss';
import TradeIn from './TradeIn';
import { useFormContext } from './context';

const Details = () => {
    const { useTradeInFields } = useSelector(getRuntimeSettings);
    const { disabled, onBasedOnChange, values, change } = useFormContext();
    const { companyCode, countryCode } = useSelector(getCompanyIdentifier);

    useEffect(() => {
        if (!useTradeInFields) {
            change('tradeInSetting.show', false);
            change('tradeInSetting.withLiability', false);
        }
    }, [change, useTradeInFields]);

    const isPorscheMalaysia = companyCode === 'POR' && countryCode === 'MY';

    const basedOn = get('basedOn', values);
    const productType = get('type', values);
    const productSubType = get('subType', values);

    const canEditDeposit = productType === FinanceProductType.LEASING && productSubType === FinanceProductSubType.LEASE;
    const canShowStructuredPayment = productSubType === FinanceProductSubType.STRUCTUREDPAYMENT;
    const canShowRV =
        productSubType === FinanceProductSubType.HIREPURCHASEBALLOON ||
        productSubType === FinanceProductSubType.HIREPURCHASEBALLOONNZ ||
        productSubType === FinanceProductSubType.HALFHALF;
    const canShowBalloonGFV = isPorscheMalaysia || productSubType === FinanceProductSubType.HIREPURCHASEBALLOONGFV;
    const canShowAverageMileage =
        productSubType === FinanceProductSubType.LEASEPURCHASE ||
        productSubType === FinanceProductSubType.LEASEPURCHASEICC;
    const canShowResidualValue =
        productSubType === FinanceProductSubType.LEASEPURCHASE ||
        productSubType === FinanceProductSubType.LEASEPURCHASEICC ||
        productSubType === FinanceProductSubType.HIREPURCHASEBALLOONGFV;

    const isUcclLeasing =
        productType === FinanceProductType.LEASING && productSubType === FinanceProductSubType.UCCLLEASING;
    const isFinanceOrUcclLeasing = productType === FinanceProductType.FINANCE || isUcclLeasing;

    return (
        <div>
            {isFinanceOrUcclLeasing && (
                <div className="container-fluid" style={{ padding: 0 }}>
                    <div className="row">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Configure based on">
                                <Field
                                    component={SwitchField}
                                    disabled={disabled}
                                    name="basedOn"
                                    onClick={onBasedOnChange}
                                    options={configBaseOnOptions}
                                />
                            </FieldContainer>
                        </div>
                    </div>
                </div>
            )}
            {isFinanceOrUcclLeasing && (
                <ApvPortlet name="Downpayment" open={basedOn === FinanceProductBase.DOWNPAYMENT} closable>
                    <DownPayment />
                </ApvPortlet>
            )}
            {isFinanceOrUcclLeasing && (
                <ApvPortlet name="Loan" open={basedOn === FinanceProductBase.LOAN} closable>
                    <Loan />
                </ApvPortlet>
            )}
            <ApvPortlet name="Margin of Finance" closable open>
                <MarginOfFinance />
            </ApvPortlet>
            <ApvPortlet name="Payment Term" closable open>
                <PaymentTerm />
            </ApvPortlet>
            {isUcclLeasing && (
                <ApvPortlet name="License Plate Fee" closable open>
                    <LicensePlateFee />
                </ApvPortlet>
            )}
            {isUcclLeasing && (
                <ApvPortlet name="Commission" closable open>
                    <Commission />
                </ApvPortlet>
            )}
            {isFinanceOrUcclLeasing && (
                <ApvPortlet name="Interest Rate" closable open>
                    <InterestRate />
                </ApvPortlet>
            )}
            {isUcclLeasing && (
                <ApvPortlet name="Monthly Payment (Fixed Interest Rate)" closable open>
                    <FixedInterestRate />
                </ApvPortlet>
            )}
            {isUcclLeasing && (
                <ApvPortlet name="License and Fuel Tax" closable open>
                    <LicenseAndFuelTax />
                </ApvPortlet>
            )}
            {isUcclLeasing && (
                <ApvPortlet name="Displacement" closable open>
                    <Displacement />
                </ApvPortlet>
            )}
            {isUcclLeasing && (
                <ApvPortlet name="Insurance Fee" closable open>
                    <InsuranceFee />
                </ApvPortlet>
            )}
            {isUcclLeasing && (
                <ApvPortlet name="Tax Loss" closable open>
                    <TaxLoss />
                </ApvPortlet>
            )}
            {canShowAverageMileage && (
                <ApvPortlet name="Average Mileage" closable open>
                    <AverageMileage />
                </ApvPortlet>
            )}
            {canShowResidualValue && (
                <ApvPortlet name="Residual Value" closable open>
                    <ResidualValue />
                </ApvPortlet>
            )}
            {canEditDeposit && (
                <ApvPortlet name="Security Deposit" closable open>
                    <Deposit />
                </ApvPortlet>
            )}
            {canShowStructuredPayment && (
                <ApvPortlet name="Structured Payment" closable open>
                    <StructuredPayment />
                </ApvPortlet>
            )}
            {canShowRV && (
                <ApvPortlet name="Balloon" closable open>
                    <BalloonPayment />
                </ApvPortlet>
            )}
            {canShowBalloonGFV && (
                <ApvPortlet name="Balloon" closable open>
                    <BalloonPaymentGFV />
                </ApvPortlet>
            )}
            {useTradeInFields && (
                <ApvPortlet name="Trade-In" closable open>
                    <TradeIn />
                </ApvPortlet>
            )}
        </div>
    );
};

export default Details;
