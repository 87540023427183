import React from 'react';
import { Field } from 'redux-form';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import NumberField from '../../../../template/Number';
import ApvPortlet from '../../../../template/Portlet';
import { useFormContext } from './context';

const TechnicalDetails = () => {
    const { disabled } = useFormContext();

    return (
        <ApvPortlet name="Technical Details" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Cylinder Arrangements/Number">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                name="technicalDetails.cylinderArrangementNo"
                                allowEmpty
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Displacement (litres)">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                name="technicalDetails.displacement"
                                allowEmpty
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Power(bhp)">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                name="technicalDetails.ratedOutput"
                                allowEmpty
                            />
                        </FieldContainer>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Torque (Nm)">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                name="technicalDetails.ratedTorque"
                                allowEmpty
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Transmission">
                            <Field component={InputField} disabled={disabled} name="technicalDetails.transmission" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Fuel Consumption (litres/100km)">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                name="technicalDetails.fuelConsumption"
                                allowEmpty
                            />
                        </FieldContainer>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="CO2 Emissions (g/km)">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                name="technicalDetails.co2Emissions"
                                allowEmpty
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Number of Airbags">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                name="technicalDetails.numberOfAirbags"
                                precision={0}
                                allowEmpty
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Drivetrain">
                            <Field component={InputField} disabled={disabled} name="technicalDetails.driveTrain" />
                        </FieldContainer>
                    </div>
                </div>
            </div>
        </ApvPortlet>
    );
};

export default TechnicalDetails;
