import { useApolloClient } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { submit } from 'redux-form';
import { addNotification } from '../../../../actions';
import { createCountryIntlValueMapper } from '../../../../i18n';
import { ChatbotType, Provider } from '../../../../schema';
import { COUNTRY_DELETE_NOTIFICATION, COUNTRY_SAVE_NOTIFICATION } from '../../../../shared/constants/notification';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import { useModal } from '../../../Modal';
import FormLayout from '../../../ui/form/FormLayout';
import Form from './Form';
import { create, update, remove, updateCompany } from './Page.graphql';

const getChatbotCredentials = configuration => {
    switch (configuration.type) {
        case ChatbotType.DIALOGFLOW:
            return configuration.dialogflowCredentials;

        case ChatbotType.HERO:
            return configuration.heroCredentials;

        case ChatbotType.USERLIKE:
            return configuration.userlikeCredentials;

        default:
            return undefined;
    }
};

const initialLuxuryTaxThreshold = {
    petrol: null,
    electric: null,
    hybrid: null,
};

const Page = ({
    initialValues = null,
    disabled = false,
    zones = null,
    banks = null,
    insurances = null,
    settings,
    events,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const modal = useModal();

    const submitForm = useCallback(() => dispatch(submit('country')), [dispatch]);

    const client = useApolloClient();
    const onSubmit = useCallback(
        async ({ id, channelSetting, email, ...data }) => {
            const query = id ? update : create;

            const luxuryTaxThreshold = data.luxuryTax?.threshold ?? {};

            const variables = {
                data: prepareForGraphQL({
                    ...data,
                    luxuryTax: data.luxuryTax?.amount
                        ? {
                              amount: data.luxuryTax.amount,
                              threshold: { ...initialLuxuryTaxThreshold, ...luxuryTaxThreshold },
                          }
                        : null,
                    email: omit([email.provider === Provider.SYSTEM ? 'fromEmail' : ''], email),
                    channelSetting: {
                        ...channelSetting,
                        new: {
                            ...omit(
                                ['isFinanceApplicationOptional', 'isChatbotEnabled', 'chatbot'],
                                channelSetting.new
                            ),
                            chatbot: channelSetting?.new?.isChatbotEnabled
                                ? {
                                      type: channelSetting?.new?.chatbot?.type,
                                      credentials: channelSetting?.new?.chatbot?.dialogflowCredentials,
                                  }
                                : null,
                            isFinanceApplicationMandatory: !channelSetting.new.isFinanceApplicationOptional,
                        },
                        used: {
                            ...omit(
                                ['isFinanceApplicationOptional', 'isChatbotEnabled', 'chatbot'],
                                channelSetting.used
                            ),
                            chatbot: channelSetting?.used?.isChatbotEnabled
                                ? {
                                      type: channelSetting?.used?.chatbot?.type,
                                      credentials: channelSetting?.used?.chatbot?.dialogflowCredentials,
                                  }
                                : null,
                            isFinanceApplicationMandatory: !channelSetting.used.isFinanceApplicationOptional,
                        },
                        express: {
                            ...omit(['isFinanceApplicationOptional'], channelSetting.express),
                            isFinanceApplicationMandatory: !channelSetting.express.isFinanceApplicationOptional,
                        },
                        event: {
                            ...omit(
                                [
                                    'hasMyInfoIntegration',
                                    'hasEventWithPayment',
                                    'hasEventWithChatbot',
                                    'hasEventWithPromoCode',
                                    'isChatbotEnabled',
                                    'chatbot',
                                ],
                                channelSetting.event
                            ),
                            chatbot: channelSetting?.event?.isChatbotEnabled
                                ? {
                                      type: channelSetting.event.chatbot.type,
                                      credentials: getChatbotCredentials(channelSetting.event.chatbot),
                                  }
                                : null,
                        },
                    },
                }),
                id,
            };

            const mapIntlValue = createCountryIntlValueMapper(variables.data);

            const companyVariables = {
                id: variables.data.companyId,
                data: {
                    name: mapIntlValue(variables.data.company.name),
                    description: mapIntlValue(variables.data.company.description),
                },
            };

            await client.mutate({ mutation: updateCompany, variables: companyVariables });

            if (id) {
                // we need to remove some more data
                variables.data = omit(
                    ['companyId', 'code', 'version', 'lastModified', 'name', 'company', 'insurances'],
                    variables.data
                );
            } else {
                variables.data = omit(['company'], variables.data);
            }

            return client
                .mutate({
                    mutation: query,
                    variables: {
                        ...variables,
                        data: { ...variables.data, mlcName: mapIntlValue(variables.data.mlcName) },
                    },
                })
                .catch(handleResponseError);
        },
        [client]
    );

    const onSubmitSuccess = useCallback(() => {
        dispatch(addNotification(COUNTRY_SAVE_NOTIFICATION));
        history.push('/scope/countries');
    }, [history, dispatch]);

    const onDelete = useCallback(() => {
        modal.confirm({
            title: '',
            content: 'Are you sure you want to delete this country?',
            options: [
                { label: 'No', action: () => null },
                {
                    label: 'Yes',
                    action: () =>
                        client.mutate({ mutation: remove, variables: { id: initialValues?.id } }).then(() => {
                            dispatch(addNotification(COUNTRY_DELETE_NOTIFICATION));
                            history.push('/scope/countries');
                        }),
                },
            ],
        });
    }, [client, dispatch, history, initialValues, modal]);

    const onCancel = useCallback(() => history.goBack(), [history]);

    const onAddZone = useCallback(() => history.push(`/scope/zones/fromCountry/${initialValues.id}`), [
        history,
        initialValues,
    ]);

    const onAddBank = useCallback(() => history.push(`/scope/banks/fromCountry/${initialValues.id}`), [
        history,
        initialValues,
    ]);

    const onAddInsurance = useCallback(() => history.push(`/scope/insurance/fromCountry/${initialValues.id}`), [
        history,
        initialValues,
    ]);

    const moreActions = useMemo(
        () =>
            [
                initialValues?.id &&
                    !disabled && {
                        label: 'delete',
                        onAction: onDelete,
                    },
                initialValues?.id &&
                    !disabled && {
                        label: 'add zone',
                        icon: faPlus,
                        onAction: onAddZone,
                    },
                initialValues?.id &&
                    !disabled && {
                        label: 'add insurance',
                        icon: faPlus,
                        onAction: onAddInsurance,
                    },
                initialValues?.id &&
                    !disabled && {
                        label: 'add bank',
                        icon: faPlus,
                        onAction: onAddBank,
                    },
                {
                    label: 'save',
                    onAction: submitForm,
                },
            ].filter(Boolean),
        [disabled, initialValues, onAddBank, onAddZone, onDelete, onAddInsurance, submitForm]
    );

    return (
        <FormLayout
            bodyComponent={
                initialValues && (
                    <Form
                        banks={banks}
                        disabled={disabled}
                        events={events}
                        initialValues={initialValues}
                        insurances={insurances}
                        onSubmit={onSubmit}
                        onSubmitSuccess={onSubmitSuccess}
                        settings={settings}
                        zones={zones}
                    />
                )
            }
            moreActions={moreActions}
            onCancel={onCancel}
            title="Country/Zone Settings"
        />
    );
};

Page.propTypes = {
    banks: PropTypes.arrayOf(PropTypes.shape({})),
    disabled: PropTypes.bool,
    events: PropTypes.arrayOf(PropTypes.shape({})),
    initialValues: PropTypes.shape({}),
    insurances: PropTypes.arrayOf(PropTypes.shape({})),
    settings: PropTypes.shape({}),
    zones: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Page;
