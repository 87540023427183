import { useQuery } from '@apollo/client';
import React, { useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FootBar, FootBarButton, FootContainer } from '../../../../containers/Layout';
import { getCountryId, getGlobalPermissions } from '../../../../selectors';
import Tree from '../../../shared/Tree';
import { List } from '../../../ui/lists';
import { getTables } from './RoleList.graphql';
import useUserTabs from './useUserTabs';

const useNodes = nodes =>
    useMemo(() => {
        if (nodes) {
            const translateDataToTree = (arr, parentId = '') => {
                const tree = [];

                arr.forEach(_item => {
                    if (_item.parentId === parentId) {
                        let obj = _item;
                        let temp = [];
                        temp = translateDataToTree(arr, _item.id);
                        if (temp.length > 0) {
                            if (!obj.nodes) {
                                obj = {
                                    ...obj,
                                    nodes: null,
                                };
                            }
                            obj.nodes = temp;
                        }
                        tree.push(obj);
                    }
                });

                return tree;
            };

            return translateDataToTree(nodes, null);
        }

        return [];
    }, [nodes]);

const HierarchyGrid = () => {
    const tabs = useUserTabs();
    const history = useHistory();
    const [id, setId] = useState(null);
    const { mayManageRolesHierarchy } = useSelector(getGlobalPermissions);

    const countryId = useSelector(getCountryId);
    const variables = { countryId };
    const { data, loading, error } = useQuery(getTables, { fetchPolicy: 'network-only', variables });

    const nodes = useNodes(data?.results?.items);

    const isLoading = loading && nodes.length <= 0;

    const onSelect = useCallback(node => setId(node), [setId]);

    const editHierarchy = useCallback(() => id && history.push(`/access/hierarchies/${id}`), [history, id]);

    const footer = (
        <FootContainer>
            <FootBar>
                {mayManageRolesHierarchy && (
                    <FootBarButton disabled={!id} onClick={editHierarchy}>
                        Edit Hierarchy
                    </FootBarButton>
                )}
            </FootBar>
        </FootContainer>
    );

    return (
        <List
            activeTab="hierarchy"
            bodyComponent={<Tree block={false} nodes={nodes} onSelect={onSelect} />}
            columns={[]}
            error={error}
            footer={footer}
            items={[]}
            loading={isLoading}
            tabWidth={316}
            tabs={tabs}
            title="Hierarchy Management"
        />
    );
};

export default HierarchyGrid;
