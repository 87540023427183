import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import { getOr } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Table, TableContainer } from '../../../containers/Layout';
import Checkbox from './Checkbox';

const getIdOrIndex = (item, index) => getOr(index, 'id', item);
const ListTable = ({
    columns,
    withNoScroll = false,
    items,
    className,
    getKey = getIdOrIndex,
    onItemClick,
    onSort,
    tableComponent: TableComponent = Table,
    sortedOn: [sortKey, sortOrder] = [null, null],
}) => (
    <TableContainer withNoScroll={withNoScroll}>
        <TableComponent>
            <thead className={className}>
                <tr>
                    {columns.map(column => (
                        <th
                            key={column.id}
                            className={cs(column.className, { 'sort-active': (sortKey?.id || sortKey) === column.id })}
                            onClick={() =>
                                column.hasSort &&
                                onSort([
                                    column.sortKey || column.id,
                                    (column.sortKey ? column.sortKey.id === sortKey.id : column.id === sortKey) &&
                                    sortOrder === 'asc'
                                        ? 'desc'
                                        : 'asc',
                                ])
                            }
                            style={column?.textAlignCenter ? { textAlign: 'center', ...column.styles } : column.styles}
                        >
                            {column.name}
                            {column.hasSort && (
                                <div className="head-icon">
                                    <i>
                                        <FontAwesomeIcon alt="" icon={faSort} />
                                    </i>
                                </div>
                            )}
                            {column.showCheckbox && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Checkbox
                                        checked={column.isAllCheckboxSelected}
                                        onChange={() => column.onCheckboxChange('all')}
                                        type="checkbox"
                                    />
                                </div>
                            )}
                            {column.filter}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {items.map((item, index) => (
                    <tr key={getKey(item, index)} onClick={() => onItemClick && onItemClick(item)}>
                        {columns.map(column => {
                            const value = column.renderCell(item, index);
                            const hasUnderline = column.underline && value !== '-';

                            return (
                                <td
                                    key={column.id}
                                    onClick={event => column.onClick && column.onClick(event, item)}
                                    style={
                                        hasUnderline ? { textDecoration: 'underline', cursor: 'pointer' } : undefined
                                    }
                                >
                                    {value}
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </TableComponent>
    </TableContainer>
);

ListTable.propTypes = {
    className: PropTypes.string,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            className: PropTypes.string,
            hasSort: PropTypes.bool,
            id: PropTypes.string.isRequired,
            isAllCheckboxSelected: PropTypes.bool,
            name: PropTypes.string.isRequired,
            onCheckboxChange: PropTypes.func,
            renderCell: PropTypes.func.isRequired,
            showCheckbox: PropTypes.bool,
        }).isRequired
    ).isRequired,
    getKey: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    onItemClick: PropTypes.func,
    onSort: PropTypes.func,
    sortedOn: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
    tableComponent: PropTypes.elementType,
    withNoScroll: PropTypes.bool,
};

export default memo(ListTable);
