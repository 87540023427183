import { useApolloClient } from '@apollo/client';
import { omit, isObject } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { submit } from 'redux-form';
import { addNotification } from '../../../../actions';
import { useContentTranslation } from '../../../../i18n';
import { COMPANY_DELETE_NOTIFICATION, COMPANY_SAVE_NOTIFICATION } from '../../../../shared/constants/notification';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import useLanguageSelector from '../../../../utils/useLanguageSelector';
import { useModal } from '../../../Modal';
import FormLayout from '../../../ui/form/FormLayout';
import Form from './Form';
import {
    create,
    update,
    uploadLogo,
    deleteLogo,
    uploadFont,
    uploadFavIcon,
    deleteFont,
    deleteFavIcon,
    remove,
} from './Page.graphql';

const Page = ({ initialValues = null, disabled = false, countries = [] }) => {
    const languageSelector = useLanguageSelector();
    const { mapIntlValue } = useContentTranslation();
    const isUpdate = !!initialValues?.id;
    const history = useHistory();
    const dispatch = useDispatch();
    const modal = useModal();

    const submitForm = useCallback(() => dispatch(submit('company')), [dispatch]);

    const client = useApolloClient();
    const onSubmit = useCallback(
        ({ logo, font, favIcon, ...data }) => {
            const query = isUpdate ? update : create;

            const cleanData = prepareForGraphQL(omit(['id', 'version', 'lastModified'], data));
            const { copyright } = cleanData;
            const variables = {
                data: {
                    ...cleanData,
                    copyright: mapIntlValue(copyright),
                },
                id: data?.id,
            };

            let promise = client.mutate({ mutation: query, variables });

            const uploads = [];

            if (logo instanceof File) {
                // must upload a new file
                uploads.push(id => client.mutate({ mutation: uploadLogo, variables: { id, file: logo } }));
            } else if (logo === null && isObject(initialValues.logo)) {
                // must remove an existing file
                uploads.push(id => client.mutate({ mutation: deleteLogo, variables: { id } }));
            }

            if (favIcon instanceof File) {
                // must upload a new file
                uploads.push(id => client.mutate({ mutation: uploadFavIcon, variables: { id, file: favIcon } }));
            } else if (favIcon === null && isObject(initialValues.favIcon)) {
                // must remove an existing file
                uploads.push(id => client.mutate({ mutation: deleteFavIcon, variables: { id } }));
            }

            if (font instanceof File) {
                // must upload a new file
                uploads.push(id => client.mutate({ mutation: uploadFont, variables: { id, file: font } }));
            } else if (font === null && isObject(initialValues.font)) {
                // must remove an existing file
                uploads.push(id => client.mutate({ mutation: deleteFont, variables: { id } }));
            }

            if (uploads.length) {
                promise = promise.then(({ data: { response } }) =>
                    Promise.all([Promise.resolve(response), ...uploads.map(upload => upload(response.id))])
                );
            }

            return promise.catch(handleResponseError);
        },
        [client, initialValues, isUpdate]
    );

    const onSubmitSuccess = useCallback(() => {
        dispatch(addNotification(COMPANY_SAVE_NOTIFICATION));
        history.push('/scope/companies');
    }, [dispatch, history]);

    const onDelete = useCallback(() => {
        modal.confirm({
            title: '',
            content: 'Are you sure you want to delete this company?',
            options: [
                { label: 'No', action: () => null },
                {
                    label: 'Yes',
                    action: () =>
                        client.mutate({ mutation: remove, variables: { id: initialValues?.id } }).then(() => {
                            dispatch(addNotification(COMPANY_DELETE_NOTIFICATION));
                            history.push('/scope/companies');
                        }),
                },
            ],
        });
    }, [client, dispatch, history, initialValues, modal]);

    const onCancel = useCallback(() => history.goBack(), [history]);

    return (
        <FormLayout
            bodyComponent={
                initialValues && (
                    <Form
                        {...languageSelector}
                        countries={countries}
                        disabled={disabled}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onSubmitSuccess={onSubmitSuccess}
                    />
                )
            }
            moreActions={[
                initialValues?.id &&
                    !disabled && {
                        label: 'delete',
                        onAction: onDelete,
                    },
                {
                    label: 'save',
                    onAction: submitForm,
                },
            ]}
            onCancel={onCancel}
            title="Company"
        />
    );
};

Page.propTypes = {
    countries: PropTypes.arrayOf(PropTypes.shape({})),
    disabled: PropTypes.bool,
    initialValues: PropTypes.shape({}),
};

export default Page;
