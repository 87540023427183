import { isValid } from 'date-fns';
import {
    every,
    get,
    getOr,
    isArray,
    isBoolean,
    isEmpty,
    isNaN,
    isNumber,
    isString,
    keys,
    map,
    merge,
    set,
    trim,
    uniq,
} from 'lodash/fp';
import MarkdownToMdast from 'mdast-util-from-markdown';
import { CustomerDetailsSource } from '../schema';
import { toDate } from '../utilities/fp';

const defaultMessage = 'Required';

export type Validator = (values: any, context: any, errors: null | object) => null | object;

export const requiredDate = (field: string, message = defaultMessage): Validator => values => {
    const value = get(field, values);
    const cleanedValue = toDate(value);

    return !value || !isValid(cleanedValue) ? set(field, message, {}) : null;
};

export const requiredTruthy = (field: string, message = defaultMessage): Validator => values => {
    const value = get(field, values);

    return value !== true ? set(field, message, {}) : null;
};

export const startDateMayNotExceedEndDate = (
    fieldStartDate: string,
    fieldEndDate: string,
    message = 'Start Date cannot be later than End Date.'
): Validator => values => {
    const periodStart = get(fieldStartDate, values);
    const periodEnd = get(fieldEndDate, values);
    if (periodStart && periodEnd) {
        // to cover for time comparision
        const formatPeriodStart = new Date(periodStart).toISOString();
        const formatPeriodEnd = new Date(periodEnd).toISOString();

        return Date.parse(formatPeriodStart) > Date.parse(formatPeriodEnd) ? set(fieldStartDate, message, {}) : null;
    }

    return null;
};

export const requiredValue = (field: string, message = defaultMessage): Validator => values =>
    isEmpty(get(field, values)) ? set(field, message, {}) : null;

export const requiredString = requiredValue;

export const requiredStringArray = (field: string, leastLength = 1, message = defaultMessage): Validator => values => {
    const value = get(field, values);

    return isArray(value) && value.length >= leastLength && every(isString, value) ? null : set(field, message, {});
};

const isInvalidNumber = (value: any): boolean => !isNumber(value) || isNaN(value);

export const requiredNumber = (field: string, message = defaultMessage): Validator => values => {
    const value = get(field, values);

    return isInvalidNumber(value) ? set(field, message, {}) : null;
};

type GreaterThanMessageFactory = (value: string | number, otherValue: string | number, context: any) => string;

export const requiredNumberGreaterThan = (
    field: string,
    otherField: string,
    message: string | GreaterThanMessageFactory = (value, otherValue) => `Should be greater than ${otherValue}`
): Validator => (values, context, errors) => {
    const value = get(field, values);
    const otherValue = get(otherField, values);
    const valueError = get(field, errors);
    const otherValueError = get(otherField, errors);

    if (isInvalidNumber(value) || isInvalidNumber(otherValue) || valueError || otherValueError) {
        // we skip this validation for now
        return null;
    }

    if (value <= otherValue) {
        const errorMessage = message instanceof Function ? message(value, otherValue, context) : message;

        return set(field, errorMessage, {});
    }

    return null;
};

export const requiredNumberLesserOrEqualThanNumber = (
    field: string,
    maxValue: number,
    message: string | GreaterThanMessageFactory = `Should be lesser or equal than ${maxValue}`
): Validator => (values, context, errors) => {
    const value = get(field, values);
    const valueError = get(field, errors);

    if (isInvalidNumber(value) || valueError) {
        // we skip this validation for now
        return null;
    }

    if (value > maxValue) {
        const errorMessage = message instanceof Function ? message(value, maxValue, context) : message;

        return set(field, errorMessage, {});
    }

    return null;
};

export const requiredNumberGreaterOrEqualThan = (
    field: string,
    otherField: string,
    message: string | GreaterThanMessageFactory = (value, otherValue) => `Should be greater or equal than ${otherValue}`
): Validator => (values, context, errors) => {
    const value = get(field, values);
    const otherValue = get(otherField, values);
    const valueError = get(field, errors);
    const otherValueError = get(otherField, errors);

    if (isInvalidNumber(value) || isInvalidNumber(otherValue) || valueError || otherValueError) {
        // we skip this validation for now
        return null;
    }

    if (value < otherValue) {
        const errorMessage = message instanceof Function ? message(value, otherValue, context) : message;

        return set(field, errorMessage, {});
    }

    return null;
};

export const requiredNumberGreaterOrEqualThanNumber = (
    field: string,
    minValue: number,
    message: string | GreaterThanMessageFactory = `Should be greater or equal than ${minValue}`
): Validator => (values, context, errors) => {
    const value = get(field, values);
    const valueError = get(field, errors);

    if (isInvalidNumber(value) || valueError) {
        // we skip this validation for now
        return null;
    }

    if (value < minValue) {
        const errorMessage = message instanceof Function ? message(value, minValue, context) : message;

        return set(field, errorMessage, {});
    }

    return null;
};

export const requiredDivisibleNumber = (
    field: string,
    divider: number,
    message: string | GreaterThanMessageFactory = `Should be divisible by ${divider}`
): Validator => (values, context, errors) => {
    const value = get(field, values);
    const valueError = get(field, errors);

    if (isInvalidNumber(value) || valueError) {
        // we skip this validation for now
        return null;
    }

    // js can not get accurate result when (2.6 % 0.01)
    if ((value * 1000) % (divider * 1000) !== 0) {
        const errorMessage = message instanceof Function ? message(value, divider, context) : message;

        return set(field, errorMessage, {});
    }

    return null;
};

export const validValue = (field: string, regex: RegExp, message: string): Validator => values => {
    const value = get(field, values);

    return !isEmpty(value) && !regex.test(value) ? set(field, message, {}) : null;
};

// eslint-disable-next-line max-len
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validEmail = (field: string, message = 'Invalid email'): Validator => values => {
    const value = get(field, values);

    return !isEmpty(value) && !emailRegex.test(value) ? set(field, message, {}) : null;
};

export const validJson = (field: string, message = 'Invalid Json', mandatoryKeys = []): Validator => values => {
    const value = get(field, values);

    if (!isEmpty(value)) {
        try {
            const json = JSON.parse(value);
            const keys = Object.keys(json);
            const mapKeys = map(item => keys.includes(item), mandatoryKeys);
            const validKeys = mapKeys.filter(item => item);

            if (validKeys.length === mandatoryKeys.length) {
                return null;
            }

            return set(field, message, {});
        } catch (error) {
            return set(field, message, {});
        }
    }

    return null;
};

export const requiredBoolean = (field: string, message = defaultMessage): Validator => values =>
    !isBoolean(get(field, values)) ? set(field, message, {}) : null;

type ValidatorTest = (values: any, context: any, errors: null | object) => boolean;

export const only = (test: ValidatorTest, validator: Validator): Validator => (...args) => {
    if (test(...args)) {
        return validator(...args);
    }

    return null;
};

export const compose = (...validators: Validator[]): Validator => (values, context, initialErrors = null) =>
    validators.reduce((errors, validator) => {
        const validatorErrors = validator(values, context, errors);

        if (validatorErrors) {
            return merge(validatorErrors, errors);
        }

        return errors;
    }, initialErrors);

export const minStringLength = (
    field: string,
    minLength: number,
    message = `Should be more than or equal to ${minLength} characters.`
): Validator => values => {
    const value = trim(get(field, values));

    return !isEmpty(value) && value.length < minLength ? set(field, message, {}) : null;
};

export const maxStringLength = (
    field: string,
    maxLength: number,
    message = `Should be less then or equal to ${maxLength} characters.`
): Validator => values => {
    const value = trim(get(field, values));

    return !isEmpty(value) && value.length > maxLength ? set(field, message, {}) : null;
};

type ForEachValidatorFactory = (key: string | number, values: any, context: any, errors: null | object) => Validator;

export const forEach = (field: string, validatorFactory: ForEachValidatorFactory): Validator => (...args) => {
    const [values] = args;
    const value = get(field, values);

    if (isEmpty(value)) {
        return null;
    }

    return compose(...keys(value).map(key => validatorFactory(key, ...args)))(...args);
};

type LazyFactory = (values: any, context: any, errors: null | object) => Validator;

export const lazy = (factory: LazyFactory): Validator => (...args) => factory(...args)(...args);

export const requiredValidRange = (field: string, minStep = 0.1, maxValue = Infinity, minValue = 0) =>
    lazy(values => {
        const value = get(field, values);
        const min = getOr(minValue, 'min', value);
        const max = getOr(minValue, 'max', value);
        const step = getOr(minStep, 'step', value);

        if (min !== max) {
            return compose(
                requiredNumberGreaterOrEqualThanNumber(`${field}.min`, minValue),
                requiredNumberLesserOrEqualThanNumber(`${field}.min`, maxValue),
                requiredNumberGreaterOrEqualThanNumber(`${field}.max`, Math.max(minValue, min)),
                requiredNumberLesserOrEqualThanNumber(`${field}.max`, maxValue),
                requiredNumberGreaterOrEqualThanNumber(`${field}.step`, minStep),
                requiredNumberLesserOrEqualThanNumber(`${field}.step`, Math.max(minStep, max - min)),
                requiredNumberGreaterOrEqualThanNumber(`${field}.default`, Math.max(minValue, min)),
                requiredNumberLesserOrEqualThanNumber(`${field}.default`, Math.min(maxValue, max)),
                requiredDivisibleNumber(`${field}.default`, step)
            );
        }

        return compose(
            requiredNumberGreaterOrEqualThanNumber(`${field}.min`, minValue),
            requiredNumberLesserOrEqualThanNumber(`${field}.min`, maxValue),
            requiredNumberGreaterOrEqualThanNumber(`${field}.max`, Math.max(minValue, min)),
            requiredNumberLesserOrEqualThanNumber(`${field}.max`, maxValue),
            requiredNumberGreaterOrEqualThanNumber(`${field}.default`, Math.max(minValue, min)),
            requiredNumberLesserOrEqualThanNumber(`${field}.default`, Math.min(maxValue, max))
        );
    });

export const requiredNumberAndGreaterThan = (
    field: string,
    minValue: number,
    message: string | GreaterThanMessageFactory = (value, minValue) => `Should be greater than ${minValue}`
): Validator => (values, context, errors) => {
    const value = get(field, values);

    if (value <= minValue) {
        const errorMessage = message instanceof Function ? message(value, minValue, context) : message;

        return set(field, errorMessage, {});
    }

    return null;
};

export const requiredPercentage = (field: string) =>
    compose(requiredNumberGreaterOrEqualThanNumber(field, 0), requiredNumberLesserOrEqualThanNumber(field, 100));

export const requiredMarkdownList = (field: string, message = defaultMessage): Validator => values => {
    const features = get(field, values);
    const mdast = MarkdownToMdast(features);
    const list = mdast.children;
    // mdast must only have one children and the type is list
    if (list.length === 1 && list[0].type === 'list') {
        const items = list[0].children;
        const listItemsTypes = uniq(items.map(i => i.type));

        // must keep all type of list children node is listItem
        if (listItemsTypes.length === 1 && listItemsTypes[0] === 'listItem') {
            const paragraphs = items.reduce<string[]>((acc, item) => acc.concat(item.children.map(i => i.type)), []);
            const paragraphTypes = uniq(paragraphs);
            if (paragraphTypes.length === 1 && paragraphTypes[0] === 'paragraph') {
                return null;
            }
        }
    }

    return set(field, message, {});
};

export const customerProperty = (field: string, validator: Validator): Validator =>
    only(
        values =>
            ![CustomerDetailsSource.MYINFO, CustomerDetailsSource.NOT_APPLICABLE].includes(
                get(`${field}.source`, values)
            ),
        compose(validator)
    );

const validSGNIRC = (input: string, dateOfBirth: Date | string) => {
    const match = input.match(/^(?<prefix>[STFGM])(?<digits>\d{7})(?<checksum>[A-Z])$/);

    if (!match) {
        return false;
    }

    // @ts-ignore
    const { prefix, digits, checksum } = match.groups;

    // validate only DoB of local singaporean
    if ((prefix === 'S' || prefix === 'T') && dateOfBirth) {
        if (new Date(dateOfBirth).getFullYear() < 2000 && prefix !== 'S') {
            return false;
        }

        if (new Date(dateOfBirth).getFullYear() > 2000 && prefix !== 'T') {
            return false;
        }
    }

    let offset = 0;

    if (prefix === 'T' || prefix === 'G') {
        offset = 4;
    } else if (prefix === 'M') {
        offset = 3;
    }

    const weights = [2, 7, 6, 5, 4, 3, 2];

    const sum = (digits as string)
        .split('')
        .reduce((accumulation, char, index) => accumulation + parseInt(char, 10) * weights[index], offset);

    let checksums = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];

    if (prefix === 'F' || prefix === 'G') {
        checksums = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K'];
    } else if (prefix === 'M') {
        checksums = ['K', 'L', 'J', 'N', 'P', 'Q', 'R', 'T', 'U', 'W', 'X'];
    }

    let index = sum % 11;

    if (prefix === 'M') {
        index = 10 - index;
    }

    return checksum === checksums[index];
};

const validateNirc = (countryCode: string) => {
    switch (countryCode) {
        case 'SG':
            return validSGNIRC;

        default:
            return () => true;
    }
};

export const validNIRC = (
    field: string,
    countryField: string,
    dateOfBirthField: string,
    message = 'Invalid Identity Number'
): Validator => values => {
    const value = get(field, values);
    const country = get(countryField, values);
    const dateOfBirth = get(dateOfBirthField, values);

    const validate = validateNirc(country);

    return !isEmpty(value) && !validate(value, dateOfBirth) ? set(field, message, {}) : null;
};

export const contextValidation = (field: string, validationKey: string, message: string): Validator => (
    values,
    context
) => {
    const value = get(field, values);
    const validation = get(['validation', validationKey], context);

    if (!validation) {
        return null;
    }

    return !isEmpty(value) && !validation.test(value) ? set(field, message, {}) : null;
};

export const hasChanged = (field: string) => (values: any, context: any) =>
    get(`initialValues.${field}`, context) !== get(field, values);
