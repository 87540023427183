import ConnectedPromoCodeField from '../components/fields/display/ConnectedPromoCodeField';
import PromoCodeLabelBox from '../components/fields/display/PromoCodeLabelBox';
import PromoCodeSnapshot from '../components/fields/display/PromoCodeSnapshot';
import UpdatePromoCode from '../components/fields/edition/UpdatePromoCode';
import { weightFieldAfter } from '../utils';
import fieldTemplate from './fieldTemplate';

const promoCodeField = {
    // copy template
    ...fieldTemplate,

    // setup meta
    label: t => t('calculator.label.promoCode'),
    weight: weightFieldAfter('cashPayment'),

    // setup states
    isEditable: () => true,

    // setup display rendering
    displayComponent: ConnectedPromoCodeField,
    displayLabelComponent: PromoCodeLabelBox,

    getInitialValue: () => '',

    // setup edition rendering
    editionComponent: UpdatePromoCode,

    // snapshot configuration
    snapshot: {
        ...fieldTemplate.snapshot,
        component: PromoCodeSnapshot,
    },
};

export default promoCodeField;
