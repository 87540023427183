import { useQuery } from '@apollo/client';
import { format, subMonths } from 'date-fns';
import { get, orderBy, getOr, isNil } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCountry, getZoneId } from '../../../selectors';
import TopSalesManTable from './TopSalesManTable';
import { getMostApplicationSalesPerson } from './TopSalesManWithApplication.graphql';
import { useDashboardContext } from './context';

const columns = [
    {
        name: 'Name',
        id: 'name',
        renderCell: get('name'),
    },
    { name: 'Alias', id: 'alias', renderCell: get('alias') },
    {
        name: 'Number of Leads',
        id: 'count',
        renderCell: get('count'),
    },
];

const TopSalesManWithLead = () => {
    const { id: countryId } = useSelector(getCurrentCountry);
    const zoneId = useSelector(getZoneId);
    const { channels, dealerIds } = useDashboardContext();
    const variables = {
        filter: { zoneId, countryId, dealerIds, channels },
        isLead: true,
        sortParam: 'count',
    };
    const { data } = useQuery(getMostApplicationSalesPerson, {
        skip: isNil(dealerIds) || dealerIds.length < 1 || channels.length < 1,
        fetchPolicy: 'no-cache',
        variables,
    });

    const tabs = useMemo(
        () => [
            {
                label: `Current Month (${format(new Date(), 'MMM')})`,
                dataKey: 'currentMonth_salesPersonWithMostLeads',
                data: orderBy('count', 'desc', getOr([], 'currentMonth', data?.metrics)),
            },
            {
                label: `Last Month (${format(subMonths(new Date(), 1), 'MMM')})`,
                dataKey: 'lastMonth_salesPersonWithMostLeads',
                data: orderBy('count', 'desc', getOr([], 'lastMonth', data?.metrics)),
            },
        ],
        [data]
    );

    return <TopSalesManTable columns={columns} tabs={tabs} title="Top 3 Salespersons: Most collected leads" />;
};

export default TopSalesManWithLead;
