import { find, get } from 'lodash/fp';

const selectBank = (run, context, banks, values) => {
    const snapshot = get('selectedFinanceProduct.bank', context);
    const bankId = get('bank', values);

    if (snapshot?.id === bankId) {
        return snapshot;
    }

    return run('selectedBank', () => find(bank => bank.id === bankId, banks), [bankId, banks]);
};

export default selectBank;
