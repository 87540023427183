import React, { createContext, useContext } from 'react';
import { AppointmentAction, AppointmentState } from './reducer';

export type AppointmentListContextProps = {
    state: AppointmentState;
    dispatch: React.Dispatch<AppointmentAction>;
};

export const AppointmentListContext = createContext<AppointmentListContextProps | null>(null);

export type AppointmentListProviderProps = {
    children?: React.ReactNode;
    state: AppointmentState;
    dispatch: React.Dispatch<AppointmentAction>;
};

export const AppointmentListProvider = ({ children, state, dispatch }: AppointmentListProviderProps) => {
    return <AppointmentListContext.Provider value={{ state, dispatch }}>{children}</AppointmentListContext.Provider>;
};

export const useAppointmentListContext = () => {
    const context = useContext(AppointmentListContext);

    if (!context) {
        throw new Error('useAppointmentListContext must be used within a AppointmentListProvider');
    }

    return context;
};
