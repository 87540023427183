// @ts-ignore
import { PortletButton } from '@appvantageasia/afc-ui';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCountry } from '../../../../../../selectors';
import { Context } from '../../../../Customer/Form/context';
import { useCustomerOptions } from '../../../../Customer/useOptions';
import { GetInsuranceApplicationQuery } from '../../../common/data/Application.graphql';
import ApplicantDetails from './ApplicantDetails';
import { useFormContext } from './context';

export type CustomerProps = {
    application: GetInsuranceApplicationQuery['insuranceApplication']['application'];
    allowPrimaryInfoChanges?: boolean;
    disabled?: boolean;
    withInsurance?: boolean;
};

const Customer = ({ disabled, allowPrimaryInfoChanges, application, withInsurance = false }: CustomerProps) => {
    const originalContext = useFormContext();
    const { mask, currency, rounding } = useSelector(getCurrentCountry);
    const options = useCustomerOptions();

    const newContext = {
        ...originalContext,
        mask,
        currency,
        rounding,
        options,
        disabled,
        allowPrimaryInfoChanges,
        application,
        withInsurance,
    };

    return (
        // @ts-ignore
        <Context.Provider value={newContext}>
            <ApplicantDetails />
        </Context.Provider>
    );
};

export default memo(Customer);
