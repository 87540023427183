import styled from 'styled-components';
import CompanyLogo from '../../shared/CompanyLogo';

const LoginLogo = styled(CompanyLogo)`
    max-height: 60px;
    max-width: 262px;
    margin-bottom: 20px;
`;

export default LoginLogo;
