import { useQuery } from '@apollo/client';
import { get } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { getLastModified } from '../../../../utilities/forms';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { getInventory } from './Edition.graphql';
import Page from './Page';

const Edition = () => {
    const { id, setId } = useParams();
    const variables = { id, setId };
    const { data, loading } = useQuery(getInventory, { variables, fetchPolicy: 'network-only' });

    const formatDateTime = useFormatDateTime();

    const initialValues = useMemo(() => {
        const inventory = data?.inventory;
        const inventorySet = get('sets[0]', inventory);
        const options = inventorySet?.options;

        if (!inventory || !inventorySet) {
            return null;
        }

        const { variant } = inventory;
        const { model } = variant;

        return {
            // main entities
            inventory,
            inventorySet,
            options,
            // variant name(s)
            variant,
            model: model.parent || model,
            subModel: model.parent ? model : null,
            // last modification date for this inventory set
            lastModified: getLastModified(inventorySet.version, formatDateTime),
            // and units
            identifier: `${inventory?.identifier}.${inventorySet?.identifier}`,
        };
    }, [data, formatDateTime]);

    if (!loading && !initialValues) {
        // the product does not exist
        return <Redirect to="/vehicle/inventories" />;
    }

    return <Page initialValues={initialValues} />;
};

export default Edition;
