import { get } from 'lodash/fp';
import React, { useEffect, useCallback } from 'react';
import { Field, FormSection } from 'redux-form';
import { Rounding, ChatbotType } from '../../../../../../schema';
import { onOffOptions } from '../../../../../../shared/constants/options';
import { useModal } from '../../../../../Modal';
import DropdownField from '../../../../../template/Dropdown';
import FieldContainer from '../../../../../template/Field-container';
import InputField from '../../../../../template/Input';
import KeyField from '../../../../../template/KeyField';
import SwitchField from '../../../../../template/Switch';
import { ChannelTitle } from '../../../../../ui/Channel';
import { Tips } from '../../../../../ui/form/File';
import MyInfoIntegration from '../../../bank/Form/MyInfoIntegration';
import PaymentIntegration from '../../../bank/Form/PaymentIntegration';
import { useFormContext } from '../context';
import ChannelURL from './ChannelURL';

type EventProps = {
    ciBaseLink: string;
    companyCode: string;
    countryZoneCode: string;
    currency: string;
    rounding: Rounding;
    getHush: (rawKey: string) => Promise<string>;
};

const Event = ({ ciBaseLink, companyCode, countryZoneCode, rounding, currency, getHush }: EventProps) => {
    const { values, change, settings, apiData } = useFormContext();

    const chatbotOptions = apiData?.options?.chatbotOptions;

    const valuePrefix = 'channelSetting.event';
    const url = `/${companyCode}/${countryZoneCode}/event`;

    const isDepositPaymentMandatory = get(`${valuePrefix}.isDepositPaymentMandatory`, values);
    const isChatbotEnabled = get(`${valuePrefix}.isChatbotEnabled`, values);
    const selectedChatbot = get(`${valuePrefix}.chatbot.type`, values);
    const isPromoCodeEnabled = get(`${valuePrefix}.isPromoCodeEnabled`, values);

    const modal = useModal();
    useEffect(() => {
        if (!isDepositPaymentMandatory) {
            if (values.channelSetting.event.hasEventWithPayment) {
                // will not be able turn off
                // when there's existing payment
                change(`${valuePrefix}.isDepositPaymentMandatory`, true);
                // show notification
                modal.confirm({
                    title: '',
                    content: 'There are existing events with Payment.',
                    options: [],
                });
            } else {
                change(`${valuePrefix}.bookingPayment`, null);
            }
        }
    }, [change, isDepositPaymentMandatory, modal, values.channelSetting.event.hasEventWithPayment]);

    useEffect(() => {
        if (!isChatbotEnabled) {
            if (values.channelSetting.event.hasEventWithChatbot) {
                // will not be able turn off
                // when there's existing event with chatbot
                change(`${valuePrefix}.isChatbotEnabled`, true);
                // show notification
                modal.confirm({
                    title: '',
                    content: 'There are existing events with Chatbot.',
                    options: [],
                });
            } else {
                change(`${valuePrefix}.chatbot`, null);
            }
        }
    }, [change, isChatbotEnabled, modal, values.channelSetting.event.hasEventWithChatbot]);

    useEffect(() => {
        if (!isPromoCodeEnabled) {
            if (values.channelSetting.event.hasEventWithPromoCode) {
                // will not be able turn off
                // when there's existing event with promo code
                change(`${valuePrefix}.isPromoCodeEnabled`, true);
                // show notification
                modal.confirm({
                    title: '',
                    content: 'There are existing events with Promo Code.',
                    options: [],
                });
            } else {
                change(`${valuePrefix}.isPromoCodeEnabled`, false);
            }
        }
    }, [change, isPromoCodeEnabled, modal, values.channelSetting.event.hasEventWithPromoCode]);

    useEffect(() => {
        if (!isChatbotEnabled) {
            change(`${valuePrefix}.chatbot.dialogflowCredentials`, null);
            change(`${valuePrefix}.chatbot.userlikeCredentials`, null);
        }
    }, [change, isChatbotEnabled]);

    const handleChange = useCallback(() => change(`${valuePrefix}.chatbot.credentials`, undefined), [change]);

    return (
        <div className="container-fluid">
            <ChannelTitle>Events Channel</ChannelTitle>
            <ChannelURL path={url} to={ciBaseLink + url} />
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Active">
                        <Field component={SwitchField} name={`${valuePrefix}.isActive`} options={onOffOptions} />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Myinfo">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.hasMyInfoIntegration`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Booking Deposit Payment">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.isDepositPaymentMandatory`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Promo Code">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.isPromoCodeEnabled`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                {isPromoCodeEnabled && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Always Show Promo Code">
                            <Field
                                component={SwitchField}
                                name={`${valuePrefix}.alwaysShowPromoCode`}
                                options={onOffOptions}
                            />
                        </FieldContainer>
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Chatbot">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.isChatbotEnabled`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
            </div>
            <FormSection name={valuePrefix}>
                <MyInfoIntegration />
            </FormSection>
            {isDepositPaymentMandatory && (
                <FormSection name={valuePrefix}>
                    <PaymentIntegration currency={currency} rounding={rounding} settings={settings} />
                    <Tips>Booking Deposit Payment is managed individually inside each Event Form</Tips>
                </FormSection>
            )}
            {isChatbotEnabled && (
                <>
                    <hr />
                    <div className="row">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Chatbot Agent">
                                <DropdownField
                                    name={`${valuePrefix}.chatbot.type`}
                                    onChange={handleChange}
                                    options={chatbotOptions}
                                />
                            </FieldContainer>
                        </div>
                        {selectedChatbot === ChatbotType.DIALOGFLOW && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Dialogflow Credentials">
                                    <Field
                                        accept=".json"
                                        component={KeyField}
                                        hashFun={getHush}
                                        name={`${valuePrefix}.chatbot.dialogflowCredentials`}
                                    />
                                </FieldContainer>
                            </div>
                        )}
                        {selectedChatbot === ChatbotType.USERLIKE && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Widget Key">
                                    <Field component={InputField} name={`${valuePrefix}.chatbot.userlikeCredentials`} />
                                </FieldContainer>
                            </div>
                        )}
                        {selectedChatbot === ChatbotType.HERO && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Hero Application Id">
                                    <Field component={InputField} name={`${valuePrefix}.chatbot.heroCredentials`} />
                                </FieldContainer>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default Event;
