export const PROFILE_SAVE_NOTIFICATION = 'Your Profile Details has been updated.';

export const USER_SAVE_NOTIFICATION = 'The User Details has been updated.';
export const USER_DELETE_NOTIFICATION = 'The User has been deleted.';

export const ROLE_SAVE_NOTIFICATION = 'The Role Details has been updated.';
export const ROLE_DELETE_NOTIFICATION = 'The Role has been deleted.';

export const HIERARCHY_SAVE_NOTIFICATION = 'The Hierarchy Details has been updated.';

export const COMPANY_SAVE_NOTIFICATION = 'The Company has been updated.';
export const COMPANY_DELETE_NOTIFICATION = 'The Company has been deleted.';

export const COUNTRY_SAVE_NOTIFICATION = 'The Country/Zone Settings has been updated.';
export const COUNTRY_DELETE_NOTIFICATION = 'The Country/Zone Settings has been deleted.';

export const ZONE_SAVE_NOTIFICATION = 'The Zone has been updated.';
export const ZONE_DELETE_NOTIFICATION = 'The Zone has been deleted.';

export const BANK_SAVE_NOTIFICATION = 'The Bank has been updated.';
export const BANK_DELETE_NOTIFICATION = 'The Bank has been deleted.';

export const INSURANCE_SAVE_NOTIFICATION = 'The Insurance has been updated.';
export const INSURANCE_DELETE_NOTIFICATION = 'The Insurance has been deleted.';

export const DEALER_SAVE_NOTIFICATION = 'The Dealer has been updated.';
export const DEALER_DELETE_NOTIFICATION = 'The Dealer has been deleted.';

export const CONSENT_SAVE_NOTIFICATION = 'The Consent & Declaration has been updated.';
export const CONSENT_DELETE_NOTIFICATION = 'The Consent & Declaration has been deleted.';

export const COE_SAVE_NOTIFICATION = 'The COE has been updated.';

export const PROMO_SAVE_NOTIFICATION = 'The Promo Code has been updated.';
export const PROMO_DELETE_NOTIFICATION = 'The Promo Code has been deleted.';

export const EVENT_SAVE_NOTIFICATION = 'The Event has been updated.';
export const EVENT_DELETE_NOTIFICATION = 'The Event has been deleted.';

export const FINANCE_SAVE_NOTIFICATION = 'The Financial Product Details has been updated.';
export const FINANCE_DELETE_NOTIFICATION = 'The Financial Product has been deleted.';

export const DOWNPAYMENT_SAVE_NOTIFICATION = 'The Downpayment Table Details has been updated.';
export const DOWNPAYMENT_DELETE_NOTIFICATION = 'The Downpayment Table has been deleted.';

export const INTEREST_SAVE_NOTIFICATION = 'The Interest Rate Table Details has been updated.';
export const INTEREST_DELETE_NOTIFICATION = 'The Interest Rate Table has been deleted.';

export const BALLOON_SAVE_NOTIFICATION = 'The Balloon Table Details has been updated.';
export const BALLOON_DELETE_NOTIFICATION = 'The Balloon Table has been deleted.';

export const RV_SAVE_NOTIFICATION = 'The Residue Value Table Details has been updated.';
export const RV_DELETE_NOTIFICATION = 'The Residue Value Table has been deleted.';

export const LEASE_SAVE_NOTIFICATION = 'The Lease Table Details has been updated.';
export const LEASE_DELETE_NOTIFICATION = 'The Lease Table has been deleted.';

export const DEPOSIT_SAVE_NOTIFICATION = 'The Deposit Table Details has been updated.';
export const DEPOSIT_DELETE_NOTIFICATION = 'The Deposit Table has been deleted.';

export const VARIANT_SAVE_NOTIFICATION = 'The Variant Details has been updated.';
export const VARIANT_DELETE_NOTIFICATION = 'The Variant has been deleted.';

export const SUBMODEL_SAVE_NOTIFICATION = 'The Sub Model Details has been updated.';
export const SUBMODEL_DELETE_NOTIFICATION = 'The Sub Model has been deleted.';

export const MODEL_SAVE_NOTIFICATION = 'The Model Details has been updated.';
export const MODEL_DELETE_NOTIFICATION = 'The Model has been deleted.';

export const MAKE_SAVE_NOTIFICATION = 'The Make Details has been updated.';
export const MAKE_DELETE_NOTIFICATION = 'The Make has been deleted.';

export const OPTION_SAVE_NOTIFICATION = 'The Option Details has been updated.';
export const OPTION_DELETE_NOTIFICATION = 'The Option has been deleted.';

export const OPTION_CATEGORY_SAVE_NOTIFICATION = 'The Options Category Details has been updated.';
export const OPTION_CATEGORY_DELETE_NOTIFICATION = 'The Options Category has been deleted.';

export const INVENTORY_SAVE_NOTIFICATION = 'The Inventory Details has been updated.';
export const INVENTORY_DELETED_NOTIFICATION = 'The Inventory has been deleted.';

export const MAINTENANCE_SAVE_NOTIFICATION = 'The Maintenance Details has been updated.';

export const APPLICATION_SUBMIT_NOTIFICATION = id => `The Application ${id} has been submitted.`;
export const APPLICATION_APPROVE_NOTIFICATION = id => `The Application ${id} has been approved.`;
export const APPLICATION_DECLINE_NOTIFICATION = id => `The Application ${id} has been declined.`;
export const APPLICATION_COMPLETE_NOTIFICATION = id => `The Application ${id} has been completed.`;
export const APPLICATION_CANCEL_NOTIFICATION = id => `The Application ${id} has been cancelled.`;
export const APPLICATION_UPDATE_NOTIFICATION = id => `The Application ${id} has been updated.`;
export const APPLICATION_CONCLUDE_AGREEMENT_NOTICATION = id => `The Application ${id} agreements has been concluded.`;
export const APPLICATION_APPLIED_FOR_FINANCING_NOTIFICATION = id => `The Application ${id} has applied for financing.`;
export const APPLICATION_ADD_GUARANTOR_NOTIFICATION = 'Guarantor Requested.';

export const CUSTOMER_SAVE_NOTIFICATION = name => `The Customer ${name} has been updated.`;

export const LEAD_SAVE_NOTIFICATION = id => `The Lead ${id} has been updated.`;
export const LEAD_SHARE_NOTIFICATION = id => `The Lead ${id} has been shared.`;

export const ACTIVATION_ACTIVATE_NOTIFICATION = id => `The Application ${id} has been activated.`;

export const FINDER_VEHICLE_SAVE_NOTIFICATION = 'The Finder Vehicle Details has been updated.';

export const APPOINTMENT_SAVE_NOTIFICATION = identifier => `The Appointment ${identifier} has been updated.`;
