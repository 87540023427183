import { ApolloClient, InMemoryCache } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getAccessToken } from '../selectors';

const createWebSocketClient = getState => {
    const wssProtocol = window.location.protocol.toLowerCase().startsWith('https') ? 'wss' : 'ws';

    return new ApolloClient({
        link: new WebSocketLink({
            uri: `${wssProtocol}://${window.location.host}/graphql`,
            options: {
                reconnect: true,
                connectionParams: () => {
                    const token = getAccessToken(getState());

                    return token ? { Authorization: `Bearer ${token}` } : {};
                },
            },
        }),
        cache: new InMemoryCache(),
    });
};

export default createWebSocketClient;
