import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback, useLayoutEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

const InputBox = styled.div`
    border: 1px solid #ced4da;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 60px;

    & + & {
        margin-top: 10px;
    }
`;

const InputWrapper = styled.div`
    flex-grow: 2;

    display: flex;
    justify-content: center;
    align-items: center;

    input {
        width: 100%;
        text-align: center;
        border: none;
        outline: none;
        font-size: 1.35rem;
        color: ${props => props.theme.calculator.valueColor};

        &:disabled {
            color: black;
            background-color: transparent;
        }
    }
`;

const addOnCss = css`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 50px;
`;

const Prefix = styled.div`
    ${addOnCss}
`;

const ResetButton = styled.button`
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    font-size: 1.78rem;
    background-color: transparent;
    ${addOnCss}
`;

const OutlineCenterInput = ({ prefix = null, reset = null, autoFocus, onChange, ...props }) => {
    const { disabled } = props;
    const inputRef = useRef(null);

    useLayoutEffect(() => {
        if (autoFocus && inputRef.current) {
            setTimeout(() => inputRef.current.focus(), 100);
        }
    }, [autoFocus, inputRef]);

    const resetAndFocus = useCallback(() => {
        reset();
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [reset, inputRef]);

    const onChangeEvent = useCallback(event => onChange(event.target.value), [onChange]);

    return (
        <InputBox disabled={disabled}>
            <Prefix>{prefix}</Prefix>
            <InputWrapper>
                <input ref={inputRef} onChange={onChangeEvent} {...props} />
            </InputWrapper>
            <ResetButton onClick={reset && resetAndFocus} type="button">
                {reset && !disabled && <Icon icon={faTimesCircle} />}
            </ResetButton>
        </InputBox>
    );
};

OutlineCenterInput.propTypes = {
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    prefix: PropTypes.node,
    reset: PropTypes.func,
};

export default OutlineCenterInput;
