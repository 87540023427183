export const SET_CONTEXT = 'SET_CONTEXT';

export const setContext = payload => ({ type: SET_CONTEXT, payload });

export const CONTEXT_SET_COMPANY_CODE = '@CONTEXT/SET_COMPANY_CODE';

export const CONTEXT_SET_COUNTRY_CODE = '@CONTEXT/SET_COUNTRY_CODE';

export const CONTEXT_SET_ZONE_CODE = '@CONTEXT/SET_ZONE_CODE';

export const CONTEXT_SET_DEFAULT_ZONE_CODE = '@CONTEXT/CONTEXT_SET_DEFAULT_ZONE_CODE';

export const CONTEXT_SET_COMPANY = '@CONTEXT/SET_COMPANY';

export const CONTEXT_SET_SCOPE = '@CONTEXT/SET_SCOPE';

export const CONTEXT_SET_USER = '@CONTEXT/SET_USER';

export const CONTEXT_SET_DEALER = '@CONTEXT/SET_DEALER';

export const CONTEXT_UNSET = '@CONTEXT/UNSET';

export const setCompanyCode = companyCode => ({ type: CONTEXT_SET_COMPANY_CODE, companyCode });

export const setCountryCode = countryCode => ({ type: CONTEXT_SET_COUNTRY_CODE, countryCode });

export const setZoneCode = zoneCode => ({ type: CONTEXT_SET_ZONE_CODE, zoneCode });

export const setDefaultZoneCode = defaultZoneCode => ({ type: CONTEXT_SET_DEFAULT_ZONE_CODE, defaultZoneCode });

export const setCompany = company => ({ type: CONTEXT_SET_COMPANY, company });

export const setDealer = dealer => ({ type: CONTEXT_SET_DEALER, dealer });

export const setScope = scope => ({ type: CONTEXT_SET_SCOPE, scope });

export const setUser = user => ({ type: CONTEXT_SET_USER, user });

export const unsetContext = () => ({ type: CONTEXT_UNSET });
