import { useEffect, useMemo, useRef, useState } from 'react';

export type PagingOptions = {
    search?: string | null;
    pageSize?: number;
    initialPage?: number;
};

export type PagingState = {
    page: number;
    pageSize: number;
    setPage: (page: number) => void;
    pageCount: number;
    itemCount: number;
};

const usePaging = <T = any>(
    items: Array<T>,
    { search = null, pageSize = 10, initialPage = 1 }: PagingOptions = {}
): [Array<T>, PagingState] => {
    const previousSearch = useRef(search);
    const [page, setPage] = useState(initialPage);

    // reset the page to zero whenever the search changed
    useEffect(() => {
        if (previousSearch.current !== search) {
            setPage(1);
            previousSearch.current = search;
        }
    }, [previousSearch, search, setPage]);

    const state = useMemo(
        () => ({
            page,
            pageSize,
            setPage,
            pageCount: Math.ceil(items.length / pageSize),
            itemCount: items.length,
        }),
        [setPage, page, pageSize, items]
    );

    const pagedItems = useMemo(() => {
        return items.slice((page - 1) * pageSize, page * pageSize);
    }, [items, page, pageSize]);

    return [pagedItems, state];
};

export default usePaging;
