import styled, { css } from 'styled-components';

export const Description = styled.div`
    font-size: 0.85rem;
    color: #999999;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const PromoError = styled(Description)`
    color: #c00000;
    margin: 5px;
`;

export const Empty = styled.div`
    height: 18px;
`;

export const PromoIcon = styled.i`
    position: absolute;
    width: 20px;

    svg {
        color: #5b833b;
        width: 16px !important;
    }
`;

export const PromoWithCode = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    & > div {
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;

        ${props =>
            props.withValid &&
            css`
                padding-right: 20px;
            `}
    }

    & > div span {
        ${props =>
            props.editable &&
            css`
                cursor: pointer;
                color: ${props.theme.calculator.valueColor};
            `}
    }
`;

const PromoCodeValue = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.21rem;
    color: #000000;
`;

export default PromoCodeValue;
