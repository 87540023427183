import { useQuery } from '@apollo/client';
import { omit } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { getLastModified } from '../../../../utilities/forms';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { getBank } from './Edition.graphql';
import Page from './Page';

const Edition = () => {
    const { id } = useParams();
    const variables = { id };
    const { data, loading } = useQuery(getBank, { variables, fetchPolicy: 'network-only' });

    const formatDateTime = useFormatDateTime();

    const [dealers, initialValues] = useMemo(() => {
        const values = data?.bank;

        if (!values) {
            return [[], null];
        }

        const {
            country: { dealers: dealersInCountry },
            disabledDealerIds,
            establishment,
        } = values;

        return [
            dealersInCountry.map(dealerInCountry => {
                if (disabledDealerIds.includes(dealerInCountry.value)) {
                    return { ...dealerInCountry, isFixed: true };
                }

                return dealerInCountry;
            }),
            {
                ...omit(['country', 'financeProducts', 'disabledDealerIds'], values),
                lastModified: getLastModified(values.version, formatDateTime),
                establishment,
            },
        ];
    }, [data, formatDateTime]);

    if (!loading && !initialValues) {
        // the product does not exist
        return <Redirect to="/scope/countries" />;
    }

    return (
        <Page
            dealers={dealers}
            financeProducts={data?.bank?.financeProducts}
            initialValues={initialValues}
            zones={data?.bank?.country?.zones}
        />
    );
};

export default Edition;
