import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { yesNoOptions } from '../../../../../shared/constants/options';
import FieldContainer from '../../../../template/Field-container';
import SwitchField from '../../../../template/Switch';
import { useFormContext } from './context';

const FixedInterestRate = () => {
    const { disabled, values, change } = useFormContext();

    const show = get('fixedInterestRateSetting.show', values);

    useEffect(() => {
        if (show === undefined) {
            change('fixedInterestRateSetting.show', false);
        }
    }, [show, change]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Show">
                        <Field
                            component={SwitchField}
                            disabled={disabled}
                            name="fixedInterestRateSetting.show"
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                </div>
            </div>
        </div>
    );
};

export default FixedInterestRate;
