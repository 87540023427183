import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import SuffixAction from '../../ui/form/SuffixAction';

const PasswordSuffix = ({ component: Component = SuffixAction, showPassword, togglePassword }) => {
    const icon = showPassword ? faEye : faEyeSlash;

    return (
        <Component onClick={togglePassword} type="button">
            <Icon icon={icon} fixedWidth />
        </Component>
    );
};

PasswordSuffix.propTypes = {
    component: PropTypes.elementType,
    showPassword: PropTypes.bool.isRequired,
    togglePassword: PropTypes.func.isRequired,
};

export default PasswordSuffix;
