import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IconBox } from './ui';

const MenuLink = ({ className, icon, label, to }) => (
    <Link className={className} to={to}>
        <p>{label}</p>
        <IconBox>
            {typeof icon === 'object' ? (
                <FontAwesomeIcon icon={icon} />
            ) : (
                <img alt="menu icon" className="icon__svg" src={icon} />
            )}
        </IconBox>
    </Link>
);

MenuLink.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
};

export default styled(MenuLink)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 18px;
    color: #b8beca;

    &:hover,
    &.highlight {
        color: white;
        background-color: rgba(255, 255, 255, 0.1);
        text-decoration: none;

        ${IconBox} {
            color: white;
        }
    }

    p {
        padding: 10px 0 0 0;
        text-align: center;
        line-height: 1;
    }

    .icon__svg {
        filter: invert(1);
        width: 1.125em;
    }
`;
