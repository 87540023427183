import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { EventExternalSite } from '../../../../schema';
import { getLastModified } from '../../../../utilities/forms';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { getEvent } from './Edition.graphql';
import Page from './Page';

const Edition = () => {
    const { id } = useParams();
    const variables = { id };
    const { data, loading } = useQuery(getEvent, { variables, fetchPolicy: 'network-only' });

    const formatDateTime = useFormatDateTime();

    const initialValues = useMemo(() => {
        const values = data?.event;

        if (!values) {
            return null;
        }

        return {
            ...values,
            lastModified: getLastModified(values.version, formatDateTime),
            setting: {
                ...values.setting,
                allowFinder: values.setting.externalSite === EventExternalSite.PORSCHEFINDER,
                enablePriceDisclaimer:
                    !!values.setting.priceDisclaimers?.length ||
                    !!values.setting.financePriceDisclaimers?.length ||
                    !!values.setting.insurancePriceDisclaimers?.length,
                priceDisclaimers: values.setting.priceDisclaimers || [],
                financePriceDisclaimers: values.setting.financePriceDisclaimers || [],
                insurancePriceDisclaimers: values.setting.insurancePriceDisclaimers || [],
                isReservationInstructionEnabled:
                    !!values.setting.reservationInstruction || !!values.setting.reservationPeriod,
                reservationInstruction: values.setting.reservationInstruction || null,
                access: values.setting.allowPrivateAccess,
                bookingPayment: {
                    amount: values.setting.bookingPayment?.amount || 0,
                },
            },
        };
    }, [data, formatDateTime]);

    if (loading) {
        return null;
    }

    if (!initialValues) {
        // the product does not exist
        return <Redirect to="/event" />;
    }

    return <Page initialValues={initialValues} />;
};

export default Edition;
