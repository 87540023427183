import { renderCurrency } from '../components/fields/display/CurrencyField';
import UpdateNumeric from '../components/fields/edition/UpdateNumeric';
import { weightFieldAfter } from '../utils';
import fieldTemplate from './fieldTemplate';

const getDefaultCOE = () => null;

const coeField = {
    // copy template
    ...fieldTemplate,

    // setup meta
    label: t => t('calculator.label.coe'),
    weight: weightFieldAfter('paymentMode'),

    // setup states
    isViewable: () => true,
    isEditable: () => true,

    // setup display rendering
    getInitialValue: getDefaultCOE,
    getDisplayProperties: () => ({ children: renderCurrency }),

    // setup edition rendering
    editionComponent: UpdateNumeric,
    getEditionProperties: ({ formats }) => ({
        prefix: formats?.currencySymbol,
        precision: formats?.currencyPrecision,
        min: Number.NEGATIVE_INFINITY,
        fixedDecimalScale: formats?.fixedDecimalScale,
        thousandSeparator: formats?.thousandSeparator,
    }),
};

export default coeField;
