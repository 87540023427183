import styled, { css } from 'styled-components';

export const Pagination = styled.div`
    display: flex;
    flex-direction: row;
    color: #9c9c9c;
    height: 33px;
    line-height: 33px;
    font-size: 0.72rem;
`;

export const Text = styled.span`
    flex-grow: 2;
`;

export const Pages = styled.div`
    display: flex;
    flex-direction: row;
    border: 1px solid #9c9c9c;
    border-radius: 3px;
`;

export const Page = styled.button`
    text-transform: ${props => props.theme.casing};
    outline: none;
    border-right: 1px solid #ced4da;
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
    cursor: pointer;
    padding: 0 10px;
    color: #9c9c9c;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    text-transform: uppercase;

    ${props =>
        props.active &&
        css`
            color: #000000;
        `}
    &:focus {
        outline: none;
    }

    &:last-child {
        border-right: 0;
    }
`;
