import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNil, get, flow } from 'lodash/fp';
import PropType from 'prop-types';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import { useContentTranslation } from '../../../../i18n';
import { ApplicationStages, StageType } from '../../../../schema';
import withCompanyFormatting from '../../../../utils/withCompanyFormatting';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { useFormContext } from './context';

const ApplicationStatus = styled.div`
    width: 80px;
    padding: 4px 8px;
    font-size: 0.57rem;
    text-align: center;
    border-radius: 3px;
    background: ${props => props.background || 'inherit'};
    color: white;

    ${props =>
        !props.background &&
        css`
            border: 1px solid #dedede;
            color: black;
        `}

    @media (max-width: 860px) {
        float: right;
        margin-top: -40px;
    }
`;

const JourneySection = styled.div`
    .journey-section {
        color: black;
        margin-bottom: 15px;
        .title {
            padding: 6px 0 9.5px 0;
            font-size: 1.28rem;
            color: #000000;
            height: 39px;
        }
        .journey-item {
            border: 1px solid #dedede;
            padding: 15px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 15px;
            font-size: 0.85rem;
            .quote-info {
                flex: 1;
                display: block;
            }
            .stage-info {
                flex: 5;
                display: flex;
                flex-direction: row;
                .stage-flow-item {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    cursor: pointer;
                    .stage-icon {
                        width: 30px;
                        height: 30px;
                        min-height: 30px;
                        min-width: 30px;
                        border-radius: 15px;
                        font-size: 1.07rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 5px;
                        &.checked {
                            color: #4fa2ff;
                            border: 1px solid #4fa2ff;
                        }
                        &.in-progress {
                            color: white;
                            background: #4fa2ff;
                            border: 1px solid #4fa2ff;
                        }
                        &.times {
                            color: #c10034;
                            border: 1px solid #c10034;
                        }
                        &.disabled {
                            color: #dedede;
                            border: 1px solid #dedede;
                        }
                    }
                    .info {
                        padding-top: 10px;
                        margin: 0 5px;
                        .info-title {
                            text-transform: uppercase;
                        }
                        .info-item {
                            color: #888;
                            font-size: 0.72rem;
                        }
                    }
                    &.disabled {
                        color: #dedede;
                        cursor: not-allowed;
                        .info {
                            .info-item {
                                color: #dedede;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 860px) {
        .journey-section {
            .journey-item {
                flex-direction: column;
                .quote-info {
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    border-bottom: 1px dashed #dedede;
                }
                .stage-info {
                    flex-wrap: wrap;
                    .stage-flow-item {
                        width: 145px;
                        min-width: 145px;
                        max-width: 145px;
                        min-height: 60px;
                    }
                }
            }
        }
    }
`;

const renderStageIcon = (stage, index) => {
    switch (stage) {
        case StageType.DECLINED:
            return (
                <div className="stage-icon times">
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            );
        case StageType.PRESENT:
            return <div className="stage-icon in-progress">{index + 1}</div>;
        case StageType.FINISHED:
            return (
                <div className="stage-icon checked">
                    <FontAwesomeIcon icon={faCheck} />
                </div>
            );
        default:
            return <div className="stage-icon disabled" />;
    }
};

const renderStage = ({ name, stage, extra, date }, index, id, onClick, formatDateTime) => (
    <div className={`stage-flow-item ${isNil(stage) ? 'disabled' : ''} `} onClick={() => onClick(id, name)}>
        {renderStageIcon(stage, index)}
        <div className="info">
            <div className="info-title">{name}</div>
            <div className="info-item">{date && formatDateTime(date)}</div>
            {extra && extra.map(extraInfo => <div className="info-item"> {extraInfo}</div>)}
        </div>
    </div>
);

const renderApplication = (application, formatCurrency, onClick, formatDateTime, { ct }) => (
    <div key={application.id} className="journey-item">
        <div className="quote-info">
            <div>{flow([get('variant.name'), ct])(application)}</div>
            <div>{flow([get('financeProduct.name'), ct])(application)}</div>
            <div>{formatCurrency(get('calculator.loan.amount', application))}</div>
            <ApplicationStatus background={application.statusText.color}>
                {application.statusText.label}
            </ApplicationStatus>
        </div>
        <div className="stage-info">
            {application.stages.map((applicationStage, index) =>
                renderStage(
                    applicationStage,
                    index,
                    applicationStage.name === ApplicationStages.INSURANCE
                        ? application.insuranceApplication?.version.id
                        : application.version.id,
                    onClick,
                    formatDateTime
                )
            )}
        </div>
    </div>
);

const ApplicationJourney = ({ formats }) => {
    const history = useHistory();
    const { journeys, values } = useFormContext();
    const formatDateTime = useFormatDateTime();
    const contentTranslation = useContentTranslation();

    const redirect = useCallback(
        (id, stage) => {
            switch (stage) {
                case ApplicationStages.LEAD:
                    history.push(`/workflow/leads/${id}`, { customerId: values.id });
                    break;

                case ApplicationStages.FINANCE:
                    history.push(`/workflow/applications/${id}`, { customerId: values.id });
                    break;

                case ApplicationStages.ACTIVATION:
                    history.push(`/workflow/activation/${id}`, { customerId: values.id });
                    break;

                case ApplicationStages.INSURANCE:
                    history.push(`/workflow/insuranceApplications/${id}`, { customerId: values.id });
                    break;

                default:
                    break;
            }
        },
        [history, values]
    );

    const { formatCurrencyDown } = formats;

    return (
        <JourneySection>
            {Object.entries(journeys).map(([channel, applications]) => (
                <div key={channel} className="journey-section">
                    <div className="title">{channel}</div>
                    {applications.map(application =>
                        renderApplication(application, formatCurrencyDown, redirect, formatDateTime, contentTranslation)
                    )}
                </div>
            ))}
        </JourneySection>
    );
};

ApplicationJourney.propTypes = {
    formats: PropType.shape({
        formatCurrencyDown: PropType.func,
    }),
};

export default withCompanyFormatting(ApplicationJourney);
