import { useQuery } from '@apollo/client';
import { flow } from 'lodash';
import { find, get, isNil, orderBy } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useContentTranslation } from '../../../i18n';
import { getCurrentCountry, getZoneId } from '../../../selectors';
import { applicationChannelOptions } from '../../../shared/constants/options';
import { searchOnKeys } from '../../../utilities/fp';
import useFormatDateTime from '../../shared/useFormatDateTime';
import { List, cells, ListSearch, DealerSelectionDropDown } from '../../ui/lists';
import usePaging from '../../utilities/usePaging';
import { getData, getDataByCountryId } from './CustomerLists.graphql';

const searchFields = [
    'name.value',
    'zone.code',
    'latestApplication.assignee.name',
    'latestApplication.identifier',
    'latestApplication.status',
];

const sortItems = (items, [sortKey, sortOrder]) => {
    // we always sort by value key
    return orderBy(sortKey, sortOrder, items);
};

const CustomerLists = () => {
    const { formatPath } = useContentTranslation();
    const history = useHistory();
    const [dealerIds, setDealerIds] = useState(null);

    const { id: countryId } = useSelector(getCurrentCountry);
    const zoneId = useSelector(getZoneId);

    // based on zone or channel
    const query = zoneId ? getData : getDataByCountryId;
    const variables = { id: zoneId || countryId, dealerIds };

    const { data, loading, error } = useQuery(query, {
        fetchPolicy: 'cache-and-network',
        variables,
        skip: isNil(dealerIds),
    });
    const items = data?.results?.items || [];
    const isLoading = loading && items.length <= 0;

    // searching
    const [search, setSearch] = useState('');
    const searchMethod = useMemo(() => searchOnKeys(searchFields, items), [items]);
    const matchedItems = useMemo(() => searchMethod(search), [search, searchMethod]);

    // sorting
    const [sortedOn, onSort] = useState(['name', 'asc']);
    const sortedItems = useMemo(() => sortItems(matchedItems, sortedOn), [matchedItems, sortedOn]);

    // paging
    const [pagedItems, paging] = usePaging(sortedItems, { search });

    const formatDateTime = useFormatDateTime();

    const columns = useMemo(
        () => [
            { name: 'Customer', id: 'name.value', renderCell: get('name.value'), hasSort: true, underline: true },
            { name: 'Zone', id: 'zone.code', renderCell: get('zone.code'), hasSort: true },
            {
                name: 'Dealer',
                id: 'dealer.name',
                renderCell: get(formatPath('latestApplication.dealer.name')),
                hasSort: true,
            },
            {
                name: 'Salesperson',
                id: 'latestApplication.assignee.name',
                renderCell: get('latestApplication.assignee.name'),
                hasSort: true,
            },
            {
                name: 'Channel',
                id: 'latestApplication.channel',
                renderCell: flow([
                    get('latestApplication'),
                    application =>
                        find(
                            option => option.channel === application?.channel && option.access === application?.access,
                            applicationChannelOptions
                        )?.label,
                ]),
            },
            {
                name: 'Latest Lead/Appl. ID',
                id: 'latestApplication.identifier',
                renderCell: get('latestApplication.identifier'),
                hasSort: true,
            },
            {
                name: 'Status',
                id: 'latestApplication.status',
                renderCell: cells.renderStatusForCustomer(get('latestApplication.statusText')),
                hasSort: true,
            },
            {
                name: 'Last Activity',
                id: 'latestApplication.version.updatedAt',
                renderCell: cells.renderDateTime('latestApplication.version.updatedAt', formatDateTime),
                hasSort: true,
            },
        ],
        [formatDateTime, formatPath]
    );

    return (
        <List
            columns={columns}
            error={error}
            headerBottomComponent={<DealerSelectionDropDown dealerIds={dealerIds} onValueChanged={setDealerIds} />}
            headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
            items={pagedItems}
            loading={isLoading}
            onItemClick={item => history.push(`/customer/${item.id}`)}
            onSort={onSort}
            paging={paging}
            sortedOn={sortedOn}
            title="Customers"
        />
    );
};

export default CustomerLists;
