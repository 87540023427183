import { get } from 'lodash/fp';
import { PromoCodeUnit, Unit } from '../../../../../schema';
import * as validators from '../../../../../utils/validators';

const validate = validators.compose(
    validators.requiredString('identifier'),
    validators.requiredDate('period.start'),
    validators.requiredString('period.startTimeZone'),
    validators.requiredDate('period.end'),
    validators.requiredString('period.endTimeZone'),
    validators.startDateMayNotExceedEndDate('period.start', 'period.end'),
    validators.only(values => get('unit', values) === Unit.PERCENTAGE, validators.requiredPercentage('value')),
    validators.only(
        values => get('unit', values) !== PromoCodeUnit.NOTUSED,
        validators.requiredNumberAndGreaterThan('value', 0)
    ),
    validators.requiredNumberGreaterOrEqualThanNumber('initialQuantity', 0),
    validators.requiredNumberGreaterOrEqualThanNumber('remainingQuantity', 0),
    validators.requiredBoolean('deductValue'),
    validators.requiredString('unit'),
    validators.requiredBoolean('isActive')
);

export default validate;
