import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getFormValues, reduxForm } from 'redux-form';
import { FormTabContainer } from '../../../../../../containers/Layout';
import ActivityLog from '../../application/Form/ActivityLog';
import { Context as ActivityContext } from '../../application/Form/context';
import Lead from './Lead';
import { Context } from './context';
import validate from './validate';

const Form = props => {
    const { form } = props;
    const values = useSelector(getFormValues(form));
    const context = { ...props, values };

    return (
        <Context.Provider value={context}>
            <FormTabContainer>
                <Tabs className="tabs">
                    <TabList>
                        <Tab>Detail</Tab>
                        <Tab>Activity Log</Tab>
                    </TabList>
                    <TabPanel>
                        <Lead />
                    </TabPanel>
                    <TabPanel>
                        <ActivityContext.Provider value={context}>
                            <ActivityLog canAddComment />
                        </ActivityContext.Provider>
                    </TabPanel>
                </Tabs>
            </FormTabContainer>
        </Context.Provider>
    );
};

Form.propTypes = {
    form: PropTypes.string.isRequired,
};

export default reduxForm({ form: 'lead', validate })(Form);
