import styled from 'styled-components';

const PureError = styled.div`
    color: #ff0000;
    font-size: 0.72rem;
    margin-bottom: 5px;
    margin-top: 5px;
`;

export default PureError;
