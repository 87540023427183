import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider as Provider } from 'styled-components';
import { getCompany, getRuntimeSettings } from '../../selectors';
import defaultTheme from '../../utilities/constants/defaultTheme';

const getCompanyTheme = ({ color, font }, { casing }) => ({
    ...defaultTheme(casing),
    themeHighlightColour: color,
    webFontUrl: font?.url,

    calculator: {
        ...defaultTheme(casing).calculator,
        valueColor: color,
    },
});

const ThemeProvider = ({ children }) => {
    // get the company from redux
    const company = useSelector(getCompany);
    const settings = useSelector(getRuntimeSettings);

    const theme = useMemo(() => {
        if (!company) {
            // use the default theme
            // we do not have any company with which we can override the default theme
            return defaultTheme(settings.casing);
        }

        // override the default theme
        return getCompanyTheme(company, settings);
    }, [company, settings]);

    return <Provider theme={theme}>{children}</Provider>;
};

ThemeProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ThemeProvider;
