import { get, getOr } from 'lodash/fp';
import React, { useContext } from 'react';
import { ReduxFormContext } from 'redux-form';
import { BankPresetOption } from '../../../../schema';
import SelectField from '../../../shared/form-v2/SelectField';
import TextField from '../../../shared/form-v2/TextField';
import SubTitle from '../../../ui/SubTitle';
import { getLabelWithFlag } from './CustomerDetails';
import { useFormContext } from './context';

const AddressDetails = () => {
    const { options } = useFormContext();
    const { sectionPrefix, getValues } = useContext(ReduxFormContext);
    const formValues = getValues();
    const residentialAddress = get(
        sectionPrefix ? `${sectionPrefix}.details.residentialAddress` : 'details.residentialAddress',
        formValues
    );
    const residentialAddressSource = residentialAddress?.source;

    const isAffinBank = formValues?.financeProduct?.bank?.presetOption === BankPresetOption.AFFINBANK;
    const countryCode = get('zone.country.code', formValues);

    const customer = getOr(formValues, `${sectionPrefix}`, formValues);

    return (
        <>
            <SubTitle>Residential Address</SubTitle>
            <div className="row">
                {isAffinBank && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={getLabelWithFlag(
                                'Address Type',
                                customer?.details?.thirdParty?.affinBank?.addressType.source
                            )}
                            name="details.thirdParty.affinBank.addressType.value"
                            options={getOr([], 'affinBank.addressTypes', options)}
                            disabled
                        />
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    {residentialAddress?.countryName ? (
                        <TextField
                            label={getLabelWithFlag('Country', residentialAddressSource)}
                            name="details.residentialAddress.countryName"
                            disabled
                        />
                    ) : (
                        <SelectField.Outline
                            label={getLabelWithFlag('Country', residentialAddressSource)}
                            name="details.residentialAddress.country"
                            options={getOr([], 'countries', options)}
                            disabled
                        />
                    )}
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag('State/City', residentialAddressSource)}
                        name="details.residentialAddress.city"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag('Postal Code', residentialAddressSource)}
                        name="details.residentialAddress.postalCode"
                        disabled
                    />
                </div>
                {countryCode === 'TH' ? (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={getLabelWithFlag('Address', residentialAddressSource)}
                            name="details.residentialAddress.fullAddress"
                            disabled
                        />
                    </div>
                ) : (
                    <>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField
                                label={getLabelWithFlag('Street', residentialAddressSource)}
                                name="details.residentialAddress.street"
                                disabled
                            />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField
                                label={getLabelWithFlag('Block', residentialAddressSource)}
                                name="details.residentialAddress.block"
                                disabled
                            />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField
                                label={getLabelWithFlag('Building', residentialAddressSource)}
                                name="details.residentialAddress.building"
                                disabled
                            />
                        </div>
                    </>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag('Unit', residentialAddressSource)}
                        name="details.residentialAddress.unit"
                        disabled
                    />
                </div>
            </div>
        </>
    );
};

export default AddressDetails;
