import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { ConsentOrDeclarationOwnerType } from '../../../../../../schema';
import Page from '../../../../Consent/Page';

const CreateConsentFromEvent = () => {
    const { eventId } = useParams();

    const initialValues = useMemo(
        () => ({
            owner: {
                id: eventId,
                type: ConsentOrDeclarationOwnerType.EVENT,
            },
            hasLegalMarkup: false,
            hasCheckbox: false,
            isMandatory: false,
            isActive: true,
            identifier: '',
        }),
        [eventId]
    );

    if (!eventId) {
        return <Redirect to="/events" />;
    }

    return <Page id={eventId} initialValues={eventId ? initialValues : null} />;
};

export default CreateConsentFromEvent;
