import styled from 'styled-components';

const LoginFooter = styled.div`
    color: #aaa;
    font-family: 'Company Font', 'Roboto', sans-serif !important;
    font-size: 0.8rem;
    text-align: center;
`;

export default LoginFooter;
