import * as exportApi from '../api/export';
import { getAccessToken, getContext } from '../selectors';
import { withLoading } from './loading';

const exportFactory = exportMethod => (...args) => (dispatch, getState) => {
    // get data from state
    const state = getState();
    const token = getAccessToken(state);
    const context = getContext(state);

    // build http headers
    const headers = token ? { Authorization: `Bearer ${token}` } : {};

    // get the promise
    const promise = exportMethod(context, headers, ...args);

    return dispatch(withLoading(promise));
};

export const exportMakes = exportFactory(exportApi.exportMakes);

export const exportModels = exportFactory(exportApi.exportModels);

export const exportSubModels = exportFactory(exportApi.exportSubModels);

export const exportVariants = exportFactory(exportApi.exportVariants);

export const exportOptions = exportFactory(exportApi.exportOptions);

export const exportInventories = exportFactory(exportApi.exportInventories);

export const exportApplications = exportFactory(exportApi.exportApplications);

export const exportInsuranceApplications = exportFactory(exportApi.exportInsuranceApplications);

export const exportAppointments = exportFactory(exportApi.exportAppointments);

export const exportTranslations = exportFactory(exportApi.exportTranslations);

export const exportEventsApplication = exportFactory(exportApi.exportEventsApplication);

export const exportApplicationDocuments = exportFactory(exportApi.exportApplicationDocuments);
