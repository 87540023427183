import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getFormValues } from 'redux-form';
import { addNotification } from '../../../../../actions';
import { ApplicationActivationStatus } from '../../../../../schema';
import { getGlobalPermissions } from '../../../../../selectors';
import * as notification from '../../../../../shared/constants/notification';
import { handleResponseError } from '../../../../../utilities/forms';
import { getApplicationPermissions } from '../../../../../utils/permissions';
import { withModal } from '../../../../Modal';
import FormLayout from '../../../../ui/form/FormLayout';
import { cancel, complete, activate } from '../../common/shared/Action.graphql';
import useConfirm from '../../common/utilities/useConfirm';
import Form from './Form';

const {
    APPLICATION_CANCEL_NOTIFICATION,
    APPLICATION_COMPLETE_NOTIFICATION,
    ACTIVATION_ACTIVATE_NOTIFICATION,
} = notification;

const getActivateLabel = application => {
    switch (application?.activationStatus) {
        case ApplicationActivationStatus.FAILED:
            return 'Re-Try Activate';
        case ApplicationActivationStatus.SUBMITTED:
        case ApplicationActivationStatus.RECEIVED:
        default:
            return 'Activate';
    }
};

const Page = ({ initialValues = null, modal }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { mayManageApplications } = useSelector(getGlobalPermissions);

    const client = useApolloClient();

    const onCancel = useCallback(() => history.goBack(), [history]);

    const values = useSelector(getFormValues('activation'));

    const { state } = useLocation();
    const handleRedirection = useCallback(
        type => {
            const { identifier } = values || {};

            switch (type) {
                case 0:
                    dispatch(addNotification(APPLICATION_CANCEL_NOTIFICATION(identifier)));
                    break;
                case 1:
                    dispatch(addNotification(APPLICATION_COMPLETE_NOTIFICATION(identifier)));
                    break;
                case 5:
                    dispatch(addNotification(ACTIVATION_ACTIVATE_NOTIFICATION(identifier)));
                    break;
                default:
                    break;
            }
            history.push(`/customer/${state?.customerId}`);
        },
        [values, history, state.customerId, dispatch]
    );

    const onVoid = useCallback(() => {
        client
            .mutate({ mutation: cancel, variables: { id: initialValues?.id } })
            .then(() => handleRedirection(0))
            .catch(handleResponseError);
    }, [client, handleRedirection, initialValues]);

    const onComplete = useCallback(() => {
        client
            .mutate({ mutation: complete, variables: { id: initialValues?.id } })
            .then(() => handleRedirection(1))
            .catch(handleResponseError);
    }, [client, handleRedirection, initialValues]);

    const onActivate = useCallback(() => {
        client
            .mutate({ mutation: activate, variables: { id: initialValues?.id } })
            .then(() => handleRedirection(5))
            .catch(handleResponseError);
    }, [client, handleRedirection, initialValues]);

    const {
        mayVoidApplication: mayVoid,
        mayCompleteApplication: mayComplete,
        mayValidateApplication: mayValidate,
    } = useMemo(() => (initialValues && getApplicationPermissions(initialValues)) || {}, [initialValues]);

    const activateDisabled = useMemo(
        () =>
            !mayValidate ||
            !mayManageApplications ||
            initialValues?.activationStatus === ApplicationActivationStatus.ACTIVATED,
        [initialValues, mayManageApplications, mayValidate]
    );

    const action = useConfirm(modal);

    const activateLabel = useMemo(() => getActivateLabel(initialValues), [initialValues]);

    const moreActions = useMemo(
        () => [
            mayManageApplications &&
                mayVoid && {
                    label: 'void',
                    onAction: () => action(0, onVoid),
                },
            mayManageApplications &&
                mayComplete && {
                    label: 'complete',
                    onAction: () => () => action(1, onComplete),
                },
            mayManageApplications &&
                mayValidate && {
                    label: activateLabel,
                    disabled: activateDisabled,
                    onAction: () => action(5, onActivate),
                },
        ],
        [
            action,
            activateDisabled,
            activateLabel,
            mayComplete,
            mayManageApplications,
            mayValidate,
            mayVoid,
            onActivate,
            onComplete,
            onVoid,
        ]
    );

    return (
        <FormLayout
            bodyComponent={initialValues && <Form initialValues={initialValues} disabled />}
            moreActions={moreActions}
            onCancel={onCancel}
            title="Activation"
        />
    );
};

Page.propTypes = {
    initialValues: PropTypes.shape(),
    modal: PropTypes.shape(),
};

export default withModal(Page);
