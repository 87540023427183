import * as yup from 'yup';
import { createFormValidation, requiredString } from '../../../../../utilities/forms';

const schema = yup.object().shape({
    name: requiredString('Name is mandatory'),
});

const validate = createFormValidation(schema);

export default validate;
