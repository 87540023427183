import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { FormSection } from 'redux-form';
import { getCurrentCountry } from '../../../../../../selectors';
import ApplicantDetails from '../../../../Customer/Form/ApplicantDetails';
import { Context } from '../../../../Customer/Form/context';
import { useCustomerOptions } from '../../../../Customer/useOptions';
import { useFormContext } from './context';

export type GuarantorProps = {
    disabled?: boolean;
};

const Guarantor = (props: GuarantorProps) => {
    const originalContext = useFormContext();
    const { mask, currency, rounding } = useSelector(getCurrentCountry);
    const options = useCustomerOptions();
    const newContext = { ...originalContext, ...props, mask, currency, rounding, options };

    return (
        // @ts-ignore
        <Context.Provider value={newContext}>
            <FormSection name="guarantor">
                <ApplicantDetails name="Guarantor Details" />
            </FormSection>
        </Context.Provider>
    );
};

export default memo(Guarantor);
