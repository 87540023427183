import styled from 'styled-components';
import { Container } from '../input/PureInput';

const OutlineContainer = styled.div`
    position: relative;
    min-height: 40px;
    display: flex;
    align-items: baseline;
    border-bottom: 1px #ced4da solid;

    label {
        font-size: 14px;
        font-style: normal;
        font-weight: 100;
        color: #666666;
        touch-action: manipulation;
        white-space: nowrap;
        padding: 9px 0;
        margin-bottom: 0px;
        line-height: 20px;
        margin-right: 0.5rem;
    }

    ${Container} {
        padding: 9px 0;
        margin-bottom: 0px;
        line-height: 20px;
    }
`;

export default OutlineContainer;
