import { useQuery } from '@apollo/client';
import { getOr, get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { compose } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import { FormTabContainer } from '../../../../../containers/Layout';
import { useContentTranslation } from '../../../../../i18n';
import { AssetCondition } from '../../../../../schema';
import { getCurrentCountry } from '../../../../../selectors';
import Gallery from './Gallery';
import Languages from './Languages';
import MainDetails from './MainDetails';
import Options from './Options';
import Prices from './Prices';
import TechnicalDetails from './TechnicalDetails';
import UsedCarDetails from './UsedCarDetails';
import { Context } from './context';
import { getData } from './data.graphql';
import validate from './validate';

const Form = props => {
    const { form, disabled, change, languageOptions } = props;
    const displayMultiLanguage = languageOptions.length > 1;
    const { formatPath } = useContentTranslation();
    const values = useSelector(getFormValues(form));

    const country = useSelector(getCurrentCountry);
    const variables = { countryId: country.id };
    const { data } = useQuery(getData, { variables, fetchPolicy: 'cache-and-network' });
    const models = getOr([], 'models.items', data).map(option => ({
        ...option,
        label: get(formatPath('label'), option),
    }));
    const subModels = getOr([], 'subModels.items', data).map(option => ({
        ...option,
        label: get(formatPath('label'), option),
    }));

    const context = { ...props, values, models, subModels, disabled };

    const images = get('images', values);

    const assetCondition = get('assetCondition', values);

    return (
        <Context.Provider value={context}>
            <FormTabContainer>
                <Tabs className="tabs">
                    <TabList>
                        <Tab>Main Details</Tab>
                        {displayMultiLanguage && <Tab>Multi-Language</Tab>}
                        <Tab>Images</Tab>
                    </TabList>
                    <TabPanel>
                        <MainDetails />
                        {assetCondition === AssetCondition.USED && <UsedCarDetails disabled={disabled} />}
                        <TechnicalDetails />
                        <Prices />
                        <Options />
                    </TabPanel>
                    {displayMultiLanguage && (
                        <TabPanel>
                            <Languages />
                        </TabPanel>
                    )}
                    <TabPanel>
                        <Gallery change={change} disabled={disabled} fieldName="images" images={images} />
                    </TabPanel>
                </Tabs>
            </FormTabContainer>
        </Context.Provider>
    );
};

Form.propTypes = {
    change: PropTypes.func,
    disabled: PropTypes.bool,
    form: PropTypes.string.isRequired,
    languageOptions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
};

export default compose(reduxForm({ form: 'variant', validate }), memo)(Form);
