import { findTimeZone, getUnixTime, getZonedTime, setTimeZone } from 'timezone-support';
import { toDate } from '../utilities/fp';

export const convertLocalToUTC = (date, timeZone) => {
    const parsedDate = toDate(date);
    const target = findTimeZone(timeZone);
    const time = setTimeZone(parsedDate, target, { useUTC: false });

    return new Date(getUnixTime(time));
};

const localTimeZone = findTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);

export const convertUTCToLocal = (date, timeZone) => {
    const target = findTimeZone(timeZone);
    const zonedTime = getZonedTime(new Date(date), target);
    const localTime = setTimeZone(zonedTime, localTimeZone);

    return new Date(getUnixTime(localTime));
};
