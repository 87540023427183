import React from 'react';
import { Field } from 'redux-form';
import { onOffOptions } from '../../../../../shared/constants/options';
import FieldContainer from '../../../../template/Field-container';
import FileUpload from '../../../../template/FileUpload';
import InputField from '../../../../template/Input';
import Portlet from '../../../../template/Portlet';
import SwitchField from '../../../../template/Switch';
import DialingCodeField from '../../../../template/options-field/DialingCodeField';
import { useFormContext } from './context';

const MainDetails = () => {
    const { disabled, isUpdate } = useFormContext();

    return (
        <Portlet name="Main Details" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8 col-sm-12 col-xs-12">
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <FieldContainer label="User Name">
                                    <Field
                                        component={InputField}
                                        disabled={isUpdate || disabled}
                                        max={40}
                                        min={3}
                                        name="username"
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <FieldContainer label="Name">
                                    <Field component={InputField} disabled={disabled} max={40} min={3} name="name" />
                                </FieldContainer>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <FieldContainer label="Email">
                                    <Field component={InputField} disabled={disabled} max={50} name="email" />
                                </FieldContainer>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <FieldContainer label="Phone">
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ flex: 1 }}>
                                            <DialingCodeField disabled={disabled} name="phonePrefix" />
                                        </div>
                                        <div style={{ width: '10px' }} />
                                        <div style={{ flex: 2 }}>
                                            <Field component={InputField} disabled={disabled} name="phone" />
                                        </div>
                                    </div>
                                </FieldContainer>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <FieldContainer label="Alias">
                                    <Field component={InputField} disabled={disabled} max={20} name="alias" />
                                </FieldContainer>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <FieldContainer label="Active">
                                    <Field
                                        component={SwitchField}
                                        disabled={disabled}
                                        name="isActive"
                                        options={onOffOptions}
                                    />
                                </FieldContainer>
                            </div>
                            {isUpdate && (
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <FieldContainer label="Status">
                                        <Field component={InputField} name="status" disabled />
                                    </FieldContainer>
                                </div>
                            )}
                        </div>
                        {isUpdate && (
                            <div className="row">
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <FieldContainer label="Last Modified">
                                        <Field component={InputField} name="lastModified" disabled />
                                    </FieldContainer>
                                </div>
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <FieldContainer label="Last Signed In">
                                        <Field component={InputField} name="lastLoginAt" disabled />
                                    </FieldContainer>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Image">
                            <Field accept=".jpg,.png,.jpeg" component={FileUpload} disabled={disabled} name="image" />
                        </FieldContainer>
                    </div>
                </div>
            </div>
        </Portlet>
    );
};

export default MainDetails;
