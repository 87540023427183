import { getOr } from 'lodash/fp';

const bindFields = (run, context, isDirty) => {
    const { fields, snapshot, disabled, t } = context;

    return run(
        'boundFields',
        () => {
            const boundUpdates = {};
            Object.entries(fields).forEach(([field, { updates }]) => {
                Object.entries(updates).forEach(([fieldSource, updateFunction]) => {
                    if (!boundUpdates[fieldSource]) {
                        // creat an empty object for this field
                        boundUpdates[fieldSource] = {};
                    }
                    boundUpdates[fieldSource][field] = updateFunction;
                });
            });

            // compute weight and fallback values on fields
            // abd bind updates
            return Object.entries(fields).reduce((acc, [name, baseField]) => {
                // copy the field and bind updates
                const field = { ...baseField, updates: boundUpdates[name] || {} };

                if (field.label instanceof Function) {
                    // translate label
                    field.label = field.label(t);
                }

                if (field.weight instanceof Function) {
                    // override the weight
                    field.weight = field.weight(fields);
                }

                // look for snapshot configuration
                if (!isDirty && snapshot) {
                    // assign the fallback value to
                    const { getValue = getOr('', name), isViewable = null, component } = baseField.snapshot;
                    // override some data
                    field.displayComponent = component;
                    field.snapshotValue = getValue(snapshot, context);

                    if (isViewable) {
                        // we also override the isViewable property
                        field.isViewable = (...args) => isViewable(snapshot, ...args);
                    }
                }

                if (disabled) {
                    // we need to ensure every field is not editable
                    field.isEditable = () => false;
                }

                return { ...acc, [name]: field };
            }, {});
        },
        [fields, snapshot, disabled, isDirty, t]
    );
};

export default bindFields;
