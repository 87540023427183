import React from 'react';
import { Field } from 'redux-form';
import { yesNoOptions, insuranceSigningModes, insuranceOptions } from '../../../../../shared/constants/options';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import ApvPortlet from '../../../../template/Portlet';
import SwitchField from '../../../../template/Switch';
import InsuranceIntegration from './InsuranceIntegration';

const InsuranceApplication = () => {
    return (
        <ApvPortlet name="Insurance Application" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Submission Approval">
                            <Field component={SwitchField} name="signing.onCreate" options={insuranceSigningModes} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Resubmission Approval">
                            <Field component={SwitchField} name="signing.onUpdate" options={insuranceSigningModes} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField
                            label="Insurance Option"
                            name="option"
                            options={insuranceOptions}
                            placeholder="Select Insurance Option"
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Integration">
                            <Field component={SwitchField} name="hasIntegration" options={yesNoOptions} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Send Email to Customer">
                            <Field component={SwitchField} name="sendEmailToCustomer" options={yesNoOptions} />
                        </FieldContainer>
                    </div>
                </div>
                <InsuranceIntegration />
            </div>
        </ApvPortlet>
    );
};

export default InsuranceApplication;
