const createRunner = initialValues => {
    const runner = {
        // previous form values
        previousValues: initialValues,
        // results from memoized function with run will be stored here
        results: {},
        // error map
        errors: {},
        // this function work like useMemo
        // but is not based on hook so it may be called in loop
        run(key, factory, dependencies) {
            // get the previous dependencies
            const previousRun = this.results[key];

            if (
                !previousRun ||
                previousRun.dependencies.some((value, index) => value !== dependencies[index]) ||
                previousRun.dependencies.length !== dependencies.length
            ) {
                // either it didn't run yet
                // or the dependencies changed
                const result = {
                    value: factory instanceof Function ? factory() : factory,
                    dependencies,
                    rev: (previousRun?.rev || 0) + 1,
                };
                // persist it
                this.results[key] = result;

                return result.value;
            }

            return previousRun.value;
        },
    };

    // bind the run method to always access this
    runner.run = runner.run.bind(runner);

    return runner;
};

export default createRunner;
