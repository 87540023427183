import { PureError, RawTextarea, RawTextareaWrapper } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React, { cloneElement } from 'react';
import { Field } from 'redux-form';
import PureTextarea from '../../ui/form/PureTextarea';
import Wrapper from '../../ui/form/Wrapper';

export const TextareaInput = props => {
    const {
        input,
        meta,
        label,
        inputComponent: Input = PureTextarea,
        errorComponent: Error = PureError,
        ...inputProps
    } = props;

    const { active, touched, error = null } = meta;
    const hasError = !active && touched && !!error;

    return (
        <Wrapper label={label} meta={meta} name={input.name}>
            {cloneElement(<Input cols={50} rows={3} {...input} {...inputProps} />, { hasError })}
            {hasError && <Error>{error}</Error>}
        </Wrapper>
    );
};

TextareaInput.propTypes = {
    errorComponent: PropTypes.elementType,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    inputComponent: PropTypes.elementType,
    label: PropTypes.string.isRequired,
    meta: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        error: PropTypes.string,
        touched: PropTypes.bool.isRequired,
    }).isRequired,
    wrapperComponent: PropTypes.elementType,
};

const TextareaField = props => <Field component={TextareaInput} {...props} />;

TextareaField.Raw = props => (
    <TextareaField inputComponent={RawTextarea} wrapperComponent={RawTextareaWrapper} {...props} />
);

export default TextareaField;
