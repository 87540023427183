import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash/fp';
import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { logout } from '../../actions';
import { getUser } from '../../selectors';
import NoProfileImage from './NoProfileImage.jpg';
import { DropdownS, DropdownItemS, DropdownMenuS, DropdownToggleS, ToggleContainer } from './UI';

const Setting = () => {
    const user = useSelector(getUser) || {};

    const history = useHistory();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);

    const toggle = useCallback(() => setOpen(state => !state), [setOpen]);

    const links = [
        {
            label: 'My Profile',
            key: 'MY_PROFILE',
            link: '/profile',
            icon: faUserCircle,
            permission: 'mayManageOwnProfile',
        },
        { label: 'Sign Out', key: 'SIGN_OUT' },
    ];

    const onMenuLinkClick = useCallback(
        item => {
            switch (item.key) {
                case 'SIGN_OUT':
                    dispatch(logout('manual'));
                    break;

                default:
                    history.push(item.link);
                    break;
            }
        },
        [history, dispatch]
    );

    const userImageUrl = get('image.url', user);
    const renderAvator = () => (
        <ToggleContainer>
            <img alt="" src={userImageUrl || NoProfileImage} />
            <FontAwesomeIcon icon={faAngleDown} />
        </ToggleContainer>
    );

    const renderItem = link => (
        <DropdownItemS key={link.key} onClick={() => onMenuLinkClick(link)}>
            {link.label}
        </DropdownItemS>
    );

    return (
        <DropdownS isOpen={open} nav={false} toggle={toggle}>
            <DropdownToggleS caret>{renderAvator()}</DropdownToggleS>
            <DropdownMenuS setting="true">{links.map(renderItem)}</DropdownMenuS>
        </DropdownS>
    );
};

export default Setting;
