import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getCurrentCountry } from '../../../../selectors';
import Page from './Page';

const Creation = () => {
    const country = useSelector(getCurrentCountry);

    const { makeId = null } = useParams();

    const initialValues = useMemo(() => ({ countryId: country.id, makeId, isActive: true }), [country.id, makeId]);

    return <Page initialValues={initialValues} />;
};

export default Creation;
