import { get, getOr, pick } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import { exportTranslations } from '../../../../../actions';
import { EventExternalSite, MaskDirection, Provider } from '../../../../../schema';
import { getRuntimeSettings } from '../../../../../selectors';
import {
    decimalPrecisionOptions,
    smtpProviders,
    yesNoOptions,
    dataMaskingCountOptions,
    dataMaskModeOptions,
} from '../../../../../shared/constants/options';
import { useModal } from '../../../../Modal';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import NumberField from '../../../../template/Number';
import PasswordField from '../../../../template/Password';
import Portlet from '../../../../template/Portlet';
import SwitchField from '../../../../template/Switch';
import ImportExport from '../../../../utilities/ImportExport';
import TranslationImportModal from './ImportTranslation';
import { useFormContext } from './context';

const mapIntlValue = (languages, value) => {
    if (typeof value === 'string') {
        return languages.reduce((acc, language) => ({ ...acc, [language]: value }), {});
    }

    return pick(languages, value);
};

const MainDetails = () => {
    const formData = useSelector(getFormValues('country'));
    const { values, change, apiData, events } = useFormContext();
    const settings = useSelector(getRuntimeSettings);

    const selectedLanguages = values?.languages;

    const edition = !!get('lastModified', values);

    const companies = useMemo(
        () =>
            getOr([], 'companies.items', apiData).map(option => ({
                ...option,
                name: option.label,
                label: option.label.en,
            })),
        [apiData]
    );

    const countries = useMemo(
        () =>
            getOr([], 'options.markets', apiData).map(country => ({
                value: country.code,
                label: country.name,
            })),
        [apiData]
    );

    const languages = useMemo(
        () =>
            getOr([], 'options.languages', apiData).map(language => ({
                value: language.code,
                label: `${language.code} - ${language.name}`,
            })),
        [apiData]
    );

    const currencies = useMemo(
        () =>
            getOr([], 'options.currencies', apiData).map(currency => ({
                value: currency.code,
                label: `${currency.code} [${currency.name}]`,
            })),
        [apiData]
    );

    const isAuthenticationRequired = get('email.isAuthenticationRequired', values);
    const matchExistingCustomer = get('matchExistingCustomer', values);
    const emailProvider = get('email.provider', values);
    const maskDirection = get('mask.direction', values);
    const usePorscheFinder = get('usePorscheFinder', values);

    useEffect(() => {
        if (maskDirection === MaskDirection.NONE) {
            change('mask.count', 0);
        }
    }, [change, maskDirection]);

    useEffect(() => {
        if (!usePorscheFinder) {
            change('useAutoplay', false);
        }
    }, [change, usePorscheFinder]);

    // for language import/export modal
    const exportFunction = useCallback(() => exportTranslations(formData), [formData]);
    const ImportExportSet = useCallback(
        () => <ImportExport ImportModal={TranslationImportModal} exportFunction={exportFunction} />,
        [exportFunction]
    );

    const modal = useModal();

    const finderEvents = useMemo(
        () => events?.filter(({ setting }) => setting.externalSite === EventExternalSite.PORSCHEFINDER),
        [events]
    );

    useEffect(() => {
        if (!values.usePorscheFinder && finderEvents?.length) {
            // will not be able turn off
            // when there's events with porsche finder
            change('usePorscheFinder', true);
            // show notification
            modal.confirm({
                title: '',
                content: `Unable to turn "Porsche Finder Integration" off as there is an event tied to: ${finderEvents
                    .map(({ name }) => name)
                    .join(', ')}`,
                options: [],
            });
        }
    }, [change, finderEvents, modal, values.usePorscheFinder]);

    const handleLanguagesChange = useCallback(
        value => {
            // Allow three options if english is one of them else only two.
            if (value.length > 2) {
                change('languages', value.includes('en') ? value : value.slice(0, 2));
            }
        },
        [change]
    );

    const handleSelectChange = useCallback(
        fieldKey => (_, selection) => {
            if (selectedLanguages.length) {
                if (fieldKey === 'country') {
                    change('mlcName', mapIntlValue(selectedLanguages, selection.label));
                }
                if (fieldKey === 'company') {
                    change('company.name', mapIntlValue(selectedLanguages, selection.name));
                    change('company.description', mapIntlValue(selectedLanguages, selection.description));
                }
            }
        },
        [selectedLanguages, change]
    );

    const handleEmailProviderChange = useCallback(
        value => {
            if (value === Provider.SMTP) {
                change('email.fromEmail', '');
            } else {
                // this will be by passed later on submission
                change('email.fromEmail', settings.systemEmail);
            }
        },
        [change, settings.systemEmail]
    );

    return (
        <Portlet name="Main Details" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField
                            disabled={edition}
                            label="Country"
                            name="code"
                            onChangeEvent={handleSelectChange('country')}
                            options={countries}
                            placeholder="Select Country"
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField
                            disabled={edition}
                            label="Company"
                            name="companyId"
                            onChangeEvent={handleSelectChange('company')}
                            options={companies}
                            placeholder="Select Company"
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField
                            defaultValue={selectedLanguages}
                            label="Languages"
                            maxValueLength={3}
                            name="languages"
                            onChangeEvent={handleLanguagesChange}
                            options={languages}
                            placeholder="Select Language"
                            multi
                        />
                    </div>
                    {edition && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Language Translations">
                                <Field accept=".xlsx,.csv" component={ImportExportSet} disabled={false} name="excel" />
                            </FieldContainer>
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField
                            label="Currency Symbol*"
                            name="currency"
                            options={currencies}
                            placeholder="Select Currency Symbol"
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Rounding for Absolute Amount">
                            <DropdownField
                                name="rounding.amount.count"
                                options={decimalPrecisionOptions}
                                placeholder="Select Decimal Place"
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Rounding for %">
                            <DropdownField
                                name="rounding.percentage.count"
                                options={decimalPrecisionOptions}
                                placeholder="Select Decimal Place"
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Match Existing Customer">
                            <Field component={SwitchField} name="matchExistingCustomer" options={yesNoOptions} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div style={{ width: '50%' }}>
                            <FieldContainer label="Customer Data Masking">
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <DropdownField
                                        disabled={matchExistingCustomer}
                                        name="mask.direction"
                                        options={dataMaskModeOptions}
                                    />
                                    <div style={{ width: '20px' }} />
                                    <DropdownField
                                        disabled={matchExistingCustomer || maskDirection === MaskDirection.NONE}
                                        name="mask.count"
                                        options={dataMaskingCountOptions}
                                    />
                                </div>
                            </FieldContainer>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="SMTP Provider*">
                            <DropdownField
                                name="email.provider"
                                onChangeEvent={handleEmailProviderChange}
                                options={smtpProviders}
                            />
                        </FieldContainer>
                    </div>
                    {emailProvider === Provider.SMTP && (
                        <>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="SMTP Host*">
                                    <Field component={InputField} name="email.host" />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="SMTP Port No*">
                                    <Field component={NumberField} name="email.port" precision={0} />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="SSL enabled">
                                    <Field component={SwitchField} name="email.isSslEnabled" options={yesNoOptions} />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Requires Authentication">
                                    <Field
                                        component={SwitchField}
                                        name="email.isAuthenticationRequired"
                                        options={yesNoOptions}
                                    />
                                </FieldContainer>
                            </div>
                            {isAuthenticationRequired && (
                                <>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <FieldContainer label="Username*">
                                            <Field component={InputField} name="email.user" />
                                        </FieldContainer>
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <FieldContainer label="Password*">
                                            <Field component={PasswordField} name="email.password" />
                                        </FieldContainer>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="From Email id*">
                            <Field
                                component={InputField}
                                disabled={emailProvider === Provider.SYSTEM}
                                name="email.fromEmail"
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Send Email to Salesperson">
                            <Field
                                component={SwitchField}
                                name="sendSalesPersonConfirmationEmail"
                                options={yesNoOptions}
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Finance Calculator API for External">
                            <Field component={SwitchField} name="useExternalCalculator" options={yesNoOptions} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Porsche Finder Integration">
                            <Field component={SwitchField} name="usePorscheFinder" options={yesNoOptions} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="ICC Checkout Integration">
                            <Field component={SwitchField} name="useIccCheckout" options={yesNoOptions} />
                        </FieldContainer>
                    </div>
                    {usePorscheFinder && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Autoplay Integration">
                                <Field component={SwitchField} name="useAutoplay" options={yesNoOptions} />
                            </FieldContainer>
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Google Tag Manager ID">
                            <Field component={InputField} name="googleTagManager.id" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Legal Notice (url)">
                            <Field component={InputField} name="externalLinks.legalNotice" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Privacy Policy (url)">
                            <Field component={InputField} name="externalLinks.privacyPolicy" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Copyright in eDM">
                            <Field component={InputField} name="externalLinks.copyright" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Social Media - Facebook (url)">
                            <Field component={InputField} name="externalLinks.facebook" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Social Media - YouTube (url)">
                            <Field component={InputField} name="externalLinks.youtube" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Social Media - Instagram (url)">
                            <Field component={InputField} name="externalLinks.instagram" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Social Media - Website (url)">
                            <Field component={InputField} name="externalLinks.website" />
                        </FieldContainer>
                    </div>
                    {edition && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Last Modified">
                                <Field component={InputField} name="lastModified" disabled />
                            </FieldContainer>
                        </div>
                    )}
                </div>
            </div>
        </Portlet>
    );
};

export default MainDetails;
