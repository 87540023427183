import styled from 'styled-components';

const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 20px;
    font-size: 0.85rem;
`;

export default FooterContainer;
