import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getCurrentCountry } from '../../../../selectors';
import Page from './Page';

const Creation = () => {
    const country = useSelector(getCurrentCountry);
    const { modelId = null } = useParams();

    const initialValues = useMemo(() => ({ countryId: country.id, parentId: modelId, isActive: true }), [
        country.id,
        modelId,
    ]);

    return <Page initialValues={initialValues} />;
};

export default Creation;
