import { useQuery } from '@apollo/client';
import { groupBy, find } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { useContentTranslation } from '../../../i18n';
import { applicationChannelOptions } from '../../../shared/constants/options';
import { getData } from './Edition.graphql';
import Page from './Page';
import CustomerOptionsProvider from './useOptions';

const getSourceLabel = (channel, access, dealerName) => {
    const data = find(option => option.channel === channel && option.access === access, applicationChannelOptions)
        ?.label;

    return `${data}: [${dealerName}]`;
};

const Edition = () => {
    const { ct } = useContentTranslation();
    const { id } = useParams();
    const variables = { id };
    const { data, loading } = useQuery(getData, { variables, fetchPolicy: 'network-only' });

    const journeys = useMemo(() => {
        const values = data?.customer;

        if (!values) {
            return null;
        }

        return groupBy(x => getSourceLabel(x.channel, x.access, ct(x.dealer.name)), values.applications);
    }, [data, ct]);

    const initialValues = data?.customer || null;

    if (!loading && !initialValues) {
        // the customer does not exist
        return <Redirect to="/customer" />;
    }

    return (
        <CustomerOptionsProvider countryCode={data?.customer?.zone?.country.code}>
            <Page initialValues={initialValues} journeys={journeys} />
        </CustomerOptionsProvider>
    );
};

export default Edition;
