import { Calculator, coerce, MonthlyInstalment } from '@appvantageasia/afc-calculator-next';
import * as yup from 'yup';
import ConnectedMonthlyInstalmentField from '../components/fields/display/ConnectedMonthlyInstalmentField';
import { renderCurrency } from '../components/fields/display/CurrencyField';
import MonthlyInstalmentLabelBox from '../components/fields/display/MonthlyInstalmentLabelBox';
import MonthlyInstalmentSnapshot from '../components/fields/display/MonthlyInstalmentSnapshot';
import UpdateNumeric from '../components/fields/edition/UpdateNumeric';
import { getDownPaymentRange } from '../context/computeChanges';
import { weightFieldAfter } from '../utils';
import { getAccurate } from './downPayment';
import fieldTemplate from './fieldTemplate';
import defaultRaiseInvalidConfiguration from './raiseInvalidConfiguration';
import { Unit } from './schema';

// eslint-disable-next-line consistent-return
export const createComputeFn = (raiseInvalidConfiguration = defaultRaiseInvalidConfiguration) => (values, context) => {
    const {
        balloonPayment,
        totalPrice,
        paymentTerm,
        interestRate,
        downPayment,
        loanAmount,
        mileage,
        deposit,
        paymentMode,
        residualValue,
    } = values;
    const { selectedVariant, selectedFinanceProduct, t } = context;

    const dependencies = [
        balloonPayment,
        // deposit,
        totalPrice,
        paymentTerm,
        interestRate,
        downPayment,
        loanAmount,
        mileage,
        selectedFinanceProduct,
        selectedVariant,
        paymentMode,
    ];

    if (dependencies.some(value => value === undefined)) {
        // no enough data yet
        return undefined;
    }

    const query = {
        paymentMode,
        variant: selectedVariant.version.id,
        price: totalPrice,
        term: paymentTerm,
        interestRate,
        downPayment,
        loan: loanAmount,
        balloon: balloonPayment,
        deposit,
        mileage,
        residualValue: residualValue?.percentage,
    };

    if (downPayment.amount >= totalPrice) {
        return 0;
    }

    try {
        // try to use the calculator to compute the monthly payment
        const financeProduct = coerce(selectedFinanceProduct);
        const calculator = new Calculator(financeProduct);
        calculator.calculate(query);
        const monthlyPayment = calculator.root.seek(MonthlyInstalment);

        return monthlyPayment.value;
    } catch (error) {
        console.error(error);
        // and send a computing error
        raiseInvalidConfiguration(t, error);
    }
};

export const compute = createComputeFn();

export const createMonthlyInstalmentField = (computeFn = compute) => ({
    // copy template
    ...fieldTemplate,

    // setup meta
    label: t => t('calculator.label.monthlyInstalment'),
    weight: weightFieldAfter('totalPrice'),

    // setup states
    isViewable: () => true,

    // setup display rendering
    displayComponent: ConnectedMonthlyInstalmentField,
    displayLabelComponent: MonthlyInstalmentLabelBox,
    getDisplayProperties: () => ({
        children: renderCurrency,
    }),

    isEditable: () => false,
    // setup edition rendering
    editionComponent: UpdateNumeric,
    getEditionProperties: (context, values) => {
        const { selectedFinanceProduct, formats, t } = context;
        const { downPaymentSetting } = selectedFinanceProduct;
        const { defaultUnit } = downPaymentSetting;

        const { totalPrice } = values;

        const { min, max } = getDownPaymentRange(selectedFinanceProduct, totalPrice);
        const minPercentage = defaultUnit === Unit.PERCENTAGE ? min : (min * 100) / totalPrice;
        const maxPercentage = defaultUnit === Unit.PERCENTAGE ? max : (max * 100) / totalPrice;

        const maxMonthlyPayment = formats.roundNumberDown(
            computeFn(
                {
                    ...values,
                    downPayment: {
                        percentage: minPercentage,
                        amount: formats.roundNumber((minPercentage / 100) * totalPrice),
                    },
                    loanAmount: {
                        percentage: getAccurate(minPercentage),
                        amount: formats.roundNumberDown(
                            totalPrice - formats.roundNumber((minPercentage / 100) * totalPrice)
                        ),
                    },
                },
                context
            )
        );

        const minMonthlyPayment = formats.roundNumberDown(
            computeFn(
                {
                    ...values,
                    downPayment: {
                        percentage: maxPercentage,
                        amount: formats.roundNumber((maxPercentage / 100) * totalPrice),
                    },
                    loanAmount: {
                        percentage: getAccurate(maxPercentage),
                        amount: formats.roundNumberDown(
                            totalPrice - formats.roundNumber((maxPercentage / 100) * totalPrice)
                        ),
                    },
                },
                context
            )
        );

        const minErrorMessage = t('calculator.error.monthlyInstalmentMin', { min: minMonthlyPayment });
        const validation = yup
            .number()
            .typeError(minErrorMessage)
            .min(minMonthlyPayment, minErrorMessage)
            .max(maxMonthlyPayment, t('calculator.error.monthlyInstalmentMax', { max: maxMonthlyPayment }))
            .required(minErrorMessage);

        return {
            prefix: formats?.currencySymbol,
            precision: formats?.currencyPrecision,
            validation,
            fixedDecimalScale: formats?.fixedDecimalScale,
            thousandSeparator: formats?.thousandSeparator,
        };
    },

    // setup values management
    getInitialValue: computeFn,
    // this field is almost reset on everything...
    updates: Object.fromEntries(
        [
            'financeProduct',
            'carModel',
            'balloonPayment',
            'totalPrice',
            'paymentTerm',
            'interestRate',
            'downPayment',
            'loanAmount',
            'mileage',
            'deposit',
            'paymentMode',
            'residualValue',
        ].map(field => [field, () => undefined])
    ),

    // snapshot configuration
    snapshot: {
        ...fieldTemplate.snapshot,
        component: MonthlyInstalmentSnapshot,
    },
});

const monthlyInstalmentField = createMonthlyInstalmentField();

export default monthlyInstalmentField;
