import styled from 'styled-components';

const OutlineContainer = styled.div`
    margin-bottom: 1rem;
    position: relative;

    label {
        display: inline-block;
        font-style: normal;
        font-weight: 100;
        color: #666666;
        margin-bottom: 0.5rem;
        touch-action: manipulation;
    }
`;

export default OutlineContainer;
