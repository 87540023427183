import { applyMiddleware, compose, createStore as create } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const createStore = extraArgument =>
    create(rootReducer, composeEnhancers(applyMiddleware(thunk.withExtraArgument(extraArgument))));

export default createStore;
