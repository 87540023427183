import { PrimaryButton } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router';
import { reduxForm } from 'redux-form';
import * as yup from 'yup';
import { createFormValidation, requiredString } from '../../../utilities/forms';
import FormError from '../../shared/form/FormError';
import PasswordField from '../../shared/form/PasswordField';
import TextField from '../../shared/form/TextField';
import { TextAlignRight } from '../../ui/TextAlign';
import FormActions from '../../ui/form/FormActions';
import LoginLogo from '../../ui/login/LoginLogo';
import ResetPasswordButton from '../../ui/login/ResetPasswordButton';

const useNotificationMessage = () => {
    const { state } = useLocation();

    if (!state) {
        return null;
    }

    switch (state.from) {
        case 'reset':
            return 'Password reset successfully.  Please login with your new password.';

        default:
            return null;
    }
};

const useErrorMessage = () => {
    const { state } = useLocation();

    if (!state) {
        return null;
    }

    switch (state.from) {
        case 'expired':
            return 'Your session has expired, please login again.';

        case 'override':
            return 'You have been logged out because your account has signed in elsewhere.';

        default:
            return null;
    }
};

const CredentialForm = ({ goToPasswordReset, handleSubmit }) => {
    const notification = useNotificationMessage();
    const externalError = useErrorMessage();

    return (
        <form autoComplete="new-password" onSubmit={handleSubmit}>
            <LoginLogo />
            <TextField.Outline autoComplete="off" label="Username" name="username" placeholder="Username" autoFocus />
            <PasswordField.Outline autoComplete="off" label="Password" name="password" placeholder="Password" />
            <TextAlignRight>
                <ResetPasswordButton onClick={goToPasswordReset} type="button">
                    Forgot Password?
                </ResetPasswordButton>
            </TextAlignRight>
            {notification && <p>{notification}</p>}
            <FormActions>
                <div>
                    <PrimaryButton type="submit">Sign In</PrimaryButton>
                </div>
                <FormError externalError={externalError} form="loginCredentials">
                    {error => <FormActions.Error>{error}</FormActions.Error>}
                </FormError>
            </FormActions>
        </form>
    );
};

CredentialForm.propTypes = {
    goToPasswordReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

const schema = yup.object().shape({
    password: requiredString('Password is mandatory').matches(
        /^(?=.{8,40}$).*/,
        'Password should be 8 - 40 characters'
    ),
    username: requiredString('Username is mandatory').matches(
        /^(?=.{3,40}$).*/,
        'Username should be 3 - 40 characters'
    ),
});

export default reduxForm({
    form: 'loginCredentials',
    validate: createFormValidation(schema),
})(CredentialForm);
