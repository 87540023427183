import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { orderBy, isNil } from 'lodash/fp';
import React, { useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    logout,
    setCompanyCode,
    setCountryCode,
    setDealer,
    setDefaultZoneCode,
    setZoneCode,
    switchTenant,
} from '../../../actions';
import { useContentTranslation } from '../../../i18n';

import { getContext } from '../../../selectors';
import {
    DropdownS,
    DropdownItemS,
    DropdownMenuS,
    DropdownToggleS,
    ToggleContainer,
    Text,
    SingleTextContainer,
} from '../UI';

const CompanySelection = () => {
    // we are going to need dispatch for redux actions
    const dispatch = useDispatch();
    const { ct } = useContentTranslation();

    // get some information from the redux state
    const { user, company, countryCode, dealer } = useSelector(getContext);
    const { availableCompanies: companies } = user;

    // state management for the selection modal
    const [open, setOpen] = useState(false);
    const toggle = useCallback(() => setOpen(state => !state), [setOpen]);

    const currentCompanyText = useMemo(() => <Text>{ct(company?.name)}</Text>, [company.name, ct]);

    const renderCurrentCompany = (
        <ToggleContainer>
            {currentCompanyText}
            <FontAwesomeIcon icon={faAngleDown} />
        </ToggleContainer>
    );

    const setCompany = useCallback(
        selectedCompany => {
            const nextCountry =
                selectedCompany.countries.find(item => item.code === countryCode) || selectedCompany.countries[0];

            // then get the zone code
            const zoneCode = nextCountry.zones.length > 1 ? 'All' : nextCountry.zones[0].code;

            // update the codes
            dispatch(setCompanyCode(selectedCompany.code));
            dispatch(setCountryCode(nextCountry.code));
            dispatch(setZoneCode(zoneCode));

            // set the dealer if it has been updated
            let nextDealer = dealer;
            const dealerUpdated =
                (isNil(dealer) && nextCountry.dealers?.length > 0) ||
                (dealer && (isNil(nextCountry.dealers) || nextCountry.dealers.every(it => it.id !== dealer.id)));
            if (dealerUpdated) {
                nextDealer = nextCountry.dealers?.length > 0 ? nextCountry.dealers[0] : null;
                dispatch(setDealer(nextDealer));
            }

            // refresh token when tenant change in admin
            dispatch(
                switchTenant(
                    selectedCompany.id,
                    nextCountry.id,
                    nextCountry.zones.length > 1 ? null : nextCountry.zones[0].id,
                    nextDealer ? nextDealer.id : null
                )
            ).catch(error => {
                // print the error because maybe it is not network related
                console.error(error);
                // we are going to logout
                dispatch(logout('manual'));
            });

            // prepare default zone for supporting datetime display according to the timezone
            if (nextCountry.zones.length > 1) {
                dispatch(setDefaultZoneCode(nextCountry.zones[0].code));
            } else {
                dispatch(setDefaultZoneCode(null));
            }
        },
        [dispatch, dealer, countryCode]
    );

    const renderItem = item => (
        <DropdownItemS key={item.id} onClick={() => setCompany(item)}>
            {' '}
            {ct(item.name)}{' '}
        </DropdownItemS>
    );

    const orderedCompanies = useMemo(() => orderBy('name', 'asc', companies), [companies]);

    if (orderedCompanies.length > 1) {
        return (
            <DropdownS isOpen={open} nav={false} toggle={toggle}>
                <DropdownToggleS caret>{renderCurrentCompany}</DropdownToggleS>
                <DropdownMenuS>{orderedCompanies.map(renderItem)}</DropdownMenuS>
            </DropdownS>
        );
    }

    return <SingleTextContainer>{currentCompanyText}</SingleTextContainer>;
};

export default CompanySelection;
