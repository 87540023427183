import { map } from 'lodash/fp';
import { prepareForGraphQL } from '../../../../../utilities/forms';

const toPayload = (state, allowOptions, newVariant) => {
    const { calculator, financeProduct, variant, options, promoCode, guarantor, remark } = state;

    const data = {
        financeProductId: financeProduct.id,
        variantId: newVariant ? newVariant.id : variant.id,
        optionIds: allowOptions ? map(item => item.id, options) : [],
        calculator,
        proceedWithCustomerDevice: true,
        assigneeId: state.assigneeId,
        promoCodeId: promoCode?.id || null,
        remark,
        guarantorId: guarantor?.id || null,
    };

    return { id: state.id, data: prepareForGraphQL(data) };
};

export default toPayload;
