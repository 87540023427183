import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoginType } from '../../../schema';
import { getCompany, getCurrentCountry } from '../../../selectors';
import { handleResponseError } from '../../../utilities/forms';
import RequestNewPasswordForm from './RequestNewPasswordForm';
import RequestNewPasswordSuccess from './RequestNewPasswordSuccess';
import { sendResetPassword } from './api.graphql';

const RequestNewPasswordStep = ({ setContext }) => {
    const client = useApolloClient();
    const { id: countryId } = useSelector(getCurrentCountry) || {};
    const { id: companyId } = useSelector(getCompany) || {};
    const [done, setDone] = useState(false);

    const onSubmit = useCallback(
        ({ username }) =>
            client
                .mutate({
                    mutation: sendResetPassword,
                    variables: {
                        username,
                        countryId,
                        companyId,
                        locale: 'en',
                        purpose: LoginType.ADMIN,
                    },
                })
                .catch(handleResponseError),
        [client, companyId, countryId]
    );

    const goBackToLogin = useCallback(() => setContext({ step: 'credentials' }), [setContext]);

    if (done) {
        return <RequestNewPasswordSuccess goBackToLogin={goBackToLogin} />;
    }

    return <RequestNewPasswordForm onSubmit={onSubmit} onSubmitSuccess={() => setDone(true)} />;
};

RequestNewPasswordStep.propTypes = {
    setContext: PropTypes.func.isRequired,
};

export default RequestNewPasswordStep;
