import { isEmpty } from 'lodash/fp';

const persistState = (storageKey, reducer) => {
    let previousState;

    const reloadState = (state, action) => {
        // redux is initializing the store
        // retrieve previous state from session storage
        const persistedState = sessionStorage.getItem(storageKey);
        // if we got one, parse it
        const initialState = persistedState ? JSON.parse(persistedState) : state;

        // then finally call the reducer
        previousState = reducer(initialState, action);

        return previousState;
    };

    const updateState = (state, action) => {
        // call the reducer
        const newState = reducer(state, action);

        // we use shallow comparison to look for changes
        // when state is undefined it means we are going to running on some actions ahead of @@INIT
        // so we are going to ignore those
        if (newState !== previousState && state !== undefined) {
            if (!isEmpty(newState)) {
                // we got data to update
                sessionStorage.setItem(storageKey, JSON.stringify(newState));
            } else {
                // can be removed
                sessionStorage.removeItem(storageKey);
            }

            previousState = newState;
        }

        return newState;
    };

    return (state, action) => {
        if (state === undefined) {
            return reloadState(state, action);
        }

        return updateState(state, action);
    };
};

export default persistState;
