import styled from 'styled-components';
import Button from './Button';
import DarkButton from './DarkButton';
import GrayButton from './GrayButton';
import OutlineButton from './OutlineButton';

const Actions = styled.div`
    display: flex;
    flex-direction: row;

    margin-top: 20px;
    margin-left: -5px;
    margin-right: -5px;

    ${DarkButton}, ${GrayButton}, ${OutlineButton}, ${Button} {
        flex: 1;
        font-size: 1.35rem;
        height: 60px;
    }

    & > * {
        margin-left: 5px;
        margin-right: 5px;
    }
`;

export default Actions;
