import { PrimaryButton } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React from 'react';
import FormActions from '../../ui/form/FormActions';
import Title from '../../ui/login/Title';

const RequestNewPasswordSuccess = ({ goBackToLogin }) => (
    <>
        <Title>PASSWORD RESET</Title>
        <p>
            An email with instructions on how to reset your password has been sent if your username is found in our
            system. Check your spam or junk folder if you don’t see the email in your inbox.
        </p>
        <FormActions>
            <PrimaryButton onClick={goBackToLogin} type="button">
                Sign In
            </PrimaryButton>
        </FormActions>
    </>
);

RequestNewPasswordSuccess.propTypes = {
    goBackToLogin: PropTypes.func.isRequired,
};

export default RequestNewPasswordSuccess;
