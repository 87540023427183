import React from 'react';
import { Channel } from '../../../../../../../schema';
import useFormValues from '../../../../../../utilities/useFormValues';
import { ApplicationFormValues } from '../context';
import Express from './Express';
import New from './New';

const Vehicle = () => {
    const { channel } = useFormValues<ApplicationFormValues>();

    switch (channel) {
        case Channel.NEW:
            return <New />;

        case Channel.EXPRESS:
            return <Express />;

        default:
            return null;
    }
};

export default Vehicle;
