import PropTypes from 'prop-types';
import React from 'react';
import Card from './Card';

const cards = [
    {
        bgIcon: 'icmn-accessibility',
        tagIcon: 'icmn-envelop',
        label: 'Application Submitted',
        indication:
            // eslint-disable-next-line max-len
            'Total number of applications received by the Bank for the month. Received resubmission will replace the original Received submission. Applications that are cancelled will not be counted.',
        key: 'submitted',
        keyType: 'number',
        active: true,
    },
    {
        bgIcon: 'icmn-accessibility',
        tagIcon: 'icmn-envelop',
        label: 'Application Approved',
        indication:
            // eslint-disable-next-line max-len
            'Total number of applications that are approved for the month. Approved resubmission will replace the original Approved submission. Applications that are cancelled will not be counted.',
        key: 'approved',
        keyType: 'number',
    },
    {
        bgIcon: 'icmn-download',
        tagIcon: 'icmn-clock',
        label: 'Submitted to Approved/Declined',
        indication:
            // eslint-disable-next-line max-len
            "Average time taken from Received to Approved/Declined status even if it's cancelled after approved. Approved/Declined resubmission will be counted again.",
        key: 'submittedTurnAround',
        active: true,
        keyType: 'duration',
    },
    {
        bgIcon: 'icmn-download',
        tagIcon: 'icmn-clock',
        label: 'Customer Info Received to Approved/Declined',
        indication:
            // eslint-disable-next-line max-len
            "Average time taken from Customer Info Received to Approved/Declined status even if it's cancelled after approved. Applications that are resubmitted will not be counted.",
        key: 'customerInfoTurnAround',
        keyType: 'duration',
    },
];

const StatisticsBlock = ({ value = {} }) => (
    <div className="row">
        {cards.map(item => (
            <div className="col-lg-6 col-sm-6 col-md-6">
                <Card item={item} value={value} />
            </div>
        ))}
    </div>
);

StatisticsBlock.propTypes = {
    value: PropTypes.shape(),
};

export default StatisticsBlock;
