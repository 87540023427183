import { get } from 'lodash/fp';
import { ContentTranslation } from '../../../../../i18n';
import * as validators from '../../../../../utils/validators';

const validate = (formatPath: ContentTranslation['formatPath']) =>
    validators.compose(
        validators.only(
            values =>
                get(formatPath('inventoryUnit.details.features'), values) !== null &&
                get(formatPath('inventoryUnit.details.features'), values) !== undefined,
            validators.requiredMarkdownList(formatPath('inventoryUnit.details.features'), 'Features must be list.')
        )
    );

export default validate;
