// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import { getOr } from 'lodash/fp';
import React from 'react';
import { ApplicationEventType, PaymentProviderType, PaymentStatus } from '../../../../../schema';
import NumberField from '../../../../shared/form-v2/NumberField';
import TextField from '../../../../shared/form-v2/TextField';
import useFormatDateTime from '../../../../shared/useFormatDateTime';
import { GetApplicationQuery } from '../../common/data/Application.graphql';

export type PaymentDetailsProps = {
    application: GetApplicationQuery['application'];
};

const mapStatusLabel = (status: PaymentStatus) => {
    switch (status) {
        case PaymentStatus.PAYMENTCAPTURING:
            return 'Capturing Payment';

        default:
            return status;
    }
};

const PaymentDetails = ({ application }: PaymentDetailsProps) => {
    const formatDateTime = useFormatDateTime();
    const variant = application.events.find(
        item => item.type === ApplicationEventType.PAYMENTCAPTURING || item.type === ApplicationEventType.PAID
    );
    const variantAt = getOr('', 'at', variant);
    const paymentDate = variantAt !== '' ? formatDateTime(variantAt) : '';
    const statusLabel = application.payment ? mapStatusLabel(application.payment.status) : '';

    return (
        <Portlet name="Payment Details" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <NumberField label="Payment Amount" name="reservationDeposit" type="currency" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            fixedValue={paymentDate}
                            label="Payment Date"
                            name="__exclude.paymentDate"
                            disabled
                        />
                    </div>
                    {application.payment?.channel.type === PaymentProviderType.ADYEN && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField label="Transaction ID" name="payment.channel.details.pspReference" disabled />
                        </div>
                    )}
                    {(application.payment?.channel.type === PaymentProviderType.PORSCHE ||
                        application.payment?.channel.type === PaymentProviderType.PAYGATE ||
                        application.payment?.channel.type === PaymentProviderType.TTB) && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField label="Transaction ID" name="payment.channel.details.transactionId" disabled />
                        </div>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField fixedValue={statusLabel} label="Status" name="__exclude.status" disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField label="Payment Method" name="payment.channel.details.paymentMethod" disabled />
                    </div>
                </div>
            </div>
        </Portlet>
    );
};

export default PaymentDetails;
