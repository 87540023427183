import styled, { css } from 'styled-components';

const Title = styled.h1`
    font-size: 1.43rem;
    font-weight: 400;
    text-transform: ${props => props.theme.casing};
    margin-bottom: 3px;
    width: 100%;
    color: #000000;

    ${props =>
        props.withMargin &&
        css`
            margin-bottom: 20px;
        `}
`;

export default Title;
