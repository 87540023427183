import { useApolloClient } from '@apollo/client';
import { omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { submit } from 'redux-form';
import { addNotification } from '../../../../actions';
import { useContentTranslation } from '../../../../i18n';
import { ZONE_DELETE_NOTIFICATION, ZONE_SAVE_NOTIFICATION } from '../../../../shared/constants/notification';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import useLanguageSelector from '../../../../utils/useLanguageSelector';
import { useModal } from '../../../Modal';
import FormLayout from '../../../ui/form/FormLayout';
import Form from './Form';
import { create, update, remove } from './Page.graphql';

const Page = ({ initialValues = null, disabled = false }) => {
    const languageSelector = useLanguageSelector();
    const { mapIntlValue } = useContentTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const modal = useModal();

    const submitForm = useCallback(() => dispatch(submit('zone')), [dispatch]);

    const client = useApolloClient();
    const onSubmit = useCallback(
        async ({ id, ...data }) => {
            const query = id ? update : create;

            const cleanData = prepareForGraphQL(data);
            const { name } = cleanData;

            const variables = {
                data: {
                    ...cleanData,
                    name: mapIntlValue(name),
                },
                id,
            };

            if (id) {
                // we need to remove some more data
                variables.data = omit(['version', 'companyId', 'countryId', 'lastModified'], variables.data);
            }

            return client
                .mutate({ mutation: query, variables })
                .catch(handleResponseError)
                .then(() => data.countryId);
        },
        [client]
    );

    const onSubmitSuccess = useCallback(
        countryId => {
            dispatch(addNotification(ZONE_SAVE_NOTIFICATION));
            history.push(`/scope/countries/${countryId}`);
        },
        [history, dispatch]
    );

    const onDelete = useCallback(() => {
        modal.confirm({
            title: '',
            content: 'Are you sure you want to delete this zone?',
            options: [
                { label: 'No', action: () => null },
                {
                    label: 'Yes',
                    action: () =>
                        client.mutate({ mutation: remove, variables: { id: initialValues?.id } }).then(() => {
                            dispatch(addNotification(ZONE_DELETE_NOTIFICATION));
                            history.push(`/scope/countries/${initialValues?.countryId}`);
                        }),
                },
            ],
        });
    }, [client, dispatch, history, initialValues, modal]);

    const onCancel = useCallback(() => history.goBack(), [history]);

    return (
        <FormLayout
            bodyComponent={
                initialValues && (
                    <Form
                        {...languageSelector}
                        disabled={disabled}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onSubmitSuccess={onSubmitSuccess}
                    />
                )
            }
            moreActions={[
                initialValues?.id &&
                    !disabled && {
                        label: 'delete',
                        onAction: onDelete,
                    },
                {
                    label: 'save',
                    onAction: submitForm,
                },
            ]}
            onCancel={onCancel}
            title="Zone"
        />
    );
};

Page.propTypes = {
    disabled: PropTypes.bool,
    initialValues: PropTypes.shape({}),
};

export default Page;
