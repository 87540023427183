import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { getTimeZones, GetTimeZonesQuery } from './useTimeZones.graphql';

type TimeZoneLabel = 'abbreviation' | 'name';

type TimeZoneOption = { label: string; value: string };

const useTimeZones = (labelField: TimeZoneLabel = 'name'): TimeZoneOption[] => {
    const { data } = useQuery<GetTimeZonesQuery>(getTimeZones, { fetchPolicy: 'cache-first' });
    const timezones = data?.options?.timezones || [];

    return useMemo(() => {
        return timezones.map(timezone => ({ value: timezone.name, label: timezone[labelField] }));
    }, [timezones, labelField]);
};

export default useTimeZones;
