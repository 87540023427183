import PropTypes from 'prop-types';
import React, { useState, memo, useCallback } from 'react';
import Edition from './Edition';
import Fields from './Fields';
import CalculatorContainer from './ui/Container';

const CalculatorInner = ({ className, modalClassName, ...props }) => {
    // state to know which fields is being edited
    const [{ field: fieldOnEdition = null, context: editionContext = null }, setEditionState] = useState({});

    const setFieldOnEdition = useCallback(
        (field, context = null) => {
            setEditionState({ field, context });
        },
        [setEditionState]
    );

    return (
        <CalculatorContainer className={className}>
            {/* the grid will display static fields */}
            <Fields setFieldOnEdition={setFieldOnEdition} {...props} />
            {/* changes are made in a modal in which is render a dynamic input */}
            <Edition
                className={modalClassName}
                editionContext={editionContext}
                field={fieldOnEdition}
                setFieldOnEdition={setFieldOnEdition}
                {...props}
            />
        </CalculatorContainer>
    );
};

CalculatorInner.propTypes = {
    className: PropTypes.string.isRequired,
    modalClassName: PropTypes.string,
};

export default memo(CalculatorInner);
