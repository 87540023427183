import deepEqual from 'fast-deep-equal';
import { get, set, isObject, pick, isEmpty } from 'lodash/fp';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { change, ReduxFormContext, getFormValues } from 'redux-form';
import { CustomerDetailsSource } from '../../../schema';

const myInfoSource = [CustomerDetailsSource.MYINFO, CustomerDetailsSource.NOT_APPLICABLE];

const useCustomerSource = (fields = [], section = null) => {
    const { form } = useContext(ReduxFormContext);
    const previousValues = useRef(null);

    const valueSelector = useCallback(
        state => {
            // get section values
            const formValues = getFormValues(form)(state);
            const sectionValues = section ? get(section, formValues) : formValues;

            const watchedValues = fields.reduce((acc, field) => {
                const path = isObject(field) ? field.path : field;

                return set(path, get(path, sectionValues), acc);
            }, {});

            if (deepEqual(previousValues.current, watchedValues)) {
                // we use a reference to keep in memory the latest values
                // and use fast deep comparison to ensure there's a change
                // otherwise with react updates and functional programming with lodash
                // we will endup in an infinity loop
                return previousValues.current;
            }

            // update the persistent pointor
            previousValues.current = watchedValues;

            return watchedValues;
        },
        [section, fields, form, previousValues]
    );

    const dispatch = useDispatch();
    const values = useSelector(valueSelector);

    const results = useMemo(() => {
        const changes = [];

        const state = fields.reduce((acc, field) => {
            const path = isObject(field) ? field.path : field;
            const { source, ...properties } = get(path, values) || {};
            const watchedProperties = pick(isObject(field) ? field.properties : ['value'], properties);

            if (isEmpty(watchedProperties)) {
                // field is not disabled
                // there is no value for it uet
                return set(path, false, acc);
            }

            if (source === undefined) {
                // we need to set the source to manual
                changes.push(path);
            }

            return set(path, myInfoSource.includes(source), acc);
        }, {});

        return { state, changes };
    }, [values, fields]);

    useEffect(() => {
        batch(() => {
            results.changes.forEach(field => {
                let path = `${field}.source`;

                if (section) {
                    path = `${section}.${path}`;
                }

                dispatch(change(form, path, CustomerDetailsSource.MANUAL));
            });
        });
    }, [form, results.changes, dispatch, section]);

    return results.state;
};

export default useCustomerSource;
