import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styled from 'styled-components';
import * as UI from './ui/TableList';

const defaultGetItemKey = get('id');

const WrapperIcon = styled.div`
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    right: 4px;
    top: 0;
`;

const TableList = ({ columns, items, sorting, message, getItemKey = defaultGetItemKey, onItemClick }) => {
    const renderHeader = column => {
        const { key, sortKey, label, checkBox, styles, textAlignCenter = false, filter } = column;

        // we might want to sort on click
        const onClick = sorting && sortKey && (() => sorting.update(sortKey));
        const isActive = sortKey && sorting?.key === sortKey;

        return (
            <UI.HeaderCell
                key={key}
                active={isActive || checkBox}
                onClick={onClick}
                style={styles}
                textAlignCenter={textAlignCenter}
            >
                {label}
                {checkBox}
                {filter}
                {sortKey && (
                    <WrapperIcon>
                        <Icon icon={faSort} />
                    </WrapperIcon>
                )}
            </UI.HeaderCell>
        );
    };

    const renderItemCell = item => column => (
        <UI.ItemCell
            key={column.key}
            onClick={event => column.onClick && column.onClick(event, item)}
            underlined={column.underlined}
        >
            {column.getValue(item)}
        </UI.ItemCell>
    );

    return (
        <UI.Table>
            <UI.Headers>
                <UI.HeaderRow>{columns.map(renderHeader)}</UI.HeaderRow>
            </UI.Headers>
            <UI.Items>
                {items?.length
                    ? items.map(item => {
                          return (
                              <UI.ItemRow key={getItemKey(item)} onClick={onItemClick && (() => onItemClick(item))}>
                                  {columns.map(renderItemCell(item))}
                              </UI.ItemRow>
                          );
                      })
                    : message}
            </UI.Items>
        </UI.Table>
    );
};

TableList.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            getValue: PropTypes.func.isRequired,
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            onClick: PropTypes.func,
            sortKey: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.func.isRequired]),
            textAlignCenter: PropTypes.bool,
        }).isRequired
    ).isRequired,
    getItemKey: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    message: PropTypes.node,
    onItemClick: PropTypes.func,
    sorting: PropTypes.shape({
        key: PropTypes.string,
        update: PropTypes.func.isRequired,
    }),
};

export default memo(TableList);
