import { getOr } from 'lodash/fp';
import React from 'react';
import DatePickerField from '../../../../../shared/form-v2/DatePickerField';
import SelectField from '../../../../../shared/form-v2/SelectField';
import TextAreaField from '../../../../../shared/form-v2/TextAreaField';
import TextField from '../../../../../shared/form-v2/TextField';
import SubTitle from '../../../../../ui/SubTitle';
import { useCustomerOptions } from '../../../../Customer/useOptions';

const DrivingLicenseDetails = () => {
    const options = useCustomerOptions();

    return (
        <>
            <SubTitle>Driving Licence</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label="Years of Driving"
                        name="calculator.yearsOfDriving"
                        options={getOr([], 'yearsOfDrivingOptions', options)}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DatePickerField
                        label="Driver License Pass Date"
                        name="calculator.driverLicensePassDate"
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label="No Claim Discount"
                        name="calculator.ncd"
                        options={getOr([], 'claimDiscountOptions', options)}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField label="Existing Car Plate to utilise NCD" name="calculator.existingCarPlate" disabled />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextAreaField label="Additional Comments for Insurance" name="comment" disabled />
                </div>
            </div>
        </>
    );
};

export default DrivingLicenseDetails;
