import { createContext, useContext } from 'react';
import {
    ApplicationCustomerDataFragment,
    GetInsuranceApplicationQuery,
} from '../../../common/data/Application.graphql';

export type ApplicationFormValues = GetInsuranceApplicationQuery['insuranceApplication'] &
    Omit<GetInsuranceApplicationQuery['insuranceApplication']['application'], 'status'> & {
        initialCustomer: ApplicationCustomerDataFragment;
    };

export type FormProps = {
    allowPrimaryInfoChanges?: boolean;
    disabled?: boolean;
    useCustomerNationality: boolean;
    useCustomerBirthDay: boolean;
    validation: {
        phonePattern: RegExp | null;
    };
};

export type FormContextData = FormProps & {
    values: ApplicationFormValues;
};

export const Context = createContext<FormContextData>(null!);

export const useFormContext = () => useContext(Context);
