import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEventHandler } from 'react';
import Logo from '../header/Logo';
import ScopeSelection from '../header/ScopeSelection';
import Setting from '../header/Setting';
import { HeaderContainer, HeaderMenuToggle } from '../header/UI';

type HeaderProps = {
    toggleMenu: MouseEventHandler;
};

const Header = ({ toggleMenu }: HeaderProps) => (
    <HeaderContainer>
        <HeaderMenuToggle onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} />
        </HeaderMenuToggle>
        <Logo />
        <div style={{ flex: 1 }} />
        <ScopeSelection />
        <Setting />
    </HeaderContainer>
);

export default Header;
