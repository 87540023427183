import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray, WrappedFieldArrayProps, GenericFieldArray, Field } from 'redux-form';
import { CustomerDetailsSource } from '../../../../../schema';
import DatePickerField from '../../../../shared/form-v2/DatePickerField';
import TextField from '../../../../shared/form-v2/TextField';

type LicenseItemProps = {
    name: string;
};

const LicenseItem = ({ name }: LicenseItemProps) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="col-md-4 col-sm-12 col-xs-12">
                <TextField
                    disabled={false}
                    label={t('customerDetails.label.drivingLicenseNumber')}
                    name={`${name}.number`}
                />
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12">
                <DatePickerField label={t('customerDetails.label.drivingLicenseExpiry')} name={`${name}.expiryDate`} />
            </div>
        </>
    );
};

const LicenseArray = ({ fields }: WrappedFieldArrayProps) => {
    const items = fields.map((field: string, index: number) => <LicenseItem key={field} name={field} />);
    useEffect(() => {
        if (fields.length > 0) {
            return;
        }

        fields.push({
            classes: [{}],
            source: CustomerDetailsSource.MANUAL,
        });
    }, []);

    return items;
};

type DrivingLicenseProps = { disabled?: boolean };

const FieldArrayCustom = FieldArray as new () => GenericFieldArray<Field, any>;

const DrivingLicense = ({ ...props }: DrivingLicenseProps) => (
    <FieldArrayCustom component={LicenseArray} name="details.drivingLicense" rerenderOnEveryChange {...props} />
);

export default DrivingLicense;
