import React from 'react';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { CustomerLists, EditCustomer } from '../routes/Customer';

const CustomerRouter = () => (
    <Switch>
        <Route component={CustomerLists} path="/customer" exact />
        <Route component={EditCustomer} path="/customer/:id" exact />
        <Redirect to="/home" />
    </Switch>
);

export default CustomerRouter;
