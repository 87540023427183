import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import NumericInput from './NumericInput';

const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;

    display: flex;
    align-items: center;

    input {
        width: 100%;
        border: none;
        outline: none;
        font-size: 1.21rem;
        color: ${props => (props.disabled ? '#000' : props.theme.themeHighlightColour)};
        background-color: transparent;
        padding: 0;
        height: 22px;
        display: flex;
        align-items: center;
    }
`;

const Prefix = styled.div`
    display: flex;
    align-items: center;
    padding-right: 3px;
    font-size: 1.21rem;
    color: ${props => (props.disabled ? '#000' : props.theme.themeHighlightColour)};
`;

const BoxedNumericInput = ({ prefix = null, ...props }) => {
    const { disabled } = props;

    return (
        <InputWrapper disabled={disabled}>
            {prefix && <Prefix disabled={disabled}>{prefix}</Prefix>}
            <div>
                <NumericInput {...props} />
            </div>
        </InputWrapper>
    );
};

BoxedNumericInput.propTypes = {
    disabled: PropTypes.bool,
    prefix: PropTypes.node,
};

export default BoxedNumericInput;
