import { get } from 'lodash/fp';
import React from 'react';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import useTimeZones from '../../../../../utils/useTimeZones';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import NumberField from '../../../../template/Number';
import Portlet from '../../../../template/Portlet';
import { useFormContext } from './context';

const MainDetails = () => {
    const { values } = useFormContext();
    const { formatPath } = useContentTranslation();

    const edition = !!get('lastModified', values);

    const timezones = useTimeZones();

    return (
        <Portlet name="Main Details" closeable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Zone Name*">
                            <Field component={InputField} name={formatPath('name')} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Zone Code*">
                            <Field component={InputField} name="code" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Tax %">
                            <Field component={NumberField} name="taxRate" />
                        </FieldContainer>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Zip Code">
                            <Field component={InputField} name="zipCode" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField label="Time Zone*" name="timezone" options={timezones} />
                    </div>
                    {edition && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Last Modified">
                                <Field component={InputField} name="lastModified" disabled />
                            </FieldContainer>
                        </div>
                    )}
                </div>
            </div>
        </Portlet>
    );
};

export default MainDetails;
