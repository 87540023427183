import { get, isEmpty, flow } from 'lodash/fp';
import * as validators from '../../../../utils/validators';

const fieldIsEmpty = name => flow([get(name), isEmpty]);

const validate = formatPath =>
    validators.compose(
        validators.requiredString('owner'),
        validators.requiredStringArray('dealerIds'),
        validators.requiredNumber('order'),
        validators.requiredBoolean('hasCheckbox'),
        validators.requiredBoolean('isMandatory'),
        validators.requiredBoolean('hasLegalMarkup'),
        validators.only(get('hasCheckbox'), validators.requiredString('description')),
        validators.only(fieldIsEmpty(formatPath('name')), validators.requiredString(formatPath('description'))),
        validators.only(fieldIsEmpty(formatPath('description')), validators.requiredString(formatPath('name'))),
        validators.only(get('hasLegalMarkup'), validators.requiredString('legalMarkup'))
    );

export default validate;
