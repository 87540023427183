import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { getCurrentCountry, getUser } from '../../selectors';
import * as core from '../../utils/permissions';
import { PromoList, CreatePromo, EditPromo } from '../routes/Promo';

const PromoRouter = () => {
    const user = useSelector(getUser) || {};
    const currentCountry = useSelector(getCurrentCountry);
    const [mayViewPromo, mayManagePromo] = useMemo(
        () => [core.mayViewPromo(user, currentCountry), core.mayManagePromo(user, currentCountry)],
        [currentCountry, user]
    );

    return (
        <Switch>
            {mayViewPromo && <Route component={PromoList} path="/promo" exact />}
            {mayManagePromo && <Route component={CreatePromo} path="/promo/new" exact />}
            {mayViewPromo && <Route component={EditPromo} path="/promo/:id" exact />}
            <Redirect to="/home" />
        </Switch>
    );
};

export default PromoRouter;
