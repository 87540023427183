import { OutlineWrapper } from '@appvantageasia/afc-ui';
import React from 'react';
import styled from 'styled-components';
import DialingCodeField from '../../template/options-field/DialingCodeField';
import TextField from './TextField';

const Container = styled.div`
    display: flex;
    flex-direction: row;

    & > div:first-child {
        flex: 1;
        margin-right: 10px;
    }

    & > div:last-child {
        flex: 2;
    }
`;

const ContactField = () => (
    <OutlineWrapper label="Phone">
        <Container>
            <DialingCodeField name="phonePrefix" />
            <TextField name="phone" />
        </Container>
    </OutlineWrapper>
);

export default ContactField;
