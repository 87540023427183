import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentZone, getDefaultZone } from '../../selectors';
import { formatDateTimeWithOffset } from '../../utilities/dates';

const useFormatDateTime = () => {
    const currentZone = useSelector(getCurrentZone);
    const defaultZone = useSelector(getDefaultZone);

    return useMemo(() => formatDateTimeWithOffset(currentZone?.timezone || defaultZone?.timezone), [
        currentZone,
        defaultZone,
    ]);
};

export default useFormatDateTime;
