import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { InsuranceOption, InsuranceSigningMode } from '../../../../schema';
import { getCountry } from './Creation.graphql';
import Page from './Page';

const Creation = () => {
    const { countryId } = useParams();

    const variables = { countryId };
    const { data, loading } = useQuery(getCountry, { variables, fetchPolicy: 'cache-first' });
    const country = data?.country;

    const initialValues = useMemo(
        () => ({
            countryId,
            zoneIds: [],
            signing: { onCreate: InsuranceSigningMode.NONE, onUpdate: InsuranceSigningMode.NONE },
            option: InsuranceOption.GNM,
            isActive: true,
            hasIntegration: false,
            sendEmailToCustomer: false,
        }),
        [countryId]
    );

    if (!loading && !country) {
        return <Redirect to="/scope/countries" />;
    }

    return <Page dealers={country?.dealers} initialValues={country ? initialValues : null} zones={country?.zones} />;
};

export default Creation;
