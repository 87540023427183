import React from 'react';
import { ApplicationPhase } from '../../../../../../schema';
import Vehicle from '../../../common/components/Vehicle';
import Summary from '../../../common/shared/Summary';
import Finance from '../../shared/Finance';
import { useFormContext } from './context';

const Lead = () => {
    const { disabled, values } = useFormContext();

    return (
        <>
            <Summary applicationPhase={ApplicationPhase.FINANCE} disabled={disabled} />
            <Vehicle />
            <Finance disabled={disabled} values={values} isApplication onValidation />
        </>
    );
};

export default Lead;
