import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getOr, keyBy, isNil } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { Field } from 'redux-form';
import { FootBarButton, TabExcelContainer } from '../../../../../containers/Layout';
import { useContentTranslation } from '../../../../../i18n';
import { DownPaymentMode, FinanceProductBase, Unit } from '../../../../../schema';
import TableField, { createColumns } from '../../../../TableField';
import { exportFinancialTabsExcel, getWorkbookToExport } from '../../../../utilities/excel';
import ImportFinanceTablesModal from '../../lists/ImportFinanceTablesModal';
import { FinanceProductDataFragment } from '../Edition.graphql';
import { useFormContext } from './context';

type ExcelInterestRateRecord = {
    term: number;
    basedOnAmount: number;
    value: number;
};

const getBasedAmountAndTermKey = ({ basedOnAmount, term }: { basedOnAmount: number; term: number }) =>
    `${basedOnAmount}-${term}`;

const useBasedOnRows = () => {
    const { values, currency } = useFormContext();

    const { basedOn, interestRateSetting } = values;

    return useMemo(() => {
        const unit = interestRateSetting?.tableGroupingUnit === Unit.PERCENTAGE ? '%' : currency;
        const { tableGroupingStep: step } = interestRateSetting;

        // build settings
        switch (basedOn) {
            case FinanceProductBase.DOWNPAYMENT: {
                // prepare downpayment settings
                const downPaymentSettings: FinanceProductDataFragment['downPaymentSetting'] = getOr(
                    {},
                    'downPaymentSetting',
                    values
                );

                const columns: { value: number }[] =
                    (downPaymentSettings.mode === DownPaymentMode.TABLE
                        ? getOr([], 'table', downPaymentSettings)
                        : createColumns({ ...interestRateSetting, step })) || [];

                return columns.map(({ value }: { value: number }) => ({
                    values: { basedOnAmount: value },
                    label: `${value.toString()} ${unit}`,
                }));
            }

            case FinanceProductBase.LOAN: {
                const columns: { value: number }[] = createColumns({ ...interestRateSetting, step });

                return columns.map(({ value }: { value: number }) => ({
                    values: { basedOnAmount: value },
                    label: `${value.toString()} ${unit}`,
                }));
            }

            default:
                throw new Error('Not Implemented. ');
        }
    }, [basedOn, currency, interestRateSetting, values]);
};

const InterestRateTab = () => {
    const { disabled, values, change } = useFormContext();
    const settings = getOr({}, 'termSetting', values);
    const { basedOn } = values;
    const interestRateTable = getOr([], 'interestRateSetting.table', values);

    const rows = useBasedOnRows();

    const productName = getOr('', 'name', values);
    const [showImport, setShowImport] = useState(false);

    const { ct } = useContentTranslation();

    const mappedHeaders = useMemo(() => {
        const basedOnKey = `${basedOn === FinanceProductBase.DOWNPAYMENT ? 'Downpayment' : 'Loan'} *`;

        return {
            'Term *': 'term',
            [basedOnKey]: 'basedOnAmount',
            'Interest *': 'value',
        };
    }, [basedOn]);

    const exportExcel = useCallback(async () => {
        const workbook = await getWorkbookToExport(mappedHeaders);
        const worksheet = workbook.getWorksheet(1);
        const terms = createColumns(settings).map(val => val.value);
        const interestRateDataByBasedAmountAndTerm = keyBy(getBasedAmountAndTermKey, interestRateTable || []);

        rows.forEach(row => {
            const { basedOnAmount } = row.values;
            terms.forEach(term => {
                const basedAmountAndTermKey = getBasedAmountAndTermKey({ basedOnAmount, term });
                worksheet.addRow({
                    term,
                    basedOnAmount,
                    value: getOr(null, `${basedAmountAndTermKey}.value`, interestRateDataByBasedAmountAndTerm),
                });
            });
        });

        exportFinancialTabsExcel(workbook, ct(productName), 'Interest Rate');
    }, [ct, interestRateTable, mappedHeaders, productName, rows, settings]);

    const onImportClose = useCallback(() => {
        setShowImport(false);
    }, [setShowImport]);

    const onImport = useCallback(
        async (data: ExcelInterestRateRecord[]) => {
            const tableDataList = data
                .filter(record => !isNil(record.value))
                .map(record => ({
                    term: record.term,
                    basedOnAmount: record.basedOnAmount,
                    value: record.value,
                }));
            change('interestRateSetting.table', tableDataList);
        },
        [change]
    );

    const header = `${basedOn === FinanceProductBase.DOWNPAYMENT ? 'Downpayment' : 'Loan'} / Term`;

    return (
        <>
            <Field
                columnKey="term"
                component={TableField}
                disabled={disabled}
                header={header}
                name="interestRateSetting.table"
                precision={2}
                rows={rows}
                settings={settings}
                suffix="%"
            />
            <TabExcelContainer>
                <FootBarButton onClick={() => setShowImport(true)}>
                    <FontAwesomeIcon icon={faUpload} /> IMPORT EXCEL
                </FootBarButton>
                <FootBarButton onClick={exportExcel}>
                    <FontAwesomeIcon icon={faDownload} /> EXPORT EXCEL
                </FootBarButton>
            </TabExcelContainer>
            {showImport && (
                <ImportFinanceTablesModal
                    mappedHeaders={mappedHeaders}
                    onClose={onImportClose}
                    onImport={onImport}
                    type="Interest Rate Table"
                />
            )}
        </>
    );
};

export default InterestRateTab;
