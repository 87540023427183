import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { getFormValues, ReduxFormContext } from 'redux-form';
import { Channel } from '../../../../../../schema';
import { ApplicationFormValues } from '../../../Finance/application/Form/context';
import Event from '../../../Finance/shared/Vehicle/Event';
import Express from './Express';
import New from './New';
import Used from './Used';

type VehicleProps = {
    disabled?: boolean;
};

const Vehicle = ({ disabled }: VehicleProps) => {
    const { form } = useContext(ReduxFormContext);
    const { channel } = useSelector(getFormValues(form)) as ApplicationFormValues;

    switch (channel) {
        case Channel.NEW:
            return <New />;

        case Channel.USED:
            return <Used />;

        case Channel.EXPRESS:
            return <Express disabled={disabled} />;

        case Channel.EVENT:
            return <Event />;
        default:
            return null;
    }
};

export default Vehicle;
