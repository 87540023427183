import styled from 'styled-components';

const BoxedGrid = styled.div`
    margin-top: 5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
`;

export default BoxedGrid;
