import { useTranslation } from 'react-i18next';
// financial product module
import {
    BalloonBase,
    BalloonMode,
    CalculationMode,
    DepositMode,
    DerivedMethod,
    DownPaymentMode,
    FinanceProductBase,
    FinanceProductSubType,
    FinanceProductType,
    InterestRateMode,
    PaymentMode,
    Unit,
    ModelType,
    AssetCondition,
    Provider,
    ViewDataType,
    SubMainIncomeType,
    MainIncomeType,
    MaskDirection,
    PaymentProviderType,
    BankSigningMode,
    Channel,
    InventoryUnitState,
    ApplicationAccess,
    EventMedium,
    EventLeadOrigin,
    LicenseType,
    PreOwnerIdType,
    ConsentOrDeclarationDataFieldName,
    FinderVehicleAvailability,
    BankPresetOption,
    InsuranceSigningMode,
    InsuranceOption,
    CalculatorVisibility,
    EngineType,
    HlfBankIntegrationApiVersion,
    CoeCategory,
    AppointmentStatus,
    AppointmentPurpose,
    CoeVehicleCondition,
} from '../../schema';

export const bookingStatuses = [
    { value: InventoryUnitState.SYSTEMMANAGED, label: '-' },
    { value: InventoryUnitState.MANUALLYDEDUCTED, label: 'Manually Deducted' },
];

export const paymentProviderOptions = [
    { label: 'Adyen', value: PaymentProviderType.ADYEN },
    { label: 'Porsche Payment Widget', value: PaymentProviderType.PORSCHE },
    { label: 'PayGate', value: PaymentProviderType.PAYGATE },
    { label: 'TTB', value: PaymentProviderType.TTB },
];

export const unitOptions = [
    { label: '%', value: Unit.PERCENTAGE },
    { label: 'amount', value: Unit.CURRENCY },
];

export const yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
];

export const balloonBasedOnOptions = [
    { label: 'Loan Amount', value: BalloonBase.LOAN },
    { label: 'Car Price', value: BalloonBase.CARPRICE },
    { label: 'Total Price', value: BalloonBase.TOTALPRICE },
];

export const settlementInstalmentOnOptions = [
    { label: '2 months before last instalment', value: 3 },
    { label: '1 month before last instalment', value: 2 },
    { label: 'Last instalment', value: 1 },
];

export const onOffOptions = [
    { label: 'ON', value: true },
    { label: 'OFF', value: false },
];

export const eventAccessOptions = [
    { label: 'Login', value: true },
    { label: 'Public', value: false },
];

export const configBaseOnOptions = [
    { label: 'Downpayment', value: FinanceProductBase.DOWNPAYMENT },
    { label: 'Loan', value: FinanceProductBase.LOAN },
];

export const depositTypeOptions = [
    { label: 'Range', value: DepositMode.RANGE },
    { label: 'Table', value: DepositMode.TABLE },
];

export const insuranceOptions = [{ label: 'Gnm', value: InsuranceOption.GNM }];

export const productTypesOptions = [
    { label: 'Finance', value: FinanceProductType.FINANCE },
    { label: 'Leasing', value: FinanceProductType.LEASING },
];

export const financeSubTypesOptions = [
    { label: 'Deferred Principal', value: FinanceProductSubType.DEFERREDPRINCIPAL },
    { label: 'HP with Balloon', value: FinanceProductSubType.HIREPURCHASEBALLOON },
    { label: 'HP with Balloon (NZ)', value: FinanceProductSubType.HIREPURCHASEBALLOONNZ },
    { label: 'HP with Balloon GFV', value: FinanceProductSubType.HIREPURCHASEBALLOONGFV },
    { label: 'Standard HP', value: FinanceProductSubType.HIREPURCHASE },
    { label: 'Lease Purchase', value: FinanceProductSubType.LEASEPURCHASE },
    { label: 'Lease Purchase (ICC)', value: FinanceProductSubType.LEASEPURCHASEICC },
];

export const leaseSubTypesOptions = [
    { label: 'Lease', value: FinanceProductSubType.LEASE },
    { label: 'UCCL Leasing', value: FinanceProductSubType.UCCLLEASING },
];

export const downPaymentTypeOptions = [
    { label: 'Range', value: DownPaymentMode.RANGE },
    { label: 'Table', value: DownPaymentMode.TABLE },
];

export const balloonTypeOptions = [
    { label: 'Range', value: BalloonMode.RANGE },
    { label: 'Table', value: BalloonMode.TABLE },
];

export const interestRateTypeOptions = [
    { label: 'Fixed', value: InterestRateMode.FLAT },
    { label: 'Range', value: InterestRateMode.RANGE },
    { label: 'Table', value: InterestRateMode.TABLE },
];

export const paymentModeOptions = [
    { label: 'Advance', value: PaymentMode.ADVANCE },
    { label: 'Arrears', value: PaymentMode.ARREARS },
];

export const calculationModeOptions = [
    { label: 'Flat Rate', value: CalculationMode.FLAT },
    { label: 'Effective Rate', value: CalculationMode.EFFECTIVE },
];

export const derivedMethodOptions = [
    { label: 'Formula', value: DerivedMethod.FORMULA },
    { label: 'Lookup Table', value: DerivedMethod.LOOKUPTABLE },
];

export const mileageOptions = [
    { value: 10000, label: '10000' },
    { value: 15000, label: '15000' },
    { value: 20000, label: '20000' },
];

export const modelTypeOptions = [
    { value: ModelType.CONVERTIBLE, label: 'Convertible' },
    { value: ModelType.COUPE, label: 'Coupe' },
    { value: ModelType.HATCHBACK, label: 'Hatchback' },
    { value: ModelType.MPV, label: 'MPV' },
    { value: ModelType.SEDAN, label: 'Sedan' },
    { value: ModelType.SUV, label: 'SUV' },
    { value: ModelType.VAN, label: 'Van' },
    { value: ModelType.WAGON, label: 'Wagon' },
];

export const assetConditionOptions = [
    { value: AssetCondition.DEMO, label: 'Demo' },
    { value: AssetCondition.NEW, label: 'New' },
    { value: AssetCondition.USED, label: 'Preowned' },
];

export const decimalPrecisionOptions = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
];

export const smtpProviders = [
    { value: Provider.SYSTEM, label: 'Appvantage SMTP' },
    { value: Provider.SMTP, label: 'Custom SMTP' },
];

export const viewDataOptions = [
    { label: 'single', value: ViewDataType.SINGLE },
    { label: 'Downline', value: ViewDataType.DOWNLINE },
];

export const mainIncomeOptions = [
    {
        label: 'Business',
        value: MainIncomeType.BUSINESS_OWNER,
    },
    {
        label: 'No Income',
        value: MainIncomeType.NO_INCOME,
    },
    {
        label: 'Salaried Employee (Sales/Commission Based)',
        value: MainIncomeType.SALARIED_COMMISSION,
    },
    {
        label: 'Salaried Employee (Non-Commission Based)',
        value: MainIncomeType.SALARIED_NON_COMMISSION,
    },
    {
        label: 'Self-Employed',
        value: MainIncomeType.SELF_EMPLOYED,
    },
];

export const subMainIncomeOptions = [
    {
        label: 'Government Employee',
        value: SubMainIncomeType.GOVERNMENT_EMPLOYEE,
        mainGroupCode: MainIncomeType.SALARIED_NON_COMMISSION,
    },
    {
        label: 'Homemaker',
        value: SubMainIncomeType.HOMEMAKER,
        mainGroupCode: MainIncomeType.NO_INCOME,
    },
    {
        label: 'MNC Employee',
        value: SubMainIncomeType.MNC_EMPLOYEE,
        mainGroupCode: MainIncomeType.SALARIED_NON_COMMISSION,
    },
    {
        label: 'Partner',
        value: SubMainIncomeType.PARTNER,
        mainGroupCode: MainIncomeType.BUSINESS_OWNER,
    },
    {
        label: 'Public Listed SME Employee',
        value: SubMainIncomeType.PUBLIC_LISTED_SME_EMPLOYEE,
        mainGroupCode: MainIncomeType.SALARIED_NON_COMMISSION,
    },
    {
        label: 'Retiree',
        value: SubMainIncomeType.RETIREE,
        mainGroupCode: MainIncomeType.NO_INCOME,
    },
    {
        label: 'Shareholder (Ltd., Pte. Ltd., etc.)',
        value: SubMainIncomeType.SHAREHOLDER,
        mainGroupCode: MainIncomeType.BUSINESS_OWNER,
    },
    {
        label: 'SME Employee',
        value: SubMainIncomeType.SME_EMPLOYEE,
        mainGroupCode: MainIncomeType.SALARIED_NON_COMMISSION,
    },
    {
        label: 'Sole Proprietor',
        value: SubMainIncomeType.SOLE_PROPRIETOR,
        mainGroupCode: MainIncomeType.BUSINESS_OWNER,
    },
    {
        label: 'Student',
        value: SubMainIncomeType.STUDENT,
        mainGroupCode: MainIncomeType.NO_INCOME,
    },
];

export const applicationChannelOptions = [
    {
        label: 'New - Dealer',
        channel: Channel.NEW,
        access: ApplicationAccess.PRIVATE,
    },
    {
        label: 'New - Public',
        channel: Channel.NEW,
        access: ApplicationAccess.PUBLIC,
    },
    {
        label: 'Used - Dealer',
        channel: Channel.USED,
        access: ApplicationAccess.PRIVATE,
    },
    {
        label: 'Used - Public',
        channel: Channel.USED,
        access: ApplicationAccess.PUBLIC,
    },
    {
        label: 'Event - Dealer',
        channel: Channel.EVENT,
        access: ApplicationAccess.PRIVATE,
    },
    {
        label: 'Event - Public',
        channel: Channel.EVENT,
        access: ApplicationAccess.PUBLIC,
    },
    {
        label: 'Pre-owned',
        channel: Channel.EXPRESS,
        access: ApplicationAccess.PRIVATE,
    },
];

export const dataMaskModeOptions = [
    { label: 'Back', value: MaskDirection.BACK },
    { label: 'Front', value: MaskDirection.FRONT },
    { label: 'None', value: MaskDirection.NONE },
];

export const dataMaskingCountOptions = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
];

export const signingModes = [
    { label: 'None', value: BankSigningMode.NONE },
    { label: 'eSignature', value: BankSigningMode.NAMIRIAL },
    { label: 'OTP', value: BankSigningMode.OTP },
];

export const insuranceSigningModes = [
    { label: 'None', value: InsuranceSigningMode.NONE },
    { label: 'eSignature', value: InsuranceSigningMode.NAMIRIAL },
];

export const eventMediumOptions = [
    { label: 'Walk-in (Showroom)', value: EventMedium.WALKIN },
    { label: 'Event', value: EventMedium.EVENT },
    { label: 'Internet', value: EventMedium.INTERNET },
];

export const eventLeadOriginOptions = [
    { label: 'Internet', value: EventLeadOrigin.INTERNET },
    { label: 'Dealer', value: EventLeadOrigin.DEALER },
];

export const licenseTypeOptions = [
    {
        label: 'Qualified',
        value: LicenseType.QUALIFIED,
    },
    {
        label: 'Provisional',
        value: LicenseType.PROVISIONAL,
    },
    {
        label: 'Not Applicable',
        value: LicenseType.NOT_APPLICABLE,
    },
];

export const ownerIdOptions = [
    {
        label: 'Business (e.g. 51234567M)',
        value: PreOwnerIdType.BUSINESS,
    },
    {
        label: 'Club/Association/Organisation (e.g. T08PQ1234A)',
        value: PreOwnerIdType.CLUBORASSOCIATIONORORGANIZATION,
    },
    {
        label: 'Company (e.g. 198912345K)',
        value: PreOwnerIdType.COMPANY,
    },
    {
        label: 'Foreign Company (e.g. T08FC1234A)',
        value: PreOwnerIdType.FOREIGNCOMPANY,
    },
    {
        label: 'Foreign Identification Number (e.g. F/G1234567N)',
        value: PreOwnerIdType.FOREIGNIDENTIFICATIONNUMBER,
    },
    {
        label: 'Foreign Passport (e.g. 12345678)',
        value: PreOwnerIdType.FOREIGNPASSPORT,
    },
    {
        label: 'Government (e.g. T08GA1234A)',
        value: PreOwnerIdType.GOVERNMENT,
    },
    {
        label: 'Limited Liability Partnership (e.g. T08LL1234A)',
        value: PreOwnerIdType.LIMITEDLIABILITYPARTNERSHIP,
    },
    {
        label: 'Limited Partnership (e.g. T08LP1234A)',
        value: PreOwnerIdType.LIMITEDPARTNERSHIP,
    },
    {
        label: 'Malaysia NRIC (e.g. 200312345678)',
        value: PreOwnerIdType.MALAYSIANRIC,
    },
    {
        label: 'Professional (e.g. T08PQ1234A)',
        value: PreOwnerIdType.PROFESSIONAL,
    },
    {
        label: 'Singapore NRIC (e.g. S1234567D)',
        value: PreOwnerIdType.SINGAPORENRIC,
    },
    {
        label: 'Statutory Board (e.g. T08GB1234A)',
        value: PreOwnerIdType.STATUTORYBOARD,
    },
];

export const consentDataFieldOptions = [
    { label: 'None', value: ConsentOrDeclarationDataFieldName.NONE },
    { label: 'Data processing consent', value: ConsentOrDeclarationDataFieldName.DATAPROCESSING },
    { label: 'Mail consent', value: ConsentOrDeclarationDataFieldName.MAIL },
    { label: 'Phone consent', value: ConsentOrDeclarationDataFieldName.PHONE },
    { label: 'Email consent', value: ConsentOrDeclarationDataFieldName.EMAIL },
    { label: 'Fax consent', value: ConsentOrDeclarationDataFieldName.FAX },
];

export const usePaymentModeOptions = () => {
    const { t } = useTranslation();

    return [
        { value: PaymentMode.ADVANCE, label: t('calculator.label.modeOfPayment.options.advance') },
        { value: PaymentMode.ARREARS, label: t('calculator.label.modeOfPayment.options.arrears') },
    ];
};

export const showHideOptions = [
    { label: 'Hide', value: true },
    { label: 'Show', value: false },
];

export const finderVehicleAvailabilityOptions = [
    { label: 'ONE-TIME', value: FinderVehicleAvailability.ONETIME },
    { label: 'PERMANENT', value: FinderVehicleAvailability.PERMANENT },
];

export const coeCategoryOptions = [
    { label: 'Cat A', value: CoeCategory.CATA },
    { label: 'Cat B', value: CoeCategory.CATB },
    { label: 'Cat C', value: CoeCategory.CATC },
    { label: 'Cat E', value: CoeCategory.CATE },
];

export const bankPresetOptions = [
    { label: 'Default', value: BankPresetOption.NONE },
    { label: 'Affin Bank', value: BankPresetOption.AFFINBANK },
    { label: 'ENBD Bank', value: BankPresetOption.ENBDBANK },
    { label: 'PME - SA', value: BankPresetOption.PMESA },
    { label: 'TTB Bank', value: BankPresetOption.TTBBANK },
];

export const calculatorVisibilityOptions = hasOptional => [
    { label: 'ON', value: CalculatorVisibility.ON },
    { label: 'OFF', value: CalculatorVisibility.OFF },
    ...(hasOptional ? [{ label: 'OPTIONAL', value: CalculatorVisibility.OPTIONAL }] : []),
];

export const engineTypeOptions = [
    { label: 'Petrol', value: EngineType.PETROL },
    { label: 'Electric', value: EngineType.ELECTRIC },
    { label: 'Hybrid', value: EngineType.HYBRID },
];

export const hlfBankIntegrationApiVersionOptions = [
    { label: 'V1', value: HlfBankIntegrationApiVersion.V1 },
    { label: 'V2', value: HlfBankIntegrationApiVersion.V2 },
];

export const appointmentStatusOptions = [
    { label: 'New', value: AppointmentStatus.NEW },
    { label: 'Contacted', value: AppointmentStatus.CONTACTED },
    { label: 'Check-in', value: AppointmentStatus.CHECKIN },
    { label: 'Completed', value: AppointmentStatus.COMPLETED },
    { label: 'Void', value: AppointmentStatus.VOID },
];

export const appointmentPurposeOptions = [{ label: 'Test Drive', value: AppointmentPurpose.TESTDRIVE }];

export const applyCoeVehicleConditionOptions = [
    { label: 'New', value: CoeVehicleCondition.NEW },
    { label: 'Preowned', value: CoeVehicleCondition.PREOWNED },
    { label: 'All', value: CoeVehicleCondition.ALL },
];
