import cs from 'classnames';
import React, { useState, useCallback, useMemo } from 'react';
import { Field } from 'redux-form';
import {
    DocumentContainer,
    Title,
    TabContainer,
    Tabs,
    TabItem,
    DocumentBg,
    DocumentGrid,
} from '../../../../../../ui/Document';
import { useFormContext } from '../context';
import DocumentPreview from './DocumentPreview';
import DocumentUpload from './DocumentUpload';

const categories = [{ label: 'Agreement', id: 1, source: ['AGREEMENT'] }];

const Document = () => {
    const { disabled, values } = useFormContext();
    const { attachments, id: applicationId } = values;
    const [activeCategory, setActiveCategory] = useState(categories[0]);

    const selectCategory = useCallback(category => setActiveCategory(category), [setActiveCategory]);

    const renderCategoryMenu = useCallback(
        category => {
            const { id, source, label } = category;
            const counter = attachments.filter(item => source.find(i => i === item.purpose)).length;

            return (
                <TabItem
                    key={id}
                    className={cs('tab-item', { active: category === activeCategory })}
                    onClick={() => selectCategory(category)}
                >
                    {label}
                    {counter > 0 && <span>({counter})</span>}
                </TabItem>
            );
        },
        [activeCategory, attachments, selectCategory]
    );

    const activeDocuments = useMemo(
        () => attachments.filter(item => activeCategory.source.find(i => i === item.purpose)),
        [activeCategory.source, attachments]
    );

    const renderDocuments = useCallback(
        document =>
            document && <DocumentPreview key={document.id} activeCategory={activeCategory} document={document} />,
        [activeCategory]
    );

    return (
        <DocumentContainer>
            <Title>Document Management</Title>
            <TabContainer>
                <Tabs>{categories.map(renderCategoryMenu)}</Tabs>
                <DocumentBg>
                    <DocumentGrid>
                        {activeDocuments.map(renderDocuments)}
                        {activeCategory.id !== 1 && (
                            <Field
                                activeCategory={activeCategory}
                                applicationId={applicationId}
                                component={DocumentUpload}
                                disabled={disabled}
                                name="attachments"
                            />
                        )}
                    </DocumentGrid>
                </DocumentBg>
            </TabContainer>
        </DocumentContainer>
    );
};

export default Document;
