import React from 'react';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logoOnly from './logo_only_white.png';
import logo from './logo_white.png';

const LogoLink = styled(Link)`
    display: flex;
    align-items: center;

    img {
        height: 13px;
    }
`;

const Logo = () => (
    <LogoLink to="/home">
        <Media query={{ minWidth: 600 }}>
            <img alt="" src={logo} />
        </Media>
        <Media query={{ maxWidth: 600 }}>
            <img alt="" src={logoOnly} />
        </Media>
    </LogoLink>
);

export default Logo;
