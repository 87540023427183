import { useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, isNil, orderBy } from 'lodash/fp';
import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FootBar, FootBarButton, FootContainer } from '../../../../containers/Layout';
import { useContentTranslation } from '../../../../i18n';
import { getCountryId, getGlobalPermissions } from '../../../../selectors';
import { productTypesOptions } from '../../../../shared/constants/options';
import { searchOnKeys } from '../../../../utilities/fp';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { List, cells, ListSearch, DealerSelectionDropDown } from '../../../ui/lists';
import usePaging from '../../../utilities/usePaging';
import { getProducts } from './ProductList.graphql';

const useColumns = () => {
    const formatDateTime = useFormatDateTime();
    const { formatPath } = useContentTranslation();

    return useMemo(
        () => [
            { name: 'Finance ID', id: 'identifier', renderCell: get('identifier'), hasSort: true },
            {
                name: 'Financial Product',
                id: formatPath('name'),
                renderCell: get(formatPath('name')),
                hasSort: true,
                underline: true,
            },
            { name: 'Type', id: 'type', renderCell: get('type'), hasSort: true },
            { name: 'Bank', id: 'bank.name', renderCell: get(formatPath('bank.name')), hasSort: true },
            {
                name: 'Start Date',
                id: 'period.start',
                renderCell: cells.renderDateTime('period.start', formatDateTime),
                hasSort: true,
            },
            {
                name: 'End Date',
                id: 'period.end',
                renderCell: cells.renderDateTime('period.end', formatDateTime),
                hasSort: true,
            },
            { name: 'Order No.', id: 'order', renderCell: get('order'), hasSort: true },
            { name: 'Active', id: 'isActive', renderCell: cells.renderActive(), hasSort: true },
        ],
        [formatDateTime, formatPath]
    );
};

const getItemType = item => {
    const type = get('type', item);

    return productTypesOptions.find(option => option.value === type)?.label;
};

const useComputeItems = items =>
    useMemo(
        () =>
            items.map(item => ({
                ...item,
                type: getItemType(item),
            })),
        [items]
    );

const useSearchFields = () => {
    const { formatPath } = useContentTranslation();

    return useMemo(() => ['identifier', formatPath('name'), 'type', 'bank.name', 'order'], [formatPath]);
};

const sortItems = (items, [sortKey, sortOrder]) => {
    // we always sort by value key
    return orderBy(sortKey, sortOrder, items);
};

const ProductList = () => {
    const history = useHistory();
    const { mayManageFinanceProducts } = useSelector(getGlobalPermissions);

    const countryId = useSelector(getCountryId);
    const [dealerIds, setDealerIds] = useState(null);

    const variables = { countryId, dealerIds };
    const { data, loading, error } = useQuery(getProducts, {
        fetchPolicy: 'cache-and-network',
        variables,
        skip: isNil(dealerIds),
    });
    const items = useComputeItems(data?.results?.items || []);
    const isLoading = loading && items.length <= 0;

    // searching
    const [search, setSearch] = useState('');
    const searchFields = useSearchFields();
    const searchMethod = useMemo(() => searchOnKeys(searchFields, items), [items, searchFields]);
    const matchedItems = useMemo(() => searchMethod(search), [search, searchMethod]);

    // sorting
    const [sortedOn, onSort] = useState(['identifier', 'asc']);
    const sortedItems = useMemo(() => sortItems(matchedItems, sortedOn), [matchedItems, sortedOn]);

    // paging
    const [pagedItems, paging] = usePaging(sortedItems, { search });

    const columns = useColumns();

    const footer = (
        <FootContainer>
            <FootBar>
                {mayManageFinanceProducts && (
                    <FootBarButton onClick={() => history.push('/finance/products/new')}>
                        <FontAwesomeIcon icon={faPlus} /> ADD Product
                    </FootBarButton>
                )}
            </FootBar>
        </FootContainer>
    );

    return (
        <List
            activeTab="financialProduct"
            columns={columns}
            error={error}
            footer={footer}
            headerBottomComponent={<DealerSelectionDropDown dealerIds={dealerIds} onValueChanged={setDealerIds} />}
            headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
            items={pagedItems}
            loading={isLoading}
            onItemClick={item => history.push(`/finance/products/${item.id}`)}
            onSort={onSort}
            paging={paging}
            sortedOn={sortedOn}
            title="Financial Products"
        />
    );
};

export default ProductList;
