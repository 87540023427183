import { get } from 'lodash/fp';
import React from 'react';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { onOffOptions } from '../../../../../shared/constants/options';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import NumberField from '../../../../template/Number';
import ApvPortlet from '../../../../template/Portlet';
import SwitchField from '../../../../template/Switch';
import TextField from '../../../../template/TextField';
import { useFormContext } from './context';

const MainDetails = () => {
    const { values } = useFormContext();
    const { options } = values;
    const { formatPath } = useContentTranslation();

    return (
        <ApvPortlet name="Main Details" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Model">
                            <TextField name={formatPath('model.name')} disabled />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Submodel">
                            <TextField name={formatPath('subModel.name')} disabled />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Variant">
                            <TextField name={formatPath('variant.name')} disabled />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Asset Condition">
                            <Field component={InputField} name="variant.assetCondition" disabled />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="ID">
                            <Field component={InputField} name="identifier" disabled />
                        </FieldContainer>
                    </div>
                    {options.map((option, index) => (
                        <div key={option.id} className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label={get(formatPath('group.name'), option)}>
                                <Field component={InputField} name={formatPath(`options[${index}].name`)} disabled />
                            </FieldContainer>
                        </div>
                    ))}
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Initial Qty">
                            <Field component={NumberField} name="inventorySet.initialStock" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Remaining Qty">
                            <Field component={NumberField} name="inventorySet.remainingStock" disabled />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Reserved Qty">
                            <Field component={NumberField} name="inventorySet.reservedStock" disabled />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Deducted Qty">
                            <Field component={NumberField} name="inventorySet.deductedStock" disabled />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="VIN Assigned">
                            <Field component={NumberField} name="inventorySet.vins" disabled />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Active">
                            <Field component={SwitchField} name="inventorySet.isActive" options={onOffOptions} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Last Modified">
                            <Field component={InputField} name="lastModified" disabled />
                        </FieldContainer>
                    </div>
                </div>
            </div>
        </ApvPortlet>
    );
};

export default MainDetails;
