import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field, FormSection } from 'redux-form';
import { Rounding, ChatbotType } from '../../../../../../schema';
import { onOffOptions } from '../../../../../../shared/constants/options';
import DropdownField from '../../../../../template/Dropdown';
import FieldContainer from '../../../../../template/Field-container';
import InputField from '../../../../../template/Input';
import KeyField from '../../../../../template/KeyField';
import SwitchField from '../../../../../template/Switch';
import { ChannelTitle } from '../../../../../ui/Channel';
import PaymentIntegration from '../../../bank/Form/PaymentIntegration';
import { useFormContext } from '../context';
import ChannelURL from './ChannelURL';
import useToggleOtherField from './useToggleOtherField';

type UsedProps = {
    ciBaseLink: string;
    companyCode: string;
    countryZoneCode: string;
    currency: string;
    getHush: (rawKey: string) => Promise<string>;
    rounding: Rounding;
};

const Used = ({ getHush, companyCode, countryZoneCode, rounding, currency, ciBaseLink }: UsedProps) => {
    const { values, change, settings, apiData } = useFormContext();
    const chatbotOptions = apiData?.options?.chatbotOptions;
    const valuePrefix = 'channelSetting.used';
    const url = `/${companyCode}/${countryZoneCode}/used`;

    const allowFinanceApplication = get(`${valuePrefix}.allowFinanceApplication`, values);
    const isDepositPaymentMandatory = get(`${valuePrefix}.isDepositPaymentMandatory`, values);
    const isPromoCodeEnabled = get(`${valuePrefix}.isPromoCodeEnabled`, values);
    const selectedChatbot = get(`${valuePrefix}.chatbot.type`, values);
    const isChatbotEnabled = get(`${valuePrefix}.isChatbotEnabled`, values);

    useToggleOtherField(`${valuePrefix}.isDepositPaymentMandatory`, `${valuePrefix}.allowFinanceApplication`);
    useToggleOtherField(`${valuePrefix}.allowFinanceApplication`, `${valuePrefix}.isDepositPaymentMandatory`);

    useEffect(() => {
        if (!allowFinanceApplication || !isDepositPaymentMandatory) {
            change(`${valuePrefix}.isFinanceApplicationOptional`, false);
        }
    }, [allowFinanceApplication, change, isDepositPaymentMandatory]);

    useEffect(() => {
        if (!isDepositPaymentMandatory) {
            change(`${valuePrefix}.bookingPayment`, null);
        }
    }, [change, isDepositPaymentMandatory]);

    useEffect(() => {
        if (!isChatbotEnabled) {
            change(`${valuePrefix}.chatbot.dialogflowCredentials`, null);
            change(`${valuePrefix}.chatbot.userlikeCredentials`, null);
        }
    }, [change, isChatbotEnabled]);

    return (
        <div className="container-fluid">
            <ChannelTitle>Used Car Channel</ChannelTitle>
            <ChannelURL path={url} to={ciBaseLink + url} />
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Active">
                        <Field component={SwitchField} name={`${valuePrefix}.isActive`} options={onOffOptions} />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Calculate with Monthly Payment">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.allowReverseCalculator`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Promo Code">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.isPromoCodeEnabled`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                {isPromoCodeEnabled && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Always Show Promo Code">
                            <Field
                                component={SwitchField}
                                name={`${valuePrefix}.alwaysShowPromoCode`}
                                options={onOffOptions}
                            />
                        </FieldContainer>
                    </div>
                )}

                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Trade-in">
                        <Field component={SwitchField} name={`${valuePrefix}.allowTradeIn`} options={onOffOptions} />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Test Drive">
                        <Field component={SwitchField} name={`${valuePrefix}.allowTestDrive`} options={onOffOptions} />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Sharing">
                        <Field component={SwitchField} name={`${valuePrefix}.allowSharing`} options={onOffOptions} />
                    </FieldContainer>
                </div>
                {/* Hide it for now, Later we may need based on requirements */}
                {/* 
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Login Access">
                            <Field
                                component={SwitchField}
                                name={`${valuePrefix}.allowPrivateAccess`}
                                options={onOffOptions}
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Public Access">
                            <Field
                                component={SwitchField}
                                name={`${valuePrefix}.allowPublicAccess`}
                                options={onOffOptions}
                            />
                        </FieldContainer>
                    </div>
                 */}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Booking Deposit Payment">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.isDepositPaymentMandatory`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Finance Application">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.allowFinanceApplication`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                {allowFinanceApplication && isDepositPaymentMandatory && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Finance Application is Optional">
                            <Field
                                component={SwitchField}
                                name={`${valuePrefix}.isFinanceApplicationOptional`}
                                options={onOffOptions}
                            />
                        </FieldContainer>
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Proceed with Customer's Device">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.allowProceedWithCustomerDevice`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Monthly Payment Filter">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.filterByMonthlyInstalment`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Chatbot">
                        <Field
                            component={SwitchField}
                            name={`${valuePrefix}.isChatbotEnabled`}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
            </div>
            {isDepositPaymentMandatory && (
                <FormSection name={valuePrefix}>
                    <PaymentIntegration currency={currency} rounding={rounding} settings={settings} />
                </FormSection>
            )}
            {isChatbotEnabled && (
                <>
                    <hr />
                    <div className="row">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Chatbot Agent">
                                <DropdownField name={`${valuePrefix}.chatbot.type`} options={chatbotOptions} disabled />
                            </FieldContainer>
                        </div>
                        {selectedChatbot === ChatbotType.DIALOGFLOW && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Dialogflow Credentials">
                                    <Field
                                        accept=".json"
                                        component={KeyField}
                                        hashFun={getHush}
                                        name={`${valuePrefix}.chatbot.dialogflowCredentials`}
                                    />
                                </FieldContainer>
                            </div>
                        )}
                        {selectedChatbot === ChatbotType.USERLIKE && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Widget Key">
                                    <Field component={InputField} name={`${valuePrefix}.chatbot.userlikeCredentials`} />
                                </FieldContainer>
                            </div>
                        )}
                    </div>
                </>
            )}
            <hr />
        </div>
    );
};

export default Used;
