import { get, isEmpty } from 'lodash/fp';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useContentTranslation } from '../../../../../i18n';
import { modelTypeOptions } from '../../../../../shared/constants/options';
import { useSort } from '../../../../../utilities/hooks';
import ApvPortlet from '../../../../template/Portlet';
import { cells, ListTable } from '../../../../ui/lists';
import { useFormContext } from './context';

const columns = formatPath => [
    { name: 'Model ID', id: 'identifier', renderCell: get('identifier'), hasSort: true },
    { name: 'Model Name', id: 'name', renderCell: get(formatPath('name')), hasSort: true, underline: true },
    {
        name: 'Body Type',
        id: 'bodyType',
        renderCell: item => modelTypeOptions.find(option => option.value === item.type)?.label,
        hasSort: true,
    },
    { name: 'Order No.', id: 'order', renderCell: get('order'), hasSort: true },
    { name: 'Active', id: 'active', renderCell: cells.renderActive(), hasSort: true },
];

const Models = () => {
    const history = useHistory();
    const { formatPath } = useContentTranslation();
    const { models } = useFormContext();

    // sorting
    const [sortedOn, onSort] = useState(['identifier', 'asc']);
    const sortedItems = useSort(models, sortedOn);

    if (isEmpty(models)) {
        return null;
    }

    return (
        <ApvPortlet name="Model" closable open>
            <br />
            <div className="container-fluid">
                <ListTable
                    columns={columns(formatPath)}
                    items={sortedItems}
                    onItemClick={item => history.push(`/vehicle/models/${item.id}`)}
                    onSort={onSort}
                    sortedOn={sortedOn}
                />
            </div>
        </ApvPortlet>
    );
};

export default Models;
