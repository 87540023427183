import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getGlobalPermissions } from '../../selectors';
import { AppointmentEdition, AppointmentList } from '../routes/Appointments';

const AppointmentRouter = () => {
    const { mayViewAppointments } = useSelector(getGlobalPermissions);

    if (!mayViewAppointments) {
        return <Redirect to="/home" />;
    }

    return (
        <Switch>
            <Route component={AppointmentEdition} path="/appointment/:versionId" exact />
            <Route component={AppointmentList} path="/appointment" exact />
            <Redirect to="/home" />
        </Switch>
    );
};

export default AppointmentRouter;
