import React from 'react';
import { useCalculatorContext } from '../../CalculatorContext';
import { useSnapshotValue } from './ConnectedSnapshotField';
import MonthlyInstalmentField from './MonthlyInstalmentField';

const MonthlyInstalmentSnapshot = props => {
    // get the calculator context
    const { snapshot } = useCalculatorContext();
    const monthlyInstalment = useSnapshotValue('monthlyInstalment');
    const interestOnlyTerms = snapshot?.selectedFinanceProduct?.termSetting?.interestOnly;
    const paymentTerm = useSnapshotValue('paymentTerm');

    return (
        <MonthlyInstalmentField
            {...props}
            interestOnlyTerms={interestOnlyTerms}
            monthlyInstalment={monthlyInstalment}
            paymentTerm={paymentTerm}
        />
    );
};

export default MonthlyInstalmentSnapshot;
