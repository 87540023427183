import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import TranslationsProvider from '../../i18n';
import { getCompanyIdentifier, getCurrentCountry, getIsAuthenticated } from '../../selectors';
import { getLoginUrl } from '../../utilities/urls';
import useLoadCompany from '../../utils/useLoadCompany';
import Channels from '../routes/Channels';
import Dashboard from '../routes/Dashboard';
import NotFoundCompanyCountryRoute from '../routes/LoginRoute/NotFoundCompanyCountryRoute';
import Maintenance from '../routes/Maintenance';
import ProfileRoute from '../routes/ProfileRoute';
import RefreshAuthorization from '../shared/RefreshAuthorization';
import Wrapper from '../shared/Wrapper';
import useScopeChangeEffect from '../utilities/useScopeChangeEffect';
import AccessRouter from './AccessRouter';
import AppointmentRouter from './AppointmentRouter';
import ConsentRouter from './ConsentRouter';
import CustomerRouter from './CustomerRouter';
import EventsRouter from './EventsRouter';
import FinanceRouter from './FinanceRouter';
import FinderVehicleRouter from './FinderVehicleRouter';
import PromoRouter from './PromoRouter';
import ScopeRouter from './ScopeRouter';
import VehicleRouter from './VehicleRouter';
import WorkflowRouter from './WorkflowRouter';

const AuthenticatedRouter = () => {
    const isAuthenticated = useSelector(getIsAuthenticated);

    // get the codes from the redux state
    const { companyCode, countryCode, zoneCode, defaultZoneCode } = useSelector(getCompanyIdentifier);

    // load the scope
    const [isLoading, scope] = useLoadCompany(companyCode, countryCode, zoneCode, defaultZoneCode);
    const country = useSelector(getCurrentCountry);

    // watch scope change to redirect page to list
    useScopeChangeEffect(companyCode, countryCode, zoneCode);

    if (!isAuthenticated) {
        return <Redirect to={getLoginUrl()} />;
    }

    if (!isLoading && !country) {
        // return no company country page
        return <NotFoundCompanyCountryRoute />;
    }

    if (!country || country?.id !== scope?.country?.id) {
        // return an empty page to keep the user waiting
        // either we are still trying to get the zone or the user, maybe both
        return null;
    }

    return (
        <TranslationsProvider key={scope.country.id} country={scope.country}>
            <Wrapper>
                <Switch>
                    <Route component={ProfileRoute} path="/profile" />
                    <Route component={Dashboard} path="/home" exact />
                    <Route component={FinanceRouter} path="/finance" />
                    <Route component={ScopeRouter} path="/scope" />
                    <Route component={VehicleRouter} path="/vehicle" />
                    <Route component={AccessRouter} path="/access" />
                    <Route component={Maintenance} path="/maintenance" exact />
                    <Route component={WorkflowRouter} path="/workflow" />
                    <Route component={CustomerRouter} path="/customer" />
                    <Route component={PromoRouter} path="/promo" />
                    <Route component={Channels} path="/channels" exact />
                    <Route component={EventsRouter} path="/event" />
                    <Route component={ConsentRouter} path="/consent" />
                    <Route component={FinderVehicleRouter} path="/finder" />
                    <Route component={AppointmentRouter} path="/appointment" />
                    <Redirect to="/home" />
                </Switch>
                <RefreshAuthorization />
            </Wrapper>
        </TranslationsProvider>
    );
};

export default AuthenticatedRouter;
