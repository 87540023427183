import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { toDuration } from '../../../utilities/fp';
import Tooltip from '../tooltip/Tooltip';
import useTooltip from '../tooltip/useTooltip';

const Container = styled.div`
    background: #acb7bf;
    color: white;
    margin-bottom: 1.35rem;
    padding: 1.78rem 2.14rem;
    border-radius: 0.25rem;
    height: 9rem;
    position: relative;

    .bg-icon {
        display: inline-block;
        font-size: 5.71rem;
        position: absolute;
        z-index: 1;
        bottom: -2.86rem;
        right: -1.79rem;
        opacity: 0.4;
    }

    .info {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;

        svg {
            font-size: 24px;
        }
    }

    h2 {
        display: flex;
        align-items: center;
        i {
            margin-right: 5px;
        }
    }
`;

const Card = ({ item, value }) => {
    const valueRef = useRef(null);
    const tooltipRef = useTooltip(valueRef);

    return (
        <>
            <Container>
                <div className="bg-icon">
                    <i className={item.bgIcon} />
                </div>
                <h2>
                    <i className={item.tagIcon} />
                    {(item.keyType === 'duration' ? toDuration(value[item.key]) : value[item.key]) ||
                        (item.keyType === 'number' ? 0 : '-')}
                </h2>
                <span ref={valueRef} className="info">
                    <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                <p>{item.label}</p>
            </Container>
            <Tooltip ref={tooltipRef}>{item.indication}</Tooltip>
        </>
    );
};

Card.propTypes = {
    item: PropTypes.shape({
        active: PropTypes.bool,
        bgIcon: PropTypes.string.isRequired,
        indication: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        keyType: PropTypes.string,
        label: PropTypes.string.isRequired,
        subLabel: PropTypes.string,
        tagIcon: PropTypes.string.isRequired,
    }),
    value: PropTypes.shape(),
};

export default Card;
