import { useQuery } from '@apollo/client';
import PropType from 'prop-types';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { useContentTranslation } from '../../../../../i18n';
import { toFormState } from '../../../../../utilities/applications/toFormState';
import withCompanyFormatting from '../../../../../utils/withCompanyFormatting';
import useFormatDateTime from '../../../../shared/useFormatDateTime';
import { getLead } from './Lead.graphql';
import Page from './Page';

const Editor = ({ formats }) => {
    const { id: versionId } = useParams();
    const { data, loading } = useQuery(getLead, { fetchPolicy: 'no-cache', variables: { versionId } });

    const translation = useContentTranslation();
    const formatDateTime = useFormatDateTime();

    const initialValues = useMemo(() => {
        if (!data?.lead) {
            return null;
        }

        return toFormState(data?.lead, formats, formatDateTime, translation);
    }, [data, formats, formatDateTime, translation]);

    if (!loading && !initialValues) {
        return <Redirect to="/workflow/leads" />;
    }

    return <Page initialValues={initialValues} />;
};

Editor.propTypes = {
    formats: PropType.shape({}),
};

export default withCompanyFormatting(Editor);
