/* eslint-disable max-len */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Upload: any;
    /**
     * A field whose value conforms with the standard mongodb object ID as described here:
     * https://docs.mongodb.com/manual/reference/method/ObjectId/#ObjectId. Example: 5e5677d71bdc2ae76344968c
     */
    ObjectID: string;
    /**
     * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6
     * of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.
     */
    DateTime: Date;
    /** JSON Object */
    JSONObject: object;
    /** A field whose value is string key value pairs, used in i18n. */
    IntlValue: any;
};

export type AdhocEventDetailPayload = {
    id: Scalars['ObjectID'];
};

export type CreateAdhocEventPayload = {
    type: AdhocEventType;
    referenceId?: Maybe<Scalars['ObjectID']>;
    detail: AdhocEventDetailPayload;
};

export type ApplicationCalculatorGenericValuePayload = {
    amount: Scalars['Float'];
    percentage: Scalars['Float'];
};

export type ApplicationCalculatorAdjustmentPayload = {
    amount: Scalars['Float'];
    label?: Maybe<Scalars['String']>;
};

export type ApplicationCalculatorCoePayload = {
    amount: Scalars['Float'];
};

export type ApplicationCalculatorDealerOptionsPayload = {
    amount: Scalars['Float'];
};

export type ApplicationCalculatorDealerOptionDetailsPayload = {
    description?: Maybe<Scalars['String']>;
    amount: Scalars['Float'];
};

export type ApplicationCalculatorPpsrPayload = {
    amount: Scalars['Float'];
};

export type ApplicationCalculatorEstablishmentPayload = {
    amount: Scalars['Float'];
};

export type ApplicationCalculatorLuxuryTaxPayload = {
    amount: Scalars['Float'];
};

export type ApplicationCalculatorMonthlyInstalmentPayload = {
    amount: Scalars['Float'];
    start: Scalars['Int'];
    end: Scalars['Int'];
};

export type ApplicationCalculatorPayload = {
    paymentMode?: Maybe<PaymentMode>;
    price?: Maybe<Scalars['Float']>;
    downPayment?: Maybe<ApplicationCalculatorGenericValuePayload>;
    loan?: Maybe<ApplicationCalculatorGenericValuePayload>;
    term?: Maybe<Scalars['Int']>;
    interestRate?: Maybe<Scalars['Float']>;
    balloon?: Maybe<ApplicationCalculatorGenericValuePayload>;
    deposit?: Maybe<ApplicationCalculatorGenericValuePayload>;
    adjustments?: Maybe<Array<ApplicationCalculatorAdjustmentPayload>>;
    averageMileage?: Maybe<Scalars['Float']>;
    coe?: Maybe<ApplicationCalculatorCoePayload>;
    establishment?: Maybe<ApplicationCalculatorEstablishmentPayload>;
    ppsr?: Maybe<ApplicationCalculatorPpsrPayload>;
    luxuryTax?: Maybe<ApplicationCalculatorLuxuryTaxPayload>;
    monthlyInstalments?: Maybe<Array<ApplicationCalculatorMonthlyInstalmentPayload>>;
    tradeIn?: Maybe<Scalars['Float']>;
    tradeInEquity?: Maybe<Scalars['Float']>;
    liability?: Maybe<Scalars['Float']>;
    residualValue?: Maybe<ApplicationCalculatorGenericValuePayload>;
    cashPayment?: Maybe<Scalars['Float']>;
    totalPrice?: Maybe<Scalars['Float']>;
    dealerOptions?: Maybe<ApplicationCalculatorDealerOptionsPayload>;
    dealerOptionDetails?: Maybe<Array<ApplicationCalculatorDealerOptionDetailsPayload>>;
    estimatedSurplus?: Maybe<Scalars['Float']>;
    totalInterestPayable?: Maybe<Scalars['Float']>;
    licensePlateFee?: Maybe<Scalars['Float']>;
    commission?: Maybe<Scalars['Float']>;
    fixedInterestRate?: Maybe<Scalars['Float']>;
    licenseAndFuelTax?: Maybe<Scalars['Float']>;
    displacement?: Maybe<Scalars['Float']>;
    insuranceFee?: Maybe<ApplicationCalculatorGenericValuePayload>;
    taxLoss?: Maybe<Scalars['Float']>;
    expectedTradeInAmount?: Maybe<Scalars['Float']>;
    expectedCashPayment?: Maybe<Scalars['Float']>;
    totalAmountPayable?: Maybe<Scalars['Float']>;
    nzFee?: Maybe<Scalars['Float']>;
    dealerEstablishment?: Maybe<Scalars['Float']>;
};

export type ApplicationBankPayload = {
    id: Scalars['ObjectID'];
};

export type UpdateApplicationPayload = {
    guarantorId?: Maybe<Scalars['ObjectID']>;
    financeProductId?: Maybe<Scalars['ObjectID']>;
    variantId: Scalars['ObjectID'];
    optionIds: Array<Scalars['ObjectID']>;
    assigneeId?: Maybe<Scalars['ObjectID']>;
    promoCodeId?: Maybe<Scalars['ObjectID']>;
    calculator: ApplicationCalculatorPayload;
    proceedWithCustomerDevice: Scalars['Boolean'];
    remark?: Maybe<Scalars['String']>;
    chassisNumber?: Maybe<Scalars['String']>;
};

export enum MarriageStatus {
    MARRIED = 'Married',
    SINGLE = 'Single',
}

export type UpdateLeadPayload = {
    financeProductId?: Maybe<Scalars['ObjectID']>;
    variantId: Scalars['ObjectID'];
    optionIds: Array<Scalars['ObjectID']>;
    assigneeId?: Maybe<Scalars['ObjectID']>;
    calculator: ApplicationCalculatorPayload;
    bank?: Maybe<ApplicationBankPayload>;
    remark?: Maybe<Scalars['String']>;
};

export type FinanceContextVariantPayload = {
    id?: Maybe<Scalars['ObjectID']>;
};

export type FinanceContextCalculatorGenericValuePayload = {
    amount: Scalars['Float'];
    percentage: Scalars['Float'];
};

export type FinanceContextCalculatorCoePayload = {
    amount: Scalars['Float'];
};

export type FinanceContextCalculatorDealerOptionsPayload = {
    amount: Scalars['Float'];
};

export type FinanceContextCalculatorPpsrPayload = {
    amount: Scalars['Float'];
};

export type FinanceContextCalculatorEstablishmentPayload = {
    amount: Scalars['Float'];
};

export type FinanceContextCalculatorAdjustmentPayload = {
    amount: Scalars['Float'];
    label?: Maybe<Scalars['String']>;
};

export type FinanceContextCalculatorPayload = {
    paymentMode?: Maybe<PaymentMode>;
    price?: Maybe<Scalars['Float']>;
    totalPrice?: Maybe<Scalars['Float']>;
    downPayment?: Maybe<FinanceContextCalculatorGenericValuePayload>;
    loan?: Maybe<FinanceContextCalculatorGenericValuePayload>;
    term?: Maybe<Scalars['Int']>;
    interestRate?: Maybe<Scalars['Float']>;
    balloon?: Maybe<FinanceContextCalculatorGenericValuePayload>;
    deposit?: Maybe<FinanceContextCalculatorGenericValuePayload>;
    averageMileage?: Maybe<Scalars['Float']>;
    adjustments?: Maybe<Array<FinanceContextCalculatorAdjustmentPayload>>;
    coe?: Maybe<FinanceContextCalculatorCoePayload>;
    dealerOptions?: Maybe<FinanceContextCalculatorDealerOptionsPayload>;
    dealerOptionDetails?: Maybe<Array<ApplicationCalculatorDealerOptionDetailsPayload>>;
    ppsr?: Maybe<FinanceContextCalculatorPpsrPayload>;
    establishment?: Maybe<FinanceContextCalculatorEstablishmentPayload>;
    monthlyInstalments?: Maybe<Array<ApplicationCalculatorMonthlyInstalmentPayload>>;
    tradeIn?: Maybe<Scalars['Float']>;
    tradeInEquity?: Maybe<Scalars['Float']>;
    liability?: Maybe<Scalars['Float']>;
    residualValue?: Maybe<FinanceContextCalculatorGenericValuePayload>;
    cashPayment?: Maybe<Scalars['Float']>;
    estimatedSurplus?: Maybe<Scalars['Float']>;
    totalInterestPayable?: Maybe<Scalars['Float']>;
    licensePlateFee?: Maybe<Scalars['Float']>;
    commission?: Maybe<Scalars['Float']>;
    fixedInterestRate?: Maybe<Scalars['Float']>;
    licenseAndFuelTax?: Maybe<Scalars['Float']>;
    displacement?: Maybe<Scalars['Float']>;
    insuranceFee?: Maybe<FinanceContextCalculatorGenericValuePayload>;
    taxLoss?: Maybe<Scalars['Float']>;
    expectedTradeInAmount?: Maybe<Scalars['Float']>;
    expectedCashPayment?: Maybe<Scalars['Float']>;
    totalAmountPayable?: Maybe<Scalars['Float']>;
    nzFee?: Maybe<Scalars['Float']>;
    dealerEstablishment?: Maybe<Scalars['Float']>;
};

export type FinanceContextOptionPayload = {
    id: Scalars['ObjectID'];
    selected: Scalars['Boolean'];
    image?: Maybe<Scalars['String']>;
};

export type FinanceContextOptionGroupPayload = {
    id: Scalars['ObjectID'];
    options: Array<FinanceContextOptionPayload>;
};

export type FinanceContextPayload = {
    financeProductId?: Maybe<Scalars['ObjectID']>;
    makeId?: Maybe<Scalars['ObjectID']>;
    variant: FinanceContextVariantPayload;
    optionGroups: Array<FinanceContextOptionGroupPayload>;
    bankId?: Maybe<Scalars['ObjectID']>;
    calculator: FinanceContextCalculatorPayload;
    unitId?: Maybe<Scalars['ObjectID']>;
};

export type ApplicationCsvConfiguratorModelPayload = {
    year: Scalars['String'];
    name: Scalars['String'];
};

export type ApplicationCsvConfiguratorVariantPayload = {
    name: Scalars['String'];
    price: Scalars['Float'];
    identifier: Scalars['String'];
    imageUrl?: Maybe<Scalars['String']>;
};

export type ApplicationCsvConfiguratorOptionPayload = {
    colour?: Maybe<Scalars['String']>;
    identifier: Scalars['String'];
    price: Scalars['Float'];
    translatedName: Scalars['String'];
    thumbnailUrl?: Maybe<Scalars['String']>;
};

export type ApplicationCsvConfiguratorPayload = {
    porscheCode: Scalars['String'];
    model: ApplicationCsvConfiguratorModelPayload;
    variant: ApplicationCsvConfiguratorVariantPayload;
    options: Array<Maybe<ApplicationCsvConfiguratorOptionPayload>>;
    totalPrice: Scalars['Float'];
    url: Scalars['String'];
};

export type InsuranceDraftPayload = {
    comment?: Maybe<Scalars['String']>;
    calculator: InsuranceApplicationCalculatorPayload;
};

export type CreateDraftPayload = {
    draft?: Maybe<Scalars['Boolean']>;
    assigneeId?: Maybe<Scalars['ObjectID']>;
    zoneId: Scalars['ObjectID'];
    dealerId: Scalars['ObjectID'];
    financeProductId?: Maybe<Scalars['ObjectID']>;
    variantId: Scalars['ObjectID'];
    optionIds: Array<Scalars['ObjectID']>;
    calculator: ApplicationCalculatorPayload;
    promoCodeId?: Maybe<Scalars['ObjectID']>;
    eventId?: Maybe<Scalars['ObjectID']>;
    remark?: Maybe<Scalars['String']>;
    chassisNumber?: Maybe<Scalars['String']>;
    appliedForFinancing: Scalars['Boolean'];
    proceedWithCustomerDevice: Scalars['Boolean'];
    bank?: Maybe<ApplicationBankPayload>;
    uploadId?: Maybe<Scalars['ObjectID']>;
    customerId: Scalars['ObjectID'];
    guarantorId?: Maybe<Scalars['ObjectID']>;
    leadId?: Maybe<Scalars['ObjectID']>;
    bookingId?: Maybe<Scalars['ObjectID']>;
    channel: Channel;
    tradeIn?: Maybe<VehiclePayload>;
    hasTestDrive?: Maybe<Scalars['Boolean']>;
    hasTradeIn?: Maybe<Scalars['Boolean']>;
    locale: Scalars['String'];
    assetCondition?: Maybe<AssetCondition>;
    miniConfiguratorDetails?: Maybe<SaveCustomerOrderPayload>;
    finderVehicleId?: Maybe<Scalars['ObjectID']>;
    isCalculatorEnabled?: Maybe<Scalars['Boolean']>;
    csvConfigurator?: Maybe<ApplicationCsvConfiguratorPayload>;
    insurance?: Maybe<InsuranceDraftPayload>;
    reservationVersionId?: Maybe<Scalars['ObjectID']>;
};

export type UpdateDraftPayload = {
    financeProductId: Scalars['ObjectID'];
    variantId: Scalars['ObjectID'];
    optionIds: Array<Scalars['ObjectID']>;
    calculator: ApplicationCalculatorPayload;
    promoCodeId?: Maybe<Scalars['ObjectID']>;
    remark?: Maybe<Scalars['String']>;
    chassisNumber?: Maybe<Scalars['String']>;
    appliedForFinancing: Scalars['Boolean'];
    proceedWithCustomerDevice: Scalars['Boolean'];
    bank: ApplicationBankPayload;
    customerId: Scalars['ObjectID'];
};

export type SubmitApplicatoinPayload = {
    uploadId?: Maybe<Scalars['ObjectID']>;
};

export type ApplicationEventDetailPayload = {
    id: Scalars['ObjectID'];
};

export type CommentApplicationEventDetailPayload = {
    comment: Scalars['String'];
};

export type CreateApplicationEventPayload = {
    type: ApplicationEventType;
    detail: ApplicationEventDetailPayload;
    source?: Maybe<ApplicationEventSource>;
};

export type AddApplicationCommentPayload = {
    applicationId: Scalars['ObjectID'];
    type: ApplicationEventType;
    detail: CommentApplicationEventDetailPayload;
    source?: Maybe<ApplicationEventSource>;
};

export type ApplicationFilteringRule = {
    identifier: Scalars['String'];
    value: Scalars['String'];
};

export type ApplicationListFilterInput = {
    dealerIds?: Maybe<Array<Scalars['ObjectID']>>;
    phase?: Maybe<ApplicationPhase>;
    eventIds?: Maybe<Array<Scalars['ObjectID']>>;
};

export type AppointmentFilterPayload = {
    dealerIds?: Maybe<Array<Scalars['ObjectID']>>;
    zoneId?: Maybe<Scalars['ObjectID']>;
    countryId?: Maybe<Scalars['ObjectID']>;
    startAppointmentDate?: Maybe<Scalars['DateTime']>;
    endAppointmentDate?: Maybe<Scalars['DateTime']>;
    exceptId?: Maybe<Scalars['ObjectID']>;
};

export type AppointmentFlowPayload = {
    appointmentDate: Scalars['DateTime'];
};

export type BankIntegrationPayload = {
    provider: BankIntegrationProvider;
    apiVersion: HlfBankIntegrationApiVersion;
    v1DealerCode: Scalars['String'];
    v2DealerCode: Scalars['String'];
    base: Scalars['String'];
    submissionPartial: Scalars['String'];
    editionPartial: Scalars['String'];
    cancellationPartial: Scalars['String'];
};

export type MyInfoIntegrationPayload = {
    clientId: Scalars['String'];
    clientSecret: Scalars['String'];
    privateKey: Scalars['String'];
    privateKeyPassphrase?: Maybe<Scalars['String']>;
    publicCert: Scalars['String'];
    purpose: Scalars['String'];
};

export type BankSigningSettingsPayload = {
    onCreate: BankSigningMode;
    onUpdate: BankSigningMode;
};

export type CreateBankPayload = {
    countryId: Scalars['ObjectID'];
    zoneIds: Array<Scalars['ObjectID']>;
    dealerIds: Array<Scalars['ObjectID']>;
    name: Scalars['IntlValue'];
    email: Scalars['String'];
    color: Scalars['String'];
    order: Scalars['Int'];
    signing: BankSigningSettingsPayload;
    integration?: Maybe<BankIntegrationPayload>;
    myInfo?: Maybe<MyInfoIntegrationPayload>;
    isKYCMandatory: Scalars['Boolean'];
    isGuarantorIncluded: Scalars['Boolean'];
    hasVSOUpload: Scalars['Boolean'];
    isActive: Scalars['Boolean'];
    remoteFlowAcknowledgmentInfo: Scalars['IntlValue'];
    presetOption: BankPresetOption;
    sendCustomerConfirmationEmail: Scalars['Boolean'];
    establishment?: Maybe<Scalars['Float']>;
    isGuaranteedBuybackEnabled: Scalars['Boolean'];
};

export type UpdateBankPayload = {
    zoneIds: Array<Scalars['ObjectID']>;
    dealerIds: Array<Scalars['ObjectID']>;
    name: Scalars['IntlValue'];
    email: Scalars['String'];
    color: Scalars['String'];
    order: Scalars['Int'];
    signing: BankSigningSettingsPayload;
    integration?: Maybe<BankIntegrationPayload>;
    myInfo?: Maybe<MyInfoIntegrationPayload>;
    isKYCMandatory: Scalars['Boolean'];
    isGuarantorIncluded: Scalars['Boolean'];
    hasVSOUpload: Scalars['Boolean'];
    isActive: Scalars['Boolean'];
    remoteFlowAcknowledgmentInfo?: Maybe<Scalars['IntlValue']>;
    presetOption: BankPresetOption;
    sendCustomerConfirmationEmail: Scalars['Boolean'];
    establishment?: Maybe<Scalars['Float']>;
    isGuaranteedBuybackEnabled: Scalars['Boolean'];
};

export type CreateOrUpdateCompanyPayload = {
    code: Scalars['String'];
    name: Scalars['IntlValue'];
    email: Scalars['String'];
    color: Scalars['String'];
    copyright: Scalars['IntlValue'];
    description?: Maybe<Scalars['IntlValue']>;
    isActive: Scalars['Boolean'];
};

export type UpdateCompanyMlcPayload = {
    name: Scalars['IntlValue'];
    description?: Maybe<Scalars['IntlValue']>;
};

export type FlowConsentPayload = {
    dealerId: Scalars['ObjectID'];
    bankId?: Maybe<Scalars['ObjectID']>;
    eventId?: Maybe<Scalars['ObjectID']>;
    insuranceId?: Maybe<Scalars['ObjectID']>;
};

export type ConsentOrDeclarationOwnerPayload = {
    type: ConsentOrDeclarationOwnerType;
    id: Scalars['ObjectID'];
};

export type CreateOrUpdateConsentOrDeclarationPayload = {
    owner: ConsentOrDeclarationOwnerPayload;
    dealerIds: Array<Scalars['ObjectID']>;
    name?: Maybe<Scalars['IntlValue']>;
    description?: Maybe<Scalars['IntlValue']>;
    order: Scalars['Int'];
    hasCheckbox: Scalars['Boolean'];
    isMandatory: Scalars['Boolean'];
    legalMarkup?: Maybe<Scalars['IntlValue']>;
    dataFieldName?: Maybe<ConsentOrDeclarationDataFieldName>;
    isActive: Scalars['Boolean'];
    identifier: Scalars['String'];
};

export type CreateCountryPayload = {
    companyId: Scalars['ObjectID'];
    code: Scalars['String'];
    mlcName?: Maybe<Scalars['IntlValue']>;
    languages: Array<Scalars['String']>;
    currency: Scalars['String'];
    mask?: Maybe<MaskPayload>;
    rounding: RoundingPayload;
    email: EmailProviderPayload;
    matchExistingCustomer: Scalars['Boolean'];
    sendSalesPersonConfirmationEmail: Scalars['Boolean'];
    googleTagManager?: Maybe<GoogleTagManagerPayload>;
    useExternalCalculator: Scalars['Boolean'];
    usePorscheFinder: Scalars['Boolean'];
    useIccCheckout: Scalars['Boolean'];
    useAutoplay: Scalars['Boolean'];
    coe?: Maybe<CoePayload>;
    ppsr?: Maybe<PpsrPayload>;
    establishment?: Maybe<EstablishmentPayload>;
    luxuryTax?: Maybe<LuxuryTaxPayload>;
    channelSetting: ChannelSettingPayload;
    externalLinks?: Maybe<CountryExternalLinksPayload>;
};

export type UpdateCountryPayload = {
    languages: Array<Scalars['String']>;
    currency: Scalars['String'];
    mask?: Maybe<MaskPayload>;
    mlcName?: Maybe<Scalars['IntlValue']>;
    rounding: RoundingPayload;
    email: EmailProviderPayload;
    matchExistingCustomer: Scalars['Boolean'];
    sendSalesPersonConfirmationEmail: Scalars['Boolean'];
    googleTagManager?: Maybe<GoogleTagManagerPayload>;
    useExternalCalculator: Scalars['Boolean'];
    usePorscheFinder: Scalars['Boolean'];
    useIccCheckout: Scalars['Boolean'];
    useAutoplay: Scalars['Boolean'];
    coe?: Maybe<CoePayload>;
    ppsr?: Maybe<PpsrPayload>;
    establishment?: Maybe<EstablishmentPayload>;
    luxuryTax?: Maybe<LuxuryTaxPayload>;
    channelSetting: ChannelSettingPayload;
    externalLinks?: Maybe<CountryExternalLinksPayload>;
};

export type ChannelSettingPayload = {
    new: NewChannelPayload;
    used: UsedChannelPayload;
    express: ExpressChannelPayload;
    event: EventChannelPayload;
};

export type NewChannelPayload = {
    isActive: Scalars['Boolean'];
    allowPublicAccess: Scalars['Boolean'];
    allowPrivateAccess: Scalars['Boolean'];
    chatbot?: Maybe<ChatbotPayload>;
    filterByMonthlyInstalment: Scalars['Boolean'];
    allowSharing: Scalars['Boolean'];
    allowOptions: Scalars['Boolean'];
    isCoeEnabled: Scalars['Boolean'];
    isCoeEditable: Scalars['Boolean'];
    isDealerOptionsEnabled: Scalars['Boolean'];
    isPpsrAndEstablishmentEnabled: Scalars['Boolean'];
    isPromoCodeEnabled: Scalars['Boolean'];
    alwaysShowPromoCode: Scalars['Boolean'];
    allowReverseCalculator: Scalars['Boolean'];
    allowFinanceApplication: Scalars['Boolean'];
    isFinanceApplicationMandatory: Scalars['Boolean'];
    allowProceedWithCustomerDevice: Scalars['Boolean'];
    bookingPayment?: Maybe<BookingPaymentPayload>;
    isDepositPaymentMandatory: Scalars['Boolean'];
    priceDisclaimer?: Maybe<Scalars['IntlValue']>;
    isInsuranceEnabled: Scalars['Boolean'];
    allowApplyInsuranceOnFinance: Scalars['Boolean'];
    isLuxuryTaxEnabled: Scalars['Boolean'];
};

export type UsedChannelPayload = {
    isActive: Scalars['Boolean'];
    allowPublicAccess: Scalars['Boolean'];
    allowPrivateAccess: Scalars['Boolean'];
    chatbot?: Maybe<ChatbotPayload>;
    filterByMonthlyInstalment: Scalars['Boolean'];
    allowSharing: Scalars['Boolean'];
    isPromoCodeEnabled: Scalars['Boolean'];
    alwaysShowPromoCode: Scalars['Boolean'];
    allowReverseCalculator: Scalars['Boolean'];
    allowTradeIn: Scalars['Boolean'];
    allowTestDrive: Scalars['Boolean'];
    allowFinanceApplication: Scalars['Boolean'];
    isFinanceApplicationMandatory: Scalars['Boolean'];
    allowProceedWithCustomerDevice: Scalars['Boolean'];
    bookingPayment?: Maybe<BookingPaymentPayload>;
    isDepositPaymentMandatory: Scalars['Boolean'];
};

export type ExpressChannelPayload = {
    isActive: Scalars['Boolean'];
    isCoeEnabled: Scalars['Boolean'];
    isCoeEditable: Scalars['Boolean'];
    isDealerOptionsEnabled: Scalars['Boolean'];
    isPpsrAndEstablishmentEnabled: Scalars['Boolean'];
    isPromoCodeEnabled: Scalars['Boolean'];
    alwaysShowPromoCode: Scalars['Boolean'];
    allowReverseCalculator: Scalars['Boolean'];
    allowFinanceApplication: Scalars['Boolean'];
    isFinanceApplicationMandatory: Scalars['Boolean'];
    allowProceedWithCustomerDevice: Scalars['Boolean'];
    bookingPayment?: Maybe<BookingPaymentPayload>;
    isDepositPaymentMandatory: Scalars['Boolean'];
    priceDisclaimer?: Maybe<Scalars['IntlValue']>;
    isInsuranceEnabled: Scalars['Boolean'];
    allowApplyInsuranceOnFinance: Scalars['Boolean'];
    isLuxuryTaxEnabled: Scalars['Boolean'];
};

export type EventChannelPayload = {
    isActive: Scalars['Boolean'];
    isPromoCodeEnabled: Scalars['Boolean'];
    myInfo?: Maybe<MyInfoIntegrationPayload>;
    alwaysShowPromoCode: Scalars['Boolean'];
    isDepositPaymentMandatory: Scalars['Boolean'];
    bookingPayment?: Maybe<BookingPaymentPayload>;
    chatbot?: Maybe<ChatbotPayload>;
};

export type PaymentProviderSettingsPayload = {
    merchantAccount?: Maybe<Scalars['String']>;
    apiKey?: Maybe<Scalars['String']>;
    clientKey?: Maybe<Scalars['String']>;
    originKey?: Maybe<Scalars['String']>;
    encryptionKey?: Maybe<Scalars['String']>;
    officeId?: Maybe<Scalars['String']>;
    signingPrivateKey?: Maybe<Scalars['String']>;
    encryptionPrivateKey?: Maybe<Scalars['String']>;
    encryptionPublicKey?: Maybe<Scalars['String']>;
    signingPublicKey?: Maybe<Scalars['String']>;
    kid?: Maybe<Scalars['String']>;
};

export type PaymentProviderPayload = {
    type: PaymentProviderType;
    settings: PaymentProviderSettingsPayload;
};

export type ChatbotPayload = {
    type: ChatbotType;
    credentials: Scalars['String'];
};

export type BookingPaymentPayload = {
    provider: PaymentProviderPayload;
    amount: Scalars['Float'];
};

export type MaskPayload = {
    direction: MaskDirection;
    count: Scalars['Int'];
};

export type RoundingDetailPayload = {
    mode?: Maybe<RoundingMode>;
    count: Scalars['Int'];
};

export type RoundingPayload = {
    amount: RoundingDetailPayload;
    percentage: RoundingDetailPayload;
};

export type EmailProviderPayload = {
    provider: Provider;
    host?: Maybe<Scalars['String']>;
    port?: Maybe<Scalars['Int']>;
    user?: Maybe<Scalars['String']>;
    password?: Maybe<Scalars['String']>;
    fromEmail?: Maybe<Scalars['String']>;
    isSslEnabled?: Maybe<Scalars['Boolean']>;
    isAuthenticationRequired?: Maybe<Scalars['Boolean']>;
};

export type CoePayload = {
    amount: Scalars['Float'];
};

export type PpsrPayload = {
    amount: Scalars['Float'];
    editable: Scalars['Boolean'];
};

export type EstablishmentPayload = {
    amount: Scalars['Float'];
    editable: Scalars['Boolean'];
};

export type LuxuryTaxThresholdPayload = {
    petrol?: Maybe<Scalars['Float']>;
    electric?: Maybe<Scalars['Float']>;
    hybrid?: Maybe<Scalars['Float']>;
};

export type LuxuryTaxPayload = {
    amount: Scalars['Float'];
    threshold: LuxuryTaxThresholdPayload;
};

export type GoogleTagManagerPayload = {
    id?: Maybe<Scalars['String']>;
};

export type MaintenancePayload = {
    start: Scalars['DateTime'];
    startTimeZone: Scalars['String'];
    end: Scalars['DateTime'];
    endTimeZone: Scalars['String'];
    warningBefore: Scalars['Int'];
    title: Scalars['IntlValue'];
    description?: Maybe<Scalars['IntlValue']>;
    isActive: Scalars['Boolean'];
};

export type UpdateNewChannelPayload = {
    variants: Array<Scalars['ObjectID']>;
    bookingAmount?: Maybe<Scalars['Float']>;
    priceDisclaimer?: Maybe<Scalars['IntlValue']>;
    insurancePriceDisclaimer?: Maybe<Scalars['IntlValue']>;
    iccFinanceProducts: Array<Scalars['ObjectID']>;
};

export type UpdateUsedChannelPayload = {
    variants: Array<Scalars['ObjectID']>;
    bookingAmount?: Maybe<Scalars['Float']>;
};

export type UpdateExpressChannelPayload = {
    financeProducts: Array<Scalars['ObjectID']>;
    bookingAmount?: Maybe<Scalars['Float']>;
    priceDisclaimer?: Maybe<Scalars['IntlValue']>;
    insurancePriceDisclaimer?: Maybe<Scalars['IntlValue']>;
};

export type UpdateEventChannelPayload = {
    bookingAmount?: Maybe<Scalars['Float']>;
    finderFinanceProducts: Array<Scalars['ObjectID']>;
    configuratorFinanceProducts: Array<Scalars['ObjectID']>;
    csvFinanceProducts: Array<Scalars['ObjectID']>;
};

export type UpdateChannelSettingsPayload = {
    newChannel?: Maybe<UpdateNewChannelPayload>;
    usedChannel?: Maybe<UpdateUsedChannelPayload>;
    expressChannel?: Maybe<UpdateExpressChannelPayload>;
    eventChannel?: Maybe<UpdateEventChannelPayload>;
    coe?: Maybe<Scalars['Float']>;
    ppsr?: Maybe<Scalars['Float']>;
    dealerEstablishment?: Maybe<Scalars['Float']>;
};

export type CountryExternalLinksPayload = {
    legalNotice?: Maybe<Scalars['String']>;
    privacyPolicy?: Maybe<Scalars['String']>;
    copyright?: Maybe<Scalars['String']>;
    facebook?: Maybe<Scalars['String']>;
    youtube?: Maybe<Scalars['String']>;
    instagram?: Maybe<Scalars['String']>;
    website?: Maybe<Scalars['String']>;
};

export type CustomerDetailsStringValuePayload = {
    source: CustomerDetailsSource;
    value: Scalars['String'];
};

export type CustomerDetailsFloatValuePayload = {
    source: CustomerDetailsSource;
    value?: Maybe<Scalars['Float']>;
};

export type CustomerDetailsDateValuePayload = {
    source: CustomerDetailsSource;
    value?: Maybe<Scalars['DateTime']>;
};

export type CustomerDetailsBooleanValuePayload = {
    source: CustomerDetailsSource;
    value?: Maybe<Scalars['Boolean']>;
};

export type AddressPayload = {
    block?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
    building?: Maybe<Scalars['String']>;
    unit?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    countryName?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    fullAddress?: Maybe<Scalars['String']>;
    poBox?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    source: CustomerDetailsSource;
};

export type OfficeAddressPayload = {
    fullAddress?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    poBox?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    source: CustomerDetailsSource;
};

export type HomeCountryAddressPayload = {
    fullAddress?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    source: CustomerDetailsSource;
};

export type CpfbIncomeHistoryPayload = {
    paidAt: Scalars['DateTime'];
    month: Scalars['DateTime'];
    amount: Scalars['Float'];
    employer: Scalars['String'];
};

export type EmploymentPayload = {
    employer: CustomerDetailsStringValuePayload;
    occupation?: Maybe<CustomerDetailsStringValuePayload>;
    assessableIncome?: Maybe<CustomerDetailsFloatValuePayload>;
    assessmentYear?: Maybe<CustomerDetailsFloatValuePayload>;
    cpfContributions?: Maybe<Array<CpfbIncomeHistoryPayload>>;
    incomeType?: Maybe<CustomerDetailsStringValuePayload>;
    monthlyIncome?: Maybe<CustomerDetailsFloatValuePayload>;
    dateOfJoining?: Maybe<CustomerDetailsDateValuePayload>;
    totalWorkExperience?: Maybe<CustomerDetailsFloatValuePayload>;
    designation?: Maybe<CustomerDetailsStringValuePayload>;
    salaryTransferredToBank?: Maybe<CustomerDetailsBooleanValuePayload>;
    bankAccountNumber?: Maybe<CustomerDetailsStringValuePayload>;
};

export type DrivingLicensePayload = {
    type?: Maybe<LicenseType>;
    validity?: Maybe<Scalars['String']>;
    expiryDate?: Maybe<Scalars['DateTime']>;
    classes?: Maybe<Array<LicenseClassPayload>>;
    source: CustomerDetailsSource;
    number?: Maybe<Scalars['String']>;
};

export type LicenseClassPayload = {
    licenseClass?: Maybe<Scalars['String']>;
    issueDate?: Maybe<Scalars['DateTime']>;
};

export type ColorPayload = {
    primary?: Maybe<Scalars['String']>;
    secondary?: Maybe<Scalars['String']>;
};

export type VehicleCoePayload = {
    category?: Maybe<Scalars['String']>;
    expiryDate?: Maybe<Scalars['DateTime']>;
};

export type VehiclePayload = {
    make?: Maybe<Scalars['String']>;
    model?: Maybe<Scalars['String']>;
    registrationNo?: Maybe<Scalars['String']>;
    mileage?: Maybe<Scalars['Float']>;
    year?: Maybe<Scalars['Int']>;
    registrationDate?: Maybe<Scalars['DateTime']>;
    roadTaxExpiryDate?: Maybe<Scalars['DateTime']>;
    engineCapacity?: Maybe<Scalars['Int']>;
    propellant?: Maybe<Scalars['String']>;
    color?: Maybe<ColorPayload>;
    coe?: Maybe<VehicleCoePayload>;
    quotaPremium?: Maybe<Scalars['Float']>;
    openMarketValue?: Maybe<Scalars['Float']>;
    noOfTransfer?: Maybe<Scalars['Int']>;
    status?: Maybe<Scalars['String']>;
    scheme?: Maybe<Scalars['String']>;
    source: CustomerDetailsSource;
};

export type PassportPayload = {
    number?: Maybe<Scalars['String']>;
    expiry?: Maybe<Scalars['DateTime']>;
    source: CustomerDetailsSource;
};

export type CustomerAffinBankPayload = {
    race: CustomerDetailsStringValuePayload;
    idType: CustomerDetailsStringValuePayload;
    idIssueCountry: CustomerDetailsStringValuePayload;
    residentialCountry: CustomerDetailsStringValuePayload;
    residentialStatus: CustomerDetailsStringValuePayload;
    addressType: CustomerDetailsStringValuePayload;
};

export type CustomerPmesaPayload = {
    emirate?: Maybe<CustomerDetailsStringValuePayload>;
};

export type CustomerEnbdBankPayload = {
    visaNumber?: Maybe<CustomerDetailsStringValuePayload>;
    visaIssueDate?: Maybe<CustomerDetailsDateValuePayload>;
    visaExpiryDate?: Maybe<CustomerDetailsDateValuePayload>;
    preferredFirstPaymentDate: CustomerDetailsDateValuePayload;
    bankName?: Maybe<CustomerDetailsStringValuePayload>;
};

export type CustomerThirdPartyPayload = {
    affinBank?: Maybe<CustomerAffinBankPayload>;
    pmeSA?: Maybe<CustomerPmesaPayload>;
    enbdBank?: Maybe<CustomerEnbdBankPayload>;
};

export type CustomerQuotationOptionPayload = {
    description?: Maybe<CustomerDetailsStringValuePayload>;
    price?: Maybe<Scalars['Float']>;
    isVatIncluded?: Maybe<Scalars['Boolean']>;
};

export type CustomerQuotationPayload = {
    fullName?: Maybe<CustomerDetailsStringValuePayload>;
    commissionNo?: Maybe<CustomerDetailsStringValuePayload>;
    engineNo?: Maybe<CustomerDetailsStringValuePayload>;
    chassisNo?: Maybe<CustomerDetailsStringValuePayload>;
    exteriorColor?: Maybe<CustomerDetailsStringValuePayload>;
    options?: Maybe<Array<Maybe<CustomerQuotationOptionPayload>>>;
    downpaymentTo?: Maybe<CustomerDetailsStringValuePayload>;
    companyName?: Maybe<CustomerDetailsStringValuePayload>;
    financeInsuranceManager?: Maybe<CustomerDetailsStringValuePayload>;
};

export type CustomerGuaranteedBuybackPayload = {
    maxMileage?: Maybe<CustomerDetailsFloatValuePayload>;
    excessMileageCharge?: Maybe<CustomerDetailsFloatValuePayload>;
    vehicleModelYear?: Maybe<CustomerDetailsFloatValuePayload>;
};

export type CustomerDetailsPayload = {
    gender?: Maybe<CustomerDetailsStringValuePayload>;
    nationality?: Maybe<CustomerDetailsStringValuePayload>;
    race?: Maybe<CustomerDetailsStringValuePayload>;
    maritalStatus?: Maybe<CustomerDetailsStringValuePayload>;
    residentialStatus?: Maybe<CustomerDetailsStringValuePayload>;
    residentialAddress?: Maybe<AddressPayload>;
    employment?: Maybe<EmploymentPayload>;
    drivingLicense?: Maybe<Array<Maybe<DrivingLicensePayload>>>;
    mainIncome?: Maybe<MainIncomeType>;
    subMainIncome?: Maybe<SubMainIncomeType>;
    monthlyIncome?: Maybe<Scalars['Float']>;
    yearsOfService?: Maybe<Scalars['Int']>;
    education?: Maybe<CustomerDetailsStringValuePayload>;
    passport?: Maybe<PassportPayload>;
    emirate?: Maybe<CustomerDetailsStringValuePayload>;
    emiratesIdExpiry?: Maybe<CustomerDetailsDateValuePayload>;
    uaeResidentSince?: Maybe<CustomerDetailsDateValuePayload>;
    addressType?: Maybe<CustomerDetailsStringValuePayload>;
    office?: Maybe<OfficeAddressPayload>;
    isCountrySameAsResidence?: Maybe<CustomerDetailsBooleanValuePayload>;
    homeCountryAddress?: Maybe<HomeCountryAddressPayload>;
    reference?: Maybe<ReferenceDetailsPayload>;
    thirdParty?: Maybe<CustomerThirdPartyPayload>;
    quotation?: Maybe<CustomerQuotationPayload>;
    guaranteedBuyback?: Maybe<CustomerGuaranteedBuybackPayload>;
};

export type ReferenceDetailsPayload = {
    name?: Maybe<Scalars['String']>;
    relationship?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    source: CustomerDetailsSource;
};

export type UpdateCustomerPayload = {
    title?: Maybe<TitlePayload>;
    name: CustomerDetailsStringValuePayload;
    firstName: CustomerDetailsStringValuePayload;
    lastName: CustomerDetailsStringValuePayload;
    email: CustomerDetailsStringValuePayload;
    emailBis?: Maybe<CustomerDetailsStringValuePayload>;
    phone?: Maybe<CustomerDetailsStringValuePayload>;
    phoneBis?: Maybe<CustomerDetailsStringValuePayload>;
    identityNumber?: Maybe<CustomerDetailsStringValuePayload>;
    dateOfBirth?: Maybe<CustomerDetailsDateValuePayload>;
    details?: Maybe<CustomerDetailsPayload>;
    withMyInfo?: Maybe<Scalars['Boolean']>;
};

export type UpdateRemoteCustomerPayload = {
    name: CustomerDetailsStringValuePayload;
    firstName: CustomerDetailsStringValuePayload;
    lastName: CustomerDetailsStringValuePayload;
    email: CustomerDetailsStringValuePayload;
    emailBis?: Maybe<CustomerDetailsStringValuePayload>;
    phone?: Maybe<CustomerDetailsStringValuePayload>;
    phoneBis?: Maybe<CustomerDetailsStringValuePayload>;
    identityNumber?: Maybe<CustomerDetailsStringValuePayload>;
    dateOfBirth?: Maybe<CustomerDetailsDateValuePayload>;
    details?: Maybe<CustomerDetailsPayload>;
    withMyInfo?: Maybe<Scalars['Boolean']>;
};

export type SearchCustomerPayload = {
    name: Scalars['String'];
    phone: Scalars['String'];
    email: Scalars['String'];
    identityNumber?: Maybe<Scalars['String']>;
};

export type TitlePayload = {
    source: CustomerDetailsSource;
    value: Title;
};

export type CreateCustomerPayload = {
    zoneId: Scalars['ObjectID'];
    type: CustomerType;
    title?: Maybe<TitlePayload>;
    /** Full name of the customer */
    name: CustomerDetailsStringValuePayload;
    /** First name of the customer, will be the same as fullname if there's only 1 field for customer name */
    firstName: CustomerDetailsStringValuePayload;
    /** Last name of the customer, if absent means name stores full name of the customer */
    lastName: CustomerDetailsStringValuePayload;
    email: CustomerDetailsStringValuePayload;
    emailBis?: Maybe<CustomerDetailsStringValuePayload>;
    phone?: Maybe<CustomerDetailsStringValuePayload>;
    phoneBis?: Maybe<CustomerDetailsStringValuePayload>;
    identityNumber?: Maybe<CustomerDetailsStringValuePayload>;
    dateOfBirth?: Maybe<CustomerDetailsDateValuePayload>;
    details?: Maybe<CustomerDetailsPayload>;
    withMyInfo: Scalars['Boolean'];
};

export enum MyInfoExcludableSection {
    DRIVERLICENSE = 'DRIVERLICENSE',
    VEHICLE = 'VEHICLE',
}

export enum ApplicationFlowSource {
    DRAFT = 'Draft',
    REMOTE = 'Remote',
    RESUME = 'Resume',
    EVENT = 'Event',
    GUARANTOR = 'Guarantor',
    INSURANCE = 'Insurance',
    RESUMEINSURANCE = 'ResumeInsurance',
    REMOTEINSURANCE = 'RemoteInsurance',
}

export type AutoplayDealerPayload = {
    dealerId: Scalars['Int'];
    yardId: Scalars['Int'];
};

export type CreateOrUpdateDealerPayload = {
    companyId: Scalars['ObjectID'];
    countryId: Scalars['ObjectID'];
    bankIds: Array<Scalars['ObjectID']>;
    identifier: Scalars['String'];
    name: Scalars['IntlValue'];
    telephone?: Maybe<Scalars['String']>;
    whatsapp?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['IntlValue']>;
    porschePaymentAssortment?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    additionalInformation?: Maybe<Scalars['IntlValue']>;
    partnerNumber?: Maybe<Scalars['String']>;
    bookingPayment?: Maybe<ChannelBookingPaymentPayload>;
    autoplay?: Maybe<AutoplayDealerPayload>;
};

export type UpdateDealerBookingPaymentPayload = {
    bookingPayment?: Maybe<ChannelBookingPaymentPayload>;
};

export type ChannelBookingPaymentPayload = {
    new?: Maybe<Scalars['Float']>;
    used?: Maybe<Scalars['Float']>;
    express?: Maybe<Scalars['Float']>;
};

export type UpdateDealerEstablishmentPayload = {
    establishment?: Maybe<ChannelEstablishmentPayload>;
};

export type ChannelEstablishmentPayload = {
    new?: Maybe<Scalars['Float']>;
    used?: Maybe<Scalars['Float']>;
    express?: Maybe<Scalars['Float']>;
    event?: Maybe<Scalars['Float']>;
};

export type CreateOrUpdateEventPayload = {
    dealerId?: Maybe<Scalars['ObjectID']>;
    name: Scalars['String'];
    period: PeriodPayload;
    dealerIds: Array<Scalars['ObjectID']>;
    campaignId?: Maybe<Scalars['String']>;
    leadOrigin: EventLeadOrigin;
    medium: EventMedium;
    /** this person's email will be used if the application/lead comes from public access */
    defaultSalesPersonId?: Maybe<Scalars['ObjectID']>;
    isActive: Scalars['Boolean'];
    setting: EventSettingPayload;
    edm?: Maybe<EventEdmPayload>;
};

export type EventSettingPayload = {
    allowPublicAccess: Scalars['Boolean'];
    allowPrivateAccess: Scalars['Boolean'];
    isCoeEnabled: Scalars['Boolean'];
    isCoeEditable: Scalars['Boolean'];
    isPpsrAndEstablishmentEnabled: Scalars['Boolean'];
    isPromoCodeEnabled: Scalars['Boolean'];
    allowTradeIn: Scalars['Boolean'];
    allowTradeInAmountInput: Scalars['Boolean'];
    allowTestDrive: Scalars['Boolean'];
    externalSite: EventExternalSite;
    isDepositPaymentMandatory: Scalars['Boolean'];
    isChatbotEnabled: Scalars['Boolean'];
    reservationInstruction?: Maybe<Scalars['String']>;
    reservationPeriod?: Maybe<Scalars['Int']>;
    calculatorVisibility: CalculatorVisibility;
    applyForFinancing: Scalars['Boolean'];
    isInsuranceEnabled: Scalars['Boolean'];
    bookingPayment?: Maybe<EventBookingPaymentPayload>;
    coeVehicleCondition?: Maybe<CoeVehicleCondition>;
    isAppointmentEnabled: Scalars['Boolean'];
    priceDisclaimers: Array<Scalars['String']>;
    financePriceDisclaimers: Array<Scalars['String']>;
    insurancePriceDisclaimers: Array<Scalars['String']>;
};

export type EventBookingPaymentPayload = {
    amount?: Maybe<Scalars['Float']>;
};

export type EventEdmPayload = {
    subject: Scalars['IntlValue'];
    introTitle: Scalars['IntlValue'];
    contentText: Scalars['IntlValue'];
    isSummaryVehicleEnabled: Scalars['Boolean'];
};

export type CustomerOrderTEquipment = {
    luggageNet?: Maybe<Scalars['Boolean']>;
    luggageCompartment?: Maybe<Scalars['Boolean']>;
    floorMats?: Maybe<Scalars['Boolean']>;
    carCareCase?: Maybe<Scalars['Boolean']>;
    bicycleRack?: Maybe<Scalars['Boolean']>;
};

export type CustomerOrderTimePiece = {
    hands: Scalars['String'];
    colouredRing: Scalars['String'];
    engraving?: Maybe<Scalars['String']>;
};

export type SaveCustomerOrderPayload = {
    exteriorColor: Scalars['String'];
    interiorColor: Scalars['String'];
    enhancedPackage: Scalars['Boolean'];
    delivery: Scalars['String'];
    tequipment?: Maybe<CustomerOrderTEquipment>;
    timepiece?: Maybe<CustomerOrderTimePiece>;
};

export type CreateFinanceProductPayload = {
    countryId: Scalars['ObjectID'];
    dealerIds: Array<Scalars['ObjectID']>;
    basedOn: FinanceProductBase;
    bankId: Scalars['ObjectID'];
    type: FinanceProductType;
    subType?: Maybe<FinanceProductSubType>;
    identifier: Scalars['String'];
    bankReferenceIdentifier: Scalars['String'];
    name: Scalars['IntlValue'];
    description?: Maybe<Scalars['IntlValue']>;
    order: Scalars['Int'];
    isActive: Scalars['Boolean'];
    period: PeriodPayload;
    paymentMode: PaymentModePayload;
    termSetting: TermSettingPayload;
    loanSetting: LoanSettingPayload;
    downPaymentSetting: DownPaymentSettingPayload;
    interestRateSetting: InterestRateSettingPayload;
    averageMileageSetting?: Maybe<AverageMileageSettingPayload>;
    residualValueSetting?: Maybe<ResidualValueSettingPayload>;
    balloonSetting?: Maybe<BalloonSettingPayload>;
    balloonGFVSetting?: Maybe<BalloonGfvSettingPayload>;
    depositSetting?: Maybe<DepositSettingPayload>;
    structuredPayment?: Maybe<StructuredPaymentSettingPayload>;
    leaseSetting?: Maybe<LeaseSettingPayload>;
    tradeInSetting?: Maybe<TradeInSettingPayload>;
    licensePlateFeeSetting?: Maybe<LicensePlateFeeSettingPayload>;
    commissionSetting?: Maybe<CommissionSettingPayload>;
    fixedInterestRateSetting?: Maybe<FixedInterestRateSettingPayload>;
    licenseAndFuelTaxSetting?: Maybe<LicenseAndFuelTaxSettingPayload>;
    displacementSetting?: Maybe<DisplacementSettingPayload>;
    insuranceFeeSetting?: Maybe<InsuranceFeeSettingPayload>;
    taxLossSetting?: Maybe<TaxLossSettingPayload>;
    totalInterestPayableSetting?: Maybe<TotalInterestPayableSettingPayload>;
    /** List of variant version ID */
    variants: Array<Scalars['ObjectID']>;
    /** List of finder vehicle ID */
    finderVehicles: Array<Scalars['ObjectID']>;
};

export type UpdateFinanceProductPayload = {
    dealerIds: Array<Scalars['ObjectID']>;
    basedOn: FinanceProductBase;
    bankId: Scalars['ObjectID'];
    type: FinanceProductType;
    subType?: Maybe<FinanceProductSubType>;
    identifier: Scalars['String'];
    bankReferenceIdentifier: Scalars['String'];
    name: Scalars['IntlValue'];
    description?: Maybe<Scalars['IntlValue']>;
    order: Scalars['Int'];
    isActive: Scalars['Boolean'];
    variants: Array<Scalars['ObjectID']>;
    finderVehicles: Array<Scalars['ObjectID']>;
    period: PeriodPayload;
    paymentMode: PaymentModePayload;
    termSetting: TermSettingPayload;
    loanSetting: LoanSettingPayload;
    downPaymentSetting: DownPaymentSettingPayload;
    interestRateSetting: InterestRateSettingPayload;
    averageMileageSetting?: Maybe<AverageMileageSettingPayload>;
    residualValueSetting?: Maybe<ResidualValueSettingPayload>;
    balloonSetting?: Maybe<BalloonSettingPayload>;
    balloonGFVSetting?: Maybe<BalloonGfvSettingPayload>;
    depositSetting?: Maybe<DepositSettingPayload>;
    structuredPayment?: Maybe<StructuredPaymentSettingPayload>;
    leaseSetting?: Maybe<LeaseSettingPayload>;
    tradeInSetting?: Maybe<TradeInSettingPayload>;
    licensePlateFeeSetting?: Maybe<LicensePlateFeeSettingPayload>;
    commissionSetting?: Maybe<CommissionSettingPayload>;
    fixedInterestRateSetting?: Maybe<FixedInterestRateSettingPayload>;
    licenseAndFuelTaxSetting?: Maybe<LicenseAndFuelTaxSettingPayload>;
    displacementSetting?: Maybe<DisplacementSettingPayload>;
    insuranceFeeSetting?: Maybe<InsuranceFeeSettingPayload>;
    taxLossSetting?: Maybe<TaxLossSettingPayload>;
    totalInterestPayableSetting?: Maybe<TotalInterestPayableSettingPayload>;
};

export type PeriodPayload = {
    start?: Maybe<Scalars['DateTime']>;
    startTimeZone?: Maybe<Scalars['String']>;
    end?: Maybe<Scalars['DateTime']>;
    endTimeZone?: Maybe<Scalars['String']>;
};

export type PaymentModePayload = {
    mode?: Maybe<PaymentMode>;
    calculationMode?: Maybe<CalculationMode>;
    derivedMethod: DerivedMethod;
    show?: Maybe<Scalars['Boolean']>;
    editable?: Maybe<Scalars['Boolean']>;
    reducedPercentage?: Maybe<Scalars['Int']>;
};

export type TermSettingPayload = {
    min: Scalars['Int'];
    max: Scalars['Int'];
    step: Scalars['Int'];
    default: Scalars['Int'];
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
    interestOnly?: Maybe<Scalars['Int']>;
};

export type AverageMileageSettingPayload = {
    min: Scalars['Int'];
    max: Scalars['Int'];
    step: Scalars['Int'];
    default: Scalars['Int'];
    defaultUnit: Unit;
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
};

export type ResidualValueSettingPayload = {
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
    defaultUnit: Unit;
    table?: Maybe<Array<ResidualTableValuePayload>>;
};

export type LoanSettingPayload = {
    min: Scalars['Float'];
    max: Scalars['Float'];
    step: Scalars['Float'];
    default: Scalars['Float'];
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
    defaultUnit: Unit;
};

export type DownPaymentTableValuesPayload = {
    term: Scalars['Int'];
    value: Scalars['Float'];
};

export type DownPaymentSettingPayload = {
    min: Scalars['Float'];
    max: Scalars['Float'];
    step: Scalars['Float'];
    default: Scalars['Float'];
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
    defaultUnit: Unit;
    mode: DownPaymentMode;
    table?: Maybe<Array<DownPaymentTableValuesPayload>>;
    icc?: Maybe<DownPaymentIccSettingPayload>;
};

export type DownPaymentIccSettingPayload = {
    min: Scalars['Float'];
    max: Scalars['Float'];
    step: Scalars['Float'];
    default: Scalars['Float'];
    defaultUnit: Unit;
};

export type InterestRateTableValuesPayload = {
    term: Scalars['Int'];
    /**
     * Based on amount reference value is based on Finance Product
     * Can be percentage or amount
     */
    basedOnAmount: Scalars['Float'];
    value: Scalars['Float'];
};

export type InterestRateSettingPayload = {
    min: Scalars['Float'];
    max: Scalars['Float'];
    step: Scalars['Float'];
    default: Scalars['Float'];
    editable: Scalars['Boolean'];
    displayMode: InterestRateDisplayMode;
    mode: InterestRateMode;
    table?: Maybe<Array<InterestRateTableValuesPayload>>;
    tableGroupingStep?: Maybe<Scalars['Float']>;
    tableGroupingUnit?: Maybe<Unit>;
};

export type BalloonTableValuesPayload = {
    term: Scalars['Int'];
    variantId: Scalars['ObjectID'];
    /** Determines what vehicle type is the variant id referencing */
    vehicleType: TableValueVehicleType;
    value: Scalars['Float'];
};

export type ResidualTableValuePayload = {
    term: Scalars['Int'];
    mileage?: Maybe<Scalars['Int']>;
    variantId: Scalars['ObjectID'];
    /** Determines what vehicle type is the variant id referencing */
    vehicleType: TableValueVehicleType;
    value: Scalars['Float'];
};

export type BalloonSettingPayload = {
    min: Scalars['Int'];
    max: Scalars['Int'];
    step: Scalars['Int'];
    default: Scalars['Int'];
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
    defaultUnit: Unit;
    mode: BalloonMode;
    basedOn: BalloonBase;
    table?: Maybe<Array<BalloonTableValuesPayload>>;
};

export type MarginOfFinanceSettingPayload = {
    min?: Maybe<Scalars['Int']>;
    max?: Maybe<Scalars['Int']>;
    show: Scalars['Boolean'];
};

export type BalloonGfvSettingPayload = {
    settlementInstalmentOn: Scalars['Int'];
    marginOfFinance?: Maybe<MarginOfFinanceSettingPayload>;
};

export type DepositTableValuesPayload = {
    term: Scalars['Int'];
    value: Scalars['Float'];
};

export type DepositSettingPayload = {
    min: Scalars['Float'];
    max: Scalars['Float'];
    step: Scalars['Float'];
    default: Scalars['Float'];
    defaultUnit: Unit;
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
    mode: DepositMode;
    table?: Maybe<Array<DepositTableValuesPayload>>;
};

export type StructuredPaymentSettingPayload = {
    min: Scalars['Float'];
    max: Scalars['Float'];
    step: Scalars['Float'];
    default: Scalars['Float'];
    defaultUnit: Unit;
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
};

export type LeaseTableValuesPayload = {
    term: Scalars['Int'];
    variantId: Scalars['ObjectID'];
    /** Determines what vehicle type is the variant id referencing */
    vehicleType: TableValueVehicleType;
    value: Scalars['Float'];
};

export type LeaseSettingPayload = {
    table: Array<LeaseTableValuesPayload>;
};

export type TradeInSettingPayload = {
    show: Scalars['Boolean'];
    withLiability: Scalars['Boolean'];
};

export type LicensePlateFeeSettingPayload = {
    min: Scalars['Int'];
    max: Scalars['Int'];
    step: Scalars['Int'];
    default: Scalars['Int'];
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
};

export type CommissionSettingPayload = {
    default: Scalars['Float'];
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
};

export type FixedInterestRateSettingPayload = {
    show: Scalars['Boolean'];
};

export type LicenseAndFuelTaxTableValuesPayload = {
    min: Scalars['Int'];
    max: Scalars['Int'];
    value: Scalars['Int'];
};

export type LicenseAndFuelTaxSettingPayload = {
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
    fuelTable: Array<LicenseAndFuelTaxTableValuesPayload>;
    licenseTable: Array<LicenseAndFuelTaxTableValuesPayload>;
};

export type DisplacementSettingPayload = {
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
};

export type InsuranceFeeTableValuesPayload = {
    value: Scalars['Float'];
    variantId: Scalars['ObjectID'];
    vehicleType: TableValueVehicleType;
};

export type InsuranceFeeSettingPayload = {
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
    table: Array<InsuranceFeeTableValuesPayload>;
};

export type TaxLossSettingPayload = {
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
};

export type TotalInterestPayableSettingPayload = {
    displayMode: TotalInterestPayableDisplayMode;
};

export type FinderVehicleSettingPayload = {
    isHidden: Scalars['Boolean'];
    availability: FinderVehicleAvailability;
};

export type FinderVehicleDetailsPayload = {
    ownerIdType: PreOwnerIdType;
    ownerId: Scalars['String'];
    make: Scalars['String'];
    model: Scalars['String'];
    colour: Scalars['String'];
    yearOfManufacture?: Maybe<Scalars['String']>;
    registrationNumber: Scalars['String'];
    engineNumber: Scalars['String'];
    chassisNumber: Scalars['String'];
    omv: Scalars['Float'];
    originalRegistrationDate: Scalars['DateTime'];
    firstRegistrationDate: Scalars['DateTime'];
    transfers: Scalars['Int'];
    coe?: Maybe<Scalars['Float']>;
    pqp?: Maybe<Scalars['Float']>;
    arf: Scalars['Float'];
    parfEligibilityExpiryDate?: Maybe<Scalars['DateTime']>;
    parfRebateAmount: Scalars['Float'];
    coeCategory: CoeCategory;
    coePeriod: Scalars['Float'];
    coeExpiryDate: Scalars['DateTime'];
    power?: Maybe<Scalars['Float']>;
};

export type UpdateFinderVehiclePayload = {
    inspected: Scalars['Boolean'];
    setting: FinderVehicleSettingPayload;
    details?: Maybe<FinderVehicleDetailsPayload>;
};

export type InsuranceIntegrationPayload = {
    submission: Scalars['String'];
    resubmission: Scalars['String'];
    cancellation: Scalars['String'];
    quickQuote?: Maybe<Scalars['String']>;
};

export type InsuranceSigningPayload = {
    onCreate: InsuranceSigningMode;
    onUpdate: InsuranceSigningMode;
};

export type CreateInsurancePayload = {
    countryId: Scalars['ObjectID'];
    zoneIds: Array<Scalars['ObjectID']>;
    name: Scalars['IntlValue'];
    email: Scalars['String'];
    order: Scalars['Int'];
    signing: InsuranceSigningPayload;
    integration?: Maybe<InsuranceIntegrationPayload>;
    isActive: Scalars['Boolean'];
    option: InsuranceOption;
    sendEmailToCustomer: Scalars['Boolean'];
};

export type UpdateInsurancePayload = {
    zoneIds: Array<Scalars['ObjectID']>;
    name: Scalars['IntlValue'];
    email: Scalars['String'];
    order: Scalars['Int'];
    remoteFlowAcknowledgmentInfo?: Maybe<Scalars['IntlValue']>;
    signing: InsuranceSigningPayload;
    integration?: Maybe<InsuranceIntegrationPayload>;
    isActive: Scalars['Boolean'];
    option: InsuranceOption;
    sendEmailToCustomer: Scalars['Boolean'];
};

export type InsurancePremiumAmountDataPayload = {
    modelName: Scalars['String'];
    originalRegistrationDate: Scalars['DateTime'];
    dateOfBirth: Scalars['DateTime'];
    drivingExperience: Scalars['Int'];
    ncd: Scalars['Int'];
    vehiclePrice: Scalars['Float'];
};

export type CreateInsuranceApplicationPayload = {
    draft?: Maybe<Scalars['Boolean']>;
    assigneeId?: Maybe<Scalars['ObjectID']>;
    zoneId: Scalars['ObjectID'];
    dealerId: Scalars['ObjectID'];
    variantId: Scalars['ObjectID'];
    customerId: Scalars['ObjectID'];
    /** Application Version Id */
    applicationVersionId?: Maybe<Scalars['ObjectID']>;
    comment?: Maybe<Scalars['String']>;
    calculator: InsuranceApplicationCalculatorPayload;
    proceedWithCustomerDevice: Scalars['Boolean'];
    channel: Channel;
    locale: Scalars['String'];
    uploadId?: Maybe<Scalars['ObjectID']>;
    /** event bind to insurance */
    eventId?: Maybe<Scalars['ObjectID']>;
    /** Porsche finder */
    finderVehicleId?: Maybe<Scalars['ObjectID']>;
};

export type InsuranceApplicationCalculatorPayload = {
    /** Customer Driver License Pass Date */
    driverLicensePassDate?: Maybe<Scalars['DateTime']>;
    /** No Claim Discount */
    ncd?: Maybe<Scalars['Int']>;
    /** Existing Car Place to apply NCD */
    existingCarPlate?: Maybe<Scalars['String']>;
    /** Vehicle Displacement */
    displacement: Scalars['Float'];
    /** Vehicle Price */
    price: Scalars['Float'];
    /** COE */
    coe?: Maybe<ApplicationCalculatorCoePayload>;
    /** Date of Registration */
    dateOfRegistration?: Maybe<Scalars['DateTime']>;
    /** Years of Driving */
    yearsOfDriving?: Maybe<Scalars['Int']>;
    /** Insurance Premium */
    insurancePremium?: Maybe<Scalars['Float']>;
    /**
     * Disclaimer insurance premium
     * It used only for disclaimer purpose
     */
    disclaimerInsurancePremium?: Maybe<Scalars['Float']>;
};

export type UpdateInsuranceApplicationPayload = {
    proceedWithCustomerDevice: Scalars['Boolean'];
};

export type InventorySetPayload = {
    /**
     * Stock initially available
     *
     * Rising it will creates new units while lowering it will remove units.
     */
    initialStock: Scalars['Int'];
    /** List of version ID from options */
    optionIds: Array<Scalars['ObjectID']>;
};

export type UpdateInventorySetPayload = {
    /**
     * Stock initially available
     *
     * Rising it will creates new units while lowering it will remove units.
     */
    initialStock: Scalars['Int'];
    /** Active State for the inventory set */
    isActive: Scalars['Boolean'];
};

export type InventoryPayload = {
    /** Variant ID */
    variantId: Scalars['ObjectID'];
    /**
     * List of sets to push onto this inventory
     *
     * If other sets exists, those will be marked as deleted
     */
    sets: Array<InventorySetPayload>;
};

export enum InventoryUnitState {
    /** The unit is managed by the system */
    SYSTEMMANAGED = 'SystemManaged',
    /** The unit is manually deducted */
    MANUALLYDEDUCTED = 'ManuallyDeducted',
}

export type InventoryUnitPayload = {
    /** Unit id */
    id: Scalars['ObjectID'];
    /** Vehicle Identity Number (VIN) */
    vin: Scalars['String'];
    /** Location */
    location: Scalars['String'];
    /** State of the unit */
    state: InventoryUnitState;
};

export type UpdateInventoryUnitDetailsPayload = {
    /** Vehicle Identity Number (VIN) */
    vin: Scalars['String'];
    /** Location */
    location: Scalars['String'];
    /** Description of the unit */
    description?: Maybe<Scalars['IntlValue']>;
    /** Features of the unit */
    features?: Maybe<Scalars['IntlValue']>;
    /** UsedCarDetails of the unit */
    usedCarDetails?: Maybe<UsedCarDetailsPayload>;
};

export type InventoryUnitPricePayload = {
    /** Price for inventory unit */
    value: Scalars['Float'];
    /** Zone for which the price is specified in */
    zoneId: Scalars['ObjectID'];
};

export type UpdateInventoryUnitPayload = {
    /** Application identifier manually filled in by user (optional) */
    applicationIdentifier?: Maybe<Scalars['String']>;
    /** State of the unit */
    state: InventoryUnitState;
    /** Images for the unit */
    imageIds: Array<Scalars['ObjectID']>;
    /** Details of the unit */
    details: UpdateInventoryUnitDetailsPayload;
    /** Prices for inventory unit categorized by zones */
    prices: Array<InventoryUnitPricePayload>;
};

export type CreateOrUpdateMakePayload = {
    companyId: Scalars['ObjectID'];
    identifier: Scalars['String'];
    name: Scalars['IntlValue'];
    order: Scalars['Int'];
    isActive: Scalars['Boolean'];
};

export type MiniConfiguratorDetailsPayload = {
    upgrades: MiniConfiguratorUpgradesPayload;
    elevationGroups: Array<MiniConfiguratorElevationGroupPayload>;
};

export type MiniConfiguratorUpgradesPayload = {
    packages: Array<MiniConfiguratorUpgradePackagePayload>;
    items: Array<MiniConfiguratorUpgradeItemPayload>;
};

export type MiniConfiguratorUpgradePackagePayload = {
    name: Scalars['String'];
    /** Formatted price, e.g: RM 1,000.00 */
    price?: Maybe<Scalars['String']>;
    items: Array<MiniConfiguratorUpgradeItemPayload>;
};

export type MiniConfiguratorUpgradeItemPayload = {
    name: Scalars['String'];
    /** Formatted price, e.g: RM 1,000.00 */
    price?: Maybe<Scalars['String']>;
};

export type MiniConfiguratorElevationGroupPayload = {
    name: Scalars['String'];
    elevations: Array<MiniConfiguratorElevationPayload>;
};

export type MiniConfiguratorElevationPayload = {
    name: Scalars['String'];
    /** Formatted price, e.g: RM 1,000.00 */
    price?: Maybe<Scalars['String']>;
};

export type CreateOrUpdateModelPayload = {
    countryId: Scalars['ObjectID'];
    makeId: Scalars['ObjectID'];
    type: ModelType;
    identifier: Scalars['String'];
    name: Scalars['IntlValue'];
    order: Scalars['Int'];
    isActive: Scalars['Boolean'];
};

export type CreateOrUpdateSubModelPayload = {
    countryId: Scalars['ObjectID'];
    parentId: Scalars['ObjectID'];
    type: ModelType;
    identifier: Scalars['String'];
    name: Scalars['IntlValue'];
    order: Scalars['Int'];
    isActive: Scalars['Boolean'];
};

export type CreateOrUpdateOptionGroupPayload = {
    countryId: Scalars['ObjectID'];
    name: Scalars['IntlValue'];
    order: Scalars['Int'];
    isActive: Scalars['Boolean'];
};

export type CreateOrUpdateOptionPayload = {
    countryId: Scalars['ObjectID'];
    groupId: Scalars['ObjectID'];
    variants: Array<Scalars['ObjectID']>;
    colour: Scalars['String'];
    identifier: Scalars['String'];
    name: Scalars['IntlValue'];
    order: Scalars['Int'];
    price: Scalars['Float'];
    isActive: Scalars['Boolean'];
};

export type CreatePromoCodePayload = {
    countryId: Scalars['ObjectID'];
    dealerIds: Array<Scalars['ObjectID']>;
    identifier: Scalars['String'];
    description: Scalars['String'];
    unit: PromoCodeUnit;
    value?: Maybe<Scalars['Float']>;
    initialQuantity: Scalars['Int'];
    remainingQuantity: Scalars['Int'];
    deductValue: Scalars['Boolean'];
    period: PromoCodePeriodPayload;
    isActive: Scalars['Boolean'];
};

export type PromoCodePeriodPayload = {
    start: Scalars['DateTime'];
    startTimeZone?: Maybe<Scalars['String']>;
    end: Scalars['DateTime'];
    endTimeZone?: Maybe<Scalars['String']>;
};

export type UpdatePromoCodePayload = {
    identifier: Scalars['String'];
    dealerIds: Array<Scalars['ObjectID']>;
    description: Scalars['String'];
    unit: PromoCodeUnit;
    value?: Maybe<Scalars['Float']>;
    initialQuantity: Scalars['Int'];
    remainingQuantity: Scalars['Int'];
    deductValue: Scalars['Boolean'];
    period: PromoCodePeriodPayload;
    isActive: Scalars['Boolean'];
};

export type CreatePromoReservationPayload = {
    applicationId: Scalars['ObjectID'];
    promoCodeId: Scalars['ObjectID'];
};

export type RolePermissionPayload = {
    code: PermissionCode;
    level: PermissionLevel;
};

export type CreateOrUpdateRolePayload = {
    parentId?: Maybe<Scalars['ObjectID']>;
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    countryId: Scalars['ObjectID'];
    permissions: Array<RolePermissionPayload>;
};

export type StatisticFilterPayload = {
    zoneId?: Maybe<Scalars['ObjectID']>;
    countryId?: Maybe<Scalars['ObjectID']>;
    dealerIds: Array<Maybe<Scalars['ObjectID']>>;
    channels: Array<Channel>;
};

export type CreateTermsAndConditionsPayload = {
    countryId: Scalars['ObjectID'];
    title: Scalars['String'];
    description?: Maybe<Scalars['String']>;
};

export type CreateOrUpdateUserPayload = {
    username: Scalars['String'];
    name: Scalars['String'];
    email: Scalars['String'];
    isActive: Scalars['Boolean'];
    phonePrefix: Scalars['String'];
    phone?: Maybe<Scalars['String']>;
    permissions: Array<UserPermissionPayload>;
    alias?: Maybe<Scalars['String']>;
};

export type UserPermissionPayload = {
    companyId: Scalars['ObjectID'];
    countryId: Scalars['ObjectID'];
    roleId: Scalars['ObjectID'];
    isParallelView: Scalars['Boolean'];
    superiorId?: Maybe<Scalars['ObjectID']>;
    viewData: ViewDataType;
    dealerIds: Array<Scalars['ObjectID']>;
};

export type UserProfilePayload = {
    name: Scalars['String'];
    email: Scalars['String'];
    phonePrefix: Scalars['String'];
    phone: Scalars['String'];
};

export type SubscribeOrUnsubscribeFcmPayload = {
    fcmToken: Scalars['String'];
};

export type CreateOrUpdateVariantPayload = {
    countryId: Scalars['ObjectID'];
    modelId: Scalars['ObjectID'];
    imageIds: Array<Scalars['ObjectID']>;
    assetCondition: AssetCondition;
    identifier: Scalars['String'];
    name: Scalars['IntlValue'];
    order: Scalars['Int'];
    seatingCapacity: Scalars['Int'];
    year: Scalars['Int'];
    engineType?: Maybe<EngineType>;
    prices: Array<VariantPricePayload>;
    description?: Maybe<Scalars['IntlValue']>;
    features?: Maybe<Scalars['IntlValue']>;
    technicalDetails: TechnicalDetailsPayload;
    usedCarDetails: UsedCarDetailsPayload;
    isActive: Scalars['Boolean'];
};

export type CreateOrUpdateUsedVariantPayload = {
    countryId: Scalars['ObjectID'];
    name: Scalars['IntlValue'];
    makeName: Scalars['IntlValue'];
    modelName: Scalars['IntlValue'];
    preOwnedCarDetails?: Maybe<PreOwnedCarDetailsPayload>;
};

export type VariantPricePayload = {
    value: Scalars['Float'];
    zoneId: Scalars['ObjectID'];
};

export type TechnicalDetailsPayload = {
    co2Emissions?: Maybe<Scalars['Float']>;
    cylinderArrangementNo?: Maybe<Scalars['Float']>;
    displacement?: Maybe<Scalars['Float']>;
    driveTrain?: Maybe<Scalars['String']>;
    fuelConsumption?: Maybe<Scalars['Float']>;
    numberOfAirbags?: Maybe<Scalars['Int']>;
    ratedOutput?: Maybe<Scalars['Float']>;
    ratedTorque?: Maybe<Scalars['Float']>;
    transmission?: Maybe<Scalars['String']>;
};

export type UsedCarDetailsPayload = {
    depreciation?: Maybe<Scalars['Float']>;
    mileage?: Maybe<Scalars['Float']>;
    registrationDate?: Maybe<Scalars['DateTime']>;
    omv?: Maybe<Scalars['Float']>;
    arf?: Maybe<Scalars['Float']>;
    coe?: Maybe<Scalars['Float']>;
    deregistrationValue?: Maybe<Scalars['Float']>;
    roadTax?: Maybe<Scalars['Float']>;
    noOfOwners?: Maybe<Scalars['Int']>;
};

export type PreOwnedCarDetailsPayload = {
    plateNumber: Scalars['String'];
    originalRegistrationDate: Scalars['DateTime'];
    coeExpiryDate?: Maybe<Scalars['DateTime']>;
    /** Quota Premium Paid */
    qpPaid?: Maybe<Scalars['Float']>;
    /** Open Market Value, use abbreviation to align with field on variant */
    omv?: Maybe<Scalars['Float']>;
    /** suffix of specified owner id, currently last 4 characters */
    ownerIdSuffix?: Maybe<Scalars['String']>;
    ownerIdType?: Maybe<PreOwnerIdType>;
    inspected?: Maybe<Scalars['Boolean']>;
    /**
     * Insurance related fields
     * These were exist in graphql type, but not stated in input, wonder why
     */
    startingPrice?: Maybe<Scalars['Float']>;
    assetCondition?: Maybe<Scalars['String']>;
    displacement?: Maybe<Scalars['Float']>;
};

export type CreateZonePayload = {
    companyId: Scalars['ObjectID'];
    countryId: Scalars['ObjectID'];
    name: Scalars['IntlValue'];
    code: Scalars['String'];
    taxRate: Scalars['Float'];
    zipCode?: Maybe<Scalars['String']>;
    timezone: Scalars['String'];
};

export type UpdateZonePayload = {
    name: Scalars['IntlValue'];
    code: Scalars['String'];
    taxRate: Scalars['Float'];
    zipCode?: Maybe<Scalars['String']>;
    timezone: Scalars['String'];
};

export type Mutation = {
    /**
     * Authenticate the user using a couple username/password.
     * When the zoneId is missing and credentials are valid, it will return companies/countries/zones available for authentication.
     * User already logged in will be rejected on newer authentication (omit the authorization header to make it wowrks).
     */
    authenticate: AuthenticateResponse;
    /** Provide a new token based on the authorization header found in HTTP headers. */
    refreshCredentials?: Maybe<Scalars['String']>;
    /** Provide a new token based on the tenant provided */
    switchTenant?: Maybe<Scalars['String']>;
    /** Create a new role */
    createRole?: Maybe<Role>;
    /** Update an existing role */
    updateRole?: Maybe<Role>;
    /** Update hierarchy for an existing role */
    updateRoleHierarchy?: Maybe<Role>;
    /** Delete an existing role */
    deleteRole?: Maybe<Role>;
    /** Create a new user */
    createUser?: Maybe<User>;
    /** Update an existing user */
    updateUser?: Maybe<User>;
    /** Delete an existing user */
    deleteUser: DeleteUserResponse;
    /** Update current user password */
    updateUserPassword?: Maybe<User>;
    /** Resend activation link to user */
    sendActivationLink?: Maybe<User>;
    /** Update current user profile */
    updateUserProfile?: Maybe<User>;
    /** Subscribe to Firebase Cloud Messaging */
    subscribeToFCM?: Maybe<Scalars['Boolean']>;
    /** Unsubscribe from Firebase Cloud Messaging */
    unsubscribeFromFCM?: Maybe<Scalars['Boolean']>;
    /** Create a finance product */
    createFinanceProduct?: Maybe<FinanceProduct>;
    /** Update a finance product */
    updateFinanceProduct?: Maybe<FinanceProduct>;
    /** Delete a finance product */
    deleteFinanceProduct?: Maybe<FinanceProduct>;
    /** Create a make */
    createMake?: Maybe<Make>;
    /** Delete an existing make */
    deleteMake?: Maybe<Make>;
    /** Import an excel file for make */
    importMake: ImportExcelResponse;
    /** Update an existing make */
    updateMake?: Maybe<Make>;
    /** Create a model */
    createModel?: Maybe<Model>;
    /** Create a sub model */
    createSubModel?: Maybe<Model>;
    /** Delete an existing model */
    deleteModel?: Maybe<Model>;
    /** Delete an existing sub model */
    deleteSubModel?: Maybe<Model>;
    /** Import an excel file for model */
    importModel: ImportExcelResponse;
    /** Update an existing model */
    updateModel?: Maybe<Model>;
    /** Create a sub model */
    updateSubModel?: Maybe<Model>;
    /** Import an excel file for subModel */
    importSubModel: ImportExcelResponse;
    /** Create an option group */
    createOptionGroup?: Maybe<OptionGroup>;
    /** Delete an existing option group */
    deleteOptionGroup?: Maybe<OptionGroup>;
    /** Update an existing option group */
    updateOptionGroup?: Maybe<OptionGroup>;
    /** Create an option */
    createOption?: Maybe<Option>;
    /** Delete an existing option */
    deleteOption?: Maybe<Option>;
    /** Import an excel file for option */
    importOption: ImportExcelResponse;
    /** Update an existing option */
    updateOption?: Maybe<Option>;
    /** Upload option image */
    uploadOptionImage: Attachment;
    /** Upload option image/s with image file format or archive */
    uploadOptionImages?: Maybe<Scalars['Int']>;
    /** Delete an image for an option */
    deleteOptionImage: Scalars['Boolean'];
    /** Create a variant */
    createVariant?: Maybe<Variant>;
    /** Create a used variant */
    createExpressVariant: Variant;
    /** Delete an existing variant */
    deleteVariant?: Maybe<Variant>;
    /** Import an excel file for variannt */
    importVariant: ImportExcelResponse;
    /** Update an existing variant */
    updateVariant?: Maybe<Variant>;
    /** Create a bank */
    createBank?: Maybe<Bank>;
    /** Update a bank */
    updateBank?: Maybe<Bank>;
    /** Delete a bank */
    deleteBank?: Maybe<Bank>;
    /** Create insurance */
    createInsurance?: Maybe<Insurance>;
    /** Create insurance application */
    createInsuranceApplication: ProceedInsuranceApplicationContext;
    /** Complete the consents and declarations for Insurance Application */
    completeInsuranceConsentsAndDeclarations: ProceedInsuranceApplicationContext;
    /** Update insurance */
    updateInsurance?: Maybe<Insurance>;
    /** Delete insurance */
    deleteInsurance?: Maybe<Insurance>;
    /** Create a dealer */
    createDealer?: Maybe<Dealer>;
    /** Update a dealer */
    updateDealer?: Maybe<Dealer>;
    /** Update dealer booking payment in different channels */
    updateDealerBookingPayment?: Maybe<Dealer>;
    /** Update dealer establishment in different channels */
    updateDealerEstablishment?: Maybe<Dealer>;
    /** Delete a dealer */
    deleteDealer?: Maybe<Dealer>;
    /** Create a consent or declaration */
    createConsentOrDeclaration?: Maybe<ConsentOrDeclaration>;
    /** Update a consent or declaration */
    updateConsentOrDeclaration?: Maybe<ConsentOrDeclaration>;
    /** Delete a consent or declaration */
    deleteConsentOrDeclaration?: Maybe<ConsentOrDeclaration>;
    /**
     * Upload a file to an upload instance
     *
     * If no upload id is provided, an upload instance will be created
     */
    uploadUnattachedFile: UploadAttachment;
    /**
     * Create an adhoc attachment
     *
     * Used for multiple uploads say variant/inventory images
     */
    createAdhocAttachment: Attachment;
    /** Delete an unattached file */
    deleteUnattachedFile: Scalars['Boolean'];
    /** Copy a file to specified upload id */
    copyToUnattachedFile: UploadAttachment;
    /** Create a new company */
    createCompany?: Maybe<Company>;
    /** Update an existing company */
    updateCompany?: Maybe<Company>;
    /** Update an existing company mlc field like name, description and etc */
    updateCompanyMLC?: Maybe<Company>;
    /** Delete an existing company */
    deleteCompany?: Maybe<Company>;
    /** Create a new country */
    createCountry?: Maybe<Country>;
    /** Update an existing country */
    updateCountry?: Maybe<Country>;
    /** Update a maintenance for a country */
    updateMaintenance?: Maybe<Country>;
    /** Upload a maintenance image for a country */
    uploadMaintenanceImage: Attachment;
    /** Delete a maintenance image for a country */
    deleteMaintenanceImage: Scalars['Boolean'];
    /** Delete an existing country */
    deleteCountry?: Maybe<Country>;
    /** Create a new zone */
    createZone?: Maybe<Zone>;
    /** Update an existing zone */
    updateZone?: Maybe<Zone>;
    /** Delete an existing zone */
    deleteZone?: Maybe<Zone>;
    /** Create a new promo code */
    createPromoCode?: Maybe<PromoCode>;
    /** Update an existing promo code */
    updatePromoCode?: Maybe<PromoCode>;
    /** Delete an existing promo code */
    deletePromoCode: Scalars['Boolean'];
    /** Update an application */
    updateApplication: ProceedApplicationContext;
    /** Update an insurance application */
    updateInsuranceApplication: ProceedInsuranceApplicationContext;
    /** Continue an application if it's continuable and there's no change to it */
    continueApplication: ProceedApplicationContext;
    /** Continue an insurance application if it's continuable and there's no change to it */
    continueInsuranceApplication: ProceedInsuranceApplicationContext;
    /** Update assignee of an application */
    updateAssignee?: Maybe<Scalars['Boolean']>;
    /** Update assignee of an insurance application */
    updateInsuranceAssignee?: Maybe<Scalars['Boolean']>;
    /** Upload an application attachment */
    uploadApplicationAttachment?: Maybe<Attachment>;
    /** Delete an application attachment */
    deleteApplicationAttachment: Scalars['Boolean'];
    /** Share variant and finance parameters via email */
    shareFinanceContexts: Scalars['Boolean'];
    /** Create a draft application */
    createDraft: ProceedApplicationContext;
    /** Complete the consents and declarations */
    completeConsentsAndDeclarations: ProceedApplicationContext;
    /** Complete KYC */
    completeKyc: ProceedApplicationContext;
    /** Complete guarantor */
    completeGuarantor: ProceedApplicationContext;
    /** Complete guarantor confirmation */
    completeGuarantorConfirmation: ProceedApplicationContext;
    /** Complete guarantor consents and declarations */
    completeGuarantorConsents: ProceedApplicationContext;
    /** Complete payment */
    completePayment: ProceedApplicationContext;
    /** Complete reservation to finance */
    completeReservationToFinance: ProceedApplicationContext;
    /** Complete appointment */
    completeAppointment: ProceedApplicationContext;
    /** Submit a application */
    submitApplication: ProceedApplicationContext;
    /** Create a adhoc event */
    createAdhocEvent: AdhocEvent;
    /** Create an event for application */
    createApplicationEvent?: Maybe<Scalars['Boolean']>;
    /** Add application comment */
    addApplicationComment?: Maybe<Scalars['Boolean']>;
    /** Approve an application */
    approveApplication?: Maybe<Scalars['Boolean']>;
    /** Decline an application */
    declineApplication?: Maybe<Scalars['Boolean']>;
    /** Cancel an application */
    cancelApplication?: Maybe<Scalars['Boolean']>;
    /** Complete an application */
    completeApplication?: Maybe<Scalars['Boolean']>;
    /** Retry bank submission */
    retryBankSubmission?: Maybe<Scalars['Boolean']>;
    /** Activate an application */
    activateApplication?: Maybe<Scalars['Boolean']>;
    /** Conclude agreement an application */
    concludeAgreementApplication?: Maybe<Scalars['Boolean']>;
    /** Approve an insurance application */
    approveInsuranceApplication?: Maybe<Scalars['Boolean']>;
    /** Cancel an insurance application */
    cancelInsuranceApplication?: Maybe<Scalars['Boolean']>;
    /** Decline an insurance application */
    declineInsuranceApplication?: Maybe<Scalars['Boolean']>;
    /** Send an email to reset the password */
    sendResetPassword?: Maybe<Scalars['Boolean']>;
    /** Reset the password */
    resetPassword?: Maybe<Scalars['Boolean']>;
    /** Upload company logo */
    uploadCompanyLogo: Attachment;
    /** Delete company logo */
    deleteCompanyLogo?: Maybe<Scalars['Boolean']>;
    /** Upload variant image */
    uploadVariantImage: Attachment;
    /** Upload variant images with archive */
    uploadVariantImages?: Maybe<Scalars['Int']>;
    /** Delete variant image */
    deleteVariantImage?: Maybe<Scalars['Boolean']>;
    /** Upload company favicon */
    uploadCompanyFavIcon: Attachment;
    /** Delete company favicon */
    deleteCompanyFavIcon?: Maybe<Scalars['Boolean']>;
    /** Upload company font */
    uploadCompanyFont: Attachment;
    /** Delete company font */
    deleteCompanyFont?: Maybe<Scalars['Boolean']>;
    /** Update an inventory */
    updateInventory: Inventory;
    /** Delete an inventory */
    deleteInventory?: Maybe<Inventory>;
    /** Delete an inventory by variant id on inventory */
    deleteInventoryByVariantId?: Maybe<Inventory>;
    /** Import an excel file for inventory */
    importInventories?: Maybe<Array<Inventory>>;
    /** Upload user profile */
    uploadUserProfile: Attachment;
    /** Delete user profile */
    deleteUserProfile?: Maybe<Scalars['Boolean']>;
    /** Create new terms and conditions */
    createTermsAndConditions: TermsAndConditions;
    /** Create new customer */
    createCustomer: Customer;
    /**
     * If proceed with customer device, token via getProceedWithCustomerDeviceToken need to be passed
     * Update an existing customer
     */
    updateCustomer: Customer;
    /** Upload bank logo */
    uploadBankLogo: Attachment;
    /** Upload bank font */
    uploadBankFont: Attachment;
    /** Upload bank stamp */
    uploadBankStamp: Attachment;
    /** Delete bank logo */
    deleteBankLogo?: Maybe<Scalars['Boolean']>;
    /** Delete bank font */
    deleteBankFont?: Maybe<Scalars['Boolean']>;
    /** Delete bank stamp */
    deleteBankStamp?: Maybe<Scalars['Boolean']>;
    /**
     * Book options
     *
     * The variant ID the option IDs must be provided to book.
     * An optional reference ID may be provided, if none is, a reference ID will be generated.
     * An optional inventory unit ID can be specified, will try to book the specified unit when provided
     */
    bookOptions: Scalars['ObjectID'];
    /**
     * Scan uploaded documents for NRIC information extraction
     *
     * Either the upload ID or the application version ID must be provided.
     * Application ID will be use before upload ID.
     * If not are provide, an empty response will be returned.
     *
     * The country code can be used to specify which nationality should be used for extraction.
     * Some countries may have special logic.
     */
    extractNric: NricExtraction;
    /**
     * Reassign user's applications
     *
     * It will retun the number of applications which has been reassigned
     */
    reassignUserApplications: Scalars['Int'];
    /**
     * Fetch customer data from MyInfo
     * either bankId or eventId should be provided
     */
    fetchMyInfo?: Maybe<MyInfoCustomerResult>;
    /**
     * generate an OTP for application approval
     *
     * it will return the secret key and expire time
     */
    generateOTP: GenerateOtpResult;
    /**
     * validate OTP for submission using the secret key
     *
     * it will return a bool
     */
    completeOTP: ProceedApplicationContext;
    /** Start a chart session with dialog flow */
    startChatSession: ChatSession;
    /** Send a chat message */
    sendChatMessage: ChatReply;
    /** Update inventory units, deprecated */
    updateInventoryUnits?: Maybe<InventorySet>;
    /** Update a inventory set */
    updateInventorySet?: Maybe<InventorySet>;
    /** Update a inventory unit */
    updateInventoryUnit?: Maybe<InventoryUnit>;
    /** Update inventory unit images with archive */
    uploadInventoryUnitImages?: Maybe<Scalars['Int']>;
    /** Update channel settings */
    updateChannelSettings?: Maybe<Country>;
    /** Create an Event */
    createEvent?: Maybe<Event>;
    /** Update an existing Event */
    updateEvent?: Maybe<Event>;
    /** Delete an existing Event */
    deleteEvent?: Maybe<Event>;
    /** Import customized translations and returns the amount of translations updated */
    importTranslations?: Maybe<Scalars['Int']>;
    /** Save customer's order, limited to CKD, for now only send the email to customer */
    saveCustomerOrder?: Maybe<Scalars['Boolean']>;
    /** Update finder vehicle */
    updateFinderVehicle?: Maybe<FinderVehicle>;
    /** Reset finder vehicle status */
    resetFinderVehicleStatus?: Maybe<FinderVehicle>;
    /**
     * Book finder vehicle
     *
     * Id refers to listing id provided by Porsche Finder
     */
    bookFinderVehicle: Scalars['String'];
    /** Save the application as draft */
    saveDraft: ProceedApplicationContext;
    logout: Scalars['Boolean'];
    /** Upload event intro image */
    uploadEventIntroImage: Attachment;
    /** Delete event intro image */
    deleteEventIntroImage?: Maybe<Scalars['Boolean']>;
    /** Update assignee appointment */
    updateAssigneeAppointment: Appointment;
    /** Update status appointment */
    updateStatusAppointment: Appointment;
    /** Re-arrange date appointment */
    rearrangeDateAppointment: Appointment;
    /** Upload appointment attachment */
    uploadAppointmentAttachment: Attachment;
    /** Delete appointment attachment */
    deleteAppointmentAttachment?: Maybe<Scalars['Boolean']>;
    /** Import LTA data for porsche finder */
    importLtaFinderData?: Maybe<Scalars['Int']>;
};

export type MutationAuthenticateArgs = {
    username: Scalars['String'];
    password: Scalars['String'];
    countryId?: Maybe<Scalars['ObjectID']>;
    zoneId?: Maybe<Scalars['ObjectID']>;
    dealerId?: Maybe<Scalars['ObjectID']>;
    loginType: LoginType;
};

export type MutationSwitchTenantArgs = {
    companyId: Scalars['ObjectID'];
    countryId: Scalars['ObjectID'];
    zoneId?: Maybe<Scalars['ObjectID']>;
    dealerId?: Maybe<Scalars['ObjectID']>;
};

export type MutationCreateRoleArgs = {
    data: CreateOrUpdateRolePayload;
};

export type MutationUpdateRoleArgs = {
    id: Scalars['ObjectID'];
    data: CreateOrUpdateRolePayload;
};

export type MutationUpdateRoleHierarchyArgs = {
    id: Scalars['ObjectID'];
    parentId?: Maybe<Scalars['ObjectID']>;
};

export type MutationDeleteRoleArgs = {
    id: Scalars['ObjectID'];
};

export type MutationCreateUserArgs = {
    data: CreateOrUpdateUserPayload;
};

export type MutationUpdateUserArgs = {
    id: Scalars['ObjectID'];
    data: CreateOrUpdateUserPayload;
};

export type MutationDeleteUserArgs = {
    id: Scalars['ObjectID'];
};

export type MutationUpdateUserPasswordArgs = {
    password: Scalars['String'];
    newPassword: Scalars['String'];
};

export type MutationSendActivationLinkArgs = {
    userId?: Maybe<Scalars['ObjectID']>;
};

export type MutationUpdateUserProfileArgs = {
    data: UserProfilePayload;
};

export type MutationSubscribeToFcmArgs = {
    id: Scalars['ObjectID'];
    data: SubscribeOrUnsubscribeFcmPayload;
};

export type MutationUnsubscribeFromFcmArgs = {
    id: Scalars['ObjectID'];
    data: SubscribeOrUnsubscribeFcmPayload;
};

export type MutationCreateFinanceProductArgs = {
    data: CreateFinanceProductPayload;
};

export type MutationUpdateFinanceProductArgs = {
    id: Scalars['ObjectID'];
    data: UpdateFinanceProductPayload;
};

export type MutationDeleteFinanceProductArgs = {
    id: Scalars['ObjectID'];
};

export type MutationCreateMakeArgs = {
    data: CreateOrUpdateMakePayload;
};

export type MutationDeleteMakeArgs = {
    id: Scalars['ObjectID'];
};

export type MutationImportMakeArgs = {
    companyId: Scalars['ObjectID'];
    file: Scalars['Upload'];
    isUpdate: Scalars['Boolean'];
    duplicateIdsToBeUpdated?: Maybe<Array<Scalars['String']>>;
};

export type MutationUpdateMakeArgs = {
    id: Scalars['ObjectID'];
    data: CreateOrUpdateMakePayload;
};

export type MutationCreateModelArgs = {
    data: CreateOrUpdateModelPayload;
};

export type MutationCreateSubModelArgs = {
    data: CreateOrUpdateSubModelPayload;
};

export type MutationDeleteModelArgs = {
    id: Scalars['ObjectID'];
};

export type MutationDeleteSubModelArgs = {
    id: Scalars['ObjectID'];
};

export type MutationImportModelArgs = {
    countryId: Scalars['ObjectID'];
    file: Scalars['Upload'];
    isUpdate: Scalars['Boolean'];
    duplicateIdsToBeUpdated?: Maybe<Array<Scalars['String']>>;
};

export type MutationUpdateModelArgs = {
    id: Scalars['ObjectID'];
    data: CreateOrUpdateModelPayload;
};

export type MutationUpdateSubModelArgs = {
    id: Scalars['ObjectID'];
    data: CreateOrUpdateSubModelPayload;
};

export type MutationImportSubModelArgs = {
    countryId: Scalars['ObjectID'];
    file: Scalars['Upload'];
    isUpdate: Scalars['Boolean'];
    duplicateIdsToBeUpdated?: Maybe<Array<Scalars['String']>>;
};

export type MutationCreateOptionGroupArgs = {
    data: CreateOrUpdateOptionGroupPayload;
};

export type MutationDeleteOptionGroupArgs = {
    id: Scalars['ObjectID'];
};

export type MutationUpdateOptionGroupArgs = {
    id: Scalars['ObjectID'];
    data: CreateOrUpdateOptionGroupPayload;
};

export type MutationCreateOptionArgs = {
    data: CreateOrUpdateOptionPayload;
};

export type MutationDeleteOptionArgs = {
    id: Scalars['ObjectID'];
};

export type MutationImportOptionArgs = {
    countryId: Scalars['ObjectID'];
    file: Scalars['Upload'];
    isUpdate: Scalars['Boolean'];
    duplicateIdsToBeUpdated?: Maybe<Array<Scalars['String']>>;
};

export type MutationUpdateOptionArgs = {
    id: Scalars['ObjectID'];
    data: CreateOrUpdateOptionPayload;
};

export type MutationUploadOptionImageArgs = {
    optionId: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type MutationUploadOptionImagesArgs = {
    countryId: Scalars['ObjectID'];
    file: Array<Maybe<Scalars['Upload']>>;
};

export type MutationDeleteOptionImageArgs = {
    optionId: Scalars['ObjectID'];
};

export type MutationCreateVariantArgs = {
    data: CreateOrUpdateVariantPayload;
};

export type MutationCreateExpressVariantArgs = {
    data: CreateOrUpdateUsedVariantPayload;
};

export type MutationDeleteVariantArgs = {
    id: Scalars['ObjectID'];
};

export type MutationImportVariantArgs = {
    countryId: Scalars['ObjectID'];
    file: Scalars['Upload'];
    isUpdate: Scalars['Boolean'];
    duplicateIdsToBeUpdated?: Maybe<Array<Scalars['String']>>;
};

export type MutationUpdateVariantArgs = {
    id: Scalars['ObjectID'];
    data: CreateOrUpdateVariantPayload;
};

export type MutationCreateBankArgs = {
    data: CreateBankPayload;
};

export type MutationUpdateBankArgs = {
    id: Scalars['ObjectID'];
    data: UpdateBankPayload;
};

export type MutationDeleteBankArgs = {
    id: Scalars['ObjectID'];
};

export type MutationCreateInsuranceArgs = {
    data: CreateInsurancePayload;
};

export type MutationCreateInsuranceApplicationArgs = {
    data: CreateInsuranceApplicationPayload;
};

export type MutationCompleteInsuranceConsentsAndDeclarationsArgs = {
    token: Scalars['String'];
    eventId?: Maybe<Scalars['ObjectID']>;
};

export type MutationUpdateInsuranceArgs = {
    id: Scalars['ObjectID'];
    data: UpdateInsurancePayload;
};

export type MutationDeleteInsuranceArgs = {
    id: Scalars['ObjectID'];
};

export type MutationCreateDealerArgs = {
    data: CreateOrUpdateDealerPayload;
};

export type MutationUpdateDealerArgs = {
    id: Scalars['ObjectID'];
    data: CreateOrUpdateDealerPayload;
};

export type MutationUpdateDealerBookingPaymentArgs = {
    id: Scalars['ObjectID'];
    data: UpdateDealerBookingPaymentPayload;
};

export type MutationUpdateDealerEstablishmentArgs = {
    id: Scalars['ObjectID'];
    data: UpdateDealerEstablishmentPayload;
};

export type MutationDeleteDealerArgs = {
    id: Scalars['ObjectID'];
};

export type MutationCreateConsentOrDeclarationArgs = {
    data: CreateOrUpdateConsentOrDeclarationPayload;
};

export type MutationUpdateConsentOrDeclarationArgs = {
    id: Scalars['ObjectID'];
    data: CreateOrUpdateConsentOrDeclarationPayload;
};

export type MutationDeleteConsentOrDeclarationArgs = {
    id: Scalars['ObjectID'];
};

export type MutationUploadUnattachedFileArgs = {
    file: Scalars['Upload'];
    purpose: UploadPurpose;
    uploadId?: Maybe<Scalars['ObjectID']>;
};

export type MutationCreateAdhocAttachmentArgs = {
    purpose?: Maybe<AdhocAttachmentPurpose>;
    file: Scalars['Upload'];
};

export type MutationDeleteUnattachedFileArgs = {
    attachmentId: Scalars['ObjectID'];
    uploadId: Scalars['ObjectID'];
};

export type MutationCopyToUnattachedFileArgs = {
    attachmentId: Scalars['ObjectID'];
    uploadId?: Maybe<Scalars['ObjectID']>;
};

export type MutationCreateCompanyArgs = {
    data: CreateOrUpdateCompanyPayload;
};

export type MutationUpdateCompanyArgs = {
    id: Scalars['ObjectID'];
    data: CreateOrUpdateCompanyPayload;
};

export type MutationUpdateCompanyMlcArgs = {
    id: Scalars['ObjectID'];
    data: UpdateCompanyMlcPayload;
};

export type MutationDeleteCompanyArgs = {
    id: Scalars['ObjectID'];
};

export type MutationCreateCountryArgs = {
    data: CreateCountryPayload;
};

export type MutationUpdateCountryArgs = {
    id: Scalars['ObjectID'];
    data: UpdateCountryPayload;
};

export type MutationUpdateMaintenanceArgs = {
    countryId: Scalars['ObjectID'];
    data: MaintenancePayload;
};

export type MutationUploadMaintenanceImageArgs = {
    countryId: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type MutationDeleteMaintenanceImageArgs = {
    countryId: Scalars['ObjectID'];
};

export type MutationDeleteCountryArgs = {
    id: Scalars['ObjectID'];
};

export type MutationCreateZoneArgs = {
    data: CreateZonePayload;
};

export type MutationUpdateZoneArgs = {
    id: Scalars['ObjectID'];
    data: UpdateZonePayload;
};

export type MutationDeleteZoneArgs = {
    id: Scalars['ObjectID'];
};

export type MutationCreatePromoCodeArgs = {
    data: CreatePromoCodePayload;
};

export type MutationUpdatePromoCodeArgs = {
    id: Scalars['ObjectID'];
    data: UpdatePromoCodePayload;
};

export type MutationDeletePromoCodeArgs = {
    id: Scalars['ObjectID'];
};

export type MutationUpdateApplicationArgs = {
    id: Scalars['ObjectID'];
    data: UpdateApplicationPayload;
};

export type MutationUpdateInsuranceApplicationArgs = {
    id: Scalars['ObjectID'];
    data: UpdateInsuranceApplicationPayload;
};

export type MutationContinueApplicationArgs = {
    id: Scalars['ObjectID'];
    proceedWithCustomerDevice: Scalars['Boolean'];
};

export type MutationContinueInsuranceApplicationArgs = {
    id: Scalars['ObjectID'];
    proceedWithCustomerDevice: Scalars['Boolean'];
};

export type MutationUpdateAssigneeArgs = {
    applicationId: Scalars['ObjectID'];
    assigneeId: Scalars['ObjectID'];
};

export type MutationUpdateInsuranceAssigneeArgs = {
    insuranceApplicationId: Scalars['ObjectID'];
    assigneeId: Scalars['ObjectID'];
};

export type MutationUploadApplicationAttachmentArgs = {
    id: Scalars['ObjectID'];
    file: Scalars['Upload'];
    token?: Maybe<Scalars['String']>;
    purpose?: Maybe<Scalars['String']>;
    source?: Maybe<ApplicationEventSource>;
};

export type MutationDeleteApplicationAttachmentArgs = {
    applicationId: Scalars['ObjectID'];
    attachmentId: Scalars['ObjectID'];
    token?: Maybe<Scalars['String']>;
};

export type MutationShareFinanceContextsArgs = {
    customerId: Scalars['ObjectID'];
    dealerId?: Maybe<Scalars['ObjectID']>;
    zoneId: Scalars['ObjectID'];
    data: Array<FinanceContextPayload>;
    channel: Channel;
    eventId?: Maybe<Scalars['ObjectID']>;
    locale: Scalars['String'];
    shouldCreateLead?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateDraftArgs = {
    data: CreateDraftPayload;
};

export type MutationCompleteConsentsAndDeclarationsArgs = {
    token: Scalars['String'];
    eventId?: Maybe<Scalars['ObjectID']>;
};

export type MutationCompleteKycArgs = {
    token: Scalars['String'];
};

export type MutationCompleteGuarantorArgs = {
    token: Scalars['String'];
};

export type MutationCompleteGuarantorConfirmationArgs = {
    token: Scalars['String'];
};

export type MutationCompleteGuarantorConsentsArgs = {
    token: Scalars['String'];
    eventId?: Maybe<Scalars['ObjectID']>;
};

export type MutationCompletePaymentArgs = {
    token: Scalars['String'];
};

export type MutationCompleteReservationToFinanceArgs = {
    token: Scalars['String'];
};

export type MutationCompleteAppointmentArgs = {
    token: Scalars['String'];
    data?: Maybe<AppointmentFlowPayload>;
};

export type MutationSubmitApplicationArgs = {
    token: Scalars['String'];
};

export type MutationCreateAdhocEventArgs = {
    data: CreateAdhocEventPayload;
};

export type MutationCreateApplicationEventArgs = {
    token: Scalars['String'];
    data: CreateApplicationEventPayload;
};

export type MutationAddApplicationCommentArgs = {
    data: AddApplicationCommentPayload;
};

export type MutationApproveApplicationArgs = {
    id: Scalars['ObjectID'];
};

export type MutationDeclineApplicationArgs = {
    id: Scalars['ObjectID'];
};

export type MutationCancelApplicationArgs = {
    id: Scalars['ObjectID'];
};

export type MutationCompleteApplicationArgs = {
    id: Scalars['ObjectID'];
};

export type MutationRetryBankSubmissionArgs = {
    applicationId: Scalars['ObjectID'];
};

export type MutationActivateApplicationArgs = {
    id: Scalars['ObjectID'];
};

export type MutationConcludeAgreementApplicationArgs = {
    id: Scalars['ObjectID'];
};

export type MutationApproveInsuranceApplicationArgs = {
    id: Scalars['ObjectID'];
};

export type MutationCancelInsuranceApplicationArgs = {
    id: Scalars['ObjectID'];
};

export type MutationDeclineInsuranceApplicationArgs = {
    id: Scalars['ObjectID'];
};

export type MutationSendResetPasswordArgs = {
    username: Scalars['String'];
    countryId?: Maybe<Scalars['ObjectID']>;
    companyId?: Maybe<Scalars['ObjectID']>;
    purpose?: Maybe<LoginType>;
    locale?: Maybe<Scalars['String']>;
};

export type MutationResetPasswordArgs = {
    secret: Scalars['String'];
    password: Scalars['String'];
};

export type MutationUploadCompanyLogoArgs = {
    companyId: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type MutationDeleteCompanyLogoArgs = {
    companyId: Scalars['ObjectID'];
};

export type MutationUploadVariantImageArgs = {
    variantId: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type MutationUploadVariantImagesArgs = {
    countryId: Scalars['ObjectID'];
    file: Array<Maybe<Scalars['Upload']>>;
};

export type MutationDeleteVariantImageArgs = {
    variantId: Scalars['ObjectID'];
};

export type MutationUploadCompanyFavIconArgs = {
    companyId: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type MutationDeleteCompanyFavIconArgs = {
    companyId: Scalars['ObjectID'];
};

export type MutationUploadCompanyFontArgs = {
    companyId: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type MutationDeleteCompanyFontArgs = {
    companyId: Scalars['ObjectID'];
};

export type MutationUpdateInventoryArgs = {
    data: InventoryPayload;
};

export type MutationDeleteInventoryArgs = {
    id: Scalars['ObjectID'];
};

export type MutationDeleteInventoryByVariantIdArgs = {
    id: Scalars['ObjectID'];
};

export type MutationImportInventoriesArgs = {
    countryId: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type MutationUploadUserProfileArgs = {
    userId: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type MutationDeleteUserProfileArgs = {
    userId: Scalars['ObjectID'];
};

export type MutationCreateTermsAndConditionsArgs = {
    data: CreateTermsAndConditionsPayload;
};

export type MutationCreateCustomerArgs = {
    data: CreateCustomerPayload;
};

export type MutationUpdateCustomerArgs = {
    id: Scalars['ObjectID'];
    data: UpdateCustomerPayload;
    token?: Maybe<Scalars['String']>;
};

export type MutationUploadBankLogoArgs = {
    bankId: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type MutationUploadBankFontArgs = {
    bankId: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type MutationUploadBankStampArgs = {
    bankId: Scalars['ObjectID'];
    purpose: UploadPurpose;
    file: Scalars['Upload'];
};

export type MutationDeleteBankLogoArgs = {
    bankId: Scalars['ObjectID'];
};

export type MutationDeleteBankFontArgs = {
    bankId: Scalars['ObjectID'];
};

export type MutationDeleteBankStampArgs = {
    bankId: Scalars['ObjectID'];
    purpose: UploadPurpose;
};

export type MutationBookOptionsArgs = {
    referenceId?: Maybe<Scalars['ObjectID']>;
    variantId: Scalars['ObjectID'];
    optionIds: Array<Scalars['ObjectID']>;
    inventoryUnitId?: Maybe<Scalars['ObjectID']>;
};

export type MutationExtractNricArgs = {
    uploadId?: Maybe<Scalars['ObjectID']>;
    applicationId?: Maybe<Scalars['ObjectID']>;
    countryCode?: Maybe<Scalars['String']>;
};

export type MutationReassignUserApplicationsArgs = {
    fromUserId: Scalars['ObjectID'];
    toUserId: Scalars['ObjectID'];
};

export type MutationFetchMyInfoArgs = {
    authorizationCode: Scalars['String'];
    bankId?: Maybe<Scalars['ObjectID']>;
    eventId?: Maybe<Scalars['ObjectID']>;
    source: ApplicationFlowSource;
    exclude?: Maybe<Array<MyInfoExcludableSection>>;
};

export type MutationGenerateOtpArgs = {
    token: Scalars['String'];
};

export type MutationCompleteOtpArgs = {
    token: Scalars['String'];
    secret: Scalars['String'];
    otp: Scalars['String'];
};

export type MutationStartChatSessionArgs = {
    zoneId: Scalars['ObjectID'];
    channel: Channel;
    eventId?: Maybe<Scalars['ObjectID']>;
};

export type MutationSendChatMessageArgs = {
    zoneId: Scalars['ObjectID'];
    sessionId: Scalars['String'];
    message: Scalars['String'];
    channel: Channel;
    eventId?: Maybe<Scalars['ObjectID']>;
};

export type MutationUpdateInventoryUnitsArgs = {
    setId: Scalars['ObjectID'];
    units: Array<InventoryUnitPayload>;
};

export type MutationUpdateInventorySetArgs = {
    id: Scalars['ObjectID'];
    data: UpdateInventorySetPayload;
};

export type MutationUpdateInventoryUnitArgs = {
    id: Scalars['ObjectID'];
    data: UpdateInventoryUnitPayload;
};

export type MutationUploadInventoryUnitImagesArgs = {
    countryId: Scalars['ObjectID'];
    file: Array<Maybe<Scalars['Upload']>>;
};

export type MutationUpdateChannelSettingsArgs = {
    countryId: Scalars['ObjectID'];
    dealerId?: Maybe<Scalars['ObjectID']>;
    data: UpdateChannelSettingsPayload;
};

export type MutationCreateEventArgs = {
    countryId: Scalars['ObjectID'];
    event: CreateOrUpdateEventPayload;
};

export type MutationUpdateEventArgs = {
    id: Scalars['ObjectID'];
    event: CreateOrUpdateEventPayload;
};

export type MutationDeleteEventArgs = {
    id: Scalars['ObjectID'];
};

export type MutationImportTranslationsArgs = {
    countryId: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type MutationSaveCustomerOrderArgs = {
    countryId: Scalars['ObjectID'];
    dealerId?: Maybe<Scalars['ObjectID']>;
    email: Scalars['String'];
    data: SaveCustomerOrderPayload;
};

export type MutationUpdateFinderVehicleArgs = {
    id: Scalars['ObjectID'];
    finderVehicle: UpdateFinderVehiclePayload;
};

export type MutationResetFinderVehicleStatusArgs = {
    id: Scalars['ObjectID'];
};

export type MutationBookFinderVehicleArgs = {
    id: Scalars['String'];
    countryId?: Maybe<Scalars['ObjectID']>;
};

export type MutationSaveDraftArgs = {
    token: Scalars['String'];
    assigneeId?: Maybe<Scalars['ObjectID']>;
};

export type MutationUploadEventIntroImageArgs = {
    eventId: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type MutationDeleteEventIntroImageArgs = {
    eventId: Scalars['ObjectID'];
};

export type MutationUpdateAssigneeAppointmentArgs = {
    id: Scalars['ObjectID'];
    assigneeId: Scalars['ObjectID'];
};

export type MutationUpdateStatusAppointmentArgs = {
    id: Scalars['ObjectID'];
    status: AppointmentStatus;
};

export type MutationRearrangeDateAppointmentArgs = {
    id: Scalars['ObjectID'];
    date: Scalars['DateTime'];
};

export type MutationUploadAppointmentAttachmentArgs = {
    id: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type MutationDeleteAppointmentAttachmentArgs = {
    id: Scalars['ObjectID'];
    attachmentId: Scalars['ObjectID'];
};

export type MutationImportLtaFinderDataArgs = {
    countryId: Scalars['ObjectID'];
    file: Scalars['Upload'];
};

export type Query = {
    helloWorld?: Maybe<Scalars['String']>;
    /** Retrieve current user */
    getCurrentUser?: Maybe<User>;
    /** Retrieve runtime settings */
    getRuntimeSettings?: Maybe<RuntimeSettings>;
    /** Get a user by his ID */
    getUserById?: Maybe<User>;
    /** Get a list of user by their company ID */
    getUsersByCompanyId: UserList;
    /** Get a list of user by their country ID */
    getUsersByCountryId: UserList;
    /** Get a list of user by their country ID */
    getUsersByCountryIdAndDealerIds: Array<User>;
    /** Get a role by ID */
    getRoleById?: Maybe<Role>;
    /** Get a list of role by their country ID */
    getRolesByCountryId: RoleList;
    /** Get a finance product by its ID */
    getFinanceProductById?: Maybe<FinanceProduct>;
    /** Get a list of finance product by their country ID */
    getFinanceProductsByCountryId: FinanceProductList;
    /** Get a list of finance products by their dealer ID, return only valid results */
    getFinanceProductsByDealerId: Array<FinanceProduct>;
    /** Get a make by its ID */
    getMakeById?: Maybe<Make>;
    /** Get a list of make by company ID */
    getMakesByCompanyId: MakeList;
    /** Get a model by its ID */
    getModelById?: Maybe<Model>;
    /** Get a list of model by country ID */
    getModelsByCountryId: ModelList;
    /** Get a list of model by make ID */
    getModelsByMakeId: ModelList;
    /** Get a list of model by make and country ID */
    getModelsByMakeIdAndCountryId: ModelList;
    /** Get a list of sub model by model ID */
    getSubModelsByModelId: ModelList;
    /** Get an option group by its ID */
    getOptionGroupById?: Maybe<OptionGroup>;
    /** Get a list of option group by country ID */
    getOptionGroupsByCountryId: OptionGroupList;
    /** Get an option by its ID */
    getOptionById?: Maybe<Option>;
    /** Get a list of option by country ID */
    getOptionsByCountryId: OptionList;
    /** Get a list of option by option group ID */
    getOptionsByOptionGroupId: OptionList;
    /** Get a list of option by variant ID */
    getOptionsByVariantId: OptionList;
    /** Get a variant by its ID */
    getVariantById?: Maybe<Variant>;
    /** Get a list of variant by country ID */
    getVariantsByCountryId: VariantList;
    /** Get a list of variants by zone ID, use for chooser screen and calculator */
    getVariantsByZoneId: Array<Variant>;
    /** Get a list of variant by model ID */
    getVariantsByModelId: VariantList;
    /** Get a list of variant by dealer ID */
    getVariantsByDealerId: Array<Variant>;
    /** Get a list of banks by country ID */
    getBankById?: Maybe<Bank>;
    /** Get a list of banks by country ID */
    getBanksByCountryId?: Maybe<BankList>;
    /** Get insurance by Id */
    getInsuranceById?: Maybe<Insurance>;
    /** Get a list of insurances by country ID */
    getInsurancesByCountryId?: Maybe<InsuranceList>;
    /** Get consent or declaration by ID */
    getConsentOrDeclarationById?: Maybe<ConsentOrDeclaration>;
    /** Get consents and declaratins by country ID */
    getConsentsAndDeclarationsByCountryId: Array<ConsentOrDeclaration>;
    /** Get consents and declaratins for specified flow */
    getFlowConsentsAndDeclarations: Array<ConsentOrDeclaration>;
    /** Get a company by its ID */
    getCompanyById?: Maybe<Company>;
    /** Get a list of company */
    getCompanies: CompanyList;
    /** Get a list of dealers */
    getDealers: DealerList;
    /** Get a list of dealers by country ID */
    getDealersByCountryId: DealerList;
    /** Get a list of available dealers by country ID */
    getAvailableDealersByCountryId: Array<Dealer>;
    /** Get a dealer by its ID */
    getDealerById?: Maybe<Dealer>;
    /** Get dealer booking payment for channels */
    getDealerBookingPayment?: Maybe<ChannelBookingPayment>;
    /** Get dealer booking payment for channels */
    getDealerEstablishment?: Maybe<ChannelEstablishment>;
    /** Get a dealer's contact information by its ID */
    getDealerContactInformationById?: Maybe<DealerContactInformation>;
    /** Get a country by its ID */
    getCountryById?: Maybe<Country>;
    /** Get a list of country by their company ID */
    getCountriesByCompanyId: CountryList;
    /** Get a list of country */
    getCountries: CountryList;
    /** Get a zone by its ID */
    getZoneById?: Maybe<Zone>;
    /** Get a list of zones by their company ID */
    getZonesByCompanyId: ZoneList;
    /** Get a list of zone by their country ID */
    getZonesByCountryId: ZoneList;
    /** Get a list of promo codes by country ID */
    getPromoCodesByCountryId: PromoCodeList;
    /** Get promo code by ID */
    getPromoCodeById: PromoCode;
    /** Get promo code by country ID and its identifier */
    getPromoCodeByCountryIdAndIdentifier?: Maybe<PromoCode>;
    /** Get if there are valid promo codes or not by country ID and dealer ID */
    getHasValidPromoCodesByCountryId: Scalars['Boolean'];
    /** Get scope based on company, country and zone codes */
    getScope: Scope;
    /** Get application options (such as countries or timezones) */
    getAppOptions: ApplicationOptions;
    /** Get a list of inventory by country id */
    getInventoriesByCountryId: InventoryList;
    /** Get an inventory by its ID */
    getInventoryById?: Maybe<Inventory>;
    /** Get an inventory unit by its ID */
    getInventoryUnitById?: Maybe<InventoryUnit>;
    /** Get inventory units by zone id */
    getInventoryUnitsByZoneId: Array<InventoryUnit>;
    /** Get list of latest applications */
    getLatestApplications: Array<Application>;
    /** Get list of latest applications in the country */
    getLatestApplicationsByCountryId: Array<Application>;
    /** Get a customer by its ID */
    getCustomerById?: Maybe<Customer>;
    /** Get a list of customer by their zone id */
    getCustomersByZoneId: CustomerList;
    /** Get a list of customer by their country id */
    getCustomersByCountryId: CustomerList;
    /** Search for an existing customer */
    searchCustomer?: Maybe<Array<Customer>>;
    /** Get signing status */
    getSigningStatusByApplicationVersionId: SigningStatus;
    /** Get guaranteed buyback status by application version id */
    getGuaranteedBuybackStatusByApplicationVersionId: GuaranteedBuybackStatus;
    /** Get an application by its version id */
    getApplicationByVersionId: Application;
    /** Get an insurance application by its version id */
    getInsuranceApplicationByVersionId: InsuranceApplication;
    /** Get an application by token(via getProceedWithCustomerDeviceToken) */
    getApplicationByToken: Application;
    /** Get an insurance application by token(via getProceedWithCustomerDeviceToken) */
    getInsuranceApplicationByToken: InsuranceApplication;
    /** Get applications by zone id */
    getApplicationsByZoneId: ApplicationList;
    /** Get insurance applications by zone id */
    getInsuranceApplicationsByZoneId: InsuranceApplicationList;
    /** Get a lead by its version id */
    getLeadByVersionId: Application;
    /** Get the finance context of the lead */
    getFinanceContextByLeadId: FinanceContext;
    /** Get leads by zone id, dealer ids */
    getLeadsByZoneId: ApplicationList;
    /** Get leads by country id and dealer ids */
    getLeadsByCountryId: ApplicationList;
    /** Get applications by country id */
    getApplicationsByCountryId: ApplicationList;
    /** Get a token for proceed with customer's device(in JWT form) */
    getProceedWithCustomerDeviceToken?: Maybe<Scalars['String']>;
    /** Get the context for proceed with customer's device */
    getProceedWithCustomerDeviceContext: ProceedWithCustomerDeviceContext;
    /**
     * Get a token for proceed application
     * Used when redirected back from some third party site, currently only redirected back from payment site
     */
    getProceedApplicationToken?: Maybe<Scalars['String']>;
    /** Get a list of events by country ID */
    getEventsByCountryId: EventList;
    /** Get a event by its ID */
    getEventById?: Maybe<Event>;
    /** Get translations */
    getTranslations: Scalars['JSONObject'];
    getDistinctApplicationDealerIds: Array<Scalars['ObjectID']>;
    getDistinctActiveInferiorDealerIds: Array<Scalars['ObjectID']>;
    /** Get current date time from server */
    getDateTime: Scalars['DateTime'];
    /** Get finder vehicle by its ID */
    getFinderVehicleById?: Maybe<FinderVehicle>;
    /** Get finder vehicle by its Remote ID */
    getFinderVehicleByRemoteId?: Maybe<FinderVehicle>;
    /** Get finder vehicles by country ID */
    getFinderVehiclesByCountryId: Array<FinderVehicle>;
    getFinderVehicleBookingByReferenceId?: Maybe<FinderVehicleBooking>;
    /** Get notifications by user ID */
    getNotificationsByCurrentUser?: Maybe<NotificationList>;
    /** Get statistic submitted application date */
    getSubmittedApplicationPerDay?: Maybe<StatisticMonthlyResult>;
    /** Get statistic application counter */
    getApplicationCounter?: Maybe<StatisticCounterResult>;
    /** Get statistic popular variant */
    getPopularVariant?: Maybe<StatisticMonthlyResult>;
    /** Get statistic popular event */
    getPopularEvent?: Maybe<StatisticMonthlyResult>;
    /** Get statistic salesperson most application / leads */
    getMostApplicationSalesPerson?: Maybe<StatisticMonthlyResult>;
    /** Get statistic deposit payment per day */
    getDepositPaymentPerDay?: Maybe<StatisticMonthlyResult>;
    /** Get csv configuration setup */
    getCsvConfigurationSetup: CsvConfiguratorSetup;
    /** Get appointment list */
    getAppointments: AppointmentList;
    /** Get latest appointment by version id */
    getAppointmentByVersionId: Appointment;
    /** Get reserved appointment dates, */
    getReservedAppointmentDates: Array<ReservedAppointmentDates>;
    /** Get insurance premium amount */
    getInsurancePremiumAmount: Scalars['Float'];
    /** Get insurance company */
    getInsuranceCompany?: Maybe<Insurance>;
};

export type QueryHelloWorldArgs = {
    name: Scalars['String'];
};

export type QueryGetUserByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetUsersByCompanyIdArgs = {
    companyId: Scalars['ObjectID'];
    paging?: Maybe<PagingPayload>;
};

export type QueryGetUsersByCountryIdArgs = {
    countryId: Scalars['ObjectID'];
    paging?: Maybe<PagingPayload>;
};

export type QueryGetUsersByCountryIdAndDealerIdsArgs = {
    countryId: Scalars['ObjectID'];
    dealerIds: Array<Scalars['ObjectID']>;
};

export type QueryGetRoleByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetRolesByCountryIdArgs = {
    countryId: Scalars['ObjectID'];
    paging?: Maybe<PagingPayload>;
};

export type QueryGetFinanceProductByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetFinanceProductsByCountryIdArgs = {
    countryId: Scalars['ObjectID'];
    dealerIds?: Maybe<Array<Scalars['ObjectID']>>;
    paging?: Maybe<PagingPayload>;
    activeOnly?: Maybe<Scalars['Boolean']>;
    channel?: Maybe<Channel>;
};

export type QueryGetFinanceProductsByDealerIdArgs = {
    id: Scalars['ObjectID'];
    channel: Channel;
    eventId?: Maybe<Scalars['ObjectID']>;
};

export type QueryGetMakeByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetMakesByCompanyIdArgs = {
    companyId: Scalars['ObjectID'];
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
};

export type QueryGetModelByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetModelsByCountryIdArgs = {
    countryId: Scalars['ObjectID'];
    isSubModel?: Maybe<Scalars['Boolean']>;
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
};

export type QueryGetModelsByMakeIdArgs = {
    makeId: Scalars['ObjectID'];
    isSubModel?: Maybe<Scalars['Boolean']>;
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
};

export type QueryGetModelsByMakeIdAndCountryIdArgs = {
    makeId: Scalars['ObjectID'];
    countryId: Scalars['ObjectID'];
    isSubModel?: Maybe<Scalars['Boolean']>;
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
};

export type QueryGetSubModelsByModelIdArgs = {
    modelId: Scalars['ObjectID'];
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
};

export type QueryGetOptionGroupByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetOptionGroupsByCountryIdArgs = {
    countryId: Scalars['ObjectID'];
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
};

export type QueryGetOptionByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetOptionsByCountryIdArgs = {
    countryId: Scalars['ObjectID'];
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
};

export type QueryGetOptionsByOptionGroupIdArgs = {
    optionGroupId: Scalars['ObjectID'];
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
};

export type QueryGetOptionsByVariantIdArgs = {
    variantId: Scalars['ObjectID'];
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
};

export type QueryGetVariantByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetVariantsByCountryIdArgs = {
    countryId: Scalars['ObjectID'];
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
    activeOnly?: Maybe<Scalars['Boolean']>;
    channel?: Maybe<Channel>;
};

export type QueryGetVariantsByZoneIdArgs = {
    zoneId: Scalars['ObjectID'];
    channel: Channel;
};

export type QueryGetVariantsByModelIdArgs = {
    modelId: Scalars['ObjectID'];
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
};

export type QueryGetVariantsByDealerIdArgs = {
    zoneId: Scalars['ObjectID'];
    dealerId: Scalars['ObjectID'];
    channel?: Maybe<Channel>;
};

export type QueryGetBankByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetBanksByCountryIdArgs = {
    id: Scalars['ObjectID'];
    paging?: Maybe<PagingPayload>;
};

export type QueryGetInsuranceByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetInsurancesByCountryIdArgs = {
    id: Scalars['ObjectID'];
    paging?: Maybe<PagingPayload>;
};

export type QueryGetConsentOrDeclarationByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetConsentsAndDeclarationsByCountryIdArgs = {
    id: Scalars['ObjectID'];
    dealerIds: Array<Scalars['ObjectID']>;
};

export type QueryGetFlowConsentsAndDeclarationsArgs = {
    consent: FlowConsentPayload;
};

export type QueryGetCompanyByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetCompaniesArgs = {
    paging?: Maybe<PagingPayload>;
};

export type QueryGetDealersArgs = {
    paging?: Maybe<PagingPayload>;
};

export type QueryGetDealersByCountryIdArgs = {
    id: Scalars['ObjectID'];
    paging?: Maybe<PagingPayload>;
};

export type QueryGetAvailableDealersByCountryIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetDealerByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetDealerBookingPaymentArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetDealerEstablishmentArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetDealerContactInformationByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetCountryByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetCountriesByCompanyIdArgs = {
    id: Scalars['ObjectID'];
    paging?: Maybe<PagingPayload>;
};

export type QueryGetCountriesArgs = {
    paging?: Maybe<PagingPayload>;
};

export type QueryGetZoneByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetZonesByCompanyIdArgs = {
    id: Scalars['ObjectID'];
    paging?: Maybe<PagingPayload>;
};

export type QueryGetZonesByCountryIdArgs = {
    id: Scalars['ObjectID'];
    paging?: Maybe<PagingPayload>;
};

export type QueryGetPromoCodesByCountryIdArgs = {
    countryId: Scalars['ObjectID'];
    dealerIds: Array<Scalars['ObjectID']>;
    paging?: Maybe<PagingPayload>;
};

export type QueryGetPromoCodeByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetPromoCodeByCountryIdAndIdentifierArgs = {
    countryId: Scalars['ObjectID'];
    identifier: Scalars['String'];
    dealerIds: Array<Scalars['ObjectID']>;
};

export type QueryGetHasValidPromoCodesByCountryIdArgs = {
    countryId: Scalars['ObjectID'];
    dealerId?: Maybe<Scalars['ObjectID']>;
};

export type QueryGetScopeArgs = {
    companyCode: Scalars['String'];
    countryCode: Scalars['String'];
    zoneCode: Scalars['String'];
};

export type QueryGetInventoriesByCountryIdArgs = {
    id: Scalars['ObjectID'];
    paging?: Maybe<PagingPayload>;
};

export type QueryGetInventoryByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetInventoryUnitByIdArgs = {
    id: Scalars['ObjectID'];
    referenceId?: Maybe<Scalars['ObjectID']>;
};

export type QueryGetInventoryUnitsByZoneIdArgs = {
    id: Scalars['ObjectID'];
    dealerId: Scalars['ObjectID'];
};

export type QueryGetLatestApplicationsArgs = {
    zoneId: Scalars['ObjectID'];
    dealerIds: Array<Scalars['ObjectID']>;
    channels: Array<Channel>;
};

export type QueryGetLatestApplicationsByCountryIdArgs = {
    id: Scalars['ObjectID'];
    dealerIds: Array<Scalars['ObjectID']>;
    channels: Array<Channel>;
};

export type QueryGetCustomerByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetCustomersByZoneIdArgs = {
    zoneId: Scalars['ObjectID'];
    dealerIds: Array<Scalars['ObjectID']>;
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
};

export type QueryGetCustomersByCountryIdArgs = {
    countryId: Scalars['ObjectID'];
    dealerIds: Array<Scalars['ObjectID']>;
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
};

export type QuerySearchCustomerArgs = {
    zoneId: Scalars['ObjectID'];
    data: SearchCustomerPayload;
};

export type QueryGetSigningStatusByApplicationVersionIdArgs = {
    versionId: Scalars['ObjectID'];
    isInsurance: Scalars['Boolean'];
    source: ApplicationFlowSource;
};

export type QueryGetGuaranteedBuybackStatusByApplicationVersionIdArgs = {
    versionId: Scalars['ObjectID'];
    source: ApplicationFlowSource;
    signer: GuaranteedBuybackSigner;
};

export type QueryGetApplicationByVersionIdArgs = {
    versionId: Scalars['ObjectID'];
};

export type QueryGetInsuranceApplicationByVersionIdArgs = {
    versionId: Scalars['ObjectID'];
};

export type QueryGetApplicationByTokenArgs = {
    token: Scalars['String'];
};

export type QueryGetInsuranceApplicationByTokenArgs = {
    token: Scalars['String'];
};

export type QueryGetApplicationsByZoneIdArgs = {
    zoneId: Scalars['ObjectID'];
    filter?: Maybe<ApplicationListFilterInput>;
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
    search?: Maybe<Array<ApplicationFilteringRule>>;
};

export type QueryGetInsuranceApplicationsByZoneIdArgs = {
    zoneId: Scalars['ObjectID'];
    dealerIds: Array<Scalars['ObjectID']>;
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
    search?: Maybe<Array<ApplicationFilteringRule>>;
};

export type QueryGetLeadByVersionIdArgs = {
    versionId: Scalars['ObjectID'];
};

export type QueryGetFinanceContextByLeadIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetLeadsByZoneIdArgs = {
    zoneId: Scalars['ObjectID'];
    dealerIds: Array<Scalars['ObjectID']>;
    eventIds?: Maybe<Array<Scalars['ObjectID']>>;
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
    search?: Maybe<Array<ApplicationFilteringRule>>;
};

export type QueryGetLeadsByCountryIdArgs = {
    countryId: Scalars['ObjectID'];
    dealerIds: Array<Scalars['ObjectID']>;
    eventIds?: Maybe<Array<Scalars['ObjectID']>>;
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
    search?: Maybe<Array<ApplicationFilteringRule>>;
};

export type QueryGetApplicationsByCountryIdArgs = {
    countryId: Scalars['ObjectID'];
    filter: ApplicationListFilterInput;
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
    search?: Maybe<Array<ApplicationFilteringRule>>;
};

export type QueryGetProceedWithCustomerDeviceTokenArgs = {
    id: Scalars['ObjectID'];
    passphrase: Scalars['String'];
    flow?: Maybe<RemoteFlow>;
};

export type QueryGetProceedWithCustomerDeviceContextArgs = {
    applicationId: Scalars['ObjectID'];
    source: Scalars['ObjectID'];
    flow?: Maybe<RemoteFlow>;
};

export type QueryGetProceedApplicationTokenArgs = {
    entry: Scalars['String'];
};

export type QueryGetEventsByCountryIdArgs = {
    countryId: Scalars['ObjectID'];
    dealerIds?: Maybe<Array<Scalars['ObjectID']>>;
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
    externalSite?: Maybe<EventExternalSite>;
    activeOnly?: Maybe<Scalars['Boolean']>;
};

export type QueryGetEventByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetTranslationsArgs = {
    language: Scalars['String'];
    countryId?: Maybe<Scalars['ObjectID']>;
};

export type QueryGetDistinctApplicationDealerIdsArgs = {
    assigneeId: Scalars['ObjectID'];
};

export type QueryGetDistinctActiveInferiorDealerIdsArgs = {
    userId: Scalars['ObjectID'];
};

export type QueryGetFinderVehicleByIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetFinderVehicleByRemoteIdArgs = {
    id: Scalars['String'];
    countryId?: Maybe<Scalars['ObjectID']>;
};

export type QueryGetFinderVehiclesByCountryIdArgs = {
    countryId: Scalars['ObjectID'];
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
};

export type QueryGetFinderVehicleBookingByReferenceIdArgs = {
    id: Scalars['ObjectID'];
};

export type QueryGetNotificationsByCurrentUserArgs = {
    paging?: Maybe<PagingPayload>;
};

export type QueryGetSubmittedApplicationPerDayArgs = {
    filter?: Maybe<StatisticFilterPayload>;
    isLead?: Maybe<Scalars['Boolean']>;
};

export type QueryGetApplicationCounterArgs = {
    filter?: Maybe<StatisticFilterPayload>;
};

export type QueryGetPopularVariantArgs = {
    filter?: Maybe<StatisticFilterPayload>;
    isLead?: Maybe<Scalars['Boolean']>;
};

export type QueryGetPopularEventArgs = {
    filter?: Maybe<StatisticFilterPayload>;
    isLead?: Maybe<Scalars['Boolean']>;
};

export type QueryGetMostApplicationSalesPersonArgs = {
    filter?: Maybe<StatisticFilterPayload>;
    isLead?: Maybe<Scalars['Boolean']>;
    sortParam?: Maybe<Scalars['String']>;
};

export type QueryGetDepositPaymentPerDayArgs = {
    filter?: Maybe<StatisticFilterPayload>;
};

export type QueryGetCsvConfigurationSetupArgs = {
    countryId: Scalars['ObjectID'];
    porscheCode: Scalars['String'];
};

export type QueryGetAppointmentsArgs = {
    filter?: Maybe<AppointmentFilterPayload>;
    sorting?: Maybe<Array<SortingPayload>>;
    paging?: Maybe<PagingPayload>;
    search?: Maybe<Scalars['String']>;
};

export type QueryGetAppointmentByVersionIdArgs = {
    versionId: Scalars['ObjectID'];
};

export type QueryGetReservedAppointmentDatesArgs = {
    filter?: Maybe<AppointmentFilterPayload>;
    exceptId?: Maybe<Scalars['ObjectID']>;
};

export type QueryGetInsurancePremiumAmountArgs = {
    insuranceCompanyId: Scalars['ObjectID'];
    data: InsurancePremiumAmountDataPayload;
};

export type QueryGetInsuranceCompanyArgs = {
    countryId: Scalars['ObjectID'];
};

export type Error = {
    field?: Maybe<Scalars['String']>;
    message: Scalars['String'];
};

export type PagingPayload = {
    offset?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type FilterVersionPayload = {
    isOutdated?: Maybe<Scalars['Boolean']>;
};

export type FilterPayload = {
    isDeleted?: Maybe<Scalars['Boolean']>;
    keys: Array<Scalars['String']>;
    text: Scalars['String'];
    version?: Maybe<FilterVersionPayload>;
};

export enum SortOrder {
    /** ASC has for value the number "1" */
    ASC = 1,
    /** DES has for value the number "-1" */
    DESC = -1,
}

export type SortingPayload = {
    type: Scalars['String'];
    order: SortOrder;
};

export type ImportExcelResponse = {
    ok: Scalars['Boolean'];
    updateCount?: Maybe<Scalars['Int']>;
    duplicateIds?: Maybe<Array<Scalars['String']>>;
};

export enum CalculatorVisibility {
    ON = 'On',
    OFF = 'Off',
    OPTIONAL = 'Optional',
}

export type Subscription = {
    /** Emit the session ID whenever there is second authentication for the same user. */
    onSecondAuthentication: Scalars['ObjectID'];
    /** Emit a maintenance update whenever it happens on a contry */
    onMaintenanceUpdate: Maintenance;
    /** Emit the session ID whenever the Finder Vehicle Booking Expires */
    onFinderVehicleBookingExpiry: Scalars['ObjectID'];
};

export type SubscriptionOnMaintenanceUpdateArgs = {
    countryId: Scalars['ObjectID'];
};

export type SubscriptionOnFinderVehicleBookingExpiryArgs = {
    bookingId: Scalars['ObjectID'];
};

export enum AdhocEventType {
    CHECK = 'Check',
    UNCHECK = 'Uncheck',
}

export type AdhocEventDetail = {
    id?: Maybe<Scalars['ObjectID']>;
};

export type AdhocEvent = {
    type: AdhocEventType;
    at: Scalars['DateTime'];
    by?: Maybe<Scalars['ObjectID']>;
    referenceId: Scalars['ObjectID'];
    detail: AdhocEventDetail;
};

export type CountryPhoneAppOption = {
    code?: Maybe<Scalars['Int']>;
    pattern?: Maybe<Scalars['String']>;
    minDigits?: Maybe<Scalars['Int']>;
    maxDigits?: Maybe<Scalars['Int']>;
};

export type CountryAppOption = {
    code: Scalars['String'];
    name: Scalars['String'];
    currency: Scalars['String'];
    phone: CountryPhoneAppOption;
};

export type CurrencyAppOtion = {
    code: Scalars['String'];
    name: Scalars['String'];
    symbol: Scalars['String'];
};

export type TimezoneAppOption = {
    name: Scalars['String'];
    abbreviation: Scalars['String'];
};

export type StatusOptionAppOption = {
    name: Scalars['String'];
    color: Scalars['String'];
    label: Scalars['String'];
};

export type DrivingLicenseClassOption = {
    value: Scalars['String'];
    label: Scalars['String'];
    purpose?: Maybe<Array<Scalars['String']>>;
};

export type DrivingLicenseValidityOption = {
    value: Scalars['String'];
    label: Scalars['String'];
    purpose?: Maybe<Array<Scalars['String']>>;
};

export type GenderOption = {
    value: Scalars['String'];
    label: Scalars['String'];
    purpose?: Maybe<Array<Scalars['String']>>;
};

export type MaritalStatusOption = {
    value: Scalars['String'];
    label: Scalars['String'];
    purpose?: Maybe<Array<Scalars['String']>>;
};

export type RaceOption = {
    value: Scalars['String'];
    label: Scalars['String'];
};

export type ResidentialStatusOption = {
    value: Scalars['String'];
    label: Scalars['String'];
    purpose?: Maybe<Array<Scalars['String']>>;
};

export type CityAppOption = {
    countryCode: Scalars['String'];
    name: Scalars['String'];
};

export type LanguageOption = {
    code: Scalars['String'];
    name: Scalars['String'];
};

export type NationalityOption = {
    code: Scalars['String'];
    name: Scalars['String'];
};

export type RelationshipOption = {
    value: Scalars['String'];
    label: Scalars['String'];
};

export type ChatbotOption = {
    value: Scalars['String'];
    label: Scalars['String'];
};

export type EducationOption = {
    value: Scalars['String'];
    label: Scalars['String'];
};

export type AddressTypeOption = {
    value: Scalars['String'];
    label: Scalars['String'];
};

export type IncomeTypeOption = {
    value: Scalars['String'];
    label: Scalars['String'];
};

export type ResidenceTypeOption = {
    value: Scalars['String'];
    label: Scalars['String'];
};

export type EmirateTypeOption = {
    code: Scalars['String'];
    name: Scalars['String'];
};

export type AddressTypeAffinOption = {
    value: Scalars['String'];
    label: Scalars['String'];
};

export type RaceAffinOption = {
    value: Scalars['String'];
    label: Scalars['String'];
};

export type ResidentialStatusAffinOption = {
    value: Scalars['String'];
    label: Scalars['String'];
    purpose?: Maybe<Array<Scalars['String']>>;
};

export type IdTypeAffinOption = {
    value: Scalars['String'];
    label: Scalars['String'];
};

export type OccupationsOption = {
    value: Scalars['String'];
    label: Scalars['String'];
};

export type AffinBankOptions = {
    races: Array<RaceAffinOption>;
    idTypes: Array<IdTypeAffinOption>;
    residentialStatuses: Array<ResidentialStatusAffinOption>;
    addressTypes: Array<AddressTypeAffinOption>;
};

export type DownpaymentToOption = {
    value: Scalars['String'];
    label: Scalars['String'];
};

export type ApplicationOptions = {
    currencies: Array<CurrencyAppOtion>;
    markets: Array<CountryAppOption>;
    countries: Array<CountryAppOption>;
    nationalities: Array<NationalityOption>;
    cities: Array<CityAppOption>;
    timezones: Array<TimezoneAppOption>;
    statusOptions: Array<StatusOptionAppOption>;
    drivingLicenseClasses: Array<DrivingLicenseClassOption>;
    drivingLicenseValidities: Array<DrivingLicenseValidityOption>;
    genders: Array<GenderOption>;
    maritalStatuses: Array<MaritalStatusOption>;
    races: Array<RaceOption>;
    residentialStatuses: Array<ResidentialStatusOption>;
    languages: Array<LanguageOption>;
    relationships: Array<RelationshipOption>;
    educations: Array<EducationOption>;
    addressTypes: Array<AddressTypeOption>;
    incomeTypes: Array<IncomeTypeOption>;
    residenceTypes: Array<ResidenceTypeOption>;
    emirates: Array<EmirateTypeOption>;
    chatbotOptions: Array<ChatbotOption>;
    affinBank: AffinBankOptions;
    occupations: Array<OccupationsOption>;
    downpaymentTo: Array<DownpaymentToOption>;
};

export type ApplicationCalculatorGenericValue = {
    amount: Scalars['Float'];
    percentage: Scalars['Float'];
};

export type ApplicationCalculatorAdjustment = {
    amount?: Maybe<Scalars['Float']>;
    label?: Maybe<Scalars['String']>;
};

export type ApplicationCalculatorCoe = {
    amount: Scalars['Float'];
};

export type ApplicationCalculatorDealerOptions = {
    amount: Scalars['Float'];
};

export type ApplicationCalculatorDealerOptionDetails = {
    amount: Scalars['Float'];
    description?: Maybe<Scalars['String']>;
};

export type ApplicationCalculatorPpsr = {
    amount: Scalars['Float'];
};

export type ApplicationCalculatorEstablishment = {
    amount: Scalars['Float'];
};

export type ApplicationCalculatorLuxuryTax = {
    amount: Scalars['Float'];
};

export type ApplicationCalculatorMonthlyInstalment = {
    amount: Scalars['Float'];
    start: Scalars['Int'];
    end: Scalars['Int'];
};

export type ApplicationCalculator = {
    price?: Maybe<Scalars['Float']>;
    downPayment?: Maybe<ApplicationCalculatorGenericValue>;
    loan?: Maybe<ApplicationCalculatorGenericValue>;
    term?: Maybe<Scalars['Int']>;
    interestRate?: Maybe<Scalars['Float']>;
    balloon?: Maybe<ApplicationCalculatorGenericValue>;
    deposit?: Maybe<ApplicationCalculatorGenericValue>;
    adjustments?: Maybe<Array<Maybe<ApplicationCalculatorAdjustment>>>;
    averageMileage?: Maybe<Scalars['Float']>;
    coe?: Maybe<ApplicationCalculatorCoe>;
    ppsr?: Maybe<ApplicationCalculatorPpsr>;
    establishment?: Maybe<ApplicationCalculatorEstablishment>;
    luxuryTax?: Maybe<ApplicationCalculatorLuxuryTax>;
    monthlyInstalments?: Maybe<Array<ApplicationCalculatorMonthlyInstalment>>;
    paymentMode?: Maybe<PaymentMode>;
    tradeIn?: Maybe<Scalars['Float']>;
    tradeInEquity?: Maybe<Scalars['Float']>;
    liability?: Maybe<Scalars['Float']>;
    residualValue?: Maybe<ApplicationCalculatorGenericValue>;
    cashPayment?: Maybe<Scalars['Float']>;
    dealerOptions?: Maybe<ApplicationCalculatorDealerOptions>;
    dealerOptionDetails?: Maybe<Array<ApplicationCalculatorDealerOptionDetails>>;
    estimatedSurplus?: Maybe<Scalars['Float']>;
    totalInterestPayable?: Maybe<Scalars['Float']>;
    licensePlateFee?: Maybe<Scalars['Float']>;
    commission?: Maybe<Scalars['Float']>;
    fixedInterestRate?: Maybe<Scalars['Float']>;
    licenseAndFuelTax?: Maybe<Scalars['Float']>;
    displacement?: Maybe<Scalars['Float']>;
    insuranceFee?: Maybe<ApplicationCalculatorGenericValue>;
    taxLoss?: Maybe<Scalars['Float']>;
    expectedTradeInAmount?: Maybe<Scalars['Float']>;
    expectedCashPayment?: Maybe<Scalars['Float']>;
    totalAmountPayable?: Maybe<Scalars['Float']>;
    nzFee?: Maybe<Scalars['Float']>;
    dealerEstablishment?: Maybe<Scalars['Float']>;
};

export type ApplicationBank = {
    id?: Maybe<Scalars['ObjectID']>;
    name?: Maybe<Scalars['IntlValue']>;
    signing?: Maybe<BankSigningSettings>;
    integration?: Maybe<BankIntegrationSetting>;
    isKYCMandatory: Scalars['Boolean'];
    isGuarantorIncluded?: Maybe<Scalars['Boolean']>;
    approvedAmount?: Maybe<Scalars['Float']>;
    referenceNumber?: Maybe<Scalars['String']>;
    remark?: Maybe<Scalars['String']>;
    submittedAt?: Maybe<Scalars['DateTime']>;
    sendCustomerConfirmationEmail?: Maybe<Scalars['Boolean']>;
    isGuaranteedBuybackEnabled?: Maybe<Scalars['Boolean']>;
};

export type ApplicationSigning = {
    provider?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    redirectionUrl?: Maybe<Scalars['String']>;
    status?: Maybe<SigningStatus>;
};

export enum CustomerInfoSource {
    MYINFO = 'MyInfo',
    MANUAL = 'Manual',
    AMEND = 'Amend',
}

export type ApplicationEventCustomerInfoDetail = {
    source?: Maybe<CustomerInfoSource>;
};

export type ApplicationEventGuarantorInfoDetail = {
    source?: Maybe<CustomerInfoSource>;
};

export type ApplicationEventApproveDetail = {
    approvedAmount?: Maybe<Scalars['Float']>;
};

export type ApplicationEventEmailDetail = {
    to?: Maybe<Scalars['String']>;
    receiver?: Maybe<Scalars['String']>;
    kind?: Maybe<Scalars['String']>;
};

export type ApplicationEventAssign = {
    from?: Maybe<Scalars['String']>;
    to?: Maybe<Scalars['String']>;
    kind?: Maybe<Scalars['String']>;
};

export type ApplicationEventDocumentUpload = {
    filename?: Maybe<Scalars['String']>;
};

export type ApplicationEventDocumentDelete = {
    filename?: Maybe<Scalars['String']>;
};

export type ApplicationEventComment = {
    comment?: Maybe<Scalars['String']>;
};

export type ConsentDeclarationEvent = {
    identifier?: Maybe<Scalars['String']>;
};

export type ApplicationEventDetail =
    | ApplicationEventCustomerInfoDetail
    | ApplicationEventGuarantorInfoDetail
    | ApplicationEventApproveDetail
    | ApplicationEventEmailDetail
    | ApplicationEventAssign
    | ApplicationEventDocumentUpload
    | ApplicationEventDocumentDelete
    | ConsentDeclarationEvent
    | ApplicationEventComment;

export enum ApplicationEventType {
    CHECK = 'Check',
    UNCHECK = 'Uncheck',
    SHARE = 'Share',
    DRAFT = 'Draft',
    SIGNING = 'Signing',
    SIGN = 'Sign',
    SIGNREJECT = 'SignReject',
    SIGNTIMEOUT = 'SignTimeout',
    UNABLETOCONNECT = 'UnableToConnect',
    CONNECTIONFAIL = 'ConnectionFail',
    DOCUMENTUPLOAD = 'DocumentUpload',
    DOCUMENTDELETE = 'DocumentDelete',
    SUBMIT = 'Submit',
    SUBMISSIONFAIL = 'SubmissionFail',
    UNABLETOSUBMIT = 'UnableToSubmit',
    PAYING = 'Paying',
    PAID = 'Paid',
    PAYMENTCAPTURING = 'PaymentCapturing',
    PAYMENTTIMEOUT = 'PaymentTimeout',
    PAYMENTFAIL = 'PaymentFail',
    UNABLETOPAY = 'UnableToPay',
    RECEIVE = 'Receive',
    APPROVE = 'Approve',
    ACTIVATE = 'Activate',
    CANCEL = 'Cancel',
    UNABLETOCANCEL = 'UnableToCancel',
    CANCELLATIONFAIL = 'CancellationFail',
    VOID = 'Void',
    COMPLETE = 'Complete',
    DECLINE = 'Decline',
    CUSTOMERINFORECEIVE = 'CustomerInfoReceive',
    GUARANTORINFORECEIVE = 'GuarantorInfoReceive',
    EMAIL = 'Email',
    ASSIGN = 'Assign',
    REFUNDED = 'Refunded',
    AGREEMENTCONCLUDED = 'AgreementConcluded',
    CONSENTDECLARATIONEVENT = 'ConsentDeclarationEvent',
    COMMENT = 'Comment',
    APPLIEDFORFINANCING = 'AppliedForFinancing',
    GUARANTEEDBUYBACKINITIALIZED = 'GuaranteedBuybackInitialized',
    GUARANTEEDBUYBACKCOMPLETED = 'GuaranteedBuybackCompleted',
    GUARANTEEDBUYBACKTIMEOUT = 'GuaranteedBuybackTimeout',
    GUARANTEEDBUYBACKREJECTED = 'GuaranteedBuybackRejected',
}

export enum ApplicationActivationStatus {
    INITIALIZED = 'Initialized',
    SUBMITTED = 'Submitted',
    FAILED = 'Failed',
    RECEIVED = 'Received',
    ACTIVATED = 'Activated',
}

export enum ApplicationEventSource {
    SYSTEM = 'System',
    USER = 'User',
    BANK = 'Bank',
    CUSTOMER = 'Customer',
    GUARANTOR = 'Guarantor',
    SIGNINGPROVIDER = 'SigningProvider',
    PAYMENTPROVIDER = 'PaymentProvider',
    INSURANCE = 'Insurance',
}

export type ApplicationEvent = {
    type: ApplicationEventType;
    at: Scalars['DateTime'];
    comment?: Maybe<Scalars['String']>;
    by?: Maybe<User>;
    source?: Maybe<ApplicationEventSource>;
    detail?: Maybe<ApplicationEventDetail>;
    instanceId: Scalars['ObjectID'];
};

export enum ApplicationStatus {
    HIDDENDRAFT = 'HiddenDraft',
    DRAFT = 'Draft',
    LEAD = 'Lead',
    SHARED = 'Shared',
    LEADRECEIVED = 'LeadReceived',
    PENDINGCUSTOMERCONFIRMATION = 'PendingCustomerConfirmation',
    PENDINGGUARANTORCONFIRMATION = 'PendingGuarantorConfirmation',
    SUBMITTED = 'Submitted',
    SIGNED = 'Signed',
    SIGNING = 'Signing',
    SIGNINGREJECTED = 'SigningRejected',
    SIGNINGTIMEOUT = 'SigningTimeout',
    UNABLETOCONNECT = 'UnableToConnect',
    CONNECTIONFAILED = 'ConnectionFailed',
    UNABLETOSUBMIT = 'UnableToSubmit',
    SUBMISSIONFAILED = 'SubmissionFailed',
    PAYING = 'Paying',
    PAID = 'Paid',
    PAYMENTTIMEOUT = 'PaymentTimeout',
    UNABLETOPAY = 'UnableToPay',
    PAYMENTFAILED = 'PaymentFailed',
    APPROVED = 'Approved',
    RECEIVED = 'Received',
    CANCELLED = 'Cancelled',
    UNABLETOCANCEL = 'UnableToCancel',
    CANCELLATIONFAILED = 'CancellationFailed',
    DECLINED = 'Declined',
    COMPLETED = 'Completed',
    CUSTOMERINFORECEIVED = 'CustomerInfoReceived',
    PENDINGOTP = 'PendingOTP',
    AGREEMENTCONCLUDED = 'AgreementConcluded',
    GUARANTEEDBUYBACKINITIALIZED = 'GuaranteedBuybackInitialized',
    GUARANTEEDBUYBACKTIMEOUT = 'GuaranteedBuybackTimeout',
    GUARANTEEDBUYBACKREJECTED = 'GuaranteedBuybackRejected',
    GUARANTEEDBUYBACKCOMPLETED = 'GuaranteedBuybackCompleted',
}

export type FinanceContext = {
    valid?: Maybe<Scalars['Boolean']>;
    zoneId: Scalars['ObjectID'];
    zone: Zone;
    financeProductId: Scalars['ObjectID'];
    financeProduct: FinanceProduct;
    variantId: Scalars['ObjectID'];
    variant: Variant;
    optionIds: Array<Scalars['ObjectID']>;
    options: Array<Option>;
    calculator: ApplicationCalculator;
};

export enum ApplicationStages {
    PAYMENT = 'PAYMENT',
    LEAD = 'LEAD',
    FINANCE = 'FINANCE',
    INSURANCE = 'INSURANCE',
    ACTIVATION = 'ACTIVATION',
    COMPLETED = 'COMPLETED',
}

export enum StageType {
    DECLINED = 'DECLINED',
    PRESENT = 'PRESENT',
    FINISHED = 'FINISHED',
}

export type Stages = {
    name: ApplicationStages;
    label?: Maybe<Scalars['String']>;
    stage?: Maybe<StageType>;
    extra: Array<Scalars['String']>;
    date?: Maybe<Scalars['DateTime']>;
};

export type StepsCondition = {
    consentsAndDeclarations?: Maybe<Scalars['Boolean']>;
    kyc?: Maybe<Scalars['Boolean']>;
    payment?: Maybe<Scalars['Boolean']>;
    appointment?: Maybe<Scalars['Boolean']>;
    approval?: Maybe<Scalars['Boolean']>;
    guarantor?: Maybe<Scalars['Boolean']>;
    guarantorConfirmation?: Maybe<Scalars['Boolean']>;
    guarantorConsents?: Maybe<Scalars['Boolean']>;
    submission?: Maybe<Scalars['Boolean']>;
    reSubmission?: Maybe<Scalars['Boolean']>;
    reservationToFinance?: Maybe<Scalars['Boolean']>;
    guaranteedBuyback?: Maybe<Scalars['Boolean']>;
};

export type ApplicationStatusText = {
    /** Application status label */
    label: Scalars['String'];
    /** Application status color */
    color: Scalars['String'];
    /** Application status full text */
    text: Scalars['String'];
    /** Application status extra text */
    extra?: Maybe<Scalars['String']>;
};

export type ApplicationStatusTextLabelArgs = {
    locale?: Maybe<Scalars['String']>;
};

export type ApplicationStatusTextTextArgs = {
    locale?: Maybe<Scalars['String']>;
};

export enum ApplicationAccess {
    PUBLIC = 'Public',
    PRIVATE = 'Private',
}

export type GuaranteedBuybackSigningInfo = {
    provider?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
};

export type GuaranteedBuybackSignerInfo = {
    id?: Maybe<Scalars['ObjectID']>;
    redirectionUrl?: Maybe<Scalars['String']>;
    status?: Maybe<SigningStatus>;
};

export type ApplicationGuaranteedBuybackSigners = {
    signing?: Maybe<GuaranteedBuybackSigningInfo>;
    dealer?: Maybe<GuaranteedBuybackSignerInfo>;
    customer?: Maybe<GuaranteedBuybackSignerInfo>;
};

export type Application = {
    id: Scalars['ObjectID'];
    dealerId: Scalars['ObjectID'];
    isLead: Scalars['Boolean'];
    zoneId: Scalars['ObjectID'];
    zone: Zone;
    financeProductId?: Maybe<Scalars['ObjectID']>;
    financeProduct?: Maybe<FinanceProduct>;
    customerId: Scalars['ObjectID'];
    customer: Customer;
    eventId?: Maybe<Scalars['ObjectID']>;
    event?: Maybe<Event>;
    guarantor?: Maybe<Customer>;
    variantId: Scalars['ObjectID'];
    variant: Variant;
    optionIds: Array<Scalars['ObjectID']>;
    options: Array<Option>;
    assigneeId?: Maybe<Scalars['ObjectID']>;
    assignee?: Maybe<User>;
    promoCodeId?: Maybe<Scalars['ObjectID']>;
    promoCode?: Maybe<PromoCode>;
    calculator: ApplicationCalculator;
    status: ApplicationStatus;
    statusText: ApplicationStatusText;
    activationStatus: ApplicationActivationStatus;
    identifier?: Maybe<Scalars['String']>;
    remark?: Maybe<Scalars['String']>;
    chassisNumber?: Maybe<Scalars['String']>;
    signing?: Maybe<ApplicationSigning>;
    appliedForFinancing: Scalars['Boolean'];
    proceedWithCustomerDevice: Scalars['Boolean'];
    withGuarantor: Scalars['Boolean'];
    bank?: Maybe<ApplicationBank>;
    version: Version;
    snapshots: Array<Application>;
    events: Array<ApplicationEvent>;
    attachments: Array<Attachment>;
    stages: Array<Stages>;
    channel: Channel;
    access: ApplicationAccess;
    steps?: Maybe<StepsCondition>;
    checkedConsentOrDeclarationIds: Array<Scalars['ObjectID']>;
    booking?: Maybe<Booking>;
    tradeIn?: Maybe<Vehicle>;
    hasTestDrive?: Maybe<Scalars['Boolean']>;
    hasTradeIn?: Maybe<Scalars['Boolean']>;
    reservationDeposit?: Maybe<Scalars['Float']>;
    assetCondition?: Maybe<AssetCondition>;
    payment?: Maybe<Payment>;
    miniConfiguratorDetails?: Maybe<MiniConfiguratorDetails>;
    finderVehicleId?: Maybe<Scalars['ObjectID']>;
    dealer?: Maybe<Dealer>;
    finderVehicle?: Maybe<FinderVehicle>;
    isCalculatorEnabled?: Maybe<Scalars['Boolean']>;
    phase?: Maybe<ApplicationPhase>;
    insuranceApplication?: Maybe<InsuranceApplication>;
    csvConfigurator?: Maybe<CsvConfigurator>;
    appointment?: Maybe<Appointment>;
    reservation?: Maybe<Application>;
    financeApplication?: Maybe<Application>;
    lead?: Maybe<Application>;
    guaranteedBuybackSigners?: Maybe<ApplicationGuaranteedBuybackSigners>;
};

export type ApplicationStagesArgs = {
    locale?: Maybe<Scalars['String']>;
};

export type ApplicationCheckedConsentOrDeclarationIdsArgs = {
    eventId?: Maybe<Scalars['ObjectID']>;
};

export type ApplicationList = {
    items: Array<Application>;
    count?: Maybe<Scalars['Int']>;
};

export type ProceedWithCustomerDeviceContext = {
    expired: Scalars['Boolean'];
    submitted: Scalars['Boolean'];
    instanceId?: Maybe<Scalars['ObjectID']>;
    bankName?: Maybe<Scalars['IntlValue']>;
    channel?: Maybe<Channel>;
    remoteFlowAcknowledgmentInfo?: Maybe<Scalars['IntlValue']>;
    dealerId?: Maybe<Scalars['ObjectID']>;
    assigneeName?: Maybe<Scalars['String']>;
    assigneePhone?: Maybe<Scalars['String']>;
    insuranceCompanyName?: Maybe<Scalars['IntlValue']>;
};

export enum SigningStatus {
    COMPLETED = 'Completed',
    REJECTED = 'Rejected',
    INPROGRESS = 'InProgress',
    TIMEOUT = 'Timeout',
}

export type ProceedApplicationContext = {
    application: Application;
    token: Scalars['String'];
};

export type MiniConfiguratorDetail = {
    price: Scalars['Float'];
    label: Scalars['String'];
};

export type MiniConfiguratorDetails = {
    exteriorColor: Scalars['String'];
    interiorColor: Scalars['String'];
    enhancedPackage?: Maybe<EnhancedPackage>;
    delivery: MiniConfiguratorDetail;
    tequipment?: Maybe<TEquipment>;
    timepiece?: Maybe<Timepiece>;
};

export type EnhancedPackage = {
    price: Scalars['Float'];
    label: Scalars['String'];
    list: Array<Scalars['String']>;
};

export type TEquipment = {
    luggageNet?: Maybe<MiniConfiguratorDetail>;
    luggageCompartment?: Maybe<MiniConfiguratorDetail>;
    floorMats?: Maybe<MiniConfiguratorDetail>;
    carCareCase?: Maybe<MiniConfiguratorDetail>;
    bicycleRack?: Maybe<MiniConfiguratorDetail>;
};

export type Timepiece = {
    price: Scalars['Float'];
    hands: Scalars['String'];
    colouredRing: Scalars['String'];
    engraving?: Maybe<Scalars['String']>;
};

export type GuaranteedBuybackStatus = {
    status: SigningStatus;
    newRedirectionUrl?: Maybe<Scalars['String']>;
};

export enum GuaranteedBuybackSigner {
    DEALER = 'Dealer',
    CUSTOMER = 'Customer',
}

export enum RemoteFlow {
    CUSTOMER = 'Customer',
    GUARANTOR = 'Guarantor',
    INSURANCE = 'Insurance',
}

export enum ApplicationPhase {
    FINANCE = 'Finance',
    RESERVATION = 'Reservation',
    LEAD = 'Lead',
}

export enum AppointmentLogSource {
    SYSTEM = 'System',
    USER = 'User',
}

export enum AppointmentLogType {
    CREATED = 'Created',
    ASSIGNED = 'Assigned',
    STATUSCHANGED = 'StatusChanged',
    DATECHANGED = 'DateChanged',
    ATTACHMENTUPLOADED = 'AttachmentUploaded',
    ATTACHMENTDELETED = 'AttachmentDeleted',
}

export type AppointmentLogCreated = {
    id: Scalars['ObjectID'];
    createdAt: Scalars['DateTime'];
    createdById?: Maybe<Scalars['ObjectID']>;
    createdBy?: Maybe<User>;
    source: AppointmentLogSource;
    appointmentVersionId: Scalars['ObjectID'];
    latestAppointment: Appointment;
    newAssigneeName: Scalars['String'];
};

export type AppointmentLogAssigned = {
    id: Scalars['ObjectID'];
    createdAt: Scalars['DateTime'];
    createdById?: Maybe<Scalars['ObjectID']>;
    createdBy?: Maybe<User>;
    source: AppointmentLogSource;
    appointmentVersionId: Scalars['ObjectID'];
    latestAppointment: Appointment;
    previousAssigneeName: Scalars['String'];
    newAssigneeName: Scalars['String'];
};

export type AppointmentLogStatusChanged = {
    id: Scalars['ObjectID'];
    createdAt: Scalars['DateTime'];
    createdById?: Maybe<Scalars['ObjectID']>;
    createdBy?: Maybe<User>;
    source: AppointmentLogSource;
    appointmentVersionId: Scalars['ObjectID'];
    latestAppointment: Appointment;
    previousStatus: AppointmentStatus;
    newStatus: AppointmentStatus;
};

export type AppointmentLogDateChanged = {
    id: Scalars['ObjectID'];
    createdAt: Scalars['DateTime'];
    createdById?: Maybe<Scalars['ObjectID']>;
    createdBy?: Maybe<User>;
    source: AppointmentLogSource;
    appointmentVersionId: Scalars['ObjectID'];
    latestAppointment: Appointment;
    previousDate: Scalars['DateTime'];
    newDate: Scalars['DateTime'];
};

export type AppointmentLogAttachmentUploaded = {
    id: Scalars['ObjectID'];
    createdAt: Scalars['DateTime'];
    createdById?: Maybe<Scalars['ObjectID']>;
    createdBy?: Maybe<User>;
    source: AppointmentLogSource;
    appointmentVersionId: Scalars['ObjectID'];
    latestAppointment: Appointment;
    fileName: Scalars['String'];
};

export type AppointmentLogAttachmentDeleted = {
    id: Scalars['ObjectID'];
    createdAt: Scalars['DateTime'];
    createdById?: Maybe<Scalars['ObjectID']>;
    createdBy?: Maybe<User>;
    source: AppointmentLogSource;
    appointmentVersionId: Scalars['ObjectID'];
    latestAppointment: Appointment;
    fileName: Scalars['String'];
};

export type AppointmentLog =
    | AppointmentLogCreated
    | AppointmentLogAssigned
    | AppointmentLogStatusChanged
    | AppointmentLogDateChanged
    | AppointmentLogAttachmentUploaded
    | AppointmentLogAttachmentDeleted;

export enum AppointmentPurpose {
    TESTDRIVE = 'TestDrive',
}

export enum AppointmentStatus {
    NEW = 'New',
    CONTACTED = 'Contacted',
    CHECKIN = 'CheckIn',
    COMPLETED = 'Completed',
    VOID = 'Void',
}

export type Appointment = {
    id: Scalars['ObjectID'];
    version: Version;
    identifier: Scalars['String'];
    purpose: AppointmentPurpose;
    date: Scalars['DateTime'];
    status: AppointmentStatus;
    assigneeId: Scalars['ObjectID'];
    assignee: User;
    applicationVersionId: Scalars['ObjectID'];
    latestApplication: Application;
    activityLogs: Array<AppointmentLog>;
    attachments: Array<Attachment>;
};

export type AppointmentList = {
    items: Array<Appointment>;
    count?: Maybe<Scalars['Int']>;
};

export type ReservedAppointmentDates = {
    date: Scalars['String'];
    times: Array<Scalars['String']>;
};

export enum AdhocAttachmentPurpose {
    VARIANTIMAGE = 'VariantImage',
    INVENTORYUNITIMAGE = 'InventoryUnitImage',
}

export type AttachmentMetaData = {
    filename: Scalars['String'];
    contentType: Scalars['String'];
};

export type Attachment = {
    id: Scalars['ObjectID'];
    url?: Maybe<Scalars['String']>;
    allowPublicAccess: Scalars['Boolean'];
    metaData: AttachmentMetaData;
    purpose?: Maybe<Scalars['String']>;
    at: Scalars['DateTime'];
    byId: Scalars['ObjectID'];
    by?: Maybe<User>;
};

export enum UploadPurpose {
    NRIC_FRONT = 'NRIC_FRONT',
    NRIC_BACK = 'NRIC_BACK',
    NRIC_NAMED_CARD = 'NRIC_NAMED_CARD',
    CUSTOMER_ATTACHED = 'CUSTOMER_ATTACHED',
    CUSTOMER_IDENTITY = 'CUSTOMER_IDENTITY',
    CUSTOMER_AGREEMENT = 'CUSTOMER_AGREEMENT',
    VEHICLE_LOG_CARD = 'VEHICLE_LOG_CARD',
    GUARANTOR_ATTACHED = 'GUARANTOR_ATTACHED',
    GUARANTOR_IDENTITY = 'GUARANTOR_IDENTITY',
    VSO_UPLOAD = 'VSO_UPLOAD',
    APPOINTMENT_UPLOAD = 'APPOINTMENT_UPLOAD',
    ENBD_QUOTATION_RIGHT = 'ENBD_QUOTATION_RIGHT',
    ENBD_QUOTATION_BOTTOM = 'ENBD_QUOTATION_BOTTOM',
    GUARANTEED_BUYBACK = 'GUARANTEED_BUYBACK',
}

export type UploadAttachment = {
    uploadId: Scalars['ObjectID'];
    attachment: Attachment;
};

export enum BankIntegrationProvider {
    HLF = 'HLF',
}

export enum HlfBankIntegrationApiVersion {
    V1 = 'V1',
    V2 = 'V2',
}

export type HlfBankIntegrationSetting = {
    provider: BankIntegrationProvider;
    apiVersion: HlfBankIntegrationApiVersion;
    v1DealerCode: Scalars['String'];
    v2DealerCode: Scalars['String'];
    base: Scalars['String'];
    submissionPartial: Scalars['String'];
    editionPartial: Scalars['String'];
    cancellationPartial: Scalars['String'];
};

export type BankIntegrationSetting = HlfBankIntegrationSetting;

export type MyInfoIntegrationEndpoints = {
    authorize: Scalars['String'];
};

export type MyInfoIntegrationAttributes = {
    base: Array<Scalars['String']>;
    vehicle: Array<Scalars['String']>;
    drivingLicense: Array<Scalars['String']>;
    additional: Array<Scalars['String']>;
};

export type MyInfoIntegration = {
    clientId: Scalars['String'];
    clientSecret: Scalars['String'];
    privateKey: Scalars['String'];
    privateKeyPassphrase?: Maybe<Scalars['String']>;
    publicCert: Scalars['String'];
    purpose: Scalars['String'];
    requestUrl: Scalars['String'];
    redirectUri: Scalars['String'];
    endpoints: MyInfoIntegrationEndpoints;
    attributes: MyInfoIntegrationAttributes;
};

export type MyInfoIntegrationAttributesArgs = {
    channel?: Maybe<Channel>;
};

export enum BankSigningMode {
    NONE = 'None',
    OTP = 'OTP',
    NAMIRIAL = 'Namirial',
}

export type BankSigningSettings = {
    onCreate: BankSigningMode;
    onUpdate: BankSigningMode;
};

export enum BankPresetOption {
    NONE = 'None',
    AFFINBANK = 'AffinBank',
    ENBDBANK = 'ENBDBank',
    PMESA = 'PMESA',
    TTBBANK = 'TTBBank',
}

export type BankStamps = {
    enbdQuotationRight?: Maybe<Attachment>;
    enbdQuotationBottom?: Maybe<Attachment>;
};

export type Bank = {
    id: Scalars['ObjectID'];
    countryId: Scalars['ObjectID'];
    country: Country;
    zoneIds: Array<Scalars['ObjectID']>;
    zones: Array<Zone>;
    dealerIds: Array<Scalars['ObjectID']>;
    disabledDealerIds: Array<Scalars['ObjectID']>;
    dealers: Array<Dealer>;
    name: Scalars['IntlValue'];
    email: Scalars['String'];
    color: Scalars['String'];
    order: Scalars['Int'];
    signing: BankSigningSettings;
    hasIntegration: Scalars['Boolean'];
    integration?: Maybe<BankIntegrationSetting>;
    isKYCMandatory: Scalars['Boolean'];
    isGuarantorIncluded: Scalars['Boolean'];
    hasVSOUpload: Scalars['Boolean'];
    hasMyInfoIntegration: Scalars['Boolean'];
    myInfo?: Maybe<MyInfoIntegration>;
    financeProducts: Array<FinanceProduct>;
    hasConsents: Scalars['Boolean'];
    consentsAndDeclarations: Array<ConsentOrDeclaration>;
    logo?: Maybe<Attachment>;
    font?: Maybe<Attachment>;
    remoteFlowAcknowledgmentInfo: Scalars['IntlValue'];
    presetOption: BankPresetOption;
    isActive: Scalars['Boolean'];
    isDeleted: Scalars['Boolean'];
    version: SimpleVersion;
    sendCustomerConfirmationEmail: Scalars['Boolean'];
    establishment?: Maybe<Scalars['Float']>;
    stamps?: Maybe<BankStamps>;
    isGuaranteedBuybackEnabled: Scalars['Boolean'];
};

export type BankList = {
    items: Array<Bank>;
    count?: Maybe<Scalars['Int']>;
};

export type ChatReply = {
    text: Scalars['String'];
    quickReplies: Array<Scalars['String']>;
};

export type ChatSession = {
    sessionId: Scalars['String'];
    reply: ChatReply;
};

export type Company = {
    id: Scalars['ObjectID'];
    code: Scalars['String'];
    name: Scalars['IntlValue'];
    email: Scalars['String'];
    color: Scalars['String'];
    copyright: Scalars['IntlValue'];
    description?: Maybe<Scalars['IntlValue']>;
    isActive: Scalars['Boolean'];
    isDeleted: Scalars['Boolean'];
    version: SimpleVersion;
    countries: Array<Country>;
    font?: Maybe<Attachment>;
    logo?: Maybe<Attachment>;
    favIcon?: Maybe<Attachment>;
};

export type CompanyList = {
    items: Array<Company>;
    count?: Maybe<Scalars['Int']>;
};

export enum ConsentOrDeclarationOwnerType {
    COUNTRY = 'Country',
    BANK = 'Bank',
    EVENT = 'Event',
    PAYMENTTERMS = 'PaymentTerms',
    ALLEVENTS = 'AllEvents',
    NEWCARCHANNEL = 'NewCarChannel',
    PREOWNEDCARCHANNEL = 'PreownedCarChannel',
    INSURANCE = 'Insurance',
}

export enum ConsentOrDeclarationDataFieldName {
    NONE = 'None',
    DATAPROCESSING = 'DataProcessing',
    MAIL = 'Mail',
    PHONE = 'Phone',
    EMAIL = 'Email',
    FAX = 'Fax',
}

export type ConsentOrDeclarationOwner = {
    type: ConsentOrDeclarationOwnerType;
    id: Scalars['ObjectID'];
    name?: Maybe<Scalars['String']>;
};

export type ConsentOrDeclaration = {
    id: Scalars['ObjectID'];
    owner: ConsentOrDeclarationOwner;
    dealerIds: Array<Scalars['ObjectID']>;
    name?: Maybe<Scalars['IntlValue']>;
    description?: Maybe<Scalars['IntlValue']>;
    order: Scalars['Int'];
    hasCheckbox: Scalars['Boolean'];
    isMandatory: Scalars['Boolean'];
    legalMarkup?: Maybe<Scalars['IntlValue']>;
    dataFieldName?: Maybe<ConsentOrDeclarationDataFieldName>;
    isActive: Scalars['Boolean'];
    isDeleted: Scalars['Boolean'];
    version: SimpleVersion;
    identifier: Scalars['String'];
};

export type ConsentOrDeclarationList = {
    items: Array<ConsentOrDeclaration>;
    count?: Maybe<Scalars['Int']>;
};

export type Country = {
    id: Scalars['ObjectID'];
    companyId: Scalars['ObjectID'];
    company: Company;
    code: Scalars['String'];
    name: Scalars['String'];
    mlcName?: Maybe<Scalars['IntlValue']>;
    languages: Array<Scalars['String']>;
    currency: Scalars['String'];
    mask?: Maybe<Mask>;
    rounding: Rounding;
    email: EmailProvider;
    matchExistingCustomer: Scalars['Boolean'];
    sendSalesPersonConfirmationEmail: Scalars['Boolean'];
    googleTagManager?: Maybe<GoogleTagManager>;
    useExternalCalculator: Scalars['Boolean'];
    usePorscheFinder: Scalars['Boolean'];
    useIccCheckout: Scalars['Boolean'];
    useAutoplay: Scalars['Boolean'];
    isDeleted: Scalars['Boolean'];
    version: SimpleVersion;
    zones: Array<Zone>;
    dealers: Array<Dealer>;
    banks: Array<Bank>;
    phoneSettings?: Maybe<CountryPhoneAppOption>;
    maintenance?: Maybe<Maintenance>;
    consentsAndDeclarations: Array<ConsentOrDeclaration>;
    coe?: Maybe<Coe>;
    ppsr?: Maybe<Ppsr>;
    establishment?: Maybe<Establishment>;
    luxuryTax?: Maybe<LuxuryTax>;
    channelSetting: ChannelSetting;
    events: Array<Event>;
    externalLinks?: Maybe<CountryExternalLinks>;
    /** Porsche payment assortment is mandatory or not, true when it is used in any channel */
    isPorschePaymentAssortmentMandatory: Scalars['Boolean'];
    /** Session Timeout in minutes */
    sessionTimeout: Scalars['Int'];
    insurances: Array<Insurance>;
};

export type CountryList = {
    items: Array<Country>;
    count?: Maybe<Scalars['Int']>;
};

export enum MaskDirection {
    BACK = 'BACK',
    FRONT = 'FRONT',
    NONE = 'NONE',
}

export type Mask = {
    direction: MaskDirection;
    count: Scalars['Int'];
};

export type RoundingDetail = {
    mode?: Maybe<RoundingMode>;
    count: Scalars['Int'];
};

export type Rounding = {
    amount: RoundingDetail;
    percentage: RoundingDetail;
};

export enum Provider {
    /** SYSTSEM has for value the string "systemSMTP" */
    SYSTEM = 'SYSTEM',
    /** SMTP has for value the string "customerSMTP" */
    SMTP = 'SMTP',
}

export type EmailProvider = {
    provider: Provider;
    host?: Maybe<Scalars['String']>;
    port?: Maybe<Scalars['Int']>;
    user?: Maybe<Scalars['String']>;
    fromEmail?: Maybe<Scalars['String']>;
    isSslEnabled?: Maybe<Scalars['Boolean']>;
    isAuthenticationRequired?: Maybe<Scalars['Boolean']>;
};

export type Coe = {
    amount: Scalars['Float'];
};

export type Ppsr = {
    amount: Scalars['Float'];
    editable: Scalars['Boolean'];
};

export type Establishment = {
    amount: Scalars['Float'];
    editable: Scalars['Boolean'];
};

export type LuxuryTaxThreshold = {
    petrol?: Maybe<Scalars['Float']>;
    electric?: Maybe<Scalars['Float']>;
    hybrid?: Maybe<Scalars['Float']>;
};

export type LuxuryTax = {
    amount: Scalars['Float'];
    threshold: LuxuryTaxThreshold;
};

export type GoogleTagManager = {
    id?: Maybe<Scalars['String']>;
};

export enum RoundingMode {
    ROUNDINGUP = 'RoundingUp',
    ROUNDINGDOWN = 'RoundingDown',
    ROUNDING = 'Rounding',
    NONE = 'None',
}

export type Maintenance = {
    start: Scalars['DateTime'];
    startTimeZone: Scalars['String'];
    end: Scalars['DateTime'];
    endTimeZone: Scalars['String'];
    warningBefore: Scalars['Int'];
    title: Scalars['IntlValue'];
    description?: Maybe<Scalars['IntlValue']>;
    isActive: Scalars['Boolean'];
    image?: Maybe<Attachment>;
};

export type ChannelSetting = {
    new: NewChannel;
    used: UsedChannel;
    express: ExpressChannel;
    event: EventChannel;
};

export type NewChannel = {
    isActive: Scalars['Boolean'];
    allowPublicAccess: Scalars['Boolean'];
    allowPrivateAccess: Scalars['Boolean'];
    isChatbotEnabled: Scalars['Boolean'];
    chatbot?: Maybe<Chatbot>;
    filterByMonthlyInstalment: Scalars['Boolean'];
    allowSharing: Scalars['Boolean'];
    allowOptions: Scalars['Boolean'];
    isCoeEnabled: Scalars['Boolean'];
    isCoeEditable: Scalars['Boolean'];
    isDealerOptionsEnabled: Scalars['Boolean'];
    isPpsrAndEstablishmentEnabled: Scalars['Boolean'];
    isPromoCodeEnabled: Scalars['Boolean'];
    alwaysShowPromoCode: Scalars['Boolean'];
    allowReverseCalculator: Scalars['Boolean'];
    allowFinanceApplication: Scalars['Boolean'];
    isFinanceApplicationMandatory: Scalars['Boolean'];
    allowProceedWithCustomerDevice: Scalars['Boolean'];
    bookingPayment?: Maybe<BookingPayment>;
    isDepositPaymentMandatory: Scalars['Boolean'];
    priceDisclaimer?: Maybe<Scalars['IntlValue']>;
    insurancePriceDisclaimer?: Maybe<Scalars['IntlValue']>;
    isInsuranceEnabled: Scalars['Boolean'];
    allowApplyInsuranceOnFinance: Scalars['Boolean'];
    isLuxuryTaxEnabled: Scalars['Boolean'];
};

export type UsedChannel = {
    isActive: Scalars['Boolean'];
    allowPublicAccess: Scalars['Boolean'];
    allowPrivateAccess: Scalars['Boolean'];
    isChatbotEnabled: Scalars['Boolean'];
    chatbot?: Maybe<Chatbot>;
    filterByMonthlyInstalment: Scalars['Boolean'];
    allowSharing: Scalars['Boolean'];
    isPromoCodeEnabled: Scalars['Boolean'];
    alwaysShowPromoCode: Scalars['Boolean'];
    allowReverseCalculator: Scalars['Boolean'];
    allowTradeIn: Scalars['Boolean'];
    allowTestDrive: Scalars['Boolean'];
    allowFinanceApplication: Scalars['Boolean'];
    isFinanceApplicationMandatory: Scalars['Boolean'];
    allowProceedWithCustomerDevice: Scalars['Boolean'];
    bookingPayment?: Maybe<BookingPayment>;
    isDepositPaymentMandatory: Scalars['Boolean'];
    isInsuranceEnabled?: Maybe<Scalars['Boolean']>;
};

export type ExpressChannel = {
    isActive: Scalars['Boolean'];
    isCoeEnabled: Scalars['Boolean'];
    isCoeEditable: Scalars['Boolean'];
    isDealerOptionsEnabled: Scalars['Boolean'];
    isPpsrAndEstablishmentEnabled: Scalars['Boolean'];
    isPromoCodeEnabled: Scalars['Boolean'];
    alwaysShowPromoCode: Scalars['Boolean'];
    allowReverseCalculator: Scalars['Boolean'];
    allowFinanceApplication: Scalars['Boolean'];
    isFinanceApplicationMandatory: Scalars['Boolean'];
    allowProceedWithCustomerDevice: Scalars['Boolean'];
    bookingPayment?: Maybe<BookingPayment>;
    isDepositPaymentMandatory: Scalars['Boolean'];
    priceDisclaimer?: Maybe<Scalars['IntlValue']>;
    insurancePriceDisclaimer?: Maybe<Scalars['IntlValue']>;
    isInsuranceEnabled: Scalars['Boolean'];
    allowApplyInsuranceOnFinance: Scalars['Boolean'];
    isLuxuryTaxEnabled: Scalars['Boolean'];
};

export type EventChannel = {
    isActive: Scalars['Boolean'];
    alwaysShowPromoCode: Scalars['Boolean'];
    hasMyInfoIntegration: Scalars['Boolean'];
    myInfo?: Maybe<MyInfoIntegration>;
    bookingPayment?: Maybe<BookingPayment>;
    isDepositPaymentMandatory: Scalars['Boolean'];
    isChatbotEnabled: Scalars['Boolean'];
    hasEventWithPayment: Scalars['Boolean'];
    hasEventWithChatbot: Scalars['Boolean'];
    hasEventWithPromoCode: Scalars['Boolean'];
    chatbot?: Maybe<Chatbot>;
    isPromoCodeEnabled: Scalars['Boolean'];
};

export type BookingPayment = {
    provider: PaymentProvider;
    amount: Scalars['Float'];
};

export enum ChatbotType {
    DIALOGFLOW = 'DIALOGFLOW',
    USERLIKE = 'USERLIKE',
    HERO = 'HERO',
}

export type Chatbot = {
    type: ChatbotType;
    credentials: Scalars['String'];
};

export enum PaymentProviderType {
    ADYEN = 'Adyen',
    PORSCHE = 'Porsche',
    PAYGATE = 'PayGate',
    TTB = 'TTB',
}

export type PaymentProvider = {
    type: PaymentProviderType;
};

export type AdyenPaymentProviderSettings = {
    merchantAccount: Scalars['String'];
    apiKey: Scalars['String'];
    clientKey: Scalars['String'];
    originKey: Scalars['String'];
};

export type AdyenPaymentProvider = PaymentProvider & {
    type: PaymentProviderType;
    settings: AdyenPaymentProviderSettings;
};

export type PorschePaymentProvider = PaymentProvider & {
    type: PaymentProviderType;
};

export type PayGatePaymentProviderSettings = {
    apiKey: Scalars['String'];
    encryptionKey: Scalars['String'];
};

export type PayGatePaymentProvider = PaymentProvider & {
    type: PaymentProviderType;
    settings: PayGatePaymentProviderSettings;
};

export type TtbPaymentProviderSettings = {
    /** TTB API Key */
    apiKey: Scalars['String'];
    /** TTB Office Id */
    officeId: Scalars['String'];
    /** AFC Signing Private Key */
    signingPrivateKey: Scalars['String'];
    /** AFC Encryption Private Key for Decryption */
    encryptionPrivateKey: Scalars['String'];
    /** TTB Public Key for Encryption */
    encryptionPublicKey: Scalars['String'];
    /** TTB Signing Public Key for Verification */
    signingPublicKey: Scalars['String'];
    /** KID */
    kid: Scalars['String'];
};

export type TtbPaymentProvider = PaymentProvider & {
    type: PaymentProviderType;
    settings: TtbPaymentProviderSettings;
};

export type CountryExternalLinks = {
    legalNotice?: Maybe<Scalars['String']>;
    privacyPolicy?: Maybe<Scalars['String']>;
    copyright?: Maybe<Scalars['String']>;
    facebook?: Maybe<Scalars['String']>;
    youtube?: Maybe<Scalars['String']>;
    instagram?: Maybe<Scalars['String']>;
    website?: Maybe<Scalars['String']>;
};

export type CsvConfiguratorModel = {
    year: Scalars['String'];
    name: Scalars['String'];
};

export type CsvConfiguratorVariant = {
    name: Scalars['String'];
    price: Scalars['Float'];
    identifier: Scalars['String'];
    imageUrl?: Maybe<Scalars['String']>;
};

export type CsvConfiguratorOption = {
    colour?: Maybe<Scalars['String']>;
    identifier: Scalars['String'];
    price: Scalars['Float'];
    translatedName: Scalars['String'];
    thumbnailUrl?: Maybe<Scalars['String']>;
};

export type CsvConfigurator = {
    porscheCode: Scalars['String'];
    model: CsvConfiguratorModel;
    variant: CsvConfiguratorVariant;
    options: Array<Maybe<CsvConfiguratorOption>>;
    totalPrice: Scalars['Float'];
    url: Scalars['String'];
};

export type CsvConfiguratorSetup = {
    csvConfigurator: CsvConfigurator;
    event: Event;
    dealers?: Maybe<Array<Dealer>>;
    variant: Variant;
};

export enum CustomerType {
    INDIVIDUAL = 'INDIVIDUAL',
    COMPANY = 'COMPANY',
}

export enum MainIncomeType {
    BUSINESS_OWNER = 'BUSINESS_OWNER',
    SALARIED_NON_COMMISSION = 'SALARIED_NON_COMMISSION',
    SALARIED_COMMISSION = 'SALARIED_COMMISSION',
    SELF_EMPLOYED = 'SELF_EMPLOYED',
    NO_INCOME = 'NO_INCOME',
}

export enum SubMainIncomeType {
    SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
    PARTNER = 'PARTNER',
    SHAREHOLDER = 'SHAREHOLDER',
    SME_EMPLOYEE = 'SME_EMPLOYEE',
    PUBLIC_LISTED_SME_EMPLOYEE = 'PUBLIC_LISTED_SME_EMPLOYEE',
    MNC_EMPLOYEE = 'MNC_EMPLOYEE',
    GOVERNMENT_EMPLOYEE = 'GOVERNMENT_EMPLOYEE',
    HOMEMAKER = 'HOMEMAKER',
    RETIREE = 'RETIREE',
    STUDENT = 'STUDENT',
}

export enum CustomerDetailsSource {
    MYINFO = 'MYINFO',
    MANUAL = 'MANUAL',
    NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum LicenseType {
    QUALIFIED = 'QUALIFIED',
    PROVISIONAL = 'PROVISIONAL',
    NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum Title {
    MR = 'MR',
    MS = 'MS',
    MRS = 'MRS',
}

export type TitleValue = {
    source: CustomerDetailsSource;
    value: Title;
};

export type CustomerDetailsStringValue = {
    source: CustomerDetailsSource;
    value: Scalars['String'];
};

export type CustomerDetailsFloatValue = {
    source: CustomerDetailsSource;
    value?: Maybe<Scalars['Float']>;
};

export type CustomerDetailsDateValue = {
    source: CustomerDetailsSource;
    value?: Maybe<Scalars['DateTime']>;
};

export type CustomerDetailsBooleanValue = {
    source: CustomerDetailsSource;
    value?: Maybe<Scalars['Boolean']>;
};

export type Address = {
    block?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
    building?: Maybe<Scalars['String']>;
    unit?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    countryName?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    fullAddress?: Maybe<Scalars['String']>;
    poBox?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    source: CustomerDetailsSource;
};

export type OfficeAddress = {
    fullAddress?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    poBox?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    source: CustomerDetailsSource;
};

export type HomeCountryAddress = {
    fullAddress?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    source: CustomerDetailsSource;
};

export type CpfbIncomeHistory = {
    paidAt: Scalars['DateTime'];
    month: Scalars['DateTime'];
    amount: Scalars['Float'];
    employer: Scalars['String'];
};

export type Employment = {
    employer?: Maybe<CustomerDetailsStringValue>;
    occupation?: Maybe<CustomerDetailsStringValue>;
    assessableIncome?: Maybe<CustomerDetailsFloatValue>;
    assessmentYear?: Maybe<CustomerDetailsFloatValue>;
    cpfContributions?: Maybe<Array<CpfbIncomeHistory>>;
    incomeType?: Maybe<CustomerDetailsStringValue>;
    monthlyIncome?: Maybe<CustomerDetailsFloatValue>;
    dateOfJoining?: Maybe<CustomerDetailsDateValue>;
    totalWorkExperience?: Maybe<CustomerDetailsFloatValue>;
    designation?: Maybe<CustomerDetailsStringValue>;
    salaryTransferredToBank?: Maybe<CustomerDetailsBooleanValue>;
    bankAccountNumber?: Maybe<CustomerDetailsStringValue>;
};

export type DrivingLicense = {
    type?: Maybe<LicenseType>;
    validity?: Maybe<Scalars['String']>;
    expiryDate?: Maybe<Scalars['DateTime']>;
    classes?: Maybe<Array<LicenseClass>>;
    source: CustomerDetailsSource;
    number?: Maybe<Scalars['String']>;
};

export type LicenseClass = {
    licenseClass?: Maybe<Scalars['String']>;
    issueDate?: Maybe<Scalars['DateTime']>;
};

export type Color = {
    primary?: Maybe<Scalars['String']>;
    secondary?: Maybe<Scalars['String']>;
};

export type VehicleCoe = {
    category?: Maybe<Scalars['String']>;
    expiryDate?: Maybe<Scalars['DateTime']>;
};

export type Vehicle = {
    make?: Maybe<Scalars['String']>;
    model?: Maybe<Scalars['String']>;
    registrationNo?: Maybe<Scalars['String']>;
    mileage?: Maybe<Scalars['Float']>;
    year?: Maybe<Scalars['Int']>;
    registrationDate?: Maybe<Scalars['DateTime']>;
    roadTaxExpiryDate?: Maybe<Scalars['DateTime']>;
    engineCapacity?: Maybe<Scalars['Int']>;
    propellant?: Maybe<Scalars['String']>;
    color?: Maybe<Color>;
    coe?: Maybe<VehicleCoe>;
    quotaPremium?: Maybe<Scalars['Float']>;
    openMarketValue?: Maybe<Scalars['Float']>;
    noOfTransfer?: Maybe<Scalars['Int']>;
    status?: Maybe<Scalars['String']>;
    scheme?: Maybe<Scalars['String']>;
    source: CustomerDetailsSource;
};

export type Passport = {
    number?: Maybe<Scalars['String']>;
    expiry?: Maybe<Scalars['DateTime']>;
    source: CustomerDetailsSource;
};

export type CustomerAffinBank = {
    race: CustomerDetailsStringValue;
    idType: CustomerDetailsStringValue;
    idIssueCountry: CustomerDetailsStringValue;
    residentialCountry: CustomerDetailsStringValue;
    residentialStatus: CustomerDetailsStringValue;
    addressType: CustomerDetailsStringValue;
};

export type CustomerPmesa = {
    emirate?: Maybe<CustomerDetailsStringValue>;
};

export type CustomerEnbdBank = {
    visaNumber?: Maybe<CustomerDetailsStringValue>;
    visaIssueDate?: Maybe<CustomerDetailsDateValue>;
    visaExpiryDate?: Maybe<CustomerDetailsDateValue>;
    preferredFirstPaymentDate: CustomerDetailsDateValue;
    bankName?: Maybe<CustomerDetailsStringValue>;
};

export type CustomerThirdParty = {
    affinBank?: Maybe<CustomerAffinBank>;
    pmeSA?: Maybe<CustomerPmesa>;
    enbdBank?: Maybe<CustomerEnbdBank>;
};

export type CustomerQuotationOption = {
    description?: Maybe<CustomerDetailsStringValue>;
    price?: Maybe<Scalars['Float']>;
    isVatIncluded?: Maybe<Scalars['Boolean']>;
};

export type CustomerQuotation = {
    fullName?: Maybe<CustomerDetailsStringValue>;
    commissionNo?: Maybe<CustomerDetailsStringValue>;
    engineNo?: Maybe<CustomerDetailsStringValue>;
    chassisNo?: Maybe<CustomerDetailsStringValue>;
    exteriorColor?: Maybe<CustomerDetailsStringValue>;
    options?: Maybe<Array<Maybe<CustomerQuotationOption>>>;
    downpaymentTo?: Maybe<CustomerDetailsStringValue>;
    companyName?: Maybe<CustomerDetailsStringValue>;
    financeInsuranceManager?: Maybe<CustomerDetailsStringValue>;
};

export type CustomerGuaranteedBuyback = {
    maxMileage?: Maybe<CustomerDetailsFloatValue>;
    excessMileageCharge?: Maybe<CustomerDetailsFloatValue>;
    vehicleModelYear?: Maybe<CustomerDetailsFloatValue>;
};

export type CustomerDetails = {
    gender?: Maybe<CustomerDetailsStringValue>;
    race?: Maybe<CustomerDetailsStringValue>;
    nationality?: Maybe<CustomerDetailsStringValue>;
    maritalStatus?: Maybe<CustomerDetailsStringValue>;
    residentialStatus?: Maybe<CustomerDetailsStringValue>;
    residentialAddress?: Maybe<Address>;
    employment?: Maybe<Employment>;
    drivingLicense?: Maybe<Array<Maybe<DrivingLicense>>>;
    mainIncome?: Maybe<MainIncomeType>;
    subMainIncome?: Maybe<SubMainIncomeType>;
    monthlyIncome?: Maybe<Scalars['Float']>;
    yearsOfService?: Maybe<Scalars['Int']>;
    education?: Maybe<CustomerDetailsStringValue>;
    passport?: Maybe<Passport>;
    emirate?: Maybe<CustomerDetailsStringValue>;
    emiratesIdExpiry?: Maybe<CustomerDetailsDateValue>;
    uaeResidentSince?: Maybe<CustomerDetailsDateValue>;
    addressType?: Maybe<CustomerDetailsStringValue>;
    office?: Maybe<OfficeAddress>;
    isCountrySameAsResidence?: Maybe<CustomerDetailsBooleanValue>;
    homeCountryAddress?: Maybe<HomeCountryAddress>;
    reference?: Maybe<ReferenceDetails>;
    thirdParty?: Maybe<CustomerThirdParty>;
    quotation?: Maybe<CustomerQuotation>;
    guaranteedBuyback?: Maybe<CustomerGuaranteedBuyback>;
};

export type ReferenceDetails = {
    name?: Maybe<Scalars['String']>;
    relationship?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    source: CustomerDetailsSource;
};

export type Customer = {
    id: Scalars['ObjectID'];
    zoneId: Scalars['ObjectID'];
    zone: Zone;
    title?: Maybe<TitleValue>;
    /** Full name of the customer */
    name: CustomerDetailsStringValue;
    /** First name of the customer, will be the same as fullname if there's only 1 field for customer name */
    firstName: CustomerDetailsStringValue;
    /** Last name of the customer, if absent means name stores full name of the customer */
    lastName: CustomerDetailsStringValue;
    email: CustomerDetailsStringValue;
    emailBis?: Maybe<CustomerDetailsStringValue>;
    phone?: Maybe<CustomerDetailsStringValue>;
    phoneBis?: Maybe<CustomerDetailsStringValue>;
    identityNumber?: Maybe<CustomerDetailsStringValue>;
    dateOfBirth?: Maybe<CustomerDetailsDateValue>;
    type: CustomerType;
    latestApplication?: Maybe<Application>;
    applications: Array<Application>;
    withMyInfo: Scalars['Boolean'];
    isDeleted: Scalars['Boolean'];
    details?: Maybe<CustomerDetails>;
    version?: Maybe<SimpleVersion>;
};

export type CustomerList = {
    items: Array<Customer>;
    count?: Maybe<Scalars['Int']>;
};

export type AutoplayDealerType = {
    dealerId: Scalars['Int'];
    yardId: Scalars['Int'];
};

export type Dealer = {
    id: Scalars['ObjectID'];
    countryId: Scalars['ObjectID'];
    companyId: Scalars['ObjectID'];
    identifier: Scalars['String'];
    name: Scalars['IntlValue'];
    telephone?: Maybe<Scalars['String']>;
    whatsapp?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['IntlValue']>;
    porschePaymentAssortment?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    banks: Array<Bank>;
    disabledBankIds: Array<Scalars['ObjectID']>;
    users: Array<User>;
    additionalInformation?: Maybe<Scalars['IntlValue']>;
    partnerNumber?: Maybe<Scalars['String']>;
    financeProducts?: Maybe<Array<Maybe<FinanceProduct>>>;
    bookingPayment?: Maybe<ChannelBookingPayment>;
    isDeleted: Scalars['Boolean'];
    version: SimpleVersion;
    establishment?: Maybe<ChannelEstablishment>;
    autoplay?: Maybe<AutoplayDealerType>;
};

export type ChannelBookingPayment = {
    new?: Maybe<Scalars['Float']>;
    used?: Maybe<Scalars['Float']>;
    express?: Maybe<Scalars['Float']>;
};

export type ChannelEstablishment = {
    new?: Maybe<Scalars['Float']>;
    used?: Maybe<Scalars['Float']>;
    express?: Maybe<Scalars['Float']>;
    event?: Maybe<Scalars['Float']>;
};

export type DealerContactInformation = {
    name: Scalars['IntlValue'];
    telephone?: Maybe<Scalars['String']>;
    whatsapp?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['IntlValue']>;
    email?: Maybe<Scalars['String']>;
    additionalInformation?: Maybe<Scalars['IntlValue']>;
};

export type DealerList = {
    items: Array<Dealer>;
    count?: Maybe<Scalars['Int']>;
};

export enum EventLeadOrigin {
    INTERNET = 'Internet',
    DEALER = 'Dealer',
}

export enum EventMedium {
    WALKIN = 'WalkIn',
    EVENT = 'Event',
    INTERNET = 'Internet',
}

export enum EventExternalSite {
    NONE = 'None',
    PORSCHEFINDER = 'PorscheFinder',
    CONFIGURATOR = 'Configurator',
    MARKETINGRECONSENT = 'MarketingReconsent',
    ICC = 'ICC',
}

export enum CoeVehicleCondition {
    ALL = 'All',
    NEW = 'New',
    PREOWNED = 'Preowned',
}

export type EventSetting = {
    allowPublicAccess: Scalars['Boolean'];
    allowPrivateAccess: Scalars['Boolean'];
    isCoeEnabled: Scalars['Boolean'];
    isCoeEditable: Scalars['Boolean'];
    isPromoCodeEnabled: Scalars['Boolean'];
    isPpsrAndEstablishmentEnabled: Scalars['Boolean'];
    allowTradeIn: Scalars['Boolean'];
    allowTradeInAmountInput: Scalars['Boolean'];
    allowTestDrive: Scalars['Boolean'];
    isDepositPaymentMandatory: Scalars['Boolean'];
    isChatbotEnabled: Scalars['Boolean'];
    reservationInstruction?: Maybe<Scalars['String']>;
    reservationPeriod?: Maybe<Scalars['Int']>;
    externalSite: EventExternalSite;
    bookingPayment?: Maybe<BookingPayment>;
    myInfo?: Maybe<MyInfoIntegration>;
    calculatorVisibility: CalculatorVisibility;
    applyForFinancing: Scalars['Boolean'];
    coeVehicleCondition?: Maybe<CoeVehicleCondition>;
    isAppointmentEnabled: Scalars['Boolean'];
    isInsuranceEnabled: Scalars['Boolean'];
    priceDisclaimers: Array<Scalars['String']>;
    financePriceDisclaimers: Array<Scalars['String']>;
    insurancePriceDisclaimers: Array<Scalars['String']>;
};

export type EventEdm = {
    subject: Scalars['IntlValue'];
    introImage?: Maybe<Attachment>;
    introTitle: Scalars['IntlValue'];
    contentText: Scalars['IntlValue'];
    isSummaryVehicleEnabled: Scalars['Boolean'];
};

export type Event = {
    id: Scalars['ObjectID'];
    dealerIds: Array<Scalars['ObjectID']>;
    dealers: Array<Dealer>;
    identifier: Scalars['String'];
    name: Scalars['String'];
    period: Period;
    campaignId?: Maybe<Scalars['String']>;
    leadOrigin: EventLeadOrigin;
    medium: EventMedium;
    /** this person's email will be used if the application/lead comes from public access */
    defaultSalesPersonId?: Maybe<Scalars['ObjectID']>;
    isActive: Scalars['Boolean'];
    setting: EventSetting;
    /** applications create via current event */
    applications: Array<Application>;
    /** consents and declarations attached to current event */
    consentsAndDeclarations: Array<ConsentOrDeclaration>;
    version: SimpleVersion;
    /** EDM event configuration */
    edm?: Maybe<EventEdm>;
};

export type EventList = {
    items: Array<Event>;
    count?: Maybe<Scalars['Int']>;
};

export type FinanceProduct = {
    id: Scalars['ObjectID'];
    countryId: Scalars['ObjectID'];
    dealerIds: Array<Scalars['ObjectID']>;
    bankId: Scalars['ObjectID'];
    bank: Bank;
    basedOn: FinanceProductBase;
    type: FinanceProductType;
    subType?: Maybe<FinanceProductSubType>;
    identifier: Scalars['String'];
    bankReferenceIdentifier: Scalars['String'];
    name: Scalars['IntlValue'];
    description?: Maybe<Scalars['IntlValue']>;
    order: Scalars['Int'];
    termsAndConditions: TermsAndConditions;
    isDeleted: Scalars['Boolean'];
    isActive: Scalars['Boolean'];
    period: Period;
    paymentMode: FinanceProductPayment;
    termSetting: TermSetting;
    loanSetting: LoanSetting;
    downPaymentSetting: DownPaymentSetting;
    interestRateSetting: InterestRateSetting;
    averageMileageSetting?: Maybe<AverageMileageSetting>;
    residualValueSetting?: Maybe<ResidualValueSetting>;
    balloonSetting?: Maybe<BalloonSetting>;
    balloonGFVSetting?: Maybe<BalloonGfvSetting>;
    depositSetting?: Maybe<DepositSetting>;
    structuredPayment?: Maybe<StructuredPaymentSetting>;
    leaseSetting?: Maybe<LeaseSetting>;
    licensePlateFeeSetting?: Maybe<LicensePlateFeeSetting>;
    commissionSetting?: Maybe<CommissionSetting>;
    fixedInterestRateSetting?: Maybe<FixedInterestRateSetting>;
    licenseAndFuelTaxSetting?: Maybe<LicenseAndFuelTaxSetting>;
    displacementSetting?: Maybe<DisplacementSetting>;
    insuranceFeeSetting?: Maybe<InsuranceFeeSetting>;
    taxLossSetting?: Maybe<TaxLossSetting>;
    totalInterestPayableSetting?: Maybe<TotalInterestPayableSetting>;
    expressDealerIds: Array<Scalars['ObjectID']>;
    finderDealerIds: Array<Scalars['ObjectID']>;
    iccDealerIds: Array<Scalars['ObjectID']>;
    csvDealerIds: Array<Scalars['ObjectID']>;
    allowOnConfigurator: Scalars['Boolean'];
    tradeInSetting: TradeInSetting;
    version: Version;
    /** List of variants version ID on which the finance product is available */
    variants: Array<Scalars['ObjectID']>;
    /** List of finder vehicles ID on which the finance product is available */
    finderVehicles: Array<Scalars['ObjectID']>;
};

export type FinanceProductList = {
    items: Array<FinanceProduct>;
    count?: Maybe<Scalars['Int']>;
};

export type TermSetting = {
    min: Scalars['Int'];
    max: Scalars['Int'];
    step: Scalars['Int'];
    default: Scalars['Int'];
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
    interestOnly?: Maybe<Scalars['Int']>;
};

export type StructuredPaymentSetting = {
    min: Scalars['Float'];
    max: Scalars['Float'];
    step: Scalars['Float'];
    default: Scalars['Float'];
    defaultUnit: Unit;
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
};

export type DownPaymentIccSetting = {
    min: Scalars['Float'];
    max: Scalars['Float'];
    step: Scalars['Float'];
    default: Scalars['Float'];
    defaultUnit: Unit;
};

export type DownPaymentTableValues = {
    term: Scalars['Int'];
    value: Scalars['Float'];
};

export type DownPaymentSetting = {
    min: Scalars['Float'];
    max: Scalars['Float'];
    step: Scalars['Float'];
    default: Scalars['Float'];
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
    defaultUnit: Unit;
    mode: DownPaymentMode;
    table?: Maybe<Array<DownPaymentTableValues>>;
    icc?: Maybe<DownPaymentIccSetting>;
};

export type LoanSetting = {
    min: Scalars['Float'];
    max: Scalars['Float'];
    step: Scalars['Float'];
    default: Scalars['Float'];
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
    defaultUnit: Unit;
};

export type InterestRateTableValues = {
    term: Scalars['Int'];
    /**
     * Based on amount reference value is based on Finance Product
     * Can be percentage or amount
     */
    basedOnAmount: Scalars['Float'];
    value: Scalars['Float'];
};

export type InterestRateSetting = {
    min: Scalars['Float'];
    max: Scalars['Float'];
    step: Scalars['Float'];
    default: Scalars['Float'];
    editable: Scalars['Boolean'];
    displayMode: InterestRateDisplayMode;
    mode: InterestRateMode;
    table?: Maybe<Array<InterestRateTableValues>>;
    tableGroupingStep?: Maybe<Scalars['Float']>;
    tableGroupingUnit?: Maybe<Unit>;
};

export type Period = {
    start?: Maybe<Scalars['DateTime']>;
    startTimeZone?: Maybe<Scalars['String']>;
    end?: Maybe<Scalars['DateTime']>;
    endTimeZone?: Maybe<Scalars['String']>;
};

export type FinanceProductPayment = {
    mode?: Maybe<PaymentMode>;
    calculationMode?: Maybe<CalculationMode>;
    derivedMethod: DerivedMethod;
    show?: Maybe<Scalars['Boolean']>;
    editable?: Maybe<Scalars['Boolean']>;
    reducedPercentage?: Maybe<Scalars['Int']>;
};

export type BalloonTableValues = {
    term: Scalars['Int'];
    variantId: Scalars['ObjectID'];
    /** Determines what vehicle type is the variant id referencing */
    vehicleType: TableValueVehicleType;
    value: Scalars['Float'];
};

export type BalloonSetting = {
    min: Scalars['Int'];
    max: Scalars['Int'];
    step: Scalars['Int'];
    default: Scalars['Int'];
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
    defaultUnit: Unit;
    mode: BalloonMode;
    basedOn: BalloonBase;
    table?: Maybe<Array<BalloonTableValues>>;
};

export type MarginOfFinanceSetting = {
    /** Margin of finance min validation (in percentage) */
    min?: Maybe<Scalars['Int']>;
    /** Margin of finance max validation (in percentage) */
    max?: Maybe<Scalars['Int']>;
    /** On/Off margin of finance */
    show: Scalars['Boolean'];
};

export type BalloonGfvSetting = {
    settlementInstalmentOn: Scalars['Int'];
    marginOfFinance?: Maybe<MarginOfFinanceSetting>;
};

export type DepositTableValues = {
    term: Scalars['Int'];
    value: Scalars['Float'];
};

export type DepositSetting = {
    min: Scalars['Float'];
    max: Scalars['Float'];
    step: Scalars['Float'];
    default: Scalars['Float'];
    defaultUnit: Unit;
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
    mode: DepositMode;
    table?: Maybe<Array<DepositTableValues>>;
};

export type AverageMileageSetting = {
    min: Scalars['Float'];
    max: Scalars['Float'];
    step: Scalars['Float'];
    default: Scalars['Float'];
    defaultUnit: Unit;
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
};

export type LeaseTableValues = {
    term: Scalars['Int'];
    variantId: Scalars['ObjectID'];
    /** Determines what vehicle type is the variant id referencing */
    vehicleType: TableValueVehicleType;
    value: Scalars['Float'];
};

export type LeaseSetting = {
    table: Array<LeaseTableValues>;
};

export type ResidualTableValue = {
    term: Scalars['Int'];
    mileage?: Maybe<Scalars['Int']>;
    variantId: Scalars['ObjectID'];
    /** Determines what vehicle type is the variant id referencing */
    vehicleType: TableValueVehicleType;
    value: Scalars['Float'];
};

export type ResidualValueSetting = {
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
    defaultUnit: Unit;
    table?: Maybe<Array<ResidualTableValue>>;
};

export type TradeInSetting = {
    show: Scalars['Boolean'];
    withLiability: Scalars['Boolean'];
};

export type LicensePlateFeeSetting = {
    min: Scalars['Int'];
    max: Scalars['Int'];
    step: Scalars['Int'];
    default: Scalars['Int'];
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
};

export type CommissionSetting = {
    default: Scalars['Float'];
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
};

export type FixedInterestRateSetting = {
    show: Scalars['Boolean'];
};

export type LicenseAndFuelTaxTableValues = {
    min: Scalars['Int'];
    max: Scalars['Int'];
    value: Scalars['Int'];
};

export type LicenseAndFuelTaxSetting = {
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
    fuelTable?: Maybe<Array<LicenseAndFuelTaxTableValues>>;
    licenseTable?: Maybe<Array<LicenseAndFuelTaxTableValues>>;
};

export type DisplacementSetting = {
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
};

export type InsuranceFeeTableValues = {
    value: Scalars['Float'];
    variantId: Scalars['ObjectID'];
    vehicleType: TableValueVehicleType;
};

export type InsuranceFeeSetting = {
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
    table?: Maybe<Array<InsuranceFeeTableValues>>;
};

export type TaxLossSetting = {
    show: Scalars['Boolean'];
    editable: Scalars['Boolean'];
};

export type TotalInterestPayableSetting = {
    displayMode: TotalInterestPayableDisplayMode;
};

export enum Unit {
    PERCENTAGE = 'Percentage',
    CURRENCY = 'Currency',
    KM = 'Km',
}

export enum DownPaymentMode {
    RANGE = 'Range',
    TABLE = 'Table',
}

export enum InterestRateMode {
    RANGE = 'Range',
    TABLE = 'Table',
    FLAT = 'Flat',
}

export enum BalloonMode {
    RANGE = 'Range',
    TABLE = 'Table',
    MILEAGETABLE = 'MileageTable',
}

export enum BalloonBase {
    CARPRICE = 'CarPrice',
    LOAN = 'Loan',
    TOTALPRICE = 'TotalPrice',
}

export enum DepositMode {
    RANGE = 'Range',
    TABLE = 'Table',
}

export enum FinanceProductType {
    FINANCE = 'Finance',
    LEASING = 'Leasing',
}

export enum FinanceProductSubType {
    HIREPURCHASE = 'HirePurchase',
    HIREPURCHASEBALLOON = 'HirePurchaseBalloon',
    HIREPURCHASEBALLOONNZ = 'HirePurchaseBalloonNZ',
    HIREPURCHASEBALLOONGFV = 'HirePurchaseBalloonGFV',
    DEFERREDPRINCIPAL = 'DeferredPrincipal',
    LEASE = 'Lease',
    LEASEPURCHASE = 'LeasePurchase',
    LEASEPURCHASEICC = 'LeasePurchaseICC',
    UCCLLEASING = 'UCCLLeasing',
}

export enum PaymentMode {
    ADVANCE = 'Advance',
    ARREARS = 'Arrears',
}

export enum DerivedMethod {
    FORMULA = 'Formula',
    LOOKUPTABLE = 'LookupTable',
}

export enum CalculationMode {
    FLAT = 'Flat',
    EFFECTIVE = 'Effective',
}

export enum FinanceProductBase {
    DOWNPAYMENT = 'DownPayment',
    LOAN = 'Loan',
}

export enum InterestRateDisplayMode {
    CALCULATOR = 'Calculator',
    FINANCEPRODUCT = 'FinanceProduct',
    NONE = 'None',
}

export enum TotalInterestPayableDisplayMode {
    CALCULATOR = 'Calculator',
    FINANCEPRODUCT = 'FinanceProduct',
    NONE = 'None',
}

export enum TableValueVehicleType {
    VARIANT = 'Variant',
    FINDERVEHICLE = 'FinderVehicle',
}

export enum FinderVehicleStatus {
    AVAILABLE = 'Available',
    RESERVED = 'Reserved',
    SUBMITTED = 'Submitted',
}

export enum FinderVehicleAvailability {
    ONETIME = 'OneTime',
    PERMANENT = 'Permanent',
}

export type FinderVehicleSetting = {
    isHidden: Scalars['Boolean'];
    availability: FinderVehicleAvailability;
};

export enum CoeCategory {
    CATA = 'CatA',
    CATB = 'CatB',
    CATC = 'CatC',
    CATE = 'CatE',
}

export type FinderVehicleDetails = {
    ownerIdType: PreOwnerIdType;
    ownerId: Scalars['String'];
    make: Scalars['String'];
    model: Scalars['String'];
    colour: Scalars['String'];
    yearOfManufacture?: Maybe<Scalars['String']>;
    registrationNumber: Scalars['String'];
    engineNumber: Scalars['String'];
    chassisNumber: Scalars['String'];
    omv: Scalars['Float'];
    originalRegistrationDate: Scalars['DateTime'];
    firstRegistrationDate?: Maybe<Scalars['DateTime']>;
    transfers: Scalars['Int'];
    coe?: Maybe<Scalars['Float']>;
    pqp?: Maybe<Scalars['Float']>;
    arf: Scalars['Float'];
    parfEligibilityExpiryDate?: Maybe<Scalars['DateTime']>;
    parfRebateAmount: Scalars['Float'];
    coeCategory: CoeCategory;
    coePeriod: Scalars['Float'];
    coeExpiryDate: Scalars['DateTime'];
    power?: Maybe<Scalars['Float']>;
};

export type FinderAutoplay = {
    listingId?: Maybe<Scalars['Int']>;
    lastUpdated?: Maybe<Scalars['DateTime']>;
};

export type FinderVehicle = {
    id: Scalars['ObjectID'];
    countryId: Scalars['ObjectID'];
    /** Remote id reference from Porsche Finder Listing API */
    remoteId: Scalars['String'];
    /** Vehicle full name */
    name: Scalars['String'];
    /** Vehicle listing data from Porsche Finder Listing API */
    listing?: Maybe<Listing>;
    /** Porsche Finder URL */
    url: Scalars['String'];
    setting?: Maybe<FinderVehicleSetting>;
    details?: Maybe<FinderVehicleDetails>;
    inspected: Scalars['Boolean'];
    status: FinderVehicleStatus;
    isDeleted: Scalars['Boolean'];
    version: SimpleVersion;
    financeProducts: Array<FinanceProduct>;
    /** Autoplay integration */
    autoplay?: Maybe<FinderAutoplay>;
};

export enum FinderVehicleBookingType {
    RESERVED = 'Reserved',
    DELETED = 'Deleted',
    COMPLETED = 'Completed',
}

export type FinderVehicleBooking = {
    id: Scalars['ObjectID'];
    listingId: Scalars['ObjectID'];
    referenceId: Scalars['ObjectID'];
    expiresAt?: Maybe<Scalars['DateTime']>;
    bookingType: FinderVehicleBookingType;
};

export type InsuranceIntegrationSetting = {
    submission: Scalars['String'];
    resubmission: Scalars['String'];
    cancellation: Scalars['String'];
    quickQuote?: Maybe<Scalars['String']>;
};

export enum InsuranceSigningMode {
    NONE = 'None',
    NAMIRIAL = 'Namirial',
}

export enum InsuranceOption {
    GNM = 'Gnm',
}

export type InsuranceSigningSettings = {
    onCreate: InsuranceSigningMode;
    onUpdate: InsuranceSigningMode;
};

export type Insurance = {
    id: Scalars['ObjectID'];
    countryId: Scalars['ObjectID'];
    country: Country;
    zoneIds: Array<Scalars['ObjectID']>;
    zones: Array<Zone>;
    dealerIds: Array<Scalars['ObjectID']>;
    dealers: Array<Dealer>;
    name: Scalars['IntlValue'];
    email: Scalars['String'];
    order: Scalars['Int'];
    remoteFlowAcknowledgmentInfo?: Maybe<Scalars['IntlValue']>;
    signing: InsuranceSigningSettings;
    hasIntegration: Scalars['Boolean'];
    integration?: Maybe<InsuranceIntegrationSetting>;
    isActive: Scalars['Boolean'];
    isDeleted: Scalars['Boolean'];
    version: SimpleVersion;
    option: InsuranceOption;
    sendEmailToCustomer: Scalars['Boolean'];
};

export type InsuranceList = {
    items: Array<Bank>;
    count?: Maybe<Scalars['Int']>;
};

export type InsuranceApplication = {
    id: Scalars['ObjectID'];
    identifier?: Maybe<Scalars['String']>;
    dealerId: Scalars['ObjectID'];
    dealer: Dealer;
    zoneId: Scalars['ObjectID'];
    zone: Zone;
    customerId: Scalars['ObjectID'];
    customer: Customer;
    variantId: Scalars['ObjectID'];
    variant: Variant;
    assigneeId?: Maybe<Scalars['ObjectID']>;
    assignee?: Maybe<User>;
    insuranceId?: Maybe<Scalars['ObjectID']>;
    insuranceCompany: Insurance;
    applicationVersionId?: Maybe<Scalars['ObjectID']>;
    application?: Maybe<Application>;
    status: InsuranceApplicationStatus;
    statusText: ApplicationStatusText;
    access: ApplicationAccess;
    channel: Channel;
    proceedWithCustomerDevice: Scalars['Boolean'];
    signing?: Maybe<ApplicationSigning>;
    version: Version;
    comment?: Maybe<Scalars['String']>;
    calculator: InsuranceApplicationCalculator;
    attachments: Array<Attachment>;
    events: Array<ApplicationEvent>;
    steps?: Maybe<StepsCondition>;
    finderVehicle?: Maybe<FinderVehicle>;
};

export type InsuranceApplicationCalculator = {
    /** Customer Driver License Pass Date */
    driverLicensePassDate?: Maybe<Scalars['DateTime']>;
    /** No Claim Discount */
    ncd?: Maybe<Scalars['Int']>;
    /** Existing Car Place to apply NCD */
    existingCarPlate?: Maybe<Scalars['String']>;
    /** Vehicle Displacement */
    displacement: Scalars['Float'];
    /** Vehicle Price */
    price: Scalars['Float'];
    /** COE */
    coe?: Maybe<ApplicationCalculatorCoe>;
    /** Insurance Premium */
    insurancePremium?: Maybe<Scalars['Float']>;
    /** Years of driving */
    yearsOfDriving?: Maybe<Scalars['Float']>;
    /** Disclaimer insurance premium */
    disclaimerInsurancePremium?: Maybe<Scalars['Float']>;
};

export enum InsuranceApplicationStatus {
    HIDDENDRAFT = 'HiddenDraft',
    DRAFT = 'Draft',
    SUBMITTED = 'Submitted',
    SIGNED = 'Signed',
    SIGNING = 'Signing',
    SIGNINGREJECTED = 'SigningRejected',
    SIGNINGTIMEOUT = 'SigningTimeout',
    UNABLETOCONNECT = 'UnableToConnect',
    CONNECTIONFAILED = 'ConnectionFailed',
    APPROVED = 'Approved',
    RECEIVED = 'Received',
    CANCELLED = 'Cancelled',
    UNABLETOCANCEL = 'UnableToCancel',
    CANCELLATIONFAILED = 'CancellationFailed',
    DECLINED = 'Declined',
    COMPLETED = 'Completed',
    UNABLETOSUBMIT = 'UnableToSubmit',
    PENDINGCUSTOMERCONFIRMATION = 'PendingCustomerConfirmation',
}

export type InsuranceApplicationList = {
    items: Array<InsuranceApplication>;
    count?: Maybe<Scalars['Int']>;
};

export type ProceedInsuranceApplicationContext = {
    insuranceApplication: InsuranceApplication;
    token: Scalars['String'];
};

export enum BookingStatus {
    /** The booking is linked to a reserved application, draft or pre-draft instance */
    RESERVED = 'Reserved',
    /** The booking is linked to a received application */
    DEDUCTED = 'Deducted',
    /** The booking is linked to a completed application */
    COMPLETED = 'Completed',
    /** The booking is manually deducted */
    MANUALLYDEDUCTED = 'ManuallyDeducted',
}

export type InventoryUnitDetails = {
    /** Vehicle Identity Number */
    vin: Scalars['String'];
    /** Location of the unit */
    location: Scalars['String'];
    /** Description of the unit */
    description?: Maybe<Scalars['IntlValue']>;
    /** Features of the unit */
    features?: Maybe<Scalars['IntlValue']>;
    /** UsedCarDetails of the unit */
    usedCarDetails?: Maybe<UsedCarDetails>;
};

export type Booking = {
    /** Booking status */
    status: BookingStatus;
    /** Inventory set ID */
    setId: Scalars['ObjectID'];
    /** Inventory set document */
    set: InventorySet;
    /** Application document (there may be none) */
    application?: Maybe<Application>;
    /** Unit */
    unit: InventoryUnit;
};

export type InventoryUnitPrice = {
    /** Price for inventory unit */
    value: Scalars['Float'];
    /** Zone for which the price is specified in */
    zoneId: Scalars['ObjectID'];
};

export type InventoryUnit = {
    id: Scalars['ObjectID'];
    /** Unique identifier in the inventory set */
    identifier: Scalars['Int'];
    /** Inventory set id */
    setId: Scalars['ObjectID'];
    /** Inventory set document */
    set: InventorySet;
    images: Array<Maybe<Attachment>>;
    /** Details */
    details: InventoryUnitDetails;
    /** Booking (optional) */
    booking?: Maybe<Booking>;
    /** Prices for inventory unit categorized by zones */
    prices: Array<InventoryUnitPrice>;
    /** Application identifier manually filled in by user (optional) */
    applicationIdentifier?: Maybe<Scalars['String']>;
    /** State */
    state?: Maybe<InventoryUnitState>;
    /** Versioning */
    version: SimpleVersion;
};

export type InventorySet = {
    id: Scalars['ObjectID'];
    /** Invenroty group of current set */
    inventory: Inventory;
    /** Unique identifier in the inventory group (for the variant) */
    identifier: Scalars['Int'];
    /** VIN registered */
    vins: Scalars['Int'];
    /** Stock initialy available */
    initialStock: Scalars['Int'];
    /** Stock remaining (included reserved stock) */
    remainingStock: Scalars['Int'];
    /** Stock still available for new applications */
    availableStock: Scalars['Int'];
    /** Reserved stock (temporary reservation) */
    reservedStock: Scalars['Int'];
    /** Deducted stock (stock in application already received) */
    deductedStock: Scalars['Int'];
    /** List of version ID from options */
    optionIds: Array<Scalars['ObjectID']>;
    /** List of options */
    options: Array<Option>;
    /** Active state */
    isActive: Scalars['Boolean'];
    /** Units */
    units: Array<InventoryUnit>;
    /** Versioning */
    version: SimpleVersion;
};

export type Inventory = {
    id: Scalars['ObjectID'];
    /** Unique identifier for the country */
    identifier: Scalars['Int'];
    /** Variant version ID */
    variantId: Scalars['ObjectID'];
    /** Variant object */
    variant?: Maybe<Variant>;
    /** Child inventory set */
    sets: Array<InventorySet>;
    /** Versioning */
    version: SimpleVersion;
};

export type InventorySetsArgs = {
    ids?: Maybe<Array<Scalars['ObjectID']>>;
};

export type InventoryList = {
    items: Array<Inventory>;
    count?: Maybe<Scalars['Int']>;
};

export type PostActionResult = {
    variant?: Maybe<Variant>;
    inventory?: Maybe<Inventory>;
    result: Scalars['String'];
};

export enum BookingPostAction {
    /** Add back the unit to the inventory */
    ADDBACK = 'AddBack',
    /** Ignore it and let the unit as it'sp */
    IGNORE = 'Ignore',
}

export type Make = {
    id: Scalars['ObjectID'];
    companyId: Scalars['ObjectID'];
    identifier: Scalars['String'];
    name: Scalars['IntlValue'];
    order: Scalars['Int'];
    models: Array<Model>;
    isActive: Scalars['Boolean'];
    isDeleted: Scalars['Boolean'];
    version: Version;
};

export type MakeList = {
    items: Array<Make>;
    count?: Maybe<Scalars['Int']>;
};

export type Model = {
    id: Scalars['ObjectID'];
    countryId: Scalars['ObjectID'];
    makeId: Scalars['ObjectID'];
    make: Make;
    type: ModelType;
    parentId?: Maybe<Scalars['ObjectID']>;
    parent?: Maybe<Model>;
    identifier: Scalars['String'];
    name: Scalars['IntlValue'];
    order: Scalars['Int'];
    isActive: Scalars['Boolean'];
    isDeleted: Scalars['Boolean'];
    subModels: Array<Model>;
    /** Returns variants for the current model */
    variants: Array<Variant>;
    /** Returns all variants for the current and model and its children */
    allVariants: Array<Variant>;
    /** Versioning meta data */
    version: Version;
    /** Alias for `version.id` */
    versionId: Scalars['ObjectID'];
};

export type ModelList = {
    items: Array<Model>;
    count?: Maybe<Scalars['Int']>;
};

export enum ModelType {
    CONVERTIBLE = 'Convertible',
    COUPE = 'Coupe',
    HATCHBACK = 'Hatchback',
    MPV = 'MPV',
    SEDAN = 'Sedan',
    SUV = 'SUV',
    VAN = 'Van',
    WAGON = 'Wagon',
    USESUBMODEL = 'UseSubModel',
}

export type MyInfoCustomerDetails = {
    gender?: Maybe<CustomerDetailsStringValue>;
    race?: Maybe<CustomerDetailsStringValue>;
    nationality?: Maybe<CustomerDetailsStringValue>;
    maritalStatus?: Maybe<CustomerDetailsStringValue>;
    residentialStatus?: Maybe<CustomerDetailsStringValue>;
    residentialAddress?: Maybe<Address>;
    employment?: Maybe<Employment>;
    drivingLicense?: Maybe<Array<Maybe<DrivingLicense>>>;
    mainIncome?: Maybe<MainIncomeType>;
    subMainIncome?: Maybe<SubMainIncomeType>;
    monthlyIncome?: Maybe<Scalars['Float']>;
    yearsOfService?: Maybe<Scalars['Int']>;
    vehicles?: Maybe<Array<Maybe<Vehicle>>>;
};

export type MyInfoCustomerResult = {
    title?: Maybe<TitleValue>;
    name?: Maybe<CustomerDetailsStringValue>;
    firstName?: Maybe<CustomerDetailsStringValue>;
    lastName?: Maybe<CustomerDetailsStringValue>;
    emailBis?: Maybe<CustomerDetailsStringValue>;
    phoneBis?: Maybe<CustomerDetailsStringValue>;
    identityNumber?: Maybe<CustomerDetailsStringValue>;
    dateOfBirth?: Maybe<CustomerDetailsDateValue>;
    details?: Maybe<MyInfoCustomerDetails>;
};

export type NotificationCore = {
    id: Scalars['ObjectID'];
    userId: Scalars['ObjectID'];
    message: Scalars['String'];
    at: Scalars['DateTime'];
};

export enum NotificationType {
    APPLICATION = 'Application',
}

export type ApplicationNotificationDetails = {
    applicationId: Scalars['ObjectID'];
    applicationVersionId: Scalars['ObjectID'];
    customerId: Scalars['ObjectID'];
    customerName: Scalars['String'];
    applicationStatus: ApplicationStatus;
};

export type ApplicationNotification = NotificationCore & {
    id: Scalars['ObjectID'];
    userId: Scalars['ObjectID'];
    message: Scalars['String'];
    at: Scalars['DateTime'];
    type: NotificationType;
    details?: Maybe<ApplicationNotificationDetails>;
};

export type NotificationList = {
    items: Array<NotificationCore>;
    count?: Maybe<Scalars['Int']>;
};

export type NricExtraction = {
    nationalId?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    nationality?: Maybe<Scalars['String']>;
    birthday?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
};

export type OptionGroup = {
    id: Scalars['ObjectID'];
    countryId: Scalars['ObjectID'];
    name: Scalars['IntlValue'];
    order: Scalars['Int'];
    isActive: Scalars['Boolean'];
    isDeleted: Scalars['Boolean'];
    version: SimpleVersion;
    options: Array<Option>;
};

export type OptionGroupList = {
    items: Array<OptionGroup>;
    count?: Maybe<Scalars['Int']>;
};

export type Option = {
    id: Scalars['ObjectID'];
    countryId: Scalars['ObjectID'];
    groupId: Scalars['ObjectID'];
    group: OptionGroup;
    variants: Array<Scalars['ObjectID']>;
    colour: Scalars['String'];
    identifier: Scalars['String'];
    name: Scalars['IntlValue'];
    order: Scalars['Int'];
    price: Scalars['Float'];
    isActive: Scalars['Boolean'];
    isDeleted: Scalars['Boolean'];
    version: Version;
    image?: Maybe<Attachment>;
    hasActiveBookings: Scalars['Boolean'];
};

export type OptionList = {
    items: Array<Option>;
    count?: Maybe<Scalars['Int']>;
};

export type GenerateOtpResult = {
    secret: Scalars['String'];
    expiresAt: Scalars['DateTime'];
};

export enum PaymentStatus {
    INITIALIZED = 'Initialized',
    SUCCESS = 'Success',
    FAILED = 'Failed',
    ERROR = 'Error',
    TIMEOUT = 'Timeout',
    PAYMENTCAPTURING = 'PaymentCapturing',
    REFUNDED = 'Refunded',
    CANCELLED = 'Cancelled',
}

export type PaymentChannel = {
    type: PaymentProviderType;
};

export type AdyenPaymentDetails = {
    paymentMethod?: Maybe<Scalars['String']>;
    pspReference?: Maybe<Scalars['String']>;
};

export type AdyenPaymentChannel = PaymentChannel & {
    type: PaymentProviderType;
    details?: Maybe<AdyenPaymentDetails>;
};

export type PorschePaymentDetails = {
    paymentMethod?: Maybe<Scalars['String']>;
    transactionId?: Maybe<Scalars['String']>;
};

export type PorschePaymentChannel = PaymentChannel & {
    type: PaymentProviderType;
    details?: Maybe<PorschePaymentDetails>;
};

export type PayGatePaymentDetails = {
    paymentMethod?: Maybe<Scalars['String']>;
    transactionId?: Maybe<Scalars['String']>;
    requestId?: Maybe<Scalars['String']>;
};

export type PayGatePaymentChannel = PaymentChannel & {
    type: PaymentProviderType;
    details?: Maybe<PayGatePaymentDetails>;
};

export type TtbPaymentDetails = {
    paymentMethod?: Maybe<Scalars['String']>;
    transactionId?: Maybe<Scalars['String']>;
};

export type TtbPaymentChannel = PaymentChannel & {
    type: PaymentProviderType;
    details?: Maybe<TtbPaymentDetails>;
};

export type Payment = {
    channel: PaymentChannel;
    status: PaymentStatus;
};

export type Characteristics = {
    configuration: Array<VehicleCharacteristic>;
    consumption: Array<VehicleCharacteristic>;
    general: Array<VehicleCharacteristic>;
};

export type ConsumptionEmission = {
    co2EmissionsWithLabel?: Maybe<LocalizedLabeledValue>;
    disclaimer: LocalizedString;
    electricityConsumptionWithLabel?: Maybe<LocalizedLabeledValue>;
    fuelConsumptionWithLabel?: Maybe<LocalizedLabeledValue>;
};

export type Currency = ICurrencyType & {
    currencyCode: Scalars['String'];
    localize: Scalars['String'];
    value: Scalars['Float'];
};

export type CurrencyLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type Displacement = {
    cubicCentimeter: NumberUnit;
    liter: NumberUnit;
};

export type DownPayment = ICurrencyType & {
    currencyCode: Scalars['String'];
    localize: Scalars['String'];
    value: Scalars['Float'];
};

export type DownPaymentLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type EfficiencyClassFranceType = {
    /** The Crit'Air label for this car. */
    critAir?: Maybe<LocalizedValue>;
    energyEfficiencyClass?: Maybe<Scalars['String']>;
    energyEfficiencyClassLabel?: Maybe<Scalars['String']>;
};

export type EfficiencyClassGermanyLabel = {
    co2Emissions: Scalars['String'];
    electricityConsumption: Scalars['String'];
    electricityCostPerUnit: Scalars['String'];
    fuelConsumptionCombined: Scalars['String'];
    fuelConsumptionExtraUrban: Scalars['String'];
    fuelConsumptionUrban: Scalars['String'];
    fuelCostPerUnit: Scalars['String'];
    fuelType: Scalars['String'];
    model: Scalars['String'];
    otherEnergyTypes: Scalars['String'];
    power: Scalars['String'];
    powerLabel: Scalars['String'];
    transmission: Scalars['String'];
    unladenWeight: Scalars['String'];
    yearlyElectricityCostNEDC: Scalars['String'];
    yearlyFuelCostNEDC: Scalars['String'];
    yearlyKilometers: Scalars['String'];
    yearlyTax: Scalars['String'];
};

export type EfficiencyClassGermanyType = {
    co2class?: Maybe<Scalars['String']>;
    co2classWithDischargedBattery?: Maybe<Scalars['String']>;
};

export type EfficiencyClassSwitzerlandType = {
    co2Emissions?: Maybe<LocalizedLabeledValue>;
    electricityConsumption?: Maybe<LocalizedLabeledValue>;
    energyEfficiencyClass: Scalars['String'];
    energyEfficiencyClassLabel?: Maybe<LocalizedString>;
    fuelConsumption?: Maybe<LocalizedLabeledValue>;
};

export type EnergyEfficiency = {
    france?: Maybe<EfficiencyClassFranceType>;
    germany?: Maybe<EfficiencyClassGermanyType>;
    switzerland?: Maybe<EfficiencyClassSwitzerlandType>;
};

export type Equipment = {
    category?: Maybe<EquipmentCategory>;
    code?: Maybe<Scalars['String']>;
    description?: Maybe<LocalizedString>;
    images?: Maybe<Array<Scalars['String']>>;
    name: LocalizedString;
    packageInformation?: Maybe<PackageInformation>;
};

export type EquipmentCategory = {
    sortIndex: Scalars['Int'];
    value: LocalizedValue;
};

export type ExteriorColor = {
    colorGroup: LocalizedColorGroup;
    metallic: Scalars['Boolean'];
    name: LocalizedString;
    value: Scalars['String'];
};

export type ExternalReference = {
    id: Scalars['String'];
    system: Scalars['String'];
};

export type ExternalSystemReference = {
    system: Scalars['String'];
    id: Scalars['String'];
};

export type Financing = {
    productTypes: Array<Scalars['String']>;
    products: Array<FinancingProduct>;
};

export type FinancingProductsArgs = {
    financingProductTypes?: Maybe<Array<Scalars['String']>>;
};

export type FinancingProduct = {
    rate?: Maybe<Rate>;
    type: Scalars['String'];
};

export type FormattedDate = {
    localize: Scalars['String'];
    value: Scalars['DateTime'];
};

export type FormattedDateLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type Hitcount = {
    key: Scalars['String'];
    value: Scalars['Float'];
};

export type Hitcounts = {
    accidentFree: Array<Hitcount>;
    audioCommunicationEquipment: Array<Hitcount>;
    bodyType: Array<Hitcount>;
    comfortAssistanceEquipment: Array<Hitcount>;
    driveSide: Array<Hitcount>;
    drivetrain: Array<Hitcount>;
    ePerformanceEquipment: Array<Hitcount>;
    engineType: Array<Hitcount>;
    exteriorColorGroup: Array<Hitcount>;
    exteriorEquipment: Array<Hitcount>;
    financingProductType: Array<Hitcount>;
    firstRegistrationDate: Array<Hitcount>;
    interiorColorGroup: Array<Hitcount>;
    interiorEquipment: Array<Hitcount>;
    interiorMaterial: Array<Hitcount>;
    maximumPowerHP: Array<Hitcount>;
    maximumPowerKW: Array<Hitcount>;
    mileage: Array<Hitcount>;
    minimumPowerHP: Array<Hitcount>;
    minimumPowerKW: Array<Hitcount>;
    modelCategory: Array<Hitcount>;
    modelGeneration: Array<Hitcount>;
    modelSeries: Array<Hitcount>;
    modelYear: Array<Hitcount>;
    numberOfPreviousOwners: Array<Hitcount>;
    optionPackage: Array<Hitcount>;
    performanceEquipment: Array<Hitcount>;
    radius: Array<Hitcount>;
    rooftopColorGroup: Array<Hitcount>;
    sales: Array<Hitcount>;
    salesIncentives: Array<Hitcount>;
    seller: Array<Hitcount>;
    transmission: Array<Hitcount>;
    vatDeductible: Array<Hitcount>;
    vehicleType: Array<Hitcount>;
    video: Array<Hitcount>;
    wheelSizeEquipment: Array<Hitcount>;
};

export type ICurrencyType = {
    currencyCode: Scalars['String'];
    localize: Scalars['String'];
    value: Scalars['Float'];
};

export type ICurrencyTypeLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type ILocalizedValue = {
    localize?: Maybe<Scalars['String']>;
    value: Scalars['String'];
};

export type ILocalizedValueLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type Interior = {
    color: InteriorColorAttributes;
    description?: Maybe<LocalizedString>;
    imageKeys?: Maybe<Array<Scalars['String']>>;
    material?: Maybe<LocalizedValue>;
    name: LocalizedString;
};

export type InteriorColorAttributes = {
    cockpitColorHexCode: Scalars['String'];
    cockpitStitchingColorHexCode?: Maybe<Scalars['String']>;
    colorGroup: LocalizedInteriorColorGroup;
    seatColorHexCode: Scalars['String'];
    seatStitchingColorHexCode?: Maybe<Scalars['String']>;
};

export type LabeledValue = {
    label: Scalars['String'];
    value: Scalars['String'];
};

export type Listing = {
    additionalCustomerInformationAvailable: Scalars['Boolean'];
    availableFrom?: Maybe<FormattedDate>;
    description?: Maybe<LocalizedString>;
    externalReference?: Maybe<ExternalReference>;
    financing?: Maybe<Financing>;
    firstVisibleAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['String'];
    location?: Maybe<Location>;
    marketplaceId: Scalars['String'];
    onlineOrderableState: OnlineOrderableState;
    price: Price;
    salesIncentives: Array<SalesIncentive>;
    seller: Seller;
    stockNumber: Scalars['String'];
    subtitle: LocalizedString;
    title: LocalizedString;
    updatedAt: Scalars['DateTime'];
    vehicle: PorscheFinderVehicle;
    warranty?: Maybe<Warranty>;
};

export type ListingTypeEdge = {
    cursor: Scalars['String'];
    node: Listing;
};

export type ListingsConnection = {
    edges: Array<ListingTypeEdge>;
    pageInfo: PaginationInfo;
    totalCount: Scalars['Int'];
};

export type LocalizedColorGroup = ILocalizedValue & {
    hexCode?: Maybe<Scalars['String']>;
    localize?: Maybe<Scalars['String']>;
    value: Scalars['String'];
};

export type LocalizedColorGroupLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type LocalizedInteriorColorGroup = ILocalizedValue & {
    hexCode: Scalars['String'];
    localize?: Maybe<Scalars['String']>;
    value: Scalars['String'];
};

export type LocalizedInteriorColorGroupLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type LocalizedLabeledValue = {
    localize?: Maybe<LabeledValue>;
};

export type LocalizedLabeledValueLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type LocalizedModelCategory = ILocalizedValue & {
    localize?: Maybe<Scalars['String']>;
    modelSeries: Scalars['String'];
    value: Scalars['String'];
};

export type LocalizedModelCategoryLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type LocalizedModelGeneration = ILocalizedValue & {
    localize?: Maybe<Scalars['String']>;
    modelSeries: Scalars['String'];
    value: Scalars['String'];
};

export type LocalizedModelGenerationLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type LocalizedSalesFilter = {
    localize: Scalars['String'];
    value: SalesFilter;
};

export type LocalizedSalesFilterLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type LocalizedSortOption = {
    localize: Scalars['String'];
    value: SortOption;
};

export type LocalizedSortOptionLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type LocalizedStandardEquipment = {
    localize?: Maybe<Array<StandardEquipmentCategory>>;
};

export type LocalizedStandardEquipmentLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type LocalizedString = {
    localize?: Maybe<Scalars['String']>;
};

export type LocalizedStringLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type LocalizedTechnicalData = {
    localize?: Maybe<Array<TechnicalDataCategory>>;
};

export type LocalizedTechnicalDataLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type LocalizedValue = ILocalizedValue & {
    localize?: Maybe<Scalars['String']>;
    value: Scalars['String'];
};

export type LocalizedValueLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type LocalizedVehicleHighlights = {
    localize: Characteristics;
};

export type LocalizedVehicleHighlightsLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type LocalizedVehicleType = {
    localize: Scalars['String'];
    value: VehicleType;
};

export type LocalizedVehicleTypeLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type Location = {
    latitude: Scalars['Float'];
    longitude: Scalars['Float'];
};

export enum MileageUnit {
    KILOMETER = 'kilometer',
    MILE = 'mile',
}

export type NumberUnit = {
    localize: Scalars['String'];
    unit: Scalars['String'];
    value: Scalars['Float'];
};

export type NumberUnitLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export enum OnlineOrderableState {
    AVAILABLE = 'available',
    RESERVED = 'reserved',
    UNAVAILABLE = 'unavailable',
}

export type PackageInformation = {
    basicContent: Array<PackageInformationContent>;
    upgradedContent: Array<PackageInformationContent>;
};

export type PackageInformationContent = {
    name: LocalizedString;
};

export type PaginationInfo = {
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
};

export type Power = {
    horsepower: NumberUnit;
    kilowatt: NumberUnit;
    localizeLabel?: Maybe<Scalars['String']>;
    localizeValue?: Maybe<Scalars['String']>;
};

export type PowerLocalizeLabelArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type PowerLocalizeValueArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type Price = ICurrencyType & {
    currencyCode: Scalars['String'];
    downPayment?: Maybe<DownPayment>;
    localize: Scalars['String'];
    /** The type of recycle fee the seller offers for this car. Only used in Japan. */
    recycleFeeType?: Maybe<LocalizedValue>;
    value: Scalars['Float'];
    vatDeductible?: Maybe<Scalars['Boolean']>;
};

export type PriceLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type Rate = ICurrencyType & {
    currencyCode: Scalars['String'];
    localize: Scalars['String'];
    value: Scalars['Float'];
};

export type RateLocalizeArgs = {
    languageTag?: Maybe<Scalars['String']>;
};

export type RooftopColor = {
    colorGroup?: Maybe<LocalizedValue>;
    name: LocalizedString;
    value: Scalars['String'];
};

export enum SalesFilter {
    ONLINE_ORDERABLE = 'online_orderable',
    SALES_INCENTIVE = 'sales_incentive',
}

export type SalesIncentive = {
    description: LocalizedString;
    title: LocalizedString;
};

export type Seller = {
    address?: Maybe<LocalizedString>;
    email: Array<Scalars['String']>;
    id: Scalars['String'];
    name: LocalizedString;
    phoneNumber?: Maybe<Scalars['String']>;
    porschePartnerNumber: Scalars['String'];
};

export enum SortOption {
    CLOSEST = 'closest',
    MILEAGE_ASC = 'mileage_asc',
    MILEAGE_DESC = 'mileage_desc',
    MONTHLY_RATE_ASC = 'monthly_rate_asc',
    MONTHLY_RATE_DESC = 'monthly_rate_desc',
    NEWEST = 'newest',
    PRICE_ASC = 'price_asc',
    PRICE_DESC = 'price_desc',
    RECOMMENDED_DESC = 'recommended_desc',
}

export type StandardEquipmentCategory = {
    items: Array<Scalars['String']>;
    name: Scalars['String'];
};

export type TechnicalDataCategory = {
    items: Array<TechnicalDataItem>;
    name: Scalars['String'];
};

export type TechnicalDataItem = {
    disclaimer?: Maybe<Scalars['String']>;
    label: Scalars['String'];
    value: Scalars['String'];
};

export type UnavailableListing = {
    additionalCustomerInformationAvailable: Scalars['Boolean'];
    availableFrom?: Maybe<FormattedDate>;
    description?: Maybe<LocalizedString>;
    externalReference?: Maybe<ExternalReference>;
    financing?: Maybe<Financing>;
    firstVisibleAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['String'];
    location?: Maybe<Location>;
    marketplaceId: Scalars['String'];
    onlineOrderableState: OnlineOrderableState;
    price: Price;
    salesIncentives: Array<SalesIncentive>;
    seller: Seller;
    state: Scalars['String'];
    stockNumber: Scalars['String'];
    subtitle: LocalizedString;
    title: LocalizedString;
    updatedAt: Scalars['DateTime'];
    vehicle: PorscheFinderVehicle;
    warranty?: Maybe<Warranty>;
};

export type UnladenWeight = {
    kilogramm: NumberUnit;
    pound: NumberUnit;
};

export type PorscheFinderVehicle = {
    accidentFree?: Maybe<Scalars['Boolean']>;
    bodyType: LocalizedValue;
    characteristics: LocalizedVehicleHighlights;
    commissionNumber?: Maybe<Scalars['String']>;
    condition: LocalizedValue;
    consumptionEmission?: Maybe<ConsumptionEmission>;
    displacement?: Maybe<Displacement>;
    driveSide?: Maybe<LocalizedValue>;
    drivetrain: LocalizedValue;
    energyEfficiency?: Maybe<EnergyEfficiency>;
    engineType: LocalizedValue;
    equipment: Array<Equipment>;
    exteriorColor: ExteriorColor;
    factoryTyreInformationAvailable: Scalars['Boolean'];
    firstRegistrationDate?: Maybe<FormattedDate>;
    fuelType?: Maybe<LocalizedValue>;
    fullServiceHistory?: Maybe<Scalars['Boolean']>;
    images: VehicleImagesConnection;
    imagesType?: Maybe<Scalars['String']>;
    interior: Interior;
    mileage: NumberUnit;
    modelCategory?: Maybe<LocalizedValue>;
    modelGeneration: LocalizedValue;
    modelSeries: LocalizedValue;
    modelYear: Scalars['Int'];
    name: LocalizedString;
    nextGeneralInspection?: Maybe<FormattedDate>;
    numberOfCylinders?: Maybe<Scalars['Int']>;
    numberOfPreviousOwners: Scalars['Int'];
    orderTypeCode: Scalars['String'];
    power: Power;
    productionDate?: Maybe<Scalars['DateTime']>;
    rooftopColor?: Maybe<RooftopColor>;
    standardEquipment?: Maybe<LocalizedStandardEquipment>;
    technicalData?: Maybe<LocalizedTechnicalData>;
    transmission: LocalizedValue;
    unladenWeight?: Maybe<UnladenWeight>;
    usageType: LocalizedValue;
    vehicleVideo?: Maybe<VehicleVideo>;
    vin: Scalars['String'];
};

export type PorscheFinderVehicleImagesArgs = {
    after?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
};

export type VehicleCharacteristic = {
    highlighted: Scalars['Boolean'];
    value: Scalars['String'];
};

export type VehicleImage = {
    id: Scalars['String'];
    variants: Array<VehicleImageVariant>;
};

export type VehicleImageVariantsArgs = {
    maximumWidth?: Maybe<Scalars['Int']>;
};

export type VehicleImageTypeEdge = {
    cursor: Scalars['String'];
    node: VehicleImage;
};

export type VehicleImageVariant = {
    height: Scalars['Int'];
    mimeType: Scalars['String'];
    url: Scalars['String'];
    width: Scalars['Int'];
};

export type VehicleImagesConnection = {
    edges: Array<VehicleImageTypeEdge>;
    pageInfo: PaginationInfo;
    totalCount: Scalars['Int'];
};

export enum VehicleType {
    CLASSIC = 'classic',
    NEW = 'new',
    PORSCHE_APPROVED = 'porsche_approved',
    USED = 'used',
}

export type VehicleVideo = {
    id: Scalars['String'];
};

export type Warranty = {
    porscheApproved: Scalars['Boolean'];
};

export type PromoCodePeriod = {
    start: Scalars['DateTime'];
    startTimeZone?: Maybe<Scalars['String']>;
    end: Scalars['DateTime'];
    endTimeZone?: Maybe<Scalars['String']>;
};

export enum PromoCodeUnit {
    NOTUSED = 'NotUsed',
    PERCENTAGE = 'Percentage',
    CURRENCY = 'Currency',
}

export type PromoCode = {
    id: Scalars['ObjectID'];
    countryId: Scalars['ObjectID'];
    dealerIds: Array<Scalars['ObjectID']>;
    identifier: Scalars['String'];
    description: Scalars['String'];
    unit: PromoCodeUnit;
    value?: Maybe<Scalars['Float']>;
    initialQuantity: Scalars['Int'];
    remainingQuantity: Scalars['Int'];
    deductValue: Scalars['Boolean'];
    period: PromoCodePeriod;
    isActive: Scalars['Boolean'];
    version: Version;
    activeApplicationIds?: Maybe<Array<Scalars['ObjectID']>>;
};

export type PromoCodeList = {
    items: Array<PromoCode>;
    count?: Maybe<Scalars['Int']>;
};

export type PromoReservation = {
    id: Scalars['ObjectID'];
    applicationId: Scalars['ObjectID'];
    promoCodeId: Scalars['ObjectID'];
};

export enum LoginType {
    ADMIN = 'ADMIN',
    CI = 'CI',
}

export type AvailableZoneForAuthentication = {
    id: Scalars['ObjectID'];
    name: Scalars['IntlValue'];
    code: Scalars['String'];
};

export type AvailableDealerForAuthentication = {
    id: Scalars['ObjectID'];
    name: Scalars['IntlValue'];
    identifier: Scalars['IntlValue'];
};

export type AvailableCountriesForAuthentication = {
    id: Scalars['ObjectID'];
    name: Scalars['String'];
    mlcName?: Maybe<Scalars['IntlValue']>;
    code: Scalars['String'];
    channelSetting: ChannelSetting;
    dealers: Array<AvailableDealerForAuthentication>;
    zones: Array<AvailableZoneForAuthentication>;
};

export type AvailableCompanyForAuthentication = {
    id: Scalars['ObjectID'];
    name: Scalars['IntlValue'];
    code: Scalars['String'];
    countries: Array<AvailableCountriesForAuthentication>;
};

export type AuthenticateResponse = {
    ok: Scalars['Boolean'];
    token?: Maybe<Scalars['String']>;
    errors: Array<Error>;
    user?: Maybe<User>;
    hasExistingSession?: Maybe<Scalars['Boolean']>;
};

export type Role = {
    id: Scalars['ObjectID'];
    parentId?: Maybe<Scalars['ObjectID']>;
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    countryId: Scalars['ObjectID'];
    country: Country;
    permissions: Array<RolePermission>;
    version: SimpleVersion;
    users: Array<User>;
};

export type RoleList = {
    items: Array<Role>;
    count?: Maybe<Scalars['Int']>;
};

export type RolePermission = {
    code: PermissionCode;
    level: PermissionLevel;
};

export enum PermissionCode {
    CI_NEW_CAR_CHANNEL = 'CI_NEW_CAR_CHANNEL',
    CI_USED_CAR_CHANNEL = 'CI_USED_CAR_CHANNEL',
    CI_EXPRESS_CHANNEL = 'CI_EXPRESS_CHANNEL',
    CI_EVENT_CHANNEL = 'CI_EVENT_CHANNEL',
    CI_APPLICATIONS = 'CI_APPLICATIONS',
    CI_RESERVATIONS = 'CI_RESERVATIONS',
    CI_INSURANCE_APPLICATIONS = 'CI_INSURANCE_APPLICATIONS',
    CI_LEADS = 'CI_LEADS',
    CI_CUSTOMERS = 'CI_CUSTOMERS',
    CI_RECALCULATE = 'CI_RECALCULATE',
    CI_RECALCULATE_INTEREST_RATE = 'CI_RECALCULATE_INTEREST_RATE',
    CI_RECALCULATE_RESIDUAL_VALUE = 'CI_RECALCULATE_RESIDUAL_VALUE',
    CI_APPOINTMENT = 'CI_APPOINTMENT',
    ADMIN_LOGIN = 'ADMIN_LOGIN',
    ADMIN_APPLICATIONS = 'ADMIN_APPLICATIONS',
    ADMIN_RESERVATIONS = 'ADMIN_RESERVATIONS',
    ADMIN_INSURANCE_APPLICATIONS = 'ADMIN_INSURANCE_APPLICATIONS',
    ADMIN_LEADS = 'ADMIN_LEADS',
    ADMIN_CUSTOMERS = 'ADMIN_CUSTOMERS',
    ADMIN_FINANCE = 'ADMIN_FINANCE',
    ADMIN_VEHICLES = 'ADMIN_VEHICLES',
    ADMIN_USERS = 'ADMIN_USERS',
    ADMIN_ROLES = 'ADMIN_ROLES',
    ADMIN_ROLE_HIERARCHY = 'ADMIN_ROLE_HIERARCHY',
    ADMIN_MAINTENANCE = 'ADMIN_MAINTENANCE',
    ADMIN_PROMO_CODE = 'ADMIN_PROMO_CODE',
    ADMIN_NEW_CAR_CHANNEL = 'ADMIN_NEW_CAR_CHANNEL',
    ADMIN_USED_CAR_CHANNEL = 'ADMIN_USED_CAR_CHANNEL',
    ADMIN_EXPRESS_CHANNEL = 'ADMIN_EXPRESS_CHANNEL',
    ADMIN_EVENT_CHANNEL = 'ADMIN_EVENT_CHANNEL',
    ADMIN_CONSENT_DECLARATION = 'ADMIN_CONSENT_DECLARATION',
    ADMIN_FINDER_VEHICLE = 'ADMIN_FINDER_VEHICLE',
    ADMIN_RECALCULATE = 'ADMIN_RECALCULATE',
    ADMIN_RECALCULATE_INTEREST_RATE = 'ADMIN_RECALCULATE_INTEREST_RATE',
    ADMIN_RECALCULATE_RESIDUAL_VALUE = 'ADMIN_RECALCULATE_RESIDUAL_VALUE',
    ADMIN_APPOINTMENT = 'ADMIN_APPOINTMENT',
}

export enum PermissionLevel {
    NONE = 'NONE',
    VIEW = 'VIEW',
    MANAGE = 'MANAGE',
}

export enum RuntimeType {
    AFC = 'AFC',
    PME = 'PME',
}

export type PorschePaymentConfiguration = {
    apiKey: Scalars['String'];
    secret: Scalars['String'];
};

export type AppointmentRuntimeSettings = {
    availabilityDays: Array<Scalars['String']>;
    availabilityTimes: Array<Scalars['String']>;
    slotPerInterval: Scalars['Int'];
    slotHourStart: Scalars['Int'];
    slotHourEnd: Scalars['Int'];
    slotMinuteInterval: Scalars['Int'];
    slotMinDays: Scalars['Int'];
    slotMaxDays: Scalars['Int'];
};

export type InsuranceRuntimeSettings = {
    age: Scalars['Int'];
    yearsOfDriving: Scalars['Int'];
    ncd: Scalars['Int'];
};

export type RuntimeSettings = {
    /** Sentry environment */
    environment?: Maybe<Scalars['String']>;
    /** Should the application integrate customer birthday */
    useCustomerBirthDay: Scalars['Boolean'];
    /** Should the application integrate customer nationality */
    useCustomerNationality: Scalars['Boolean'];
    /** Should the caculator integrate trade-in fields */
    useTradeInFields: Scalars['Boolean'];
    /** Casing to be used by the application for titles and buttons */
    casing: Scalars['String'];
    /** Application title */
    title: Scalars['String'];
    /** Customer Interface Theme */
    theme: Scalars['String'];
    /** CI Base Link */
    ciBaseLink: Scalars['String'];
    /** CKD configuration */
    ckdConfiguration: Scalars['JSONObject'];
    /** Porsche Payment Configuration */
    porschePaymentConfiguration: PorschePaymentConfiguration;
    /** Runtime type */
    type: RuntimeType;
    /** CDN base URL for public assets */
    cdnLink: Scalars['String'];
    /** Marketing Reconsent Variant Id */
    marketingReconsentVariant: Scalars['String'];
    /** System from email name */
    systemEmail: Scalars['String'];
    /** Appointment setting runtime */
    appointment: AppointmentRuntimeSettings;
    /** Insurance setting runtime */
    insurance: InsuranceRuntimeSettings;
};

export type Scope = {
    company?: Maybe<Company>;
    country?: Maybe<Country>;
    zone?: Maybe<Zone>;
};

export type StatisticMonthlyResultItem = {
    date?: Maybe<Scalars['DateTime']>;
    name?: Maybe<Scalars['String']>;
    count?: Maybe<Scalars['Int']>;
    amount?: Maybe<Scalars['Float']>;
    alias?: Maybe<Scalars['String']>;
};

export type StatisticMonthlyResult = {
    currentMonth: Array<StatisticMonthlyResultItem>;
    lastMonth: Array<StatisticMonthlyResultItem>;
};

export type StatisticCounterResultMonth = {
    submitted: Scalars['Int'];
    approved: Scalars['Int'];
    submittedTurnAround: Scalars['Float'];
    customerInfoTurnAround: Scalars['Float'];
};

export type StatisticCounterResult = {
    currentMonth?: Maybe<StatisticCounterResultMonth>;
    lastMonth?: Maybe<StatisticCounterResultMonth>;
};

export type TermsAndConditions = {
    id: Scalars['ObjectID'];
    countryId: Scalars['ObjectID'];
    title: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    version: SimpleVersion;
};

export type User = {
    id: Scalars['ObjectID'];
    username: Scalars['String'];
    name: Scalars['String'];
    email: Scalars['String'];
    isSuperUser: Scalars['Boolean'];
    isActive: Scalars['Boolean'];
    isDeleted: Scalars['Boolean'];
    hasSentNotificationEmail: Scalars['Boolean'];
    hasPassword: Scalars['Boolean'];
    phonePrefix: Scalars['String'];
    phone: Scalars['String'];
    lastLoginAt?: Maybe<Scalars['DateTime']>;
    permissions: Array<UserPermission>;
    alias?: Maybe<Scalars['String']>;
    availableCompanies?: Maybe<Array<AvailableCompanyForAuthentication>>;
    image?: Maybe<Attachment>;
    status: Scalars['String'];
    version?: Maybe<SimpleVersion>;
};

export type UserList = {
    items: Array<User>;
    count?: Maybe<Scalars['Int']>;
};

export enum ViewDataType {
    SINGLE = 'SINGLE',
    DOWNLINE = 'DOWNLINE',
}

export type UserPermission = {
    companyId: Scalars['ObjectID'];
    countryId: Scalars['ObjectID'];
    country: Country;
    roleId: Scalars['ObjectID'];
    role: Role;
    isParallelView: Scalars['Boolean'];
    superiorId?: Maybe<Scalars['ObjectID']>;
    superior?: Maybe<User>;
    viewData: ViewDataType;
    dealerIds: Array<Scalars['ObjectID']>;
    dealers: Array<Dealer>;
};

export type UserPasswordHistory = {
    password: Scalars['String'];
    replacedAt: Scalars['DateTime'];
};

export type DeleteUserResponse = {
    user: User;
    deleted: Scalars['Boolean'];
    hasApplicationsToReassign: Scalars['Boolean'];
};

export type VariantPrice = {
    value: Scalars['Float'];
    zoneId: Scalars['ObjectID'];
};

export type TechnicalDetails = {
    co2Emissions?: Maybe<Scalars['Float']>;
    cylinderArrangementNo?: Maybe<Scalars['Float']>;
    displacement?: Maybe<Scalars['Float']>;
    driveTrain?: Maybe<Scalars['String']>;
    fuelConsumption?: Maybe<Scalars['Float']>;
    numberOfAirbags?: Maybe<Scalars['Int']>;
    ratedOutput?: Maybe<Scalars['Float']>;
    ratedTorque?: Maybe<Scalars['Float']>;
    transmission?: Maybe<Scalars['String']>;
};

export type UsedCarDetails = {
    depreciation?: Maybe<Scalars['Float']>;
    mileage?: Maybe<Scalars['Float']>;
    registrationDate?: Maybe<Scalars['DateTime']>;
    omv?: Maybe<Scalars['Float']>;
    arf?: Maybe<Scalars['Float']>;
    coe?: Maybe<Scalars['Float']>;
    deregistrationValue?: Maybe<Scalars['Float']>;
    roadTax?: Maybe<Scalars['Float']>;
    noOfOwners?: Maybe<Scalars['Int']>;
};

export enum PreOwnerIdType {
    BUSINESS = 'Business',
    CLUBORASSOCIATIONORORGANIZATION = 'ClubOrAssociationOrOrganization',
    COMPANY = 'Company',
    FOREIGNCOMPANY = 'ForeignCompany',
    /** NRIC for foreigners in SG */
    FOREIGNIDENTIFICATIONNUMBER = 'ForeignIdentificationNumber',
    FOREIGNPASSPORT = 'ForeignPassport',
    GOVERNMENT = 'Government',
    LIMITEDLIABILITYPARTNERSHIP = 'LimitedLiabilityPartnership',
    LIMITEDPARTNERSHIP = 'LimitedPartnership',
    /** NRIC should be a abbreviation dedicated to SG, the term below is kind of inaccurate in this regard */
    MALAYSIANRIC = 'MalaysiaNric',
    PROFESSIONAL = 'Professional',
    SINGAPORENRIC = 'SingaporeNric',
    STATUTORYBOARD = 'StatutoryBoard',
}

export type PreOwnedCarDetails = {
    plateNumber: Scalars['String'];
    originalRegistrationDate: Scalars['DateTime'];
    coeExpiryDate?: Maybe<Scalars['DateTime']>;
    /** Quota Premium Paid */
    qpPaid?: Maybe<Scalars['Float']>;
    /** Open Market Value, use abbreviation to align with field on variant */
    omv?: Maybe<Scalars['Float']>;
    /** suffix of specified owner id, currently last 4 characters */
    ownerIdSuffix?: Maybe<Scalars['String']>;
    ownerIdType?: Maybe<PreOwnerIdType>;
    inspected?: Maybe<Scalars['Boolean']>;
    startingPrice?: Maybe<Scalars['Float']>;
    assetCondition?: Maybe<Scalars['String']>;
    displacement?: Maybe<Scalars['Float']>;
};

export type Variant = {
    id: Scalars['ObjectID'];
    countryId: Scalars['ObjectID'];
    modelId: Scalars['ObjectID'];
    model: Model;
    assetCondition: AssetCondition;
    channels: ChannelList;
    identifier: Scalars['String'];
    name: Scalars['IntlValue'];
    order: Scalars['Int'];
    seatingCapacity: Scalars['Int'];
    year: Scalars['Int'];
    engineType?: Maybe<EngineType>;
    prices: Array<VariantPrice>;
    description?: Maybe<Scalars['IntlValue']>;
    features?: Maybe<Scalars['IntlValue']>;
    technicalDetails: TechnicalDetails;
    usedCarDetails: UsedCarDetails;
    /** details for pre-owned cars, similar to used car details but dedicated for express channel */
    preOwnedCarDetails?: Maybe<PreOwnedCarDetails>;
    isActive: Scalars['Boolean'];
    isDeleted: Scalars['Boolean'];
    images: Array<Maybe<Attachment>>;
    /** Available options */
    options: Array<Option>;
    /** Inventory object */
    inventory?: Maybe<Inventory>;
    /** Versioning meta data */
    version: Version;
    /** Alias for `version.id` */
    versionId: Scalars['ObjectID'];
};

export type ChannelList = {
    new: Array<Scalars['ObjectID']>;
    used: Array<Scalars['ObjectID']>;
    express: Scalars['Boolean'];
};

export type VariantList = {
    items: Array<Variant>;
    count?: Maybe<Scalars['Int']>;
};

export enum AssetCondition {
    DEMO = 'Demo',
    NEW = 'New',
    USED = 'Used',
}

export enum Channel {
    NEW = 'New',
    USED = 'Used',
    EXPRESS = 'Express',
    EVENT = 'Event',
}

export enum EngineType {
    PETROL = 'Petrol',
    ELECTRIC = 'Electric',
    HYBRID = 'Hybrid',
}

export type SimpleVersion = {
    createdAt: Scalars['DateTime'];
    createdById?: Maybe<Scalars['ObjectID']>;
    createdBy?: Maybe<User>;
    updatedAt: Scalars['DateTime'];
    updatedById?: Maybe<Scalars['ObjectID']>;
    updatedBy?: Maybe<User>;
};

export type Version = {
    id: Scalars['ObjectID'];
    isOutdated: Scalars['Boolean'];
    createdAt: Scalars['DateTime'];
    createdById?: Maybe<Scalars['ObjectID']>;
    createdBy?: Maybe<User>;
    updatedAt: Scalars['DateTime'];
    updatedById?: Maybe<Scalars['ObjectID']>;
    updatedBy?: Maybe<User>;
};

export type Zone = {
    id: Scalars['ObjectID'];
    companyId: Scalars['ObjectID'];
    company: Company;
    countryId: Scalars['ObjectID'];
    country: Country;
    name: Scalars['IntlValue'];
    code: Scalars['String'];
    taxRate: Scalars['Float'];
    zipCode?: Maybe<Scalars['String']>;
    timezone: Scalars['String'];
    hasConsents: Scalars['Boolean'];
    consentsAndDeclarations: Array<ConsentOrDeclaration>;
    isDeleted: Scalars['Boolean'];
    version?: Maybe<SimpleVersion>;
};

export type ZoneList = {
    items: Array<Zone>;
    count?: Maybe<Scalars['Int']>;
};
