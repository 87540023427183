import { omit, isObject, isArray } from 'lodash/fp';

// eslint-disable-next-line import/prefer-default-export
export function deepOmit(keys, object) {
    const omitKeys = omit(keys);
    const omitFunction = item => {
        if (!isObject(item)) {
            // nothing to omit
            return item;
        }

        if (isArray(item)) {
            return item.map(value => omitFunction(value));
        }

        const newItem = {};
        for (const [key, value] of Object.entries(omitKeys(item))) {
            newItem[key] = omitFunction(value);
        }

        return newItem;
    };

    if (arguments.length === 1) {
        return omitFunction;
    }

    return omitFunction(object);
}
