import CarAndPriceDisplayField from '../components/fields/display/CarAndPriceDisplayField';
import fieldTemplate from './fieldTemplate';

/* this is a proxy field for carModel and carPrice */
const carModelAndPriceProxyField = {
    // copy the template
    ...fieldTemplate,

    // setup meta
    // the field is the first one in the rendering
    label: t => t('calculator.label.carModelAndPrice'), // label only shown when there is makes
    weight: 0,
    gridSize: 2,

    // setup states
    isViewable: (context, value, theme) => !theme.calculator.singleColumn,

    // setup display rendering
    displayComponent: CarAndPriceDisplayField,

    // snapshot configuration
    snapshot: {
        ...fieldTemplate.snapshot,
        // CarAndPriceDisplayField has a complexity above average
        // it is fully capable to handle snapshot on its own
        component: CarAndPriceDisplayField,
    },
};

export default carModelAndPriceProxyField;
