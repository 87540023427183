import { useQuery, useApolloClient } from '@apollo/client';
import { Portlet, PortletButton } from '@appvantageasia/afc-ui';
import { differenceWith, isEmpty } from 'lodash';
import { get, getOr, isNil, isEqual } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, FieldArray, fieldArrayFieldsPropTypes } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { ViewDataType } from '../../../../../schema';
import { getUser } from '../../../../../selectors';
import { yesNoOptions, viewDataOptions } from '../../../../../shared/constants/options';
import { getGlobalPermissions } from '../../../../../utils/permissions';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import SwitchField from '../../../../template/Switch';
import Button from '../../../../ui/Button';
import { getCompanies, getUsers, getDisabledDealerIds, getUserById, getDataByCountryId } from './Permission.graphql';
import { useFormContext } from './context';

const Item = ({ name, value: formValue, index, fields, isSuperUser, userId, currentUser }) => {
    const { ct } = useContentTranslation();
    const [display, setDisplay] = useState(false);
    const { values, change, disabled, initialValues, isUpdate } = useFormContext();
    const viewData = get('viewData', formValue);
    const roleId = get('roleId', formValue);
    const companyId = get('companyId', formValue);
    const countryId = get('countryId', formValue);
    const dealerIds = get('dealerIds', formValue);
    const superiorId = get('superiorId', formValue);

    // get company list
    const { data: companyList } = useQuery(getCompanies, { fetchPolicy: 'cache-and-network' });
    const companyOptions = useMemo(
        () =>
            getOr([], 'companies.items', companyList).map(item => ({
                ...item,
                label: ct(item?.label),
            })),
        [ct, companyList]
    );

    // get the role, dealers by selected company
    const { data } = useQuery(getDataByCountryId, {
        skip: isNil(countryId),
        fetchPolicy: 'cache-and-network',
        variables: { countryId },
    });

    const { dealers, roleOptions } = useMemo(
        () => ({
            roleOptions: getOr([], 'roles.items', data),
            dealers: getOr([], 'dealers.items', data).map(item => ({
                ...item,
                label: ct(item?.label),
            })),
        }),
        [ct, data]
    );

    const { data: disabledDealerIds } = useQuery(getDisabledDealerIds, {
        skip: isEmpty(dealers) || isNil(values.id),
        fetchPolicy: 'cache-and-network',
        variables: {
            assigneeId: values.id,
        },
    });

    // get the user by superior
    const { data: getUserByIdData } = useQuery(getUserById, {
        skip: isNil(superiorId),
        fetchPolicy: 'cache-and-network',
        variables: {
            id: superiorId,
        },
    });

    const rolePermissionPayload = useMemo(() => {
        const countries = companyOptions.find(company => company.value === companyId)?.countries || [];

        // get countries on that company
        const countryIds = countries.map(country => country.value);
        const rolePermissions = [];
        // get permission payload of current user
        countryIds.forEach(id => {
            const getGlobalPermission = getGlobalPermissions(currentUser, { id });
            const permissionPayload = {
                countryId: id,
                permissions: getGlobalPermission,
            };

            rolePermissions.push(permissionPayload);
        });

        return rolePermissions;
    }, [companyId, companyOptions, currentUser]);

    // if not super user fetch the country based on role permission
    const countryOptions = useMemo(() => {
        const countries = companyOptions.find(company => company.value === companyId)?.countries || [];
        // for superuser show all country based on selected company
        if (isSuperUser) {
            return countries;
        }

        return countries.filter(company => {
            const rolePermissionByCompany = rolePermissionPayload.find(role => role.countryId === company.value);
            const { permissions } = rolePermissionByCompany;

            return permissions.mayManageUsers;
        });
    }, [companyId, companyOptions, rolePermissionPayload, isSuperUser]);

    const superiorDealerIds = useMemo(() => {
        const userLists = getUserByIdData?.user?.permissions || [];
        // get superior dealerId by selected company/country
        const superiorDealer = userLists.find(user => user.companyId === companyId && user.countryId === countryId);

        if (superiorDealer) {
            return superiorDealer.dealerIds;
        }

        return undefined;
    }, [companyId, countryId, getUserByIdData]);

    const currentDealerIds = get('dealerIds', formValue);

    const dealerOptions = useMemo(() => {
        return dealers
            .filter(({ value }) => isNil(superiorDealerIds) || superiorDealerIds.includes(value))
            .map(({ value, label }) => {
                const result = { value, label };

                if (disabledDealerIds?.application?.includes(value) || disabledDealerIds?.inferior?.includes(value)) {
                    result.isFixed = true;
                }

                return result;
            });
    }, [dealers, disabledDealerIds, superiorDealerIds]);

    useEffect(() => {
        // return if no dealer options
        if (dealerOptions?.length === 0) {
            return;
        }

        // auto populate with superior dealer id if there's only one
        if (superiorDealerIds?.length === 1) {
            change(`${name}.dealerIds`, superiorDealerIds);
        }
    }, [dealerOptions, superiorDealerIds, currentDealerIds, change, name]);

    const client = useApolloClient();
    const [initSuperiors, setInitSuperiors] = useState(false);
    const [superiorOptions, setSuperiorOptions] = useState([]);
    const selectedRole = useMemo(() => roleOptions.find(i => i.value === roleId), [roleId, roleOptions]);

    useEffect(() => {
        change(`${name}.__exclude.selectedRole`, selectedRole);
    }, [change, selectedRole, name]);

    const setSuperiors = useCallback(
        value => {
            if (value) {
                const find = roleOptions.find(i => i.value === value);

                if (find && find.parentId) {
                    client.query({ query: getUsers, variables: { countryId } }).then(({ data: { users } }) => {
                        const userLists = users?.items.filter(user =>
                            user.permissions.find(permission => permission.roleId === find.parentId)
                        );

                        const roleIds = [];
                        userLists.forEach(user =>
                            user.permissions.forEach(permission => roleIds.push(permission.roleId))
                        );

                        const hasSuperior = roleIds.some(id => id === find.parentId);
                        // not its own user value
                        const result = userLists.filter(user => user.value !== values.id);

                        if (hasSuperior) {
                            setSuperiorOptions(result);
                        }

                        setInitSuperiors(true);
                    });
                } else {
                    setSuperiorOptions([]);
                    setInitSuperiors(true);
                }
            }
        },
        [client, countryId, roleOptions, values]
    );

    // action on role changes
    const onRoleChange = useCallback(
        value => {
            setSuperiors(value);
            change(`${name}.superiorId`, null);
        },
        [change, setSuperiors, name]
    );

    // initial value
    useEffect(() => {
        if (initialValues?.permissions) {
            const initialViewData = initialValues?.permissions[index]?.viewData || ViewDataType.SINGLE;
            const parallelView = initialValues?.permissions[index]?.isParallelView ?? true;

            change(`${name}.viewData`, initialViewData);
            change(`${name}.isParallelView`, parallelView);
        } else {
            change(`${name}.viewData`, ViewDataType.SINGLE);
            change(`${name}.isParallelView`, true);
        }
    }, [initialValues, name, index, change]);

    // set isParallelView to false if view data is single
    const previousViewData = useRef(null);
    useEffect(() => {
        if (viewData === ViewDataType.SINGLE && viewData !== previousViewData.current) {
            change(`${name}.isParallelView`, false);
            previousViewData.current = viewData;
        }

        if (roleId && !initSuperiors && !!roleOptions.length) {
            setSuperiors(roleId);
        }
    }, [viewData, change, previousViewData, values, setSuperiors, initSuperiors, roleOptions.length, name, roleId]);

    const handleDelete = () => {
        // remove on that fields
        fields.splice(index, 1);
    };

    // is the superior disabled
    const superiorDisabled = useMemo(() => !roleOptions.find(i => i.value === roleId)?.parentId, [roleId, roleOptions]);
    // enable role and dealer selection, only after country selection
    const hasSelectedCountry = useMemo(() => countryOptions.length < 1 || !countryId, [countryId, countryOptions]);

    // get current user permission
    const { data: getCurrentUser } = useQuery(getUserById, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: userId,
        },
    });

    // validate onshow based on permission
    useEffect(() => {
        // display if there is only one field
        if (isSuperUser || fields.length <= 1) {
            setDisplay(true);
        }

        if (initialValues?.permissions && getCurrentUser) {
            // get countryId from initialvalue
            const countryIds = initialValues?.permissions.map(x => x.countryId);
            const rolePermissions = [];
            // get permission payload of current user
            countryIds.forEach(id => {
                const getGlobalPermission = getGlobalPermissions(currentUser, { id });
                const permissionPayload = {
                    countryId: id,
                    permissions: getGlobalPermission,
                };

                rolePermissions.push(permissionPayload);
            });

            // filter by role permission
            const filterPermissionByRolePermission = initialValues?.permissions.filter(x => {
                const rolePermissionByCompany = rolePermissions.find(role => role.countryId === x.countryId);
                const { permissions } = rolePermissionByCompany;

                return permissions.mayManageUsers;
            });

            const availableCountryIds = getCurrentUser?.user.permissions.map(user => user.countryId);
            const availableCompanyIds = getCurrentUser?.user.permissions.map(user => user.companyId);

            // filter by assigned permission on that user
            const availablePermission = filterPermissionByRolePermission.filter(
                permission =>
                    availableCountryIds.some(id => id === permission.countryId) &&
                    availableCompanyIds.some(id => id === permission.companyId)
            );

            const invalidPermission = differenceWith([fields.get(index)], availablePermission, isEqual);
            if (isEmpty(invalidPermission)) {
                setDisplay(true);
            }
        }
    }, [currentUser, fields, getCurrentUser, index, initialValues, isSuperUser]);

    return (
        display && (
            <Portlet
                action={
                    isSuperUser && fields.length > 1 && <PortletButton onClick={handleDelete}>Delete</PortletButton>
                }
                name="Permission"
                closable
                open
            >
                <div style={{ position: 'relative' }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <DropdownField
                                    disabled={!isSuperUser && isUpdate}
                                    label="Company"
                                    name={`${name}.companyId`}
                                    options={companyOptions}
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <DropdownField
                                    disabled={!isSuperUser && isUpdate}
                                    label="Country"
                                    name={`${name}.countryId`}
                                    options={countryOptions}
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <DropdownField
                                    disabled={hasSelectedCountry}
                                    label="Assign to"
                                    name={`${name}.dealerIds`}
                                    options={dealerOptions}
                                    multi
                                    noSort
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <DropdownField
                                    disabled={hasSelectedCountry || !dealerIds?.length || disabled}
                                    label="Role"
                                    name={`${name}.roleId`}
                                    onChangeEvent={onRoleChange}
                                    options={roleOptions}
                                    placeholder="Select Role"
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <DropdownField
                                    disabled={superiorDisabled || disabled}
                                    label="Superior"
                                    name={`${name}.superiorId`}
                                    options={superiorOptions}
                                    placeholder="Select Superior"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="View Data">
                                    <Field
                                        component={SwitchField}
                                        disabled={disabled}
                                        name={`${name}.viewData`}
                                        options={viewDataOptions}
                                    />
                                </FieldContainer>
                            </div>
                            {viewData === ViewDataType.DOWNLINE && (
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <FieldContainer label="Parallel">
                                        <Field
                                            component={SwitchField}
                                            disabled={disabled}
                                            name={`${name}.isParallelView`}
                                            options={yesNoOptions}
                                        />
                                    </FieldContainer>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Portlet>
        )
    );
};

Item.propTypes = {
    currentUser: PropTypes.shape({ permissions: PropTypes.arrayOf(PropTypes.shape({}).isRequired) }).isRequired,
    fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
    index: PropTypes.number.isRequired,
    isSuperUser: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    value: PropTypes.shape({}).isRequired,
};

const PermissionComponent = ({ fields, disabled }) => {
    // get user permission
    const currentUser = useSelector(getUser);
    const { isSuperUser, id } = currentUser;

    // initial only show one permission for first creation
    useEffect(() => {
        if (fields.length < 1) {
            fields.push();
        }
    }, []);

    const addPermission = useCallback(() => {
        fields.push();
    }, [fields]);

    const items = fields.map((field, index) => (
        <Item
            key={field}
            currentUser={currentUser}
            disabled={disabled}
            fields={fields}
            index={index}
            isSuperUser={isSuperUser}
            name={field}
            userId={id}
            value={fields.get(index)}
        />
    ));

    return (
        <>
            {items}
            {isSuperUser && (
                <Button onClick={addPermission}>
                    <span>Add Permission</span>
                </Button>
            )}
        </>
    );
};

PermissionComponent.propTypes = {
    disabled: PropTypes.bool,
    fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
};

const Permission = props => (
    <FieldArray component={PermissionComponent} name="permissions" rerenderOnEveryChange {...props} />
);

export default Permission;
