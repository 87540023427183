import { get, find, flow, filter, size, lt } from 'lodash/fp';
import FinanceProductField from '../components/fields/display/FinanceProductField';
import UpdateOptions from '../components/fields/edition/UpdateOption';
import FinanceProductDescription from '../components/ui/FinanceProductDescription';
import { weightFieldAfter } from '../utils';
import fieldTemplate from './fieldTemplate';

const getDefaultFinanceProduct = ({ bank }, context) => {
    if (bank === undefined) {
        // we cannot initialize it yet
        return undefined;
    }

    // get the financial product
    const productId = flow([
        get('financeProducts'),
        filter(financeProduct => financeProduct.bankId === bank),
        get('0.id'),
    ])(context);

    if (!productId) {
        const fallbackFinanceProduct = get('fallbackFinanceProduct', context);

        if (fallbackFinanceProduct) {
            return fallbackFinanceProduct.id;
        }
    }

    return productId;
};

const findFinanceProduct = id =>
    flow([get('financeProducts'), find(financeProduct => get('id', financeProduct) === id), get('id')]);

const financeProductField = {
    // copy template
    ...fieldTemplate,

    // setup meta
    label: t => t('calculator.label.financeProduct'),
    weight: weightFieldAfter('bank'),

    // setup states
    isViewable: () => true,
    // need to filter by bank
    isEditable: (context, { bank }) =>
        flow([get('financeProducts'), filter(item => item.bankId === bank), size, lt(1)])(context),
    // cannot select the finance product as long as the variant or bank is invalid
    isInvalid: ({ isInvalidVariant, isInvalidBank }) => isInvalidVariant || isInvalidBank,

    // setup display rendering
    displayComponent: FinanceProductField,

    // setup edition rendering
    editionComponent: UpdateOptions,
    getEditionProperties: ({ financeProducts, contentTranslation }, { bank }) => ({
        options: financeProducts
            .filter(financeProduct => financeProduct.bankId === bank)
            .map(financeProduct => ({
                value: financeProduct.id,
                label: contentTranslation.ct(financeProduct.name),
            })),
        footerComponent: FinanceProductDescription,
    }),

    // setup values management
    getInitialValue: getDefaultFinanceProduct,
    updates: {
        // reset the finance product whenever the bank change
        bank: () => undefined,
        carModel: ({ financeProduct }, context) => findFinanceProduct(financeProduct)(context),
    },

    // snapshot settings
    snapshot: {
        ...fieldTemplate.snapshot,
        component: FinanceProductField,
    },
};

export default financeProductField;
