import { getOr, get } from 'lodash/fp';
import PropTypes, { shape } from 'prop-types';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { compose } from 'redux';
import { FormSection, getFormValues, reduxForm } from 'redux-form';
import { FormTabContainer } from '../../../../../containers/Layout';
import { AssetCondition } from '../../../../../schema';
import Gallery from '../../variant/Form/Gallery';
import UsedCarDetails from '../../variant/Form/UsedCarDetails';
import Languages from './Languages';
import MainDetails from './MainDetails';
import Prices from './Prices';
import { Context } from './context';

const Form = props => {
    const { form, disabled, change, languageOptions } = props;
    const values = useSelector(getFormValues(form));
    const context = { ...props, values };

    const images = getOr([], 'inventoryUnit.images', values);

    const assetCondition = get('variant.assetCondition', values);

    const displayMultiLanguage = languageOptions.length > 1;

    return (
        <Context.Provider value={context}>
            <FormTabContainer>
                <Tabs className="tabs" forceRenderTabPanel>
                    <TabList>
                        <Tab>Main Details</Tab>
                        {displayMultiLanguage && <Tab>Multi-Language</Tab>}
                        <Tab>Images</Tab>
                    </TabList>
                    <TabPanel>
                        <MainDetails />
                        {assetCondition === AssetCondition.USED && (
                            <FormSection name="inventoryUnit.details">
                                <UsedCarDetails />
                            </FormSection>
                        )}
                        <Prices />
                    </TabPanel>
                    {displayMultiLanguage && (
                        <TabPanel>
                            <FormSection name="inventoryUnit">
                                <Languages />
                            </FormSection>
                        </TabPanel>
                    )}
                    <TabPanel>
                        <FormSection name="inventoryUnit">
                            <Gallery
                                change={change}
                                disabled={disabled}
                                fieldName="inventoryUnit.images"
                                images={images}
                            />
                        </FormSection>
                    </TabPanel>
                </Tabs>
            </FormTabContainer>
        </Context.Provider>
    );
};

Form.propTypes = {
    change: PropTypes.func,
    disabled: PropTypes.bool,
    form: PropTypes.string.isRequired,
    languageOptions: PropTypes.arrayOf(
        shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
};

export default compose(reduxForm({ form: 'inventoryUnit' }), memo)(Form);
