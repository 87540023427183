import { useApolloClient } from '@apollo/client';
import { omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { submit } from 'redux-form';
import { addNotification } from '../../../../actions';
import { getGlobalPermissions } from '../../../../selectors';
import { INVENTORY_SAVE_NOTIFICATION } from '../../../../shared/constants/notification';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import FormLayout from '../../../ui/form/FormLayout';
import Form from './Form';
import { update } from './Page.graphql';

const Page = ({ initialValues = null }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { mayManageVehicles } = useSelector(getGlobalPermissions);

    const submitForm = useCallback(() => dispatch(submit('inventory')), [dispatch]);

    const client = useApolloClient();
    const onSubmit = useCallback(
        async ({ id, ...data }) => {
            const variables = {
                data: omit(
                    ['id', 'version', 'lastModified', 'modelId', 'subModelId', 'identifier'],
                    prepareForGraphQL(data)
                ),
            };

            return client.mutate({ mutation: update, variables }).catch(handleResponseError);
        },
        [client]
    );

    const onSubmitSuccess = useCallback(() => {
        dispatch(addNotification(INVENTORY_SAVE_NOTIFICATION));
        history.push('/vehicle/inventories');
    }, [history, dispatch]);

    const onCancel = useCallback(() => history.goBack(), [history]);

    return (
        <FormLayout
            bodyComponent={
                initialValues && (
                    <Form
                        disabled={!mayManageVehicles}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onSubmitSuccess={onSubmitSuccess}
                    />
                )
            }
            moreActions={[
                mayManageVehicles && {
                    label: 'save',
                    onAction: submitForm,
                },
            ]}
            onCancel={onCancel}
            title="Inventory Details"
        />
    );
};

Page.propTypes = {
    initialValues: PropTypes.shape({}),
};

export default Page;
