import { useQuery } from '@apollo/client';
import { get, flow, map } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { getCurrentCountry } from '../../../../selectors';
import { getLastModified, prepareForGraphQL } from '../../../../utilities/forms';
import { ZoneDataFragment } from '../../../../utils/useLoadCompany.graphql';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import {
    getInventory,
    InventoryUnitDataFragment,
    InventorySetDataFragment,
    VariantInventoryDataFragment,
} from './Edition.graphql';
import Page from './Page';

type Params = { id: string; setId: string; unitId: string };

const Edition = () => {
    const { id, setId, unitId } = useParams<Params>();
    const variables = { id, setId, unitId };
    const { data, loading } = useQuery(getInventory, { variables, fetchPolicy: 'network-only' });

    const { zones }: { zones: ZoneDataFragment[] } = useSelector(getCurrentCountry);

    const formatDateTime = useFormatDateTime();

    const initialValues = useMemo(() => {
        const inventory = data?.inventory;
        const inventorySet: InventorySetDataFragment = get('sets[0]', inventory);
        const options = inventorySet?.options;

        if (!inventory || !inventorySet) {
            return null;
        }

        const { variant }: { variant: VariantInventoryDataFragment } = inventory;
        const { model } = variant;

        // clean units
        const units = flow([
            // first remove useless information
            prepareForGraphQL,
            // then process each
            map((unit: InventoryUnitDataFragment) => [
                unit.id,
                {
                    id: unit.id,
                    // details
                    vin: unit.details.vin,
                    location: unit.details.location,
                    // state
                    state: unit.state,
                },
            ]),
            // turn it into an object
            Object.fromEntries,
        ])(inventorySet.units);

        let inventoryUnit = inventorySet.units.find(i => i.id === unitId);

        if (!inventoryUnit) {
            return null;
        }

        inventoryUnit = {
            ...inventoryUnit,
            // transform prices array (remote state) into an object (local state)
            prices: {
                ...Object.fromEntries(zones.map(zone => [zone.id, null])),
                ...Object.fromEntries(
                    inventoryUnit.prices.map(({ zoneId, value }: { zoneId: string; value: number }) => [zoneId, value])
                ),
            },
        };

        const mandatories = Object.entries(inventoryUnit.prices).reduce(
            (acc: { [key: string]: boolean }, [zoneId, value]) => {
                acc[zoneId] = value !== null;

                return acc;
            },
            {}
        );

        return {
            // main entities
            inventory,
            inventorySet,
            options,
            // variant name(s)
            variant: {
                ...variant,
                prices: {
                    ...Object.fromEntries(variant.prices.map(({ zoneId, value }) => [zoneId, value])),
                },
            },
            model: model.parent || model,
            subModel: model.parent ? model : null,
            // last modification date for this inventory set
            lastModified: getLastModified(inventoryUnit?.version, formatDateTime),
            // and units
            units,
            inventoryUnit,
            no: inventoryUnit?.identifier,
            title: `${inventory?.identifier}.${inventorySet?.identifier} - ${inventoryUnit?.identifier}`,
            identifier: `${inventory?.identifier}.${inventorySet?.identifier}`,
            mandatories,
        };
    }, [data, formatDateTime, unitId, zones]);

    if (!loading && !initialValues) {
        // the product does not exist
        return <Redirect to="/vehicle/inventories" />;
    }

    return <Page initialValues={initialValues} />;
};

export default Edition;
