import React from 'react';
import { settlementInstalmentOnOptions } from '../../../../../shared/constants/options';
import DropdownField from '../../../../template/Dropdown';
import { useFormContext } from './context';

const BalloonPaymentGFV = () => {
    const { disabled } = useFormContext();

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField
                            disabled={disabled}
                            label="Settlement Instalment on"
                            name="balloonGFVSetting.settlementInstalmentOn"
                            options={settlementInstalmentOnOptions}
                            noSort
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BalloonPaymentGFV;
