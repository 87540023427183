import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

const PortletContainer = styled.div`
    margin-bottom: 10px;
`;

const PortletTitle = styled.div`
    padding: 6px 0 9.5px 0;
    font-size: 1.28rem;
    border-bottom: 1px solid #dedede;
    display: flex;
    flex-direction: row;
    color: #000000;
    height: 39px;
    box-sizing: border-box;
    cursor: ${props => (props.closable ? 'pointer' : 'default')};
`;

const PortletBody = styled.div`
    padding: 0 0 10px 0;
    margin: 0 -10px 0 -10px;

    height: ${props => (props.open ? 'auto' : 0)};
    display: ${props => (props.open ? 'block' : 'none')};

    .sub-container-fluid {
        width: 100%;
        display: flex;
        flex-direction: row;
        span {
            display: block;
            width: 5px;
        }

        & > div {
            flex: 1;
        }

        & > div.flex-3 {
            flex: 3;
        }
    }
`;

const PortletTitleLabel = styled.div`
    flex: 1;
`;

const PortletTitleAction = styled.div`
    width: 15px;

    svg {
        font-size: 0.85rem;
        color: #000000;
    }
`;

const ApvPortlet = ({ open: initialOpen = true, closable = false, name, children, className, action }) => {
    const [open, setOpen] = useState(initialOpen);

    useEffect(() => {
        setOpen(initialOpen);
    }, [initialOpen, setOpen]);

    const toggle = useCallback(() => closable && setOpen(state => !state), [closable, setOpen]);

    return (
        <PortletContainer>
            <PortletTitle className={className} closable={closable} onClick={toggle}>
                <PortletTitleLabel>{name}</PortletTitleLabel>
                {action}
                {closable && (
                    <PortletTitleAction>
                        <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
                    </PortletTitleAction>
                )}
            </PortletTitle>
            <PortletBody open={open}>{children}</PortletBody>
        </PortletContainer>
    );
};

ApvPortlet.propTypes = {
    action: PropTypes.node,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    closable: PropTypes.bool,
    name: PropTypes.string.isRequired,
    open: PropTypes.bool,
};

export default ApvPortlet;
