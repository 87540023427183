import { getOr } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useCalculatorContext } from '../../CalculatorContext';
import StaticField, { sharedPropTypes } from './StaticField';

const defaultRender = props => <StaticField {...props} />;

export const useSnapshotValue = name => getOr('', ['fields', name, 'snapshotValue'], useCalculatorContext());

const ConnectedSnapshotField = ({ children = defaultRender, ...props }) => {
    const { name } = props;
    const value = useSnapshotValue(name);

    return children({ ...props, value });
};

ConnectedSnapshotField.propTypes = {
    ...sharedPropTypes,
    children: PropTypes.func,
};

// use memo to reduce tree updates
export default memo(ConnectedSnapshotField);
