import { useApolloClient, ApolloError } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import ImportModal from '../../../../ui/importModal';
import { importTranslations } from './ImportTranslations.graphql';

const TranslationImportModal = ({ onClose }) => {
    const [errors, setErrors] = useState(null);
    const [updateCount, setUpdateCount] = useState(null);
    const { id: countryId } = useSelector(getFormValues('country'));
    const client = useApolloClient();

    const onUpload = useCallback(
        async inputFile => {
            try {
                const variables = { countryId, file: inputFile };

                const { data } = await client.mutate({ mutation: importTranslations, variables });
                const count = data?.response;

                setUpdateCount(count);
            } catch (error) {
                if (error instanceof ApolloError) {
                    const messages = error.graphQLErrors.reduce((acc, { message }) => `${acc}${message} `, '');
                    setErrors([messages]);
                }
            }
        },
        [client, countryId]
    );

    return (
        <ImportModal
            errors={errors}
            onClose={onClose}
            onDownloadClick={() => null}
            onUpload={onUpload}
            type="Language"
            updateCount={updateCount}
        />
    );
};

TranslationImportModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default TranslationImportModal;
