import React from 'react';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { onOffOptions } from '../../../../../shared/constants/options';
import ColorPickerField from '../../../../template/ColorPicker';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import FileUpload from '../../../../template/FileUpload';
import InputField from '../../../../template/Input';
import NumberField from '../../../../template/Number';
import SwitchField from '../../../../template/Switch';
import { useFormContext } from './context';

const MainDetails = () => {
    const { formatPath } = useContentTranslation();
    const { disabled, values, currency, groups } = useFormContext();
    const edition = !!values.lastModified;

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Option ID">
                            <Field component={InputField} disabled={disabled} name="identifier" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Option Name">
                            <Field component={InputField} disabled={disabled} name={formatPath('name')} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField disabled={disabled} label="Option Category" name="groupId" options={groups} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label={`Price (${currency})`}>
                            <Field component={NumberField} disabled={disabled} name="price" precision={0} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Order No.">
                            <Field component={NumberField} disabled={disabled} name="order" precision={0} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Active">
                            <Field component={SwitchField} disabled={disabled} name="isActive" options={onOffOptions} />
                        </FieldContainer>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Option Colour">
                            <Field component={ColorPickerField} name="colour" />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Option Image">
                            <Field
                                accept=".jpg,.png,.jpeg"
                                component={FileUpload}
                                disabled={disabled}
                                label="Size 500x500px in jpg or png"
                                name="image"
                            />
                        </FieldContainer>
                    </div>
                    {edition && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Last Modified">
                                <Field component={InputField} name="lastModified" disabled />
                            </FieldContainer>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MainDetails;
