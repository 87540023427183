import { get, isEmpty } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { useContentTranslation } from '../../../../../i18n';
import { productTypesOptions } from '../../../../../shared/constants/options';
import useFormatDateTime from '../../../../shared/useFormatDateTime';
import ApvPortlet from '../../../../template/Portlet';
import { cells, ListTable } from '../../../../ui/lists';
import { useFormContext } from './context';

const useColumns = () => {
    const formatDateTime = useFormatDateTime();
    const { formatPath } = useContentTranslation();

    return useMemo(
        () => [
            { name: 'Finance ID', id: 'identifier', renderCell: get('identifier') },
            { name: 'Financial Product', id: formatPath('name'), renderCell: get(formatPath('name')), underline: true },
            {
                name: 'Type',
                id: 'type',
                renderCell: item => {
                    const type = get('type', item);

                    return productTypesOptions.find(option => option.value === type)?.label;
                },
            },
            { name: 'Start Date', id: 'startDt', renderCell: cells.renderDateTime('period.start', formatDateTime) },
            { name: 'End Date', id: 'endDt', renderCell: cells.renderDateTime('period.end', formatDateTime) },
            { name: 'Order No.', id: 'order', renderCell: get('order') },
            { name: 'Active', id: 'active', renderCell: cells.renderActive() },
        ],
        [formatDateTime, formatPath]
    );
};

const FinanceProducts = () => {
    const { financeProducts } = useFormContext();
    const history = useHistory();

    const columns = useColumns();

    if (isEmpty(financeProducts)) {
        return null;
    }

    return (
        <ApvPortlet name="Financial Products" closable open>
            <div className="container-fluid">
                <br />
                <ListTable
                    columns={columns}
                    items={financeProducts}
                    onItemClick={item => history.push(`/finance/products/${item.id}`)}
                />
            </div>
        </ApvPortlet>
    );
};

export default FinanceProducts;
