import { Portlet, PortletButton } from '@appvantageasia/afc-ui';
import { isString } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { ApplicationPhase, FinanceProductSubType, FinanceProductType } from '../../../../../schema';
import { getCompanyIdentifier, getCurrentCountry, getGlobalPermissions } from '../../../../../selectors';
import { settlementInstalmentOnOptions, usePaymentModeOptions } from '../../../../../shared/constants/options';
import SelectField from '../../../../shared/form-v2/SelectField';
import TextAreaField from '../../../../shared/form-v2/TextAreaField';
import TextField from '../../../../shared/form-v2/TextField';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';

const Finance = ({ isApplication, disabled, isLeasing, onValidation, recalculate, hasSubMonthly, values }) => {
    const { mayViewRecalculate } = useSelector(getGlobalPermissions);
    // check whether COE should be displayed based on country and channel
    const { code, channelSetting } = useSelector(getCurrentCountry);
    const { companyCode } = useSelector(getCompanyIdentifier);

    const { channel, calculator, phase } = values;
    const showPaymentMode = isString(values.display.paymentMode);

    const { t } = useTranslation();
    const { formatPath } = useContentTranslation();

    const paymentModeOptions = usePaymentModeOptions();
    const selectedPaymentMode = paymentModeOptions.find(item => item.value === calculator?.paymentMode)?.label;
    const isBalloonGFV = values?.financeProduct?.subType === FinanceProductSubType.HIREPURCHASEBALLOONGFV;

    const showResidualValue =
        values?.financeProduct?.type === FinanceProductType.FINANCE &&
        (values?.financeProduct?.subType === FinanceProductSubType.LEASEPURCHASE || isBalloonGFV);

    const showTradeIn = values?.financeProduct?.tradeInSetting?.show;

    const isUccl = values?.financeProduct?.subType === FinanceProductSubType.UCCLLEASING;
    const isPorsche = companyCode === 'POR';

    const canShowMarginOfFinance = (isPorsche && code === 'MY') || isBalloonGFV;

    const renderMonthlyInstallment = useCallback(() => {
        if (hasSubMonthly) {
            return (
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Monthly Payment">
                        {calculator?.monthlyInstalments.map((value, index) => (
                            <div className="row">
                                <div
                                    className="col-md-4 col-sm-4 col-xs-4"
                                    style={{ paddingRight: '0', marginBottom: '5px' }}
                                >
                                    <Field
                                        component={InputField}
                                        name={`display.monthlyInstalmentPrefix[${index}]`}
                                        disabled
                                    />
                                </div>
                                <div className="col-md-8 col-sm-8 col-xs-8">
                                    <Field
                                        component={InputField}
                                        name={`display.monthlyInstalments[${index}]`}
                                        textAlign="right"
                                        disabled
                                    />
                                </div>
                            </div>
                        ))}
                    </FieldContainer>
                </div>
            );
        }

        return (
            <div className="col-md-4 col-sm-12 col-xs-12">
                <TextField label="Monthly Payment" name="display.monthlyInstalments[0]" disabled />
            </div>
        );
    }, [calculator, hasSubMonthly]);

    return (
        <Portlet
            action={
                recalculate &&
                !disabled &&
                mayViewRecalculate &&
                values.financeProductId && <PortletButton onClick={recalculate}>RECALCULATE</PortletButton>
            }
            name="Financing Details"
            closable
            open
        >
            <div style={{ position: 'relative' }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField label="Bank" name={formatPath('display.bank')} disabled />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField label="Financial Product" name="display.financialProduct" disabled />
                        </div>
                        {showPaymentMode && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField
                                    fixedValue={selectedPaymentMode}
                                    label={t('calculator.label.paymentMode')}
                                    name="display.paymentMode"
                                    disabled
                                />
                            </div>
                        )}
                        {/* Hide Interest Rate for finance product type 'Leasing' and sub type 'Lease'  */}
                        {!isLeasing && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField label="Interest Rate %" name="display.interestRate" disabled />
                            </div>
                        )}
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField label="Payment Term" name="display.paymentTerm" disabled />
                        </div>
                        {/* Hide Downpayment for finance product type 'Leasing' and sub type 'Lease'  */}
                        {!isLeasing && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField label="Downpayment" name="display.downpaymentAmount" disabled />
                            </div>
                        )}
                        {canShowMarginOfFinance && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField
                                    label="Margin of Finance"
                                    name="display.financialAmountPercentage"
                                    disabled
                                />
                            </div>
                        )}
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField
                                label={isBalloonGFV ? 'HP Amount' : 'Loan Amount'}
                                name="display.financialAmount"
                                disabled
                            />
                        </div>
                        {/* Display COE only for Singapore and based on channel setting */}
                        {code === 'SG' && channelSetting[channel.toLowerCase()].isCoeEnabled && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField label="COE" name="display.coeAmount" disabled />
                            </div>
                        )}
                        {code === 'NZ' && channelSetting[channel.toLowerCase()].isPpsrAndEstablishmentEnabled && (
                            <>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <TextField label="PPSR Fee Amount" name="display.ppsrAmount" disabled />
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <TextField
                                        label="Establishment Fee Amount"
                                        name="display.establishmentAmount"
                                        disabled
                                    />
                                </div>
                            </>
                        )}
                        {code === 'TW' && channelSetting[channel.toLowerCase()]?.isLuxuryTaxEnabled && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField label="Luxury Tax" name="display.luxuryTaxAmount" disabled />
                            </div>
                        )}
                        {isPorsche && code === 'TW' && isUccl && (
                            <>
                                {/* Display License Plate Fee only for finance product sub type UCCL Leasing */}
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <TextField label="License Plate Fee" name="display.licensePlateFee" disabled />
                                </div>
                                {/* Display Commission only for finance product sub type UCCL Leasing */}
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <TextField label="Commission %" name="display.commission" disabled />
                                </div>
                                {/* Display Fixed Interest Rate only for finance product sub type UCCL Leasing */}
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <TextField label="Fixed Interest Rate" name="display.fixedInterestRate" disabled />
                                </div>
                                {/* Display License Tax and Fuel Tax only for finance product sub type UCCL Leasing */}
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <TextField label="License and Fuel Tax" name="display.licenseAndFuelTax" disabled />
                                </div>
                                {/* Display Displacement only for finance product sub type UCCL Leasing */}
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <TextField label="Displacement" name="display.displacement" disabled />
                                </div>
                                {/* Display Insurance Fee only for finance product sub type UCCL Leasing */}
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <TextField label="Insurance Fee" name="display.insuranceFeeAmount" disabled />
                                </div>
                                {/* Display Tax loss only for finance product sub type UCCL Leasing */}
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <TextField label="Tax Loss" name="display.taxLoss" disabled />
                                </div>
                            </>
                        )}

                        {/* Display Security Deposit only for finance product type 'Leasing' and sub type 'Lease' */}
                        {isLeasing && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField label="Security Deposit" name="display.securityDeposit" disabled />
                            </div>
                        )}
                        {channelSetting[channel.toLowerCase()].isDealerOptionsEnabled && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField label="Dealer Options" name="display.dealerOptionsAmount" disabled />
                            </div>
                        )}
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField label="Total Price" name="display.totalPrice" disabled />
                        </div>
                        {renderMonthlyInstallment()}
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField label="Promo Code" name="display.promoCode" disabled />
                        </div>
                        {isApplication && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField
                                    label="Reservation Deposit Paid"
                                    name="display.reservationDeposit"
                                    disabled
                                />
                            </div>
                        )}
                        {showResidualValue && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField
                                    label={isBalloonGFV ? 'Assured Resale Value' : 'Residual Value'}
                                    name="display.residualValueAmount"
                                    disabled
                                />
                            </div>
                        )}
                        {isBalloonGFV && (
                            <>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <TextField
                                        label="Estimated Surplus after Assured Resale Value"
                                        name="display.estimatedSurplus"
                                        disabled
                                    />
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12 ">
                                    <SelectField.Outline
                                        label="Settlement Instalment"
                                        name="financeProduct.balloonGFVSetting.settlementInstalmentOn"
                                        options={settlementInstalmentOnOptions}
                                        disabled
                                    />
                                </div>
                            </>
                        )}
                        {showTradeIn && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField label="Trade-In Equity" name="display.tradeInEquity" disabled />
                            </div>
                        )}
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField label="Application Type" name="display.applicationType" disabled />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextAreaField disabled={onValidation || disabled} label="Remarks" name="remark" />
                        </div>
                        {isApplication && phase === ApplicationPhase.FINANCE && (
                            <>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <TextAreaField
                                        label="Application Status"
                                        name="display.applicationStatus"
                                        disabled
                                    />
                                </div>
                                {onValidation && (
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <TextAreaField
                                            label="Activation Status"
                                            name="display.activationStatus"
                                            disabled
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Portlet>
    );
};

Finance.propTypes = {
    disabled: PropTypes.bool,
    hasSubMonthly: PropTypes.bool,
    isApplication: PropTypes.bool,
    isLeasing: PropTypes.bool,
    onValidation: PropTypes.bool,
    recalculate: PropTypes.func,
    values: PropTypes.shape({
        calculator: PropTypes.shape({
            monthlyInstalments: PropTypes.arrayOf(),
            paymentMode: PropTypes.string,
        }),
        channel: PropTypes.string,
        display: PropTypes.shape({
            activationStatus: PropTypes.string,
            applicationStatus: PropTypes.string,
            paymentMode: PropTypes.string,
        }),
        financeProduct: PropTypes.shape({
            subType: PropTypes.string,
            tradeInSetting: PropTypes.shape({
                show: PropTypes.bool,
            }),
            type: PropTypes.string,
        }),
        financeProductId: PropTypes.string,
        options: PropTypes.arrayOf(),
        phase: PropTypes.string,
    }),
};

export default Finance;
