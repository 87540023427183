// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import { get, isNil } from 'lodash/fp';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { getFormValues, ReduxFormContext } from 'redux-form';
import { Channel } from '../../../../../schema';
import { yesNoOptions } from '../../../../../shared/constants/options';
import DatePickerField from '../../../../shared/form-v2/DatePickerField';
import NumberField from '../../../../shared/form-v2/NumberField';
import SelectField from '../../../../shared/form-v2/SelectField';
import TextField from '../../../../shared/form-v2/TextField';
import { getLabelWithFlag } from '../../../Customer/Form/CustomerDetails';
import { ApplicationFormValues } from '../application/Form/context';

const TradeInVehicle = () => {
    const { form, sectionPrefix } = useContext(ReduxFormContext);
    const formValues = useSelector(getFormValues(form));

    const application = sectionPrefix
        ? (get(sectionPrefix)(formValues) as ApplicationFormValues)
        : (formValues as ApplicationFormValues);

    const { customer, channel, tradeIn, calculator, event } = application;

    const { withMyInfo } = customer;
    const isEvent = channel === Channel.EVENT;

    return (
        <Portlet name="Vehicle to trade-in" closable open>
            <div className="container-fluid">
                <div className="row">
                    {isEvent && (
                        <>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField
                                    label={getLabelWithFlag('Make', tradeIn?.source)}
                                    name="tradeIn.make"
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField
                                    label={getLabelWithFlag('Model', tradeIn?.source)}
                                    name="tradeIn.model"
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <TextField
                                    label={getLabelWithFlag('Registration Number', tradeIn?.source)}
                                    name="tradeIn.registrationNo"
                                    disabled
                                />
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <NumberField label="Mileage (km)" name="tradeIn.mileage" disabled />
                            </div>
                            {withMyInfo && (
                                <>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <TextField
                                            label={getLabelWithFlag('Year of Manufacture', tradeIn?.source)}
                                            name="tradeIn.year"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <DatePickerField
                                            label={getLabelWithFlag('Registration Date', tradeIn?.source)}
                                            name="tradeIn.registrationDate"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <DatePickerField
                                            label={getLabelWithFlag('Road Tax Expiry Date', tradeIn?.source)}
                                            name="tradeIn.roadTaxExpiryDate"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <NumberField
                                            label={getLabelWithFlag('Engine Capacity', tradeIn?.source)}
                                            name="tradeIn.engineCapacity"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <TextField
                                            label={getLabelWithFlag('Propellant', tradeIn?.source)}
                                            name="tradeIn.propellant"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <TextField
                                            label={getLabelWithFlag('Primary Colour', tradeIn?.source)}
                                            name="tradeIn.color.primary"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <TextField
                                            label={getLabelWithFlag('Secondary Colour', tradeIn?.source)}
                                            name="tradeIn.color.secondary"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <TextField
                                            label={getLabelWithFlag('Vehicle Status', tradeIn?.source)}
                                            name="tradeIn.status"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <TextField
                                            label={getLabelWithFlag('Vehicle Scheme', tradeIn?.source)}
                                            name="tradeIn.scheme"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <TextField
                                            label={getLabelWithFlag('COE Category', tradeIn?.source)}
                                            name="tradeIn.coe.category"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <DatePickerField
                                            label={getLabelWithFlag('COE Expiry Date', tradeIn?.source)}
                                            name="tradeIn.coe.expiryDate"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <NumberField
                                            label={getLabelWithFlag('Quota Premium', tradeIn?.source)}
                                            name="tradeIn.quotaPremium"
                                            type="currency"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <NumberField
                                            label={getLabelWithFlag('Open Market Value', tradeIn?.source)}
                                            name="tradeIn.openMarketValue"
                                            type="currency"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <NumberField
                                            label={getLabelWithFlag('No. of Transfers', tradeIn?.source)}
                                            name="tradeIn.noOfTransfer"
                                            disabled
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    <div className="col-md-4 col-sm-12 col-xs-12 ">
                        <SelectField.Outline label="Trade-in" name="hasTradeIn" options={yesNoOptions} disabled />
                    </div>
                    {event?.setting?.allowTradeInAmountInput && !isNil(calculator.expectedTradeInAmount) && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <NumberField
                                label={getLabelWithFlag('Expected Trade-in Amount', tradeIn?.source)}
                                name="calculator.expectedTradeInAmount"
                                type="currency"
                                disabled
                            />
                        </div>
                    )}
                    {event?.setting?.allowTradeInAmountInput && !isNil(calculator.expectedCashPayment) && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <NumberField
                                label={getLabelWithFlag('Initial Cash Payment', tradeIn?.source)}
                                name="calculator.expectedCashPayment"
                                type="currency"
                                allowNegative
                                disabled
                            />
                        </div>
                    )}
                </div>
            </div>
        </Portlet>
    );
};

export default TradeInVehicle;
