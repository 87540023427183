import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Unit } from '../../../fields/schema';
import { useComparisonContext } from '../../ComparisonContext';
import { useValues } from '../../ValuesContext';
import PromoCodeValue, { Description, Empty, PromoWithCode, PromoIcon, PromoError } from '../../ui/PromoCodeValue';
import { useFormatCurrency } from './CurrencyField';
import { useFormatPercentage } from './PercentageField';
import StaticField, { sharedPropTypes } from './StaticField';

const PromoCodeField = ({ promo, ...props }) => {
    const values = useValues();
    const { t } = useTranslation();
    const { formatCurrencyDown } = useFormatCurrency();
    const formatPercentage = useFormatPercentage();
    const { promoCode, carPrice } = values;
    const { editable } = props;
    const hasPromoStock = promo?.remainingQuantity > 0;

    // compute value
    const value = useMemo(() => {
        // undefined mean promo is loading
        if (!promoCode || promo === undefined) {
            return <Empty />;
        }

        const renderText = (text, valid) => (
            <PromoCodeValue>
                <PromoWithCode editable={editable} withValid={valid}>
                    <div>
                        <span>{promoCode} </span>
                        {text}
                    </div>
                </PromoWithCode>
                {hasPromoStock && promo?.description && <Description>{promo?.description}</Description>}
                {promo && !hasPromoStock && (
                    <PromoError>{t('calculator.promoCode.error.promoStockNotAvailable')}</PromoError>
                )}
            </PromoCodeValue>
        );

        // null mean promo code is invalid
        if (promo === null || !hasPromoStock) {
            const invalidText = <>: {t('calculator.promoCode.error.inValidCode')}</>;

            return renderText(invalidText);
        }

        const { unit, value: promoValue } = promo;

        const icon = (
            <PromoIcon>
                <Icon icon={faCheckCircle} />
            </PromoIcon>
        );

        if (!promoValue) {
            return renderText(icon, true);
        }

        // check promo value more than starting price
        const isExceededCarPrice = promoValue > carPrice;
        const availablePromoPrice = isExceededCarPrice ? carPrice : promoValue;
        const isPercentage = unit === Unit.PERCENTAGE;

        const formatValue = isPercentage ? (carPrice * promoValue) / 100 : availablePromoPrice;

        const validText = (
            <>
                : {formatCurrencyDown(-formatValue)} {isPercentage && <>({formatPercentage(-promoValue)}) </>}
                {icon}
            </>
        );

        return renderText(validText, true);
    }, [promoCode, promo, hasPromoStock, carPrice, formatCurrencyDown, formatPercentage, editable, t]);

    // for now, we can not get promo detail information in comparison context
    // so we give a fixed height temporarily
    let style = {
        minHeight: '60px',
    };

    const { active } = useComparisonContext();
    if (promo?.description && !active) {
        style = {
            ...style,
            // make value align center and extend height when it is single calculator
            padding: '15px',
        };
    }

    return <StaticField {...props} style={style} value={value} />;
};

PromoCodeField.propTypes = {
    ...sharedPropTypes,
};

export default PromoCodeField;
