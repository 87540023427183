import cs from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ListTable from '../../ui/lists/ListTable';

const TopSalesManTable = ({ title, columns, tabs }) => (
    <section className="card" order-id="card-10">
        <div className="card-header">
            <h5 className="mb-0 mr-3 d-inline-block text-black">
                <strong>{title}</strong>
            </h5>
        </div>
        <div className="card-body top-sales-man">
            <div>
                <ul className="nav nav-tabs mb-4">
                    {tabs.map((tab, index) => (
                        <li key={tab.dataKey} className="nav-item">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a
                                className={cs('nav-link', { active: !index })}
                                data-target={`#topsales-${tab.dataKey}`}
                                data-toggle="tab"
                                href="#"
                                role="tab"
                            >
                                {tab.label}
                            </a>
                        </li>
                    ))}
                </ul>
                <div className="tab-content">
                    {tabs.map((tab, index) => (
                        <div
                            key={tab.dataKey}
                            className={cs('tab-pane', { active: !index })}
                            id={`topsales-${tab.dataKey}`}
                        >
                            {tab.data.length ? (
                                <ListTable columns={columns} items={tab.data} />
                            ) : (
                                <div className="m-1">There is currently no data for this month.</div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </section>
);

TopSalesManTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            renderCell: PropTypes.func.isRequired,
        }).isRequired
    ).isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({}.isRequired)).isRequired,
    title: PropTypes.string,
};

export default TopSalesManTable;
