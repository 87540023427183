import { get } from 'lodash/fp';
import * as validators from '../../../../../utils/validators';

const validate = validators.compose(
    validators.requiredString('name'),
    validators.requiredValue('zoneIds'),
    validators.requiredStringArray('dealerIds'),
    validators.requiredBoolean('isActive'),
    validators.requiredString('email'),
    validators.requiredNumber('order'),
    validators.validEmail('email'),
    validators.requiredString('signing.onCreate'),
    validators.requiredString('signing.onUpdate'),
    validators.requiredString('remoteFlowAcknowledgmentInfo'),
    validators.requiredBoolean('hasIntegration'),
    validators.only(
        get('hasIntegration'),
        validators.compose(
            validators.requiredString('integration.base'),
            validators.requiredString('integration.submissionPartial'),
            validators.requiredString('integration.editionPartial'),
            validators.requiredString('integration.cancellationPartial')
        )
    ),
    validators.only(
        get('hasMyInfoIntegration'),
        validators.compose(
            validators.requiredString('myInfo.clientId'),
            validators.requiredString('myInfo.clientSecret'),
            validators.requiredString('myInfo.publicCert'),
            validators.requiredString('myInfo.privateKey'),
            validators.requiredString('myInfo.purpose')
        )
    ),
    validators.requiredString('color')
);

export default validate;
