import PropTypes from 'prop-types';
import { useEffect } from 'react';

const ESCEventDetector = ({ onEvent, ignoreCheck }) => {
    useEffect(() => {
        const catchEscape = event => {
            if (ignoreCheck && ignoreCheck()) {
                return;
            }

            if (event.keyCode === 27) {
                onEvent();
            }
        };

        document.addEventListener('keydown', catchEscape, false);

        return () => {
            document.removeEventListener('keydown', catchEscape, false);
        };
    }, [ignoreCheck, onEvent]);

    return null;
};

ESCEventDetector.propTypes = {
    ignoreCheck: PropTypes.func,
    onEvent: PropTypes.func.isRequired,
};

export default ESCEventDetector;
