import { useQuery } from '@apollo/client';
import React from 'react';
import { Redirect, useParams } from 'react-router';
import { getRole } from './Edition.graphql';
import Page from './Page';

const Editor = () => {
    const { id } = useParams();
    const { data, loading } = useQuery(getRole, { fetchPolicy: 'network-only', variables: { id } });

    const initialValues = data?.role;

    if (!loading && !initialValues) {
        return <Redirect to="/access/hierarchies" />;
    }

    return <Page initialValues={initialValues} />;
};

export default Editor;
