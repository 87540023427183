import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { List } from '../modal';

const InputCheckbox = styled.input`
    height: 15px;
    width: 15px;
    margin-right: 1rem;
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;

    border-bottom: 1px solid rgb(255, 255, 255, 0.5);
    padding: 0.5rem 0;
`;

const CheckboxLabel = styled.label`
    margin-bottom: 0;
`;

const ImportButton = styled.button`
    font-weight: bold;
    color: #fff;

    outline: none;
    background: transparent;
    border: 1px rgba(255, 255, 255, 0.56) solid;
    padding: 5px 15px;
    margin: 0.5rem auto;

    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

export const UpdateModalContainer = styled.div`
    position: relative;
    padding: 10px;
`;

const DuplicateCheckbox = ({ onDuplicateClick, item }) => {
    return (
        <CheckboxContainer>
            <InputCheckbox checked={item.value} onChange={e => onDuplicateClick(item, e)} type="checkbox" />
            <CheckboxLabel>{item.label}</CheckboxLabel>
        </CheckboxContainer>
    );
};

DuplicateCheckbox.propTypes = {
    item: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.bool,
    }),
    onDuplicateClick: PropTypes.func,
};

const UpdateDuplicateContent = ({ duplicates, onDuplicateClick, onOverrideClick }) => {
    return (
        <UpdateModalContainer>
            <p>Existing records are found. Please select the IDs to be replaced</p>
            <List>
                {duplicates.map(duplicate => (
                    <DuplicateCheckbox key={duplicate.key} item={duplicate} onDuplicateClick={onDuplicateClick} />
                ))}
            </List>
            <ImportButton onClick={onOverrideClick}>Confirm Override</ImportButton>
        </UpdateModalContainer>
    );
};

UpdateDuplicateContent.propTypes = {
    duplicates: PropTypes.arrayOf(PropTypes.shape({})),
    onDuplicateClick: PropTypes.func,
    onOverrideClick: PropTypes.func,
};

export default UpdateDuplicateContent;
