import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Channel } from '../../schema';
import { getCurrentCountry } from '../../selectors';

const ALL = 'All';

export const allChannelOption = { value: ALL, label: ALL };

export type ChannelOption = { value: string | Channel; label: string };

export type UseDownloadChannelResult = [
    ChannelOption,
    Dispatch<SetStateAction<ChannelOption>>,
    {
        usedChannelOptions: ChannelOption[];
        channelValue: string | undefined;
    }
];

export const useDownloadChannels = (channelOptions: ChannelOption[]): UseDownloadChannelResult => {
    const { channelSetting } = useSelector(getCurrentCountry);

    const usedChannelOptions = useMemo(() => {
        const actives: Record<Channel, boolean> = {
            [Channel.NEW]: channelSetting?.new?.isActive,
            [Channel.USED]: channelSetting?.used?.isActive,
            [Channel.EXPRESS]: channelSetting?.express?.isActive,
            [Channel.EVENT]: channelSetting?.event?.isActive,
        };

        const result = channelOptions.filter(({ value }) => actives[value as Channel]);

        return [...result, allChannelOption];
    }, [channelOptions, channelSetting]);

    const [channel, setChannel] = useState<ChannelOption>(usedChannelOptions[0]);

    const channelValue = useMemo(() => {
        return channel?.value === ALL ? undefined : channel?.value.toLowerCase();
    }, [channel]);

    return [channel, setChannel, { usedChannelOptions, channelValue }];
};
