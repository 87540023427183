// @ts-ignore
import { HeadBar, HeadTitle } from '@appvantageasia/afc-ui';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import useMedia from 'use-media';
import {
    AdminBgContainer,
    ContentBody,
    ScrollContainer,
    FootContainer,
    FootBar,
    FootBarButton,
} from '../../../../../containers/Layout';
import breakpoints from '../../../../../utilities/constants/breakpoints';
import ESCEventDetector from '../../../../common/ESCEventDetector';
import ActionButtonSelect from '../../../../ui/form/ActionButtonSelect';

// @ts-ignore
const ignoreCheck = () => !!document.modalCount;

export type ApplicationFormProps = {
    title: string;
    onSubmit: (values: any) => void;
    onCancel: () => void;
    moreActions: (
        | {
              label: string;
              disabled: boolean;
              onAction: () => Promise<boolean>;
          }
        | {
              label: string;
              disabled: any;
              onAction: (...args: any[]) => Promise<boolean | null | undefined>;
          }
    )[];
    bodyComponent: JSX.Element | null;
};

const ApplicationForm = ({ title, bodyComponent, onCancel, moreActions, onSubmit }: ApplicationFormProps) => {
    const isMobile = useMedia({ maxWidth: breakpoints.md });

    const actions = useMemo(() => {
        if (!moreActions) {
            // the user is not here yet
            return [];
        }

        // filter btn submit and disable true
        return moreActions.filter(item => item.label !== 'submit' && !item.disabled);
    }, [moreActions]);

    const handleChange = useCallback(option => option.onAction(), []);

    // for submit btn on off
    const submit = moreActions.some(item => item.label === 'submit' && !item.disabled);
    const hasMoreActions = moreActions.length > 0;

    const footer =
        hasMoreActions &&
        moreActions.map(item => (
            <FootBarButton key={item.label} disabled={item.disabled} onClick={item.onAction}>
                {item.label}
            </FootBarButton>
        ));

    const mobileFooter = useMemo(
        () => (
            <>
                <ActionButtonSelect
                    isSearchable={false}
                    onChange={handleChange}
                    options={actions}
                    placeholder="Action"
                    value="Action"
                    isTextCenter
                />
                {submit && <FootBarButton onClick={onSubmit}>submit</FootBarButton>}
            </>
        ),
        [submit, onSubmit, actions, handleChange]
    );

    return (
        <AdminBgContainer>
            <ESCEventDetector ignoreCheck={ignoreCheck} onEvent={onCancel} />
            <ScrollContainer>
                <HeadBar>
                    <HeadTitle>{title}</HeadTitle>
                </HeadBar>
                <ContentBody>{bodyComponent}</ContentBody>
            </ScrollContainer>
            <FootContainer>
                <FootBar>
                    {onCancel && <FootBarButton onClick={onCancel}>cancel</FootBarButton>}
                    <div style={{ flex: 1 }} />
                    {isMobile ? mobileFooter : footer}
                </FootBar>
            </FootContainer>
        </AdminBgContainer>
    );
};

export default ApplicationForm;
