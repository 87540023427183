import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Select from 'react-select';

const BlockSelect = props => {
    const { inTable, backgroundColor = '#f0f0f0', minHeight, maxHeight, height, disabled } = props;
    const customStyles = useMemo(
        () => ({
            control: (provided, { isDisabled }) => ({
                ...provided,
                cursor: disabled ? 'default' : 'pointer',
                outline: 'none',
                border: 'none',
                boxShadow: 'none',
                borderRadius: 0,
                height: height || '30px',
                maxHeight: maxHeight || '30px',
                minHeight: minHeight || '30px',
                // eslint-disable-next-line no-nested-ternary
                backgroundColor: isDisabled ? '#d8d8d8' : inTable ? '#cacaca' : backgroundColor,
            }),
            menu: provided => ({
                ...provided,
                marginTop: 0,
                borderRadius: 0,
            }),
            menuList: provided =>
                disabled
                    ? { display: 'none' }
                    : {
                          ...provided,
                          padding: 0,
                          maxHeight: '200px',
                      },
            option: (provided, { isSelected, isFocused }) => ({
                ...provided,
                cursor: disabled ? 'default' : 'pointer',
                color: '#000000',
                backgroundColor: isSelected || isFocused ? 'rgba(0, 0, 0, 0.04)' : '#ffffff',
                padding: '7px 10px',
                ':active': {
                    ...provided[':active'],
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            }),
            indicatorSeparator: () => ({ display: 'none' }),
            dropdownIndicator: provided =>
                disabled
                    ? { display: 'none' }
                    : {
                          ...provided,
                          padding: '0 5px 0 0',
                          color: '#000000',
                          ':hover': {
                              ...provided[':hover'],
                              color: '#000000',
                          },
                      },
            clearIndicator: () => ({ display: 'none' }),
            input: provided => ({ ...provided, margin: 0 }),
            valueContainer: provided => ({
                ...provided,
                padding: '0px 10px 0 10px',
                height: height || '30px',
                overflowY: 'auto',
            }),
            singleValue: provided => ({
                ...provided,
                marginLeft: 0,
                marginRight: 0,
                fontSize: '0.85rem',
                color: '#000000',
                lineHeight: '30px',
            }),
            multiValue: provided => ({
                ...provided,
                margin: '0 3px 0 0',
                color: '#000000',
                backgroundColor: '#aba8a8',
                height: '24px',
                borderRadius: 0,
            }),
            multiValueLabel: provided => ({
                ...provided,
                padding: '0 6px 0 6px',
                lineHeight: '24px',
            }),
            multiValueRemove: (provided, { data }) =>
                disabled || data?.isFixed
                    ? { display: 'none' }
                    : {
                          ...provided,
                          ':hover': {
                              ...provided[':hover'],
                              backgroundColor: '#aba8a8',
                              color: '#000000',
                          },
                      },
            placeholder: provided => ({
                ...provided,
                color: '#000000',
                fontSize: '0.85rem',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                right: '10px',
                left: '10px',
            }),
        }),
        [inTable, backgroundColor]
    );
    const selectProps = {
        menuPlacement: 'auto',
        noOptionsMessage: () => 'No data available',
        styles: customStyles,
    };

    return <Select {...props} {...selectProps} />;
};

BlockSelect.propTypes = {
    backgroundColor: PropTypes.string,
    className: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape()), PropTypes.shape(), PropTypes.string]),
    disabled: PropTypes.bool,
    height: PropTypes.string,
    inTable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isMulti: PropTypes.bool,
    maxHeight: PropTypes.string,
    minHeight: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape()),
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape()), PropTypes.shape(), PropTypes.string]),
};

export default BlockSelect;
