import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { InjectedFormProps } from 'redux-form';
import { LanguageOption } from '../../../../../i18n';
import { CountryDataFragment } from '../../../../../utils/useLoadCompany.graphql';
import { GetEventQuery } from '../Edition.graphql';
import { GetDataQuery } from '../Page.graphql';

export type FormValues = GetEventQuery['event'];

export type EventSubmissionCount = {
    financingCount: number;
    reservationCount: number;
    leadCount: number;
};

export type FormContext = InjectedFormProps<FormValues> & {
    values: FormValues;
    currency: CountryDataFragment['currency'];
    rounding: CountryDataFragment['rounding'];
    channelSetting: CountryDataFragment['channelSetting'];
    countryCode: CountryDataFragment['code'];
    availableDealers: GetDataQuery['availableDealers'];
    languageOptions: LanguageOption[];
    disabled?: boolean;

    // Should show dealer filter or not
    setSubmissionCounter: Dispatch<SetStateAction<EventSubmissionCount>>;
};

export const Context = createContext<FormContext>(null!);

export const useFormContext = () => useContext(Context);
