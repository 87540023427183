import { useApolloClient } from '@apollo/client';
import { isObject, omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { submit } from 'redux-form';
import { addNotification } from '../../../../actions';
import { useContentTranslation } from '../../../../i18n';
import { getGlobalPermissions } from '../../../../selectors';
import { OPTION_DELETE_NOTIFICATION, OPTION_SAVE_NOTIFICATION } from '../../../../shared/constants/notification';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import useLanguageSelector from '../../../../utils/useLanguageSelector';
import useValidation from '../../../../utils/useValidation';
import { withModal } from '../../../Modal';
import FormLayout from '../../../ui/form/FormLayout';
import Form from './Form';
import validator from './Form/validate';
import { create, update, uploadImage, deleteImage, deleteOption } from './Page.graphql';

const Page = ({ initialValues = null, modal }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const languageSelector = useLanguageSelector();
    const { mayManageVehicles } = useSelector(getGlobalPermissions);

    const submitForm = useCallback(() => dispatch(submit('option')), [dispatch]);

    const { mapIntlValue } = useContentTranslation();
    const client = useApolloClient();
    const onSubmit = useCallback(
        async ({ image, ...data }) => {
            const isUpdate = !!data?.id;
            const query = isUpdate ? update : create;
            const variables = {
                data: prepareForGraphQL({
                    ...data,
                    name: mapIntlValue(data.name),
                }),
                id: data?.id,
            };

            if (isUpdate) {
                // we need to remove some more dta
                variables.data = omit(['id', 'lastModified', 'version'], variables.data);
            }

            let promise = client.mutate({ mutation: query, variables });

            if (image instanceof File) {
                // must upload a new file
                promise = promise.then(({ data: { response } }) =>
                    client.mutate({ mutation: uploadImage, variables: { id: response.id, file: image } })
                );
            } else if (image === null && isObject(initialValues.image)) {
                // must remove an existing file
                promise = promise.then(({ data: { response } }) =>
                    client.mutate({ mutation: deleteImage, variables: { id: response.id } })
                );
            }

            return promise.catch(handleResponseError);
        },
        [client, initialValues, mapIntlValue]
    );

    const deleteForm = useCallback(async () => {
        const { id } = initialValues;
        await client.mutate({ mutation: deleteOption, variables: { id } });

        dispatch(addNotification(OPTION_DELETE_NOTIFICATION));
        history.push('/vehicle/options');
    }, [client, dispatch, history, initialValues]);

    const onDelete = useCallback(() => {
        modal.confirm({
            title: '',
            content: 'Are you sure you want to delete this option?',
            options: [
                { label: 'No', action: () => null },
                { label: 'Yes', action: deleteForm },
            ],
        });
    }, [deleteForm, modal]);

    const onSubmitSuccess = useCallback(() => {
        dispatch(addNotification(OPTION_SAVE_NOTIFICATION));
        history.push('/vehicle/options');
    }, [history, dispatch]);

    const onCancel = useCallback(() => history.goBack(), [history]);

    const validate = useValidation(validator);

    return (
        <FormLayout
            bodyComponent={
                initialValues && (
                    <Form
                        {...languageSelector}
                        disabled={!mayManageVehicles}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onSubmitSuccess={onSubmitSuccess}
                        validate={validate}
                    />
                )
            }
            moreActions={[
                mayManageVehicles &&
                    initialValues?.id && {
                        label: 'delete',
                        onAction: onDelete,
                    },
                mayManageVehicles && {
                    label: 'save',
                    onAction: submitForm,
                },
            ]}
            onCancel={onCancel}
            title="Option Details"
        />
    );
};

Page.propTypes = {
    initialValues: PropTypes.shape({
        id: PropTypes.string,
    }),
    modal: PropTypes.shape({
        confirm: PropTypes.func,
    }),
};

export default withModal(Page);
