import { useQuery } from '@apollo/client';
import { get, getOr } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useContentTranslation } from '../../../../../i18n';
import TextareaField from '../../../../shared/form/TextareaField';
import DropdownField from '../../../../template/Dropdown';
import TextField from '../../../../template/TextField';
import Portlet from '../../../../ui/Portlet';
import { getData } from './MainDetails.graphql';
import { RoleFormContext, useFormContext } from './context';

const MainDetails = () => {
    const { disabled, isUpdate, values } = useFormContext() as RoleFormContext;

    const { data } = useQuery(getData, { fetchPolicy: 'cache-and-network' });
    const companyId = get('companyId', values);
    const { ct } = useContentTranslation();
    const companies = useMemo(
        () =>
            getOr([], 'companies.items', data).map((value: { value: string; label: { [key: string]: string } }) => ({
                ...value,
                label: ct(value.label),
            })),
        [ct, data]
    );

    const countries = useMemo(() => {
        return companies.find((company: { value: string }) => company.value === companyId)?.countries || [];
    }, [companies, companyId]);

    return (
        <Portlet title="Main Details" closeable open>
            <div className="container-fluid" style={{ padding: 0 }}>
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField disabled={disabled} label="Role Name" name="name" />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField label="Company" name="companyId" options={companies} disabled />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField label="Country" name="countryId" options={countries} disabled />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextareaField disabled={disabled} label="Role Description" name="description" />
                    </div>
                    {isUpdate && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <TextField label="Last Modified" name="lastModified" disabled />
                        </div>
                    )}
                </div>
            </div>
        </Portlet>
    );
};

export default MainDetails;
