import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useValue } from '../../ValuesContext';
import StaticField, { sharedPropTypes } from './StaticField';

const defaultRender = props => <StaticField {...props} />;

const ConnectedField = ({ children = defaultRender, ...props }) => {
    const { name } = props;

    // get its current value
    const value = useValue(name, '');

    return children({ ...props, value });
};

ConnectedField.propTypes = {
    ...sharedPropTypes,
    children: PropTypes.func,
};

// use memo to reduce tree updates
export default memo(ConnectedField);
