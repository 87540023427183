import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { BankPresetOption, BankSigningMode } from '../../../../schema';
import { getCountry } from './Creation.graphql';
import Page from './Page';

const Creation = () => {
    const { countryId } = useParams();

    const variables = { countryId };
    const { data, loading } = useQuery(getCountry, { variables, fetchPolicy: 'cache-first' });
    const country = data?.country;

    const initialValues = useMemo(
        () => ({
            countryId,
            zoneIds: [],
            dealerIds: [],
            color: '#ef2c72',
            signing: { onCreate: BankSigningMode.NONE, onUpdate: BankSigningMode.NONE },
            isKYCMandatory: false,
            isGuarantorIncluded: false,
            hasVSOUpload: false,
            hasMyInfoIntegration: false,
            sendCustomerConfirmationEmail: false,
            isActive: true,
            hasIntegration: false,
            myInfo: null,
            establishment: 0,
            presetOption: BankPresetOption.NONE,
            isGuaranteedBuybackEnabled: false,
        }),
        [countryId]
    );

    if (!loading && !country) {
        return <Redirect to="/scope/countries" />;
    }

    return <Page dealers={country?.dealers} initialValues={country ? initialValues : null} zones={country?.zones} />;
};

export default Creation;
