import { getOr, head } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { FormTabContainer } from '../../../../containers/Layout';
import Gallery from '../../Vehicles/variant/Form/Gallery';
import { FinderVehicleDataFragment, VehicleImageConnectionDataFragment } from '../Edition.graphql';
import MainDetails from './MainDetails';
import { Context, FormProps } from './context';
import validate from './validate';

const Form = (props: FormProps & InjectedFormProps<FinderVehicleDataFragment, FormProps>) => {
    const { form } = props;
    const values = useSelector(getFormValues(form));

    const images = useMemo(() => {
        const edges: VehicleImageConnectionDataFragment['edges'] = getOr([], 'listing.vehicle.images.edges', values);

        return edges.map(({ node }) => head(node.variants));
    }, [values]);

    const context = { ...props, values };

    return (
        <Context.Provider value={context}>
            <FormTabContainer>
                <Tabs className="tabs">
                    <TabList>
                        <Tab>Main Details</Tab>
                        <Tab>Images</Tab>
                    </TabList>
                    <TabPanel>
                        <MainDetails />
                    </TabPanel>
                    <TabPanel>
                        <Gallery fieldName="images" hasTip={false} images={images} disabled />
                    </TabPanel>
                </Tabs>
            </FormTabContainer>
        </Context.Provider>
    );
};

// @ts-ignore
export default reduxForm({ form: 'finderVehicle', validate, enableReinitialize: true })(Form);
