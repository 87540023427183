import React from 'react';
import { Field } from 'redux-form';
import CurrencyField from '../../../../../template/Currency';
import FieldContainer from '../../../../../template/Field-container';

type LuxuryTaxProps = {
    currency: string;
    precision: number;
};

const LuxuryTax = ({ currency, precision }: LuxuryTaxProps) => (
    <>
        <hr />
        <div className="row">
            <div className="col-md-4 col-sm-12 col-xs-12">
                <FieldContainer label="Luxury Tax">
                    <Field
                        component={CurrencyField}
                        name="luxuryTax.amount"
                        precision={precision}
                        prefix={currency}
                        allowNegative
                    />
                </FieldContainer>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12">
                <FieldContainer label="Threshold of Total car price for vehicle with Engine Type = PETROL">
                    <Field
                        component={CurrencyField}
                        name="luxuryTax.threshold.petrol"
                        precision={precision}
                        prefix={currency}
                        allowNegative
                    />
                </FieldContainer>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12">
                <FieldContainer label="Threshold of Total car price for vehicle with Engine Type = ELECTRIC">
                    <Field
                        component={CurrencyField}
                        name="luxuryTax.threshold.electric"
                        precision={precision}
                        prefix={currency}
                        allowNegative
                    />
                </FieldContainer>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12">
                <FieldContainer label="Threshold of Total car price for vehicle with Engine Type = HYBRID">
                    <Field
                        component={CurrencyField}
                        name="luxuryTax.threshold.hybrid"
                        precision={precision}
                        prefix={currency}
                        allowNegative
                    />
                </FieldContainer>
            </div>
        </div>
    </>
);

export default LuxuryTax;
