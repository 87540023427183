import React from 'react';
import { useCalculatorContext } from '../../CalculatorContext';
import PromoCodeField from './PromoCodeField';

const ConnectedPromoCodeField = props => {
    // get the calculator context
    const { promo } = useCalculatorContext();

    return <PromoCodeField {...props} promo={promo} />;
};

export default ConnectedPromoCodeField;
