import { useQuery } from '@apollo/client';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import { compose } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import { FormTabContainer } from '../../../../../containers/Layout';
import { useContentTranslation } from '../../../../../i18n';
import { getCurrentCountry } from '../../../../../selectors';
import ApvPortlet from '../../../../template/Portlet';
import Languages from './Languages';
import MainDetails from './MainDetails';
import Variants from './Variants';
import { Context } from './context';
import { getData } from './data.graphql';
import validate from './validate';

const Form = props => {
    const { form, languageOptions } = props;
    const displayMultiLanguage = languageOptions.length > 1;
    const { formatPath } = useContentTranslation();
    const values = useSelector(getFormValues(form));

    const country = useSelector(getCurrentCountry);
    const variables = { countryId: country.id };
    const { data } = useQuery(getData, { variables, fetchPolicy: 'cache-and-network' });
    // if the model already have variant, we can not add sub model then.
    const models = data?.models?.items.filter(item => !item.variants.length) || [];
    const context = {
        ...props,
        values,
        apiData: models.map(model => ({ ...model, label: get(formatPath('label'), model) })),
    };

    return (
        <Context.Provider value={context}>
            <FormTabContainer>
                <Tabs forceRenderTabPanel>
                    <TabList>
                        <Tab>Main Details</Tab>
                        {displayMultiLanguage && <Tab>Multi-Language</Tab>}
                    </TabList>
                    <TabPanel>
                        <ApvPortlet name="Main Details" closable open>
                            <MainDetails />
                        </ApvPortlet>
                        <Variants />
                    </TabPanel>
                    {displayMultiLanguage && (
                        <TabPanel>
                            <Languages />
                        </TabPanel>
                    )}
                </Tabs>
            </FormTabContainer>
        </Context.Provider>
    );
};

Form.propTypes = {
    form: PropTypes.string.isRequired,
    languageOptions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
};

export default compose(reduxForm({ form: 'subModel', validate }), memo)(Form);
