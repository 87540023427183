import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { MenuLogoContainer, ToggleContainer } from './ui';
import logo from '../../header/logo_white.png';

const LogoLink = styled(Link)`
    display: flex;
    align-items: center;

    img {
        height: 13px;
    }
`;

type MenuLogoProps = {
    fixedMenu: boolean;
    toggleMenu: MouseEventHandler;
};

const MenuLogo = ({ fixedMenu, toggleMenu }: MenuLogoProps) => (
    <>
        <MenuLogoContainer>
            <LogoLink to="/home">
                <img alt="" src={logo} />
            </LogoLink>
        </MenuLogoContainer>
        <ToggleContainer onClick={toggleMenu}>
            <FontAwesomeIcon icon={fixedMenu ? faTimes : faBars} />
        </ToggleContainer>
    </>
);

export default MenuLogo;
