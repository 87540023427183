import { get, isEmpty } from 'lodash/fp';
import React from 'react';
import { useHistory } from 'react-router';
import ApvPortlet from '../../../../template/Portlet';
import { ListTable } from '../../../../ui/lists';
import { useFormContext } from './context';

const columns = [
    { id: 'code', name: 'Country Code', renderCell: get('code') },
    { id: 'name', name: 'Country Name', renderCell: get('name'), underline: true },
    { id: 'currency', name: 'Currency', renderCell: get('currency') },
];

const Countries = () => {
    const { countries } = useFormContext();
    const history = useHistory();

    if (isEmpty(countries)) {
        return null;
    }

    return (
        <ApvPortlet name="Countries" closable open>
            <div className="container-fluid">
                <br />
                <ListTable
                    columns={columns}
                    items={countries}
                    onItemClick={item => history.push(`/scope/countries/${item.id}`)}
                />
            </div>
        </ApvPortlet>
    );
};

export default Countries;
