import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import styled, { css } from 'styled-components';
import breakpoints from '../../utilities/constants/breakpoints';

export const DropdownS = styled(Dropdown)`
    margin: 0 10px;
    height: 28px;
    font-size: 1.14rem;
    color: #ffffff;
`;

export const DropdownToggleS = styled(DropdownToggle)`
    height: 28px;
    background: none !important;
    border: none !important;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: flex;

    &::after {
        display: none;
    }
`;

export const DropdownMenuS = styled(DropdownMenu)`
    margin-top: 6px;
    background-color: black;

    ${props =>
        props.setting &&
        css`
            text-align: right;
        `}
`;

export const DropdownItemS = styled(DropdownItem)`
    color: white;
    background-color: black;

    &:hover {
        color: white;
        background-color: rgba(255, 255, 255, 0.1);
        cursor: pointer;
    }
    &:focus {
        outline: none;
    }
`;

export const ToggleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 28px;

    img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        object-fit: cover;
    }

    svg {
        width: 8px !important;
        margin: 0 4px;
        color: rgba(255, 255, 255, 0.31);
        position: relative;
        top: 1px;
    }
`;

export const SingleTextContainer = styled.div`
    margin: 0 10px;
`;
export const Text = styled.div`
    display: flex;
    align-items: center;
    height: 28px;
    font-size: 1.14rem;
    color: #ffffff;
`;

export const HeaderContainer = styled.div`
    flex-shrink: 0;

    display: flex;
    flex-direction: row;
    height: 67.5px;
    background-color: #000000;
    padding: 20px 24px 20px 19.5px;
`;

export const HeaderMenuToggle = styled.div`
    display: none;

    @media (max-width: ${breakpoints.sm}) {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 20px;
    }
`;
