import { isNil } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';
import ParameterBox from '../../ui/ParameterBox';
import ParameterLabel from '../../ui/ParameterLabel';
import ParameterValue from '../../ui/ParameterValue';
import Tooltip from '../../ui/Tooltip';
import useTooltip from './useTooltip';

const StaticField = ({
    editable,
    editionContext,
    label,
    name,
    updateName,
    setFieldOnEdition,
    value,
    format,
    ...props
}) => {
    // if the field is editable, we will update it on click
    const onClick = useMemo(
        () => (editable ? () => setFieldOnEdition(updateName || name, editionContext) : undefined),
        [editable, name, updateName, setFieldOnEdition, editionContext]
    );

    const paramValue = format && !isNil(value) ? format(value) : value || '-';

    const valueRef = useRef(null);
    const tooltipRef = useTooltip(valueRef);

    return (
        <ParameterBox editable={editable} onClick={onClick} {...props}>
            <ParameterLabel>{label}</ParameterLabel>
            <ParameterValue ref={valueRef} name={name}>
                {paramValue}
            </ParameterValue>
            <Tooltip ref={tooltipRef}>{paramValue}</Tooltip>
        </ParameterBox>
    );
};

export const sharedPropTypes = {
    editable: PropTypes.bool.isRequired,
    editionContext: PropTypes.shape({}),
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    updateName: PropTypes.string,
    setFieldOnEdition: PropTypes.func.isRequired,
};

StaticField.propTypes = {
    ...sharedPropTypes,
    format: PropTypes.func,
    value: PropTypes.node.isRequired,
};

// use memo to reduce tree updates
export default StaticField;
