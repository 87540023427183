// withLoading is a asynchronised action factory

// eslint-disable-next-line import/prefer-default-export
export const withLoading = promise => dispatch => {
    if (promise instanceof Promise) {
        // inform we are listening another promise
        dispatch({ type: 'LOADING_BEGIN' });

        // wrap our promise
        return promise
            .then(resolved => {
                // inform it ended
                dispatch({ type: 'LOADING_END' });

                // return the resolved value
                return resolved;
            })
            .catch(error => {
                // inform it ended
                dispatch({ type: 'LOADING_END' });

                // keep the rejection ongoing
                return Promise.reject(error);
            });
    }

    // somehow we ended with something else than a promise
    // let's have some warning about it
    console.warn(`withLoading received a ${typeof promise} instead of Promise`);

    // fallback on what we expect
    return Promise.resolve();
};
