import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import ConnectedField from './ConnectedField';
import { useFormatCurrency } from './CurrencyField';
import { useFormatPercentage } from './PercentageField';
import StaticField from './StaticField';

const DualField = ({ connectComponent: Component = ConnectedField, ...props }) => {
    const { formatCurrency, formatCurrencyDown } = useFormatCurrency();
    const formatPercentage = useFormatPercentage();

    const render = useCallback(
        ({ value = {}, ...staticProps }) => {
            const { amount = 0, percentage = 0 } = value;
            let textValue = `${formatCurrency(amount)} (${formatPercentage(percentage)})`;

            const { name } = staticProps;
            if (name === 'loanAmount' || name === 'balloonPayment') {
                textValue = `${formatCurrencyDown(amount)} (${formatPercentage(percentage)})`;
            }
            if (name === 'deposit') {
                textValue = `${formatCurrencyDown(amount)}`;
            }

            return <StaticField {...staticProps} value={textValue} />;
        },
        [formatPercentage, formatCurrency, formatCurrencyDown]
    );

    return <Component {...props}>{render}</Component>;
};

DualField.propTypes = {
    connectComponent: PropTypes.elementType,
};

export default DualField;
