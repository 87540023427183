import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
`;

const TextAreaInput = styled.textarea`
    min-height: 100px !important;
    width: 100%;
    border: 0;
    color: ${props => props.theme.themeHighlightColour};
    background: #ffffff;
    font-size: 14px;
    padding: 0px;
    outline: none;

    &:disabled {
        color: #000000;
    }
`;

const TextArea = ({ inputRef, ...props }) => (
    <Container>
        <TextAreaInput {...props} ref={inputRef} />
    </Container>
);

TextArea.propTypes = {
    inputRef: PropTypes.shape({}),
};

export default TextArea;
