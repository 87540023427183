import { identity } from 'lodash';
import React from 'react';
import { useCalculatorContext } from '../../CalculatorContext';
import StaticField, { sharedPropTypes } from './StaticField';

export const useFormatCurrency = () => {
    const { formats = {} } = useCalculatorContext();
    const { formatCurrency = identity, formatCurrencyDown = identity } = formats;

    return { formatCurrency, formatCurrencyDown };
};

const CurrencyField = props => {
    const { formatCurrency } = useFormatCurrency();

    return <StaticField {...props} format={formatCurrency} />;
};

CurrencyField.propTypes = {
    ...sharedPropTypes,
};

export default CurrencyField;

export const renderCurrency = props => <CurrencyField {...props} />;
