import { useQuery } from '@apollo/client';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, isNil, orderBy } from 'lodash/fp';
import React, { useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FootBar, FootBarButton, FootContainer } from '../../../../containers/Layout';
import { getCompanyId, getCurrentCountry, getGlobalPermissions } from '../../../../selectors';
import { searchOnKeys } from '../../../../utilities/fp';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { List, cells, ListSearch, DealerSelectionDropDown } from '../../../ui/lists';
import usePaging from '../../../utilities/usePaging';
import { getTables } from './UserLists.graphql';
import useUserTabs from './useUserTabs';

const useColumns = () => {
    const history = useHistory();
    const formatDateTime = useFormatDateTime();

    return useMemo(
        () => [
            { name: 'User Name', id: 'username', renderCell: get('username'), hasSort: true, underline: true },
            { name: 'Name', id: 'name', renderCell: get('name'), hasSort: true },
            { name: 'Email', id: 'email', renderCell: get('email'), hasSort: true },
            { name: 'Contact', id: 'phone', renderCell: get('phone'), hasSort: true },
            {
                name: 'Role',
                id: 'permissions[0].role.name',
                renderCell: get('permissions[0].role.name'),
                hasSort: true,
                underline: true,
                onClick: (event, item) => {
                    event.stopPropagation();
                    history.push(`/access/roles/${item.permissions[0].role.id}`);
                },
            },
            {
                name: 'Last Signed In',
                id: 'lastLoginAt',
                renderCell: cells.renderDateTime('lastLoginAt', formatDateTime),
                hasSort: true,
            },
            { name: 'Active', id: 'isActive', renderCell: cells.renderActive(), hasSort: true },
        ],
        [history, formatDateTime]
    );
};

const searchFields = ['username', 'name', 'email', 'phone', 'permissions[0].country.name'];

const sortItems = (items, [sortKey, sortOrder]) => {
    // we always sort by value key
    return orderBy(sortKey, sortOrder, items);
};

const UserLists = () => {
    const tabs = useUserTabs();
    const history = useHistory();
    const { mayManageUsers } = useSelector(getGlobalPermissions);

    const columns = useColumns();

    const { id: countryId } = useSelector(getCurrentCountry);
    const companyId = useSelector(getCompanyId);
    const [selectedDealers, selectDealers] = useState(null);
    const variables = { countryId, dealerIds: selectedDealers };
    const { data, loading, error } = useQuery(getTables, {
        fetchPolicy: 'cache-and-network',
        variables,
        skip: isNil(selectedDealers),
    });
    const items = data?.users || [];
    const isLoading = loading && items.length <= 0;

    const modifiedItems = items.map(item => {
        const filtered = item.permissions.filter(
            permission => permission.companyId === companyId && permission.countryId === countryId
        );

        return { ...item, permissions: filtered };
    });

    // searching
    const [search, setSearch] = useState('');
    const searchMethod = useMemo(() => searchOnKeys(searchFields, modifiedItems), [modifiedItems]);
    const matchedItems = useMemo(() => searchMethod(search), [search, searchMethod]);

    // sorting
    const [sortedOn, onSort] = useState(['username', 'asc']);
    const sortedItems = useMemo(() => sortItems(matchedItems, sortedOn), [matchedItems, sortedOn]);

    // paging
    const [pagedItems, paging] = usePaging(sortedItems, { search });

    const createUser = useCallback(() => history.push('/access/users/new'), [history]);

    const footer = (
        <FootContainer>
            <FootBar>
                {mayManageUsers && (
                    <FootBarButton onClick={createUser}>
                        <FontAwesomeIcon icon={faPlus} /> ADD USER
                    </FootBarButton>
                )}
            </FootBar>
        </FootContainer>
    );

    return (
        <List
            activeTab="user"
            columns={columns}
            error={error}
            footer={footer}
            headerBottomComponent={
                <DealerSelectionDropDown dealerIds={selectedDealers} onValueChanged={selectDealers} />
            }
            headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
            items={pagedItems}
            loading={isLoading}
            onItemClick={item => history.push(`/access/users/${item.id}`)}
            onSort={onSort}
            paging={paging}
            sortedOn={sortedOn}
            tabWidth={316}
            tabs={tabs}
            title="User Management"
        />
    );
};

export default UserLists;
