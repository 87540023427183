import { get, isEmpty } from 'lodash/fp';
import React from 'react';
import { useHistory } from 'react-router';
import { useContentTranslation } from '../../../../../i18n';
import ApvPortlet from '../../../../template/Portlet';
import { ListTable } from '../../../../ui/lists';
import { useFormContext } from './context';

const columns = formatPath => [
    { id: 'code', name: 'Zone Code', renderCell: get('code') },
    { id: 'name', name: 'Zone Name', renderCell: get(formatPath('name')), underline: true },
    { id: 'tax', name: 'Tax %', renderCell: get('taxRate') },
    { id: 'zipCode', name: 'Zip Code', renderCell: get('zipCode') },
    { id: 'timezone', name: 'Time Zone', renderCell: get('timezone') },
];

const Zones = () => {
    const { zones } = useFormContext();
    const history = useHistory();
    const { formatPath } = useContentTranslation();

    if (isEmpty(zones)) {
        return null;
    }

    return (
        <ApvPortlet name="Zones" closable open>
            <div className="container-fluid">
                <br />
                <ListTable
                    columns={columns(formatPath)}
                    items={zones}
                    onItemClick={item => history.push(`/scope/zones/${item.id}`)}
                />
            </div>
        </ApvPortlet>
    );
};

export default Zones;
