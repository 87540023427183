import React from 'react';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../../i18n';
import { modelTypeOptions, onOffOptions } from '../../../../../shared/constants/options';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import InputField from '../../../../template/Input';
import NumberField from '../../../../template/Number';
import SwitchField from '../../../../template/Switch';
import { useFormContext } from './context';

const MainDetails = () => {
    const { formatPath } = useContentTranslation();
    const { disabled, values, apiData: models } = useFormContext();
    const edition = !!values.lastModified;

    const isActiveField = (
        <div className="col-md-4 col-sm-12 col-xs-12">
            <FieldContainer label="Active">
                <Field component={SwitchField} disabled={disabled} name="isActive" options={onOffOptions} />
            </FieldContainer>
        </div>
    );

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField
                            disabled={disabled}
                            label="Model"
                            name="parentId"
                            options={models}
                            placeholder="Select Model"
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Submodel Name">
                            <Field component={InputField} disabled={disabled} name={formatPath('name')} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Submodel ID">
                            <Field component={InputField} disabled={disabled} name="identifier" />
                        </FieldContainer>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField
                            disabled={disabled}
                            label="Body Type"
                            name="type"
                            options={modelTypeOptions}
                            placeholder="Select Body Type"
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Order No.">
                            <Field component={NumberField} disabled={disabled} name="order" precision={0} />
                        </FieldContainer>
                    </div>
                    {edition ? (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Last Modified">
                                <Field component={InputField} name="lastModified" disabled />
                            </FieldContainer>
                        </div>
                    ) : (
                        isActiveField
                    )}
                </div>
                {edition && <div className="row">{isActiveField}</div>}
            </div>
        </div>
    );
};

export default MainDetails;
