/* eslint-disable no-underscore-dangle */
import React, { useCallback } from 'react';
import useFormatDateTime from '../../../../shared/useFormatDateTime';
import ActivityLogContainer from '../../../../ui/ActivityLog';
import { DocumentContainer, Title } from '../../../../ui/Document';
import { AppointmentLogDataFragment } from '../../appointment.graphql';
import { useAppointmentFormContext } from '../Form/context';

const TabActivityLog = () => {
    const { values } = useAppointmentFormContext();

    const formatDateTime = useFormatDateTime();

    const getLabel = useCallback(
        (appointmentLog: AppointmentLogDataFragment) => {
            switch (appointmentLog.__typename) {
                case 'AppointmentLogAssigned':
                    return `Assigned from ${appointmentLog.previousAssigneeName ?? ''} to ${
                        appointmentLog.newAssigneeName ?? ''
                    }`;

                case 'AppointmentLogStatusChanged':
                    return `Status changed from ${appointmentLog.previousStatus ?? ''} to ${
                        appointmentLog.newStatus ?? ''
                    }`;

                case 'AppointmentLogCreated':
                    return `Appointment is created and assigned to ${appointmentLog.newAssigneeName ?? ''}`;

                case 'AppointmentLogAttachmentUploaded':
                    return `Attachment ${appointmentLog.fileName} uploaded by ${appointmentLog.createdBy?.name ?? ''}`;

                case 'AppointmentLogAttachmentDeleted':
                    return `Attachment ${appointmentLog.fileName} deleted by ${appointmentLog.createdBy?.name ?? ''}`;

                case 'AppointmentLogDateChanged':
                    return `Date changed from ${formatDateTime(appointmentLog.previousDate)} to ${formatDateTime(
                        appointmentLog.newDate
                    )}`;

                default:
                    return '';
            }
        },
        [formatDateTime]
    );

    const renderLogs = useCallback(
        (appointmentLog: AppointmentLogDataFragment, index: number) => (
            <tr key={index.toString()}>
                <td>{formatDateTime(appointmentLog.createdAt)}</td>
                <td>{getLabel(appointmentLog)}</td>
            </tr>
        ),
        [formatDateTime, getLabel]
    );

    return (
        <DocumentContainer>
            <Title>Activity Log</Title>
            <ActivityLogContainer style={{ paddingTop: 16 }}>
                <table>
                    <thead>
                        <tr>
                            <th>Date/Time</th>
                            <th>Activity</th>
                        </tr>
                    </thead>
                    <tbody>{values.activityLogs.map(renderLogs)}</tbody>
                </table>
            </ActivityLogContainer>
        </DocumentContainer>
    );
};

export default TabActivityLog;
