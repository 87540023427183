import { useQuery } from '@apollo/client';
import { get, orderBy, isNil, flow, find, getOr } from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { useContentTranslation } from '../../../../i18n';
import { ConsentOrDeclarationOwnerType } from '../../../../schema';
import { getCurrentCountry, getCurrentUserCountryDealers, getUser } from '../../../../selectors';
import { yesNoOptions, onOffOptions, consentDataFieldOptions } from '../../../../shared/constants/options';
import DropdownField from '../../../template/Dropdown';
import FieldContainer from '../../../template/Field-container';
import InputField from '../../../template/Input';
import NumberField from '../../../template/Number';
import SwitchField from '../../../template/Switch';
import TextareaField from '../../../template/Textarea';
import { getData, getEventData } from './MainDetails.graphql';
import { useFormContext } from './context';

const MainDetails = () => {
    const { values, change, id, disabled, initialValues } = useFormContext();
    const { formatPath, ct } = useContentTranslation();
    const country = useSelector(getCurrentCountry);
    const hasCheckbox = get('hasCheckbox', values);
    const hasLegalMarkup = get('hasLegalMarkup', values);
    const edition = !!get('lastModified', values);
    const ownerType = get('owner.type', values);
    const ownerId = get('owner.id', values);

    const isChannelEventActive = get('channelSetting.event.isActive', country) ?? false;

    const isInsuranceNewActive = get('channelSetting.new.isInsuranceEnabled', country) ?? false;

    const isInsuranceExpressActive = get('channelSetting.express.isInsuranceEnabled', country) ?? false;

    const allowFinanceApplicationNewChannel = get('channelSetting.new.allowFinanceApplication', country) ?? false;
    const allowFinanceApplicationExpressChannel =
        get('channelSetting.express.allowFinanceApplication', country) ?? false;

    const isOwnerTypeEvent = ownerType === ConsentOrDeclarationOwnerType.EVENT;
    const query = isOwnerTypeEvent ? getEventData : getData;

    const { data, loading } = useQuery(query, { variables: { id }, fetchPolicy: 'cache-first' });

    // organize owner options
    const ownerOptions = useMemo(() => {
        if (isNil(data)) {
            return [];
        }

        // creating consent from event
        if (ownerType === ConsentOrDeclarationOwnerType.EVENT) {
            return [
                {
                    value: { id: data?.event?.id, type: ConsentOrDeclarationOwnerType.EVENT },
                    label: data?.event?.name,
                },
            ];
        }

        const options = [];

        // creating consent from country/bank
        if (allowFinanceApplicationNewChannel || allowFinanceApplicationExpressChannel) {
            const bankOptions =
                orderBy(
                    ['label'],
                    ['asc'],
                    data?.banks.items.map(({ id: bankId, name }) => ({
                        value: { id: bankId, type: ConsentOrDeclarationOwnerType.BANK },
                        label: ct(name),
                    }))
                ) || [];

            options.push(...bankOptions);
        }

        if (isChannelEventActive) {
            options.unshift({
                value: { id: data?.country.id, type: ConsentOrDeclarationOwnerType.ALLEVENTS },
                label: 'All Events',
            });
        }

        if (isInsuranceNewActive || isInsuranceExpressActive) {
            // creating consent from insurance
            const insuranceOptions = data?.insurances.items.map(({ id: insuranceId, name }) => ({
                value: { id: insuranceId, type: ConsentOrDeclarationOwnerType.INSURANCE },
                label: ct(name),
            }));

            options.push(...insuranceOptions);
        }

        options.unshift(
            {
                value: { id: data?.country.id, type: ConsentOrDeclarationOwnerType.COUNTRY },
                label: `${ct(data?.country?.company?.name)} ${data?.country?.name}`,
            },
            {
                value: { id: data?.country.id, type: ConsentOrDeclarationOwnerType.PAYMENTTERMS },
                label: 'Payment Terms',
            },
            {
                value: { id: data?.country.id, type: ConsentOrDeclarationOwnerType.NEWCARCHANNEL },
                label: 'New Car Channel',
            },
            {
                value: { id: data?.country.id, type: ConsentOrDeclarationOwnerType.PREOWNEDCARCHANNEL },
                label: 'Pre-owned Channel',
            }
        );

        return options;
    }, [
        ct,
        data,
        isChannelEventActive,
        ownerType,
        allowFinanceApplicationNewChannel,
        allowFinanceApplicationExpressChannel,
        isInsuranceNewActive,
        isInsuranceExpressActive,
    ]);

    const allDealers = useMemo(() => {
        if (isNil(data)) {
            return [];
        }

        switch (ownerType) {
            case ConsentOrDeclarationOwnerType.EVENT:
                return getOr([], 'event.dealers', data);
            case ConsentOrDeclarationOwnerType.BANK:
                return flow(get('banks.items'), find(['id', ownerId]), getOr([], 'dealers'))(data);
            case ConsentOrDeclarationOwnerType.COUNTRY:
            case ConsentOrDeclarationOwnerType.PAYMENTTERMS:
            case ConsentOrDeclarationOwnerType.ALLEVENTS:
            case ConsentOrDeclarationOwnerType.NEWCARCHANNEL:
            case ConsentOrDeclarationOwnerType.PREOWNEDCARCHANNEL:
            default:
                return getOr([], 'country.dealers', data);
        }
    }, [data, ownerType, ownerId]);

    const userDealers = useSelector(getCurrentUserCountryDealers);
    const user = useSelector(getUser) || {};
    const dealerOptions = useMemo(() => {
        if (user.isSuperUser) {
            // return all dealers for super user
            return allDealers.map(option => ({ ...option, label: ct(option.label) }));
        }

        const combinedDealers = [...userDealers, ...(initialValues?.dealerIds || [])];

        // only return dealers associated to user or initialValues
        return allDealers
            .filter(({ value }) => combinedDealers.includes(value))
            .map(dealer => {
                // check if dealer is associated to user

                if (userDealers.includes(dealer.value)) {
                    return dealer;
                }

                // else it should be fixed
                return { ...dealer, isFixed: true };
            })
            .map(option => ({ ...option, label: ct(option.label) }));
    }, [allDealers, initialValues, user, userDealers, ct]);

    const dealerIds = get('dealerIds', values);
    useEffect(() => {
        if (!loading && dealerIds?.length) {
            const cleanedDealerIds = dealerIds.filter(dealerId => find(['value', dealerId], dealerOptions));
            if (cleanedDealerIds.length !== dealerIds.length) {
                change('dealerIds', cleanedDealerIds);
            }
        }
    }, [dealerOptions, loading, dealerIds, change]);

    useEffect(() => {
        if (!hasCheckbox) {
            change('isMandatory', false);
            change('hasLegalMarkup', false);
        }
    }, [hasCheckbox, change]);

    useEffect(() => {
        if (!hasLegalMarkup) {
            change('legalMarkup', null);
        }
    }, [hasLegalMarkup, change]);

    if (loading) {
        return null;
    }

    return (
        <div className="container-fluid" style={{ margin: '0 -10px' }}>
            <div className="row">
                {edition && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Consent ID">
                            <Field component={InputField} maxLength={100} name="identifier" disabled />
                        </FieldContainer>
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Name">
                        <Field component={InputField} disabled={disabled} maxLength={100} name={formatPath('name')} />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DropdownField
                        disabled={disabled || isOwnerTypeEvent}
                        label="Owner"
                        name="owner"
                        options={ownerOptions}
                        noSort
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DropdownField
                        disabled={disabled}
                        height="75px"
                        label="Assign to"
                        maxHeight="100px"
                        minHeight="75px"
                        name="dealerIds"
                        options={dealerOptions}
                        multi
                        noSort
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Active">
                        <Field component={SwitchField} disabled={disabled} name="isActive" options={onOffOptions} />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Checkbox">
                        <Field component={SwitchField} disabled={disabled} name="hasCheckbox" options={yesNoOptions} />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Mandatory">
                        <Field
                            component={SwitchField}
                            disabled={disabled || !hasCheckbox}
                            name="isMandatory"
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Order No.">
                        <Field component={NumberField} disabled={disabled} name="order" />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <DropdownField
                        disabled={disabled}
                        label="Data Field Name"
                        name="dataFieldName"
                        options={consentDataFieldOptions}
                        noSort
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Description">
                        <Field
                            component={TextareaField}
                            disabled={disabled}
                            maxLength={5000}
                            name={formatPath('description')}
                            rows={3}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Legal Text">
                        <Field
                            component={SwitchField}
                            disabled={disabled || !hasCheckbox}
                            name="hasLegalMarkup"
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                    {hasLegalMarkup && (
                        <Field
                            component={TextareaField}
                            disabled={disabled}
                            maxLength={100000}
                            name={formatPath('legalMarkup')}
                            placeholder="Legal Text..."
                            rows={10}
                        />
                    )}
                </div>
                {edition && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Last Modified">
                            <Field component={InputField} name="lastModified" disabled />
                        </FieldContainer>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MainDetails;
