import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getOr, isEmpty, keyBy } from 'lodash/fp';
import React, { useCallback, useEffect, useState } from 'react';
import { Field } from 'redux-form';
import { FootBarButton, TabExcelContainer } from '../../../../../containers/Layout';
import { useContentTranslation } from '../../../../../i18n';
import TableField from '../../../../TableField';
import { exportFinancialTabsExcel, getWorkbookToExport } from '../../../../utilities/excel';
import ImportFinanceTablesModal from '../../lists/ImportFinanceTablesModal';
import { useFormContext } from './context';

type ExcelTaxRecord = {
    min: number;
    max: number;
    value: number;
};
export type TaxTable = {
    name: string;
    label: string;
    rows: { label: string; values: { min: number; max: number } }[];
};

const licenseTaxRows: TaxTable['rows'] = [
    { label: '0', values: { min: 0, max: 0 } },
    { label: '1-500', values: { min: 1, max: 500 } },
    { label: '501-600', values: { min: 501, max: 600 } },
    { label: '601-1200', values: { min: 601, max: 1200 } },
    { label: '1201-1800', values: { min: 1201, max: 1800 } },
    { label: '1801-2400', values: { min: 1801, max: 2400 } },
    { label: '2401-3000', values: { min: 2401, max: 3000 } },
    { label: '3001-4200', values: { min: 3001, max: 4200 } },
    { label: '4201-5400', values: { min: 4201, max: 5400 } },
    { label: '5401-6600', values: { min: 5401, max: 6600 } },
    { label: '6601-7800', values: { min: 6601, max: 7800 } },
    { label: '7801-10000', values: { min: 7801, max: 10000 } },
];

const mappedHeaders = {
    'Displacement Min. *': 'min',
    'Displacement Max. *': 'max',
    'Tax *': 'value',
};

const getTaxKey = ({ min, max }: { min: number; max: number }) => `${min}-${max}`;

export const useDefaultTaxData = (key: string, data: number[], rows: TaxTable['rows']) => {
    const { values, change } = useFormContext();
    const table = getOr([], key, values);

    useEffect(() => {
        if (isEmpty(table)) {
            change(
                key,
                rows.map((row, rowIndex) => ({
                    ...row.values,
                    value: data[rowIndex],
                }))
            );
        }
    }, [change, data, key, rows, table]);
};
export const ExcelTax = ({ name, label, rows }: TaxTable) => {
    const { values, change } = useFormContext();

    const productName = getOr('', 'name', values);
    const [showImport, setShowImport] = useState(false);

    const { ct } = useContentTranslation();
    const table = getOr([], name, values);

    const exportExcel = useCallback(async () => {
        const workbook = await getWorkbookToExport(mappedHeaders);
        const worksheet = workbook.getWorksheet(1);
        const tableData = keyBy(getTaxKey, table || []);

        rows.forEach(row => {
            const { min, max } = row.values;
            const taxKey = getTaxKey({ min, max });
            worksheet.addRow({
                min,
                max,
                value: getOr(null, `${taxKey}.value`, tableData),
            });
        });

        exportFinancialTabsExcel(workbook, ct(productName), label);
    }, [ct, label, productName, rows, table]);

    const onImportClose = useCallback(() => {
        setShowImport(false);
    }, [setShowImport]);

    const onImport = useCallback(
        async (data: ExcelTaxRecord[]) => {
            const tableDataList = rows.map((record, index) => ({
                min: record.values.min,
                max: record.values.max,
                value: data[index]?.value ?? 0,
            }));
            change(name, tableDataList);
        },
        [change, name, rows]
    );

    return (
        <>
            <TabExcelContainer>
                <FootBarButton onClick={() => setShowImport(true)}>
                    <FontAwesomeIcon icon={faUpload} /> IMPORT EXCEL
                </FootBarButton>
                <FootBarButton onClick={exportExcel}>
                    <FontAwesomeIcon icon={faDownload} /> EXPORT EXCEL
                </FootBarButton>
            </TabExcelContainer>
            {showImport && (
                <ImportFinanceTablesModal
                    mappedHeaders={mappedHeaders}
                    onClose={onImportClose}
                    onImport={onImport}
                    type={label}
                />
            )}
        </>
    );
};

const defaultLicenseTaxes = [0, 1620, 2160, 4320, 7120, 11230, 15210, 28220, 46170, 69690, 117000, 151200];

const LicenseTaxTab = () => {
    const { disabled, rounding } = useFormContext();

    useDefaultTaxData('licenseAndFuelTaxSetting.licenseTable', defaultLicenseTaxes, licenseTaxRows);

    return (
        <div className="container-fluid">
            <Field
                columnKey="value"
                columns={[{ label: 'Value', value: 'value' }]}
                component={TableField}
                disabled={disabled}
                header="Displacement"
                name="licenseAndFuelTaxSetting.licenseTable"
                precision={rounding.amount.count}
                rows={licenseTaxRows}
            />
            <ExcelTax label="License Tax" name="licenseAndFuelTaxSetting.licenseTable" rows={licenseTaxRows} />
        </div>
    );
};

export default LicenseTaxTab;
