import styled from 'styled-components';

const Error = styled.p`
    //PO do not want modal extend when error message display
    //so we need min height for error message
    min-height: 16px;
    color: red;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 0;
`;

export default Error;
