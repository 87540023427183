import React, { useCallback } from 'react';
import { Field } from 'redux-form';
import FieldContainer from '../../../template/Field-container';
import InputField from '../../../template/Input';
import TextareaField from '../../../template/Textarea';
import { useFormContext } from './context';

const Languages = () => {
    const { disabled, languageOptions } = useFormContext();
    const getFormatPath = useCallback((language, label) => `${label}.${language}`, []);

    return (
        <div className="container-fluid">
            <div className="row">
                {languageOptions.map(language => (
                    <div key={language.value} className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label={`${language.label} Title`}>
                            <Field
                                component={InputField}
                                disabled={disabled}
                                name={getFormatPath(language.value, 'title')}
                            />
                        </FieldContainer>
                        <FieldContainer label={`${language.label} Description`}>
                            <Field
                                component={TextareaField}
                                disabled={disabled}
                                maxLength="500"
                                name={getFormatPath(language.value, 'description')}
                            />
                        </FieldContainer>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Languages;
