import styled from 'styled-components';

const Tooltip = styled.span`
    visibility: hidden;
    max-width: 300px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 8px;
    white-space: pre-wrap;
    position: fixed;
    z-index: 99999;
    font-size: 1rem;

    &::after {
        content: '';
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
    }
`;

export default Tooltip;
