import React from 'react';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { LeadList, ApplicationList, EditApplication, EditLead, EditActivation } from '../routes/Workflow/Finance';
import {
    ApplicationList as InsuranceApplicationList,
    EditApplication as EditInsuranceApplication,
} from '../routes/Workflow/Insurance';
import { ReservationList, EditReservation } from '../routes/Workflow/reservation';

const WorkflowRouter = () => (
    <Switch>
        <Route component={ReservationList} path="/workflow/reservations" exact />
        <Route component={ApplicationList} path="/workflow/applications" exact />
        <Route component={EditApplication} path="/workflow/applications/:id" exact />
        <Route component={EditReservation} path="/workflow/reservations/:id" exact />
        <Route component={EditActivation} path="/workflow/activation/:id" exact />
        <Route component={LeadList} path="/workflow/leads" exact />
        <Route component={EditLead} path="/workflow/leads/:id" exact />

        <Route component={InsuranceApplicationList} path="/workflow/insuranceApplications" exact />
        <Route component={EditInsuranceApplication} path="/workflow/insuranceApplications/:id" exact />

        <Redirect to="/home" />
    </Switch>
);

export default WorkflowRouter;
