import { useQuery } from '@apollo/client';
import { omit } from 'lodash/fp';
import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { getLastModified } from '../../../../utilities/forms';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import { getInsurance } from './Edition.graphql';
import Page from './Page';

const Edition = () => {
    const { id } = useParams();
    const variables = { id };
    const { data, loading } = useQuery(getInsurance, { variables, fetchPolicy: 'network-only' });

    const formatDateTime = useFormatDateTime();

    const initialValues = useMemo(() => {
        const values = data?.insurance;

        if (!values) {
            return null;
        }

        return {
            ...omit(['country'], values),
            lastModified: getLastModified(values.version, formatDateTime),
        };
    }, [data, formatDateTime]);

    if (!loading && !initialValues) {
        // the insurance does not exist
        return <Redirect to="/scope/countries" />;
    }

    return <Page initialValues={initialValues} zones={data?.insurance?.country?.zones} />;
};

export default Edition;
