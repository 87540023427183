import { get, isEmpty } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useContentTranslation } from '../../../../../i18n';
import { useSort } from '../../../../../utilities/hooks';
import ApvPortlet from '../../../../template/Portlet';
import { cells, ListTable } from '../../../../ui/lists';
import { useFormContext } from './context';

const useColumns = () => {
    const { formatPath } = useContentTranslation();

    return useMemo(
        () => [
            { name: 'Vehicle ID', id: 'identifier', renderCell: get('identifier'), hasSort: true },
            {
                name: 'Variant Name',
                id: formatPath('name'),
                renderCell: get(formatPath('name')),
                hasSort: true,
                underline: true,
            },
            { name: 'Order No.', id: 'order', renderCell: get('order'), hasSort: true },
            { name: 'Active', id: 'active', renderCell: cells.renderActive(), hasSort: true },
        ],
        [formatPath]
    );
};

const VariantList = () => {
    const history = useHistory();
    const { variants } = useFormContext();

    // sorting
    const [sortedOn, onSort] = useState(['identifier', 'asc']);
    const sortedItems = useSort(variants, sortedOn);

    const columns = useColumns();

    if (isEmpty(variants)) {
        return null;
    }

    return (
        <ApvPortlet name="Variant" closable open>
            <br />
            <div className="container-fluid">
                <ListTable
                    columns={columns}
                    items={sortedItems}
                    onItemClick={item => history.push(`/vehicle/variants/${item.id}`)}
                    onSort={onSort}
                    sortedOn={sortedOn}
                />
            </div>
        </ApvPortlet>
    );
};

export default VariantList;
