import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import { compose } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import { FormTabContainer } from '../../../../../containers/Layout';
import Banks from './Banks';
import Channels from './Channels';
import Insurances from './Insurances';
import Languages from './Languages';
import MainDetails from './MainDetails';
import Zones from './Zones';
import { Context } from './context';
import { getData } from './data.graphql';
import validate from './validate';

const Form = props => {
    const { form } = props;
    const values = useSelector(getFormValues(form));
    const { data } = useQuery(getData, { fetchPolicy: 'cache-and-network' });
    const displayMultiLanguage = values?.languages?.length > 1;

    const context = { ...props, values, apiData: data };

    return (
        <Context.Provider value={context}>
            <FormTabContainer>
                <Tabs forceRenderTabPanel>
                    <TabList>
                        <Tab>Main Details</Tab>
                        {displayMultiLanguage && <Tab>Multi-Language</Tab>}
                    </TabList>
                    <TabPanel>
                        <MainDetails />
                        <Zones />
                        <Channels />
                        <Banks />
                        <Insurances />
                    </TabPanel>
                    {displayMultiLanguage && (
                        <TabPanel>
                            <Languages />
                        </TabPanel>
                    )}
                </Tabs>
            </FormTabContainer>
        </Context.Provider>
    );
};

Form.propTypes = {
    form: PropTypes.string.isRequired,
};

export default compose(reduxForm({ form: 'country', validate }), memo)(Form);
