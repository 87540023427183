import { format, subMonths } from 'date-fns';

export const generateFilename = ({ companyCode, countryCode }, type) => {
    const date = format(new Date(), 'dd_MM_yyyy');

    return `${companyCode}_${countryCode}_${type}_${date}.xlsx`;
};

export const generateEventApplicationFilename = ({ companyCode, countryCode }, eventId, purpose) => {
    const date = format(new Date(), 'dd_MM_yyyy');

    return `${companyCode}_${countryCode}_${eventId}_${purpose}_${date}.xlsx`;
};

export const getApplicationFileName = ({ companyCode, countryCode }, duration, type) => {
    let time = '';

    switch (duration) {
        case 0:
        case 1:
            time = format(subMonths(new Date(), duration), 'yyyyMM');
            break;

        case 3:
        case 6:
        case 12:
            time = `${format(subMonths(new Date(), duration), 'yyyyMM')}-${format(subMonths(new Date(), 1), 'yyyyMM')}`;
            break;

        default:
            break;
    }

    return `${companyCode}_${countryCode}_${type}_${time}.xlsx`;
};
