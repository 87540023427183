import * as validators from '../../../../../utils/validators';

const validate = validators.compose(
    validators.requiredString('code'),
    validators.requiredString('name'),
    validators.requiredString('copyright'),
    validators.requiredString('email'),
    validators.validEmail('email'),
    validators.requiredBoolean('isActive')
);

export default validate;
