import { subYears } from 'date-fns';
import { getOr, capitalize } from 'lodash/fp';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReduxFormContext } from 'redux-form';
import { BankPresetOption, CustomerDetailsSource, MaskDirection, Channel } from '../../../../schema';
import { getRuntimeSettings } from '../../../../selectors';
import { getLastModified } from '../../../../utilities/forms';
import DatePickerField from '../../../shared/form-v2/DatePickerField';
import SelectField from '../../../shared/form-v2/SelectField';
import TextField from '../../../shared/form-v2/TextField';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import SubTitle from '../../../ui/SubTitle';
import usePhone from '../../../utilities/usePhone';
import useCustomerSourceOptions from '../useCustomerSourceOptions';
import { useFormContext } from './context';

const myInfoSource = [CustomerDetailsSource.MYINFO, CustomerDetailsSource.NOT_APPLICABLE];

export const getLabelWithFlag = (label: string, source?: CustomerDetailsSource) => {
    if (source && myInfoSource.includes(source)) {
        return `${label} (${capitalize(CustomerDetailsSource.MYINFO)})`;
    }

    return label;
};

const CustomerDetails = () => {
    const { sectionPrefix, getValues } = useContext(ReduxFormContext);
    const formValues = getValues();
    const { disabled, mask, options, allowPrimaryInfoChanges, application } = useFormContext();

    // get runtime settings
    const { useCustomerBirthDay } = useSelector(getRuntimeSettings);
    const withMyInfo = getOr(false, sectionPrefix ? `${sectionPrefix}.withMyInfo` : 'withMyInfo', formValues);
    const withFocusClear = mask ? mask.direction !== MaskDirection.NONE : false;
    const minDateOfBirth = useMemo(() => subYears(new Date(), 18), []);
    const customer = getOr(formValues, `${sectionPrefix}`, formValues);
    const countryCode = getOr('', 'zone.country.code', formValues);

    const { code: phoneCountryCode } = usePhone(countryCode);

    const formatDateTime = useFormatDateTime();
    const lastModified = getLastModified(formValues.version, formatDateTime);
    const customerSourceOptions = useCustomerSourceOptions();
    const isEvent = application?.channel === Channel.EVENT;

    const presetOption = formValues?.financeProduct?.bank?.presetOption;

    const isAffinBank = presetOption === BankPresetOption.AFFINBANK;
    const isTTBBank = presetOption === BankPresetOption.TTBBANK;

    const showDateOfBirth = countryCode === 'NZ' || isEvent || useCustomerBirthDay;

    return (
        <>
            <SubTitle>Customer Details</SubTitle>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <SelectField.Outline
                        label={getLabelWithFlag('Title', customer?.title?.source)}
                        name="title.value"
                        options={getOr([], 'title', options)}
                        disabled
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getLabelWithFlag('Full Name', customer?.name?.source)}
                        name="name.value"
                        withFocusClear={withFocusClear}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getLabelWithFlag('First Name', customer?.firstName?.source)}
                        name="firstName.value"
                        withFocusClear={withFocusClear}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getLabelWithFlag('Last Name', customer?.lastName?.source)}
                        name="lastName.value"
                        withFocusClear={withFocusClear}
                    />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        disabled={!allowPrimaryInfoChanges && disabled}
                        label={getLabelWithFlag('Email 1', customer?.email?.source)}
                        name="email.value"
                        withFocusClear={withFocusClear}
                    />
                </div>
                {withMyInfo && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            disabled={!allowPrimaryInfoChanges && disabled}
                            label={getLabelWithFlag('Email 2', customer?.emailBis?.source)}
                            name="emailBis.value"
                            withFocusClear={withFocusClear}
                        />
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField
                        label={getLabelWithFlag('Mobile No. 1', customer?.phone?.source)}
                        name="phone.value"
                        prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                        withFocusClear={withFocusClear}
                        disabled
                    />
                </div>
                {withMyInfo && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            disabled={!allowPrimaryInfoChanges && disabled}
                            label={getLabelWithFlag('Mobile No. 2', customer?.phoneBis?.source)}
                            name="phoneBis.value"
                            prefix={phoneCountryCode ? `+${phoneCountryCode}` : undefined}
                            type="tel"
                            withFocusClear={withFocusClear}
                        />
                    </div>
                )}
                {countryCode !== 'NZ' && !isTTBBank && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={getLabelWithFlag('Nationality', customer?.details?.nationality?.source)}
                            name="details.nationality.value"
                            options={getOr([], 'nationalities', options)}
                            disabled
                        />
                    </div>
                )}
                {countryCode !== 'NZ' && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <TextField
                            label={getLabelWithFlag('Identity No.', customer?.identityNumber?.source)}
                            name="identityNumber.value"
                            withFocusClear={withFocusClear}
                            disabled
                        />
                    </div>
                )}
                {showDateOfBirth && !isTTBBank && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DatePickerField
                            label={getLabelWithFlag('Date of Birth', customer?.dateOfBirth?.source)}
                            max={minDateOfBirth}
                            name="dateOfBirth.value"
                            disabled
                        />
                    </div>
                )}
                {!isTTBBank && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={getLabelWithFlag(
                                'Race',
                                isAffinBank
                                    ? customer?.details?.thirdParty?.affinBank?.race?.source
                                    : customer?.details?.race?.source
                            )}
                            name={isAffinBank ? 'details.thirdParty.affinBank.race.value' : 'details.race.value'}
                            options={getOr([], isAffinBank ? 'affinBank.races' : 'races', options)}
                            disabled
                        />
                    </div>
                )}
                {isAffinBank && (
                    <>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <SelectField.Outline
                                label={getLabelWithFlag(
                                    'ID Type',
                                    customer?.details?.thirdParty?.affinBank?.idType?.source
                                )}
                                name="details.thirdParty.affinBank.idType.value"
                                options={getOr([], 'affinBank.idTypes', options)}
                                disabled
                            />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <SelectField.Outline
                                label={getLabelWithFlag(
                                    'ID Issue Country',
                                    customer?.details?.thirdParty?.affinBank?.idIssueCountry?.source
                                )}
                                name="details.thirdParty.affinBank.idIssueCountry.value"
                                options={getOr([], 'countries', options)}
                                disabled
                            />
                        </div>
                    </>
                )}
                {!isTTBBank && (
                    <>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <SelectField.Outline
                                label={getLabelWithFlag('Gender', customer?.details?.gender?.source)}
                                name="details.gender.value"
                                options={getOr([], 'genders', customerSourceOptions)}
                                disabled
                            />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <SelectField.Outline
                                disabled={!allowPrimaryInfoChanges && disabled}
                                label={getLabelWithFlag('Marital Status', customer?.details?.maritalStatus?.source)}
                                name="details.maritalStatus.value"
                                options={getOr([], 'maritalStatuses', options)}
                                sort={false}
                            />
                        </div>
                    </>
                )}
                {isAffinBank && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={getLabelWithFlag(
                                'Country of Residence',
                                customer?.details?.thirdParty?.affinBank?.residentialCountry?.source
                            )}
                            name="details.thirdParty.affinBank.residentialCountry.value"
                            options={getOr([], 'countries', options)}
                            disabled
                        />
                    </div>
                )}
                {!isTTBBank && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <SelectField.Outline
                            label={getLabelWithFlag(
                                'Residential Status',
                                isAffinBank
                                    ? customer?.details?.thirdParty?.affinBank?.residentialStatus?.source
                                    : customer?.details?.residentialStatus?.source
                            )}
                            name={
                                isAffinBank
                                    ? 'details.thirdParty.affinBank.residentialStatus.value'
                                    : 'details.residentialStatus.value'
                            }
                            options={getOr(
                                [],
                                isAffinBank ? 'affinBank.residentialStatuses' : 'residentialStatuses',
                                isAffinBank ? options : customerSourceOptions
                            )}
                            disabled
                        />
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <TextField fixedValue={lastModified} label="Last Modified" name="__exclude.lastModified" disabled />
                </div>
            </div>
        </>
    );
};

export default CustomerDetails;
