import React from 'react';
import { useCalculatorContext } from '../../CalculatorContext';
import PromoCodeField from './PromoCodeField';

const PromoCodeSnapshot = props => {
    // get the calculator context
    const { snapshot } = useCalculatorContext();

    return <PromoCodeField promo={snapshot?.promo} {...props} />;
};

export default PromoCodeSnapshot;
