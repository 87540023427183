import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import ImportModal from '../../../ui/importModal';
import { getDatafromExcel } from '../../../utilities/excel';

const checkMissingVariants = (variants, excelData) => {
    const missingVariants = [];
    excelData.forEach(data => {
        if (
            variants.find(variant => data.variantId === variant) === undefined &&
            !missingVariants.includes(data.variantName)
        ) {
            missingVariants.push(data.variantName);
        }
    });

    return missingVariants;
};

const ImportFinanceTablesModal = ({ mappedHeaders, onClose, onImport, type, variants }) => {
    const [errors, setErrors] = useState(null);

    const onUpload = useCallback(
        async inputFile => {
            const data = await getDatafromExcel(inputFile, mappedHeaders);
            const errorList = [];

            // check if any of the headers are missing
            if (typeof data[0] === 'string') {
                errorList.push(...data);
            } else {
                // if not, check for assigned variants
                switch (type) {
                    case 'Lease Table':
                        {
                            const missingVariants = checkMissingVariants(variants, data);
                            if (missingVariants?.length) {
                                errorList.push(
                                    `Missing Assigned Variant/s: ${missingVariants.join(
                                        ', '
                                    )}. Please assign the variants and re-upload.`
                                );
                            }
                        }

                        break;
                    default:
                        break;
                }
            }
            if (errorList.length) {
                setErrors(errorList);

                return;
            }

            onImport(data);
            onClose();
        },
        [mappedHeaders, onClose, onImport, type, variants]
    );

    return <ImportModal errors={errors} onClose={onClose} onUpload={onUpload} type={type} />;
};

ImportFinanceTablesModal.propTypes = {
    mappedHeaders: PropTypes.shape({}).isRequired,
    onClose: PropTypes.func.isRequired,
    onImport: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    variants: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ImportFinanceTablesModal;
