import dayjs from 'dayjs';
import { head, isEmpty, split, trim } from 'lodash/fp';
import { CoeCategory } from '../../../../schema';
import { ownerIdOptions } from '../../../../shared/constants/options';
import { FinderVehicleDataFragment } from '../Edition.graphql';

type FieldName = keyof NonNullable<FinderVehicleDataFragment['details']>;

const contentToFieldName: Record<string, FieldName> = {
    'Owner ID Type': 'ownerIdType',
    'Owner ID': 'ownerId',
    'Vehicle Make': 'make',
    'Vehicle Model': 'model',
    'Primary Colour': 'colour',
    'Manufacturing Year': 'yearOfManufacture',
    'Vehicle No.': 'registrationNumber',
    'Engine No.': 'engineNumber',
    'Chassis No.': 'chassisNumber',
    'Open Market Value': 'omv',
    'Original Registration Date': 'originalRegistrationDate',
    'First Registration Date': 'firstRegistrationDate',
    'Transfer Count': 'transfers',
    'QP Paid': 'coe',
    'PQP Paid': 'pqp',
    'Actual ARF Paid': 'arf',
    'PARF Eligibility Expiry Date': 'parfEligibilityExpiryDate',
    'PARF Rebate Amount': 'parfRebateAmount',
    'COE Category': 'coeCategory',
    'COE Period(Years)': 'coePeriod',
    'COE Expiry Date': 'coeExpiryDate',
    'Maximum Power Output': 'power',
};

const parseNumber = (input: string) => {
    const purified = input.replace(/[^0-9.-]+/g, '');
    const parsed = parseInt(purified, 10);

    return Number.isNaN(parsed) ? undefined : parsed;
};

const parseCoeCategory = (input: string) => {
    const initial = head(input)?.toLowerCase();

    switch (initial) {
        case 'a':
            return CoeCategory.CATA;

        case 'b':
            return CoeCategory.CATB;

        case 'c':
            return CoeCategory.CATC;

        case 'e':
            return CoeCategory.CATE;

        default:
            return undefined;
    }
};

const parseOwnerIdType = (input: string) => {
    return ownerIdOptions.find(({ label, value }) => label.toLowerCase().startsWith(input.toLowerCase()))?.value;
};

const parseDate = (input: string) => {
    const value = dayjs(input);

    return value.isValid() ? value.toDate() : null;
};

const parse = (raw: string | null | undefined) => {
    if (isEmpty(raw)) {
        return {};
    }

    const lines = split(/\r?\n/, raw);

    const result: Partial<FinderVehicleDataFragment['details']> = {};
    let fieldName: FieldName | undefined;

    for (const line of lines) {
        const trimmed = trim(line);
        if (isEmpty(trimmed)) {
            continue;
        }

        if (fieldName) {
            switch (fieldName) {
                case 'omv':
                case 'coe':
                case 'transfers':
                case 'arf':
                case 'coePeriod':
                case 'parfRebateAmount':
                case 'pqp':
                    result[fieldName] = parseNumber(trimmed);
                    break;

                case 'originalRegistrationDate':
                case 'firstRegistrationDate':
                case 'coeExpiryDate':
                case 'parfEligibilityExpiryDate':
                    result[fieldName] = parseDate(trimmed);
                    break;

                case 'make':
                case 'model':
                case 'colour':
                case 'yearOfManufacture':
                case 'registrationNumber':
                case 'engineNumber':
                case 'chassisNumber':
                case 'ownerId':
                    result[fieldName] = trimmed;
                    break;

                case 'ownerIdType':
                    result[fieldName] = parseOwnerIdType(trimmed);
                    break;

                case 'coeCategory':
                    result[fieldName] = parseCoeCategory(trimmed);
                    break;

                case 'power': {
                    const match = trimmed.match(/\(.*bhp\)/);

                    if (match) {
                        const [value] = match;

                        result[fieldName] = parseNumber(value);
                    }
                    break;
                }
            }

            fieldName = undefined;

            continue;
        }

        const parsedFieldName = contentToFieldName[trimmed];
        if (parsedFieldName) {
            fieldName = parsedFieldName;
        }
    }

    return result;
};

export default parse;
