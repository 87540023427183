import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { yesNoOptions } from '../../../../../shared/constants/options';
import FieldContainer from '../../../../template/Field-container';
import SwitchField from '../../../../template/Switch';
import { useFormContext } from './context';

const TaxLoss = () => {
    const { disabled, values, change } = useFormContext();

    const show = get('taxLossSetting.show', values);
    const editable = get('taxLossSetting.editable', values);

    useEffect(() => {
        if (show === undefined) {
            change('taxLossSetting.show', false);
        }
        if (editable === undefined) {
            change('taxLossSetting.editable', false);
        }
    }, [show, change, editable]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Show">
                        <Field
                            component={SwitchField}
                            disabled={disabled}
                            name="taxLossSetting.show"
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Editable">
                        <Field
                            component={SwitchField}
                            disabled={!show || disabled}
                            name="taxLossSetting.editable"
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                </div>
            </div>
        </div>
    );
};

export default TaxLoss;
