import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import styled from 'styled-components';
import { ErrorMessageDiv } from '../../containers/Layout';
import { toDate } from '../../utilities/fp';
import { DATE_FORMAT } from '../../utils/DateFormat';
import Field from '../DatePickerField';

const DatePickerFieldDiv = styled.div`
    width: 100%;

    .react-datepicker-wrapper,
    .react-datepicker__input-container {
        width: 100%;
    }

    input {
        width: 100%;
        border: 0;
        color: #000000;
        background: #f0f0f0;
        height: 30px;
        font-size: 0.85rem;
        padding-left: 10px;
    }

    input[type='text']:disabled {
        background: #d8d8d8;
    }
`;

const DatePickerField = ({ input, meta, ...props }) => {
    const { error, touched, active } = meta;
    const { value, onChange } = input;

    const cleanedDate = useMemo(() => toDate(value), [value]);

    return (
        <DatePickerFieldDiv>
            <Field dateFormat={DATE_FORMAT} {...props} onChange={onChange} value={cleanedDate} />
            <ErrorMessageDiv>{touched && !active && error}</ErrorMessageDiv>
        </DatePickerFieldDiv>
    );
};

DatePickerField.propTypes = {
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};

export default DatePickerField;
