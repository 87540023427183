import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ConsentOrDeclarationOwnerType } from '../../../schema';
import { getCurrentCountry } from '../../../selectors';
import Page from './Page';

const Creation = () => {
    const { id } = useSelector(getCurrentCountry);

    const initialValues = useMemo(
        () => ({
            owner: {
                id,
                type: ConsentOrDeclarationOwnerType.COUNTRY,
            },
            dealerIds: [],
            hasLegalMarkup: false,
            hasCheckbox: false,
            isMandatory: false,
            isActive: true,
            identifier: '',
        }),
        [id]
    );

    return <Page id={id} initialValues={initialValues} />;
};

export default Creation;
