import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SortOrder } from '../../../../../schema';
import { sortToEnum, sortToTable } from '../../../Appointments/lists/AppointmentList';

export type SortState = {
    type: string;
    order: SortOrder;
};

export type EventApplicationsState = {
    paging: {
        page: number;
        pageSize: number;
    };
    sortedOn: SortState;
    checkedIds: string[];
    isCheckedAll: boolean;
};

export type SetPagingAction = {
    type: 'SET_PAGING';
    payload?: {
        page?: number;
        pageSize?: number;
    };
};

export type ChangeCheckAction = {
    type: 'CHANGE_CHECK';
    payload: string;
};

export type ChangeCheckAllAction = {
    type: 'CHANGE_CHECK_ALL';
};

export type SetSortAction = {
    type: 'SET_SORT';
    payload: [string, SortOrder];
};

export type EventApplicationsAction = SetPagingAction | ChangeCheckAction | ChangeCheckAllAction | SetSortAction;

export const eventApplicationsReducer = (
    state: EventApplicationsState,
    action: EventApplicationsAction
): EventApplicationsState => {
    switch (action.type) {
        case 'SET_PAGING':
            return {
                ...state,
                paging: {
                    page: action.payload?.page ?? state.paging.page,
                    pageSize: action.payload?.pageSize ?? state.paging?.pageSize,
                },
            };

        case 'CHANGE_CHECK':
            return {
                ...state,
                isCheckedAll: false,
                checkedIds: state.checkedIds.includes(action.payload)
                    ? state.checkedIds.filter(id => id !== action.payload)
                    : [...state.checkedIds, action.payload],
            };

        case 'CHANGE_CHECK_ALL':
            return {
                ...state,
                isCheckedAll: !state.isCheckedAll,
                checkedIds: !state.isCheckedAll ? [] : state.checkedIds,
            };

        case 'SET_SORT':
            return {
                ...state,
                sortedOn: {
                    type: action.payload[0],
                    order: action.payload[1],
                },
            };

        default:
            return state;
    }
};

export const initialEventApplicationsState: EventApplicationsState = {
    paging: {
        page: 1,
        pageSize: 10,
    },
    sortedOn: {
        type: 'identifier',
        order: SortOrder.DESC,
    },
    checkedIds: [],
    isCheckedAll: false,
};

export const useReducerActions = (
    state: EventApplicationsState,
    dispatch: React.Dispatch<EventApplicationsAction>,
    itemCountProps?: number
) => {
    const prevItemCount = useRef<number>(0);
    const [itemCount, setItemCount] = useState(itemCountProps ?? 0);

    useEffect(() => {
        if (!prevItemCount.current && itemCountProps) {
            setItemCount(itemCountProps);

            prevItemCount.current = itemCountProps;
        }
    }, [itemCountProps]);

    const pagingProps = useMemo(
        () => ({
            page: state.paging.page,
            pageSize: state.paging.pageSize,
            setPage: (page: number) => dispatch({ type: 'SET_PAGING', payload: { page } }),
            itemCount,
            pageCount: Math.ceil(itemCount / state.paging.pageSize),
        }),
        [dispatch, itemCount, state.paging.page, state.paging.pageSize]
    );

    const onSort = useCallback(
        (newSorts: [string, 'asc' | 'desc']) => {
            dispatch({ type: 'SET_SORT', payload: [newSorts[0], sortToEnum(newSorts[1])] });
        },
        [dispatch]
    );

    const onCheckboxChange = useCallback(
        (versionId: string) => {
            if (versionId === 'all') {
                dispatch({ type: 'CHANGE_CHECK_ALL' });

                return;
            }

            dispatch({ type: 'CHANGE_CHECK', payload: versionId });
        },
        [dispatch]
    );

    return {
        pagingProps,
        onSort,
        sortOnTable: [state.sortedOn.type, sortToTable(state.sortedOn.order)],
        onCheckboxChange,
    };
};
