import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { Unit } from '../../../../../schema';
import { yesNoOptions } from '../../../../../shared/constants/options';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import NumberField from '../../../../template/Number';
import SwitchField from '../../../../template/Switch';
import { useFormContext } from './context';

const unitOptions = [{ label: 'km', value: Unit.KM }];

const AverageMileage = () => {
    const { disabled, values, change, rounding } = useFormContext();

    const min = get('averageMileageSetting.min', values);
    const max = get('averageMileageSetting.max', values);
    const show = get('averageMileageSetting.show', values);
    const step = get('averageMileageSetting.step', values);
    const defaultValue = get('averageMileageSetting.default', values);
    const defaultUnit = get('averageMileageSetting.defaultUnit', values);

    useEffect(() => {
        if (min === max) {
            change('averageMileageSetting.step', 0);
            change('averageMileageSetting.default', min);
        }

        if (show === undefined) {
            change('averageMileageSetting.show', false);
        }

        if (!show || min === max) {
            change('averageMileageSetting.editable', false);
        }

        if (!defaultUnit) {
            change('averageMileageSetting.defaultUnit', Unit.KM);
        }
    }, [show, change, min, max, step, defaultValue, defaultUnit]);

    const mayManageEditable = show || min !== max;

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <div className="sub-container-fluid">
                        <FieldContainer label="Min*">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                min={0}
                                name="averageMileageSetting.min"
                                precision={rounding.percentage.count}
                            />
                        </FieldContainer>
                        <span />
                        <FieldContainer label="Max*">
                            <Field
                                component={NumberField}
                                disabled={disabled}
                                min={0}
                                name="averageMileageSetting.max"
                                precision={rounding.percentage.count}
                            />
                        </FieldContainer>
                        <span />
                        <FieldContainer label="Step*">
                            <Field
                                component={NumberField}
                                disabled={min === max || disabled}
                                max={max}
                                min={1}
                                name="averageMileageSetting.step"
                                precision={rounding.percentage.count}
                            />
                        </FieldContainer>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <div className="sub-container-fluid">
                        <div className="flex-3">
                            <FieldContainer label="Default*">
                                <Field
                                    component={NumberField}
                                    disabled={disabled}
                                    min={0}
                                    name="averageMileageSetting.default"
                                    precision={rounding.percentage.count}
                                />
                            </FieldContainer>
                        </div>
                        <span />
                        <DropdownField
                            label=""
                            name="averageMileageSetting.defaultUnit"
                            options={unitOptions}
                            disabled
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Show">
                        <Field
                            component={SwitchField}
                            disabled={disabled}
                            name="averageMileageSetting.show"
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Editable">
                        <Field
                            component={SwitchField}
                            disabled={!mayManageEditable || disabled}
                            name="averageMileageSetting.editable"
                            options={yesNoOptions}
                        />
                    </FieldContainer>
                </div>
            </div>
        </div>
    );
};

export default AverageMileage;
