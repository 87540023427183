import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { rotateNotification } from '../../actions';

const NotificationManager = ({ children }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        return history.listen(() => dispatch(rotateNotification()));
    }, [history, dispatch]);

    return children;
};

NotificationManager.propTypes = {
    children: PropTypes.node.isRequired,
};

export default NotificationManager;
