import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConsentList, CreateConsent, EditConsent } from '../routes/Consent';
import CreateConsentFromEvent from '../routes/Events/events/Form/Consents/Creation';

const ConsentRouter = () => (
    <Switch>
        <Route component={ConsentList} path="/consent" exact />
        <Route component={CreateConsent} path="/consent/fromCountry" exact />
        <Route component={CreateConsentFromEvent} path="/consent/fromEvent/:eventId" exact />
        <Route component={EditConsent} path="/consent/:id" exact />
        <Redirect to="/home" />
    </Switch>
);

export default ConsentRouter;
