import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { ConsentOrDeclarationOwnerType } from '../../../schema';
import { getCurrentCountry } from '../../../selectors';
import { getLastModified } from '../../../utilities/forms';
import useFormatDateTime from '../../shared/useFormatDateTime';
import { getConsent } from './Edition.graphql';
import Page from './Page';
import { deepOmit } from './utilities';

const Edition = () => {
    const { id: countryId } = useSelector(getCurrentCountry);
    const { id } = useParams<{ id: string }>();
    const variables = { id };
    const { data, loading } = useQuery(getConsent, { variables, fetchPolicy: 'network-only' });

    const formatDateTime = useFormatDateTime();

    const initialValues = useMemo(() => {
        const values = deepOmit(['__typename'], data?.consent);

        if (!values) {
            return null;
        }

        return {
            ...values,
            hasLegalMarkup: !isEmpty(values.legalMarkup),
            lastModified: getLastModified(values.version, formatDateTime),
        };
    }, [data, formatDateTime]);

    const isEventConsent = initialValues?.owner?.type === ConsentOrDeclarationOwnerType.EVENT;
    const ownerId = initialValues?.owner?.id;

    if (!loading && !initialValues) {
        // the consent does not exist
        return <Redirect to="/consent" />;
    }

    if (!initialValues) {
        return null;
    }

    return <Page id={isEventConsent ? ownerId : countryId} initialValues={initialValues} />;
};

export default Edition;
