import { get } from 'lodash/fp';
import * as validators from '../../../../../utils/validators';

const validate = validators.compose(
    validators.requiredString('username'),
    validators.minStringLength('username', 3),
    validators.maxStringLength('username', 40),
    validators.requiredString('name'),
    validators.requiredString('phonePrefix'),
    validators.requiredString('email'),
    validators.validEmail('email'),
    validators.maxStringLength('alias', 20),
    validators.requiredString('companyId'),
    validators.requiredValue('permissions'),
    validators.forEach('permissions', countryIndex =>
        validators.compose(
            validators.requiredString(`permissions.[${countryIndex}].companyId`),
            validators.requiredString(`permissions.[${countryIndex}].countryId`),
            validators.requiredString(`permissions.[${countryIndex}].roleId`),
            validators.only(
                get(`permissions.[${countryIndex}].__exclude.selectedRole.parentId`),
                validators.requiredString(`permissions.[${countryIndex}].superiorId`)
            ),
            validators.requiredString(`permissions.[${countryIndex}].viewData`),
            validators.requiredBoolean(`permissions.[${countryIndex}].isParallelView`),
            validators.requiredStringArray(`permissions.[${countryIndex}].dealerIds`)
        )
    )
);

export default validate;
