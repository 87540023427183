import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { BalloonMode } from '../../../../../schema';
import { balloonBasedOnOptions, balloonTypeOptions, yesNoOptions } from '../../../../../shared/constants/options';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import SwitchField from '../../../../template/Switch';
import BalloonPaymentRange from './BalloonPaymentRange';
import { useFormContext } from './context';

const BalloonPayment = () => {
    const { disabled, values, change } = useFormContext();

    const mode = get('balloonSetting.mode', values);
    const show = get('balloonSetting.show', values);
    const min = get('balloonSetting.min', values);
    const max = get('balloonSetting.max', values);

    const editable = show && mode === BalloonMode.RANGE && min !== max;
    const showEditable = mode !== BalloonMode.TABLE;

    useEffect(() => {
        if (!show) {
            change('balloonSetting.editable', false);
        }
    }, [change, show]);

    useEffect(() => {
        if (!balloonTypeOptions.some(item => item.value === mode)) {
            change('balloonSetting.mode', balloonTypeOptions[0].value);
        }
    }, [mode, change]);

    useEffect(() => {
        if (!showEditable) {
            change('balloonSetting.editable', false);
        }
    }, [showEditable, change]);

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Balloon Type">
                            <Field
                                component={SwitchField}
                                disabled={disabled}
                                name="balloonSetting.mode"
                                options={balloonTypeOptions}
                            />
                        </FieldContainer>
                    </div>
                </div>
                {mode === BalloonMode.RANGE && <BalloonPaymentRange />}
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <DropdownField
                            disabled={disabled}
                            label="Base On"
                            name="balloonSetting.basedOn"
                            options={balloonBasedOnOptions}
                            placeholder="Select Base On"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Show">
                            <Field
                                component={SwitchField}
                                disabled={disabled}
                                name="balloonSetting.show"
                                options={yesNoOptions}
                            />
                        </FieldContainer>
                    </div>
                    {showEditable && (
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Editable">
                                <Field
                                    component={SwitchField}
                                    disabled={!editable || disabled}
                                    name="balloonSetting.editable"
                                    options={yesNoOptions}
                                />
                            </FieldContainer>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BalloonPayment;
